import React, { Component } from 'react';
import Container from 'modules/core/components/container/container';
import './layout.css';

class Layout extends Component {
  render() {
    const { side, main } = this.props;

    return (
      <Container>
        <div className="Profile_Layout">
          <div className="Profile_Layout-grid">
            <div className="Profile_Layout-side">{side}</div>
            <div className="Profile_Layout-main">{main}</div>
          </div>
        </div>
      </Container>
    );
  }
}

export default Layout;
