import { takeEvery, takeLatest, call, put, select, cancelled } from 'redux-saga/effects';
// import { eventChannel } from 'redux-saga';
import { delay } from 'redux-saga';
import * as fromAccount from 'modules/core/ducks/account';
import BasketActions from '../ducks/basket/actions';
import * as fromBasket from '../ducks/basket/selectors';
import * as BASKET from '../ducks/basket/types';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import * as Basket from '../domain/basket';
import * as goodsDomain from '../domain/goods';
import { mixitApi } from 'services/mixit';
import { createBasketPersistor } from 'services/local-storage';
import takeDebounced from 'modules/utils/saga/take-debounced';
import ifChanged from 'modules/utils/saga/if-changed';
import { addSeconds } from 'modules/utils';
import { getSerialized as getSerializedBasket } from 'services/local-storage/selectors/basket';

import * as fromRouter from 'modules/core/ducks/router/selectors';

import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
// import { queryStringToJSON } from 'modules/utils/get-utm';
// import BroadcastChannel from 'broadcast-channel';

export function* basketWatcher() {
  yield takeEvery(BASKET.REQUEST, requestBasket);
  yield takeEvery(BASKET.SHOW, ifAuthorized(requestBasket));
  yield takeEvery(BASKET.REHYDRATE, verifyBasketRehydrate);
  yield takeLatest(BASKET.VERIFY_REQUEST, verify);
  yield takeLatest(
    LOCATION_CHANGE,
    ifAuthorized(() => {}, handleLocationChange)
  );

  /* PRODUCTS */
  yield takeEvery(BASKET.PRODUCT_ADD, ifAuthorized(addProduct, updateBasket));
  yield takeLatest(BASKET.PRODUCT_CHANGE_AMOUNT, ifAuthorized(changeProductAmount, updateBasket));
  yield takeEvery(BASKET.PRODUCT_REMOVE, ifAuthorized(removeProduct, updateBasket));

  /* PROMOS */
  yield takeEvery(BASKET.PROMO_APPLY, ifAuthorized(applyPromo, updateBasket));
  yield takeEvery(BASKET.PROMO_OPTION_SELECT, ifAuthorized(applyPromoOption, updateBasket));
  yield takeEvery(BASKET.PROMO_REMOVE, ifAuthorized(removePromo, updateBasket));

  /** BONUSES */
  yield takeEvery(BASKET.BONUS_APPLY, applyBonuses);
  yield takeEvery(BASKET.BONUS_REMOVE, applyBonuses);
  // yield takeEvery(BASKET.BONUS_REMOVE, removeBonuses);
}

export function* authWatcher() {
  yield takeEvery([ACCOUNT.AUTHORIZATION_READY], handleAuthResponse);
}

function* handleLocationChange({ payload }) {
  const {
    location: { pathname }
  } = payload;

  const matchBasketPage = matchPath(pathname, {
    path: '/basket'
  });
  const isBasketPage = Boolean(matchBasketPage);
  if (!isBasketPage) {
    return;
  }

  yield updateBasket();
}

export function* persistenceWatcher() {
  const basketPersistor = createBasketPersistor();

  const rehydrate = function*() {
    const RESET_BASKET_EXPIRE_SECONDS = 4 * 24 * 60 * 60;
    const RESET_PROMO_EXPIRE_SECONDS = 3 * 60 * 60;
    // const RESET_PROMO_EXPIRE_SECONDS = 20;
    const basket = yield call(basketPersistor.read);

    if (!basket) {
      return;
    }

    const resetBasketExpiredDate = addSeconds(basket.lastChangeDate, RESET_BASKET_EXPIRE_SECONDS);
    const isBasketExpired = new Date() > resetBasketExpiredDate;

    const resetPromoExpiredDate = addSeconds(basket.lastChangeDate, RESET_PROMO_EXPIRE_SECONDS);
    const isPromoExpired = new Date() > resetPromoExpiredDate;

    if (isBasketExpired) {
      // yield put(BasketActions.reset());
      return;
    }

    if (isPromoExpired) {
      // yield put(BasketActions.resetPromo());

      const _basket = { ...basket, promos: [] };
      yield put(BasketActions.rehydrate(_basket));
      return;
    }

    yield put(BasketActions.rehydrate(basket));
  };

  const persist = function*(basket) {
    yield call(basketPersistor.write, basket);
  };

  const getState = function*() {
    return yield select(getSerializedBasket);
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
  yield takeDebounced('*', ifChanged(getState, persist), 200);
}

// export function* tabVisibilitychangeWatcher() {
//   if (!__BROWSER__) {
//     return;
//   }

//   function visibilityChangeEmitt() {
//     return eventChannel(emitter => {
//       const handleVisibilityChange = data => {
//         emitter(data);
//       };

//       const unsubscribe = () => {
//         document.removeEventListener('visibilitychange', handleVisibilityChange);
//       };

//       document.addEventListener('visibilitychange', handleVisibilityChange);

//       return unsubscribe;
//     });
//   }

//   function* handleTabVisibilityChange() {
//     const authorization = yield getAuth();

//     if (document.hidden) {
//       return;
//     }
//     if (!authorization) {
//       yield updateBasket({}, true);
//       return;
//     }

//     yield requestBasket();
//   }

//   const tabVisibilitichangeChanel = visibilityChangeEmitt();
//   yield takeEvery(tabVisibilitichangeChanel, handleTabVisibilityChange);
// }

export function* basketChangeWatcher() {
  // const basketChannel = new BroadcastChannel('basketChannel');
  // function handleBroadcastMessage(goods) {
  //   const parsedGoods = JSON.parse(goods);
  //   const action = BasketActions.updateFromLocalStorage(parsedGoods);
  //   put(action, action);
  // }
  // basketChannel.addEventListener('message', handleBroadcastMessage);
  // const notifyChannelSubscribers = function*() {
  // const goods = yield select(fromBasket.getGoods);
  // const stringifiedBasket = JSON.stringify(goods);
  // yield basketChannel.postMessage(stringifiedBasket);
  // };
  // yield takeEvery(BASKET.CHANGES_NOTIFY, notifyChannelSubscribers);
}

function getAuth() {
  return select(fromAccount.getAuthorization);
}

function* handleAuthResponse() {
  const authorization = yield getAuth();

  if (!authorization) {
    return;
  }

  yield put(BasketActions.sync());
  yield syncFromLocal();

  // yield put(BasketActions.sync());

  // const { error, basket } = yield getBasketRequest();

  // if (error) {
  //   yield syncFromLocal();
  //   return;
  // }

  // const basketEntity = Basket.deserialize(basket);
  // const changeDate = yield select(fromBasket.getLastChangeDate);

  // if (basketEntity.changeDate <= changeDate) {
  //   yield syncFromLocal();
  //   return;
  // }

  // yield put(BasketActions.syncResponse(basket));
}

function* syncFromLocal() {
  const goods = yield select(fromBasket.getGoods);
  const promos = yield select(fromBasket.getPromos);

  const authorization = yield select(fromAccount.getAuthorization);

  const currentBasket = Basket.serialize({ goods, promos });

  const { error, basket } = yield pushBasketRequest(currentBasket, authorization);

  if (error) {
    console.error(error);
    // yield put(BasketActions.syncResponse(basket));
    return;
  }

  yield put(BasketActions.syncResponse(basket));
}

function* requestBasket() {
  const { error, basket } = yield getBasketRequest();

  if (error) {
    console.error(error);
    return;
  }

  // const lastChangeLocal = yield select();

  yield put(BasketActions.updateFromServer(basket));
}

function* addProduct(action) {
  const { product, variant, amount } = action.payload;

  const { error, basket } = yield addGoodsRequest(goodsDomain.buildItem(product, variant, amount));

  if (error) {
    console.error(error);
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* removeProduct(action) {
  const { product } = action.payload;

  const { error, basket } = yield deleteGoodsRequest(product.slug);

  if (error) {
    console.error(error);
    return;
  }

  if (!basket) {
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* changeProductAmount(action) {
  yield delay(1000);
  const { product, variant, amount } = action.payload;
  const changedGoods = goodsDomain.buildItem(product, variant, amount);

  const { error, basket } = yield changeGoodsRequest(product.slug, changedGoods);

  if (error) {
    console.error(error);
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* applyPromo(action) {
  const { code } = action.payload;
  const { error, basket } = yield addPromosRequest(code);

  if (error) {
    yield put(BasketActions.errorPromo(code));
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* applyPromoOption(action) {
  const { promoId, optionId } = action.payload;
  const { error, basket } = yield changePromoRequest(promoId, optionId);

  if (error) {
    console.warn(error);
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* removePromo(action) {
  const { promo } = action.payload;
  const { error, basket } = yield deletePromosRequest(promo.id);

  if (error) {
    console.error(error);
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* verifyBasketRehydrate({ payload }) {
  const authorization = yield select(fromAccount.getAuthorization);

  const currentPathName = yield select(fromRouter.getPathName);
  const matchBasketPage = matchPath(currentPathName, {
    path: '/basket'
  });
  const isBasketPage = Boolean(matchBasketPage);
  if (!isBasketPage) {
    return;
  }

  if (authorization) {
    return;
  }

  yield updateBasket(payload.basket);
}

function* updateBasket(basket, reload) {
  const currentPathName = yield select(fromRouter.getPathName);
  const matchBasketPage = matchPath(currentPathName, {
    path: '/basket'
  });
  const isBasketPage = Boolean(matchBasketPage);
  if (!isBasketPage) {
    return;
  }

  yield put(BasketActions.verifyRequest(reload));
}

function* verify({ payload }) {
  const shouldReload = payload.reload;
  try {
    yield delay(1000);
    yield put(BasketActions.setOuterBasketLoading());
    const goods = yield select(fromBasket.getGoods);

    const promos = yield select(fromBasket.getPromos);

    const currentBasket = Basket.serialize({ goods, promos });

    const { error, basket } = yield pushBasketRequest(currentBasket, null, shouldReload);

    if (error) {
      console.error(error);
      return;
    }
    yield put(BasketActions.updateFromServer(basket));
  } finally {
    yield put(BasketActions.setOuterBasketLoaded());
    if (yield cancelled()) {
      yield put(BasketActions.verifyCancel());
    }
  }
}

/* REQUESTS */
function* getBasketRequest() {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(mixitApi(authorization).basket().item);
    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* pushBasketRequest(localBasket, authorization = null) {
  try {
    const response = yield call(mixitApi(authorization).basket().add, {
      ...localBasket
    });
    const basket = response.data;
    return { basket };
  } catch (error) {
    return { error };
  }
}

function* addGoodsRequest(goods) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .goods().add,
      {
        product_slug: goods.product,
        variant_id: goods.variant,
        quantity: goods.amount
      }
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* deleteGoodsRequest(id) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .goods(id).delete
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* changeGoodsRequest(id, goods) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .goods(id).change,
      {
        product_slug: goods.product,
        variant_id: goods.variant,
        quantity: goods.amount
      }
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* addPromosRequest(code) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .promos().add,
      {
        code
      }
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* changePromoRequest(id, optionId) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .promos(id).change,
      {
        option_id: optionId
      }
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

function* deletePromosRequest(id) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .promos(id).delete
    );

    const basket = response.data;

    return { basket };
  } catch (error) {
    return { error };
  }
}

/** BONUSES */

function* applyBonuses(action) {
  const { value } = action.payload;

  const { error, basket } = yield addBonusesRequest({ value });

  if (error) {
    yield put(BasketActions.errorBonus(error));
    return;
  }

  yield put(BasketActions.updateFromServer(basket));
}

function* addBonusesRequest({ value }) {
  const authorization = yield select(fromAccount.getAuthorization);
  const data = { bonuses: { value } };

  try {
    const response = yield call(
      mixitApi(authorization)
        .basket()
        .bonuses(data).apply
    );
    const basket = response.data;
    return { basket };
  } catch (error) {
    return { error };
  }
}

// function* removeBonuses() {
//   const { error, basket } = yield removeBonusesRequest();
//   yield delay(1000);

//   if (error) {
//     yield put(BasketActions.errorBonus(error));
//     return;
//   }

//   yield put(BasketActions.updateFromServer(basket));
// }

// function* removeBonusesRequest() {
//   const authorization = yield select(fromAccount.getAuthorization);

//   try {
//     const response = yield call(
//       mixitApi(authorization)
//         .basket()
//         .bonuses().remove
//     );

//     const basket = response.data;

//     return { basket };
//   } catch (error) {
//     return { error };
//   }
// }

/* UTILS */

function ifAuthorized(saga, elseSaga) {
  return function*(...params) {
    const isAuthorized = yield select(fromAccount.getIsAuthorized);
    if (isAuthorized) {
      yield saga(...params);
    } else {
      if (!elseSaga) return;
      yield elseSaga(...params);
    }
  };
}
