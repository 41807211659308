import React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-media';

// import Container from 'modules/core/components/container/container';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
import LandingBannerImages from '../landing-banner-images/landing-banner-images';
import RibbonLabel from '../ribbon-label/ribbon-label';
import SVGNewCircle from '../svg-new-circle/svg-new-circle';

import './opening-shops-banner.css';

export default function OpeningShopsBanner({ data, colors, light }) {
  return (
    <MediaQuery query="(max-width: 840px)">
      {isMobile => (
        <div className={cn('OpeningShopsBanner')}>
          {/* <div className="OpeningShopsBanner-top"> */}
          <div className="OpeningShopsBanner-title">
            <LandingSectionTitle
              data={data.title}
              top={data.title[0]}
              bottom={data.title[1]}
              large
              noGap
              light={light}
            />
          </div>
          <div className="OpeningShopsBanner-labels">
            <RibbonLabel labels={data.labels} colors={colors} />
          </div>
          {/* </div> */}

          <div className="OpeningShopsBanner-imagesHolder">
            <div className="OpeningShopsBanner-images">
              <LandingBannerImages
                images={data.images}
                mainWidth={isMobile ? '64%' : '70%'}
                secondaryWidth={isMobile ? '64%' : '56%'}
                translateY={isMobile ? '-45%' : '-20%'}
                // reverse
              />
            </div>
          </div>

          <div className="OpeningShopsBanner-bottom">
            <div className="OpeningShopsBanner-newCircle">
              <SVGNewCircle />
            </div>
          </div>
        </div>
      )}
    </MediaQuery>
  );
}
