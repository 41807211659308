import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Media from 'modules/core/components/media/media';
import BetterCarousel from 'modules/core/components/better-carousel/carousel';
import VideoTrigger from 'modules/core/components/video-trigger/video-trigger';

import mediaShape from '../../prop-types/media-shape';
import PaginationButton from './pagination-button';

import { MEDIA_TYPE } from 'modules/core/components/media/constants';

import getYoutubeVideoIdentifier from 'modules/utils/get-youtube-video-identifier';

import CosmopolitenIcon from 'modules/core/components/icons/cosmopoliten-icon/cosmopoliten-icon';
import Zoomer from 'modules/core/components/zoomer/zoomer';

import './product-card-slider.css';

function CarouselPage({ mediaList, current, page, onClick }) {
  return (
    <div className="ProductCardSlider-paginationItem">
      <PaginationButton media={mediaList[page]} size={280} onClick={onClick} isActive={current} />
    </div>
  );
}

class MediaSlide extends Component {
  state = {
    isFullImageLoad: false,
    wasShoved: false,
    isVideoPlaying: false
  };

  render() {
    const { media, onVideoTriggerClick, isCurrent } = this.props;
    const { isFullImageLoad, wasShoved, isVideoPlaying } = this.state;

    if (media.type === MEDIA_TYPE.VIDEO) {
      const link = media.sources[0].main.normal;
      const youtubeIdentifier = getYoutubeVideoIdentifier(link);

      if (youtubeIdentifier) {
        return (
          <div key={media.id} className="ProductCardSlider-item">
            <div className="ProductCardSlider-media">
              <VideoTrigger
                key={media.id}
                identifier={youtubeIdentifier}
                onClick={onVideoTriggerClick(youtubeIdentifier)}
              />
            </div>
          </div>
        );
      }

      return (
        wasShoved && (
          <div key={media.id} className="ProductCardSlider-item">
            <div className="ProductCardSlider-media">
              <Media
                media={media}
                nocaption
                noClickableVideo
                setVideoState={this.handleSetVideoState}
                active={isCurrent && isVideoPlaying}
              />
            </div>
          </div>
        )
      );
    }

    return (
      <div
        key={media.id}
        className={cn('ProductCardSlider-item', {
          'ProductCardSlider-item--withFullImage': isFullImageLoad
        })}
      >
        <div className="ProductCardSlider-media">
          <Zoomer throttleTime="60" ratio={2}>
            <Media
              media={media}
              resizeWidth={1064}
              stretch="horizontal"
              background="#fff"
              nocaption
              onLoad={this.handleImageLoad}
            />
          </Zoomer>
        </div>
        <div className="ProductCardSlider-blurredImage">
          <div className="ProductCardSlider-media">
            <Media
              media={media}
              resizeWidth={100}
              background="#fff"
              stretch="horizontal"
              nocaption
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { isCurrent } = this.props;
    if (isCurrent) {
      this.setWasShoved();
    }
  }

  componentDidUpdate(prevProps) {
    const { isCurrent } = this.props;
    if (!prevProps.isCurrent && isCurrent) {
      this.setWasShoved();
    }
  }

  setWasShoved() {
    this.setState({
      wasShoved: true
    });
  }

  showFullImage() {
    this.setState({ isFullImageLoad: true });
  }

  handleImageLoad = () => {
    this.showFullImage();
  };

  handleSetVideoState = isVideoPlaying => {
    this.setState({ isVideoPlaying });
  };
}

class ProductCardSlider extends Component {
  static propTypes = {
    /**
     * madiaList - массив всех переданных медиа
     */
    mediaList: PropTypes.arrayOf(mediaShape)
  };

  state = {
    currentPage: 0
  };

  render() {
    const { mediaList, isCosmopolitanIconExist } = this.props;

    const { currentPage } = this.state;

    return (
      <div className="ProductCardSlider">
        {isCosmopolitanIconExist && (
          <div className="ProductCardSlider-cosmopolitenLabel">
            <CosmopolitenIcon />
          </div>
        )}

        <BetterCarousel
          duration={300}
          currentPage={currentPage}
          onChange={this.handleCarouselChange}
          pagination={mediaList.length > 1}
          container={<div className="ProductCardSlider-list" />}
          paginationContainer={<div className="ProductCardSlider-pagination" />}
          page={<CarouselPage mediaList={mediaList} />}
        >
          {mediaList.map((media, index) => (
            <MediaSlide
              key={index}
              media={media}
              onVideoTriggerClick={this.handleVideoTriggerClick}
              isCurrent={currentPage === index}
            />
          ))}
        </BetterCarousel>
      </div>
    );
  }

  handleCarouselChange = data => {
    this.changePage(data.page);
  };

  handleVideoTriggerClick = identefier => () => {
    const { onVideoTriggerClick } = this.props;

    onVideoTriggerClick(identefier);
  };

  changePage(page) {
    const { mediaList } = this.props;
    const currentPage = this.normalizePage(page, 0, mediaList.length - 1);

    this.setState({
      currentPage
    });
  }

  normalizePage(page, first, last) {
    if (page >= last) {
      return last;
    }

    if (page <= first) {
      return first;
    }

    return page;
  }
}

export default ProductCardSlider;
