import React, { Component } from 'react';
// import Media from 'react-media';

import Link from '../link/short-link';

import Dialog from '../dialog/dialog';
import DialogSection from '../dialog/dialog-section/dialog-section';
import PasswordRecoveryRequestForm from '../password-recovery-request-form/password-recovery-request-form';

import './password-recovery-request-dialog.css';

class PasswordRecoveryRequestDialog extends Component {
  render() {
    const {
      error,
      onRegistrationOpen,
      onAuthOpen,
      onClose,
      onSubmit,
      onChange,
      isSubmitting,
      defaultValue,
      accountEmail,
      ...rest
    } = this.props;

    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="PasswordRecoveryRequestDialog-content">
          <DialogSection title="Восстановление пароля">
            <div className="PasswordRecoveryRequestDialog-description">
              Введите свой email и мы вышлем Вам пароль
            </div>
            <div className="PasswordRecoveryRequestDialog-form">
              <PasswordRecoveryRequestForm
                error={error}
                onSubmit={onSubmit}
                onRegistrationOpen={onRegistrationOpen}
                onChange={onChange}
                isSubmitting={isSubmitting}
                defaultValue={defaultValue}
              />
            </div>
            {!defaultValue && (
              <div className="PasswordRecoveryRequestDialog-actions">
                <div className="PasswordRecoveryRequestDialog-authOpen">
                  <Link component="button" type="button" onClick={onAuthOpen}>
                    Я вспомнил пароль
                  </Link>
                </div>
              </div>
            )}
          </DialogSection>
        </div>
      </Dialog>
    );
  }
}

export default PasswordRecoveryRequestDialog;
