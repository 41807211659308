import * as userLocation from './user-location-saga';
import * as location from './location-saga';
import * as locationLocalStorage from './location-local-storage';

const sagas = {
  ...userLocation,
  ...location,
  ...locationLocalStorage
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
