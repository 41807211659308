export default function initBannersRequest(createRequest) {
  return {
    list: createRequest((parameters = null) => {
      return {
        method: 'GET',
        path: `/banners`,
        query: parameters
      };
    })
  };
}
