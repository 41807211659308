import React, { Component } from 'react';
import Media from 'react-media';
// import DottedList from 'modules/core/components/dotted-list/dotted-list.jsx';
import TrueDottedList from 'modules/core/components/dotted-list/true-dotted-list';
import DashList from '../dash-list/dash-list';
import { IconCircleRuble, IconCircleClock } from 'modules/core/components/icons/index';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import './payment-info.css';

class PaymentInfo extends Component {
  render() {
    const { timeList, paymentList, freeCost, description } = this.props;
    return (
      <Media query="(max-width: 560px)">
        {isMobile => (
          <div className="PaymentInfo">
            <div className="PaymentInfo-title">
              <div className="PaymentInfo-titleIco">
                <IconContainer>
                  <IconCircleClock />
                </IconContainer>
              </div>
              <h4 className="PaymentInfo-titleText">Время</h4>
            </div>
            <div className="PaymentInfo-timeList">
              {isMobile ? <DashList list={timeList} /> : <TrueDottedList list={timeList} table />}
            </div>
            <h4 className="PaymentInfo-title">
              <div className="PaymentInfo-titleIco">
                <IconContainer>
                  <IconCircleRuble />
                </IconContainer>
              </div>
              <h4 className="PaymentInfo-titleText">Стоимость</h4>
            </h4>
            <div className="PaymentInfo-paymentList">
              {isMobile ? (
                <DashList list={paymentList} withListMark />
              ) : (
                <TrueDottedList list={paymentList} table markedList />
              )}
            </div>
            <div className="PaymentInfo-freeCost">
              {isMobile ? (
                <DashList list={[freeCost]} />
              ) : (
                <TrueDottedList list={[freeCost]} table />
              )}
            </div>
            <div className="PaymentInfo-description">{description}</div>
          </div>
        )}
      </Media>
    );
  }
}

export default PaymentInfo;
