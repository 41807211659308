import * as profile from './profile-saga';

import * as subscriptions from './subscriptions';

import * as physicalTraits from './physical-traits-saga';
import * as passwordChange from './password-change-saga';

const sagas = {
  ...profile,
  ...subscriptions,
  ...physicalTraits,
  ...passwordChange
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
