import React, { Component } from 'react';
import './icon-arrow-down.css';

class IconArrowDown extends Component {
  render() {
    return (
      <svg
        className="IconArrowDown"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -53.545 256.05 256.05"
      >
        <path d="M119.53 145.43l-116-117.8a12 12 0 0 1 0-17l7.1-7.1a12 12 0 0 1 17 0L128 105.83 228.42 3.63a12 12 0 0 1 17 0l7.1 7.1a12 12 0 0 1 0 17l-116 117.8a12.1 12.1 0 0 1-16.99-.1z" />
      </svg>
    );
  }
}

export default IconArrowDown;
