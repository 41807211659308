import ArticleSchema from './schemas/article-schema';
import MediaSchema from './schemas/media-schema';
import { normalize as _normalize } from 'normalizr';

export function normalize(apiArticleResponse) {
  const media = MediaSchema();
  const article = ArticleSchema({ media });

  return _normalize(apiArticleResponse, article);
}
