import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RegistrationDialog from '../components/registration-dialog/registration-dialog';
import accountActions from '../ducks/actions/account';
import * as fromDialogs from '../ducks/dialogs';
import * as fromRecoveryPassword from '../ducks/recovery-password';
import recoveryPasswordActions from '../ducks/actions/recovery-password';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsRegistrationOpened,
  defaultEmailValue: fromRecoveryPassword.getRegisteredEmail
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeAuthDialog,
  openAuthDialog: fromDialogs.actions.openAuthDialog,
  signUp: accountActions.signUp,
  resetRegisteredEmail: recoveryPasswordActions.resetRegisteredEmail
};

class RegistrationDialogContainer extends Component {
  state = {
    show: true
  };

  render() {
    const { isOpened, defaultEmailValue } = this.props;

    return (
      <RegistrationDialog
        show={isOpened}
        onClose={this.handleClose}
        onAuthOpen={this.handleAuthOpen}
        onSubmit={this.handleSubmit}
        defaultEmailValue={defaultEmailValue}
      />
    );
  }

  handleAuthOpen = () => {
    const { resetRegisteredEmail, openAuthDialog } = this.props;
    resetRegisteredEmail();
    openAuthDialog();
  };

  handleClose = () => {
    const { resetRegisteredEmail, close } = this.props;
    resetRegisteredEmail();
    close();
  };

  handleSubmit = data => {
    const { signUp, resetRegisteredEmail } = this.props;
    resetRegisteredEmail();
    signUp(data.email, data.password, data.firstName);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationDialogContainer);
