import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MainHeaderMobile from '../components/main-header-mobile/main-header-mobile';

import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromAccount from '../ducks/account';
import * as fromProfile from 'modules/profile/ducks/profile';

const mapStateToProps = createStructuredSelector({
  productsAmount: fromBasket.getProductsAmount,
  isBasketDropdownShowed: fromBasket.getDropdownShow,
  isAuthorized: fromAccount.getIsAuthorized,
  isProfileLoading: fromProfile.getIsLoading
});

const mapDispatchToProps = {
  openAuthDialog: fromDialogs.actions.openAuthDialog,
  openBasketDropdown: fromBasket.actions.openBasketDropdown,
  closeBasketDropdown: fromBasket.actions.closeBasketDropdown
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeaderMobile);
