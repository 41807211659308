import React, { Component, Fragment } from 'react';
import Media from 'react-media';

// import DottedList from 'modules/core/components/dotted-list/dotted-list.jsx';
import TrueDottedList from 'modules/core/components/dotted-list/true-dotted-list';
import DashList from '../dash-list/dash-list';

import './payment-info-short.css';

class PaymentInfoShort extends Component {
  render() {
    const { list, description } = this.props;
    return (
      <div className="PaymentInfoShort">
        <div className="PaymentInfoShort-paymentList">
          <Media query="(max-width: 560px)">
            {isMobile => (
              <Fragment>
                {isMobile ? <DashList list={list} /> : <TrueDottedList list={list} table />}
              </Fragment>
            )}
          </Media>
        </div>

        <div className="PaymentInfoShort-description">{description}</div>
      </div>
    );
  }
}

export default PaymentInfoShort;
