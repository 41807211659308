import React, { Component } from 'react';
import './marker-link.css';
import classNames from 'classnames';

class MarkerLink extends Component {
  render() {
    const iconPosition = this.props.imgPosition || 'right';
    const LinkElement = this.props.tag || 'button';

    return (
      <LinkElement
        href={this.props.href}
        className={classNames('MarkerLink', {
          'MarkerLink--under': this.props.underline,
          'MarkerLink--like': this.props.like
        })}
      >
        {iconPosition === 'left' && this.renderIcon()}
        <span className="MarkerLink-text">{this.props.title}</span>
        {iconPosition === 'right' && this.renderIcon()}
      </LinkElement>
    );
  }

  renderIcon() {
    const Icon = this.props.ico;

    return (
      <span className="MarkerLink-ico">
        <Icon />
      </span>
    );
  }
}

export default MarkerLink;
