import { takeEvery, put, call } from 'redux-saga/effects';

import { mixitApi } from 'services/mixit';
import { normalizeCityList, normalizeShopList } from 'services/mixit/adapters/shop-list';

import listToTable from 'modules/utils/list-to-table';

import SHOP_LIST from '../ducks/types/shop-list';
import ShopList from '../ducks/actions/shop-list';

export function* shopListWatcher() {
  yield takeEvery(SHOP_LIST.CITIES_REQUEST, handleCitiesRequest);
  yield takeEvery(SHOP_LIST.SHOPS_REQUEST, handleShopsRequest);
  yield takeEvery('@@REHYDRATE', handleCitiesRequest);
}

function* handleCitiesRequest() {
  try {
    const response = yield call(mixitApi().shopListRequest().cities);

    const cityList = response.data;

    const normalizedCityList = normalizeCityList(cityList);
    const cities = listToTable(normalizedCityList);

    yield put(ShopList.responseCities(cities));
  } catch (error) {
    console.error(error);
    yield put(ShopList.errorCities(error));
  }
}

function* handleShopsRequest({ payload }) {
  const { cityId } = payload;
  let queryParameters;

  if (!cityId) {
    queryParameters = { key: 'country_title', value: 'russia' };
  } else {
    queryParameters = { key: 'city_id', value: cityId };
  }

  try {
    const response = yield call(mixitApi().shopListRequest(queryParameters).shops);

    const shopList = response.data ? response.data.shops : [];

    const normalizedShopList = normalizeShopList(shopList);

    const _cityId = cityId || 'NO_CITY';

    yield put(ShopList.responseShopList(_cityId, normalizedShopList));
  } catch (e) {
    console.error(e);
  }
}
