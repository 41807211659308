import { takeEvery, call, put } from 'redux-saga/effects';
import * as LOCATION from '../ducks/types/location';
// import * as fromCityList from '../ducks/city-list';
import LocationActions from '../ducks/actions/location';
// import CityListActions from '../ducks/actions/city-list';

import fromSelectedCityLocalStorageActions from 'services/selected-city.js';
// import fromLSCityTree from 'services/city-tree';

export function* locationLocalStorage() {
  yield takeEvery(LOCATION.CITY_CHOOSE, updateProfileCity);
  // yield takeEvery(LOCATION.CITY_CHOOSE, storeCityList);
  //   yield takeEvery('@REHYDRATE', rehydrate);
  yield takeEvery('@@REHYDRATE', rehydrate);
}

// function* storeCityList({ payload }) {
//   const allCities = yield select(fromCityList.getAll);
//   yield call(() => fromLSCityTree.setCityTree(allCities));
// }

function* updateProfileCity(action) {
  const { id } = action.payload;

  yield call(() => fromSelectedCityLocalStorageActions.setCity(id));
}

function* rehydrate() {
  const cityId = yield call(fromSelectedCityLocalStorageActions.getCity);
  // const cityTreeString = yield call(fromLSCityTree.getCityTree);
  // const cityTree = JSON.parse(cityTreeString);

  yield put(LocationActions.cityRehydrate(cityId));
  // yield put(CityListActions.responseCityList(cityTree));
}
