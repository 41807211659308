import { schema } from 'normalizr';

export default function build() {
  return new schema.Entity(
    'filterOption',
    {},
    {
      idAttribute: 'slug'
    }
  );
}
