import React from 'react';
import LandingSectionTitle from '../landing-section-title/landing-section-title';

import './title-section.css';

export default function TitleSection({ data }) {
  return (
    <div className="TitleSection">
      <LandingSectionTitle data={data.title} top={data.title[0]} bottom={data.title[1]} />
    </div>
  );
}
