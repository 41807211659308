import React, { Component } from 'react';
import cn from 'classnames';

import Container from '../container/container';
import Content from '../content/content';

class Banner extends Component {
  render() {
    const { image, title, description } = this.props;
    const isTextExist = !!title || !!description;
    return (
      <div
        className={cn('Page-banner', { 'Page-banner--textExist': isTextExist })}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="Page-bannerContent">
          <Container>
            {title && <h1 className="Page-title Page-title--inBanner">{title}</h1>}
            {description && (
              <div className="Page-bannerDescription">
                <Content html={description} />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

export default Banner;
