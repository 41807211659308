import React, { Component } from 'react';

import './icon-education.css';

class IconEducation extends Component {
  render() {
    return (
      <svg className="IconEducation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <polyline points="30.43 17.14 30.43 23.27 18.17 30.17 5.91 23.27 5.91 17.14" />
        <polygon points="1.31 14.84 13.57 21.74 18.17 18.67 18.17 17.91 6.52 11.01 1.31 14.07 1.31 14.84" />
        <polygon points="35.03 14.84 22.77 21.74 18.17 18.67 18.17 17.91 30.04 11.4 35.03 14.07 35.03 14.84" />
        <polyline points="5.91 11.33 18.17 4.88 35.03 14.07" />
      </svg>
    );
  }
}

export default IconEducation;
