import React, { Component } from 'react';

import Container from 'modules/core/components/container/container';
import ConditionList from 'modules/core/components/condition-list/condition-list';

import './section-product-storage.css';

class SectionProductStorage extends Component {
  render() {
    const { conditions } = this.props;
    return (
      <section className="SectionProductStorage" id="productStorage" name="productStorage">
        <Container>
          <h2 className="SectionProductStorage-title">Условия хранения</h2>
          <div className="SectionProductStorage-content">
            <ConditionList conditions={conditions} />
          </div>
        </Container>
      </section>
    );
  }
}

export default SectionProductStorage;
