import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as SUBSCRIPTIONS from '../types/subscriptions';

function getChannelTopicList(channelSlug, topicList) {
  return topicList.filter(topic => topic.channelSlug === channelSlug);
}

/* REDUCERS */
const reducer = createReducer(
  on(SUBSCRIPTIONS.SHOW, state => {
    return {
      ...state,
      _isLoaded: false
    };
  }),

  on(SUBSCRIPTIONS.ADD, (state, { topics, channelList }) => {
    return {
      ...state,
      topics,
      channelList,
      _isLoaded: true
    };
  }),

  on(SUBSCRIPTIONS.SUBSCRIBE_CHANNEL, (state, { slug }) => {
    const topicList = Object.values(state.topics);

    let channelTopicList = getChannelTopicList(slug, topicList);

    let channelTopics = {};

    for (let i = 0; i < channelTopicList.length; i++) {
      const topic = channelTopicList[i];
      topic.isActive = true;

      channelTopics = { ...channelTopics, [topic.slug]: topic };
    }

    return {
      ...state,
      topics: {
        ...state.topics,
        ...channelTopics
      }
    };
  }),

  on(SUBSCRIPTIONS.UNSUBSCRIBE_CHANNEL, (state, { slug }) => {
    const topicList = Object.values(state.topics);

    let channelTopicList = getChannelTopicList(slug, topicList);

    let channelTopics = {};

    for (let i = 0; i < channelTopicList.length; i++) {
      const topic = channelTopicList[i];
      topic.isActive = false;

      channelTopics = { ...channelTopics, [topic.slug]: topic };
    }

    return {
      ...state,
      topics: {
        ...state.topics,
        ...channelTopics
      }
    };
  }),

  on(SUBSCRIPTIONS.SUBSCRIBE_TOPIC, (state, { slug }) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [slug]: {
          ...state.topics[slug],
          isActive: true
        }
      }
    };
  }),

  on(SUBSCRIPTIONS.SUBSCRIBE_TOPIC_ERROR, (state, { slug }) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [slug]: {
          ...state.topics[slug],
          isActive: false
        }
      }
    };
  }),

  on(SUBSCRIPTIONS.UNSUBSCRIBE_TOPIC, (state, { slug }) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [slug]: {
          ...state.topics[slug],
          isActive: false
        }
      }
    };
  }),

  on(SUBSCRIPTIONS.UNSUBSCRIBE_TOPIC_ERROR, (state, { slug }) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [slug]: {
          ...state.topics[slug],
          isActive: true
        }
      }
    };
  })
);

export default reducer({ channelList: [], topics: {} });

/* SELECTORS */
const getAll = state => state.profile.subscriptions;

export const getIsLoaded = createSelector(
  getAll,
  function _getChannelList(all) {
    return all._isLoaded;
  }
);

export const getTopicList = createSelector(
  getAll,
  function _getTopicList(all) {
    return Object.values(all.topics);
  }
);

export const getChannelListWithTopics = createSelector(
  getAll,
  getTopicList,
  function _getChannelList(all, topicList) {
    const channelListWithTopics = all.channelList.map(channel => {
      const filteredTopicList = topicList.filter(topic => topic.channelSlug === channel.slug);

      return { ...channel, topicList: filteredTopicList };
    });

    return channelListWithTopics;
  }
);
