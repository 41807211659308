import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProfile from '../ducks/profile';
import Profile from '../actions/profile';

import MobileProfileFormContainer from './mobile-profile-form';
import FormData from '../components/form-data/form-data';
import formatDate from './helpers/format-date';

const mapStateToProps = createStructuredSelector({
  fullName: fromProfile.getFullName,
  delivery: fromProfile.getDelivery,
  phone: fromProfile.getPhone,
  birthDate: fromProfile.getBirthDate
});

const mapDispatchToProps = {
  change: Profile.change
};

class MobileProfileEditor extends Component {
  state = {
    editing: false
  };

  render() {
    const { editing } = this.state;

    const {
      fullName,
      phone,
      birthDate,
      delivery,
      isPhoneEditable,
      onCallbackDialogOpen
    } = this.props;

    const addressString = delivery.address
      ? `${delivery.address.street}, 
      ${delivery.address.building}, 
      кв./офис ${delivery.address.apartment}`
      : '';

    return (
      <Fragment>
        {' '}
        {editing ? (
          <MobileProfileFormContainer
            profile={{
              fullName,
              phone,
              delivery,
              birthDate
            }}
            isPhoneEditable={isPhoneEditable}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            onCallbackDialogOpen={onCallbackDialogOpen}
          />
        ) : (
          <FormData onEdit={this.handleEdit}>
            <FormData.Field label="Телефон" value={phone} />{' '}
            <FormData.Field label="Адрес доставки" value={addressString} />{' '}
            <FormData.Field
              label="Дата рождения"
              value={formatDate(birthDate)}
              placeholder="не указана"
            />
          </FormData>
        )}{' '}
      </Fragment>
    );
  }

  setEditMode() {
    this.setState({
      editing: true
    });
  }

  setViewMode() {
    this.setState({
      editing: false
    });
  }

  handleEdit = () => {
    this.setEditMode();
  };

  handleSubmit = profile => {
    // const { phone } = this.state;
    // const _profile = { ...profile, phone };
    const _profile = {
      ...profile
    };
    const { change } = this.props;
    change(_profile);
    this.setViewMode();
  };

  handleCancel = () => {
    this.setViewMode();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileProfileEditor);
