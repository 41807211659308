import React, { Component } from 'react';
import './icon-bonus-card.css';

class IconBonusCard extends Component {
  render() {
    return (
      <svg className="IconBonusCard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19.44 6.43H4.58A.56.56 0 0 0 4 7v3.51a.73.73 0 0 0 0 .24V17a.56.56 0 0 0 .57.56h14.87A.56.56 0 0 0 20 17V7a.57.57 0 0 0-.56-.57zm-.57 10H5.14v-5.24h13.73zm0-6.37H5.14v-2.5h13.73z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    );
  }
}

export default IconBonusCard;
