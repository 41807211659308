import getOffset from 'dom-helpers/query/offset';

function getAnchorPoints(anchor, container) {
  const { top, height, left, width } = getOffset(anchor);
  const { width: containerWidth } = getOffset(container);

  return {
    top,
    left,
    bottom: top + height,
    right: containerWidth - (left + width),
    center: left + width / 2,
    middle: top + height / 2
  };
}

export default getAnchorPoints;
