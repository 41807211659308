import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-comments.css';

class IconComments extends Component {
  render() {
    const { size, inline } = this.props;
    const _size = !size || inline ? 'xsmall' : size;
    switch (_size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconComments">
            <path d="M4.3 14c-.1 0-.3 0-.4-.1-.2-.1-.4-.4-.4-.6v-2c-1.3-1-2-2.4-2-3.9C1.5 4.4 4.4 2 8 2s6.5 2.4 6.5 5.4-2.9 5.4-6.5 5.4h-.8L4.6 14h-.3zm.1-.7zM8 2.9c-3.1 0-5.6 2-5.6 4.4 0 1.2.7 2.4 1.8 3.3.1.1.2.2.2.4v2l2.5-1.2c.1 0 .2-.1.3 0 .3 0 .5.1.8.1 3.1 0 5.6-2 5.6-4.4 0-2.6-2.5-4.6-5.6-4.6z" />
          </Svg>
        );

      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 24 24" className="IconComments">
            <path d="M7.1 20c-.2 0-.3 0-.4-.1-.3-.2-.5-.5-.5-.8v-2.8a6.49 6.49 0 0 1-2.6-5.2C3.5 7.2 7.3 4 12 4s8.5 3.2 8.5 7.1c0 3.9-3.8 7.1-8.5 7.1-.3 0-.7 0-1-.1l-3.5 1.7c-.2.2-.3.2-.4.2zm0-.9zM7 19zm5-14c-4.1 0-7.5 2.8-7.5 6.1 0 1.7.9 3.4 2.4 4.5.1.1.2.2.2.4v2.9l3.5-1.7c.1 0 .2-.1.3 0 .4 0 .7.1 1.1.1 4.1 0 7.5-2.8 7.5-6.1C19.5 7.8 16.1 5 12 5z" />
          </Svg>
        );
      default:
    }
  }
}

export default IconComments;
