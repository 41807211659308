import React from 'react';

import BottomSheet from 'modules/core/components/bottom-sheet/bottom-sheet';
import ButtonClose from 'modules/core/components/button-close/button-close';
import isFunction from 'modules/utils/is-function';
import Radio from 'modules/form/components/radio/radio';

import './sorting-bottom-sheet.css';

function SortingBottomSheet(props) {
  const { sortTypeList, activeSortTypeIndex, onClose, onChange, ...bottomSheetProps } = props;

  return (
    <BottomSheet
      header={renderHeader()}
      // withBorder={true}
      // scrollable={true}
      onClose={handleClose}
      fullOppened
      {...bottomSheetProps}
    >
      <div className="SortingBottomSheet">
        <div className="SortingBottomSheet-sortingsListHolder">
          {renderSortTriggerList(sortTypeList)}
        </div>
      </div>
    </BottomSheet>
  );

  function renderSortTriggerList() {
    return (
      <form className="SortingBottomSheet-content">
        <ul className="SortingBottomSheet-list">
          {sortTypeList.map(({ id, name, title }, index) => (
            <li key={id} className="SortingBottomSheet-item">
              <Radio
                label={<span className="SortingBottomSheet-label">{title}</span>}
                name="sort_type"
                value={name}
                expanded
                checked={id === sortTypeList[activeSortTypeIndex].id}
                onChange={handleOptionChange(index)}
              />
            </li>
          ))}
        </ul>
      </form>
    );
  }
  function close() {
    if (!isFunction(onClose)) {
      return;
    }

    onClose();
  }

  function selectType(currentType) {
    if (!isFunction(onChange)) {
      return;
    }

    onChange(currentType);
  }

  function handleOptionChange(currentType) {
    return () => {
      selectType(currentType);
      setTimeout(() => close(), 120);
    };
  }

  function handleClose() {
    close();
  }

  function renderHeader() {
    return (
      <div className="SortingBottomSheet-header">
        <div className="SortingBottomSheet-headerTitle">Сортировка</div>
        <div className="SortingBottomSheet-headerClose">
          <ButtonClose onClick={onClose} />
        </div>
      </div>
    );
  }
}

export default SortingBottomSheet;
