export function reduceListByIdentifier(list, selectIdentifier, normalize) {
  return list.reduce(
    (all, item) => ({
      ...all,
      [selectIdentifier(item)]: normalize(item)
    }),
    {}
  );
}

export function normalizeIngredient(ingredient) {
  const media = ingredient.media;

  return {
    ...ingredient,
    media
  };
}

export function selectId({ id }) {
  return id;
}
