function initSignInRequest() {
  return function getSignInRequest(email, password) {
    return {
      method: 'POST',
      path: `/account/sign-in`,
      data: {
        email: email.trim(),
        password
      }
    };
  };
}

function initSignOutRequest() {
  return function getSignOutRequest() {
    throw new Error('Not implemented');

    // return {
    //   method: 'POST',
    //   path: `/account/sign-out`
    // };
  };
}

function initSignUpRequest() {
  return function getSignUpRequest(email, password, first_name) {
    return {
      method: 'POST',
      path: `/account/sign-up`,
      data: {
        email: email.trim(),
        password,
        first_name
      }
    };
  };
}

function initRefreshRequest() {
  return function getRefreshRequest() {
    return {
      method: 'POST',
      path: `/account/refresh`
    };
  };
}

function initPasswordRecoveryRequest() {
  return function getRefreshRequest(email) {
    return {
      method: 'POST',
      path: `/account/password-recovery`,
      data: {
        email: email.trim(),
      }
    };
  };
}

function initConfirmPasswordRecoveryRequest() {
  return function getRefreshRequest(confirmation_key, new_password) {
    return {
      method: 'POST',
      path: `/account/confirm-password-recovery`,
      data: {
        confirmation_key,
        new_password
      }
    };
  };
}

function initExistsRequest() {
  return function getExistsRequest(email) {
    return {
      method: 'GET',
      path: `/account/exists`,
      query: {
        email: email.trim(),
      }
    };
  };
}

function initPasswordChangeRequest() {
  return function changePasswordRequest({ current_password, new_password }) {
    return {
      method: 'POST',
      path: `/account/change-password`,
      data: {
        current_password,
        new_password
      }
    };
  };
}

export default function initAccountRequest(createRequest) {
  return function _getAccountRequest() {
    return {
      signIn: createRequest(initSignInRequest()),
      signOut: createRequest(initSignOutRequest()),
      signUp: createRequest(initSignUpRequest()),
      refresh: createRequest(initRefreshRequest()),
      exists: createRequest(initExistsRequest()),
      passwordRecovery: createRequest(initPasswordRecoveryRequest()),
      changePassword: createRequest(initPasswordChangeRequest()),
      confirmPasswordRecovery: createRequest(initConfirmPasswordRecoveryRequest())
      // confirmEmail,
      // confirmPhone,
      // confirmPasswordRecovery
    };
  };
}
