import { takeEvery, put, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import takeDebounced from 'modules/utils/saga/take-debounced';
import ifChanged from 'modules/utils/saga/if-changed';

import { mixitApi } from 'services/mixit';

import { createSubscriptionPersistor } from 'services/local-storage';

import { queryStringToJSON, getHasUTM, getQueryExist } from 'modules/utils/get-utm';

import * as SUBSCRIBE from '../ducks/types/subscribe';
import Subscribe from '../ducks/actions/subscribe';
import * as fromSubscribe from '../ducks/subscribe';

export function* subscribeWatcher() {
  yield takeEvery(SUBSCRIBE.REQUEST, handleRequest);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

export function* subscribePersistenceWatcher() {
  const subscriptionPersistor = createSubscriptionPersistor();

  const rehydrate = function*() {
    const haveSuggestionBeenShowed = yield call(subscriptionPersistor.read);

    if (!haveSuggestionBeenShowed) {
      return false;
    }

    yield put(Subscribe.rehydrate(haveSuggestionBeenShowed));
  };

  const persist = function*(subscribe) {
    yield call(subscriptionPersistor.write, subscribe);
  };

  const getState = function*() {
    return yield select(fromSubscribe.getAll);
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
  yield takeDebounced('*', ifChanged(getState, persist), 200);
}

function* handleRequest(action) {
  const { email } = action.payload;

  try {
    yield call(mixitApi().subscriptions, email);
    yield put(Subscribe.response(email));
  } catch (e) {}
}

function* handleLocationChange(action) {
    yield openSubscribeSuggestion(action)
}

function* openSubscribeSuggestion(action) {
  const { search } = action.payload.location;

  const isUTMCompaignExists = getHasUTM(search);

  yield checkProhibitions(search);

  if (!isUTMCompaignExists) {
    return;
  }

  const parameters = queryStringToJSON(search);
  const isSubscriptionSuggestionOpened = parameters.utm_term === 'email_subscription';

  if (isSubscriptionSuggestionOpened) {
    yield put(Subscribe.open());
  }
}

function* checkProhibitions(search) {
  const existUTMSourceYandexmarket = getQueryExist(search, "utm_source", "yandexmarket")
  const existUTMSourceMindbox = getQueryExist(search, "utm_source", "mindbox")
  const existForbiddances = existUTMSourceYandexmarket || existUTMSourceMindbox

  if(existForbiddances) {
    yield put(Subscribe.forbidToShow());
  } 
  if(!existForbiddances) {
    yield put(Subscribe.allowToShow());
  } 

}
