import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromAccount from '../ducks/account';
import { actions as Dialogs } from '../ducks/dialogs';

import RegisterDialogRoute from '../components/register-dialog-route/register-dialog-route';

const mapState = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

const mapActions = {
  openRegistrationDialog: Dialogs.openRegistrationDialog
};

class RegisterDialogRouteContainer extends Component {
  render() {
    const { openRegistrationDialog } = this.props;

    return <RegisterDialogRoute openRegistrationDialog={openRegistrationDialog} {...this.props} />;
  }
}

export default connect(
  mapState,
  mapActions
)(RegisterDialogRouteContainer);
