import React from 'react';
import cn from 'classnames';

// import Container from 'modules/core/components/container/container';
import Activity from './activity/activity';

import './activities.css';

export default function Activities({ data, colors }) {
  return (
    <div className={cn('Activities')}>
      <div className="Activities-holder">
        {data.list.map((activity, index) => (
          <div key={index} className="Activities-item">
            <Activity activity={activity} colors={colors} />
          </div>
        ))}
      </div>
    </div>
  );
}
