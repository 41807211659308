import React, { Component } from 'react';

class Slide extends Component {
  render() {
    const { child } = this.props;
    const styles = this.getStyles();

    return <div style={styles}>{child}</div>;
  }

  getStyles() {
    const { width, progress } = this.props;

    return {
      flexShrink: '0',
      flexGrow: '0',
      flexBasis: `${width}%`,
      width: `${width}%`,
      maxWidth: `${width}%`,
      boxSizing: 'border-box',
      opacity: `${progress}`,
      transform: `scale(${0.5 + progress * 0.5})`,
      transition: 'opacity .2s ease-out, transform .2s ease-out'
    };
  }
}

export default Slide;
