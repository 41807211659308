import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'modules/core/components/link/short-link';
import SizeSwitch from '../size-switch/size-switch';
import './size-choice.css';

const optionShape = PropTypes.shape({
  value: PropTypes.string,
  disabled: PropTypes.bool
}).isRequired;

class SizeChoice extends Component {
  static propTypes = {
    /**
     * Список опций:
     * `id` - идентификатор опции
     * `value` - значение цвета в любом поддерживаемом в CSS формате
     * `disabled` - доступна ли эта опция в переключателе
     */
    options: PropTypes.arrayOf(optionShape),
    /** Selected option */
    selected: optionShape
  };

  render() {
    const { selected, noSizeTable, ...rest } = this.props;
    return (
      <div className="SizeChoice">
        <div className="SizeChoice-titleHolder">
          {this.renderTitle()}
          {!noSizeTable && (
            <div className="SizeChoice-titleLink">
              <Link>Таблица размеров</Link>
            </div>
          )}
        </div>
        <div className="SizeChoice-switch">
          <SizeSwitch selected={selected.id} {...rest} onChange={this.handleChange} />
        </div>
      </div>
    );
  }

  renderTitle() {
    const { selected } = this.props;

    if (!selected) return null;

    return (
      <div className="SizeChoice-title">
        Размер: <span className="SizeChoice-value">{selected.title || selected.value}</span>
      </div>
    );
  }

  handleChange = optionId => {
    const { onChange, options } = this.props;

    const currentOption = options.filter(item => item.id === optionId)[0];

    onChange(currentOption);
  };
}

export default SizeChoice;
