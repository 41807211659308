import React, { Component } from 'react';
import cn from "classnames"
import MediaQuery from 'react-media';

import Media from 'modules/core/components/media/media';
import { Link } from 'react-router-dom';
import { getProductLink } from 'routes/links';
import Tooltip from 'modules/core/components/tooltip/tooltip';

import './basket-preview-panel.css';

class ProductButton extends Component {
  render() {
    const { media, product } = this.props;

    const UsedComponent = product.isGift ? "div": Link;
    const description = `${product.isGift ? "Подарок: " : ""}${product.description}`
    const paremeters = {
      ...(!product.isGift && {to: getProductLink(product)}),
      // title
    } 
    
    return (
      <MediaQuery query="(max-width:840px)">
        {isWideMobile => (
          <>
          {isWideMobile ? (
            <UsedComponent
              {...paremeters}
              className={cn("BasketPreviewPanel-productButton", 
              {
                "BasketPreviewPanel-productButton--gift": product.isGift
              }
              )}
            >
              <Media media={media} stretch="horizontal" resizeWidth={80} nocaption />
            </UsedComponent>
          ) : (
          <Tooltip
            content={
              <div className="BasketPreviewPanel-productTooltip">
                <div className="BasketPreviewPanel-productTooltipDescription">
                  {description}
                </div>
                <div className="BasketPreviewPanel-productTooltipTitle">
                  {product.title}
                </div>
                
              </div>}
            showDelay={100}
            hideDelay={100}
            alignment="center"
          >
            <UsedComponent
              {...paremeters}
              className={cn("BasketPreviewPanel-productButton", 
              {
                "BasketPreviewPanel-productButton--gift": product.isGift
              }
              )}
            >
              <Media media={media} stretch="horizontal" resizeWidth={80} nocaption />
            </UsedComponent>
          </Tooltip>
          )}
          </>

        )}
      </MediaQuery>
    )
  }
}

export default ProductButton;