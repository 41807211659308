import PropTypes from 'prop-types';
import mediaShape from 'modules/product/prop-types/media-shape';

const { number, string, shape } = PropTypes;

export default shape({
  id: number.isRequired,
  sex: string.isRequired, // TODO: use PropTypes.oneOf()
  media: shape(mediaShape)
});
