import React, { Component } from 'react';
import IconContainer from 'modules/core/components/icon-container/icon-container';

class BaseEditableButton extends Component {
  render() {
    const { icon, ...rest } = this.props;

    return (
      <button type="button" className="BaseEditable-button" {...rest}>
        <IconContainer inline>{icon}</IconContainer>
      </button>
    );
  }
}

export default BaseEditableButton;
