import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import Button from 'modules/core/components/button/button';

import copyStringToClipboard from './helpers/copy-string-to-clipboard';

import basketActions from 'modules/ordering/ducks/basket/actions';
import * as fromBasket from 'modules/ordering/ducks/basket/selectors';

import { createStructuredSelector } from 'reselect';

import './promo-code-with-button.css';

const { string, bool, oneOf } = PropTypes;

const mapStateToProps = createStructuredSelector({
  currentPromocode: fromBasket.getLastCoupon
});

const mapDispatchToProps = {
  applyPromo: basketActions.applyPromo
};

const SIZES = {
  large: { buttonSize: 'normal', promocodeSize: 'large' },
  normal: { buttonSize: 'normal', promocodeSize: 'normal' },
  small: { buttonSize: 'small', promocodeSize: 'small' }
};

class PromoCodeWithButton extends Component {
  state = {
    isBrowserSupportedCoping: true,
    isPromoCodeCopied: false,
    noColumn: false
  };

  static propTypes = {
    code: string,
    size: string,
    inverted: bool,
    themeColor: string,
    withOverlay: bool,
    variant: oneOf(['copy', 'apply'])
  };

  static defaultProps = {
    size: SIZES['large'],
    variant: 'copy'
  };

  codeText = null;
  copiedStatusTimer = null;

  render() {
    const { code, size, inverted, themeColor, withOverlay, variant, currentPromocode } = this.props;

    const stringWidth = this.getStringWidth(code.toUpperCase());

    const { isBrowserSupportedCoping, isPromoCodeCopied } = this.state;

    let promoStyle = { color: themeColor };

    const isPromoCodeApplyed = currentPromocode && code === currentPromocode.code;

    if (!withOverlay) {
      delete promoStyle.color;
    }

    return (
      <div
        className={cn('PromoCodeWithButton', {
          [`PromoCodeWithButton--size-${SIZES[size].promocodeSize}`]: SIZES[size].promocodeSize,
          'PromoCodeWithButton--inverted': inverted,
          'PromoCodeWithButton--onOverlay': withOverlay,
          'PromoCodeWithButton--notSupporterCopy': !isBrowserSupportedCoping,
          'PromoCodeWithButton--promoCodeCopied': isPromoCodeCopied
        })}
      >
        <label className="PromoCodeWithButton-label" style={promoStyle}>
          <input
            className="PromoCodeWithButton-input"
            style={{ width: stringWidth }}
            ref={this.setCodeTextRef}
            type="text"
            value={code}
            readOnly
          />
        </label>

        <div className="PromoCodeWithButton-copyButton">
          {variant === 'apply' ? (
            <div className="PromoCodeWithButton-copyButton">
              <Button
                title={isPromoCodeApplyed ? 'Применен' : 'Применить'}
                variant="primary"
                size={SIZES[size].buttonSize}
                onClick={this.handleApplyClick}
                expanded
                disabled={isPromoCodeApplyed}
              />
            </div>
          ) : (
            <div className="PromoCodeWithButton-copyButton">
              <Button
                title={isPromoCodeCopied ? 'Скопировано!' : 'Копировать'}
                variant="primary"
                size={SIZES[size].buttonSize}
                onClick={this.handleCopyClick}
                expanded
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.dummy.parentNode.removeChild(this.dummy);
  }

  getStringWidth(value) {
    if (!__BROWSER__) {
      return null;
    }

    let styles = {
      display: 'inline-block',
      position: 'absolute',
      top: '-1000px',
      left: '-1000px',
      zIndex: '-1',
      visibility: 'hidden',
      whiteSpace: 'nowrap',
      width: 'auto',
      fontFamily: 'inherit'
    };

    const textSizeValuableProperties = [
      'border-left-style',
      'border-left-width',
      'border-right-style',
      'border-right-width',
      'box-sizing',
      'font-family',
      'font-size',
      'font-stretch',
      'font-style',
      'font-variant',
      'font-weight',
      'letter-spacing',
      'padding-left',
      'padding-right'
    ];

    if (!this.dummy) {
      this.dummy = document.createElement('span');
      this.dummy.setAttribute('aria-hidden', true);
      Object.entries(styles).map(([key, value]) => (this.dummy.style[key] = value));
      document.body.appendChild(this.dummy);
    }

    this.dummy.innerHTML = value;

    if (this.codeText) {
      const inputComputedStyle = window.getComputedStyle(this.codeText);

      Array.from(textSizeValuableProperties).forEach(property => {
        this.dummy.style.setProperty(
          property,
          inputComputedStyle.getPropertyValue(property),
          inputComputedStyle.getPropertyPriority(property)
        );
      });
    }

    const { width } = this.dummy.getBoundingClientRect();
    return width;
  }

  setNotSupportingClick() {
    this.setState({
      isBrowserSupportedCoping: false
    });
  }

  setCopiedState() {
    this.setState({
      isPromoCodeCopied: true
    });
  }

  resetCopiedState = () => {
    this.setState({
      isPromoCodeCopied: false
    });
  };

  componentDidMount() {
    if (!document.execCommand) {
      this.setNotSupportingClick();
    }
  }

  copyToClipboard() {
    const { isBrowserSupportedCoping } = this.state;
    const { code } = this.props;
    // this.codeText.select();
    let copyStatus = true;
    if (isBrowserSupportedCoping) {
      copyStatus = copyStringToClipboard(code.toUpperCase());
      this.setCopiedState();
    }
    if (!copyStatus) {
      this.setNotSupportingClick();
    }
  }

  setCodeTextRef = element => {
    if (!element) {
      return;
    }
    this.codeText = element;
    this.forceUpdate();
  };

  handleCopyClick = () => {
    const { isPromoCodeCopied } = this.state;

    if (isPromoCodeCopied) {
      return;
    }

    this.copyToClipboard();

    this.copiedStatusTimer = setTimeout(this.resetCopiedState, 2500);
  };

  handleApplyClick = () => {
    const { code, applyPromo, applyPromocode } = this.props;

    applyPromo(code);

    applyPromocode();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeWithButton);
