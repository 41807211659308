import React, { Component } from 'react';
import './section-product-card.css';
import Container from 'modules/core/components/container/container';

class SectionProductCard extends Component {
  render() {
    return (
      <div className="SectionProductCard">
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}

export default SectionProductCard;
