import React, { Component } from 'react';
import AnimatedLink from './animated-link';
import { Link } from 'react-router-dom';

class AnimatedRouterLink extends Component {
  render() {
    const { href, ...props } = this.props;

    return <AnimatedLink component={Link} to={href} {...props} />;
  }
}

export default AnimatedRouterLink;
