import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CurrentCity from '../components/current-city/current-city';

import locationActions from '../ducks/actions/location';
import * as fromLocation from '../ducks/location';
import * as fromDialogs from 'modules/core/ducks/dialogs';

// import Timer from 'modules/core/components/toast/helpers/timer';

const mapStateToProps = createStructuredSelector({
  current: fromLocation.getCurrentCity,
  suggested: fromLocation.getSuggestedCityId,
  hadChosen: fromLocation.getHadChosen,
  selected: fromLocation.getLocalCityId
});

const mapDispatchToProps = {
  chooseCity: locationActions.chooseCity,
  openCityDialog: fromDialogs.actions.openCityDialog
};

class CurrentCityContainer extends Component {
  state = {
    isDropdownShowed: false
  };

  render() {
    const { isDropdownShowed } = this.state;

    return (
      <CurrentCity
        isShowed={isDropdownShowed}
        onDropdownChange={this.handleDropdownChange}
        onCityChooseClick={this.handleChooseCityClick}
        onSuggestionConfirm={this.handleSuggestionConfirm}
        onClick={this.handleClick}
        {...this.props}
      />
    );
  }

  // componentDidMount() {
  //   const { hadChosen } = this.props;

  //   if (!hadChosen) {
  //     this.timer = new Timer(5000, this.openDropdown);
  //     this.openOnTimer();
  //   }
  // }

  componentDidMount() {
    const { selected } = this.props;
    if (!selected) {
      this.openDropdown();
    }
  }

  openOnTimer() {
    this.timer.start();
  }

  openDropdown = () => {
    this.setState({ isDropdownShowed: true });

    if (this.timer) {
      this.timer.stop();
    }
  };

  closeDropdown = () => {
    this.setState({ isDropdownShowed: false });
  };

  handleClick = () => {
    const { hadChosen, suggested } = this.props;
    if (!hadChosen && suggested) {
      return;
    }
    this.props.openCityDialog();
  };

  handleDropdownChange = isDropdownShow => {
    const { hadChosen } = this.props;

    if (isDropdownShow && !hadChosen) {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  };

  handleSuggestionConfirm = id => {
    const { chooseCity } = this.props;

    chooseCity(id);
  };

  handleCityChoose = id => {
    const { chooseCity } = this.props;

    chooseCity(id);
  };

  handleChooseCityClick = () => {
    const { openCityDialog } = this.props;

    openCityDialog();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCityContainer);
