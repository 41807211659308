import React, { Component } from 'react';
import { getIndexLink } from 'routes/links';
import Helmet from 'react-helmet';

import { Page, PageContent } from '../components/page/index';
import DeclarationsSectionsContainer from '../containers/declarations-section';
import Container from '../components/container/container';
import SectionDefault from '../components/section-default/section-default';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

class DeclarationsPage extends Component {
  render() {
    return (
      <Page
        title="Декларации"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Декларации'
          }
        ]}
      >
        <PageContent>
          <Helmet>
            <title>Награды</title>
            <meta name="description" content="Декларации" />
          </Helmet>
          <DeclarationsSectionsContainer />
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </PageContent>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default DeclarationsPage;
