import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
// import { getCategoryLink, getSpecialOffersLink } from 'routes/links';
import computeScrollIntoView from 'compute-scroll-into-view';
import { IconSpecialOfferCoupon } from '../icons';

import './mobile-catalog-navigation.css';

// const categories = [
//   {
//     id: 1,
//     title: 'Лицо',
//     slug: 'face'
//   },
//   {
//     id: 2,
//     title: 'Тело',
//     slug: 'body'
//   },
//   {
//     id: 3,
//     title: 'Волосы',
//     slug: 'hair'
//   },
//   {
//     id: 7,
//     title: 'Здоровье',
//     slug: 'health',
//     style: {
//       color: '#25bc8b'
//     }
//   },
//   {
//     id: 4,
//     title: 'Декоративная косметика',
//     slug: 'decorate',
//     href: 'https://art.mixit.ru'
//   },
//   {
//     id: 5,
//     title: 'Подарочные наборы',
//     slug: 'gift-sets'
//   },
//   {
//     id: 6,
//     title: 'Эффективные комплексы',
//     slug: 'effective'
//   }
// ];

// const specialOffersItem = {
//   id: 8,
//   title: 'Акции',
//   slug: 'special-offers',
//   style: {
//     color: '#e95958'
//   }
// };

class MobileCatalogNavigation extends Component {
  _navElement = null;
  _navItemElements = {};

  // state = {
  //   wasScrolled: false
  // };

  render() {
    const { currentCategory, navigation } = this.props;

    return (
      <nav className="CatalogNavigation" ref={this.setNavRef}>
        <ul className="CatalogNavigation-list">
          {navigation.list.map(item => {
            const LinkComponent = item.href ? 'a' : Link;

            return (
              <li
                key={item.id}
                className="CatalogNavigation-listItem"
                ref={this.setItemRef(item.slug)}
              >
                <LinkComponent
                  {...item.linkProps}
                  className={cn('CatalogNavigation-link', {
                    'CatalogNavigation-link--active': item.slug === currentCategory
                  })}
                >
                  {item.title === 'Акции' ? (
                    <span className="CatalogNavigation-listItemValue CatalogNavigation-listItemValue--widthIcon">
                      <div
                        style={{
                          height: '18px',
                          verticalAlign: 'top',
                          paddingTop: '2px',
                          marginRight: '2px'
                        }}
                      >
                        <IconSpecialOfferCoupon />
                      </div>

                      {item.title}
                    </span>
                  ) : (
                    <span className="CatalogNavigation-listItemValue">{item.title}</span>
                  )}
                </LinkComponent>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }

  componentDidMount() {
    this.scroll();
  }

  componentDidUpdate() {
    this.scroll();
  }

  scroll() {
    const { currentCategory } = this.props;
    // const { wasScrolled } = this.state;

    // if (wasScrolled) {
    //   this.scrollToActive2(currentCategory);
    //   return;
    // }

    if (
      // !wasScrolled &&
      currentCategory &&
      this._navItemElements[currentCategory]
    ) {
      this.scrollToActive(currentCategory);
      // this.setWasScrolledState();
    }
  }

  // setWasScrolledState() {
  //   this.setState({
  //     wasScrolled: true
  //   });
  // }

  setItemRef = slug => element => {
    if (!element) {
      return;
    }

    this._navItemElements[slug] = element;
  };

  setNavRef = element => {
    if (!element) {
      return;
    }

    this._navElement = element;
  };

  scrollToActive2(currentCategory) {
    this._navItemElements[currentCategory].scrollIntoView({
      behavior: 'smooth',
      inline: 'center'
    });
  }

  scrollToActive(currentCategory) {
    const actions = computeScrollIntoView(this._navItemElements[currentCategory], {
      boundary: this._navElement,
      inline: 'center',
      scrollMode: 'if-needed'
    });
    actions.forEach(({ el, top, left }) => {
      el.scrollTop = top;
      el.scrollLeft = left;
    });
  }
}

export default MobileCatalogNavigation;
