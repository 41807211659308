import { takeEvery, take, put, select, call, fork, race } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import * as fromOrders from '../ducks/orders';
import { actions as PersonalInfoForm } from '../ducks/personal-info-form';
import { actions as DeliveryForm } from '../ducks/delivery-form';
import { actions as BonusesConfirmationActions } from '../ducks/bonuses-conformation';
import * as ORDER from '../ducks/types/orders';
import * as BONUSES_CONFIRMATION from '../ducks/types/bonuses-conformation';
import * as BASKET from '../ducks/basket/types';
import { push, replace, LOCATION_CHANGE } from 'connected-react-router';
import {
  getOrderLink,
  getOrderingSuccessLink,
  getBasketLink,
  getRedirectPaymentPage
} from 'routes/links';
import { mixitApi } from 'services/mixit';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromBasket from '../ducks/basket/selectors';
import * as Order from '../domain/order';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import * as fromProfile from 'modules/profile/ducks/profile';
import {
  getLastOrderAuthorization,
  getTotalCost,
  getLastOrder
} from '../ducks/last-order/selectors';

import nanoid from 'nanoid';

import Dadata from 'services/dadata';

import {
  getCity,
  getCityFiasId,
  getDeliveryMethodId,
  getAddress,
  actions as deliveryFormAction
} from '../ducks/delivery-form';

const dadata = new Dadata(process.env.REACT_APP_DADATA_API_KEY);

export function* checkoutWatcher() {
  yield takeEvery(ORDER.PLACE, place);
  yield takeEvery(ORDER.SHOW, show);
  yield takeEvery(ORDER.REQUEST, request);
  yield takeEvery(ORDER.SUBMIT, submit);
  yield takeEvery(ORDER.SUCCESS, onOrderSuccess);
  yield takeEvery(ORDER.ERROR, onOrderFail);
  yield takeEvery(ORDER.SERVER_ERROR, onOrderLoadFail);
  yield takeEvery(ORDER.LOAD_FROM_SERVER, updateOrder);
  yield takeEvery(ORDER.CREATE_ON_SERVER, createOrder);
  yield takeEvery(ORDER.PERSONAL_INFO_SUBMIT, submitPersonalInfo);
  yield takeEvery(ORDER.DELIVERY_INFO_SUBMIT, submitDeliveryInfo);
  yield takeEvery(ORDER.PAYMENT_REQUEST, paymentRequest);
}

export function* confirmationBonusesWatcher() {
  yield takeEvery(BONUSES_CONFIRMATION.REQUEST_CODE, bonusesConfirmCode);
  yield takeEvery(BONUSES_CONFIRMATION.REQUEST_CONFIRM, bonusesConfirm);
}

function* bonusesConfirm({ payload }) {
  const { code } = payload;

  const isBasketPage = window.location.pathname === getBasketLink();

  const { error, status } = yield bonusesConfirmRequest({
    code
  });

  if (error) {
    yield put(BonusesConfirmationActions.errorConfirm(error));
    return;
  }

  yield put(BonusesConfirmationActions.responseConfirm(status));
  if (isBasketPage) {
    yield place();
  }
}

function* bonusesConfirmRequest({ code }) {
  const authorization = yield select(fromAccount.getAuthorization);
  const data = {
    confirmation_code: code
  };

  try {
    const response = yield call(
      mixitApi(authorization)
        .orders()
        .bonuses({
          code: data
        }).confirm
    );

    return {
      status: response.status
    };
  } catch (error) {
    return {
      error
    };
  }
}

function* bonusesConfirmCode({ payload }) {
  const { value } = payload;

  const { error, phone } = yield confirmationCodeRequest({
    value
  });

  if (error) {
    yield put(BonusesConfirmationActions.errorCode(error));
    return;
  }

  yield put(
    BonusesConfirmationActions.responseCode({
      phone
    })
  );
}

function* confirmationCodeRequest({ value }) {
  const authorization = yield select(fromAccount.getAuthorization);
  const data = {
    bonuses: {
      value
    }
  };

  try {
    const response = yield call(
      mixitApi(authorization)
        .orders()
        .bonuses({
          bonuses: data
        }).code
    );
    const phone = response.data.phone;
    return {
      phone
    };
  } catch (error) {
    return {
      error
    };
  }
}

export function* paymentWatcher() {}

function* watchAuthentication(id) {
  const [authAction, submitAction, locationChange] = yield race([
    take(ACCOUNT.AUTHORIZATION_SUCCESS),
    call(function*() {
      while (1) {
        const action = yield take(ORDER.SUBMIT);
        if (action.payload.id === id) {
          return true;
        }
      }
    }),
    take(LOCATION_CHANGE)
  ]);

  if (locationChange || submitAction) {
    console.log('disable auth watcher');
    return;
  }

  const { account, entities: { profile } = {} } = authAction.payload;

  yield put(
    fromOrders.actions.fillPersonalInfo(id, account.email, profile.phone, profile.firstName)
  );

  const localOrder = yield select(fromOrders.getFullById, {
    id
  });

  yield take(BASKET.SYNC_RESPONSE);
  yield put(fromOrders.actions.createOnServer(localOrder));

  const action = yield take(ORDER.UPDATE_FROM_SERVER);
  const { order } = action.payload;

  yield put(push(getOrderLink(order)));
}

function* paymentRequest(action) {
  const { id } = action.payload;
  yield put(replace(getRedirectPaymentPage()));
  const authorization = yield select(fromAccount.getAuthorization);

  const orderAuthorization = yield select(fromOrders.getAuthorizationById, {
    id
  });

  const lastOrderAuthorization = yield select(getLastOrderAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization || orderAuthorization || lastOrderAuthorization)
        .orders()
        .item(id).payment
    );
    const payment = Order.normalizePayment(response.data);

    const totalCost = yield select(getTotalCost);

    const lastOrder = yield select(getLastOrder);

    if (totalCost === 0) {
      yield put(replace(getOrderingSuccessLink(lastOrder)));
      return;
    }

    yield __BROWSER__ && setTimeout(window.location.replace(payment.invoiceLink), 2000);

    window.mixit_payment = window.open(payment.invoiceLink, 'mixit_payment');
    yield put(fromOrders.actions.paymentResponse(id, payment));
  } catch (error) {
    yield put(replace('/payment-wrong'));
    console.error(error);
    yield put(fromOrders.actions.paymentResponse(id));
  }
}

function* place() {
  yield put(PersonalInfoForm.reset());
  yield put(DeliveryForm.reset());
  const orderId = yield select(fromBasket.getConnectedOrder);

  if (orderId) {
    yield put(
      push(
        getOrderLink({
          id: orderId
        })
      )
    );
    return;
  }

  const authorization = yield select(fromAccount.getAuthorization);

  if (!authorization) {
    const id = nanoid();
    yield put(fromOrders.actions.createLocal(id));
    yield put(
      push(
        getOrderLink({
          id
        })
      )
    );

    yield fork(watchAuthentication, id);

    return;
  }

  const firstName = yield select(fromProfile.getFirstName);
  const phone = yield select(fromProfile.getPhone);
  const email = yield select(fromAccount.getEmail);

  yield put(
    fromOrders.actions.createOnServer({
      personalInfo: {
        firstName,
        phone,
        email
      }
    })
  );

  const action = yield take(ORDER.UPDATE_FROM_SERVER);
  const { order } = action.payload;

  yield put(push(getOrderLink(order)));
}

function* show(action) {
  const { id, isOrderPage } = action.payload;
  const order = yield select(fromOrders.getItemById, {
    id
  });

  if (!order || order.isError) {
    const authorization = yield select(fromAccount.getAuthorization);

    if (!authorization) {
      if (isOrderPage) {
        yield put(replace(getBasketLink()));
      }
      return;
    }
    yield put(fromOrders.actions.loadFromServer(id, isOrderPage));
  }
}

function* request(action) {
  const { id } = action.payload;
  const order = yield select(fromOrders.getItemById, {
    id
  });

  if (!order) {
    yield put(fromOrders.actions.loadFromServer(id));
  }
}

function* createOrder(action) {
  const { order: localOrder } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);
  const { error, order } = yield createOrderRequest(authorization, Order.denormalize(localOrder));

  if (error) {
    yield put(fromOrders.actions.serverError, error);
    return;
  }

  yield put(fromOrders.actions.updateFromServer(Order.normalize(order)));
}

function* updateOrder(action) {
  const { id, redirect } = action.payload;

  const authorization = yield select(fromAccount.getAuthorization);

  const { error, order } = yield getOrderRequest(authorization, id);

  if (error) {
    yield put(fromOrders.actions.serverError(id, error, redirect));
    return;
  }

  yield put(
    deliveryFormAction.changeCityFiasId(order.delivery.geography.city.fias_id, {
      title: order.delivery.geography.city.title
    })
  );

  yield put(fromOrders.actions.updateFromServer(Order.normalize(order)));
}

function* submit(action) {
  const { id, order: rawOrder } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);
  const queryParams = { request_source: 'site' };

  if (!authorization) {
    const localOrder = yield select(fromOrders.getFullById, {
      id
    });

    const localOrderWithDadata = yield addDadatatoOrderForUnauthorization(localOrder);

    const goods = yield select(fromBasket.getGoods);
    const promos = yield select(fromBasket.getPromos);
    const apiOrder = Order.denormalize({
      ...localOrderWithDadata,
      basket: {
        goods,
        promos
      },
      status: 'ORDER_STATUS/NEW'
    });

    const { order, authorization, error } = yield createOrderRequest(null, apiOrder, queryParams);

    if (error) {
      yield put(fromOrders.actions.fail(id, order, error));
      return;
    }
    const normalizedOrder = Order.normalize(order);

    yield put(fromOrders.actions.success(normalizedOrder.id, normalizedOrder, authorization));

    // console.log("function*submit -> order", order)
    // console.log("function*submit -> Order.normalize(order)", Order.normalize(order))
    // yield delay(500000);

    if (normalizedOrder.payment.paymentMethod.id === 11) {
      yield paymentRequest({ payload: { id: order.id } });
    }

    return;
  }

  const localOrder = yield select(fromOrders.getFullById, {
    id
  });
  const apiOrder = Order.denormalize({
    ...rawOrder,
    personalInfo: localOrder.personalInfo,
    status: 'ORDER_STATUS/NEW'
  });

  const apiOrderWithDadata = yield addDadatatoOrderForAuthorization(apiOrder);

  const { order, error } = yield updateOrderRequest(
    authorization,
    id,
    apiOrderWithDadata,
    queryParams
  );

  if (error) {
    yield put(fromOrders.actions.fail(id, order, error));
    return;
  }

  yield put(fromOrders.actions.success(id, Order.normalize(order)));

  if (order.payment.id === 11) {
    yield paymentRequest({ payload: { id: order.id } });
  }
}

function* onOrderSuccess(action) {
  const { order } = action.payload;
  if (order.payment.paymentMethod.id !== 11) {
    yield put(replace(getOrderingSuccessLink(order)));
  }
}

function onOrderFail(action) {
  const { id, order, message } = action.payload;
  console.error('Ordering failed:', message, id, order);
}

function* onOrderLoadFail(action) {
  const { redirect } = action.payload;
  if (!redirect) {
    return;
  }
  yield put(replace(getBasketLink()));
}

function* submitPersonalInfo(action) {
  const { id, personalInfo } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);

  if (!authorization) {
    yield put(fromOrders.actions.updatePersonalInfo(id, personalInfo));
    return;
  }

  const { order, error } = yield updateOrderRequest(
    authorization,
    id,
    Order.denormalize({
      personalInfo
    })
  );

  if (error) {
    yield put(fromOrders.actions.personalInfoError(error));
  }

  yield put(fromOrders.actions.updatePersonalInfo(id, Order.normalize(order).personalInfo));
}

function* submitDeliveryInfo(action) {
  const { id } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);

  if (!authorization) {
    return;
  }

  const delivery = yield select(fromOrders.getDeliveryById, {
    id
  });

  const { order, error } = yield updateOrderRequest(
    authorization,
    id,
    Order.denormalize({
      delivery
    })
  );

  if (error) {
    yield put(fromOrders.actions.deliveryInfoError(error));
  }

  yield put(fromOrders.actions.updateDeliveryInfo(id, Order.normalize(order).delivery));
}

/** Requests */
function* getOrderRequest(authorization, id) {
  try {
    const response = yield call(
      mixitApi(authorization)
        .orders()
        .item(id).item
    );

    const order = response.data;

    return {
      order
    };
  } catch (error) {
    return {
      error
    };
  }
}

function* createOrderRequest(authorization, apiOrder = {}, queryParams = null) {
  try {
    const response = yield call(
      mixitApi(authorization)
        .orders()
        .item().add,
      apiOrder,
      queryParams
    );

    const order = response.data;

    return {
      order,
      authorization: response.authorization
    };
  } catch (error) {
    return {
      error
    };
  }
}

function* updateOrderRequest(authorization, id, orderRequest, queryParams = null) {
  try {
    const response = yield call(
      mixitApi(authorization)
        .orders()
        .item(id).change,
      orderRequest,
      queryParams
    );

    const order = response.data;

    return {
      order
    };
  } catch (error) {
    return {
      error
    };
  }
}

function* addDadatatoOrderForUnauthorization(order) {
  const deliveryMethodId = yield select(getDeliveryMethodId);
  const isDeliveryNeedStreet = deliveryMethodId === 4 || deliveryMethodId === 11;
  if (isDeliveryNeedStreet) {
    const data = yield getStreetDadataObject();

    const currentOrder = { ...order };

    currentOrder.delivery.data = data;

    return currentOrder;
  }

  const query = order.delivery.geography.city.title;

  const dadata = yield getCityOPtionsFromDadata(query);

  const currentOrder = { ...order };

  currentOrder.delivery.data = dadata.suggestions[0];

  return currentOrder;
}

function* addDadatatoOrderForAuthorization(order) {
  const deliveryMethodId = yield select(getDeliveryMethodId);
  const isDeliveryNeedStreet = deliveryMethodId === 4 || deliveryMethodId === 11;
  if (isDeliveryNeedStreet) {
    const data = yield getStreetDadataObject();

    const currentOrder = { ...order };

    currentOrder.delivery = { data: data };

    return currentOrder;
  }

  const city = yield select(getCity);

  const dadata = yield getCityOPtionsFromDadata(city.title);

  const currentOrder = { ...order };

  currentOrder.delivery = { data: dadata.suggestions[0] };

  return currentOrder;
}

function* getStreetDadataObject() {
  const cityfiasId = yield select(getCityFiasId);

  const address = yield select(getAddress);

  if (address.apartment) {
    const city = yield select(getCity);
    const query = `г ${city.title}, ${address.street.raw}, кв ${address.apartment}`;
    const data = yield dadata.adress(query);
    return data.suggestions[0];
  }

  const query = `${address.street.raw}`;
  const data = yield dadata.street(cityfiasId, query);
  return data.suggestions[0];
}

function* getCityOPtionsFromDadata(query) {
  const data = yield dadata.city(query, {
    withSettlements: true,
    locations: [
      {
        country: 'Россия'
      },
      {
        country: 'Беларусь'
      },
      {
        country: 'Казахстан'
      }
    ]
  });
  return data;
}
