import { SOCIAL } from '../types';
import { createAction } from 'modules/utils/dux';

export function callVkAuth() {
  return createAction(SOCIAL.VK_AUTH_CALL);
}

export function receiveVkAuth(authData) {
  return createAction(SOCIAL.VK_RECEIVE_AUTH, { ...authData });
}

export function callFacebookAuth() {
  return createAction(SOCIAL.FACEBOOK_AUTH_CALL);
}

export function logoutFacebookAuth() {
  return createAction(SOCIAL.FACEBOOK_AUTH_LOGOUT);
}

export function receiveFacebookAuth(authData) {
  return createAction(SOCIAL.FACEBOOK_RECEIVE_AUTH, { ...authData });
}
