import React, { Component } from 'react';
import cn from 'classnames';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import { mapSelectSizeToIcon } from './helpers';

import './dropdown-option.css';

class Option extends Component {
  render() {
    const { children, active, size, icon, ...rest } = this.props;

    return (
      <button
        type="button"
        className={cn('DropdownOption', { 'DropdownOption--active': active })}
        {...rest}
      >
        {icon && (
          <div className="DropdownOption-icon">
            <IconContainer size={mapSelectSizeToIcon(size)}>{icon}</IconContainer>
          </div>
        )}
        <div className="DropdownOption-title">{children}</div>
      </button>
    );
  }
}

export default Option;
