import { call } from 'redux-saga/effects';

export default function ifChanged(getValue, handleChange) {
  let previousValue = null;
  return function*() {
    const value = yield call(getValue);
    if (value === previousValue) {
      return;
    }
    previousValue = value;
    yield call(handleChange, value);
  };
}
