import React, { Component } from 'react';
import Media from 'react-media';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'modules/form/components/form';
import Input from 'modules/form/components/input/input';
import Field from 'modules/form/components/field/field';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import Button from '../button/button';

import './subscribe.css';

const EMAIL_REQUIRED_ERROR = 'Введите свой email';
const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';

const credentialsSchema = Yup.object().shape({
  email: Yup.string()
    .required(EMAIL_REQUIRED_ERROR)
    .email(EMAIL_FORMAT_ERROR)
});

class Subscribe extends Component {
  render() {
    const { accountEmail, isLoading, onSubmit } = this.props;

    const initialValues = {
      email: accountEmail || '',
      agreement: false
    };

    return (
      <div className="Subscribe">
        <div className="Subscribe-hint">Получайте акции и специальные предложения</div>

        <div className="Subscribe-form">
          <Formik
            validationSchema={credentialsSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Media query="(min-width: 641px)">
                  {isDesktop => (
                    <div className="Subscribe-formContent">
                      <div className="Subscribe-field">
                        <Field error={touched.email && errors.email}>
                          <Input
                            type="email"
                            placeholder="Введите e-mail"
                            name="email"
                            size={isDesktop ? 'normal' : 'small'}
                            state={touched.email && errors.email && 'error'}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isLoading}
                          />
                        </Field>
                      </div>

                      <div className="Subscribe-button">
                        <Button
                          title="Подписаться"
                          variant="primary"
                          type="submit"
                          size={isDesktop ? 'normal' : 'small'}
                          disabled={!values.agreement || isLoading}
                          expanded={!isDesktop}
                        />
                      </div>

                      <div className="Subscribe-confirmation">
                        <div className="Subscribe-confirmationCheckbox">
                          <Checkbox
                            name="agreement"
                            value="agreement"
                            // label="Я согласен(-на) на обработку персональных данных и с условиями подписки"
                            checked={values.agreement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="Subscribe-confirmationUnclickedLabel">
                          Я согласен(-на) на обработку{' '}
                          <a
                            className="Subscribe-confirmationLink"
                            href="https://mixit.ru/upload/personal_data.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            персональных данных
                          </a>{' '}
                          и с{' '}
                          <a
                            className="Subscribe-confirmationLink"
                            href="https://mixit.ru/upload/personal_data1.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            условиями подписки
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </Media>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default Subscribe;
