export function toDomainModel(apiBanner) {
  const {
    id,
    title,
    label,
    description,
    start_date,
    end_date,
    image,
    theme,
    url,
    promocode,
    alignment
  } = apiBanner;

  return {
    id,
    title,
    label,
    description,
    startDate: Date.parse(start_date),
    endDate: Date.parse(end_date),
    image,
    theme,
    url,
    promocode,
    alignment
  };
}

export function fromDomainModel(banner) {
  return banner;
}
