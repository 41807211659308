import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
// import MainCarousel from 'modules/core/components/main-carousel/main-carousel';
import PackingProductContainer from 'modules/product/containers/packing-product';

import './packing-dialog.css';

class PackingDialog extends Component {
  render() {
    const { products, isLoaded, onClose, ...rest } = this.props;

    return (
      <Dialog title="Выбор подарочной упаковки" onClose={onClose} closeable {...rest}>
        <div className="PackingDialog">
          {isLoaded && (
            // <MainCarousel>
            <div className="PackingDialog-static">
              {products.map((slug, index) => (
                // <MainCarousel.Item key={index}>
                <div key={index} className="PackingDialog-staticItem">
                  <PackingProductContainer slug={slug} onChoose={onClose} />
                </div>
                // {/* </MainCarousel.Item> */}
              ))}
            </div>
            // {/* </MainCarousel> */}
          )}
        </div>
      </Dialog>
    );
  }
}

export default PackingDialog;
