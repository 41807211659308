import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { createLastOrderPersistor } from 'services/local-storage';
import * as ORDER from '../ducks/types/orders';
import * as fromOrders from '../ducks/orders';
import * as fromDelivery from 'modules/ordering/ducks/delivery-form';

import * as fromLastOrder from '../ducks/last-order/action';
import * as fromProducts from 'modules/product/ducks/products';

import { getLastOrder } from '../ducks/last-order/selectors';

export function* asdpersistenceWatcher() {
  const lastOrderPersistor = createLastOrderPersistor();

  const rehydrate = function*() {
    const lastOrder = yield call(lastOrderPersistor.read);

    if (!lastOrder) {
      return;
    }

    yield put(fromLastOrder.rehydrate(lastOrder));
    return;
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
}

export function* checkoutLastOrderWatcher() {
  yield takeEvery(ORDER.SUCCESS, onOrderSuccess);
}

export function* checkoutIsPaymentOrder() {
  yield takeEvery(ORDER.PAYMENT_RESPONSE, writeToLocalStorige);
}

function* onOrderSuccess(action) {
  const lastOrderPersistor = createLastOrderPersistor();
  const { order } = action.payload;
  const _order = yield select(fromOrders.getItemById, order);
  const _payment = yield select(fromOrders.getPaymentById, order);
  const _comment = yield select(fromOrders.getCommentById, order);
  const _delivery = yield select(fromOrders.getDeliveryById, order);
  const _personalInfo = yield select(fromOrders.getPersonalInfoById, order);
  const _goodsList = yield select(fromOrders.getGoodsListById, order);
  const _productsCost = yield select(fromOrders.getProductsCostWithDiscount, order);
  const _deliveryCost = yield select(fromOrders.getDeliveryCostById, order);
  const _totalCost = yield select(fromOrders.getTotalCostById, order);
  const _deliveryMethod = yield select(fromDelivery.getDeliveryMethod, order);
  const _currentDeliveryCost = yield select(fromDelivery.getDeliveryCost, order);

  const _isPaymentLoading = yield select(fromOrders.getIsPaymentLoading);
  const _isPaymentRequired = yield select(fromOrders.getIsPaymentRequiredById, order);
  const _isLoading = yield select(fromOrders.getIsLoadingById, order);
  const _orderAuthorization = yield select(fromOrders.getAuthorizationById, order);

  // const _product = yield select(fromProducts.getItemBySlug, { slug: _goodsList[0].product });

  const getProduct = item => select(fromProducts.getItemBySlug, { slug: item.product });
  const getProducts = _goodsList.map(getProduct);
  const _productList = yield all(getProducts);

  const currentTime = yield Date.now();
  const _paymentLinkExpireDate = yield new Date(currentTime + 19.8 * 60000);
  const lastOrder = {
    order: _order,
    payment: _payment,
    comment: _comment,
    delivery: _delivery,
    personalInfo: _personalInfo,
    goodsList: _goodsList,
    productCost: _productsCost,
    deliveryCost: _deliveryCost,
    totalCost: _totalCost,
    deliveryMethod: _deliveryMethod,
    currentDeliveryCost: _currentDeliveryCost,
    isPaymentRequired: _isPaymentRequired,
    isPaymentLoading: _isPaymentLoading,
    isLoading: _isLoading,
    productList: _productList,
    orderAuthorization: _orderAuthorization,
    paymentLinkExpireDate: _paymentLinkExpireDate
  };

  yield put(fromLastOrder.rehydrate(lastOrder));

  yield call(lastOrderPersistor.write, lastOrder);
}

function* writeToLocalStorige() {
  const lastOrderPersistor = createLastOrderPersistor();

  const lastOrder = yield select(getLastOrder);

  yield call(lastOrderPersistor.write, lastOrder);
}
