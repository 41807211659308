import React, { Component } from 'react';
import './icon-dot.css';

class IconDot extends Component {
  render() {
    return (
      <svg className="IconDot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M259 125c-80.7-1.8-145.5 69.7-132.3 152.4 8.8 55.4 52.5 99 107.9 107.9C317.3 398.5 388.8 333.7 387 253c-1.6-69.9-58.1-126.4-128-128z" />
      </svg>
    );
  }
}

export default IconDot;
