const current = props => {
  const { children, selected } = props;

  if (selected) {
    return selected;
  }

  const enabledChildren = children.filter(child => !child.props.disabled);

  if (!enabledChildren.length) {
    return null;
  }

  return enabledChildren[0].props.value;
};

export default current;
