import React from 'react';

class LockScroll extends React.Component {
  render() {
    return null;
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.handleScroll, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleScroll, {
      passive: false
    });
  }

  handleScroll = event => {
    if (
      !this.props.filter({
        target: event.target
      })
    ) {
      return;
    }
    event.preventDefault();
  };
}

export default LockScroll;
