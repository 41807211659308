import React from 'react';

const Content = props => {
  const { isDesktop, children, className, onClick } = props;

  if (isDesktop) {
    return <div className={className}>{children}</div>;
  }

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default Content;
