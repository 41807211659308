export default function initSubscriptionListRequest(createRequest) {
  return function getItemRequest() {
    return {
      all: createRequest(initListRequest()),
      item: function _getItemRequest(slug = null) {
        return {
          add: createRequest(initItemAddRequest(slug)),
          delete: createRequest(initItemDeleteRequest(slug))
        };
      }
    };
  };
}

function initListRequest() {
  return function getItemRequest(parameters = null) {
    return {
      method: 'GET',
      path: `/profile/subscriptions`,
      query: parameters
    };
  };
}

function initItemAddRequest(slug) {
  return function getItemRequest() {
    return {
      method: 'POST',
      path: `/profile/subscriptions`,
      data: {
        topic_slug: slug
      }
    };
  };
}

function initItemDeleteRequest(slug) {
  return function getItemRequest() {
    return {
      method: 'DELETE',
      path: `/profile/subscriptions/${slug}`
    };
  };
}
