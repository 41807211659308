import React, { Component } from 'react';
import Container from '../container/container';
// import SideNavigation from '../side-navigation/side-navigation';
import Content from '../content/content';

import './cookie-section.css';

class CookieSection extends Component {
  render() {
    return (
      <div className="PaymentAndDeliverySection">
        <Container>
          <div className="PaymentAndDeliverySection-content">
            <div className="PaymentAndDeliverySection-exchangeAndReturn">
              <Content
                html={`
                      <p>Общество с ограниченной ответственностью ООО «Миксит» администрирует
                        Сайт <a href="https://mixit.ru/" target="_blank">https://mixit.ru/</a> и использует технологию cookie с целью предложить
                        Пользователям более персонализированный и качественный веб-сервис.
                        Используя этот Сайт, Пользователь Сайта соглашается с размещением cookieфайлов на своем компьютере и с использованием администратором Сайта
                        таких cookie-файлов в соответствии с условиями настоящей Политики. </p>
                      <ol>
                        <li>Файлами cookie называются небольшие текстовые файлы, которые
                            сохраняются на устройство, используемое Пользователем для работы с
                            Сайтом (персональный компьютер, смартфон, планшетный компьютер и т.д.).
                            Эти файлы могут содержать сведения, которые необходимы для работы
                            Сайта. Например, информацию о настройках браузера, о просмотренных
                            страницах, о настройках интерфейса Сайта и т.п. </li>
                        <li>Cookie-файлы используются для следующих задач: 
                            <ul>      
                                <li>обеспечение нормального функционирования Сайта;</li>
                                <li>аналитические исследования поведения и интересов аудитории Сайта;</li>
                                <li>аутентификация Пользователя;</li>
                                <li>хранение персональных предпочтений и настроек Пользователя;</li>
                                <li>отслеживание состояния сессии доступа Пользователя;</li>
                                <li>ведение статистики о Пользователях;</li>
                                <li>реклама и маркетинг.</li>
                            </ul>
                        </li>
                        <li>Некоторые cookie-файлы могут хранится в течении определенного
                            времени (одной сессии) и удалять при закрытии браузера. Другие же могут
                            хранится в течении долгого времени и записываются в специальный
                            файл cookies.txt, который лежит в директории используемого браузера.</li>
                        <li>Мы разрешаем третьим лицам использовать cookie-файлы для
                            статистических и оценочных целей, чтобы собирать и сохранять общую
                            информацию. Эти файлы обеспечивают информацию на совокупной основе, то
                            есть не идентифицируют личность Пользователя. Мы используем совокупные
                            отчеты, чтобы понять, как используется Сайт и улучшить его
                            функциональность для нашей аудитории. Мы не контролируем использование
                            cookie-файлов третьими лицами и не несем ответственности за любые
                            действия или политики третьих лиц.
                            </li>
                        <li>Реклама может предоставляться Пользователю с учетом характера его
                            деятельности в Интернете или при использовании мобильных устройств, а
                            также с учетом его действий на Сайте.</li>
                        <li>Веб-маяки
                            <p>На Сайте могут использоваться такие технологии как веб-маяки
                                («однопиксельные файлы», «прозрачные GIF-файлы» или «метки действий». С
                                помощью такой технологии мы узнаем, какие именно Пользователи
                                осуществлял переход по ключевым элементам (ссылкам или изображениям)
                                на странице Сайта. Используемые Пользователем рекламные и
                                информационные материалы на Сайте могут содержать веб-майки, которые
                                позволяют нам определять, были ли открыты эти сообщения. Полученные
                                сведения используются для оптимизации содержимого, услуг и рассылаемых
                                материалов.</p>
                                <p>Веб-маяки обычно используются совместно с cookie-файлами и могут быть
                                    выключены путем полного отключения cookie-файлов.</p>
                        </li>
                        <li>
                           <p>Указанные в настоящей Политике cookie-файлы не должны содержать
                            персональные данные Пользователя.</p>
                           <p>Большинство интернет-браузеров изначально настроены автоматически
                            принимать cookie-файлы. Пользователь может изменить настройки браузера
                            таким образом, чтобы блокировать cookie-файлы или предупреждать
                            Пользователя, когда файлы данного типа будут отправлены на устройство.
                            Пользователь может воспользоваться инструкцией своего браузера для того,
                            чтобы узна</p>
                        </li>
                        <li><b>ВНИМАНИЕ!</b> Сookie-файлы могут быть использованы злоумышленниками
                            в корыстных целях. Если вашим компьютером воспользуется посторонний, а в
                            cookie-файлах сохранены пароли, то он сможет войти в ваши аккаунты на
                            сайтах.</li>
                      </ol>

                      `}
                expanded
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default CookieSection;
