import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './animated-link.css';

class AnimatedLink extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    icon: PropTypes.element
  };

  static defaultProps = {
    component: 'a'
  };

  render() {
    const { title, icon, component: LinkComponent, nolink, inline, noGap, ...rest } = this.props;

    return (
      <LinkComponent
        className={cn('AnimatedLink', {
          'AnimatedLink--noLink': nolink,
          'AnimatedLink--inline': inline,
          'AnimatedLink--noGap': noGap
        })}
        {...rest}
      >
        {icon && <span className="AnimatedLink-icon">{icon}</span>}
        <span className="AnimatedLink-value">{title}</span>
      </LinkComponent>
    );
  }
}

export default AnimatedLink;
