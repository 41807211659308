import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './basket-label.css';

const { string, oneOf } = PropTypes;

class BasketLabel extends Component {
  static propTypes = {
    type: oneOf(['FEATURE/GIFT', 'FEATURE/LAST']).isRequired,
    title: string.isRequired
  };
  render() {
    const { type, title } = this.props;

    return (
      <div className={cn('BasketLabel', `BasketLabel--${this.getLabelFeature(type)}`)}>{title}</div>
    );
  }

  getLabelFeature(feature) {
    return feature
      .split('/')
      .join('-')
      .toLowerCase();
  }
}

export default BasketLabel;
