import { getCategoryLink } from 'routes/links';
const getFilteredLink = module => `/category/${module}/`;

export default function normalizeNavigation(rawConfig) {
  let normalizedConfig = { ...rawConfig };
  const keys = Object.keys(normalizedConfig);

  keys.forEach(key => {
    normalizedConfig[key] = {
      ...normalizedConfig[key],
      ...(normalizedConfig[key].list && {
        list: normalizedConfig[key].list.map(normalizeNavigationElement)
      })
    };
  });

  return normalizedConfig;
}

function normalizeNavigationElement({
  id,
  title,
  path,
  href,
  slug,
  submenu = null,
  props = null,
  perentId
}) {
  const _link = path || getFilteredLink(slug);

  const _props = {
    ...(Boolean(perentId) && { perentId }),
    id,
    title,
    name: slug,
    slug,
    ...(!Boolean(href) && { link: _link }),
    ...(Boolean(href) && { href }),
    ...(Boolean(props) && { props }),
    ...(Boolean(submenu) && {
      submenu: submenu.map(item => {
        const { list, type, media, cardProps, path } = item;

        return {
          ...item,
          ...(Boolean(list) && { list: list.map(normalizeSubmenuItem(slug)) }),
          ...(Boolean(type) && { type }),
          ...(Boolean(type) &&
            type === 'card' && {
              href: path ? path : getCategoryLink({ slug }, { slug: item.slug })
            }),

          ...(Boolean(media) && { media }),
          ...(Boolean(cardProps) && { cardProps })
        };
      })
    })
  };

  const linkProps = {
    ...(_props.href && { href: _props.href }),
    ...(_props.link && { to: _props.link }),
    ..._props.props
  };

  return {
    ..._props,
    linkProps
  };
}

function normalizeSubmenuItem(parentSlug) {
  return ({ slug, title, filter, href, path, submenu, id, perentId }) => {
    if (submenu) {
      return normalizeNavigationElement({ title, path, href, slug, submenu, id, perentId });
    }

    const parrentPart = { slug: parentSlug };
    const subPart = slug ? { slug } : null;
    const filterPart = filter ? { filter } : null;

    const link = path ? path : getCategoryLink(parrentPart, subPart, filterPart);

    return {
      link: link,
      title,
      ...(slug && { slug }),
      ...(href && { href }),
      ...(filter && { filter })
    };
  };
}
