import { call, takeEvery, select } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import * as TYPES from '../types';
import * as fromAccount from 'modules/core/ducks/account';

export function* counterWatcher() {
  yield takeEvery(TYPES.MAGAZINE_ARTICLE_LIKE, like);
  yield takeEvery(TYPES.MAGAZINE_ARTICLE_UNLIKE, unlike);
  yield takeEvery(TYPES.MAGAZINE_ARTICLE_SHARE, share);
}

function* like(action) {
  const { slug } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);
  try {
    yield call(
      mixitApi(authorization)
        .blog()
        .articles(slug).like
    );
  } catch (e) {}
}

function* unlike(action) {
  const { slug } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);
  try {
    yield call(
      mixitApi(authorization)
        .blog()
        .articles(slug).unlike
    );
  } catch (e) {}
}

function* share(action) {
  const { slug } = action.payload;
  const authorization = yield select(fromAccount.getAuthorization);
  try {
    yield call(
      mixitApi(authorization)
        .blog()
        .articles(slug).share
    );
  } catch (e) {}
}
