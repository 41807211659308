import { createStore, compose, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createBeaconMiddleware from './beacon';

import rootReducer from './reducer';
import registerSagas from './sagas';

import { connectRouter, routerMiddleware as createRouterMiddleware } from 'connected-react-router';

const prodMode = __BROWSER__ ? window.localStorage.getItem('__mode__') : 'production';
const __DEV__ = process.env.NODE_ENV === 'development';
const LOG_IN_PROD = prodMode === 'development';
let composeEnhancers = compose;

if (__DEV__ && __BROWSER__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

function createReducer(rootReducer, history) {
  return compose(connectRouter(history))(rootReducer);
}

function configureStore(history, initialState = {}) {
  const reducer = createReducer(rootReducer, history);
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [routerMiddleware, sagaMiddleware];

  if (__BROWSER__) {
    const beaconMiddleware = createBeaconMiddleware(process.env.REACT_APP_GTAG_GA_ID);

    middleware.push(beaconMiddleware);
  }

  if (__DEV__ || LOG_IN_PROD) {
    const loggerMiddleware = __BROWSER__
      ? createLogger({
          collapsed: true,
          logErrors: false
        })
      : () => next => action => {
          console.log(action.type);
          return next(action);
        };

    middleware.push(loggerMiddleware);
  }

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  const store = createStore(reducer, initialState, enhancer);

  store.runSagas = registerSagas(sagaMiddleware);

  if (__DEV__ && __BROWSER__) {
    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        const reducer = createReducer(rootReducer, history);
        store.replaceReducer(reducer);
      });
    }
  }

  return store;
}

export default configureStore;
