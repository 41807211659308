import React, { Component } from 'react';

class ButtonPlay extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <button className="Media-buttonPlay" type="button" {...rest}>
        <svg
          className="Media-iconPlay"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 88 63"
        >
          <path d="M46.108 63c-.002 0 0 0 0 0-10.007 0-20.902-.317-32.379-.935C6.948 61.693 1.688 56.462.94 49.341a169.9 169.9 0 0 1 0-35.69c.747-7.06 6-12.292 12.774-12.718 19.794-1.247 40.162-1.247 60.567 0 6.854.416 11.988 5.524 12.781 12.711 1.249 11.381 1.251 23.387.001 35.693-.721 7.115-5.851 12.229-12.763 12.725-8.699.621-18.185.938-28.192.938zM43.767 3.379c-9.975 0-20.016.314-29.844.931-5.177.325-9.044 4.224-9.623 9.698a166.664 166.664 0 0 0 0 34.98c.579 5.519 4.442 9.415 9.61 9.699 11.417.615 22.25.93 32.195.93 9.928 0 19.332-.312 27.951-.928 5.216-.376 9.092-4.271 9.641-9.695 1.227-12.068 1.227-23.836.002-34.979-.604-5.492-4.472-9.392-9.624-9.705a499.326 499.326 0 0 0-30.308-.931zm-8.376 16.139V42.73l22.276-11.527-22.276-11.685z" />
        </svg>
      </button>
    );
  }
}

export default ButtonPlay;
