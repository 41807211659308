import React, { Component } from 'react';
import './icon-vk.css';

class IconVk extends Component {
  render() {
    return (
      <svg className="IconVk" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path d="M28.75,21.06A40.3,40.3,0,0,1,31.67,24a8.32,8.32,0,0,1,1,1.51c.4.78,0,1.64-.66,1.68H27.71a3.35,3.35,0,0,1-2.77-1.13c-.6-.61-1.16-1.26-1.74-1.9a4.06,4.06,0,0,0-.78-.7.91.91,0,0,0-1.45.35,4.55,4.55,0,0,0-.46,2c0,1-.36,1.29-1.38,1.34a11.12,11.12,0,0,1-6.21-1.34,13.71,13.71,0,0,1-4.18-3.91,52.74,52.74,0,0,1-5.5-9.81c-.35-.77-.09-1.19.76-1.2,1.42,0,2.83,0,4.25,0a1.25,1.25,0,0,1,1.18.88,26.38,26.38,0,0,0,2.88,5.34,4.56,4.56,0,0,0,1.09,1.2A.66.66,0,0,0,14.53,18a3.64,3.64,0,0,0,.25-1.12A16.73,16.73,0,0,0,14.71,13a1.64,1.64,0,0,0-1.39-1.5c-.41-.08-.35-.23-.15-.47a1.56,1.56,0,0,1,1.33-.66H19.4c.77.15.95.5,1.05,1.28v5.45c0,.3.15,1.19.69,1.39s.72-.2,1-.48a17,17,0,0,0,2.77-4.25c.33-.67.62-1.37.89-2.07a1.06,1.06,0,0,1,1.11-.76h4.72a2.46,2.46,0,0,1,.42,0c.8.14,1,.48.77,1.26a11.77,11.77,0,0,1-1.88,3.26c-.79,1.09-1.63,2.14-2.41,3.24S27.86,20.19,28.75,21.06Z" />
      </svg>
    );
  }
}

export default IconVk;
