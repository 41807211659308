import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProductList from '../components/product-list/product-list';

import * as fromSearch from '../ducks/search';

const getQuery = (_, props) => props.query;
const getFilters = (_, props) => props.appliedFilters;

const mapStateToProps = createStructuredSelector({
  products: fromSearch.makeGetProductListByQueryAndFilters(getQuery, getFilters),
  isLoaded: fromSearch.makeGetIsProductLoadedByQueryAndFilters(getQuery, getFilters),
  microMarkingObject: fromSearch.getMicroMarkingObject(getQuery, getFilters)
});

class SearchProductListContainer extends Component {
  render() {
    return <ProductList {...this.props} isLoading={!this.props.isLoaded} />;
  }
}

export default connect(mapStateToProps)(SearchProductListContainer);
