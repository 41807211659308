import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Media from 'modules/core/components/media/media';
import isFunction from 'modules/utils/is-function';

import AvatarPlaceholder from '../avatar-placeholder/avatar-placeholder';

import './profile-avatar.css';

const { string, number, func } = PropTypes;

class ProfileAvatar extends Component {
  propTypes = {
    name: string,
    photo: string,
    resizeWidth: number,
    resizeHeight: number,
    onError: func,
    onLoad: func
  };
  render() {
    const { name, photo, resizeWidth, resizeHeight } = this.props;

    return (
      <div className="ProfileAvatar">
        {photo ? (
          <div className="ProfileAvatar-image">
            <Media
              media={photo}
              stretch="horizontal"
              onError={this.handleError}
              onLoad={this.handleLoad}
              resizeWidth={resizeWidth}
              resizeHeight={resizeHeight}
            />
          </div>
        ) : (
          <div className="ProfileAvatar-imagePlaceholder">
            <AvatarPlaceholder name={name} />
          </div>
        )}
      </div>
    );
  }

  handleError = () => {
    const { onError } = this.props;

    if (!isFunction(onError)) {
      return;
    }
    onError();
  };

  handleLoad = () => {
    const { onLoad } = this.props;

    if (!isFunction(onLoad)) {
      return;
    }
    onLoad();
  };
}

export default ProfileAvatar;
