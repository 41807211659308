import * as Basket from './basket';
import * as Product from 'modules/product/ducks/products/helpers';
import * as DeliveryMethod from './delivery-method';
import * as PickPoint from './pick-point';
import { deserializeProduct } from 'modules/product/deserializers/product';

export function normalize(serverOrder) {
  const {
    placing_date,
    tracking_number, 
    delivery_company_title, 
    contacts,
    delivery,
    payment,
    basket,
    confirmation_required,
    is_payed,
    ...rest
  } = serverOrder;

  const productList = basket.goods_list.map(goods => goods.product);

  const order = {
    basket: Basket.deserialize(basket),
    placingDate: Date.parse(placing_date),
    personalInfo: getPersonalInfo(contacts),
    delivery: getDelivery(delivery),
    payment: normalizePayment(payment),
    products: Product.reduceListBySlug(productList.map(deserializeProduct)),
    bonusConformationRequired: confirmation_required,
    isPayed: is_payed,
    // trackingNumber: tracking_number || 5454,
    // deliveryCompanyTitle: delivery_company_title || "Рога и копыта деливери",
    trackingNumber: tracking_number || null,
    deliveryCompanyTitle: delivery_company_title || null,
    ...rest
  };

  return order;
}

export function normalizePayment(payment) {
  if (!payment) {
    return null;
  }

  return {
    paymentMethod: getPaymentMethod(payment),
    invoiceLink: payment.invoice_link || null
  };
}

function getPersonalInfo(contacts) {
  if (!contacts) {
    return null;
  }

  const { email, phone, first_name: firstName } = contacts;

  if (!email && !phone && !firstName) {
    return null;
  }

  return {
    email,
    phone,
    firstName
  };
}

function getDelivery(delivery) {
  if (!delivery) {
    return null;
  }

  const deliveryMethod = delivery.delivery_method;
  const deliveryMethodId = DeliveryMethod.getId(delivery.delivery_method);
  const geography = getGeography(delivery.geography);
  const pickPoint = delivery.pick_point;
  const pickPointId = PickPoint.getId(pickPoint);
  const recipientName = delivery.recipient_full_name;
  const recipientPhone = delivery.recipient_phone;
  const price = delivery.price;

  return {
    deliveryMethod,
    deliveryMethodId,
    geography,
    pickPoint,
    pickPointId,
    recipientName,
    recipientPhone,
    price
  };
}

function getGeography(gepgraphy) {
  if (!gepgraphy) {
    return null;
  }

  const { city, address, ...rest } = gepgraphy;

  const { street_type, street_fias_id, street_title, raw, ...restAddress } = address;

  const street = {
    fiasId: street_fias_id,
    type: street_type,
    title: street_title,
    raw: raw
  };

  return {
    ...rest,
    city: {
      ...city,
      fiasId: city.fias_id
    },
    address: {
      ...restAddress,
      street,
      raw
    }
  };
}

function getPaymentMethod(paymentMethod) {
  if (!paymentMethod) {
    return null;
  }

  return paymentMethod;
}

function denormalizeAddress(address) {
  if (!address) {
    return null;
  }

  const { street, building, apartment } = address;

  return {
    street_title: street.title,
    street_type: street.type,
    street_fias_id: street.fiasId,
    building: building,
    apartment: apartment,
    raw: street.raw
  };
}

function denormalizeCity(city) {
  if (!city) {
    return null;
  }

  const { fiasId, title, country, id, isSettlement } = city;

  if ((!id && !fiasId) || isSettlement) {
    return {
      city_title: title,
      country_title: country
    };
  }

  return {
    id,
    fias_id: fiasId,
    city_title: title,
    country_title: country
  };
}

function denormalizePickPoint(pickPointId) {
  if (!pickPointId) {
    return null;
  }

  return pickPointId;
}

function denormalizeDelivery(delivery) {
  if (!delivery) {
    return null;
  }

  const {
    deliveryMethodId,
    deliveryMethod,
    geography,
    pickPointId,
    recipientPhone,
    recipientName,
    data
  } = delivery;

  return {
    delivery_method_id: deliveryMethod.deliveryMethodId || deliveryMethodId,
    geography: {
      city: denormalizeCity(geography.city),
      address: denormalizeAddress(geography.address)
    },
    pick_point_id: denormalizePickPoint(pickPointId),

    recipient_full_name: recipientName,
    recipient_phone: recipientPhone,
    data: data
  };
}

function denormalizePersonalInfo(personalInfo) {
  if (!personalInfo) {
    return null;
  }

  const { email, phone, firstName: first_name, lastName } = personalInfo;

  return {
    email,
    phone,
    first_name: first_name.split(' ')[0],
    // ...(Boolean(first_name.split(" ")[1]) && {last_name:first_name.split(" ")[1]})
    ...(Boolean(lastName) && { last_name: lastName })
  };
}

function denormalizePayment(payment) {
  if (!payment) {
    return null;
  }

  return {
    payment_method_id: payment.paymentMethodId || null
  };
}

function denormalizeStatus(status) {
  if (!status) {
    return null;
  }

  return status;
}

export function denormalize(order) {
  if (!order) {
    return null;
  }

  const serverOrder = {
    basket: Basket.serialize(order.basket),
    status: denormalizeStatus(order.status),
    contacts: denormalizePersonalInfo(order.personalInfo),
    delivery: denormalizeDelivery(order.delivery),
    payment: denormalizePayment(order.payment),
    comment: order.comment ? order.comment : null,
    confirmation_method: order.confirmationMethod
  };

  if (!serverOrder.basket) {
    delete serverOrder.basket;
  }

  return serverOrder;
}

export function getId(order) {
  return order.id;
}
