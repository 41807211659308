import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './price.css';

const { string, oneOf, bool } = PropTypes;

class Price extends Component {
  static propTypes = {
    /** Price */
    price: string.isRequired,

    /** Price variant **/
    variant: oneOf(['default', 'previous', 'secondary', 'discount', 'mark']),

    currencySign: bool
  };

  static defaultProps = {
    variant: 'default',
    currencySign: false
  };

  render() {
    const { price, variant, currencySign } = this.props;
    return (
      <span className={classNames('Price', `Price--variant-${variant}`)}>
        <nobr>
          <span className="Price-number">{price}</span>&nbsp;
          <span className="Price-currency">{currencySign ? '₽' : 'руб.'}</span>
        </nobr>
      </span>
    );
  }
}

export default Price;
