import React, { Component } from 'react';
import PropTypes from 'prop-types';

import articleShape from '../../prop-types/article-shape';
import ArticlePreview from '../article-preview/article-preview';

import './article-list.css';
import ArticleCard from '../article-card/article-card';

class ArticleList extends Component {
  static propTypes = {
    articleList: PropTypes.arrayOf(articleShape)
  };

  render() {
    const { articleList } = this.props;
    return (
      <div className="ArticleList">
        <div className="ArticleList-itemsHolder">
          {articleList.map(article => (
            <div key={article.id} className="ArticleList-item">
              {article.promoted ? (
                <ArticleCard article={article} size="medium" />
              ) : (
                <ArticlePreview article={article} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default ArticleList;
