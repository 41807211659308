export function normalizeCityList(cityList) {
  const normalizedCityList = cityList.map(city => normalizeCity(city));

  return normalizedCityList;
}

function normalizeCity(city) {
  const { id, title, fias_id, coordinates, country } = city;

  return { id, title, fiasId: fias_id || '', coordinates, country };
}

export function normalizeShopList(shopList) {
  const normalizedShopList = shopList.map(shop => normalizeShop(shop));

  return normalizedShopList;
}

function nornalizeFeatures(feature) {
  return {
    ...(feature.type && { type: feature.type }),
    ...(feature.title && { title: feature.title }),
    ...(feature.description && { description: feature.description }),
    ...(feature.style && { style: feature.style })
  };
}

// const feuturesMock = [
//   {
//     type: 'FEATURE/PPD',
//     title: 'Доступен самовывоз',
//     description: 'В этом магазине доступен самовывоз'
//     // style: {
//     //   backgroungColor: 'coral',
//     //   color: 'red',
//     //   fontWeight: 'bold'
//     // }
//   },
//   {
//     type: 'FEATURE/NEW',
//     title: 'NEW',
//     description: 'Мы открылись'
//     // style: {
//     //   color: 'black',
//     //   padding: '8px 10px 5px'
//     // }
//   }
// ];

export function normalizeShop(shop) {
  const { id, geography, phone, title, schedule, schedule_raw, guides, is_new, features } = shop;

  return {
    id,
    geography: {
      cityId: geography.city ? geography.city.id : null,
      address: geography.address.raw,
      coordinates: {
        lng: +geography.coordinates.lng,
        lat: +geography.coordinates.lat,
        latlng: [+geography.coordinates.lat, +geography.coordinates.lng]
      }
    },
    isNew: is_new,
    phones: phone ? [...phone] : null,
    guides: guides ? guides : null,
    title: title ? title : null,
    schedule: schedule ? schedule : null,
    schedule_raw,
    ...(features && { features: features.map(nornalizeFeatures) })
  };
}
