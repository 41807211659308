import React from 'react';

import './landing-bg.css';

function LandingBG({ style }) {
  return (
    <div className="LandingBG" style={style}>
      <div className="LandingBG-item">
        <SVG1 />
      </div>
      <div className="LandingBG-item">
        <SVG2 />
      </div>
      <div className="LandingBG-item">
        <SVG3 />
      </div>
      <div className="LandingBG-item">
        <SVG3 />
      </div>
      <div className="LandingBG-item">
        <SVG3 />
      </div>
      <div className="LandingBG-item">
        <SVG6 />
      </div>
      <div className="LandingBG-item">
        <SVG7 />
      </div>
      <div className="LandingBG-item">
        <SVG8 />
      </div>
      <div className="LandingBG-item">
        <SVG9 />
      </div>
      <div className="LandingBG-item">
        <SVG10 />
      </div>
      <div className="LandingBG-item">
        <SVG11 />
      </div>
      <div className="LandingBG-item">
        <SVG12 />
      </div>
      <div className="LandingBG-item">
        <SVG13 />
      </div>
      <div className="LandingBG-item">
        <SVG14 />
      </div>
    </div>
  );
}

function SVG1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 27">
      <path d="M16.4.3L0 20.7l24.5 5.4L16.4.3z" fill="#46cacc" />
    </svg>
  );
}
function SVG2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 27">
      <path d="M.2 20.9l25.5 5.7L17.5.8.2 20.9z" fill="#a04ab0" />
    </svg>
  );
}
function SVG3() {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 33 35"
    >
      <path
        fill="#46cacc"
        d="M16.1.7c.9 0 1.6.8 1.6 1.7v8.2c0 .9-.7 1.7-1.6 1.7-.9 0-1.6-.8-1.6-1.7V2.4c0-.9.7-1.7 1.6-1.7zM16.1 23.2c.9 0 1.6.8 1.6 1.7V33c0 .9-.7 1.7-1.6 1.7-.9 0-1.6-.8-1.6-1.7v-8.2c0-.9.7-1.6 1.6-1.6zM22.8 16h7.8c.9 0 1.6.8 1.6 1.7 0 .9-.7 1.7-1.6 1.7h-7.8c-.9 0-1.6-.8-1.6-1.7.1-.9.8-1.7 1.6-1.7zM1.6 16h7.8c.9 0 1.6.8 1.6 1.7 0 .9-.7 1.7-1.6 1.7H1.6c-.9 0-1.6-.8-1.6-1.7 0-.9.7-1.7 1.6-1.7zM26.3 5.3c.4 0 .7.2 1.1.5.6.5.6 1.6 0 2.2L22 13.8c-.6.6-1.6.6-2.2 0-.6-.6-.6-1.7 0-2.3l5.5-5.8c.1-.3.6-.4 1-.4zM11.2 21.2c.4 0 .7.2 1.1.5.6.6.6 1.7 0 2.3l-5.5 5.8c-.6.6-1.6.6-2.2 0s-.6-1.7 0-2.3l5.5-5.8c.4-.4.7-.5 1.1-.5zM20.8 21.2c.4 0 .7.2 1.1.5l5.5 5.8c.6.6.6 1.7 0 2.3-.6.6-1.6.6-2.2 0L19.7 24c-.6-.6-.6-1.7 0-2.3.4-.4.7-.5 1.1-.5zM5.7 5.3c.4 0 .7.2 1.1.5l5.5 5.8c.6.6.6 1.7 0 2.3-.6.6-1.6.6-2.2 0L4.7 8c-.6-.6-.6-1.7 0-2.3.3-.3.8-.4 1-.4z"
      />
    </svg>
  );
}
function SVG6() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18">
      <path
        d="M15.9 8.6c0 4.1-3.1 7.5-7.1 7.5-4 0-7.1-3.3-7.1-7.5C1.6 4.5 4.8 1 8.8 1c4 0 7.1 3.5 7.1 7.6z"
        fill="none"
        stroke="#a04ab0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
function SVG7() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        d="M12.5 6.9c0 3.2-2.4 5.9-5.6 5.9-3 0-5.6-2.6-5.6-5.9C1.3 3.7 3.7 1 6.9 1c3.2.2 5.6 2.7 5.6 5.9z"
        fill="none"
        stroke="#46cacc"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
function SVG8() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
      <path d="M17.3.7L0 1.4l9.2 15.4L17.3.7z" fill="#46cacc" />
    </svg>
  );
}
function SVG9() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 19">
      <path d="M16.5 4.3L.2.6l5.4 18.3L16.5 4.3z" fill="#46cacc" />
    </svg>
  );
}
function SVG10() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20">
      <path d="M2 19.3l14.3-11L0 1l2 18.3z" fill="#46cacc" />
    </svg>
  );
}
function SVG11() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 19">
      <path d="M2.6 18.3l14.3-11L.6 0l2 18.3z" fill="#46cacc" />
    </svg>
  );
}
function SVG12() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
      <path d="M15 19.4L15.3 0 0 9.4l15 10z" fill="#46cacc" />
    </svg>
  );
}
function SVG13() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
      <path d="M6.1.5L0 18.8l17.3-3.5L6.1.5z" fill="#46cacc" />
    </svg>
  );
}
function SVG14() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26">
      <path d="M24.5 0L0 8.3l19.2 17.6L24.5 0z" fill="#46cacc" />
    </svg>
  );
}

export default LandingBG;
