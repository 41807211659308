import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './product-features.css';
import { IconHandbasket } from 'modules/core/components/icons';
// import IconH from 'modules/core/components/icons/icon-heart_/icon-heart';

class ProductFeatures extends Component {
  static propTypes = {
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    )
  };
  static defaultProps = {};

  render() {
    const { features, size, isGift } = this.props;

    return (
      <div
        className={cn('ProductFeatures', {
          'ProductFeatures--size-small': size === 'small'
        })}
      >
        {isGift && <div className=" ProductFeatures-item ProductFeatures-item--gift">Подарок</div>}

        {features.map(this.renderFeature)}
      </div>
    );
  }

  renderFeature = (feature, index) => {
    const { isGift } = this.props;

    // const label = feature.description ? feature.description : '';

    return (
      <div
        // title={label}
        key={index}
        className={cn('ProductFeatures-item', {
          'ProductFeatures-item--sale': feature.type === 'FEATURE/SALE' && !isGift,
          'ProductFeatures-item--new': feature.type === 'FEATURE/NEW',
          'ProductFeatures-item--popular': feature.type === 'FEATURE/POPULAR',
          'ProductFeatures-item--rest': feature.type === 'FEATURE/REST',
          'ProductFeatures-item--consumersChoice': feature.type === 'FEATURE/CONSUMERS_CHOICE',
          'ProductFeatures-item--art': feature.type === 'FEATURE/MIXIT_ART'
        })}
      >
        <div className="ProductFeatures-holder">
          {feature.type === 'FEATURE/CONSUMERS_CHOICE' && (
            <div className="ProductFeatures-icon">
              <IconHandbasket />
            </div>
          )}
          {!(feature.type === 'FEATURE/COSMOPOLITAN') && (
            <span className="ProductFeatures-title">{feature.title}</span>
          )}
        </div>
      </div>
    );
  };
}

export default ProductFeatures;
