import React, { PureComponent, Fragment } from 'react';
import cn from 'classnames';

import BottomSheet from '../bottom-sheet/bottom-sheet';
import Button from '../button/button';

import './cities-with-shops-bottom-sheet.css';

class CitiesWithShopsBottomSheet extends PureComponent {
  render() {
    const { list, activeOptionIndex, onClose, ...rest } = this.props;

    return (
      <BottomSheet
        footer={<Button title="Назад" size="small" onClick={onClose} expanded />}
        onClose={onClose}
        {...rest}
      >
        <div className="CitiesWithShopsBottomSheet">
          <ul className="CityChoiceBottomSheet-list">
            {list.map((city, index) => (
              <li
                className={cn('CityChoiceBottomSheet-item', {
                  'CityChoiceBottomSheet-item--active': index === activeOptionIndex
                })}
                key={city.id}
              >
                <button
                  className="CitiesWithShopsBottomSheet-button"
                  onClick={this.handleClick(city)}
                >
                  {city.title}

                  {city.country && (
                    <Fragment>
                      ,{' '}
                      <span className="CitiesWithShopsBottomSheet-country">
                        {city.country.title}
                      </span>
                    </Fragment>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>
    );
  }

  handleClick = city => () => {
    const { onCitySelect } = this.props;

    onCitySelect(city);
  };
}

export default CitiesWithShopsBottomSheet;
