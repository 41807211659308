import React, { Component, Fragment } from 'react';
import SectionDefault from 'modules/core/components/section-default/section-default';
import InstagramCarousel from 'modules/core/components/instagram-carousel/instagram-carousel';
import Container from 'modules/core/components/container/container';
import isFunction from 'modules/utils/is-function';

import InstagramPostContainer from '../../containers/instagram-post';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

class InstagramReviewSlider extends Component {
  render() {
    const { instagramPosts } = this.props;

    return this.isReviews(instagramPosts) ? (
      <SectionDefault>
        <Container>
          <InstagramCarousel coll={3}>
            {instagramPosts.map((instagramPost, index) => {
              return (
                <HideErrorBoundary key={index}>
                  <Fragment>
                    <InstagramCarousel.Item>
                      <InstagramPostContainer
                        post={instagramPost}
                        onError={this.handleError(instagramPost)}
                        squareImage={true}
                      />
                    </InstagramCarousel.Item>
                  </Fragment>
                </HideErrorBoundary>
              );
            })}
          </InstagramCarousel>
        </Container>
      </SectionDefault>
    ) : null;
  }

  isReviews(reviews) {
    return reviews && reviews.length;
  }

  isLoaded(post, list) {
    return list.indexOf(post) === -1;
  }

  handleError = post => () => {
    const { onError } = this.props;

    if (!isFunction(onError)) {
      return;
    }
    onError(post);
  };
}

export default InstagramReviewSlider;
