import React, { Component } from 'react';
import './icon-location.css';

class IconLocation extends Component {
  render() {
    return (
      <svg className="IconLocation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M8 15c-.2 0-.4-.1-.6-.3-.1-.1-.2-.3-.4-.4-1-1.1-2.9-3.2-4-5.6-.3-.7-.5-1.5-.5-2.2C2.5 3.4 5 1 8 1s5.5 2.4 5.5 5.5c0 .8-.2 1.5-.5 2.2-1.1 2.4-3 4.5-4 5.6l-.4.4c-.2.2-.4.3-.6.3zM8 2a4.48 4.48 0 0 0-4.1 6.3c1 2.2 2.8 4.3 3.8 5.3.1.1.2.2.2.3.1-.1.2-.2.2-.3 1-1.1 2.8-3.1 3.8-5.3.3-.6.4-1.2.4-1.8A4.2 4.2 0 0 0 8 2z" />
        <path d="M8 8a1.9 1.9 0 1 1 0-3.8A1.9 1.9 0 0 1 8 8zm0-2.8c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9z" />
      </svg>
    );
  }
}

export default IconLocation;
