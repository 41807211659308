const ORDER_STATUS = {
  ABANDONED: 'ORDER_STATUS/ABANDONED',
  NEW: 'ORDER_STATUS/NEW',
  AWAITING_CALL: 'ORDER_STATUS/AWAITING_CALL',
  ACCEPTED: 'ORDER_STATUS/ACCEPTED',
  IN_DELIVERY: 'ORDER_STATUS/IN_DELIVERY',
  DELIVERED: 'ORDER_STATUS/DELIVERED',
  CANCELED: 'ORDER_STATUS/CANCELED',
  EXPECTING_PAYMENT: 'ORDER_STATUS/EXPECTING_PAYMENT',
  MISSED_CALL: 'ORDER_STATUS/MISSED_CALL'
};

export default ORDER_STATUS;
