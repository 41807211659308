import { getMaxScroll, getTopElementPosition } from 'modules/utils';

export default function scrollToElementTop(element, offset = 0, scrollLimiter) {
  const { top, bottom } = element.getBoundingClientRect();

  if (top > 0 && bottom < window.innerHeight) {
    return;
  }

  const topElementPosition = getTopElementPosition(element) - offset;
  const maxScroll = scrollLimiter ? getMaxScroll(scrollLimiter) : window.innerHeight;
  const pageY = Math.min(topElementPosition, maxScroll);

  window.scrollTo({
    top: pageY,
    behavior: 'smooth'
  });
}
