export default function initLocationRequest(createRequest) {
  return function _getLocationRequest() {
    return {
      cities: createRequest(initCitiesRequest())
    };
  };
}

function initCitiesRequest() {
  return function getItemRequest(parameters = null) {
    return {
      method: 'GET',
      path: `/cities`,
      query: parameters
    };
  };
}
