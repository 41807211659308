import React, { Component } from 'react';
import Media from 'react-media';

import Dialog from 'modules/core/components/dialog/dialog';
import Button from 'modules/core/components/button/button';

import PromoCodeWithButton from '../promo-code-with-button/promo-code-with-button';

import './utm-dialog.css';

class UTMDialog extends Component {
  render() {
    const { onClose, title, promoCode, description, descriptionTitle, label, ...rest } = this.props;
    return (
      <Dialog closeable {...rest} onClose={onClose}>
        <Media query="(max-width: 970px)">
          {isMobile => (
            <div className="UTMDialog">
              {!!title && <h1 className="UTMDialog-title">{title}</h1>}

              {!!descriptionTitle && (
                <h2 className="UTMDialog-descriptionTitle">{descriptionTitle}</h2>
              )}

              {!!label && <p className="UTMDialog-label">{label}</p>}

              {!!description && (
                <p
                  className="UTMDialog-description"
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              )}

              {!!promoCode && (
                <div className="UTMDialog-promo">
                  {!isMobile && <div className="UTMDialog-promoTitle">Промокод</div>}
                  <PromoCodeWithButton code={promoCode} size="small" inverted />
                </div>
              )}
              <div className="UTMDialog-close">
                <Button title="Понятно" variant="primary" size="small" onClick={onClose} expanded />
              </div>
            </div>
          )}
        </Media>
      </Dialog>
    );
  }
}

export default UTMDialog;
