import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
import BottomSheet from 'modules/core/components/bottom-sheet/bottom-sheet';

import IngredientDetails from '../ingredient-details/ingredient-details';
// import Loader from 'modules/core/components/loader/loader';
import ButtonClose from 'modules/core/components/button-close/button-close';

import "./ingredient-dialog.css"


class IngredientDialog extends Component {
  render() {
    const { isInBottoSheet, ingredient, ingredientId, ...rest } = this.props;

    return isInBottoSheet ? (
        <BottomSheet 
          header={ingredient && (
            <div className="IngredientBottomSheet-header">
                <div className="IngredientBottomSheet-headerTitle">{ingredient.title}</div>
                <div className="IngredientBottomSheet-headerClose">
                <ButtonClose onClick={rest.onClose} />
                </div>
          </div>
          )} 
          opened={rest.show} 
          fullOppened 
          closeable 
          scrollable
          withBorder
          {...rest}>
             {this.renderContent("IngredientBottomSheet-content")}
        </BottomSheet>
    ) :
    
    (
        <Dialog expanded closeable {...rest} title={ingredient && ingredient.title}>
            {this.renderContent("IngredientDialog")}
        </Dialog>
    );
  }

  renderContent = (className) => {
    const { ingredient, ingredientId } = this.props;

    return Boolean(ingredientId) ? (
        <div className={className}>
            <IngredientDetails ingredient={ingredient} />
        </div>
        ) : null;
        // (
        //     <div className="IngredientDialog-loader" 
        //     style={{ 
        //         position: 'relative', 
        //         paddingTop: '64px', 
        //         width: "220px" }} >
        //         <Loader size="small" secondary />
        //     </div>
        // )
  }
}

export default IngredientDialog;
