function initItemRequest(slug, isIndexGroup) {
  return function getItemRequest(parameters = null) {
    const path = `/product-groups${isIndexGroup ? '-index' : ''}/${slug.replace('_index', '')}`;

    return {
      method: 'GET',
      path,
      query: parameters
    };
  };
}

export default function initProductGroupsRequest(createRequest) {
  return function _getyProductGroupsRequest(slug, isIndexGroup) {
    return {
      item: createRequest(initItemRequest(slug, isIndexGroup))
    };
  };
}
