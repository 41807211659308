import React, { Component, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getProductLink } from 'routes/links';

import isFunction from 'modules/utils/is-function';
import textSlicer from 'modules/utils/text-slicer';

import IngredientTitlesContainer from 'modules/ingredient/containers/ingredient-titles';
import MainTabs from 'modules/core/components/main-tabs/main-tabs';
import Link from 'modules/core/components/link/short-link';

class Tabs extends Component {
  render() {
    const { productDetails, product } = this.props;
    const { about, usage, consistency } = productDetails;

    return (
      <div className="ProductOverview-tabs">
        <MainTabs>
          <MainTabs.Tab panel={this.renderProductDescription(textSlicer(about, 350))}>
            О продукте
          </MainTabs.Tab>
          {usage && (
            <MainTabs.Tab
              panel={
                <Fragment>
                  <div className="ProductOverview-usage">
                    {textSlicer(usage.description, 350)}
                    <Link
                      title="Перейти  на страницу продукта"
                      to={{
                        pathname: getProductLink(product),
                        hash: '#usage'
                      }}
                      component={RouterLink}
                      className="ProductOverview-link"
                      onClick={this.handleReadMoreClick}
                    >
                      Читать далее
                    </Link>
                  </div>
                </Fragment>
              }
            >
              Применение
            </MainTabs.Tab>
          )}
          {consistency && <MainTabs.Tab panel={this.renderIngredients()}>Состав</MainTabs.Tab>}
        </MainTabs>
      </div>
    );
  }

  renderProductDescription(productDescription) {
    const { product } = this.props;
    return (
      <Fragment>
        <div className="ProductOverview-description">
          <span dangerouslySetInnerHTML={{ __html: productDescription }} />
          <Link
            title="Перейти  на страницу продукта"
            to={{
              pathname: getProductLink(product),
              hash: '#about'
            }}
            component={RouterLink}
            className="ProductOverview-link"
            onClick={this.handleReadMoreClick}
          >
            Читать далее
          </Link>
        </div>
      </Fragment>
    );
  }

  renderIngredients() {
    const { consistency } = this.props.productDetails;
    const { product } = this.props;
    return (
      <ul className="ProductOverview-ingredientsTypes">
        <li className="ProductOverview-ingredientsType">
          <div className="ProductOverview-mainIngredientsList">
            <IngredientTitlesContainer ids={consistency.primary} />
          </div>
        </li>
        {consistency.rawText && (
          <li className="ProductOverview-ingredientsType">
            <div className="ProductOverview-ingredientsList">
              {textSlicer(consistency.rawText, 300)}
              <Link
                title="Перейти к составу на странице продукта"
                to={{
                  pathname: getProductLink(product),
                  hash: '#consistency'
                }}
                component={RouterLink}
                className="ProductOverview-link"
                onClick={this.handleReadMoreClick}
              >
                Перейти к составу
              </Link>
            </div>
          </li>
        )}
      </ul>
    );
  }

  handleReadMoreClick = () => {
    const { onReadMoreClick } = this.props;

    if (!isFunction(onReadMoreClick)) {
      return;
    }
    onReadMoreClick();
  };
}

export default Tabs;
