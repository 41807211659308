import React, { Component } from 'react';
import './notification.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Notification extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['error', 'info', 'success', 'primary', 'default']),
    children: PropTypes.any.isRequired
  };
  static defaultProps = {
    type: 'default'
  };
  render() {
    return (
      <div
        className={classNames('Notification', {
          'Notification--error': this.props.type === 'error',
          'Notification--info': this.props.type === 'info',
          'Notification--success': this.props.type === 'success',
          'Notification--primary': this.props.type === 'primary',
          'Notification--marginBottom': this.props.mb,
          'Notification--largeGap': this.props.largeGap
        })}
      >
        <div className="Notification-wrapper">{this.props.children}</div>
      </div>
    );
  }
}

export default Notification;
