import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { IconHeart } from '../icons';
import IconContainer from '../icon-container/icon-container';

import './button-heart.css';

const { oneOf, bool } = PropTypes;

const buttonToIconSize = {
  normal: 'small',
  small: 'xsmall'
};

class ButtonHeart extends Component {
  static propTypes = {
    /** Размер кнопки */
    size: oneOf(['normal', 'small']),

    isActive: bool
  };

  static defaultProps = {
    size: 'normal',
    isActive: false
  };

  render() {
    const { size, isActive, ...rest } = this.props;
    return (
      <button
        className={cn('ButtonHeart', `ButtonHeart--size-${size}`, {
          'ButtonHeart--isActive': isActive
        })}
        {...rest}
      >
        <IconContainer size={buttonToIconSize[size]}>
          <IconHeart active={isActive} />
        </IconContainer>
      </button>
    );
  }
}

export default ButtonHeart;
