import React from 'react';
import cn from 'classnames';

import './scroll-shadow-element.css';

function Element({ position, active }) {
  return (
    <div
      className={cn('ScrollShadow-element', `ScrollShadow-element--${position}`, {
        'ScrollShadow-element--active': active
      })}
    />
  );
}

export default Element;

export function Top({ active }) {
  // console.log('​Top -> active', active);
  return <Element position="top" active={active} />;
}

export function Bottom({ active }) {
  // console.log('​Bottom -> active', active);
  return <Element position="bottom" active={active} />;
}
