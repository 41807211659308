import { createSelector } from 'reselect';
import { createReducer, handle } from 'modules/utils/dux';
import { BASKET_PREVIEW_OPEN, BASKET_PREVIEW_WAS_CLOSE } from './types';

const reducer = createReducer(
  handle(BASKET_PREVIEW_OPEN, state => ({
    ...state,
    isBasketPreviewOpen: true
  })),

  handle(BASKET_PREVIEW_WAS_CLOSE, state => ({
    ...state,
    isBasketPreviewClosed: true,
    isBasketPreviewOpen: false
  }))
);

export default reducer({
  isBasketPreviewOpen: false,
  isBasketPreviewClosed: false
});

const getBasketPreviewFlags = state => state.ordering.basketPreview;

export const getIsBasketPreviewOpen = createSelector(
  getBasketPreviewFlags,
  function _getIsBasketPreviewOpen(basketPreviewFlags) {
    return basketPreviewFlags.isBasketPreviewOpen;
  }
);

export const getIsBasketPreviewClosed = createSelector(
  getBasketPreviewFlags,
  function _getIsBasketPreviewClosed(basketPreviewFlags) {
    return basketPreviewFlags.isBasketPreviewClosed;
  }
);
