import React, { Component } from 'react';
import './icon-hand-basket.css';

class IconHandbasket extends Component {
  render() {
    return (
      <svg
        className="IconHandbasket"
        width="16"
        height="15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.93 7.825v6.25M3.983 7.496l1.645 6.58M11.878 7.496l-1.645 6.58"
          stroke="#fff"
          stroke-width="1.2"
        />
        <path
          d="M4.316 3.183l.358.481-.358-.481zm7.244 0l.358-.481-.358.481zM8.992 1.367l-.335.498.335-.498zm3.869 11.335l.567.195-.567-.195zm-9.846 0l-.567.195.567-.195zM1.154 7.304L.586 7.5l.568-.196zM.586 7.5l1.862 5.397 1.134-.391-1.861-5.397-1.135.39zm3.371-4.798c-.965.718-1.86 1.408-2.522 1.922l.737.947a154.15 154.15 0 0 1 2.502-1.907l-.717-.962zM6.55.869a66.62 66.62 0 0 0-2.592 1.833l.717.962a65.414 65.414 0 0 1 2.544-1.799L6.548.87zm2.108.996c.659.443 1.567 1.07 2.544 1.8l.717-.963A66.605 66.605 0 0 0 9.327.869l-.67.996zm2.544 1.8c.957.711 1.846 1.395 2.503 1.906l.736-.947a155.262 155.262 0 0 0-2.522-1.922l-.716.962zm2.954 3.444l-1.861 5.397 1.134.391L15.289 7.5l-1.134-.391zm-3.185 6.34H8.774v1.2h2.196v-1.2zm-2.196 0h-.836v1.2h.836v-1.2zm-1.672 1.2h.836v-1.2h-.836v1.2zm-2.196 0h2.196v-1.2H4.906v1.2zM7.218 1.866a1.285 1.285 0 0 1 1.44 0L9.326.87a2.485 2.485 0 0 0-2.778 0l.67.996zm5.075 10.641a1.4 1.4 0 0 1-1.323.944v1.2a2.6 2.6 0 0 0 2.458-1.753l-1.135-.391zm-9.845.391a2.6 2.6 0 0 0 2.458 1.753v-1.2a1.4 1.4 0 0 1-1.324-.944l-1.134.391zm11.256-7.326c.463.36.644.977.45 1.538l1.135.39a2.576 2.576 0 0 0-.848-2.875l-.737.947zM1.72 7.109a1.376 1.376 0 0 1 .45-1.538l-.736-.947A2.576 2.576 0 0 0 .586 7.5l1.135-.391z"
          fill="#fff"
        />
        <path d="M1.022 6.18c2.413 1.536 9.21 2.632 13.816 0" stroke="#fff" stroke-width="1.2" />
      </svg>
    );
  }
}

export default IconHandbasket;
