import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import isFunction from 'modules/utils/is-function';
// import TextWithTooltip from 'modules/core/components/text-with-tooltip/text-with-tooltip';
import MainTabs from 'modules/core/components/main-tabs/main-tabs';
import DashboardGrid from 'modules/core/components/dashboard-grid/dashboard-grid';

import GeneralTileContainer from '../../containers/general-tile';
import GeneralForm from '../general-form/general-form';
import QuestionnaireContainer from '../../containers/questionnaire';

import MobileProfileEditor from '../../containers/mobile-profile-editor';
// import { FACE_TRAITS, HAIR_TRAITS, BODY_TRAITS } from './traits/index';
import { conformToMask } from 'react-text-mask';

import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';
import sliceUnusedParenthesis from 'modules/core/components/input-phone-with-area-code/helpers/slice-unused-parenthesis';

import './general-scene.css';

const areaCodes = [
  { id: 0, title: 'Россия, Казахстан', value: '+7', name: 'rus' },
  { id: 1, title: 'Беларусь', value: '+375', name: 'bel' }
];

const phoneMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

// const EXTENDER = '0';

const { func, shape, string, number, any } = PropTypes;

// const allTraits = [FACE_TRAITS, HAIR_TRAITS, BODY_TRAITS];

class GeneralScene extends Component {
  static propTypes = {
    profile: shape({
      id: number,
      name: string,
      email: string,
      phone: string,
      birthDate: string,
      photo: string,
      completeness: number
    }),
    delivery: any,
    onChangeName: func,
    onChangePhoto: func,
    onPhoneChange: func,
    onDateChange: func,
    onDeliveryAddressChange: func,
    onAddressSave: func
  };

  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return (
      <div className="Profile_GeneralScene">
        <Media query="(max-width: 840px)">
          {matches =>
            matches ? (
              <div className="Profile_GeneralScene-mobile">
                <div className="Profile_GeneralScene-mobileGeneral">
                  <GeneralTileContainer />
                </div>
                <div className="Profile_GeneralScene-mobileForm">
                  {this.renderGeneralMobileForm()}
                </div>
              </div>
            ) : (
              <MainTabs currentTab={0}>
                <MainTabs.Tab panel={this.renderPersonalData()}>
                  {/* <TextWithTooltip tooltipContent=""> */}
                  Личные данные
                  {/* </TextWithTooltip> */}
                </MainTabs.Tab>
                <MainTabs.Tab panel={<QuestionnaireContainer />}>
                  {/* FIX: при одном табе ошибка */}
                  {/* <TextWithTooltip tooltipContent=""> */}
                  Физиологические данные
                  {/* </TextWithTooltip> */}
                </MainTabs.Tab>
              </MainTabs>
            )
          }
        </Media>
      </div>
    );
  }

  renderGeneralMobileForm() {
    const { isPhoneEditable, onCallbackDialogOpen } = this.props;

    return (
      <MobileProfileEditor
        isPhoneEditable={isPhoneEditable}
        onCallbackDialogOpen={onCallbackDialogOpen}
      />
    );
  }

  renderPersonalData() {
    return (
      <div className="Profile_GeneralScene-panel">
        <DashboardGrid>
          <DashboardGrid.StrictRow>
            <DashboardGrid.Cell>
              <GeneralTileContainer />
            </DashboardGrid.Cell>
          </DashboardGrid.StrictRow>
          <DashboardGrid.StrictRow>
            <DashboardGrid.Cell>{this.renderGeneralForm()}</DashboardGrid.Cell>
          </DashboardGrid.StrictRow>
        </DashboardGrid>
      </div>
    );
  }

  renderGeneralForm() {
    const {
      profile,
      delivery,
      currentCity,
      suggestedCity,
      isPhoneEditable,
      openCityDialog,
      onCallbackDialogOpen,
      onPhoneStateChange,
      phoneError,
      onPhoneStateReset
    } = this.props;

    const phone = this.getFinalPhone(profile.phone);

    return (
      <GeneralForm
        size="small"
        phone={phone}
        delivery={delivery}
        currentCity={currentCity}
        suggestedCity={suggestedCity}
        birthDate={profile.birthDate}
        isPhoneEditable={isPhoneEditable}
        onPhoneChange={this.handlePhoneChange}
        onPhoneStateChange={onPhoneStateChange}
        onPhoneStateReset={onPhoneStateReset}
        phoneError={phoneError}
        onDateChange={this.handleDateChange}
        // onDeliveryAddressChange={this.handleDeliveryAddressChange}
        // onAddressSave={this.handleAddressSave}
        onAddressChange={this.handleAddressChange}
        onCallbackDialogOpen={onCallbackDialogOpen}
        openCityDialog={openCityDialog}
      />
    );
  }

  getFinalPhone(phone) {
    for (let i = 0; i < areaCodes.length; i++) {
      const code = areaCodes[i];

      if (phone === code.value) return '';
    }

    return phone;
  }

  getMaskedPhone(value) {
    const maskedPhone = conformToMask(value, phoneMask, {
      guide: false
    }).conformedValue.trim();

    const cleanedPhone = sliceUnusedParenthesis(maskedPhone);

    return cleanedPhone;
  }

  handlePhoneChange = phone => {
    const { onPhoneChange } = this.props;

    if (!isFunction(onPhoneChange)) {
      return;
    }

    const separatedPhone = getSeparatedPhone(phone, areaCodes);

    const rawPhone = separatedPhone.phone;

    if (!rawPhone) {
      onPhoneChange('');
      return;
    }

    // if (rawPhone.length < 10) {
    //   const supplementedPhone = rawPhone + EXTENDER.repeat(10 - rawPhone.length);
    //   const maskedPhone = this.getMaskedPhone(supplementedPhone);

    //   const finishedPhone = separatedPhone.code.value + ' ' + maskedPhone;

    //   onPhoneChange(finishedPhone);

    //   return;
    // }

    onPhoneChange(phone);
  };

  handleDateChange = date => {
    const { onDateChange } = this.props;

    if (!isFunction(onDateChange)) {
      return;
    }
    onDateChange(date);
  };

  // handleDeliveryAddressChange = address => {
  //   const { onDeliveryAddressChange } = this.props;

  //   if (!isFunction(onDeliveryAddressChange)) {
  //     return;
  //   }
  //   onDeliveryAddressChange(address);
  // };

  // handleAddressSave = address => {
  //   const { onAddressSave } = this.props;

  //   if (!isFunction(onAddressSave)) {
  //     return;
  //   }
  //   onAddressSave(address);
  // };

  handleAddressChange = address => {
    const { onAddressChange } = this.props;

    if (!isFunction(onAddressChange)) {
      return;
    }
    onAddressChange(address);
  };

  handleChangeName = name => {
    const { onChangeName } = this.props;

    if (!isFunction(onChangeName)) {
      return;
    }
    onChangeName(name);
  };

  handleChangePhoto = photo => {
    const { onChangePhoto } = this.props;

    if (!isFunction(onChangePhoto)) {
      return;
    }
    onChangePhoto(photo);
  };
}

export default GeneralScene;
