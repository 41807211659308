import { mixitApi } from 'services/mixit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { getError404Link, getCategoryLink } from 'routes/links';
import * as fromCategories from '../ducks/categories';
import { actions as Categories } from '../ducks/categories';
import * as CATEGORIES from '../ducks/types/categories';
import * as fromSortOptions from '../ducks/sort-options';
import { serializeSortType, serializeSortOrder } from '../ducks/constants/sort-options';

export function* categoryWatcher() {
  yield takeEvery(CATEGORIES.SHOW_ITEM, showItem);
  yield takeEvery(CATEGORIES.LOAD_CATEGORY, fetchCategory);
  yield takeEvery(CATEGORIES.PRODUCTS_LOAD, loadProducts);
  yield takeEvery(CATEGORIES.LOAD_MORE, loadMore);
  yield takeEvery(CATEGORIES.SET_FILTER, setFilter);
  yield takeEvery(CATEGORIES.RESET_FILTER, resetFilter);
  yield takeEvery(CATEGORIES.SORT, sort);
  yield takeEvery(CATEGORIES.CHANGE_NESTED, changeNestedCategory);
}

function* showItem(action) {
  const { slug, nestedSlug, filters } = action.payload;
  const productsCategorySlug = nestedSlug || slug;
  const parent = nestedSlug ? slug : null;

  const isCategoryLoaded = yield select(fromCategories.makeGetIsItemLoaded(slug));
  const isNestedCategoryLoaded = yield select(fromCategories.makeGetIsItemLoaded(nestedSlug));
  const isProductsLoaded = yield select(fromCategories.getIsProductsLoaded, {
    slug: productsCategorySlug
  });

  const sortId = yield select(fromCategories.getSortBySlug, { slug });
  const sortOption = yield select(fromSortOptions.getItem, sortId);

  const sortType = serializeSortType(sortOption.name);
  const sortOrder = serializeSortOrder(sortOption.order);

  if (!isCategoryLoaded) {
    yield put(Categories.loadCategory(slug));
  }

  if (nestedSlug && !isNestedCategoryLoaded) {
    yield put(Categories.loadCategory(nestedSlug, slug));
  }

  if (!isProductsLoaded) {
    yield put(
      Categories.loadProducts(productsCategorySlug, 0, filters, parent, {
        sortType,
        sortOrder
      })
    );
  }
}

function* fetchCategory(action) {
  const { slug, parent } = action.payload;
  const categoryApi = mixitApi().categories(slug);

  try {
    const categoryResponse = yield call(categoryApi.item, { parent });

    const category = categoryResponse.data;

    yield put(Categories.addItem(slug, category));
  } catch (e) {
    console.error(e);
    yield put(replace(getError404Link()));
  }
}

function* loadMore({ payload }) {
  const { slug, amount, filters, parent } = payload;
  const categoryApi = mixitApi().categories(slug);

  const sortId = yield select(fromCategories.getSortBySlug, { slug });
  const sortOption = yield select(fromSortOptions.getItem, sortId);

  const sortType = serializeSortType(sortOption.name);
  const sortOrder = serializeSortOrder(sortOption.order);

  const productsResponse = yield call(categoryApi.products, {
    skip: amount,
    count: 24,
    filter: filters,
    parent,
    sort: sortType,
    order: sortOrder
  });

  const { products, total_amount: totalAmount } = productsResponse.data;

  yield put(Categories.addProducts(slug, products, totalAmount, amount));
}

function* loadProducts({ payload }) {
  const { slug, amount, filters, parent, sort } = payload;

  try {
    const { products, total_amount: totalAmount } = yield call(
      loadProductRequest,
      slug,
      amount,
      filters,
      parent,
      sort
    );

    yield put(Categories.addProducts(slug, products, totalAmount));
  } catch (e) {
    console.error(e);
  }
}

function* loadProductRequest(slug, amount, filter, parent, { sortType, sortOrder } = {}) {
  const categoryApi = mixitApi().categories(slug);

  const productsResponse = yield call(categoryApi.products, {
    skip: amount,
    parent,
    count: 24,
    filter,
    sort: sortType,
    order: sortOrder
  });

  return productsResponse.data;
}

function* setFilter({ payload }) {
  const { slug, nestedSlug, appliedFilters } = payload;
  const productsCategorySlug = nestedSlug || slug;
  const parent = nestedSlug ? slug : null;

  const sortId = yield select(fromCategories.getSortBySlug, { slug });
  const sortOption = yield select(fromSortOptions.getItem, sortId);

  const sortType = serializeSortType(sortOption.name);
  const sortOrder = serializeSortOrder(sortOption.order);

  yield updateUrl(slug, nestedSlug, appliedFilters);

  yield put(
    Categories.loadProducts(productsCategorySlug, 0, appliedFilters, parent, {
      sortType,
      sortOrder
    })
  );
}

function* sort({ payload }) {
  const { slug, nestedSlug, appliedFilters } = payload;
  const productsCategorySlug = nestedSlug || slug;
  const parent = nestedSlug ? slug : null;

  const sortId = yield select(fromCategories.getSortBySlug, {
    slug: productsCategorySlug
  });
  const sortOption = yield select(fromSortOptions.getItem, sortId);

  const sortType = serializeSortType(sortOption.name);
  const sortOrder = serializeSortOrder(sortOption.order);

  yield put(
    Categories.loadProducts(productsCategorySlug, 0, appliedFilters, parent, {
      sortType,
      sortOrder
    })
  );
}

function* resetFilter({ payload }) {
  const { slug, nestedSlug } = payload;
  const productsCategorySlug = nestedSlug || slug;
  const parent = nestedSlug ? slug : null;

  const sortId = yield select(fromCategories.getSortBySlug, { slug });
  const sortOption = yield select(fromSortOptions.getItem, sortId);

  const sortType = serializeSortType(sortOption.name);
  const sortOrder = serializeSortOrder(sortOption.order);

  yield updateUrl(slug, nestedSlug);
  yield put(
    Categories.loadProducts(productsCategorySlug, 0, [], parent, {
      sortType,
      sortOrder
    })
  );
}

function* changeNestedCategory(action) {
  const { ctegorySlug, nestedSlug, appliedFilters } = action.payload;

  yield updateUrl(ctegorySlug, nestedSlug, appliedFilters);
}

function* updateUrl(categorySlug, nestedSlug = null, appliedFilters = null) {
  const category = {
    slug: categorySlug
  };

  const nested = {
    slug: nestedSlug
  };

  const properties = {
    filter: appliedFilters
  };

  yield put(push(getCategoryLink(category, nested, properties)));
}
