import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromSearch from '../ducks/search';
import { actions as SearchAction } from '../ducks/search';

import SearchResults from '../components/search-results/search-results';

const getQuery = (_, props) => props.query;

const mapState = createStructuredSelector({
  isLoaded: fromSearch.getIsLoaded,
  filters: fromSearch.makeGetFilterListByQuery(getQuery),
  categories: fromSearch.makeGetCategoriesByQuery(getQuery),
  appliedFilters: fromSearch.getAppliedFilters,
  filteredAmount: () => {},
  sortTypes: () => {}
});

const mapDispatch = {
  searchRequest: SearchAction.request,
  filterRequest: SearchAction.filterRequest
};

const SORT_TYPES = [
  {
    id: 1,
    active: false,
    name: 'SORT_TYPE/BY_POPULARITY',
    title: 'По популярности'
  },
  {
    id: 2,
    active: false,
    name: 'SORT_TYPE/BY_STOCK',
    title: 'Акционные'
  },
  {
    id: 3,
    active: true,
    name: 'SORT_TYPE/BY_NOVELTY',
    title: 'По новизне'
  },
  {
    id: 4,
    active: false,
    name: 'SORT_TYPE/FROM_CHEAP_TO_EXPENSIVE',
    title: 'От дорогих к дешевым'
  },
  {
    id: 5,
    active: false,
    name: 'SORT_TYPE/FROM_EXPENSIVE_TO_CHEAP',
    title: 'От дешевых к дорогим'
  }
];

class SearchResultsContainer extends Component {
  render() {
    const { isLoaded, query, filters, appliedFilters } = this.props;

    return (
      <SearchResults
        isLoaded={isLoaded}
        filters={filters}
        appliedFilters={appliedFilters}
        sortTypes={SORT_TYPES}
        query={query}
        onFilterRemove={this.handleFilterRemove}
        onFiltersReset={this.handleFiltersReset}
        onFilterChange={this.hadnleFilterChange}
      />
    );
  }

  componentDidMount() {
    const { query, appliedFilters } = this.props;
    this.props.searchRequest(query, { filters: appliedFilters });
  }

  handleFilterRemove = removedSlug => {
    const { query, appliedFilters } = this.props;
    const options = appliedFilters.filter(slug => slug !== removedSlug);

    this.props.filterRequest(query, options);
  };

  handleFiltersReset = () => {
    const { query } = this.props;
    this.props.filterRequest(query, []);
  };

  hadnleFilterChange = filterChanges => {
    const { query } = this.props;
    this.props.filterRequest(query, filterChanges.options);
  };
}

export default connect(
  mapState,
  mapDispatch
)(SearchResultsContainer);
