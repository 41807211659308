import PropTypes from 'prop-types';
import mediaShape from 'modules/product/prop-types/media-shape';

const { shape, number, string } = PropTypes;

export default shape({
  id: number,
  title: string,
  slug: string,
  publicationDate: string,
  description: string,
  content: string,
  cover: mediaShape
});
