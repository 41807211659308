import React, { Component } from 'react';
import Button from 'modules/core/components/button/button';

class ButtonAddToWaitingList extends Component {
  render() {
    return (
      <Button
        title="Сообщить о появлении"
        variant="primary"
        size="small"
        expanded
        {...this.props}
      />
    );
  }
}

export default ButtonAddToWaitingList;
