import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import './consistency.css';
import ConsistencyLegend from '../consistency-legend/consistency-legend';
import ConsistencyContent from '../consistency-content/consistency-content';

const { string, number, shape, arrayOf } = PropTypes;

class Consistency extends Component {
  static propTypes = {
    list: arrayOf(
      shape({
        id: number.isRequired,
        title: string.isRequired,
        name: string.isRequired,
        color: string,
        list: arrayOf(number).isRequired
      })
    )
  };

  render() {
    const { list } = this.props;
    return (
      <div className="Consistency">
        <Media
          query="(min-width: 841px)"
          render={() => <h3 className="Consistency-title">Состав</h3>}
        />

        <div className="Consistency-ingredientLists">
          <ConsistencyContent list={list} />
        </div>
        <div className="Consistency-legend">
          <ConsistencyLegend list={list} />
        </div>
      </div>
    );
  }
}

export default Consistency;
