import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-search.css';

class IconSearch extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
        return (
          <Svg viewBox="0 0 24 24" className="IconSearch">
            <path d="M19.9 19.1l-3.1-3c1.1-1.3 1.8-3 1.8-4.8 0-4-3.3-7.3-7.3-7.3S4 7.3 4 11.3s3.3 7.3 7.3 7.3c1.8 0 3.5-.7 4.8-1.8l3.1 3c.1.1.2.1.4.1.1 0 .3 0 .4-.1.1-.1 0-.5-.1-.7zM5 11.3C5 7.8 7.8 5 11.3 5s6.3 2.8 6.3 6.3-2.8 6.3-6.3 6.3S5 14.8 5 11.3z" />
          </Svg>
        );
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconSearch">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.9422 8.95146C15.3618 8.95146 13.8168 9.4201 12.5028 10.2981C11.1887 11.1762 10.1645 12.4241 9.55972 13.8843C8.95492 15.3444 8.79668 16.951 9.105 18.5011C9.41332 20.0511 10.1744 21.4749 11.2919 22.5924C12.4094 23.71 13.8332 24.471 15.3833 24.7793C16.9333 25.0877 18.54 24.9294 20.0001 24.3246C20.9589 23.9275 21.8262 23.3495 22.5574 22.6273C22.5621 22.6221 22.5669 22.6171 22.5718 22.6121C22.5785 22.6054 22.5853 22.5989 22.5923 22.5926C22.9607 22.2243 23.2939 21.819 23.5862 21.3816C24.4642 20.0675 24.9329 18.5226 24.9329 16.9422C24.9329 14.8229 24.091 12.7904 22.5925 11.2919C21.0939 9.79333 19.0614 8.95146 16.9422 8.95146ZM23.5764 22.938C23.8668 22.6166 24.1347 22.2733 24.3773 21.9102C25.3599 20.4396 25.8843 18.7108 25.8843 16.9422C25.8843 14.5706 24.9422 12.2961 23.2652 10.6191C21.5883 8.94212 19.3138 8 16.9422 8C15.1736 8 13.4447 8.52445 11.9742 9.50703C10.5036 10.4896 9.3575 11.8862 8.68069 13.5201C8.00387 15.1541 7.82679 16.9521 8.17183 18.6867C8.51686 20.4213 9.36852 22.0146 10.6191 23.2652C11.8697 24.5158 13.463 25.3675 15.1976 25.7125C16.9322 26.0575 18.7302 25.8805 20.3642 25.2036C21.2987 24.8166 22.1556 24.2759 22.9018 23.6089L26.7894 27.4621C26.976 27.6471 27.2772 27.6458 27.4622 27.4592C27.6471 27.2726 27.6458 26.9713 27.4592 26.7864L23.5764 22.938Z"
            />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconSearch;
