import React, { Component } from 'react';
import './icon-show-on-map.css';

class IconShowOnMap extends Component {
  render() {
    return (
      <svg className="IconShowOnMap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
        <g fillRule="evenodd" clipRule="evenodd">
          <path d="M8.92 4.79a.5.5 0 0 1 .46-.06l5.6 2.2c.2.08.32.26.32.47v12.4a.5.5 0 0 1-.68.47l-5.6-2.2a.5.5 0 0 1-.32-.47V5.2a.5.5 0 0 1 .22-.41zm.78 1.14v11.33l4.6 1.8V7.75l-4.6-1.8z" />
          <path d="M20.78 4.79c.14.1.22.25.22.41v12.4c0 .2-.13.4-.32.47l-5.7 2.2a.5.5 0 0 1-.36-.94L20 17.26V5.93l-5.02 1.94a.5.5 0 1 1-.36-.94l5.7-2.2a.5.5 0 0 1 .46.06zm-11.11.23a.5.5 0 0 1-.29.65L4 7.74v11.33l5.02-1.94a.5.5 0 0 1 .36.94l-5.7 2.2A.5.5 0 0 1 3 19.8V7.4c0-.2.13-.4.32-.47l5.7-2.2a.5.5 0 0 1 .65.29z" />
        </g>
      </svg>
    );
  }
}

export default IconShowOnMap;
