import React, { Component } from 'react';

import AnimatedRouterLink from '../../animated-link/animated-router-link';
import AnimatedLink from '../../animated-link/animated-link';

import './global-navigation.css';

class GlobalNavigation extends Component {
  render() {
    return (
      <ul className="GlobalNavigation">
        <li className="GlobalNavigation-item">
          <AnimatedRouterLink href="/shops" title="Магазины" />
        </li>
        <li className="GlobalNavigation-item">
          <AnimatedRouterLink href="/payment-and-delivery" title="Доставка и оплата" />
        </li>
        <li className="GlobalNavigation-item">
          <AnimatedLink href="http://franchise.mixit.ru/" title="Франшиза" target="_blank" />
        </li>
        <li className="GlobalNavigation-item">
          <AnimatedRouterLink href="/contacts" title="Контакты" />
        </li>
      </ul>
    );
  }
}

export default GlobalNavigation;
