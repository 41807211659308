import React, { Component } from 'react';

import CategoryPanel from '../../category-panel/category-panel';

import FieldsetChoice from 'modules/form/components/fieldset-choice/fieldset-choice';
import Radio from 'modules/form/components/radio/radio';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import './filter-item.css';

class FilterItem extends Component {
  render() {
    const { filter, count, withoutAmount, onChange, ...rest } = this.props;

    return (
      <CategoryPanel.Item
        title={filter.title}
        count={count > 0 && count}
        content={
          <div className="CategoryFilterPanel_FilterItem">
            <FieldsetChoice>
              {filter.options.map(option => {
                const Selectable = filter.multiple ? Checkbox : Radio;

                const label = (
                  <span className="CategoryFilterPanel_FilterItem-option">
                    <span className="CategoryFilterPanel_FilterItem-optionTitle">
                      {option.title}
                    </span>

                    {!withoutAmount && option.amount && typeof option.amount === 'number' && (
                      <span className="CategoryFilterPanel_FilterItem-optionAmount">
                        ({option.amount})
                      </span>
                    )}
                  </span>
                );

                return (
                  <FieldsetChoice.Item key={option.id}>
                    <Selectable
                      name={`CATEGORY_FILTER/${filter.name}`}
                      value={option.slug}
                      checked={option.checked}
                      onChange={onChange}
                      label={label}
                    />
                  </FieldsetChoice.Item>
                );
              })}
            </FieldsetChoice>
          </div>
        }
        {...rest}
      />
    );
  }
}

export default FilterItem;
