import React, { Component } from 'react';
import classNames from 'classnames';

class Tab extends Component {
  render() {
    const { children, isActive, buttonRef, ...rest } = this.props;
    return (
      <button
        type="button"
        className={classNames('MainTabs-tab', {
          'MainTabs-tab--isActive': isActive
        })}
        role="tab"
        ref={buttonRef}
        {...rest}
      >
        {children}
      </button>
    );
  }
}

export default Tab;
