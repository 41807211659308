import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import isFunction from 'modules/utils/is-function';
import Radio from 'modules/form/components/radio/radio';
import Checkbox from 'modules/form/components/checkbox/checkbox';
import Button from 'modules/core/components/button/button';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';

import Fieldset from './fieldset';

import './questionnaire.css';

const { arrayOf, func, string, shape, bool } = PropTypes;

class Questionnaire extends Component {
  state = {
    isTouched: false
  };

  static propTypes = {
    title: string,
    description: string,
    options: arrayOf(
      shape({
        slug: string,
        title: string,
        values: arrayOf(
          shape({
            name: string,
            title: string,
            checked: bool
          })
        )
      })
    ),
    errorMessage: string,
    onSubmit: func
  };

  render() {
    const { allOptions, isLoading, errorMessage, checkedValues } = this.props;

    const { isTouched } = this.state;

    const showErrorMessage = errorMessage && !isTouched;
    const canBeEdit = isTouched || errorMessage;

    const initialValues = checkedValues.reduce(
      (all, currentValue) => ({
        ...all,
        [currentValue.slug]: currentValue.value
      }),
      {}
    );

    return (
      <Fragment>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={({ setFieldValue }) => {
            return (
              <Form>
                <div className="Questionnaire">
                  {showErrorMessage && <div className="Questionnaire-error">{errorMessage}</div>}
                  {allOptions.map((category, index) => (
                    <div key={index} className="Questionnaire-category">
                      <div className="Questionnaire-title">{category.title}</div>
                      <div className="Questionnaire-description">{category.description}</div>

                      <ul className="Questionnaire-list">
                        {category.options.map(option => (
                          <li key={option.slug} id={option.slug} className="Questionnaire-option">
                            {this.renderFieldset(option, setFieldValue)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {canBeEdit && (
                  <div className="Questionnaire-submit">
                    <Button
                      title="Сохранить"
                      variant="primary"
                      size="small"
                      type="submit"
                      disabled={isLoading}
                      iconAppend={isLoading && <IconFlatLoader />}
                    />
                  </div>
                )}
              </Form>
            );
          }}
        />
      </Fragment>
    );
  }

  renderFieldset = (option, setFieldValue) => {
    return (
      <Fieldset title={option.title}>
        <ul className="Questionnaire-optionChoice">
          {option.values.map(variant => this.renderOption(option, variant, setFieldValue))}
        </ul>
      </Fieldset>
    );
  };

  renderOption = (option, variant, setFieldValue) => {
    const { isLoading } = this.props;
    return (
      <li key={variant.name} className="Questionnaire-optionChoiceVariant">
        <Field
          name={option.slug}
          key={variant.name}
          render={({ field }) => {
            return (
              <Fragment>
                {Array.isArray(field.value) ? (
                  <Checkbox
                    label={this.getLabel(variant.title)}
                    name={option.slug}
                    value={variant.name}
                    disabled={isLoading}
                    checked={field.value.indexOf(variant.name) !== -1}
                    onChange={this.handleCheckboxChange(setFieldValue, field.value)}
                  />
                ) : (
                  <Radio
                    label={this.getLabel(variant.title)}
                    name={option.slug}
                    value={variant.name}
                    disabled={isLoading}
                    checked={variant.name === field.value}
                    onChange={this.handleRadioChange(field.onChange)}
                  />
                )}
              </Fragment>
            );
          }}
        />
      </li>
    );
  };

  setTouched() {
    this.setState({
      isTouched: true
    });
  }

  getLabel(value) {
    return <span className="Questionnaire-optionChoiceVariantLabel">{value}</span>;
  }

  handleSubmit = data => {
    const { onSubmit } = this.props;

    if (!isFunction(onSubmit)) {
      return;
    }
    const preparedData = this.getPreparedData(data);

    onSubmit(preparedData);
  };

  handleRadioChange = onChange => e => {
    this.setTouched();

    onChange(e);
  };

  handleCheckboxChange = (setFieldValue, value) => e => {
    this.setTouched();

    const target = e.currentTarget;

    if (target.checked) {
      value.push(target.value);
    } else {
      value.splice(value.indexOf(target.value), 1);
    }

    setFieldValue(target.name, value);
  };

  getPreparedData(data) {
    const dataList = Object.keys(data)
      .filter(key => data[key])
      .map(key => ({ slug: key, value: data[key] }));

    return dataList;
  }
}

export default Questionnaire;
