import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from './dashboard-row';
import StrictRow from './dashboard-strict-row';
import Cell from './dashboard-cell';
import StrictCell from './dashboard-strict-cell';
import './dashboard-grid.css';

const { element, oneOfType, arrayOf } = PropTypes;

export class DashboardGrid extends Component {
  static propTypes = {
    /** React элемент(ы) - рядки сетки  */
    children: oneOfType([element, arrayOf(element)])
  };

  static Row = Row;
  static StrictRow = StrictRow;
  static Cell = Cell;
  static StrictCell = StrictCell;

  render() {
    const { children } = this.props;
    return <div className="DashboardGrid">{children}</div>;
  }
}

export default DashboardGrid;
