import React, { Component } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ExploreArticleList from '../components/explore-article-list/explore-article-list';

import * as fromArticleList from '../ducks/article-list';

const mapStateToProps = createStructuredSelector({
  articleList: fromArticleList.getExploreArticleList
});

class ExploreArticleListContainer extends Component {
  static propTypes = {
    slug: string.isRequired
  };

  render() {
    return <ExploreArticleList {...this.props} />;
  }
}

export default connect(mapStateToProps)(ExploreArticleListContainer);
