import { createAction } from 'modules/utils/dux';
import * as TYPES from './types';

export function showDetails(id) {
  return createAction(TYPES.DETAILS_SHOW, { id });
}

export function addDetails(id, details) {
  return createAction(TYPES.DETAILS_ADD, { id, details });
}
