import React, { Component } from 'react';
import './icon-google-pay.css';

class IconGooglePay extends Component {
  static defaultProps = {
    opacity: 1
  };

  render() {
    const { opacity } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65 34"
        className="IconGooglePay"
        style={{ opacity: opacity }}
      >
        <path
          d="M30.9 16.7v7h-2.2V6.4h5.9c1.4 0 2.8.5 3.8 1.5 1 .9 1.6 2.2 1.6 3.7s-.6 2.7-1.6 3.7-2.3 1.5-3.8 1.5c0-.1-3.7-.1-3.7-.1zm0-8.2v6.1h3.8c.9 0 1.6-.3 2.2-.9 1.2-1.1 1.2-3 .1-4.2l-.1-.1c-.6-.6-1.4-.9-2.2-.9h-3.8zm14.2 3c1.6 0 3 .4 3.9 1.4 1 .9 1.4 2.1 1.4 3.6v7.4h-2.1v-1.6h-.1c-.9 1.4-2.1 2.1-3.7 2.1-1.3 0-2.4-.3-3.3-1.1-.9-.7-1.4-1.8-1.4-2.9 0-1.2.5-2.2 1.4-3 .9-.7 2.2-1.1 3.8-1.1 1.4 0 2.4.3 3.3.7v-.5c0-.8-.3-1.5-.9-2-.6-.5-1.4-.9-2.1-.9-1.3 0-2.2.5-3 1.5l-2.2-1.3c1.3-1.5 2.9-2.3 5-2.3zm-2.8 8.6c0 .6.3 1.1.7 1.5.5.3 1.1.6 1.8.6.9 0 1.8-.3 2.6-1 .7-.7 1.1-1.5 1.1-2.5-.7-.6-1.8-.9-3-.9-.9 0-1.7.2-2.2.6-.8.5-1 1.1-1 1.7zm20.4-8.2L55.2 29h-2.3l2.7-6-4.9-11.1h2.4l3.5 8.6h.1l3.4-8.6h2.6z"
          fill="#828da2"
        />
        <path
          d="M21.9 15.2c0-.7-.1-1.4-.2-2.1h-9.4V17h5.4c-.2 1.2-.9 2.3-2 3v2.5H19c1.8-1.7 2.9-4.2 2.9-7.3z"
          fill="#828da2"
        />
        <path
          d="M12.3 25c2.7 0 5-.9 6.6-2.4l-3.3-2.5c-.9.6-2.1 1-3.4 1-2.6 0-4.8-1.8-5.6-4.1H3.3v2.6c1.8 3.2 5.2 5.4 9 5.4z"
          fill="#828da2"
        />
        <path
          d="M6.7 16.8c-.4-1.2-.4-2.6 0-3.8v-2.5H3.4c-1.4 2.8-1.4 6.2 0 9l3.3-2.7z"
          fill="#828da2"
        />
        <path
          d="M12.3 8.9c1.4 0 2.8.5 3.8 1.5l2.8-2.8C17.1 5.9 14.8 5 12.3 5c-3.8 0-7.2 2.1-8.9 5.5l3.3 2.6c.8-2.4 2.9-4.2 5.6-4.2z"
          fill="#828da2"
        />
      </svg>
    );
  }
}

export default IconGooglePay;
