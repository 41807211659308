import { createSelector } from 'reselect';
import * as fromDialogs from 'modules/core/ducks/dialogs';

export const getIngredients = state => state.ingredient.ingredients;

const getIdFromProps = (_, { id }) => id;

export const getIngredientById = createSelector(
  getIdFromProps,
  getIngredients,
  fromDialogs.getIngredientId,
  (id, ingredients, dialogIngredientId) => {
    const _id = id || dialogIngredientId
    return ingredients[_id]
  }
);

const getIds = (_, { ids }) => ids;

export const getIngredientsByIds = createSelector(
  getIngredients,
  getIds,
  (ingredients, ids) => {
    if (!ingredients || !ids) {
      return null;
    }

    return ids.map(id => ingredients[id]);
  }
);

export const makeGetIngredientsByIds = createSelector(
  getIngredients,
  ingredients => {
    return ids => {
      if (!ingredients || !ids) {
        return null;
      }

      return ids.map(id => ingredients[id]);
    };
  }
);

export const getIngredientsWithDetails = createSelector(
  getIngredientById,
  ingredient => {
    if (!ingredient || !ingredient.details) {
      return null;
    }
    return {
      ...ingredient,
      details: ingredient.details
    };
  }
);

export const getDetailsById = createSelector(
  getIngredientById,
  function _getDetailsById(ingredient) {
    return ingredient.details;
  }
);

export const getLoadingDetailsById = createSelector(
  getIngredientById,
  function _getLoadingDetailsById(ingredient) {
    if (!ingredient) {
      return true;
    }
    return ingredient.detailsLoading;
  }
);
