import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from 'modules/core/components/loader/loader';

import Form from './form';
import Result from './result';

import './coupon.css';
import WarningPicture from './warning-picture';
import StaticTooltip from 'modules/core/components/static-tooltip/static-tooltip';
import InfoSign from 'modules/review/components/reply-form/info-sign/info-sign';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Content from 'modules/core/components/content/content';

const { func, bool } = PropTypes;

function TooltipContent({ showWarning, description }) {
  return (
    <div className="Coupon-conditionsGrid">
      {showWarning && (
        <div className="Coupon-warning">
          <WarningPicture />
        </div>
      )}
      <div className="Coupon-conditions">
        <h3 className="Coupon-conditionsTitle">
          {showWarning ? 'Условия промокода не выполнены' : 'Условия промокода'}
        </h3>

        <p className="Coupon-conditionsDescription">
          {/* {description} */}
          <Content html={description} />
        </p>
      </div>
    </div>
  );
}

class Coupon extends Component {
  static propTypes = {
    /** Флаг загрузки */
    isLoading: bool.isRequired,

    /** Функция-обработчик подтверждения промокода */
    onSubmit: func.isRequired,

    /** Функция-обработчик сброса промокода */
    onReset: func.isRequired
  };

  state = {
    showConditions: true
  };

  render() {
    const {
      coupon,
      optionId,
      isLoading,
      render,
      isVerified,
      onReset,
      onSubmit,
      openApplyAnotherGiftDialog
    } = this.props;

    const { showConditions } = this.state;

    if (isLoading || !isVerified) {
      const children = (
        <div className="Coupon">
          <div className="Coupon-loader">
            <Loader type="goo" />
          </div>
        </div>
      );

      return render
        ? render({
            children
          })
        : children;
    }

    const isNotDisabled = coupon && !coupon.disabled;

    const metConditions = coupon && coupon.isApplied;
    const hasOptions = coupon && coupon.options && coupon.options.length > 0;

    const showWarning = !metConditions && isVerified && ((hasOptions && optionId) || !hasOptions);

    return isNotDisabled ? (
      <StaticTooltip
        renderTrigger={({ ref, onClick }) => {
          const trigger = (
            <div className="Coupon">
              <Result
                coupon={coupon}
                showWarning={showWarning}
                couponInfoButtonRef={ref}
                onInfoButtonClick={onClick}
                onReset={onReset}
                onSubmit={onSubmit}
                openApplyAnotherGiftDialog={openApplyAnotherGiftDialog}
              />
            </div>
          );

          return render
            ? render({
                children: trigger
              })
            : trigger;
        }}
        show={showWarning || showConditions}
        onShowChange={this.handleShowConditionsChange}
      >
        <TooltipContent showWarning={showWarning} description={coupon.description} />
      </StaticTooltip>
    ) : (
      this.renderForm()
    );
  }

  renderForm() {
    const { render, coupon, disabled, onSubmit } = this.props;
    const hasError = coupon && coupon.disabled;

    const form = (
      <div className="Coupon">
        <div className="Coupon-form">
          <Form
            initial={hasError && coupon.code}
            error={hasError && `Промокод ${coupon.code} не найден`}
            disabled={disabled}
            onSubmit={onSubmit}
          />
        </div>
        {!!disabled && (
          <div className="Coupon-infoSignHolder">
            <Tooltip content={this.renderLabelInfo()} showDelay={0}>
              <InfoSign />
            </Tooltip>
          </div>
        )}
      </div>
    );

    return render ? render({ children: form }) : form;
  }

  renderLabelInfo() {
    return <div className="Coupon-labelInfo">Что бы использовать промо код, отмените бонусы</div>;
  }

  handleShowConditionsChange = showConditions => {
    this.setState({ showConditions });
  };
}

export default Coupon;
