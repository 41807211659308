import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import * as fromProduct from '../ducks/products';
import Details from '../components/details/details';

export default withRouter(
  connect(
    createStructuredSelector({
      product: fromProduct.getItemByRouteParams,
      details: fromProduct.getDetailsBySlug,
      category: fromProduct.getCategory,
      subCategory: fromProduct.getSubcategory,
      isLoaded: fromProduct.getIsDetailsLoaded
    })
  )(Details)
);
