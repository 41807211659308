import React from 'react';

import ProductGroupProvider from 'modules/product/containers/group-provider';
import ProductList from 'modules/product/components/product-list/product-list';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
// import Container from 'modules/core/components/container/container';

import './landing-products.css';

function LandingProducts({ data, light }) {
  return (
    <div className="LandingProducts">
      <div className="LandingProducts-title">
        <LandingSectionTitle
          data={data.title}
          top={data.title[0]}
          bottom={data.title[1]}
          light={light}
        />
      </div>
      <div className="LandingProducts-list">
        <ProductGroupProvider slug={data.slug}>{renderWithData}</ProductGroupProvider>
      </div>
    </div>
  );

  function renderWithData(props) {
    const { isLoading, group, isProductsLoading } = props;
    const productsAmount = group && group.products.length;
    if (isLoading) {
      return null;
    }

    return (
      <ProductList
        products={group.products}
        wide
        total={group.totalAmount}
        amount={productsAmount}
        isLoading={isProductsLoading}
        isLanding
      />
    );
  }
}

export default LandingProducts;
