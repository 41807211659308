import { takeEvery, select, put, call } from 'redux-saga/effects';
import wait from 'modules/utils/saga/wait-effect';

import { mixitApi } from 'services/mixit';

import * as ACCOUNT from 'modules/core/ducks/types/account';
import * as fromProfile from 'modules/profile/ducks/profile';

import Location from '../ducks/actions/location';
import * as fromLocation from '../ducks/location';

import CityList from '../ducks/actions/city-list';
import * as fromCityList from '../ducks/city-list';

import { arrayToObject } from 'modules/utils';

export function* locationWatcher() {
  yield takeEvery('@@REHYDRATE', requestAllCity);
  yield takeEvery(ACCOUNT.AUTHORIZATION_READY, requestSuggestedCity);
}

function* requestSuggestedCity(action) {
  const { isAuthorized } = action.payload;

  if (isAuthorized) {
    yield wait(fromProfile.getReady);

    const chosenCityId = yield select(fromProfile.getCity);

    if (chosenCityId) {
      return;
    }
  } else {
    const localCityId = yield select(fromLocation.getLocalCityId);

    if (localCityId) {
      return;
    }
  }

  if (__SERVER__) {
    return;
  }

  try {
    const response = yield call(mixitApi().location().cities, { geo_ip: true });
    const city = response.data[0];

    yield put(Location.setSuggestedCity(city));
  } catch (error) {
    console.warn(error);
  }
}

function* requestAllCity() {
  try {
    const response = yield call(mixitApi().location().cities);

    const city = response.data.map(normalizeCity);

    const all = yield select(fromCityList.getAll);

    const finnalyAll = getNewStore(all, city);

    yield put(CityList.rehydrateAll(finnalyAll));
  } catch (error) {
    console.warn(error);
  }
}

function normalizeCity(cityOptions) {
  const { id, free_delivery_from, title } = cityOptions;

  return {
    id,
    title,
    freeDeliveryFrom: free_delivery_from || 3000
    // freeDeliveryFrom: Math.round(Math.random() * 1000)
  };
}

function getNewStore(prevState, fetchedData) {
  const supplementedFetchedData = fetchedData.map(item => ({ ...item, ...prevState[item.id] }));
  const fetchedTable = arrayToObject(supplementedFetchedData, 'id');
  return { ...prevState, ...fetchedTable };
}
