import { createReducer, handle } from 'modules/utils/dux';

import { createSelector } from '../../utils/debug-reselect';

import * as SUBSCRIBE from './types/subscribe';

/* REDUCERS */

const reducer = createReducer(
  handle(SUBSCRIBE.REHYDRATE, (state, { haveSuggestionBeenShowed }) => {
    return {
      ...state,
      haveSuggestionBeenShowed
    };
  }),

  handle(SUBSCRIBE.REQUEST, (state, { email }) => {
    return {
      ...state,
      isLoading: true,
      isSubmitted: false,
      requestedEmail: email
    };
  }),

  handle(SUBSCRIBE.RESPONSE, (state, { email }) => {
    return {
      ...state,
      isLoading: false,
      submittedEmail: email,
      isSubmitted: true,
      requestedEmail: null,
      haveSuggestionBeenShowed: true
    };
  }),

  handle(SUBSCRIBE.RESET_SUBSCRIBED_EMAIL, state => {
    return {
      ...state,
      submittedEmail: null
    };
  }),

  handle(SUBSCRIBE.SUBSCRIBE_SUGGESTION_OPEN, state => {
    return {
      ...state,
      isOpened: true
    };
  }),

  handle(SUBSCRIBE.SUBSCRIBE_SUGGESTION_CLOSE, state => {
    return {
      ...state,
      isOpened: false,
      haveSuggestionBeenShowed: true
    };
  }),

  handle(SUBSCRIBE.ALLOW_TO_SHOW, state => {
    return {
      ...state,
      allowedToShow: true
    };
  }),
  handle(SUBSCRIBE.FORBID_TO_SHOW, state => {
    return {
      ...state,
      allowedToShow: false
    };
  })
);

export default reducer({
  //   _error: false,
  isLoading: false,
  submittedEmail: null,
  isSubmitted: false,
  requestedEmail: null,
  isOpened: false,
  haveSuggestionBeenShowed: false,
  allowedToShow: true
});

/* SELECTORS */

const getRoot = state => state.core.subscribe;

export const getAll = getRoot;

export const getIsLoading = createSelector(
  getAll,
  root => root.isLoading
);
export const getIsOpened = createSelector(
  getAll,
  root => root.isOpened
);
export const getRequestedEmail = createSelector(
  getRoot,
  root => root.requestedEmail
);
export const getIsSubmitted = createSelector(
  getAll,
  root => root.isSubmitted
);
export const getSubmittedEmail = createSelector(
  getRoot,
  root => root.submittedEmail
);
export const getAllowToShow = createSelector(
  getRoot,
  root => root.allowedToShow
);

export const getHaveSuggestionBeenShowed = createSelector(
  getAll,
  function _getHaveSuggestionBeenShowed(all) {
    return all.haveSuggestionBeenShowed;
  }
);
