import React from 'react';

function Svg({ viewBox, className, children }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      {children}
    </svg>
  );
}

export default Svg;
