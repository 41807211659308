import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { string, node } = PropTypes;

class Fieldset extends Component {
  static propTypes = {
    title: string,
    children: node
  };
  render() {
    const { title, children } = this.props;

    return (
      <fieldset className="Questionnaire-fieldset">
        <legend className="Questionnaire-legend">{title}</legend>
        {children}
      </fieldset>
    );
  }
}

export default Fieldset;
