import React, { Component, Fragment } from 'react';
import { YMaps, Map, Placemark, Clusterer, ZoomControl } from 'react-yandex-maps';

import Media from './media';

import HideErrorBoundary from '../hide-error-boundary/hide-error-boundary';

const MAP_HEIGHTS = {
  desktop: '600px',
  tablet: '480px',
  mobile: '360px'
};

const API_KEY = '6552443e-463a-464c-ada6-14f09df45c2f';

class ShopsMap extends Component {
  render() {
    const { isLoaded, state, onApiAvaliable, responsive, activeId } = this.props;

    const clustererOptions = this.getClustererOptions();
    const placemarkList = this.renderPlacemarkList();
    return (
      <YMaps query={{ load: 'geocode', apikey: API_KEY }}>
        <Media>
          {query => (
            <Map
              width="100%"
              height={responsive ? '100%' : MAP_HEIGHTS[query]}
              state={state}
              key={activeId}
              onLoad={onApiAvaliable}
            >
              <ZoomControl />
              <Clusterer options={clustererOptions}>{isLoaded && placemarkList}</Clusterer>
            </Map>
          )}
        </Media>
      </YMaps>
    );
  }

  renderPlacemarkList() {
    const { list, isLoaded } = this.props;

    if (!isLoaded) {
      return null;
    }

    return <Fragment>{list.map(this.renderPlacemark)}</Fragment>;
  }

  renderPlacemark = item => {
    const options = {
      iconLayout: 'default#image',
      iconImageHref: this.getPlacemarkImage(item.id),
      iconImageSize: [40, 72],
      iconImageOffset: [-20, -36]
    };

    return (
      <HideErrorBoundary>
        <Placemark
          key={item.id}
          geometry={item.geography.coordinates.latlng}
          options={options}
          onClick={this.handleClick(item)}
          onDblClick={this.handleDblClick}
        />
      </HideErrorBoundary>
    );
  };

  getClustererOptions() {
    return { hasBalloon: false, clusterIconColor: '#ff0000' };
  }

  getPlacemarkImage(id) {
    const { activeId } = this.props;

    if (activeId === id) {
      return '/content/map/pin-active.png';
    } else {
      return '/content/map/pin.png';
    }
  }

  handleClick = item => () => {
    const { onShopSelect } = this.props;

    onShopSelect(item);
  };

  handleDblClick = e => {
    e.preventDefault();
  };
}

export default ShopsMap;
