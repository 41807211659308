import React from 'react';
import cn from 'classnames';
import ActivityDetail from './activity-detail/activity-detail';

import './activity-details.css';

export default function ActivityDetails({ list, colors }) {
  return (
    <div className={cn('ActivityDetails')}>
      <div className="ActivityDetails-holder">
        {list.map((detail, index) => (
          <div key={index} className="ActivityDetails-item">
            <ActivityDetail data={detail} colors={colors} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
}
