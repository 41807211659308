import React, { Component } from 'react';
import Helmet from 'react-helmet';

// import reviewList from 'modules/review/ducks/reviews/seeds/reviews';
import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';
// import MagazineListContainer from '../containers/magazine-list';
import SubscribeContainer from 'modules/core/containers/subscribe';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
// import FeedbackSection from 'modules/core/components/feedback-section/feedback-section';
// import GiftSetsContainer from '../containers/gift-sets';
import ProductsNewContainer from 'modules/product/containers/products-new';
import ProductsPopularContainer from 'modules/product/containers/products-popular';
import ProductsSaleContainer from 'modules/product/containers/products-sale';
// import ReviewList from 'modules/review/components/review-list/review-list';
import Page from '../components/page/page';
// import HomePageSlider from '../components/home-page-slider/home-page-slider';
import HomePageFeaturedSlider from '../components/home-page-featured-slider/home-page-featured-slider';
import FreeDeliveryNotification from '../containers/free-delivery-notification';
// import ShortListBasketProductsPanel from '../containers/basket-preview-panel-container';
// import ShortListBasketProductsPanelSection from '../components/basket-preview-panel/basket-preview-panel-section-container';
// import BlackFridayNotification from '../components/black-friday-notification/black-friday-notification';
// import HolidaysNotification from '../components/holidays-notification/holidays-notification';
import { getGroupLink } from 'routes/links';
import MainDescriptionSection from '../components/main-description-section/main-description-section';

// import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

// function OptionalContainer({ disabled, children }) {
//   if (disabled) {
//     return children;
//   }
//   return <Container>{children}</Container>;
// }

// function ProductGroupSection({ children, ...props }) {
//   return (
//     <SectionDefault {...props}>
//       <Container>{children}</Container>
//     </SectionDefault>
//   );
// }

class IndexPage extends Component {
  static path = '/';
  static link = () => '/';
  static exact = true;

  render() {
    return (
      <Page notification={<FreeDeliveryNotification />} withoutSaleNotification>
        <Helmet>
          <title>MIXIT - интернет-магазин современной косметики</title>

          <meta
            name="description"
            content="MIXIT - интернет-магазин современной косметики. Производим эффективные средства для ухода за лицом, телом и волосами. Купить с доставкой по Москве и всей России."
          />
        </Helmet>

        {/* <ShortListBasketProductsPanelSection>
          <ShortListBasketProductsPanel />
        </ShortListBasketProductsPanelSection> */}

        {__BROWSER__ && (
          <SectionDefault noGap>
            {/* <HomePageSlider /> */}
            <HomePageFeaturedSlider />
          </SectionDefault>
        )}

        {/* <HideErrorBoundary>
          <BlackFridayNotification
            expireData="2019-11-26T23:59:59+03:00"
            endDate="2019-12-01T23:59:59+03:00"
          />
        </HideErrorBoundary> */}
        <ProductsNewContainer
          sectionProps={{
            title: 'Новинки',
            buttonTitle: 'Все новинки',
            moreLink: getGroupLink({ slug: 'new' })
          }}
        />

        <ProductsPopularContainer
          sectionProps={{
            title: 'Бестселлеры',
            buttonTitle: 'Все бестселлеры',
            moreLink: getGroupLink({ slug: 'bestsellers' })
          }}
        />
        <ProductsSaleContainer
          sectionProps={{
            title: 'Скидки',
            buttonTitle: 'Все товары со скидкой',
            moreLink: getGroupLink({ slug: 'sale' })
          }}
        />
        {/* <GiftSetsContainer
          sectionProps={{
            title: 'Подарочные наборы',
            buttonTitle: 'Все подарочные наборы',
            moreLink: getCategoryLink({ slug: 'gift-sets' })
          }}
        /> */}
        {/* <SectionDefault title="Отзывы наших покупателей">
          <Container>
            <ReviewList reviewList={reviewList} />
          </Container>
        </SectionDefault> */}
        {/* <SectionDefault
          title="Журнал"
          moreLink={getBlogLink()}
          buttonTitle="Все статьи"
        >
          <Container>
            <MagazineListContainer />
          </Container>
        </SectionDefault> */}
        <SectionDefault highlightBackground>
          <Container>
            <SubscribeContainer />
          </Container>
        </SectionDefault>
        <SectionDefault>
          <Container>
            <MainDescriptionSection />
          </Container>
        </SectionDefault>
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
        {/* <SectionDefault>
          <Container>
            <FeedbackSection />
          </Container>
        </SectionDefault> */}
      </Page>
    );
  }
}

export default IndexPage;
