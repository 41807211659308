import React, { PureComponent } from 'react';
import './product-consistency.css';
import IngredientGrid from '../ingredient-grid/ingredient-grid';
import Consistency from '../consistency/consistency';

class ProductConsistency extends PureComponent {
  render() {
    const { consistency } = this.props;

    const groups = this.getGroups();

    return (
      <div className="ProductConsistency">
        <div className="ProductConsistency-colInfo">
          <div className="ProductConsistency-info">
            <Consistency list={groups} />
          </div>
        </div>
        {consistency.primary && (
          <div className="ProductConsistency-colIngredients">
            <IngredientGrid idList={consistency.primary} />
          </div>
        )}
      </div>
    );
  }

  getGroups() {
    const { consistency } = this.props;

    return [
      {
        id: 1,
        title: 'Основные ингредиенты',
        name: 'main',
        color: '#25bc8b',
        list: consistency.ingredients
      },
      {
        id: 2,
        title: 'Второстепенные ингредиенты',
        name: 'secondary',
        color: '#5b5b5f',
        raw: consistency.rawText
      }
    ];
  }
}

export default ProductConsistency;
