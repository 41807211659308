import * as Goods from './goods';
import * as Promo from './promo';

export function addPromo(basket, promo) {}

export function removePromo(basket, promo) {}

export function addProduct(basket, product, amount) {}

export function changeProductAmount(basket, product, amount) {}

export function removeProduct(basket, product) {}

export function serialize(basket) {
  if (!basket) {
    return null;
  }

  return {
    goods_list: basket.goods.map(Goods.serialize).filter(Boolean),
    promos: basket.promos.map(Promo.serialize).filter(Boolean)
  };
}

function deserializeGoods(externalGoodsList = []) {
  return externalGoodsList.map(Goods.deserialize);
}

function deserializePromos(externalPromosList = []) {
  return externalPromosList.map(Promo.deserialize);
}

function deserializeChangeDate(changeDate) {
  return Date.parse(changeDate) || 0;
}

export function deserialize(externalBasket) {
  if (!externalBasket) {
    return null;
  }

  const { goods_list, promos, change_date, bonuses } = externalBasket;

  return {
    goods: deserializeGoods(goods_list),
    promos: deserializePromos(promos),
    changeDate: deserializeChangeDate(change_date),
    bonuses
  };
}
