import { createReducer, handle } from 'modules/utils/dux';
import productTypes from 'modules/product/ducks/types';
import { ingredientsFromDetails } from 'modules/product/ducks/products/helpers';
import { reduceListByIdentifier, normalizeIngredient, selectId } from './helpers';
import * as TYPES from './types';

const reducer = createReducer(
  handle(productTypes.products.ADD_DETAILS, (state, payload) => {
    const ingredients = ingredientsFromDetails(payload.details);
    const ingredientsByIds = reduceListByIdentifier(ingredients, selectId, normalizeIngredient);

    return {
      ...state,
      ...ingredientsByIds
    };
  }),

  handle(TYPES.DETAILS_SHOW, (state, { id }) => {
    const ingredient = state[id];

    return {
      ...state,
      [id]: {
        ...ingredient,
        loadingDetails: true
      }
    };
  }),

  handle(TYPES.DETAILS_ADD, (state, { id, details }) => {
    const ingredient = state[id];

    return {
      ...state,
      [id]: {
        ...ingredient,
        loadingDetails: false,
        details
      }
    };
  })
);

export default reducer({});
