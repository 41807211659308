import React, { Component } from 'react';

import Dialog from '../dialog/dialog';

import './feedback-confirm-dialog.css';

class FeedbackСonfirmDialog extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Dialog closeable {...rest}>
        <div className="FeedbackСonfirmDialog">
          <h2 className="FeedbackСonfirmDialog-title">Спасибо за твой отзыв</h2>
        </div>
      </Dialog>
    );
  }
}

export default FeedbackСonfirmDialog;
