import isFunction from './is-function';

class LineNumberObserver {
  constructor(element) {
    this._element = element;
    this._callbacks = [];
    this._lineCount = 0;

    this.forceRecalculate();

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleLoad);
  }

  release() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('load', this.handleLoad);
    delete this._callbacks;
    delete this._element;
  }

  subscribe(callback) {
    this._throwIfReleased('It is not allowed to subscribe anymore');

    if (!isFunction(callback)) {
      throw new Error('Subscribe callback is not a function');
    }

    callback(this._lineCount);

    this._callbacks.push(callback);
  }

  unsubscribe(callback) {
    this._throwIfReleased('It is not allowed to unsubscribe anymore');

    this._callbacks = this._callbacks.filter(cb => cb !== callback);
  }

  emit() {
    this._throwIfReleased('It is not allowed to emit anymore');

    for (const callback of this._callbacks) {
      callback(this._lineCount);
    }
  }

  emitIfLineCountChange() {
    const lineCount = this.calculateLineCount();

    if (this._lineCount === lineCount) {
      return;
    }

    this._lineCount = lineCount;

    this.emit();
  }

  forceRecalculate() {
    this._lineCount = this.calculateLineCount();
    this.emit();
  }

  recalculate() {
    this.emitIfLineCountChange();
  }

  calculateLineCount() {
    const { paddingTop, paddingBottom, lineHeight } = window.getComputedStyle(this._element);
    const { height } = this._element.getBoundingClientRect();

    const innerHeight = height - parseInt(paddingTop, 10) - parseInt(paddingBottom, 10);
    const lineCount = innerHeight / parseInt(lineHeight, 10);

    return lineCount;
  }

  handleResize = () => {
    this.emitIfLineCountChange();
  };

  handleLoad = () => {
    this.emitIfLineCountChange();
  };

  _throwIfReleased(message) {
    if (!this._element) {
      throw new Error('Observer was released and ready for destruction. ' + message);
    }
  }
}

export default LineNumberObserver;
