import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';

import isFunction from 'modules/utils/is-function';
import Button from 'modules/core/components/button/button';
import QuickFilter from '../quick-filter/quick-filter';
import ProductFilterContainer from '../../containers/product-filter';

import BottomSheet from 'modules/core/components/bottom-sheet/bottom-sheet';
import ButtonClose from 'modules/core/components/button-close/button-close';

import './filter-bottom-sheet.css';

function getWasFiltersChanged(initial, current) {
  return !isEqual(initial.sort(), current.sort());
}

function FilterBottomSheet(props) {
  const [appliedFilters, setAppliedFilters] = useState(props.applied || []);
  const notFiltered = appliedFilters.length === 0;

  function handleSetAppliedFilters({ options }) {
    setAppliedFilters(options);
  }

  const {
    filters,
    quick,
    applied,
    onFilterChange,
    onQuickFilterApply,
    onClose,
    onFiltersReset,
    ...bottomSheetProps
  } = props;

  return (
    <BottomSheet
      header={renderHeader()}
      footer={renderFooter()}
      withBorder={true}
      scrollable={true}
      onClose={handleClose}
      fullOppened
      {...bottomSheetProps}
    >
      <div className="FilterBottomSheet">
        <div className="FilterBottomSheet-quickFiltersHolder">{renderQuickFilters()}</div>
        <div className="FilterBottomSheet-filterListHolder">{renderFilterList(filters)}</div>
      </div>
    </BottomSheet>
  );

  function renderFilterList(filters) {
    return (
      <div className="FilterBottomSheet-filterList">
        {filters.map(id => (
          <div key={id} className="FilterBottomSheet-filterItem">
            <div className="FilterBottomSheet-productFilters">
              <ProductFilterContainer
                id={id}
                appliedFilters={appliedFilters}
                onFilterChange={e => {
                  //   console.log('renderFilterList -> e', e);
                  handleSetAppliedFilters(e);
                  //   onFilterChange(e);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className="FilterBottomSheet-header">
        <div className="FilterBottomSheet-headerTitle">Фильтр</div>
        <div className="FilterBottomSheet-headerClose">
          <ButtonClose onClick={onClose} />
        </div>
      </div>
    );
  }

  function renderFooter() {
    return (
      <div className="FilterBottomSheet-footer">
        <div className="FilterBottomSheet-footerActions">
          <div className="FilterBottomSheet-footerActionsItem">
            {!notFiltered && (
              <Button
                title="Очистить"
                variant="secondary"
                size="small"
                expanded
                onClick={handleReset}
              />
            )}
          </div>
          <div className="FilterBottomSheet-footerActionsItem">
            <Button
              title="Применить"
              variant="primary"
              size="small"
              expanded
              onClick={handleApply}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderQuickFilters() {
    if (!quick.length) {
      return null;
    }

    return (
      <div className="FilterBottomSheet-quickFilters">
        <QuickFilter filters={quick} onQuickFilterApply={onQuickFilterApply} />
      </div>
    );
  }

  function reset() {
    setAppliedFilters([]);
    onFiltersReset();
  }

  function close() {
    if (!isFunction(onClose)) {
      return;
    }
    onClose();
    // setAppliedFilters([]);
  }

  function handleClose() {
    const wasFiltersChanged = getWasFiltersChanged(applied, appliedFilters);
    close();
    if (wasFiltersChanged) {
      setAppliedFilters(props.applied);
    }
  }

  function handleReset() {
    reset();
    close();
  }

  function handleApply() {
    const wasFiltersChanged = getWasFiltersChanged(applied, appliedFilters);
    if (wasFiltersChanged) {
      onFilterChange({ options: appliedFilters });
    }

    close();
  }

  //   function handleCancel() {
  //     close();
  //   }
}

export default FilterBottomSheet;
