const feedbackVariants = {
  good: {
    title: 'good',
    comment: 'Мы счастливы, что тебе понравилось наше письмо! Постараемся радовать тебя и дальше.',
    optionList: [
      {
        title: 'Информация о новинках',
        value: 'news-information'
      },
      {
        title: 'Описания бьюти-средств',
        value: 'beauty-descriptions'
      },
      {
        title: 'Интересные обучающие видео',
        value: 'tutorial-videos'
      },
      {
        title: 'Индивидуальные подборки продукции',
        value: 'product-selections'
      },
      {
        title: 'Конкурсы',
        value: 'competitions'
      },
      {
        title: 'Твой комментарий',
        value: 'comment'
      }
    ]
  },

  neutral: {
    title: 'neutral',
    comment: 'Нам есть куда стремиться, чтобы быть лучше!',
    optionList: [
      {
        title: 'Изменить дизайн письма',
        value: 'change-letter-design'
      },
      {
        title: 'Сделать больше конкурсов',
        value: 'more-competitions'
      },
      {
        title: 'Поделиться ссылками на обучающие видео',
        value: 'add-tutorial-videos'
      },
      {
        title: 'Добавить рекомендации по пользованию нашими средствами',
        value: 'add-usage-recommendation'
      },
      {
        title: 'Прислать подборку из средств индивидуально для тебя',
        value: 'add-product-selections'
      },
      {
        title: 'Твой комментарий',
        value: 'comment'
      }
    ]
  },

  bad: {
    title: 'bad',
    comment:
      'Очень жаль, что тебе не понравилось наше письмо! Будем работать над ошибками и стремиться к совершенству.',
    optionList: [
      {
        title: 'Новые акции',
        value: 'more-special-offers'
      },
      {
        title: 'Дизайн',
        value: 'change-letter'
      },
      {
        title: 'Чаще проводить конкурсы',
        value: 'more-competitions'
      },
      {
        title: 'Твой комментарий',
        value: 'comment'
      }
    ]
  }
};

export default feedbackVariants;
