import * as TYPES from '../types/utm-banner';
import { createAction } from 'modules/utils/dux';

export default {
  rehydrate(utmList) {
    return createAction(TYPES.REHYDRATE, {
      utmList
    });
  },

  noteUtm(utm) {
    return createAction(TYPES.NOTE_UTM, {
      utm
    });
  },

  request(utm_campaign) {
    return createAction(TYPES.REQUEST, {
      utm_campaign
    });
  },

  response(data) {
    return createAction(TYPES.RESPONSE, {
      data
    });
  },

  resetData() {
    return createAction(TYPES.RESET_DATA);
  },

  error(message) {
    return createAction(TYPES.ERROR, {
      message
    });
  },

  openBanner() {
    return createAction(TYPES.OPEN_BANNER);
  },

  closeBanner() {
    return createAction(TYPES.CLOSE_BANNER);
  }
};
