import React, { Component } from 'react';
import './icon-mail.css';

class IconMail extends Component {
  render() {
    return (
      <svg className="IconMail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M20.6 4H3.2c-1 0-1.8.8-1.8 1.9v12.4c0 1 .8 1.9 1.8 1.9h17.3c1 0 1.8-.8 1.8-1.9V5.9c.1-1.1-.7-1.9-1.7-1.9zm-.3 1.2l-8.4 8.4-8.4-8.4h16.8zM2.6 18V6.1l6 5.9-6 6zm.9.9l5.9-6 2.1 2.1c.2.2.6.2.9 0l2-2 5.9 6H3.5zm17.7-.9l-5.9-6 5.9-6v12z" 
          fill="#262931" 
          stroke="#fff" 
          stroke-width="0.1"/>
      </svg>
    );
  }
}

export default IconMail;
