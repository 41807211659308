import {
  // createReducer,
  createAction
  // , handle
} from 'modules/utils/dux';
// import { createSelector } from 'reselect';
import * as BONUS_CONFIRMATION from './types/bonuses-conformation';

export const actions = {
  requestCode({ value }) {
    return createAction(BONUS_CONFIRMATION.REQUEST_CODE, { value });
  },

  responseCode({ phone }) {
    return createAction(BONUS_CONFIRMATION.RESPONSE_CODE, {
      phone
    });
  },

  errorCode({ message }) {
    return createAction(BONUS_CONFIRMATION.ERROR_CODE, {
      message
    });
  },

  requestConfirm(payload) {
    const { code } = payload;
    return createAction(BONUS_CONFIRMATION.REQUEST_CONFIRM, { code });
  },

  responseConfirm() {
    return createAction(BONUS_CONFIRMATION.RESPONSE_CONFIRM);
  },

  errorConfirm({ message }) {
    return createAction(BONUS_CONFIRMATION.ERROR_CONFIRM, {
      message
    });
  }
};
