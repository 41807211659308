import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { IconEdit, IconCheck, IconReturn } from 'modules/core/components/icons';

import BaseEditableButton from './base-editable-button';

import isFunction from 'modules/utils/is-function';

import './base-editable.css';

const { func, bool, object, oneOfType, string, any } = PropTypes;

class BaseEditable extends Component {
  static propTypes = {
    renderView: func,
    prepareValue: func,
    isDisabled: bool,
    renderEdit: func,
    onEdit: func,
    onCancel: func,
    onSave: func,
    onChange: func,
    initialValue: any,
    value: oneOfType([object, string]),
    apliable: bool
  };

  static defaultProps = {
    isDisabled: false,
    prepareValue: value => value,
    apliable: true
  };

  state = {
    isEditing: false,
    editingValue: null
  };

  render() {
    const { isDisabled, renderEdit, apliable } = this.props;
    const { editingValue, isEditing } = this.state;

    if (isEditing) {
      return (
        <div className="BaseEditable BaseEditable--isEditing">
          <div className="BaseEditable-option">
            {renderEdit({ editingValue, onChange: this.handleChange })}
          </div>

          <div className="BaseEditable-actionGroup">
            <div className="BaseEditable-actionsHolder">
              <div className="BaseEditable-action">
                <BaseEditableButton
                  disabled={!apliable}
                  icon={<IconCheck />}
                  onClick={this.handleAcceptButtonClick}
                />
              </div>

              <div className="BaseEditable-action">
                <BaseEditableButton icon={<IconReturn />} onClick={this.handleCancelButtonClick} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    const optionContent = this.formOptionContent();

    return (
      <div className={cn('BaseEditable', { 'BaseEditable--disabled': isDisabled })}>
        <button
          className="BaseEditable-option"
          onClick={this.handleOptionClick}
          disabled={isDisabled}
        >
          {optionContent}
        </button>

        {!isDisabled && (
          <div className="BaseEditable-actionGroup">
            <div className="BaseEditable-actionsHolder">
              <div className="BaseEditable-action">
                <BaseEditableButton icon={<IconEdit />} onClick={this.handleEditButtonClick} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  formOptionContent() {
    const { value, renderView, prepareValue } = this.props;

    if (isFunction(renderView)) {
      return renderView({ value });
    }

    const preparedValue = prepareValue(value);

    if (preparedValue) {
      return <span className="BaseEditable-optionValue">{preparedValue}</span>;
    }

    return <span className="BaseEditable-optionPlaceholder">Не указан</span>;
  }

  accept(value) {
    const { onChange } = this.props;
    onChange(value);
    this.setViewMode();
  }

  edit() {
    const { onEdit } = this.props;
    this.setEditMode();
    this.setLocalValue();

    if (!isFunction(onEdit)) {
      return;
    }
    onEdit();
  }

  cancel() {
    this.setViewMode();
  }

  setEditMode() {
    this.setState({
      isEditing: true
    });
  }

  setViewMode() {
    this.setState({
      isEditing: false
    });
  }

  setLocalValue() {
    const { value, initialValue } = this.props;

    this.setState({
      editingValue: value || initialValue
    });
  }

  handleEditButtonClick = () => {
    this.edit();
  };

  handleOptionClick = () => {
    this.edit();
  };

  handleCancelButtonClick = () => {
    const { onCancel } = this.props;

    this.cancel();

    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  handleAcceptButtonClick = () => {
    const { onSave } = this.props;
    const { editingValue } = this.state;

    this.accept(editingValue);

    if (isFunction(onSave)) {
      onSave();
    }
  };

  handleChange = editingValue => {
    this.setState({ editingValue });
  };
}

export default BaseEditable;
