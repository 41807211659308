import { takeEvery, put, call, select } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import * as fromBasketProducts from '../ducks/basket-products';
import * as fromDeliveryForm from '../ducks/delivery-form';

import * as ORDERS from '../ducks/types/orders';
import * as PAYMENT_METHODS from '../ducks/types/payment-methods';
import { actions as PaymentMethods } from '../ducks/payment-methods';

export function* orderWatcher() {
  yield takeEvery(ORDERS.DELIVERY_INFO_SUBMIT, onDeliverySubmit);
  yield takeEvery(ORDERS.UPDATE_FROM_SERVER, onOrderFetch);
}

export function* paymentMethodsWatcher() {
  yield takeEvery(PAYMENT_METHODS.REQUEST, requestPaymentMethods);
}

function* onDeliverySubmit(action) {
  const { deliveryMethod } = action.payload;

  yield put(PaymentMethods.request(deliveryMethod.id));
}

function* onOrderFetch(action) {
  const { order } = action.payload;

  if (!order || !order.delivery || !order.delivery.deliveryMethod) {
    return;
  }

  yield put(PaymentMethods.request(order.delivery.deliveryMethod.id));
}

function* requestPaymentMethods(action) {
  const { deliveryMethodId } = action.payload;

  const { response, error } = yield call(fetchPaymentMethods, deliveryMethodId);

  if (error) {
    yield put(PaymentMethods.error(deliveryMethodId, null, error));
    return;
  }

  yield put(
    PaymentMethods.response(
      deliveryMethodId,
      null,
      response.entities.deliveryMethods,
      response.result
    )
  );
}

function* fetchPaymentMethods(deliveryMethodId) {
  try {
    const city = yield select(fromDeliveryForm.getCityId);

    const productsCost = yield select(fromBasketProducts.getProductsCost);

    const response = yield call(
      mixitApi().paymentMethods.list,
      deliveryMethodId,
      productsCost,
      city.fiasId
    );

    const normalized = normalizePaymentMethods(response.data);

    return { response: normalized };
  } catch (error) {
    return { error };
  }
}

function normalizePaymentMethods(paymentMethods) {
  const ids = paymentMethods.map(item => item.id);
  const table = paymentMethods.reduce((all, item) => Object.assign(all, { [item.id]: item }), {});

  return {
    result: ids,
    entities: {
      deliveryMethods: table
    }
  };
}
