import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Actions as Banner } from '../ducks/banner';
import * as fromBanner from '../ducks/banner';
import isFunction from 'modules/utils/is-function';

const mapState = createStructuredSelector({
  isLoading: fromBanner.getIsLoading,
  list: fromBanner.getList
});

const mapActions = {
  load: Banner.request
};

class BannerProvider extends React.Component {
  render() {
    if (!isFunction(this.props.children)) {
      return null;
    }
    return this.props.children(this.props);
  }

  componentWillMount() {
    if (__SERVER__) {
      this.props.load();
    }
  }

  componentDidMount() {
    this.props.load();
  }
}

export default connect(
  mapState,
  mapActions
)(BannerProvider);
