export function getId(deliveryMethod) {
  if (!deliveryMethod || !deliveryMethod.id) {
    return null;
  }

  return deliveryMethod.id;
}

export function getName(deliveryMethod) {
  if (!deliveryMethod || !deliveryMethod.name) {
    return null;
  }

  return deliveryMethod.name;
}

export function normalize(deliveryMethod) {
  const { id, name, ...rest } = deliveryMethod;
  return {
    id,
    name,
    ...rest
  };
}
