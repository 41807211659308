import React, { Component } from 'react';
import Page from 'modules/core/components/page/page';

import AboutSection from '../components/about-section/about-section';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';

import { getIndexLink } from 'routes/links';

class AboutPage extends Component {
  static path = '/abount';
  static link = () => 'abount';
  static exact = true;

  render() {
    return (
      <Page
        title="О компании"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'О компании'
          }
        ]}
      >
        <AboutSection />

        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default AboutPage;
