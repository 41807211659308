import React, { Component } from 'react';
import './icon-master.css';

class IconMasterCard extends Component {
  static defaultProps = {
    opacity: 1
  };

  render() {
    const { opacity } = this.props;

    return (
      <svg className="IconMasterCard" viewBox="0 0 64 34" style={{ opacity: opacity }}>
        <path
          d="M59.9 17c0-9.4-7.6-17-17-17-4.1 0-7.9 1.5-10.8 3.9C29.2 1.5 25.4 0 21.2 0c-9.4 0-17 7.6-17 17s7.6 17 17 17c4.1 0 7.9-1.5 10.9-3.9C35 32.5 38.7 34 42.9 34c9.3 0 17-7.6 17-17z"
          fill="#828da2"
        />
        <path
          d="M58.6 17c0-8.7-7.1-15.8-15.8-15.8-3.8 0-7.2 1.3-9.9 3.5 2.1 2 3.6 4.5 4.5 7.3h-1.3c-.8-2.5-2.3-4.7-4.1-6.5-1.9 1.8-3.3 4-4.1 6.5h-1.3c.8-2.8 2.4-5.3 4.5-7.3-2.7-2.2-6.1-3.5-9.9-3.5C12.5 1.2 5.4 8.3 5.4 17s7.1 15.8 15.8 15.8c3.8 0 7.2-1.4 9.9-3.5-2.1-2-3.6-4.5-4.5-7.3h1.3c.8 2.5 2.2 4.7 4.1 6.5 1.9-1.8 3.3-4 4.1-6.5h1.3c-.8 2.8-2.4 5.3-4.5 7.3 2.7 2.2 6.1 3.5 9.9 3.5 8.8 0 15.8-7.1 15.8-15.8z"
          fill="#fff"
        />
        <path
          d="M47 20.7l1.1-5.8h1.4l.1.6c.2-.3.7-.6 1.5-.6l-.4 1.5c-.4 0-1.1-.1-1.6 1.6l-.5 2.7H47zm-21-7.3h1.6l-.3 1.5h.9l-.3 1.3H27l-.4 2.4c-.1.4 0 .9.9.7l-.1 1.2c-.3.2-.8.3-1.2.3-.4 0-1.6-.2-1.3-1.8l1.1-5.6z"
          fill="#828da2"
        />
        <path
          d="M32.8 18.1c.2-.9.6-3.3-2.2-3.3-2.6 0-2.9 2.5-2.9 2.9-.1.4-.1 2.9 2.6 2.9.7 0 1.6-.1 2-.3l.2-1.2c-.2.1-1.2.1-1.8.1-.6 0-1.6 0-1.5-1.3h3.6v.2z"
          fill="#828da2"
        />
        <path d="M29.5 17h2c.1-.6-.1-1.1-.7-1.1-.7.1-1.1.4-1.3 1.1 0 0 .2-.7 0 0z" fill="#fff" />
        <path
          d="M24.5 16.2l.4-1.2c-.8-.3-1.3-.2-1.6-.2-.2 0-2.1 0-2.4 1.4-.2 1.1.2 1.5.5 1.7.6.3 1 .5 1.3.7.4.2.3.9-.2.9-.2 0-1.3.1-2.1-.1l-.2 1.2c.7.2 1.3.2 1.9.2.4 0 2.3.1 2.5-1.6.1-.9-.2-1.2-.5-1.4s-.8-.5-1.1-.6c-.3-.1-.6-.7 0-.9.3-.2 1-.2 1.5-.1 0 0-.5-.1 0 0zm-10.3 4.5l1.3-7h-2.6L11 17.9l-.3-4.2H8.1l-1.3 7h1.5l1-5.1.4 5.1h1.5l2.3-5-1 5h1.7zm1.7-5.7l-.2 1.3c.4 0 1.4-.2 2-.2.5 0 1.2-.1 1 .8-.4 0-3.3-.4-3.7 2-.2 1.2.9 1.8 1.7 1.8.8 0 1-.1 1.3-.3l.2.3h1.5l.6-3.2c.2-.8.4-2.7-2.2-2.7-.6 0-1.1 0-2.2.2 0 0 1.1-.2 0 0z"
          fill="#828da2"
        />
        <path d="M18.4 18.1c-.3 0-.9-.2-1.4.1s-.7 1-.1 1.2c.5.2 1.3-.1 1.4-.7l.1-.6z" fill="#fff" />
        <path
          d="M41.6 15.3c-.4-.3-.7-.3-1.2-.3s-1.7.5-1.9 1.9c-.3 1.7.9 2.5 2.5 2l.5 1.4c-.5.2-1.1.4-1.9.4-1.6 0-3.4-1.5-2.6-4.4.6-2.2 2.5-3 3.6-2.9 1.3 0 1.8.5 2.2.9l-1.2 1zm13.5.2c-.3-.2-.5-.7-1.2-.7-.9 0-2.2-.2-3 2.5-.5 1.8.5 3.4 1.7 3.4.5 0 1.1-.2 1.4-.5l.2.5h1.7l1.4-7.4h-1.7l-.5 2.2z"
          fill="#828da2"
        />
        <path
          d="M54.5 17.9c.2-.5.1-1.2-.1-1.4-.5-.7-1.6-.1-2 1.1-.2.8.3 1.5.8 1.5.7 0 1.1-.5 1.3-1.2z"
          fill="#fff"
        />
        <path
          d="M42.7 15l-.2 1.3c.4 0 1.4-.2 2-.2.5 0 1.2-.1 1 .8-.4 0-3.3-.4-3.7 2-.2 1.2.9 1.8 1.7 1.8s1-.1 1.3-.3l.2.3h1.5l.6-3.2c.2-.8.4-2.7-2.2-2.7-.6 0-1.1 0-2.2.2 0 0 1.1-.2 0 0z"
          fill="#828da2"
        />
        <path d="M45.2 18.1c-.3 0-.9-.2-1.4.1s-.6 1-.1 1.2 1.3-.1 1.4-.7l.1-.6z" fill="#fff" />
        <path
          d="M32.9 20.7l1.1-5.8h1.4l.1.6c.2-.3.7-.6 1.5-.6l-.4 1.5c-.4 0-1.1-.1-1.6 1.6l-.5 2.6h-1.6v.1z"
          fill="#828da2"
        />
      </svg>
    );
  }
}

export default IconMasterCard;
