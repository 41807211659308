//api.mixit.ru/api/v3

/* interface CityFias {
  cidy_fias_id: string;
}

interface CityLocal {
  cidy_id: number;
}

interface CityRaw {
  city_title: string;
  country_title: string;
}

type CityRequest = CityFias | CityLocal | CityRaw; */

export default function initPaymentMethodsRequest(createRequest) {
  return {
    list: createRequest(function getItemRequest(
      delivery_method_id,
      products_cost,
      fias_id,
      city /* : CityRequest */
    ) {
      return {
        method: 'GET',
        path: `/payment-methods`,
        query: {
          ...city,
          products_cost,
          delivery_method_id,
          fias_id
        }
      };
    }),

    item: createRequest(function getItemRequest(id) {
      return {
        method: 'GET',
        path: `/payment-methods/${id}`
      };
    })
  };
}
