import { deserializeProduct } from 'modules/product/deserializers/product';

export function getId(apiProduct) {
  return apiProduct.slug;
}

export function normalizeList(list) {
  const idList = list.map(getId);

  const products = list.reduce((entities, item) => {
    const product = deserializeProduct(item);
    return {
      ...entities,
      [getId(item)]: product
    };
  }, {});

  return {
    list: idList,
    entities: { products }
  };
}
