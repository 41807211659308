import React, { Component } from 'react';

import Container from 'modules/core/components/container/container';
import Loader from 'modules/core/components/loader/loader';

import SearchProductListContainer from '../../containers/search-product-list';

import './search-results.css';

class SearchResults extends Component {
  render() {
    const { appliedFilters, query, isLoaded } = this.props;

    if (!isLoaded) {
      return (
        <div className="SearchResults">
          <div className="SearchResults-loaderHolder">
            <Loader />
          </div>
        </div>
      );
    }

    return (
      <div className="SearchResults">
        <Container>
          <div className="SearchResults-productList">
            <SearchProductListContainer
              query={query}
              appliedFilters={appliedFilters}
              // listId={`search-result-by-request_${query}`}
              listId="search-result"
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default SearchResults;
