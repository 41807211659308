import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Item extends Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <div className="BannerFeaturedSlider-item">{React.cloneElement(children, { ...rest })}</div>
    );
  }
}

export default Item;
