import React, { Component } from 'react';
import './footer-information.css';
import {
  IconApple,
  IconVk,
  IconFacebook,
  IconInstagram,
  IconYoutube,
  IconOdnoklassniki,
  IconAndroid,
  IconMaster,
  IconVisa,
  IconCardWorld,
  IconGooglePay,
  IconApplePay
} from '../../icons';

// import VKButton from 'modules/social/components/vk-button/vk-button';

import IconButton from 'modules/social/components/icon-button/icon-button';

class AdditionalInformationFooter extends Component {
  render() {
    return (
      <div className="AdditionalInformationFooter">
        <div className="AdditionalInformationFooter-item AdditionalInformationFooter-item--expanded">
          <div className="AdditionalInformationFooter-wrap">
            <div className="AdditionalInformationFooter-content">
              <span>ООО «Миксит», 2020 г.</span>
            </div>
          </div>
        </div>
        <div className="AdditionalInformationFooter-item">
          <div className="AdditionalInformationFooter-wrap">
            <div className="AdditionalInformationFooter-content">Наши аккаунты в соц сетях</div>
            <div className="AdditionalInformationFooter-visualPart">
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://www.instagram.com/mixit_ru/"
                  target="_blank"
                  icon={<IconInstagram />}
                  size="small"
                />
              </div>
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://vk.com/mixit_ru"
                  target="_blank"
                  icon={<IconVk />}
                  size="small"
                />
                {/* <VKButton size="small" href="https://vk.com/mixit_ru" target="_blank" /> */}
              </div>
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://www.facebook.com/mixit.ru/"
                  target="_blank"
                  icon={<IconFacebook />}
                  size="small"
                />
              </div>
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://www.youtube.com/channel/UCZhmTmXstevt0-LImfpDtqw/featured"
                  target="_blank"
                  icon={<IconYoutube />}
                  size="small"
                />
              </div>
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://www.ok.ru/profile/576654175956"
                  target="_blank"
                  icon={<IconOdnoklassniki />}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="AdditionalInformationFooter-item AdditionalInformationFooter-item--orderCharge">
          <div className="AdditionalInformationFooter-wrap">
            <div className="AdditionalInformationFooter-content">Способы оплаты</div>
            <div className="AdditionalInformationFooter-visualPart">
              <div className="AdditionalInformationFooter-iconSizer">
                <IconMaster />
              </div>

              <div className="AdditionalInformationFooter-iconSizer">
                <IconVisa />
              </div>

              <div className="AdditionalInformationFooter-iconSizer">
                <IconGooglePay />
              </div>

              <div className="AdditionalInformationFooter-iconSizer">
                <IconApplePay />
              </div>

              <div className="AdditionalInformationFooter-iconSizer">
                <IconCardWorld />
              </div>
            </div>
          </div>
        </div>
        <div className="AdditionalInformationFooter-item">
          <div className="AdditionalInformationFooter-wrap">
            <div className="AdditionalInformationFooter-content">Cкачайте наше приложение</div>
            <div className="AdditionalInformationFooter-visualPart">
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://play.google.com/store/apps/details?id=com.shezak.mixit"
                  target="_blank"
                  icon={<IconAndroid />}
                  size="small"
                />
              </div>
              <div className="AdditionalInformationFooter-icon">
                <IconButton
                  component="a"
                  href="https://itunes.apple.com/ru/app/mixit-%D0%BD%D0%B0%D1%82%D1%83%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D0%B0/id1237286540?mt=8"
                  target="_blank"
                  icon={<IconApple />}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalInformationFooter;
