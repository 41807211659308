import React, { Component } from 'react';
import Media from 'react-media';
import ProductContainer from '../../containers/product';
import Loader from 'modules/core/components/loader/loader';

import Grid from '../grid/grid';
// import NativeCarousel from '../native-carousel/native-carousel';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

const MAX_SALE_COUNT = 8;
const MOBILE_MAX_SALE_COUNT = 8;

function Layout({ children }) {
  return <Grid>{children}</Grid>;
  // return (
  //   <Media query="(max-width: 1100px)">
  //     {isMobile =>
  //       isMobile ? (
  //         <NativeCarousel>{children}</NativeCarousel>
  //       ) : (
  //         <Grid>{children}</Grid>
  //       )
  //     }
  //   </Media>
  // );
}

class ProductsSale extends Component {
  render() {
    const { sale, sectionProps } = this.props;

    if (sale && sale.error) {
      // return 'Failed loading popular products';
      return null;
    }

    if (!sale || !sale.products) {
      // return null;
      return (
        <SectionDefault title={sectionProps && sectionProps.title ? sectionProps.title : null}>
          <Container>
            <div className="ProductsPopular">
              <Loader type="spin" size="small" secondary />
            </div>
          </Container>
        </SectionDefault>
      );
    }

    return (
      <SectionDefault {...sectionProps}>
        <Container>
          <Media query="(max-width: 840px)">
            {isMobile => (
              <Layout>
                {/* {sale.products
          .slice(0, MAX_SALE_COUNT)
          .map(slug => <ProductContainer key={slug} slug={slug} />)} */}

                {this.getProductList(sale.products, isMobile).map(slug => (
                  <HideErrorBoundary>
                    <ProductContainer key={slug} slug={slug} listId="sale" />
                  </HideErrorBoundary>
                ))}
              </Layout>
            )}
          </Media>
        </Container>
      </SectionDefault>
    );
  }

  // componentWillMount() {
  //   if (__SERVER__) {
  //     this.props.showItems();
  //   }
  // }

  componentDidMount() {
    // Defer request to improve FCP
    setTimeout(() => this.props.showItems(), 1000); // REMOVE DELAY AFTER INTEGRATING SSR
  }

  getProductList(productList, isMobile) {
    if (isMobile) {
      return productList.slice(0, MOBILE_MAX_SALE_COUNT);
    }

    return productList.slice(0, MAX_SALE_COUNT);
  }
}

export default ProductsSale;
