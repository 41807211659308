import React from 'react';
import { Field as FormikField } from 'formik';
import { Field, Input } from 'modules/form';

function CouponFormikField({ error, name, ...rest }) {
  return (
    <FormikField
      name={name}
      render={({ form, field }) => {
        return (
          <Field error={error}>
            <Input
              {...rest}
              {...field}
              state={error && 'error'}
              onChange={e => {
                const value = e.target.value;
                form.setFieldValue('code', value.toUpperCase());
              }}
            />
          </Field>
        );
      }}
    />
  );
}

export default CouponFormikField;
