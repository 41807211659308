import React, { Component } from 'react';

import cn from 'classnames';

import Expire from './expire';
import plural from 'modules/utils/plural';

import './count-down.css';

class CountDown extends Component {
  static defaultProps = {
    size: 'normal'
  };

  render() {
    const { expireTime, detalized, fontSizeInherited, theme, fullUnit, size } = this.props;

    const pluralizeDays = plural(expireTime.days, ['День', 'Дня', 'Дней']);
    const pluralizeHours = plural(expireTime.hours, ['Час', 'Часа', 'Часов']);
    const pluralizeMinutes = plural(expireTime.minutes, ['Минута', 'Минуты', 'Минут']);
    // const pluralizeSeconds = plural(expireTime.seconds, ['Секунда', 'Секунды', 'Секунд']);
    const pluralizeSeconds = plural(expireTime.seconds, ['Сек.', 'Сек.', 'Сек.']);

    return (
      <div
        className={cn('Expire-time', `Expire-time--size-${size}`, {
          'Expire-time--fontSizeInherited': fontSizeInherited
        })}
      >
        {expireTime.hasOwnProperty('days') && (
          <>
            <Expire
              amount={expireTime.days}
              unitItem={fullUnit ? pluralizeDays : 'д'}
              fontSizeInherited
              theme={theme}
            />
            <span className="Expire-separator"></span>
          </>
        )}
        {expireTime.hasOwnProperty('days') && (
          <>
            <Expire
              amount={expireTime.hours}
              unitItem={fullUnit ? pluralizeHours : 'ч'}
              fontSizeInherited
              theme={theme}
            />
            <span className="Expire-separator"></span>
          </>
        )}
        {expireTime.hasOwnProperty('days') && (
          <>
            <Expire
              amount={expireTime.minutes}
              unitItem={fullUnit ? pluralizeMinutes : 'м'}
              fontSizeInherited
              theme={theme}
            />
            {detalized && (
              <>
                <span className="Expire-separator"></span>
                <Expire
                  amount={expireTime.seconds}
                  unitItem={fullUnit ? pluralizeSeconds : 'с'}
                  fontSizeInherited
                  theme={theme}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default CountDown;
