import * as TYPES from '../types/navigations';
import { createAction } from 'modules/utils/dux';

export default {
  add(table) {
    return createAction(TYPES.ADD, {
      table
    });
  },

  setLoaded() {
    return createAction(TYPES.SET_LOADED);
  }
};
