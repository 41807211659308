import { schema } from 'normalizr';
import { deserializeProduct } from 'modules/product/deserializers/product';

export default function build({ category, filterOption }) {
  return new schema.Entity(
    'product',
    {
      category: category,
      properties: [filterOption]
    },
    {
      idAttribute: 'slug',
      processStrategy: transformToEntity
    }
  );
}

export function transformToEntity(value) {
  return deserializeProduct(value);
}
