import React from 'react';
import { Link } from 'react-router-dom';

import AnimatedRouterLink from '../animated-link/animated-router-link';
import AnimatedLink from '../animated-link/animated-link';
import { getIndexLink } from 'routes/links';
import Media from 'react-media';

import './main-description-section.css';

const MainDescriptionSection = () => {
  return (
    <Media query="(max-width:360px)">
      {isLowMobile => (
        <div className="MainDescriptionSection">
          <div className="MainDescriptionSection-headColumn">
            <div className="MainDescriptionSection-logo">
              <Link to={getIndexLink()} className="MainDescriptionSection-logoLink">
                <div className="MainDescriptionSection-logoLinkImage">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173 30.8">
                    <path fill="#d65d8b" d="M100.7 0H73.1l13.8 13.8z" />
                    <path fill="#ffea47" d="M73.1 30.7h27.6L86.9 16.9z" />
                    <path d="M0 0h2.2l12.3 18.2L26.8 0H29v30.7h-2.3V4L14.5 21.7h-.1L2.2 4v26.7H0zM50.3 0h2.3v30.7h-2.3zM121.2 0h2.3v30.7h-2.3zM157.8 2.1h-13.1V0H173v2.1h-12.9v28.6h-2.3zM71.439 1.56L72.994.005l29.133 29.132-1.556 1.556z" />
                    <path d="M71.551 29.207L100.684.075l1.555 1.555-29.132 29.133z" />
                    <path fill="#7cc3e3" d="M71.5 1.6v27.6l13.8-13.8z" />
                    <path fill="#8072af" d="M102.2 29.2V1.6L88.4 15.4z" />
                    <g fill="#000000" fill-rule="nonzero">
                      <polygon points="0 0 2.2 0 14.5 18.2 26.8 0 29 0 29 30.7 26.7 30.7 26.7 4 14.5 21.7 14.4 21.7 2.2 4 2.2 30.7 0 30.7"></polygon>
                      <polygon points="50.3 0 52.6 0 52.6 30.7 50.3 30.7"></polygon>
                      <polygon points="121.2 0 123.5 0 123.5 30.7 121.2 30.7"></polygon>
                      <polygon points="157.8 2.1 144.7 2.1 144.7 0 173 0 173 2.1 160.1 2.1 160.1 30.7 157.8 30.7"></polygon>
                    </g>
                  </svg>
                </div>
              </Link>
            </div>

            <div className="MainDescriptionSection-titleWrapper">
              <h1 className="MainDescriptionSection-title">
                Mixit - официальный интернет-магазин российского бренда косметики
              </h1>
            </div>
          </div>

          <div className="MainDescriptionSection-descriptionColumn">
            <p>
              Миксит это не просто бренд, а настоящая лаборатория современной косметики. Мы делаем
              свои продукты с 2014 года. У нас есть собственное производство, где создаются
              инновационные разработки и формулы.
            </p>
            <p>
              Мы открыли более{' '}
              <b>
                <AnimatedRouterLink
                  href="/shops"
                  title="160 магазинов"
                  inline={isLowMobile}
                  noGap
                />
              </b>{' '}
              по всей России, ближайшем зарубежье и не хотим останавливаться на достигнутом. Любой
              может стать частью нашей команды, открыв свой магазин косметики Миксит в своём городе
              по
              <b>
                {' '}
                <AnimatedLink
                  href="https://franchise.mixit.ru/"
                  title="франшизе"
                  target="_blank"
                  inline={isLowMobile}
                />
              </b>
              .
            </p>
          </div>

          <div className="MainDescriptionSection-descriptionColumn">
            <p>
              В нашем магазине представлен широкий выбор косметики и средств для{' '}
              <b>
                <AnimatedRouterLink
                  href="category/hair"
                  title="ухода за волосами"
                  inline={isLowMobile}
                  noGap
                />
              </b>
              ,{' '}
              <b>
                <AnimatedRouterLink
                  href="/category/face"
                  title="кожей лица"
                  inline={isLowMobile}
                  noGap
                />
              </b>{' '}
              и{' '}
              <b>
                <AnimatedRouterLink href="/category/body" title="тела" inline={isLowMobile} noGap />
              </b>
              . Также в 2019 году{' '}
              <nobr>
                мы запустили
                <br />
                <b>
                  <AnimatedRouterLink
                    href="/category/decorate"
                    title="линейку декоративной косметики MIXIT ART"
                    inline={isLowMobile}
                    noGap
                  />
                </b>
                .{' '}
              </nobr>{' '}
              В неё входит 4 категории: брови, губы, глаза и лицо
            </p>
            <p>
              Мы всегда стараемся заботиться и радовать наших клиентов, поэтому в нашей компании
              есть подарочные сертификаты и проводятся{' '}
              <nobr>
                <b>
                  <AnimatedRouterLink
                    href="/special-offers"
                    title="акции и скидки на косметику"
                    inline={isLowMobile}
                    noGap
                  />
                </b>
                .
              </nobr>{' '}
              У вас всегда есть возможность сделать подарок себе и близким.
            </p>
          </div>
        </div>
      )}
    </Media>
  );
};

export default MainDescriptionSection;
