import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-hamburger.css';

class IconHamburger extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
        return (
          <Svg viewBox="0 0 24 24" className="IconHamburger">
            <path d="M22 5.93333H2V5H22V5.93333ZM22 12.4667H2V11.5333H22V12.4667ZM22 19H2V18.0667H22V19Z" />
          </Svg>
        );
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconHamburger">
            <path d="M31.6408 8.95146H5V8H31.6408V8.95146ZM31.6408 18.2757H5V17.3243H31.6408V18.2757ZM31.6408 27.6H5V26.6485H31.6408V27.6Z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconHamburger;
