import result from '../helpers/result-object';

export function normalize(apiCity) {
  if (!apiCity) {
    return result(null);
  }

  const { fias_id, ...rest } = apiCity;

  const id = getId(apiCity);

  if (!id) {
    return result(null);
  }

  const cities = {
    [id]: {
      fiasId: fias_id,
      ...rest
    }
  };

  return result(id, { cities });
}

export function getId(apiCity) {
  return apiCity.id;
}
