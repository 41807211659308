import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import OrderDetails from './order-details/order-details';
import OrderDetailsCompact from './order-details/order-details-compact';
import { Form } from 'modules/form/index';

import productShape from 'modules/product/prop-types/product-shape';
import PhoneNumber from 'modules/core/components/phone-number/phone-number';

import OrderTable from './table-thanks/table-thanks';
import Comment from './comment';

import './successful-order.css';

const { number, arrayOf, shape } = PropTypes;

class SuccessfulOrder extends Component {
  state = {
    expiredMilliseconds: null,
    expireAfter: null,

    expiredTime: {},
    expired: false
  };

  static propTypes = {
    /** Displayed products list **/
    goodsList: arrayOf(
      shape({
        product: productShape,
        amount: number.isRequired
      })
    )
  };

  static defaultProps = {
    comment: ''
  };

  render() {
    const {
      isLoading,
      order,
      comment,
      delivery,
      personalInfo,
      payment,
      goodsList,
      productsCost,
      deliveryCost,
      totalCost,
      requirePayment,
      deliveryMethod,
      currentDeliveryCost
    } = this.props;

    if (isLoading || !order) {
      return null;
    }

    const noRussia =
      deliveryMethod &&
      (deliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_BELARUS' ||
        deliveryMethod.name === 'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN');

    const deliveryPrice = noRussia ? currentDeliveryCost : deliveryCost;

    const _totalCost = noRussia ? totalCost + deliveryPrice : totalCost;

    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <Form>
            <div className="SuccessfulOrder-mainInfo">
              <div className="SuccessfulOrder-description">
                <b>Номер заказа: {order.id}.</b>{' '}
                {(!requirePayment && delivery && delivery.deliveryMethod.id === 12 && (
                  <div style={{ color: '#e95958' }}>
                    Пожалуйста, дождитесь SMS-уведомеления о готовности заказа. После этого будем
                    ждать вас в магазине MIXIT
                  </div>
                )) ||
                  'Статус будет отправлен на почту.'}
              </div>

              {!isMobile && (
                <div className="SuccessfulOrder-userInfoTable">
                  <OrderDetails delivery={delivery} personalInfo={personalInfo} payment={payment} />
                </div>
              )}

              {comment && (
                <Media query="(min-width: 841px)">
                  <Comment comment={comment} />
                </Media>
              )}

              <div className="SuccessfulOrder-orderList">
                <h2 className="SuccessfulOrder-orderListTitle">Ваш заказ</h2>
                <OrderTable
                  goodsList={goodsList}
                  productsCost={productsCost}
                  // deliveryCost={deliveryCost}
                  deliveryCost={deliveryPrice}
                  // totalCost={totalCost}
                  totalCost={_totalCost}
                  listId="successful-order"
                />
              </div>

              {comment && (
                <Media query="(max-width: 840px)">
                  <Comment comment={comment} isMobile />
                </Media>
              )}

              {isMobile && (
                <div className="SuccessfulOrder-userInfoTable">
                  <OrderDetailsCompact
                    personalInfo={personalInfo}
                    delivery={delivery}
                    payment={payment}
                  />
                </div>
              )}

              {!isMobile && (
                <Fragment>
                  <div className="SuccessfulOrder-contacts">
                    <div className="SuccessfulOrder-contactsTitle">
                      Будем рады ответить на Ваши вопросы
                    </div>
                    <div className="SuccessfulOrder-contactsPhone">
                      <PhoneNumber bold tel="8 (800) 550-98-70" />
                    </div>
                    <div className="SuccessfulOrder-contactsFooter">С любовью, Миксит</div>
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Media>
    );
  }
}

export default SuccessfulOrder;
