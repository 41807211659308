import { takeEvery, call, put } from 'redux-saga/effects';
import { SOCIAL } from '../ducks/types';
import * as SocialActions from '../ducks/actions/social';
import * as VkSdk from 'services/vk';

const VK_APP_ID = process.env.REACT_APP_VK_APP_ID;

export function* vkAuth() {
  yield takeEvery(SOCIAL.VK_AUTH_CALL, callVkAuth);
}

function* callVkAuth() {
  try {
    const response = yield call(VkSdk.oauth, VK_APP_ID);

    const authData = {
      accessToken: response.access_token,
      userId: response.user_id,
      expiresIn: response.expires_in,
      email: response.email
    };

    console.log(response);
    yield put(SocialActions.receiveVkAuth(authData));
  } catch (e) {
    console.warn(e);
  }
}
