import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import * as fromFilters from '../ducks/filters';

import FilterItem from '../components/category-filter-panel/filter-item/filter-item';

import isFunction from 'modules/utils/is-function';

const mapStateToProps = createStructuredSelector({
  filter: fromFilters.getFilterWithCheckedOptions
});

class FilterItemContainer extends Component {
  render() {
    const { filter, ...rest } = this.props;

    return (
      <FilterItem
        filter={filter}
        count={filter.multiple && this.getCount()}
        onChange={this.handleChange}
        {...rest}
      />
    );
  }

  getCount() {
    const { filter } = this.props;
    return filter.options.filter(item => item.checked).length;
  }

  handleChange = e => {
    const { onFilterChange, filter } = this.props;
    const { value, checked } = e.target;

    const checkedOptions = filter.options
      .filter(item => {
        if (item.slug === value) {
          return checked;
        }

        return filter.multiple && item.checked;
      })
      .map(item => item.slug);

    if (isFunction(onFilterChange)) {
      onFilterChange({
        checked,
        filterId: filter.id,
        option: value,
        options: [...filter.explicitOptions, ...checkedOptions]
      });
    }
  };
}

export default connect(mapStateToProps)(FilterItemContainer);
