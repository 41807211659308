import * as TYPES from '../types/account';
import { createAction, createActionError } from 'modules/utils/dux';

export default {
  rehydrate(account) {
    return createAction(TYPES.REHYDRATE, { account });
  },

  ready() {
    return createAction(TYPES.READY);
  },

  signOut() {
    return createAction(TYPES.SIGN_OUT);
  },

  signIn(email, password) {
    return createAction(TYPES.AUTHORIZATION_REQUEST, { email, password });
  },

  signUp(email, password, firstName) {
    return createAction(TYPES.REGISTRATION_REQUEST, {
      email,
      password,
      firstName
    });
  },

  authorizationSuccess(authorization, account, entities) {
    return createAction(TYPES.AUTHORIZATION_SUCCESS, {
      authorization,
      account,
      entities
    });
  },

  authorizationRenewRequest(authorization) {
    return createAction(TYPES.AUTHORIZATION_RENEW_REQUEST, { authorization });
  },

  authorizationRenewResponse(error, authorization) {
    if (error) {
      return createActionError(TYPES.AUTHORIZATION_RENEW_RESPONSE, {
        error
      });
    }
    return createAction(TYPES.AUTHORIZATION_RENEW_RESPONSE, { authorization });
  },

  authorizationError(message) {
    return createAction(TYPES.AUTHORIZATION_ERROR, { message });
  },

  authorizationReady(isAuthorized, account) {
    return createAction(TYPES.AUTHORIZATION_READY, { isAuthorized, account });
  },

  registrationError(message) {
    return createAction(TYPES.REGISTRATION_ERROR, { message });
  },

  registrationComplete(authorization, account, entities) {
    return createAction(TYPES.REGISTRATION_COMPLETE, {
      authorization,
      account,
      entities
    });
  }
};
