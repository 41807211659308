import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import cn from 'classnames';

import InputPrice from 'modules/core/components/input-price/input-price';
import Button from 'modules/core/components/button/button';
import Link from 'modules/core/components/link/short-link';
import priceFormatter from 'modules/ordering/formatters/price';
import Loader from 'modules/core/components/loader/loader';

import Slider from '../slider/slider';
// import BonusesConformationDialog from './conformation-dialog/conformation-dialog';

import './bonus-input.css';

const { number, func, bool } = PropTypes;

class BonusInput extends Component {
  state = {
    value: this.props.value || 0
  };

  static propTypes = {
    min: number,
    max: number,
    step: number,
    value: number,
    onApply: func,
    alwaysShowSlider: bool,
    disabled: bool
  };

  static defaultProps = {
    min: 0,
    step: 1,
    alwaysShowSlider: false,
    disabled: false
  };

  render() {
    const {
      alwaysShowSlider,
      disabled,
      isLoading,
      editing,
      isConfirmationPending,
      available,
      ...rest
    } = this.props;

    const { value } = editing ? this.props : this.state;

    if (isLoading) {
      return (
        <div className="BonusInput">
          <div className="BonusInput-loader">
            <Loader type="goo" />
          </div>
        </div>
      );
    }

    return (
      <Media query="(min-width: 1101px)">
        {isDesktop => (
          <div
            className={cn('BonusInput', {
              'BonusInput--editing': editing,
              'BonusInput--disabled': disabled
            })}
          >
            <div className="BonusInput-field">
              <InputPrice
                type="number"
                onChange={this.handleInputChange}
                appended={!isDesktop && this.renderMobileAction()}
                size={isDesktop ? 'normal' : 'small'}
                disabled={editing || disabled}
                selectable={!editing}
                {...rest}
                value={value}
              />
            </div>

            {isDesktop && <div className="BonusInput-action">{this.renderDesktopAction()}</div>}

            {(alwaysShowSlider || !editing) && isDesktop && (
              <div className="BonusInput-slider">
                <Slider
                  onChange={this.handleChange}
                  max={available}
                  {...rest}
                  value={value}
                  disabled={editing || disabled}
                />
              </div>
            )}
          </div>
        )}
      </Media>
    );
  }

  getAppended(render) {
    const { editing } = this.props;
    if (!render) {
      return null;
    }

    return editing ? this.renderUnAcceptButton() : this.renderAcceptButton();
  }

  renderMobileAction() {
    const { editing } = this.props;
    const { value } = this.state;

    const isDisabled = value === 0;

    if (isDisabled) {
      return null;
    }

    if (editing) {
      return (
        <div className="BonusInput-acceptWrapper">
          <button
            className="BonusInput-unAccept"
            onClick={this.handleUnApply}
            title={`Отменить списание ${priceFormatter(value)} руб. с бонусного счета`}
          >
            &times;
          </button>
        </div>
      );
    }

    return (
      <div className="BonusInput-acceptWrapper">
        <button
          className="BonusInput-accept"
          onClick={this.handleApply}
          disabled={isDisabled}
          title={
            isDisabled
              ? 'Введите желаемую сумму для списания'
              : `Списать с бонусного счета ${priceFormatter(value)} руб.`
          }
        >
          ок
        </button>
      </div>
    );
  }

  renderDesktopAction() {
    const { value } = this.state;
    const { disabled, editing } = this.props;

    const isDisabled = value === 0 || disabled;

    if (isDisabled) {
      return null;
    }

    return (
      <div className="BonusInput-desktopAction">
        {editing ? (
          <Link
            component="button"
            onClick={this.handleUnApply}
            title={`Отменить списание ${priceFormatter(value)} руб. с бонусного счета`}
          >
            отменить
          </Link>
        ) : (
          <Button
            title="Применить"
            variant={disabled ? 'secondary' : 'accent'}
            // inverted
            onClick={this.handleApply}
            disabled={isDisabled}
            caption={
              isDisabled
                ? 'Введите желаемую сумму для списания'
                : `Списать с бонусного счета ${priceFormatter(value)} руб.`
            }
          />
        )}
      </div>
    );
  }

  setValue(value) {
    this.setState({
      value
    });
  }

  handleInputChange = e => {
    const { value } = e.target;
    const { min, available } = this.props;

    const valueNumber = +value.replace(/\D/g, '');

    if (valueNumber > available) {
      this.setValue(available);
      return;
    }

    if (valueNumber < min) {
      this.setValue(min);
      return;
    }

    this.setValue(valueNumber);
  };

  handleChange = value => {
    this.setValue(value);
  };

  handleApply = () => {
    const { onApply } = this.props;
    const { value } = this.state;

    onApply({ value });
  };

  handleUnApply = () => {
    const { onUnApply } = this.props;

    onUnApply();
  };
}

export default BonusInput;
