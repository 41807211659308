import PropTypes from 'prop-types';

let { number, shape } = PropTypes;

export default shape({
  default: number,
  current: number,
  discount: shape({
    percentage: number,
    numeral: number
  })
});
