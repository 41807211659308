import React, { Component } from 'react';
import Loader from 'modules/core/components/loader/loader';

class IngredientDescription extends Component {
  render() {
    const { isLoading, description } = this.props;

    if (isLoading) {
      return (
        <div className="Ingredient-description">
          <Loader type="spin" size="small" />
        </div>
      );
    }

    return <div className="Ingredient-description">{description}</div>;
  }
}

export default IngredientDescription;
