import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import VideoDialog from 'modules/core/components/video-dialog/video-dialog';
import AddToWaitListDialog from 'modules/core/components/add-to-wait-list-dialog/add-to-wait-list-dialog';

import MainCard from './main-card/main-card';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

class Card extends Component {
  static propTypes = {
    product: PropTypes.any,
    variantSet: PropTypes.any,
    defaultVariant: PropTypes.any,
    weightList: PropTypes.any,
    // currentWeight: PropTypes.any,
    defaultWeight: PropTypes.any,
    colorList: PropTypes.any,
    // currentColor: PropTypes.any,
    defaultColor: PropTypes.any,
    sizeList: PropTypes.any,
    // currentSize: PropTypes.any,
    defaultSize: PropTypes.any,
    currentVariant: PropTypes.any,

    isInBasket: PropTypes.any,
    amount: PropTypes.number,

    currentColor: PropTypes.any,
    currentSize: PropTypes.any,
    currentWeight: PropTypes.any,

    onAddToBasket: PropTypes.func,
    onAmountChange: PropTypes.func,
    onColorChange: PropTypes.func,
    onWeightChange: PropTypes.func,
    onSizeChange: PropTypes.func,
    onAddToWaitingList: PropTypes.func
  };

  render() {
    const {
      slug,
      product,
      videoId,
      isAddToWaitListDialogOpened,
      isVideoDialogOpened,
      onDialogFormSubmit,
      onAddToWaitListDialogClose,
      onVideoDialogClose
    } = this.props;

    if (!product) {
      return null;
    }

    return (
      <Fragment>
        <ProductAnalyticsProvider
          productId={slug}
          listId="card"
          render={({ productRef }) => <MainCard {...this.props} productRef={productRef} />}
        />

        <VideoDialog
          show={isVideoDialogOpened}
          id={videoId}
          autoplay
          onClose={onVideoDialogClose}
        />

        <AddToWaitListDialog
          show={isAddToWaitListDialogOpened}
          onFormSubmit={onDialogFormSubmit}
          onClose={onAddToWaitListDialogClose}
        />
      </Fragment>
    );
  }
}

export default Card;
