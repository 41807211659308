import PropTypes from 'prop-types';
import ingredientDetailsShape from './ingredient-details-shape';
import mediaShape from 'modules/product/prop-types/media-shape';

const { string, number, shape, arrayOf } = PropTypes;

export default shape({
  id: number,
  name: string,
  title: string,
  media: arrayOf(mediaShape),
  type: string.isRequired,
  details: ingredientDetailsShape
});
