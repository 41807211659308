import React, { Component } from 'react';

import Media from 'modules/core/components/media/media';
import textSlicer from 'modules/utils/text-slicer';
import dateToString from 'modules/utils/date-string';
import Link from 'modules/core/components/link/short-link';
import { Link as RouterLink } from 'react-router-dom';
import { getArticleLink } from 'routes/links';

import articleShape from '../../prop-types/article-shape';

import './article-preview.css';

class ArticlePreview extends Component {
  static propTypes = {
    article: articleShape
  };

  render() {
    const { title, slug, createDate, description, cover } = this.props.article;

    return (
      <div className="ArticlePreview">
        <a href={`/magazine/${slug}`} className="ArticlePreview-imageLink">
          <Media
            className="ArticlePreview-image"
            media={cover}
            resizeWidth={400}
            stretch="horizontal"
            nocaption
          />
        </a>
        <div className="ArticlePreview-info">
          <time dateTime={createDate} className="ArticlePreview-date">
            {dateToString(createDate)}
          </time>
          <h2 className="ArticlePreview-title">
            <RouterLink
              title={title}
              to={getArticleLink(this.props.article)}
              className="ArticlePreview-titleLink"
            >
              {textSlicer(title, 120)}
            </RouterLink>
          </h2>
          <div className="ArticlePreview-content">{textSlicer(description, 240)}</div>
          <div title={title} className="ArticlePreview-toArticle">
            <Link href={`/magazine/${slug}`}>Читать далее</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticlePreview;
