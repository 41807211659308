import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
import ProductDialogContent from '../product-dialog-content/product-dialog-content';
import ProductOverviewContainer from '../../containers/product-overview';

class ProductDialog extends Component {
  render() {
    const { product, isInRecent, ...rest } = this.props;
    return (
      <Dialog {...rest} closeable>
        <ProductDialogContent>
          <ProductOverviewContainer
            slug={product.slug}
            onClose={this.props.onClose}
            isInRecent={isInRecent}
          />
        </ProductDialogContent>
      </Dialog>
    );
  }
}

export default ProductDialog;
