export default function normalize(topics) {
  let normalizedTopics = {};

  for (let i = 0; i < topics.length; i++) {
    const topic = topics[i];
    normalizedTopics = { ...normalizedTopics, [topic.slug]: topic };
  }

  return normalizedTopics;
}
