import React, { Component } from 'react';

import Dialog from 'modules/core/components/dialog/dialog';

import './subscribe-conformation-dialog.css';

class SubscribeConformationDialog extends Component {
  render() {
    const { onClose, email, ...rest } = this.props;
    return (
      <Dialog closeable {...rest} onClose={onClose}>
        <div className="SubscribeConformationDialog">
          <h1 className="SubscribeConformationDialog-title">Спасибо!</h1>
          <p className="SubscribeConformationDialog-info">
            Ваш email (<b>{email}</b>) был добавлен.
          </p>
        </div>
      </Dialog>
    );
  }
}

export default SubscribeConformationDialog;
