import React, { Component } from 'react';
import Content from 'modules/core/components/content/content';
import Media from 'modules/core/components/media/media';
import ListDescriptions from 'modules/core/components/list-description/list-description';
import ingredientShape from '../../prop-types/ingredient-details-shape';
import './ingredient-details.css';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

class IngredientDetails extends Component {
  static propTypes = {
    ingredient: ingredientShape
  };

  render() {
    const { ingredient } = this.props;

    if (!ingredient) {
      return null;
    }

    return (
      <div className="IngredientDetails">
        <div className="IngredientDetails-previewCol">
          <div className="IngredientDetails-visualGroup">
            <HideErrorBoundary>
              <div className="IngredientDetails-imageHolder">
                <Media
                  className="IngredientDetails-image"
                  media={ingredient.details.media}
                  alt=""
                  stretch="horizontal"
                  nocaption
                />
              </div>
            </HideErrorBoundary>
            {ingredient.details.main_property && (
              <div className="IngredientDetails-propertyHolder">
                <div className="IngredientDetails-line" />
                <h3 className="IngredientDetails-property">
                  {ingredient.details.main_property.title}
                </h3>
              </div>
            )}
          </div>
          <div className="SectionIngredients-contentGroup">
            <ListDescriptions
              list={ingredient.details.properties.map(({ property_type, value }) => ({
                title: property_type.title,
                value: value
              }))}
            />
          </div>
        </div>
        <div className="IngredientDetails-descriptionCol">
          <Content html={ingredient.details.description} />

          <h2>Чем полезен для лица</h2>
          <p>{ingredient.details.description_parts.face_effect}</p>
          <h2>Чем полезен для тела</h2>
          <p>{ingredient.details.description_parts.body_effect}</p>
          <h2>Чем полезен для волос</h2>
          <p>{ingredient.details.description_parts.hair_effect}</p>
        </div>
      </div>
    );
  }
}

export default IngredientDetails;
