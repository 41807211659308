const sortOptions = {
  1: {
    id: 1,
    active: false,
    name: 'SORT_TYPE/POPULARITY',
    order: 'SORT_ORDER/DESC',
    title: 'По популярности'
  },
  2: {
    id: 2,
    active: false,
    name: 'SORT_TYPE/PROMOTION',
    order: 'SORT_ORDER/DESC',
    title: 'Акционные'
  },
  3: {
    id: 3,
    active: true,
    name: 'SORT_TYPE/DATE',
    order: 'SORT_ORDER/DESC',
    title: 'По новизне'
  },
  4: {
    id: 4,
    active: false,
    name: 'SORT_TYPE/PRICE',
    order: 'SORT_ORDER/ASC',
    title: 'От дешевых к дорогим'
  },
  5: {
    id: 5,
    active: false,
    name: 'SORT_TYPE/PRICE',
    order: 'SORT_ORDER/DESC',
    title: 'От дорогих к дешевым'
  }
};

export default sortOptions;

export function serializeSortType(sortType) {
  const map = {
    'SORT_TYPE/PRICE': 'price',
    'SORT_TYPE/DATE': 'date',
    'SORT_TYPE/PROMOTION': 'promotion',
    'SORT_TYPE/POPULARITY': 'popularity'
  };

  return map[sortType];
}

export function serializeSortOrder(sortOrder) {
  const map = {
    'SORT_ORDER/ASC': 'asc',
    'SORT_ORDER/DESC': 'desc'
  };

  return map[sortOrder];
}

export const COMPARISON_FUNCTION = {
  1: (a, b) => 0,
  2: (a, b) => b.price.discount - a.price.discount,
  3: (a, b) => a.price.createDate - b.price.createDate,
  4: (a, b) => a.price.current - b.price.current,
  5: (a, b) => -a.price.current + b.price.current
};
