import React from 'react';
import cn from 'classnames';

import LandingButton from '../../landing-button/landing-button';
import { Link } from 'react-router-dom';

import './timetable-item.css';

const getLandingLink = slug => `/landing/${slug}`;

export default function TimetableItem({ event, colors, light }) {
  const [day, month] = event.date.split(' ');
  const dateStyle = { color: colors[0] };
  const { city, place, address } = event.location;

  return (
    <div className={cn('TimetableItem', { 'TimetableItem--light': light })}>
      <div className="TimetableItem-holder">
        <div className="TimetableItem-date" style={dateStyle}>
          <div className="TimetableItem-dateHolder">
            <div className="TimetableItem-day">{day}</div>
            <div className="TimetableItem-month">{month}</div>
          </div>
        </div>
        <div className="TimetableItem-location">
          <div className="TimetableItem-city">{city}</div>
          <div className="TimetableItem-place">{place}</div>
          <div className="TimetableItem-address">{address}</div>
        </div>
        <div className="TimetableItem-action">
          <LandingButton
            component={Link}
            title="подробнее"
            colors={colors}
            fullWidth
            to={getLandingLink(event.slug)}
          />
        </div>
      </div>
    </div>
  );
}
