import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createReducer, handle, createAction } from 'modules/utils/dux';

export const TYPES = {
  REQUEST: 'core/banners/REQUEST',
  RESPONSE: 'core/banners/RESPONSE',
  CANCEL_REQUEST: 'core/banners/CANCEL_REQUEST'
};

export const Actions = {
  request() {
    return createAction(TYPES.REQUEST);
  },

  response(banners, entities) {
    return createAction(TYPES.RESPONSE, { banners, entities });
  },

  cancelRequest() {
    return createAction(TYPES.CANCEL_REQUEST);
  }
};

const table = createReducer(
  handle(TYPES.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.banner) {
      return state;
    }

    return {
      ...state,
      ...entities.banner
    };
  })
);

const isLoading = createReducer();

const list = createReducer(
  handle(TYPES.RESPONSE, (state, { banners }, error) => {
    if (error || !banners) {
      return state;
    }

    return banners;
  })
);

export default combineReducers({
  table: table(),
  isLoading: isLoading(true),
  list: list([])
});

export const fromBanner = {
  getIsLoading: state => state.isLoading,
  getList: state => state.list,
  getTable: state => state.table,
  getItemById: createSelector(
    state => state.table,
    (_, id) => id,
    (table, id) => table[id] || null
  )
};

const getRoot = state => state.core.banner;

export const getIdList = createSelector(
  getRoot,
  fromBanner.getList
);
export const getList = createSelector(
  getRoot,
  getIdList,
  (state, list) => list.map(id => fromBanner.getItemById(state, id))
);
export const getIsLoading = createSelector(
  getRoot,
  fromBanner.getIsLoading
);
