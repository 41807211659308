import React, { Component } from 'react';
import { withRouter } from 'react-router';

import IconContainer from '../icon-container/icon-container';
import { IconAngleArrowLeft } from '../icons/index';

import './navigation-back-button.css';

class NavigationBackButton extends Component {
  render() {
    return (
      <button type="button" className="NavigationBackButton" onClick={this.handleButtonClick}>
        <span className="NavigationBackButton-icon">
          <IconContainer size="tiny">
            <IconAngleArrowLeft />
          </IconContainer>
        </span>
        <span className="NavigationBackButton-title">Назад</span>
      </button>
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  handleButtonClick = () => {
    this.goBack();
  };
}

export default withRouter(NavigationBackButton);
