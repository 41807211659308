import React, { Component } from 'react';
import ColorSwitch from '../color-switch/color-switch';
import './color-choice.css';

class ColorChoice extends Component {
  render() {
    const { selected, ...rest } = this.props;

    return (
      <div className="ColorChoice">
        {this.renderTitle()}
        <div className="ColorChoice-switch">
          <ColorSwitch
            {...rest}
            selected={selected ? selected.id : null}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }

  renderTitle() {
    const { selected } = this.props;

    if (!selected) return null;

    return (
      <div className="ColorChoice-title">
        Цвет: <span className="ColorChoice-value">{selected.title.toLowerCase()}</span>
      </div>
    );
  }

  handleChange = optionId => {
    const { onChange, options } = this.props;

    const currentOption = options.filter(item => item.id === optionId)[0];

    onChange(currentOption);
  };
}

export default ColorChoice;
