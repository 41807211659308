import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromProfile from '../ducks/profile';
import * as fromOrders from 'modules/ordering/ducks/orders';

import OrderScene from '../components/order-in-detail-scene/order-in-detail-scene';

const mapState = createStructuredSelector({
  isLoading: fromProfile.getIsLoading,
  order: fromOrders.getItemById,
  delivery: fromOrders.getDeliveryById,
  personalInfo: fromOrders.getPersonalInfoById,
  payment: fromOrders.getPaymentById,
  goodsList: fromOrders.getGoodsListById,
  deliveryCost: fromOrders.getDeliveryCostById,
  discount: fromOrders.getDiscountById,
  productsCost: fromOrders.getProductsCostById,
  totalCost: fromOrders.getTotalCostById
});

class OrderSceneContainer extends Component {
  render() {
    return <OrderScene {...this.props} />;
  }
}

export default connect(mapState)(OrderSceneContainer);
