import { createSelector } from 'reselect';
import { matchPath } from "react-router-dom";

export const getRouter = state => state.router;

export const getPathName = createSelector(getRouter, router => router.location.pathname);

export const getRouterState = createSelector(getRouter, router => router.location.state);

// routerHistory

export const getRouterHistory = state => state.core.routerHistory;
export const getRouterHistoryLength = createSelector(getRouterHistory, history => history.length);


export const getPreviousLocation = createSelector(getRouterHistory, getRouterHistoryLength, (history, length) => {
   // const historyLength = history.length;
   const latIndex = length - 1;
   const preLastIndex = latIndex - 1;

   const preLastLocation = history[preLastIndex] || null;

   return preLastLocation ;
});

export const getIsPreviousProductPage = createSelector(getPreviousLocation, (histiryItem)=>{
   if(!histiryItem) {
      return false;
   }

   const isMatched = Boolean(matchPath(histiryItem.location.pathname, {
      path: "/product"
   }))
   return isMatched;
} )