import getTopElementPosition from './get-top-element-position';

export default function getMaxScroll(element) {
  const topElementPosition = getTopElementPosition(element);

  const elementHeight = element.getBoundingClientRect().height;

  const screenHeight = window.innerHeight;

  const maxScroll = elementHeight + topElementPosition - screenHeight;

  return maxScroll;
}
