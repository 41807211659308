import React, { Component } from 'react';
import Media from 'react-media';

import Dialog from '../dialog/dialog';
import DialogGrid from '../dialog-grid/dialog-grid';
import DialogSection from '../dialog/dialog-section/dialog-section';
import CallToAuth from '../call-to-auth/call-to-auth';
import RegistrationFormContainer from '../../containers/registration-form';

import './registration-dialog.css';

class RegistrationDialog extends Component {
  render() {
    const { onAuthOpen, onClose, formErrors, onSubmit, defaultEmailValue, ...rest } = this.props;

    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="RegistrationDialog-content ">
          <Media query="(min-width: 1101px)">
            {isDesktop =>
              isDesktop ? (
                <DialogGrid>
                  {this.renderCallToAuthColumn()}

                  <DialogGrid.Column>
                    <DialogSection title="Регистрация">
                      <RegistrationFormContainer
                        onAuthOpen={onAuthOpen}
                        onSubmit={onSubmit}
                        defaultEmailValue={defaultEmailValue}
                      />
                    </DialogSection>
                  </DialogGrid.Column>
                </DialogGrid>
              ) : (
                <DialogSection title="Регистрация">
                  <RegistrationFormContainer
                    onAuthOpen={onAuthOpen}
                    onSubmit={onSubmit}
                    defaultEmailValue={defaultEmailValue}
                  />
                </DialogSection>
              )
            }
          </Media>
        </div>
      </Dialog>
    );
  }

  renderCallToAuthColumn = () => {
    const { onAuthOpen } = this.props;
    return (
      <DialogGrid.Column>
        <DialogSection title="Уже зарегистрированы?">
          <CallToAuth onAuthOpen={onAuthOpen} />
        </DialogSection>
      </DialogGrid.Column>
    );
  };
}

export default RegistrationDialog;
