import React, { Component } from 'react';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowLeft } from 'modules/core/components/icons';

import './button-back.css';

class ButtonBack extends Component {
  render() {
    const { ...buttonProps } = this.props;
    return (
      <button type="button" className="CategoryPanel_ButtonBack" {...buttonProps}>
        <span className="CategoryPanel_ButtonBack-icon">
          <IconContainer inline>
            <IconAngleArrowLeft />
          </IconContainer>
        </span>

        <span className="CategoryPanel_ButtonBack-title">Назад</span>
      </button>
    );
  }
}

export default ButtonBack;
