import React, { Component } from 'react';

import cn from 'classnames';

import Overlay from './overlay';

import './bottom-sheet.css';

class BottomSheet extends Component {
  render() {
    const { header, children, footer, withBorder, ...rest } = this.props;

    return (
      <Overlay {...rest}>
        <div
          className={cn('BottomSheet', {
            'BottomSheet--withBorder': withBorder
          })}
        >
          {header && <div className="BottomSheet-header">{header}</div>}

          <div className="BottomSheet-body">{children}</div>

          {footer && <div className="BottomSheet-footer">{footer}</div>}
        </div>
      </Overlay>
    );
  }
}

export default BottomSheet;
