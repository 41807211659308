import React, { Component } from 'react';

class NavigationDrawerFooter extends Component {
  render() {
    const { children } = this.props;
    return <div className="NavigationDrawer-footer">{children}</div>;
  }
}

export default NavigationDrawerFooter;
