import { createSelector } from 'reselect';
import * as fromOrders from './orders';
import * as fromProducts from 'modules/product/ducks/products';

export const getSerializedGoodsListById = createSelector(
  fromOrders.getGoodsListById,
  fromProducts.getFindProductBySlug,
  (goodsList, findProduct) => {
    if (!goodsList) {
      return null;
    }

    return goodsList.map(item => ({
      ...item,
      product: findProduct(item.product)
    }));
  }
);
