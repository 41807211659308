import React, { Component } from 'react';
import cn from 'classnames';
import SearchInput from '../search-input/search-input';

import './search-field.css';

class SearchField extends Component {
  render() {
    const {
      wide,
      placeholder,
      focused,
      suggestions,
      onBlur,
      onFocus,
      onSubmit,
      onChange,
      onProductSelect
    } = this.props;

    return (
      <div className={cn('SearchField', { 'SearchField--wide': wide })}>
        <SearchInput
          options={suggestions}
          placeholder={placeholder}
          focused={focused}
          onBlur={onBlur}
          onFocus={onFocus}
          onSubmit={onSubmit}
          onChange={onChange}
          onProductSelect={onProductSelect}
        />
      </div>
    );
  }
}

export default SearchField;
