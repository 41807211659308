import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Media from 'react-media';

import UserPhoto from '../user-photo/user-photo';

import * as routes from '../../constants/routes';

import isFunction from 'modules/utils/is-function';

import './user-overview.css';

const { shape, number, string, oneOf, bool } = PropTypes;

class UserOverview extends Component {
  static propTypes = {
    /** Данные о пользователе */
    profile: shape({
      /** Полное имя */
      fullName: string,

      /** Фото пользователя */
      photo: string,

      /** Заполненность профиля */
      completeness: number
    }).isRequired,

    direction: oneOf(['row', 'column']),

    nameWeight: oneOf(['normal', 'bold']),

    withEmail: bool
  };

  static defaultProps = {
    direction: 'column',
    nameWeight: 'normal'
  };

  render() {
    const { profile, direction, nameWeight, email, fullSizeInteractive } = this.props;

    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <div
            className={cn(
              'UserOverview',
              `UserOverview--direction-${direction}`,
              `UserOverview--nameWeight-${nameWeight}`,
              {
                'UserOverview--fullSizeInteractive': fullSizeInteractive
              }
            )}
          >
            <Link
              className="UserOverview-visualGroup"
              to={isMobile ? routes.root.path : routes.general.path}
              title="Перейти в мой профиль"
              onClick={this.handleClick}
            >
              <div className="UserOverview-image">
                <UserPhoto
                  photo={profile.photo}
                  completeness={profile.completeness}
                  name={profile.fullName}
                />
              </div>
            </Link>

            <div className="UserOverview-contentGroup">
              <div className="UserOverview-nameHolder">
                <Link
                  className="UserOverview-name"
                  to={isMobile ? routes.root.path : routes.general.path}
                  title="Перейти в мой профиль"
                  onClick={this.handleClick}
                >
                  <div className="UserOverview-nameTitle">{profile.fullName}</div>
                </Link>
              </div>

              {email && (
                <div className="UserOverview-emailHolder">
                  <div className="UserOverview-email">{email}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </Media>
    );
  }

  handleClick = () => {
    const { onUserOverviewClick } = this.props;

    if (isFunction(onUserOverviewClick)) {
      onUserOverviewClick();
    }
  };
}

export default UserOverview;
