import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';

import './section-next-article.css';

const { any } = PropTypes;

class SectionNextArticle extends Component {
  static propTypes = {
    children: any.isRequired
  };

  render() {
    const { children } = this.props;
    return (
      <section className="SectionNextArticle">
        <Container>{children}</Container>
      </section>
    );
  }
}

export default SectionNextArticle;
