import { mixitApi } from 'services/mixit';

import { takeEvery, put } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import normalizeMassMedia from 'services/mixit/adapters/mass-media';
import * as MASS_MEDIA_TYPES from '../ducks/types';
import massMediaActions from '../ducks/actions';

function* massMediaWatcher() {
  yield takeEvery(MASS_MEDIA_TYPES.REQUEST, request);
}

function* request(action) {
  const { queryParams } = action.payload;

  const massMediaApi = mixitApi().massMedia();

  try {
    const massMediaResponse = yield call(massMediaApi.item, queryParams);

    const normalizeMassMediaReponse = normalizeMassMedia(massMediaResponse.data);

    yield put(massMediaActions.response(normalizeMassMediaReponse));
  } catch (error) {
    yield put(massMediaActions.error(error));
  }
}

export default function register(middleware) {
  return () => Object.values({ massMediaWatcher }).map(middleware.run);
}
