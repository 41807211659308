import React, { Component } from 'react';

class FrameStatic extends Component {
  render() {
    const style = this.getStyles();
    const { container, children } = this.props;

    return React.cloneElement(container, {
      style,
      children
    });
  }

  getStyles() {
    return {
      display: 'flex',
      verticalAlign: 'top'
    };
  }
}

export default FrameStatic;
