import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import './accordion-notification.css';

const { string, number, oneOfType } = PropTypes;

export default class AccordionNotification extends Component {
  static propTypes = {
    text: oneOfType([string, number])
  };

  render() {
    const { text } = this.props;
    return <div className="AccordionNotification">{text}</div>;
  }
}
