import React, { Component } from 'react';
import './icon-duration.css';

class IconDuration extends Component {
  render() {
    return (
      <svg className="IconDuration" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M18.8 27.6c-.2.1-.5.1-.7.2-.6.1-1 .7-.9 1.3.1.3.2.5.5.7.2.2.6.2.9.2.3-.1.6-.1.8-.2.6-.2 1-.8.8-1.4-.1-.6-.7-1-1.4-.8zm8.4-15.2c.1.2.2.4.4.6.3.2.7.3 1 .1.6-.2.9-.8.7-1.4-.1-.3-.2-.5-.3-.8-.2-.6-.9-.9-1.5-.6-.6.2-.9.9-.6 1.5.2.1.2.4.3.6zM22.5 26c-.2.1-.4.3-.6.4-.5.3-.7 1-.4 1.5.1.1.2.3.3.4.3.2.8.3 1.2.1.2-.1.5-.3.7-.5.5-.3.7-1 .3-1.6-.3-.6-1-.7-1.5-.3zm7.6-10.4c0-.6-.6-1.1-1.2-1.1s-1.1.6-1.1 1.2v.7c0 .4.2.7.5 1 .2.1.4.2.6.2.6 0 1.1-.5 1.2-1.1v-.9zM27 23c-.5-.4-1.2-.3-1.6.2-.1.2-.3.4-.4.6-.4.5-.3 1.2.1 1.6 0 0 .1 0 .1.1.5.3 1.1.3 1.5-.2.2-.2.4-.4.5-.7.4-.5.3-1.3-.2-1.6zm1.7-4.1c-.6-.2-1.2.1-1.4.7-.1.2-.2.5-.2.7-.2.5 0 1 .4 1.3.1.1.2.1.2.1.6.2 1.2-.1 1.5-.7.1-.3.2-.5.3-.8.1-.5-.2-1.1-.8-1.3zm-14.8 8.8c-1-.2-2-.5-2.9-.9l-.6-.3c-.4-.2-.8-.4-1.1-.7-5.4-3.8-6.7-11.2-3-16.5.8-1.2 1.8-2.1 2.9-2.9 3.9-2.7 9.1-2.9 13.3-.1l-.9 1.3c-.2.4-.1.6.3.6l3.8-.3c.4 0 .7-.4.6-.8l-1-3.7c-.1-.4-.4-.5-.7-.1l-.9 1.3c-3-2-6.6-2.8-10.2-2.2-.4.1-.7.1-1.1.2-3 .7-5.7 2.5-7.7 5.1v.1c-.1 0-.1.1-.2.2s-.2.3-.3.4v.1C2.6 11 1.8 13.8 2 16.7c0 .3 0 .6.1.9v.1c0 .3.1.6.1.9.4 2.8 1.8 5.4 3.9 7.5.6.6 1.2 1.1 1.8 1.5 1.7 1.2 3.6 2 5.6 2.3.6.1 1.2-.3 1.3-.9.1-.6-.3-1.2-.9-1.3z" />
        <path d="M15.1 7.7c-.5 0-.9.4-.9.9v9.1l8.3 4.3c.1.1.3.1.4.1.3 0 .7-.2.8-.5.2-.4.1-1-.4-1.2L16 16.6v-8c0-.5-.4-.9-.9-.9z" />
      </svg>
    );
  }
}

export default IconDuration;
