export const SHOW = 'profile/subscriptions/SHOW';
export const ADD = 'profile/subscriptions/ADD';

export const SUBSCRIBE_CHANNEL = 'profile/subscriptions/SUBSCRIBE_CHANNEL';

export const UNSUBSCRIBE_CHANNEL = 'profile/subscriptions/UNSUBSCRIBE_CHANNEL';

export const SUBSCRIBE_TOPIC = 'profile/subscriptions/SUBSCRIBE_TOPIC';
export const SUBSCRIBE_TOPIC_ERROR = 'profile/subscriptions/SUBSCRIBE_TOPIC_ERROR';

export const UNSUBSCRIBE_TOPIC = 'profile/subscriptions/UNSUBSCRIBE_TOPIC';
export const UNSUBSCRIBE_TOPIC_ERROR = 'profile/subscriptions/UNSUBSCRIBE_TOPIC_ERROR';
