import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { getBasketLink, getCategoryLink } from 'routes/links';

import ProductPreview from 'modules/product/components/product-preview/product-preview';
import LockScroll from 'modules/core/components/lock-scroll/lock-scroll';
import Dropdown from 'modules/core/components/dropdown/dropdown';
import Button from 'modules/core/components/button/button';
import plural from 'modules/utils/plural';
import ScrollShadow from 'modules/core/components/scroll-shadow';
import ScrollEdges from 'modules/core/components/scroll-edges/scroll-edges';

import Price from '../price-formatted/price-formatted';
import PriceFormatted from '../price-formatted/price-formatted';

import './basket-dropdown.css';

class BasketDropdown extends Component {
  static propTypes = {
    ...Dropdown.propTypes,

    productList: PropTypes.any.isRequired,
    productsCost: PropTypes.number,
    deliveryCost: PropTypes.number,
    removeProduct: PropTypes.func.isRequired
  };

  static defaultProps = {
    ...Dropdown.defaultProps
  };

  render() {
    const { productList, isBasketFetching, ...dropdownProps } = this.props;

    const hadToLockScroll = dropdownProps.overlay && dropdownProps.show;

    return (
      <Fragment>
        {hadToLockScroll && <LockScroll />}
        <Dropdown {...dropdownProps}>
          <div className="BasketDropdown">
            {productList.length > 0 ? (
              <Fragment>
                {this.renderHeader()}
                {this.renderList()}
                {this.renderPriceGroup()}
                {this.renderActionGroup()}
              </Fragment>
            ) : (
              this.renderEmpty()
            )}
          </div>
        </Dropdown>
      </Fragment>
    );
  }

  renderPriceGroup() {
    const { productsCost } = this.props;
    return (
      <div className="BasketDropdown-totalGroup">
        {productsCost !== null && (
          <div className="BasketDropdown-priceItem">
            <div className="BasketDropdown-priceText">Стоимость товаров:</div>
            <div className="BasketDropdown-priceAmount">
              <Price number={productsCost} />
            </div>
          </div>
        )}
        {/* TODO: Add estimate delivery cost */}
        {/* {deliveryCost !== null && (
          <div className="BasketDropdown-priceItem">
            <div className="BasketDropdown-priceText">Доставка:</div>
            <div className="BasketDropdown-priceAmount">
              <Price number={deliveryCost} variant="secondary" />
            </div>
          </div>
        )} */}
        {/* TODO: Add full price after delivery estimate */}
        {/* {totalCost !== null && (
          <div className="BasketDropdown-priceItemTotal">
            <div className="BasketDropdown-priceText">Итого:</div>
            <div className="BasketDropdown-priceAmount">
              <Price number={totalCost} />
            </div>
          </div>
        )} */}
      </div>
    );
  }

  renderHeader() {
    const { productsCount } = this.props;
    return (
      <div className="BasketDropdown-header">
        <h3 className="BasketDropdown-title">
          В корзине {productsCount} {plural(productsCount, ['товар', 'товара', 'товаров'])}
        </h3>
      </div>
    );
  }

  renderActionGroup() {
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <div className="BasketDropdown-actionGroup">
            <div className="BasketDropdown-action">
              <Button
                component={Link}
                to={getBasketLink()}
                title="В корзину"
                variant="primary"
                expanded
                size={isMobile ? 'small' : 'normal'}
                onClick={this.handleCartButtonClick}
              />
            </div>
            {/* <div className="BasketDropdown-action">
          <Button
            title="Быстрый заказ"
            expanded
            onClick={this.props.onQuickOrderSubmit}
          />
        </div> */}
          </div>
        )}
      </Media>
    );
  }

  renderList(item, index) {
    const { productList, changeAmount, withCounter } = this.props;

    return (
      <ScrollEdges shadowTopStyle={{}} shadowBottomStyle={{}}>
        {(contentElementRef, handleScroll, hasScrollTop, hasScrollBottom) => {
          return (
            <ScrollShadow.Container className="BasketDropdown-listHolder">
              <ScrollShadow.Top active={hasScrollTop} />
              <div className="BasketDropdown-list" ref={contentElementRef} onScroll={handleScroll}>
                {productList.map(item => (
                  <div key={item.uid} className="BasketDropdown-item">
                    <ProductPreview
                      product={item.product}
                      amount={item.amount}
                      price={item.price}
                      isGift={item.gift}
                      onRemove={this.handleRemoveProduct(item)}
                      onAmountChange={amount =>
                        changeAmount(item, amount, {
                          previousAmount: item.amount
                        })
                      }
                      withCounter={withCounter}
                      listId="basket-dropdown"
                    />
                  </div>
                ))}
              </div>
              <ScrollShadow.Bottom active={hasScrollBottom} />
            </ScrollShadow.Container>
          );
        }}
      </ScrollEdges>
    );
  }

  renderEmpty() {
    return (
      <div className="BasketDropdown-empty">
        <div className="BasketDropdown-emptyTitle">Упс! Корзина пуста :(</div>
        <div className="BasketDropdown-emptyMesage">
          <div className="BasketDropdown-emptyParagraph">Специальное предложение!</div>
          <div className="BasketDropdown-emptyParagraph">
            Наборы для лица и тела всего от{' '}
            <b>
              <PriceFormatted number={200} />
            </b>
          </div>
        </div>
        <div className="BasketDropdown-emptyAction">
          <Button
            component={Link}
            to={getCategoryLink({ slug: 'gift-sets' })}
            title="Посмотреть"
            variant="primary"
            onClick={this.handleCategoryButtonClick}
          />
        </div>
      </div>
    );
  }

  close() {
    this.props.onChange(false);
  }

  handleCartButtonClick = () => {
    this.close();
  };

  handleCategoryButtonClick = () => {
    this.close();
  };

  handleRemoveProduct = (item, changeContentHeight) => () => {
    const { removeProduct } = this.props;

    removeProduct(item);
  };
}

export default BasketDropdown;
