import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Feedback from '../ducks/actions/email-feedback';
import * as fromEmailFeedback from '../ducks/email-feedback';

import EmailFeedback from '../components/email-feedback/email-feedback';

const mapStateToProps = createStructuredSelector({
  variant: fromEmailFeedback.getVariant
});

const mapDispatchToProps = {
  submit: Feedback.submit
};

const COMMENT_OPTION_VALUE = 'comment';

class EmailFeedbackContainer extends Component {
  state = {
    selectedOption: null,
    comment: ''
  };

  render() {
    const { variant } = this.props;
    const { selectedOption, comment } = this.state;

    const isCommentOptionSelected = selectedOption && selectedOption.value === COMMENT_OPTION_VALUE;
    const isCommentExist = isCommentOptionSelected && comment;

    const isButtonDisabled = !selectedOption || (isCommentOptionSelected && !isCommentExist);

    return (
      <EmailFeedback
        variant={variant}
        isButtonDisabled={isButtonDisabled}
        isCommentOptionSelected={isCommentOptionSelected}
        onOptionSelect={this.handleOptionSelect}
        onCommentChange={this.handleCommentChange}
        onSubmit={this.handleSubmit}
      />
    );
  }

  setSelectedOption(selectedOption) {
    this.setState({ selectedOption });
  }

  handleOptionSelect = option => {
    this.setSelectedOption(option);
    this.clearComment();
  };

  clearComment() {
    this.setState({ comment: '' });
  }

  changeComment(comment) {
    this.setState({ comment });
  }

  handleCommentChange = comment => {
    this.changeComment(comment);
  };

  handleSubmit = () => {
    const { variant, submit } = this.props;
    const { selectedOption, comment } = this.state;

    submit({ variant: variant.title, option: selectedOption, comment });
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailFeedbackContainer);
