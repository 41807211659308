import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './category-navigation-panel.css';

import Option from './category-navigation-panel-column';

const { element } = PropTypes;

export default class CategoryNavigationPanel extends Component {
  static propTypes = {
    prop: element
  };

  static Column = Option;

  render() {
    const { children } = this.props;
    return <div className="CategoryNavigationPanel">{children}</div>;
  }
}
