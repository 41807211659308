import React, { Component } from 'react';
import PropTypes from 'prop-types';

import plural from 'modules/utils/plural';

import Link from 'modules/core/components/link/short-link';
import priceFormatter from 'modules/ordering/formatters/price';
import InfoSign from 'modules/review/components/reply-form/info-sign/info-sign';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Loader from 'modules/core/components/loader/loader';

import './bonus-input-heder.css';

const { number, bool } = PropTypes;

const BONUS_PLURAL_STRINGS = ['бонусный', 'бонусных', 'бонусных'];
const ROUBLE_PLURAL_STRINGS = ['рубль', 'рубля', 'рублей'];

class BonusInputHeader extends Component {
  static propTypes = {
    available: number,
    value: number,
    editing: bool
  };

  render() {
    const { disabled, editing } = this.props;

    return (
      <div className="BonusInputHeader">
        {editing ? this.bonusAcceptedAmountInfo() : this.bonusGeneralAmountInfo()}
        {!!disabled && (
          <div className="BonusInputHeader-info">
            <Tooltip content={this.renderLabelInfo()} showDelay={0}>
              <InfoSign />
            </Tooltip>
          </div>
        )}
        {editing && this.renderAction()}
      </div>
    );
  }

  renderLabelInfo() {
    return (
      <div className="BonusInputHeader-infoLabel">
        Чтобы воспользоваться бонусами, отмените промокод
      </div>
    );
  }

  bonusGeneralAmountInfo() {
    const { available } = this.props;
    return `На счету ${priceFormatter(available)} ${plural(
      available,
      BONUS_PLURAL_STRINGS
    )} ${plural(available, ROUBLE_PLURAL_STRINGS)}`;
  }

  bonusAcceptedAmountInfo() {
    const { value, available } = this.props;
    return `Будет списано ${priceFormatter(value)} из ${priceFormatter(available)} бонусных рублей`;
  }

  renderAction() {
    const { value, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="BonusInputHeader-loader">
          <Loader type="goo" />
        </div>
      );
    }

    return (
      <div className="BonusInputHeader-action">
        <Link
          component="button"
          onClick={this.handleUnApply}
          title={`Отменить списание ${priceFormatter(value)} руб. с бонусного счета`}
        >
          отменить
        </Link>
      </div>
    );
  }

  handleUnApply = () => {
    const { onUnApply } = this.props;

    onUnApply();
  };
}

export default BonusInputHeader;
