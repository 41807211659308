import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dropdown from 'modules/core/components/dropdown/dropdown';
import * as routes from '../../constants/routes';
import { IconPackage, IconLock } from 'modules/core/components/icons';
import NavigationLink from '../navigation/navigation-link/navigation-link';
import IconExit from 'modules/core/components/icons/icon-exit/icon-exit';
import Link from 'modules/core/components/link/short-link';
import ProfileAvatar from 'modules/profile/components/profile-avatar/profile-avatar';
import { getProfileLink } from 'routes/links';

import * as fromProfile from '../../ducks/profile';
import AccountActions from 'modules/core/ducks/actions/account';

import './profile-dropdown.css';

function getOrdersItem(count) {
  return {
    ...routes.orderings,
    label: count ? count : undefined,
    icon: <IconPackage />
  };
}

function changePassword() {
  return {
    ...routes.changePassword,
    icon: <IconLock />
  };
}

function getNavigation(ordersCount) {
  return [getOrdersItem(ordersCount), changePassword()];
}

const mapStateToProps = createStructuredSelector({
  ordersCount: fromProfile.getOrderCount,
  firstName: fromProfile.getFirstName,
  photo: fromProfile.getPhoto
});

const mapDispatchToProps = {
  onLogOut: AccountActions.signOut
};

class ProfileDropdown extends Component {
  static propTypes = {
    ...Dropdown.propTypes
  };

  static defaultProps = {
    ...Dropdown.defaultProps
  };

  render() {
    const { ...dropdownProps } = this.props;

    const navigationList = getNavigation(this.props.ordersCount);

    return (
      <Dropdown {...dropdownProps}>
        <div className="ProfileDropdown">
          <ul className="ProfileDropdown-navigation">
            {this.renderProfilePanel()}
            {navigationList.map(this.renderItem)}
            {this.renderSeparator()}
            {this.renderExit()}
          </ul>
        </div>
      </Dropdown>
    );
  }

  renderProfilePanel = () => {
    const { firstName, photo } = this.props;
    return (
      <div className="ProfileDropdown-item ProfileDropdown-item--noGap">
        <RouterLink to={getProfileLink()} className="ProfileDropdown-profileLink">
          <div className="ProfileDropdown-profilePanel">
            <div className="ProfileDropdown-avatar">
              <ProfileAvatar name={firstName} photo={photo} resizeWidth={40} resizeHeight={40} />
            </div>
            <div className="ProfileDropdown-profileTitle">Мой профиль</div>
          </div>
        </RouterLink>
      </div>
    );
  };

  renderItem = link => {
    return (
      <li key={link.path} className="ProfileDropdown-item">
        <div className="ProfileDropdown-container">
          <NavigationLink
            path={link.path}
            title={link.title}
            label={link.label}
            icon={link.icon}
            component={RouterLink}
            to={link.path}
          />
        </div>
      </li>
    );
  };

  renderSeparator() {
    return (
      <li className="ProfileDropdown-item" role="presentation">
        <div className="ProfileDropdown-container">
          <div className="ProfileDropdown-itemSeparator"></div>
        </div>
      </li>
    );
  }

  renderExit() {
    return (
      <li className="Profile_Navigation-item">
        <div className="ProfileDropdown-container">
          <Link
            href="/"
            prependedIcon={<IconExit />}
            noUnderline
            size="xsmall"
            onClick={this.handleClick}
          >
            Выход
          </Link>
        </div>
      </li>
    );
  }

  handleClick = e => {
    e.preventDefault();
    const { onLogOut } = this.props;
    onLogOut();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
