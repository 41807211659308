import React from 'react';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
import DoubledTitle from '../doubled-title/doubled-title';
import MediaQuery from 'react-media';

import './bottom-banner.css';

export default function BottomBanner({ data, light }) {
  return (
    <div className="BottomBanner">
      <div className="BottomBanner-imageHolder">
        <img className="BottomBanner-image" src={data.image.sources[0].main.normal} alt="" />
      </div>
      <div className="BottomBanner-content">
        <div className="BottomBanner-contentInner">
          <div className="BottomBanner-title">
            <MediaQuery query="(max-width: 840px)">
              {isMobile => (
                <LandingSectionTitle
                  data={data.title}
                  top={data.title[0]}
                  bottom={data.title[1]}
                  light={light}
                  verticalReverse
                  centered={isMobile}
                />
              )}
            </MediaQuery>
          </div>
          <div className="BottomBanner-mainTitle">
            <DoubledTitle text={data.main_title} light={light} options={data.main_title_props} />
          </div>
        </div>
      </div>
    </div>
  );
}
