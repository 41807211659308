import React, { Component } from 'react';
import './icon-reload.css';

class IconReload extends Component {
  render() {
    return (
      <svg className="IconReload" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -4 27 26">
        <path d="M19.35 9.82A8.86 8.86 0 0 1 2.6 13.11h1.87a7.25 7.25 0 0 0 13.25-3.3h-1.57l2.42-3.27L21 9.82zM16 4.16a7.21 7.21 0 0 0-5.5-2.52 7.29 7.29 0 0 0-7.09 5.72h1.44l-2.43 3.27L0 7.36h1.76a8.85 8.85 0 0 1 16.18-3.28h-1.65z" />
      </svg>
    );
  }
}

export default IconReload;
