import { takeEvery, put, select } from 'redux-saga/effects';

import ABTestsMarkersActions from 'modules/core/ducks/actions/a-b-test-markers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from "react-router-dom";
import { createABTestVersionPersistor } from 'services/local-storage';

import  {getRouterHistory} from "modules/core/ducks/router-history";

export default function* abtest() {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange({payload}) {
  yield productPageTestVersion(payload);
  yield prosuctRationgABTEST(payload);
  yield toggleBodyPageMarkers(payload);
}

function* toggleBodyPageMarkers(payload) {
  const { pathname } = payload.location;
  const routerHistory =  yield select(getRouterHistory);
  const previousPath = getPreviousPath(routerHistory);

  const margedCurrentList = getModifetList(pathname);
  const margedPreviousList = getModifetList(previousPath);

  toggleUrlModificatorsToBody(margedPreviousList, true);
  toggleUrlModificatorsToBody(margedCurrentList);
}

function toggleUrlModificatorsToBody(list, remove = false) {
  const action = remove ? "remove" : "add";
  if(!Boolean(list) || list.length === 0) {
    return;
  }

  list.forEach(modificator=>{
    if(__BROWSER__){
     document.body.classList[action](`BODY_${modificator}`)
   }
  })
}

function getPreviousPath(history) {
  if(history.length === 1) {
    return null
  }

  return history[history.length-2].location.pathname;
}

function getModifetList(pathname) {
  if(!pathname) {
    return null;
  }
  const pathList = getPathList(pathname).filter(Boolean);

  const margedListWithPrefix = pathList.reduce((acc, element, index) => {
    
    if(index === 0) {
      return [element]
    } 

    return [...acc, `${acc[acc.length-1]}_${element}`]
  }, []);

  return margedListWithPrefix;
}

function getPathList(pathname) {
  let [,urlModificator, ...rest] = pathname.split('/');
  if (!Boolean(urlModificator)) {urlModificator = "main"}
  return [urlModificator, ...rest]; 
}

function* productPageTestVersion(payload) {
  const { pathname } = payload.location;
  const productPageMatch = matchPath(pathname, { path: "/product" });
  const isProductPage = Boolean(productPageMatch);
  const productPageVersion = createABTestVersionPersistor('_product_page_version').read();

  if(!isProductPage ) {
    return;
  }

  yield put(ABTestsMarkersActions.set({name: "productPage", type: productPageVersion}));
}
function* prosuctRationgABTEST() {
  const productRatingVrsion = createABTestVersionPersistor('_product_rating_version').read();
  yield put(ABTestsMarkersActions.set({name: "productRating", type: productRatingVrsion}));
}


