import React, { Component } from 'react';
import './icon-circle-ruble.css';

class IconCircleRuble extends Component {
  render() {
    return (
      <svg className="IconCircleRuble" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
        <g fill="none" fillRule="evenodd">
          <circle cx="11.5" cy="11.5" r="11" stroke="#262931" />
          <path
            fill="#262931"
            d="M10.6 6.57h1.66a2.24 2.24 0 0 1 2.43 2.32 2.17 2.17 0 0 1-2.43 2.32H10.6V6.57zm-2.57 5.56h1.52v1.64H8.03v.73h1.52v2.36h1.06V14.5h2.02v-.73H10.6v-1.64h1.86a3.24 3.24 0 1 0 0-6.47H9.54v5.55H8.03v.92z"
          />
        </g>
      </svg>
    );
  }
}

export default IconCircleRuble;
