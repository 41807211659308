import React, { Component } from 'react';
import './ingredient-grid.css';
import IngredientContainer from '../../containers/ingredient';
import Media from 'react-media';

class IngredientGrid extends Component {
  render() {
    const { idList } = this.props;
    return (
      <div className="IngredientGrid">
        <div className="IngredientGrid-group">{idList.map(this.renderItem)}</div>
      </div>
    );
  }

  renderItem(id) {
    const moveSpreadX = 48;
    const moveSpreadY = 24;

    const x = Math.trunc(2 * moveSpreadX * (Math.random() - 0.5));
    const y = Math.trunc(2 * moveSpreadY * (Math.random() - 0.5));

    const style = {
      transform: `translate(${x}px, ${y}px)`
    };

    return (
      <Media key={id} query="(max-width: 840px)">
        {matches =>
          matches ? (
            <div className="IngredientGrid-item">
              <IngredientContainer id={id} />
            </div>
          ) : (
            <div className="IngredientGrid-item" style={style}>
              <IngredientContainer id={id} />
            </div>
          )
        }
      </Media>
    );
  }
}

export default IngredientGrid;
