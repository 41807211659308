import { takeEvery } from 'redux-saga/effects';

import { cookieUtils, getIsStartWith } from 'modules/utils';
import nanoid from 'nanoid/generate';

import { queryStringToJSON } from 'modules/utils/get-utm';

import { LOCATION_CHANGE } from 'connected-react-router';

const treckedTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export function* UTMTagsWatcher() {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange({ payload }) {
  const query = payload.location.search;
  yield checkUTMTags(query);
}

function checkAdvCakeParams(utm_source, utmParameterKeys, parameters) {
  if (utm_source === 'advcake') {
    setAdvCakeCookie();
    utmParameterKeys.forEach(parametr => cookieUtils.setCookie(parametr, parameters[parametr], 30));
  }
  if (utm_source !== 'advcake') {
    deleteAdvCakeCookie();
    deleteUTMCakeTags();
  }
}

function setAdvCakeCookie() {
  const { href } = window.location;

  const time = Date.now() + '';

  const id = nanoid('0123456789qwertyuiopasdfghjklzxcvbnm', 19) + time;

  cookieUtils.setCookie('advcake_url', href, 30);
  cookieUtils.setCookie('advcake_trackid', id, 30);
}

function deleteAdvCakeCookie() {
  // console.log("deleteAdvCakeCookie -> deleteByMatchithQuery")
  cookieUtils.deleteByMatchithQuery(/advcake/)
  // cookieUtils.deleteCookie('advcake_url')
  // cookieUtils.deleteCookie('advcake_trackid')

}

function checkUTMTags(query) {
  const parameters = queryStringToJSON(query);

  const parametrKeys = Object.keys(parameters);

  const utmParameterKeys = parametrKeys.filter(getIsStartWith('utm'));

  if (utmParameterKeys.indexOf('utm_source') !== -1) {
    checkAdvCakeParams(parameters['utm_source'], utmParameterKeys, parameters);
  }
}

function deleteUTMCakeTags() {
  treckedTags.forEach(tag => {
    if (cookieUtils.getCookie(tag)) {
      cookieUtils.deleteCookie(tag);
    }
  });
}
