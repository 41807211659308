import { createReducer } from 'modules/utils/dux';
import { createSelector } from 'reselect';

const media = createReducer();

export default media({});

export const getRoot = state => state.media;

export const getFindItem = createSelector(media => {
  return id => {
    if (!id || !media) {
      return null;
    }

    return media[id];
  };
});
