import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './static-tooltip.css';

const { func, any, bool, string } = PropTypes;

class StaticTooltip extends Component {
  state = {
    triggerLeftPosition: null,
    contentLeftPosition: null
  };

  static propTypes = {
    renderTrigger: func.isRequired,
    contentTile: string,
    show: bool.isRequired,
    onShowChange: func.isRequired,
    children: any
  };

  triggerElement = null;
  contentElement = null;

  render() {
    const { renderTrigger, show, children, contentTile } = this.props;

    return (
      <Fragment>
        {renderTrigger({
          ref: this.setTriggerElementRef,
          onClick: this.handleShowChange
        })}
        {show && !!children && (
          <div className="StaticTooltip">
            <div className="StaticTooltip-contentHolder">
              {this.isArrowPositionCalculated() && (
                <div
                  className="StaticTooltip-contentArrow"
                  style={{ left: `${this.getArrowLeftPosition()}px` }}
                />
              )}
              <div className="StaticTooltip-content" ref={this.setContentElementRef}>
                {!!contentTile && <div className="StaticTooltip-contentTitle">{contentTile}</div>}

                <div className="StaticTooltip-contentText">{children}</div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getArrowLeftPosition() {
    const { triggerLeftPosition, contentLeftPosition } = this.state;
    return triggerLeftPosition - contentLeftPosition;
  }

  handleShowChange = () => {
    const { show, onShowChange } = this.props;
    onShowChange(!show);
  };

  isArrowPositionCalculated() {
    const { triggerLeftPosition, contentLeftPosition } = this.state;

    return triggerLeftPosition !== null && contentLeftPosition !== null;
  }

  setContentElementRef = element => {
    if (!element) {
      return;
    }

    this.contentElement = element;
    this.setContentLeftPosition(this.getElementLeftPosition(this.contentElement));

    if (this.triggerElement) {
      this.updateTriggerPosition();
    }
  };

  setTriggerElementRef = element => {
    if (!element) {
      return;
    }

    this.triggerElement = element;
    this.setTriggerLeftPosition(this.getElementCenterPosition(this.triggerElement));
    if (this.contentElement) {
      this.updateContentPosition();
    }
  };

  setContentLeftPosition(contentLeftPosition) {
    this.setState({ contentLeftPosition });
  }

  setTriggerLeftPosition(triggerLeftPosition) {
    this.setState({
      triggerLeftPosition: triggerLeftPosition
    });
  }

  getElementLeftPosition(element) {
    const { left } = element.getBoundingClientRect();

    return left;
  }

  getElementCenterPosition(element) {
    const { left, right } = element.getBoundingClientRect();

    return (right - left) / 2 + left;
  }

  updateTriggerPosition() {
    this.setTriggerLeftPosition(this.getElementCenterPosition(this.triggerElement));
  }

  updateContentPosition() {
    this.setContentLeftPosition(this.getElementLeftPosition(this.contentElement));
  }

  updateArrowPosition() {
    this.updateTriggerPosition();
    this.updateContentPosition();
  }

  handleResize = () => {
    if (this.contentElement) {
      this.updateArrowPosition();
    }
  };
}

export default StaticTooltip;
