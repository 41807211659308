import getDaysInMonth from './get-days-in-month';

const currentDate = new Date();
const validYear = currentDate.getFullYear();

const getYear = (rowDate, minYear) => {
  let { year } = rowDate;

  if (year.length === 1) {
    year = '190' + year;
  }

  if (year.length === 2) {
    year = '19' + year;
  }

  if (+year > validYear) {
    year = validYear + '';
  }

  if (+year < minYear) {
    year = minYear + '';
  }

  return year;
};

const getMonth = rowDate => {
  let { month } = rowDate;

  if (month.length === 1) {
    month = '0' + month;
  }

  if (+month > 12) {
    month = '12';
  }

  return month;
};

const getDay = rowDate => {
  let { day } = rowDate;
  const daysInMonth = getDaysInMonth(getMonth(rowDate), getYear(rowDate));

  if (day.length === 1) {
    day = '0' + day;
  }

  if (+day > daysInMonth) {
    day = daysInMonth + '';
  }

  return day;
};

const formatToISOString = (rowDate, minYear) => {
  const year = getYear(rowDate, minYear);
  const month = getMonth(rowDate);
  const day = getDay(rowDate);

  return `${year}-${month}-${day}`;
};

export default formatToISOString;
