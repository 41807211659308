import React, { Component, Fragment } from 'react';
import './list-description.css';
import PropTypes from 'prop-types';

const { string, arrayOf, shape } = PropTypes;

class ListDescriptions extends Component {
  static propTypes = {
    list: arrayOf(
      shape({
        /**
         * name - property of list
         */
        title: string,
        /**
         * value - value of list
         */
        value: string
      })
    )
  };

  render() {
    const { list } = this.props;
    return (
      <dl className="Core_ListDescription">
        {list.map((item, index) => {
          return (
            item.value && (
              <Fragment key={index}>
                <dt className="Core_ListDescription-name">{item.title}</dt>
                <dd className="Core_ListDescription-value">{item.value}</dd>
              </Fragment>
            )
          );
        })}
      </dl>
    );
  }
}

export default ListDescriptions;
