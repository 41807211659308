import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromAccount from 'modules/core/ducks/account';

import * as fromSubscribe from 'modules/core/ducks/subscribe';
import actions from 'modules/core/ducks/actions/subscribe';

import Subscribe from '../components/subscribe/subscribe';

const mapStateToProps = createStructuredSelector({
  isLoading: fromSubscribe.getIsLoading,
  accountEmail: fromAccount.getEmail,
  isAuthorized: fromAccount.getIsAuthorized,
  isSubmitted: fromSubscribe.getIsSubmitted,
  requestedEmail: fromSubscribe.getRequestedEmail
});

const mapDispatchToProps = {
  request: actions.request
};

class SubscribeContainer extends Component {
  render() {
    const { isLoading } = this.props;

    return (
      <Subscribe
        key={isLoading ? 'isLoading' : this.getAccountEmail()}
        onSubmit={this.handleSubmit}
        isLoading={isLoading}
        accountEmail={this.getAccountEmail()}
      />
    );
  }

  getAccountEmail() {
    const { accountEmail, isAuthorized, isSubmitted, isLoading, requestedEmail } = this.props;

    if (isLoading) {
      return requestedEmail;
    }

    return isAuthorized && !isSubmitted ? accountEmail : null;
  }

  handleSubmit = data => {
    const { request } = this.props;

    request(data.email);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeContainer);
