import { takeEvery, select, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as BASKET from '../ducks/basket/types';
import * as fromBasket from '../ducks/basket/selectors';
import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';
import BasketActions from '../ducks/basket/actions';
import BroadcastChannel from 'broadcast-channel';

const basketChannel = __BROWSER__ ? new BroadcastChannel('basket-sync') : null;

function crossTabMessages(channel) {
  return eventChannel(emitter => {
    const handleMessage = data => {
      emitter(data);
    };

    const unsubscribe = () => {
      channel.removeEventListener('message', handleMessage);
    };

    channel.addEventListener('message', handleMessage);

    return unsubscribe;
  });
}

function sendCrossTabMessage(data) {
  if (!basketChannel) {
    return;
  }
  basketChannel.postMessage(data);
}

function* handleCrossTabMessage(data) {
  try {
    const { goods, promos } = JSON.parse(data);
    yield put(BasketActions.updateFromAnotherTab(goods, promos));
  } catch (e) {
    console.error('Failed to sync basket from another tab');
  }
}

export function* watchCrossTabBasket() {
  if (!basketChannel) {
    return;
  }

  yield takeEvery(BASKET.UPDATE_FROM_SERVER, notifyChannelSubscribers);
  yield takeEvery(BASKET.CHANGES_NOTIFY, synchronizeBasket);

  const crossTabChannel = crossTabMessages(basketChannel);

  yield takeEvery(crossTabChannel, handleCrossTabMessage);
}

function* notifyChannelSubscribers() {
  yield put(BasketActions.notifyChanges());
}

function* synchronizeBasket() {
  const goods = yield select(fromBasketProducts.getRawList);
  const promos = yield select(fromBasket.getPromos);

  const basket = JSON.stringify({ goods, promos });

  sendCrossTabMessage(basket);
}
