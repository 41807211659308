import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Placemark } from 'react-yandex-maps';

import pickPointShape from '../../prop-types/pick-point-shape';

const { bool, func } = PropTypes;

class PickPointPlacemark extends Component {
  static propTypes = {
    /** Данные постамата/пункта выдачи PickPoint */
    pickPoint: pickPointShape.isRequired,

    /** Флаг выбранного постамата/пункта выдачи PickPoint */
    selected: bool.isRequired,

    /** Обработчик функции выбора постамата/пункта выдачи PickPoint */
    onClick: func.isRequired
  };

  render() {
    return (
      <Placemark
        {...this.props}
        geometry={this.getPlacemarkGeometry()}
        options={this.getPlacemarkOptions()}
      />
    );
  }

  getPlacemarkGeometry() {
    const { lat, lng } = this.props.pickPoint.geography.coordinates;

    return [lat, lng];
  }

  getPlacemarkOptions() {
    return {
      iconLayout: 'default#image',
      iconImageHref: this.getPlacemarkImage(),
      iconImageSize: [40, 72],
      iconImageOffset: [-20, -36]
    };
  }

  getPlacemarkImage() {
    const { selected } = this.props;

    if (selected) {
      return '/content/map/pin-active.png';
    }

    return '/content/map/pin.png';
  }
}

export default PickPointPlacemark;
