import formatNumber from 'modules/form/helpers/format-number';

export default function formatDate(dateString) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const day = formatNumber(date.getDate());
  const month = formatNumber(date.getMonth() + 1);
  const year = date.getFullYear();

  return day + '.' + month + '.' + year;
}
