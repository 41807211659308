import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as fromAccount from 'modules/core/ducks/account';

import { actions as Dialog } from 'modules/core/ducks/dialogs';

import * as fromPasswordChange from '../ducks/password-change';
import PasswordChangeActions from '../actions/password-change';
import PasswordChangeScene from '../components/password-change-scene/password-change-scene';
import { actions as Dialogs } from 'modules/core/ducks/dialogs';

import {
  getIndexLink
  // getProfileLink
} from 'routes/links';

const mapStateToProps = createStructuredSelector({
  errorMessage: fromPasswordChange.getErrorMessage,
  isFetching: fromPasswordChange.getIsFetching,
  isToastOpened: fromPasswordChange.getIsToastOpened,
  isAuthorized: fromAccount.getIsAuthorized
});

const mapDispatchToProps = {
  onSubmit: PasswordChangeActions.request,
  openAuthDialog: Dialog.openAuthDialog,
  openRecoveryPasswordDialog: Dialogs.openPasswordRecoveryRequestDialog,
  resetErrorMessage: PasswordChangeActions.resetErrorMessage
};

class ChangePasswordSceneContainer extends Component {
  render() {
    const { isAuthorized, isToastOpened, ...rest } = this.props;

    if (!isAuthorized) {
      return <Redirect to={getIndexLink()} />;
    }

    return <PasswordChangeScene {...rest} />;
  }

  componentDidMount() {
    const { isAuthorized, openAuthDialog } = this.props;

    if (!isAuthorized) {
      openAuthDialog();
    }
  }

  componentWillUnmount() {
    const { resetErrorMessage } = this.props;
    resetErrorMessage();
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordSceneContainer);
