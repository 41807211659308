import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createReducer } from 'modules/utils/dux';
import sortOptions from './constants/sort-options';

const table = createReducer();
const list = createReducer();

export default combineReducers({
  table: table(sortOptions),
  list: list([1, 2, 3, 4, 5])
});

export const getRoot = state => state.products.sortOptions;

export const getTable = createSelector(
  getRoot,
  root => root.table
);
export const getIdList = createSelector(
  getRoot,
  root => root.list
);

export const getList = createSelector(
  getTable,
  getIdList,
  (table, list) => {
    return list.map(id => table[id]);
  }
);

const getId = (_, id) => id;

export const getItem = createSelector(
  [getId, getTable],

  (id, table) => {
    if (!id || !table) {
      return null;
    }
    return table[id];
  }
);
