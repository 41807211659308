import { takeEvery, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { mixitApi } from 'services/mixit';

import { getEmailFeedbackThanksLink } from 'routes/links';

import EMAIL_FEEDBACK from '../ducks/types/email-feedback';

export function* emailFeedbackWatcher() {
  yield takeEvery(EMAIL_FEEDBACK.SUBMIT, handleSubmit);
}

function* handleSubmit({ payload }) {
  const { variant, option, comment } = payload;

  try {
    yield call(mixitApi().emailFeedback().item, { variant, option, comment });
  } catch (err) {
    console.warn(err);
  }

  yield put(replace(getEmailFeedbackThanksLink()));
}
