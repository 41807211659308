import React, { Component } from 'react';
import './icon-flat-loader.css';

class IconFlatLoader extends Component {
  render() {
    return (
      <svg className="IconFlatLoader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle className="IconFlatLoader-first" cx="6" cy="50" r="6">
          {/* <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin=".1"
          /> */}
        </circle>
        <circle className="IconFlatLoader-second" cx="30" cy="50" r="6">
          {/* <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin=".2"
          /> */}
        </circle>
        <circle className="IconFlatLoader-third" cx="54" cy="50" r="6">
          {/* <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin=".3"
          /> */}
        </circle>
      </svg>
    );
  }
}

export default IconFlatLoader;
