import React, { Component, Fragment } from 'react';
import './checked-list.css';
import classNames from 'classnames';
import { IconCheck } from '../icons/index';

class CheckedList extends Component {
  render() {
    const { list, iconColor } = this.props;
    return (
      <Fragment>
        <ul
          className={classNames('CheckedList', {
            'CheckedList--yellow': iconColor === 'yellow'
          })}
        >
          {list.map((option, index) => (
            <li key={index} className="CheckedList-option">
              <span className="CheckedList-icon">
                <IconCheck />
              </span>
              <span className="CheckedList-text">{option}</span>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }
}

export default CheckedList;
