import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isFunction from 'modules/utils/is-function';
import * as fromGroups from '../ducks/groups';
import { actions as Group } from '../ducks/groups';
// import { button } from '@storybook/addon-knobs';
// import { bindActionCreators } from 'redux';
// import reducer from 'modules/ordering/ducks/basket';

const mapState = createStructuredSelector({
  group: fromGroups.getSortedItemBySlug,
  categories: fromGroups.getCategoriesBySlug,
  isLoading: fromGroups.getIsLoadingBySlug,
  isProductsLoading: fromGroups.getIsProductListLoading
});

const mapActions = {
  show: Group.showItem,
  loadMore: Group.loadMoreProducts
};

class GroupProviderInner extends React.Component {
  state = {
    previousFatched: null
  }
  render() {
    const { children, ...rest } = this.props;

    if (!isFunction(this.props.children)) {
      return null;
    }

    return this.props.children(rest);
  }

  componentDidMount() {
    const {slug, show} = this.props;
    show(slug);
    this.setState({previousFatched: slug})
  }
  componentDidUpdate() {
    const { group, show, slug } = this.props;
    const { previousFatched } = this.state;
    const isGroutExist = Boolean(group);
    if(!isGroutExist && previousFatched !== slug) {
      show(slug);
      this.setState({previousFatched: slug})
    }

  }
}

const GroupProvider = connect(mapState, mapActions)(GroupProviderInner);

GroupProvider.propTypes = {
  slug: string.isRequired
};

export default GroupProvider;
