import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromSubscribe from 'modules/core/ducks/subscribe';
import subscribeActions from 'modules/core/ducks/actions/subscribe';

import SubscribeConformationDialog from '../components/subscribe-conformation-dialog/subscribe-conformation-dialog';

const { bool } = PropTypes;

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsSubscribeConformationOpened,
  email: fromSubscribe.getSubmittedEmail
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close,
  resetEmail: subscribeActions.resetEmail
};

class SubscribeConformationDialogContainer extends Component {
  static propTypes = {
    isOpened: bool,
    close: bool
  };

  render() {
    const { isOpened, email } = this.props;

    return <SubscribeConformationDialog show={isOpened} onClose={this.handleClose} email={email} />;
  }

  handleClose = () => {
    const { close, resetEmail } = this.props;

    close();
    setTimeout(resetEmail, 500);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeConformationDialogContainer);
