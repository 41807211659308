import React, { Component } from 'react';
import CategoryNavigationPanel from '../category-navigation-panel/category-navigation-panel';
import SubcategoryList from '../category-navigation-panel/subcategory-list/subcategory-list';
import SubcategoryCard from '../category-navigation-panel/subcategory-card/subcategory-card';

class MainCategoryPanel extends Component {
  render() {
    const {
      submenuList,
      onNestedLinkClick,
      onFilterLinkClick,
      onSubcategoryCardClick
    } = this.props;

    return (
      <CategoryNavigationPanel>
        {submenuList.map(menuItem => {
          const _list = Boolean(menuItem.list)
            ? menuItem.list.map(item => ({
                ...item,
                href: item.link
              }))
            : null;

          const isList = Boolean(_list);
          const isCard = menuItem.type === 'card';

          if (!isList && !isCard) {
            return null;
          }

          return (
            <>
              {isList && (
                <CategoryNavigationPanel.Column key={menuItem.slug}>
                  <SubcategoryList
                    title={menuItem.title}
                    // list={menuItem.list}
                    list={_list}
                    onNestedLinkClick={onNestedLinkClick}
                    onFilterLinkClick={onFilterLinkClick}
                  />
                </CategoryNavigationPanel.Column>
              )}
              {isCard && (
                <CategoryNavigationPanel.Column key={menuItem.slug}>
                  <SubcategoryCard
                    title={menuItem.title}
                    href={menuItem.href}
                    media={menuItem.media}
                    item={menuItem}
                    onClick={onSubcategoryCardClick}
                  />
                </CategoryNavigationPanel.Column>
              )}
            </>
          );
        })}
      </CategoryNavigationPanel>
    );
  }
}

export default MainCategoryPanel;
