import React, { Component } from 'react';

class Container extends Component {
  render() {
    const { children, className } = this.props;
    return (
      <div className={className} style={{ position: 'relative' }}>
        {children}
      </div>
    );
  }
}

export default Container;
