import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-special-offer-coupon.css';

class IconSpecialOfferCoupon extends Component {
  render() {
    return (
      <Svg viewBox="0 0 36 36" className="IconSpecialOfferCoupon">
        <path d="M30 3.9c0-.5-.2-1-.6-1.4-.3-.3-.8-.5-1.3-.5s-1 .2-1.4.6c-.2.2-.4.5-.6.7-.1.1-.1.4.1.6.2.1.5.1.7-.1.2-.3.4-.5.5-.6.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.8.6-1.6 1.2-.5-.9-1.4-1.4-2.5-1.4h-6.6c-.9 0-1.7.3-2.4 1L2.4 18.7c-.3.3-.4.6-.4 1s.1.7.4 1l8.9 8.9c.3.3.6.4 1 .4s.7-.1 1-.4l13.3-13.3c.6-.6 1-1.5 1-2.4V7.3c0-.2 0-.4-.1-.6 1-.7 1.7-1.2 1.9-1.5.4-.3.6-.8.6-1.3zm-3.3 10c0 .7-.3 1.3-.7 1.7L12.7 28.9c-.2.2-.5.2-.7 0L3.1 20c-.1-.1-.2-.2-.2-.4 0-.1.1-.3.2-.4L16.3 6c.5-.5 1.1-.7 1.7-.7h6.6c.7 0 1.4.4 1.7 1-.5.4-1.1.7-1.8 1.1l-.1-.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.5-.5.7-1.2.5-1.8.6-.3 1.1-.7 1.6-1v6.7zm-3.8-5c.1.2.2.2.4.2h.2c.2-.1.5-.2.8-.4 0 .3-.1.5-.3.7-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.2 0 .4.1.6.2-.3.1-.5.3-.8.4-.3.1-.4.4-.2.6z" />
        <path d="M15.5 11.2c-.3 0-.5.2-.5.5v9.6c0 .3.2.5.5.5s.5-.2.5-.5v-9.6c0-.3-.2-.5-.5-.5zM13.4 15.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.4-.4.6-.8.6-1.3.1-.5-.1-1-.5-1.4zm-.6 2.1c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7zM20.3 15.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.8-.7.8-1.9.1-2.7zm-.7 2.1c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.3 0 .5.1.7.3.4.4.4 1 0 1.4z" />
        <path d="M30 3.9c0-.5-.2-1-.6-1.4-.3-.3-.8-.5-1.3-.5s-1 .2-1.4.6c-.2.2-.4.5-.6.7-.1.1-.1.4.1.6.2.1.5.1.7-.1.2-.3.4-.5.5-.6.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.8.6-1.6 1.2-.5-.9-1.4-1.4-2.5-1.4h-6.6c-.9 0-1.7.3-2.4 1L2.4 18.7c-.3.3-.4.6-.4 1s.1.7.4 1l8.9 8.9c.3.3.6.4 1 .4s.7-.1 1-.4l13.3-13.3c.6-.6 1-1.5 1-2.4V7.3c0-.2 0-.4-.1-.6 1-.7 1.7-1.2 1.9-1.5.4-.3.6-.8.6-1.3zm-3.3 10c0 .7-.3 1.3-.7 1.7L12.7 28.9c-.2.2-.5.2-.7 0L3.1 20c-.1-.1-.2-.2-.2-.4 0-.1.1-.3.2-.4L16.3 6c.5-.5 1.1-.7 1.7-.7h6.6c.7 0 1.4.4 1.7 1-.5.4-1.1.7-1.8 1.1l-.1-.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.5-.5.7-1.2.5-1.8.6-.3 1.1-.7 1.6-1v6.7zm-3.8-5c.1.2.2.2.4.2h.2c.2-.1.5-.2.8-.4 0 .3-.1.5-.3.7-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.2 0 .4.1.6.2-.3.1-.5.3-.8.4-.3.1-.4.4-.2.6z" />
        <path d="M15.5 11.2c-.3 0-.5.2-.5.5v9.6c0 .3.2.5.5.5s.5-.2.5-.5v-9.6c0-.3-.2-.5-.5-.5zM13.4 15.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.4-.4.6-.8.6-1.3.1-.5-.1-1-.5-1.4zm-.6 2.1c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7zM20.3 15.1c-.7-.7-2-.7-2.7 0-.7.7-.7 2 0 2.7.4.4.9.6 1.3.6.5 0 1-.2 1.3-.6.8-.7.8-1.9.1-2.7zm-.7 2.1c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.2-.2.5-.3.7-.3.3 0 .5.1.7.3.4.4.4 1 0 1.4z" />
      </Svg>
    );
  }
}

export default IconSpecialOfferCoupon;
