import immer from 'immer';

/**
 * Creates reducer from provided object and initial state
 *
 * @export
 * @param {Object} reducerObject
 * @param {*} initialState
 * @returns
 */
export default function createImmerReducer(reducerObject, initialState) {
  return function(state = initialState, action) {
    if (!action) return state;

    const { type, payload } = action;

    if (!reducerObject.hasOwnProperty(type)) return state;

    return immer(state, draft => reducerObject[type](draft, payload));
  };
}

/*

Usage example:

const reducer = createImmerReducer({

  [ADD_PRODUCT]: (draft, payload) => { draft.push(payload.product) }
  [UPDATE_PRODUCT]: (draft, { product }) => draft[product.id] = product

})

*/
