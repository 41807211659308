import * as TYPES from './types';
import { createAction } from 'modules/utils/dux';
import { normalizeBasket } from '../basket/helpers';

const show = () => createAction(TYPES.SHOW);

const request = () => createAction(TYPES.REQUEST);

const addProduct = (product, amount = 1) => ({
  type: TYPES.PRODUCT_ADD,
  payload: {
    product,
    amount
  }
});

const removeProduct = goods => ({
  type: TYPES.PRODUCT_REMOVE,
  payload: {
    ...goods
  }
});

const requestProductRemove = goods =>
  createAction(TYPES.PRODUCT_REMOVE_REQUEST, {
    ...goods
  });

const responseProductRemove = goods =>
  createAction(TYPES.PRODUCT_REMOVE_RESPONSE, {
    ...goods
  });

const markProductForRemoval = goods => {
  return {
    type: TYPES.PRODUCT_MARK_FOR_REMOVAL,
    payload: {
      ...goods
    }
  };
};

const cancelProductRemoval = goods => {
  return {
    type: TYPES.PRODUCT_CANCEL_REMOVAL,
    payload: {
      ...goods
    }
  };
};

const changeProductAmount = (goods, amount, { previousAmount = 0 } = {}) => ({
  type: TYPES.PRODUCT_CHANGE_AMOUNT,
  payload: {
    ...goods,
    amount,
    previousAmount
  }
});

const openBasketDropdown = () => ({
  type: TYPES.DROPDOWN_OPEN
});

const closeBasketDropdown = () => ({
  type: TYPES.DROPDOWN_CLOSE
});

const updateFromServer = serverBasket => {
  const { basket, products } = normalizeBasket(serverBasket);

  return createAction(TYPES.UPDATE_FROM_SERVER, { basket, products });
};

const updateFromAnotherTab = (goods, promos) => {
  return createAction(TYPES.UPDATE_FROM_ANOTHER_TAB, { goods, promos });
};

const applyPromo = code => createAction(TYPES.PROMO_APPLY, { code });
const reset = _ => createAction(TYPES.RESET);
const resetPromo = _ => createAction(TYPES.RESET_PROMO);

const selectPromoOption = (optionId, promoId) =>
  createAction(TYPES.PROMO_OPTION_SELECT, { optionId, promoId });

const errorPromo = code => createAction(TYPES.PROMO_ERROR, { code });

const removePromo = promo => createAction(TYPES.PROMO_REMOVE, { promo });

const rehydrate = basket => createAction(TYPES.REHYDRATE, { basket });

const sync = () => createAction(TYPES.SYNC);
const syncResponse = serverBasket => {
  const { basket, products } = normalizeBasket(serverBasket);
  return createAction(TYPES.SYNC_RESPONSE, { basket, products });
};

const verifyRequest = (reload = false) => createAction(TYPES.VERIFY_REQUEST, { reload });
const verifyCancel = () => createAction(TYPES.VERIFY_CANCEL);

const setOuterBasketLoading = () => createAction(TYPES.SET_OUTER_BASKET_LOADING);
const setOuterBasketLoaded = () => createAction(TYPES.SET_OUTER_BASKET_LOADED);

const applyBonus = ({ value, type }) => createAction(TYPES.BONUS_APPLY, { value, type });
const removeBonus = ({ value = 0, type }) => createAction(TYPES.BONUS_REMOVE, { value, type });
const errorBonus = message => createAction(TYPES.BONUS_ERROR, { message });

const openApplyAnotherGiftDialog = () => createAction(TYPES.OPEN_APPLY_ANOTHER_GIFT_DIALOG);

const closeApplyAnotherGiftDialog = () => createAction(TYPES.CLOSE_APPLY_ANOTHER_GIFT_DIALOG);

function notifyChanges() {
  return createAction(TYPES.CHANGES_NOTIFY);
}

export default {
  show,
  request,
  addProduct,
  changeProductAmount,
  removeProduct,
  openBasketDropdown,
  closeBasketDropdown,
  markProductForRemoval,
  cancelProductRemoval,
  updateFromServer,
  updateFromAnotherTab,
  reset,

  applyPromo,
  selectPromoOption,
  errorPromo,
  removePromo,
  resetPromo,
  requestProductRemove,
  responseProductRemove,
  rehydrate,
  sync,
  syncResponse,
  verifyRequest,
  verifyCancel,
  applyBonus,
  removeBonus,
  errorBonus,
  notifyChanges,
  setOuterBasketLoading,
  setOuterBasketLoaded,
  openApplyAnotherGiftDialog,
  closeApplyAnotherGiftDialog
};
