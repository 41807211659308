import React, { Component } from 'react';

import './icon-plus.css';

class IconPlus extends Component {
  render() {
    return <span className="IconPlus" />;
  }
}

export default IconPlus;
