import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromNavigations from 'modules/core/ducks/navigations';
import Loader from 'modules/core/components/loader/loader';

import GlobalNavigationMobileLink from './global-navigation-mobile-link';

import './global-navigation-mobile.css';

const mapStateToProps = createStructuredSelector({
  categories: fromNavigations.getGlogalMobile,
});


class GlobalNavigationMobile extends Component {
  render() {
    const { onLinkClick, categories } = this.props;

    if(!categories || !categories.list ) {
      return (
      <div style={{ position: 'relative', paddingTop: '100px' }}>
        <Loader size="small" secondary />
      </div>
      )
    }

    return (
      <ul className="GlobalNavigationMobile">
        {categories.list.map(link => (
          <li key={link.id} className="GlobalNavigationMobile-item">
            <GlobalNavigationMobileLink onClick={onLinkClick} {...link} />
          </li>
        ))}
      </ul>
    );
  }
}

export default connect(mapStateToProps)(GlobalNavigationMobile);
