import React, { Component } from 'react';

import { IconMaster, IconVisa, IconCardWorld, IconGooglePay, IconApplePay } from '../../icons';

import './payment-methods-mobile.css';

class PaymentMethodsMobile extends Component {
  render() {
    return (
      <div className="PaymentMethodsMobile">
        <h5 className="PaymentMethodsMobile-title">Способы оплаты</h5>

        <div className="PaymentMethodsMobile-content">
          <div className="PaymentMethodsMobile-item">
            <IconMaster />
          </div>

          <div className="PaymentMethodsMobile-item">
            <IconVisa />
          </div>

          <div className="PaymentMethodsMobile-item">
            <IconGooglePay />
          </div>

          <div className="PaymentMethodsMobile-item">
            <IconApplePay />
          </div>

          <div className="PaymentMethodsMobile-item">
            <IconCardWorld />
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMethodsMobile;
