import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getProfileGeneralLink } from 'routes/links';
import { mixitApi } from 'services/mixit';

import * as fromAccount from 'modules/core/ducks/account';

import * as PASSWORD_CHANGE from '../types/password-change.js';
import PasswordChange from '../actions/password-change';

const NOT_VALID_CURRENT_PASSWORD_STATUSES = [401, 404];
const NOT_VALID_CURRENT_PASSWORD_ERROR_MESSAGE = 'Текущий пароль неверный';

export function* passwordChangeWatcher() {
  yield takeEvery(PASSWORD_CHANGE.REQUEST, changePassword);
}

function* changePassword({ payload }) {
  const { currentPassword, newPassword } = payload;

  const authorization = yield getAuth();

  if (!authorization) {
    return;
  }

  const serializedData = serialize({
    currentPassword,
    newPassword
  });

  try {
    yield call(mixitApi(authorization).account().changePassword, serializedData);

    yield put(PasswordChange.response());
    yield put(push(getProfileGeneralLink()));
  } catch (error) {
    console.warn(error);
    const respondedStatus = error.response.status;
    if (NOT_VALID_CURRENT_PASSWORD_STATUSES.indexOf(respondedStatus) === -1) {
      yield put(PasswordChange.error(error));
      return;
    }
    yield put(PasswordChange.error({ message: NOT_VALID_CURRENT_PASSWORD_ERROR_MESSAGE }));
  }
}

function serialize({ currentPassword, newPassword }) {
  return {
    current_password: currentPassword,
    new_password: newPassword
  };
}

function getAuth() {
  return select(fromAccount.getAuthorization);
}
