import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromCityList from 'modules/geography/ducks/city-list';
import * as fromLocation from 'modules/geography/ducks/location';
import Location from 'modules/geography/ducks/actions/location';

import CityChoiceBottomSheet from '../components/city-choice-bottom-sheet/city-choice-bottom-sheet';

const mapState = createStructuredSelector({
  cityIds: fromCityList.getIdList,
  currentId: fromLocation.getChosenId,
  cityTitleList: fromCityList.getСityTitleList
});

const mapDispatchToProps = {
  chooseCity: Location.chooseCity
};

class CityChoiceBottomSheetContainer extends Component {
  state = {
    suggestions: []
  };

  render() {
    const { cityIds, currentId, ...rest } = this.props;
    const { suggestions } = this.state;

    return (
      <CityChoiceBottomSheet
        onFilterChange={this.handleFilterChange}
        suggestions={suggestions}
        list={cityIds}
        currentId={currentId}
        onChooseFromSearch={this.handleChooseFromSearch}
        onCityChoose={this.handleCityChoose}
        {...rest}
      />
    );
  }

  fetchSuggestions(query) {
    const { cityTitleList } = this.props;

    const suggestions = cityTitleList.filter(this.getFilteredListByQuery(query));

    this.setState({
      query,
      inputQuery: query,
      isOpened: true,
      selected: null,
      suggestions
    });
  }

  getFilteredListByQuery = query => item => {
    const title = item.title.toLowerCase().trim();
    const _query = query.toLowerCase().trim();
    const isIncludesQuery = title.includes(_query);

    return isIncludesQuery;
  };

  handleFilterChange = e => {
    const value = e.target.value;
    this.fetchSuggestions(value);
  };

  handleChooseFromSearch = data => {
    this.props.chooseCity(data.id, data);
  };

  handleCityChoose = (city, searchCity) => {
    const { chooseCity, onClose } = this.props;

    chooseCity(city.id, searchCity);
    onClose();
  };
}

export default connect(mapState, mapDispatchToProps)(CityChoiceBottomSheetContainer);
