import React, { Component } from 'react';
import './button-tooltip.css';
import classNames from 'classnames';
import { IconPlus } from 'modules/core/components/icons';

class ButtonTooltip extends Component {
  render() {
    const { active, ...rest } = this.props;
    return (
      <div
        className={classNames('ButtonTooltip', {
          'ButtonTooltip--isActive': active
        })}
        {...rest}
      >
        <button className="ButtonTooltip-button">
          <div className="ButtonTooltip-iconHolder">
            <IconPlus />
          </div>
        </button>
        <div className="ButtonTooltip-info" />
      </div>
    );
  }
}

export default ButtonTooltip;
