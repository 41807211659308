import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavigationLabel from '../navigation-label/navigation-label';

import './navigation-link.css';
import IconContainer from 'modules/core/components/icon-container/icon-container';

const { string } = PropTypes;

class NavigationLink extends Component {
  static propTypes = {
    title: string.isRequired,
    path: string.isRequired
  };

  static defaultProps = {
    component: 'a'
  };

  render() {
    const { link, title, label, icon, component: Component, ...rest } = this.props;

    return (
      <Component title={title} className="NavigationLink" {...rest}>
        {icon && (
          <span className="NavigationLink-icon">
            <IconContainer size="small">{icon}</IconContainer>
          </span>
        )}

        <span className="NavigationLink-text">{title}</span>
        {label && (
          <span className="NavigationLink-label">
            <NavigationLabel label={label} />
          </span>
        )}
      </Component>
    );
  }
}

export default NavigationLink;
