import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { string } = PropTypes;

class FormDataField extends Component {
  static propTypes = {
    label: string,
    value: string,
    placeholder: string
  };

  static defaultProps = {
    placeholder: 'не указан'
  };

  render() {
    const { value, label, placeholder } = this.props;
    return (
      <div className="FormData-field">
        <div className="FormData-fieldLabel">{label}</div>
        <div className="FormData-fieldValue">
          {value || <span className="FormData-fieldPlaceholder">{placeholder}</span>}
        </div>
      </div>
    );
  }
}

export default FormDataField;
