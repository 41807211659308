import { createSelector } from 'reselect';

const wontBeRemoved = goods => !goods.willBeRemoved;

const getBasket = state => state.ordering.basket;

const getUi = createSelector(getBasket, basket => basket.ui);

export const getAll = createSelector(getBasket, basket => basket);

export const getPromoSelectedOptions = createSelector(getBasket, function _getPromoSelectedOptions(
  basket
) {
  return basket.promoSelectedOptions;
});

export const getWillBeRemoved = createSelector(getBasket, basket => basket.willBeRemoved);

export const getRawList = createSelector(getBasket, basket => basket.list);

export const getListWithRemoved = createSelector(
  getRawList,
  getWillBeRemoved,
  (list, willBeRemoved) =>
    list.map(addDiscountToPrice).map(item => {
      return {
        ...item,
        willBeRemoved: willBeRemoved.indexOf(item.uid) !== -1
      };
    })
);

export const getList = createSelector(getListWithRemoved, list => list.filter(wontBeRemoved));

export const getListToOrder = createSelector(getBasket, basket => basket.list);

export const getListWithoutGifts = createSelector(getListWithRemoved, list => list.filter(notGift));

export const getGoods = createSelector(getList, list => list);

export const getAccessItemBySlug = createSelector(
  getList,

  list => {
    return slug => {
      for (let i = 0; i < list.length; i++) {
        if (list[i].product === slug) {
          const item = list[i];

          return {
            ...item
          };
        }
      }
      return null;
    };
  }
);

export const getAccessIsProductInBasketBySlug = createSelector(
  getAccessItemBySlug,
  accessItemBySlug => {
    return slug => {
      const item = accessItemBySlug(slug);
      if (!item) {
        return false;
      }

      return item.amount > 0;
    };
  }
);

export const getAccessAmountProductInBasketBySlug = createSelector(
  getAccessItemBySlug,
  accessItemBySlug => {
    return slug => {
      const item = accessItemBySlug(slug);
      if (!item) {
        return false;
      }

      return item.amount;
    };
  }
);

export const getProductsAmount = createSelector(getList, list =>
  list.reduce((sum, current) => {
    return sum + current.amount;
  }, 0)
);

export const getIsEmpty = createSelector(getListWithRemoved, list => !list.length);

export const getDeliveryCost = createSelector(getBasket, basket => basket.delivery.cost);

export const getDropdownShow = createSelector(getUi, ui => ui.isDropdownOpened);

export const getFreeDeliveryPrice = createSelector(
  getBasket,
  basket => basket.delivery.freeDeliveryPrice
);

export const getMaxDeliveryPrice = createSelector(getBasket, basket => {
  return basket.delivery.maxDeliveryPrice;
});

export const getPromos = createSelector(getBasket, getPromoSelectedOptions, function _getPromos(
  basket,
  promoSelectedOptions
) {
  return basket.promos.map(promo => {
    if (promoSelectedOptions && promoSelectedOptions[promo.id]) {
      return { ...promo, optionId: promoSelectedOptions[promo.id] };
    }

    return promo;
  });
});

export const getEnabledPromos = createSelector(getPromos, function _getValidPromos(promos) {
  return promos.filter(item => !item.disabled);
});

//   basket =>
//   basket.promos.map(promo => addSelectedOptionId(promo))
// );

export const getCouponList = createSelector(getPromos, promos => {
  if (!promos) {
    return null;
  }
  return promos.filter(item => item.code);
});

export const getLastCoupon = createSelector(getCouponList, coupons => {
  if (!coupons) {
    return null;
  }

  const lastCoupon = coupons[0];

  if (!lastCoupon) {
    return null;
  }

  return lastCoupon;
});

export const getLastPromo = createSelector(getPromos, promos => {
  if (!promos) {
    return null;
  }

  const getLastPromo = promos[0];

  if (!getLastPromo) {
    return null;
  }

  return getLastPromo;
});

export const getLastCouponLoaded = createSelector(getLastCoupon, coupon => {
  if (!coupon) {
    return false;
  }
  return coupon._isLoaded || false;
});

export const getLastCouponRemoving = createSelector(getLastCoupon, coupon => {
  if (!coupon) {
    return false;
  }
  return coupon._isRemoving || false;
});

export const getIsLastPromoApplied = createSelector(getLastPromo, getList, (promo, goods) => {
  if (!promo) {
    return false;
  }
  return goods.filter(item => item.promoId === promo.id).length > 0;
});

export const getPromoOptionId = createSelector(
  getLastCoupon,
  getPromoSelectedOptions,
  (coupon, selectedOptions) => {
    if (!coupon || !coupon.options) {
      return false;
    }
    return selectedOptions[coupon.id];
  }
);

export const getIsPromoVerified = createSelector(getBasket, basket => basket.isPromoVerified);

export const getIsGiftChoiseShowed = createSelector(
  getLastPromo,
  getIsLastPromoApplied,
  getIsPromoVerified,
  getPromoSelectedOptions,
  function _getIsGiftProductBottomSheetShowed(
    promo,
    isPromoApplied,
    isPromoVerified,
    promoSelectedOptions
  ) {
    if (!isPromoVerified) {
      return false;
    }

    if (!promo) {
      return false;
    }

    if (!promo.options || !promo.options.length) {
      return false;
    }

    return !isPromoApplied || !!promoSelectedOptions.applyingGift;
  }
);

export const getGiftProductSlugs = createSelector(getLastPromo, function _getGiftProductsSlug(
  promo
) {
  if (!promo || !promo.options) {
    return null;
  }

  if (promo.options.length === 0) {
    return null;
  }

  return promo.options.map(item => item.goods.product);
});

export const getPromoOptions = createSelector(getLastPromo, function _getPromoOptions(promo) {
  if (!promo || !promo.options) {
    return null;
  }

  if (promo.options.length === 0) {
    return null;
  }

  return promo.options;
});

export const getConnectedOrder = createSelector(getBasket, function _getConnectedOrder(basket) {
  return basket.order;
});

export const getIsPlacing = createSelector(getBasket, basket => basket._isPlacing);

export const getLastChangeDate = createSelector(getBasket, basket => basket.lastChangeDate);

export const getIsVerifying = createSelector(getBasket, basket => basket._isVerifying > 0);

function addDiscountToPrice(goods) {
  const discount = calculateDiscount(goods.price);
  const current = goods.price.default - discount;
  return {
    ...goods,
    price: {
      ...goods.price,
      current,
      discount
    }
  };
}

function calculateDiscount(price) {
  const { discount } = price;

  if (!discount) {
    return 0;
  }

  if (discount.numerical) {
    return discount.numerical;
  }

  if (discount.percentage) {
    return price.default * discount.percentage;
  }

  return 0;
}

function notGift(item) {
  return !item.gift;
}

/** BONUSES */
export const getRowBonuses = createSelector(getBasket, basket =>
  basket.bonuses ? basket.bonuses : null
);

const getBonusesIsLoading = createSelector(getRowBonuses, bonuses => bonuses.isLoading);

export const getAvailableBonuses = createSelector(getRowBonuses, bonuses => {
  if (!bonuses.available) {
    return null;
  }
  if (!bonuses.available.length === 0) {
    return null;
  }
  if (!bonuses.available[0].value) {
    return null;
  }
  return bonuses.available[0].value;
});

export const getAppliedBonuses = createSelector(getRowBonuses, bonuses => {
  if (!bonuses.applied) {
    return null;
  }
  if (!bonuses.applied.length === 0) {
    return null;
  }
  // if (!bonuses.applied[0].value) {
  //   return null;
  // }
  return bonuses.applied[0].value;
});

const getIsBonusesApplied = createSelector(
  getAppliedBonuses,
  appliedBonuses => !!appliedBonuses && appliedBonuses.length !== 0
);

export const getEarnedBonuses = createSelector(getRowBonuses, bonuses => {
  if (!bonuses.earned) {
    return null;
  }
  if (!bonuses.earned.length === 0) {
    return null;
  }
  if (!bonuses.earned[0].value) {
    return null;
  }
  return bonuses.earned[0].value;
});

/** BONUSES CONFORMATION */

export const getIsConformationBonusesRequired = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return false;
  }
  if (!basket.bonusesConformation.required) {
    return false;
  }
  return basket.bonusesConformation.required;
});

const getIsFetchingConformationBonusesCode = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return false;
  }
  if (!basket.bonusesConformation.requestCodeFetching) {
    return false;
  }
  return basket.bonusesConformation.requestCodeFetching;
});

const getIsFetchingConformationBonuses = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return false;
  }
  if (!basket.bonusesConformation.requestConfirmFetching) {
    return false;
  }
  return basket.bonusesConformation.requestConfirmFetching;
});

const getConformationBonusesPhone = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return null;
  }
  if (!basket.bonusesConformation.phone) {
    return null;
  }
  return basket.bonusesConformation.phone;
});

const getConformationBonusesIsPlacing = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return false;
  }
  if (!basket.bonusesConformation.isPlacing) {
    return false;
  }
  return basket.bonusesConformation.isPlacing;
});

const getIsConformationCodeInvalid = createSelector(getBasket, basket => {
  if (!basket.bonusesConformation) {
    return false;
  }
  if (!basket.bonusesConformation.isCodeInvalid) {
    return false;
  }
  return basket.bonusesConformation.isCodeInvalid;
});

const getConfirmationBonusesOptions = createSelector(
  getIsConformationBonusesRequired,
  getIsFetchingConformationBonusesCode,
  getIsFetchingConformationBonuses,
  getConformationBonusesPhone,
  getIsConformationCodeInvalid,
  getConformationBonusesIsPlacing,
  (required, codeFetching, confirmFetching, phone, isCodeInvalid, isPlacing) => ({
    required,
    codeFetching,
    confirmFetching,
    phone,
    isCodeInvalid,
    isPlacing
  })
);

export const getBonuses = createSelector(
  getAvailableBonuses,
  getAppliedBonuses,
  getEarnedBonuses,
  getBonusesIsLoading,
  getIsBonusesApplied,
  getConfirmationBonusesOptions,
  (available, applied, earned, isLoading, isApplied, conformationOptions) => ({
    available,
    applied,
    earned,
    isLoading,
    isApplied,
    conformationOptions
  })
);

export const getIsOuterBasketLoading = createSelector(
  getBasket,
  basket => basket.isOuterBasketLoading
);

export const getIsBasketFetching = createSelector(getBasket, basket => basket._isFetching);
