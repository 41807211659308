import React, { Component } from 'react';
import { Form } from 'modules/form/components/form';
import PropTypes from 'prop-types';

import Tooltip from 'modules/core/components/tooltip/tooltip';
import Link from 'modules/core/components/link/short-link';
import InfoSign from 'modules/review/components/reply-form/info-sign/info-sign';
import EditablePhone from 'modules/form/components/editable-phone-new/editable-phone';
import EditableDate from 'modules/form/components/editable-date-new/editable-date';
import LeftLabeledField from 'modules/form/components/left-labeled-field/left-labeled-field';
import EditableAddress from 'modules/form/components/editable-address/editable-address';

import isFunction from 'modules/utils/is-function';

import './general-form.css';

const { string, arrayOf, shape, number, func, oneOf } = PropTypes;

const addressShape = shape({
  id: number,
  city: string,
  street: string,
  house: string,
  housing: string,
  building: string,
  apartment: string,
  full: string
});

const phoneField = 'Телефон:';
const addressField = 'Адрес:';
const dateField = 'Дата рождения:';

class GeneralForm extends Component {
  static propTypes = {
    /**номер телефона  */
    phone: string,
    /** список адресов */
    addressList: arrayOf(addressShape),
    /** выбранный адрес */
    selectedAddress: addressShape,
    /** дата рождения */
    birthDate: string,
    /** событие изменения даты рождения */
    onDateChange: func,
    /** событие изменения номера телефона */
    onPhoneChange: func,
    /** событие изменения доставки по умолчанию */
    onDeliveryAddressChange: func,
    /** событие сохранения нового адреса */
    onAddressSave: func,
    /** параметр размера Input-ов и Button-ов */
    size: oneOf(['normal', 'small'])
  };

  state = {
    editedFields: []
  };

  render() {
    const {
      phone,
      delivery,
      birthDate,
      currentCity,
      suggestedCity,
      isPhoneEditable,
      openCityDialog,
      phoneError
    } = this.props;

    return (
      <Form component="div" className="GeneralForm">
        <Form.Row>
          <Form.SmallTitle>Контактные и личные данные</Form.SmallTitle>
        </Form.Row>

        <Form.Row>
          <LeftLabeledField
            label="Телефон:"
            size={this.isFieldEditing(phoneField) ? 'small' : 'inline'}
            error={phoneError}
          >
            <EditablePhone
              apliable={!phoneError}
              phone={phone}
              isDisabled={!isPhoneEditable}
              onChange={this.handlePhoneChange}
              onEdit={this.handleEdit(phoneField)}
              onCancel={this.handleCancel(phoneField)}
              onPhoneStateChange={this.handlePhoneStateChange}
            />

            {!isPhoneEditable && (
              <Tooltip content={this.renderTooltipContent()} showDelay={0}>
                <span className="GeneralForm-infoIcon">
                  <InfoSign />
                </span>
              </Tooltip>
            )}
          </LeftLabeledField>
        </Form.Row>

        <Form.Row>
          <LeftLabeledField label="Город:" size="inline">
            <Link onClick={openCityDialog}>{this.getCityTitle(currentCity, suggestedCity)}</Link>
          </LeftLabeledField>
        </Form.Row>

        <Form.Row>
          <LeftLabeledField
            label="Адрес:"
            size={this.isFieldEditing(addressField) ? 'small' : 'inline'}
          >
            <EditableAddress
              address={delivery.address}
              onChange={this.handleAddressChange}
              onEdit={this.handleEdit(addressField)}
              onCancel={this.handleCancel(addressField)}
            />
          </LeftLabeledField>
        </Form.Row>
        <Form.Row>
          <LeftLabeledField
            label="Дата рождения:"
            size={this.isFieldEditing(dateField) ? 'small' : 'inline'}
          >
            <EditableDate
              date={birthDate}
              onChange={this.handleDateChange}
              onEdit={this.handleEdit(dateField)}
              onCancel={this.handleCancel(dateField)}
            />
          </LeftLabeledField>
        </Form.Row>
      </Form>
    );
  }

  renderTooltipContent() {
    const { onCallbackDialogOpen } = this.props;

    return (
      <span className="GeneralForm-phoneTooltipContent">
        Вы не можете редактировать телефон, так как он привязан к бонусной карте.
        <span className="GeneralForm-phoneTooltipSeparator" role="presentation" />
        Чтобы изменить его,
        <button
          type="button"
          onClick={onCallbackDialogOpen}
          className="GeneralForm-phoneTooltipButton"
        >
          свяжитесь с нашим оператором
        </button>
      </span>
    );
  }

  isFieldEditing(field) {
    return this.state.editedFields.indexOf(field) !== -1;
  }

  filterEditedFields(editedFields, field) {
    const FilteredFields = editedFields.filter(item => item !== field);

    return FilteredFields;
  }

  addEditedField(field) {
    this.setState(state => {
      return {
        editedFields: [field, ...this.filterEditedFields(state.editedFields, field)]
      };
    });
  }

  removeEditedField(field) {
    this.setState(state => {
      return {
        editedFields: [...this.filterEditedFields(state.editedFields, field)]
      };
    });
  }

  getCityTitle(currentCity, suggestedCity) {
    if (!currentCity && !suggestedCity) {
      return 'Не выбран';
    }

    if (!currentCity) {
      return suggestedCity.title;
    }

    return currentCity.title;
  }

  handlePhoneChange = phone => {
    const { onPhoneChange } = this.props;

    this.removeEditedField(phoneField);

    if (!isFunction(onPhoneChange)) {
      return;
    }

    onPhoneChange(phone);
  };

  handleDateChange = date => {
    const { onDateChange } = this.props;

    this.removeEditedField(dateField);

    if (!isFunction(onDateChange)) {
      return;
    }

    onDateChange(date);
  };

  handleEdit = id => () => {
    this.addEditedField(id);
  };

  handleCancel = id => () => {
    const { onPhoneStateReset } = this.props;

    if (!isFunction(onPhoneStateReset)) {
      return;
    }

    onPhoneStateReset();

    this.removeEditedField(id);
  };

  handleAddressChange = address => {
    const { onAddressChange } = this.props;

    this.removeEditedField(addressField);

    if (!isFunction(onAddressChange)) {
      return;
    }

    onAddressChange(address);
  };

  handlePhoneStateChange = value => {
    const { onPhoneStateChange } = this.props;

    if (!isFunction(onPhoneStateChange)) {
      return;
    }
    onPhoneStateChange(value);
  };
}

export default GeneralForm;
