import React from 'react';

import IconContainer from '../icon-container/icon-container';
import IconMetro from '../icons/icon-metro/icon-metro';

function Guide({ title, type, subwayLine }) {
  const color = subwayLine.color;
  const icon = getIcon(type, color);

  return (
    <div className="Shop-guide">
      <span className="Shop-guideIcon">
        <IconContainer size="xsmall">{icon}</IconContainer>
      </span>

      <span className="Shop-guideTitle">{title}</span>
    </div>
  );
}

function getIcon(type, color) {
  let icon = null;

  switch (type) {
    case 'SUBWAY_STATION':
    case 'TRAIN_STATION':
    case 'SHOPPING_MALL':
    default:
      icon = <IconMetro color={color} />;
  }

  return icon;
}

export default Guide;
