import { combineReducers } from 'redux';

import deliveryAddresses from './delivery-addresses';
import profile from './profile';
import subscriptions from './subscriptions';
import physicalTraits from './physical-traits';
import passwordChange from './password-change';

export default combineReducers({
  deliveryAddresses,
  profile,
  subscriptions,
  physicalTraits,
  passwordChange
});
