import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Dialog from 'modules/core/components/dialog/dialog';
import Trigger from 'modules/core/components/trigger/trigger';
import Input from 'modules/form/components/input/input';

import PickPointList from '../pick-point-list/pick-point-list';
import PickPointMap from '../pick-point-map/pick-point-map';

import cityShape from 'modules/geography/prop-types/city-shape';
import Loader from 'modules/core/components/loader/loader';
import { cityIn } from 'lvovich';
import BottomSheet from 'modules/core/components/bottom-sheet/bottom-sheet';
import ButtonClose from 'modules/core/components/button-close/button-close';

import './pick-point-dialog.css';

const { string, func } = PropTypes;

const PLACES = {
  dialog: Dialog,
  // dialog: BottomSheet,
  bottom_sheet: BottomSheet
};

class PickPointDialog extends Component {
  static propTypes = {
    /** Обьект текущего выбранного города */
    city: cityShape,

    /** Значения поля поиска */
    address: string,

    /** значение поля писка */
    filterMask: string,

    /** Обработчик функции изменения значения поля поиска */
    onFilterMaskChange: func
  };

  static defaultProps = {
    address: ''
  };

  render() {
    const {
      viewState,
      mapProps,
      address,
      city,
      list,
      selected,
      onClose,
      active,
      isLoaded,
      pickPointFilterMask,
      onViewStateChange,
      onSelect,
      onCardShow,
      onCardHide,
      onApiAvailable,
      onFilterMaskChange,
      onPickPointQueryChange,
      onQuerySubmit,
      pickPointType,
      place,
      ...rest
    } = this.props;

    const PalceComponent = PLACES[place];
    const isDialog = place === 'dialog';
    const isBottomSheet = place === 'bottom_sheet';

    const placeComponentProps = {
      onClose,
      ...(isDialog && { title: this.getTitle() }),
      ...(isBottomSheet && { opened: rest.show }),
      ...(isBottomSheet && { header: this.renderBottomSheetHeader() }),
      ...(isBottomSheet && { footer: this.renderBottomSheetFooter() }),
      ...(isBottomSheet && { scrollable: true }),
      ...(isBottomSheet && { withBorder: true }),
      ...(isBottomSheet && { fullOppened: true }),
      ...(isBottomSheet && { withoutSpacer: true }),
      ...rest
    };

    return (
      <PalceComponent {...placeComponentProps}>
        <div className="PickPointDialog">
          {!isLoaded ? (
            <div className="PickPointDialog-loader">
              <Loader size="small" type="spin" />
            </div>
          ) : !list ? (
            <div className="PickPointDialog-alert">Нет пунктов выдачи</div>
          ) : (
            <Fragment>
              {isDialog && (
                <div className="PickPointDialog-headerHolder">{this.renderActions()}</div>
              )}

              <div className="PickPointDialog-contentHolder">
                {viewState === 'list' && (
                  <PickPointList
                    filterMask={pickPointFilterMask}
                    selectedId={this.getPickPointId(selected)}
                    list={list}
                    onSelect={onSelect}
                    pickPointType={pickPointType}
                    fullHeight
                  />
                )}

                {viewState === 'map' && (
                  <PickPointMap
                    mapState={mapProps}
                    list={list}
                    filterMask={pickPointFilterMask}
                    activeId={this.getPickPointId(active)}
                    selectedId={this.getPickPointId(selected)}
                    onSelect={onSelect}
                    onCardShow={onCardShow}
                    onCardHide={onCardHide}
                    onApiAvailable={onApiAvailable}
                  />
                )}
              </div>
            </Fragment>
          )}
        </div>
      </PalceComponent>
    );
  }

  renderActions() {
    const {
      viewState,
      onViewStateChange,
      onQuerySubmit,
      pickPointFilterMask,
      onPickPointQueryChange
    } = this.props;

    return (
      <div className="PickPointDialog-header">
        <Media query="(max-width: 840px)">
          {isMobile => (
            <Fragment>
              <div className="PickPointDialog-trigger">
                <Trigger
                  expanded={isMobile}
                  size={isMobile ? 'small' : 'normal'}
                  value={viewState}
                  onChange={onViewStateChange}
                >
                  <Trigger.Item value="list">Списком</Trigger.Item>
                  <Trigger.Item value="map">На карте</Trigger.Item>
                </Trigger>
              </div>

              <div className="PickPointDialog-search">
                {viewState === 'map' ? (
                  <Input
                    key="map"
                    type="text"
                    id="mapSuggest"
                    placeholder={this.getAddressInputLabel()}
                    size={isMobile ? 'small' : 'normal'}
                    onKeyDown={onQuerySubmit}
                  />
                ) : (
                  <Input
                    key="list"
                    type="text"
                    placeholder={this.getAddressInputLabel()}
                    value={pickPointFilterMask}
                    size={isMobile ? 'small' : 'normal'}
                    onChange={onPickPointQueryChange}
                  />
                )}
              </div>
            </Fragment>
          )}
        </Media>
      </div>
    );
  }

  renderBottomSheetHeader() {
    const { onClose } = this.props;
    return (
      <div className="FilterBottomSheet-header">
        <div className="FilterBottomSheet-headerTitle">{this.getTitle()}</div>
        <div className="FilterBottomSheet-headerClose">
          <ButtonClose onClick={onClose} />
        </div>
      </div>
    );
  }
  renderBottomSheetFooter() {
    return <div className="FilterBottomSheet-footer">{this.renderActions()}</div>;
  }

  getTitle() {
    const { city, list } = this.props;

    return `Пункты самовывоза в ${cityIn(city.title)} ${list ? `(${list.length})` : ''}`;
  }

  getAddressInputLabel() {
    const { viewState } = this.props;

    if (viewState === 'map') {
      return 'Поиск по названию, адресу или станции метро';
    }

    return 'Фильтрация по названию, адресу или станции метро';
  }

  getPickPointId(pickPoint) {
    return pickPoint ? pickPoint.id : null;
  }
}

export default PickPointDialog;
