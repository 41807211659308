export default function initIngredientsRequest(createRequest) {
  return function _getIngredientsRequest(slug) {
    return {
      item: createRequest(initItemRequest(slug)),
      details: createRequest(initDetailsRequest(slug))
    };
  };
}

function initItemRequest(slug) {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/ingredients/${slug}`
    };
  };
}

function initDetailsRequest(slug) {
  return function getDetailsRequest() {
    return {
      method: 'GET',
      path: `/ingredients/${slug}/details`
    };
  };
}
