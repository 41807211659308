import React, { Component } from 'react';
import { getIndexLink } from 'routes/links';
import Helmet from 'react-helmet';

import { Page, PageContent } from '../components/page/index';
import PaymentAndDeliverySection from '../components/payment-and-delivery-section/payment-and-delivery-section';
import Container from '../components/container/container';
import SectionDefault from '../components/section-default/section-default';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

class PaymentAndDeliveryPage extends Component {
  render() {
    return (
      <Page
        title="Доставка и оплата"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Доставка и оплата'
          }
        ]}
      >
        <PageContent>
          <Helmet>
            <title>Доставка и оплата магазина косметики MIXIT</title>

            <meta
              name="description"
              content="Условия доставки и оплаты ваших заказов в магазине современной косметики MIXIT. Любой способ оплаты, магазины и доставка по всей России."
            />
          </Helmet>
          <PaymentAndDeliverySection />
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </PageContent>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default PaymentAndDeliveryPage;
