import qs from 'qs';

const SCRIPT_TAG_ID = 'vk-sdk';

export function login() {
  return new Promise(resolve => window.VK.Auth.login(resolve)).then(response => {
    // connected — user is authorized in VK and granted the application access;
    // not_authorized — user is authorized in VK but not granted the application access;
    // unknown — user is not authorized in VK.

    if (response.session) {
      /* User is authorized successfully */
    } else {
      /* User clicked Cancel button in the authorization window */
    }
  });
}

export function logout() {
  return new Promise(resolve => window.VK.Auth.logout());
}

function insertScript(src) {
  const el = document.createElement('script');
  el.id = SCRIPT_TAG_ID;
  el.type = 'text/javascript';
  el.src = src;
  el.async = true;
  document.head.appendChild(el);
}

function initCallback(cb) {
  window.vkAsyncInit = cb;
}

export function load(APP_ID) {
  return new Promise(resolve => {
    if (document.getElementById(SCRIPT_TAG_ID)) {
      resolve(window.VK);
      return;
    }
    insertScript('https://vk.com/js/api/openapi.js');
    initCallback(() => {
      window.VK.init({ apiId: APP_ID });
      resolve(window.VK);
    });
  });
}

const getOAuthLink = (APP_ID, redirectUri, scope, display = 'popup') =>
  `https://oauth.vk.com/authorize?client_id=${APP_ID}&display=${display}&redirect_uri=${redirectUri}&scope=${scope}&response_type=token&v=5.87`;

const ACCESS = {
  EMAIL: 1 << 22
};

function accessListToNumber(accessList) {
  return accessList.reduce((a, b) => a | b, 0);
}

export function oauth(APP_ID) {
  return new Promise((resolve, reject) => {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/vk-auth.html`;

    const handlePopupClose = () => reject();

    window.vkOAuthResponse = hash => {
      if (!popup.closed) {
        popup.removeEventListener('beforeunload', handlePopupClose);
        popup.close();
      }
      delete window.vkOAuthResponse;

      const query = hash.substring(1);
      const parameters = qs.parse(query);

      resolve(parameters);
    };

    const popup = window.open(
      getOAuthLink(APP_ID, redirectUrl, accessListToNumber([ACCESS.EMAIL]))
    );

    popup.addEventListener('beforeunload', handlePopupClose);
  });
}
