function isOS() {
  return navigator.userAgent.match(/ipad|iphone/i);
}

export default function copyStringToClipboard(text) {
  const id = 'mycustom-clipboard-textarea-hidden-id';
  let existsTextarea = document.getElementById(id);
  let range, selection;

  if (!existsTextarea) {
    console.log('Creating textarea');
    let textarea = document.createElement('textarea');

    textarea.id = id;
    // Place in top-left corner of screen regardless of scroll position.
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = '1px';
    textarea.style.height = '1px';

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = 0;

    // Clean up any borders.
    textarea.style.border = 'none';
    textarea.style.outline = 'none';
    textarea.style.boxShadow = 'none';

    // Prevent open keyboard on mobile.
    textarea.setAttribute('readonly', 'readonly');

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = 'transparent';
    document.querySelector('body').appendChild(textarea);
    // console.log('The textarea now exists :)');
    existsTextarea = document.getElementById(id);
  } else {
    // console.log('The textarea already exists :3');
  }

  existsTextarea.value = text;

  if (isOS()) {
    range = document.createRange();
    range.selectNodeContents(existsTextarea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    existsTextarea.setSelectionRange(0, 999999);
  } else {
    existsTextarea.select();
  }

  try {
    document.execCommand('copy');

    return true;
  } catch (err) {
    return false;
  }
}
