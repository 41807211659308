import React, { Component } from 'react';
import PropTypes from 'prop-types';

import formatStreet from '../../delivery-summary/helpers/format-street';

import './order-details.css';

const { string, number, arrayOf, shape } = PropTypes;

class OrderDetails extends Component {
  static propTypes = {
    cols: number,
    list: arrayOf(
      shape({
        title: string,
        value: string,
        importantInfo: string
      })
    )
  };

  static defaultProps = {
    cols: 2
  };

  render() {
    const { personalInfo, delivery, payment } = this.props;

    return (
      <div className="OrderDetails-short">
        {this.renderDetailsItem('Личные данные', [
          personalInfo.firstName,
          personalInfo.phone,
          personalInfo.email
        ])}

        {delivery.pickPoint
          ? this.renderDetailsItem('Способ доставки', [
              delivery.deliveryMethod.title,
              delivery.pickPoint.title
            ])
          : this.renderDetailsItem('Способ доставки', [delivery.deliveryMethod.title])}

        {delivery.pickPoint
          ? this.renderDetailsItem('Адрес доставки', [
              `${delivery.geography.city.title}, ${this.getPickPointAddress()}`
            ])
          : this.renderDetailsItem('Адрес доставки', [
              `${delivery.geography.city.title}, ${delivery.geography.address.raw}${
                delivery.geography.address.apartment
                  ? `, кв. ${delivery.geography.address.apartment}`
                  : ''
              }`
            ])}

        {this.renderDetailsItem('Оплата', [payment.paymentMethod.title])}
      </div>
    );
  }

  renderDetailsItem(title, arr) {
    return (
      <div className="OrderDetails-shortItem">
        <div className="OrderDetails-shortTitle">{title}</div>
        {arr.map((item, index) => (
          <div key={index} className="OrderDetails-shortValue">
            {item}
          </div>
        ))}
      </div>
    );
  }

  getPickPointAddress() {
    const { delivery } = this.props;

    const street = formatStreet(delivery.pickPoint.geography.address.street_title);

    const building = delivery.pickPoint.geography.address.building;

    return `${street}, ${building}`;
  }
}

export default OrderDetails;
