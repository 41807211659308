import React, { Component } from 'react';
import './icon-exit.css';

class IconExit extends Component {
  render() {
    return (
      <svg
        className="IconExit"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 15 15"
      >
        <path
          fillRule="evenodd"
          d="M14.863 7.842l-3.233 3.281a.465.465 0 0 1-.663 0 .476.476 0 0 1 0-.67l2.437-2.472H3.751a.471.471 0 0 1-.468-.474c0-.261.21-.473.468-.473h9.653l-2.437-2.473a.474.474 0 0 1 0-.669.465.465 0 0 1 .663 0l3.234 3.28a.48.48 0 0 1-.001.67zM7.031.937H.937v13.125h6.094a.469.469 0 0 1 0 .938H.469A.469.469 0 0 1 0 14.531V.468C0 .21.21 0 .469 0h6.562a.469.469 0 1 1 0 .937z"
        />
      </svg>
    );
  }
}

export default IconExit;
