import React, { Component } from 'react';
import './icon-flash.css';

import Svg from '../svg/svg';

class IconFlash extends Component {
  render() {
    return (
      <Svg className="IconFlash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9.31 21.65a.42.42 0 0 1-.22 0 .5.5 0 0 1-.28-.51l1-7.51H5a.5.5 0 0 1-.36-.85L14.33 2.5a.5.5 0 0 1 .58-.11.52.52 0 0 1 .28.52l-1 7.51H19a.5.5 0 0 1 .36.85L9.67 21.5a.51.51 0 0 1-.36.15zm-3.15-9.07h4.22a.49.49 0 0 1 .38.17.47.47 0 0 1 .12.39L10 19.69l7.83-8.27h-4.21a.49.49 0 0 1-.38-.17.47.47 0 0 1-.12-.39L14 4.31z" />
      </Svg>
    );
  }
}

export default IconFlash;
