import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import core from 'modules/core';
import ordering from 'modules/ordering';
import ingredient from 'modules/ingredient/reducer';
import product from 'modules/product/reducer';
import blog from 'modules/blog/ducks';
import * as productMeta from 'modules/product/meta';
import profile from 'modules/profile/ducks';
import geography from 'modules/geography/ducks';
import media from 'modules/media/reducers';
import landings from 'modules/landing/ducks';
import massMedia from 'modules/mass-media/ducks/reducer';

export default combineReducers({
  [core.MODULE_NAME]: core.reducer,
  [ordering.MODULE_NAME]: ordering.reducer,
  ingredient,
  [productMeta.MODULE_NAME]: product,
  profile,
  blog,
  geography,
  media,
  landings,
  massMedia
});

/* SELECTORS */
export const getBlogModule = createSelector(state => state.blog);
