import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './dot-pagination-button.css';

class DotPaginationButton extends PureComponent {
  render() {
    let { media, size, isActive, isMedium, isSmall, isHidden, ...rest } = this.props;

    return (
      <button
        className={classNames('DotPaginationButton', {
          'DotPaginationButton--active': isActive,
          'DotPaginationButton--medium': isMedium,
          'DotPaginationButton--small': isSmall,
          'DotPaginationButton--hidden': isHidden
        })}
        type="button"
        {...rest}
      />
    );
  }
}

export default DotPaginationButton;
