import React, { Component } from 'react';

import Media from 'react-media';

import Button from '../button/button';
import IconContainer from '../icon-container/icon-container';
import { IconAngleArrowDown } from '../icons';

import Form from 'modules/form/components/form/form';
import Field from 'modules/form/components/field/field';
import Radio from 'modules/form/components/radio/radio';
import Textarea from 'modules/form/components/textarea/textarea';

import './email-feedback.css';

const COMMENT_OPTION_VALUE = 'comment';

class EmailFeedback extends Component {
  render() {
    const { variant, isButtonDisabled } = this.props;

    return (
      <div className="EmailFeedback">
        <div className="EmailFeedback-gratitudeGroup">
          <div className="EmailFeedback-comment">{variant.comment}</div>

          <div className="EmailFeedback-iconHolder">
            <div className="EmailFeedback-icon">
              <IconContainer>
                <IconAngleArrowDown />
              </IconContainer>
            </div>
          </div>
        </div>

        <div className="EmailFeedback-form">
          <Form>
            <Form.Row>
              <Field
                label="Какие материалы в наших рассылках вызывают интерес и какие хотелось бы в них добавить?"
                notAbsoluteLabel
              >
                <ul className="EmailFeedback-optionList">
                  {variant.optionList.map(option => {
                    const isTextareaDisplay = this.getIsTextareaDisplay(option);

                    return (
                      <li key={option.value} className="EmailFeedback-option">
                        <Radio
                          label={option.title}
                          name={variant.title}
                          value={option.value}
                          onChange={this.handleRadioChange(option)}
                        />

                        {isTextareaDisplay && (
                          <div className="EmailFeedback-optionTextarea">
                            <Textarea
                              placeholder="Комментарий"
                              onChange={this.handleTextareaChange}
                              opened
                            />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Field>
            </Form.Row>

            <Form.ActionRow>
              <div className="EmailFeedback-button">
                <Media query="(max-width: 840px)">
                  {isMobile => (
                    <Button
                      variant="primary"
                      title="Отправить"
                      size={isMobile ? 'small' : 'normal'}
                      expanded={isMobile}
                      disabled={isButtonDisabled}
                      onClick={this.handleButtonClick}
                    />
                  )}
                </Media>
              </div>
            </Form.ActionRow>
          </Form>
        </div>
      </div>
    );
  }

  getIsTextareaDisplay(option) {
    const { isCommentOptionSelected } = this.props;

    return isCommentOptionSelected && option.value === COMMENT_OPTION_VALUE;
  }

  handleRadioChange = option => () => {
    const { onOptionSelect } = this.props;

    onOptionSelect(option);
  };

  handleTextareaChange = e => {
    const { onCommentChange } = this.props;
    const { value } = e.target;

    onCommentChange(value);
  };

  handleButtonClick = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };
}

export default EmailFeedback;
