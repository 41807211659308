import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { arrayToObject } from 'modules/utils';

// import { getCategoryLink, getSpecialOffersLink } from 'routes/links';
import Container from '../container/container';
import MainCategoryPanel from './main-category-panel';
// import FaceCategoryPanel from './face-category-panel';
// import BodyCategoryPanel from './body-category-panel';
// import HairCategoryPanel from './hair-category-panel';
// import HealthCategoryPanel from './health-category-panel';
// import EffectiveCategoryPanel from './effective-category-panel';
// import MakeupCategoryPanel from './makeup-category-panel';
// import SetsCategoryPanel from './sets-category-panel';
// import ComplexesCategoryPanel from './complexes-category-panel';
// import AccessoriesCategoryPanel from './accessories-category-panel';
import { Spring, animated } from 'react-spring';
import Loader from 'modules/core/components/loader/loader';
import { IconSpecialOfferCoupon } from '../icons';

import * as fromNavigations from 'modules/core/ducks/navigations';
import * as fromShopList from 'modules/core/ducks/shop-list';

import './catalog-navigation.css';

const mapStateToProps = createStructuredSelector({
  navigation: fromNavigations.getMainCatalogNavigationMobile,
  isNavigationLoaded: fromNavigations.getNavigationDataLoaded,
  isNoRussia: fromShopList.getIsBelarusOrKazahstan
});

class CatalogNavigation extends PureComponent {
  state = {
    currentCategory: null,
    showPanel: false
  };

  render() {
    const { navigation, isNavigationLoaded, isNoRussia } = this.props;
    const { currentCategory, showPanel } = this.state;

    const { pathname } = this.props.location;

    const urlModificator = pathname.split('/')[1];

    if (!isNavigationLoaded) {
      return (
        <div style={{ position: 'relative', paddingTop: '64px' }}>
          <Loader size="small" secondary />
        </div>
      );
    }

    if (!navigation) {
      return null;
    }

    return (
      <nav
        className={cn('CatalogNavigation', {
          [`CatalogNavigation--url-${urlModificator}`]: Boolean(urlModificator),
          'CatalogNavigation--url-index': !Boolean(urlModificator),
          'CatalogNavigation--hasBorderBottom': isNoRussia && !Boolean(urlModificator)
        })}
        onMouseLeave={this.handleMouseLeave}
      >
        <Container>
          <ul className="CatalogNavigation-list">
            {navigation.list.map(item => {
              const LinkComponent = item.href ? 'a' : Link;

              return (
                <li key={item.id} className="CatalogNavigation-listItem">
                  <LinkComponent
                    {...item.linkProps}
                    className={cn('CatalogNavigation-link', {
                      'CatalogNavigation-link--active': item.slug === currentCategory && showPanel

                      // 'CatalogNavigation-link--highlighted': item.highlighted
                    })}
                    onMouseOver={this.handleMouseOver(item)}
                    onClick={this.handleClick(item)}
                  >
                    {item.title === 'Акции' ? (
                      <span className="CatalogNavigation-listItemValue CatalogNavigation-listItemValue--widthIcon">
                        <div
                          style={{
                            height: '24px',
                            verticalAlign: 'top',
                            paddingTop: '2px',
                            marginRight: '4px'
                          }}
                        >
                          <IconSpecialOfferCoupon />
                        </div>

                        {item.title}
                      </span>
                    ) : (
                      <span className="CatalogNavigation-listItemValue">{item.title}</span>
                    )}
                  </LinkComponent>
                </li>
              );
            })}
          </ul>
        </Container>
        {this.renderNavigationPanel()}
      </nav>
    );
  }

  renderNavigationPanel() {
    const { currentCategory, showPanel } = this.state;

    // const CategoryPanel = this.getCategoryPanel(currentCategory);
    // const show = !CategoryPanel ? false : showPanel;
    // const categoryPanelExist = Boolean(CategoryPanel);

    const categoryPanel = this._getCategoryPanel(currentCategory);
    const show = !categoryPanel ? false : showPanel;
    const categoryPanelExist = Boolean(categoryPanel);

    return (
      <div className="CatalogNavigation-panelHolder">
        <Spring
          from={{
            height: 0,
            opacity: show ? 1 : 0,
            visibility: show ? 'visible' : 'hidden'
          }}
          to={{
            height: show ? 'auto' : 0,
            opacity: show ? 1 : 0,
            visibility: show ? 'visible' : 'hidden'
          }}
          native
          force
          unique
        >
          {({ height, opacity, visibility }) => (
            <animated.div className="CatalogNavigation-panelBlock" style={{ height }}>
              <div className="CatalogNavigation-panelBlockInner">
                <animated.div className="CatalogNavigation-panel">
                  <Spring
                    key={currentCategory}
                    from={{ opacity: 0 }}
                    to={{ opacity: show ? 1 : 0 }}
                    native
                    force
                  >
                    {props => (
                      <animated.div style={props}>
                        <Container>
                          {/* {categoryPanelExist && (
                            <CategoryPanel
                              onNestedLinkClick={this.handleNestedLinkClick}
                              onFilterLinkClick={this.handleFilterLinkClick}
                              onSubcategoryCardClick={this.handleSubcategoryCardClick}
                            />
                          )} */}
                          {categoryPanelExist && categoryPanel}
                        </Container>
                      </animated.div>
                    )}
                  </Spring>
                </animated.div>
              </div>

              <animated.div
                style={{ opacity, visibility }}
                className="CatalogNavigation-panelBlockShadow"
              />
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }

  _getCategoryPanel(currentCategory) {
    const { navigation } = this.props;

    const navigationTable = arrayToObject(navigation.list, 'slug');
    const currentNavigation = navigationTable[currentCategory];
    const existNavigation = Boolean(currentNavigation);

    if (!existNavigation || !currentNavigation.submenu) {
      return null;
    }

    return (
      <MainCategoryPanel
        onNestedLinkClick={this.handleNestedLinkClick}
        onFilterLinkClick={this.handleFilterLinkClick}
        onSubcategoryCardClick={this.handleSubcategoryCardClick}
        submenuList={currentNavigation.submenu}
      />
    );
  }

  // getCategoryPanel(currentCategory) {
  //   switch (currentCategory) {
  //     case 'face':
  //       return FaceCategoryPanel;
  //     case 'body':
  //       return BodyCategoryPanel;
  //     case 'hair':
  //       return HairCategoryPanel;
  //     case 'decorate':
  //       return null;
  //     case 'gift-sets':
  //       return SetsCategoryPanel;
  //     case 'effective':
  //       return EffectiveCategoryPanel;
  //     case 'health':
  //       return HealthCategoryPanel;
  //     case 'accessories':
  //       return null;
  //     default:
  //       return null;
  //   }
  // }

  closeNavigationPanel() {
    this.setState({
      showPanel: false
    });
  }

  openNavigationPanel() {
    this.unignoreMouseOver();
    this.setState({
      showPanel: true
    });
  }

  selectCategory(currentCategory) {
    this.setState({
      currentCategory
    });
  }

  ignoreNextMouseOver(slug) {
    this._ignoreMouseOver = slug;
  }

  unignoreMouseOver() {
    this._ignoreMouseOver = false;
  }

  handleMouseLeave = () => {
    clearTimeout(this._timer);
    this.closeNavigationPanel();
    this.unignoreMouseOver();
  };

  handleMouseOver = category => () => {
    clearTimeout(this._timer);
    if (this._ignoreMouseOver === category.slug) {
      return;
    }
    this.selectCategory(category.slug);

    this.unignoreMouseOver();

    if (!this.state.showPanel) {
      this._timer = setTimeout(() => this.openNavigationPanel(), 400);
    }
  };

  handleClick = category => () => {
    clearTimeout(this._timer);
    this.closeNavigationPanel();
    this.ignoreNextMouseOver(category.slug);
  };

  handleFilterLinkClick = () => {
    this.closeNavigationPanel();
  };

  handleNestedLinkClick = () => {
    this.closeNavigationPanel();
  };

  handleSubcategoryCardClick = () => {
    this.closeNavigationPanel();
  };
}

const enhancedCatalogNavigation = withRouter(connect(mapStateToProps)(CatalogNavigation));

export default enhancedCatalogNavigation;
