import React, { Component } from 'react';
import Container from '../container/container';

import DeclarationCard from '../declaration-card/declaration-card';

import './rewards-section.css';

class RewardsSection extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="RewardsSections">
        <Container>
          <div className="RewardsSections-content">
            <ul className="RewardsSections-grid">
              {data.map((declaration, index) => (
                <li key={index} className="RewardsSections-gridItem">
                  <DeclarationCard title={declaration.title} media={declaration.media} />
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </div>
    );
  }
}

export default RewardsSection;
