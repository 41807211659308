import React from 'react';

function rememberStyle(element, properties) {
  const style = properties
    .map(item => ({ [item]: element.style[item] }))
    .reduce((all, item) => Object.assign(all, item), {});

  element._rememberedStyles = style;
}

function restoreStyle(element) {
  if (!element._rememberedStyles) {
    return;
  }

  Object.entries(element._rememberedStyles).forEach(([name, value]) => {
    element.style[name] = value;
  });
}

function getScroll() {
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';

  if (supportPageOffset) {
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;
    return [scrollX, scrollY];
  }

  if (isCSS1Compat) {
    const { scrollLeft, scrollTop } = document.documentElement;
    return [scrollLeft, scrollTop];
  }

  const { scrollLeft, scrollTop } = document.body.scrollTop;

  return [scrollLeft, scrollTop];
}

class LockScroll extends React.Component {
  render() {
    return null;
  }

  componentDidMount() {
    requestAnimationFrame(() => this.lock());
  }

  componentWillUnmount() {
    requestAnimationFrame(() => this.unlock());
  }

  shouldComponentUpdate() {
    return false;
  }

  lock() {
    this._scroll = getScroll();
    const [scrollX, scrollY] = this._scroll;
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    const documentStyles = {
      overflow: 'hidden',
      height: '100%',
      width: '100%'
    };

    const bodyStyles = {
      overflow: 'visible',
      position: 'relative',
      top: `${-scrollY}px`,
      left: `${-scrollX}px`,
      paddingRight: `${scrollBarWidth}px`
    };

    rememberStyle(document.documentElement, Object.keys(documentStyles));
    rememberStyle(document.body, Object.keys(bodyStyles));

    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }

    Object.assign(document.documentElement.style, documentStyles);
    Object.assign(document.body.style, bodyStyles);
  }

  unlock() {
    restoreStyle(document.documentElement);
    restoreStyle(document.body);
    window.scrollTo(...this._scroll);
  }
}

export default LockScroll;
