import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { number } from 'prop-types';
import SuccessfulOrder from '../components/successful-order/successful-order';
// import * as fromDeliveryForm from 'modules/ordering/ducks/delivery-form';
import { getLastOrder } from 'modules/ordering/ducks/last-order/selectors';

import * as fromOrders from '../ducks/orders';
import { setPaymentLinkExpireTime } from '../ducks/last-order/action';

const mapState = createStructuredSelector({
  lastOrder: getLastOrder
});

// const mapState = createStructuredSelector({
//   isLoading: fromOrders.getIsLoadingById,
//   order: fromOrders.getItemById,
//   payment: fromOrders.getPaymentById,
//   comment: fromOrders.getCommentById,
//   delivery: fromOrders.getDeliveryById,
//   personalInfo: fromOrders.getPersonalInfoById,
//   goodsList: fromOrders.getGoodsListById,
//   productsCost: fromOrders.getProductsCostWithDiscount,
//   deliveryCost: fromOrders.getDeliveryCostById,
//   totalCost: fromOrders.getTotalCostById,
//   isPaymentRequired: fromOrders.getIsPaymentRequiredById,
//   isPaymentLoading: fromOrders.getIsPaymentLoading,
//   deliveryMethod: fromDeliveryForm.getDeliveryMethod,
//   currentDeliveryCost: fromDeliveryForm.getDeliveryCost
// });

const mapDispatch = {
  request: fromOrders.actions.request,
  paymentRequest: fromOrders.actions.paymentRequest,
  setPaymentLinkExpireTime: setPaymentLinkExpireTime
};

class SuccessfulOrderContainer extends Component {
  static propTypes = {
    id: number
  };

  state = {
    hasError: false
  };

  render() {
    const {
      lastOrder,
      // changeExpiredMilliseconds,
      // changeExpireAfter,
      setGetPaymentLinkExpireTime
    } = this.props;
    const {
      order,
      payment,
      comment,
      delivery,
      personalInfo,
      goodsList,
      isLoading,
      isPaymentRequired,
      productCost,
      deliveryCost,
      totalCost,
      isPaymentLoading,
      deliveryMethod,
      currentDeliveryCost,
      paymentLinkExpireDate
    } = lastOrder;


    const { hasError } = this.state;

    if (hasError) {
      return <b>Error :(</b>;
    }

    return (
      <SuccessfulOrder
        isLoading={isLoading}
        requirePayment={isPaymentRequired}
        isPaymentLoading={isPaymentLoading}
        order={order}
        payment={payment}
        comment={comment}
        delivery={delivery}
        personalInfo={personalInfo}
        goodsList={goodsList}
        productsCost={productCost}
        deliveryCost={deliveryCost}
        totalCost={totalCost}
        onPaymentRequest={this.handlePaymentRequest}
        deliveryMethod={deliveryMethod}
        currentDeliveryCost={currentDeliveryCost}
        setGetPaymentLinkExpireTime={setGetPaymentLinkExpireTime}
        paymentLinkExpireDate={paymentLinkExpireDate}
      />
    );
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error);
    }
    console.warn(error);
    this.setState({ hasError: true });
  }

  componentDidMount() {
    this.props.request(this.props.id);
  }

  handlePaymentRequest = () => {
    this.props.paymentRequest(this.props.id);
    const currentTime = Date.now();
    const _paymentLinkExpireDate = new Date(currentTime + 19.8 * 60000);
    this.props.setPaymentLinkExpireTime(_paymentLinkExpireDate);
  };
}

export default connect(
  mapState,
  mapDispatch
)(SuccessfulOrderContainer);
