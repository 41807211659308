import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import { Link as RouterLink } from 'react-router-dom';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconSignArrowLeft, IconSignArrowRight } from 'modules/core/components/icons';

import { getArticleLink } from 'routes/links';

import './next-article.css';

const { string, shape } = PropTypes;

class NextArticle extends Component {
  static propTypes = {
    previous: shape({
      title: string.isRequired,
      slug: string.isRequired
    }),

    next: shape({
      title: string.isRequired,
      slug: string.isRequired
    })
  };

  static defaultProps = {
    previous: null,
    next: null
  };

  render() {
    return (
      <div className="NextArticle">
        <div className="NextArticle-leftColumn">{this.renderPreviousArticle()}</div>

        <div className="NextArticle-separatorColumn">{this.renderSeparator()}</div>

        <div className="NextArticle-rightColumn">{this.renderNextArticle()}</div>
      </div>
    );
  }

  renderPreviousArticle() {
    const { previous } = this.props;

    if (!previous) {
      return null;
    }

    return (
      <div className="NextArticle-button">
        <div className="NextArticle-previousArrowHolder">
          <RouterLink
            to={getArticleLink(previous)}
            className="NextArticle-arrow"
            title="Перейти к статье"
          >
            <Media query="(max-width: 840px)">
              {isMobile => (
                <IconContainer size={isMobile ? 'small' : 'xsmall'}>
                  <IconSignArrowLeft />
                </IconContainer>
              )}
            </Media>
          </RouterLink>
        </div>

        <div className="NextArticle-contentHolder">
          <div className="NextArticle-caption">Предыдущая статья</div>
          <h3 className="NextArticle-title">{previous.title}</h3>
        </div>
      </div>
    );
  }

  renderNextArticle() {
    const { next } = this.props;

    if (!next) {
      return null;
    }

    return (
      <div className="NextArticle-button">
        <div className="NextArticle-contentHolder">
          <div className="NextArticle-caption">Следующая статья</div>
          <h3 className="NextArticle-title">{next.title}</h3>
        </div>

        <div className="NextArticle-nextArrowHolder">
          <RouterLink
            to={getArticleLink(next)}
            className="NextArticle-arrow"
            title="Перейти к статье"
          >
            <Media query="(max-width: 840px)">
              {isMobile => (
                <IconContainer size={isMobile ? 'small' : 'xsmall'}>
                  <IconSignArrowRight />
                </IconContainer>
              )}
            </Media>
          </RouterLink>
        </div>
      </div>
    );
  }

  renderSeparator() {
    const { previous, next } = this.props;

    if (!previous || !next) {
      return null;
    }

    return <div className="NextArticle-separator" role="presentation" />;
  }
}

export default NextArticle;
