import * as TYPES from '../types/draw-roll-up';
import { createAction } from 'modules/utils/dux';

export default {
  open() {
    return createAction(TYPES.DRAW_ROLL_UP_OPEN);
  },

  close() {
    return createAction(TYPES.DRAW_ROLL_UP_CLOSE);
  }
};
