import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import generateResizedImageLink from './helpers/generate-resized-image-link';

function canUseWebP() {
  if (!__BROWSER__) {
    return false;
  }
  let elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
  // was able or not to get WebP representation
  return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
}

function getRatio(dimensions) {
  return dimensions.height / dimensions.width;
}

function Source({ normal, double, size, ...sourceProps }) {
  const srcset = double ? `${double} 2x, ${normal}` : normal;
  return <source srcSet={srcset} {...sourceProps} />;
}

function Image({ normal, double, caption }) {
  return <img src={normal} srcSet={double && `${double} 2x`} alt={caption} title={caption} />;
}

class Picture extends Component {
  static propTypes = {
    sources: PropTypes.arrayOf(PropTypes.object).isRequired,
    alt: PropTypes.string.isRequired
  };

  static defaultProps = {
    local: false
  };

  render() {
    const {
      local,
      sources,
      alt,
      wait,
      onLoad,
      onError,
      isLoaded,
      resizeWidth,
      resizeHeight,
      background,
      blur,
      ratio
    } = this.props;

    if (!sources) {
      return null;
    }

    const preparedSources = this.prepareSources(sources);

    if (!preparedSources.length) {
      return null;
    }

    const largestSource = preparedSources[preparedSources.length - 1];
    
    const resized = resizeWidth > 0 || resizeHeight > 0;
  
    return (
      <Fragment>
        {!isLoaded && (
          <span
            className="Media-placeholderHolder"
            style={{
              paddingBottom: `${ratio || getRatio(largestSource.dimensions) * 100}%`
            }}
          >
            <span className="Media-placeholder" />
          </span>
        )}

        {!wait && (
          <picture className="Media-picture" onLoad={onLoad} onError={onError} alt={alt}>

            {canUseWebP() && this.renderMainImage(
              largestSource.fallback,
              alt,
              {
                width: resizeWidth,
                height: resizeHeight,
                background,
                blur
              },
              local,
              true
            )}

            {resized
              ? this.renderMainImage(
                  largestSource.fallback,
                  alt,
                  {
                    width: resizeWidth,
                    height: resizeHeight,
                    background,
                    blur
                  },
                  local
                )
              : preparedSources.map(this.renderItem)}

            {this.renderFallbackImage(largestSource.fallback, alt, {
              width: resizeWidth,
              height: resizeHeight,
              blur
            })}
          </picture>
        )}
      </Fragment>
    );
  }

  renderMainImage(source, alt, { width, height, background, blur }, local = false, renderWebp = false) {
    const { normal, double } = source;

    const type = renderWebp ? "image/webp" : null;

    const resizeOptions = {
      background,
      resizing_type: 'fill',
      width,
      height,
      blur
    };

    const doubleResizeOptions = {
      background,
      resizing_type: 'fill',
      width: 2 * width,
      height: 2 * height,
      blur
    };

    const rawSource = double || normal;

    const resizedNormal = generateResizedImageLink(rawSource, resizeOptions, local, renderWebp);
    const resizedDouble = generateResizedImageLink(rawSource, doubleResizeOptions, local, renderWebp);

    return <Source normal={resizedNormal} double={resizedDouble} type={type} />;
  }

  renderFallbackImage(source, alt, { width, height, blur }) {
    const { normal } = source;

    const resizeOptions = {
      resizing_type: 'fill',
      width,
      height,
      blur
    };

    const resizedNormal = generateResizedImageLink(normal, resizeOptions);

    return <Image normal={resizedNormal} caption={alt} />;
  }

  renderItem = (source, index) => {
    const { main, fallback } = source;

    return (
      <Fragment key={index}>
        {main && <Source normal={main.normal} double={main.double} />}
        {fallback && <Source normal={fallback.normal} double={fallback.double} />}
      </Fragment>
    );
  };

  renderSource(src, size, previousSize = 0, index) {
    return (
      <source
        key={index}
        srcSet={src}
        media={`(min-width: ${previousSize}px)`}
        sizes={`${size}px`}
      />
    );
  }

  prepareSources(sources) {
    const sortedSources = sources.sort((a, b) => a.dimensions.width - b.dimensions.width);

    let prepared = [
      {
        ...sortedSources[0],
        minWidth: 0
      }
    ];

    for (let i = 0; i < sortedSources.length - 1; i++) {
      prepared[i + 1] = {
        ...sortedSources[i],
        minWidth: sortedSources[i].dimensions.width
      };
    }

    return prepared.reverse();
  }
}

export default Picture;
