import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Page from 'modules/core/components/page/page';
import AgreementsSection from '../components/agreements-section/agreements-section';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';

import { getIndexLink } from 'routes/links';

class AgreementsPage extends Component {
  static path = '/agreements';
  static link = () => 'agreements';
  static exact = true;

  render() {
    return (
      <Page
        title="Пользовательские соглашения"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Пользовательские соглашения'
          }
        ]}
      >
        <Helmet>
          <title>Пользовательские соглашения с компанией MIXIT</title>

          <meta
            name="description"
            content="Пользовательские соглашения с компанией MIXIT. Наш интернет-магазин соблюдает все правила дистанционной торговли. Совершать покупки в Миксит - безопасно для ваших персональных данных."
          />
        </Helmet>

        <AgreementsSection />
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default AgreementsPage;
