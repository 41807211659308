import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from '../../utils/debug-reselect';

import * as AB_TEST_MARKERS from './types/a-b-test-markers';

/* REDUCERS */

const reducer = createReducer(
  handle(AB_TEST_MARKERS.SET, (state, {name, type = null}) => {
    return {
      ...state,
      [name]: type
    };
  })
);

export default reducer({
  productPage: null,
});

/* SELECTORS */

const getRoot = state => state.core.abtestmarkers;

export const getProductPageVersion = createSelector(
  getRoot,
  root => root.productPage
);

export const getIsProductPageTestVersion = createSelector(
  getProductPageVersion,
  productPageVersion => productPageVersion === 'TYPE/B'
);

export const getProductRatingVersion = createSelector(
  getRoot,
  root => root.productRating
);

export const getIsProductRatingTestB = createSelector(
  getProductRatingVersion,
  productRatingVersion => productRatingVersion === 'TYPE/B'
);