export default function weekdaysToString(days) {
  const daysWithoutZero = replaceZeroToSeven(days);

  const sortedDays = daysWithoutZero.sort((a, b) => a - b);

  const intervals = getIntervals(sortedDays);

  const stringIntervals = getStringIntervals(intervals);

  return stringIntervals.join(', ');
}

function getStringIntervals(intervals) {
  let stringIntervals = [];

  for (let i = 0; i < intervals.length; i++) {
    if (intervals[i].length === 1) {
      stringIntervals.push(getStringDayFromDayNumber(intervals[i][0]));
    } else if (intervals[i].length === 2) {
      stringIntervals.push(getStringDayFromDayNumber(intervals[i][0]));
      stringIntervals.push(getStringDayFromDayNumber(intervals[i][1]));
    } else {
      stringIntervals.push(
        `${getStringDayFromDayNumber(intervals[i][0])}-${getStringDayFromDayNumber(
          intervals[i][intervals[i].length - 1]
        )}`
      );
    }
  }

  return stringIntervals;
}

function replaceZeroToSeven(days) {
  return days.map(day => (day === 0 ? 7 : day));
}

function getStringDayFromDayNumber(number) {
  const rusSringDaysArray = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  return rusSringDaysArray[number - 1];
}

function getIntervals(days) {
  let intervals = [];
  let currentInterval = [];

  for (let i = 0; i < days.length; i++) {
    if (!currentInterval.length) {
      currentInterval.push(days[i]);
    } else {
      if (days[i] === currentInterval[currentInterval.length - 1] + 1) {
        currentInterval.push(days[i]);
      } else {
        intervals.push(currentInterval);
        currentInterval = [days[i]];
      }
    }
  }

  if (currentInterval.length) {
    intervals.push(currentInterval);
  }

  return intervals;
}
