import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FullIngredient from 'modules/ingredient/components/full-ingredient/full-ingredient';
import * as fromIngredients from 'modules/ingredient/ducks/ingredients';
import { actions as Ingredient } from '../ducks/ingredients';

const mapStateToProps = createStructuredSelector({
  ingredient: fromIngredients.getIngredientsWithDetails,
  isLoading: fromIngredients.getLoadingDetailsById
});

const mapDispatchToProps = {
  show: Ingredient.showDetails
};

class FullIngredientContainer extends Component {
  render() {
    return <FullIngredient {...this.props} inDialog />;
  }

  init(props) {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
    props.show(props.id);
  }

  UNSAFE_componentWillUpdate(props) {
    if (props.id === this.props.id) {
      return;
    }

    this.init(props);
  }

  componentDidMount() {
    this.init(this.props);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullIngredientContainer);
