import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import formatDateFromObject from '../../helpers/format-date-from-object';
import parseDate from '../../helpers/parse-date';
import formatToISOString from '../../helpers/format-to-iso-string';

import BaseEditable from '../base-editable/base-editable';
import DateField from '../date-field/date-field';

const { func, shape, string } = PropTypes;

const MIN_YEAR = 1900;

class EditableDate extends Component {
  static propTypes = {
    address: shape({
      building: string,
      apartment: string
    }),
    onChange: func
  };

  render() {
    const { date } = this.props;
    const parsedDate = parseDate(date);

    return (
      <BaseEditable
        prepareValue={value => value && formatDateFromObject(value)}
        renderEdit={({ editingValue, onChange }) => (
          <DateField date={editingValue} onChange={onChange} />
        )}
        onChange={this.handleChange}
        onEdit={this.handleEdit}
        onCancel={this.handleCancel}
        value={parsedDate}
      />
    );
  }

  normalizeDate(date) {
    const normalizedDate = { ...date };

    if (!date.day || date.day === '0' || date.day === '00') {
      normalizedDate.day = '01';
    }

    if (!date.month || date.month === '0' || date.month === '00') {
      normalizedDate.month = '01';
    }

    if (!date.year) {
      normalizedDate.year = MIN_YEAR;
    }

    return normalizedDate;
  }

  handleEdit = () => {
    const { onEdit } = this.props;

    if (!isFunction(onEdit)) {
      return;
    }
    onEdit();
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (!isFunction(onCancel)) {
      return;
    }
    onCancel();
  };

  handleChange = value => {
    const { onChange } = this.props;

    if (!value) {
      onChange('');
      return;
    }

    const normalizedDate = this.normalizeDate(value);

    const ISOString = formatToISOString(normalizedDate, MIN_YEAR);

    if (!isFunction(onChange)) {
      return;
    }
    onChange(ISOString);
  };
}

export default EditableDate;
