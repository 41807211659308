export const REQUEST = 'core/recovery-password/REQUEST';
export const RESPONSE = 'core/recovery-password/RESPONSE';

export const CONFIRM_PASSWORD_REQUEST = 'core/recovery-password/CONFIRM_PASSWORD_REQUEST';
export const CONFIRM_PASSWORD_RESPONSE = 'core/recovery-password/CONFIRM_PASSWORD_RESPONSE';

export const START_RECOVERY = 'core/recovery-password/START_RECOVERY';

export const ERROR = 'core/recovery-password/ERROR';
export const RESET_ERROR = 'core/recovery-password/RESET_ERROR';

export const SET_SUBMITTING = 'core/recovery-password/SET_SUBMITTING';
export const RESET_SUBMITTING = 'core/recovery-password/RESET_SUBMITTING';

export const SET_REGISTERED_EMAIL = 'core/recovery-password/SET_REGISTERED_EMAIL';
export const RESET_REGISTERED_EMAIL = 'core/recovery-password/RESET_REGISTERED_EMAIL';

export const SAVE_CONFIRM_KEY = 'core/recovery-password/SAVE_CONFIRM_KEY';
export const REMOVE_CONFIRM_KEY = 'core/recovery-password/REMOVE_CONFIRM_KEY';
