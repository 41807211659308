export default function getHeaders(authorization, multipart = false) {
  const headers = {
    authorization,
    accept: 'application/json',
    'content-type': 'application/json'
  };

  if (multipart) {
    delete headers['content-type'];
  }

  if (!authorization) {
    delete headers.authorization;
  }

  return headers;
}
