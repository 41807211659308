import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ConfirmPasswordRecoverySuccessDialog from '../components/confirm-password-success-dialog/confirm-password-success-dialog';
import * as fromDialogs from '../ducks/dialogs';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsConfirmPasswordRecoverySuccessOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeConfirmPasswordSuccessRecoveryDialog
};

class ConfirmPasswordRecoverySuccessDialogContainer extends Component {
  render() {
    const { isOpened } = this.props;

    return <ConfirmPasswordRecoverySuccessDialog show={isOpened} onClose={this.handleClose} />;
  }

  handleClose = () => {
    this.props.close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPasswordRecoverySuccessDialogContainer);
