import types from './types';
import { createSelector } from 'reselect';
import * as TYPES from './types/recent';
import * as fromProducts from 'modules/product/ducks/products';
import { createReducer, handle } from 'modules/utils/dux';

const reducer = createReducer(
  handle(types.products.SHOW_ITEM, (state, { slug }) => {
    return [slug, ...state.filter(item => item !== slug)];
  }),

  handle(types.products.OVERVIEW_SHOWED, (state, { product, isInRecent }) => {
    const { slug } = product;

    if (isInRecent) {
      return state;
    }
    return [slug, ...state.filter(item => item !== slug)];
  }),

  handle(TYPES.REHYDRATE, (state, { recentList }) => {
    if (!recentList) {
      return state;
    }

    const slugList = recentList.map(product => product.slug);
    return slugList;
  })
);

export default reducer([]);

/* SELECTORS */

export const getRoot = state => state.products.recent;

export const getList = createSelector(getRoot, root => root);
export const getProductList = createSelector(
  getList,
  fromProducts.getFindProduct,
  (list, findProduct) => {
    return list.map(slug => findProduct(slug)).filter(Boolean);
  }
);

export const getCleanedProductList = createSelector(getProductList, list => {
  return list.map(product => {
    const { inBasket, isDetailsLoaded, details, ...rest } = product;

    return {
      ...rest,
      shouldBeUpdated: true
    };
  });
});
