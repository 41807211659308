import React, { Component } from 'react';
import './notification-counter.css';

class NotificationCounter extends Component {
  render() {
    return <span className="NotificationCounter">{this.props.count}</span>;
  }
}

export default NotificationCounter;
