import React, { Component } from 'react';
import { getIndexLink } from 'routes/links';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Helmet from 'react-helmet';
import Media from 'react-media';

import * as fromFAQ from 'modules/core/ducks/faq';
import { Page, PageContent } from '../components/page/index';
import Container from '../components/container/container';
import SectionDefault from '../components/section-default/section-default';
import FAQSection from 'modules/core/components/faq-section/faq-section';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import Loader from 'modules/core/components/loader/loader';

class FAQPage extends Component {
  render() {
    const { list, ...rest } = this.props;

    return (
      <Page
        title="FAQ"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'FAQ'
          }
        ]}
      >
        <Media query="(max-width: 840px)">
          {isMobile => (
            <PageContent>
              <Helmet>
                <title>MIXIT - FAQ</title>

                <meta name="description" content="FAQ - часто задаваемые вопросы" />
              </Helmet>
              {/* <RewardsSectionsContainer /> */}
              {Boolean(list) ? (
                <FAQSection list={list} isMobile={isMobile} {...rest} />
              ) : (
                <div style={{ paddingTop: '110px', position: 'relative' }}>
                  <Loader />
                </div>
              )}
              <SectionDefault>
                <Container>
                  <FeedbackCall />
                </Container>
              </SectionDefault>
            </PageContent>
          )}
        </Media>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    const { list, requestList } = this.props;
    this.scrollWindowToTop();
    if (!list) {
      requestList();
    }
  }
}

const mapStateToProps = createStructuredSelector({
  list: fromFAQ.getList
});

const mapDispatchToProps = {
  requestList: fromFAQ.Actions.request,
  // openFAQQuestion: fromDialogs.actions.openFAQQuestion,
  openFAQQuestion: fromFAQ.Actions.sendQuestion
};

const enhancedFAQPage = connect(mapStateToProps, mapDispatchToProps)(FAQPage);

export default enhancedFAQPage;
