import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import {separateListByCondition} from "modules/utils"

import { getTopElementPosition, getMaxScroll } from 'modules/utils';

import Loader from '../loader/loader';
import Shop from '../shop/shop';
import ShopsMap from '../shops-map/shops-map';
import HideErrorBoundary from '../hide-error-boundary/hide-error-boundary';

import CitySelectPanelContainer from '../../containers/city-select-panel';

import './shops-grid.css';

function getHasPPD(shop){
  const hasPPD = shop.features && shop.features.some(feature => feature.type === "FEATURE/PPD")
  return Boolean(hasPPD)
}

class ShopsGrid extends Component {
  _mapElement = null;
  _shopListElement = null;
  _shopElements = {};

  render() {
    const {
      shopList,
      activeCity,
      activeCityId,
      activeShopId,
      isLoaded,
      isActiveCityWasChanged,
      isCityListAvailable,
      mapState,
      onCitySelect,
      onApiAvaliable
    } = this.props;

    const [shopsWithPPD, shopsWithoutPPD] = separateListByCondition(shopList, getHasPPD)
    const sortedShopList = [...shopsWithPPD, ...shopsWithoutPPD]

    return (
      <div className="ShopsGrid">
        <div className="ShopsGrid-listCol">
          {!isLoaded ? (
            <div className="ShopsGrid-loader">
              <Media query="(max-width: 640px)">
                {isMobile => <Loader type="spin" size={isMobile ? 'small' : 'normal'} />}
              </Media>
            </div>
          ) : (
            <Fragment>
              <HideErrorBoundary>
                <div className="ShopsGrid-searchPanel">
                  <CitySelectPanelContainer
                    activeCity={activeCity}
                    activeCityId={activeCityId}
                    shopList={shopList}
                    isActiveCityWasChanged={isActiveCityWasChanged}
                    isLoaded={isLoaded}
                    isCityListAvailable={isCityListAvailable}
                    onCitySelect={onCitySelect}
                  />
                </div>
              </HideErrorBoundary>

              <ul className="ShopsGrid-list" ref={this.setShopListElementRef}>
                {sortedShopList.map(shop => (
                  <HideErrorBoundary key={shop.id}>
                    <li className="ShopsGrid-item" ref={this.setShopElementRef(shop.id)}>
                      <Media query="(max-width: 1100px)">
                        {isTablet => (
                          <Shop
                            shop={shop}
                            onShopSelect={this.handleShopSelect(isTablet)}
                            isActive={shop.id === activeShopId}
                          />
                        )}
                      </Media>
                    </li>
                  </HideErrorBoundary>
                ))}
              </ul>
            </Fragment>
          )}
        </div>

        <div className="ShopsGrid-mapCol">
          <Media query="(max-width: 1100px)">
            {isTablet => (
              <div className="ShopsGrid-mapHolder">
                <div className="ShopsGrid-mapSquare">
                  <div className="ShopsGrid-mapSizer" ref={this.setMapElementRef}>
                    <ShopsMap
                      list={shopList}
                      activeId={activeShopId}
                      state={mapState}
                      isLoaded={isLoaded}
                      onShopSelect={this.handleShopSelect(isTablet)}
                      onApiAvaliable={onApiAvaliable}
                      responsive
                    />
                  </div>
                </div>
              </div>
            )}
          </Media>
        </div>
      </div>
    );
  }

  setShopListElementRef = element => {
    if (!element) return;
    this._shopListElement = element;
  };

  setShopElementRef = id => element => {
    if (!element) return;
    this._shopElements = { ...this._shopElements, [id]: element };
  };

  setMapElementRef = element => {
    if (!element) return;
    this._mapElement = element;
  };

  scrollToShopElementTop(id) {
    if (!this._shopElements[id]) {
      return;
    }

    const topShopElementPosition = getTopElementPosition(this._shopElements[id]);

    const maxScroll = getMaxScroll(this._shopListElement);

    this.pageY = topShopElementPosition > maxScroll ? maxScroll : topShopElementPosition;

    window.scrollTo({
      top: this.pageY,
      behavior: 'smooth'
    });
  }

  scrollToMapTop() {
    const topMapPosition = getTopElementPosition(this._mapElement);

    window.scrollTo({
      top: topMapPosition - 16,
      behavior: 'smooth'
    });
  }

  handleShopSelect = isTablet => shop => {
    const { onShopSelect } = this.props;

    if (isTablet) {
      this.scrollToMapTop();
    } else {
      this.scrollToShopElementTop(shop.id);
    }

    onShopSelect(shop);
  };
}

export default ShopsGrid;
