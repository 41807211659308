import React, { Component } from 'react';

import './icon-youtube-inverted.css';

class IconYoutubeInverted extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" className="IconYoutubeInverted">
        <path d="M32.8 8.6c-1.1-1.4-3.3-1.9-7.3-1.9H10.6c-4.1 0-6.3.7-7.4 2.1C2 10.2 2 12.3 2 15.3v5.5c0 5.6 1.4 8.5 8.6 8.5h14.8c3.6 0 5.5-.5 6.8-1.7s1.8-3.3 1.8-6.8v-5.5c0-3.1-.1-5.3-1.2-6.7zM22.6 18.8l-6.8 3.6c-.1.1-.3.1-.5.1s-.3 0-.6-.1c-.3-.2-.5-.6-.5-.9v-7c0-.3.2-.7.5-.9.3-.2.7-.2 1 0l6.8 3.6c.3.2.6.6.6.9.1.1-.2.5-.5.7z" />
      </svg>
    );
  }
}

export default IconYoutubeInverted;
