import React, { Component } from 'react';

import FieldsetChoice from 'modules/form/components/fieldset-choice/fieldset-choice';
import FieldsetChoiceItem from 'modules/form/components/fieldset-choice/fieldset-choice-item';
import Radio from 'modules/form/components/radio/radio';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import './product-filter.css';

class ProductFilter extends Component {
  render() {
    const { filter, opened, onShowToggle } = this.props;

    return (
      <div className="ProductFilter">
        <button className="ProductFilter-header" type="button" onClick={onShowToggle}>
          <span className="ProductFilter-name">{filter.title}</span>
          <span className="ProductFilter-indicator">{opened ? '−' : '+'}</span>
        </button>
        {opened && (
          <div className="ProductFilter-options">
            <FieldsetChoice>
              {filter.options.map(option => (
                <FieldsetChoiceItem key={option.id}>{this.renderValue(option)}</FieldsetChoiceItem>
              ))}
            </FieldsetChoice>
          </div>
        )}
      </div>
    );
  }

  renderValue({ title, number, slug, checked }) {
    const { filter, onChange } = this.props;

    const Selectable = filter.multiple ? Checkbox : Radio;
    const label = (
      <span>
        {title} {number && <span className="ProductFilter-amount">{number}</span>}
      </span>
    );

    return (
      <Selectable
        name={`CATEGORY_FILTER/${filter.name}`}
        value={slug}
        checked={checked}
        onChange={onChange}
        label={label}
      />
    );
  }
}

export default ProductFilter;
