import React from 'react';
import cn from 'classnames';

import Media from 'modules/core/components/media/media';

import './actions-gift.css';

export default function ActionsGigt({ gift, colors }) {
  const style = { backgroundColor: colors[0] };
  return (
    <div className={cn('ActionsGigt')}>
      <div className="ActionsGigt-imageHolder">
        <div className="ActionsGigt-image">
          <Media
            className="ActionsGigt-imageSRC"
            media={gift.media}
            alt=""
            stretch="horizontal"
            nocaption
          />
        </div>
      </div>
      <div className="ActionsGigt-title">
        <div className="ActionsGigt-titleBG" style={style} />
        {gift.title}
      </div>
    </div>
  );
}
