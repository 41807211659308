import React, { Component } from 'react';
import Page from 'modules/core/components/page/page';
import Container from 'modules/core/components/container/container';

import PaymentResult from '../components/payment-result/payment-result';

class PaymentSuccessPage extends Component {
  static path = '/payment-success';
  static link = () => '/payment-success';
  static exact = true;

  render() {
    return (
      <Page title="Оплата прошла успешно!">
        <Container>
          <div style={{ textAlign: 'center' }}>
            Нам понадобится некоторое время, чтобы укомплектовать заказ и убедиться в наличии всех
            товаров. Это займёт не больше 3-х часов. По готовности мы обязательно отправим тебе
            sms-уведомление.
          </div>
        </Container>
        <PaymentResult comment="Если возникнут вопросы, свяжись с сотрудниками call-центра по номеру: +7 (495)252-07-43" />
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default PaymentSuccessPage;
