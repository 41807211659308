import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProfile from '../ducks/profile';

import UserWelcome from '../components/user-welcome/user-welcome';

const mapStateToProps = createStructuredSelector({
  profile: fromProfile.getAll
  // bonusAccount
});

class UserWelcomeContainer extends Component {
  render() {
    const { profile, ...rest } = this.props;

    return <UserWelcome profile={profile} {...rest} />;
  }
}

export default connect(mapStateToProps)(UserWelcomeContainer);
