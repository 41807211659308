import React, { Component } from 'react';

import Link from 'modules/core/components/link/short-link';
import { IconReset } from 'modules/core/components/icons';

import FilterOptionContainer from '../../containers/filter-option';

import './applied-filters.css';

class AppliedProductFilters extends Component {
  render() {
    const { appliedFilters, onFilterRemove, onFiltersReset } = this.props;

    return (
      <div className="AppliedFilters">
        <div className="AppliedFilters-content">
          <ul className="AppliedFilters-list">
            {appliedFilters.map(slug => (
              <li key={slug} className="AppliedFilters-item">
                <FilterOptionContainer slug={slug} onRemove={onFilterRemove} />
              </li>
            ))}
          </ul>
          <div className="AppliedFilters-clear">
            <Link
              component="button"
              onClick={onFiltersReset}
              prependedIcon={<IconReset />}
              size="small"
              noUnderline
            >
              Сбросить фильтры
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default AppliedProductFilters;
