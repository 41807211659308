import React, { Component } from 'react';
import './list-description.css';
import PropTypes from 'prop-types';

// TODO: replace with refactored component from core
class ListDescriptions extends Component {
  static propTypes = {
    list: PropTypes.arrayOf([
      PropTypes.shape({
        /**
         * name - property of list
         */
        title: PropTypes.string,
        /**
         * value - value of list
         */
        value: PropTypes.string
      })
    ])
  };

  render() {
    const { list } = this.props;

    return (
      <div className="ListDescription">
        {list.map((item, index) => (
          <div key={index} className="ListDescription-item">
            <span className="ListDescription-name">{item.title}</span>
            <span className="ListDescription-value">{this.renderValue(item)}</span>
          </div>
        ))}
      </div>
    );
  }

  renderValue(property) {
    if (!property.options) {
      return null;
    }

    return property.options.map(option => option.title).join(', ');
  }
}

export default ListDescriptions;
