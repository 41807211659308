import React, { Component } from 'react';

import './icon-close-eye.css';

class IconCloseEye extends Component {
  render() {
    return (
      <svg className="IconCloseEye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M4.1 8.9c-.1-.3-.5-.4-.8-.3-.3.2-.4.5-.2.8l1-.5zm16.8.5c.1-.3 0-.6-.3-.8-.3-.1-.6 0-.8.3l1.1.5zm-8.3 5.3c0-.3-.3-.6-.6-.6s-.6.3-.6.6h1.2zm-1.2 3.6c0 .3.3.6.6.6s.6-.3.6-.6h-1.2zm5.2-5.1c-.2-.2-.6-.3-.8-.1-.2.2-.3.6-.1.8l.9-.7zm1.3 3.3c.2.2.6.3.8.1.2-.2.3-.6.1-.8l-.9.7zm-9.8-2.4c.2-.2.2-.6-.1-.8-.2-.2-.6-.2-.8.1l.9.7zm-2.9 1.6c-.2.2-.2.6.1.8.2.2.6.2.8-.1l-.9-.7zm6.8-1.8c-3.5 0-6.5-2-7.9-5l-1 .5c1.6 3.4 5 5.7 8.9 5.7v-1.2zm7.9-5c-1.4 3-4.4 5-7.9 5v1.2c4 0 7.4-2.3 8.9-5.7l-1-.5zm-8.5 5.8v3.6h1.2v-3.6h-1.2zm4.3-.8l2.2 2.6.9-.8-2.2-2.6-.9.8zm-8.5-.6l-2 2.4.9.8 2-2.4-.9-.8z" />
      </svg>
    );
  }
}

export default IconCloseEye;
