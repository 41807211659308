import { combineReducers } from 'redux';
import { createReducer, createAction, handle } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import * as PAYMENT_METHODS from './types/payment-methods';

const paymentRequired = [8, 11];

export const actions = {
  request(deliveryMethodId) {
    return createAction(PAYMENT_METHODS.REQUEST, { deliveryMethodId });
  },

  response(deliveryMethodId, city, table, idList) {
    return createAction(PAYMENT_METHODS.RESPONSE, {
      deliveryMethodId,
      city,
      table,
      idList
    });
  },

  error(deliveryMethodId, city, message) {
    return createAction(PAYMENT_METHODS.ERROR, {
      deliveryMethodId,
      city,
      message
    });
  }
};

const all = createReducer(
  handle(PAYMENT_METHODS.RESPONSE, (state, { table }) => {
    if (!table) {
      return state;
    }

    return {
      ...state,
      ...table
    };
  })
);

const list = createReducer();

const byDelivery = createReducer(
  handle(PAYMENT_METHODS.REQUEST, (state, { deliveryMethodId }) => {
    if (!deliveryMethodId) {
      return state;
    }

    return {
      ...state,
      [deliveryMethodId]: {
        _isLoading: true
      }
    };
  }),

  handle(PAYMENT_METHODS.RESPONSE, (state, { deliveryMethodId, idList }) => {
    if (!deliveryMethodId || !idList) {
      return state;
    }

    return {
      ...state,
      [deliveryMethodId]: {
        _isLoading: false,
        list: idList
      }
    };
  }),

  handle(PAYMENT_METHODS.ERROR, (state, { deliveryMethodId }) => {
    if (!deliveryMethodId) {
      return state;
    }

    return {
      ...state,
      [deliveryMethodId]: {
        _isLoading: false,
        error: true
      }
    };
  })
);

export default combineReducers({
  all: all({
    1: {
      id: 1,
      name: 'PAYMENT_METHOD/CASH',
      title: 'Наличные курьеру',
      commission: 0
    },
    7: {
      id: 7,
      name: 'PAYMENT_METHOD/SBERBANK_NEW',
      title: 'Банковская квитанция',
      commission: 0
    },
    8: {
      id: 8,
      name: 'PAYMENT_METHOD/ROBOXCHANGE',
      title: 'Электронные деньги',
      commission: 0,
      requirePayment: true
    },
    9: {
      id: 9,
      name: 'PAYMENT_METHOD/CASH',
      title: 'Наличные в магазине',
      commission: 0
    },
    11: {
      id: 11,
      name: 'PAYMENT_METHOD/SBERBANK_API',
      title: 'Оплата картой',
      commission: 0,
      requirePayment: true
    }
  }),
  list: list([1, 7, 8, 9, 11]),

  byDelivery: byDelivery({})
});

export const getRoot = state => state.ordering.paymentMethods;
export const getAll = createSelector(
  getRoot,
  root => root.all
);
export const getByDeliveryTable = createSelector(
  getRoot,
  root => root.byDelivery
);

export const getList = createSelector(
  getAll,
  root => root.list.map(id => root.all[id])
);

export const getFindRootByDeliveryMethod = createSelector(
  getByDeliveryTable,
  function(byDelivery) {
    return deliveryMethodId => {
      if (!byDelivery || !deliveryMethodId) {
        return null;
      }

      return byDelivery[deliveryMethodId];
    };
  }
);

export const getFindListByDeliveryMethod = createSelector(
  getFindRootByDeliveryMethod,
  getAll,
  function(findRootByDelivery, all) {
    return deliveryMethodId => {
      const rootByDelivery = findRootByDelivery(deliveryMethodId);

      if (!rootByDelivery || rootByDelivery.error || rootByDelivery._isLoading) {
        return null;
      }

      return rootByDelivery.list
        .map(id => all[id] || null)
        .filter(Boolean)
        .map(withPaymentRequired);
    };
  }
);

export const getFindIsLoading = createSelector(
  getFindRootByDeliveryMethod,
  function(findRootByDelivery) {
    return deliveryMethodId => (findRootByDelivery(deliveryMethodId) || {})._isLoading || false;
  }
);

export const getFindItemById = createSelector(
  getAll,
  all => {
    return function findItemById(id) {
      if (!id || !all) {
        return null;
      }
      const item = all[id];

      if (!item) {
        return null;
      }

      return withPaymentRequired(item);
    };
  }
);

function withPaymentRequired(item) {
  return {
    ...item,
    requirePayment: paymentRequired.indexOf(item.id) !== -1
  };
}
