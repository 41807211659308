const CLOSE_CITY_DIALOG = 'core/location/CLOSE_CITY_DIALOG';
const OPEN_CITY_DIALOG = 'core/location/OPEN_CITY_DIALOG';
const SELECT_CITY = 'core/location/SELECT_CITY';
const CLOSE_CITY_DROPDOWN = 'core/location/CLOSE_CITY_DROPDOWN';
const OPEN_CITY_DROPDOWN = 'core/location/OPEN_CITY_DROPDOWN';
const RESET = 'core/location/RESET';

export default {
  SELECT_CITY,
  CLOSE_CITY_DIALOG,
  OPEN_CITY_DIALOG,
  CLOSE_CITY_DROPDOWN,
  OPEN_CITY_DROPDOWN,
  RESET
};
