import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { Page, PageContent } from '../components/page';
import { getIndexLink } from 'routes/links';
import ComplainFormContainer from 'modules/core/containers/complain-form-container';

const title = 'Форма отзывов и предложений';

class ComplaintFormPage extends Component {
  static path = '/feedback';
  static link = () => 'feedback';
  static exact = true;

  render() {
    return (
      <Page
        title={title}
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: title
          }
        ]}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
        </Helmet>
        <PageContent>
          <ComplainFormContainer description="Помоги нам стать лучше! Сообщи о проблеме" />
        </PageContent>
      </Page>
    );
  }
}

export default ComplaintFormPage;
