import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-track.css';

class IconTrack extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 31 24" className="IconTrack">
            <path d="M26.2 18.4H25.1C24.8 18.4 24.6 18.2 24.6 17.9C24.6 17.6 24.8 17.4 25.1 17.4H26.2C26.5 17.4 26.8 17.1 26.8 16.8V13.3L23.4 9C23.3 8.8 23.1 8.7 22.9 8.7H18.7C18.4 8.7 18.2 8.5 18.2 8.2C18.2 7.9 18.4 7.7 18.7 7.7H22.9C23.4 7.7 23.9 8 24.2 8.4L27.6 12.8C27.7 12.9 27.7 13 27.7 13.1V16.7C27.7 17.7 27 18.4 26.2 18.4Z" />
            <path d="M18.7 18.4H11.4C11.1 18.4 10.9 18.2 10.9 17.9C10.9 17.6 11.1 17.4 11.4 17.4H18.2V4.5H4.29998V17.4H6.8281C7.12811 17.4 7.3281 17.6 7.3281 17.9C7.3281 18.2 7.12811 18.4 6.8281 18.4H3.79998C3.49998 18.4 3.29998 18.2 3.29998 17.9V4C3.29998 3.7 3.49998 3.5 3.79998 3.5H18.7C19 3.5 19.2 3.7 19.2 4V17.9C19.2 18.1 19 18.4 18.7 18.4Z" />
            <path d="M23 20.5C21.5 20.5 20.4 19.3 20.4 17.9C20.4 16.5 21.6 15.3 23 15.3C24.4 15.3 25.6 16.5 25.6 17.9C25.6 19.3 24.4 20.5 23 20.5ZM23 16.2C22.1 16.2 21.4 16.9 21.4 17.8C21.4 18.7 22.1 19.4 23 19.4C23.9 19.4 24.6 18.7 24.6 17.8C24.6 17 23.9 16.2 23 16.2Z" />
            <path d="M9.09998 20.375C7.59998 20.375 6.49998 19.175 6.49998 17.775C6.49998 16.375 7.69998 15.175 9.09998 15.175C10.6 15.175 11.7 16.375 11.7 17.775C11.7 19.175 10.6 20.375 9.09998 20.375ZM9.09998 16.075C8.19998 16.075 7.49998 16.775 7.49998 17.675C7.49998 18.575 8.19998 19.275 9.09998 19.275C9.99998 19.275 10.7 18.575 10.7 17.675C10.8 16.875 9.99998 16.075 9.09998 16.075Z" />
            <path d="M21.3 17.4H18.2V18.4H21.3V17.4Z" />
          </Svg>
        );
      default:
    }

    // return (
    //   <svg
    //     className="IconTrack"
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 32 32"
    //   >
    //     <path d="M21.5 13.1h4.2c.3 0 .7.2.9.5l1.8 3.2h.5c.6 0 1.1.5 1.1 1v3.7c0 .6-.5 1.1-1 1.1h-1.1m-14.8 0h8.4V8.9H3.1v13.7h5.8" />
    //     <ellipse cx="25.7" cy="22.6" rx="2.1" ry="2.1" />
    //     <ellipse cx="11" cy="22.6" rx="2.1" ry="2.1" />
    //     <path d="M21.5 22.6h2.1" />
    //   </svg>
    // );
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconTrack;
