import React from 'react';

import OpeningAction from '../opening-action/opening-action';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
// import Container from 'modules/core/components/container/container';

import './opening-actions.css';

export default function OpeningActions({ data, colors, light }) {
  return (
    <div className="OpeningActions">
      <div className="OpeningActions-title">
        <LandingSectionTitle
          data={data.title}
          top={data.title[0]}
          bottom={data.title[1]}
          light={light}
        />
      </div>
      <div className="OpeningActions-list">
        {data.list.map((action, index) => (
          <div key={index} className="OpeningActions-item">
            <OpeningAction
              action={action}
              inverted={index % 2 !== 0}
              colors={colors}
              light={light}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
