import React, { Component } from 'react';

import CountedList from '../counted-list/counted-list';
import giftSertificatesRules from '../../seeds/gift-sertificates-rules';
import Container from 'modules/core/components/container/container';

import './gift-sertifikat-section.css';

class GiftSertifikatRulesSection extends Component {
  render() {
    return (
      <Container>
        <div className="GiftSertifikatRulesSection">
          <div className="GiftSertifikatRulesSection-description">
            Учитывая важность нижеизложенного, лицу, заинтересованному в приобретении/использовании
            Подарочных сертификатов на условиях настоящих Правил, настоятельно рекомендуется
            внимательно ознакомиться с текстом Правил, и в случае несогласия с каким-либо пунктом,
            предлагается отказаться от получения Подарочного сертификата и его использования.
          </div>

          <CountedList list={giftSertificatesRules} />
        </div>
      </Container>
    );
  }
}

export default GiftSertifikatRulesSection;
