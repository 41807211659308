import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

class OrderTableRow extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   };

  render() {
    const { children, ...rest } = this.props;
    return (
      <Link className="OrderTable-row" {...rest}>
        {children}
      </Link>
    );
  }
}

export default OrderTableRow;
