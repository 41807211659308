import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import App from './app';
import ToastStackContainer from 'modules/core/containers/toast-stack';
import Helmet from 'react-helmet';
import { ProductAnalyticsManager } from 'modules/product/containers/product-analytics';

const AppMedia = ({ render }) => {
  return (
    <Fragment>
      <Media
        query="(min-width: 1101px)"
        defaultMatches={false}
        render={() => render({ isDesktop: true })}
      />
      <Media
        query="(min-width: 841px) and (max-width: 1100px)"
        defaultMatches={false}
        render={() => render({ isTablet: true })}
      />
      <Media
        query="(max-width: 840px)"
        defaultMatches={__SERVER__}
        render={() => render({ isMobile: true })}
      />
    </Fragment>
  );
};

class AppWrapper extends Component {
  render() {
    return (
      <ProductAnalyticsManager>
        <ToastStackContainer alignment="right" verticalAlignment="bottom">
          <Helmet>
            <title>MIXIT — интернет-магазин уходовой и декоративной косметики</title>
          </Helmet>
          <AppMedia
            render={({ isMobile, isTablet }) => <App isMobile={isMobile} isTablet={isTablet} />}
          />
        </ToastStackContainer>
      </ProductAnalyticsManager>
    );
  }
}

export default AppWrapper;
