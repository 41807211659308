import React, { Component } from 'react';
import Container from 'modules/core/components/container/container';
import BasketContainer from '../containers/basket';
import Page from 'modules/core/components/page/page';
import NoSsr from 'modules/core/components/no-ssr/no-ssr';
import EmptyBasket from '../components/basket/empty-basket';
import SectionDefault from 'modules/core/components/section-default/section-default';
import ProductsNewContainer from 'modules/product/containers/products-new';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromBasket from '../ducks/basket';
import { getIndexLink } from 'routes/links';
import plural from 'modules/utils/plural';
import Helmet from 'react-helmet';
// import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import Loader from 'modules/core/components/loader/loader';
import FreeDeliveryNotification from 'modules/core/containers/free-delivery-notification';

const mapStateToProps = createStructuredSelector({
  productsAmount: fromBasket.getProductsAmount,
  isOuterBasketLoading: fromBasket.getIsOuterBasketLoading,
  isEmpty: fromBasket.getIsEmpty
});

class BasketPage extends Component {
  render() {
    const { productsAmount, isEmpty, isOuterBasketLoading } = this.props;

    return (
      <Page
        title={
          productsAmount > 0
            ? `${productsAmount} ${plural(productsAmount, [
                'товар',
                'товара',
                'товаров'
              ])} в корзине`
            : isEmpty && !isOuterBasketLoading
            ? 'Упс! Корзина пуста :('
            : 'Корзина'
        }
        notification={<FreeDeliveryNotification />}
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Корзина'
          }
        ]}
        withBackButton
        withoutSaleNotification
      >
        <Helmet>
          <title>
            {`Корзина (${productsAmount || 0})`}
          </title>

          <meta
            name="description"
            content={`Корзина (${productsAmount || 0}) -
            интернет-магазин MIXIT`}
          />
        </Helmet>

        <NoSsr>
          <Container>{isEmpty ? this.getEmptyBasket() : <BasketContainer />}</Container>

          {!productsAmount && (
            <SectionDefault>
              <Container>
                <ProductsNewContainer />
              </Container>
            </SectionDefault>
          )}
          {/* <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault> */}
        </NoSsr>
      </Page>
    );
  }

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 20);
  }

  getEmptyBasket() {
    const { isOuterBasketLoading } = this.props;

    return isOuterBasketLoading ? (
      <div style={{ position: 'relative', paddingTop: '120px' }}>
        <Loader />
      </div>
    ) : (
      <EmptyBasket />
    );
  }
}

export default connect(mapStateToProps)(BasketPage);
