import React, { Component } from 'react';
import './icon-metro.css';

class IconMetro extends Component {
  render() {
    const { color, stroke } = this.props;
    return (
      <svg
        className="IconMetro"
        style={{ color, stroke }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -19.585 131.69 131.69"
      >
        <path d="M131.69 92.52v-7.09h-8.27L89.02 0 65.85 48.33 42.74 0 8.28 85.43H0v7.09h47.41v-7.09h-9.4l10.98-27.1 16.86 34.19 16.93-34.19 10.91 27.1h-9.41v7.09h47.41z" />
      </svg>
    );
  }
}

export default IconMetro;
