import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromNavigations from 'modules/core/ducks/navigations';
import Loader from 'modules/core/components/loader/loader';

import FooterMobileNavigationLink from './footer-mobile-navigation-link';

import './footer-mobile-navigation.css';

const mapStateToProps = createStructuredSelector({
  categories: fromNavigations.getFooterMobile,
});

class FooterMobileNavigation extends Component {
  render() {
    const {
      categories
    } = this.props;
  
    if(!categories || !categories.list ) {
      return (
        <div style={{ position: 'relative', paddingTop: '100px' }}>
        <Loader size="small" secondary />
      </div>
      )
    }

    return (
      <ul className="FooterMobileNavigation">
        {categories.list.map(link => (
          <li key={link.id} className="FooterMobileNavigation-item">
            <FooterMobileNavigationLink title={link.title} to={link.linkProps.to} href={link.linkProps.href} />
          </li>
        ))}
      </ul>
    );
  }
}

export default connect(mapStateToProps)(FooterMobileNavigation);
