import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/utm-banner';
import { createSelector } from '../../utils/debug-reselect';

/* REDUCERS */

const OUTPUT_METHODS = {
  BANNER: 'banner',
  DIALOG: 'pop_up'
};

const reducer = createReducer(
  handle(TYPES.REHYDRATE, (state, { utmList }) => {
    return {
      ...state,
      utmList
    };
  }),

  handle(TYPES.NOTE_UTM, (state, { utm }) => {
    return {
      ...state,
      utmList: [...state.utmList, utm]
    };
  }),

  handle(TYPES.OPEN_BANNER, state => {
    return {
      ...state,
      isOpen: true
    };
  }),

  handle(TYPES.CLOSE_BANNER, state => {
    return {
      ...state,
      isOpen: false
    };
  }),

  handle(TYPES.RESPONSE, (state, { data }) => {
    return {
      ...state,
      data
    };
  }),

  handle(TYPES.RESET_DATA, state => {
    return {
      ...state,
      data: null
    };
  })
);

export default reducer({
  error: null,
  isOpen: false,
  data: [],
  utmList: []
});

/* SELECTORS */

const getRoot = state => state.core.utmBanner;

export const getAll = getRoot;
export const getError = createSelector(
  getRoot,
  root => root.error
);
export const getIsOpen = createSelector(
  getRoot,
  root => root.isOpen
);
export const getData = createSelector(
  getRoot,
  root => root.data
);

export const getBannerData = createSelector(
  getData,
  dataList => {
    if (!dataList || dataList.length === 0) {
      return null;
    }
    const _dataList = dataList.filter(_data => _data.type.toLowerCase() === OUTPUT_METHODS.BANNER);
    if (_dataList.length === 0) {
      return null;
    }
    return _dataList[0];
  }
);

export const getDialogData = createSelector(
  getData,
  dataList => {
    if (!dataList || dataList.length === 0) {
      return null;
    }
    const _dataList = dataList.filter(_data => _data.type.toLowerCase() === OUTPUT_METHODS.DIALOG);
    if (_dataList.length === 0) {
      return null;
    }
    return _dataList[0];
  }
);

export const getUtmList = createSelector(
  getRoot,
  root => root.utmList
);
