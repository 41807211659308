import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import ButtonPlay from './button-play';
import getTypedSource from './helpers/get-typed-source';
import isFunction from 'modules/utils/is-function';

const sourceOrder = [
  {
    extension: 'mp4',
    videoType: 'video/mp4'
  },
  {
    extension: 'MP4',
    videoType: 'video/mp4'
  },
  {
    extension: 'ogv',
    videoType: 'video/ogg'
  },
  {
    extension: 'OGV',
    videoType: 'video/ogg'
  },
  {
    extension: 'webm',
    videoType: 'video/webm'
  },
  {
    extension: 'WEBEM',
    videoType: 'video/webm'
  }
];

class Video extends Component {
  state = {
    isPlaying: false,
    isManualModeEnabled: false,
    wasStarted: false
  };

  render() {
    const { source, cover, alt, noControls, muted, setVideoState, darkened, ...rest } = this.props;
    const { isPlaying, wasStarted } = this.state;
    const typedSource = getTypedSource(source);

    const isButtonPlayAvailable = this.getIsButtonPlayVisible();

    return (
      <Fragment>
        <div
          className={cn('Media-video', {
            'Media-video--noInteractive': noControls,
            'Media-video--coverPoster': !wasStarted && darkened
          })}
        >
          <div className="Media-videoCover"></div>
          <video
            onClick={this.handleClick}
            onPlay={this.handlePlay}
            onPause={this.pauseVideo}
            ref={this.handleVideoRef}
            className="Media-videoWindow"
            poster={cover}
            alt={alt}
            controls={!noControls && isPlaying}
            muted={muted || noControls}
            loop={noControls}
            playsInline
            {...rest}
          >
            {sourceOrder.map(type => {
              if (!typedSource[type.extension]) {
                return null;
              }
              return this.renderSource(
                typedSource[type.extension].main,
                typedSource[type.extension].fallback,
                type.videoType
              );
            })}
          </video>
        </div>
        {isButtonPlayAvailable && <ButtonPlay onClick={this.playVideo} />}
      </Fragment>
    );
  }

  componentDidMount() {
    const { active } = this.props;

    if (active) {
      this.autoplayVideo();
      return;
    }
  }

  componentDidUpdate(prevProps) {
    this.toggleVideo(prevProps);
  }

  renderSource(main, fallback, videoType) {
    const { noControls, muted } = this.props;
    return (
      <Fragment>
        <source
          src={main.normal}
          type={videoType}
          playsInline
          loop={noControls}
          muted={muted || noControls}
        />
        {false && <source src={fallback.normal} type={videoType} />}
      </Fragment>
    );
  }

  autoplayVideo() {
    try {
      this.video.play();
    } catch {
      this.setManualMode();
    }
  }

  toggleVideo(prevVideoProps) {
    const { active } = this.props;

    const { isManualModeEnabled } = this.state;

    if (!isManualModeEnabled && active && !prevVideoProps.active) {
      this.video.play();
    }

    if (!active && prevVideoProps.active) {
      this.video.pause();
    }
  }

  setManualMode() {
    this.setState({ isManualModeEnabled: true });
  }

  getIsButtonPlayVisible() {
    const { noControls, noButtonPlay } = this.props;
    const { isPlaying, isManualModeEnabled } = this.state;

    return !isPlaying && !noButtonPlay && (!noControls || isManualModeEnabled);
  }

  handleVideoRef = element => {
    this.video = element;
  };

  handleClick = e => {
    const { noControls, noClickableVideo } = this.props;
    if (noControls || noClickableVideo) {
      // e.preventDefault();
      return;
    }

    this.togglePlay(e);
  };

  handlePlay = () => {
    const { setVideoState } = this.props;

    this.setState({
      isPlaying: true
    });
    if (isFunction(setVideoState)) {
      setVideoState(true);
    }
  };

  togglePlay(e) {
    if (this.state.isPlaying) {
      this.pauseVideo(e);
    } else {
      this.playVideo();
    }
  }

  playVideo = () => {
    const { setVideoState } = this.props;
    this.setState({
      isPlaying: true,
      wasStarted: true
    });
    this.video.play();
    if (isFunction(setVideoState)) {
      setVideoState(true);
    }
  };

  pauseVideo = e => {
    const { noControls, setVideoState } = this.props;
    if (noControls) {
      e.preventDefault();
      return;
    }
    this.setState({
      isPlaying: false
    });
    this.video.pause();

    if (isFunction(setVideoState)) {
      setVideoState(false);
    }
  };
}

export default Video;
