import React, { Component } from 'react';
import classNames from 'classnames';

import Media from 'modules/core/components/media/media';
import { MEDIA_TYPE } from 'modules/core/components/media/constants';

import VideoMiniature from './video-miniature';

import './pagination-button.css';

class PaginationButton extends Component {
  render() {
    const { media, isActive, ...rest } = this.props;
    return (
      <button
        className={classNames('GalleryPaginationButton', {
          'GalleryPaginationButton--active': isActive
        })}
        type="button"
        {...rest}
      >
        {media.type === MEDIA_TYPE.VIDEO ? (
          <VideoMiniature media={media} />
        ) : (
          <Media media={media} stretch="horizontal" resizeWidth={80} nocaption />
        )}
        {/* {media.type === 'MEDIA_TYPE.VIDEO' && (
          <span className="GalleryPaginationButton-play" role="presentation" />
        )} */}
      </button>
    );
  }
}

export default PaginationButton;
