import { createReducer } from 'modules/utils/dux';

import { createSelector } from '../../utils/debug-reselect';

import seeds from '../seeds/email-feedback-variants';

const reducer = createReducer();

export default reducer({ variants: seeds });

/* SELECTORS */

const getAll = state => state.core.emailFeedback;
const getVariantString = (_, { variant }) => variant;

export const getVariantList = createSelector(
  getAll,
  function _getVariantList(all) {
    return all.variants;
  }
);

export const getVariantStringList = createSelector(
  getVariantList,
  function _getVariantStringList(variantList) {
    return Object.keys(variantList);
  }
);

export const getVariant = createSelector(
  getVariantList,
  getVariantString,
  function _getVariant(variantList, variantString) {
    return variantList[variantString];
  }
);
