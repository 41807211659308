import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Media from 'react-media';
import { findAll } from 'highlight-words-core';

import Availability from 'modules/core/components/availability/availability';

import pickPointShape from '../../prop-types/pick-point-shape';

import { weekdaysToString } from 'modules/utils';
import timeIntervalToString from '../../formatters/time-interval';

// import formatStreet from '../delivery-summary/helpers/format-street';

import './pick-point.css';

const { bool, func } = PropTypes;

class PickPoint extends Component {
  static propTypes = {
    /** Данные постамата/пункта выдачи PickPoint */
    pickPoint: pickPointShape.isRequired,

    /** Флаг выбранного постамата/пункта выдачи PickPoint */
    selected: bool.isRequired,

    /** Обработчик функции выбора постамата/пункта выдачи PickPoint */
    onSelect: func.isRequired
  };

  render() {
    const { pickPoint, selected } = this.props;

    if (!pickPoint) {
      return null;
    }

    return (
      <button
        className={cn('PickPoint', {
          'PickPoint--selected': selected
        })}
        onClick={this.handlePickPointSelect}
      >
        {/* <Media query="(min-width: 600px) and (max-width: 840px)"> */}
        <Media query="(max-width: 840px)">
          {isMobile => (
            <Fragment>
              <div className="PickPoint-titleCol">
                {this.renderTitle()}
                {isMobile && this.renderAddress()}
              </div>

              {!isMobile && <div className="PickPoint-addressCol">{this.renderAddress()}</div>}

              <div className="PickPoint-scheduleCol">
                {/* {isMobile && this.renderPayment(!isMobile)} */}
                {this.renderSchedule()}
              </div>

              {/* {!isMobile &&
                (!(pickPointType === 'PICK_POINT_TYPE/PPD') && (
                  <div className="PickPoint-paymentCol">{this.renderPayment()}</div>
                ))} */}
            </Fragment>
          )}
        </Media>
      </button>
    );
  }

  renderTitle() {
    const { pickPoint, filterMask } = this.props;
    return (
      <span className="PickPoint-title">
        {this.highlight(pickPoint.title, filterMask)}
        {/* {pickPoint.title} */}
      </span>
    );
  }

  renderAddress() {
    const { pickPoint, filterMask } = this.props;

    return (
      <span className="PickPoint-address">
        {/* {this.highlight(formatStreet(pickPoint.geography.address.raw), filterMask)} */}
        {this.highlight(pickPoint.geography.address.raw, filterMask)}
        {/* {pickPoint.geography.address.raw} */}
      </span>
    );
  }

  highlight(text, query) {
    const chunks = findAll({
      textToHighlight: text,
      searchWords: query.split(/\s/)
    });

    return chunks.map(({ start, end, highlight }) => {
      const chunk = text.substr(start, end - start);
      return highlight ? <mark>{chunk}</mark> : chunk;
    });
  }

  renderSchedule() {
    const { pickPoint } = this.props;

    if (!pickPoint.schedule.length) {
      return <span className="PickPoint-scheduleItem">{pickPoint.schedule_raw}</span>;
    }
    return (
      <span className="PickPoint-scheduleList">
        {pickPoint.schedule.map(this.renderScheduleItem)}
      </span>
    );
  }

  renderPayment(isShort = true) {
    const { pickPoint, pickPointType } = this.props;
    if (pickPointType === 'PICK_POINT_TYPE/PPD') {
      return null;
    }

    return (
      <span className="PickPoint-payment">
        {!isShort && 'Оплата картой: '}
        <span className="PickPoint-paymentAvailability">
          <Availability
            is={pickPoint.payment && pickPoint.payment.hasTerminal}
            title="Есть"
            falsyTitle="Нет"
          />
        </span>
      </span>
    );
  }

  renderScheduleItem = (item, index) => {
    const { pickPoint } = this.props;
    try {
      const weekdays = weekdaysToString(item.days);
      const timeIntervals = timeIntervalToString(item.time);

      if (!item.days.length && !item.time) {
        return <span className="PickPoint-scheduleItem">{pickPoint.schedule_raw}</span>;
      }

      return (
        <span key={index} className="PickPoint-scheduleItem">
          {weekdays} {timeIntervals}
        </span>
      );
    } catch (e) {
      return (
        <span key={index} className="PickPoint-scheduleItem">
          -
        </span>
      );
    }
  };

  handlePickPointSelect = () => {
    const { pickPoint, selected, onSelect } = this.props;

    if (selected) {
      return;
    }

    onSelect(pickPoint);
  };
}

export default PickPoint;
