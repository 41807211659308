import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const { element, oneOfType, arrayOf, string, node } = PropTypes;

export class DashboardCell extends Component {
  static propTypes = {
    /** Содержимое ячейки сетки */
    children: oneOfType([element, arrayOf(element), node, string]),
    /** Класс(ы), которые приходят DashboardStrictCell */
    className: string
  };

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div
        className={cn('DashboardGrid-cell', {
          [className]: className
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

export default DashboardCell;
