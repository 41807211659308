export function createReducer(...handlers) {
  return function createReducerWithInitialState(initialState = null) {
    const handlersObject = Object.assign({}, ...handlers);

    if (!Object.keys(handlersObject).length) {
      return function reducer(state = initialState) {
        return state;
      };
    }

    return function reducer(state = initialState, action) {
      if (handlersObject.hasOwnProperty(action.type)) {
        return handlersObject[action.type](state, action.payload, action.error);
      } else {
        return state;
      }
    };
  };
}

export function handle(actionType, actionHandler) {
  return {
    [actionType]: actionHandler
  };
}

export function createAction(type, payload = null, meta = null) {
  if (!type) {
    throw new Error('type is required');
  }
  return { type, payload, meta };
}

export function action(type, payloadCreator, metaCreator) {
  const actionCreator = function(...parameters) {
    return {
      type,
      payload: payloadCreator(...parameters),
      meta: metaCreator(...parameters)
    };
  };

  Object.defineProperty(actionCreator, 'name', {
    value: `actionCreator_${type}`,
    writable: false
  });

  return actionCreator;
}

export function createActionError(type, payload = null, meta = null) {
  if (!type) {
    throw new Error('type is required');
  }
  return { type, payload, meta, error: true };
}

/*

function createAction(ACTION_TYPE, createPayload, createMeta) {
  const getAction = function(payload, meta, error) {
    return {
      payload,
      meta,
      error
    };
  };

  const getActionCreator = function(error = false) {
    return function(...props) {
      try {
        const payload = createPayload(...props);
        const meta = createMeta(...props);

        return getAction(payload, meta, error);
      } catch (error) {
        return getAction(null, null, true);
      }
    };
  };

  const actionCreator = getActionCreator(false);

  Object.defineProperty(actionCreator, 'name', {
    value: `create_${ACTION_TYPE}`
  });

  actionCreator.prototype.withError = getActionCreator(true);

  Object.defineProperty(actionCreator.prototype.asError, 'name', {
    value: `create_${ACTION_TYPE}_withError`
  });

  return getActionCreator(false);
}

*/
