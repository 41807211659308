import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import Dialog from 'modules/core/components/dialog/dialog';
import InputCode from 'modules/core/components/input-code/input-code';
import Link from 'modules/core/components/link/short-link';
import Loader from 'modules/core/components/loader/loader';

import Countdown from 'modules/core/components/contdown/contdown';

import './bonuses-confirmation-dialog.css';

const PREVENT_REQUEST_CODE_DURATION = 60000;

class BonusesConformationDialog extends Component {
  state = {
    requestSendingDate: null
  };

  _inputElement = null;
  render() {
    const { requestSendingDate } = this.state;
    const { onChange, bonuses, onRequestCode, ...rest } = this.props;

    return (
      <Dialog closeable {...rest}>
        <div
          className={cn('BonusesConformationDialog', {
            'BonusesConformationDialog--invalidCode': bonuses.conformationOptions.isCodeInvalid
          })}
        >
          <div className="BonusesConformationDialog-title">{this.getTitle()}</div>
          <div className="BonusesConformationDialog-info">{this.getInfo()}</div>
          <div className="BonusesConformationDialog-input">
            <InputCode
              onChange={this.handleChange}
              inputRef={this.setInputRef}
              disabled={!bonuses.conformationOptions.phone}
            />
          </div>
          <div className="BonusesConformationDialog-repeatConfirm">
            {requestSendingDate ? (
              <Fragment>
                Повторно можно будет отправить через{' '}
                <span className="BonusesConformationDialog-countDown">
                  <Countdown finishTime={requestSendingDate} />
                </span>{' '}
                сек.
              </Fragment>
            ) : (
              <Link component="button" type="button" onClick={this.handleRequestCode}>
                Повторно отправить код
              </Link>
            )}
          </div>
        </div>
      </Dialog>
    );
  }

  getInfo() {
    const { phone } = this.props.bonuses.conformationOptions;
    if (phone) {
      return (
        <Fragment>
          На номер телефона <span className="BonusesConformationDialog-phone">{phone}</span>{' '}
          отправлен код подтверждения
        </Fragment>
      );
    }

    return (
      <Fragment>
        Отправка кода подтверждения на Ваш номер телефона
        <span className="BonusesConformationDialog-loader">
          <Loader type="goo" size="small" />
        </span>
      </Fragment>
    );
  }

  getTitle() {
    const { bonuses } = this.props;
    const { isCodeInvalid } = bonuses.conformationOptions;

    if (isCodeInvalid) {
      return 'Неверный код! Повторте ввод.';
    }

    return 'Подтвердите списание бонусов';
  }

  setInputRef = element => {
    if (!element) {
      return;
    }
    this._inputElement = element;
    this._inputElement.focus();
    // this.forceUpdate();
  };

  setRequestSendingDate(finishTime) {
    this.setState({ requestSendingDate: finishTime }, () =>
      setTimeout(this.unsetRequestSendingDate, PREVENT_REQUEST_CODE_DURATION)
    );
  }

  unsetRequestSendingDate = () => {
    this.setState({ requestSendingDate: null });
  };

  handleRequestCode = () => {
    const { onRequestCode } = this.props;
    const curentTime = Date.now();
    const finishTime = curentTime + PREVENT_REQUEST_CODE_DURATION;

    this.setRequestSendingDate(finishTime);
    onRequestCode();
  };

  handleChange = code => {
    const { onChange } = this.props;

    // this.setRequestSending();
    onChange(code);
  };
}

export default BonusesConformationDialog;
