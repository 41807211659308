import React, { Component } from 'react';
import cn from 'classnames';

import './info-sign.css';

class InfoSign extends Component {
  render() {
    const { variant, size, ...rest } = this.props;
    return (
      <span
        className={cn('InfoSign', {
          'InfoSign--light': variant === 'light',
          'InfoSign--size-large': size === 'large'
        })}
        {...rest}
      >
        ?
      </span>
    );
  }
}

export default InfoSign;
