export function deserializeVariantOption(variantOption) {
  if (!variantOption) {
    return null;
  }

  const { id, name, variant_title, title, value, payload } = variantOption;

  return {
    id,
    name,
    title,
    value,
    variantTitle: variant_title,
    payload
  };
}
