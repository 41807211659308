import React, { Component } from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';

import RollUp from '../roll-up/roll-up';
import Button from '../button/button';

import { getIndexLink } from 'routes/links';

import './draw-roll-up.css';

class DrawRollUp extends Component {
  render() {
    const { onClose, opened } = this.props;

    return (
      <Media query="(max-width:840px)">
        {isMobile => (
          <RollUp
            show={opened}
            onClose={onClose}
            style={{
              backgroundImage: isMobile
                ? 'url(/content/draw-roll-up-bg/bg-mobile.png)'
                : 'url(/content/draw-roll-up-bg/bg.png)',
              backgroundPosition: isMobile ? 'left top' : 'right top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
            // onClose={onClose}
            content={() => (
              <div className="DrawRollUp">
                <div className="DrawRollUp-content">
                  <div class="DrawRollUp-contentInfo">
                    <h3 className="DrawRollUp-contentTitle">Розыгрыш призов от MIXIT</h3>

                    <div className="DrawRollUp-contentCaptionCoverWrapper">
                      <div className="DrawRollUp-contentCaptionWrapper">
                        <div className="DrawRollUp-contentCaptionBlock">Выиграть может каждый</div>

                        <div className="DrawRollUp-contentCaptionBlockCover" />
                      </div>
                    </div>
                  </div>

                  <div className="DrawRollUp-action">
                    <Button
                      title="К условиям акции"
                      variant="primary"
                      expanded={isMobile}
                      size={isMobile ? 'small' : 'normal'}
                      component={Link}
                      to={getIndexLink()}
                    />
                  </div>
                </div>
              </div>
            )}
          ></RollUp>
        )}
      </Media>
    );
  }
}

export default DrawRollUp;
