const TOAST_REHYDRATE = 'core/toasts/TOAST_REHYDRATE';
const COOKIE_TOAST_ACCEPT = 'core/toasts/COOKIE_TOAST_ACCEPT';
const COOKIE_TOAST_CLOSE = 'core/toasts/COOKIE_TOAST_CLOSE';

const PASSWORD_CHANGE_TOAST_ACCEPT = 'core/toasts/PASSWORD_CHANGE_TOAST_ACCEPT';
const PASSWORD_CHANGE_TOAST_CLOSE = 'core/toasts/PASSWORD_CHANGE_TOAST_CLOSE';

export default {
  TOAST_REHYDRATE,
  COOKIE_TOAST_ACCEPT,
  COOKIE_TOAST_CLOSE,
  PASSWORD_CHANGE_TOAST_ACCEPT,
  PASSWORD_CHANGE_TOAST_CLOSE
};
