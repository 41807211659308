import { combineReducers } from 'redux';
import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import SHOPS_LIST from './types/shop-list';

import * as fromLocation from 'modules/geography/ducks/location';

const IGNORED_COUNTRY_ID = 4;

// REDUCERS
const shops = createReducer(
  on(SHOPS_LIST.SHOPS_RESPONSE, (state, { shops }) => {
    return {
      ...state,
      ...listToTable(shops)
    };
  })
);

const shopsByCities = createReducer(
  on(SHOPS_LIST.SHOPS_RESPONSE, (state, { cityId, shops }) => {
    const _cityId = cityId || 'NO_CITY';
    return {
      ...state,
      [_cityId]: shops.map(shop => shop.id)
    };
  })
);

const statusByCities = createReducer(
  on(SHOPS_LIST.SHOPS_REQUEST, (state, { cityId }) => {
    const _cityId = cityId || 'NO_CITY';
    return {
      ...state,
      [_cityId]: false
    };
  }),

  on(SHOPS_LIST.SHOPS_RESPONSE, (state, { cityId }) => {
    const _cityId = cityId || 'NO_CITY';
    return {
      ...state,
      [_cityId]: true
    };
  })
);

const cities = createReducer(
  on(SHOPS_LIST.CITIES_REQUEST, state => {
    return {
      ...state,
      isLoading: true,
      error: null
    };
  }),

  on(SHOPS_LIST.CITIES_RESPONSE, (state, { cities }) => {
    return {
      ...state,
      all: { ...state.all, ...cities },
      isLoading: false
    };
  }),

  on(SHOPS_LIST.CITIES_ERROR, (state, { error }) => {
    return {
      ...state,
      error
    };
  })
);

export default combineReducers({
  cities: cities({ all: {}, isLoading: false, error: null }),
  shops: shops({}),
  shopsByCities: shopsByCities({}),
  _statuses: statusByCities({})
});

// SELECTORS
export const getAll = state => state.core.shopList;

export const getIgnoredCountryId = () => IGNORED_COUNTRY_ID;

const getCityCollection = createSelector(
  getAll,
  function _getCityCollection(all) {
    return all.cities;
  }
);

export const getCities = createSelector(
  getCityCollection,
  function _getCityCollection(cityCollection) {
    return cityCollection.all;
  }
);

export const getCityList = createSelector(
  getCities,
  function _getCityList(cities) {
    return Object.values(cities);
  }
);

export const getIsCitiesLoading = createSelector(
  getCityCollection,
  function _getCityCollection(cityCollection) {
    return cityCollection.isLoading;
  }
);

export const getIsCitiesLoaded = createSelector(
  getCityCollection,
  getCities,
  function _getCityCollection(cityCollection, cities) {
    return !cityCollection.error && Object.values(cities).length !== 0;
  }
);

export const getShopsByCities = createSelector(
  getAll,
  function _getShopsByCities(all) {
    return all.shopsByCities;
  }
);

const getCurrentCityId = createSelector(
  fromLocation.getCurrentCityId,
  cityId => cityId || 'NO_CITY'
);

export const getShopIdList = createSelector(
  getShopsByCities,
  getCurrentCityId,
  function _getShopIdList(shopsByCities, currentCityId) {
    if (!shopsByCities || !currentCityId) {
      return null;
    }

    const shopIdList = shopsByCities[currentCityId];

    if (!shopIdList) {
      return null;
    }

    return shopIdList;
  }
);

export const getShops = createSelector(
  getAll,
  getShopIdList,
  function _getShopIdList(all, shopIdList) {
    const citiesIds = Object.keys(all.shops);

    if (!shopIdList && !citiesIds) {
      return null;
    }

    if (!shopIdList) {
      return citiesIds.map(id => all.shops[id]);
    }

    const shopList = shopIdList.map(id => all.shops[id]);

    return shopList;
  }
);

export const getStatusByCities = createSelector(
  getAll,
  shopList => shopList._statuses
);

export const getIsFullLoaded = createSelector(
  getStatusByCities,
  function _getIsFullLoaded(statusByCities) {
    return statusByCities['NO_CITY'];
  }
);

export const getIsLoaded = createSelector(
  getStatusByCities,
  getCurrentCityId,
  function _getIsLoaded(statusByCities, currentCityId) {
    if (!statusByCities || !currentCityId) {
      return false;
    }

    const isLoaded = statusByCities[currentCityId];

    if (!isLoaded) {
      return false;
    }

    return statusByCities[currentCityId];
  }
);

function listToTable(list) {
  let result = {};

  for (let item of list) {
    result[item.id] = item;
  }

  return result;
}


export const getCityByCurrentCityId = createSelector(getCities, fromLocation.getCurrentCity, function _getCityByCurrentCityId(cities, currentCity) {
  if (!cities || (!currentCity || !currentCity.id)) {
    return null;
  }

  const city = cities[currentCity.id];

  if (!city) {
    return null;
  }

  return city;
});

export const getIsBelarusOrKazahstan = createSelector(getCityByCurrentCityId, (currentCity)=>{
  const KAZAHSTAN_COUNTRY_ID = 10;
  const BELARUS_COUNTRY_ID = 2;

    if(!currentCity || !currentCity.country || !currentCity.country.id) {
      return false;
    }

    if(currentCity.country.id === KAZAHSTAN_COUNTRY_ID || currentCity.country.id === BELARUS_COUNTRY_ID) {
      return true;
    }

    return false;
} )