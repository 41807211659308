import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Hero from '../hero/hero';
import articleShape from './../../prop-types/article-shape';
import Container from 'modules/core/components/container/container';

class FeaturedArticlesSection extends Component {
  static propTypes = {
    articleList: PropTypes.arrayOf(articleShape)
  };

  render() {
    const { articleList, isLoading } = this.props;
    return (
      <section className="FeaturedArticlesSection">
        <Container>{!isLoading && <Hero articleList={articleList} />}</Container>
      </section>
    );
  }
}

export default FeaturedArticlesSection;
