import registerCoreSagas from 'modules/core/sagas';
import registerGeographySagas from 'modules/geography/sagas';
import registerProductSagas from 'modules/product/sagas';
import registerOrderingSagas from 'modules/ordering/sagas';
import registerIngredientsSagas from 'modules/ingredient/sagas';
import registerProfileSagas from 'modules/profile/sagas';
import registerBlogSagas from 'modules/blog/sagas';
import registerMassMediaSagas from 'modules/mass-media/sagas/mass-media-saga';
import registerHappyLandingSagas from 'modules/happy-master-landing/happy-master-lending-saga';

export default function register(middleware) {
  const allSagas = [
    registerCoreSagas(middleware),
    registerProductSagas(middleware),
    registerOrderingSagas(middleware),
    registerIngredientsSagas(middleware),
    registerProfileSagas(middleware),
    registerBlogSagas(middleware),
    registerGeographySagas(middleware),
    registerMassMediaSagas(middleware),
    registerHappyLandingSagas(middleware)
  ];

  return () => allSagas.reduce((all, moduleSagas) => all.concat(moduleSagas()), []);
}
