import React, { Component } from 'react';
import cn from 'classnames';
// import PropTypes from 'prop-types';
import './true-dotted-list.css';

export default class TrueDottedList extends Component {
  render() {
    const { list, highlightedTitle, markedList } = this.props;

    return (
      <div
        className={cn('TrueDottedList', {
          'TrueDottedList--highlightedTitle': highlightedTitle,
          'TrueDottedList--markedList': markedList
        })}
        x-ms-format-detection="none"
      >
        <ul className="TrueDottedList-coll">
          {list.map((item, index) => (
            <li key={index} className="TrueDottedList-item">
              <span className="TrueDottedList-title">{item.title}</span>
              {item.importantTitle && (
                <span className="TrueDottedList-importantTitle">{` ${item.importantTitle}`}</span>
              )}
            </li>
          ))}
        </ul>
        <ul className="TrueDottedList-coll">
          {list.map((item, index) => (
            <li key={index} className="TrueDottedList-item">
              <span className="TrueDottedList-value">
                {item.value}
                {!item.importantInfo && !item.value && ', '}
                {item.importantInfo && (
                  <span className="TrueDottedList-importantInfo"> {item.importantInfo}</span>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
