import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Media from 'modules/core/components/media/media';

import './subcategory-card.css';

const { string, shape } = PropTypes;

const mediaSeeds = {
  id: 570836,
  type: 'MEDIA_TYPE/IMAGE',
  title: 'Антисептический спрей для рук',
  sources: [
    {
      dimensions: {
        width: 3200,
        height: 3200
      },
      main: {
        normal: 'https://mixit.ru/upload/iblock/748/748891020c1b80637512db2cca5965d9.png'
      },
      fallback: {
        normal: 'https://mixit.ru/upload/iblock/748/748891020c1b80637512db2cca5965d9.png'
      }
    }
  ]
};

export default class SubcategoryCard extends Component {
  static propTypes = {
    /*  Ссылка для карточки*/
    href: string,
    /* текст заголовка */
    title: string,
    /*  Описание под заголовком  */
    description: string,
    /*  данные для картинки */
    media: shape({
      src: string,
      srcset: string
    })
  };

  render() {
    const { href, title, description, hiddenTitle, onClick, item } = this.props;
    const linkTitle = `${title} ${description ? ` - ${description}` : ''}`;
    const _mediaSeeds = {
      ...mediaSeeds,
      title,
      sources: [
        {
          dimensions: {
            width: 3200,
            height: 3200
          },
          main: {
            normal: item.cardProps.media.src
          },
          fallback: {
            normal: item.cardProps.media.srcset
          }
        }
      ]
    };

    return (
      <div
        className={cn('SubcategoryCard', {
          'SubcategoryCard--hiddenTitle': hiddenTitle
        })}
      >
        <div className="SubcategoryCard-imageHolder" style={item.cardProps.imageHolderStyle}>
        <div className="SubcategoryCard-imageHoverHolder" style={item.cardProps.imageHolderHoverStyle} role="presentation" aria-hidden="true"></div>
          {/* <img
            className="SubcategoryCard-image"
            src={media.src}
            srcSet={`${media.srcset} 2x`}
            alt={linkTitle}
          /> */}
          <Media
            className="SubcategoryCard-image"
            media={_mediaSeeds}
            stretch="horizontal"
            resizeWidth={480}
            // background="#fff"
            nocaption
            lazy
            // onLoad={this.handleImageLoad}
          />
        </div>
        {!hiddenTitle && (
          <h3 className="SubcategoryCard-title" style={item.cardProps.titleStyle}>
            <Link
              to={href}
              style={item.cardProps.titleStyle}
              title={linkTitle}
              onClick={onClick}
              className="SubcategoryCard-link"
            >
              {item.cardProps.showTitle && title}
            </Link>
          </h3>
        )}

        {description && <div className="SubcategoryCard-description">{description}</div>}
      </div>
    );
  }
}
