import React, { Component } from 'react';

import './bonus-conditions.css';

class BonusConditions extends Component {
  render() {
    return (
      <div className="BonusConditions">
        <div className="BonusConditions-appeal">
          <h2 className="BonusConditions-appealContent">УВАЖАЕМЫЕ ПОКУПАТЕЛИ!</h2>
          <div className="BonusConditions-appealContent">
            <p>
              Просим Вас до принятия решения об участии в «Бонусной Программе» сети магазинов
              «MIXIT» внимательно прочитать нижеизложенные Правила участия в «Бонусной Программе»
              сети магазинов «MIXIT» (далее по тексту – «Правила»).
            </p>

            <p>
              Настоящие Правила определяют условия получения Карты Участника и Правила участия в
              «Бонусной Программе» сети магазинов «MIXIT» (далее по тексту – «Программа»).
            </p>

            <p>
              Настоящие Правила Программы являются адресованной неограниченному кругу дееспособных
              физических лиц (потребителей) публичной офертой в соответствии с п.2 ст.437
              Гражданского кодекса РФ и определяют условия и порядок проведения Программы,
              вступления, участия и прекращения (приостановления) участия в ней Участников.
            </p>
          </div>
        </div>

        <div>
          <ul>
            <li style={{ marginBottom: '24px' }}>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  1.1. &#160;<b>«Организатор Программы» (Организатор)</b> — Общество с ограниченной
                  ответственностью «Миксит», основной государственный регистрационный номер (ОГРН)
                  1187746961742, идентификационный номер налогоплательщика (ИНН) 7733333130, адрес
                  места нахождения: Российская Федерация, 125362, г. Москва, пр. Строительный, д.
                  7А, корп. 11А, комн. 37, контактный телефон: 8 (495) 252-07-43, обладающее
                  исключительными правами управления и развития Программы. С дополнительной
                  информацией об Организаторе Программы можно ознакомиться на интернет сайте
                  Программы или по вышеуказанному номеру телефону.
                </li>

                <li>
                  1.2. &#160;<b>«Партнер»</b> — индивидуальный предприниматель или юридическое лицо,
                  подписавшее с Организатором соглашение об участии в Программе, осуществляющее сбор
                  и передачу Организатору информации, необходимой для начисления/списания Бонусов
                  Участникам.
                </li>

                <li>
                  1.3. &#160;<b>«Служба поддержки Программы»</b> — служба, осуществляющая
                  информационно-справочное обслуживание Участников Программы без выходных с 09:00 до
                  21:00 по телефонным номерам:
                  <ul style={{ paddingLeft: '32px', listStyleType: 'square' }}>
                    <li>
                      8 (800) 550-98-70 - для звонков по всей территории Российской Федерации;
                    </li>

                    <li>
                      8 (820) 00718851 - для звонков по любым вопросам на территории Республики
                      Беларусь.
                    </li>
                  </ul>
                </li>

                <li>
                  1.4. &#160;<b>«Сайт Программы»</b> — сайт Программы в сети интернет по адресу
                  www.mixit.ru.
                </li>

                <li>
                  1.5. &#160;<b>«Участник Программы»</b> — физическое лицо, достигшее 18 лет (либо
                  лицо, не достигшее 18 лет, но приобретшее дееспособность в соответствии с
                  законодательством) и являющееся держателем Карты Участника (активированной либо
                  неактивированной), подтвердивший свое согласие на участие в Программе в
                  соответствии с настоящими Правилами. К участию в Программе не допускаются
                  юридические лица и их представители.
                </li>

                <li>
                  1.6. &#160;<b> «Анкета» </b>— заявление физического лица (потребителя),
                  заполняемое на Сайте Программы и содержащее информацию об Участнике (фамилия, имя,
                  отчество, дата рождения, пол, номер телефона, адрес электронной почты, иные
                  сведения).
                </li>

                <li>
                  1.7. &#160;<b> «Бонусные баллы» (Бонусы)</b> — расчетные бонусные единицы,
                  зачисляемые на бонусный счет Карты Участника Программы в соответствии с настоящими
                  Правилами и определяющие объем Прав Участника на особые условия обслуживания в
                  рамках Программы. Бонусы не имеют наличного выражения и не предоставляют право на
                  получение их в денежном эквиваленте;
                </li>

                <li>
                  1.8. &#160;<b> «Карта Участника»</b> — пластиковая карта или виртуальная карта
                  (без физического носителя), обладающая уникальным в рамках Программы номером
                  (штрих-кодом), который используется для идентификации Участника в Программе;
                </li>

                <li>
                  1.9. &#160;<b>«Активация»</b> – заполнение Участником Анкеты на Сайте Программы, а
                  также последующая отправка Участником Организатору специального кода посредством
                  Сайта Программы.
                </li>

                <li>
                  1.10. &#160;<b>«База данных»</b> — электронная база данных, содержащая данные о
                  зарегистрированных Участниках Программы.
                </li>

                <li>
                  1.11. &#160;<b>«Магазин(ы)»</b> - магазины, работающие под товарным знаком «MIXIT»
                  и перечисленные на Сайте Программы.
                </li>

                <li>
                  1.12. &#160;<b>«Территория проведения Программы» </b>– Программа действует при
                  совершении покупок в Магазинах и на Сайте Программы.
                </li>

                <li>
                  1.13. &#160;<b>«Условия Участия (Правила Программы)»</b> – Условия участия в
                  Программе, изложенные в настоящем документе и доступные для ознакомления каждому
                  заинтересованному лицу на Сайте Программы.
                </li>

                <li>
                  1.14. &#160;<b>«Права на особые условия обслуживания»</b> — права Участника на
                  предоставление преимуществ, обусловленных его участием в Программе. Данными
                  преимуществами могут являться: предоставление дополнительных скидок в рамках
                  Программы, обмен Бонусов на бонусные единицы иных программ лояльности, участие в
                  специально организованных акциях в рамках Программы и т.д. Организатор вправе
                  самостоятельно определять предоставляемые в качестве особых условий обслуживания
                  преимущества. Объем Прав Участника на особые условия обслуживания определяется
                  исходя из суммы Бонусов, учтенных на его бонусный счет в Программе, и может быть
                  ограничен на усмотрение Организатора.
                </li>
              </ul>
            </li>

            <li style={{ marginBottom: '24px' }}>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>2. УСЛОВИЯ ПОЛУЧЕНИЯ И АКТИВАЦИИ КАРТЫ УЧАСТНИКА</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  2.1. &#160;Лицо, желающее принять участие в Программе и стать ее Участником, может
                  получить виртуальную Карту Участника Программы в Магазине, назвав свой номер
                  телефона, и активировать Карту согласно условиям настоящих Правил на Сайте
                  Программы, либо получить виртуальную карту, заполнив Анкету на Сайте Программы.
                  Участие в Программе является добровольным
                </li>

                <li>
                  2.2. &#160;Для подтверждения номера телефона, указанного Участником при заполнении
                  Анкеты, необходимо ввести специальный код, который придет в смс-сообщении. Адрес
                  электронной почты подтверждается Участником посредством перехода по специальной
                  ссылке из письма.
                </li>

                <li>
                  2.3. &#160;Участник, совершивший вышеуказанные действия по активации Карты
                  Участника, становится держателем Карты Участника и подтверждает:
                  <ul style={{ paddingLeft: '12px' }}>
                    <li>
                      2.3.1. &#160;Cвое согласие на участие в Программе, а также свое согласие с
                      настоящими Правилами. Если в Правила будут внесены изменения, и Участник
                      продолжает пользоваться Картой после внесения в Правила изменений, то он
                      подтверждает согласие с новыми условиями.
                    </li>

                    <li>
                      2.3.2. &#160;В соответствии с п. 7.1 настоящих Правил свое согласие на
                      обработку персональных данных и предоставляет право обрабатывать свои
                      персональные данные, указанные в форме регистрации в соответствии с настоящими
                      Правилами.
                    </li>

                    <li>
                      2.3.3. &#160;Свое согласие на получение информации рекламного характера от
                      Организатора Программы, который имеет право на осуществление
                      рекламно-информационных рассылок с использованием: SMS уведомлений,
                      электронной почты, почтовой связи, телефонной связи и/или иных способов, при
                      этом Участник Программы сохраняет за собой право отказаться от уведомлений
                      рекламного характера одним из следующих способов:
                      <ul style={{ paddingLeft: '32px', listStyleType: 'square' }}>
                        <li>
                          письменное заявление об отказе от получения вышеуказанной информации,
                          отправленное на электронный адрес hello@mixit.ru;
                        </li>

                        <li>
                          устное заявление об отказе от получения вышеуказанной информации,
                          переданное оператору Службы поддержки Программы.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  2.4. &#160;Карта является собственностью Организатора. Карта Участника действует в
                  течение срока проведения Программы или до любой даты прекращения действия Карты
                  Участника согласно настоящим Правилам, в зависимости от того, какой момент
                  наступит раньше.
                </li>

                <li>
                  2.5. &#160;Каждый Участник вправе иметь одновременно не более одной Карты. В
                  случае, если Организатору станет известно, что Участник одновременно имеет более
                  одной Карты, Организатор вправе в любой момент без какого-либо предварительного
                  уведомления ограничить и/или заблокировать все или некоторые Карты
                  соответствующего Участника с аннулированием Бонусов, учтенных на бонусном счете в
                  Программе. В случае, если Участник не использует Карту в течение одного года в
                  целях начисления и/ или списания Бонусов, учитываемые на бонусном счете в
                  Программе Бонусы могут быть аннулированы Организатором без уведомления Участника и
                  предоставления такому Участнику какой-либо компенсации.
                </li>
              </ul>
            </li>

            <li style={{ marginBottom: '24px' }}>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>3. ПОРЯДОК И СПОСОБ ИНФОРМИРОВАНИЯ УЧАСТНИКОВ ПРОГРАММЫ ОБ УСЛОВИЯХ ПРОГРАММЫ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  3.1. &#160;Информирование участников Программы и потенциальных участников
                  Программы об условиях ее проведения, а также об их изменениях проводится с
                  использованием: Службы поддержки Программы, или информационных плакатов,
                  размещаемых в Магазинах, или Сайта Программы www.mixit.ru/top/loyalty-program.
                </li>

                <li>
                  3.2. &#160;Организатор оставляет за собой право в любое время приостановить или
                  прекратить Программу, передать права на управление Программой другому лицу с
                  размещением соответствующей информации на сайте Программы за 14 (Четырнадцать)
                  календарных дней до окончания действия Программы. Организатор не несет
                  ответственности за приостановление или прекращение Программы в отношении любого
                  бонусного счета в Программе.
                </li>
              </ul>
            </li>

            <li style={{ marginBottom: '24px' }}>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>4. ПОРЯДОК НАЧИСЛЕНИЯ БОНУСОВ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  4.1. &#160;Бонусы начисляются на Карту Участника при совершении Участником покупок
                  в Магазинах и/или на Сайте Программы.
                </li>

                <li>
                  4.2. &#160;За покупки, совершенные в Магазинах, Бонусы начисляются в течение 24
                  часов после совершения покупки.
                </li>

                <li>
                  4.3. &#160;За покупки, совершенные на Сайте Программы, осуществляется начисление
                  только после доставки заказа и получения информации об оплате товара. За покупки,
                  совершенные на Сайте Программы, Бонусы начисляются при условии, что бонусная карта
                  была указана при оформлении заказа.
                </li>

                <li>
                  4.4. &#160;Организатор Программы вправе самостоятельно определять количество
                  дополнительных Экстра-бонусов, то есть бонусов, начисляемых на Карту Участника при
                  приобретении товаров в рамках проведения промо-акций. Условия проведения
                  конкретной промо-акции доводятся до сведения Участников на Сайте Программы и/или в
                  Магазинах.
                </li>

                <li>
                  4.5. &#160;Для начисления Бонусов Участник Программы должен уведомить Организатора
                  непосредственно перед совершением покупки о том, что покупка товара осуществляется
                  в рамках Программы, путем предъявления карты Участника Программы при оплате на
                  кассовом терминале Магазина, или внесения номера Карты в специальное поле при
                  оформлении заказа на Сайте Программы, или сообщения номера мобильного телефона,
                  привязанного к Карте. Начисление Бонусов осуществляется только при условии оплаты
                  покупки на кассовом терминале в Магазине или при совершении покупок на Сайте
                  Программы. Организатор не несет ответственность за не начисление Бонусов в том
                  случае, если Участник не известил Организатора о том, что покупка будет сделана в
                  рамках Программы до совершения покупки. Начисление Бонусов после совершения
                  покупки, по чеку, не производится.
                </li>

                <li>
                  4.6. &#160;Начисление Бонусов по виртуальной Карте в Магазинах осуществляется по
                  номеру мобильного телефона Участника. Участнику необходимо на кассе в момент
                  покупки сообщить номер мобильного телефона. В случае, если номер мобильного
                  телефона Участника отсутствует в Базе данных, начисление Бонусов по такой покупке
                  не происходит. Если в Базе данных номер мобильного телефона присутствует более чем
                  в одной Анкете Участника Программы (т.е. привязан к нескольким Картам), при оплате
                  покупки на кассе автоматически для начисления Бонусов выбирается Карта, по которой
                  была совершена наиболее поздняя покупка.
                </li>

                <li>
                  4.7. &#160;Участнику Программы в личном кабинете на Сайте Программы доступна
                  информация о балансе Бонусов, количестве активных Бонусов для списания, история
                  покупок, начислений и списаний Бонусов, статус Карты Участника. При обращении в
                  Магазин Участник Программы может уточнить информацию об остатке накопленных
                  Бонусов и статусе Карты.
                </li>

                <li>
                  4.8. &#160;Бонусы не начисляются{' '}
                  <b>при совершении Участником следующих покупок:</b>
                  <ul style={{ listStyleType: 'square', paddingLeft: '32px' }}>
                    <li>
                      покупка подарочных сертификатов (т.е. документов, дающих право их предъявителю
                      на совершение в течение определенного срока покупок на указанную сумму);
                    </li>

                    <li>
                      покупка товаров, оплаченных полностью путем списания Бонусов, при этом на
                      покупку товаров, оплаченных частично путем списания Бонусов, Бонусы
                      начисляются только на ту часть стоимости товара, которая оплачена денежными
                      средствами (наличные или с использованием банковской карты);
                    </li>

                    <li>оплата услуг, предоставляемых в Магазинах или на Сайте;</li>

                    <li>покупка иных товаров и услуг, определенных по усмотрению Организатора.</li>
                  </ul>
                </li>

                <li>
                  4.9. &#160;При возврате товара Бонусы, начисленные за покупку этого товара,
                  списываются с бонусного счета. При этом при возврате товара, Покупателю необходимо
                  уведомить сотрудника Магазина, о том, что при покупке товара была использована
                  Карта Участника и предоставить Карту или назвать номер телефона для поиска и
                  идентификации Карты.
                </li>

                <li>
                  4.10. &#160;Начисление Бонусов в Магазинах и на Сайте Программы осуществляется при
                  предъявлении Карты Участника в размере 5 (пяти) % от суммы чека или заказа
                  независимо от суммы покупки.
                </li>

                <li>
                  4.11. &#160;Срок действия Бонусов равен 12 (двенадцать) месяцев. Неиспользованные
                  Бонусы сгорают по завершении их срока действия.
                </li>

                <li>
                  4.12. &#160;Срок действия Экстра-бонусов, полученных по промо-акциям,
                  устанавливается отдельно для каждой такой акции и прописывается в правилах
                  промо-акции.
                </li>

                <li>
                  4.13. &#160;Правила начисления дополнительных Бонусов:
                  <div className="BonusConditions-tableContainer">
                    <div className="BonusConditions-table">
                      <div className="BonusConditions-tableColumn">
                        <div className="BonusConditions-tableHeaderCell">Бонусы</div>
                        <div className="BonusConditions-tableCell">
                          Приве
                          <wbr />
                          тственные бонусы
                        </div>
                        <div className="BonusConditions-tableCell">День Рождения</div>
                      </div>
                      <div className="BonusConditions-tableColumn">
                        <div className="BonusConditions-tableHeaderCell">
                          Основа
                          <wbr />
                          ния начисле <wbr />
                          ния
                        </div>
                        <div className="BonusConditions-tableCell">За активацию Карты</div>
                        <div className="BonusConditions-tableCell">
                          День рожде
                          <wbr />
                          ния Участника
                        </div>
                      </div>
                      <div className="BonusConditions-tableColumn">
                        <div className="BonusConditions-tableHeaderCell">
                          Раз
                          <wbr />
                          мер
                        </div>
                        <div className="BonusConditions-tableCell">
                          150 Бону
                          <wbr />
                          сов
                        </div>
                        <div className="BonusConditions-tableCell">
                          150 Бону
                          <wbr />
                          сов
                        </div>
                      </div>
                      <div className="BonusConditions-tableColumn">
                        <div className="BonusConditions-tableHeaderCell">Срок действия</div>
                        <div className="BonusConditions-tableCell">1 месяц</div>
                        <div className="BonusConditions-tableCell">
                          За неде
                          <wbr />
                          лю до дня рожде
                          <wbr />
                          ния и неде
                          <wbr />
                          лю после
                        </div>
                      </div>
                    </div>
                  </div>
                  Даже если в Анкету были внесены правки по дате рождения, то начисление на Карту
                  может быть произведено не чаще одного раза в год.
                </li>
              </ul>
            </li>

            <li>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>5. ПОРЯДОК СПИСАНИЯ БОНУСОВ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  5.1. &#160;Накопленные на Карте Участника Бонусы можно использовать в соответствии
                  с условиями настоящих Правил для списания при оплате товаров, реализуемых в
                  Магазинах и на Сайте.
                </li>

                <li>5.2. &#160;Участник может использовать Бонусы только после Активации Карты.</li>

                <li>
                  5.3. &#160;Использовать для списания при оплате покупки можно только Активные
                  бонусы.
                </li>

                <li>
                  5.4. &#160;Участнику Программы предоставляется возможность использовать Бонусы для
                  списания при оплате до 30 (тридцати) % от суммы чека. Для этого необходимо
                  предъявить на кассе Карту Участника с накопленными Бонусами или назвать номер
                  телефона для идентификации Карты, и получить скидку на приобретаемые товары из
                  расчета 1 (один) рубль за 1 (один) Бонус, накопленный на Карте.
                </li>

                <li>
                  5.5. &#160;Для списания Бонусов Участник Программы должен уведомить Организатора
                  Программы непосредственно перед оплатой покупки о том, что покупка товара
                  осуществляется в рамках Программы, путем предъявления Карты или по виртуальной
                  Карте, либо путем сообщения номера мобильного телефона и информирования о своем
                  желании списать Бонусы при оплате товара. На номер мобильного телефона Участника,
                  к которому привязана Карта/виртуальная Карта, отправляется смс-сообщение с кодом
                  подтверждения. Участник сообщает код подтверждения сотруднику Магазина или вводит
                  в специальное поле на Сайте Программы. Если подтверждающий код совпадает,
                  Организатор осуществляет списание Бонусов. Если код не введен или введен с ошибкой
                  покупка завершается без списания Бонусов. В случае, если номер мобильного телефона
                  Участника отсутствует в Базе данных, списание Бонусов невозможно.
                </li>

                <li>
                  5.6. &#160;Если в Базе данных номер мобильного телефона присутствует более чем в
                  одной Анкете Участника Программы, автоматические выбирается Карта, по которой была
                  совершена наиболее поздняя покупка.
                </li>

                <li>
                  5.7. &#160;При списании Бонусов с Карты Участника списываются Бонусы с более
                  коротким сроком действия, за исключением Экстра-бонусов, по которым установлены
                  иные правила списания Бонусов. Такие условия доводятся до сведения Участников на
                  Сайте Программы и/или в Магазинах.
                </li>

                <li>
                  5.8. &#160;Порядок использования Бонусов на Сайте:
                  <ul style={{ paddingLeft: '12px' }}>
                    <li>
                      5.8.1. &#160;Списание Бонусов при осуществлении оплаты товара на Сайте
                      возможно только при условии Активации Карты. При отсутствии Активации Карты
                      списание Бонусов невозможно.
                    </li>

                    <li>
                      5.8.2. &#160;Для возможности списания Бонусов при оплате заказа на Сайте на
                      мобильный телефон Участника отправляется смс-сообщение с кодом подтверждения.
                      Участнику необходимо ввести код подтверждения в специальное поле в заказе. Без
                      ввода кода подтверждения списание Бонусов невозможно.
                    </li>

                    <li>
                      5.8.3. &#160;При одновременном использовании Участником при оплате промокода и
                      Бонусов списание Бонусов невозможно. В таком случае Участник вправе
                      использовать при оплате либо промокод, либо Бонусы. При использовании
                      Участником промокода в соответствии с настоящим пунктом Бонусы на сумму
                      оплаченного заказа будут начислены согласно Правилам Программы.
                    </li>
                  </ul>
                </li>

                <li>
                  5.9. &#160;В случае если сумма, подлежащая оплате, превышает сумму Бонусов на
                  Карте Участника, Участник Программы доплачивает разницу денежными средствами
                  способами, доступными в момент оплаты в Магазине или на Сайте.
                </li>

                <li>5.10. &#160;Бонусы не подлежат выдаче в денежном и ином эквиваленте.</li>

                <li>
                  5.11. &#160;При оплате двух и более единиц товара с использованием Бонусов,
                  накопленных на Карте Участника, Бонусы списываются в счет оплаты каждого товара
                  пропорционально стоимости оплачиваемых товаров.
                </li>

                <li>
                  5.12. &#160;В случае возврата товара, частично или полностью оплаченного Бонусами,
                  накопленными на Карте Участника, Бонусы будут возвращены на бонусный счет Карты
                  Участника в течение 24 часов с момента совершения возврата, в случае если возврат
                  товара происходит в день совершения покупки.
                </li>

                <li>
                  5.13. &#160;В случае, если Бонусами оплачивается два и более товаров, и затем
                  возвращается один из них, то на бонусный счет Карты Участника возвращается
                  количество Бонусов, истраченных на возвращенный товар.
                </li>

                <li>
                  5.14. &#160;Бонусы не могут быть использованы при совершении Участником следующих
                  покупок:
                  <ul style={{ listStyleType: 'square', paddingLeft: '32px' }}>
                    <li>
                      покупка подарочных сертификатов (т.е. документов, дающих право их предъявителю
                      на совершение в течение определенного срока покупок на указанную сумму);
                    </li>

                    <li>оплата услуг, предоставляемых в Магазинах или на Сайте;</li>

                    <li>покупка иных товаров и услуг, определенных по усмотрению Организатора.</li>
                  </ul>
                </li>

                <li>
                  5.15. &#160;Бонусы могут быть списаны по инициативе Организатора Программы без
                  предварительного уведомления Участника Программы и без его согласия, в случае если
                  они были начислены на бонусный счет Участника Программы ошибочно и/или в
                  результате противоправных действий Участника Программы или третьих лиц или по иным
                  основаниям.
                </li>

                <li>
                  5.16. &#160;Если по техническим причинам в момент покупки операции с Картами
                  онлайн невозможны, покупка завершается без использования Бонусов.
                </li>
              </ul>
            </li>

            <li>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>6. ПРАВА ОРГАНИЗАТОРА</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  6.1. &#160;Организатор вправе вносить любые изменения в перечень товаров (услуг),
                  действий, в отношении которых начисляются/списываются Бонусы, и изменять
                  количество Бонусов, которые Участники получают/расходуют в результате приобретения
                  таких товаров и услуг.
                </li>

                <li>
                  6.2. &#160;Организатор вправе приостановить и/или прекратить участие в Программе
                  любого Участника и заблокировать Карту без уведомления в следующих случаях:
                  <ul style={{ listStyleType: 'square', paddingLeft: '32px' }}>
                    <li>
                      Участник не соблюдает настоящие Правила, а также условия акций и иных
                      предложений Программы;
                    </li>

                    <li>
                      Участник совершил или намеревается совершить действия, расцененные
                      Организатором как мошеннические, обман или прочие манипуляции, которые
                      повлекли или могут повлечь за собой материальные, моральные и прочие
                      вредоносные последствия различного типа и степени как для Организатора,
                      Партнеров Программы, самого Участника или иных Участников, а также любого
                      третьего лица;
                    </li>

                    <li>
                      Участник злоупотребляет Программой в целом и/или какими-либо правами,
                      предоставляемыми Участнику в рамках Программы. Для целей настоящих Правил под
                      злоупотреблением (помимо прочего) понимаются недобросовестные действия
                      Участника, направленные на накопление как можно большего количества Бонусов на
                      своем Счете в Программе без фактического приобретения товаров (работ, услуг)
                      для собственных нужд (для личного потребления). За исключением случаев, явно
                      предусмотренных рекламными акциями в рамках Программы и/или персональным
                      предложением (информированием), направленным Участнику по электронной почте
                      и/или на номер телефона, однозначным и безусловным фактом злоупотребления
                      Программой является систематическое (два и более раза в течение календарного
                      месяца) приобретение Участником у Партнеров товаров (работ, услуг) в целях
                      дальнейшей перепродажи, безвозмездной передачи другому лицу, не являющемуся
                      близким родственником Участника, и/или иного использования, не связанного с
                      личными нуждами соответствующего Участника, с намерением накопить как можно
                      больше Бонусов на своем бонусном счете в Программе для целей получения в
                      дальнейшем особых условий обслуживания;
                    </li>

                    <li>
                      Участник предоставляет информацию (сведения), вводящую в заблуждение
                      Организатора или Партнеров Программы, либо не соответствующую
                      действительности;
                    </li>

                    <li>В соответствии с требованиями государственных органов;</li>

                    <li>В случае смерти Участника.</li>
                  </ul>
                </li>

                <li>
                  6.3. &#160;В случае приостановления участия по указанным выше основаниям Карта и
                  бонусный счет в Программе соответствующего Участника блокируется и не могут быть
                  использованы в целях расходования Бонусов и получения особых условий обслуживания
                  в рамках Программ до принятия Организатором окончательного решения о прекращении
                  участия соответствующего Участника в Программе или о возобновлении его участия.
                  Организатор вправе запросить у соответствующего Участника письменные пояснения
                  относительно нарушений таким Участником настоящих Правил, которые послужили
                  основанием для приостановления и/или прекращения его участия в Программе. В случае
                  прекращения участия по указанным выше основаниям Бонусы на счете Участника
                  аннулируются.
                </li>

                <li>
                  6.4. &#160;Организатор оставляет за собой право в любое время приостановить,
                  прекратить действие Программы, передать права на управление Программой другому
                  лицу с уведомлением об этом Участников. Организатор не несет ответственности за
                  приостановление или прекращение Программы в отношении любого бонусного счета:
                  <ul style={{ paddingLeft: '12px' }}>
                    <li>
                      6.4.1 &#160;При передаче прав на управление Программой другому лицу согласием
                      Участника на смену лица на стороне Организатора является продолжение им
                      пользования Картой (проведение списания или начисления Бонусов). При этом
                      Организатор вправе перевести Карту в статус «только начисление», если данный
                      Участник ранее не проходил процесс Активации Карты.
                    </li>
                  </ul>
                </li>

                <li>
                  6.5. &#160;Организатор вправе вносить изменения в настоящие Правила Программы в
                  любое время.
                </li>
              </ul>
            </li>

            <li>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>7. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  7.1. &#160;Акцептуя настоящие Правила Программы (п. 2.2), а также используя в
                  дальнейшем Карту Участник выражает свое информированное и сознательное согласие на
                  обработку персональных данных, указанных в Анкете, Организатору, который является
                  оператором персональных данных в соответствии с Федеральным законом от 27.07.2006
                  № 152-ФЗ «О персональных данных.
                </li>

                <li>
                  7.2. &#160;Согласие дается в целях обеспечения Организатором участия субъекта
                  персональных данных в Программе. Данное условие распространяется на следующую
                  информацию, передаваемую Участником: фамилия, имя, отчество, дата рождения, пол,
                  номер телефона, адрес электронной почты, иная информация, относящаяся к личности
                  Участника, доступная либо известная в любой конкретный момент времени Организатору
                  в рамках Программы (далее – «Персональные данные»).
                </li>

                <li>
                  7.3. &#160;Согласие предоставляется на осуществление Организатором в целях,
                  предусмотренных настоящими Правилами, действий в отношении Персональных данных
                  Участника включая: сбор, систематизацию, накопление, хранение, уточнение
                  (обновление, изменение), использование, распространение (в том числе передача),
                  обезличивание, блокирование, уничтожение Персональных данных, а также для
                  осуществления Организатором контактов с Участником, в том числе, в целях
                  продвижения товаров, работ, услуг на рынке (с использованием, включая, но не
                  ограничиваясь: sms-сервисов, электронной почты, почтовой и телефонной связи) с
                  учетом требований действующего законодательства Российской Федерации.
                </li>

                <li>
                  7.4. &#160;Предоставленное Участником согласие на обработку персональных данных
                  действует в течение срока участия в Программе, или до отзыва Участником такого
                  согласия в простой письменной форме в адрес Организатора. В случае прекращения
                  участия Участника в Программе (в соответствии с настоящими Правилами), в том
                  числе, в случае отзыва Участником данного согласия на обработку Персональных
                  данных, Организатор обязуется прекратить обработку персональных данных Участника и
                  уничтожить Персональные данные в срок, не превышающий 30 (тридцати) дней с даты
                  прекращения участия Участника в Программе/получения от Участника отзыва согласия.
                </li>

                <li>
                  7.5. &#160;Участник осведомлен и согласен с тем, что обработка предоставленных
                  Персональных данных в вышеуказанных целях, осуществляется, в том числе, путем
                  передачи (включая предоставление доступа) предоставленных Персональных данных
                  строго на условиях конфиденциальности и по поручению Организатора Партнерам
                  Программы, перечень которых приведен на Сайте Программы, а также иным лицам, как
                  вступающим с Организатором в договорные отношения по поводу предоставления в
                  рамках Программы особых условиях обслуживания и/или обеспечения участия Участников
                  в Программе, так и без вступления в такие договорные отношения.
                </li>

                <li>
                  7.6. &#160;Участник настоящим осведомлен с тем, что в соответствии с п. 2 ст. 9 ФЗ
                  №152-ФЗ «О персональных данных», в случае отзыва согласия на обработку
                  персональных данных оператор персональных данных вправе продолжить обработку
                  персональных данных без согласия при наличии оснований, предусмотренных
                  действующим законодательством Российской Федерации.
                </li>

                <li>
                  7.7. &#160;Вступая в Программу, в том числе при направлении Анкеты и прохождении
                  Активации, а также используя в дальнейшем Карту Участник в соответствии со ст. 18
                  Федерального закона № 38-ФЗ от 13.03.2006 г. «О рекламе», ст. 44.1 Федерального
                  закона от 07.07.2003 №126-ФЗ «О связи» также выражает свое согласие на получение
                  от Организатора или от имени Организатора рекламной информации, распространяемой
                  по сетям электросвязи, в том числе посредством использования телефонной,
                  факсимильной, подвижной радиотелефонной связи, электронной почты, сети Интернет,
                  на получение от имени Организатора рассылки по сети подвижной радиотелефонной
                  связи посредством отправки коротких текстовых сообщений (сообщений, состоящих из
                  букв и (или) символов, набранных в определенной последовательности), включая
                  рассылки рекламного характера, а также на получение звонков от операторов Службы
                  поддержки Программы, в том числе путем осуществления прямых контактов. Участник
                  может самостоятельно в любом момент отозвать свое согласие на предусмотренные
                  настоящим пункты виды коммуникаций посредством уведомления в простой письменной
                  форме в адрес Организатора. Согласие на получение рекламной информации,
                  распространяемой по электронной почте, может быть также отозвано в любой момент
                  посредством перехода по гипертекстовой ссылке, предусмотренной в каждом сообщении
                  электронной почты.
                </li>
              </ul>
            </li>

            <li>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  8.1. &#160;Лицо, ставшее Участником Программы в соответствии с п. 2.3 настоящих
                  Правил, соглашается со всеми правилами Программы, в частности с порядком
                  начисления и списания Бонусов.
                </li>

                <li>
                  8.2. &#160;Если Участник Программы желает отказаться от участия в Программе,
                  заблокировать Карту Участника или изменить персональные данные, он должен
                  направить соответствующее заявление по адресу, указанному в п. 2.3 настоящих
                  Правил. В случае порчи или утраты Карты, в том числе ее хищения, Карту Участника
                  можно восстановить путем получения в Магазине новой карты и при условии написания
                  соответствующего заявления от владельца карты с предоставлением документов,
                  удостоверяющих личность. В случаях кражи и утери карты, Организатор Программы
                  рекомендует незамедлительно обратиться для блокировки карты в Службу поддержки
                  Программы по телефонам: 8 (800) 550-98-70 - для звонков по всей территории
                  Российской Федерации; 8 (820) 00718851 - для звонков по любым вопросам на
                  территории Республики Беларусь; 8 (800) 100-10-14 - для звонков по любым вопросам
                  на территории Республики Казахстан. Если имеющиеся на потерянной/украденной Карте
                  Бонусы были использованы до того момента, как карта была заблокирована, претензии
                  по восстановлению Бонусов не принимаются.
                </li>

                <li>
                  8.3. &#160;Организатор Программы не несет ответственности за ущерб, нанесенный в
                  результате несанкционированного использования Карты Участника Программы.
                </li>

                <li>
                  8.4. &#160;Уплата налогов и исполнение обязательств, связанных с участием
                  Участника в Программе, являются обязанностью Участника.
                </li>

                <li>
                  8.5. &#160;Документом, подтверждающим обязанность Организатора Программы начислить
                  Бонусы, является кассовый чек. Все претензии по факту начисления Бонусов
                  рассматриваются Организатором Программы только при предъявлении вышеуказанного
                  документа.
                </li>

                <li>
                  8.6. &#160; В случаях, если по причине сбоев функционирования электрических или
                  сетей передачи данных, а также в случае отсутствия связи с сервером и по иным
                  причинам операции по Картам не возможны и приостановлены, Организатор Программы
                  оставляет за собой право отказать Участнику в проведении операции по Карте до
                  устранения причин, сообщив при этом Участнику примерные сроки устранения причин и
                  возможности произвести операцию по Карте, при этом Организатор Программы не несет
                  ответственность за приостановку операций по Картам. Информация о приостановлении
                  операции по Картам указывается Организатором Программы на информационных стендах
                  потребителя в Магазинах, на информационных табличках в кассовой зоне, на Сайте или
                  иным способом.
                </li>
              </ul>
            </li>

            <li>
              <div style={{ fontSize: '24px', marginBottom: '8px' }}>
                <b>9. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ ОРГАНИЗАТОРА</b>
              </div>

              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  9.1. &#160; Участник самостоятельно несет ответственность за сохранность и
                  конфиденциальность информации, внесенной им на Сайте при регистрации и/ или при
                  заполнении Анкеты (далее по тексту – учетная информация). Организатор не несет
                  ответственность за любого рода убытки, причиненные Участнику в результате
                  использования третьими лицами учетной информации Участника, ставшей доступной
                  третьим лицам не по вине Организатора.
                </li>

                <li>
                  9.2. &#160; Участник несет ответственность за любые действия, осуществленные с
                  использованием Сайта от имени Участника.
                </li>

                <li>
                  9.3. &#160;Участник самостоятельно несет ответственность за сохранность Карты,
                  используемой для получения особых условий обслуживания в соответствии с настоящими
                  Правилами.
                </li>

                <li>
                  9.4. &#160;Организатор прилагает все возможные усилия для обеспечения надлежащей
                  работоспособности Сайта, однако не несет ответственности за неисполнение или
                  ненадлежащее исполнение взятых на себя обязательств в соответствии с настоящими
                  Правилами, а также возможные убытки, возникшие в том числе, но не ограничиваясь, в
                  результате:
                  <ul style={{ listStyleType: 'square', paddingLeft: '32px' }}>
                    <li>
                      неправомерных действий третьих лиц, направленных на нарушение информационной
                      безопасности или нормального функционирования Сайта;
                    </li>

                    <li>
                      сбоев в работе Сайта, в иных сетях телекоммуникаций, вызванных ошибками в
                      коде, компьютерными вирусами и иными посторонними фрагментами кода в
                      программном обеспечении Сайта;
                    </li>

                    <li>
                      отсутствия (невозможности установления, прекращения и пр.) интернет соединений
                      между сервером Участника и сервером Сайта;
                    </li>

                    <li>
                      проведения государственными и муниципальными органами, а также иными
                      организациями мероприятий в рамках СОРМ (Системы оперативно-розыскных
                      мероприятий);
                    </li>

                    <li>
                      установления государственного регулирования (или регулирования иными
                      организациями) хозяйственной деятельности коммерческих организаций в сети
                      Интернет и/или установления указанными субъектами разовых ограничений,
                      затрудняющих или делающих невозможным исполнение обязательств в соответствии с
                      настоящими Правилами или их части;
                    </li>

                    <li>
                      других случаев, связанных с действиями (бездействием) третьих лиц,
                      направленными на ухудшение общей ситуации с использованием сети Интернет и/или
                      компьютерного оборудования, существующего в течение действия Программы, также
                      любых других действий, связанных с работоспособностью Сайта и действия третьих
                      лиц;
                    </li>

                    <li>
                      выполнения работ на Сайте, а именно: Организатор имеет право производить
                      профилактические работы в программно-аппаратном комплексе Сайта с временным
                      приостановлением работы Сайта по возможности в ночное время, и максимально
                      сокращая время неработоспособности Сайта. В случае наступления форс-мажорных
                      обстоятельств, а также аварий или сбоев в программно-аппаратных комплексах
                      третьих лиц, сотрудничающих с Организатором, или действий (бездействий)
                      третьих лиц, направленных на приостановку или прекращение функционирования
                      Сайта, возможна приостановка работы Сайта без какого-либо уведомления
                      Участника и возмещения любого рода убытков.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default BonusConditions;
