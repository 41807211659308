import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromCategories from '../ducks/categories';
import ProductList from '../components/product-list/product-list';

const mapStateToProps = createStructuredSelector({
  products: fromCategories.getProductSlugList,
  amount: fromCategories.getProductsAmount,
  total: fromCategories.getTotalAmount,
  isLoading: fromCategories.getIsProductListLoading,
  productList: fromCategories.getProductList
});

const mapDispatchToProps = {
  loadMore: fromCategories.actions.loadMoreProducts
};

class CategoryProductList extends Component {
  static propTypes = {
    /** Category slug */
    slug: PropTypes.string.isRequired
  };

  render() {
    const microMarkingObject = this.getMicroMarkingObject();

    return (
      <ProductList
        {...this.props}
        onLoadMore={this.handleLoadMore}
        listId="category"
        microMarkingObject={microMarkingObject}
      />
    );
  }

  handleLoadMore = () => {
    const { slug, parent, appliedFilters, amount, loadMore } = this.props;
    loadMore(slug, amount, appliedFilters, parent);
  };

  getMicroMarkingObject = () => {
    const { productList } = this.props;

    if (!productList || !productList.length > 0) {
      return null;
    }

    const offerCount = productList.length;

    const highPrice = productList.reduce((highPrice, item) => {
      if (item.price.current > highPrice) {
        return item.price.current;
      }

      return highPrice;
    }, 0);

    const lowPrice = productList.reduce((lowPrice, item) => {
      if (item.price.current < lowPrice) {
        return item.price.current;
      }

      return lowPrice;
    }, productList[0].price.current);

    return { offerCount, highPrice, lowPrice };
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductList);
