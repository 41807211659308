import React, { Component, Children } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import debounce from './utils/debounce';
import Frame from './frame';
import Pagination from './pagination';

class Carousel extends Component {
  static propTypes = {
    resizeDebounce: PropTypes.number,
    duration: PropTypes.number,
    easing: PropTypes.string,
    perPage: PropTypes.number,
    startIndex: PropTypes.number,
    currentPage: PropTypes.number,
    draggable: PropTypes.bool,
    threshold: PropTypes.number,
    loop: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    pagination: PropTypes.bool,
    page: PropTypes.element,
    container: PropTypes.node,
    paginationContainer: PropTypes.node,
    overflowVisible: PropTypes.bool
  };

  static defaultProps = {
    resizeDebounce: 250,
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    currentPage: false,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => {},
    onChange: false,
    pagination: false,
    page: <button />,
    container: <div />,
    paginationContainer: (
      <div
        style={{
          textAlign: 'center'
        }}
      />
    ),
    overflowVisible: false
  };

  state = {
    width: 0,
    current: 0
  };

  render() {
    const { children, pagination, paginationContainer, page, currentPage, ...rest } = this.props;
    const { perPage } = this.props;
    const { width, current } = this.state;
    const slidesCount = Children.count(children);

    const currentPageFinal = currentPage || current;
    const pageCount = slidesCount - perPage + 1;
    return (
      <div ref={this.handleSelectorRef}>
        {width && (
          <Frame
            slidesCount={slidesCount}
            width={width}
            {...rest}
            parent={this.selector}
            current={currentPageFinal}
            draggable={slidesCount > perPage}
            onChange={this.handleFrameChange}
          >
            {children}
          </Frame>
        )}
        {pagination && (
          <Pagination
            paginationContainer={paginationContainer}
            page={page}
            current={currentPageFinal}
            count={pageCount}
            pageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    const { startIndex, resizeDebounce } = this.props;
    this.setState({
      current: startIndex
    });

    this.handleResize = debounce(() => {
      this.processResize();
    }, resizeDebounce);

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleFrameChange = data => {
    this.changePage(data.current);
  };

  handlePageChange = (e, page) => {
    this.changePage(page);
  };

  handleSelectorRef = element => {
    this.selector = findDOMNode(element);

    this.updateWidth();
  };

  processResize() {
    this.updateWidth();
  }

  updateWidth() {
    if (!this.selector) {
      return;
    }

    this.setState({
      width: this.selector.getBoundingClientRect().width
    });
  }

  normalizeCurrent(slide) {
    const { perPage } = this.props;
    const slidesCount = Children.count(this.props.children);
    const firstPage = 0;
    const lastPage = slidesCount - perPage;

    if (slide <= firstPage) {
      return firstPage;
    }

    if (slide >= lastPage) {
      return lastPage;
    }

    return slide;
  }

  changePage(number) {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ page: number });
    } else {
      this.setState({
        current: this.normalizeCurrent(number)
      });
    }
  }
}

export default Carousel;
