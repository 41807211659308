import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PickPointCard from '../components/pick-point-card/pick-point-card';

import * as fromPickPointList from '../ducks/pick-point-list';

const mapStateToProps = createStructuredSelector({
  pickPoint: fromPickPointList.getPickPoint
});

class PickPointCardContainer extends Component {
  render() {
    return <PickPointCard onSelect={this.handleSelect} {...this.props} />;
  }

  handleSelect = pickPoint => {
    const { onSelect } = this.props;

    onSelect(pickPoint);
  };
}

export default connect(mapStateToProps)(PickPointCardContainer);
