const SCRIPT_TAG_ID = 'facebook-jssdk';

function insertScript(src) {
  const el = document.createElement('script');
  el.id = SCRIPT_TAG_ID;
  el.type = 'text/javascript';
  el.src = src;
  el.async = true;
  document.head.appendChild(el);
}

function initCallback(cb) {
  window.fbAsyncInit = cb;
}

export function load(APP_ID) {
  return new Promise(resolve => {
    if (document.getElementById(SCRIPT_TAG_ID)) {
      resolve(window.FB);
      return;
    }

    initCallback(() => {
      window.FB.init({
        appId: APP_ID,
        // appId: 2598214120212546,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0'
      });
      resolve(window.FB);
    });

    insertScript('https://connect.facebook.net/en_US/sdk.js');
  });
}
// https://stackoverflow.com/questions/46295455/facebook-api-javascript-sdk-status-shows-connected-after-logout
// export function deletePermitions(response) {
//   return new Promise(resolve => {
//     window.FB.api(
//       '/me/permissions',
//       'delete',
//       {
//         access_token: response.authResponse.accessToken // use existing token from database
//       },
//       r => {
//         if (r.success) response.authResponse.accessToken = null; // remove token in database
//       }
//     );
//     return resolve(window.FB);
//   });
// }

export function login() {
  return new Promise((resolve, reject) =>
    window.FB.login(function(response) {
      if (response.status === 'connected') {
        resolve(response.authResponse);
        return;
      }
      reject(response.status);
    })
  );
}

export function logout() {
  return new Promise(resolve =>
    window.FB.logout(function(response) {
      resolve(response);
    })
  );
  // .then(response => deletePermitions(response));
}

export function authorize() {
  return new Promise(resolve =>
    window.FB.getLoginStatus(function(response) {
      resolve(response);
    })
  ).then(response => {
    if (response.status === 'connected') {
      return response.authResponse;
    }

    return login();
  });
}

export function unAuthorize() {
  return new Promise(resolve =>
    window.FB.getLoginStatus(function(response) {
      resolve(response);
    })
  ).then(response => {
    if (response.status === 'connected') {
      logout();
    }
  });
}
