import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './article-action-bar.css';

const { any } = PropTypes;

class Item extends Component {
  static propTypes = {
    children: any.isRequired
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <li className="ArticleActionBar-actionItem" {...rest}>
        {children}
      </li>
    );
  }
}

export default Item;
