import * as ECOMMERCE from 'modules/core/ducks/types/ecommerce';
import * as fromAccount from 'modules/core/ducks/account';
// import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';

function getLocationString() {
  const { protocol, hostname, pathname, search, port } = window.location;
  const _port = !!port ? port : '';
  return `${protocol}//${hostname}${_port}${pathname}${search}`;
}

function resetDataLayer() {
  if (!__BROWSER__) {
    return;
  }

  if (!window.google_tag_manager || !GTM_ID) {
    return;
  }

  const gtm = window.google_tag_manager[GTM_ID];

  if (!gtm) {
    return;
  }

  gtm.dataLayer.reset();
}

const originalLocation = __BROWSER__ ? getLocationString() : '';

const trackPageView = eventDef => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);

  const { payload } = action;

  if (!event) {
    return null;
  }

  const email = fromAccount.getEmail(nextState);

  const slug = getCategorySlug(event.path);

  const pageType = getPageType(event.path, slug);

  // console.log('event', {
  //   event: 'pageview',
  //   type: 'page',
  //   email,
  //   page_title: event.title,
  //   page_location: event.location,
  //   page_path: event.path,
  //   page_type: [pageType] || null,
  //   product_ids: productIdsNormalize(payload.products),
  //   product_sum: productSumNormalize(payload.totalCost)
  // });

  return {
    event: 'pageview',
    type: 'page',
    email,
    page_title: event.title,
    page_location: event.location,
    page_path: event.path,
    page_type: [pageType] || null,
    product_ids: productIdsNormalize(payload.products),
    product_sum: productSumNormalize(payload.totalCost)
  };
};

const productIdsNormalize = arr => {
  return arr || [];
};

const productSumNormalize = totalCost => {
  if (!totalCost || totalCost === 0) {
    return 0;
  }

  return [totalCost.toFixed(2)] || null;
};

const getPageType = (path, slug) => {
  if (path === '/') {
    return 'home';
  }

  if (/category/gi.test(path)) {
    return 'category';
  }

  if (/search/gi.test(path)) {
    return 'searchresults';
  }

  if (/product/gi.test(path)) {
    return 'product';
  }

  if (/basket/gi.test(path)) {
    return 'cart';
  }

  if (/redirect-payment-page/gi.test(path) || (/ordering/gi.test(path) && slug === 'success')) {
    return 'purchase';
  }

  return null;
};

function getCategorySlug(pathname) {
  let normalPathName = pathname;

  const lastSymbol = pathname.slice(-1);

  if (lastSymbol === '/') {
    normalPathName = pathname.slice(0, -1);
  }

  const arr = [];

  let pos = 0;

  while (true) {
    let foundPos = normalPathName.indexOf('/', pos);
    if (foundPos === -1) break;

    arr.push(foundPos);
    pos = foundPos + 1;
  }

  return normalPathName.slice(arr[arr.length - 1] + 1);
}

const trackFullEvent = eventDef => (actionObject, prevState, nextState) => {
  const { action, category, label, value, ...rest } = eventDef(actionObject, prevState, nextState);

  return {
    ...rest,
    action,
    value,
    type: 'event',
    event_category: category
  };
};

const trackProductExternalId = eventDef => (action, prevState, nextState) => {
  const { externalId } = eventDef(action, prevState, nextState);

  return {
    event: 'productview',
    external_id: externalId
  };
};

const trackCategoryId = eventDef => (action, prevState, nextState) => {
  const { id, categoryName } = eventDef(action, prevState, nextState);

  return {
    event: 'categoryview',
    category_id: id,
    category_name: categoryName
  };
};

function getPageTypeFromPath(location, { product, checkout, transaction } = {}) {
  if (transaction) {
    return 'purchase';
  }

  if (checkout) {
    return 'checkout';
  }

  if (/category/gi.test(location)) {
    return 'catalog';
  }

  if (/product/gi.test(location) && product) {
    return 'product';
  }

  if (/search/gi.test(location)) {
    return 'searchresults';
  }

  if (/basket/gi.test(location)) {
    return 'cart';
  }

  if (location === '/') {
    return 'home';
  }

  return null;
}

const trackRemarketing = eventDef => (action, prevState, nextState) => {
  const { value, productList, product, checkout, transaction } = eventDef(
    action,
    prevState,
    nextState
  );
  const email = fromAccount.getEmail(nextState);
  const pageType = getPageTypeFromPath(nextState.router.location.pathname, {
    product,
    checkout,
    transaction
  });

  if (!pageType) {
    return null;
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn('Reamrketing', {
      crto_email: email,
      ecomm_pagetype: pageType,
      ecomm_totalvalue: value,
      ecomm_prodid: productList
    });
  }

  return {
    type: 'event',
    event: 'basket_data',
    crto_email: email,
    ecomm_pagetype: pageType,
    ecomm_totalvalue: value,
    ecomm_prodid: productList
  };
};

const trackEcommerceEvent = eventDef => (actionObject, prevState, nextState) => {
  const event = eventDef(actionObject, prevState, nextState);

  return {
    type: 'event',
    event: 'ecomEvent',
    eventCategory: 'Ecommerce',
    ...event
  };
};

// function prepareBasket(basketProducts) {
//   return basketProducts.map(item => {
//     let title = item.product.category.title;
//     let parentTitle = item.product.category.parent && item.product.category.parent.title;
//     let categoryName = `${parentTitle ? parentTitle + '/' : ''}${title}`;

//     return {
//       id: item.id,
//       name: item.product.title,
//       price: item.product.price.current,
//       quantity: item.amount,
//       category_id: item.product.category.id,
//       category_name: categoryName
//     };
//   });
// }

// const trackBasketDataByLocationChange = eventDef => (action, prevState, nextState) => {
//   const basketProducts = fromBasketProducts.getList(nextState);
//   const isEmpty = basketProducts.length === 0;

//   const basketInfo = isEmpty ? 'basket_empty' : 'basket_full';

//   return {
//     event: 'basket_data',
//     basket_info: basketInfo,
//     data: prepareBasket(basketProducts)
//   };
// };

export const pageView = trackPageView(function(action) {
  const { pathname, search, skipTrack } = action.payload.location;

  if (/login/gi.test(pathname) || /register/gi.test(pathname)) {
    return null;
  }

  if (skipTrack) {
    return null;
  }

  resetDataLayer();

  return {
    title: document.title,
    path: pathname.concat(search),
    location: originalLocation
  };
});

export const showList = trackEcommerceEvent(action => {
  const { products } = action.payload;

  return {
    eventAction: 'Impressions',
    ecommerce: {
      impressions: products.map(item => ({
        ...item.product,
        list: item.listId,
        position: item.position
      }))
    }
  };
});

const viewProductPage = trackProductExternalId(action => {
  const { product } = action.payload;

  return { externalId: product.externalId };
});

export const clickProduct = trackEcommerceEvent(function(action) {
  const { product, listName, position } = action.payload;

  return {
    eventAction: 'Product Click',
    ecommerce: {
      click: {
        actionField: { list: listName }, // подставить список или коллекцию, куда входит товар
        products: [
          {
            ...product,
            position //позиция товара на странице
          }
        ]
      }
    }
  };
});

export const showProduct = trackEcommerceEvent(function(action) {
  const { product } = action.payload;

  return {
    eventAction: 'Product View',
    ecommerce: {
      detail: {
        products: [product]
      }
    }
  };
});

export const addProductToCart = trackEcommerceEvent(function(action) {
  const { product } = action.payload;

  return {
    eventAction: 'Add to Cart',
    ecommerce: {
      add: {
        products: [product]
      }
    }
  };
});

const viewCategory = trackCategoryId(action => {
  const { id, name, categoryName } = action.payload.category;

  return { id, name, categoryName };
});

const trackBasketDataShow = eventDef => (action, prevState, nextState) => {
  const { products } = eventDef(action, prevState, nextState);
  const isEmpty = products.length === 0;

  const basketInfo = isEmpty ? 'basket_empty' : 'basket_full';
  return {
    type: 'event',
    event: 'basket_data',
    basket_info: basketInfo,
    data: products
  };
};

const basketDataShow = trackBasketDataShow(action => {
  const { products } = action.payload;

  return { products };
});

const viewBasket = trackEcommerceEvent(action => {
  const { products } = action.payload;

  return {
    eventAction: 'Cart',
    ecommerce: {
      checkout: {
        actionField: { step: 1 },
        products
      }
    }
  };
});

const removeProductFromCart = trackEcommerceEvent(action => {
  const { product } = action.payload;

  return {
    eventAction: 'Remove from Cart',
    ecommerce: {
      remove: {
        products: [product]
      }
    }
  };
});

const checkout = trackEcommerceEvent(action => {
  const { products } = action.payload;

  return {
    eventAction: 'Checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2 },
        products
      }
    }
  };
});

const transaction = trackEcommerceEvent(action => {
  const {
    deliveryMethod,
    paymentMethod,
    orderId,
    orderTotal,
    promoCoupon,
    products
  } = action.payload;

  return {
    eventAction: 'Transaction',
    delivery: deliveryMethod,
    payment: paymentMethod,
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          revenue: orderTotal,
          coupon: promoCoupon
        },
        products
      }
    }
  };
});

const signIn = trackFullEvent(action => {
  const { id } = action.payload;

  return {
    userid: id
  };
});

const signOut = trackFullEvent(() => {
  return {
    userid: null
  };
});

const sum = (a, b) => a + b;

const sendDynamicRemarketingImpression = trackRemarketing((action, prevState, nextState) => {
  const { products } = action.payload;
  const pageType = getPageTypeFromPath(nextState.router.location.pathname);
  if (pageType === 'cart') {
    const basketProducts = products.filter(item => item.listId === 'basket');
    return {
      productList: basketProducts.map(item => item.product.id),
      value: basketProducts.map(item => item.product.price).reduce(sum, 0)
    };
  }
  return {
    productList: products.map(item => item.product.id),
    value: products.map(item => item.product.price).reduce(sum, 0)
  };
});

const sendDynamicRemarketingView = trackRemarketing(action => {
  const { product } = action.payload;

  return {
    productList: [product.id],
    value: product.price,
    product: true
  };
});

const sendDynamicRemarketingCheckout = trackRemarketing(action => {
  const { products } = action.payload;

  return {
    productList: products.map(item => item.id),
    value: products.map(item => item.price).reduce(sum, 0),
    checkout: true
  };
});

const sendDynamicRemarketingTransaction = trackRemarketing(action => {
  const { products } = action.payload;
  return {
    productList: products.map(item => item.id),
    value: products.map(item => item.price).reduce(sum, 0),
    transaction: true
  };
});

const combineEvents = (...eventDefs) => (action, prevState, nextState) =>
  eventDefs.map(eventDef => eventDef(action, prevState, nextState)).filter(Boolean);

export default {
  [ECOMMERCE.PAGE_VIEW]: pageView,
  // [LOCATION_CHANGE]: trackBasketDataByLocationChange(),
  [ECOMMERCE.BASKET_DATA_SHOW]: basketDataShow,
  [ECOMMERCE.PRODUCT_LIST_SHOW]: combineEvents(showList, sendDynamicRemarketingImpression),
  [ECOMMERCE.PRODUCT_CLICK]: clickProduct,
  [ECOMMERCE.PRODUCT_VIEW]: combineEvents(showProduct, sendDynamicRemarketingView, viewProductPage),
  [ECOMMERCE.PRODUCT_ADD_TO_BASKET]: addProductToCart,
  [ECOMMERCE.PRODUCT_REMOVE_FROM_BASKET]: removeProductFromCart,
  [ECOMMERCE.CATEGORY_VIEW]: viewCategory,
  [ECOMMERCE.BASKET_SHOW]: viewBasket,
  [ECOMMERCE.CHECKOUT]: combineEvents(checkout, sendDynamicRemarketingCheckout),
  [ECOMMERCE.TRANSACTION]: combineEvents(transaction, sendDynamicRemarketingTransaction),
  [ECOMMERCE.USER_SET]: signIn,
  [ECOMMERCE.USER_RESET]: signOut
};
