import { createAction } from 'modules/utils/dux';

import * as FEEDBACK from '../types/feedback';

export default {
  send({ text, firstName, email, phone, photoFile }) {
    return createAction(FEEDBACK.SEND, {
      text,
      firstName,
      email,
      phone,
      photoFile
    });
  },

  response() {
    return createAction(FEEDBACK.RESPONSE);
  },

  error(message) {
    return createAction(FEEDBACK.ERROR, {
      message
    });
  }
};
