import React, { Component } from 'react';

import Link from 'modules/core/components/link/short-link';

import textSlicer from 'modules/utils/text-slicer';

const DESKTOP_COMMENT_LENGHT = 480;
const MOBILE_COMMENT_LENGHT = 240;

class Comment extends Component {
  state = {
    isExpanded: false
  };

  render() {
    const { comment, isMobile } = this.props;
    const { isExpanded } = this.state;

    const COMMENT_LENGHT = isMobile ? MOBILE_COMMENT_LENGHT : DESKTOP_COMMENT_LENGHT;

    const isCommentLarge = comment.length > COMMENT_LENGHT;

    console.log('COMMENT_LENGHT: ', COMMENT_LENGHT);

    if (isCommentLarge) {
      const slicedComment = textSlicer(comment, COMMENT_LENGHT);

      return (
        <div className="SuccessfulOrder-commentHolder">
          <b>Комментарий к заказу: </b>

          <span className="SuccessfulOrder-commentText">
            {isExpanded ? comment : slicedComment}
          </span>

          <span className="SuccessfulOrder-commentButton">
            <Link component="button" type="button" onClick={this.handleClick}>
              {isExpanded ? 'Скрыть' : 'Показать'}
            </Link>
          </span>
        </div>
      );
    }

    return (
      <div className="SuccessfulOrder-commentHolder">
        <b>Комментарий к заказу: </b>
        <span className="SuccessfulOrder-comment">{comment}</span>
      </div>
    );
  }

  toggleCommentState() {
    this.setState(state => ({
      ...state,
      isExpanded: !state.isExpanded
    }));
  }

  handleClick = () => {
    this.toggleCommentState();
  };
}

export default Comment;
