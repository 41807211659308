export default function formatStreet(street) {
  if (street.indexOf('ул.') === -1 || street.indexOf('ул.') === 0) {
    return street;
  }

  const target = 'ул.';
  let position = street.indexOf('ул.') - 1; // Обрезаем пробел

  return target + ' ' + street.substring(0, position);
}
