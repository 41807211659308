import { takeEvery, select, put, call } from 'redux-saga/effects';
import { ACCOUNT } from 'modules/core/ducks/types';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromCore from 'modules/core/ducks';
import { mixitApi } from 'services/mixit';
import * as ProfileAdapter from 'services/mixit/adapters/profile-adapter';
import ProfileActions from '../actions/profile';
import * as PROFILE from '../types/profile';
import * as fromCityList from 'modules/geography/ducks/city-list';

export function* authWatcher() {
  yield takeEvery(ACCOUNT.AUTHORIZATION_RENEW_RESPONSE, requestProfile);
  yield takeEvery(PROFILE.CITY_CHANGE, changeCity);
  yield takeEvery(PROFILE.BIRTH_DATE_CHANGE, changeBirthDate);
  yield takeEvery(PROFILE.ADDRESS_CHANGE, changeAddress);
  yield takeEvery(PROFILE.FULL_NAME_CHANGE, changeFullName);
  yield takeEvery(PROFILE.PHONE_CHANGE, changePhone);
  yield takeEvery(PROFILE.PHOTO_CHANGE, changePhoto);
  yield takeEvery(PROFILE.CHANGE, changeProfile);
}

function* requestProfile(action) {
  const { error } = action.payload;
  if (error) {
    return;
  }
  yield put(ProfileActions.request());
  const authorization = yield select(fromCore.getAuthorization);
  try {
    const response = yield call(mixitApi(authorization).profile().item);

    const { profile, entities } = ProfileAdapter.normalize(response.data);

    yield put(ProfileActions.response(null, profile, entities));
  } catch (error) {
    yield put(ProfileActions.response(error));
  }
}

const getCity = city => {
  if (!city) {
    return null;
  }

  if (city._local) {
    return {
      fiasId: city.fiasId
    };
  }

  return {
    id: city.id
  };
};

function* changeProfile(action) {
  const profileData = action.payload;

  const { error, result } = yield call(updateProfile, {
    ...profileData,
    legacyAddress: profileData.address
  });
  console.log(error, result);
}

function* changeCity(action) {
  const { cityId } = action.payload;

  const city = yield getCityById(cityId);

  const { error, result } = yield call(updateProfile, { city: getCity(city) });

  console.log(error, result);
}

function* changeBirthDate(action) {
  const { birthDate } = action.payload;

  const { error, result } = yield call(updateProfile, { birthDate });
  console.log(error, result);
}

function* changeAddress(action) {
  const { address } = action.payload;

  const { error, result } = yield call(updateProfile, {
    legacyAddress: address
  });
  console.log(error, result);
}

function* changeFullName(action) {
  const { firstName, lastName } = action.payload;

  const response = yield call(updateProfile, {
    firstName: firstName.trim(),
    lastName: lastName.trim()
  });
  console.log(response.error, response.result);

  // if(response.result) {
  //   const { profile, entities } = ProfileAdapter.normalize(response.result.profile);
  //   console.log("function*changeFullName -> entities", entities)
  //   console.log("function*changeFullName -> profile", profile)

  //   yield put(ProfileActions.response(null, profile, entities));
  // }
}

function* changePhone(action) {
  const { phone } = action.payload;

  const { error, result } = yield call(updateProfile, { phone });
  console.log(error, result);
}

function* changePhoto(action) {
  const { photo } = action.payload;

  const { error, result } = yield call(uploadPhoto, photo);
  console.log(error, result);
  yield put(ProfileActions.changePhotoResponse(result.profile.photo));
}

function* uploadPhoto(photo) {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(mixitApi(authorization).profile().uploadPhoto, photo);

    const result = ProfileAdapter.normalize(response.data);

    return { result };
  } catch (error) {
    return { error };
  }
}

function* updateProfile(profile) {
  const authorization = yield select(fromAccount.getAuthorization);

  console.log(ProfileAdapter.serialize(profile));

  try {
    const response = yield call(
      mixitApi(authorization).profile().update,
      ProfileAdapter.serialize(profile)
    );

    const result = ProfileAdapter.normalize(response.data);

    return { result };
  } catch (error) {
    return { error };
  }
}

function getCityById(id) {
  return select(fromCityList.getCityById, { id });
}
