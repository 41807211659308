import React, { Component } from 'react';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowDown } from 'modules/core/components/icons';

import './button.css';

class Button extends Component {
  render() {
    const { title, count, ...rest } = this.props;

    return (
      <button className="CategoryPanel_Button" type="button" {...rest}>
        <span className="CategoryPanel_Button-title">{title}</span>

        <span className="CategoryPanel_Button-counterHolder">
          {count && <span className="CategoryPanel_Button-counter">{count}</span>}
        </span>

        <span className="CategoryPanel_Button-icon">
          <IconContainer inline>
            <IconAngleArrowDown />
          </IconContainer>
        </span>
      </button>
    );
  }
}

export default Button;
