import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-simple-basket-add.css';

class IconSimpleBasketAdd extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconSimpleBasketAdd">
            <path d="M20.6 24.6c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2c0-1.2 1-2.2 2.2-2.2zM29.1 24.6c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2c0-1.2 1-2.2 2.2-2.2z" />
            <path d="M9.7 7h4.5l4.2 13.7h14M16.5 13.5h18.3M5.5 12.8v8.5M9.8 17.1H1.2" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconSimpleBasketAdd;
