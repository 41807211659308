import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import './accordion-label.css';

const { arrayOf, element } = PropTypes;

export default class Accordionlabel extends Component {
  static propTypes = {
    shildren: arrayOf(element)
  };

  render() {
    const { children } = this.props;
    return (
      <div className="AccordionLabel">
        {Children.map(
          children,
          (element, index) =>
            element && (
              <div key={index} className="AccordionLabel-itemHolder">
                {element}
              </div>
            )
        )}
      </div>
    );
  }
}
