import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ConfirmPasswordRecoveryErrorDialog from '../components/confirm-password-error-dialog/confirm-password-error-dialog';
import * as fromDialogs from '../ducks/dialogs';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsConfirmPasswordRecoveryErrorOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeConfirmPasswordErrorRecoveryDialog,
  openRecoveryPasswordDialog: fromDialogs.actions.openPasswordRecoveryRequestDialog
};

class ConfirmPasswordRecoveryErrorDialogContainer extends Component {
  render() {
    const { isOpened, openRecoveryPasswordDialog } = this.props;

    return (
      <ConfirmPasswordRecoveryErrorDialog
        show={isOpened}
        onClose={this.handleClose}
        openRecoveryPasswordDialog={openRecoveryPasswordDialog}
      />
    );
  }

  handleClose = () => {
    this.props.close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPasswordRecoveryErrorDialogContainer);
