import { PRODUCT_TYPE } from '../constants';

export const regularProducts = {
  'food-fetish-grape-backberry': {
    id: 1,
    slug: 'food-fetish-grape-backberry',
    properties: [],
    sku: '150021',
    title: 'Food Fetish Grape and Blackberry',
    description: 'Витаминная обновляющая маска с виноградом и черной смородиной',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 1,
      title: 'Food Fetish'
    },
    category: {
      id: 1,
      title: 'Для лица'
    },
    price: {
      default: 345,
      current: 345,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 12,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: [12, 13, 14],
    variants: [
      {
        id: 123,
        price: {
          default: 345,
          current: 345,
          discount: {
            percentage: 0,
            numeral: 0
          }
        },
        media: [1],
        options: [12]
      },
      {
        id: 124,
        price: {
          default: 445,
          current: 345,
          discount: {
            percentage: 0,
            numeral: 100
          }
        },
        media: [3],
        options: [13]
      },
      {
        id: 125,
        price: {
          default: 545,
          current: 545,
          discount: {
            percentage: 0,
            numeral: 0
          }
        },
        media: [4],
        quantity: 0,
        options: [14]
      }
    ],
    inFavorite: true,
    inBasket: false,
    createDate: {
      humanized: '12 минут назад',
      formatted: '17 июня, 12:34',
      datetime: '2018-06-17T12:34:04'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Food Fetish Grape and Blackberry',
        caption: 'Food Fetish Grape and Blackberry in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Food Fetish Grape and Blackberry',
        caption: 'Food Fetish Grape and Blackberry in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Food Fetish Grape and Blackberry',
        caption: 'Food Fetish Grape and Blackberry in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Food Fetish Grape and Blackberry',
        caption: 'Food Fetish Grape and Blackberry in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-140.png',
              double: '/content/products/food-fetish-grape-blackberry-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-280.png',
              double: '/content/products/food-fetish-grape-blackberry-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-560.png',
              double: '/content/products/food-fetish-grape-blackberry-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-grape-blackberry-1120.png',
              double: '/content/products/food-fetish-grape-blackberry-2240.png'
            }
          }
        ]
      }
    ]
  },
  'food-fetish-avocado-kiwi': {
    id: 2,
    slug: 'food-fetish-avocado-kiwi',
    properties: [],
    sku: '150024',
    title: 'Food Fetish Avocado and Kiwi',
    description: 'Витаминная тонизирующая маска с киви и авокадо',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 1,
      title: 'Food Fetish'
    },
    category: {
      id: 1,
      title: 'Для лица'
    },
    price: {
      default: 395,
      current: 315,
      discount: {
        percentage: 20,
        numeral: 80
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Food Fetish Avocado and Kiwi',
        caption: 'Food Fetish Avocado and Kiwi in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Food Fetish Avocado and Kiwi',
        caption: 'Food Fetish Avocado and Kiwi in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Food Fetish Avocado and Kiwi',
        caption: 'Food Fetish Avocado and Kiwi in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Food Fetish Avocado and Kiwi',
        caption: 'Food Fetish Avocado and Kiwi in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-140.png',
              double: '/content/products/food-fetish-avocado-kiwi-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-280.png',
              double: '/content/products/food-fetish-avocado-kiwi-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-560.png',
              double: '/content/products/food-fetish-avocado-kiwi-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-avocado-kiwi-1120.png',
              double: '/content/products/food-fetish-avocado-kiwi-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 18,
    features: [
      {
        type: 'FEATURE/GIFT',
        title: 'Подарок',
        description: 'Совершенно бесплатно для Вас'
      },
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    createDate: {
      humanized: '56 минут назад',
      formatted: '23 февраля, 17:44',
      datetime: '2018-02-23T17:44:15'
    }
  },
  'food-fetish-sweet-berries': {
    id: 3,
    slug: 'food-fetish-sweet-berries',
    properties: [],
    sku: '150029',
    title: 'Food Fetish Sweet Berries',
    description: 'Витаминная осветляющая маска с малиной и клубникой',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 1,
      title: 'Food Fetish'
    },
    category: {
      id: 1,
      title: 'Для лица'
    },
    price: {
      default: 325,
      current: 275,
      discount: {
        percentage: 15,
        numeral: 50
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Food Fetish Sweet Berries',
        caption: 'Food Fetish Sweet Berries in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Food Fetish Sweet Berries',
        caption: 'Food Fetish Sweet Berries in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Food Fetish Sweet Berries',
        caption: 'Food Fetish Sweet Berries in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Food Fetish Sweet Berries',
        caption: 'Food Fetish Sweet Berries in a jar, top view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-140.png',
              double: '/content/products/food-fetish-sweet-berries-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-280.png',
              double: '/content/products/food-fetish-sweet-berries-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-560.png',
              double: '/content/products/food-fetish-sweet-berries-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            },
            fallback: {
              normal: '/content/products/food-fetish-sweet-berries-1120.png',
              double: '/content/products/food-fetish-sweet-berries-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 25,
    features: [
      {
        type: 'FEATURE/LAST',
        title: 'Последний',
        description: 'Последняя единица товара'
      },
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: true,
    createDate: {
      humanized: '2 минуты назад',
      formatted: '3 апреля, 16:05',
      datetime: '2018-04-03T16:05:17'
    }
  },
  'tropical-mask': {
    id: 4,
    slug: 'tropical-mask',
    properties: [],
    sku: '1500216',
    title: 'Tropical Mask',
    description: 'Очищающая пластифицирующая альгинатная маска с экстрактом папайи',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 2,
      title: 'Tropical products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 445,
      current: 445,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Tropical Mask',
        caption: 'Tropical Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Tropical Mask',
        caption: 'Tropical Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Tropical Mask',
        caption: 'Tropical Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Tropical Mask',
        caption: 'Tropical Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-140.png',
              double: '/content/products/tropical-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-280.png',
              double: '/content/products/tropical-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-560.png',
              double: '/content/products/tropical-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tropical-mask-1120.png',
              double: '/content/products/tropical-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 25,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: true,
    inBasket: false,
    createDate: {
      humanized: '3 минуты назад',
      formatted: '7 март, 16:05',
      datetime: '2018-03-07T22:15:00'
    }
  },
  'vegetable-mask': {
    id: 5,
    slug: 'vegetable-mask',
    properties: [],
    sku: '1500225',
    title: 'Vegetable Mask',
    description: 'Питательная пластифицирующая альгинатная маска с маслом авокадо',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 3,
      title: 'Vegetable products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 445,
      current: 400,
      discount: {
        percentage: 10,
        numeral: 45
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Vegetable Mask',
        caption: 'Vegetable Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Vegetable Mask',
        caption: 'Vegetable Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Vegetable Mask',
        caption: 'Vegetable Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Vegetable Mask',
        caption: 'Vegetable Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-140.png',
              double: '/content/products/vegetable-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-280.png',
              double: '/content/products/vegetable-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-560.png',
              double: '/content/products/vegetable-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vegetable-mask-1120.png',
              double: '/content/products/vegetable-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 4,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: true,
    inBasket: true,
    createDate: {
      humanized: '41 минута назад',
      formatted: '7 июня, 23:20',
      datetime: '2018-06-07T23:20:00'
    }
  },
  'mineral-mask': {
    id: 6,
    slug: 'mineral-mask',
    properties: [],
    sku: '1500236',
    title: 'Mineral Mask',
    description: 'Питательная пластифицирующая альгинатная маска с маслом авокадо',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 4,
      title: 'Mineral products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 565,
      current: 565,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Mineral Mask',
        caption: 'Mineral Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Mineral Mask',
        caption: 'Mineral Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Mineral Mask',
        caption: 'Mineral Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Mineral Mask',
        caption: 'Mineral Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-140.png',
              double: '/content/products/mineral-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-280.png',
              double: '/content/products/mineral-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-560.png',
              double: '/content/products/mineral-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/mineral-mask-1120.png',
              double: '/content/products/mineral-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 50,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '1 минуту назад',
      formatted: '8 октября, 00:01',
      datetime: '2018-10-08T00:01:17'
    }
  },
  'vitamin-mask': {
    id: 7,
    slug: 'vitamin-mask',
    properties: [],
    sku: '1500249',
    title: 'Vitamin Mask',
    description:
      'Минеральная биоревитализация для всех типов кожи лица на основе красной глины и экстракта лотоса',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 5,
      title: 'Vitamin products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 450,
      current: 400,
      discount: {
        percentage: 10,
        numeral: 50
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Vitamin Mask',
        caption: 'Vitamin Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Vitamin Mask',
        caption: 'Vitamin Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Vitamin Mask',
        caption: 'Vitamin Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Vitamin Mask',
        caption: 'Vitamin Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-140.png',
              double: '/content/products/vitamin-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-280.png',
              double: '/content/products/vitamin-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-560.png',
              double: '/content/products/vitamin-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/vitamin-mask-1120.png',
              double: '/content/products/vitamin-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 23,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: true,
    createDate: {
      humanized: '1 минуту назад',
      formatted: '14 декабря, 06:32',
      datetime: '2018-12-14T06:32:32'
    }
  },
  'energy-mask': {
    id: 8,
    slug: 'energy-mask',
    properties: [],
    sku: '1500264',
    title: 'Energy Mask',
    description:
      'Минеральная биоревитализация для всех типов кожи лица на основе красной глины и экстракта лотоса',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 6,
      title: 'Energy products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 595,
      current: 425,
      discount: {
        percentage: 30,
        numeral: 170
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Energy Mask',
        caption: 'Energy Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Energy Mask',
        caption: 'Energy Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Energy Mask',
        caption: 'Energy Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Energy Mask',
        caption: 'Energy Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-140.png',
              double: '/content/products/energy-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-280.png',
              double: '/content/products/energy-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-560.png',
              double: '/content/products/energy-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/energy-mask-1120.png',
              double: '/content/products/energy-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 13,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '17 минут назад',
      formatted: '23 августа, 15:47',
      datetime: '2018-08-23T15:47:26'
    }
  },
  'young-beauty-mask': {
    id: 9,
    slug: 'young-beauty-mask',
    properties: [],
    sku: '1500281',
    title: 'Young Beauty Mask',
    description: 'Маска для лица глиняная омолаживающая с гиалуроном и маслом виноградной косточки',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 8,
      title: 'Beauty products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 535,
      current: 535,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Young Beauty Mask',
        caption: 'Young Beauty Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Young Beauty Mask',
        caption: 'Young Beauty Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Young Beauty Mask',
        caption: 'Young Beauty Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Young Beauty Mask',
        caption: 'Young Beauty Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-140.png',
              double: '/content/products/young-beauty-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-280.png',
              double: '/content/products/young-beauty-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-560.png',
              double: '/content/products/young-beauty-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/young-beauty-mask-1120.png',
              double: '/content/products/young-beauty-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 98,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: true,
    inBasket: false,
    createDate: {
      humanized: '3 часа назад',
      formatted: '7 ноября, 22:22',
      datetime: '2018-11-07T22:22:00'
    }
  },
  'tiffanys-mask': {
    id: 10,
    properties: [],
    slug: 'tiffanys-mask',
    sku: '15002100',
    title: 'Tiffany`s Mask',
    description: 'Маска для лица глиняная очищающая с ментолом и экстрактом петрушки',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 9,
      title: 'Tiffany`s products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 570,
      current: 450,
      discount: {
        percentage: 20,
        numeral: 120
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Tiffany`s Mask',
        caption: 'Tiffany`s Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Tiffany`s Mask',
        caption: 'Tiffany`s Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Tiffany`s Mask',
        caption: 'Tiffany`s Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Tiffany`s Mask',
        caption: 'Tiffany`s Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-140.png',
              double: '/content/products/tiffanys-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-280.png',
              double: '/content/products/tiffanys-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-560.png',
              double: '/content/products/tiffanys-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/tiffanys-mask-1120.png',
              double: '/content/products/tiffanys-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 57,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: true,
    createDate: {
      humanized: '2.5 часа назад',
      formatted: '3 сентября, 18:35',
      datetime: '2018-09-03T18:35:00'
    }
  },
  'hydro-essence-mask': {
    id: 11,
    properties: [],
    slug: 'hydro-essence-mask',
    sku: '15002121',
    title: 'Hydro Essence Mask',
    description:
      'Интенсивный серум-флюид для глубокого увлажнения всех типов кожи лица с голубым ретинолом и гиалуроновой кислотой',
    type: PRODUCT_TYPE.DEFAULT,
    series: {
      id: 10,
      title: 'Hydro Essence products'
    },
    category: {
      id: 2,
      title: 'Для лица'
    },
    price: {
      default: 435,
      current: 435,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Hydro Essence Mask',
        caption: 'Hydro Essence Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Hydro Essence Mask',
        caption: 'Hydro Essence Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Hydro Essence Mask',
        caption: 'Hydro Essence Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            }
          }
        ]
      },
      {
        id: 4,
        type: 'IMAGE',
        title: 'Hydro Essence Mask',
        caption: 'Hydro Essence Mask in the package, front view',
        sources: [
          {
            size: 140,
            main: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-140.png',
              double: '/content/products/hydro-essence-mask-280.png'
            }
          },
          {
            size: 280,
            main: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-280.png',
              double: '/content/products/hydro-essence-mask-560.png'
            }
          },
          {
            size: 560,
            main: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-560.png',
              double: '/content/products/hydro-essence-mask-1120.png'
            }
          },
          {
            size: 1120,
            main: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            },
            fallback: {
              normal: '/content/products/hydro-essence-mask-1120.png',
              double: '/content/products/hydro-essence-mask-2240.png'
            }
          }
        ]
      }
    ],
    quantity: 34,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: true,
    inBasket: true,
    createDate: {
      humanized: '5 минут назад',
      formatted: '9 май, 14:02',
      datetime: '2018-05-09T14:02:00'
    }
  }
};

export const clothes = {
  'clothes-my-banana': {
    id: 12,
    slug: 'clothes-my-banana',
    properties: [],
    sku: '150021',
    title: 'My banana',
    description: 'Комплект домашней одежды',
    type: PRODUCT_TYPE.CLOTHES,
    variantOptions: [120, 121, 122, 123, 124, 125, 126],
    variants: [
      {
        id: 131,
        available: false,
        options: [121, 120]
      },
      {
        id: 131,
        available: true,
        price: {
          default: 345,
          current: 345,
          discount: {
            percentage: 0,
            numeral: 0
          }
        },
        options: [123, 125]
      },
      {
        id: 132,
        available: true,
        price: {
          default: 345,
          current: 345,
          discount: {
            percentage: 0,
            numeral: 0
          }
        },
        options: [121, 124]
      },
      {
        id: 133,
        available: true,
        price: {
          default: 345,
          current: 345,
          discount: {
            percentage: 0,
            numeral: 0
          }
        },
        options: [121, 126]
      }
    ],
    series: {
      id: 1,
      title: 'Home clothes'
    },
    category: {
      id: 1,
      title: 'Одежда'
    },
    price: {
      default: 345,
      discount: {
        percentage: 0.1
      }
    },
    quantity: 12,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],

    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '12 минут назад',
      formatted: '17 июня, 12:34',
      datetime: '2018-06-17T12:34:04'
    },
    media: [
      {
        id: 71,
        type: 'IMAGE',
        title: 'MY BANANA',
        caption: 'MY BANANA',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/clothes-1-570.png',
              double: '/content/products/clothes-1-570.png'
            },
            fallback: {
              normal: '/content/products/clothes-1-570.png',
              double: '/content/products/clothes-1-570.png'
            }
          }
        ]
      },
      {
        id: 72,
        type: 'IMAGE',
        title: 'MY BANANA',
        caption: 'MY BANANA',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/clothes-2-570.png',
              double: '/content/products/clothes-2-570.png'
            },
            fallback: {
              normal: '/content/products/clothes-2-570.png',
              double: '/content/products/clothes-2-570.png'
            }
          }
        ]
      },
      {
        id: 73,
        type: 'IMAGE',
        title: 'MY BANANA',
        caption: 'MY BANANA',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/clothes-3-570.png',
              double: '/content/products/clothes-3-570.png'
            },
            fallback: {
              normal: '/content/products/clothes-3-570.png',
              double: '/content/products/clothes-3-570.png'
            }
          }
        ]
      },
      {
        id: 74,
        type: 'IMAGE',
        title: 'MY BANANA',
        caption: 'MY BANANA',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/clothes-4-570.png',
              double: '/content/products/clothes-4-570.png'
            },
            fallback: {
              normal: '/content/products/clothes-4-570.png',
              double: '/content/products/clothes-4-570.png'
            }
          }
        ]
      }
    ]
  }
};

export const cosmetics = {
  'bronze-fantasy-mineral-compact-blush': {
    id: 13,
    slug: 'bronze-fantasy-mineral-compact-blush',
    properties: [],
    sku: '150021',
    title: 'Bronze Fantasy Mineral Compact Blush',
    description: 'Румяна для лица компактные (бронзовый беж)',
    type: PRODUCT_TYPE.COSMETICS,
    series: {
      id: 1,
      title: 'Декоративная косметика'
    },
    category: {
      id: 1,
      title: 'Одежда'
    },
    price: {
      default: 345,
      current: 345,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    media: [
      {
        id: 71,
        type: 'IMAGE',
        title: 'Mineral Compact Blush',
        caption: 'Mineral Compact Blush',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/decor-1-570.jpg',
              double: '/content/products/decor-1-570.jpg'
            },
            fallback: {
              normal: '/content/products/decor-1-570.jpg',
              double: '/content/products/decor-1-570.jpg'
            }
          }
        ]
      },
      {
        id: 72,
        type: 'IMAGE',
        title: 'Mineral Compact Blush',
        caption: 'Mineral Compact Blush',
        sources: [
          {
            size: 570,
            main: {
              normal: '/content/products/decor-2-1200.jpg',
              double: '/content/products/decor-2-1200.jpg'
            },
            fallback: {
              normal: '/content/products/decor-2-1200.jpg',
              double: '/content/products/decor-2-1200.jpg'
            }
          }
        ]
      }
    ],
    quantity: 12,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '12 минут назад',
      formatted: '17 июня, 12:34',
      datetime: '2018-06-17T12:34:04'
    }
  }
};

export const sets = {
  'nabor-dlya-ukroshheniya-stroptivyx-volos': {
    id: 14,
    slug: 'nabor-dlya-ukroshheniya-stroptivyx-volos',
    properties: [],
    sku: '15050',
    title: 'Набор для укрощения строптивых волос',
    description: 'Минеральная биочистка для всех типов кожи лица',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 2125,
      current: 2125,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 23,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: true,
    inBasket: false,
    createDate: {
      humanized: '27 минут назад',
      formatted: '17 июня, 12:27',
      datetime: '2018-06-17T12:27:12'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор для укрощения строптивых волос',
        caption: 'Набор для укрощения строптивых волос, вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор для укрощения строптивых волос',
        caption: 'Набор для укрощения строптивых волос, вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор для укрощения строптивых волос',
        caption: 'Набор для укрощения строптивых волос, вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      }
    ]
  },
  'ekspress-uvlazhnenie-dlya-obezvozhennoj-kozhi': {
    id: 15,
    slug: 'ekspress-uvlazhnenie-dlya-obezvozhennoj-kozhi',
    properties: [],
    sku: '15051',
    title: 'Экспресс увлажнение для обезвоженной кожи',
    description: 'Лифтинг-гель для области бедер и ягодиц',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 1499,
      current: 1499,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 8,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: false,
    inBasket: true,
    createDate: {
      humanized: '5 минут назад',
      formatted: '23 августа, 17:27',
      datetime: '2018-08-23T17:27:40'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор "Экспресс увлажнение для обезвоженной кожи"',
        caption: 'Набор "Экспресс увлажнение для обезвоженной кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор "Экспресс увлажнение для обезвоженной кожи"',
        caption: 'Набор "Экспресс увлажнение для обезвоженной кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор "Экспресс увлажнение для обезвоженной кожи"',
        caption: 'Набор "Экспресс увлажнение для обезвоженной кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      }
    ]
  },
  'svezhest-i-glubokoe-uvlazhnenie-kozhi': {
    id: 16,
    slug: 'svezhest-i-glubokoe-uvlazhnenie-kozhi',
    properties: [],
    sku: '15052',
    title: 'Свежесть и глубокое увлажнение кожи',
    description: 'Масло для питания ногтей и кутикулы',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 1499,
      current: 1499,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 2,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '5 минут назад',
      formatted: '17 августа, 17:27',
      datetime: '2018-08-17T17:27:40'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор "Свежесть и глубокое увлажнение кожи"',
        caption: 'Набор "Свежесть и глубокое увлажнение кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор "Свежесть и глубокое увлажнение кожи"',
        caption: 'Набор "Свежесть и глубокое увлажнение кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор "Свежесть и глубокое увлажнение кожи"',
        caption: 'Набор "Свежесть и глубокое увлажнение кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      }
    ]
  },
  'svezhest-i-molodost-kozhi': {
    id: 17,
    slug: 'svezhest-i-molodost-kozhi',
    properties: [],
    sku: '15053',
    title: 'Свежесть и молодость кожи',
    description: 'Тонизирующий шампунь для всех типов волос',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 399,
      current: 399,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 23,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: true,
    inBasket: true,
    createDate: {
      humanized: '5 минут назад',
      formatted: '10 декабря, 18:23',
      datetime: '2017-12-10T18:23:40'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор "Свежесть и молодость кожи"',
        caption: 'Набор "Свежесть и молодость кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор "Свежесть и молодость кожи"',
        caption: 'Набор "Свежесть и молодость кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор "Свежесть и молодость кожи"',
        caption: 'Набор "Свежесть и молодость кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      }
    ]
  },
  'barxatnaya-matovost-i-nezhnost-kozhi': {
    id: 18,
    slug: 'barxatnaya-matovost-i-nezhnost-kozhi',
    properties: [],
    sku: '15054',
    title: 'Бархатная матовость и нежность кожи',
    description: 'Тонизирующий шампунь для всех типов волос',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 399,
      current: 299,
      discount: {
        percentage: 25,
        numeral: 100
      }
    },
    hasDiscount: true,
    quantity: 17,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: true,
    inBasket: false,
    createDate: {
      humanized: '2 часа назад',
      formatted: '17 октября, 22:23',
      datetime: '2017-10-17T22:23:12'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор "Бархатная матовость и нежность кожи"',
        caption: 'Набор "Бархатная матовость и нежность кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор "Бархатная матовость и нежность кожи"',
        caption: 'Набор "Бархатная матовость и нежность кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор "Бархатная матовость и нежность кожи"',
        caption: 'Набор "Бархатная матовость и нежность кожи", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-2.png',
              double: '/content/gift-sets/gift-set-2@2.png'
            }
          }
        ]
      }
    ]
  },
  'modeliruyushhij-duet-dlya-beder-i-yagodic': {
    id: 19,
    slug: 'modeliruyushhij-duet-dlya-beder-i-yagodic',
    properties: [],
    sku: '15055',
    title: 'Моделирующий дуэт для бедер и ягодиц',
    description: 'Шампунь-активатор роста волос с перцем',
    type: PRODUCT_TYPE.SET,
    series: null,
    category: {
      id: 5,
      title: 'Подарочные наборы'
    },
    price: {
      default: 799,
      current: 799,
      discount: {
        percentage: 0,
        numeral: 0
      }
    },
    quantity: 4,
    availability: true,
    features: [
      {
        type: 'FEATURE/SALE',
        title: 'sale',
        description: 'Данный товар предоставляется со скидкой'
      },
      {
        type: 'FEATURE/NEW',
        title: 'new',
        description: 'Данный товар поступил в продажу недавно'
      },
      {
        type: 'FEATURE/POPULAR',
        title: 'popular',
        description: 'Данный товар находится в разделе "Популярные"'
      }
    ],
    variantOptions: null,
    variants: null,
    inFavorite: false,
    inBasket: false,
    createDate: {
      humanized: '3 часа назад',
      formatted: '18 октября, 00:04',
      datetime: '2017-10-18T00:04:12'
    },
    media: [
      {
        id: 1,
        type: 'IMAGE',
        title: 'Набор "Моделирующий дуэт для бедер и ягодиц"',
        caption: 'Набор "Моделирующий дуэт для бедер и ягодиц", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 2,
        type: 'IMAGE',
        title: 'Набор "Моделирующий дуэт для бедер и ягодиц"',
        caption: 'Набор "Моделирующий дуэт для бедер и ягодиц", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      },
      {
        id: 3,
        type: 'IMAGE',
        title: 'Набор "Моделирующий дуэт для бедер и ягодиц"',
        caption: 'Набор "Моделирующий дуэт для бедер и ягодиц", вид сверху',
        sources: [
          {
            size: 280,
            main: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            },
            fallback: {
              normal: '/content/gift-sets/gift-set-1.png',
              double: '/content/gift-sets/gift-set-1@2.png'
            }
          }
        ]
      }
    ]
  }
};

export const presentPackes = {
  'paket-s-prorubnoy-ruchkoy': {
    id: 303532,
    slug: 'paket-s-prorubnoy-ruchkoy',
    name: 'paket-s-prorubnoy-ruchkoy',
    title: 'Пакет',
    category: {
      id: 275,
      name: 'present-pack',
      slug: 'present-pack',
      title: 'Подарочная упаковка',
      description: '',
      amount: 5
    },
    sku: 303532,
    features: [],
    rating: {
      reviews_count: 1
    },
    price: {
      default: 55,
      discount: {
        percentage: 0,
        numerical: 0
      }
    },
    announcementMedia: {
      id: 146588,
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Пакет с прорубной ручкой',
      sources: [
        {
          dimensions: {
            width: 1200,
            height: 1200
          },
          main: {
            normal: 'https://api.mixit.ru/upload/iblock/0d8/0d8783f5da103d203b0e4550a4373483.jpg'
          },
          fallback: {
            normal: 'https://api.mixit.ru/upload/iblock/0d8/0d8783f5da103d203b0e4550a4373483.jpg'
          }
        }
      ]
    },
    createDate: '2017-12-12T12:39:58',
    description: 'Пакет с прорубной ручкой',
    media: [
      {
        id: 146589,
        type: 'MEDIA_TYPE/IMAGE',
        title: 'Пакет с прорубной ручкой',
        sources: [
          {
            dimensions: {
              width: 1200,
              height: 1200
            },
            main: {
              normal: 'https://api.mixit.ru/upload/iblock/ddc/ddc55bf68c0a582c27e542bd179a7b9c.jpg'
            },
            fallback: {
              normal: 'https://api.mixit.ru/upload/iblock/ddc/ddc55bf68c0a582c27e542bd179a7b9c.jpg'
            }
          }
        ],
        source: [
          {
            dimensions: {
              width: 1200,
              height: 1200
            },
            main: {
              normal: 'https://api.mixit.ru/upload/iblock/ddc/ddc55bf68c0a582c27e542bd179a7b9c.jpg'
            },
            fallback: {
              normal: 'https://api.mixit.ru/upload/iblock/ddc/ddc55bf68c0a582c27e542bd179a7b9c.jpg'
            }
          }
        ]
      }
    ],
    properties: [],
    variantOptions: [48],
    variantOptionsAll: [
      {
        id: 48,
        name: 'VARIANT_OPTION/WEIGHT',
        title: 'Объем/вес',
        value: '5',
        payload: {
          unit: 'г.'
        }
      }
    ],
    inBasket: false,
    isAvailable: true,
    inFavorite: false
  }
};

export default {
  ...clothes,
  ...cosmetics,
  ...sets,
  ...presentPackes
};
