import types from './types';

function selectCity(id) {
  return {
    type: types.SELECT_CITY,
    payload: {
      id
    }
  };
}

function closeCityDialog() {
  return {
    type: types.CLOSE_CITY_DIALOG
  };
}

function openCityDialog() {
  return {
    type: types.OPEN_CITY_DIALOG
  };
}

function openCityDropdown() {
  return {
    type: types.OPEN_CITY_DROPDOWN
  };
}

function closeCityDropdown() {
  return {
    type: types.CLOSE_CITY_DROPDOWN
  };
}

export default {
  selectCity,
  closeCityDialog,
  openCityDialog,
  openCityDropdown,
  closeCityDropdown
};
