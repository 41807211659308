import { takeEvery, select, put, call } from 'redux-saga/effects';

import * as fromAccount from 'modules/core/ducks/account';
import * as fromPhysicalTraits from '../ducks/physical-traits';

import { mixitApi } from 'services/mixit';
import * as TYPES from '../types/profile';
import Actions from '../actions/profile';

const ERROR_MESSAGE = 'К сожалению, данные не были сохранены, попробуйте сохранить снова';

export function* physicalTraitsWatcher() {
  yield takeEvery(TYPES.PHYSICAL_TRAITS_SHOW, requestPhysicalTraits);
  yield takeEvery(TYPES.PHYSICAL_TRAITS_CHANGE, changePhysicalTrait);
}

function* requestPhysicalTraits() {
  const authorization = yield select(fromAccount.getAuthorization);

  try {
    const response = yield call(
      mixitApi(authorization)
        .profile()
        .physicalTraits().request
    );

    yield put(Actions.physicalTraitsInitialResponse(response.data));
  } catch (error) {
    console.warn('requestPhysicalTraitsERROR', error);
  }
}

function* changePhysicalTrait({ payload }) {
  const authorization = yield select(fromAccount.getAuthorization);
  const checkedValues = yield select(fromPhysicalTraits.getCheckedValues);

  let response;

  yield put(Actions.physicalTraitsTemporarySave(payload));

  for (let index in payload) {
    const option = payload[index];

    if (checkedValues.indexOf(option.value) === -1) {
      try {
        response = yield call(
          mixitApi(authorization)
            .profile()
            .physicalTraits(getSerializedData(option)).add
        );
      } catch (error) {
        console.warn('addPhysicalTraitERROR', error);

        yield put(Actions.physicalTraitsError(ERROR_MESSAGE));
      }
    }
  }
  if (!response) {
    yield put(Actions.physicalTraitsResetChangesLoading());
    return;
  }
  yield put(Actions.physicalTraitsResponse(response.data));
}

function getSerializedData(option) {
  return {
    ...option,
    value: { value: option.value }
  };
}
