import React from 'react';
// import { prefix } from 'inline-style-prefixer';

// import Container from 'modules/core/components/container/container';
import OpeningActions from './opening-actions/opening-actions';
import ActionsGift from './actions-gifts/actions-gifts';
// import LandingSectionTitle from './landing-section-title/landing-section-title';
import LandingProducts from './landing-products/landing-products';
import LandingBanner from './landing-banner/landing-banner';
import LandingBG from './landing-bg/landing-bg';
import Activities from './activities/activities';
import BottomBanner from './bottom-banner/bottom-banner';
import OpeningTimetable from './opening-timetable/opening-timetable';
import OpeningShopsBanner from './opening-shops-banner/opening-shops-banner';
import DesctiptionSection from './desctiption_section/desctiption_section';
import TitleSection from './title-section/title-section';
import PlainText from './plain-text/plain-text';
import Html from './html/html';
import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';
import getNormalizedRenderConditions from 'modules/core/components/conditional-renderer/helpers/get-normalized-render-conditions';

import getContainer from './get-container';

import './landing.css';

const components = {
  top_banner: LandingBanner,
  opening_shops_banner: OpeningShopsBanner,
  bottom_banner: BottomBanner,
  gifts: ActionsGift,
  rules: OpeningActions,
  group: LandingProducts,
  opening_timetable: OpeningTimetable,
  activity: Activities,
  bottom_info: ButtomInfo,
  desctiption_section: DesctiptionSection,
  title_section: TitleSection,
  plain_text: PlainText,
  html: Html
};

function Landing({ data }) {
  if (!data) {
    return null;
  }

  return (
    <div className="Landing">
      {renderBG()}
      {renderContent()}
    </div>
  );

  function renderBG() {
    const landingStyle = { background: data.background.gradient };
    const bgsCounter = new Array(15).fill('');

    return (
      <div className="Landing-BG" style={landingStyle}>
        {bgsCounter.map((_, index) => (
          <LandingBG key={index} style={data.background.style} />
        ))}
      </div>
    );
  }

  function renderContent() {
    // if (Boolean(data.components)) {
    return <div className="Landing-content">{renderComponentsByType(data)}</div>;
    // }

    // return (
    //   <div className="Landing-content">
    //     <OpeningShopsBanner
    //       data={data.opening_shops_banner}
    //       colors={data.colors}
    //       light={data.light}
    //     />
    //     <OpeningTimetable data={data.opening_timetable} colors={data.colors} light={data.light} />
    //     <LandingBanner data={data.top_banner} colors={data.colors} light={data.light} />
    //     <ActionsGigt data={data.gifts} colors={data.colors} light={data.light} />
    //     <OpeningActions data={data.rules} colors={data.colors} light={data.light} />
    //     <LandingProducts data={data.group} light={data.light} />
    //     <Activities data={data.activity} colors={data.colors} light={data.light} />
    //     <BottomBanner data={data.bottom_banner} colors={data.colors} light={data.light} />
    //     <ButtomInfo data={data.bottom_info} colors={data.colors} light={data.light} />
    //   </div>
    // );
  }

  function normalizeComponentData(data) {
    const { type = null, show = true, hasContainer = false } = data;
    return { type, show, hasContainer, ...data };
  }

  function renderComponentsByType(data) {
    return data.components.map((componentProps, index) => {
      const { colors, light } = data;

      const _componentProps = normalizeComponentData(componentProps);

      if (!_componentProps.type) {
        return null;
      }
      const componentType = _componentProps.type.toLowerCase().trim();
      const Component = components[componentType];
      if (typeof Component === 'undefined') {
        return null;
      }

      if (!_componentProps.show) {
        return null;
      }

      const [Container, containerProps] = getContainer(_componentProps);

      const conditionalConfig = {
        elseRender: null,
        ...getNormalizedRenderConditions(_componentProps.render_conditions)
      };

      return (
        <ConditionalRenderer key={index} config={conditionalConfig}>
          <div style={_componentProps.wraper_style}>
            <Container {...containerProps}>
              <Component data={_componentProps} totalData={data} colors={colors} light={light} />
            </Container>
          </div>
        </ConditionalRenderer>
      );
    });
  }
}

function ButtomInfo({ data }) {
  return <div className="Landing-bottomInfo">{data.text}</div>;
}

export default Landing;
