import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { getIndexLink } from 'routes/links';

import Page from 'modules/core/components/page/page';
import Container from 'modules/core/components/container/container';

import GiftSertifikatRulesSection from '../components/gift-sertifikat-section/gift-sertifikat-section';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from '../components/section-default/section-default';

class GiftSertifikatRulesPage extends Component {
  static path = '/gift-sertifikat-rules';
  static link = () => 'gift-sertifikat-rules';
  static exact = true;

  render() {
    return (
      <Page
        title={
          <Container>
            Правила приобретения и использования физическими лицами подарочных сертификатов
          </Container>
        }
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Правила приобретения и использования физическими лицами подарочных сертификатов'
          }
        ]}
      >
        <Helmet>
          <title>Правила приобретения и использования подарочных сертификатов MIXIT</title>

          <meta
            name="description"
            content="Правила приобретения и использования физическими лицами подарочных сертификатов в интернет-магазине Миксит."
          />
        </Helmet>

        <GiftSertifikatRulesSection />
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default GiftSertifikatRulesPage;
