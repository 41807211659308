import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Container from 'modules/core/components/container/container';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
import PasswordInput from 'modules/form/components/password-input/password-input';
import Button from 'modules/core/components/button/button';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import Link from 'modules/core/components/link/short-link';

import './password-change-scene.css';

// Regex Letters, Numbers, Dashes, and Underscores
const LATIN_AND_NUMBERS = /([A-Za-z0-9\-_]+)/;

// const LATIN_REGEX = /[a-zA-Z]/;

// for more complicated passwords:
// Minimum eight characters, at least one letter and one number:
// "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"

// Minimum eight characters, at least one letter, one number and one special character:
// "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"

// Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"

const PASSWORD_REQUIRED_ERROR = 'Пароль является обязательным полем';
const PASSWORD_SHORT_ERROR = 'Используйте пароль не менее 6-ти символов';
const PASSWORD_NO_LATIN_ERROR = 'Пароль может содержать только латинские символы и цифры';
const PASSWORDS_NOT_EQUAL = 'Пароли не совпадают';
const CURRENT_AND_NEW_PASSWORDS_ARE_EQUAL = 'Текущий и новый пароли не должны совпадать';

const credentialsSchema = Yup.object().shape({
  current_password: Yup.string()
    .required(PASSWORD_REQUIRED_ERROR)
    .min(6, PASSWORD_SHORT_ERROR)
    .matches(LATIN_AND_NUMBERS, PASSWORD_NO_LATIN_ERROR),
  new_password: Yup.string()
    .trim()
    .notOneOf([Yup.ref('current_password')], CURRENT_AND_NEW_PASSWORDS_ARE_EQUAL)
    .required(PASSWORD_REQUIRED_ERROR)
    .min(6, PASSWORD_SHORT_ERROR)
    .matches(LATIN_AND_NUMBERS, PASSWORD_NO_LATIN_ERROR),
  confirm_new_password: Yup.string()
    .trim()
    .oneOf([Yup.ref('new_password')], PASSWORDS_NOT_EQUAL)
    .required(PASSWORD_REQUIRED_ERROR)
});

class PasswordChangeScene extends Component {
  render() {
    const { errorMessage, isFetching } = this.props;

    const initialValues = {
      current_password: '',
      new_password: '',
      confirm_new_password: ''
    };

    return (
      <Container>
        <Formik
          validationSchema={credentialsSchema}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <div className="PasswordChangeScene">
                <div className="PasswordChangeScene-header">
                  <h1 className="PasswordChangeScene-title">Смена пароля</h1>
                  {!!errorMessage && (
                    <div className="PasswordChangeScene-errorMessage">{errorMessage}</div>
                  )}
                </div>

                <div className="PasswordChangeScene-form">
                  <Form onSubmit={handleSubmit}>
                    <Media query="(max-width: 840px)">
                      {isMobile => (
                        <Fragment>
                          <Form.LabeledRow>
                            <Field
                              label="Текущий пароль"
                              error={touched.current_password && errors.current_password}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <PasswordInput
                                name="current_password"
                                value={values.current_password}
                                state={
                                  touched.current_password && errors.current_password && 'error'
                                }
                                onChange={handleChange}
                                onFocus={this.handleCurrentPasswordFocus}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                disabled={isFetching}
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field
                              label="Новый пароль"
                              error={touched.new_password && errors.new_password}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <PasswordInput
                                name="new_password"
                                value={values.new_password}
                                state={touched.new_password && errors.new_password && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                disabled={isFetching}
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field
                              label="Подтверждение нового пароля"
                              error={touched.confirm_new_password && errors.confirm_new_password}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <PasswordInput
                                name="confirm_new_password"
                                value={values.confirm_new_password}
                                state={
                                  touched.confirm_new_password &&
                                  errors.confirm_new_password &&
                                  'error'
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                disabled={isFetching || values.new_password.length < 6}
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.Row>
                            <Field>
                              <Button
                                title="Сменить пароль"
                                variant="primary"
                                type="submit"
                                size={isMobile ? 'small' : 'normal'}
                                // expanded
                                disabled={isFetching}
                                iconAppend={isFetching && <IconFlatLoader />}
                              />
                            </Field>
                          </Form.Row>
                          <Form.Row>
                            <Field>
                              <Link
                                component="button"
                                type="button"
                                onClick={this.handleRecoverPassword}
                              >
                                Не помню свой пароль
                              </Link>
                            </Field>
                          </Form.Row>
                        </Fragment>
                      )}
                    </Media>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Container>
    );
  }

  handleSubmit = data => {
    const { onSubmit } = this.props;

    const requestData = {
      currentPassword: data.current_password,
      newPassword: data.new_password
    };

    onSubmit(requestData);
  };

  handleCurrentPasswordFocus = () => {
    const { resetErrorMessage } = this.props;
    resetErrorMessage();
  };

  handleRecoverPassword = () => {
    const { openRecoveryPasswordDialog } = this.props;
    openRecoveryPasswordDialog();
  };
}

export default PasswordChangeScene;
