import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DottedList from 'modules/core/components/dotted-list/dotted-list';

import './order-details.css';

import { chunkArrayInGroups, getCols, amountItemsInCol } from './helpers';
import formatStreet from '../../delivery-summary/helpers/format-street';

const { string, number, arrayOf, shape } = PropTypes;

class OrderDetails extends Component {
  static propTypes = {
    cols: number,
    list: arrayOf(
      shape({
        title: string,
        value: string,
        importantInfo: string
      })
    )
  };

  static defaultProps = {
    cols: 2
  };

  render() {
    const order = this.getOrderDetails();
    const cols = getCols(this.props.cols);
    const chunkedList = chunkArrayInGroups(order, amountItemsInCol(order, cols));

    return (
      <div className={cn('OrderDetails', { 'OrderDetails--oneCol': cols === 1 })}>
        {chunkedList.map(
          (item, index) =>
            item && (
              <div key={index} className="OrderDetails-item">
                <DottedList list={item} table highlightedTitle />
              </div>
            )
        )}
      </div>
    );
  }

  getOrderDetails() {
    const { delivery } = this.props;

    const defaultDetails = this.getDefaultDetails();
    const trackingNumber = defaultDetails.trackingNumber ? [defaultDetails.trackingNumber] : [];
    const deliveryCompanyTitle = defaultDetails.deliveryCompanyTitle
      ? [defaultDetails.deliveryCompanyTitle]
      : [];

    if (delivery.pickPoint) {
      const pickPointDetails = this.getPickPointDetails();

      return [
        ...defaultDetails.entry,
        ...deliveryCompanyTitle,
        ...trackingNumber,
        ...pickPointDetails,
        defaultDetails.payment
      ];
    }

    const address = {
      title: 'Адрес доставки',
      value: (
        <Fragment>
          {delivery.geography.city.title}
          {delivery.geography.address.raw && (
            <Fragment>
              , {delivery.geography.address.raw}
              {delivery.geography.address.apartment && (
                <nobr>, кв. {delivery.geography.address.apartment}</nobr>
              )}
            </Fragment>
          )}
        </Fragment>
      )
    };

    if (delivery.recipientName) {
      return [
        ...defaultDetails.entry,
        ...deliveryCompanyTitle,
        ...trackingNumber,
        address,
        {
          title: 'Имя получателя',
          value: delivery.recipientName
        },
        defaultDetails.payment
      ];
    }

    return [
      ...defaultDetails.entry,
      ...deliveryCompanyTitle,
      ...trackingNumber,
      address,
      defaultDetails.payment
    ];
  }

  getDefaultDetails() {
    const { delivery, personalInfo, payment, order } = this.props;

    const defaultDetails = { entry: [] };

    if (personalInfo.firstName) {
      defaultDetails.entry.push({
        title: 'Контактное лицо',
        value: personalInfo.firstName
      });
    }

    if (personalInfo.phone) {
      defaultDetails.entry.push({
        title: 'Конатактный номер',
        value: personalInfo.phone
      });
    }

    if (personalInfo.email) {
      defaultDetails.entry.push({
        title: 'Email',
        value: personalInfo.email
      });
    }

    if (delivery.deliveryMethod.title || delivery.deliveryMethod.additionalInfo) {
      defaultDetails.entry.push({
        title: 'Доставка',
        value: (
          <Fragment>
            {delivery.deliveryMethod.title}{' '}
            {delivery.deliveryMethod.additionalInfo && (
              <b>({delivery.deliveryMethod.additionalInfo})</b>
            )}
          </Fragment>
        )
      });
    }

    if (payment.paymentMethod.title) {
      defaultDetails.payment = {
        title: 'Способ оплаты',
        value: payment.paymentMethod.title
      };
    }

    if (order && order.trackingNumber) {
      defaultDetails.trackingNumber = {
        title: 'Трек-номер',
        value: order.trackingNumber
      };
    }
    if (order && order.deliveryCompanyTitle) {
      defaultDetails.deliveryCompanyTitle = {
        title: 'Транспортная компания',
        value: order.deliveryCompanyTitle
      };
    }

    return defaultDetails;
  }

  getPickPointDetails() {
    const { delivery } = this.props;

    const title = {
      title: 'Пункт самовывоза',
      value: delivery.pickPoint.title
    };

    const address = {
      title: 'Адрес',
      value: this.getPickPointAddress()
    };

    const phone = {
      title: 'Номер получателя',
      value: delivery.recipientPhone
    };

    return [title, address, phone];
  }

  getPickPointAddress() {
    const { delivery } = this.props;

    const street = formatStreet(delivery.pickPoint.geography.address.street_title);

    const city = (delivery.geography.city && delivery.geography.city.title) || '';

    const building = delivery.pickPoint.geography.address.building;

    return (
      <Fragment>
        {city && `${city}, `}
        {street}, <nobr>{building}</nobr>
      </Fragment>
    );
  }
}

export default OrderDetails;

// const street = formatStreet(
//   selectedPickPoint.geography.address.street_title
// );

// const building = selectedPickPoint.geography.address.building;

// return `${street}, ${building}`;
