import React, { Component } from 'react';
import { Sticky } from 'react-sticky';
import classNames from 'classnames';
import Tabs from 'modules/core/components/tabs/tabs';
import TabsItem from 'modules/core/components/tabs/tabs-item';

import './happy-master-landing.css';

class HappyNavigation extends Component {
  render() {
    return (
      <section className="HappyMasterNavigation">
        <Sticky topOffset={30}>{this.renderNav}</Sticky>
      </section>
    );
  }

  renderNav = ({ style, isSticky, wasSticky }) => {
    const NAV_HEIGHT = 70;

    return (
      <div
        className={classNames('HappyMasterNavigation-fullContainer', {
          'HappyMasterNavigation-fullContainer--isSticky': isSticky,
          'HappyMasterNavigation-fullContainer--wasSticky': wasSticky
        })}
        style={style}
      >
        <div className="HappyMasterNavigation-container">
          <nav className="HappyMasterNavigation-nav">
            <Tabs nav>
              <TabsItem
                name="whoIAm"
                offset={-NAV_HEIGHT}
                title="Кто я?"
                defaultActive={!isSticky}
              />

              <TabsItem
                name="problemsAndSolution"
                offset={-NAV_HEIGHT - 30}
                title="Проблемы, которые я решаю"
              />

              <TabsItem name="video" offset={-NAV_HEIGHT} title="Видео приветствие" />

              <TabsItem name="feedback" offset={-NAV_HEIGHT} title="Связаться" />
            </Tabs>
          </nav>
        </div>
      </div>
    );
  };
}

export default HappyNavigation;
