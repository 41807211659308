import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeaturedArticlesSection from '../components/featured-articles-section/featured-articles-section';

import * as fromFeaturedArticleList from '../ducks/featured-article-list';
import { Magazine } from '../actions';

const mapState = createStructuredSelector({
  articleList: fromFeaturedArticleList.getList,
  isLoading: fromFeaturedArticleList.getIsLoading
});

const mapActions = {
  requestMagazineHome: Magazine.homeRequest
};

class FeaturedArticlesSectionContainer extends Component {
  render() {
    return <FeaturedArticlesSection {...this.props} />;
  }

  update(props) {
    props.requestMagazineHome();
  }

  componentDidMount() {
    this.update(this.props);
  }
}

export default connect(
  mapState,
  mapActions
)(FeaturedArticlesSectionContainer);
