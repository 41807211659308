export default [
  {
    title: 'Общие положения',
    list: [
      'Настоящий документ устанавливает порядок приобретения и использования Подарочных сертификатов в Магазинах',
      'Настоящее Соглашение регулирует отношения между Администрацией Интернет-магазина «MIXIT», размещенном на сайте mixit.ru (далее – Администрация сайта) и Пользователем данного Сайта.',
      {
        title:
          'Лицо (Покупатель) выражает согласие с изложенными ниже условиями настоящих Правил путем:',
        list: [
          'внесения Продавцу денежных средств (предоплаты за Товар), в подтверждение принятия которых Продавцом выдается Подарочный сертификат, а также кассовый чек, удовлетворяющий требованиям действующего законодательства Российской Федерации (далее по тексту «Чек»), либо',
          'предъявления Продавцу Подарочного сертификата, либо указание номера ВПС на Сайте в счет оплаты всей/части стоимости Товара.'
        ],
        unordered: true
      },
      {
        title: 'В настоящих Правилах нижеприведенные термины имеют следующие значения: ',
        list: [
          '<b>«Продавец»</b> – юридическое лицо либо индивидуальный предприниматель, осуществляющее(ий) деятельность по розничной продаже Товара и реализации Подарочных сертификатов под товарным знаком «MIXIT».',
          '<b>«Магазин»</b> - магазин под товарным знаком «MIXIT», в котором Продавец осуществляет деятельность по розничной продаже Товара и реализации Подарочных сертификатов.',
          '<b>«Правила»</b> - настоящий документ, регламентирующий порядок приобретения и использования Подарочных сертификатов.',
          '<b>«Покупатель»</b> - физическое лицо, получившее Подарочный сертификат.',
          '<b>«Подарочный сертификат»</b> - неименной документ, выпускаемый в форме пластиковой карты, либо в электронной форме - «Виртуальный Подарочный сертификат», «ВПС», содержащий уникальные реквизиты (номер), удостоверяющий факт внесения денежных средств в размере Номинала. Номер Подарочного сертификата указывается на самом сертификате.',
          '<b>«Номинал»</b> – вносимые Покупателем денежные средства в размере, определяемом Продавцом, являющиеся предоплатой за Товар, в отношении которого Покупатель в будущем может заключить договор купли-продажи. Номинал Подарочного сертификата указывается в Чеке и на самом Подарочном сертификате.',
          '<b>«Товар»</b> – любая парфюмерно-косметическая продукция и аксессуары под товарным знаком «MIXIT», реализуемая в Магазинах.',
          '<b>«Срок действия Подарочного сертификата»</b> - период времени, в течение которого Номинал Подарочного сертификата может быть зачтен при оплате Товаров. Срок действия Подарочного сертификата не ограничен',
          '<b>«Интернет-магазин»</b> – интернет сайт, находящийся по адресу https://mixit.ru/.'
        ],
        unordered: true
      }
    ]
  },
  {
    title: 'Порядок приобретения Подарочного сертификата.',
    list: [
      'Продавец обязуется принять Номинал Подарочного сертификата, в подтверждение чего выдать Подарочный сертификат и Чек, а также засчитать в период Срока действия Подарочного сертификата Номинал Подарочного сертификата в счет оплаты Товара при обращении Покупателя в Магазин либо через Интернет-магазин, а Покупатель обязуется оплатить Продавцу Номинал Подарочного сертификата и использовать Подарочный сертификат в течение Срока его действия.'
    ]
  },
  {
    title: 'Общие условия использования Подарочных сертификатов.',
    list: [
      'Номинал Подарочного сертификата является предварительной оплатой за Товар, который может быть приобретен Покупателем в Магазинах либо через Интернет-магазин. Подарочный сертификат подтверждает факт оплаты стоимости Товара/его части на сумму, равную Номиналу Подарочного сертификата.',
      {
        title:
          'Использование Подарочного сертификата на условиях настоящих Правил возможно в течение всего Срока действия Подарочного сертификата. Факт неиспользования Покупателем Подарочного сертификата в течение Срока действия означает односторонний отказ Покупателя от заключения договора купли-продажи Товара с условием оплаты его стоимости (части его стоимости) за счет Номинала Подарочного сертификата, а также ведет к:',
        list: [
          'прекращению обязательств Продавца по отношению к Покупателю, вытекающих из Подарочного сертификата;',
          'признанию Номинала Подарочного сертификата доходом Продавца.'
        ],
        unordered: true
      },
      'Номинал Подарочного сертификата не подлежит выплате денежными средствами как в наличной, так и в безналичной форме (с учетом п. 4.4 настоящих Правил).',
      'Восстановление Подарочного сертификата, в том числе в случае кражи, утери, не производится.'
    ]
  },
  {
    title: 'Порядок использования Подарочного сертификата.',
    list: [
      {
        title:
          'Продавец засчитывает Номинал Подарочного сертификата в счет оплаты Товара, приобретаемого Покупателем в Магазине либо через Интернет-магазин, при соблюдении следующих условий:',
        list: [
          'Для оплаты стоимости Товара при совершении покупки в Магазине может быть использован только Подарочный сертификат, выданный в Магазине. При оплате совершении покупки Товара на Сайте к оплате может быть принят только Подарочный сертификат, полученный Покупателем на Сайте.',
          'Покупатель вправе для оплаты суммы по одному Чеку в Магазине предъявить один или несколько Подарочных сертификатов (Номиналы Подарочных сертификатов суммируются). При оплате стоимости заказа на Сайте может быть использован только один Подарочный сертификат;',
          'в случае приобретения Товара на сумму, меньшую Номинала Подарочного сертификата, разница между стоимостью Товара и Номиналом Подарочного сертификата Покупателю не возвращается и признается доходом Продавца;',
          {
            title:
              'в случае приобретения Товара на сумму, превышающую Номинал Подарочного сертификата, разница между суммой стоимости Товара и Номиналом Подарочного сертификата доплачивается Покупателем одним из следующих способов:',
            list: [
              ' путем совершения операции по оплате Товара с использованием банковской карты в порядке, установленном действующим законодательством Российской Федерации и договором между кредитной организацией, эмитировавшей банковскую карту, и Покупателем;',
              'путем внесения наличных денежных средств в кассу Магазина;'
            ],
            unordered: true
          },
          'при приобретении Товара через Интернет-магазин Номинал Подарочного сертификата может быть зачтен в счет оплаты за Товар только при условии безналичной предоплаты заказа на Сайте.'
        ]
      },
      'В момент оплаты Товаров в Магазине с использованием Подарочного сертификата, сотрудник Продавца изымает у Покупателя Подарочный сертификат. Подарочный сертификат после оплаты считается использованным и погашенным.',
      'В случае заявления Покупателем требования о расторжении договора купли-продажи Товара (всех либо части единиц Товара по Чеку), оплата/частичная оплата которого произведена при помощи Подарочного сертификата, и возврате денежных средств по основаниям, предусмотренным действующим законодательством, возврат денежных средств за Товар осуществляется в соответствии с требованиями действующего законодательства Российской Федерации. Возврат денежных средств осуществляется в размере соответствующей суммы, уплаченной Покупателем за Товар, отказ от которого заявлен Покупателем, с учетом оплаты при помощи Подарочного сертификата.',
      'Денежные средства по неиспользованному Подарочному сертификату, независимо от Срока действия, не подлежат возврату.'
    ]
  },
  {
    title: 'Права и обязанности Сторон.',
    list: [
      'Покупатель вправе передать Подарочный сертификат другому физическому лицу на безвозмездной основе. Взимание денежных средств при передаче Подарочных сертификатов любым лицом, не являющимся Продавцом, запрещено. Передача Подарочного сертификата допускается при условии предварительного ознакомления лица, которому передается Подарочный сертификат, с условиями настоящих Правил. Использование таким лицом Подарочного сертификата подтверждает ознакомление и согласие его с условиями Правил.',
      'Продавец вправе отказать Покупателю в зачете Номинала Подарочного сертификата в счет оплаты Товара. В данном случае стороны обязуются действовать в порядке, предусмотренном в разделе 6 настоящих Правил.',
      'При внесении изменений в настоящие Правила доведение новой редакции Правил до сведения потребителей осуществляется путем размещения текста новой редакции Правил в Магазинах, а также через Службу поддержки.</br> В отношении Подарочных сертификатов, приобретенных/полученных Покупателем в рамках Правил, действующей до вступления в силу новой редакции, применяются условия использования Подарочных сертификатов действующей на момент приобретения/получения сертификата редакции Правил.'
    ]
  },
  {
    title:
      'Порядок взаимодействия Сторон в случае отказа Продавца зачесть Номинал Подарочного сертификата в счет оплаты Товара.',
    list: [
      {
        title:
          ' В случае отказа Продавца в зачете Номинала Подарочного сертификата в счет оплаты Товара по причинам, указанным в п. 5.2. настоящих Правил, Покупатель должен незамедлительно сообщить об этом одним из следующих способов:',
        list: [
          {
            title: 'позвонив в Службу поддержки, ежедневно с 09:00 до 21:00, по следующим номерам:',
            list: [
              '<a href="tel:8 (800) 550-98-70">8 (800) 550-98-70</a> - для звонков по всей территории Российской Федерации;',
              '<a href="tel:8 (820) 00718851">8 (820) 00718851</a> - для звонков по любым вопросам на территории Республики Беларусь;'
            ],
            unordered: true
          },
          'сообщив информацию по электронной почте: hello@mixit.ru.'
        ],
        unordered: true
      },
      'При обращении Покупателя на основании п. 6.1 Правил Покупатель должен либо предъявить Подарочный сертификат, либо сообщить номер Подарочного сертификата.',
      'На основании обращения Покупателя проводится проверка, по результатам которой до Покупателя доводится принятое решение и обоснование такого решения.'
    ]
  }
];
