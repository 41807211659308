import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getIsPreviousProductPage } from 'modules/core/ducks/router/selectors';

import Loader from 'modules/core/components/loader/loader';

import Container from 'modules/core/components/container/container';
import Page from 'modules/core/components/page/page';
import ProductGroupProvider from 'modules/product/containers/group-provider';

import { getIndexLink } from 'routes/links';
import sortOptions from 'modules/product/ducks/constants/sort-options';
import Group from '../components/group/group';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

// import SaleCookieSetter from 'modules/core/components/sale-cookie-setter/sale-cookie-setter';

import SectionDefault from '../components/section-default/section-default';

const sortOptionsList = [sortOptions[1], sortOptions[2], sortOptions[4], sortOptions[5]];

const mapStateToProps = createStructuredSelector({
  isPreviousProductPage: getIsPreviousProductPage
});

const mapDispatchToProps = {};

class GroupPage extends Component {
  state = {
    sorting: 1,
    selectedCategories: []
  };

  render() {
    const { slug } = this.props.match.params;
    return (
      <>
        <ProductGroupProvider
          slug={slug}
          sorting={this.state.sorting}
          categories={this.state.selectedCategories}
        >
          {this.renderWithData}
        </ProductGroupProvider>

        {/* <SaleCookieSetter slug={slug} saleSlug="blackfriday-2019" expireDays={3} /> */}
      </>
    );
  }

  renderWithData = props => {
    const { isLoading, group, categories, slug, loadMore, isProductsLoading } = props;
    const productsAmount = group && group.products.length;

    if (isLoading) {
      return (
        <>
          {this.getSSRHelmet(slug)}
          <div style={{ position: 'relative', paddingTop: '120px' }}>
            <Loader />
          </div>
            
        </>
        );
    }

    return (
      <div itemscope="" itemtype="http://schema.org/Product">
        <Page
          title={group.title}
          breadcrumbs={[
            {
              name: 'Главная',
              link: getIndexLink()
            },
            {
              name: group.title
            }
          ]}
          banner={this.getBannerImage(group)}
          bannerTitle={this.getBannerTitle(group)}
          bannerCounter={this.getBannerCounter(group)}
          bannerDescription={this.getBannerDesc(group)}
          description={group.description}
          isGroup
          withoutSaleNotification={slug === 'blackfriday-2019'}
        >
          {this.getHelmet(group)}

          <Container>
            <Group
              sortOptions={sortOptionsList}
              categories={categories}
              selectedCategories={this.state.selectedCategories}
              onSortingChange={this.handleSortingChange}
              onCategoryChange={this.handleCategoryChange}
              products={group.products}
              listId={`group-page/${slug}`}
              onLoadMore={this.handleLoadMore(loadMore, slug, productsAmount)}
              total={group.totalAmount}
              amount={productsAmount}
              isLoading={isProductsLoading}
              microMarkingObject={group.microMarkingObject}
              group={group}
            />
          </Container>
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </Page>
      </div>
    );
  };

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    const { isPreviousProductPage } = this.props;

    if (!isPreviousProductPage) {
      this.scrollWindowToTop();
    }
  }

  handleSortingChange = option => {
    this.setState({
      sorting: option
    });
  };

  handleCategoryChange = (category, checked) => {
    this.setState(({ selectedCategories }) => ({
      selectedCategories: checked
        ? selectedCategories.concat(category)
        : selectedCategories.filter(item => item !== category)
    }));
  };

  handleLoadMore = (loadMore, slug, amount) => () => {
    loadMore(slug, amount);
  };

  getBannerImage(group) {
    return group && group.banner && group.banner.sources && group.banner.sources[0].main.normal;
  }

  getBannerTitle(group) {
    return group && group.banner && group.banner.title;
  }

  getBannerDesc(group) {
    return group && group.banner && group.banner.sources && group.banner.caption;
  }
  getBannerCounter(group) {
    return {
      ...((group && group.endDate) && {endDate: group.endDate})
    }
  }

  getHelmet({ slug, title }) {
    if (!slug || !title) {
      return (
        <Helmet>
          <title>MIXIT - интернет-магазин современной косметики</title>

          <meta
            name="description"
            content="MIXIT - интернет-магазин современной косметики. Производим эффективные средства для ухода за лицом, телом и волосами. Купить с доставкой по Москве и всей России."
          />
        </Helmet>
      );
    }

    if (slug === 'new') {
      return (
        <Helmet>
          <title>Все новинки косметики MIXIT на одной странице</title>

          <meta
            name="description"
            content=" Все новинки косметики MIXIT для ухода за волосами, лицом и телом на одной странице."
          />
        </Helmet>
      );
    }

    if (slug === 'bestsellers') {
      return (
        <Helmet>
          <title>Хиты продаж косметики MIXIT. Выбор наших клиентов</title>

          <meta
            name="description"
            content="Хиты продаж косметики MIXIT. Какие продукты Миксит пользуются успехом среди покупателей. Нам доверяют тысячи клиентов по всей России каждый день."
          />
        </Helmet>
      );
    }

    if (slug === 'gift-sets') {
      return (
        <Helmet>
          <title>Подарочные наборы MIXIT. Купить с доставкой.</title>

          <meta
            name="description"
            content="Подарочные наборы MIXIT. Купить с доставкой по Москве и всей России. Наборы и подарки натуральной косметики от интернет-магазина MIXIT. Найди свой подарок по любой цене!"
          />
        </Helmet>
      );
    }

    return (
      <Helmet>
        <title>{title}</title>

        <meta name="description" content={`${title} - интернет-магазин MIXIT.`} />
      </Helmet>
    );
  }

  getSSRHelmet(slug) {
    if (slug === 'new') {
      return (
        <Helmet>
          <title>Все новинки косметики MIXIT на одной странице</title>

          <meta
            name="description"
            content=" Все новинки косметики MIXIT для ухода за волосами, лицом и телом на одной странице."
          />
        </Helmet>
      );
    }

    if (slug === 'bestsellers') {
      return (
        <Helmet>
          <title>Хиты продаж косметики MIXIT. Выбор наших клиентов</title>

          <meta
            name="description"
            content="Хиты продаж косметики MIXIT. Какие продукты Миксит пользуются успехом среди покупателей. Нам доверяют тысячи клиентов по всей России каждый день."
          />
        </Helmet>
      );
    }

    if (slug === 'gift-sets') {
      return (
        <Helmet>
          <title>Подарочные наборы MIXIT. Купить с доставкой.</title>

          <meta
            name="description"
            content="Подарочные наборы MIXIT. Купить с доставкой по Москве и всей России. Наборы и подарки натуральной косметики от интернет-магазина MIXIT. Найди свой подарок по любой цене!"
          />
        </Helmet>
      );
    }

    return (
      <Helmet>
        <title>MIXIT — интернет-магазин уходовой и декоративной косметики</title>

        <meta
          name="description"
          content={`MIXIT — интернет-магазин уходовой и декоративной косметики.`}
        />
      </Helmet>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
