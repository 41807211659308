import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-odnoklassniki.css';

class IconOdnoklassniki extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconOdnoklassniki">
            <path d="M9.5 19c-.7 1.4.1 2.1 1.9 3.2 1.6 1 3.8 1.3 5.1 1.5-.6.6 2.1-2-5.5 5.3-1.6 1.5 1 4 2.6 2.5l4.3-4.2c1.7 1.6 3.3 3.1 4.3 4.2 1.6 1.5 4.2-.9 2.6-2.5-.1-.1-5.9-5.6-5.5-5.3 1.4-.1 3.6-.5 5.1-1.5 1.9-1.2 2.7-1.8 2-3.2-.4-.8-1.6-1.5-3.1-.3 0 0-2.1 1.6-5.4 1.6-3.3 0-5.4-1.6-5.4-1.6-1.4-1.1-2.6-.5-3 .3z" />
            <path d="M18 18.2c4.1 0 7.4-3.2 7.4-7.1C25.4 7.2 22.1 4 18 4c-4.1 0-7.4 3.2-7.4 7.1 0 3.9 3.3 7.1 7.4 7.1zm0-10.6c2 0 3.6 1.6 3.6 3.5S20 14.6 18 14.6s-3.6-1.6-3.6-3.5S16 7.6 18 7.6z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconOdnoklassniki;
