import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

import mediaShape from 'modules/product/prop-types/media-shape';

import { getArticleLink } from 'routes/links';

import './read-next-article.css';

const { string, shape } = PropTypes;

class ReadNextArticle extends Component {
  static propTypes = {
    article: shape({
      title: string.isRequired,
      slug: string.isRequired,
      cover: mediaShape.isRequired
    }).isRequired
  };

  state = {
    count: 0
  };

  render() {
    const { article } = this.props;

    return (
      <div className="ReadNextArticle">
        <div className="ReadNextArticle-suggest">Читайте также</div>
        <div className="ReadNextArticle-contentGroup">
          <div className="ReadNextArticle-imageHolder">
            <img
              className="ReadNextArticle-image"
              src={article.cover.sources[0].main.normal}
              srcSet={article.cover.sources[0].main.double}
              alt={article.cover.caption}
              title={article.title}
            />
          </div>
          <h2 className="ReadNextArticle-title">
            <RouterLink to={getArticleLink(article)} className="ReadNextArticle-link">
              {article.title}
            </RouterLink>
          </h2>
        </div>
      </div>
    );
  }
}

export default ReadNextArticle;
