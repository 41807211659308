import React, { Component } from 'react';

import './icon-sign-arrow-left.css';

class IconSignArrowLeft extends Component {
  render() {
    return (
      <svg className="IconSignArrowLeft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M7.3 18H32M15.3 7.7L4 18l11.3 10.3"
        />
      </svg>
    );
  }
}

export default IconSignArrowLeft;
