import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NextArticle from '../components/next-article/next-article';
import * as fromArticleList from '../ducks/article-list';

const getPrevousSlug = (_, { previousSlug }) => previousSlug;
const getNextSlug = (_, { nextSlug }) => nextSlug;

const mapState = createStructuredSelector({
  previous: fromArticleList.makeGetItemBySlug(getPrevousSlug),
  next: fromArticleList.makeGetItemBySlug(getNextSlug)
});

class NextArticleContainer extends Component {
  render() {
    return <NextArticle {...this.props} />;
  }
}

export default connect(mapState)(NextArticleContainer);
