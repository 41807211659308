import React, { Component } from 'react';

class EventBoundary extends Component {
  render() {
    const { children } = this.props;
    return (
      <div
        onClick={this.handleAll}
        onMouseDown={this.handleAll}
        onMouseMove={this.handleAll}
        onMouseUp={this.handleAll}
      >
        {children}
      </div>
    );
  }

  handleAll = e => {
    e.stopPropagation();
  };
}

export default EventBoundary;
