import React, { Component, Children, cloneElement, Fragment } from 'react';
import Overlay from 'react-overlays/lib/Overlay';

import PropTypes from 'prop-types';

import TooltipInner from './tooltip-inner';

import './tooltip.css';

const { bool, number, oneOf, string, oneOfType, element } = PropTypes;

class Tooltip extends Component {
  static propTypes = {
    /** Title of tooltip */
    title: string,

    /** Tooltip content */
    content: oneOfType([string, element]),

    /** Is tooltip must be closable or should close automatically */
    closable: bool,

    size: oneOf(['normal', 'small']),

    /** Force tooltip show */
    show: bool,
    showDelay: number,
    hideDelay: number
  };

  static defaultProps = {
    alignment: 'center',
    title: null,
    content: null,
    closable: false,
    size: 'normal',
    showDelay: 800,
    hideDelay: 200
  };

  state = {
    shouldShow: false
  };

  render() {
    const { show, alignment, content, title, closable, size } = this.props;

    const showTooltip = this.state.shouldShow || show;

    return (
      <Fragment>
        {this.renderChild()}
        <Overlay
          target={this._target}
          show={showTooltip}
          shouldUpdatePosition={true}
          placement="top"
        >
          <TooltipInner
            title={title}
            alignment={alignment}
            closable={closable}
            onClose={this.onClose}
            onMouseOver={this.hmOver}
            onMouseOut={this.hmOut}
            active={showTooltip}
            size={size}
          >
            {content}
          </TooltipInner>
        </Overlay>
      </Fragment>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  renderChild() {
    const { children } = this.props;
    const child = Children.only(children);
    const showTooltip = this.state.shouldShow || this.props.show;

    return cloneElement(child, {
      ref: this.handleRef,
      onMouseOver: this.handleMouseOver(child),
      onMouseOut: this.handleMouseOut(child),
      active: showTooltip
    });
  }

  handleRef = node => {
    this._target = node;
  };

  onChildOver = () => {
    const { showDelay } = this.props;
    clearTimeout(this._timer);
    this._timer = setTimeout(this.onOverTimeout, showDelay);
  };

  onOverTimeout = () => {
    clearTimeout(this._timer);
    this.setState({
      shouldShow: true
    });
  };

  onChildLeave = () => {
    const { hideDelay } = this.props;
    clearTimeout(this._timer);
    if (this.props.closable) return;
    this._timer = setTimeout(this.onLeaveTimeout, hideDelay);
  };

  onLeaveTimeout = () => {
    clearTimeout(this._timer);
    this.setState({
      shouldShow: false
    });
  };

  onClose = () => {
    clearTimeout(this._timer);
    this.setState({
      shouldShow: false
    });
  };

  handleWindowResize = () => {
    this.onClose();
  };

  handleMouseOver = child => (...args) => {
    if (child.props.onMouseOver) {
      child.props.onMouseOver(...args);
    }
    this.onChildOver(...args);
  };

  handleMouseOut = child => (...args) => {
    if (child.props.onMouseOut) {
      child.props.onMouseOut(...args);
    }
    this.onChildLeave(...args);
  };

  hmOver = () => {
    this.onChildOver();
  };

  hmOut = () => {
    this.onChildLeave();
  };
}

export default Tooltip;
