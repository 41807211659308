import React, { Component } from 'react';
import './icon-apple.css';

class IconApple extends Component {
  render() {
    return (
      <svg className="IconApple" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path d="M6.8 13.3c-2.6 4.5-.9 11.3 1.9 15.4 1.4 2.1 2.9 3.9 4.9 3.9h.1c.8 0 1.6-.2 2.3-.6.8-.4 1.7-.6 2.6-.6.9 0 1.8.2 2.5.6.8.4 1.6.6 2.4.6 2.2 0 3.6-2 4.8-3.8.9-1.3 1.6-2.8 2.1-4.3 0-.1 0-.3-.1-.3-2.3-1-3.9-3.3-3.9-5.9 0-2.3 1.2-4.4 3.1-5.6.1-.1.1-.2.1-.4-1.3-1.9-3.5-3-5.8-3.1h-.5c-1.2.1-2.5.4-3.6.9-.5.3-1.1.4-1.7.5-.6-.1-1.2-.2-1.8-.5-1-.5-2-.7-3.1-.8H13c-2.5.2-4.9 1.7-6.2 4z" />
        <path d="M22.9 2.6c-1.6.2-3.1.9-4.2 2.2-1.1 1.2-1.6 2.8-1.5 4.4 0 .1.1.2.2.2h.3c1.6-.1 3-.8 4-2 1.1-1.3 1.6-2.9 1.5-4.6-.1-.1-.2-.2-.3-.2z" />
      </svg>
    );
  }
}

export default IconApple;
