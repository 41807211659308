import React, { Fragment } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromLocation from 'modules/geography/ducks/location';
import * as fromLandings from 'modules/landing/ducks/landings';

import TopLandingBanner from 'modules/core/components/top-landing-banner/top-landing-banner';

const mapStateToProps = createStructuredSelector({
  selectCityId: fromLocation.getLocalCityId,
  suggestedId: fromLocation.getSuggestedCityId,

  isLandingsDataLoaded: fromLandings.getIsLandingDataLoaded,
  bannersList: fromLandings.getBannersList
});

function LandingBannerContainer(props) {
  const { isLandingsDataLoaded, bannersList, selectCityId, suggestedId } = props;
  if (!isLandingsDataLoaded || bannersList.length === 0) {
    return null;
  }

  return (
    <Fragment key={props.location.pathname}>
      {bannersList.map((bannerData, index) => (
        <TopLandingBanner
          key={index}
          data={bannerData}
          selectCityId={selectCityId}
          suggestedId={suggestedId}
        />
      ))}
    </Fragment>
  );
}

export default withRouter(connect(mapStateToProps)(LandingBannerContainer));
