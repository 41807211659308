import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LatestArticlesSection from '../components/latest-articles-section/latest-articles-section';

import * as formLatestArticleList from '../ducks/last-article-list';

const mapStateToProps = createStructuredSelector({
  articleList: formLatestArticleList.getList,
  isLoading: formLatestArticleList.getIsLoading
});

class LatestArticleSectionContainer extends Component {
  render() {
    return <LatestArticlesSection {...this.props} />;
  }
}

export default connect(mapStateToProps)(LatestArticleSectionContainer);
