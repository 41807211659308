// import getFilledArray from './get-filled-array';

const MASKS = {
  ELEVENT_SYMBOLS: [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/
  ],

  TWELVE_SYMBOLS: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]
};

const getMask = (phoneLength = 11, withCode = false) => {
  // const filling = /\d/;
  // const operatorMask = getFilledArray(operatorCodeLength, filling);

  if (phoneLength === 12) {
    return withCode ? ['+', /\d/, /\d/, /\d/, ' ', ...MASKS.TWELVE_SYMBOLS] : MASKS.TWELVE_SYMBOLS;
  }

  return withCode ? ['+', /\d/, ' ', ...MASKS.ELEVENT_SYMBOLS] : MASKS.ELEVENT_SYMBOLS;
};

export default getMask;
