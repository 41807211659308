import React, { Component } from 'react';
import ProductContainer from '../../containers/product';
import ProductCarousel from 'modules/core/components/product-carousel/product-carousel';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

class SimilarProducts extends Component {
  render() {
    const { list } = this.props;

    return <ProductCarousel>{list.map(this.renderProduct)}</ProductCarousel>;
  }

  renderProduct = slug => {
    const { listId } = this.props;

    return (
      <HideErrorBoundary key={slug}>
        <ProductCarousel.Item>
          <ProductContainer slug={slug} listId={listId} />
        </ProductCarousel.Item>
      </HideErrorBoundary>
    );
  };
}

export default SimilarProducts;
