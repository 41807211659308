import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TriggerSwitcher from 'modules/core/components/trigger-switcher/trigger-switcher';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import './subscribe-channel.css';

const { string, bool, arrayOf, shape, func } = PropTypes;

class SubscribeChannel extends Component {
  static propTypes = {
    channel: shape({
      slug: string.isRequired,
      title: string.isRequired,
      topicList: arrayOf(
        shape({
          slug: string.isRequired,
          title: string.isRequired,
          isActive: bool.isRequired,
          channelSlug: string.isRequired
        })
      ).isRequired
    }).isRequired,

    onChannelSubscribe: func.isRequired,
    onChannelUnsubscribe: func.isRequired,
    onTopicSubscribe: func.isRequired,
    onTopicUnsubscribe: func.isRequired
  };

  render() {
    const { channel } = this.props;

    const isEnabled = this.checkChannelEnabled();
    const isTopicListExist = channel.topicList.length > 1;

    return (
      <div className="SubscribeChannel">
        <div className="SubscribeChannel-header">
          <div className="SubscribeChannel-triggerHolder">
            <div className="SubscribeChannel-trigger">
              <TriggerSwitcher active={isEnabled} onChange={this.handleTriggerChange} />
            </div>

            <div className="SubscribeChannel-triggerState">{isEnabled ? 'Вкл.' : 'Выкл.'}</div>
          </div>

          <h3 className="SubscribeChannel-title">{channel.title}</h3>
        </div>

        {isTopicListExist && (
          <ul className="SubscribeChannel-topicList">{channel.topicList.map(this.renderTopic)}</ul>
        )}
      </div>
    );
  }

  renderTopic = topic => {
    return (
      <li className="SubscribeChannel-topicItem">
        <Checkbox
          key={topic.slug}
          label={topic.title}
          value={topic.slug}
          name={topic.slug}
          checked={topic.isActive}
          onChange={this.handleTopicChange(topic)}
        />
      </li>
    );
  };

  checkChannelEnabled() {
    const { channel } = this.props;

    return channel.topicList.filter(topic => topic.isActive).length > 0;
  }

  toggleTopic(topic) {
    const { onTopicSubscribe, onTopicUnsubscribe } = this.props;

    if (!topic.isActive) {
      onTopicSubscribe(topic);
    } else {
      onTopicUnsubscribe(topic);
    }
  }

  toggleChannel(channel) {
    const { onChannelSubscribe, onChannelUnsubscribe } = this.props;

    const isEnabled = this.checkChannelEnabled();

    if (!isEnabled) {
      onChannelSubscribe(channel);
    } else {
      onChannelUnsubscribe(channel);
    }
  }

  handleTriggerChange = () => {
    const { channel } = this.props;

    this.toggleChannel(channel);
  };

  handleTopicChange = topic => () => {
    this.toggleTopic(topic);
  };
}

export default SubscribeChannel;
