import * as TYPES from '../types/subscribe';
import { createAction } from 'modules/utils/dux';

export default {
  rehydrate(haveSuggestionBeenShowed) {
    return createAction(TYPES.REHYDRATE, { haveSuggestionBeenShowed });
  },

  request(email) {
    return createAction(TYPES.REQUEST, { email });
  },

  response(email) {
    return createAction(TYPES.RESPONSE, { email });
  },

  resetEmail() {
    return createAction(TYPES.RESET_SUBSCRIBED_EMAIL);
  },

  open() {
    return createAction(TYPES.SUBSCRIBE_SUGGESTION_OPEN);
  },

  close() {
    return createAction(TYPES.SUBSCRIBE_SUGGESTION_CLOSE);
  },

  allowToShow() {
    return createAction(TYPES.ALLOW_TO_SHOW);
  },
  forbidToShow() {
    return createAction(TYPES.FORBID_TO_SHOW);
  }
};
