import React, { Component } from 'react';
import { connect } from 'react-redux';
import LastViewedProductsSection from '../components/last-viewed-products-section/last-viewed-products-section';
import { createStructuredSelector } from 'reselect';
import * as fromRecent from '../ducks/recent';

const mapState = createStructuredSelector({
  productList: fromRecent.getList
});

class LastViewedProductsSectionContainer extends Component {
  render() {
    return <LastViewedProductsSection {...this.props} />;
  }
}

export default connect(mapState)(LastViewedProductsSectionContainer);
