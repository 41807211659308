import React, { Component } from 'react';
import './icon-click.css';

class IconClick extends Component {
  render() {
    return (
      <svg className="IconClick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M12.6 15c-.3 0-.6-.1-.8-.3l-1.9-1.9-.6 1c-.2.4-.6.6-1 .6-.5 0-.9-.3-1.1-.7L4 5.5c-.2-.5-.1-1 .2-1.3.3-.3.8-.4 1.3-.2l8.2 3.3c.4.2.7.5.7 1 0 .4-.2.9-.6 1.1l-1 .6 1.9 1.9c.2.2.3.5.3.8 0 .3-.1.6-.3.8l-1.3 1.3c-.2.1-.5.2-.8.2zm-2.8-3.4c.1 0 .2 0 .3.1l2.3 2.3c.1.1.3.1.4 0l1.3-1.3s.1-.1.1-.2 0-.1-.1-.2L11.8 10c-.1-.1-.1-.2-.1-.4 0-.1.1-.3.2-.3l1.5-.9c.1 0 .1-.1.1-.2s-.1-.2-.2-.2L5.1 4.8c-.1 0-.2 0-.3.1v.2l3.3 8.2c0 .1.1.2.2.2s.2 0 .2-.1l.9-1.5c.1-.2.2-.3.4-.3-.1 0 0 0 0 0zM3 2.3c-.2-.2-.5-.2-.6 0-.2.2-.2.5-.1.7l1 1c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.5 0-.6L3 2.3zM3.3 5.5c0-.3-.2-.5-.4-.5H1.5c-.3 0-.5.2-.5.5s.2.5.5.5h1.4c.2-.1.4-.3.4-.5M3.1 6.9l-1 1c-.1.1-.1.4 0 .6.1.1.2.1.3.1.1 0 .2 0 .3-.1l1-1c.3-.2.3-.5.1-.6-.2-.2-.5-.2-.7 0M5.5 3.3c.3 0 .5-.2.5-.5V1.5c-.1-.3-.3-.5-.5-.5-.3 0-.5.2-.5.5v1.4c0 .2.2.4.5.4M7.2 3.9c.1 0 .2 0 .3-.1l1-1c.2-.2.2-.5 0-.6-.2-.2-.5-.2-.6-.1l-1 1c-.2.2-.2.5 0 .6 0 .2.2.2.3.2" />
      </svg>
    );
  }
}

export default IconClick;
