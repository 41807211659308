export default {
  title: 'Состояние кожи лица',
  id: 'face-skin',
  description:
    'Выбери свой тип кожи лица и тела, тип волос и получай персональные рекомендации по уходу',
  options: [
    {
      slug: 'skin-type',
      title: 'Тип кожи лица',
      values: [
        {
          name: 'SKIN_TYPES/DRY',
          title: 'Сухая'
        },
        {
          name: 'SKIN_TYPES/NORMAL',
          title: 'Нормальная'
        },
        {
          name: 'SKIN_TYPES/OILY',
          title: 'Жирная'
        },
        {
          name: 'SKIN_TYPES/COMBINE',
          title: 'Комбинированная'
        }
      ]
    },
    {
      slug: 'wide-pores-on-face',
      title: 'Широкие поры на лице',
      values: [
        {
          name: 'WIDE_PORES/TRUE',
          title: 'Да'
        },
        {
          name: 'WIDE_PORES/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'black-dots-on-the-face',
      title: 'Черные точки на лице',
      values: [
        {
          name: 'BLACK_DOTS/TRUE',
          title: 'Да'
        },
        {
          name: 'BLACK_DOTS/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'facial-rash',
      title: 'Наличие высыпаний на лице',
      values: [
        {
          name: 'FACIAL_RASH/TRUE',
          title: 'Да'
        },
        {
          name: 'FACIAL_RASH/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'skin-peeling',
      title: 'Шелушение кожи',
      values: [
        {
          name: 'SKIN_PEELING/TRUE',
          title: 'Да'
        },
        {
          name: 'SKIN_PEELING/FALSE',
          title: 'Нет'
        }
      ]
    }
  ]
};
