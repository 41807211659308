import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { element } = PropTypes;

export default class CategoryNavigationPanelOption extends Component {
  static propTypes = {
    children: element
  };

  render() {
    const { children } = this.props;
    return <div className="CategoryNavigationPanel-option">{children}</div>;
  }
}
