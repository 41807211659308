import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';

import mediaShape from 'modules/product/prop-types/media-shape';

import dateString from 'modules/utils/date-string';

import { formatDuration } from './../../../formatters/format-duration';

import './header.css';

const { string } = PropTypes;

class Header extends Component {
  static propTypes = {
    publishDate: string.isRequired,
    readingTime: string.isRequired,
    title: string.isRequired,
    author: string.isRequired,
    cover: mediaShape.isRequired
  };

  render() {
    const {
      publicationDate,
      readingTime,
      title,
      // author,
      cover
    } = this.props;

    return (
      <div className="Article_Header">
        <Container>
          <div className="Article_Header-metaInfo">
            <time className="Article_Header-publicationDate" pubdate={publicationDate}>
              {this.getHumanizedPublishDate()}
            </time>
            <span className="Article_Header-separator" role="presentation">
              •
            </span>
            <span className="Article_Header-readingTime">
              {formatDuration(readingTime, true)}{' '}
              <span className="Article_Header-hint">(время чтения)</span>
            </span>
          </div>

          <h1 className="Article_Header-title">{title}</h1>

          {/* <div className="Article_Header-author">
            {author.firstName} {author.lastName}
          </div> */}

          <div className="Article_Header-imageHolder">
            <img
              className="Article_Header-image"
              src={cover.sources[0].main.normal}
              srcSet={`${cover.sources[0].main.double} 2x`}
              alt={cover.caption}
              title={title}
            />
          </div>
        </Container>
      </div>
    );
  }

  getHumanizedPublishDate() {
    const { publicationDate } = this.props;

    const date = new Date(publicationDate);

    return dateString(date);
  }
}

export default Header;
