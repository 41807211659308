import React, { Component } from 'react';

import './tabs.css';
import classNames from 'classnames';

class Tabs extends Component {
  render() {
    return (
      <ul
        className={classNames('Tabs', {
          'Tabs--nav': this.props.nav
        })}
      >
        {this.props.children}
      </ul>
    );
  }
}

export default Tabs;
