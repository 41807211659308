import React, { Component } from 'react';
import './icon-not-available.css';

class IconNonAvailable extends Component {
  render() {
    return (
      <svg
        className="IconNonAvailable"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 142.916 142.916"
      >
        <path d="M32.901 114.799l-12.015 16.507a7.308 7.308 0 0 0 5.903 11.61 7.301 7.301 0 0 0 5.917-3.006l12.11-16.638a56.47 56.47 0 0 0 26.644 6.651c31.342 0 56.84-25.499 56.84-56.842 0-15.979-6.636-30.427-17.283-40.764l15.074-20.709a7.31 7.31 0 0 0-1.607-10.21c-3.273-2.377-7.84-1.651-10.209 1.608L99.313 23.562a56.458 56.458 0 0 0-27.856-7.323c-31.343 0-56.842 25.499-56.842 56.841 0 16.477 7.05 31.329 18.286 41.719zm80.781-41.719c0 23.284-18.94 42.226-42.226 42.226-6.407 0-12.461-1.477-17.905-4.039l48.729-66.951c7.051 7.548 11.402 17.648 11.402 28.764zM71.457 30.856c6.901 0 13.403 1.698 19.159 4.646l-49.043 67.381C33.95 95.24 29.229 84.702 29.229 73.082c.003-23.284 18.944-42.226 42.228-42.226z" />
      </svg>
    );
  }
}

export default IconNonAvailable;
