import React, { Component } from 'react';
import './logout-button.css';
import MobileNavigationButton from 'modules/core/components/mobile-navigation-button/mobile-navigation-button';
import { IconExit } from '../icons';

class LogoutButton extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <div className="LogoutButton">
        <MobileNavigationButton title="Выйти" {...rest}>
          <IconExit />
        </MobileNavigationButton>
      </div>
    );
  }
}

export default LogoutButton;
