import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Media from 'react-media';
import { IconCheck, IconCross } from 'modules/core/components/icons';
import './availability.css';
import { IconDot } from '../icons/index';

class Availability extends Component {
  static propTypes = {
    /** Availability status */
    is: PropTypes.bool,
    /** Title string when availability status is true */
    title: PropTypes.string,
    /** Title string when availability status is false */
    falsyTitle: PropTypes.string,
    /** set dot icon */
    dot: PropTypes.bool
  };

  static defaultProps = {
    is: false,
    title: 'Есть в наличии',
    falsyTitle: 'Нет в наличии'
  };

  render() {
    const { title, falsyTitle, is } = this.props;
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <div
            className={cn('Availability', {
              'Availability--is-true': is
            })}
          >
            <div className="Availability-icon">
              {isMobile ? this.renderDot() : this.renderIcon()}
            </div>

            {is ? title : falsyTitle}
          </div>
        )}
      </Media>
    );
  }

  renderDot() {
    return <IconDot />;
  }

  renderIcon() {
    const { is } = this.props;

    return is ? <IconCheck /> : <IconCross />;
  }
}

export default Availability;
