import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './navigation-label.css';

const { string } = PropTypes;

export default class NavigationLabel extends Component {
  static propTypes = {
    label: string
  };

  render() {
    return <span className="NavigationLabel">{this.props.label}</span>;
  }
}
