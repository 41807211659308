import React, { Component } from 'react';
import './icon-android.css';

class IconAndroid extends Component {
  render() {
    return (
      <svg className="IconAndroid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        ">
        <path d="M6 12.3c-1.2 0-2 .9-2 2v8.5c0 1.1.9 1.9 2 2 1.1 0 1.9-.9 2-2v-8.5c0-1.1-.9-2-2-2zm2.9 13.8c0 1 .8 1.8 1.8 1.8h1.9v4.6c0 1.1.9 2 2 2h.1c1.1 0 2-.9 2-2v-4.6h2.7v4.6c0 1.1.9 2 2 2h.1c1.1 0 2-.9 2-2v-4.6h1.9c1 0 1.8-.8 1.8-1.8V12.6H8.9v13.5zM22.5 4.5L24 2.1c.1-.1.1-.3-.1-.4-.1-.1-.3-.1-.4.1l-1.6 2.5c-2.5-1-5.3-1-7.8 0l-1.6-2.5c-.1-.1-.3-.2-.5 0-.1.1-.1.3-.1.4l1.5 2.5c-2.6 1-4.4 3.7-4.5 6.6v.5h18.2v-.5c-.1-2.9-1.9-5.6-4.6-6.8zm-8.7 4.2c-.5 0-.9-.4-.9-.9s.4-.8.9-.8.9.4.9.9-.4.8-.9.8zm8.4 0c-.5 0-.9-.4-.9-.9s.4-.8.9-.8.9.4.9.9c0 .4-.4.8-.9.8zm7.8 3.6c-1.2 0-2 .9-2.1 2v8.5c0 1.1.9 2 2 2h.1c1.1 0 1.9-.9 2-2v-8.5c0-1.1-.9-2-2-2z" />
      </svg>
    );
  }
}

export default IconAndroid;
