import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';

import MobileProfileForm from '../components/mobile-profile-form/mobile-profile-form';
import QuestionnaireContainer from './questionnaire';
const { string, func, shape } = PropTypes;

const FULL_NAME_REQUIRED_ERROR = 'Имя является обязательным полем';
const FULL_NAME_SHORT_ERROR = 'Имя должно содержать не менее 3-х символов';

// const STREET_SHORT_ERROR =
//   'Название улицы должно содержать не менее 3-х символов';
const NOT_FULL_ADDRESS_ERROR = 'Требуется указать полный адрес';

// const STREET_MIN_LENGTH = 3;

class MobileProfileFormContainer extends Component {
  state = {
    phone: this.getInitialPhone() ? '+' + this.getInitialPhone().replace(/\D/g, '') : null
  };

  static propTypes = {
    profile: shape({
      fullName: string,
      phone: string,
      delivery: shape({
        address: shape({
          street: string,
          building: string,
          apartment: string
        })
      }),
      birthDate: string
    }),
    onSubmit: func,
    onCancel: func
  };

  render() {
    const { isPhoneEditable, onCancel, onSubmit, onCallbackDialogOpen } = this.props;

    const phoneLength = this.state.phone
      ? getSeparatedPhone(this.state.phone, areaCodes).code.phoneLength
      : areaCodes[0].phoneLength;

    const PHONE_SHORT_ERROR = `Номер должен содержать ${phoneLength} цифр`;

    const initialValues = {
      fullName: this.getInitialName() || '',
      phone: this.getInitialPhone(),
      birthDate: this.getInitialBirthDate() || '',
      address: {
        street: this.getInitialStreet() || '',
        building: this.getInitialBuilding() || '',
        apartment: this.getInitialApartment() || ''
      }
    };

    const credentialsSchema = Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .required(FULL_NAME_REQUIRED_ERROR)
        .min(3, FULL_NAME_SHORT_ERROR),
      phone: Yup.string().min(phoneLength + 1, PHONE_SHORT_ERROR),
      address: Yup.object().shape({
        street: Yup.string()
          .trim()
          .when('apartment', {
            is: apartment => (apartment && apartment.length > 0) === true,
            then: Yup.string().required(NOT_FULL_ADDRESS_ERROR),
            otherwise: Yup.string()
          })
          .when('building', {
            is: building => (building && building.length > 0) === true,
            then: Yup.string().required(NOT_FULL_ADDRESS_ERROR),
            otherwise: Yup.string()
          }),
        // .min(STREET_MIN_LENGTH, STREET_SHORT_ERROR),
        //
        building: Yup.string()
          .trim()
          // .when('street', {
          //   is: street => (street && street.length >= STREET_MIN_LENGTH) === true,
          //   then: Yup.string().required(NOT_FULL_ADDRESS_ERROR),
          //   otherwise: Yup.string()
          // })
          .when('apartment', {
            is: apartment => (apartment && apartment.length > 0) === true,
            then: Yup.string().required(NOT_FULL_ADDRESS_ERROR),
            otherwise: Yup.string()
          }),
        apartment: Yup.string().trim()
      })
    });

    return (
      <Fragment>
        <Formik
          validationSchema={credentialsSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ values, touched, errors, handleBlur, handleSubmit, handleChange }) => (
            <MobileProfileForm
              profile={values}
              touched={touched}
              errors={errors}
              isPhoneEditable={isPhoneEditable}
              onChange={handleChange}
              onPhoneChange={this.handlePhoneChange}
              onBlur={handleBlur}
              onSubmit={handleSubmit}
              onCancel={onCancel}
              onCallbackDialogOpen={onCallbackDialogOpen}
            />
          )}
        />{' '}
        <QuestionnaireContainer />
      </Fragment>
    );
  }

  getInitialName() {
    const { profile } = this.props;

    if (!profile || !profile.fullName) {
      return null;
    }

    return profile.fullName;
  }

  getInitialPhone() {
    const { profile } = this.props;

    if (!profile || !profile.phone) {
      return '';
    }

    return profile.phone;
  }

  getInitialStreet() {
    const { profile } = this.props;

    if (!profile || !profile.delivery || !profile.delivery.address) {
      return null;
    }

    return profile.delivery.address.street;
  }

  getInitialBuilding() {
    const { profile } = this.props;

    if (!profile || !profile.delivery || !profile.delivery.address) {
      return null;
    }

    return profile.delivery.address.building;
  }

  getInitialApartment() {
    const { profile } = this.props;

    if (!profile || !profile.delivery || !profile.delivery.address) {
      return null;
    }

    return profile.delivery.address.apartment;
  }

  getInitialAddress() {
    const { profile } = this.props;

    if (!profile || !profile.delivery || !profile.delivery.address) {
      return null;
    }

    return profile.delivery.address;
  }

  getInitialBirthDate() {
    const { profile } = this.props;

    if (!profile || !profile.birthDate) {
      return null;
    }

    return profile.birthDate;
  }
  setPhoneState(phone) {
    this.setState({
      phone
    });
  }

  handlePhoneChange = value => {
    this.setPhoneState(value);
  };
}

export default MobileProfileFormContainer;
