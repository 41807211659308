import React from 'react';
import cn from 'classnames';

import './ribbon-label.css';

function RibbonLabel({ labels, colors }) {
  return (
    <div
      className={cn('RibbonLabel', {
        'RibbonLabel--onlyOne': labels.length === 1
      })}
      style={{ color: colors[0] }}
    >
      <div className="RibbonLabel-inner">
        <div className="RibbonLabel-list">
          {labels.map((item, index) => {
            const isLastItem = index === labels.length - 1;
            return (
              <>
                <div className="RibbonLabel-item">{item}</div>
                {!isLastItem && <div className="RibbonLabel-itemSeparator"></div>}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default RibbonLabel;
