import React, { Component } from 'react';
import cn from 'classnames';
import nanoid from 'nanoid';

import './field-bottom-labeled.css';

class FieldBottomLabeled extends Component {
  _id = nanoid();

  render() {
    const { label, renderInput, id, size, ...rest } = this.props;

    const inputId = id || this._id;

    const inputProps = {
      id: inputId,
      size,
      ...rest
    };

    return (
      <div className={cn('FieldBottomLabeled', `FieldBottomLabeled--size-${size}`)}>
        <label for={inputId} className="FieldBottomLabeled-label">
          {label}
        </label>

        <div className="FieldBottomLabeled-input">{renderInput({ ...inputProps })}</div>
      </div>
    );
  }
}

export default FieldBottomLabeled;
