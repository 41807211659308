import React, { Component } from 'react';
import CatalogNavigationMobileItem from './catalog-navigation-mobile-item';
import Loader from 'modules/core/components/loader/loader';

import './catalog-navigation-mobile.css';

class CatalogNavigationMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenuItem: null,
      previousActive: null
    };
  }

  activeSubMenu = null;
  subMenuTriggers = [];

  render() {
    const { catalog, animatedCollapsing, onCatalogLinkClick, isNavigationLoaded } = this.props;
    const { activeMenuItem, previousActive } = this.state;

    if (!isNavigationLoaded) {
      return (
        <div style={{ position: 'relative', paddingTop: '100px' }}>
          <Loader size="small" secondary />
        </div>
      );
    }

    if (!catalog) {
      return null;
    }

    return (
      <ul className="CatalogNavigationMobile">
        {catalog.map((item, index) => {
          const isActive = activeMenuItem === item.id || previousActive === item.id;

          return (
            <CatalogNavigationMobileItem
              key={item.id}
              index={index}
              item={item}
              animatedCollapsing={animatedCollapsing}
              active={isActive}
              onTrigActiveMenuItem={this.handleTrigActiveMenuItem}
              onTrigActiveSubMenuItem={this.handleTrigActiveSubMenuItem}
              onCatalogLinkClick={item =>
                item.submenu ? this.handleTrigActiveMenuItem(item.id) : onCatalogLinkClick
              }
              subMenuRef={this.setActiveSubMenuRef}
              subMenuTriggerRef={this.setSubMenuTriggerRef}
              onNestedLinkClick={this.handleNestedLinkClick}
              onFilterLinkClick={this.handleFilterLinkClick}
              activeMenuItem={activeMenuItem}
              previousActive={previousActive}
            />
          );
        })}
      </ul>
    );
  }

  getElementHeight(element) {
    return element.getBoundingClientRect().bottom - element.getBoundingClientRect().top;
  }

  getElementTopPosition(element) {
    return element.getBoundingClientRect().top;
  }

  isBefore(firstElement, secondElement) {
    return firstElement.offsetTop < secondElement.offsetTop;
  }

  setActiveSubMenuRef = id => element => {
    const { activeMenuItem } = this.state;

    if (!element || activeMenuItem !== id) return;

    this.activeSubMenu = element;
  };

  setSubMenuTriggerRef = id => element => {
    if (!element) return;
    this.subMenuTriggers[id] = element;
  };

  closeMenuItem() {
    this.setState({
      activeMenuItem: null
    });
    this.activeSubMenu = null;
  }

  closePreviousItem = () => {
    this.setState({
      previousActive: null
    });
  };

  openMenuItem(activeMenuItem) {
    const { onNavigationItemOpen } = this.props;

    this.setState(state => ({
      activeMenuItem,
      previousActive: state.activeMenuItem
    }));

    const triggerElement = this.subMenuTriggers[activeMenuItem];
    const triggerElementTopPosition = this.getElementTopPosition(triggerElement);

    if (this.activeSubMenu && this.isBefore(this.activeSubMenu, triggerElement)) {
      setTimeout(this.closePreviousItem, 500);
    } else {
      this.closePreviousItem();
    }

    onNavigationItemOpen(triggerElementTopPosition);
  }

  handleTrigActiveMenuItem = id => () => {
    const isOpened = id === this.state.activeMenuItem;

    if (isOpened) {
      this.closeMenuItem();
    } else {
      this.openMenuItem(id);
    }
  };

  handleFilterLinkClick = () => {
    const { onCatalogLinkClick } = this.props;

    onCatalogLinkClick();
  };

  handleNestedLinkClick = () => {
    const { onCatalogLinkClick } = this.props;
    onCatalogLinkClick();
  };
}

export default CatalogNavigationMobile;
