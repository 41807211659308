import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderingForm from '../ordering-form/ordering-form';
import { createStructuredSelector } from 'reselect';
import * as fromOrders from '../../../ducks/orders';
import { actions as Order } from '../../../ducks/orders';
import scrollToElementTop from './helpers/scroll-to-element-top';

const { string } = PropTypes;

const PERSONAL_STEP = 1;
const DELIVERY_STEP = 2;
const PAYMENT_STEP = 3;

const CONFIRMATION_METHODS = {
  CALL: 'call',
  SMS: 'sms'
};

const PAYMENT_METHODS_IDS = {
  'PAYMENT_METHOD/SBERBANK_API': 11,
  'PAYMENT_METHOD/ROBOXCHANGE': 8,
  'PAYMENT_METHOD/CASH': 12
};
const confirmedPaymentMethodsList = [
  PAYMENT_METHODS_IDS['PAYMENT_METHOD/SBERBANK_API'],
  PAYMENT_METHODS_IDS['PAYMENT_METHOD/ROBOXCHANGE'],
  PAYMENT_METHODS_IDS['PAYMENT_METHOD/CASH']
];

const mapState = createStructuredSelector({
  isLoading: fromOrders.getIsLoadingById,
  isSubmitting: fromOrders.getIsSubmitting,
  error: fromOrders.getError,
  personalInfoComplete: fromOrders.getPersonalInfoComplete,
  deliveryComplete: fromOrders.getDeliveryComplete,
  paymentComplete: fromOrders.getPaymentComplete,
  confirmationMethod: fromOrders.getConfirmationMethod
});

const mapDispatch = {
  submitOrder: Order.submit,
  onConfirmationMethodChange: Order.changeConfirmationMethod
};

class OrderingFormContainer extends Component {
  state = {
    activeStep: this.getInitialActiveStep(),
    editing: null,
    comment: '',
    paymentType: null,
    isAuthorized: false
  };

  static propTypes = {
    confirmationMethod: string
  };

  static defaultProps = {
    confirmationMethod: 'sms'
  };

  stepElements = [];
  formElement = null;

  render() {
    const { comment, paymentType } = this.state;

    const {
      personalInfoComplete,
      deliveryComplete,
      paymentComplete,
      error,
      confirmationMethod
    } = this.props;

    const isSmsConfirmationMethodActive = confirmationMethod !== CONFIRMATION_METHODS.SMS;

    const personalStep = {
      stepNumber: PERSONAL_STEP,
      isActive: this.isActive(PERSONAL_STEP),
      isComplete: personalInfoComplete,
      isEditing: this.isEditing(PERSONAL_STEP),
      nextStepNumber: DELIVERY_STEP
    };

    const deliveryStep = {
      stepNumber: DELIVERY_STEP,
      isActive: this.isActive(DELIVERY_STEP),
      isComplete: deliveryComplete,
      isEditing: this.isEditing(DELIVERY_STEP),
      nextStepNumber: PAYMENT_STEP
    };

    const paymentStep = {
      stepNumber: PAYMENT_STEP,
      isActive: this.isActive(PAYMENT_STEP),
      isComplete: paymentComplete,
      isEditing: this.isEditing(PAYMENT_STEP),
      nextStepNumber: null
    };

    const shouldPaymentConfirm = confirmedPaymentMethodsList.indexOf(paymentType) !== -1;

    return (
      <OrderingForm
        key={this.props.id}
        error={error}
        isLoading={this.props.isLoading}
        isSubmitting={this.props.isSubmitting}
        id={this.props.id}
        comment={comment}
        paymentType={paymentType}
        personalStep={personalStep}
        deliveryStep={deliveryStep}
        paymentStep={paymentStep}
        isAnyEditing={this.anyEditing()}
        isAllComplete={this.isAllComplete()}
        onPaymentTypeChange={this.handlePaymentTypeChange}
        onCommentChange={this.handleCommentChange}
        onEdit={this.handleEdit}
        onStepSubmit={this.handleStepSubmit}
        onSubmit={this.handleSubmit}
        orderingFormRef={this.setFormRef}
        personalInfoStepRef={this.setStepRef(PERSONAL_STEP)}
        deliveryStepRef={this.setStepRef(DELIVERY_STEP)}
        paymentStepRef={this.setStepRef(PAYMENT_STEP)}
        confirmationMethod={confirmationMethod}
        isSmsConfirmationMethodActive={isSmsConfirmationMethodActive}
        onChangeConfirmationMethod={this.handleChangeConfirmationMethod}
        choiceConfirmationMethod={shouldPaymentConfirm}
      />
    );
  }

  setStepRef = stepNumber => element => {
    if (!element) {
      return;
    }
    this.stepElements[stepNumber] = element;
  };

  setFormRef = element => {
    if (!element) {
      return;
    }
    this.formElement = element;
  };

  scrollToActiveStep() {
    // const scrollLimiter = this.formElement;\
    //TODO: set limiter to scrollToElementTop

    if (this.isActive(PERSONAL_STEP)) {
      scrollToElementTop(this.stepElements[PERSONAL_STEP]);
    }
    if (this.isActive(DELIVERY_STEP)) {
      scrollToElementTop(this.stepElements[PERSONAL_STEP]);
    }
    if (this.isActive(PAYMENT_STEP)) {
      scrollToElementTop(this.stepElements[DELIVERY_STEP]);
    }
  }

  getInitialActiveStep() {
    const { personalInfoComplete, deliveryComplete } = this.props;

    if (!personalInfoComplete) {
      return PERSONAL_STEP;
    }

    if (!deliveryComplete) {
      return DELIVERY_STEP;
    }

    return PAYMENT_STEP;
  }

  changeStep(activeStep) {
    if (!activeStep) {
      return null;
    }

    this.setState(
      {
        activeStep
      },
      // () => this.scrollToActiveStep()
      () => setTimeout(() => this.scrollToActiveStep(), 500)
    );
  }

  edit(step) {
    this.setState(
      {
        editing: step
      },
      // () => this.scrollToActiveStep()
      () => setTimeout(() => this.scrollToActiveStep(), 500)
    );
  }

  finishEditing() {
    this.setState(
      {
        editing: null
      },
      // () => this.scrollToActiveStep()
      () => setTimeout(() => this.scrollToActiveStep(), 500)
    );
  }

  completeStep(step) {
    this.setState(state => ({
      complete: {
        ...state.complete,
        [step]: true
      }
    }));
  }

  isActive(step) {
    if (this.isEditing(step)) {
      return true;
    }

    if (this.anyEditing()) {
      return false;
    }

    const { activeStep } = this.state;

    return activeStep === step;
  }

  isEditing(step) {
    const { editing } = this.state;
    return step === editing;
  }

  anyEditing() {
    const { editing } = this.state;
    return null !== editing;
  }

  isAllComplete() {
    const { personalInfoComplete, deliveryComplete } = this.props;

    const { paymentType } = this.state;

    return personalInfoComplete && deliveryComplete && paymentType;
  }

  handlePaymentTypeChange = paymentType => {
    this.setState({
      paymentType
    });
  };

  handleCommentChange = comment => {
    this.setState({
      comment
    });
  };

  handleSubmit = () => {
    const { paymentType, comment } = this.state;
    const { confirmationMethod } = this.props;

    this.props.submitOrder(this.props.id, {
      comment,
      payment: {
        paymentMethodId: paymentType
      },
      confirmationMethod
    });
  };

  handleStepSubmit = step => {
    if (this.isEditing(step.stepNumber)) {
      this.finishEditing(step.stepNumber);
    } else {
      // this.completeStep(step.stepNumber);
    }

    this.changeStep(step.nextStepNumber);
  };

  handleEdit = step => {
    this.edit(step.stepNumber);
  };

  handleChangeConfirmationMethod = () => {
    const { id, confirmationMethod, onConfirmationMethodChange } = this.props;

    if (confirmationMethod === CONFIRMATION_METHODS.CALL) {
      onConfirmationMethodChange(id, CONFIRMATION_METHODS.SMS);
    } else {
      onConfirmationMethodChange(id, CONFIRMATION_METHODS.CALL);
    }
  };
}

const OrderingFormContainerEnhanced = connect(mapState, mapDispatch)(OrderingFormContainer);

export default OrderingFormContainerEnhanced;
