/**
 * Creates reducer from provided object and initial state
 *
 * @export
 * @param {*} initialState
 * @param {Object} reducerObject
 * @returns
 */
export default function createObjectReducer(initialState, reducerObject) {
  return function(state = initialState, action) {
    if (!action) {
      return state;
    }

    const { type, payload } = action;

    if (!reducerObject.hasOwnProperty(type)) {
      return state;
    }

    return reducerObject[type](state, payload);
  };
}
