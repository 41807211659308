import React, { Component } from 'react';
import './icon-filtering.css';

class IconFiltering extends Component {
  render() {
    return (
      <svg className="IconFiltering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459 459">
        <path d="M0 382.5h153v-51H0v51zm0-306v51h459v-51H0zM0 255h306v-51H0v51z" />
      </svg>
    );
  }
}

export default IconFiltering;
