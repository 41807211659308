import React, { Component } from 'react';

import Container from '../container/container';
import Content from '../content/content';
import YoutubeVideo from '../youtube-video/youtube-video';

import './about-section.css';

class AboutSection extends Component {
  state = {
    isVideoPlaying: false
  };

  render() {
    return (
      <div className="AboutSection">
        <Container>
          <div className="AboutSection-content">
            <Content
              html={`
                <p>
                    В 2014 году небольшой командой профессионалов был основан бренд MIXIT. 
                    Из маленькой лаборатории, где создавались первые формулы, бренд вырос 
                    в одну из крупнейших косметических российских компаний. С самого 
                    первого дня марка делает все, чтобы стать для своих покупателей символом 
                    красоты и заботы о себе.
                </p>
                <p>
                    Сегодня компания MIXIT - это собственное производство, инновационные 
                    разработки и формулы, более 160 розничных магазинов в России и странах СНГ, 
                    а также гордость марки - успешный коммерческий Instagram с аудиторией 4,5 млн 
                    подписчиков.
                </p>
                <p>MIXIT - трендсеттер в пространстве красоты.</p>
                <p>
                    Концепция ухода от MIXIT включает в себя 4 базовых ценности: современность, 
                    доступность, актуальность и&nbsp;эффективность.
                </p>

                <h2>Современность</h1>
                <p>
                    Объединяя все главные тренды в области современной косметологии, 
                    мы создаем высокоэффективную косметику на каждый день, которая способна 
                    активизировать внутренние ресурсы кожи и сохранить её естественную красоту, 
                    не нарушая при этом общую гармонию работы организма.
                </p>

                <h2>Доступность</h1>
                <p>
                    За счет собственного производства и отсутствия дорогих упаковок, мы сохраняем 
                    демократичную цену на продукцию, направляя свои силы на «сердце» нашей 
                    косметики - составы, в которых сочетаются натуральные и биоактивные компоненты.
                </p>

                <h2>Актуальность</h2>
                <p>Мы предлагаем инновационные косметические решения, которые удовлетворяют любую потребность кожи.</p>
                
                <h2>Эффективность</h2>
                <p>
                    Мы широко применяем то, что предлагает природа, улучшая и усиливая эффект 
                    высокотехнологичными разработками. Результаты применения неоднократно доказаны 
                    и протестированы <nobr>фокус-группами</nobr>.
                </p>
                <p>Ты можешь быть разной с MIXIT, но при этом оставаться собой!</p>
                `}
              expanded
            />
          </div>

          <div className="AboutSection-media">
            <YoutubeVideo id="J6LUCUCysq8" />
          </div>
        </Container>
      </div>
    );
  }

  handleSetVideoState = isVideoPlaying => {
    this.setState({ isVideoPlaying });
  };
}

export default AboutSection;
