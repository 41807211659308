// export function setCookie(name, value, options = {}) {
//   options = {
//     path: '/',
//     // при необходимости добавьте другие значения по умолчанию
//     ...options
//   };

//   if (options.expires && options.expires.toUTCString) {
//     options.expires = options.expires.toUTCString();
//   }

//   let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

//   for (let optionKey in options) {
//     updatedCookie += '; ' + optionKey;
//     let optionValue = options[optionKey];
//     if (optionValue !== true) {
//       updatedCookie += '=' + optionValue;
//     }
//   }

//   document.cookie = updatedCookie;
// }

export function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
}

export function deleteByMatchithQuery(query) {
  console.log("deleteByMatchithQuery -> query", query)
  const cookies = document.cookie.split(/=[^;]*(?:;\s*|$)/);
  cookies.forEach( cookie => {
    const isMatched = Boolean(cookie.match(query))
    if(isMatched) {
      deleteCookie(cookie)
    }

  })

  // cookieUtils.deleteCookie('advcake_url');
  // cookieUtils.deleteCookie('advcake_trackid');
}

export default {
  getCookie,
  deleteCookie,
  setCookie,
  deleteByMatchithQuery
};
