import React from 'react';
import IconCategory2 from 'modules/core/components/icons/icon-category2/icon-category2';

import IconContainer from 'modules/core/components/icon-container/icon-container';

import './category-field-trigger.css';

const CategoryFieldTrigger = React.forwardRef(({ count, ...buttonProps }, ref) => {
  return (
    <button type="button" className="CategoryFieldTrigger" ref={ref} {...buttonProps}>
      <span className="CategoryFieldTrigger-icon">
        <IconContainer inline>
          <IconCategory2 />
        </IconContainer>
      </span>

      <span className="CategoryFieldTrigger-text">Категории</span>

      {count > 0 && <span className="CategoryFieldTrigger-count">{count}</span>}
    </button>
  );
});

export default CategoryFieldTrigger;
