import React, { Component } from 'react';
import './icon-return.css';

class IconReturn extends Component {
  state = {};
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon-return" viewBox="0 0 512.001 512.001">
        <path d="M384.834 180.699H36.101l73.326-82.187c4.755-5.33 4.289-13.505-1.041-18.26-5.328-4.754-13.505-4.29-18.26 1.041l-82.582 92.56c-10.059 11.278-10.058 28.282.001 39.557l82.582 92.561c2.556 2.865 6.097 4.323 9.654 4.323 3.064 0 6.139-1.083 8.606-3.282 5.33-4.755 5.795-12.93 1.041-18.26l-73.326-82.188h348.733c55.858 0 101.3 45.444 101.3 101.3s-45.443 101.3-101.3 101.3h-61.58c-7.143 0-12.933 5.791-12.933 12.933 0 7.142 5.79 12.933 12.933 12.933h61.58c70.12 0 127.166-57.046 127.166-127.166-.001-70.119-57.047-127.165-127.167-127.165z" />
      </svg>
    );
  }
}

export default IconReturn;
