import React from 'react';

import OpeningAction from '../actions-gift/actions-gift';
// import Container from 'modules/core/components/container/container';

import './actions-gifts.css';

export default function ActionsGigts({ data, colors }) {
  return (
    <div className="ActionsGigts">
      {data.list.map((gift, index) => (
        <div key={index} className="ActionsGigts-itemHolder">
          <div className="ActionsGigts-item">
            <div className="ActionsGigts-itemInner">
              <OpeningAction gift={gift} colors={colors} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
