import React, { Component } from 'react';
import cn from 'classnames';

class CityChoiceBottomSheetButton extends Component {
  render() {
    const { city, isActive } = this.props;

    return (
      <button
        className={cn('CityChoiceBottomSheet-button', {
          'CityChoiceBottomSheet-button--active': isActive
        })}
        onClick={this.handleButtonClick}
      >
        {city.title}
      </button>
    );
  }

  handleButtonClick = () => {
    const { city, onClick } = this.props;

    onClick(city);
  };
}

export default CityChoiceBottomSheetButton;
