import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Item from './item';

import './article-action-bar.css';

const { string, any } = PropTypes;

class ArticleActionBar extends Component {
  static Item = Item;

  static propTypes = {
    title: string.isRequired,

    children: any.isRequired
  };

  render() {
    const { title, children } = this.props;

    return (
      <div className="ArticleActionBar">
        <h3 className="ArticleActionBar-title">{title}</h3>
        <ul className="ArticleActionBar-actionList">{children}</ul>
      </div>
    );
  }
}

export default ArticleActionBar;
