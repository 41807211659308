import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Container from 'modules/core/components/container/container';
import SectionDefault from '../components/section-default/section-default';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import Page from 'modules/core/components/page/page';
import SectionContacts from '../components/section-contacts/section-contacts';

import { getIndexLink } from 'routes/links';

const questionList = [
  {
    title: 'Общие вопросы',
    description: `Также вы всегда можете связаться с нами по почте <b><a href="mailto:hello@mixit.ru">hello@mixit.ru</a></b>`
  },
  {
    title: 'Проблемы и качество обслуживания',
    description: `Если вы недовольны качеством обслуживания, пожалуйста, сообщите нам по адресу <b><a href="mailto:problem@mixit.ru">problem@mixit.ru</a></b>  о проблеме, которую не удалось решить.`
  },
  {
    title: 'Коммерческие предложения',
    description: `По вопросам коммерческих предложений для отдела закупок просьба связываться по почте <b><a href="mailto:zakupki@mixit.ru">zakupki@mixit.ru</a></b>`
  },
  {
    title: 'PR',
    description: `По вопросам СМИ и благотворительным проектам просьба связываться по почте <b><a href="mailto:pr@mixit.ru ">pr@mixit.ru</a></b>`
  },
  {
    title: 'Международное сотрудничество',
    description:
      'По вопросам международного сотрудничества просьба связываться по почте <b><a href="mailto:international@mixit.ru">international@mixit.ru</a></b>'
  }
];

const contactList = [
  {
    // number: '84952520743',
    // formattedNumber: '8 (495) 252-07-43',
    number: '+74958990134',
    formattedNumber: '8 (800) 550-98-70 ',
    description: `По любым вопросам для <b>Москвы</b> (ежедневно с 9:00 до 21:00)`
  },
  {
    // number: '88005509870',
    // formattedNumber: '8 (800) 550-98-70',
    number: '+74958990134',
    formattedNumber: '8 (800) 550-98-70 ',
    description: `Бесплатный телефон для звонков по всей территории России (ежедневно с 9:00 до 21:00)`
  },
  {
    number: '882000718851',
    formattedNumber: '8 (820) 071-88-51',
    description: `Бесплатный телефон для звонков по любым вопросам для <b>Беларуси</b> (ежедневно с 9:00 до 21:00)`
  }
  // {
  //   number: '88001001014',
  //   formattedNumber: '8 (800) 100-10-14',
  //   description: `Бесплатный телефон для звонков по любым вопросам для <b>Казахстана</b> (ежедневно с 9:00 до 21:00)`
  // }
];

const testList = [
  {
    // number: '84952520743',
    // formattedNumber: '8 (495) 252-07-43',
    number: '+74958990134',
    formattedNumber: '8 (800) 550-98-70 ',
    description: `По любым вопросам для <b>Москвы</b> (ежедневно с 9:00 до 21:00)`
  },
  {
    // number: '88005509870',
    // formattedNumber: '8 (800) 550-98-70',
    number: '+74958990134',
    formattedNumber: '8 (800) 550-98-70 ',
    description: `Бесплатный телефон для звонков по всей территории России (ежедневно с 9:00 до 21:00)`
  },
  {
    number: '882000718851',
    formattedNumber: '8 (820) 071-88-51',
    description: `Бесплатный телефон для звонков по любым вопросам для <b>Беларуси</b> (ежедневно с 9:00 до 21:00)`
  }
];

class ContactsPage extends Component {
  static path = '/contacts';
  static link = () => 'contacts';
  static exact = true;

  state = {
    isRussia: false,
    response: ''
  };

  render() {
    return (
      <Page
        title="Контакты"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Контакты'
          }
        ]}
      >
        <Helmet>
          <title>Контакты компании MIXIT</title>
          <meta
            name="description"
            content="Вся контактная информация компании MXIT. Телефон Миксит - 8 (800) 550-98-70 бесплатный телефон для звонков по всей территории России (ежедневно с 9:00 до 21:00)."
          />
        </Helmet>
        <Container>
          <SectionContacts
            questionList={questionList}
            contactList={this.state.isRussia ? testList : contactList}
          />
        </Container>
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
    fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address', {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Token 872e17f6cd47fd17d96d682f1d9a7e4d12d0efd3'
      },
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ response: data });
        this.changeIsRussia(data);
      });
  }

  changeIsRussia(data) {
    if (!data) {
      return;
    }

    if (data.location && data.location.data.country === 'Россия') {
      this.setState({ isRussia: true });
    }

    return;
  }
}

export default ContactsPage;
