import React, { Component } from 'react';

import { MEDIA_TYPE } from 'modules/core/components/media/constants';

import VerticalSlider from '../components/vertical-slider/vertical-slider';

class OverviewVerticalSliderContainer extends Component {
  state = { current: 0 };
  imageList = null;

  render() {
    const { mediaList } = this.props;
    const { current } = this.state;

    this.imageList = mediaList.filter(media => media.type === MEDIA_TYPE.IMAGE);

    const showPagination = this.imageList.length > 1;

    return (
      <VerticalSlider
        list={this.imageList}
        current={current}
        showPagination={showPagination}
        onSlideChange={this.handleSlideChange}
        onWheel={this.handleWheel}
      />
    );
  }

  showNext() {
    this.setState(state => ({
      current: this.bound(state.current + 1)
    }));
  }

  showPrev() {
    this.setState(state => ({
      current: this.bound(state.current - 1)
    }));
  }

  bound(current) {
    const count = this.imageList.length;
    return (current + count) % count;
  }

  handleSlideChange = index => {
    this.setState({
      current: index
    });
  };

  handleWheel = event => {
    event.preventDefault();
    const delta = event.deltaY;
    if (delta > 0) {
      this.showNext();
    } else {
      this.showPrev();
    }
  };
}

export default OverviewVerticalSliderContainer;
