import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LineNumber from './line-number';
import './tile.css';

const { string, oneOfType, element, arrayOf, any } = PropTypes;

class Tile extends Component {
  static propTypes = {
    /**
     * Title of Tile. Content is displayed in the upper left corner at the same level with 'actions'
     */
    title: string,

    /**
     * Element or array of elements for interaction with Tile. Usually these are buttons. Content is displayed in the upper right corner
     */
    actions: oneOfType([element, arrayOf(element)]),

    /**
     * Tile content. It contains one or more elements
     */
    children: any.isRequired
  };

  static defaultProps = {
    title: '',
    actionList: []
  };

  render() {
    const { children } = this.props;
    return (
      <div className="Tile">
        {this.renderHeader()}
        <div className="Tile-contentHolder">
          <div className="Tile-content">{children}</div>
        </div>
      </div>
    );
  }

  renderHeader() {
    const { title } = this.props;

    if (!title) {
      return <div className="Tile-header">{this.renderActions()}</div>;
    }

    return (
      <LineNumber
        render={({ lines, elementProps }) => (
          <div
            className={cn('Tile-header', {
              'Tile-header--multiline': lines > 1
            })}
          >
            <h3 className="Tile-title" title={title} {...elementProps}>
              {title}
            </h3>
            {this.renderActions()}
          </div>
        )}
      />
    );
  }

  renderActions() {
    const { actionList } = this.props;

    if (!actionList.length) {
      return null;
    }

    return <div className="Tile-actions">{actionList.map(this.renderAction)}</div>;
  }

  renderAction = (item, index) => {
    return (
      <span className="Tile-item" key={index}>
        {item}
      </span>
    );
  };
}

export default Tile;
