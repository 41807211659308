import { takeEvery, put, select } from 'redux-saga/effects';
import * as LOCATION from '../ducks/types/location';
import Profile from 'modules/profile/actions/profile';
import * as fromAccount from 'modules/core/ducks/account';

export function* userLocation() {
  yield takeEvery(LOCATION.CITY_CHOOSE, onCityChoose);
  yield takeEvery(LOCATION.CITY_SUGGESTED_SET, onSuggestedCitySet);
}

function* onCityChoose(action) {
  const { id } = action.payload;

  yield updateProfileCity(id);
}

function* onSuggestedCitySet(action) {
  const { city } = action.payload;

  yield updateProfileCity(city.id);
}

function* updateProfileCity(id) {
  const isAuth = yield isAuthorized();

  if (!isAuth) {
    return;
  }

  yield put(Profile.cityChange(id));
}

function isAuthorized() {
  return select(fromAccount.getIsAuthorized);
}
