import React, { Component } from 'react';

import Media from 'react-media';

import Container from '../container/container';
import FooterNavigation from './footer-navigation/footer-navigation';
import AdditionalInformationFooter from './footer-information/footer-information';
import Author from './footer-copyright/footer-copyright';
import FooterContacts from './footer-contacts/footer-contacts';
import AdditionalInfoItem from '../additional-info-item/additional-info-item';
import {
  IconInstagram,
  IconVk,
  IconFacebook,
  IconYoutube,
  IconOdnoklassniki,
  IconApple,
  IconAndroid
} from '../icons';
import FooterMobileNavigation from './footer-mobile-navigation/footer-mobile-navigation';
import PaymentMethodsMobile from './payment-methods-mobile/payment-methods-mobile';

import './footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <Container>
          <div className="Footer-content">
            <Media
              query="(min-width: 1101px)"
              defaultMatches={false}
              render={this.renderFooterNavigation}
            />
            <Media query="(max-width: 1100px)" render={this.renderFooterContacts} />
            <Media query="(max-width: 1100px)" render={this.renderFooterMobileNavigation} />
            <div className="Footer-additionalInfo">
              <Media
                query="(min-width: 1101px)"
                defaultMatches={false}
                render={this.renderAdditionalInformationFooter}
              />
              <Media query="(max-width: 1100px)" render={this.renderAdditionalInformationSocial} />
              <Media query="(max-width: 1100px)">
                <PaymentMethodsMobile />
              </Media>
              <Media query="(max-width: 1100px)" render={this.renderAdditionalInformationNetwork} />
            </div>
            <div className="Footer-author">
              <Author />
            </div>
          </div>
        </Container>
      </div>
    );
  }

  renderFooterNavigation = () => {
    const { isAuthorized, onAuthorizationClick } = this.props;

    return (
      <div className="Footer-navigation">
        <FooterNavigation isAuthorized={isAuthorized} onAuthorizationClick={onAuthorizationClick} />
      </div>
    );
  };

  renderFooterContacts() {
    return (
      <div className="Footer-contacts">
        <FooterContacts tel="8 (800) 550-98-70" />
      </div>
    );
  }

  renderFooterMobileNavigation = () => {
    return (
      <div className="Footer-navigation">
        <FooterMobileNavigation />
      </div>
    );
  };

  renderAdditionalInformationFooter() {
    return <AdditionalInformationFooter />;
  }

  renderAdditionalInformationSocial() {
    return (
      <div className="Footer-additionalInfoItem">
        <AdditionalInfoItem
          title="Наши аккаунты в соц сетях"
          list={[
            {
              name: 'instagram',
              icon: <IconInstagram />,
              href: 'https://www.instagram.com/mixit_ru/'
            },
            {
              name: 'vk',
              icon: <IconVk />,
              href: 'https://vk.com/mixit_ru'
            },
            {
              name: 'facebook',
              icon: <IconFacebook />,
              href: 'https://www.facebook.com/mixit.ru/'
            },
            {
              name: 'youtube',
              icon: <IconYoutube />,
              href: 'https://www.youtube.com/channel/UCZhmTmXstevt0-LImfpDtqw/featured'
            },
            {
              name: 'odnoklassniki',
              icon: <IconOdnoklassniki />,
              href: 'https://www.ok.ru/profile/576654175956'
            }
          ]}
        />
      </div>
    );
  }

  renderAdditionalInformationNetwork() {
    return (
      <div className="Footer-additionalInfoItem">
        <AdditionalInfoItem
          title="Скачайте наше приложение"
          list={[
            {
              name: 'instagram',
              icon: <IconAndroid />,
              href: 'https://play.google.com/store/apps/details?id=com.shezak.mixit'
            },
            {
              name: 'vk',
              icon: <IconApple />,
              href:
                'https://itunes.apple.com/ru/app/mixit-%D0%BD%D0%B0%D1%82%D1%83%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D0%B0/id1237286540?mt=8'
            }
          ]}
        />
      </div>
    );
  }
}

export default Footer;
