import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ingredientShape from '../../prop-types/ingredient-shape';
import { getIngredientLink } from 'routes/links';

const { arrayOf } = PropTypes;

class IngredientTitles extends Component {
  static propTypes = {
    ingredientList: arrayOf(ingredientShape),
    withLinks: PropTypes.bool
  };

  static defaultProps = {
    withLinks: false
  };

  render() {
    const { ingredientList } = this.props;
    if (!ingredientList) {
      return null;
    }
    const lastIndex = ingredientList.length - 1;
    return ingredientList.map((item, index) => (
      <Fragment key={item.id}>
        {this.renderTitle(item)}
        {index !== lastIndex && ', '}
      </Fragment>
    ));
  }

  renderTitle(item) {
    const { withLinks } = this.props;

    if (withLinks) {
      return (
        <Link className="ConsistencyContent-link" to={getIngredientLink(item)}>
          {item.title}
        </Link>
      );
    }

    return <span>{item.title}</span>;
  }
}

export default IngredientTitles;
