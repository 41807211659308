import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import IngredientDetails from '../ingredient-details/ingredient-details';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import MainCarousel from 'modules/core/components/main-carousel/main-carousel';
import ingredientShape from '../../prop-types/ingredient-details-shape';
import ProductContainer from 'modules/product/containers/product';
import Loader from 'modules/core/components/loader/loader';

class FullIngredient extends Component {
  static propTypes = {
    ingredient: ingredientShape
  };
  render() {
    const { ingredient, isLoading } = this.props;
    // const list = [1, 2, 3, 4];

    if (isLoading) {
      return (
        <div style={{ height: '60vh', position: 'relative' }}>
          <Loader type="spin" />
        </div>
      );
    }

    const title = this.getTitle(ingredient);

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <SectionDefault>
          <Container>
            <IngredientDetails ingredient={ingredient} />
          </Container>
        </SectionDefault>

        {/* <SectionDefault title="Продукты с содержанием Витамин F">
          <Container>
            <MainCarousel>
              {list.map(id => this.renderProduct(id))}
            </MainCarousel>
          </Container>
        </SectionDefault> */}
      </Fragment>
    );
  }

  renderProduct(id) {
    return (
      <MainCarousel.Item key={id}>
        <ProductContainer id={id} listId="ingredient" />
      </MainCarousel.Item>
    );
  }

  getTitle(ingredient) {
    if (!ingredient) {
      return 'MIXIT — интернет-магазин уходовой и декоративной косметики';
    }

    if (!ingredient.title) {
      return ingredient.details.media.title;
    }

    return ingredient.title;
  }
}

export default FullIngredient;
