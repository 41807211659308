import React, { Component } from 'react';

import './icon-copy.css';

class IconCopy extends Component {
  render() {
    return (
      <svg className="IconCopy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path d="M53.98 9.14H50c-.08 0-.16.03-.23.05V5.02c0-2.77-2.3-5.02-5.14-5.02H10.22a5.09 5.09 0 0 0-5.14 5.02v46.85c0 2.77 2.3 5.02 5.14 5.02h6.03v2.27A4.9 4.9 0 0 0 21.2 64h32.78a4.9 4.9 0 0 0 4.94-4.84V13.99a4.9 4.9 0 0 0-4.94-4.85zM7.1 51.87V5.02c0-1.65 1.4-2.99 3.1-2.99h34.43c1.71 0 3.1 1.34 3.1 3v46.84c0 1.64-1.39 2.99-3.1 2.99H10.22a3.05 3.05 0 0 1-3.1-3zm49.78 7.29c0 1.55-1.3 2.8-2.91 2.8H21.2a2.87 2.87 0 0 1-2.91-2.8v-2.27h26.35a5.09 5.09 0 0 0 5.14-5.02V11.13c.07.02.15.04.23.04h3.97c1.6 0 2.9 1.27 2.9 2.82v45.17z" />
        <path d="M38.6 13.2H16.25a1.02 1.02 0 1 0 0 2.04H38.6a1.02 1.02 0 1 0 0-2.03zM38.6 21.33H16.25a1.02 1.02 0 1 0 0 2.04H38.6a1.02 1.02 0 1 0 0-2.04zM38.6 29.46H16.25a1.02 1.02 0 1 0 0 2.03H38.6a1.02 1.02 0 1 0 0-2.03zM28.44 37.59H16.25a1.02 1.02 0 1 0 0 2.03h12.2a1.02 1.02 0 1 0 0-2.03z" />
      </svg>
    );
  }
}

export default IconCopy;
