import ProductsPopular from 'modules/product/components/products-popular/products-popular';
import { connect } from 'react-redux';
import * as fromGroups from 'modules/product/ducks/groups';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({
  popular: fromGroups.getPopular
});

const mapDispatchToProps = {
  showItems: fromGroups.actions.showPopular
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPopular);
