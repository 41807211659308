import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import Media from 'modules/core/components/media/media';
import productShape from 'modules/product/prop-types/product-shape';
import { getProductLink } from 'routes/links';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

import Price from '../price-formatted/price-formatted';

import './order-product.css';

const { number, bool } = PropTypes;

class OrderProduct extends Component {
  static propTypes = {
    /**
     * Product
     **/
    product: productShape,

    /**
     * Product amount
     **/
    amount: number.isRequired,
    /** Параметр отделяющий цену в верстке */
    separatePrice: bool
  };

  render() {
    const { product, amount, price, separatePrice, listId, isGift } = this.props;

    return (
      <ProductAnalyticsProvider
        productId={product.slug}
        listId={listId}
        isGift={isGift}
        render={({ productRef, handleClick }) => (
          <div
            className={cn('OrderProduct', {
              'OrderProduct--separatePrice': separatePrice
            })}
            ref={productRef}
          >
            <div className="OrderProduct-imageGroup">
              <Media
                className="OrderProduct-image"
                media={product.announcementMedia}
                resizeWidth={80}
                stretch="horizontal"
                nocaption
              />
            </div>
            <div className="OrderProduct-contentGroup">
              <div className="OrderProduct-contentCol">
                <div className="OrderProduct-description">{product.description}</div>
                <h3 className="OrderProduct-title">
                  {
                    this.renderProductLinkComponent(handleClick, "OrderProduct-link")
                  }
                </h3>
                {/* <div className="OrderProduct-selectedVariant">250 мг</div> */}
              </div>
              <div className="OrderProduct-contentCol">
                <div className="OrderProduct-purchaseDetails">
                  <span className="OrderProduct-amount">{amount}</span>
                  &times;
                  <span className="OrderProduct-price">
                    <Price number={price.current} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
  renderProductLinkComponent = (handleClick, className) => {
    const {
      product,isGift 
    } = this.props;

    const UsedTag = isGift ? "div": Link;
    const paremeters = {
      ...(!isGift && {to: getProductLink(product)}),
      ...(!isGift && {target: "_blank"}),
      ...(!isGift && {rel: "noreferrer noopener"}),
    } 
    
    return (
      <UsedTag
        {...paremeters}
        className={className}
        onClick={handleClick()}
      >
        {product.title}
      </UsedTag>
    )
  }
}

export default OrderProduct;
