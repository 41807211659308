import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import { getIndexLink } from 'routes/links';

import Page from 'modules/core/components/page/page';
import Loader from 'modules/core/components/loader/loader';
import OrderInfoContainer from '../containers/order-info';

import OrderingScene from '../scenes/ordering-scene/ordering-scene';
// import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import FreeDeliveryNotification from 'modules/core/containers/free-delivery-notification';

// import SectionDefault from 'modules/core/components/section-default/section-default';
import { createStructuredSelector } from 'reselect';
import * as fromOrders from '../ducks/orders';
import * as fromAccount from 'modules/core/ducks/account';
// import Notification from 'modules/core/components/notification/notification';
// import Link from 'modules/core/components/link/short-link';
import Container from 'modules/core/components/container/container';
import Helmet from 'react-helmet';
import * as fromDialogs from 'modules/core/ducks/dialogs';

const mapStateToProps = createStructuredSelector({
  id: fromOrders.getIdByRouteParams,
  isLoaded: fromOrders.makeGetIsLoaded(fromOrders.makeGetItem(fromOrders.getIdByRouteParams)),
  authIsReady: fromAccount.getStatusIsReady,
  isAuthorized: fromAccount.getIsAuthorized,
  personalInfoComplete: fromOrders.getPersonalInfoComplete
});

const mapDispatchToProps = {
  show: fromOrders.actions.show,
  openAuthDialog: fromDialogs.actions.openAuthDialog
};

function isReady(props) {
  return props.authIsReady;
}

class OrderPage extends Component {
  static path = '/ordering/:id';
  static exact = true;

  render() {
    const { isLoaded, isAuthorized } = this.props;

    return (
      <Page
        title="Оформление заказа"
        notification={<FreeDeliveryNotification />}
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Оформление заказа'
          }
        ]}
        withoutSaleNotification
      >
        {!isReady(this.props) || !isLoaded ? (
          <div style={{ position: 'relative', minHeight: '160px' }}>
            <Loader type="spin" />
          </div>
        ) : (
          <Fragment>
            {!isAuthorized && (
              <Fragment>
                <Container narrow>
                  <Helmet>
                    <title>Оформление заказа</title>

                    <meta name="description" content="Оформление заказа - интернет-магазин MIXIT" />
                  </Helmet>
                  {/* <Notification type="info">
                    Уже есть аккаунт?{' '}
                    <Link component="button" type="button" onClick={() => openAuthDialog()}>
                      Авторизоваться
                    </Link>
                  </Notification> */}
                </Container>
                {/* <br /> */}
              </Fragment>
            )}

            <Media query="(max-width: 840px)">
              <OrderInfoContainer id={this.props.id} />
            </Media>

            <OrderingScene id={this.props.id} />

            {/* <SectionDefault>
              <Container>
                <FeedbackCall />
              </Container>
            </SectionDefault> */}
          </Fragment>
        )}
      </Page>
    );
  }

  componentDidMount() {
    this.update(this.props);
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  update(props) {
    if (isReady(props)) {
      this.props.show(props.id, true);
    }
  }

  UNSAFE_componentWillUpdate(props) {
    if (this.props.id !== props.id || this.props.authIsReady !== props.authIsReady) {
      this.update(props);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
