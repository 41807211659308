import React, { Component } from 'react';
import AnimatedLink from './animated-link';

class AnimatedButtonLink extends Component {
  render() {
    const { ...props } = this.props;

    return <AnimatedLink component="button" type="button" {...props} />;
  }
}

export default AnimatedButtonLink;
