import React, { Component } from 'react';
import Media from 'react-media';

import SelectCityDesktop from '../select-city-desktop/select-city-desktop';
import SelectCityMobile from '../select-city-mobile/select-city-mobile';

import './city-select-panel.css';

class CitySelectPanel extends Component {
  render() {
    const { cityList, activeOptionIndex, caption, isCityListAvailable, onCitySelect } = this.props;

    return (
      <div className="CitySelectPanel">
        <div className="CitySelectPanel-search">
          <Media query="(min-width: 1101px)">
            {isDesktop =>
              isDesktop ? (
                <SelectCityDesktop
                  cityList={cityList}
                  activeOptionIndex={activeOptionIndex}
                  isDisabled={!isCityListAvailable}
                  onCitySelect={onCitySelect}
                />
              ) : (
                <SelectCityMobile
                  cityList={cityList}
                  activeOptionIndex={activeOptionIndex}
                  isDisabled={!isCityListAvailable}
                  onCitySelect={onCitySelect}
                />
              )
            }
          </Media>
        </div>

        <div className="ShopsGrid-caption">{caption}</div>
      </div>
    );
  }
}

export default CitySelectPanel;
