import { createReducer } from 'modules/utils/dux';
import { createSelector } from 'reselect';

const reducer = createReducer();

export default reducer({
  'DELIVERY_METHOD/IML': {
    id: 'DELIVERY_METHOD/IML',
    // title: 'Пункты самовывоза IML',
    pickPointType: 'PICK_POINT_TYPE/IML',
    pickPointRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false
  },
  'DELIVERY_METHOD/PICKPOINT': {
    id: 'DELIVERY_METHOD/PICKPOINT',
    // title: 'Постаматы PickPoint',
    pickPointType: 'PICK_POINT_TYPE/PICK_POINT',
    pickPointRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false
  },
  'DELIVERY_METHOD/COURIER': {
    id: 'DELIVERY_METHOD/COURIER',
    // title: 'Курьер',
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false
  },
  'DELIVERY_METHOD/POSTDATA': {
    id: 'DELIVERY_METHOD/POSTDATA',
    // title: 'Почта России',
    fullNameRequired: true,
    addressRequired: true,
    isNoDeliveryRussia: false
  },
  'DELIVERY_METHOD/POSTDATA_ADDRESS': {
    id: 'DELIVERY_METHOD/POSTDATA_ADDRESS',
    // title: 'Почта России',
    fullNameRequired: true,
    addressRequired: true,
    isNoDeliveryRussia: false
  },
  'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN': {
    id: 7,
    name: 'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN',
    // title: 'Доставка в Казахстан',
    description: 'Доставка в крупнейшие города Казахстана',
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: true
  },
  'DELIVERY_METHOD/DELIVERY_TO_BELARUS': {
    id: 8,
    name: 'DELIVERY_METHOD/DELIVERY_TO_BELARUS',
    // title: 'Доставка в Беларусь',
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: true
  },
  'DELIVERY_METHOD/PPD': {
    id: 'DELIVERY_METHOD/PPD',
    // title: 'Пункт самовывоза заказов',
    description: 'Ты можешь забрать заказ в любом удобном для тебя магазине',
    pickPointType: 'PICK_POINT_TYPE/PPD',
    pickPointRequired: true,
    phoneRequired: true,
    fullNameRequired: true,
    isNoDeliveryRussia: false
  }
});

const getRoot = state => state.ordering.deliveryMethods;

export const getAll = createSelector(getRoot, root => root);
