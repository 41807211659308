import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// import { Scrollbars } from 'react-custom-scrollbars';
import ShadowScrollbars from 'modules/core/components/shadow-scrollbars/shadow-scrollbars';
import Media from 'react-media';

import { getBasketLink } from 'routes/links';

import Link from 'modules/core/components/link/short-link';
import ConditionalWrapper from 'modules/core/components/conditional-wrapper/conditional-wrapper';

import OrderProductContainer from '../../containers/order-product';
// import Promo from '../coupon-field/promo';
import Price from '../price-formatted/price-formatted';

import productShape from 'modules/product/prop-types/product-shape';

import './product-overview.css';

const { number, string, shape, arrayOf, func } = PropTypes;

class ProductOverview extends Component {
  static propTypes = {
    /** Displayed products list **/
    productList: arrayOf(
      shape({
        product: productShape,
        amount: number.isRequired
      })
    ),
    /** Delivery cost **/
    delivery: number.isRequired,
    discount: number.isRequired,
    /** Promotional code for purchase **/
    promocode: string,
    onPromocodeChange: func.isRequired,
    onPromocodeSubmit: func.isRequired
  };

  static defaultProps = {
    promocode: ''
  };

  render() {
    const {
      productList,
      productsCost,
      totalCost,
      deliveryCost,
      // estimatedDeliveryCost,
      discount,
      deliveryMethodExist,
      method
      // promocode
    } = this.props;

    const isPPD = method && method.pickPointType && method.pickPointType === 'PICK_POINT_TYPE/PPD';

    return (
    <Media query="(max-width: 840px)">
      {isMobile => (
        <div className="Ordering_ProductOverview">
          <div className="Ordering_ProductOverview-titleRow">
            <h2 className="Ordering_ProductOverview-title">Ваш заказ</h2>
            <div className="Ordering_ProductOverview-link">
              <Link component={RouterLink} to={getBasketLink()}>
                Редактировать
              </Link>
            </div>
          </div>

          {productList && productList.length > 0 && (
            <ul className="Ordering_ProductOverview-productList">
              {/* {isMobile ? (
                <>
                  {productList.map(this.renderOrderProduct)}
                </>
              ) : (
                <Scrollbars 
                  autoHeight
                  autoHeightMax={480}
                >
                  {productList.map(this.renderOrderProduct)}
                </Scrollbars>
              )} */}
              <ConditionalWrapper
                condition={!isMobile}
                // wrapper={children => <Scrollbars 
                //   autoHeight
                //   autoHeightMax={480}
                // >
                //   {children}
                // </Scrollbars>}
                wrappElement={<ShadowScrollbars 
                  autoHeight
                  autoHeightMax={480}
                />}
              >
                {productList.map(this.renderOrderProduct)}
              </ConditionalWrapper>
            </ul>
          )}

          {/* <div className="Ordering_ProductOverview-promo">
            <Promo
              device="mobile"
              value={promocode}
              onChange={this.handlePromocodeChange}
              onSubmit={this.handlePromocodeSubmit}
            />
          </div> */}

          <div className="Ordering_ProductOverview-summary">
            <div className="Ordering_ProductOverview-point">
              <span className="Ordering_ProductOverview-key">Стоимость товаров:</span>
              <span className="Ordering_ProductOverview-value">
                <Price number={productsCost} variant="secondary" />
              </span>
            </div>
            {/* {deliveryCost === null ? (
              estimatedDeliveryCost && (
                <div className="Ordering_ProductOverview-point">
                  <span className="Ordering_ProductOverview-key">Стоимость доставки:</span>
                  <span className="Ordering_ProductOverview-value">
                    <Price number={estimatedDeliveryCost} variant="secondary" />
                  </span>
                </div>
              )
            ) : (
              <div className="Ordering_ProductOverview-point">
                <span className="Ordering_ProductOverview-key">Доставка:</span>
                <span className="Ordering_ProductOverview-value">
                  <Price number={deliveryCost} variant="secondary" />
                </span>
              </div>
            )} */}

            {(Boolean(deliveryCost) || deliveryMethodExist) && (
              <div className="Ordering_ProductOverview-point">
                <span className="Ordering_ProductOverview-key">Стоимость доставки:</span>
                <span className="Ordering_ProductOverview-value">
                  {isPPD ? <b>бесплатно</b> : <Price number={deliveryCost} variant="secondary" />}
                  {/* <Price number={Number(deliveryCost)} variant="secondary" /> */}
                </span>
              </div>
            )}

            {/* PREVIOUS STATE */}
            {/* {!!deliveryCost && !estimatedDeliveryCost && (
              <div className="Ordering_ProductOverview-point">
                <span className="Ordering_ProductOverview-key">Стоимость доставки:</span>
                <span className="Ordering_ProductOverview-value">
                  <Price number={deliveryCost} variant="secondary" />
                </span>
              </div>
            )}
            {!!deliveryCost && !!estimatedDeliveryCost && (
              <div className="Ordering_ProductOverview-point qqq">
                <span className="Ordering_ProductOverview-key">Стоимость доставки:</span>
                <span className="Ordering_ProductOverview-value">
                  <Price number={estimatedDeliveryCost} variant="secondary" />
                </span>
              </div>
            )} */}
            {discount > 0 && (
              <div className="Ordering_ProductOverview-point">
                <span className="Ordering_ProductOverview-key">Скидка:</span>
                <span className="Ordering_ProductOverview-value">
                  <b>
                    <Price number={-discount} variant="discount" />
                  </b>
                </span>
              </div>
            )}
            <div className="Ordering_ProductOverview-total">
              <span className="Ordering_ProductOverview-totalKey">Итого:</span>
              <span className="Ordering_ProductOverview-totalValue">
                <Price number={totalCost} />
              </span>
            </div>
          </div>
        </div>
      )}
    </Media>
    );
  }

  renderOrderProduct = item => {
    return (
      <li className="Ordering_ProductOverview-item" key={item.product}>
        <OrderProductContainer
          slug={item.product}
          isGift={item.gift}
          price={item.price}
          amount={item.amount}
          listId="order"
        />
      </li>
    );
  };

  handlePromocodeChange = value => {
    const { onPromocodeChange } = this.props;
    onPromocodeChange(value);
  };

  handlePromocodeSubmit = () => {
    const { onPromocodeSubmit } = this.props;
    onPromocodeSubmit();
  };
}

export default ProductOverview;
