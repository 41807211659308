import React, { Component } from 'react';
import './icon-instagram2.css';

class IconInstagram2 extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'small':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Слой_1"
            viewBox="0 0 16 16"
            className="IconInstagram2"
          >
            <path d="M11.6 1H4.4C2.5 1 1 2.5 1 4.4v7.1c0 2 1.5 3.5 3.4 3.5h7.1c1.9 0 3.4-1.5 3.4-3.4V4.4C15 2.5 13.5 1 11.6 1zM14 11.6c0 1.3-1.1 2.4-2.4 2.4H4.4C3.1 14 2 12.9 2 11.6V4.4C2 3.1 3.1 2 4.4 2h7.1C12.9 2 14 3.1 14 4.4v7.2z" />
            <path d="M8 4.1c-2.3 0-4.1 1.8-4.1 4s1.9 4 4.1 4 4.1-1.8 4.1-4-1.8-4-4.1-4zm0 7c-1.7 0-3.1-1.4-3.1-3s1.4-3 3.1-3 3.1 1.4 3.1 3-1.4 3-3.1 3zM12.2 2.8c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z" />
          </svg>
        );

      case 'medium':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" className="IconInstagram2">
            <path d="M33 11.8c-.1-1.6-.3-2.7-.7-3.6-.4-1-1-1.9-1.7-2.7-.8-.8-1.7-1.4-2.7-1.7-1-.4-2-.6-3.6-.7-1.7-.1-2.2-.1-6.2-.1-4.1 0-4.6 0-6.2.1-1.6.1-2.7.3-3.7.7s-1.9 1-2.7 1.7c-.7.8-1.3 1.7-1.7 2.7s-.6 2-.7 3.6C3 13.4 3 13.9 3 18s0 4.6.1 6.2c.1 1.6.3 2.7.7 3.6.4 1 1 1.9 1.7 2.7.8.8 1.7 1.4 2.7 1.7 1 .4 2 .6 3.6.7 1.7.1 2.2.1 6.2.1 4.1 0 4.6 0 6.2-.1 1.6-.1 2.7-.3 3.6-.7 2-.8 3.6-2.4 4.4-4.4.4-1 .6-2 .7-3.6.1-1.6.1-2.1.1-6.2v-6.2zm-2.7 12.3c-.1 1.5-.3 2.3-.5 2.8-.5 1.3-1.5 2.3-2.8 2.8-.5.2-1.3.5-2.8.5-1.6.1-2.1.1-6.1.1s-4.5 0-6.1-.1c-1.5-.1-2.3-.3-2.8-.5-.7-.2-1.2-.6-1.7-1.1s-.9-1.1-1.1-1.7c-.2-.5-.5-1.3-.5-2.8-.1-1.6-.1-2.1-.1-6.1s0-4.5.1-6.1c.1-1.5.3-2.3.5-2.8.2-.6.6-1.2 1.1-1.7s1.1-.8 1.7-1.1c.6-.2 1.3-.4 2.8-.5 1.6-.1 2.1-.1 6.1-.1s4.5 0 6.1.1c1.5.1 2.3.3 2.8.5.7.2 1.2.6 1.7 1.1s.9 1.1 1.1 1.7c.2.5.5 1.3.5 2.8.1 1.6.1 2.1.1 6.1s-.1 4.5-.1 6.1z" />
            <path d="M18.1 10.3c-4.3 0-7.7 3.5-7.7 7.7s3.5 7.7 7.7 7.7c4.3 0 7.7-3.5 7.7-7.7s-3.5-7.7-7.7-7.7zm0 12.7c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.3 5-5 5zM27.9 10c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8z" />
          </svg>
        );

      default:
    }
  }

  getSize() {
    const { size } = this.props;

    return size;
  }
}

export default IconInstagram2;
