import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './consistency-legend.css';

const { string, number, shape, arrayOf } = PropTypes;

class ConsistencyLegend extends Component {
  static propTypes = {
    list: arrayOf(
      shape({
        title: string.isRequired,
        id: number.isRequired,
        name: string.isRequired,
        color: string.isRequired,
        list: arrayOf(number).isRequired
      })
    )
  };

  render() {
    const { list } = this.props;
    return <ul className="ConsistencyLegend">{list.map(this.renderItem.bind(this))}</ul>;
  }

  renderItem(item, index) {
    return (
      <li key={index} className="ConsistencyLegend-remark" style={{ color: item.color }}>
        <div className="ConsistencyLegend-icon" />
        <span className="ConsistencyLegend-text">{item.title}</span>
      </li>
    );
  }
}

export default ConsistencyLegend;
