export const REHYDRATE = 'core/account/REHYDRATE';
export const READY = 'core/account/READY';
export const SIGN_OUT = 'core/account/SIGN_OUT';

export const AUTHORIZATION_REQUEST = 'core/account/AUTHORIZATION_REQUEST';
export const AUTHORIZATION_SUCCESS = 'core/account/AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_ERROR = 'core/account/AUTHORIZATION_ERROR';
export const AUTHORIZATION_RESET = 'core/account/AUTHORIZATION_RESET';

export const AUTHORIZATION_RENEW_REQUEST = 'core/account/AUTHORIZATION_RENEW_REQUEST';
export const AUTHORIZATION_RENEW_RESPONSE = 'core/account/AUTHORIZATION_RENEW_RESPONSE';

export const AUTHORIZATION_READY = 'core/account/AUTHORIZATION_READY';

export const REGISTRATION_REQUEST = 'core/account/REGISTRATION_REQUEST';
export const REGISTRATION_ERROR = 'core/account/REGISTRATION_ERROR';
export const REGISTRATION_COMPLETE = 'core/account/REGISTRATION_COMPLETE';
