import React, { Component } from 'react';
import './icon-video.css';

class IconVideo extends Component {
  render() {
    return (
      <svg className="IconVideo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M20.3 8.2c-.5-.3-1-.3-1.5-.1l-1.8 1V7.4c0-.8-.6-1.4-1.4-1.4H4.4C3.6 6 3 6.6 3 7.4v9.1c0 .9.6 1.5 1.4 1.5h11.2c.8 0 1.4-.6 1.4-1.4v-1.8l1.8 1c.2.1.5.2.7.2.3 0 .5-.1.8-.2.4-.3.7-.7.7-1.2V9.4c0-.5-.2-.9-.7-1.2zM16 16.6c0 .2-.2.4-.4.4H4.4c-.2 0-.4-.2-.4-.4V7.4c0-.2.2-.4.4-.4h11.2c.2 0 .4.2.4.4v9.2zm4-2.1c0 .2-.1.3-.2.3-.1.1-.3.1-.6 0L17 13.6v-3.4L19.3 9c.2-.1.4-.1.5 0 .1 0 .2.2.2.3v5.2z" />
      </svg>
    );
  }
}

export default IconVideo;
