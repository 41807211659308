export default {
  serialize(basket, entities) {
    const goods = basket.goods.map(item => {
      const { details, isDetailsLoaded, ...restProduct } = item.product;
      return {
        ...item,
        product: {
          ...restProduct
        }
      };
    });

    return JSON.stringify({
      ...basket,
      goods
    });
  },

  deserialize(persistedJson) {
    const basket = JSON.parse(persistedJson);

    return basket;
  }
};
