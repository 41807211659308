function initMassMediaRequest() {
  return function getItemRequest(parameters = null) {
    console.log('parameters', parameters);
    return {
      method: 'GET',
      path: `/blog-mass-media`,
      query: parameters
    };
  };
}

export default function initCategoryRequest(createRequest) {
  return function _getCategoryRequest() {
    return {
      item: createRequest(initMassMediaRequest())
    };
  };
}
