import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import ButtonHeart from 'modules/core/components/button-heart/button-heart';
import SocialButton from 'modules/social/components/button/button';

import ArticleAction from '../../article-action/article-action';

import './footer.css';

const { number, bool, arrayOf, shape, func, oneOf, object } = PropTypes;

class Footer extends Component {
  static propTypes = {
    /** Массив социальных сетей, в которых можно поделиться статьей */
    shares: arrayOf(
      shape({
        name: oneOf(['instagram', 'facebook', 'vk']).isRequired,
        amount: number.isRequired
      }).isRequired
    ),

    /** Автор статьи */
    author: object.isRequired,

    /** Количество лайков */
    likes: number.isRequired,

    /** Флаг лайкнутой статьи */
    isLiked: bool.isRequired,

    /** Функция-обработчик действия "Лайкнуть статью" */
    onLike: func.isRequired,

    /** Функция-обработчик действия "Вернуть лайк" */
    onUnlike: func.isRequired,

    /** Функция-обработчик действия "Поделиться статьей" */
    onShare: func.isRequired
  };

  render() {
    const { author, likes, shares, isLiked } = this.props;

    return (
      <div className="Article_Footer">
        <div className="Article_Footer-author">
          {author.firstName} {author.lastName}
        </div>
        <div className="Article_Footer-actionGroup">
          <div className="Article_Footer-socialNetworkColumn">
            <ul className="Article_Footer-socialNetworkList">
              {shares.map(this.renderSocailNetworkItem)}
            </ul>
          </div>

          <div className="Article_Footer-likeColumn">
            <Media query="(max-width: 480px)">
              {isLowMobile => (
                <ArticleAction
                  button={
                    <ButtonHeart onClick={this.handleButtonLike} size="small" isActive={isLiked} />
                  }
                  amount={likes}
                  direction={isLowMobile ? 'column' : 'row'}
                />
              )}
            </Media>
          </div>
        </div>
      </div>
    );
  }

  renderSocailNetworkItem = ({ name, amount, link }, index) => {
    return (
      <li key={index} className="Article_Footer-socialNetworkItem">
        <Media query="(max-width: 480px)">
          {isLowMobile => (
            <ArticleAction
              button={
                <SocialButton
                  social={name}
                  size="small"
                  onClick={this.handleButtonShare(name)}
                  href={link}
                  component="a"
                  target="_blank"
                />
              }
              amount={amount}
              direction={isLowMobile ? 'column' : 'row'}
            />
          )}
        </Media>
      </li>
    );
  };

  handleButtonLike = () => {
    const { isLiked, onLike, onUnlike } = this.props;

    if (isLiked) {
      onUnlike();
      return;
    }

    onLike();
  };

  handleButtonShare = name => () => {
    const { onShare } = this.props;

    onShare(name);
  };
}

export default Footer;
