import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ProfileScene from '../components/profile-scene/profile-scene';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

import { createStructuredSelector } from 'reselect';

import * as fromAccount from 'modules/core/ducks/account';
import { actions as Dialog } from 'modules/core/ducks/dialogs';

import { getIndexLink } from 'routes/links';

const mapState = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

const mapActions = {
  openAuthDialog: Dialog.openAuthDialog
};

class Profile extends Component {
  static path = '/profile';

  render() {
    const { isAuthorized } = this.props;

    if (!isAuthorized) {
      return <Redirect to={getIndexLink()} />;
    }

    return (
      <Fragment>
        <ProfileScene />
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Fragment>
    );
  }

  componentDidMount() {
    const { isAuthorized, openAuthDialog } = this.props;

    if (!isAuthorized) {
      openAuthDialog();
    }
  }
}

export default connect(mapState, mapActions)(Profile);
