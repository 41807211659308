import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from 'modules/core/components/link/short-link';

import cityShape from '../../prop-types/city-shape';

import './city-dialog.css';

const { bool } = PropTypes;

class Button extends Component {
  static propTypes = {
    city: cityShape.isRequired,
    isActive: bool
  };

  static defaultProps = {
    isActive: false
  };

  render() {
    const { city, isActive } = this.props;

    return (
      <Link component="button" active={isActive} onClick={this.handleButtonClick} noUnderline>
        {city.title}
      </Link>
    );
  }

  handleButtonClick = () => {
    const { city, onClick } = this.props;

    onClick(city.id);
  };
}

export default Button;
