import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromOrders from '../ducks/orders';
import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromAccount from 'modules/core/ducks/account';
import PersonalInfoSummary from '../components/personal-info-summary/personal-info-summary';

const mapStateToProps = createStructuredSelector({
  personalInfo: fromOrders.getPersonalInfoById,
  profileFirstName: fromProfile.getFirstName,
  profileLastName: fromProfile.getLastName,
  profilePhone: fromProfile.getPhone,
  isAuthorized: fromAccount.getIsAuthorized
});

class PersonalInfoSummaryContainer extends Component {
  render() {
    const { personalInfo, isAuthorized, profileFirstName, profileLastName, profilePhone, ...rest } = this.props;

    // const editAbility = (isAuthorized && !profileFirstName) || !profilePhone;
    return (
      <PersonalInfoSummary
      email={personalInfo.email}
      firstName={personalInfo.firstName}
      // firstName={profileFirstName}
        lastName={personalInfo.lastName}
        phone={personalInfo.phone}
        editAbility={true}
        {...rest}
      />
    );
  }
}

export default connect(mapStateToProps)(PersonalInfoSummaryContainer);
