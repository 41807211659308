import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReadNextArticle from '../components/read-next-article/read-next-article';

import * as fromArticleList from '../ducks/article-list';

const mapStateToProps = createStructuredSelector({
  article: fromArticleList.getItemAdBySlug
});

class ReadNextArticleContainer extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    return <ReadNextArticle {...this.props} />;
  }
}

export default connect(mapStateToProps)(ReadNextArticleContainer);
