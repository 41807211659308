import React, { Component } from 'react';

import CityButtonContainer from '../../containers/city-button';

import range from './helpers';
const COLUMN_NUMBERS = 4;

class Grid extends Component {
  render() {
    return (
      <div className="CityDialog-grid">
        {range(COLUMN_NUMBERS).map(number => this.renderColumn(number))}
      </div>
    );
  }

  renderColumn(number) {
    const { list } = this.props;
    const columnSize = Math.ceil(list.length / COLUMN_NUMBERS);
    const cityOffset = number * columnSize;
    return (
      <div className="CityDialog-column" key={number}>
        {this.renderCityList(cityOffset, columnSize)}
      </div>
    );
  }

  renderCityList(start, size) {
    const { list } = this.props;
    const cities = list.slice(start, start + size);
    return <ul className="CityDialog-list">{cities.map(this.renderCity)}</ul>;
  }

  renderCity = id => {
    const { currentId } = this.props;

    return (
      <li key={id} className="CityDialog-item">
        <CityButtonContainer id={id} isActive={currentId === id} />
      </li>
    );
  };
}

export default Grid;
