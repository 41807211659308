export const REQUEST = 'profile/profile/REQUEST';
export const RESPONSE = 'profile/profile/RESPONSE';
export const FULL_NAME_CHANGE = 'profile/profile/FULL_NAME_CHANGE';
export const PHOTO_CHANGE = 'profile/profile/PHOTO_CHANGE';
export const PHOTO_CHANGE_RESPONSE = 'profile/profile/PHOTO_CHANGE_RESPONSE';
export const PHONE_CHANGE = 'profile/profile/PHONE_CHANGE';
export const ADDRESS_CHANGE = 'profile/profile/ADDRESS_CHANGE';
export const BIRTH_DATE_CHANGE = 'profile/profile/BIRTH_DATE_CHANGE';
export const CITY_CHANGE = 'profile/profile/CITY_CHANGE';
export const CHANGE = 'profile/profile/CHANGE';
export const READY = 'profile/profile/READY';

/** перед рендером компонента (вызывается с компонента), отлавливается в саге */
export const PHYSICAL_TRAITS_SHOW = 'profile/profile/PHYSICAL_TRAITS_SHOW';
/** ответ с данными по изначальному запросу данных*/
export const PHYSICAL_TRAITS_INITIAL_RESPONSE = 'profile/profile/PHYSICAL_TRAITS_INITIAL_RESPONSE';
/** запрос на изменение данных (по нажатию на "сохранить") */
export const PHYSICAL_TRAITS_CHANGE = 'profile/profile/CHANGE_PHYSICAL_TRAITS_CHANGE';
/** сохранение измененных данных в хранилище до отправки запросов */
export const PHYSICAL_TRAITS_TEMPORARY_SAVE = 'profile/profile/PHYSICAL_TRAITS_TEMPORARY_SAVE';
/** ответ с данными по запросу на изменение */
export const PHYSICAL_TRAITS_RESPONSE = 'profile/profile/PHYSICAL_TRAITS_RESPONSE';
/** если фактически зменений не произошло, запросы не отправились, отключаем лоадинг установленный на изменение */
export const PHYSICAL_TRAITS_RESET_CHANGES_LOADING =
  'profile/profile/PHYSICAL_TRAITS_RESET_CHANGES_LOADING';
/** если при отправке запросов произошла ошибка */
export const PHYSICAL_TRAITS_ERROR = 'profile/profile/PHYSICAL_TRAITS_ERROR';
/** Закрытие диалога уведомления о изменении */
export const PHYSICAL_TRAITS_CONFIRM_DIALOG_CLOSE =
  'profile/profile/PHYSICAL_TRAITS_CONFIRM_DIALOG_CLOSE';

export const DROPDOWN_OPEN = 'profile/profile/DROPDOWN_OPEN';
export const DROPDOWN_CLOSE = 'profile/profile/DROPDOWN_CLOSE';
