import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { calendarIcon, durationIcon, termIcon } from './icons';

import './condition-list.css';

const icons = [calendarIcon, termIcon, durationIcon];

class ConditionList extends Component {
  static propTypes = {
    /**
     *
     */
    condition: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string
    })
  };

  render() {
    const { conditions } = this.props;
    return (
      <ul className="ConditionList">
        {conditions.map((condition, key) => (
          <li key={key} className="ConditionList-item">
            <img className="ConditionList-img" src={this.getIcon(condition.image, key)} alt="" />
            <div className="ConditionList-name">{condition.title}</div>
            <div className="ConditionList-descr">{condition.subtitle}</div>
          </li>
        ))}
      </ul>
    );
  }

  getIcon(image, key) {
    if (image) {
      return image;
    }
    // fallback
    return icons[key] ? icons[key] : icons[0];
  }
}

export default ConditionList;
