import React, { Component, Children } from 'react';
import './grid.css';

class Grid extends Component {
  render() {
    return (
      <div className="Product_Grid">
        {Children.map(this.props.children, (product, key) => (
          <div key={key} className="Product_Grid-item">
            {product}
          </div>
        ))}
      </div>
    );
  }
}

export default Grid;
