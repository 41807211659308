import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-package.css';

class IconPackage extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 24 24" className="IconPackage">
            <path d="M7.48235 7.16091C7.01187 7.16091 6.76078 7.48474 6.76078 7.74449V7.78281L4.94236 18.8754C4.9614 19.1291 5.21089 19.4278 5.66275 19.4278H18.3373C18.7449 19.4278 19.0349 19.1153 19.0574 18.874L17.2392 7.78281V7.74449C17.2392 7.48474 16.9881 7.16091 16.5176 7.16091H7.48235ZM5.82021 7.70248C5.84436 6.86631 6.58737 6.21974 7.48235 6.21974H16.5176C17.4126 6.21974 18.1556 6.86631 18.1798 7.70248L20 18.8059V18.8442C20 19.7158 19.1675 20.369 18.3373 20.369H5.66275C4.75283 20.369 4 19.7007 4 18.8442V18.8059L5.82021 7.70248Z" />
            <path d="M9.97372 3.68228C9.43018 4.39162 9.08254 5.38955 9.08254 6.50429H8.14136C8.14136 5.20065 8.54666 3.99724 9.22665 3.10983C9.90723 2.22164 10.8822 1.63101 12.0002 1.63101C13.1181 1.63101 14.0931 2.22164 14.7737 3.10983C15.4537 3.99724 15.859 5.20065 15.859 6.50429H14.9178C14.9178 5.38955 14.5702 4.39162 14.0266 3.68228C13.4837 2.97372 12.7646 2.57219 12.0002 2.57219C11.2357 2.57219 10.5166 2.97372 9.97372 3.68228Z" />
          </Svg>
        );

      default:
        return;
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconPackage;
