import { createReducer, handle as on } from 'modules/utils/dux';
import { combineReducers } from 'redux';
import { createSelector } from '../../utils/debug-reselect';

import * as TYPES from './types/city-list';
import * as LOCATION from './types/location';
import * as PROFILE from 'modules/profile/types/profile';
import * as ORDER from 'modules/ordering/ducks/types/orders';


import cityListSeeds from './seeds/city-list';

const all = createReducer(
  on(TYPES.CITY_LIST_RESPONSE, (state, { cityList }) => {
    return {
      ...state,
      ...cityList
    };
  }),

  on(LOCATION.CITY_SUGGESTED_SET, (state, { city }) => {
    if (!city) {
      return state;
    }

    return {
      ...state,
      [city.id]: city
    };
  }),

  on(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.cities) {
      return state;
    }

    return {
      ...entities.cities,
      ...state
    };
  }),

  // on(LOCATION.CITY_CHOOSE, (state, { citySearchData }) => {
  //   if (!citySearchData) {
  //     return state;
  //   }

  //   const id = citySearchData.id;

  //   const newCity = {
  //     id,
  //     title: citySearchData.title,
  //     type: citySearchData.type,
  //     country: citySearchData.country,
  //     fiasId: citySearchData.fiasId,
  //     _local: true
  //   };

  //   return {
  //     ...state,
  //     [id]: newCity
  //   };
  // }),

  on(ORDER.SUCCESS, (state, { order }) => {
    const { city } = order.delivery.geography;
    return {
      ...state,
      [city.id]: city
    };
  }),

  on(ORDER.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order.delivery || !order.delivery.geography) {
      return state;
    }

    const { city } = order.delivery.geography;

    if (!city) {
      return state;
    }

    return {
      ...state,
      [city.id]: city
    };
  }),

  on(TYPES.REHYDRATE_ALL, (_, { all }) => {
    return {
      ...all
    };
  })
);

const popular = createReducer();

export default combineReducers({
  all: all(cityListSeeds),
  popular: popular([
    358,
    392,
    939,
    128,
    448,
    192,
    218,
    617,
    725,
    764,
    779,
    804,
    817,
    857,
    892,
    566,
    903,
    253,
    898,
    1039,
    18,
    1069
  ])
});

/* SELECTORS */

const getId = (_, { id }) => id;

export const getRoot = state => state.geography.cityList;

export const getAll = createSelector(getRoot, root => root.all);
export const getAllList = createSelector(getAll, all => Object.values(all));

export const getIdList = createSelector(getRoot, function _getIdList(root) {
  if (!root) {
    return null;
  }

  return root.popular;
});

export const getCityById = createSelector(getAll, getId, function _getCityById(all, id) {
  if (!all || !id) {
    return null;
  }

  const city = all[id];

  if (!city) {
    return null;
  }

  return city;
});

export const getFindItem = createSelector(getAll, function _getCityById(all) {
  return id => {
    if (!all || !id) {
      return null;
    }

    const city = all[id];

    if (!city) {
      return null;
    }

    return city;
  };
});

export const getСityTitleList = createSelector(getAll, function _getAllCityTitle(all) {
  const allCityList = Object.values(all);

  const cityTitleList = allCityList.map(item => ({ id: item.id, title: item.title }));

  return cityTitleList;
});

