import React, { Component } from 'react';
import PropTypes from 'prop-types';

import getFirstLetter from './helpers/get-first-letter';

import './avatar-placeholder.css';

const { string } = PropTypes;

class AvatarPlaceholder extends Component {
  propTypes = {
    name: string
  };
  render() {
    const { name } = this.props;
    return <div className="AvatarPlaceholder">{getFirstLetter(name)}</div>;
  }
}

export default AvatarPlaceholder;
