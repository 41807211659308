import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromCategories from '../ducks/categories';
import Category from '../components/category/category';

const mapStateToProps = createStructuredSelector({
  category: fromCategories.getCategoryBySlug,
  nested: fromCategories.getNested((_, props) => props.nested),
  isLoaded: fromCategories.getIsItemLoaded,
  isNestedLoaded: fromCategories.makeGetIsItemLoadedBySelector((_, props) => props.nested)
});

const CategoryContainer = connect(mapStateToProps)(Category);

export default CategoryContainer;
