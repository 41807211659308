import { takeEvery, put, call } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import { getFeedbackThanksLink } from 'routes/links';
import { mixitApi } from 'services/mixit';

import * as FEEDBACK from '../ducks/types/feedback';
import Feedback from '../ducks/actions/feedback';

export function* feedbackWatcher() {
  yield takeEvery(FEEDBACK.SEND, handleRequest);
}

function* handleRequest({ payload }) {
  const { text, firstName, email, phone, photoFile = null } = payload;
  console.log("function*handleRequest -> payload", payload)

  const serialized = serializeFeedback({
    text,
    firstName,
    email,
    phone,
    photoFile 
  });
  console.log("function*handleRequest -> serialized", serialized)

  const hasMedia = !!serialized.media;

  try {
    let id = null;

    if (hasMedia) {
      const response = yield call(mixitApi().feedback().sendMediaData, serialized.media);
      id = response.data.id;
    }

    yield call(mixitApi().feedback().sendData, {
      feedback: serialized.feedback,
      id
    });

    yield put(Feedback.response());
  } catch (e) {
    yield put(Feedback.error());
  }

  yield put(push(getFeedbackThanksLink()));
}

function serializeFeedback({ text, firstName, email, phone, photoFile }) {
  const feedback = {
    first_name: firstName,
    email,
    phone,
    text
  };

  if (photoFile && photoFile.name) {
    const media = {
      photo_file: photoFile
    };

    return {
      feedback,
      media
    };
  }

  return {
    feedback
  };
}
