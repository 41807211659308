import React, { Component } from 'react';

import Dialog from '../dialog/dialog';

import './add-to-wait-list-confirmation-dialog.css';

class AddToWaitListConfirmationDialog extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Dialog closeable {...rest}>
        <div className="AddToWaitListConfirmationDialog">
          <h2 className="AddToWaitListConfirmationDialog-title">
            Товар добавлен в список ожидаемых!
          </h2>

          {/* <div className="AddToWaitListConfirmationDialog-info">
            Как только товар поступит в продажу, мы Вам сообщим
          </div> */}
        </div>
      </Dialog>
    );
  }
}

export default AddToWaitListConfirmationDialog;
