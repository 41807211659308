import React, { Component } from 'react';
import Container from 'modules/core/components/container/container';
import './form-section.css';

class FormSection extends Component {
  render() {
    const { main, side, header } = this.props;

    return (
      <section className="OrderingScene_FormSection">
        <Container narrow>
          {!!header && (
            <div className="OrderingScene_FormSection-header">
              {header}
            </div>
          )}
          <div className="OrderingScene_FormSection-grid">
            <div className="OrderingScene_FormSection-main">{main}</div>
            <div className="OrderingScene_FormSection-side">{side}</div>
          </div>
        </Container>
      </section>
    );
  }
}

export default FormSection;
