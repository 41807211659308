import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArticleVerticalPreviewContainer from '../../containers/aricle-vertical-preview';

import './explore-article-list.css';

const { string, arrayOf } = PropTypes;

const ARTICLE_AMOUNT = 3;

class ExploreArticleList extends Component {
  static propTypes = {
    articleList: arrayOf(string).isRequired
  };

  render() {
    const { articleList } = this.props;

    if (!articleList) {
      return null;
    }

    return (
      <div className="ExploreArticleList">
        {articleList.slice(0, ARTICLE_AMOUNT).map(this.renderExploreArticleItem)}
      </div>
    );
  }

  renderExploreArticleItem = (slug, index) => {
    return (
      <div key={index} className="ExploreArticleList-item">
        <ArticleVerticalPreviewContainer slug={slug} />
      </div>
    );
  };
}

export default ExploreArticleList;
