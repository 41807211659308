import React, { Component } from 'react';
import ProductNavigation from '../product-navigation/product-navigation';
import { Sticky } from 'react-sticky';

class SectionProductNavigation extends Component {
  render() {
    return (
      <section className="ProductNavigation">
        <Sticky topOffset={30}>{this.renderNav}</Sticky>
      </section>
    );
  }

  renderNav = ({ style, isSticky, wasSticky }) => {
    const { ...rest } = this.props;

    return <ProductNavigation style={style} isSticky={isSticky} wasSticky={wasSticky} {...rest} />;
  };
}

export default SectionProductNavigation;
