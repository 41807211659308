import * as TYPES from '../types/recovery-password';
import { createAction } from 'modules/utils/dux';

export default {
  request(email) {
    return createAction(TYPES.REQUEST, { email });
  },

  startRecovery(email) {
    return createAction(TYPES.START_RECOVERY, { email });
  },

  error(message) {
    return createAction(TYPES.ERROR, { message });
  },

  resetError() {
    return createAction(TYPES.RESET_ERROR);
  },

  setSubmitting() {
    return createAction(TYPES.SET_SUBMITTING);
  },

  resetSubmitting() {
    return createAction(TYPES.RESET_SUBMITTING);
  },

  setRegisteredEmail(email) {
    return createAction(TYPES.SET_REGISTERED_EMAIL, { email });
  },

  resetRegisteredEmail() {
    return createAction(TYPES.RESET_REGISTERED_EMAIL);
  },

  confirmPasswordRequest(payload) {
    return createAction(TYPES.CONFIRM_PASSWORD_REQUEST, payload);
  },

  confirmPasswordResponse(password) {
    return createAction(TYPES.CONFIRM_PASSWORD_RESPONSE, password);
  },

  saveConfirmKey(code) {
    return createAction(TYPES.SAVE_CONFIRM_KEY, { code });
  },

  removeConfirmKey() {
    return createAction(TYPES.REMOVE_CONFIRM_KEY);
  }
};
