import React, { Children, Component } from 'react';
import Media from 'react-media';

import Container from '../container/container';
import { SliderArrowLeft, SliderArrowRight } from '../icons';
import IconContainer from '../icon-container/icon-container';
import Carousel from '../carousel/carousel';
import Item from './item';
import { IconArrowRight, IconArrowLeft } from '../icons';
import ScrollBar from '../scroll-bar/scroll-bar';

import './product-carousel.css';

const PER_PAGE = 4;

class ProductCarousel extends Component {
  static Item = Item;

  state = {
    currentPage: 0
  };

  render() {
    const { children } = this.props;
    const { currentPage } = this.state;
    const productAmount = Children.count(children);
    const hasPages = productAmount > PER_PAGE;

    return (
      <Media query="(max-width: 1100px)">
        {isMobile => (
          <div className="ProductCarousel">
            <div className="ProductCarousel-content">
              {isMobile ? (
                children
              ) : (
                <Carousel
                  perPage={PER_PAGE}
                  currentPage={currentPage}
                  overflowVisible={true}
                  onChange={this.handlePageChange}
                >
                  {children}
                </Carousel>
              )}
            </div>
            {/* {hasPages && this.renderControls(isMobile)} */}
            {hasPages && !isMobile && this.renderUnderControls()}
          </div>
        )}
      </Media>
    );
  }

  renderUnderControls() {
    const { children } = this.props;

    const { currentPage } = this.state;

    const productAmount = Children.count(children);

    const pagesAmount = productAmount - PER_PAGE;

    const isFirstSlide = currentPage === 0;
    const isLastSlide = currentPage === pagesAmount;

    // const progress = (PER_PAGE + currentPage) / productAmount;

    return (
      <Container>
        <div className="ProductCarousel-underControls">
          <button
            className="ProductCarousel-underControl"
            onClick={this.handlePrevButtonClick}
            type="button"
            disabled={isFirstSlide}
          >
            <IconContainer size="xsmall">
              <IconArrowLeft />
            </IconContainer>
          </button>
          <div className="ProductCarousel-progress">
            <div className="ProductCarousel-progressHolder">
              <ScrollBar
                amount={PER_PAGE}
                totalAmount={productAmount}
                scrolled={currentPage}
                onBeforeClick={this.handlePrevButtonClick}
                onAfterClick={this.handleNextButtonClick}
                activeBar
              />
            </div>
          </div>

          <div className="ProductCarousel-rightHolder">
            {/* <div className="ProductCarousel-counter">
              {this.getSliderInfo(productAmount)}
            </div> */}
            <button
              className="ProductCarousel-underControl"
              onClick={this.handleNextButtonClick}
              type="button"
              disabled={isLastSlide}
            >
              <IconContainer size="xsmall">
                <IconArrowRight />
              </IconContainer>
            </button>
          </div>
        </div>
      </Container>
    );
  }

  getSliderInfo(amount) {
    const { currentPage } = this.state;
    const firstProductIndex = currentPage + 1;
    const lastProductIndex = firstProductIndex + PER_PAGE - 1;
    return `${firstProductIndex}-${lastProductIndex} из ${amount}`;
  }

  renderControls(isMobile) {
    return (
      !isMobile && (
        <div className="ProductCarousel-controls">
          <button
            type="button"
            className="ProductCarousel-prev"
            onClick={this.handlePrevButtonClick}
          >
            <span className="ProductCarousel-controlHolder">
              <IconContainer size="xsmall">
                <SliderArrowLeft />
              </IconContainer>
            </span>
          </button>

          <button
            type="button"
            className="ProductCarousel-next"
            onClick={this.handleNextButtonClick}
          >
            <div className="ProductCarousel-controlHolder">
              <IconContainer size="xsmall">
                <SliderArrowRight />
              </IconContainer>
            </div>
          </button>
        </div>
      )
    );
  }

  handleNextButtonClick = e => {
    e.preventDefault();
    this.goToNextPage();
  };

  handlePrevButtonClick = e => {
    e.preventDefault();
    this.goToPrevPage();
  };

  handlePageButtonClick = (e, currentPage) => {
    e.preventDefault();
    this.goToPage(currentPage);
  };

  handlePageChange = data => {
    this.changePage(data.page);
  };

  changePage(page) {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    const lastPage = childrenCount - PER_PAGE;
    const firstPage = 0;

    if (page >= lastPage) {
      this.setState({
        currentPage: lastPage
      });
      return;
    }

    if (page <= firstPage) {
      this.setState({
        currentPage: firstPage
      });
      return;
    }

    this.setState({
      currentPage: page
    });
  }

  goToNextPage() {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    if (this.state.currentPage >= childrenCount - PER_PAGE) {
      return;
    }
    this.setState(state => ({
      currentPage: state.currentPage + 1
    }));
  }

  goToPrevPage() {
    if (this.state.currentPage <= 0) {
      return;
    }

    this.setState(state => ({
      currentPage: state.currentPage - 1
    }));
  }

  goToPage(currentPage) {
    this.setState({
      currentPage
    });
  }

  handleVariantTrig = () => {
    this.setState(state => ({
      dimaVariant: !state.dimaVariant
    }));
  };

  handleRangeChange = value => {
    this.goToPage(value);
  };
}

export default ProductCarousel;
