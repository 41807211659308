import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import current from './helpers.js';
import './volume-dropdown.css';
import DropdownSelect from 'modules/ordering/components/pick-point-dialog/dropdown-select/dropdown-select';
import current from './helpers.js';

class VolumeDropdown extends Component {
  static propTypes = {
    /**
     * получаем массив данных опций:
     * volume - значение
     * disabled - доступна ли эта опция в переключателе
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool
      }).isRequired
    ),
    /** событие по изменению (переключению) опции */
    onChange: PropTypes.func
  };

  state = {
    currentOption: current(this.props)
  };

  componentDidMount() {
    this.setInitialCurrent(current(this.props));
  }

  currentOptionId = this.props.options[this.state.currentOption].id;

  render() {
    const { options, onChange, unit } = this.props;

    return (
      <DropdownSelect placeholder="Выберите вес" onChange={onChange} current={this.currentOptionId}>
        {options.map(option => (
          <DropdownSelect.Option key={option.id} value={option.id}>
            {option.volume}
            {unit}
          </DropdownSelect.Option>
        ))}
      </DropdownSelect>
    );
  }

  setInitialCurrent(currentOption) {
    const { options } = this.props;
    const currentValue = options[currentOption].value;
    this.setState({ currentOption });
    this.handlerChange(currentValue);
  }
  handlerChange = currentOption => {
    const { onChange } = this.props;
    onChange(currentOption);
  };
}
export default VolumeDropdown;
