import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Goo from './goo';
import './loader.css';

class Loader extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['default', 'short', 'spin', 'circle', 'goo']),
    size: PropTypes.oneOf(['normal', 'small', 'large']),
    secondary: PropTypes.bool,
    inverted: PropTypes.bool
  };

  static defaultProps = {
    type: 'default',
    size: 'normal',
    secondary: false,
    inverted: false
  };

  render() {
    const { type, size, secondary, inverted } = this.props;

    if (type === 'goo') {
      return (
        <div role="presentation" aria-label="Загрузка..." className="Loader_Goo">
          <Goo
            radius={16}
            blur={5}
            slope={24}
            intercept={-20}
            gap={4}
            colors={['#F15B5B', '#FFD64F', '#9D85BE', '#5AC5C8']}
            colorful
            duration={2}
          />
        </div>
      );
    }

    return (
      <div
        role="presentation"
        aria-label="Загрузка..."
        className={classNames('Loader', {
          'Loader--type-default': type === 'default',
          'Loader--type-short': type === 'short',
          'Loader--type-spin': type === 'spin',
          'Loader--type-circle': type === 'circle',
          'Loader--size-small': size === 'small',
          'Loader--size-large': size === 'large',
          'Loader--secondary': secondary,
          'Loader--inverted': inverted
        })}
      >
        {type !== 'circle' && (
          <div className="Loader-inner">
            <div className="Loader-part Loader-top" />
            <div className="Loader-part Loader-right" />
            <div className="Loader-part Loader-bottom" />
            <div className="Loader-part Loader-left" />
          </div>
        )}
      </div>
    );
  }
}

export default Loader;
