import React, { Component, Children } from 'react';

import ButtonBack from './button-back/button-back';
import Item from './item';

import './category-panel.css';

class CategoryPanel extends Component {
  static Item = Item;

  render() {
    const { hideBackButton, children, onBackButtonClick } = this.props;

    return (
      <div className="CategoryPanel">
        {!hideBackButton && (
          <div className="CategoryPanel-buttonBackCol">
            <ButtonBack onClick={onBackButtonClick} />
          </div>
        )}

        <div className="CategoryPanel-listCol">
          <ul className="CategoryPanel-list">
            {Children.map(children, (item, key) => (
              <li key={key} className="CategoryPanel-item">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CategoryPanel;
