import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KEY_RETURN, KEY_UP, KEY_DOWN } from 'keycode-js';

import Autocomplete from './autocomplete';

import isFunction from 'modules/utils/is-function';
import { ring } from './helpers';
import scrollIntoView from './scroll-into-view';

function checkMatch(exampleStirng, incomingString) {
  return exampleStirng.toLowerCase().indexOf(incomingString.toLowerCase()) === 0;
}

function getNormalizedOptionIndex(options, activeOptionId) {
  const optionsId = options.map(option => option.id);

  const index = optionsId.indexOf(activeOptionId);

  return index !== -1 ? index : null;
}

const { number, string, func, shape, arrayOf } = PropTypes;

const optionShape = shape({
  id: number.isRequired,
  value: string.isRequired
});

class AutocompleteInputProvider extends Component {
  static propTypes = {
    options: arrayOf(optionShape).isRequired,

    /** Inex активной опции по умолчанию */
    defaultActiveOptionIndex: number,

    /** Функция, которая указывает, как конструировать заглавие.
     * Example: renderTitle = option => option.value + option.id
     */
    renderTitle: func,
    onOptionClick: func,
    onInputChange: func,
    onInputFocus: func,
    onInputBlurs: func
  };

  static defaultProps = {
    defaultActiveOptionIndex: -1,
    renderTitle: option => option.value,
    selectTitle: option => option.value,
    onOptionClick: null,
    onInputChange: null,
    onInputFocus: null,
    onInputBlurs: null
  };

  state = {
    activeOptionIndex: this.defineInitialActiveOptionIndex(),
    highlightedOptionIndex: this.defineInitialHighlightedOptionIndex(),
    optionList: this.props.options,
    isOptionShowed: false,
    inputValue: this.defineInitialInputValue(),
    isFocused: false
  };

  _optionElements = [];
  _inputElement = null;
  _listElement = null;

  render() {
    const { ...rest } = this.props;
    const { optionList, activeOptionIndex, highlightedOptionIndex, inputValue } = this.state;
    delete rest.onChange;
    delete rest.query;
    delete rest.value;
    delete rest.defaultValue;

    const isOptionShowed = this.checkIsNeedToShowOptions();

    return (
      <Autocomplete
        {...rest}
        value={inputValue}
        options={optionList}
        activeOptionIndex={activeOptionIndex}
        highlightedOption={highlightedOptionIndex}
        showOptions={isOptionShowed}
        optionRef={this.handleOptionRef}
        inputRef={this.handleInputRef}
        listRef={this.handleListRef}
        onOptionClick={this.handleOptionClick}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        onChange={this.handleInputChange}
        onInputClick={this.handleInputClick}
        onKeyDown={this.handleKeyDown}
      />
    );
  }

  componentDidUpdate(prevProps) {
    const { options, defaultActiveOptionIndex } = this.props;

    if (
      prevProps.options.length !== options.length ||
      prevProps.defaultActiveOptionIndex !== defaultActiveOptionIndex
    ) {
      this.updateOptionList(options);
      this.updateActiveOptionIndex(defaultActiveOptionIndex);

      const inputValue = this.defineInitialInputValue();
      this.updateInputValue(inputValue);
    }
  }

  updateOptionList(optionList) {
    this.setState({ optionList });
  }

  updateActiveOptionIndex(activeOptionIndex) {
    this.setState({ activeOptionIndex });
  }

  updateInputValue(inputValue) {
    this.setState({ inputValue });
  }

  defineInitialActiveOptionIndex() {
    const { defaultActiveOptionIndex } = this.props;

    if (typeof defaultActiveOptionIndex !== 'number') {
      return -1;
    }

    return defaultActiveOptionIndex;
  }

  defineInitialHighlightedOptionIndex() {
    const { defaultActiveOptionIndex } = this.props;

    if (typeof defaultActiveOptionIndex !== 'number' || defaultActiveOptionIndex === -1) {
      return -1;
    }

    return defaultActiveOptionIndex;
  }

  defineInitialInputValue() {
    const { options, defaultActiveOptionIndex } = this.props;

    if (typeof defaultActiveOptionIndex !== 'number' || defaultActiveOptionIndex === -1) {
      return '';
    }

    return options[defaultActiveOptionIndex].title;
  }

  checkIsNeedToShowOptions() {
    const { optionList, isOptionShowed } = this.state;

    if (!optionList.length) {
      return false;
    }

    return isOptionShowed;
  }

  checkActiveOptionExistence() {
    const { activeOptionIndex } = this.state;

    return activeOptionIndex !== -1;
  }

  showOptions() {
    this.setState({ isOptionShowed: true });

    const isActiveOptionExist = this.checkActiveOptionExistence();

    if (isActiveOptionExist) {
      const { activeOptionIndex } = this.state;
      this.highlightOption(activeOptionIndex);
    }
  }

  hideOptions() {
    this.setState({ isOptionShowed: false });
  }

  setOptionList(optionList) {
    this.setState({ optionList });
  }

  selectOption(index) {
    const { optionList } = this.state;
    const { options, selectTitle, onOptionClick } = this.props;

    const activeOption = optionList[index];

    if (isFunction(onOptionClick)) {
      onOptionClick(activeOption);
    }

    const normalizedActiveOptionIndex = getNormalizedOptionIndex(options, activeOption.id);

    this.setActiveOptionIndex(normalizedActiveOptionIndex);
    this.setInputValue(selectTitle(activeOption));
    this.setOptionList(options);
    this.setHighlitedOptionIndex(normalizedActiveOptionIndex);
  }

  setActiveOptionIndex(activeOptionIndex) {
    this.setState({ activeOptionIndex });
  }

  setHighlitedOptionIndex(highlightedOptionIndex) {
    this.setState({ highlightedOptionIndex });
  }

  setInputValue(inputValue) {
    this.setState({ inputValue });
  }

  highlightOption(index) {
    this.scrollToOption(index);
    this.setHighlitedOptionIndex(index);
  }

  resetHighlightedOption() {
    const defaultIndex = 0;
    this.setHighlitedOptionIndex(defaultIndex);
  }

  focus() {
    this.setState({ isFocused: true });
  }

  blur() {
    this.setState({ isFocused: false });
  }

  filter(value) {
    const { options, selectTitle } = this.props;
    const { activeOptionIndex } = this.state;

    const isNeedToShowOptions = this.checkIsNeedToShowOptions();

    if (!isNeedToShowOptions) {
      this.showOptions();
    }

    if (!value) {
      this.highlightOption(activeOptionIndex);
    } else {
      this.resetHighlightedOption();
    }

    const filteredOptions = options.filter(option => checkMatch(selectTitle(option), value));

    this.setOptionList(filteredOptions);
  }

  apply() {
    const { optionList, highlightedOptionIndex } = this.state;

    if (!optionList.length) {
      return;
    }

    this.selectOption(highlightedOptionIndex);
    this.hideOptions();
  }

  nextOption() {
    const { optionList, highlightedOptionIndex } = this.state;

    const ringln = ring(0, optionList.length);
    const nextOption = ringln(highlightedOptionIndex + 1);

    this.highlightOption(nextOption);
  }

  prevOption() {
    const { optionList, highlightedOptionIndex } = this.state;

    const ringln = ring(0, optionList.length);
    const prevOption = ringln(highlightedOptionIndex - 1);

    this.highlightOption(prevOption);
  }

  scrollToOption(optionIndex) {
    const option = this._optionElements[optionIndex];
    const root = this._listElement;
    if (!option || !root) {
      this.scheduleScrollToOption(optionIndex);
      return;
    }
    scrollIntoView(option, root);
  }

  scrollToOptionSetTarget(target, index) {
    if (
      !target ||
      !this.__scheduledScrollToOption ||
      this.__scheduledScrollToOption.optionIndex !== index
    ) {
      return;
    }

    this.__scheduledScrollToOption.target = target;

    if (this.__scheduledScrollToOption.root) {
      this.scrollToOptionScheduled();
    }
  }

  scrollToOptionSetRoot(root) {
    if (!root || !this.__scheduledScrollToOption) {
      return;
    }

    this.__scheduledScrollToOption.root = root;

    if (this.__scheduledScrollToOption.target) {
      this.scrollToOptionScheduled();
    }
  }

  scheduleScrollToOption(optionIndex) {
    this.__scheduledScrollToOption = {
      optionIndex
    };

    const target = this._optionElements[optionIndex];
    const root = this._listElement;

    if (target) {
      this.__scheduledScrollToOption.target = target;
    }

    if (root) {
      this.__scheduledScrollToOption.root = root;
    }
  }

  scrollToOptionScheduled() {
    if (this.__scheduledScrollToOption) {
      const { target, root } = this.__scheduledScrollToOption;
      scrollIntoView(target, root);
    }

    this.__scheduledScrollToOption = null;
  }

  handleOptionRef = (element, index) => {
    this._optionElements[index] = element;
    this.scrollToOptionSetTarget(element, index);
  };

  handleListRef = element => {
    this._listElement = element;
    this.scrollToOptionSetRoot(element);
  };

  handleInputRef = element => {
    this._inputElement = element;
  };

  handleOptionClick = (e, index) => {
    this.selectOption(index);
    this.hideOptions();
  };

  handleInputFocus = () => {
    const { onInputFocus } = this.props;

    this.focus();

    this.showOptions();

    if (isFunction(onInputFocus)) {
      onInputFocus();
    }
  };

  handleInputBlur = () => {
    const { onInputBlur } = this.props;

    this.blur();

    this.hideOptions();

    if (isFunction(onInputBlur)) {
      onInputBlur();
    }
  };

  handleInputChange = value => {
    const { onInputChange } = this.props;
    const { inputValue } = this.state;

    const normalizedValue = value.trim();

    if (normalizedValue === inputValue) {
      return;
    }

    this.setInputValue(normalizedValue);
    this.filter(normalizedValue);

    if (isFunction(onInputChange)) {
      onInputChange(normalizedValue);
    }
  };

  handleInputClick = () => {
    const { isFocused, isOptionShowed } = this.state;

    if (isFocused && !isOptionShowed) {
      this.showOptions();
    }
  };

  handleKeyDown = (code, e) => {
    const { isOptionShowed } = this.state;

    if (KEY_UP === code) {
      if (!isOptionShowed) {
        this.showOptions();
        return;
      }

      this.prevOption();
      return;
    }

    if (KEY_DOWN === code) {
      if (!isOptionShowed) {
        this.showOptions();
        return;
      }

      this.nextOption();
      return;
    }

    if (KEY_RETURN === code) {
      this.apply();
    }

    return;
  };
}

export default AutocompleteInputProvider;
