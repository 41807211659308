import React, { Component } from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import { getIndexLink } from 'routes/links';

import Button from '../button/button';

import zeroCreamImage from './assets/zero-cream.png';
import zeroCreamImage2x from './assets/zero-cream@2x.png';
import './error-message.css';

class ErrorMessage extends Component {
  static propTypes = {
    code: PT.number,
    message: PT.string
  };

  static defaultProps = {
    code: 404,
    message: 'Извините! Страница, которую вы ищете, не найдена'
  };

  render() {
    const { message } = this.props;
    return (
      <div className="ErrorMessage">
        <h2 className="ErrorMessage-title">
          <span className="ErrorMessage-number">4</span>
          <img
            src={zeroCreamImage}
            srcSet={`${zeroCreamImage2x} 2x`}
            className="ErrorMessage-imageNumber"
            alt="0"
          />
          <span className="ErrorMessage-number">4</span>
        </h2>
        <div className="ErrorMessage-message">{message}</div>
        <div className="ErrorMessage-action">
          <Button
            title="Продолжить покупки"
            to={getIndexLink()}
            variant="primary"
            component={Link}
          />
        </div>
      </div>
    );
  }
}

export default ErrorMessage;
