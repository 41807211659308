import { createSelector } from 'reselect';
import { getProductsModule } from '../meta';
import * as CATEGORY from './types/categories';
import * as SEARCH from './types/search';

import { createReducer, handle as on } from 'modules/utils/dux';

import filterSeeds from './seeds/filters';

import * as fromFilterOptions from './filter-options';

const reducer = createReducer(
  on(CATEGORY.ADD_ITEM, (state, { slug, category }) => {
    const prefix = `${slug}:`;
    return category.filters.reduce((all, item) => {
      const filter = all[item.id] || {};
      const newFilter = normalizeFilter(item, prefix);
      // const options = newFilter.options.reduce((options, item) => {
      //   if (options.indexOf(item) !== -1) {
      //     return options;
      //   }
      //   return options.concat([item]);
      // }, filter.options || []);
      return {
        ...all,
        [newFilter.id]: { ...filter, ...newFilter /*, options */ }
      };
    }, state);
  }),
  on(SEARCH.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.filter) {
      return state;
    }

    return {
      ...state,
      ...entities.filter
    };
  })
);

export default reducer(filterSeeds);

export const getFilters = state => getProductsModule(state).filters;

export const getFilterIdFormProps = (_, { id }) => id;
export const getAppliedFiltersFromProps = (_, { appliedFilters }) => appliedFilters;

export const getFilterByName = createSelector(
  getFilters,
  getFilterIdFormProps,
  fromFilterOptions.makeGetItemBySlug,
  (filters, id, getOptionBySlug) =>
    id in filters ? transformFilter(filters[id], getOptionBySlug) : null
);

export const getFilterWithCheckedOptions = createSelector(
  getFilters,
  getFilterIdFormProps,
  getAppliedFiltersFromProps,
  fromFilterOptions.makeGetItemBySlug,
  (filters, id, applied, getOptionBySlug) => {
    if (!(id in filters)) {
      return null;
    }

    const filter = transformFilter(filters[id], getOptionBySlug);

    const optionSlugMap = filter.options.reduce(
      (map, item) => ({
        ...map,
        [item.slug]: 1
      }),
      {}
    );

    const explicitOptions = applied.filter(slug => optionSlugMap[slug] !== 1);

    const options = filter.options.map(item => {
      const includes = applied.indexOf(item.slug) !== -1;

      return {
        ...item,
        checked: includes
      };
    });

    return {
      ...filter,
      options,
      explicitOptions
    };
  }
);

export const getApplied = createSelector(
  getFilters,
  filters => filters.applied
);

export const getUserRelated = createSelector(
  getFilters,
  filters => filters.userRelated
);

/* HELPERS */
function transformFilter(filter, getOptionBySlug) {
  const options = filter.options.map(getOptionBySlug);

  return {
    ...filter,
    options
  };
}

function normalizeFilter(filter, idPrefix = '') {
  return {
    ...filter,
    id: idPrefix + filter.id,
    options: filter.options.map(getOptionSlug)
  };
}

function getOptionSlug(option) {
  return option.slug;
}
