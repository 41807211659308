import React, { Component } from 'react';
import DropdownSelect from 'modules/ordering/components/pick-point-dialog/dropdown-select/dropdown-select-thin';

class CitySuggest extends Component {
  render() {
    const { suggestions, ...rest } = this.props;
    return (
      <DropdownSelect {...rest}>
        {suggestions.map(item => (
          <DropdownSelect.Option value={item.id}>{item.title}</DropdownSelect.Option>
        ))}
      </DropdownSelect>
    );
  }
}

export default CitySuggest;
