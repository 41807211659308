import React, { Component } from 'react';
import './compact-breadcrumbs.css';
import PropTypes from 'prop-types';
import IconContainer from '../icon-container/icon-container';
import { IconAngleArrowLeft } from '../icons/index';
import { Link } from 'react-router-dom';

class CompactBreadcrumbs extends Component {
  static propTypes = {
    name: PropTypes.string,
    link: PropTypes.string
  };

  render() {
    const { name, link, absoluteLink } = this.props;

    return (
      <>
        {!!absoluteLink ? (
          <a href={absoluteLink} rel="noreferrer noopener" className="CompactBreadcrumbs">
            <span className="CompactBreadcrumbs-icon">
              <IconContainer size="tiny">
                <IconAngleArrowLeft />
              </IconContainer>
            </span>
            <span className="CompactBreadcrumbs-title">{name}</span>
          </a>
        ) : (
          <Link to={link} className="CompactBreadcrumbs" title={name}>
            <span className="CompactBreadcrumbs-icon">
              <IconContainer size="tiny">
                <IconAngleArrowLeft />
              </IconContainer>
            </span>
            <span className="CompactBreadcrumbs-title">{name}</span>
          </Link>
        )}
      </>
    );
  }
}

export default CompactBreadcrumbs;
