import AccountMapper from './mappers/account';
import BasketMapper from './mappers/basket';
import SubscriptionMapper from './mappers/subscription';
import CookieToastMapper from './mappers/cookie-toast';
import UtmMapper from './mappers/utm';
import LastOrderMapper from './mappers/last-order';
import MainBannerVersion from './mappers/main-banner-version';

const Storage = __BROWSER__
  ? window.localStorage
  : {
      setItem: () => null,
      getItem: () => null
    };

function createWrite(key, serialize) {
  return function(data) {
    Storage.setItem(key, serialize(data));
  };
}

function createRead(key, deserialize) {
  return function() {
    const value = Storage.getItem(key);
    if (!value) {
      return null;
    }
    return deserialize(value);
  };
}

export function createAccountPersistor() {
  const KEY = '_persisted_account';

  return {
    write: createWrite(KEY, AccountMapper.serialize),
    read: createRead(KEY, AccountMapper.deserialize)
  };
}

export function createBasketPersistor() {
  const KEY = '_persisted_basket';

  return {
    write: createWrite(KEY, BasketMapper.serialize),
    read: createRead(KEY, BasketMapper.deserialize)
  };
}

export function createSubscriptionPersistor() {
  const KEY = '_persisted_have_subscribe_suggestion_been_showed';

  return {
    write: createWrite(KEY, SubscriptionMapper.serialize),
    read: createRead(KEY, SubscriptionMapper.deserialize)
  };
}

export function createCookieToastPresistor() {
  const KEY = '_persisted_have_cookie_toast_been_showed';

  return {
    write: createWrite(KEY, CookieToastMapper.serialize),
    read: createRead(KEY, CookieToastMapper.deserialize)
  };
}

export function createUtmPersistor() {
  const KEY = '_persisted_utm';

  return {
    write: createWrite(KEY, UtmMapper.serialize),
    read: createRead(KEY, UtmMapper.deserialize)
  };
}

export function createLastOrderPersistor() {
  const KEY = '_persisted_last_order';

  return {
    write: createWrite(KEY, LastOrderMapper.serialize),
    read: createRead(KEY, LastOrderMapper.deserialize)
  };
}

export function creatMainBannerVersionPersistor() {
  const KEY = '_persisted_main_banner_version';

  return {
    write: createWrite(KEY, MainBannerVersion.serialize),
    read: createRead(KEY, MainBannerVersion.deserialize)
  };
}

export function createABTestVersionPersistor(KEY) {
  return {
    write: createWrite(KEY, MainBannerVersion.serialize),
    read: createRead(KEY, MainBannerVersion.deserialize)
  };
}
