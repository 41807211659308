import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from '../../utils/debug-reselect';

import TOASTS from './types/toasts';

/* REDUCERS */

const reducer = createReducer(
  on(TOASTS.TOAST_REHYDRATE, (state, { haveCookieToastBeenShowed }) => {
    return {
      ...state,
      haveCookieToastBeenShowed
    };
  }),

  on(TOASTS.COOKIE_TOAST_ACCEPT, state => {
    return {
      ...state,
      haveCookieToastBeenShowed: true
    };
  }),

  on(TOASTS.COOKIE_TOAST_CLOSE, state => {
    return {
      ...state,
      haveCookieToastBeenShowed: true
    };
  }),

  on(TOASTS.PASSWORD_CHANGE_TOAST_ACCEPT, state => {
    return {
      ...state,
      havePasswordChangeToastBeenShowed: true
    };
  }),

  on(TOASTS.PASSWORD_CHANGE_TOAST_CLOSE, state => {
    return {
      ...state,
      havePasswordChangeToastBeenShowed: true
    };
  })
);

export default reducer({
  haveCookieToastBeenShowed: false,
  havePasswordChangeToastBeenShowed: false
});

/* SELECTORS */

const getRoot = state => state.core.toasts;

export const getAll = getRoot;

export const getHaveCookieToastBeenShowed = createSelector(
  getAll,
  function _getIsCookieToastOpened(all) {
    return all.haveCookieToastBeenShowed;
  }
);
export const getHavePasswordChangeToastBeenShowed = createSelector(
  getAll,
  all => all.havePasswordChangeToastBeenShowed
);
