import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import FieldBottomLabeled from 'modules/profile/components/field-bottom-labeled/field-bottom-labeled';
import Input from '../input/input';

import './address-field.css';

const { shape, string, func } = PropTypes;

class AddressField extends Component {
  static propTypes = {
    address: shape({
      building: string,
      apartment: string
    }),
    onChange: func
  };

  render() {
    const { address } = this.props;

    const street = address ? address.street : '';
    const building = address ? address.building : '';
    const apartment = address ? address.apartment : '';

    return (
      <div className="AddressField">
        <div className="AddressField-wideCol">
          <FieldBottomLabeled
            label="Улица"
            size="small"
            value={street}
            renderInput={props => <Input {...props} />}
            onChange={this.handleStreetChange}
          />
        </div>
        <div className="AddressField-narrowCol">
          <FieldBottomLabeled
            label="Дом"
            size="small"
            value={building}
            renderInput={props => <Input {...props} />}
            onChange={this.handleBuildingChange}
          />
        </div>
        <div className="AddressField-narrowCol">
          <FieldBottomLabeled
            label="Кв. / Офис"
            size="small"
            value={apartment}
            renderInput={props => <Input {...props} />}
            onChange={this.handleApartmentChange}
          />
        </div>
      </div>
    );
  }

  handleStreetChange = e => {
    const { address, onChange } = this.props;
    const { value } = e.target;

    if (!isFunction(onChange)) {
      return;
    }

    onChange({
      ...address,
      street: value
    });
  };

  handleBuildingChange = e => {
    const { address, onChange } = this.props;
    const { value } = e.target;

    if (!isFunction(onChange)) {
      return;
    }

    onChange({
      ...address,
      building: value
    });
  };

  handleApartmentChange = e => {
    const { address, onChange } = this.props;
    const { value } = e.target;

    if (!isFunction(onChange)) {
      return;
    }

    onChange({
      ...address,
      apartment: value
    });
  };
}

export default AddressField;
