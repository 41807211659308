export default function getSeparatedPhone(rawPhone = '', codeList) {
  const cleanedValue = rawPhone.replace(/[^0-9+]/gi, '');

  for (let option of codeList) {
    if (cleanedValue.indexOf(option.value) === 0) {
      const code = option;
      const phone = cleanedValue.replace(code.value, '');

      return { code, phone };
    }
  }

  const code = null;
  const phone = cleanedValue.replace(/\+/, '');

  return { code, phone };
}

// export default function getSeparatedPhone(rawPhone = '', codeList) {
//   const cleanedValue = rawPhone.replace(/[^0-9]/gi, '');

//   const _codeList = codeList.map(code=> ({
//     ...code,
//     value: code.value.replace(/[^0-9]/gi, '')
//   }))

//   for (let option of _codeList) {
//     if (cleanedValue.startsWith(option)) {
//       const code = option;
//       const phone = cleanedValue.replace(code.value, '');

//       return { code, phone };
//     }
//   }

//   const code = null;
//   const phone = cleanedValue

//   return { code, phone };
// }

