import { combineReducers } from 'redux';
import createObjectReducer from 'modules/utils/create-object-reducer';
import {
  CHANGE_COUNTRY,
  CHANGE_CITY,
  CHANGE_PAYMENT_METHOD,
  CHANGE_DELIVERY_METHOD,
  CHANGE_PASSWORD_CREATING,
  CHANGE_COMMENT,
  CHANGE_EMAIL,
  CHANGE_NUMBER,
  CHANGE_NAME,
  CHANGE_STREET,
  CHANGE_HOUSE,
  CHANGE_HOUSING,
  CHANGE_BUILDING,
  CHANGE_APARTMENT
} from './types';

const country = createObjectReducer(
  {
    value: '',
    options: [
      {
        id: 1,
        title: 'Россия'
      },
      {
        id: 2,
        title: 'Казахстан'
      },
      {
        id: 3,
        title: 'Беларусь'
      }
    ]
  },
  {
    [CHANGE_COUNTRY]: (state, payload) => changeCountryValue(state, payload.country.id)
  }
);

const city = createObjectReducer(
  {
    value: '',
    options: [
      {
        id: 1,
        title: 'Москва'
      },
      {
        id: 2,
        title: 'Астрахань'
      },
      {
        id: 3,
        title: 'Краснодар'
      }
    ]
  },
  {
    [CHANGE_CITY]: (state, payload) => changeCityValue(state, payload.city.id)
  }
);

const payment = createObjectReducer(
  {
    value: '',
    options: [
      {
        id: 'payment/IN_CASH',
        title: 'Наличными курьеру'
      },
      {
        id: 'payment/CASHLESS',
        title: 'Безналичный расчет'
      },
      {
        id: 'payment/WEB_MONEY',
        title: 'WebMoney'
      }
    ]
  },
  {
    [CHANGE_PAYMENT_METHOD]: (state, { id }) => changePaymentType(state, id)
  }
);

const delivery = createObjectReducer(
  {
    value: '',
    options: [
      {
        id: 'DELIVERY_METHOD/IML',
        text: 'Пункты самовывоза IML',
        time: '3-5 дней',
        timeNote: 'Срок хранения заказа 3 дня',
        price: {
          current: 220,
          default: 220,
          discount: 0
        },
        priceNote: 'Бесплатно при заказе от 3000 Р',
        buttonFlag: true
      },
      {
        id: 'DELIVERY_METHOD/PICK_POINT',
        text: 'Постаматы PickPoint',
        time: '3-5 дней',
        timeNote: 'Срок хранения заказа 3 дня',
        price: {
          current: 290,
          default: 290,
          discount: 0
        },
        priceNote: 'Бесплатно при заказе от 3000 Р',
        buttonFlag: false
      },
      {
        id: 'DELIVERY_METHOD/CURIER',
        text: 'Курьер',
        time: '20 августа',
        timeNote: 'Доставка по Москве осуществляется в будни с 10 до 22, в выходные с 10 до 18.',
        price: {
          current: 290,
          default: 290,
          discount: 0
        },
        priceNote: 'Бесплатно при заказе от 3000 Р',
        buttonFlag: false
      }
    ]
  },
  {
    [CHANGE_DELIVERY_METHOD]: (state, { id }) => changeDeliveryMethod(state, id)
  }
);

const withPassword = createObjectReducer(
  {
    checked: false
  },
  {
    [CHANGE_PASSWORD_CREATING]: (state, { checked }) => changePasswordCreating(state, checked)
  }
);

const comment = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_COMMENT]: (state, { text }) => changeComment(state, text)
  }
);

const email = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_EMAIL]: (state, { email }) => changeEmail(state, email)
  }
);

const number = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_NUMBER]: (state, { number }) => changeNumber(state, number)
  }
);

const name = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_NAME]: (state, { name }) => changeName(state, name)
  }
);

const street = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_STREET]: (state, { street }) => changeStreet(state, street)
  }
);

const house = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_HOUSE]: (state, { house }) => changeHouse(state, house)
  }
);

const housing = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_HOUSING]: (state, { housing }) => changeHousing(state, housing)
  }
);

const building = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_BUILDING]: (state, { building }) => changeBuilding(state, building)
  }
);

const apartment = createObjectReducer(
  {
    value: ''
  },
  {
    [CHANGE_APARTMENT]: (state, { apartment }) => changeApartment(state, apartment)
  }
);

function changeCountryValue(state, countryId) {
  return {
    ...state,
    value: countryId
  };
}

function changeCityValue(state, cityId) {
  return {
    ...state,
    value: cityId
  };
}

function changePaymentType(state, paymentId) {
  return {
    ...state,
    value: paymentId
  };
}

function changeDeliveryMethod(state, deliveryId) {
  return {
    ...state,
    value: deliveryId
  };
}

function changePasswordCreating(state, flag) {
  return {
    ...state,
    checked: flag
  };
}

function changeComment(state, text) {
  return {
    ...state,
    value: text
  };
}

function changeEmail(state, email) {
  return {
    ...state,
    value: email
  };
}

function changeNumber(state, number) {
  return {
    ...state,
    value: number.replace(/[^0-9]/g, '')
  };
}

function changeName(state, name) {
  return {
    ...state,
    value: name
  };
}

function changeStreet(state, street) {
  return {
    ...state,
    value: street
  };
}

function changeHouse(state, house) {
  return {
    ...state,
    value: house
  };
}

function changeHousing(state, housing) {
  return {
    ...state,
    value: housing
  };
}

function changeBuilding(state, building) {
  return {
    ...state,
    value: building
  };
}

function changeApartment(state, apartment) {
  return {
    ...state,
    value: apartment.replace(/[^0-9]/g, '')
  };
}

export default combineReducers({
  country,
  city,
  payment,
  withPassword,
  comment,
  email,
  number,
  name,
  street,
  house,
  housing,
  building,
  apartment,
  delivery
});
