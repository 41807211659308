import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { actions as Dialog } from '../ducks/dialogs';
import * as fromAccount from '../ducks/account';
import * as fromNavigations from 'modules/core/ducks/navigations';
import AccountActions from '../ducks/actions/account';
import * as fromProfile from 'modules/profile/ducks/profile';

import NavigationDrawer from '../components/navigation-drawer/navigation-drawer';

const mapStateToProps = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized,
  navigation: fromNavigations.getDrawerCatalogWithSucatalogNavigation,
  isNavigationLoaded: fromNavigations.getNavigationDataLoaded,
  isProfileLoading: fromProfile.getIsLoading
});

const mapDispatchToProps = {
  openAuthorizationDialog: Dialog.openAuthDialog,
  openRegistrationDialog: Dialog.openRegistrationDialog,
  signOut: AccountActions.signOut
};

class NavigationDrawerContainer extends Component {
  render() {
    const { isAuthorized, ...rest } = this.props;

    return (
      <NavigationDrawer
        isAuthorized={isAuthorized}
        onAuthorizationDialogOpen={this.handleAuthorizationDialogOpen}
        onRegistrationDialogOpen={this.handleRegistrationDialogOpen}
        onCityDialogOpen={this.handleCityDialogOpen}
        onSingOut={this.handleSingOut}
        {...rest}
      />
    );
  }

  handleAuthorizationDialogOpen = () => {
    const { openAuthorizationDialog, onDialogOpened } = this.props;

    onDialogOpened();
    openAuthorizationDialog();
  };

  handleRegistrationDialogOpen = () => {
    const { openRegistrationDialog, onDialogOpened } = this.props;

    onDialogOpened();
    openRegistrationDialog();
  };

  handleCityDialogOpen = () => {
    const { onDialogOpened } = this.props;
    onDialogOpened();
  };

  handleSingOut = e => {
    e.preventDefault();
    const { signOut } = this.props;

    signOut();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawerContainer);
