import { schema } from 'normalizr';

export default function build() {
  const category = new schema.Entity(
    'category',
    {},
    {
      idAttribute: 'slug'
    }
  );

  category.define({
    nested: [category]
  });

  return category;
}
