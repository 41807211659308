import React, { Component } from 'react';
import Link from 'modules/core/components/link/short-link';

import './removed-product.css';
import { IconReturn } from '../../../core/components/icons';
import { getProductLink } from 'routes/links';

class RemovedProduct extends Component {
  state = {};
  render() {
    const { product, onCancel } = this.props;
    return (
      <div className="Basket_RemovedProduct">
        <span>
          Продукт{' '}
          <Link href={getProductLink(product)} target="_blank" rel="noopener noreferrer">
            <b>{product.title}</b>
          </Link>{' '}
          удалён из корзины.
        </span>
        &nbsp;&nbsp;
        <Link
          component="button"
          type="button"
          prependedIcon={<IconReturn />}
          onClick={onCancel}
          inlineIcon
        >
          Вернуть
        </Link>
      </div>
    );
  }
}

export default RemovedProduct;
