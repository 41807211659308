import React, { Component } from 'react';
import './sorting.css';
import SelectField from './select-field/select-field';

class Sorting extends Component {
  render() {
    const { summary, ...rest } = this.props;
    return (
      <div className="Sorting">
        <div className="Sorting-summary">{summary}</div>
        <div className="Sorting-select">
          <SelectField {...rest} />
        </div>
      </div>
    );
  }
}

export default Sorting;
