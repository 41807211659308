import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import CategoryPanel from '../../category-panel/category-panel';

import './select-item.css';

class SelectItem extends Component {
  render() {
    const { current, options, renderItem } = this.props;

    return (
      <CategoryPanel.Item
        title={current.slug ? current.title : 'Подкатегории'}
        content={
          <div className="CategoryFilterPanel_SelectItem">
            <ul className="CategoryFilterPanel_SelectItem-list">
              {options.map(item => {
                const isAmountAvailability = this.checkAmountAvailability(item);
                const itemClassName = cn('CategoryFilterPanel_SelectItem-button', {
                  'CategoryFilterPanel_SelectItem-button--active': item.slug === current.slug
                });
                const children = (
                  <Fragment>
                    <span className="CategoryFilterPanel_SelectItem-buttonTitle">{item.title}</span>

                    {isAmountAvailability && (
                      <span className="CategoryFilterPanel_SelectItem-buttonAmount">
                        ({item.amount})
                      </span>
                    )}
                  </Fragment>
                );
                return (
                  <li key={item.id} className="CategoryFilterPanel_SelectItem-listItem">
                    {typeof renderItem === 'function' ? (
                      renderItem({
                        item,
                        className: itemClassName,
                        onClick: this.handleClick(item),
                        children
                      })
                    ) : (
                      <button className={itemClassName} onClick={this.handleClick(item)}>
                        {children}
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        }
      />
    );
  }

  checkAmountAvailability(item) {
    return (item.amount || item.amount === 0) && typeof item.amount === 'number';
  }

  handleClick = item => () => {
    const { onClick } = this.props;

    onClick(item);
  };
}

export default SelectItem;
