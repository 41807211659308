export default function timeIntervalToString(time) {
  const separatedStartAndEnd = separateStartAndEndArray(time);

  const separatedTimeValues = separateTimeValues(separatedStartAndEnd);

  const hoursMinutesFormat = setHoursMinutesFormat(separatedTimeValues);

  const hoursMinutesFormatString = setHoursMinutesFormatToString(hoursMinutesFormat);

  return hoursMinutesFormatString.join('-');
}

function separateStartAndEndArray(scheduleString) {
  return scheduleString.split('/');
}

function separateTimeValues(separatedStartAndEnd) {
  return separatedStartAndEnd.map(time => time.split(':'));
}

function setHoursMinutesFormat(separatedTimeValues) {
  return separatedTimeValues.map(time => {
    return [time[0], time[1]];
  });
}

function setHoursMinutesFormatToString(hoursMinutesFormat) {
  return hoursMinutesFormat.map(time => time.join(':'));
}
