const getMask = (charAmount, filler, separator = ' ') => {
  let mask = [];

  for (let i = 1; i <= charAmount; i++) {
    mask.push(filler);
    if (i !== charAmount && separator) {
      mask.push(separator);
    }
  }

  return mask;
};

export default getMask;
