import PropTypes from 'prop-types';

let { string, bool, number, arrayOf, shape, oneOf } = PropTypes;

const pickPointShape = shape({
  id: number,
  title: string,
  type: oneOf(['PICK_POINT_TYPE/PICK_POINT']),
  deliveryDuration: shape({
    max: string,
    min: string
  }),
  description: string,
  geography: shape({
    address: shape({
      streetTitle: string,
      building: string,
      raw: string
    }),
    coordinates: shape({
      lat: number,
      lng: number
    }),
    guides: arrayOf(
      shape({
        type: oneOf(['GUIDE_TYPE/SUBWAY_STATION', 'GUIDE_TYPE/SHOPPING_MALL']),
        title: string
      })
    )
  }),
  schedule: arrayOf(
    shape({
      days: arrayOf(number),
      time: string,
      break: string
    })
  ),
  contacts: arrayOf(string),
  payment: shape({
    hasTerminal: bool
  })
});

export default pickPointShape;
