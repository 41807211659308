import { takeEvery, put, call, select } from 'redux-saga/effects';
import { actions as CityDelivery } from '../ducks/city-delivery';
import { mixitApi } from 'services/mixit';

import * as fromBasket from '../ducks/basket/selectors';
import * as Basket from '../domain/basket';
import { actions as DeliveryForm } from 'modules/ordering/ducks/delivery-form';

import { types as DELIVERY_FORM } from '../ducks/delivery-form';
import * as CITY_DELIVERY from '../ducks/types/city-delivery';
import * as fromCityTable from '../ducks/city-table';
import Dadata from 'services/dadata';
const dadata = new Dadata(process.env.REACT_APP_DADATA_API_KEY);

export function* cityChangeWatcher() {
  yield takeEvery(DELIVERY_FORM.SET_INITIAL_DATA, onSetInitialData);
  yield takeEvery(DELIVERY_FORM.CHANGE_CITY_FIAS_ID, onChangeCityFiasId);
  yield takeEvery(DELIVERY_FORM.CHANGE_CITY_GEONAME_ID, onChangeCityGeonameId);
}

export function* cityDeliveryWatcher() {
  yield takeEvery(CITY_DELIVERY.REQUEST, requestCityDelivery);
}

export function* pickPointsWatcher() {
  yield takeEvery(CITY_DELIVERY.PICK_POINT_REQUEST, requestPickPoints);
}

function* onSetInitialData(action) {
  const { city } = action.payload;

  if (!city || !city.fiasId) {
    return;
  }


  yield put(CityDelivery.request(city.fiasId));
}

function* onChangeCityFiasId(action) {
  const { fiasId } = action.payload;

  if (!fiasId) {
    return;
  }
  // yield put(DeliveryForm.changeDeliveryMethodId(null));

  yield put(CityDelivery.request(fiasId));
}

function* onChangeCityGeonameId(action) {
  const { geonameId } = action.payload;

  if (!geonameId) {
    return;
  }

  yield put(CityDelivery.requestByGeonameId(geonameId));
}

function* requestCityDelivery(action) {
  const { fiasId, geonameId } = action.payload;

  let city = null;

  yield put(DeliveryForm.changeDeliveryMethodId(null));

  if (geonameId) {
    city = yield select(fromCityTable.getCityByGeonameId, geonameId); // ITS JUST ID!
  }

  if (fiasId) {
    city = yield select(fromCityTable.getCityByFiasId, fiasId); // ITS JUST ID!
  }

  let { deliveryRequestOptions } = city;

  if (!deliveryRequestOptions) {
    const data = yield getCityOPtionsFromDadata(city.value || city.title);
    const suggestedCity = data.suggestions[0];
    deliveryRequestOptions = getTransformedDadata(suggestedCity).deliveryRequestOptions;
  }

  try {
    const localBasket = yield getBasket();
    const deliveryMethodsResponse = yield call(
      mixitApi().deliveryMethods.basket,
      {
        ...localBasket
      },
      city.isSettlement || !fiasId
        ? {
            city_title: city.title,
            country_title: city.country,
            ...(deliveryRequestOptions && { ...deliveryRequestOptions })
          }
        : {
            city_fias_id: city.fiasId,
            ...(deliveryRequestOptions && { ...deliveryRequestOptions })
          }
    ); // TODO: Choose way to query city deliveries

    const deliveryList = deliveryMethodsResponse.data.map(normalizeCityDelivery);

    const deliveryMethods = reduceListToMap('id', deliveryList);
    const idList = deliveryList.map(item => item.id);

    if (fiasId) {
      yield put(CityDelivery.response(fiasId, deliveryMethods, idList));
    }

    if (geonameId) {
      yield put(CityDelivery.responseByGeonameId(geonameId, deliveryMethods, idList));
    }
    // TODO: Add city to global store
  } catch (error) {
    if (fiasId) {
      yield put(CityDelivery.responseError(fiasId, error));
    }

    if (geonameId) {
      yield put(CityDelivery.responseErrorByGeonameId(geonameId, error));
    }
  }
}

function* requestPickPoints(action) {
  const { cityFiasId, deliveryMethodId, pickPointType } = action.payload;

  let city = null;
  city = yield select(fromCityTable.getCityByFiasId, cityFiasId);
  const data = yield getCityOPtionsFromDadata(city.value || city.title);
  const suggestedCity = data.suggestions[0];
  const deliveryRequestOptions = getTransformedDadata(suggestedCity).deliveryRequestOptions;
  deliveryRequestOptions.city_title = city.title;
  deliveryRequestOptions.country_title = deliveryRequestOptions.country;

  // TODO: Check if pickpoints was already loaded

  try {
    // const result = yield call(mixitApi().pickPoints.list, cityFiasId, pickPointType);
    const basket = yield getBasket();
    const result = yield call(
      mixitApi().pickPoints.list,
      cityFiasId,
      pickPointType,
      basket,
      deliveryRequestOptions
    );

    const resultData = result.data;

    const pickPoints = normalizePickPoints(resultData);

    // console.log(resultData);
    // testResponseData

    yield put(CityDelivery.pickPointResponse(cityFiasId, deliveryMethodId, pickPoints));
  } catch (e) {
    yield put(CityDelivery.pickPointResponse(cityFiasId, deliveryMethodId, null));
  }
}

function* getBasket() {
  const goods = yield select(fromBasket.getGoods);
  const promos = yield select(fromBasket.getPromos);
  const currentBasket = Basket.serialize({ goods, promos });
  return currentBasket;
}

function normalizeCityDelivery(cityDeliveryApi) {
  const {
    id,
    name,
    price,
    description,
    title,
    free_delivery_from
    // = 250
  } = cityDeliveryApi;

  // const isCourier = name === 'DELIVERY_METHOD/COURIER';

  return {
    id,
    name,
    description,
    price,
    title,
    ...(free_delivery_from && { freeDeliveryFrom: free_delivery_from })
    // ...(isCourier && { freeDeliveryFrom: free_delivery_from })
  };
}

function reduceListToMap(key, list) {
  return list.reduce((all, item) => {
    return {
      ...all,
      [item[key]]: item
    };
  }, {});
}

function normalizePickPoints(pickPoints) {
  return {
    result: pickPoints.map(item => item.id),
    entities: {
      pickPoint: pickPoints.reduce((all, item) => Object.assign(all, { [item.id]: item }), {})
    }
  };
}

function* getCityOPtionsFromDadata(query) {
  const data = yield dadata.city(query, {
    withSettlements: true,
    locations: [
      {
        country: 'Россия'
      },
      {
        country: 'Беларусь'
      },
      {
        country: 'Казахстан'
      }
    ]
  });
  return data;
}

function getTransformedDadata(item) {
  return {
    id: item.data.city_fias_id || item.data.settlement_fias_id || item.data.geoname_id,
    title: item.value,
    fiasId: item.data.city_fias_id || item.data.settlement_fias_id,
    name: item.data.city || item.data.settlement,
    type: item.data.city_type_full || item.data.settlement_type_full,
    country: item.data.country,
    geonameId: item.data.geoname_id,
    isSettlement: item.data.fias_level === '6',
    isCity: item.data.fias_level === '4',
    deliveryRequestOptions: getDeliveryRequestOptions(item.data)
  };
}

function getDeliveryRequestOptions(data) {
  const {
    country,
    region_type,
    region,
    settlement_type,
    city,
    settlement,
    city_type,
    country_iso_code
  } = data;
  return {
    ...(city && { city }),
    ...(city_type && { city_type }),
    ...(region && { region }),
    ...(region_type && { region_type }),
    ...(settlement && { settlement }),
    ...(settlement_type && {
      settlement_type
    }),
    ...(country && { country }),
    ...(country && { country_title: country }),
    ...(country_iso_code && { country_iso_code })
  };
}
