import React, { Component } from 'react';

import CountedList from '../counted-list/counted-list';
import agreements from '../../seeds/agreements';
import Container from 'modules/core/components/container/container';

class AgreemetsSection extends Component {
  render() {
    return (
      <Container>
        <div className="AgreemetsSection">
          <CountedList list={agreements} />
        </div>
      </Container>
    );
  }
}

export default AgreemetsSection;
