import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import cn from 'classnames';

import './scroll-bar.css';

// const { string, bool, oneOf, number, oneOfType, func, element } = PropTypes;

class ScrollBar extends Component {
  render() {
    const { amount, totalAmount, scrolled, onBeforeClick, onAfterClick, activeBar } = this.props;

    const activeWidth = (amount * 100) / totalAmount;

    const offsetLeft = (scrolled * 100) / totalAmount;

    const afterWidth = 100 - offsetLeft - activeWidth;

    return (
      <div className="ScrollBar">
        {!!offsetLeft && activeBar && (
          <button
            className="ScrollBar-before"
            style={{ width: `${offsetLeft}%` }}
            onClick={onBeforeClick}
          />
        )}

        <div
          className="ScrollBar-active"
          style={{
            width: `${activeWidth}%`,
            left: `${offsetLeft}%`
          }}
        />

        {!!afterWidth && activeBar && (
          <button
            className="ScrollBar-after"
            style={{ width: `${afterWidth}%` }}
            onClick={onAfterClick}
          />
        )}
      </div>
    );
  }
}

export default ScrollBar;
