export function deserializeDetails(details) {
  const {
    id,
    product_id,
    related_products,
    similar_products,
    included_products,
    storage_conditions,
    consistency,
    usage,
    reviews,
    ...rest
  } = details;

  const related = related_products ? related_products.map(({ slug }) => slug) : null;
  const similar = similar_products ? similar_products.map(({ slug }) => slug) : null;
  const included = included_products ? included_products.map(({ slug }) => slug) : null;
  const storage = storage_conditions;
  const normalUsage = usage && !Array.isArray(usage) ? usage : null;
  const reviewIds = (reviews || []).map(({ id }) => id);

  const ingredients = consistency && consistency.ingredients ? consistency.ingredients : [];
  const primary = ingredients.filter(ingredient => ingredient.primary).slice(0, 4);

  const normalConsistency =
    consistency && !Array.isArray(consistency)
      ? {
          ingredients: ingredients.map(({ id }) => id),
          ingredientGroups: consistency.ingredient_groups,
          primary: primary.map(({ id }) => id),
          rawText: consistency.ingredients_raw
        }
      : null;

  return {
    ...rest,
    consistency: normalConsistency,
    included,
    related,
    similar,
    storage,
    usage: normalUsage,
    reviews: reviewIds
  };
}
