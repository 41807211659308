export default function initFaqRequest(createRequest) {
  return createRequest(() => {
    return {
      // method: 'GET',
      path: `/faq`
    };
  })
}

// export default function initInstagramReviewsRequest(createRequest) {
//   return createRequest(() => {
//     return {
//       path: `/social/instagram-reviews`
//     };
//   });
// }
