import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import { getAgreementsLink } from 'routes/links';

import Form from 'modules/form/components/form/form';
import Input from 'modules/form/components/input/input';
import PasswordInput from 'modules/form/components/password-input/password-input';
import Field from 'modules/form/components/field/field';

import Button from '../button/button';
import Link from '../link/short-link';
import { IconFlatLoader } from '../icons';

import './registration-form.css';

class RegistrationForm extends Component {
  render() {
    const { errors, values, touched, isValidating, onChange, onSubmit, onBlur } = this.props;

    const isButtonDisabled = this.checkButtonDisabling();

    return (
      <div className="RegistrationForm">
        <Form onSubmit={onSubmit}>
          <Media query="(max-width: 1100px)" render={this.renderAuthorizationSuggest} />

          <Media query="(max-width: 840px)">
            {isMobile => (
              <Fragment>
                <Form.LabeledRow>
                  <Field
                    label="Имя"
                    error={touched.firstName && errors.firstName}
                    size={isMobile ? 'small' : 'normal'}
                  >
                    <Input
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      size={isMobile ? 'small' : 'normal'}
                      onChange={onChange}
                      onBlur={onBlur}
                      state={touched.firstName && errors.firstName && 'error'}
                    />
                  </Field>
                </Form.LabeledRow>

                <Form.LabeledRow>
                  <Field
                    label="Email"
                    error={touched.email && errors.email}
                    size={isMobile ? 'small' : 'normal'}
                  >
                    <Input
                      name="email"
                      type="email"
                      value={values.email}
                      size={isMobile ? 'small' : 'normal'}
                      onChange={onChange}
                      onBlur={onBlur}
                      state={touched.email && errors.email && 'error'}
                    />
                  </Field>
                </Form.LabeledRow>

                <Form.LabeledRow>
                  <Field
                    label="Пароль"
                    error={touched.password && errors.password}
                    size={isMobile ? 'small' : 'normal'}
                  >
                    <PasswordInput
                      name="password"
                      type="password"
                      value={values.password}
                      size={isMobile ? 'small' : 'normal'}
                      onChange={onChange}
                      onBlur={onBlur}
                      state={touched.password && errors.password && 'error'}
                    />
                  </Field>
                </Form.LabeledRow>

                {!isMobile && this.renderUserAgreement()}

                <Media query="(max-width: 480px)">
                  {matches480 => (
                    <Form.ActionRow>
                      <Button
                        type="submit"
                        title="Зарегистрироваться"
                        variant="primary"
                        size={matches480 ? 'small' : 'normal'}
                        expanded={isMobile}
                        disabled={isButtonDisabled}
                        iconAppend={isValidating && <IconFlatLoader />}
                      />
                    </Form.ActionRow>
                  )}
                </Media>

                {isMobile && this.renderUserAgreement()}
              </Fragment>
            )}
          </Media>
        </Form>
      </div>
    );
  }

  renderTelephoneField() {
    return (
      <Field label="Номер телефона:">
        <Input type="tel" />
      </Field>
    );
  }

  renderAuthorizationSuggest = () => {
    const { onAuthOpen } = this.props;

    return (
      <Form.Row>
        <div className="RegistrationForm-authorizationSuggest">
          <span className="RegistrationForm-authorizationSuggestText">У вас уже есть аккаунт?</span>
          <Link component="button" type="button" onClick={onAuthOpen}>
            Войти
          </Link>
        </div>
      </Form.Row>
    );
  };

  renderUserAgreement() {
    return (
      <Form.Row>
        <div className="RegistrationForm-userAgreement">
          Нажимая кнопку «Зарегистрироваться», <nobr>я принимаю условия</nobr>{' '}
          <a
            className="RegistrationForm-userAgreementLink"
            href={getAgreementsLink()}
            target="_blank"
            rel="noreferrer noopener"
          >
            Пользовательского соглашения
          </a>
        </div>
      </Form.Row>
    );
  }

  checkButtonDisabling() {
    const { touched, errors, isValidating } = this.props;

    if (isValidating) {
      return true;
    }

    const errorsKeys = Object.keys(errors);

    for (let i = 0; i < errorsKeys.length; i++) {
      const key = errorsKeys[i];
      if (touched[key]) {
        return true;
      }
    }

    return false;
  }
}

export default RegistrationForm;
