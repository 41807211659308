import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromCategories from '../ducks/categories';
import CategoryProducts from '../components/category-products/category-products';
import * as fromSortOptions from '../ducks/sort-options';

const getCurrentNested = (_, props) => props.nested;
const getCurrentSlug = (_, props) => {
  if (props.nested) {
    return props.nested;
  }
  return props.slug;
};

const mapStateToProps = createStructuredSelector({
  category: fromCategories.getCategoryBySlug,
  productsCategorySlug: getCurrentSlug,
  nestedList: fromCategories.getNestedCategoriesBySlug,
  currentNested: fromCategories.getNested(getCurrentNested),
  listedAmount: fromCategories.makeGetProductsAmount(getCurrentSlug),
  totalAmount: fromCategories.makeGetTotalAmount(getCurrentSlug),
  filters: fromCategories.makeGetPossibleFilters(getCurrentSlug),
  isFiltersApplied: fromCategories.getHasAppliedFilters,
  quickFilters: fromCategories.getQuickFilters,
  appliedFilters: fromCategories.getValidAppliedFilters,
  sortOptions: fromSortOptions.getList
});

const mapDispatchToProps = {
  setFilter: fromCategories.actions.setFilter,
  resetFilter: fromCategories.actions.resetFilter,
  sort: fromCategories.actions.sort
};

class CategoryProductsContainer extends Component {
  static propTypes = {
    /** Category slug */
    slug: PropTypes.string.isRequired
  };

  render() {
    return (
      <CategoryProducts
        {...this.props}
        onFilterChange={this.handleFilterChange}
        onFilterRemove={this.handleFilterRemove}
        onFiltersReset={this.handleFiltersReset}
        onNestedChange={this.handleNestedChange}
        onSortingChange={this.handleSortingChange}
      />
    );
  }

  handleFilterChange = ({ options }) => {
    const { slug, nested, setFilter } = this.props;

    setFilter(slug, nested, options);
  };

  handleFilterRemove = option => {
    const { slug, nested, appliedFilters, setFilter } = this.props;

    setFilter(
      slug,
      nested,
      appliedFilters.filter(item => item !== option)
    );
  };

  handleFiltersReset = () => {
    const { resetFilter, slug, nested } = this.props;

    resetFilter(slug, nested);
  };

  handleNestedChange = nested => {
    const { slug, appliedFilters, changeNested } = this.props;
    const nestedSlug = nested.slug;

    changeNested(slug, nestedSlug, appliedFilters);
  };

  handleSortingChange = id => {
    const { slug, nested, appliedFilters } = this.props;
    this.props.sort(slug, id, { appliedFilters, nestedSlug: nested });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductsContainer);
