import { takeEvery, call, put, select } from 'redux-saga/effects';

import { mixitApi } from 'services/mixit';

import * as fromCore from 'modules/core/ducks';
import Subscriptions from '../actions/subscriptions';
import * as fromSubscriptions from '../ducks/subscriptions';
import * as SUBSCRIPTIONS from '../types/subscriptions';

import { deserializeTopic } from '../domain/subscriptions';
import normalizeTopics from 'services/mixit/adapters/topics';

export function* subscriptionsWatcher() {
  yield takeEvery(SUBSCRIPTIONS.SHOW, requestSubscriptions);
  yield takeEvery(SUBSCRIPTIONS.SUBSCRIBE_TOPIC, requestTopicSubscription);
  yield takeEvery(SUBSCRIPTIONS.UNSUBSCRIBE_TOPIC, requestTopicUnsubscription);
  yield takeEvery(SUBSCRIPTIONS.SUBSCRIBE_CHANNEL, requestChannelSubscription);
  yield takeEvery(SUBSCRIPTIONS.UNSUBSCRIBE_CHANNEL, requestChannelUnsubscription);
}

function* requestSubscriptions() {
  const authorization = yield select(fromCore.getAuthorization);

  try {
    const response = yield call(mixitApi(authorization).subscriptionList().all);

    const { channels, topics } = response.data;

    const deserializedTopics = topics.map(topic => deserializeTopic(topic));
    const normalizedTopics = normalizeTopics(deserializedTopics);

    yield put(Subscriptions.addSubscriptions(channels, normalizedTopics));
  } catch (error) {
    console.warn(error);
  }
}

function* requestTopicSubscription(action) {
  const { slug } = action.payload;

  const authorization = yield select(fromCore.getAuthorization);

  try {
    yield call(
      mixitApi(authorization)
        .subscriptionList()
        .item(slug).add
    );
  } catch (error) {
    console.warn(error);
    yield put(Subscriptions.subscribeTopicError(slug));
  }
}

function* requestTopicUnsubscription(action) {
  const { slug } = action.payload;

  const authorization = yield select(fromCore.getAuthorization);

  try {
    yield call(
      mixitApi(authorization)
        .subscriptionList()
        .item(slug).delete
    );
  } catch (error) {
    console.warn(error);
    yield put(Subscriptions.unsubscribeTopicError(slug));
  }
}

// Это верменный очень плохой код. Аккуратно! Может вызвать приступ плача и разочарования
function* requestChannelSubscription(action) {
  const { slug } = action.payload;

  const authorization = yield select(fromCore.getAuthorization);

  const channelList = yield select(fromSubscriptions.getChannelListWithTopics);

  const channel = channelList.filter(item => item.slug === slug)[0];

  const topicList = channel.topicList;

  for (let i = 0; i < topicList.length; i++) {
    const item = topicList[i];

    try {
      yield call(
        mixitApi(authorization)
          .subscriptionList()
          .item(item.slug).add
      );
    } catch (error) {
      console.warn(error);
      yield put(Subscriptions.subscribeTopicError(item.slug));
    }
  }
}

function* requestChannelUnsubscription(action) {
  const { slug } = action.payload;

  const authorization = yield select(fromCore.getAuthorization);

  const channelList = yield select(fromSubscriptions.getChannelListWithTopics);

  const channel = channelList.filter(item => item.slug === slug)[0];

  const topicList = channel.topicList;

  for (let i = 0; i < topicList.length; i++) {
    const item = topicList[i];

    try {
      yield call(
        mixitApi(authorization)
          .subscriptionList()
          .item(item.slug).delete
      );
    } catch (error) {
      console.warn(error);
      yield put(Subscriptions.unsubscribeTopicError(item.slug));
    }
  }
}
