import React, { Component } from 'react';
import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';
import { Link } from 'react-router-dom';

import { IconAngleArrowRight } from '../icons/index';

import './catalog-navigation-mobile.css';

class CatalogNavigationMobileItem extends Component {
  state = {
    isActive: false
  };

  render() {
    const { item, animatedCollapsing, subMenuTriggerRef } = this.props;

    const LinkComponent = !!item.submenu ? 'button' : item.href ? 'a' : Link;

    const linkProps = {
      ...(item.href && !item.submenu && { href: item.href }),
      ...(item.link && !item.submenu && { to: item.link }),
      ...item.props
    };

    return (
      <li
        className={classNames('CatalogNavigationMobile-subitem', {
          'CatalogNavigationMobile-subitem--active': this.state.isActive,
          'CatalogNavigationMobile-item--highlight': item.highlight
        })}
      >
        <div className="CatalogNavigationMobile-controls">
          <LinkComponent
            {...linkProps}
            onClick={() => {
              this.setState(state => ({
                isActive: !state.isActive
              }));
            }}
            className="CatalogNavigationMobile-link"
          >
            {item.title}
          </LinkComponent>
          {!!item.submenu && (
            <button
              onClick={() => {
                this.setState(state => ({
                  isActive: !state.isActive
                }));
              }}
              className="CatalogNavigationMobile-submenuTrigger"
              ref={subMenuTriggerRef(item.id)}
            >
              <span className="CatalogNavigationMobile-iconSizer">
                <IconAngleArrowRight />
              </span>
            </button>
          )}
        </div>
        {animatedCollapsing ? (
          <UnmountClosed isOpened={this.state.isActive}>{this.renderSubmenu()}</UnmountClosed>
        ) : (
          this.state.isActive && this.renderSubmenu()
        )}
      </li>
    );
  }

  renderSubmenu() {
    const { item, subMenuRef } = this.props;

    return (
      <ul className="CatalogNavigationMobile-submenu" ref={subMenuRef(item.id)}>
        {item.submenu.map((subItem, index) => this.renderSubmenuItem(subItem, index))}
      </ul>
    );
  }

  renderSubmenuItem = (subItem, index) => {
    if (!subItem.link) {
      console.warn(`!_Пункт меню:${JSON.stringify(subItem)} (index: ${index}) не валидный`);
      return null;
    }
    return (
      <li key={index} className="CatalogNavigationMobile-submenuItem">
        <Link
          to={subItem.link}
          onClick={this.handleSubClick(subItem)}
          className="CatalogNavigationMobile-submenuLink"
        >
          {subItem.title}
        </Link>
      </li>
    );
  };

  handleSubClick = item => () => {
    const { onCatalogLinkClick, onNestedLinkClick, onFilterLinkClick, item: category } = this.props;

    // debugger;

    if (item.filter) {
      onFilterLinkClick(category.slug, [item.filter]);
      return;
    }

    onNestedLinkClick(category.slug, item.slug);

    onCatalogLinkClick(item)();
  };
}

export default CatalogNavigationMobileItem;
