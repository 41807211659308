import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './quick-filter.css';

class QuickFilter extends Component {
  static propTypes = {
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * id - id of tab switcher
         */
        id: PropTypes.number,

        /**
         * title - name of tab switcher
         */
        title: PropTypes.string,

        /**
         * active - indicator of active filter
         */
        active: PropTypes.bool
      })
    )
  };

  render() {
    const { filters } = this.props;
    return (
      <div className="QuickFilter">
        {filters.map((item, index) => (
          <div key={index} className={item.active ? 'QuickFilter-active' : 'QuickFilter-item'}>
            {item.title}
          </div>
        ))}
      </div>
    );
  }
}

export default QuickFilter;
