import React, { Component } from 'react';
import './icon-card.css';

class IconCard extends Component {
  render() {
    return (
      <svg className="IconCard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M27.8 25.1H5.2C4 25.1 3 24 3 22.9V8.7c0-1.2 1-2.2 2.2-2.2h22.4c1.2 0 2.2 1 2.2 2.2v14c.2 1.3-.8 2.4-2 2.4z" />
        <path d="M5.5 11.6h10.2M3 20h27M18.2 11.6h5.9" />
      </svg>
    );
  }
}

export default IconCard;
