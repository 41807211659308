import PropTypes from 'prop-types';

const { number, string, shape, arrayOf, oneOf } = PropTypes;

const mainMediaShape = shape({
  normal: string,
  double: string
});

export default shape({
  id: number,
  type: oneOf(['MEDIA_TYPE/IMAGE', 'MEDIA_TYPE/VIDEO']),
  title: string,
  caption: string,
  sources: arrayOf(
    shape({
      size: number,
      main: mainMediaShape,
      fallback: mainMediaShape,
      cover: number
    })
  )
});
