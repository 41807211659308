import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Link from 'modules/core/components/link/short-link';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconInfo } from 'modules/core/components/icons';
import IconAlert from 'modules/core/components/icons/icon-alert/icon-alert';
const { string } = PropTypes;

class Result extends Component {
  static propTypes = {
    /** Код промокода */
    // code: string.isRequired,

    /** Описание промокода */
    description: string.isRequired
  };

  render() {
    const { coupon, showWarning, couponInfoButtonRef, onInfoButtonClick } = this.props;

    return (
      <div className="Coupon-result">
        <div className="Coupon-resultInfo">
          <span className="Coupon-description">
            Промокод <span className="Coupon-code">{coupon.code}</span> применен
          </span>

          <button
            className="Coupon-infoButton"
            title="Дополнительная информация"
            ref={couponInfoButtonRef}
            onClick={onInfoButtonClick}
          >
            {showWarning ? (
              <span className="Coupon-alertIcon">
                <IconContainer inline>
                  <IconAlert />
                </IconContainer>
              </span>
            ) : (
              <span className="Coupon-infoIcon">
                <IconContainer inline>
                  <IconInfo />
                </IconContainer>
              </span>
            )}
          </button>

          <Media query="(min-width:1001px)">
            {!showWarning && !!coupon.options.length && (
              <div className="Coupon-changeGift">
                <Link component="button" type="button" onClick={this.handleChangeGiftClick}>
                  Выбрать другой подарок
                </Link>
              </div>
            )}
          </Media>
          <div className="Coupon-resultReset">
            <Link component="button" type="button" onClick={this.handleResetClick}>
              Отменить
            </Link>
          </div>
        </div>
        <Media query="(max-width:1000px)">
          {!showWarning && !!coupon.options.length && (
            <div className="Coupon-changeGiftPanel">
              <div className="Coupon-changeGift">
                <Link component="button" type="button" onClick={this.handleChangeGiftClick}>
                  Выбрать другой подарок
                </Link>
              </div>
            </div>
          )}
        </Media>
      </div>
    );
  }

  handleResetClick = () => {
    const { onReset } = this.props;

    onReset();
  };

  handleChangeGiftClick = () => {
    const { openApplyAnotherGiftDialog } = this.props;

    openApplyAnotherGiftDialog();
    // onReset();

    // onSubmit(coupon);
  };
}

export default Result;
