import React, { Component } from 'react';
import './icon-coupon.css';

class IconCoupon extends Component {
  render() {
    return (
      <svg className="IconCoupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 319">
        <path d="M448.8 123.5c4 0 7.2-3.2 7.2-7.2v-72c0-4-3.2-7.2-7.2-7.2H31.2c-4 0-7.2 3.2-7.2 7.2v72c0 4 3.2 7.2 7.2 7.2 19.9 0 36 16.1 36 36s-16.1 36-36 36c-4 0-7.2 3.2-7.2 7.2v72c0 4 3.2 7.2 7.2 7.2h417.6c4 0 7.2-3.2 7.2-7.2v-72c0-4-3.2-7.2-7.2-7.2-19.9 0-36-16.1-36-36s16.1-36 36-36zm-49.9 43.2c3.2 22.1 20.6 39.5 42.7 42.7v58.1H38.4v-58.1c27.6-4 46.7-29.5 42.7-57-3.2-22.1-20.6-39.5-42.7-42.7V51.5h403.2v58.1c-27.6 4-46.7 29.5-42.7 57.1z" />
        <path d="M196 99.7c-15.9 0-28.8 12.9-28.8 28.8s12.9 28.8 28.8 28.8 28.8-12.9 28.8-28.8-12.9-28.8-28.8-28.8zm0 43.2c-8 0-14.4-6.4-14.4-14.4s6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4-6.4 14.4-14.4 14.4zM279 165.7c-15.9 0-28.8 12.9-28.8 28.8s12.9 28.8 28.8 28.8 28.8-12.9 28.8-28.8-12.9-28.8-28.8-28.8zm0 43.2c-8 0-14.4-6.4-14.4-14.4s6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4-6.4 14.4-14.4 14.4zM173.278 212.038L288.464 96.851l10.182 10.183L183.46 222.22z" />
      </svg>
    );
  }
}

export default IconCoupon;
