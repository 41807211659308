function getCurrentTime() {
  return Date.now();
}

class Timer {
  constructor(delay, onTimeOut) {
    this.delay = delay;
    this.onTimeOut = onTimeOut;
  }

  start() {
    this.startTime = getCurrentTime();
    this.timerId = setTimeout(this.onTimeOut, this.delay);
  }

  stop() {
    clearTimeout(this.timerId);
  }

  pause() {
    clearTimeout(this.timerId);
    this.delay += this.startTime - getCurrentTime();
  }

  resume() {
    this.start();
  }
}

export default Timer;
