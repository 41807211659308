import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './review-rating.css';
import classNames from 'classnames';
import { IconStar } from 'modules/core/components/icons';
import IconContainer from 'modules/core/components/icon-container/icon-container';

const FILL_VARIANT = {
  FULL: 'FULL',
  HALF: 'HALF'
};

class ReviewRating extends Component {
  static propTypes = {
    rating: PropTypes.number,
    big: PropTypes.bool
  };

  render() {
    const { rating, big, showRate, reverse } = this.props;
    const ratingFactor = (Math.round(2 * rating) + 1) / 2 - 1;

    const starList = [...Array(5)];

    return (
      <div
        title={rating}
        className={classNames('ReviewRating', {
          'ReviewRating--big': this.props.big,
          'ReviewRating--reverse': reverse
        })}
        data-testid="star-list"
      >
        {showRate && (
          <div className="ReviewRating-rate">{rating}</div>
        ) }
        <ul className="ReviewRating-list">
          {starList.map((_, index) => {
            const fullFill = index < ratingFactor;
            const halfFill = index === ratingFactor;
            const fill = (fullFill && FILL_VARIANT.FULL) || (halfFill && FILL_VARIANT.HALF);

            return (
              <li className="ReviewRating-star" key={index} data-testid="star">
                <IconContainer size={big ? 'large' : 'xsmall'}>
                  <IconStar fill={fill} />
                </IconContainer>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ReviewRating;
