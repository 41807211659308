import React, { Component } from 'react';
import ProductContainer from '../../containers/product';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

import Media from 'react-media';
import Loader from 'modules/core/components/loader/loader';
import Grid from '../grid/grid';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

const MAX_NEW_COUNT = 8;
const MOBILE_MAX_NEW_COUNT = 8;

function Layout({ children }) {
  return <Grid>{children}</Grid>;
}

class ProductsNew extends Component {
  render() {
    const { productList, error, isLoading, sectionProps } = this.props;

    if (error) {
      return null;
    }

    if (isLoading) {
      // return null;
      return (
        <SectionDefault title={sectionProps && sectionProps.title ? sectionProps.title : null}>
          <Container>
            <div className="ProductsPopular">
              <Loader type="spin" size="small" secondary />
            </div>
          </Container>
        </SectionDefault>
      );
    }

    return (
      <SectionDefault {...sectionProps}>
        <Container>
          <Media query="(max-width: 840px)">
            {isMobile => (
              <Layout>
                {/* {productList.map(slug => (
              <ProductContainer key={slug} slug={slug} listId="new" />
            ))} */}
                {this.getProductList(productList, isMobile).map(slug => (
                  <HideErrorBoundary>
                    <ProductContainer key={slug} slug={slug} listId="sale" />
                  </HideErrorBoundary>
                ))}
              </Layout>
            )}
          </Media>
        </Container>
      </SectionDefault>
    );
  }

  getProductList(productList, isMobile) {
    if (isMobile) {
      return productList.slice(0, MOBILE_MAX_NEW_COUNT);
    }

    return productList.slice(0, MAX_NEW_COUNT);
  }
}

export default ProductsNew;
