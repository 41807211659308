import React, { Component } from 'react';
// import PropTypes from 'prop-types';

class OrderTableSubCell extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   };

  render() {
    const { children } = this.props;
    return <div className="OrderTable-subCell">{children}</div>;
  }
}

export default OrderTableSubCell;
