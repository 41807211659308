import React, { Component } from 'react';

import Dropdown from 'modules/core/components/dropdown/dropdown';

import isFunction from 'modules/utils/is-function';

import './sorting-dropdown.css';

class SortingDropdown extends Component {
  render() {
    const { list, ...rest } = this.props;

    return (
      <Dropdown onChange={this.handleDropdownChange} {...rest}>
        <div className="SortingDropdown">
          <ul className="SortingDropdown-list">{list.map(this.renderSortType)}</ul>
        </div>
      </Dropdown>
    );
  }

  renderSortType = (sortType, index) => {
    return (
      <li key={sortType.id} className="SortingDropdown-item">
        <button className="SortingDropdown-type" onClick={this.handleSortTypeSelect(index)}>
          {sortType.title}
        </button>
      </li>
    );
  };

  closeDropdown() {
    const { closeDropdown } = this.props;
    closeDropdown();
  }

  openDropdown() {
    const { openDropdown } = this.props;
    openDropdown();
  }

  handleSortTypeSelect = index => () => {
    const { onSortTypeSelect } = this.props;

    if (!isFunction(onSortTypeSelect)) {
      return;
    }
    onSortTypeSelect(index);
    this.closeDropdown();
  };

  handleDropdownChange = isShowed => {
    if (isShowed) {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  };
}

export default SortingDropdown;
