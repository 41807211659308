import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as PRODUCTS_AUTOSUGGEST from './types';

// REDUCERS
const autosuggestedProducts = createReducer(
  on(PRODUCTS_AUTOSUGGEST.REQUEST, (state, { query }) => {
    return {
      ...state,
      query
    };
  }),

  on(PRODUCTS_AUTOSUGGEST.RESET, state => {
    return {
      ...state,
      data: {
        ...state.data,
        products: [],
        amount: null,
        totalAmount: null
      },
      error: '',
      query: ''
    };
  }),

  on(PRODUCTS_AUTOSUGGEST.RESPONSE, (state, { products, amount, totalAmount }) => {
    return {
      ...state,
      data: {
        ...state.data,
        products,
        amount,
        totalAmount
      }
    };
  }),

  on(PRODUCTS_AUTOSUGGEST.RESPONSE_ERROR, (state, { query, error }) => {
    return {
      ...state,
      data: {
        ...state.data,
        products: [],
        amount: null,
        totalAmount: null
      },
      error
    };
  })
);

export default autosuggestedProducts({
  data: {
    products: [],
    amount: null,
    totalAmount: null
  },
  query: '',
  error: ''
});

// SELECTORS
export const getAll = state => state.products.autosuggestedProducts;

export const getProductsData = createSelector(
  getAll,
  function _getProducts(all) {
    return all.data;
  }
);

export const getProducts = createSelector(
  getProductsData,
  function _getProducts(data) {
    return data.products;
  }
);

export const getQuery = createSelector(
  getAll,
  function _getQuery(all) {
    return all.query;
  }
);
