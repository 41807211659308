import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Page from 'modules/core/components/page/page';
import { getIndexLink } from 'routes/links';
import SearchResultsContainer from '../containers/search-results';
import * as fromSearch from '../ducks/search';
import plural from 'modules/utils/plural';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

const getSearchQueryFromRouteParams = (_, props) => {
  return decodeURI(props.match.params.query);
};

const mapStateToProps = createStructuredSelector({
  searchQuery: getSearchQueryFromRouteParams,
  resultAmount: fromSearch.makeGetAmountByQueryAndFilters(
    getSearchQueryFromRouteParams,
    fromSearch.getAppliedFilters
  ),
  isLoaded: fromSearch.getIsLoaded
});

class SearchPage extends Component {
  static path = '/search/:query';
  static exact = true;

  render() {
    const { searchQuery, isLoaded, resultAmount } = this.props;

    if (!searchQuery) {
      return null;
    }

    const title = this.getTitle(isLoaded, resultAmount, searchQuery);

    return (
      <div itemscope="" itemtype="http://schema.org/Product">
        <Page
          title={title}
          breadcrumbs={[
            {
              name: 'Главная',
              link: getIndexLink()
            },
            {
              name: `Результаты поиска`
            }
          ]}
        >
          <SearchResultsContainer query={searchQuery} />
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </Page>
      </div>
    );
  }

  getTitle(isLoaded, resultAmount, searchQuery) {
    if (!isLoaded) {
      return (
        <Fragment>
          Результаты поиска по запросу <br /> <span itemprop="Name">«{searchQuery}»</span>
        </Fragment>
      );
    }

    const isEmpty = resultAmount === 0;

    if (isEmpty) {
      return `Товаров по запросу «${searchQuery}» не найдено`;
    }

    return (
      <Fragment>
        Найдено {resultAmount} {plural(resultAmount, ['товар', 'товарa', 'товаров'])} по запросу{' '}
        <br /> «{searchQuery}»{' '}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(SearchPage);
