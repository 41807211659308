import React, { Component } from 'react';
import Notification from 'modules/core/components/notification/notification';
import Price from '../price-formatted/price-formatted';

class FreeDeliveryNotification extends Component {
  render() {
    const { currentPrice, discountPrice } = this.props;

    // const actualPrice = <Price number={currentPrice} />;
    const uptoPrice = <Price number={discountPrice - currentPrice} />;

    return (
      <Notification type="info">
        <span>
          Дополни корзину еще на <b>{uptoPrice}</b> и получи
        </span>{' '}
        <b>доставку бесплатно!</b>
      </Notification>
    );
  }
}

export default FreeDeliveryNotification;
