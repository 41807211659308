import React, { Component } from 'react';
import cn from 'classnames';

class Item extends Component {
  render() {
    const { value, children, active, ...rest } = this.props;

    return (
      <button
        type="button"
        className={cn('Trigger-item', {
          'Trigger-item--active': active
        })}
        aria-pressed={active}
        {...rest}
      >
        {children}
      </button>
    );
  }
}

export default Item;
