import React, { Component } from 'react';

import './icon-calendar.css';

class IconCalendar extends Component {
  render() {
    return (
      <svg className="IconCalendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M13.6 15H2.4c-.8 0-1.4-.7-1.4-1.5V3.9c0-.8.6-1.5 1.4-1.5h11.2c.8 0 1.4.7 1.4 1.5v9.6c0 .8-.6 1.5-1.4 1.5zM2.4 3.4c-.2 0-.5.2-.5.5v9.6c0 .3.2.5.5.5h11.2c.2 0 .5-.2.5-.5V3.9c0-.3-.2-.5-.5-.5H2.4z" />
        <path d="M4.3 4.5c-.3 0-.5-.2-.5-.5V1.5c0-.3.2-.5.5-.5s.5.2.5.5V4c0 .3-.2.5-.5.5zM11.7 4.5c-.3 0-.5-.2-.5-.5V1.5c0-.3.2-.5.5-.5s.5.2.5.5V4c0 .3-.2.5-.5.5zM14.1 6.4H1.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H14c.3 0 .5.2.5.5s-.2.5-.4.5z" />
      </svg>
    );
  }
}

export default IconCalendar;
