import React, { Component } from 'react';
import './icon-angle-arrow-left.css';

class IconAngleArrowLeft extends Component {
  render() {
    return (
      <svg
        className="IconAngleArrowLeft"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 284.935 284.936"
      >
        <path d="M110.488 142.468L222.694 30.264c1.902-1.903 2.854-4.093 2.854-6.567s-.951-4.664-2.854-6.563L208.417 2.857C206.513.955 204.324 0 201.856 0c-2.475 0-4.664.955-6.567 2.857L62.24 135.9c-1.903 1.903-2.852 4.093-2.852 6.567 0 2.475.949 4.664 2.852 6.567l133.042 133.043c1.906 1.906 4.097 2.857 6.571 2.857 2.471 0 4.66-.951 6.563-2.857l14.277-14.267c1.902-1.903 2.851-4.094 2.851-6.57 0-2.472-.948-4.661-2.851-6.564L110.488 142.468z" />
      </svg>
    );
  }
}

export default IconAngleArrowLeft;
