import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';

import Container from 'modules/core/components/container/container';
import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';
import getNormalizedRenderConditions from 'modules/core/components/conditional-renderer/helpers/get-normalized-render-conditions';

import './top-landing-banner.css';

function TopLandingBanner({ data, location: { pathname }, selectCityId, suggestedId }) {
  // check if current path according to target landing
  const { title, landingPath } = data;
  const matchTargetPath = matchPath(pathname, {
    path: landingPath,
    exact: true
  });
  const isTargetPath = Boolean(matchTargetPath);
  const HolderComponent = isTargetPath ? 'div' : Link;
  const linkProps = {
    ...(!isTargetPath && { to: landingPath }),
    ...(!isTargetPath && Boolean(title) && { title })
  };

  const conditionalConfig = {
    elseRender: null,
    selectCityId,
    suggestedId,
    ...getNormalizedRenderConditions(data.render_conditions)
  };

  return (
    <ConditionalRenderer config={conditionalConfig}>
      <HolderComponent {...linkProps}>
        <Media query="(max-width: 860px)">
          {isMobile => (
            <div className="TopLandingBanner">
              <Container fullHeight>
                <div className="TopLandingBanner-imageHolder">
                  <img
                    src={
                      isMobile
                        ? `${data.imagesPath}/image_mobile_@1x.png`
                        : `${data.imagesPath}/image_@1x.png`
                    }
                    srcSet={
                      isMobile
                        ? `${data.imagesPath}/image_mobile_@2x.png`
                        : `${data.imagesPath}/image_@2x.png`
                    }
                    alt=""
                    className="TopLandingBanner-image"
                  />
                </div>
              </Container>
            </div>
          )}
        </Media>
      </HolderComponent>
    </ConditionalRenderer>
  );
}

export default withRouter(TopLandingBanner);
