import React, { Component } from 'react';

import Price from 'modules/ordering/components/price-formatted/price-formatted';

class ProductPrice extends Component {
  render() {
    const { price } = this.props;
    return price.discount > 0 ? (
      <span>
        <Price number={price.default} variant="previous" /> <Price number={price.current} />
      </span>
    ) : (
      <Price number={price.current} />
    );
  }
}

export default ProductPrice;
