import React, { Component } from 'react';
import './icon-gift.css';

class IconGift extends Component {
  render() {
    return (
      <svg className="IconGift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19.7 6.6h-3.2c.2-.2.4-.4.5-.6.2-.4.4-1-.1-1.9-.5-.9-1.1-1-1.5-1-1.3 0-2.7 1.9-3.4 3-.7-1.1-2.1-3-3.4-3-.4 0-1 .2-1.5 1C6.6 5 6.8 5.6 7 6c.1.2.3.4.6.6H4.3C3.6 6.6 3 7.2 3 8v2.1c0 .6.4 1.1.9 1.3v7.4c0 .7.6 1.3 1.3 1.3h13.5c.7 0 1.3-.6 1.3-1.3v-7.4c.5-.2.9-.7.9-1.3V8c.1-.8-.5-1.4-1.2-1.4zm-4.3-2.5c.1 0 .4 0 .7.5.3.5.2.8.1.9-.4.6-2 1-3.3 1.1.7-1.2 1.8-2.5 2.5-2.5zm-7.5.5c.3-.5.6-.5.7-.5.7 0 1.8 1.3 2.6 2.5-1.4-.1-3-.4-3.3-1.1-.1-.1-.3-.3 0-.9zM3.9 8c0-.2.2-.4.4-.4H11.5v3H4.3c-.2 0-.4-.2-.4-.4V8zm1 10.8v-7.3h6.6v7.6H5.2c-.2 0-.3-.1-.3-.3zm13.9.3h-6.3v-7.6h6.6v7.3c0 .2-.1.3-.3.3zm1.3-9c0 .2-.2.4-.4.4h-7.2v-3H19.7c.2 0 .4.2.4.4v2.2z" />
      </svg>
    );
  }
}

export default IconGift;
