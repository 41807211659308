const KEY = '_persisted_recent_products';
const LocalStorage = __BROWSER__
  ? window.localStorage
  : {
      setItem: () => null,
      getItem: () => null
    };

function setProducts(city) {
  LocalStorage.setItem(KEY, JSON.stringify(city));
}

function getProducts() {
  const persisted = JSON.parse(LocalStorage.getItem(KEY));
  return persisted;
}

export default { setProducts, getProducts };
