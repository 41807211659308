import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class SwitchOption extends Component {
  static propTypes = {
    /** элемент который оборачивает каждую опцию */
    optionHolder: PropTypes.element,
    /** параметр работоспособности опции (если true - на опцию нельзя переключиться) */
    disabled: PropTypes.bool,
    /** верстка элемента опции */
    children: PropTypes.any
  };

  static defaultProps = {
    disabled: false
  };

  render() {
    return React.cloneElement(this.getOptionHolder(), {
      children: this.renderOptionMarkup()
    });
  }

  renderOptionMarkup() {
    const { children, disabled, buttonRef, onClick, checked } = this.props;

    return React.cloneElement(children, {
      checked,
      disabled,
      onClick,
      role: 'radio',
      ref: buttonRef,
      'aria-disabled': disabled,
      'aria-checked': checked,
      tabIndex: this.getTabIndex()
    });
  }

  getTabIndex() {
    const { checked, disabled } = this.props;
    if (disabled) return null;
    if (checked) return 0;
    return -1;
  }

  getOptionHolder() {
    const { optionHolder, disabled } = this.props;
    return optionHolder ? (
      optionHolder
    ) : (
      <div
        className={classNames('Switch-option', {
          'Switch-option--disabled': disabled
        })}
      />
    );
  }
}

export default SwitchOption;
