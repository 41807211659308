/* interface CityFias {
  cidy_fias_id: string;
}

interface CityLocal {
  cidy_id: number;
}

interface CityRaw {
  city_title: string;
  country_title: string;
}

type CityRequest = CityFias | CityLocal | CityRaw; */

export default function initDeliveryMethodsRequest(createRequest) {
  return {
    list: createRequest(function getItemRequest(city /* : CityRequest */) {
      return {
        method: 'GET',
        path: `/delivery-methods`,
        query: {
          ...city
        }
      };
    }),

    item: createRequest(function getItemRequest(id, city /* : CityRequest */) {
      return {
        method: 'GET',
        path: `/delivery-methods/${id}`,
        query: {
          ...city
        }
      };
    }),

    basket: createRequest(function getItemRequest(basket, city /* : CityRequest */) {
      return {
        method: 'POST',
        path: `/delivery-methods`,
        query: {
          ...city
        },
        data: { ...basket }
      };
    })
  };
}
