import React, { Component } from 'react';

import './icon-edit.css';

class IconEdit extends Component {
  render() {
    return (
      <svg className="IconEdit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M28.1 26.6H3.9c-.5 0-.9.4-.9.9s.4.9.9.9h24.3c.5 0 .9-.4.9-.9-.1-.5-.5-.9-1-.9zM3 18.8V23c0 .2.1.5.3.6.2.2.4.3.7.3h4.3c.2 0 .5-.1.6-.3L23.6 9.3c.4-.3.4-.9.1-1.2L19.4 4c-.4-.3-.9-.3-1.3 0l-3 2.9L3.2 18.2c-.1.2-.2.4-.2.6zm15.7-13l3 2.9-1.7 1.6-3-2.9 1.7-1.6zM4.8 19.2l11-10.6 3 2.9-11 10.6h-3v-2.9z" />
      </svg>
    );
  }
}

export default IconEdit;
