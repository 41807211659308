import React, { Component, Fragment } from 'react';

import { IconLocation } from '../icons';
import IconContainer from '../icon-container/icon-container';

import './city-choice-button.css';

class CityChoiceButton extends Component {
  render() {
    const { title, onClick } = this.props;

    return (
      <Fragment>
        <button type="button" className="CityChoiceButton" onClick={onClick}>
          <span className="CityChoiceButton-icon">
            <IconContainer inline>
              <IconLocation />
            </IconContainer>
          </span>

          <span className="CityChoiceButton-title">{title}</span>
        </button>
      </Fragment>
    );
  }
}

export default CityChoiceButton;
