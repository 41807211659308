import React, { Component } from 'react';
import './icon-circle-clock.css';

class IconCircleClock extends Component {
  render() {
    return (
      <svg className="IconCircleClock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
        <g fill="none" fillRule="evenodd" stroke="#262931">
          <circle cx="11.5" cy="11.5" r="11" />
          <path d="M11.5 11.5h-1v-7h1zM10.5 12.5v-1h6v1z" />
        </g>
      </svg>
    );
  }
}

export default IconCircleClock;
