function textSlicer(text, symbolAmount) {
  let textArray = Array.from(text);

  let firstWord = text.split(' ')[0];

  if (symbolAmount < firstWord.length) {
    return firstWord + '...';
  }

  if (textArray.length <= symbolAmount) {
    return text;
  }

  if (textArray[symbolAmount + 1] === ' ') {
    return text.substr(0, symbolAmount) + '...';
  }

  for (let i = 1; i <= textArray.length; i++) {
    if (text[symbolAmount - i] === ' ') {
      return text.substr(0, symbolAmount - i) + ' ...';
    }
  }
}

export default textSlicer;
