import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Media from 'react-media';

import PickPointDialog from '../components/pick-point-dialog/pick-point-dialog';

import * as fromPickPointQuery from '../ducks/pick-point-query';
// import * as fromPickPointList from '../ducks/pick-point-list';
// import * as fromLocation from 'modules/core/ducks/location/selectors';

const mapStateToProps = createStructuredSelector({
  filteredIdList: fromPickPointQuery.getFilteredIdList,
  pickPointFilterMask: fromPickPointQuery.getQuery
});

const mapDispatchToProps = {
  pickPointQueryChange: fromPickPointQuery.actions.change
};

const defaultMapProps = {
  zoom: 12,
  behaviors: ['scrollZoom']
};

class PickPointDialogContainer extends Component {
  state = {
    viewState: 'list',
    active: null
  };

  render() {
    const { viewState, active } = this.state;
    const { selected, filteredIdList, city, isLoaded } = this.props;

    const mapProps = {
      ...defaultMapProps,
      center: this.getMapCoordinates()
    };

    return (
      <Media query="(min-width: 1101px)">
        {isDesktop => {
          const finalMapProps = {
            ...mapProps,
            behaviors: [...mapProps.behaviors, isDesktop ? 'drag' : 'multiTouch']
          };

          return (
            <PickPointDialog
              isLoaded={isLoaded}
              place={isDesktop ? 'dialog' : 'bottom_sheet'}
              city={city}
              list={filteredIdList}
              viewState={viewState}
              mapProps={finalMapProps}
              active={active}
              selected={selected}
              onViewStateChange={this.handleViewStateChange}
              onSelect={this.handleSelect}
              onCardShow={this.handleCardShow}
              onCardHide={this.handleCardHide}
              onApiAvailable={this.handleYandexApi}
              onQuerySubmit={this.handleQuerySubmit}
              onPickPointQueryChange={this.handlePickPointQueryChange}
              {...this.props}
            />
          );
        }}
      </Media>
    );
  }

  getMapCoordinates() {
    const { selected } = this.props;
    const { active } = this.state;

    if (active) {
      return this.getPickPointCoordinates(active);
    }

    if (selected) {
      return this.getPickPointCoordinates(selected);
    }

    return this.getCityCoordinates();
  }

  getPickPointCoordinates(pickPoint) {
    const { lat, lng } = pickPoint.geography.coordinates;
    return [lat, lng];
  }

  getCityCoordinates() {
    if (!this.props.city || !this.props.city.coordinates) {
      if (!this.state.cityCoordinates) {
        return [0, 0];
      }
      return this.state.cityCoordinates;
    }

    const { lat, lng } = this.props.city.coordinates;

    return [lat, lng];
  }

  getCityGeocoding = result => {
    try {
      const city = result.geoObjects.get(0);
      this.setState({
        cityCoordinates: city.geometry.getCoordinates()
      });
    } catch (error) {
      console.log(error);
    }
  };

  findCityCoordinates() {
    this.ymaps.geocode(this.props.city.title).then(this.getCityGeocoding);
  }

  triggerViewState(value) {
    this.setState({
      viewState: value
    });
  }

  showPickPointInfo(active) {
    this.setState({
      active
    });
  }

  hidePickPointInfo() {
    this.setState({
      active: null
    });
  }

  handleAddressChange = e => {
    const { onAddressChange } = this.props;

    const { value } = e.target;
    onAddressChange(value);
  };

  handleViewStateChange = value => {
    this.triggerViewState(value);
  };

  handleSelect = pickPoint => {
    this.props.onClose();
    this.props.onChange(pickPoint);
    this.showPickPointInfo(pickPoint);
  };

  handleCardShow = id => {
    this.showPickPointInfo(id);
  };

  handleCardHide = () => {
    this.hidePickPointInfo();
  };

  createCityProvider = (ymaps, city) => {
    return {
      suggest: request => ymaps.suggest(city.title + ', ' + request)
    };
  };

  handleYandexApi = ymaps => {
    this.ymaps = ymaps;

    this.findCityCoordinates();
  };

  handleQuerySubmit = e => {};

  handlePickPointQueryChange = e => {
    const query = e.target.value;
    const { pickPointQueryChange } = this.props;
    pickPointQueryChange(query);
  };
}

// export default PickPointDialogContainer;

export default connect(mapStateToProps, mapDispatchToProps)(PickPointDialogContainer);
