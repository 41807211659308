import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'modules/core/components/container/container';

import './content-section.css';

const { element, arrayOf, oneOfType, any } = PropTypes;

class ContentSection extends Component {
  static propTypes = {
    /** Содержит элемент или список элементов, размещенных в левой колонке */
    leftSide: oneOfType([element, arrayOf(element)]),

    /** Содержит элемент или список элементов, размещенных в правой колонке */
    rightSide: oneOfType([element, arrayOf(element)]),

    children: any
  };

  static defaultProps = {
    leftSide: {},
    rightSide: {}
  };

  render() {
    const { leftSide, rightSide, children } = this.props;
    return (
      <section className="Article_ContentSection">
        <Container>
          <div className="Article_ContentSection-grid">
            <div className="Article_ContentSection-leftColumn">
              {this.checkSidebarExistence(leftSide) && (
                <div className="Article_ContentSection-leftSidebarGroup">
                  {this.renderSidebarGroupContent(leftSide)}
                </div>
              )}
            </div>

            <div className="Article_ContentSection-mainColumn">{children}</div>

            <div className="Article_ContentSection-rightColumn">
              {this.checkSidebarExistence(rightSide) && this.renderSidebarGroupContent(rightSide)}
            </div>
          </div>
        </Container>
      </section>
    );
  }

  renderSidebarGroupContent(data) {
    if (Array.isArray(data)) {
      return data.map(this.renderSidebar);
    } else {
      return <div className="Article_ContentSection-sidebarGroupItem">{data}</div>;
    }
  }

  renderSidebar = (sidebar, index) => {
    return (
      <div key={index} className="Article_ContentSection-sidebarGroupItem">
        {sidebar}
      </div>
    );
  };

  checkSidebarExistence(data) {
    /**
     * Если входящих данных нет, то по умолчанию data - обьект.
     * Здесь просиходит проверка, является ли data обьектом
     */
    return !(typeof data === 'object' && Object.keys(data).length === 0 && !Array.isArray(data));
  }
}

export default ContentSection;
