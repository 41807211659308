import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';

import CallbackConformationDialog from '../components/callback-conformation-dialog/callback-conformation-dialog';

const { bool } = PropTypes;

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsConformationOpened,
  name: fromDialogs.getName
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close
};

class CallbackConformationDialogContainer extends Component {
  static propTypes = {
    isOpened: bool,
    close: bool
  };

  render() {
    const { isOpened, name } = this.props;

    return <CallbackConformationDialog show={isOpened} onClose={this.handleClose} name={name} />;
  }

  handleClose = () => {
    const { close } = this.props;

    close();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackConformationDialogContainer);
