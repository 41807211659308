import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import './accordion.css';

const { bool, arrayOf, node } = PropTypes;

const KEY_HOME = 36;
const KEY_END = 35;

class Accordion extends Component {
  static propTypes = {
    children: arrayOf(node),
    clickableHeadings: bool,
    fade: bool,
    scrollToTop: bool
  };

  state = {
    openedItem: null,
    // previousOpenedItem: null,
    focusedItem: null
  };

  // openedAccordionItem = null;
  // items = [];

  render() {
    const { clickableHeadings, animatedCollapsing, fade, children } = this.props;
    const { focusedItem } = this.state;

    return (
      <div className="Accordion" onKeyDown={this.handleKeyDown}>
        {Children.map(children, (element, index) => {
          if (!element) {
            return null;
          }

          const { anchor } = element.props;

          return (
            <div key={index} className="Accordion-itemHolder">
              {React.cloneElement(element, {
                fade,
                index,
                animatedCollapsing,
                focused: focusedItem === index,
                clickableHeading: clickableHeadings,
                visible: this.isVisible(index),
                onFocusNext: this.handleFocusNext(index),
                onFocusPrevious: this.handleFocusPrevious(index),
                onVisibilityChange: this.handleVisibilityChange(index, anchor)
                // openedAccordionItemRef: this.setOpenedAccordionItemRef(index),
                // itemRef: this.setItemRef(index)
              })}
            </div>
          );
        })}
      </div>
    );
  }

  /** State changers */
  setOpenedItem(item, anchor) {
    this.setState(() => ({
      openedItem: item
      // previousOpenedItem: state.openedItem
    }));
    if (anchor) {
      this.setHash(anchor);
    }

    // const itemElement = this.items[item];
    // // const itemElementTopPosition = this.getElementTopPosition(itemElement);

    // if (
    //   this.openedAccordionItem &&
    //   this.isBefore(this.openedAccordionItem, itemElement)
    // ) {
    //   setTimeout(this.closePreviousOpenedItem, 500);
    // } else {
    //   this.closePreviousOpenedItem();
    // }

    // this.scrollMenuItemToTop(itemElementTopPosition);
  }

  closeAllItems() {
    this.setState({
      openedItem: null
    });
    // this.openedAccordionItem = null;

    this.removeHash();
  }

  // closePreviousOpenedItem = () => {
  //   this.setState({
  //     previousOpenedItem: null
  //   });
  // };

  resetFocused() {
    this.setState({
      focusedItem: null
    });
  }

  setFocus(item) {
    this.setState({
      focusedItem: item
    });
  }

  setFirstFocus() {
    this.setState({
      focusedItem: 0
    });
  }

  setLastFocus() {
    const { children } = this.props;
    const lastIndex = children.length - 1;
    this.setState({
      focusedItem: lastIndex
    });
  }

  /** Helpers  */
  // scrollMenuItemToTop(itemElementTopPosition) {
  //   const verticalWindowScroll = this.getVerticalWindowScroll();
  //   const topPosition = verticalWindowScroll + itemElementTopPosition;

  //   setTimeout(() => {
  //     window.scrollTo({
  //       top: topPosition,
  //       behavior: 'smooth'
  //     });
  //   });
  // }

  setHash(anchor) {
    // const { anchor } = this.props;
    // const anchor = `${anchorPrefix}_${index}`;
    setTimeout(() => {
      window.location.hash = anchor;
    }, 50);
  }

  removeHash() {
    window.history.replaceState(
      '',
      document.title,
      window.location.pathname + window.location.search
    ); // FIXME: use react-router for this
  }

  isVisible = index => {
    const { openedItem, previousOpenedItem } = this.state;
    const { initiallyOpenedItem } = this.props;
    const initiallyOpenedIndex = initiallyOpenedItem - 1;

    return initiallyOpenedItem
      ? initiallyOpenedIndex === index
      : openedItem === index || previousOpenedItem === index;
  };

  setNextFocus(index) {
    const { children } = this.props;
    const lastIndex = children.length - 1;
    if (lastIndex === index) {
      this.setFocus(0);
    } else {
      this.setFocus(index + 1);
    }
  }

  setPreviousFocus(index) {
    const { children } = this.props;
    const lastIndex = children.length - 1;
    if (index === 0) {
      this.setFocus(lastIndex);
    } else {
      this.setFocus(index - 1);
    }
  }

  // getVerticalWindowScroll() {
  //   return window.pageYOffset || document.documentElement.scrollTop;
  // }

  // getElementHeight(element) {
  //   return (
  //     element.getBoundingClientRect().bottom -
  //     element.getBoundingClientRect().top
  //   );
  // }

  // getElementTopPosition(element) {
  //   return element.getBoundingClientRect().top;
  // }

  // isBefore(firstElement, secondElement) {
  //   return firstElement.offsetTop < secondElement.offsetTop;
  // }

  /** References */
  // setItemRef = index => element => {
  //   if (!element) return;
  //   this.items[index] = element;
  // };

  // setOpenedAccordionItemRef = index => element => {
  //   const { openedItem } = this.state;

  //   if (!element || openedItem !== index) return;

  //   this.activeSubMenu = element;
  // };

  /**  HANDLERS */
  handleVisibilityChange = (index, anchor) => action => {
    const { onAccordionVisibleItemChange } = this.props;

    this.resetFocused();

    if (action) {
      this.setOpenedItem(index, anchor);
    } else {
      this.closeAllItems();
    }

    if (!isFunction(onAccordionVisibleItemChange)) {
      return;
    }

    onAccordionVisibleItemChange();
  };

  handleFocusNext = index => () => {
    this.setNextFocus(index);
  };

  handleFocusPrevious = index => () => {
    this.setPreviousFocus(index);
  };

  handleKeyDown = e => {
    const keyCode = e.keyCode;
    if (keyCode === KEY_HOME) {
      e.preventDefault();
      this.setFirstFocus();
    } else if (keyCode === KEY_END) {
      e.preventDefault();
      this.setLastFocus();
    }
  };
}

export default Accordion;
