const realNormalizeMassMedia = massMedia => {
  const { page, sort, total_pages, data } = massMedia;

  const article = data.map(item => realNormalizeArticle(item));

  return {
    page,
    sort,
    totalPages: total_pages,
    article
  };
};

const realNormalizeArticle = article => {
  const { name, create_date, link, image } = article;

  return { title: name, createDate: create_date, link, media: image };
};

export default realNormalizeMassMedia;
