import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import LinkNavigation from 'modules/core/components/link-navigation/link-navigation';
import { Link } from 'react-router-dom';
import OrderDetails from 'modules/ordering/components/successful-order/order-details/order-details';
import TableThanks from 'modules/ordering/components/successful-order/table-thanks/table-thanks';
import Price from 'modules/ordering/components/price-formatted/price-formatted';
import StatusIndicator from 'modules/core/components/status-indicator/status-indicator';

import { getParsedDate } from './helpers';
import ORDER_STATUS from 'modules/ordering/constants/order-status';
import { getProfileOrderListLink } from 'routes/links';

import './order-in-detail-scene.css';

function OrderStatus({ order }) {
  switch (order.status) {
    case ORDER_STATUS.ABANDONED:
      return <StatusIndicator status="empty" title="Не заполнен" />;
    case ORDER_STATUS.ACCEPTED:
      return <StatusIndicator status="waiting" title="Подтвержден" />;
    case ORDER_STATUS.AWAITING_CALL:
      return <StatusIndicator status="waiting" title="В обработке" />;
    case ORDER_STATUS.NEW:
      return <StatusIndicator status="waiting" title="Новый" />;
    case ORDER_STATUS.IN_DELIVERY:
      return <StatusIndicator status="waiting" title="Передан в доставку" />;
    case ORDER_STATUS.DELIVERED:
      return <StatusIndicator status="success" title="Доставлен" />;
    case ORDER_STATUS.CANCELED:
      return <StatusIndicator status="canceled" title="Отменен" />;
    case ORDER_STATUS.EXPECTING_PAYMENT:
      return <StatusIndicator status="canceled" title="Ожидает оплату" />;
    default:
      return null;
  }
}

class OrderInDetailScene extends Component {
  static propTypes = {
    prop: PropTypes
  };

  _titleElement = null;

  render() {
    const {
      isLoading,
      order,
      delivery,
      personalInfo,
      payment,
      goodsList,
      productsCost,
      deliveryCost,
      discount,
      totalCost
    } = this.props;

    if (isLoading || !order) {
      return null;
    }

    return (
      <div className="OrderInDetailScene">
        <div className="OrderInDetailScene-header">
          <div className="OrderInDetailScene-back">
            <LinkNavigation component={Link} to={getProfileOrderListLink()} back>
              Назад к истории покупок
            </LinkNavigation>
          </div>
          <div className="OrderInDetailScene-headerBottom">
            <h3 className="OrderInDetailScene-title" ref={this.setTitleElementRef}>
              <span className="OrderInDetailScene-titleNumber">Заказ №{order.id}</span>{' '}
              <span className="OrderInDetailScene-titleDate">
                от {getParsedDate(order.placingDate)}
              </span>
            </h3>
            <div className="OrderInDetailScene-status">
              <OrderStatus order={order} />
            </div>
          </div>
        </div>
        <Media query="(max-width: 640px)">
          {isMobile =>
            isMobile ? (
              <div className="OrderInDetailScene-details">
                <div className="OrderInDetailScene-detailItem">
                  {order.personalInfo.firstName && (
                    <>
                      <h4 className="OrderInDetailScene-detailTitle">Получатель</h4>
                      <div className="OrderInDetailScene-detailInfo">
                        {order.personalInfo.firstName}
                      </div>
                    </>
                  )}
                  {order.personalInfo.phone && (
                    <>
                      <div className="OrderInDetailScene-detailInfo">
                        {order.personalInfo.phone}
                      </div>
                    </>
                  )}
                  {order.personalInfo.email && (
                    <>
                      <div className="OrderInDetailScene-detailInfo">
                        {order.personalInfo.email}
                      </div>
                    </>
                  )}
                </div>

                {order.delivery.geography.address.raw && (
                  <div className="OrderInDetailScene-detailItem">
                    <h4 className="OrderInDetailScene-detailTitle">Адрес доставки</h4>
                    <div className="OrderInDetailScene-detailInfo">
                      {order.delivery.geography.address.raw}
                    </div>
                  </div>
                )}

                {order.delivery.deliveryMethod.title && (
                  <div className="OrderInDetailScene-detailItem">
                    <h4 className="OrderInDetailScene-detailTitle">Способ доставки</h4>
                    <div className="OrderInDetailScene-detailInfo">
                      {order.delivery.deliveryMethod.title}
                    </div>
                  </div>
                )}

                {order.payment.paymentMethod.title && (
                  <div className="OrderInDetailScene-detailItem">
                    <h4 className="OrderInDetailScene-detailTitle">Оплата</h4>
                    <div className="OrderInDetailScene-detailInfo">
                      {order.payment.paymentMethod.title}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="OrderInDetailScene-details">
                <OrderDetails
                  cols={1}
                  order={order}
                  delivery={delivery}
                  personalInfo={personalInfo}
                  payment={payment}
                />
              </div>
            )
          }
        </Media>

        <div className="OrderInDetailScene-payment">
          {order.isPayed ? 'Оплачено:' : 'К оплате'}
          <span className="OrderInDetailScene-price">
            <Price number={totalCost} />
          </span>
        </div>
        <div className="OrderInDetailScene-table">
          <TableThanks
            goodsList={goodsList}
            deliveryCost={deliveryCost}
            productsCost={productsCost}
            discount={discount}
            totalCost={totalCost}
            listId="order-history"
          />
        </div>
        {/* <div className="OrderInDetailScene-repeatOrder">
          <Media query="(max-width: 480px)">
            {isLowMobile => (
              <Button
                title="Повторить заказ"
                variant="primary"
                size={isLowMobile ? 'small' : 'normal'}
                expanded={isLowMobile}
              />
            )}
          </Media>
        </div> */}
      </div>
    );
  }
  setTitleElementRef = element => {
    if (!element) {
      return;
    }

    this._titleElement = element;

    this.scrollToTitle();
  };

  scrollToTitle() {
    const scrollY = this._titleElement.getBoundingClientRect().top;
    const scrollOffset = 70;

    window.scrollTo(0, scrollY - scrollOffset);
  }
}

export default OrderInDetailScene;
