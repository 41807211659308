import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IngredientDescription from '../components/ingredient/indredient-description';
import * as Ingredient from '../ducks/ingredients/actions';
import * as fromIngredient from '../ducks/ingredients/selectors';

const mapStateToProps = createStructuredSelector({
  details: fromIngredient.getDetailsById,
  ingredient: fromIngredient.getIngredientById,
  isLoading: fromIngredient.getLoadingDetailsById
});

const mapDispatchToProps = {
  show: Ingredient.showDetails
};

class IngredientDescriptionContainer extends Component {
  render() {
    const { ingredient } = this.props;
    return <IngredientDescription description={ingredient.short_description} />;
  }

  componentDidMount() {
    this.props.show(this.props.id);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngredientDescriptionContainer);
