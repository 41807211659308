import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromBasket from '../ducks/basket';

import BonusesConformationDialog from '../components/personal-info-form/bonuses-confirmation-dialog/bonuses-confirmation-dialog';
import { actions as BonusesConfirmation } from '../ducks/bonuses-conformation';

const { bool } = PropTypes;

const mapStateToProps = createStructuredSelector({
  bonuses: fromBasket.getBonuses,
  isOpened: fromDialogs.getIsBonusesConformationCodeOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeBonusesConfirmationCodeDialog,
  onRequestCode: BonusesConfirmation.requestCode,
  onConfirmBonuses: BonusesConfirmation.requestConfirm
};

class BonusesConformationDialogContainer extends Component {
  static propTypes = {
    isOpened: bool,
    close: bool
  };

  render() {
    const { bonuses, isOpened } = this.props;

    return (
      <BonusesConformationDialog
        show={isOpened}
        onClose={this.handleClose}
        // onConfirm={this.handleBonusesConfirm}
        bonuses={bonuses}
        onChange={this.handleBonusesConfirm}
        onRequestCode={this.handleRequestCode}
      />
    );
  }

  handleClose = () => {
    const { close } = this.props;

    close();
  };

  handleBonusesConfirm = code => {
    const { onConfirmBonuses, close } = this.props;

    onConfirmBonuses({ code });
    close();
  };

  handleRequestCode = () => {
    const { bonuses, onRequestCode } = this.props;

    onRequestCode({ value: bonuses.applied });
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonusesConformationDialogContainer);
