import * as PRODUCTS_AUTOSUGGEST from './types';
import { createAction } from 'modules/utils/dux';

export default {
  request(query) {
    return createAction(PRODUCTS_AUTOSUGGEST.REQUEST, { query });
  },

  reset() {
    return createAction(PRODUCTS_AUTOSUGGEST.RESET);
  },

  response(products, amount, totalAmount) {
    return createAction(PRODUCTS_AUTOSUGGEST.RESPONSE, {
      products,
      amount,
      totalAmount
    });
  },

  responseError(query, error) {
    return createAction(PRODUCTS_AUTOSUGGEST.RESPONSE_ERROR, {
      query,
      error
    });
  }
};
