import React, { Component } from 'react';
import './product-dialog-content.css';

class ProductDialogContent extends Component {
  render() {
    return <div className="ProductDialogContent">{this.props.children}</div>;
  }
}

export default ProductDialogContent;
