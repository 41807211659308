import React, { Component } from 'react';
import GeneralTile from '../components/general-tile/general-tile';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromProfile from '../ducks/profile';
import ProfileActions from '../actions/profile';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromDialogs from 'modules/core/ducks/dialogs';


const mapStateToProps = createStructuredSelector({
  fullName: fromProfile.getFullName,
  firstName: fromProfile.getFirstName,
  lastName: fromProfile.getLastName,
  photo: fromProfile.getPhoto,
  email: fromAccount.getEmail,
  isLoading: fromProfile.getIsLoading
});

const mapDispatchToProps = {
  changeFullName: ProfileActions.changeFullName,
  changePhoto: ProfileActions.changePhoto,
  openUserNameDialog: fromDialogs.actions.openChangeProfileName
};

class GeneralTitle extends Component {
  render() {
    const {isLoading, fullName, firstName, lastName, photo, email, openUserNameDialog} = this.props;

    return (
      <GeneralTile
        isLoading={isLoading}
        name={fullName}
        firstName={firstName}
        lastName={lastName}
        photo={photo}
        email={email}
        onChangeName={this.handleChangeName}
        onChangePhoto={this.handleChangePhoto}
        openUserNameDialog={openUserNameDialog}
      />
    );
  }

  handleChangeName = value => {
    this.props.changeFullName(value);
  };

  handleChangePhoto = photo => {
    this.props.changePhoto(photo);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralTitle);
