import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import cn from 'classnames';

import SortField from 'modules/product/components/sorting/select-field/select-field';
import CategoryHeader from 'modules/product/components/category-header/category-header';

import CategoryField from './category-field';
import CategoryFieldDialog from './category-field-dialog';

import './group-list-header.css';

class GroupListHeader extends Component {
  render() {
    const {hasCounter} = this.props;
    return (
      <div
        className={cn('GroupListHeader', {
          'GroupListHeader--widthTopBorder': this.props.widthTopBorder
        })}
      >
        {!hasCounter && (
          <Media query="(max-width: 840px)">
            {isMobile => (
              <Fragment>
                {!isMobile && (
                  <Fragment>
                    <div className="GroupListHeader-desktop">
                      {this.props.categories.length > 1 && (
                        <CategoryField
                          categories={this.props.categories}
                          selected={this.props.selectedCategories}
                          onChange={this.props.onCategoryChange}
                        />
                      )}

                      <div className="GroupListHeader-sort">
                        <SortField
                          sortTypeList={this.props.sortOptions}
                          onChange={this.props.onSortingChange}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {isMobile && (
                  <div className="GroupListHeader-mobile">
                    <CategoryHeader
                      sortTypeList={this.props.sortOptions}
                      onSortingChange={this.props.onSortingChange}
                      categories={this.props.categories}
                      renderItem={
                        <CategoryFieldDialog
                          categories={this.props.categories}
                          selected={this.props.selectedCategories}
                          onChange={this.props.onCategoryChange}
                        />
                      }
                    />
                  </div>
                )}
              </Fragment>
            )}
          </Media>
        )}
      </div>
    );
  }
}

export default GroupListHeader;
