import React, { Component, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Container from '../container/container';
import Carousel from '../carousel/carousel';
import NavigationButton from './navigation-button';
import NavigationButtonMobile from 'modules/product/components/mobile-product-card-slider/navigation-button';
import Item from './item';
import { IconArrowRight, IconArrowLeft } from '../icons';
import IconContainer from '../icon-container/icon-container';
import PaginationContainer from './pagination-container';
import Pagination from '../carousel/pagination';

import './banner-featured-slider.css';

const CarouselPage = ({ current, ...rest }) => {
  return (
    <Media query="(max-width:840px)">
      {isMobile =>
        (isMobile && <NavigationButtonMobile {...rest} current={current} />) || (
          <NavigationButton isActive={current} {...rest} />
        )
      }
    </Media>
  );
};
// const CarouselPage = ({ current, ...rest }) => <NavigationButton isActive={current} {...rest} />;
// const CarouselPage = ({ ...rest }) => <NavigationButton {...rest} />;

class BannerFeaturedSlider extends Component {
  static Item = Item;

  static propTypes = {
    children: PropTypes.any
  };

  state = {
    currentPage: 0
  };

  render() {
    const { children } = this.props;
    const { currentPage } = this.state;

    const bannerAmount = Children.count(children);

    const hasPages = bannerAmount > 1;

    const isFirstSlide = currentPage === 0;
    const isLastSlide = currentPage === bannerAmount - 1;

    return (
      <div className="BannerFeaturedSlider">
        <Media query="(max-width: 1100px)">
          {isTablet => (
            <Fragment>
              {isTablet && this.renderCarousel()}
              <Container>
                {!isTablet && this.renderCarousel()}
                {hasPages && (
                  <Media query="(min-width: 840px)">
                    <div className="BannerFeaturedSlider-controls">
                      <button
                        className="BannerFeaturedSlider-control"
                        onClick={this.handlePrevButtonClick}
                        type="button"
                        disabled={isFirstSlide}
                      >
                        <IconContainer size="xsmall">
                          <IconArrowLeft />
                        </IconContainer>
                      </button>

                      {this.renderPagination()}

                      <div className="BannerFeaturedSlider-rightHolder">
                        <div className="BannerFeaturedSlider-counter">{`${currentPage +
                          1}/${bannerAmount}`}</div>
                        <button
                          className="BannerFeaturedSlider-control"
                          onClick={this.handleNextButtonClick}
                          type="button"
                          disabled={isLastSlide}
                        >
                          <IconContainer size="xsmall">
                            <IconArrowRight />
                          </IconContainer>
                        </button>
                      </div>
                    </div>
                  </Media>
                )}
              </Container>
            </Fragment>
          )}
        </Media>
      </div>
    );
  }

  renderCarousel() {
    const { children } = this.props;
    const { currentPage } = this.state;
    return (
      <div className="BannerFeaturedSlider-content">
        <Media query="(max-width:840px)">
          <Carousel
            duration={400}
            currentPage={currentPage}
            pagination={true}
            onChange={this.handlePageChange}
            container={<div className="BannerFeaturedSlider-list" />}
            paginationContainer={<PaginationContainer currentPage={currentPage} buttonAmount={7} />}
            page={<CarouselPage buttonAmount={7} />}
          >
            {children}
          </Carousel>
        </Media>
        <Media query="(min-width:841px)">
          <Carousel
            duration={400}
            currentPage={currentPage}
            onChange={this.handlePageChange}
            container={<div className="BannerFeaturedSlider-list" />}
            page={<CarouselPage />}
          >
            {children}
          </Carousel>
        </Media>
      </div>
    );
  }

  renderPagination() {
    const { children } = this.props;
    const { currentPage } = this.state;

    const bannerAmount = Children.count(children);
    return (
      <div className="BannerFeaturedSlider-paginationHolder">
        <Pagination
          paginationContainer={<div className="BannerFeaturedSlider-pagination" />}
          page={<CarouselPage />}
          current={currentPage}
          count={bannerAmount}
          pageChange={this.handlePaginationClick}
        />
      </div>
    );
  }

  handleNextButtonClick = e => {
    e.preventDefault();
    this.goToNextPage();
  };

  handlePrevButtonClick = e => {
    e.preventDefault();
    this.goToPrevPage();
  };

  handlePageChange = data => {
    this.changePage(data.page);
  };

  handlePaginationClick = (_, page) => {
    this.changePage(page);
  };

  changePage(page) {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    const lastPage = childrenCount - 1;
    const firstPage = 0;

    if (page >= lastPage) {
      this.setState({
        currentPage: lastPage
      });
      return;
    }

    if (page <= firstPage) {
      this.setState({
        currentPage: firstPage
      });
      return;
    }

    this.setState({
      currentPage: page
    });
  }

  goToNextPage() {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    if (this.state.currentPage >= childrenCount - 1) {
      return;
    }
    this.setState(state => ({
      currentPage: state.currentPage + 1
    }));
  }

  goToPrevPage() {
    if (this.state.currentPage <= 0) {
      return;
    }

    this.setState(state => ({
      currentPage: state.currentPage - 1
    }));
  }
}

export default BannerFeaturedSlider;
