import isJsonString from '../helpers/isJsonString';

export default {
  serialize(mainBannerVersion) {
    return JSON.stringify(mainBannerVersion);
  },

  deserialize(persistedJson) {
    if (isJsonString(persistedJson)) {
      const mainBannerVersion = JSON.parse(persistedJson);

      return mainBannerVersion;
    }
    return persistedJson;
  }
};
