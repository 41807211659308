import React, { Component } from 'react';
import Media from 'react-media';

import Field from 'modules/form/components/field/field';
import Textarea from 'modules/form/components/textarea/textarea';
import Link from 'modules/core/components/link/short-link';

import isFunction from 'modules/utils/is-function';

import './comment.css';

class Comment extends Component {
  state = {
    showField: this.props.value.length > 0
  };

  render() {
    return (
      <div className="OrderingForm_Comment">
        <Media query="(max-width:640px)">{isMobile => this.renderWithMedia(isMobile)}</Media>
      </div>
    );
  }

  renderWithMedia(isMobile) {
    return [this.renderButton(isMobile), this.renderField(isMobile)];
  }

  renderButton(isMobile) {
    const { showField } = this.state;

    if (isMobile) {
      return null;
    }

    return (
      <div className="OrderingForm_Comment-button">
        {showField ? (
          <Link component="button" type="button" onClick={this.handleHideButtonClick}>
            Убрать комментарий
          </Link>
        ) : (
          <Link component="button" type="button" onClick={this.handleShowButtonClick}>
            Добавить комментарий к заказу
          </Link>
        )}
      </div>
    );
  }

  renderField(isMobile) {
    const { showField } = this.state;

    if (!showField && !isMobile) {
      return null;
    }

    const { ...rest } = this.props;
    delete rest.onChange;

    return (
      <div className="OrderingForm_Comment-field">
        <Field>
          <Textarea
            style={!isMobile && { minHeight: 120 }}
            placeholder="Ваш комментарий"
            opened={!isMobile}
            autosize
            {...rest}
            onChange={this.handleChange}
          />
        </Field>
      </div>
    );
  }

  show() {
    this.setState({
      showField: true
    });
  }

  hide() {
    this.setState({
      showField: false
    });
  }

  change(value) {
    const { onChange } = this.props;
    if (isFunction(onChange)) {
      onChange(value);
    }
  }

  clear() {
    this.change('');
  }

  handleChange = e => {
    const { value } = e.target;
    this.change(value);
  };

  handleShowButtonClick = () => {
    this.show();
  };

  handleHideButtonClick = () => {
    this.hide();
    this.clear();
  };
}

export default Comment;
