import { selectIdentifier as selectProductIdentifier } from 'modules/product/ducks/products/helpers';

import nanoid from 'nanoid';
import { deserializeProduct } from 'modules/product/deserializers/product';

export function normalizeBasket(rawBasket) {
  const goods = rawBasket.goods_list.map(normalizeGoods);
  const promos = rawBasket.promos.map(promo => ({
    ...promo,
    isApplied: promo.is_applied,
    options: promo.options
      ? promo.options.map(promoOption => ({
          ...promoOption,
          goods: {
            ...promoOption.goods,
            product: selectProductIdentifier(promoOption.goods.product)
          }
        }))
      : []
  }));
  const bonuses = rawBasket.bonuses;
  const bonusConformationRequired = rawBasket.confirmation_required;
  const maxDeliveryPrice = rawBasket.delivery_price.max;

  const basket = {
    goods,
    promos,
    bonuses,
    bonusConformationRequired,
    maxDeliveryPrice
  };

  const promoProducts = rawBasket.promos
    .map(promo =>
      promo.options && promo.options.length > 0
        ? promo.options.map(promoOption => promoOption.goods.product)
        : []
    )
    .reduce((products, list) => products.concat(list), []);

  const products = rawBasket.goods_list
    .map(item => item.product)
    .concat(promoProducts)
    .map(deserializeProduct);

  return { basket, products };
}

export function normalizeGoods(rawGoods) {
  const product = selectProductIdentifier(rawGoods.product);
  const price = rawGoods.price;
  const amount = parseInt(rawGoods.quantity, 10) || 1;
  const variant = parseInt(rawGoods.variant_id, 10) || null;
  const promoId = parseInt(rawGoods.promo_id, 10) || null;
  const isAmountLocked = rawGoods.lock_quantity || rawGoods.id === 0;
  let uid = selectProductIdentifier(rawGoods.product);

  if (rawGoods.id !== 0) {
    uid = [uid, rawGoods.id].join(':');
  } else {
    if (rawGoods.gift) {
      uid = [uid, 'gift', nanoid()].join(':');
    } else {
      uid = [uid, nanoid()].join(':');
    }
  }

  delete rawGoods.quantity;
  delete rawGoods.variant_id;

  const goods = {
    ...rawGoods,
    uid,
    price,
    product,
    variant,
    amount,
    isAmountLocked,
    promoId
  };

  return goods;
}
