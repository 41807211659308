const current = props => {
  const { options } = props;
  for (let i = 0; i < options.length; i++) {
    if (options[i].disabled) {
      continue;
    }
    return i;
  }
  return null;
};

export default current;
