export function queryStringToJSON(queryString) {
  if (queryString.indexOf('?') > -1) {
    queryString = queryString.split('?')[1];
  }
  const pairs = queryString.split('&');
  let result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

export function getHasUTM(search) {
  const query = search.substr(1);
  const parameters = queryStringToJSON(query);

  return Boolean(parameters.utm_campaign);
}

export function getQueryExist(search, key, value) {
  const query = search.substr(1);
  const parameters = queryStringToJSON(query);

  return Boolean(parameters[key]) &&  (value ? Boolean(parameters[key] === value) : true);
}
