import React, { Component, Fragment } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { isElementScrollEnabled, getScrollBottom } from './helpers';

class ScrollEdges extends Component {
  state = {
    shadowAble: false,
    scrollTop: false,
    scrollBottom: false
  };

  _contentElement = null;

  render() {
    const { children } = this.props;
    const { shadowAble, scrollTop, scrollBottom } = this.state;

    const hasScrollTop = shadowAble && scrollTop;
    const hasScrollBottom = shadowAble && scrollBottom;

    return (
      <Fragment>
        {children(this.setContentElementRef, this.handleScroll, hasScrollTop, hasScrollBottom)}
        <ReactResizeDetector handleHeight onResize={this.onResize} />
      </Fragment>
    );
  }

  setScrollTop(scrollTop) {
    if (this.state.scrollTop === scrollTop) {
      return;
    }
    this.setState({
      scrollTop
    });
  }

  setScrollBottom(scrollBottom) {
    if (this.state.scrollBottom === scrollBottom) {
      return;
    }
    this.setState({
      scrollBottom
    });
  }

  setScrollPosition(element) {
    const scrollTop = element.scrollTop;
    const scrollBottom = getScrollBottom(element);

    if (scrollTop === 0) {
      this.setScrollTop(false);
    } else {
      this.setScrollTop(true);
    }

    if (scrollBottom === 0) {
      this.setScrollBottom(false);
    } else {
      this.setScrollBottom(true);
    }
  }

  setShadowAble() {
    this.setScrollPosition(this._contentElement);
    this.setState({
      shadowAble: true
    });
  }

  resetShadowAble() {
    this.setState({
      shadowAble: false
    });
  }

  calculateShadowAble(element) {
    if (isElementScrollEnabled(element)) {
      this.setShadowAble();
    } else {
      this.resetShadowAble();
    }
  }

  setContentElementRef = element => {
    if (!element) {
      return;
    }

    this._contentElement = element;

    this.calculateShadowAble(this._contentElement);
    this.setScrollPosition(this._contentElement);
  };

  onResize = () => {
    this.calculateShadowAble(this._contentElement);
    this.setScrollPosition(this._contentElement);
  };

  handleScroll = () => {
    this.setScrollPosition(this._contentElement);
  };
}

export default ScrollEdges;
