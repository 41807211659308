import React, { Component } from 'react';

import Loader from 'modules/core/components/loader/loader';

import SubscribeChannel from '../subscribe-channel/subscribe-channel';

import './subscription-scene.css';

class SubscriptionScene extends Component {
  render() {
    const { channelList, isLoaded } = this.props;

    return (
      <div className="SubscriptionScene">
        <h2 className="SubscriptionScene-title">Уведомления и рассылки</h2>

        {isLoaded ? (
          <ul className="SubscriptionScene-channelList">{channelList.map(this.renderChannel)}</ul>
        ) : (
          <div className="SubscriptionScene-loader">
            <Loader type="spin" />
          </div>
        )}
      </div>
    );
  }

  renderChannel = channel => {
    const {
      onChannelSubscribe,
      onChannelUnsubscribe,
      onTopicSubscribe,
      onTopicUnsubscribe
    } = this.props;

    return (
      <li key={channel.slug} className="SubscriptionScene-channelItem">
        <SubscribeChannel
          channel={channel}
          onChannelSubscribe={onChannelSubscribe}
          onChannelUnsubscribe={onChannelUnsubscribe}
          onTopicSubscribe={onTopicSubscribe}
          onTopicUnsubscribe={onTopicUnsubscribe}
        />
      </li>
    );
  };
}

export default SubscriptionScene;
