import { delay } from 'redux-saga';
import { takeLatest, select, put, call } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import { types as PERSONAL_INFO_FORM } from '../ducks/personal-info-form';
import * as fromPersonalInfoForm from '../ducks/personal-info-form';
import { actions as PersonalInfoForm } from '../ducks/personal-info-form';
import * as yup from 'yup';

const emailSchema = yup
  .string()
  .required('e-mail является обязательным')
  .email('Данный e-mail не валиден');

const firstNameSchema = yup.string().required('Имя является обязательным');

const phoneSchema = yup
  .string()
  .required('Телефон является обязательным')
  .min(16, 'Номер должен содержать не менее 10-ти цифр');

export function* personalInfoFormWatcher() {
  yield takeLatest(PERSONAL_INFO_FORM.CHANGE_EMAIL, validateEmail);
  yield takeLatest(PERSONAL_INFO_FORM.CHANGE_EMAIL, checkEmail);
  yield takeLatest(PERSONAL_INFO_FORM.CHANGE_FIRST_NAME, validateFirstName);
  yield takeLatest(PERSONAL_INFO_FORM.CHANGE_PHONE, validatePhone);
}

function* validateEmail(action) {
  yield delay(1000);
  const { email } = action.payload;

  try {
    const validation = emailSchema.validateSync(email);
    if (validation) {
      yield put(PersonalInfoForm.emailValidationResult(false));
    }
  } catch (error) {
    yield put(PersonalInfoForm.emailValidationResult(error));
  }
}

function* checkEmail(action) {
  const { email } = action.payload;

  yield delay(1200);

  const emailError = yield select(fromPersonalInfoForm.getEmailError);

  if (emailError) {
    return;
  }

  yield put(PersonalInfoForm.emailStatusRequest());

  try {
    const response = yield call(mixitApi().account().exists, email);
    yield put(PersonalInfoForm.emailStatusRegistered(response.data));
  } catch (e) {
    yield put(PersonalInfoForm.emailStatusUnregistered());
  }
}

function* validateFirstName(action) {
  yield delay(500);
  const { firstName } = action.payload;

  try {
    const validation = firstNameSchema.validateSync(firstName);
    if (validation) {
      yield put(PersonalInfoForm.firstNameValidationResult(false));
    }
  } catch (error) {
    yield put(PersonalInfoForm.firstNameValidationResult(error));
  }
}

function* validatePhone(action) {
  yield delay(500);
  const { phone } = action.payload;

  try {
    const validation = phoneSchema.validateSync(phone);
    if (validation) {
      yield put(PersonalInfoForm.phoneValidationResult(false));
    }
  } catch (error) {
    yield put(PersonalInfoForm.phoneValidationResult(error));
  }
}
