import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

import Container from '../container/container';
import MixitLogo from '../mixit-logo/mixit-logo';
import UserMenuContainer from '../../containers/user-menu';

import './main-header.css';

import SearchFieldContainer from '../../containers/search-field';

class MainHeader extends PureComponent {
  render() {
    return (
      <div className="MainHeader">
        <Container>
          <div className="MainHeader-content">
            <div className="MainHeader-searchCol">
              <SearchFieldContainer />
            </div>
            <div className="MainHeader-logoCol">
              <MixitLogo key={this.props.location.pathname} />
            </div>
            <div className="MainHeader-userMenuCol">
              <UserMenuContainer />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(MainHeader);
