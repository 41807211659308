import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromNavigations from 'modules/core/ducks/navigations';
import Loader from 'modules/core/components/loader/loader';

import AnimatedLink from 'modules/core/components/animated-link/animated-link';
import { Link as RouterLink } from 'react-router-dom';

import FooterNavigationGroup from '../footer-navigation-group/footer-navigation-group';
import FooterContacts from '../footer-contacts/footer-contacts';

import './footer-navigation.css';

const mapStateToProps = createStructuredSelector({
  firstColumn: fromNavigations.getFooterFirstColumn,
  secondColumn: fromNavigations.getFooterSecondColumn,
  thirdColumn: fromNavigations.getFooterThirdColumn,
  fourthColumn: fromNavigations.getFooterFourthColumn,
  isNavigationLoaded: fromNavigations.getNavigationDataLoaded
});

class FooterNavigation extends Component {
  render() {
    // const { isAuthorized, onAuthorizationClick } = this.props;
    const { firstColumn, secondColumn, thirdColumn, fourthColumn, isNavigationLoaded } = this.props;

    return (
      <div className="FooterNavigation">
        <div className="FooterNavigation-item">
          {isNavigationLoaded ? (
            <FooterNavigationGroup title={firstColumn.title}>
              {firstColumn.list.map(({ id, href, link, title, props }) => (
                <FooterNavigationGroup.Item key={id}>
                  <AnimatedLink
                    href={href ? href : null}
                    to={link ? link : null}
                    noUnderline
                    component={link ? RouterLink : 'a'}
                    title={title}
                    target={href ? '_blank' : null}
                    {...props}
                  >
                    {title}
                  </AnimatedLink>
                </FooterNavigationGroup.Item>
              ))}
            </FooterNavigationGroup>
          ) : (
            <div style={{ position: 'relative', paddingTop: '100px' }}>
              <Loader size="small" secondary />
            </div>
          )}
        </div>
        <div className="FooterNavigation-item">
          {/* <FooterNavigationGroup title="Клиенту"> */}
          {/* {isAuthorized ? (
              <FooterNavigationGroup.Item>
                <AnimatedLink
                  to={getProfileLink()}
                  noUnderline
                  component={RouterLink}
                  title="Личный кабинет"
                >
                  "Личный кабинет"
                </AnimatedLink>
              </FooterNavigationGroup.Item>
            ) : (
              <FooterNavigationGroup.Item>
                <AnimatedLink
                  onClick={onAuthorizationClick}
                  noUnderline
                  component="button"
                  type="button"
                  title="Личный кабинет"
                >
                  "Личный кабинет"
                </AnimatedLink>
              </FooterNavigationGroup.Item>
            )} */}

          {isNavigationLoaded ? (
            <FooterNavigationGroup title={secondColumn.title}>
              {secondColumn.list.map(({ id, href, link, title, props }) => (
                <FooterNavigationGroup.Item key={id}>
                  <AnimatedLink
                    href={href ? href : null}
                    to={link ? link : null}
                    noUnderline
                    component={link ? RouterLink : 'a'}
                    title={title}
                    target={href ? '_blank' : null}
                    {...props}
                  >
                    {title}
                  </AnimatedLink>
                </FooterNavigationGroup.Item>
              ))}
            </FooterNavigationGroup>
          ) : (
            <div style={{ position: 'relative', paddingTop: '100px' }}>
              <Loader size="small" secondary />
            </div>
          )}
          {/* </FooterNavigationGroup> */}
        </div>
        <div className="FooterNavigation-item">
          {isNavigationLoaded ? (
            <FooterNavigationGroup title={thirdColumn.title}>
              {thirdColumn.list.map(({ id, href, link, title, props }) => (
                <FooterNavigationGroup.Item key={id}>
                  <AnimatedLink
                    href={href ? href : null}
                    to={link ? link : null}
                    noUnderline
                    component={link ? RouterLink : 'a'}
                    title={title}
                    target={href ? '_blank' : null}
                    {...props}
                  >
                    {title}
                  </AnimatedLink>
                </FooterNavigationGroup.Item>
              ))}
            </FooterNavigationGroup>
          ) : (
            <div style={{ position: 'relative', paddingTop: '100px' }}>
              <Loader size="small" secondary />
            </div>
          )}
        </div>
        <div className="FooterNavigation-item">
          {isNavigationLoaded ? (
            <FooterNavigationGroup title={fourthColumn.title}>
              {fourthColumn.list.map(({ id, href, link, title, props }) => (
                <FooterNavigationGroup.Item key={id}>
                  <AnimatedLink
                    href={href ? href : null}
                    to={link ? link : null}
                    noUnderline
                    component={link ? RouterLink : 'a'}
                    title={title}
                    target={href ? '_blank' : null}
                    {...props}
                  >
                    {title}
                  </AnimatedLink>
                </FooterNavigationGroup.Item>
              ))}
            </FooterNavigationGroup>
          ) : (
            <div style={{ position: 'relative', paddingTop: '100px' }}>
              <Loader size="small" secondary />
            </div>
          )}
          <div className="FooterNavigation-contacts">
            {/* <FooterContacts tel="8 (495) 252-07-43" /> */}
            <FooterContacts tel="8 (800) 550-98-70 " />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FooterNavigation);
