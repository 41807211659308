import React, { Component } from 'react';
import './navigation-button.css';
import classNames from 'classnames';

class NavigationButton extends Component {
  render() {
    const { isActive, ...rest } = this.props;

    return (
      <button
        className={classNames('BannerFeaturedSlider_NavigationButton', {
          'BannerFeaturedSlider_NavigationButton--isActive': isActive
        })}
        type="button"
        {...rest}
        disabled={isActive}
      >
        <span className="BannerFeaturedSlider_NavigationButton-dot" />
      </button>
    );
  }
}

export default NavigationButton;
