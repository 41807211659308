import React, { Component } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArticleVerticalPreview from '../components/article-vertical-preview/article-vertical-preview';

import * as fromArticleList from '../ducks/article-list';

const mapStateToProps = createStructuredSelector({
  article: fromArticleList.getItemBySlug
});

class ArticleVerticalPreviewContainer extends Component {
  static propTypes = {
    slug: string.isRequired
  };

  render() {
    return <ArticleVerticalPreview {...this.props} />;
  }
}

export default connect(mapStateToProps)(ArticleVerticalPreviewContainer);
