import initialState from './seeds';
import types from './types';

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.RESET:
      return initialState;
    case types.SELECT_CITY:
      return {
        ...state,
        selectedCityId: payload.id
      };

    case types.CLOSE_CITY_DIALOG:
      return {
        ...state,
        isCityDialogShowed: false
      };

    case types.OPEN_CITY_DIALOG:
      return {
        ...state,
        isCityDialogShowed: true
      };

    case types.CLOSE_CITY_DROPDOWN:
      return {
        ...state,
        isCityDropdownShowed: false
      };

    case types.OPEN_CITY_DROPDOWN:
      return {
        ...state,
        isCityDropdownShowed: true
      };

    default:
  }
  return state;
}

export default reducer;
