import { Fragment } from 'react';
import Container from 'modules/core/components/container/container';
export default ({ hasContainer }) => {
  let containerProps = {};
  if (typeof hasContainer !== 'boolean') {
    containerProps = hasContainer.props;
  }
  const _Container = Boolean(hasContainer) ? Container : Fragment;
  return [_Container, containerProps];
};
