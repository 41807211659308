import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import plural from 'modules/utils/plural';
import prepareCitiesOption from './helpers/prepare-cities-option';

import CitySelectPanel from '../components/city-select-panel/city-select-panel';

import * as fromShopList from '../ducks/shop-list';
import * as fromLocation from 'modules/geography/ducks/location';

const mapStateToProps = createStructuredSelector({
  cityList: fromShopList.getCityList,
  ignoredCountryId: fromShopList.getIgnoredCountryId,
  currentCity: fromLocation.getCurrentCity,
  currentCityId: fromLocation.getCurrentCityId,
  isCitiesLoading: fromShopList.getIsCitiesLoading,
  isCitiesLoaded: fromShopList.getIsCitiesLoaded
});

class CitySelectPanelContainer extends Component {
  render() {
    const {
      currentCity,
      cityList,
      activeCityId,
      ignoredCountryId,
      isCitiesLoading,
      isCitiesLoaded,
      onCitySelect
    } = this.props;

    const isCityListAvailable = !isCitiesLoading && isCitiesLoaded;
    const finalCityList = this.formFinalCityList(currentCity, cityList, isCityListAvailable);
    const preparedCityOptions = prepareCitiesOption(finalCityList, ignoredCountryId);
    const activeOptionIndex = this.findActiveOptionIndex(activeCityId, preparedCityOptions);

    const caption = this.getCaption();

    return (
      <CitySelectPanel
        caption={caption}
        cityList={preparedCityOptions}
        activeOptionIndex={activeOptionIndex}
        isCityListAvailable={isCityListAvailable}
        onCitySelect={onCitySelect}
      />
    );
  }

  findActiveOptionIndex(activeCityId, cityOptionList) {
    if (!activeCityId) {
      return 0;
    }

    for (let i = 0; i < cityOptionList.length; i++) {
      if (activeCityId === cityOptionList[i].id) {
        return i;
      }
    }

    return 0;
  }

  formFinalCityList(currentCity, cityList, isCityListAvailable) {
    if (currentCity && !isCityListAvailable) {
      return [currentCity, ...cityList];
    }

    return cityList;
  }

  getCaption() {
    const {
      shopList,
      currentCity,
      currentCityId,
      activeCity,
      isCityListAvailable,
      isLoaded,
      activeCityId,
      isActiveCityWasChanged
    } = this.props;

    if (!isLoaded || !isCityListAvailable) {
      return '';
    }

    const plurals = ['магазин', 'магазина', 'магазинов'];
    const shopsAmount = shopList.length;

    const isCountry =
      (!activeCity && !currentCity) || (isActiveCityWasChanged && activeCityId === 'all');

    // Will be removed
    const additionalCountryCondition = currentCity && currentCityId.toString().length > 5;

    if (isCountry || additionalCountryCondition) {
      return `${shopsAmount} ${plural(shopsAmount, plurals)} в России`;
    }

    if (!activeCity && currentCity) {
      return `В городе ${currentCity.title} нет магазинов`;
    }

    return `${shopsAmount} ${plural(shopsAmount, plurals)} в городе`;
  }
}

export default connect(mapStateToProps)(CitySelectPanelContainer);
