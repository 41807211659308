import React, { Component } from 'react';
import classNames from 'classnames';

class Cell extends Component {
  render() {
    return (
      <div
        className={classNames({
          'Basket-cellProduct': this.props.type === 'mainInfo',
          'Basket-cellAmount': this.props.type === 'amount',
          'Basket-cellPrice': this.props.type === 'price',
          'Basket-cellActions': this.props.type === 'actions'
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Cell;
