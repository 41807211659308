import React, { Component } from 'react';

import Price from 'modules/ordering/components/price-formatted/price-formatted';

import Notification from '../notification/notification';
import IconContainer from '../icon-container/icon-container';
import { IconTrack } from '../icons';

class FreeDeliveryNotification extends Component {
  render() {
    const { freeDeliveryFrom } = this.props;

    return (
      <Notification type="primary">
        <span className="Notification-icon">
          <IconContainer size="small">
            <IconTrack />
          </IconContainer>
        </span>
        <span className="Notification-text">
          Для обеспечения бесконтактной доставки все заказы оформляются со 100% предоплатой на
          сайте. При покупке от{' '}
          <b>
            <Price number={freeDeliveryFrom} />
          </b>{' '}
          доставка бесплатно!
        </span>
      </Notification>
    );
  }
}

export default FreeDeliveryNotification;
