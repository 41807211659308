import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import { IconVk, IconFacebook } from 'modules/core/components/icons';
import Form from 'modules/form/components/form/form';
import Field from 'modules/form/components/field/field';
import LeftLabeledField from 'modules/form/components/left-labeled-field/left-labeled-field';
import FormGroup from 'modules/form/components/form-group/form-group';
import Input from 'modules/form/components/input/input';
import PasswordInput from 'modules/form/components/password-input/password-input';
import Link from 'modules/core/components/link/short-link';
import Button from 'modules/core/components/button/button';
import IconButton from 'modules/social/components/icon-button/icon-button';
import SocialButton from 'modules/social/components/button/button';
// import VKButton from 'modules/social/components/vk-button/vk-button';

import './auth-form.css';

const deleteWhiteSpaces = str => str.replace(/\s/g, '');
class AuthForm extends Component {
 state= {
   values: null
 };
 
  render() {
    const { authError, onRecoveryPasswordOpen } = this.props;

    const {values} = this.state

      return (
      <div className="AuthForm">
        <Form onSubmit={this.handleSubmit} method="POST">
          <Media query="(max-width: 1100px)" render={this.renderRegistrationSuggest} />

          <Media query="(max-width: 840px)">
            {isMobile => (
              <Fragment>
                {!isMobile && this.renderQuickLogin()}

                {authError && <span style={{ color: 'red' }}>{authError}</span>}

                <Form.LabeledRow>
                  <Field label="E-mail" size={isMobile ? 'small' : 'normal'}>
                    <Input
                      name="email"
                      type="email"
                      required
                      value={values ? values["email"] : null}
                      size={isMobile ? 'small' : 'normal'}
                      onChange={this.handleChange}
                      onInput={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                    />
                  </Field>
                </Form.LabeledRow>

                <Form.LabeledRow>
                  <Field
                    label="Пароль"
                    size={isMobile ? 'small' : 'normal'}
                    hint={
                      isMobile && (
                        <span className="AuthForm-forgottenPassword">
                          <Link type="button" component="button" onClick={onRecoveryPasswordOpen}>
                            Забыли пароль?
                          </Link>
                        </span>
                      )
                    }
                  >
                    <PasswordInput
                      name="password"
                      type="password"
                      required
                      size={isMobile ? 'small' : 'normal'}
                    />
                  </Field>
                </Form.LabeledRow>

                <Form.ActionRow>
                  {isMobile ? (
                    this.renderSubmitButton(isMobile)
                  ) : (
                    <FormGroup>
                      <FormGroup.Item>{this.renderSubmitButton(isMobile)}</FormGroup.Item>

                      <FormGroup.Item>
                        <Link type="button" component="button" onClick={onRecoveryPasswordOpen}>
                          Забыли пароль?
                        </Link>
                      </FormGroup.Item>
                    </FormGroup>
                  )}
                </Form.ActionRow>

                {isMobile && this.renderQuickLogin()}
              </Fragment>
            )}
          </Media>
        </Form>
      </div>
    );
  }

  handleKeyDown =(e)=> {
    if ( e.key === " " ) {
      e.preventDefault();
    }
  }

  handleChange=({target:{value, name}})=> {
   
    const cleanedValue = deleteWhiteSpaces(value)

    this.setState((prevState)=>{
      return {
        ...prevState, 
        values: {
          ...prevState.values,
          [name]: cleanedValue
        }
      }
    })
  }

  renderSubmitButton(isMobile) {
    return (
      <Button
        type="submit"
        variant="primary"
        title="Войти"
        expanded={isMobile}
        size={isMobile ? 'small' : 'normal'}
      />
    );
  }

  renderQuickLogin() {
    const { onVkAuthCall, onFacebookAuthCall } = this.props;
    return (
      <Form.Row>
        <div className="AuthForm-quickLogin">
          <div className="AuthForm-quickLoginText">Вход в один клик</div>
          <ul className="AuthForm-quickLoginSocialNetworkList">
            <li className="AuthForm-quickLoginSocialNetworkItem">
              <SocialButton social="vk" size="small" icon={<IconVk />} onClick={onVkAuthCall} />
              {/* <VKButton size="small" onClick={onVkAuthCall} component="button" /> */}
            </li>

            <li className="AuthForm-quickLoginSocialNetworkItem">
              <SocialButton
                social="facebook"
                icon={<IconFacebook />}
                size="small"
                onClick={onFacebookAuthCall}
              />
            </li>
          </ul>
        </div>
      </Form.Row>
    );
  }

  renderMobileEntryInOneClick() {
    return (
      <Form.Row>
        <LeftLabeledField label="Вход в один клик" size="small">
          <FormGroup>
            <FormGroup.Item>
              <IconButton icon={<IconVk />} size="small" />
            </FormGroup.Item>
            <FormGroup.Item>
              <IconButton icon={<IconFacebook />} size="small" />
            </FormGroup.Item>
          </FormGroup>
        </LeftLabeledField>
      </Form.Row>
    );
  }

  renderRegistrationSuggest = () => {
    const { onRegistrationOpen } = this.props;

    return (
      <Form.Row>
        <div className="AuthForm-registrationSuggest">
          <span className="AuthForm-registrationSuggestText">Еще нет аккаунта?</span>
          <Link component="button" type="button" onClick={onRegistrationOpen}>
            Зарегистрироваться
          </Link>
        </div>
      </Form.Row>
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = new FormData(e.target);
    const { onSubmit } = this.props;

    const email = form.get('email');
    const password = form.get('password');

    onSubmit({
      email,
      password
    });
  };
}

export default AuthForm;
