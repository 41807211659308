import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MediaQuery from 'react-media';


import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromIngredients from 'modules/ingredient/ducks/ingredients';
import { actions as Ingredient } from '../ducks/ingredients';

import IngredientDialog from "../components/ingredient-dialog/ingredient-dialog"

const mapStateToProps = createStructuredSelector({
  show: fromDialogs.getIsIngredientOpened,
  ingredient: fromIngredients.getIngredientsWithDetails,
  isLoading: fromIngredients.getLoadingDetailsById,
  ingredientId: fromDialogs.getIngredientId,
});

const mapDispatchToProps = {
    onClose: fromDialogs.actions.closeIngredient,
    loadDetails: Ingredient.showDetails
};

class IngredientDialogContainer extends Component {
  render() {
    const {...rest} = this.props;

    return (
        <MediaQuery query="(max-width: 840px)">
            {isMobile => (
                <IngredientDialog {...rest} isInBottoSheet={Boolean(isMobile)}/>
            )}
        </MediaQuery>
    );
  }

  componentDidMount() {
    this.manageLoadDetails();
  }
  componentDidUpdate() {
    this.manageLoadDetails();
  }


  manageLoadDetails = () => {
    const {ingredient, ingredientId, loadDetails} = this.props;

    if(!Boolean(ingredient) && Boolean(ingredientId)) {
      loadDetails(ingredientId)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngredientDialogContainer);
