import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './subcategory-list.css';

const { string, arrayOf, shape } = PropTypes;

export default class SubcategoryList extends Component {
  static propTypes = {
    /*  Ссылка для заголовка */
    href: string,
    /* текст заголовка */
    title: string,
    /*  данные для списка  */
    list: arrayOf(
      shape({
        /* Название элемента списка */
        title: string,
        /* Ссылка для элемента списка */
        href: string
      })
    )
  };

  render() {
    const { href, title, list } = this.props;

    const TitleComponent = href ? Link : 'span';

    return (
      <div className="SubcategoryList">
        <TitleComponent
          {...this.getLinkProps()}
          className={cn('SubcategoryList-title', {
            'SubcategoryList-title--likeLink': href
          })}
        >
          {title}
        </TitleComponent>
        <ul className="SubcategoryList-list">
          {list.map((item, index) => {
            return (
            <li key={index} className="SubcategoryList-item">
              <Link
                to={item.href}
                title={item.title}
                onClick={this.handleLinkClick(item)}
                className="SubcategoryList-itemLink"
              >
                {item.title}
              </Link>
            </li>
          )})}
        </ul>
      </div>
    );
  }

  getLinkProps() {
    const { href, onLinkClick } = this.props;

    const linkProps = href
      ? {
          to: href,
          onClick: onLinkClick
        }
      : null;

    return linkProps;
  }

  handleLinkClick = item => () => {
    const { onNestedLinkClick, onFilterLinkClick } = this.props;

    if (item.filter) {
      onFilterLinkClick([item.filter]);
      return;
    }

    onNestedLinkClick(item.slug);
  };
}
