import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'modules/core/components/button/button';

import Field from './form-data-field';

import './form-data.css';

class FormData extends Component {
  static propTypes = {
    onEdit: PropTypes.func
  };

  static Field = Field;

  render() {
    const { children, onEdit } = this.props;
    return (
      <div className="FormData">
        <div className="FormData-fields">{children}</div>
        <div className="FormData-actionEdit">
          <Button
            title="Редактировать данные"
            onClick={onEdit}
            variant="primary"
            size="small"
            expanded
          />
        </div>
      </div>
    );
  }
}

export default FormData;
