import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import isFunction from 'modules/utils/is-function';
import contains from 'dom-helpers/query/contains';
import { Portal } from 'react-portal';
import Position from './position';
import LockScroll from './lock-scroll';
import { Transition, animated } from 'react-spring';
import './dropdown.css';
import './dropdown-overlay.css';
import isMobile from 'modules/utils/is-mobile';
import isReducedMotion from 'modules/utils/is-reduced-motion';

function getHorizontalOrigin(align) {
  switch (align) {
    case 'right':
      return '100%';
    case 'center':
      return '50%';
    case 'left':
    default:
      return '0%';
  }
}

function getVerticalOrigin(align) {
  switch (align) {
    case 'bottom':
      return '100%';
    case 'top':
    default:
      return '0%';
  }
}

const springConfig = {
  mass: 0.8, // spring mass
  tension: 300, // spring energetic load
  friction: 25 // spring resistence
};

class Dropdown extends Component {
  static propTypes = {
    /** Tooltip content */
    children: PropTypes.any,

    /** Dropdown opener position related to */
    trigger: PropTypes.element,

    /** Should show dropdown */
    show: PropTypes.bool,

    align: Position.propTypes.align,

    valign: Position.propTypes.valign,

    container: PropTypes.any,

    overlay: PropTypes.bool,

    /** Handle show state. Toggle component state to outer if passed */
    onChange: PropTypes.func,

    /** Use fixed positioning */
    fixed: PropTypes.bool
  };

  static defaultProps = {
    content: null,
    closable: false,
    overlay: false,
    container: __BROWSER__ ? document.body : null,
    onChange: null,
    fixed: false
  };

  state = {
    show: false
  };

  render() {
    const {
      children,
      container,
      align,
      valign,
      fixed,
      overlay,
      offsetTop,
      offsetLeft,
      offsetRight,
      offsetBottom
    } = this.props;

    const show = this.isShowed();
    const offsetStyle = {
      marginTop: offsetTop,
      marginLeft: offsetLeft,
      marginRight: offsetRight,
      marginBottom: offsetBottom
    };

    void (offsetTop || delete offsetStyle.marginTop);
    void (offsetLeft || delete offsetStyle.marginLeft);
    void (offsetRight || delete offsetStyle.marginRight);
    void (offsetBottom || delete offsetStyle.marginBottom);

    return (
      <Fragment>
        {this.renderTrigger()}

        <Transition
          native
          config={springConfig}
          items={show}
          from={{ opacity: 0, transform: 'scale(0.9) translateY(-20px)' }}
          enter={{ opacity: 1, transform: 'scale(1) translateY(0)' }}
          leave={{ opacity: 0, transform: 'scale(0.9) translateY(-20px)' }}
          immediate={isReducedMotion() || isMobile()}
        >
          {show => {
            if (!show) {
              return null;
            }

            return props => (
              <Portal node={container}>
                {overlay && <LockScroll filter={this.filterScrollLock} />}
                {overlay && (
                  <animated.div className="DropdownOverlay" style={{ opacity: props.opacity }} />
                )}
                <Position
                  target={this._trigger}
                  container={container}
                  align={align}
                  valign={valign}
                  fixed={fixed}
                  style={{
                    ...props,
                    ...offsetStyle,
                    transformOrigin: `${getHorizontalOrigin(align)} ${getVerticalOrigin(valign)}`
                  }}
                >
                  <animated.div
                    className={cn('Dropdown', {
                      'Dropdown--fixed': fixed
                    })}
                    ref={this.handleDropdownRef}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    {children}
                  </animated.div>
                </Position>
              </Portal>
            );
          }}
        </Transition>
      </Fragment>
    );
  }

  renderTrigger() {
    const { trigger, action } = this.props;

    if (action === 'hover') {
      return React.cloneElement(trigger, {
        ref: this.handleTriggerRef,
        onMouseEnter: this.handleTriggerMouseEnter,
        onMouseLeave: this.handleTriggerMouseLeave
      });
    }

    return React.cloneElement(trigger, {
      ref: this.handleTriggerRef,
      onClick: this.handleTriggerClick
    });
  }

  componentDidMount() {
    window.addEventListener('click', this.handleWindowClick);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  filterScrollLock = ({ target }) => {
    if (!this._dropdown) {
      return true;
    }

    return contains(document.body, target) && !contains(this._dropdown, target);
  };

  isShowed() {
    const { onChange, show } = this.props;
    if (isFunction(onChange)) {
      return show;
    }

    return this.state.show;
  }

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  handleTriggerRef = target => {
    if (!target) return;

    const targetNode = findDOMNode(target);

    if (this._trigger === targetNode) {
      return;
    }

    this._trigger = targetNode;
    this.forceUpdate();
  };

  handleTriggerClick = e => {
    e.preventDefault();
    const { onClick } = this.props;

    this.toggleShow();

    if (isFunction(onClick)) {
      onClick();
    }
  };

  handleTriggerMouseEnter = e => {
    if (this.props.action !== 'hover') return;
    clearTimeout(this._hoverTimer);
    this.show();
  };

  handleTriggerMouseLeave = e => {
    if (this.props.action !== 'hover') return;
    clearTimeout(this._hoverTimer);
    this._hoverTimer = setTimeout(() => this.close(), 500);
  };

  handleMouseEnter = e => {
    if (this.props.action !== 'hover') return;
    clearTimeout(this._hoverTimer);
    this.show();
  };

  handleMouseLeave = e => {
    if (this.props.action !== 'hover') return;
    clearTimeout(this._hoverTimer);
    this._hoverTimer = setTimeout(() => this.close(), 500);
  };

  handleWindowClick = e => {
    let target = e.target;

    if (!this._dropdown) {
      return;
    }

    if (
      !contains(document.body, target) ||
      contains(this._dropdown, target) ||
      contains(this._trigger, target)
    ) {
      return;
    }

    this.close();
  };

  handleWindowResize = () => {
    // if (this.isShowed()) {
    //   this.close();
    // }
  };

  handleDropdownRef = target => {
    this._dropdown = findDOMNode(target);
  };

  close() {
    clearTimeout(this._hoverTimer);
    this.onChange(false);

    if (!this.props.show && !this.state.show) {
      return;
    }

    this.setState({
      show: false
    });
  }

  show() {
    clearTimeout(this._hoverTimer);
    const { onChange } = this.props;

    if (this.props.show || this.state.show) {
      return;
    }

    if (this._idle) {
      return;
    }

    if (isFunction(onChange)) {
      this.onChange(true);
    } else {
      this.setState({
        show: true
      });
    }
  }

  toggleShow() {
    const { onChange, show } = this.props;

    if (isFunction(onChange)) {
      this.onChange(!show);
    } else {
      this.setState(state => {
        const show = !state.show;
        return {
          show
        };
      });
    }
  }

  onChange(show) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(show);
    }
  }
}

export default Dropdown;
