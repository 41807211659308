import productSeed from '../../products/seeds';

let productsDetailsObject = {};

Object.keys(productSeed).forEach(slug => {
  productsDetailsObject[slug] = {
    slug,
    properties: [
      {
        id: 1,
        name: 'age',
        title: 'Возраст',
        description: '',
        multiple: false,
        value: '30+',
        options: [
          {
            id: 11,
            name: '30-plus',
            slug: '30-plus',
            title: '30+'
          }
        ]
      },
      {
        id: 2,
        name: 'assignment',
        title: 'Назначение',
        description: '',
        multiple: true,
        options: [
          {
            id: 21,
            name: 'energy',
            slug: 'energy',
            title: 'питание'
          },
          {
            id: 22,
            name: 'recovery',
            slug: 'recovery',
            title: 'востановление'
          },
          {
            id: 23,
            name: 'softness',
            slug: 'softness',
            title: 'смягчение'
          },
          {
            id: 24,
            name: 'from-wrinkles',
            slug: 'from-wrinkles',
            title: 'от морщин'
          },
          {
            id: 25,
            name: 'for-happiness',
            slug: 'for-happiness',
            title: 'для хорошего настроения'
          }
        ]
      },
      {
        id: 3,
        name: 'apply-time',
        title: 'Время применения',
        description: '',
        multiple: false,
        options: [
          {
            id: 35,
            name: 'nightly',
            slug: 'nightly',
            title: 'ночной'
          }
        ]
      },
      {
        id: 4,
        name: 'skin-type',
        title: 'Тип кожи',
        description: '',
        multiple: false,
        options: [
          {
            id: 45,
            name: 'dry',
            slug: 'dry',
            title: 'сухой'
          }
        ]
      },
      {
        id: 5,
        name: 'sex',
        title: 'Пол',
        description: '',
        multiple: true,
        options: [
          {
            id: 55,
            name: 'for-women',
            slug: 'for-women',
            title: 'для женщин'
          }
        ]
      }
    ],
    about:
      '<p>Всегда хочется, чтобы уход за собой радовал все пять (а лучше шесть!) чувств. Увлажняющий и питательный воздушный крем-йогурт All Babes Cream для тела создавался именно из этих соображений.</p></p>Приятная консистенция, вкусный аромат, радующая глаз нежная цветовая гамма - главное, не съешь этот волшебный йогурт! Он - только для наружного применения, именно на твоей коже он принесет максимум пользы и удовольствия, ведь благодаря натуральным экстрактам морошки, клюквы, ежевики, а также гелю алоэ и витаминам - гладкость и притягательность просто обеспечены!</p>',
    consistency: {
      ingredient_groups: [
        {
          id: 4,
          title: 'Натуральные ингредиенты',
          name: 'INGREDIENT_TYPE/NATURAL',
          list: [1, 2, 3, 4],
          color: '#28BF90'
        },
        {
          id: 2,
          title: 'Безопасные синтетические ингредиенты',
          name: 'INGREDIENT_TYPE/SYNTHETIC',
          list: [3, 4],
          color: '#5B5B5F'
        }
      ],
      primary: [1, 2, 3, 4]
    },
    usage: {
      id: 10,
      description:
        'Маску следует наносить на предварительно очищенную, не мокрую, но слегка влажную кожу, равномерно распределив ее плотным слоем по всему лицу. 10 - 15 минут чудесные живые ягоды будут творить свои чудеса, проникая в клетки и обновляя их естественным путем. После этого маску нужно тщательно смыть спонжем или просто теплой водой (высыхать она не будет!) и нанести на кожу любимый крем. ',
      media: {
        type: 'VIDEO',
        caption: 'Cats',
        title: '',
        cover: '/content/products/cat-screensaver.jpg',
        sources: [
          {
            size: 1080,
            main: {
              normal: '/content/products/cats.webm',
              mime: 'video/webm'
            },
            fallback: {
              normal: '/content/products/cats.mp4',
              mime: 'video/mp4'
            }
          }
        ]
      }
    },
    storageConditions: [
      {
        title: 'Не более 1 года',
        description: 'без прямых солнечных лучей',
        iconPath: '/content/products/calendar.svg'
      },
      {
        title: 'от +5 °С до +25 °С',
        description: '',
        iconPath: '/content/products/term.svg'
      },
      {
        title: 'Не более 6 мес.',
        description: 'после вскрытия',
        iconPath: '/content/products/reload.svg'
      }
    ],
    similar: [
      'food-fetish-grape-backberry',
      'food-fetish-avocado-kiwi',
      'food-fetish-sweet-berries',
      'tropical-mask',
      'vegetable-mask',
      'mineral-mask'
    ],
    related: [
      'food-fetish-grape-backberry',
      'food-fetish-avocado-kiwi',
      'food-fetish-sweet-berries',
      'tropical-mask',
      'vegetable-mask',
      'mineral-mask'
    ]
  };
});

export default productsDetailsObject;
