import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Status from '../status/status';

class ErrorBoundary extends Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      this.setState({ error });

      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });

      this.props.onError();
    }
  }

  render() {
    if (this.state.error) {
      return <Status code="500" />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
