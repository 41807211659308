import React, { Component } from 'react';
import './icon-facebook.css';

class IconFacebook extends Component {
  render() {
    return (
      <svg className="IconFacebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path d="M24.17 17.92h-4v14.4h-6v-14.4H11.3v-5.09h2.84V9.54c0-2.36 1.12-6 6-6h4.43v4.92H21.4a1.22 1.22 0 0 0-1.27 1.39v3h4.57z" />
      </svg>
    );
  }
}
export default IconFacebook;
