import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';

import './slider.css';

import ReactSlider from 'react-slider';

const { number, func } = PropTypes;

const KEY_CODES = {
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40
};

class Slider extends Component {
  static propTypes = {
    min: number,
    max: number,
    step: number,
    value: number,
    onAccept: func
  };

  static defaultProps = {
    min: 0,
    step: 1
  };

  render() {
    const { disabled, ...rest } = this.props;

    return (
      <div className="Slider-holder" onKeyDown={this.handleKeyDown} tabIndex={disabled ? -1 : 0}>
        <ReactSlider
          withBars
          className="Slider"
          barClassName="Slider-bar"
          handleClassName="Slider-handle"
          handleActiveClassName="Slider-handle--active"
          disabled={disabled}
          {...rest}
        >
          {/* <div className="my-handle">{value}</div> */}
        </ReactSlider>
      </div>
    );
  }

  increase() {
    const { onChange, value, max } = this.props;

    if (value >= max) {
      onChange(max);
      return;
    }
    onChange(value + 1);
  }

  decrease() {
    const { onChange, value, min } = this.props;

    if (value <= min) {
      onChange(min);
      return;
    }
    onChange(value - 1);
  }

  handleKeyDown = e => {
    const keyCode = e.keyCode;

    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    if (keyCode === KEY_CODES.ARROW_LEFT || keyCode === KEY_CODES.ARROW_DOWN) {
      e.preventDefault();
      this.decrease();
    } else if (keyCode === KEY_CODES.ARROW_RIGHT || keyCode === KEY_CODES.ARROW_UP) {
      e.preventDefault();
      this.increase();
    }
  };
}

export default Slider;
