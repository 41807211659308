import React, { Component } from 'react';

import BottomSheet from '../bottom-sheet/bottom-sheet';
import Button from '../button/button';
import ButtonClose from '../button-close/button-close';

import './city-suggest-bottom-sheet.css';

class CitySuggestBottomSheet extends Component {
  render() {
    const { title, onClose, onAccept, onAnotherSelect, ...rest } = this.props;

    return (
      <BottomSheet onClose={onClose} {...rest}>
        <div className="CitySuggestBottomSheet">
          <div className="CitySuggestBottomSheet-header">
            <div className="CitySuggestBottomSheet-buttonClose">
              <ButtonClose onClick={onClose} />
            </div>
            <h2 className="CitySuggestBottomSheet-title">Ваш город - {title}?</h2>
          </div>

          <div className="CitySuggestBottomSheet-info">
            От города зависят способы доставки и их стоимость. Выберите город для доставки
          </div>

          <div className="CitySuggestBottomSheet-actions">
            <div className="CitySuggestBottomSheet-actionCol">
              <Button title="Нет, другой" size="small" onClick={onAnotherSelect} expanded />
            </div>

            <div className="CitySuggestBottomSheet-actionCol">
              <Button
                title="Да, верно"
                size="small"
                variant="primary"
                onClick={onAccept}
                expanded
              />
            </div>
          </div>
        </div>
      </BottomSheet>
    );
  }
}

export default CitySuggestBottomSheet;
