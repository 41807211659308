import React, { Component } from 'react';
import { IconBasket } from 'modules/core/components/icons';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';

import Button from 'modules/core/components/button/button';

class ButtonAddToBasket extends Component {
  render() {
    const { isInBasket, isFetching, ...rest } = this.props;
    return (
      <Button
        title={isInBasket ? 'Добавить еще' : 'Добавить в корзину'}
        variant="primary"
        size="small"
        iconPrepend={<IconBasket />}
        iconAppend={isFetching && <IconFlatLoader />}
        {...rest}
        expanded
      />
    );
  }
}

export default ButtonAddToBasket;
