import React, { Component } from 'react';

import dateString from 'modules/utils/date-string';
import Media from 'modules/core/components/media/media';

import './mass-media-article.css';

class MassMediaArticle extends Component {
  render() {
    const { link, media, createDate, title } = this.props;

    return (
      <div className="MassMediaArticle">
        <a className="MassMediaArticle-link" href={link} target="_blank" rel="noopener noreferrer">
          <div className="MassMediaArticle-logo">
            <Media
              className="ArticalVerticalPreview-image"
              media={media}
              stretch="horizontal"
              nocaption
            />
          </div>
          <div className="MassMediaArticle-date">{dateString(createDate)}</div>
          <h2 className="MassMediaArticle-title">{title}</h2>
        </a>
      </div>
    );
  }
}

export default MassMediaArticle;
