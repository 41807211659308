import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromProducts from '../ducks/products';

import GiftProduct from '../components/gift-product/gift-product';

const mapStateToProps = createStructuredSelector({
  product: fromProducts.getItemBySlug
});

const mapDispatchToProps = {
  addProductToBasket: fromBasket.actions.addProduct
};

const { string } = PropTypes;

class ProductContainer extends Component {
  static propTypes = {
    slug: string.isRequired
  };

  render() {
    const { product } = this.props;

    return (
      <GiftProduct product={product} {...this.props} onSelect={this.handleAddProductToBasket} />
    );
  }

  handleAddProductToBasket = () => {
    const { addProductToBasket, onChoose, product } = this.props;

    addProductToBasket(product);
    onChoose();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductContainer);
