import 'cross-fetch/polyfill';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'modules/core';
// import * as sw from './service-worker';
import { initApi } from 'services/mixit';

import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from './store';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'modules/core/components/error-boundary/error-boundary';
import { version } from '../package.json';

import './index.css';

/** this polyfill is used for HashLink ('react-router-hash-link') library */
smoothscroll.polyfill();

console.log(`%c${version}`, 'font-weight: bold;font-size: large');
console.log(
  `%c  /\\     %cCreated by\n%c /__\\    %chttps://prismsolutions.ru\n%c/ rism`,
  'color: #0000ff; font-weight: bold;',
  '',
  'color: #0000ff; font-weight: bold;',
  '',
  'color: #0000ff; font-weight: bold;'
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c3218819e2de4a649985f0efdce05581@sentry.io/1353558',
    release: `mixit-frontend-client@${version}`
  });
}

const initialState = __BROWSER__ && window.__PRELOADED_STATE__;

if (__BROWSER__) {
  delete window.__PRELOADED_STATE__;
}

initApi(process.env.REACT_APP_MIXIT_API_HOST, { suffix: 'api/v4' });
/* END MIXIT API */

const history = createBrowserHistory();
const store = configureStore(history, initialState);

store.runSagas();
if (__BROWSER__) {
  store.dispatch({ type: '@@REHYDRATE' });
}

function render(Application, root) {
  const useHydration = initialState && !module.hot;
  const renderFn = useHydration ? ReactDOM.hydrate : ReactDOM.render;

  if (!useHydration) {
    // Clear markup before rendering
    while (root.firstChild) {
      root.removeChild(root.firstChild);
    }
  }

  renderFn(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary onError={() => setTimeout(() => window.location.reload(), 5000)}>
          <Application />
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    root
  );
}

const rootElement = document.getElementById('root');

render(App, rootElement);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('modules/core', () => {
    const { App } = require('modules/core');
    render(App, rootElement);
  });
}

// sw.unregister();
