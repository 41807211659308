import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import * as fromPaymentMethods from '../ducks/payment-methods';
import * as fromOrders from '../ducks/orders';
import PaymentForm from '../components/payment-form/payment-form';

const getDeliveryMethodId = createSelector(
  fromOrders.getDeliveryById,

  function(delivery) {
    return delivery.deliveryMethod.id;
  }
);

const getPossiblePaymentMethods = createSelector(
  getDeliveryMethodId,
  fromPaymentMethods.getFindListByDeliveryMethod,

  function(deliveryMethodId, findListByDeliveryMethod) {
    return findListByDeliveryMethod(deliveryMethodId);
  }
);

const getIsLoading = createSelector(
  getDeliveryMethodId,
  fromPaymentMethods.getFindIsLoading,

  function(deliveryMethodId, findIsLoading) {
    return findIsLoading(deliveryMethodId);
  }
);

const mapState = createStructuredSelector({
  paymentMethodList: getPossiblePaymentMethods,
  isLoading: getIsLoading
});

class PaymentFormContainer extends Component {
  render() {
    const { paymentType, paymentMethodList, isLoading } = this.props;

    return (
      <PaymentForm
        isLoading={isLoading}
        paymentMethodList={paymentMethodList}
        paymentType={paymentType}
        onPaymentTypeChange={this.handlePaymentTypeChange}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handlePaymentTypeChange = paymentType => {
    this.props.onComplete();
    this.props.onChange(paymentType);
  };
}

export default connect(mapState)(PaymentFormContainer);
