import React, { Component, Fragment } from 'react';

import Media from 'react-media';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Form from 'modules/form/components/form/form';
import Field from 'modules/form/components/field/field';
import Input from 'modules/form/components/input/input';

import Dialog from '../dialog/dialog';
import Button from '../button/button';

import './add-to-wait-list-dialog.css';

const EMAIL_REQUIRED_ERROR = 'Почта является обязательным полем';
const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(EMAIL_REQUIRED_ERROR)
    .email(EMAIL_FORMAT_ERROR)
});

const initialValues = {
  email: ''
};

class AddToWaitListDialog extends Component {
  render() {
    const { isAuthorized, ...rest } = this.props;

    return (
      <Dialog closeable {...rest}>
        <div className="AddToWaitListDialog">
          <h2 className="AddToWaitListDialog-title">Товара нет в наличии</h2>

          <div className="AddToWaitListDialog-info">
            Мы сообщим вам, когда товар поступит в продажу
          </div>

          {!isAuthorized && (
            <div className="AddToWaitListDialog-form">
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
              >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Media query="(max-width: 840px)">
                      {isMobile => (
                        <Fragment>
                          <Form.LabeledRow>
                            <Field
                              label="Email"
                              error={touched.email && errors.email}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="email"
                                value={values.email}
                                state={touched.email && errors.email && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                required
                              />
                            </Field>
                          </Form.LabeledRow>

                          <Form.ActionRow>
                            <Field>
                              <Button
                                title="Сообщить о появлении"
                                variant="primary"
                                type="submit"
                                size={isMobile ? 'small' : 'normal'}
                                expanded
                              />
                            </Field>
                          </Form.ActionRow>
                        </Fragment>
                      )}
                    </Media>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </Dialog>
    );
  }

  handleSubmit = data => {
    const { onFormSubmit } = this.props;

    onFormSubmit(data.email);
  };
}

export default AddToWaitListDialog;
