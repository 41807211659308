import { normalize as _normalize } from 'normalizr';

import ResponseSchema from './schemas/response-schema';
import BannerSchema from './schemas/banner-schema';

import { creatMainBannerVersionPersistor } from 'services/local-storage';

import * as BannerEntity from '../entities/banner';

function entitiesToDomain(entityToDomain, entities) {
  const toObject = (object, [key, value]) => Object.assign(object, { [key]: value });

  return Object.entries(entities)
    .map(entry => {
      if (entry.key in entityToDomain) {
        return Object.entries(entry.value)
          .map(([key, item]) => [key, entityToDomain[entry.key](item)])
          .reduce(toObject);
      }
      return entry;
    })
    .reduce(toObject, {});
}

export function normalize(response) {
  const mainBannerVersionPersistor = creatMainBannerVersionPersistor();

  const mainBannerVersion = mainBannerVersionPersistor.read();

  response.data = response.data.filter(
    item =>
      !item.test_version ||
      (mainBannerVersion && item.test_version === mainBannerVersion) ||
      (!mainBannerVersion && item.test_version === 'TYPE/DEFAULT')
  );

  const banner = BannerSchema();

  const responseSchema = ResponseSchema([banner]);

  const normalizedResponse = _normalize(response, responseSchema);

  const normalizedList = {
    ...normalizedResponse,
    entities: entitiesToDomain(
      {
        banner: BannerEntity.toDomainModel,
        media: item => item
      },
      normalizedResponse.entities
    )
  };

  return normalizedList;
}
