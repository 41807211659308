import { mixitApi } from 'services/mixit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import * as INGREDIENT from '../ducks/ingredients/types';
import * as Ingredient from '../ducks/ingredients/actions';
import * as fromIngredient from '../ducks/ingredients/selectors';

function* showDetails({ payload }) {
  const { id } = payload;
  const details = yield select(fromIngredient.getDetailsById, { id });

  if (!details) {
    yield call(fetchIngredientDetails, id);
  }
}

function* fetchIngredientDetails(id) {
  const ingredientsApi = mixitApi().ingredients(id);

  try {
    const ingredientResponse = yield call(ingredientsApi.details);
    const details = ingredientResponse.data;

    yield put(Ingredient.addDetails(id, details));
  } catch (e) {
    console.error(e);
  }
}

export function* ingredientWatcher() {
  yield takeEvery(INGREDIENT.DETAILS_SHOW, showDetails);
}
