import React, { Component } from 'react';
import { Formik } from 'formik';
import RegistrationForm from '../components/registration-form/registration-form';
import { mixitApi } from 'services/mixit';
import * as Yup from 'yup';

const FIRST_NAME_REQUIRED_ERROR = 'Имя является обязательным полем';
const EMAIL_REQUIRED_ERROR = 'Почта является обязательным полем';
const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';
// const EMAIL_BUSY_ERROR = 'Пользователь с данной почтой уже зарегистрирован';
const EMAIL_BUSY_ERROR = 'Данная почта уже зарегистрирована';
const PASSWORD_SHORT_ERROR = 'Используйте пароль не менее 6-ти символов';
const PASSWORD_REQUIRED_ERROR = 'Пароль является обязательным полем';

const credentialsSchema = Yup.object().shape({
  firstName: Yup.string().required(FIRST_NAME_REQUIRED_ERROR),
  email: Yup.string()
    .required(EMAIL_REQUIRED_ERROR)
    .email(EMAIL_FORMAT_ERROR)
    .test('free-email', EMAIL_BUSY_ERROR, email => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const isValidEmail = regex.test(email);
      if (!isValidEmail) {
        return;
      }

      return mixitApi()
        .account()
        .exists(email)
        .then(() => false)
        .catch(() => true);
    }),
  password: Yup.string()
    .required(PASSWORD_REQUIRED_ERROR)
    .min(6, PASSWORD_SHORT_ERROR)
});

class RegistrationFormContainer extends Component {
  render() {
    const { onSubmit, onAuthOpen, defaultEmailValue, ...formProps } = this.props;

    const initialValues = {
      firstName: '',
      email: defaultEmailValue ? defaultEmailValue : '',
      password: ''
    };

    return (
      <Formik
        validationSchema={credentialsSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ values, touched, errors, isValidating, handleChange, handleBlur, handleSubmit }) => (
          <RegistrationForm
            {...formProps}
            values={values}
            touched={touched}
            errors={errors}
            isValidating={isValidating}
            onChange={handleChange}
            onBlur={handleBlur}
            onSubmit={handleSubmit}
            onAuthOpen={onAuthOpen}
          />
        )}
      </Formik>
    );
  }
}

export default RegistrationFormContainer;
