import React, { Component } from 'react';

import Media from 'modules/core/components/media/media';
import NavigationButton from './navigation-button';
import { Spring, animated } from 'react-spring';

import './vertical-slider.css';

const showStyle = {
  opacity: 1,
  position: 'relative',
  top: 0
};

const hideStyle = {
  opacity: 0,
  position: 'absolute',
  top: 0
};

class VerticalSlider extends Component {
  first = true;
  render() {
    const { list, current, showPagination, onSlideChange, ...rest } = this.props;

    return (
      <div className="VerticalSlider" {...rest}>
        {showPagination && (
          <div className="VerticalSlider-navigation">
            {list.map((_, index) => (
              <NavigationButton
                isActive={index === current}
                onClick={this.handleNavButtonClick(index)}
              />
            ))}
          </div>
        )}
        <div className="VerticalSlider-imageHolder">
          {list.map((media, index) => {
            const show = index === current;

            const from = showStyle;
            const to = show ? showStyle : hideStyle;

            return (
              <Spring from={from} to={to} native>
                {style => (
                  <animated.div style={style}>
                    <Media media={media} resizeWidth={560} stretch="horizontal" nocaption />
                  </animated.div>
                )}
              </Spring>
            );
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.first = false;
  }

  handleNavButtonClick = index => e => {
    e.preventDefault();
    this.props.onSlideChange(index);
  };
}

export default VerticalSlider;
