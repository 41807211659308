import React from 'react';

const range = number => {
  const result = [];
  for (let i = 0; i < number; i++) {
    result.push(i);
  }
  return result;
};

function Goo({ radius, slope, intercept, blur, gap, colors, duration, colorful }) {
  const ballX = number => {
    return 2 * radius * number + radius + gap * number;
  };

  const time = sections => keyframe => {
    return (1 / sections) * keyframe;
  };

  const animationRange = range(colors.length * 2 - 1);

  const animationTime = time((colors.length - 1) * 2);

  const times = animationRange
    .map(keyframe => animationTime(keyframe))
    .map(time => Math.floor(time * 100) / 100);
  const splines = animationRange.slice(1).map(() => '0.5 0 0.5 1');
  const size = colors.length - 1;
  const colorNumbers = animationRange.map(keyframe => size - Math.abs(size - keyframe));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="lds-gooey-balls"
      preserveAspectRatio="xMidYMid"
      viewBox={`0 0 ${2 * radius * 4 + gap * 3} ${2 * radius}`}
      shapeRendering="geometricPrecision"
    >
      <defs>
        <filter
          id="a"
          width="300%"
          height="300%"
          x="-100%"
          y="-100%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation={blur} />
          <feComponentTransfer result="cutoff">
            <feFuncA intercept={intercept} slope={slope} type="linear" />
          </feComponentTransfer>
        </filter>
      </defs>
      <g filter="url(#a)">
        {colors.map((color, index) => (
          <circle cx={ballX(index)} cy={radius} r={radius} fill={colorful ? color : '#f3f3f3'} />
        ))}
        <circle cx={ballX(0)} cy={radius} r={radius * 0.8} fill={colors[0]}>
          <animate
            attributeName="cx"
            begin="0s"
            calcMode="spline"
            dur={duration}
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            values={`${ballX(-0.168)};${ballX(size + 0.168)};${ballX(-0.168)}`}
          />
          <animate
            attributeName="fill"
            begin="0s"
            calcMode="spline"
            dur={duration}
            keySplines={splines.join(';')}
            keyTimes={times.join(';')}
            repeatCount="indefinite"
            values={colorNumbers.map(colorIndex => colors[colorIndex]).join(';')}
          />
        </circle>
      </g>
    </svg>
  );
}

export default Goo;
