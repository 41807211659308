export default {
  '184446': { id: 184446, name: '', title: 'Тонизирование', slug: '184446' },
  '184436': { id: 184436, name: '', title: 'Увлажнение', slug: '184436' },
  face_skin_type_problem: {
    id: 5,
    name: 'FACE_SKIN_TYPE/PROBLEM',
    title: 'Проблемная',
    slug: 'face_skin_type_problem'
  },
  face_skin_type_mixed: {
    id: 4,
    name: 'FACE_SKIN_TYPE/MIXED',
    title: 'Смешанная',
    slug: 'face_skin_type_mixed'
  },
  face_skin_type_dry: {
    id: 3,
    name: 'FACE_SKIN_TYPE/DRY',
    title: 'Сухая',
    slug: 'face_skin_type_dry'
  },
  face_skin_type_fatty: {
    id: 2,
    name: 'FACE_SKIN_TYPE/FATTY',
    title: 'Жирная',
    slug: 'face_skin_type_fatty'
  },
  face_skin_type_normal: {
    id: 1,
    name: 'FACE_SKIN_TYPE/NORMAL',
    title: 'Нормальная',
    slug: 'face_skin_type_normal'
  }
};
