import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './content.css';

const { string, bool } = PropTypes;

class Content extends Component {
  static propTypes = {
    html: string.isRequired,
    expanded: bool
  };

  render() {
    const { html, expanded, customTextProps } = this.props;

    return (
      <div
        className={cn('Content', {
          'Content--expanded': expanded,
          'Content--customTextProps': customTextProps
        })}
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    );
  }
}

export default Content;
