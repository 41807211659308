import { REHYDRATE, CHANGE_PAYMENT_LINK_EXPIRE_DATE } from './types';
import { createAction } from 'modules/utils/dux';

const rehydrate = lastOrder => {
  return createAction(REHYDRATE, lastOrder);
};

const setPaymentLinkExpireTime = amount => {
  return createAction(CHANGE_PAYMENT_LINK_EXPIRE_DATE, amount);
};

export { rehydrate, setPaymentLinkExpireTime };
