import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'modules/core/components/switch/switch';
import VolumeSwitchOption from './volume-switch-option';
import VolumeSwitchItem from './volume-switch-item';
import VolumeSwitchContainer from './volume-switch-container';
import './volume-switch.css';

class VolumeSwitch extends Component {
  static propTypes = {
    /**
     * получаем массив данных опций:
     * volume - значение
     * disabled - доступна ли эта опция в переключателе
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool
      }).isRequired
    ),
    /** событие по изменению (переключению) опции */
    onChange: PropTypes.func
  };

  render() {
    const { options, onChange } = this.props;

    return (
      <Switch
        switchHolder={<VolumeSwitchContainer />}
        optionHolder={<VolumeSwitchItem />}
        onChange={onChange}
      >
        {options.map((option, index) => this.renderOption(option, index))}
      </Switch>
    );
  }

  renderOption(option, index) {
    return (
      <Switch.Option key={index} disabled={option.disabled} value={option.id}>
        <VolumeSwitchOption volume={option.value} unit={option.payload.unit} />
      </Switch.Option>
    );
  }
}
export default VolumeSwitch;
