import React, { Component } from 'react';

class IconAlert extends Component {
  render() {
    return (
      <svg className="IconAlert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490">
        <path d="M268.953 368.339L289.9 108.632V0h-89.8v108.632l22.15 259.707zM203.326 421.483h83.349V490h-83.349z" />
      </svg>
    );
  }
}

export default IconAlert;
