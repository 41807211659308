import React, { Component } from 'react';
import './footer-copyright.css';
import Link from '../../link/short-link';

class CreatedBy extends Component {
  render() {
    return (
      <div className="CreatedBy">
        Сделано в{' '}
        <span className="CreatedBy-link">
          <Link href="https://prismsolutions.ru/">Prism Solutions</Link>
        </span>
      </div>
    );
  }
}

export default CreatedBy;
