import React, { Component } from 'react';
import './icon-category2.css';

class IconCategory2 extends Component {
  render() {
    return (
      <svg className="IconCategory2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M220,0 L20,0 C8.953,0 0,8.953 0,20 L0,220 C0,231.047 8.953,240 20,240 L220,240 C231.047,240 240,231.047 240,220 L240,20 C240,8.953 231.047,0 220,0 Z M200,200 L40,200 L40,40 L200,40 L200,200 Z M492,0 L292,0 C280.953,0 272,8.953 272,20 L272,220 C272,231.047 280.953,240 292,240 L492,240 C503.047,240 512,231.047 512,220 L512,20 C512,8.953 503.047,0 492,0 Z M472,200 L312,200 L312,40 L472,40 L472,200 Z M220,272 L20,272 C8.953,272 0,280.953 0,292 L0,492 C0,503.047 8.953,512 20,512 L220,512 C231.047,512 240,503.047 240,492 L240,292 C240,280.953 231.047,272 220,272 Z M200,472 L40,472 L40,312 L200,312 L200,472 Z M492,272 L292,272 C280.953,272 272,280.953 272,292 L272,492 C272,503.047 280.953,512 292,512 L492,512 C503.047,512 512,503.047 512,492 L512,292 C512,280.953 503.047,272 492,272 Z M472,472 L312,472 L312,312 L472,312 L472,472 Z"
          id="Shape"
        />
      </svg>
    );
  }
}

export default IconCategory2;
