import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromDeliveryForm from '../ducks/delivery-form';
import * as fromOrders from '../ducks/orders';
import * as fromBasket from '../ducks/basket/selectors';
import * as fromBasketProducts from '../ducks/basket-products';

import ProductOverview from '../components/product-overview/product-overview';

const mapStateToProps = createStructuredSelector({
  products: fromOrders.getGoodsListById,
  productsCost: fromOrders.getProductsCostById,
  productsCostWithDiscount: fromOrders.getProductsCostWithDiscount,
  deliveryCost: fromOrders.getDeliveryCostById,
  currentDeliveryCost: fromDeliveryForm.getDeliveryCost,
  method: fromDeliveryForm.getDeliveryMethod,
  totalCost: fromOrders.getTotalCostById,
  discount: fromOrders.getDiscountById,
  maxDeliveryPrice: fromBasket.getMaxDeliveryPrice,
  isDeliveryFreeByDeliveryCity: fromBasketProducts.getIsDeliveryFreeByDeliveryMethod
});

function changePromocode(promocode) {
  return {
    promocode
  };
}

// const FREE_DELIVERY_COST = 3000;

class OrderReceipt extends Component {
  state = {
    promocode: ''
  };

  render() {
    const {
      products,
      productsCost,
      // productsCostWithDiscount,
      // deliveryCost,
      discount,
      deliveryCost,
      currentDeliveryCost,
      maxDeliveryPrice,
      method,
      isDeliveryFreeByDeliveryCity
    } = this.props;

    const deliveryMethodExist = Boolean(method);

    const noRussia = method && method.isNoDeliveryRussia;

    // const deliveryPrice = noRussia ? price.current : maxDeliveryPrice === 0 ? 0 : price.current;
    // const deliveryPrice = noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost : 0;
    let deliveryPrice =
      noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost || deliveryCost : 0;
    // let deliveryPrice = noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost : 0;

    if (isDeliveryFreeByDeliveryCity && !noRussia) {
      deliveryPrice = 0;
    }

    const { promocode } = this.state;

    let estimatedTotalCost = this.getEstimatedTotalCost(noRussia);

    return (
      <ProductOverview
        productList={products}
        productsCost={productsCost}
        totalCost={estimatedTotalCost}
        // deliveryCost={productsCostWithDiscount >= FREE_DELIVERY_COST ? 0 : deliveryCost}
        // deliveryCost={maxDeliveryPrice === 0 ? 0 : deliveryCost}
        deliveryCost={deliveryPrice}
        estimatedDeliveryCost={currentDeliveryCost}
        discount={discount}
        promocode={promocode}
        onPromocodeChange={this.handlePromocodeChange}
        onPromocodeSubmit={() => console.warn('onPromocodeSubmit is not implemented')}
        deliveryMethodExist={deliveryMethodExist}
        method={method}
      />
    );
  }

  getEstimatedTotalCost(noRussia) {
    const {
      productsCost,
      deliveryCost,
      discount,
      totalCost,
      currentDeliveryCost,
      maxDeliveryPrice,
      isDeliveryFreeByDeliveryCity
    } = this.props;

    if (maxDeliveryPrice === 0 && !noRussia) {
      return totalCost - deliveryCost;
    }

    if (isDeliveryFreeByDeliveryCity && !noRussia) {
      return totalCost - deliveryCost;
    }

    const estimatedTotalCost = productsCost + (currentDeliveryCost || deliveryCost) - discount;

    return estimatedTotalCost;
  }

  handlePromocodeChange = value => {
    this.setState(changePromocode(value));
  };
}

export default connect(mapStateToProps)(OrderReceipt);
