import React, { Component } from 'react';

import AccordionItem from './accordion-item/accordion-item';
import findParentByTagName from './helpers/find-parent-by-tag-name';

import './accordion.css';

class ProductSpoiler extends Component {
  state = {
    visible: this.props.initialOpened
  };
  render() {
    const { children, anchor, ...rest } = this.props;
    const { visible } = this.state;
    return (
      <div className="Accordion-itemHolder">
        <AccordionItem
          clickableHeading
          visible={visible}
          // animatedCollapsing
          anchor={anchor}
          onVisibilityChange={this.handleVisibilityChange(anchor)}
          {...rest}
        />
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    window.addEventListener('click', this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
    window.removeEventListener('click', this.handleWindowClick);
  }

  toggle() {
    this.setState(state => ({
      visible: !state.visible
    }));
  }

  open() {
    this.setState({
      visible: true
    });
  }

  removeHash() {
    window.history.replaceState(
      '',
      document.title,
      window.location.pathname + window.location.search
    ); // FIXME: use react-router for this
  }

  setHash(anchor) {
    setTimeout(() => {
      window.location.hash = anchor;
    }, 50);
  }

  handleVisibilityChange = anchor => () => {
    if (anchor && !this.state.visible) {
      this.setHash(anchor);
    }
    this.toggle();
  };

  handleWindowScroll = () => {
    const { anchor } = this.props;
    if (window.location.hash === `#${anchor}`) {
      this.removeHash();
    }
  };

  handleWindowClick = ({ target }) => {
    const { anchor } = this.props;
    const anchorElement = findParentByTagName(target, 'A');

    if (anchorElement && anchorElement.hash === `#${anchor}`) {
      this.open();
    }
  };
}

export default ProductSpoiler;
