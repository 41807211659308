import TOASTS from '../types/toasts';
import { createAction } from 'modules/utils/dux';

export default {
  rehydrate(haveCookieToastBeenShowed) {
    return createAction(TOASTS.TOAST_REHYDRATE, {
      haveCookieToastBeenShowed
    });
  },

  acceptCookieToast() {
    return createAction(TOASTS.COOKIE_TOAST_ACCEPT);
  },

  closeCookieToast() {
    return createAction(TOASTS.COOKIE_TOAST_CLOSE);
  },

  acceptPasswordChangedToast() {
    return createAction(TOASTS.PASSWORD_CHANGE_TOAST_ACCEPT);
  },

  closePasswordChangedToast() {
    return createAction(TOASTS.PASSWORD_CHANGE_TOAST_CLOSE);
  }
};
