import React from 'react';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import './faq-action.css';

export default function FAQAction({ callback, icon, title, ...rest }) {
  return (
    <div className="FAQ_Action">
      <div className="FAQ_Action-title">{title}</div>
      <button onClick={callback} className="FAQ_Action-button" {...rest}>
        <span className="FAQ_Action-icon">
          <IconContainer size={'medium'}>{icon}</IconContainer>
        </span>
      </button>
    </div>
  );
}
