import React, { Component } from 'react';
import OrderSummary from '../components/order-summary/order-summary';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromOrders from '../ducks/orders';
import * as fromBasket from '../ducks/basket/selectors';
import * as fromDeliveryForm from '../ducks/delivery-form';
import * as fromBasketProducts from '../ducks/basket-products';

const mapState = createStructuredSelector({
  // productsCost: fromOrders.getProductsCostWithDiscount,
  productsCost: fromOrders.getProductsCostById,
  deliveryCost: fromOrders.getDeliveryCostById,
  currentDeliveryCost: fromDeliveryForm.getDeliveryCost,
  totalCost: fromOrders.getTotalCostById,
  method: fromDeliveryForm.getDeliveryMethod,
  discount: fromOrders.getDiscountById,
  maxDeliveryPrice: fromBasket.getMaxDeliveryPrice,
  isDeliveryFreeByDeliveryCity: fromBasketProducts.getIsDeliveryFreeByDeliveryMethod
});

class OrderSummaryContainer extends Component {
  render() {
    const {
      productsCost,
      method,
      deliveryCost,
      // totalCost,
      discount,
      currentDeliveryCost,
      maxDeliveryPrice,
      isDeliveryFreeByDeliveryCity
    } = this.props;
    const noRussia = method && method.isNoDeliveryRussia;

    let estimatedTotalCost = this.getEstimatedTotalCost(noRussia);

    let deliveryPrice =
      noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost || deliveryCost : 0;

    if (isDeliveryFreeByDeliveryCity && !noRussia) {
      deliveryPrice = 0;
    }

    const deliveryMethodExist = Boolean(method);

    return (
      <OrderSummary
        productsCost={productsCost}
        // deliveryCost={deliveryCost}
        deliveryCost={deliveryPrice}
        estimatedDeliveryCost={currentDeliveryCost}
        // totalCost={totalCost}
        totalCost={estimatedTotalCost}
        discount={discount}
        deliveryMethodExist={deliveryMethodExist}
        method={method}
      />
    );
  }

  getEstimatedTotalCost(noRussia) {
    const {
      productsCost,
      deliveryCost,
      discount,
      totalCost,
      currentDeliveryCost,
      maxDeliveryPrice,
      isDeliveryFreeByDeliveryCity
    } = this.props;

    if (maxDeliveryPrice === 0 && !noRussia) {
      return totalCost;
    }

    if (isDeliveryFreeByDeliveryCity && !noRussia) {
      return totalCost;
    }

    const estimatedTotalCost = productsCost + (currentDeliveryCost || deliveryCost) - discount;

    return estimatedTotalCost;
  }
}

export default connect(mapState)(OrderSummaryContainer);
