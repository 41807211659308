import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';

import Dialog from 'modules/core/components/dialog/dialog';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
import Textarea from 'modules/form/components/textarea/textarea';
// import ReactPhoneInput from 'modules/form/components/react-phone-input/react-phone-input';
import Input from 'modules/form/components/input/input';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';
import Link from 'modules/core/components/link/short-link';
import Checkbox from 'modules/form/components/checkbox/checkbox';
import plural from 'modules/utils/plural';

import InputPhoneWithAreaCode from './../input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from './../input-phone-with-area-code/area-codes';

import Button from '../button/button';

import './faq-dialog.css';

// const phoneTemplateLength = ' (xxx) xxx xxxx'.length;

class FAQDialog extends Component {
  state = {
    phone: this.props.profilePhone || ''
  };

  render() {
    const { profileFirstName, profilePhone, profileEmail, ...rest } = this.props;
    const { phone } = this.state;

    const initialValues = {
      firstName: profileFirstName || '',
      phone: profilePhone || '',
      email: profileEmail || '',
      question:"",
      confirm: false
    };

    const phoneLength =
      phone && phone.length && getSeparatedPhone(phone, areaCodes).code
        ? getSeparatedPhone(phone, areaCodes).code.phoneLength
        : areaCodes[0].phoneLength;

    const EMAIL_REQUIRED_ERROR = 'Введите свой email';
    const QUESTION_REQUIRED_ERROR = 'Напиши вопрос';
    const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';
    const NAME_REQUIRED_ERROR = 'Укажите Ваше имя';
    const PHONE_REQUIRED_ERROR = 'Укажите Ваш номер телефона';
    const PHONE_SHORT_ERROR = `Номер должен содержать ${phoneLength} цифр, не хватает ${phoneLength -
      this.state.phone.length +
      1}${plural(phoneLength - this.state.phone.length + 1, ['-й', '-x', '-ти'])}`;
    const CONFIRM_ERROR = 'Поле дожно быть выбранно';

    const credentialsSchema = Yup.object().shape({
      phone: Yup.string()
        .required(PHONE_REQUIRED_ERROR)
        .min(phoneLength, PHONE_SHORT_ERROR),
      firstName: Yup.string()
        .trim()
        .required(NAME_REQUIRED_ERROR),
      confirm: Yup.bool().oneOf([true], CONFIRM_ERROR),
      email: Yup.string()
        .required(EMAIL_REQUIRED_ERROR)
        .email(EMAIL_FORMAT_ERROR),
      question: Yup.string()
        .required(QUESTION_REQUIRED_ERROR)
    });

    return (
      <Dialog closeable overflowVisible {...rest}>
        <Formik
          validationSchema={credentialsSchema}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid
            } = props;
            return (
              <div className="CallbackDialog">
                <h1 className="CallbackDialog-title">Остались вопросы, напиши нам</h1>
                <p className="CallbackDialog-info">
                  Задай вопрос, и мы с Тобой обязательно свяжемся в ближайшее время!
                </p>
                {/* <p className="CallbackDialog-warningInfo">
                  Внимание!!! В связи с повышенной нагрузкой время обработки заказов составляет 24
                  часа. Приносим свои извинения.
                </p> */}

                <div className="CallbackDialog-form">
                  <Form onSubmit={handleSubmit}>
                    <Media query="(max-width: 840px)">
                      {isMobile => (
                        <Fragment>
                          <Form.LabeledRow>
                            <Field
                              label="Имя"
                              error={touched.firstName && errors.firstName}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="firstName"
                                value={values.firstName}
                                state={touched.firstName && errors.firstName && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                // required
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field
                              label="Email"
                              error={touched.email && errors.email}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="email"
                                value={values.email}
                                state={touched.email && errors.email && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                // required
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field
                              label="Телефон"
                              error={touched.phone && errors.phone}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <FormikField
                                name="phone"
                                render={({ field, form }) => {
                                  return (
                                    <>
                                      <InputPhoneWithAreaCode
                                        name={field.name}
                                        codeList={areaCodes}
                                        value={field.value}
                                        size={isMobile ? 'small' : 'normal'}
                                        // value="+380506848618"
                                        onChange={this.handlePhoneChange(form)(field.name)}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field label="Вопрос"
                              error={touched.question && errors.question}
                              size={isMobile ? 'small' : 'normal'}>
                              <Textarea
                                opened
                                name="question"
                                value={values.question}
                                state={touched.question && errors.question && 'error'}
                                id="question"
                                // required
                                placeholder="Общее впечатление от использования товара"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.Row>
                            <Field
                              // label="Submit"
                              error={touched.confirm && errors.confirm}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <div className="CallbackDialog-submitField">
                                <div className="CallbackDialog-checkSubmit">
                                  <FormikField
                                    name="confirm"
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        label={this.renderSubmitLable()}
                                        value={field.value}
                                        checked={field.value}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </Field>
                          </Form.Row>
                          <Form.Row>
                            <Field>
                              <div className="CallbackDialog-submit">
                                <Button
                                  title="Отправить"
                                  variant="primary"
                                  type="submit"
                                  size={isMobile ? 'small' : 'normal'}
                                  visualyDisabled={!isValid}
                                  expanded
                                />
                              </div>
                            </Field>
                          </Form.Row>

                        </Fragment>
                      )}
                    </Media>
                  </Form>
                </div>
              </div>
            );
          }}
        />
      </Dialog>
    );
  }

  renderSubmitLable() {
    return (
      <>
        <span>Я согласен(-на) на обработку персональных данных</span>{' '}
        <Link href="https://mixit.ru/upload/personal_data.pdf" target="_blank">
          персональных данных
        </Link>
      </>
    );
  }

  setPhone(phone) {
    this.setState({ phone: '+' + phone });
  }

  handleSubmit = data => {
    const { onClose, request } = this.props;

    const { phone } = this.state;

    const requestData = {
      name: data.firstName,
      phone,
      email: data.email,
      question: data.question,
    };

    request(requestData);
    onClose();
  };

  handlePhoneChange = form => name => value => {
    form.setFieldValue(name, value);
    this.setPhone(value);
  };
}

export default FAQDialog;
