import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromProfile from '../ducks/profile';

import OrderListScene from '../components/order-scene/order-scene';

const mapState = createStructuredSelector({
  isLoading: fromProfile.getIsLoading,
  isReady: fromProfile.getReady,
  orders: fromProfile.getOrderList
});

class OrderListSceneContainer extends Component {
  state = {
    // _orders: orders,
    activeFilter: -1
  };

  render() {
    const { activeFilter } = this.state;
    const { orders, isReady } = this.props;
    const isOrdersExist = orders && !!orders.length;

    return (
      <OrderListScene
        orders={orders}
        isLoading={this.props.isLoading}
        isReady={isReady}
        activeFilter={activeFilter}
        isOrdersExist={isOrdersExist}
        onFilterChange={this.handleFilterChange}
      />
    );
  }

  setActiveFilter(activeFilter) {
    this.setState({
      activeFilter
    });
  }

  handleFilterChange = id => {
    this.setActiveFilter(id);
  };
}

export default connect(mapState)(OrderListSceneContainer);
