import { put, takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { BASKET_PREVIEW_OPEN } from '../ducks/basket-preview/types';
import * as fromBasket from 'modules/ordering/ducks/basket';
import { getIsBasketPreviewClosed } from '../ducks/basket-preview/reducer';

function* onLocationChange() {
  const productsAmount = yield select(fromBasket.getProductsAmount);
  const isBasketPreviewClosed = yield select(getIsBasketPreviewClosed);
  if (!!productsAmount && !isBasketPreviewClosed) {
    yield put({
      type: BASKET_PREVIEW_OPEN
    });
  }
}

export function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, onLocationChange);
}
