import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-media';

import Media from 'modules/core/components/media/media';

import { getProductLink } from 'routes/links';

import Price from 'modules/ordering/components/price-formatted/price-formatted';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

import productShape from '../../prop-types/product-shape';
// import ButtonAddToBasketText from 'modules/product/components/product/button-add-to-pre-order';
import Button from 'modules/core/components/button/button';

import './product-set-card.css';

const { number } = PropTypes;

class ProductSetCard extends Component {
  static propTypes = {
    set: productShape.isRequired,

    /** Количество колонок, на которое растягивается набор */
    col: number
  };

  render() {
    const {
      set,
      col,
      slug,
      listId,
      allwaysSetBackground,
      isWideMedia,
      withAddToBasket,
      transparentBackground
    } = this.props;
    // console.log("ProductSetCard -> render -> set", set)

    if (!set) {
      return null;
    }

    return (
      <ProductAnalyticsProvider
        productId={slug}
        listId={listId}
        render={({ productRef, handleClick }) => (
          <MediaQuery query="(max-width: 840px)">
            {isMobile => (
              <div
                className={classNames('ProductSetCard', {
                  'ProductSetCard--2x': col === 2,
                  'ProductSetCard--allwaysSetBackground': allwaysSetBackground,
                  'ProductSetCard--isWideMedia': isWideMedia,
                  'ProductSetCard--transparentBackground': transparentBackground
                })}
                ref={productRef}
              >
                <div className="ProductSetCard-imageHolder">
                  <div className="ProductSetCard-image">
                    <Media
                      media={set.announcementMedia}
                      // resizeWidth={col === 2 ? 480 : 240}
                      resizeWidth={500}
                      stretch="horizontal"
                      nocaption
                      lazy
                    />
                  </div>
                </div>

                <div className="ProductSetCard-contentHolder">
                  <div className="ProductSetCard-titleHolder">
                    <h3 className="ProductSetCard-title">
                      <Link
                        to={getProductLink(set)}
                        className="ProductSetCard-link"
                        onClick={handleClick()}
                      >
                        {set.title}
                      </Link>
                    </h3>
                  </div>
                  <div className="ProductSetCard-priceHolder">{this.renderPrice()}</div>
                  {withAddToBasket && (
                    <div className="ProductSetCard-addToBasket">
                      <Button
                        title={set.isInBasket ? 'Добавить еще' : 'Купить'}
                        size={isMobile ? 'small' : 'normal'}
                        variant="primary"
                        expanded={isMobile}
                        disabled={set.isGift || set.isBasketFetchin}
                        onClick={this.handleAddToBasket(!isMobile)}
                      />
                      {/* <ButtonAddToBasketText
                        isInBasket={set.isInBasket}
                        disabled={set.isGift || set.isBasketFetching}
                        // onClick={addProductToBasket}
                        onClick={this.handleAddToBasket(!isMobile)}
                        variant="primary"
                      /> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </MediaQuery>
        )}
      />
    );
  }

  createToast(isDesktop) {
    const { set, createToast } = this.props;

    const delay = isDesktop ? 3000 : 1500;

    let toast = {
      id: `${set.slug}-ordering-added-${Math.random()}`,
      title: `${set.title} добавлен в корзину!`,
      state: 'success',
      delay
    };

    const desktopToastParam = {
      content: set.description,
      actionList: [
        {
          title: 'Перейти в корзину',
          type: 'ACTION_GO_BASKET',
          variant: 'primary'
        }
      ]
    };

    if (isDesktop) {
      toast = { ...toast, ...desktopToastParam };
    }

    createToast({
      toast: toast,
      onActionTrigger: this.handleToastAction
    });
  }

  handleAddToBasket = isDesktop => e => {
    const { set, addProductToBasket } = this.props;

    e.preventDefault();

    this.createToast(isDesktop);
    addProductToBasket(set);
  };

  renderPrice() {
    const { set } = this.props;

    return (
      <Fragment>
        {set.hasDiscount && (
          <span className="ProductSetCard-previousPrice">
            <Price number={set.price.default} variant="previous" />
          </span>
        )}
        <span className="ProductSetCard-currentPrice">
          <Price number={set.price.current} />
        </span>
      </Fragment>
    );
  }
}

export default ProductSetCard;
