import React, { Component } from 'react';
// import classNames from 'classnames';

import Svg from '../svg/svg';

import './icon-heart.css';

class IconHeart extends Component {
  render() {
    const { active } = this.props;
    const size = this.getSize();

    if (active) {
      switch (size) {
        case 'xsmall':
          return (
            <Svg viewBox="0 0 16 16" className="IconHeart">
              <path d="M8 14c-.1 0-.2 0-.3-.1L1.8 8.2A4 4 0 0 1 1 5.9C1 3.7 2.8 2 5.1 2A4 4 0 0 1 8 3.2 4 4 0 0 1 10.9 2C13.2 2 15 3.7 15 5.9a4 4 0 0 1-.8 2.3l-.3.3-5.6 5.3c-.1.2-.2.2-.3.2z" />
            </Svg>
          );
        case 'small':
          return (
            <Svg viewBox="0 0 24 24" className="IconHeart">
              <path d="M12 19.7c-.1 0-.2 0-.3-.1l-7.2-6.9-.5-.5c-.7-.9-1-1.9-1-3 0-2.7 2.3-4.9 5.2-4.9 1.5 0 2.8.6 3.8 1.6 1-1 2.3-1.6 3.8-1.6 2.9 0 5.2 2.2 5.2 4.9 0 1.1-.4 2.1-1 2.9l-.4.4-7.3 6.9c-.1.3-.2.3-.3.3z" />
            </Svg>
          );
        case 'medium':
        case 'mediumPlus':
        case 'large':
        case 'xlarge':
          return (
            <Svg viewBox="0 0 36 36" className="IconHeart">
              <path d="M18 29c-.2 0-.3-.1-.4-.2l-10.5-10-.6-.6C5.5 17 5 15.5 5 14c0-3.9 3.4-7 7.5-7 2.1 0 4.1.8 5.5 2.3C19.4 7.8 21.4 7 23.5 7c4.1 0 7.5 3.2 7.5 7 0 1.5-.5 2.9-1.5 4.2l-.5.6-10.6 10c-.1.1-.2.2-.4.2z" />
            </Svg>
          );
        default:
      }
    }

    switch (size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconHeart">
            <path d="M8 14c-.1 0-.2 0-.3-.1L1.8 8.2A4 4 0 0 1 1 5.9C1 3.7 2.8 2 5.1 2A4 4 0 0 1 8 3.2 4 4 0 0 1 10.9 2C13.2 2 15 3.7 15 5.9a4 4 0 0 1-.8 2.3l-.3.3-5.6 5.3c-.1.2-.2.2-.3.2zM5.1 2.9c-1.7 0-3.2 1.3-3.2 3 0 .6.2 1.2.6 1.7l.3.3 5.2 5 5.5-5.3c.4-.5.6-1.1.6-1.7 0-1.6-1.4-3-3.2-3-1 0-1.9.4-2.5 1.2-.2.2-.6.2-.7 0-.7-.7-1.6-1.2-2.6-1.2zm2.9 1z" />
          </Svg>
        );
      case 'small':
        return (
          <Svg viewBox="0 0 24 24" className="IconHeart">
            <path d="M12 20c-.1 0-.3 0-.3-.1l-7.4-7.2-.4-.5c-.7-.9-1.1-2-1.1-3.1C2.8 6.3 5.1 4 8.1 4c1.5 0 2.9.6 3.9 1.6 1-1 2.4-1.6 3.9-1.6 2.9 0 5.3 2.3 5.3 5.1 0 1.1-.4 2.1-1 3l-.4.5-7.5 7.2c0 .2-.2.2-.3.2zM8.1 5C5.7 5 3.8 6.9 3.8 9.1c0 .9.3 1.7.8 2.4l.3.5 7.1 6.8 7.1-6.9.3-.4c.5-.7.8-1.5.8-2.4 0-2.3-2-4.1-4.3-4.1-1.4 0-2.7.6-3.5 1.7-.2.2-.6.2-.8 0C10.8 5.6 9.5 5 8.1 5zM12 6.4z" />
          </Svg>
        );
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconHeart">
            <path d="M18 29c-.2 0-.3-.1-.4-.2L7.1 18.9l-.6-.6C5.5 17 5 15.5 5 14c0-3.9 3.4-7 7.5-7 2.1 0 4.1.8 5.5 2.3C19.4 7.8 21.4 7 23.5 7c4.1 0 7.5 3.2 7.5 7 0 1.5-.5 3-1.5 4.2l-.5.6-10.5 10c-.2.1-.3.2-.5.2zM12.5 8.3c-3.4 0-6.2 2.6-6.2 5.8 0 1.2.4 2.4 1.2 3.4l.5.5 10 9.5L28.1 18l.5-.6c.8-1 1.2-2.1 1.2-3.4 0-3.2-2.8-5.8-6.2-5.8-2 0-3.8.9-5 2.4-.2.3-.8.3-1 0a6.96 6.96 0 0 0-5.1-2.3zm5.5 1.9z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }
    return size;
  }
}

export default IconHeart;
