import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  IconMaster,
  IconVisa,
  IconGooglePay,
  IconApplePay,
  IconCardWorld
} from 'modules/core/components/icons';

import './order-step.css';

const { string, bool, number } = PropTypes;

class OrderStep extends Component {
  static propTypes = {
    stepNumber: number.isRequired,
    title: string.isRequired,
    isActive: bool
  };

  static defaultProps = {
    isActive: false
  };

  render() {
    const { isActive, title, stepNumber, children } = this.props;

    return (
      <div
        className={classNames('OrderStep', {
          'OrderStep--active': isActive
        })}
      >
        <div className="OrderStep-titleRow">
          <h3 className="OrderStep-title">
            <span className="OrderStep-titleMark">
              <span className="OrderStep-stepNumber">{stepNumber}.</span>
            </span>
            <span className="OrderStep-titleString">{title}</span>
          </h3>

          {stepNumber === 3 && (
            <div className="OrderStep-paymentMethods">
              <div className="OrderStep-IconCardWorldContainer">
                <IconCardWorld opacity={0.4} />
              </div>

              <div className="OrderStep-IconApplePayContainer">
                <IconApplePay opacity={0.4} />
              </div>

              <div className="OrderStep-IconGooglePayContainer">
                <IconGooglePay opacity={0.4} />
              </div>

              <div className="OrderStep-IconMasterContainer">
                <IconMaster opacity={0.4} />
              </div>

              <div className="OrderStep-IconVisaContainer">
                <IconVisa opacity={0.4} />
              </div>
            </div>
          )}
        </div>
        {this.isActive() && <div className="OrderStep-content">{children}</div>}
      </div>
    );
  }

  isActive() {
    const { isActive } = this.props;
    return isActive;
  }
}

export default OrderStep;
