import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Button from 'modules/core/components/button/button';
import { IconReload } from 'modules/core/components/icons/index';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import isFunction from 'modules/utils/is-function';

import articleShape from '../../prop-types/article-shape';
// import VideoPlaylistSection from '../../containers/video-playlist-section';
import ArticleSection from '../article-section/article-section';
// import SurveyCall from '../survey-call/survey-call';
import ArticleList from '../article-list/article-list';

import './latest-articles-section.css';

// const ARTICLES_BEFORE_VIDEO = 3;

const { arrayOf, func } = PropTypes;

class LatestArticlesSection extends Component {
  static propTypes = {
    articleList: arrayOf(articleShape),
    onLoadMore: func
  };

  render() {
    const { articleList, onLoadMore, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    // const articlesListBeforeVideo = articleList.slice(0, ARTICLES_BEFORE_VIDEO);
    // const articlesListAfterVideo = articleList.slice(ARTICLES_BEFORE_VIDEO);

    return (
      <SectionDefault title="Последние публикации">
        <div className="LatestArticlesSection">
          <div className="LatestArticlesSection-item">
            <ArticleSection
            // side={<SurveyCall id={15} title="Какой твой тип кожи?" duration="PT3M" />}
            >
              <ArticleList articleList={articleList} />
            </ArticleSection>
          </div>

          {/* <div className="LatestArticlesSection-item">
            <VideoPlaylistSection title="Популярные видео на YouTube" />
          </div> */}

          {/* {articlesListAfterVideo.length >= 1 && (
            <div className="LatestArticlesSection-item">
              <ArticleSection
                side={
                  <SurveyCall
                    id={17}
                    title="Какой твой тип кожи?"
                    duration="PT5M"
                  />
                }
              >
                <ArticleList articleList={articlesListAfterVideo} />
              </ArticleSection>
            </div>
          )} */}

          {onLoadMore && (
            <div className="LatestArticlesSection-showMore">
              <Button
                onClick={this.handleClick}
                title="Показать еще"
                variant="primary"
                iconPrepend={
                  <IconContainer>
                    <IconReload />
                  </IconContainer>
                }
              />
            </div>
          )}
        </div>
      </SectionDefault>
    );
  }

  handleClick = () => {
    const { onLoadMore } = this.props;

    if (!isFunction(onLoadMore)) {
      return;
    }
    onLoadMore();
  };
}

export default LatestArticlesSection;
