// import { combineReducers } from 'redux';

import landings from './landings';

// const reducers = {
//   landings
// };

// export const reducer = combineReducers(reducers);

export default landings;
