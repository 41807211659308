import React, { PureComponent } from 'react';

import './product-navigation.css';
import classNames from 'classnames';
import Tabs from 'modules/core/components/tabs/tabs';
import TabsItem from 'modules/core/components/tabs/tabs-item';
import equal from 'fast-deep-equal';

const NAV_HEIGHT = 70;

class ProductNavigation extends PureComponent {
  shouldComponentUpdate(props) {
    return (
      !equal(this.props.style, props.style) ||
      this.props.details !== props.details ||
      this.props.isSticky !== props.isSticky ||
      this.props.wasSticky !== props.wasSticky
    );
  }
  render() {
    const {
      style,
      isSticky,
      wasSticky,
      details
      // product
    } = this.props;
    // const reviewsCount = product.rating.reviews_count;

    const isDefaultActive = this.getIsDefaultActive();

    return (
      <div
        className={classNames('ProductNavigation-fullContainer', {
          'ProductNavigation-fullContainer--isSticky': isSticky,
          'ProductNavigation-fullContainer--wasSticky': wasSticky
        })}
        style={style}
      >
        <div className="ProductNavigation-container">
          <nav className="ProductNavigation-nav">
            <Tabs nav>
              {details.about && (
                <TabsItem
                  name="about"
                  offset={-NAV_HEIGHT}
                  title="О продукте"
                  defaultActive={isDefaultActive}
                />
              )}

              {details.consistency && (
                <TabsItem name="consistency" offset={-NAV_HEIGHT} title="Состав" />
              )}

              {details.usage && <TabsItem name="usage" offset={-NAV_HEIGHT} title="Применение" />}

              {details.storageConditions && (
                <TabsItem name="productStorage" offset={-NAV_HEIGHT} title="Хранение" />
              )}
              {/* {details.reviews && <TabsItem name="reviews" offset={-NAV_HEIGHT} title={`Отзывы (${Boolean(reviewsCount) ? reviewsCount : ""})`} />} */}
              {/* {details.reviews && (
                <TabsItem
                  name="reviews"
                  offset={-NAV_HEIGHT}
                  title={`Отзывы ${Boolean(reviewsCount) ? `(${reviewsCount})` : ''}`}
                />
              )} */}
            </Tabs>
          </nav>
        </div>
      </div>
    );
  }

  getIsDefaultActive() {
    const { isSticky } = this.props;

    return !isSticky;
  }
}

export default ProductNavigation;
