import React, { Component } from 'react';
import './product-code.css';
import PropTypes from 'prop-types';

class ProductCode extends Component {
  static propTypes = {
    /**
     *
     */
    sku: PropTypes.string.isRequired
  };
  render() {
    const { sku } = this.props;
    return (
      <div className="ProductCode">
        <span className="ProductCode-title">Код товара:</span>
        <span className="ProductCode-code" itemprop="sku">
          {sku}
        </span>
      </div>
    );
  }
}

export default ProductCode;
