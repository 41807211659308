import { takeEvery, put, call, select } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import * as BannerResponse from 'services/mixit/adapters/banners-response-adapter';
import { TYPES as BANNER } from '../ducks/banner';
import { Actions as Banner } from '../ducks/banner';
import * as fromBanner from '../ducks/banner';

export function* bannerWatcher() {
  yield takeEvery(BANNER.REQUEST, handleRequest);
}

function* handleRequest() {
  try {
    const banners = yield select(fromBanner.getList);
    if (banners && banners.length) {
      yield put(Banner.cancelRequest());
      return;
    }
    const response = yield call(mixitApi().banners.list, { request_source: 'site' });

    const bannerResponse = BannerResponse.normalize(response);

    yield put(Banner.response(bannerResponse.result.data, bannerResponse.entities));
  } catch (e) {
    console.error(e);
  }
}
