import React, { Component } from 'react';

import './icon-sign-arrow-right.css';

class IconSignArrowRight extends Component {
  render() {
    return (
      <svg className="IconSignArrowLeft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M28.7 18H4M20.7 28.3L32 18 20.7 7.7"
        />
      </svg>
    );
  }
}

export default IconSignArrowRight;
