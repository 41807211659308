const numberExpression = '\\d+(?:[.,]\\d+)?';

const yearExpression = `(?:(${numberExpression})Y)?`;
const monthExpression = `(?:(${numberExpression})M)?`;
const dayExpression = `(?:(${numberExpression})D)?`;

const hoursExpression = `(?:(${numberExpression})H)?`;
const minutesExpression = `(?:(${numberExpression})M)?`;
const secondsExpression = `(?:(${numberExpression})S)?`;

const durationExpression = new RegExp(
  `^P${yearExpression}${monthExpression}${dayExpression}(?:T${hoursExpression}${minutesExpression}${secondsExpression})?$`
);

export default function parseDuration(duration) {
  if (typeof duration !== 'string') {
    return null;
  }

  const matchResult = duration.match(durationExpression);

  if (!matchResult) {
    return null;
  }

  const matchTimeNumbers = matchResult.map(stringToNumber);

  if (!matchTimeNumbers.some(Boolean)) {
    return null;
  }

  const [, year, month, day, hour, minute, second] = matchTimeNumbers;

  return {
    year,
    month,
    day,
    hour,
    minute,
    second
  };
}

const stringToNumber = timeString => {
  if (!timeString) {
    return null;
  }

  const parsed = parseInt(timeString.replace(',', '.'), 10);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
};
