import PropTypes from 'prop-types';

const { string, number, shape, arrayOf } = PropTypes;

export default shape({
  address: string.isRequired,
  city: string.isRequired,
  street: string.isRequired,
  building: string.isRequired,
  apartment: string.isRequired,
  coordinates: shape({
    lat: number,
    lng: number,
    latlng: arrayOf(number)
  })
});
