import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PickPointPlacemark from '../components/pick-point-map/placemark';

import * as fromPickPointList from '../ducks/pick-point-list';

const mapStateToProps = createStructuredSelector({
  pickPoint: fromPickPointList.getPickPoint
});

class PickPointPlacemarkContainer extends Component {
  render() {
    return (
      <PickPointPlacemark {...this.props} onClick={this.handleClick} onSelect={this.handleSelect} />
    );
  }

  handleClick = () => {
    const { onClick, pickPoint } = this.props;

    onClick(pickPoint);
  };

  handleSelect = () => {
    const { onSelect, pickPoint } = this.props;

    onSelect(pickPoint);
  };
}

export default connect(mapStateToProps)(PickPointPlacemarkContainer);
