import { combineReducers } from 'redux';

import banner from './banner';
import routerHistory from './router-history';
import media from './media';
import dialogs from './dialogs';
import location from './location';
import account from './account';
import shopList from './shop-list';
import recoveryPassword from './recovery-password';
import subscribe from './subscribe';
import waitingList from './waiting-list';
import utmBanner from './utm-banner';
import toasts from './toasts';
import feedback from './feedback';
import emailFeedback from './email-feedback';
import navigations from './navigations';
import abtestmarkers from './a-b-test-markers';
import faq from './faq';
import drawRollUp from './draw-roll-up';

import * as fromAccount from './account';

export const MODULE_NAME = 'core';
export const rootSelector = state => state[MODULE_NAME];

const reducers = {
  banner,
  media,
  dialogs,
  location,
  account,
  shopList,
  recoveryPassword,
  subscribe,
  waitingList,
  utmBanner,
  toasts,
  feedback,
  emailFeedback,
  navigations,
  routerHistory,
  abtestmarkers,
  faq,
  drawRollUp
};

export const reducer = combineReducers(reducers);

export const getAuthorization = (state, ...props) => fromAccount.getAuthorization(state, ...props);

export default reducer;
