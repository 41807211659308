import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-angle-arrow-down.css';

class IconAngleArrowDown extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
      default:
        return (
          <Svg viewBox="0 0 24 24" className="IconAngleArrowDown">
            <path d="M23.3 6.7l-1.2-1.2c-.2-.2-.3-.2-.5-.2s-.4.1-.5.2L12 14.6 2.9 5.5c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2L.7 6.7c-.1.1-.2.3-.2.5s.1.4.2.5l10.7 10.7c.2.2.3.2.5.2s.4-.1.5-.2L23.3 7.7c.2-.2.2-.3.2-.5s-.1-.4-.2-.5z" />
          </Svg>
        );
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconAngleArrowDown;
