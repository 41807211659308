import React, { Component } from 'react';
// import cn from 'classnames';
import { Link } from 'react-router-dom';

import Button from 'modules/core/components/button/button';
import { getFeedbackLink } from 'routes/links';

import './feedback-call.css';

class FeedbackCall extends Component {
  render() {
    return (
      <div className="FeedbackCall">
        <div className="FeedbackCall-title">Помоги нам стать лучше!</div>

        <div className="FeedbackCall-action">
          <Button
            title="Сообщить о проблеме"
            target="_blank"
            component={Link}
            to={getFeedbackLink()}
            variant="accent"
            // expanded={isMobile}
            // disabled={isGift}
          />
        </div>
      </div>
    );
  }
}

export default FeedbackCall;
