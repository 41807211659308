import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import textSlicer from 'modules/utils/text-slicer';
import dateToString from 'modules/utils/date-string';

import articleShape from '../../prop-types/article-shape';
import { getArticleLink } from 'routes/links';

import './article-card.css';

class ArticleCard extends Component {
  static propTypes = {
    article: articleShape,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
  };

  static defaultProps = {
    size: 'large'
  };

  render() {
    const { article, size } = this.props;
    const { title, createDate, cover } = article;

    const style = { backgroundImage: `url(${cover.sources[0].main.normal})` };
    return (
      <div className={cn('ArticleCard', `ArticleCard--${size}`)}>
        <div className="ArticleCard-backgroundHolder">
          <div className="ArticleCard-background" style={style} />
        </div>

        <div className="ArticleCard-content">
          {size === 'large' && (
            <div className="ArticleCard-dateHolder">
              <time dateTime={createDate} className="ArticleCard-date">
                {dateToString(createDate)}
              </time>
            </div>
          )}
          <h2 className="ArticleCard-title">
            <Link title={title} to={getArticleLink(article)} className="ArticleCard-link">
              {textSlicer(title, 120)}
            </Link>
          </h2>
        </div>
      </div>
    );
  }
}

export default ArticleCard;
