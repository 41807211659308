import React, { Component } from 'react';
import './icon-telegram.css';

class IconTelegram extends Component {
  render() {
    return (
      <svg viewBox="0 0 34 30" xmlns="http://www.w3.org/2000/svg" className="IconTelegram">
        <path d="M13.34 19.585l-.562 8.143c.805 0 1.153-.355 1.571-.783l3.773-3.711 7.817 5.893c1.434.823 2.444.39 2.83-1.358l5.132-24.75.001-.002c.455-2.182-.766-3.035-2.163-2.5L1.579 12.404c-2.06.823-2.028 2.004-.35 2.54l7.71 2.468L26.85 5.875c.843-.574 1.61-.256.979.318L13.341 19.585z" />
      </svg>
    );
  }
}

export default IconTelegram;
