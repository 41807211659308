import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AnimatedButtonLink from 'modules/core/components/animated-link/animated-button-link';
import { IconLocation } from 'modules/core/components/icons';

import CitySelectDropdown from '../city-select-dropdown/city-select-dropdown';

const { func } = PropTypes;

class CurrentCity extends Component {
  static propTypes = {
    onDropdownChange: func.isRequired
  };

  render() {
    const { isShowed, onDropdownChange, ...rest } = this.props;

    return (
      <Fragment>
        {!rest.hadChosen && rest.suggested ? (
          <CitySelectDropdown
            show={isShowed}
            onChange={onDropdownChange}
            trigger={<AnimatedButtonLink icon={<IconLocation />} title={this.getCityTitle()} />}
            {...rest}
          />
        ) : (
          <AnimatedButtonLink icon={<IconLocation />} title={this.getCityTitle()} {...rest} />
        )}
      </Fragment>
    );
  }

  getCityTitle() {
    const { current } = this.props;

    if (!current || !current.title) {
      return 'Выберите город';
    }

    return current.title;
  }
}

export default CurrentCity;
