import { normalize as _normalize } from 'normalizr';
import SearchSchema from './schemas/search-schema';
import CategorySchema from './schemas/category-schema';
import FilterSchema from './schemas/filter-schema';
import FilterOptionSchema from './schemas/filter-option-schema';
import ProductSchema from './schemas/product-schema';

export function normalize(apiSearchResponse) {
  const category = CategorySchema();
  const filterOption = FilterOptionSchema();
  const filter = FilterSchema({ filterOption });
  const product = ProductSchema({ category, filterOption });
  const search = SearchSchema({ category, filter, product });

  const {
    result: { total_amount, products, filters },
    entities
  } = _normalize(apiSearchResponse, search);

  return {
    result: {
      products,
      filters,
      totalAmount: total_amount
    },
    entities
  };
}
