import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import isFunction from 'modules/utils/is-function';

import getMask from './helpers/get-mask';

import './input-code.css';

const { number, string } = PropTypes;

const maskFiller = /\d/;
const placeholderFiller = '_';

class InputCode extends Component {
  state = { disabled: false };

  static propTypes = {
    charAmount: number,
    value: string
  };

  static defaultProps = {
    charAmount: 4,
    selectable: true
  };

  render() {
    const { disabled } = this.state;
    const { charAmount, selectable, ...rest } = this.props;
    const codeMask = getMask(charAmount, maskFiller);
    const placeholder = getMask(charAmount, placeholderFiller).join('');

    return (
      <MaskedInput
        mask={codeMask}
        {...rest}
        onChange={this.handleChange}
        render={(ref, props) => (
          <input
            className="InputCode"
            type="text"
            inputMode="numeric"
            placeholder={placeholder}
            ref={this.handleBothRefs(ref)}
            readOnly={!selectable}
            guide={true}
            disabled={disabled}
            {...props}
          />
        )}
      />
    );
  }

  disabled() {
    this.setState({
      disabled: true
    });
  }

  handleBothRefs = ref => element => {
    const { inputRef } = this.props;
    ref(element);
    if (isFunction(inputRef)) {
      inputRef(element);
    }
  };

  handleChange = e => {
    const { value } = e.target;
    const { charAmount, onChange } = this.props;
    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length >= charAmount) {
      this.disabled();

      setTimeout(() => onChange(cleanedValue), 0);
    }
  };
}

export default InputCode;
