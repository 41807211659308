import React, { Component } from 'react';

import IconContainer from '../../icon-container/icon-container';
import { IconPhone } from '../../icons';

import './footer-contacts.css';

class FooterContacts extends Component {
  render() {
    return (
      <div className="FooterContacts">
        <a href={`tel:${this.props.tel}`} className="FooterContacts-tel">
          <span className="FooterContacts-icon">
            <IconContainer size="small">
              <IconPhone />
            </IconContainer>
          </span>
          <span className="FooterContacts-number">{this.props.tel}</span>
        </a>
        <span className="FooterContacts-schedule">Ежедневно с 09:00 до 21:00</span>
      </div>
    );
  }
}

export default FooterContacts;
