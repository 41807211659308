import { combineReducers } from 'redux';
import * as TYPE from './types';

import createImmerReducer from 'modules/utils/create-immer-reducer';
import { selectIdentifier as selectProductIdentifier } from 'modules/product/ducks/products/helpers';
import { createReducer, handle } from 'modules/utils/dux';
import * as goodsDomain from '../../domain/goods';
import * as Product from '../../domain/product';
import * as ORDER from '../types/orders';
import * as BONUS_CONFIRMATION from '../types/bonuses-conformation';
import * as DIALOGS from 'modules/core/ducks/types/dialogs';
import * as ACCOUNT from 'modules/core/ducks/types/account';

// import mockedBonuses from './mocked-data/mocked-data';

const lastChangeDate = createReducer(
  handle(TYPE.PRODUCT_ADD, () => Date.now()),
  handle(TYPE.PRODUCT_REMOVE, () => Date.now()),
  handle(TYPE.PRODUCT_CHANGE_AMOUNT, () => Date.now()),
  handle(TYPE.PROMO_APPLY, () => Date.now()),
  handle(TYPE.PROMO_REMOVE, () => Date.now()),

  handle(TYPE.BONUS_APPLY, () => Date.now()),
  handle(TYPE.BONUS_REMOVE, () => Date.now()),
  handle(TYPE.REHYDRATE, (state, { basket }) => basket.lastChangeDate)
);

const listReducer = createImmerReducer(
  {
    [TYPE.PRODUCT_ADD]: (draft, { product, amount }) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].product === selectProductIdentifier(product)) {
          draft[i].amount += amount;
          return;
        }
      }
      draft.push(goodsDomain.buildItem(product, null, amount));
    },

    [TYPE.REHYDRATE]: (draft, { basket }) => {
      const { goods } = basket;

      goods.forEach(function(item) {
        draft.push(goodsDomain.buildItem(item.product, item.variantId, item.amount, item.price));
      });
    },

    [TYPE.PRODUCT_REMOVE]: (draft, { uid }) => {
      draft.splice(
        draft.findIndex(item => item.uid === uid),
        1
      );
    },

    [TYPE.PRODUCT_REMOVE_REQUEST]: (draft, { uid }) => {
      draft.splice(
        draft.findIndex(item => item.uid === uid),
        1
      );
    },

    [TYPE.PRODUCT_CHANGE_AMOUNT]: (draft, { uid, amount }) => {
      draft.forEach(item => {
        if (item.uid === uid) {
          item.amount = amount;
        }
      });
    },

    [TYPE.UPDATE_FROM_SERVER]: (draft, { basket }) => {
      return basket.goods;
    },

    [TYPE.UPDATE_FROM_ANOTHER_TAB]: (draft, { goods }) => {
      return goods.map(item => ({ ...item, product: Product.getIdentifier(item.product) }));
    },

    [TYPE.SYNC_RESPONSE]: (draft, { basket }) => {
      return basket.goods;
    },

    [ORDER.SUCCESS]: () => [],
    [TYPE.RESET]: () => []
  },
  []
);

const willBeRemoved = createReducer(
  handle(TYPE.PRODUCT_MARK_FOR_REMOVAL, (state, { uid }) => {
    if (state.indexOf(uid) !== -1) {
      return state;
    }
    return [...state, uid];
  }),

  handle(TYPE.PRODUCT_REMOVE, (state, { uid }) => {
    return [...state.filter(itemUid => itemUid !== uid)];
  }),

  handle(TYPE.PRODUCT_CANCEL_REMOVAL, (state, { uid }) => {
    return [...state.filter(itemUid => itemUid !== uid)];
  }),

  handle(TYPE.UPDATE_FROM_SERVER, (state, { basket }) => {
    const uids = basket.goods.map(item => item.uid);

    return [...state.filter(uid => uids.indexOf(uid) !== -1)];
  }),

  handle(TYPE.SYNC_RESPONSE, (state, { basket }) => {
    const uids = basket.goods.map(item => item.uid);

    return [...state.filter(uid => uids.indexOf(uid) !== -1)];
  }),

  handle(ORDER.SUCCESS, () => []),
  handle(TYPE.RESET, () => [])
);

const promos = createReducer(
  handle(TYPE.REHYDRATE, function(state, { basket }) {
    return basket.promos;
  }),

  handle(TYPE.PROMO_APPLY, function(state, { code }) {
    return [
      ...state.filter(item => !item.disabled),
      {
        code
      }
    ];
  }),

  handle(TYPE.PROMO_ERROR, function(state, { code }) {
    return state.map(item => {
      if (item.code === code) {
        return {
          ...item,
          disabled: true,
          _isLoaded: true
        };
      }

      return item;
    });
  }),

  handle(TYPE.PROMO_REMOVE, function(state, { promo }) {
    return [
      ...state.map(item => {
        if (item.id === promo.id) {
          return {
            ...item,
            _isRemoving: true
          };
        }
        return item;
      })
    ];
  }),

  handle(TYPE.UPDATE_FROM_SERVER, function(state, { basket }) {
    const isEmptyBasket = basket.goods.length === 0;

    if (isEmptyBasket) {
      return [];
    }

    const promos = basket.promos.map(item => {
      return {
        ...item,
        _isLoaded: true
      };
    });

    const disabledPromos = state
      .filter(localItem => !promos.some(item => localItem.code === item.code))
      .filter(localItem => !localItem._isRemoving)
      .map(item => ({
        ...item,
        _isLoaded: true,
        disabled: true
      }));

    return [...promos, ...disabledPromos];
  }),

  handle(TYPE.UPDATE_FROM_ANOTHER_TAB, function(state, { promos }) {
    return promos;
  }),

  handle(TYPE.SYNC_RESPONSE, function(state, { basket }) {
    const promos = basket.promos.map(item => ({
      ...item,
      _isLoaded: true
    }));

    return promos;
  }),

  handle(ORDER.SUCCESS, () => []),
  handle(TYPE.RESET, () => []),
  handle(TYPE.RESET_PROMO, () => [])
);

const bonuses = createReducer(
  handle(TYPE.REHYDRATE, function(state, { basket }) {
    return {
      ...state,
      ...basket.bonuses
    };
    // return { ...state, ...mockedBonuses };
  }),

  handle(TYPE.BONUS_APPLY, function(state, { value, type }) {
    return {
      ...state,
      applied: [
        {
          value,
          type
        }
      ],
      isLoading: true
    };
  }),

  handle(TYPE.BONUS_REMOVE, function(state, { value, type }) {
    return {
      ...state,
      applied: [
        {
          value,
          type
        }
      ],
      isLoading: true
    };
  }),

  handle(TYPE.BONUS_ERROR, function(state) {
    return {
      ...state,
      isLoading: false
    };
  }),

  handle(TYPE.UPDATE_FROM_SERVER, function(state, { basket }) {
    return {
      ...state,
      ...basket.bonuses,
      isLoading: false
    };
  }),

  handle(ORDER.UPDATE_FROM_SERVER, function(state, { order }) {
    return {
      ...state,
      ...order.basket.bonuses
    };
  }),

  handle(TYPE.SYNC_RESPONSE, function(state, { basket }) {
    return {
      ...state,
      ...basket.bonuses
    };
  }),

  // handle(TYPE.UPDATE_FROM_SERVER, function(state, { basket }) {
  //   const promos = basket.promos.map(item => ({
  //     ...item,
  //     _isLoaded: true
  //   }));

  //   const disabledPromos = state
  //     .filter(localItem => !promos.some(item => localItem.code === item.code))
  //     .filter(localItem => !localItem._isRemoving)
  //     .map(item => ({
  //       ...item,
  //       _isLoaded: true,
  //       disabled: true
  //     }));

  //   return [...promos, ...disabledPromos];
  // }),

  // handle(TYPE.SYNC_RESPONSE, function(state, { basket }) {
  //   const promos = basket.promos.map(item => ({
  //     ...item,
  //     _isLoaded: true
  //   }));

  //   return promos;
  // }),

  handle(ORDER.SUCCESS, () => ({})),
  handle(TYPE.RESET, () => ({})),
  handle(ACCOUNT.SIGN_OUT, () => ({}))
);

const bonusesConformation = createReducer(
  handle(ORDER.UPDATE_FROM_SERVER, function(state, payload) {
    const { order } = payload;
    return {
      ...state,
      required: order.bonusConformationRequired
    };
  }),

  handle(BONUS_CONFIRMATION.REQUEST_CODE, function(state) {
    return {
      ...state,
      requestCodeFetching: true
      // isCodeInvalid: false
    };
  }),

  handle(BONUS_CONFIRMATION.RESPONSE_CODE, function(state, { phone }) {
    return {
      ...state,
      requestCodeFetching: false,
      phone
    };
  }),

  handle(BONUS_CONFIRMATION.REQUEST_CONFIRM, function(state) {
    return {
      ...state,
      requestConfirmFetching: true,
      isCodeInvalid: false
    };
  }),

  handle(BONUS_CONFIRMATION.RESPONSE_CONFIRM, function(state) {
    return {
      ...state,
      requestConfirmFetching: false,
      required: false,
      isPlacing: true
    };
  }),

  handle(ORDER.SHOW, state => ({
    ...state,
    isPlacing: false
  })),

  handle(BONUS_CONFIRMATION.ERROR_CONFIRM, function(state) {
    return {
      ...state,
      requestConfirmFetching: false,
      isCodeInvalid: true
    };
  }),

  handle(DIALOGS.BONUSES_CONFIRMATION_CODE_CLOSE, state => ({
    ...state,
    isCodeInvalid: false
  })),

  handle(TYPE.BONUS_REMOVE, function(state) {
    return {
      ...state,
      isCodeInvalid: false
    };
  }),

  handle(TYPE.UPDATE_FROM_SERVER, function(state, payload) {
    const { basket } = payload;

    return {
      ...state,
      required: basket.bonusConformationRequired
    };
  }),

  handle(TYPE.SYNC_RESPONSE, function(state, { basket }) {
    return {
      ...state,
      required: basket.bonusConformationRequired
    };
  }),

  handle(ORDER.SUCCESS, () => ({})),
  handle(ACCOUNT.SIGN_OUT, () => ({}))
);

const promoSelectedOptions = createReducer(
  handle(TYPE.REHYDRATE, (state, { basket }) => {
    const { promos } = basket;
    let updatedState = {};

    for (let i = 0; i < promos.length; i++) {
      let promo = promos[i];

      if (promo.optionId) {
        updatedState[promo.id] = promo.optionId;
      }
    }

    return {
      ...updatedState
    };
  }),

  handle(TYPE.PROMO_OPTION_SELECT, (state, { optionId, promoId }) => {
    return {
      ...state,
      [promoId]: optionId
    };
  }),

  handle(TYPE.PROMO_REMOVE, (state, { promo }) => {
    const updatedState = {
      ...state
    };
    delete updatedState[promo.id];

    return {
      ...updatedState
    };
  }),

  handle(TYPE.OPEN_APPLY_ANOTHER_GIFT_DIALOG, state => {
    return {
      ...state,
      applyingGift: true
    };
  }),

  handle(TYPE.CLOSE_APPLY_ANOTHER_GIFT_DIALOG, state => {
    return {
      ...state,
      applyingGift: false
    };
  })
);

const isPromoVerified = createReducer(
  handle(TYPE.REHYDRATE, () => false),
  handle(TYPE.PROMO_APPLY, () => false),
  handle(TYPE.PROMO_OPTION_SELECT, () => false),
  handle(TYPE.PROMO_REMOVE, () => false),

  handle(TYPE.PROMO_ERROR, () => true),
  handle(TYPE.SYNC_RESPONSE, () => true),
  handle(TYPE.UPDATE_FROM_SERVER, () => true)
);

const order = createReducer();

const deliveryReducer = createReducer(
  handle(TYPE.UPDATE_FROM_SERVER, (state, { basket }) => {
    return { ...state, maxDeliveryPrice: basket.maxDeliveryPrice };
  }),
  handle(TYPE.SYNC_RESPONSE, (state, { basket }) => {
    return { ...state, maxDeliveryPrice: basket.maxDeliveryPrice };
    // return { ...state };
  })
  // handle(TYPE.VERIFY_REQUEST, (state, payload) => {
  //   return { ...state };
  // }),
  // handle(TYPE.REHYDRATE, (state, payload) => {
  //   return { ...state };
  // }),
  // handle(TYPE.BONUS_APPLY, (state, payload) => {
  //   return { ...state };
  // })
);

const uiReducer = createImmerReducer(
  {
    [TYPE.DROPDOWN_OPEN]: draft => {
      draft.isDropdownOpened = true;
    },
    [TYPE.DROPDOWN_CLOSE]: draft => {
      draft.isDropdownOpened = false;
    }
  },
  {
    isDropdownOpened: false
  }
);

const isPlacing = createReducer(
  handle(ORDER.PLACE, () => true),
  handle(ORDER.UPDATE_FROM_SERVER, () => false),
  handle(ORDER.LOAD_FROM_SERVER, () => false),
  handle(ORDER.CREATE_LOCAL, () => false)
);

const isVerifying = createReducer(
  handle(TYPE.VERIFY_REQUEST, state => state + 1),
  handle(TYPE.VERIFY_CANCEL, state => state - 1),
  handle(TYPE.UPDATE_FROM_SERVER, state => state - 1)
);

const isFetching = createReducer(
  handle(TYPE.SYNC, () => true),
  handle(TYPE.SYNC_RESPONSE, () => false),
  handle(TYPE.SET_OUTER_BASKET_LOADED, () => false)
);

const isOuterBasketLoadingReducer = createReducer(
  handle(TYPE.SET_OUTER_BASKET_LOADING, () => {
    return true;
  }),

  handle(TYPE.UPDATE_FROM_SERVER, () => {
    return false;
  })
);

export default combineReducers({
  isOuterBasketLoading: isOuterBasketLoadingReducer(),
  list: listReducer,
  delivery: deliveryReducer({
    cost: null,
    freeDeliveryPrice: 3000,
    maxDeliveryPrice: null
  }),
  ui: uiReducer,
  bonuses: bonuses([]),
  bonusesConformation: bonusesConformation({}),
  promos: promos([]),
  promoSelectedOptions: promoSelectedOptions({}),
  isPromoVerified: isPromoVerified(false),
  willBeRemoved: willBeRemoved([]),
  order: order(null),
  lastChangeDate: lastChangeDate(0),
  _isPlacing: isPlacing(false),
  _isFetching: isFetching(false),
  _isVerifying: isVerifying(0)
});
