import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Formik } from 'formik';
import * as Yup from 'yup';

import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';
import plural from 'modules/utils/plural';

import * as fromOrders from '../ducks/orders';
import PersonalInfoForm from '../components/personal-info-form/personal-info-form';
import * as fromBasket from '../ducks/basket/selectors';
import { actions as BonusesConfirmation } from '../ducks/bonuses-conformation';

const mapStateToProps = createStructuredSelector({
  personalInfo: fromOrders.getPersonalInfoById,
  isAuthorized: fromAccount.getIsAuthorized,
  isAuthReady: fromAccount.getStatusIsReady,
  profileEmail: fromAccount.getEmail,
  profileFirstName: fromProfile.getFirstName,
  profileLastName: fromProfile.getLastName,
  profileFullName: fromProfile.getFullName,
  profilePhone: fromProfile.getPhone,
  bonuses: fromBasket.getBonuses,
});

const mapDispatchToProps = {
  submitPersonalInfo: fromOrders.actions.submitPersonalInfo,
  onRequestBonusesConfirmationCode: BonusesConfirmation.requestCode,
  openConfirmCodeDialog: fromDialogs.actions.openBonusesConfirmationCodeDialog
};

const FIRST_NAME_REQUIRED_ERROR = 'Имя является обязательным полем';
const LAST_NAME_REQUIRED_ERROR = "Фамилия является обязательным полем";
const FIRST_NAME_FORMAT_ERROR =
  'Имя может содержать кириллицу (а-я) и латиницу (a-z)';
const LAST_NAME_FORMAT_ERROR =
  'Фамилия может содержать кириллицу (а-я) и латиницу (a-z)';
const EMAIL_REQUIRED_ERROR = 'Почта является обязательным полем';
const EMAIL_FORMAT_ERROR = 'Неверный формат введенной почты';
const PHONE_REQUIRED_ERROR = 'Укажите ваш номер телефона';
// const PHONE_SHORT_ERROR = 'Номер должен содержать не менее 10-ти цифр';

class PersonalInfoFormContainer extends Component {
  state = {
    phone: this.setInitialPhone()
  };

  render() {
    const {
      profileEmail,
      profileFirstName,
      profileLastName,
      // profileFullName,
      profilePhone,
      personalInfo,
      bonuses,
      openConfirmCodeDialog
    } = this.props;

    
    // const hasPersonalInfoLastName = personalInfo && personalInfo.firstName && personalInfo.firstName.indexOf(" ") !== -1;
    
    // const personalInfoFirstName = personalInfo && personalInfo.firstName.split(" ")[0]
    // const personalInfoLastName = personalInfo && personalInfo.firstName.split(" ").filter((_, index) => index !== 0 ).join(" ")
    const personalInfoFirstName = personalInfo && personalInfo.firstName;
    const personalInfoLastName = personalInfo && personalInfo.lastName;

    const initialFirstName = personalInfoFirstName || profileFirstName || ""
    const initialLastName = personalInfoLastName || profileLastName || ""

    const initialValues = {
      email: (personalInfo && personalInfo.email) || profileEmail || '',
      // name: (personalInfo && personalInfo.firstName ) || profileFirstName || '',
      // name: (personalInfo && `${personalInfo.firstName} ${hasPersonalInfoLastName ? "" : profileLastName}`.trim() ) || profileFullName || '',
      // name: profileFullName || '',
      phone: (personalInfo && personalInfo.phone) || profilePhone || '',
      // phone: "+380506848618",
      firstname: initialFirstName,
      lastname: initialLastName
    };

    const phoneLength =
      this.state.phone &&
      this.state.phone.length &&
      getSeparatedPhone(this.state.phone, areaCodes).code
        ? getSeparatedPhone(this.state.phone, areaCodes).code.phoneLength
        : areaCodes[0].phoneLength;

    const PHONE_SHORT_ERROR = `Номер должен содержать ${phoneLength} цифр, не хватает ${phoneLength-this.state.phone.length+1}${plural(phoneLength-this.state.phone.length+1, ["-й","-x", "-ти"])}`;

    const personalInfoSchema = Yup.object().shape({
      // name: Yup.string()
      //   .trim()
      //   .matches(/^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"`\\/<>?:;|=.,{}()[\]]+$/, FIRST_NAME_FORMAT_ERROR)
      //   .matches(/[\t\n\v\f\r \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]/, LAST_NAME_REQUIRED_ERROR)
      //   .required(FIRST_NAME_REQUIRED_ERROR),
      firstname: Yup.string()
        .trim()
        .matches(/^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"`\\/<>?:;|=.,{}()[\]]+$/, LAST_NAME_FORMAT_ERROR)
        .required(FIRST_NAME_REQUIRED_ERROR),
      lastname: Yup.string()
        .trim()
        .matches(/^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"`\\/<>?:;|=.,{}()[\]]+$/, FIRST_NAME_FORMAT_ERROR)
        // .matches(/[\t\n\v\f\r \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]/, LAST_NAME_REQUIRED_ERROR)
        .required(LAST_NAME_REQUIRED_ERROR),

      phone: Yup.string()
        .required(PHONE_REQUIRED_ERROR)
        .min(phoneLength + 1, PHONE_SHORT_ERROR),

      email: Yup.string()
        .trim()
        .required(EMAIL_REQUIRED_ERROR)
        .email(EMAIL_FORMAT_ERROR)
    });

    // isInitialValid
    // https://github.com/jaredpalmer/formik/issues/1116
    return (
      <Formik
        key={profileEmail}
        initialValues={initialValues}
        validationSchema={personalInfoSchema}
        isInitialValid={personalInfoSchema.isValidSync(initialValues)}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleFocus,
          handleBlur,
          handleSubmit,
          isValid
        }) => (
          <PersonalInfoForm
            key={bonuses.conformationOptions.isCodeInvalid}
            values={values}
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSubmit={handleSubmit}
            onPhoneChange={this.handlePhoneChange}
            bonuses={bonuses}
            onConfirmBonuses={openConfirmCodeDialog}
            isValid={isValid}
          />
        )}
        onSubmit={this.handleSubmit}
      />
    );
  }

  componentDidMount() {
    const { bonuses, onRequestBonusesConfirmationCode } = this.props;

    if (!bonuses.conformationOptions.required) {
      return;
    }

    onRequestBonusesConfirmationCode({
      value: bonuses.applied
    });
  }

  setInitialPhone() {
    const { personalInfo, profilePhone } = this.props;

    return (personalInfo && personalInfo.phone) || profilePhone || '';
  }

  setPhone(phone) {
    this.setState({
      phone
    });
  }

  handleSubmit = ({ email, firstname, lastname }) => {
    const { id } = this.props;
    const { phone } = this.state;
    const name = firstname + " " + lastname

    // const [firstName, ...rest] = name.trim().split(" ")
    // const lastName = rest.join(" ")
    this.props.submitPersonalInfo(id, email, phone, name, firstname, lastname);
    this.props.onComplete();
  };

  handlePhoneChange = phone => {
    this.setPhone(phone.trim());
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfoFormContainer);
