import React, { Component } from 'react';
import cn from 'classnames';

import './icon-like.css';

class IconLike extends Component {
  render() {
    const { filled } = this.props;
    return (
      <svg
        className={cn('IconLike', {
          'IconLike--filled': filled
        })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
      >
        <path d="M22.1 31.5h-3.8c-4.9-.6-7.6-3.8-7.6-9.4 0-2.8 1.3-5.4 3.6-7.3l.1-.1s4.2-3.5 3.8-6.9c-.1-1.2.1-2.2.7-2.8.3-.4.7-.5 1.2-.5.6 0 1.4.3 2.2 1.8 1.7 2.9.3 7.5.1 8.2l-.1.4h7c1.5 0 3.1 1 2.9 2.9 0 .6-.4 1.2-.7 1.7.8.5 1.4 1.4 1.4 2.5 0 1-.7 1.9-1.4 2.4.3.4.7.9.7 1.7 0 .9-.7 1.5-1.4 2 .2.4.4.8.4 1.2 0 1.3-1.2 2.3-2.7 2.3 0-.1-3.5-.1-6.4-.1M6.8 31h-.4c-1.8 0-3.3-1.3-3.3-2.8V17.5c0-1.6 1.5-2.8 3.3-2.8h.4c1.8 0 3.3 1.3 3.3 2.8v10.6c.1 1.6-1.4 2.9-3.3 2.9" />
      </svg>
    );
  }
}

export default IconLike;
