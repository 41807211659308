import React, { Component } from 'react';
import FormSection from './form-section/form-section';
import OrderReceiptContainer from '../../containers/order-receipt';
import OrderingFormContainer from './containers/ordering-form';
import OrderingFormHeader from './containers/ordering-header';
import AsideReceipt from '../../components/aside-receipt/aside-receipt';

class OrderingScene extends Component {
  render() {
    const { id } = this.props;
    return (
      <FormSection
        header={<OrderingFormHeader id={id} />}
        main={<OrderingFormContainer id={id} />}
        side={
          <AsideReceipt>
            <OrderReceiptContainer id={id} />
          </AsideReceipt>
        }
      />
    );
  }
}

export default OrderingScene;
