import React, { Component } from 'react';

class Item extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <div className="InstagramCarousel-item" {...rest}>
        {children}
      </div>
    );
  }
}

export default Item;
