export default function uniq(list) {
  const seen = {};
  const result = [];
  const length = list.length;

  for (let i = 0; i < length; i++) {
    const item = list[i];

    if (seen[item]) {
      continue;
    }

    seen[item] = 1;
    result.push(item);
  }

  return result;
}

export function uniqBy(a, key) {
  let seen = {};
  return a.filter(function(item) {
    let k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}
