import React, { Component } from 'react';
import './unauthorized-menu.css';
import MobileNavigationButton from 'modules/core/components/mobile-navigation-button/mobile-navigation-button';
import { IconUser } from 'modules/core/components/icons';

class UnauthorizedMenu extends Component {
  render() {
    const { openAuthDialog, openRegistrationDialog } = this.props;
    return (
      <div className="UnauthorizedMenu">
        <div className="UnauthorizedMenu-item">
          <MobileNavigationButton title="Вход" onClick={openAuthDialog}>
            <IconUser />
          </MobileNavigationButton>
        </div>
        <div className="UnauthorizedMenu-item">
          <MobileNavigationButton title="Регистрация" onClick={openRegistrationDialog} />
        </div>
      </div>
    );
  }
}

export default UnauthorizedMenu;
