import React, { Component } from 'react';

import Content from 'modules/core/components/content/content';
import ButtonHeart from 'modules/core/components/button-heart/button-heart';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';
import SocialButton from 'modules/social/components/button/button';

import ArticleActionBar from '../article-action-bar/article-action-bar';
import ArticleAction from '../article-action/article-action';
import SectionNextArticle from '../section-next-article/section-next-article';
import NextArticleContainer from '../../containers/next-article';

import Header from './header/header';
import ContentSection from './content-section/content-section';
import Footer from './footer/footer';

import ReadNextArticleContainer from '../../containers/read-next-article';

import articleShape from '../../prop-types/article-shape';

import './article.css';

class Article extends Component {
  static propTypes = {
    article: articleShape.isRequired
  };

  render() {
    const { article, shares, onLike, onUnlike, onShare } = this.props;

    if (!article._details) {
      return null;
    }

    return (
      <div className="Article">
        <HideErrorBoundary>
          <Header
            publicationDate={article.createDate}
            readingTime={article.readingTime}
            title={article.title}
            author={article.author}
            cover={article.cover}
          />
        </HideErrorBoundary>

        <ContentSection
          leftSide={[this.getLikeActionBar(), this.getSocialActionBar()]}
          rightSide={this.getReadNextArticleArray()}
        >
          <HideErrorBoundary>
            <Content html={article.content} />
          </HideErrorBoundary>

          <HideErrorBoundary>
            <Footer
              author={article.author}
              shares={shares}
              likes={article.counters.likes}
              isLiked={article.isLiked}
              onLike={onLike}
              onUnlike={onUnlike}
              onShare={onShare}
            />
          </HideErrorBoundary>
        </ContentSection>

        <HideErrorBoundary>
          <SectionNextArticle>
            <NextArticleContainer
              previousSlug={article.previousArticle}
              nextSlug={article.nextArticle}
            />
          </SectionNextArticle>
        </HideErrorBoundary>
      </div>
    );
  }

  renderSocailNetworkAction = ({ name, amount, link }, index) => {
    return (
      <ArticleActionBar.Item key={index}>
        <ArticleAction
          button={
            <SocialButton
              component="a"
              target="_blank"
              href={link}
              social={name}
              size="small"
              onClick={this.handleButtonShare(name)}
            />
          }
          amount={amount}
        />
      </ArticleActionBar.Item>
    );
  };

  renderReadNextArticle = (slug, index) => {
    return (
      <HideErrorBoundary>
        <ReadNextArticleContainer key={index} slug={slug} />
      </HideErrorBoundary>
    );
  };

  getLikeActionBar() {
    const { article } = this.props;
    return (
      <HideErrorBoundary>
        <ArticleActionBar title="Мне нравится">
          <ArticleActionBar.Item>
            <ArticleAction
              button={
                <ButtonHeart
                  onClick={this.handleButtonLike}
                  size="small"
                  isActive={article.isLiked}
                />
              }
              amount={article.counters.likes}
            />
          </ArticleActionBar.Item>
        </ArticleActionBar>
      </HideErrorBoundary>
    );
  }

  getSocialActionBar() {
    const { shares } = this.props;

    if (!shares.length) {
      return null;
    }

    return (
      <HideErrorBoundary>
        <ArticleActionBar title="Поделиться">
          {shares.map(this.renderSocailNetworkAction)}
        </ArticleActionBar>
      </HideErrorBoundary>
    );
  }

  getReadNextArticleArray() {
    const { article } = this.props;

    return article.relatedArticleList.map(this.renderReadNextArticle);
  }

  handleButtonLike = () => {
    const { article, onLike, onUnlike } = this.props;

    if (article.isLiked) {
      onUnlike();
      return;
    }

    onLike();
  };

  handleButtonShare = name => () => {
    const { onShare } = this.props;

    onShare(name);
  };
}

export default Article;
