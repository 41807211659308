import React, { Component } from 'react';
import Media from 'react-media';
import MobileProductCardSlider from '../mobile-product-card-slider/mobile-product-card-slider';
import ProductCardSlider from '../product-card-slider/product-card-slider';

// import videoMedia from './video-media-mocked-data';

class CardSlider extends Component {
  render() {
    const { media, ...rest } = this.props;

    return (
      <Media query="(max-width: 840px)">
        {isMobile =>
          isMobile ? (
            <MobileProductCardSlider mediaList={media} {...rest} />
          ) : (
            <ProductCardSlider mediaList={media} {...rest} />
          )
        }
      </Media>
    );
  }
}

export default CardSlider;
