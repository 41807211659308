import React, { Component } from 'react';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconYoutube } from 'modules/core/components/icons';

import getYoutubeVideoIdentifier from 'modules/utils/get-youtube-video-identifier';
import getYoutubeVideoPreviewImage from 'modules/utils/get-youtube-video-preview-image';

import './video-miniature.css';

class VideoMiniature extends Component {
  render() {
    const { media } = this.props;
    let img;
    const link = media.sources[0].main.normal;
    const identifier = getYoutubeVideoIdentifier(link);

    if (identifier) {
      img = getYoutubeVideoPreviewImage(identifier);
    } else {
      img = media.cover;
    }

    const styles = { backgroundImage: `url(${img})` };

    return (
      <span style={styles} className="ProductCardSlider_VideoMiniature">
        <span className="ProductCardSlider_VideoMiniature-icon">
          <IconContainer inline>
            <IconYoutube />
          </IconContainer>
        </span>
      </span>
    );
  }
}

export default VideoMiniature;
