import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import UTMBanner from '../components/utm-banner/utm-banner';
import * as fromUTMBanner from '../ducks/utm-banner';
import UTMBannerActions from '../ducks/actions/utm-banner';

const mapStateToProps = createStructuredSelector({
  isOpened: fromUTMBanner.getIsOpen,
  data: fromUTMBanner.getBannerData
});

const mapDispatchToProps = {
  resetData: UTMBannerActions.resetData,
  close: UTMBannerActions.closeBanner
};

class UTMBannerContainer extends Component {
  render() {
    const { isOpened, data } = this.props;

    if (!data) {
      return null;
    }

    if (!isOpened) {
      return null;
    }

    return <UTMBanner show={isOpened} onClose={this.handleClose} {...data} />;
  }

  handleClose = () => {
    const { resetData, close } = this.props;

    close();
    resetData();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UTMBannerContainer);
