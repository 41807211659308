import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from '../../utils/debug-reselect';

import * as FEEDBACK from './types/feedback';

/* REDUCERS */

const reducer = createReducer(
  handle(FEEDBACK.SEND, state => {
    return {
      ...state,
      isFetching: true
    };
  }),

  handle(FEEDBACK.RESPONSE, state => {
    return {
      ...state,
      isFetching: false
    };
  }),

  handle(FEEDBACK.ERROR, state => {
    return {
      ...state,
      isFetching: false
    };
  })
);

export default reducer({
  isFetching: false
});

/* SELECTORS */

const getRoot = state => state.core.feedback;

export const getIsFetching = createSelector(
  getRoot,
  root => root.isFetching
);
