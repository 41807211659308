import React, { Component, Fragment } from 'react';
import ToastStack from '../components/toast-stack/toast-stack';
import NoSsr from '../components/no-ssr/no-ssr';

const isIdEqualTo = id => item => item.id === id;
const not = fn => item => !fn(item);

const ToastContext = React.createContext();

class ToastConsumer extends Component {
  render() {
    return <ToastContext.Consumer>{this.props.children}</ToastContext.Consumer>;
  }
}

class ToastStackContainer extends Component {
  state = {
    toastList: []
  };

  render() {
    const { toastList } = this.state;

    return (
      <Fragment>
        <NoSsr>
          <ToastStack toastList={toastList} {...this.props} />
        </NoSsr>
        <ToastContext.Provider
          value={{
            create: this.create,
            close: this.close
          }}
        >
          {this.props.children}
        </ToastContext.Provider>
      </Fragment>
    );
  }

  create = ({ toast, onClose, ...rest }) => {
    const finalToast = {
      state: 'neutral',
      delay: 5000,
      isClosable: true,
      isPauseOnHover: true,
      onClose: this.handleToastClose(toast.id, onClose),
      ...rest,
      ...toast
    };

    this.setState(state => ({
      toastList: [finalToast, ...state.toastList]
    }));
  };

  handleToastClose = (id, onClose) => () => {
    this.close(id);

    if (onClose) {
      onClose();
    }
  };

  close = id => {
    this.setState(state => ({
      toastList: state.toastList.map(item => {
        if (item.id === id) {
          return {
            ...item,
            exiting: true
          };
        }

        return item;
      })
    }));

    const remove = this.removeToast(id);

    setTimeout(remove, 300);
  };

  removeToast = id => () => {
    this.setState(state => ({
      toastList: state.toastList.filter(not(isIdEqualTo(id)))
    }));
  };
}

export { ToastConsumer, ToastContext };
export default ToastStackContainer;
