import React, { Component } from 'react';
import Media from 'react-media';

import { getAgreementsLink } from 'routes/links';

// import Tooltip from 'modules/core/components/tooltip/tooltip';
import Loader from 'modules/core/components/loader/loader';
import Button from 'modules/core/components/button/button';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import Checkbox from 'modules/form/components/checkbox/checkbox';
// import InfoSign from 'modules/review/components/reply-form/info-sign/info-sign';

import OrderStep from '../../../components/order-step/order-step';
import PersonalInfoFormContainer from '../../../containers/personal-info-form';
import PersonalInfoSummaryContainer from '../../../containers/personal-info-summary';
import DeliveryFormContainer from '../../../containers/delivery-form';
import DeliverySummaryContainer from '../../../containers/delivery-summary';
import PaymentFormContainer from '../../../containers/payment-form';
import OrderSummaryContainer from '../../../containers/order-summary';
// import StaticTooltip from 'modules/core/components/static-tooltip/static-tooltip';

import Comment from '../comment/comment';

import './ordering-form.css';
import Toast from './toast';

class OrderingForm extends Component {
  state = {
    showConfirmationMethodInfo: false
  };

  render() {
    const {
      id,
      error,
      isLoading,
      isSubmitting,
      comment,
      paymentType,
      personalStep,
      deliveryStep,
      paymentStep,
      isAllComplete,
      isAnyEditing,
      onPaymentTypeChange,
      onCommentChange,
      onSubmit,
      orderingFormRef,
      personalInfoStepRef,
      deliveryStepRef,
      paymentStepRef,
      choiceConfirmationMethod
    } = this.props;

    if (isLoading) {
      return (
        <div className="OrderingForm">
          <div className="OrderingForm-loader">
            <Loader />
          </div>
        </div>
      );
    }
    
    return (
      <div className="OrderingForm" ref={orderingFormRef}>
        <div className="OrderingForm-step" ref={personalInfoStepRef}>
          {personalStep.isComplete && !personalStep.isActive ? (
            <PersonalInfoSummaryContainer id={id} onEdit={this.handleEdit(personalStep)} />
          ) : (
            <OrderStep title="Личные данные" {...personalStep}>
              <PersonalInfoFormContainer
                id={id}
                isEditing={personalStep.isEditing}
                onComplete={this.handleSubmit(personalStep)}
              />
            </OrderStep>
          )}
        </div>

        <div className="OrderingForm-step" ref={deliveryStepRef}>
          {deliveryStep.isComplete && !deliveryStep.isActive ? (
            <DeliverySummaryContainer
              id={id}
              onEdit={this.handleEdit(deliveryStep)}
              // anchor="deliverySummaryStep"
            />
          ) : (
            <OrderStep title="Способы доставки" {...deliveryStep}>
              <DeliveryFormContainer
                id={id}
                isEditing={personalStep.isEditing}
                onComplete={this.handleSubmit(deliveryStep)}
              />
            </OrderStep>
          )}
        </div>

        <div className="OrderingForm-step" ref={paymentStepRef}>
          <OrderStep title="Способы оплаты" {...paymentStep} onEdit={this.handleEdit(paymentStep)}>
            <PaymentFormContainer
              id={id}
              isEditing={paymentStep.isEditing}
              paymentType={paymentType}
              onChange={onPaymentTypeChange}
              onComplete={this.handleSubmit(paymentStep)}
            />
          </OrderStep>
        </div>

        <div className="OrderingForm-comment">
          <Comment value={comment} onChange={onCommentChange} />
        </div>

        <Media query="(max-width: 840px)">
          {isMobile =>
            isMobile && (
              <div className="OrderingForm-summary">
                <OrderSummaryContainer id={id} />
              </div>
            )
          }
        </Media>
        {choiceConfirmationMethod && (
          <Media query="(max-width: 840px)">
            {isMobile =>
              isMobile
                ? this.renderChoiceConfirmationMethodWithStaticTooltiop()
                : this.renderChoiceConfirmationMethodWithTooltiop()
            }
          </Media>
        )}

        <div className="OrderingForm-submit">
          <div className="OrderingForm-submitButton">
            <Button
              title={paymentType === 11 ? 'Оплатить' : 'Оформить заказ'}
              onClick={onSubmit}
              variant="primary"
              expanded
              disabled={!isAllComplete || isAnyEditing || isSubmitting}
              iconAppend={isSubmitting && <IconFlatLoader />}
            />
          </div>

          <div className="OrderingForm-userAgreement">
            Оформляя заказ, я принимаю условия{' '}
            <a href={getAgreementsLink()} target="_blank" rel="noreferrer noopener">
              пользовательского соглашения
            </a>
          </div>
        </div>

        <Toast
          show={error}
          id="ordering-error"
          title="Ой-ой"
          state="fail"
          content="Во время оформления заказа произошла ошибка. Мы уже приступили к решению проблемы!"
          delay={0}
          actionList={[
            {
              title: 'Перезагрузить страницу',
              type: 'ACTION_RELOAD_PAGE',
              variant: 'primary'
            }
          ]}
          onActionTrigger={this.handleToastAction}
        />
      </div>
    );
  }

  renderChoiceConfirmationMethodWithStaticTooltiop() {
    // const { showConfirmationMethodInfo } = this.state;
    const { isSmsConfirmationMethodActive, onChangeConfirmationMethod } = this.props;

    return (
      <div className="OrderingForm-confirmationStaticTooltip">
        {/* <StaticTooltip
          renderTrigger={({ ref, onClick }) => {
            return ( */}
              <div className="OrderingForm-confirmationMethod">
                <div className="OrderingForm-confirmationCheckbox">
                  <Checkbox
                    label="Позвоните мне для уточнения информации"
                    value="confirmation_method"
                    name="confirmation_method"
                    checked={isSmsConfirmationMethodActive}
                    onChange={onChangeConfirmationMethod}
                  />
                </div>
                {/* {isSmsConfirmationMethodActive && (
                  <div className="OrderingForm-confirmationMethodInfo">
                    <div
                      ref={ref}
                      onClick={onClick}
                      className="OrderingForm-confirmationMethodInfoIcon"
                    >
                      <InfoSign />
                    </div>
                  </div>
                )} */}
              </div>
         {/*   );
          }}
          show={showConfirmationMethodInfo}
          onShowChange={this.handleShowConditionsChange}
        >
          {this.renderLabelInfo()}
        </StaticTooltip> */}
      </div>
    );
  }

  renderChoiceConfirmationMethodWithTooltiop() {
    const { isSmsConfirmationMethodActive, onChangeConfirmationMethod } = this.props;
    return (
      <div className="OrderingForm-confirmationMethod">
        <div className="OrderingForm-confirmationCheckbox">
          <Checkbox
            label="Позвоните мне для уточнения информации"
            value="confirmation_method"
            name="confirmation_method"
            checked={isSmsConfirmationMethodActive}
            onChange={onChangeConfirmationMethod}
          />
        </div>
        {/* {isSmsConfirmationMethodActive && (
          <div className="OrderingForm-confirmationMethodInfo">
            <Tooltip content={this.renderLabelInfo()} showDelay={0} alignment="right">
              <div className="OrderingForm-confirmationMethodInfoIcon">
                <InfoSign />
              </div>
            </Tooltip>
          </div>
        )} */}
      </div>
    );
  }

  renderLabelInfo() {
    return (
      <div className="OrderingForm-confirmationMethodInfoLabel">
        Отлично! Мы любим уверенных в себе людей! Но все же убедись, что личные данные заполненны
        верно, так как заказ будет обработан и отправлен автоматически, после чего ты получишь
        СМС-уведомление.
      </div>
    );
  }

  handleToastAction = action => {
    if (action.type === 'ACTION_RELOAD_PAGE') {
      window.location.reload();
    }
  };

  handleEdit = step => () => {
    this.props.onEdit(step);
  };

  handleSubmit = step => () => {
    this.props.onStepSubmit(step);
  };

  handleShowConditionsChange = showConfirmationMethodInfo => {
    this.setState({ showConfirmationMethodInfo });
  };
}

export default OrderingForm;
