const KEY = '_persisted_selectedCity_id';
const LocalStorage = __BROWSER__
  ? window.localStorage
  : {
      setItem: () => null,
      getItem: () => null
    };

function setCity(city) {
  LocalStorage.setItem(KEY, city);
}

function getCity() {
  return LocalStorage.getItem(KEY);
}

export default { setCity, getCity };
