import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import InfoSign from 'modules/review/components/reply-form/info-sign/info-sign';

import StaticTooltip from '../static-tooltip/static-tooltip.jsx';
import PromoCodeWithButton from '../promo-code-with-button/promo-code-with-button';
import RollUp from '../roll-up/roll-up';

import './utm-banner.css';

const { bool, string, any, func } = PropTypes;

class UTMBanner extends Component {
  state = { isInfoOpened: false };

  static propTypes = {
    title: string,
    label: string,
    description: any,
    promoCode: string,
    show: bool,
    onClose: func
  };

  render() {
    const { isInfoOpened } = this.state;

    const { show, title, promoCode, description, descriptionTitle, label, onClose } = this.props;

    return (
      <RollUp
        show={show}
        onClose={onClose}
        content={() => (
          <div className="UTMBanner">
            <div className="UTMBanner-content">
              <StaticTooltip
                renderTrigger={({ ref, onClick }) => (
                  <Media query="(max-width: 970px)">
                    {isMobile => (
                      <div className="UTMBanner-header">
                        <div className="UTMBanner-leftCol">
                          {!!title && <div className="UTMBanner-title">{title}</div>}

                          <span className="UTMBanner-infoTitle">
                            {label || ''}
                            {!isMobile && !!description && (
                              <button className="UTMBanner-infoTrigger" ref={ref} onClick={onClick}>
                                <InfoSign variant="light" />
                              </button>
                            )}
                          </span>
                        </div>
                        <div className="UTMBanner-rightCol">
                          {isMobile && !!description && (
                            <div className="UTMBanner-infoTriggerHolder">
                              <button className="UTMBanner-infoTrigger" ref={ref} onClick={onClick}>
                                <InfoSign variant="light" size="large" />
                              </button>
                            </div>
                          )}
                          {!!promoCode && (
                            <div className="UTMBanner-promo">
                              {!isMobile && <div className="UTMBanner-promoTitle">Промокод</div>}
                              <PromoCodeWithButton
                                code={promoCode}
                                size={isMobile ? 'small' : 'large'}
                                inverted
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Media>
                )}
                contentTile={descriptionTitle}
                onShowChange={this.handleShowChange}
                show={isInfoOpened}
              >
                {!!description && (
                  <div
                    className="UTMBanner-content"
                    dangerouslySetInnerHTML={{
                      __html: description
                    }}
                  />
                )}
              </StaticTooltip>
            </div>
          </div>
        )}
      />
    );
  }

  openContent() {
    this.setState({
      isInfoOpened: true
    });
  }

  closeContent() {
    this.setState({
      isInfoOpened: false
    });
  }

  triggerContent(action) {
    if (!action) {
      this.closeContent();
    } else {
      this.openContent();
    }
  }

  handleShowChange = action => {
    this.triggerContent(action);
  };
}

export default UTMBanner;
