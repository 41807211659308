import React, { Component } from 'react';
import { Circle } from 'rc-progress';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ProfileAvatar from '../profile-avatar/profile-avatar';

import './user-photo.css';

const { number, string } = PropTypes;

class UserPhoto extends Component {
  static propTypes = {
    /** Фото пользователя */
    photo: string,

    /** Заполненность профиля */
    completeness: number
  };

  render() {
    const { completeness, photo, name } = this.props;

    return (
      <div
        className={cn('UserPhoto', {
          'UserPhoto--noDiagram': !completeness
        })}
      >
        {this.renderDiagram()}
        <div className="UserPhoto-holder">
          <ProfileAvatar name={name} photo={photo} resizeWidth={200} resizeHeight={200} />
        </div>
      </div>
    );
  }

  renderDiagram() {
    const { completeness } = this.props;

    if (!completeness) return null;
    return (
      <div className="UserPhoto-diagram">
        <Circle percent={completeness * 100} strokeWidth={2} trailWidth={2} prefixCls="UserPhoto" />
      </div>
    );
  }
}

export default UserPhoto;
