import { takeEvery, call, put, select } from 'redux-saga/effects';
import takeDebounced from 'modules/utils/saga/take-debounced';
import ifChanged from 'modules/utils/saga/if-changed';

import { replace, push } from 'connected-react-router';
import { ACCOUNT, SOCIAL } from '../ducks/types';
import accountActions from '../ducks/actions/account';
import * as socialActions from 'modules/core/ducks/actions/social';
import * as fromAccount from '../ducks/account';
import { getIndexLink } from 'routes/links';
import { AccountAdapter } from 'services/mixit/adapters';
import { mixitApi } from 'services/mixit';
import { createAccountPersistor } from 'services/local-storage';
import { types as PERSONAL_INFO_FORM } from 'modules/ordering/ducks/personal-info-form';

export function* authorizationWatcher() {
  yield takeEvery(ACCOUNT.REHYDRATE, rehydrate);
  yield takeEvery(ACCOUNT.SIGN_OUT, signOut);
  yield takeEvery(ACCOUNT.AUTHORIZATION_REQUEST, signIn);
  yield takeEvery(ACCOUNT.AUTHORIZATION_RENEW_REQUEST, renewAuthorization);
  yield takeEvery(SOCIAL.FACEBOOK_RECEIVE_AUTH, signInWithFacebook);
  yield takeEvery(SOCIAL.VK_RECEIVE_AUTH, signInWithVk);
  yield takeEvery(PERSONAL_INFO_FORM.SIGN_IN, signInOrder);
}

export function* authorizationReadyWatcher() {
  yield takeEvery(ACCOUNT.REHYDRATE, function*(action) {
    const { account } = action.payload;
    if (!account || !account.authorization) {
      yield put(accountActions.authorizationReady(false));
    }
  });

  yield takeEvery(ACCOUNT.AUTHORIZATION_SUCCESS, function*(action) {
    const { account } = action.payload;
    yield put(accountActions.authorizationReady(true, account));
  });

  yield takeEvery(ACCOUNT.AUTHORIZATION_ERROR, function*() {
    yield put(accountActions.authorizationReady(false));
  });

  yield takeEvery(ACCOUNT.AUTHORIZATION_RENEW_RESPONSE, function*(action) {
    const { error } = action.payload;
    if (error) {
      yield put(accountActions.authorizationReady(false));
      return;
    }
    const account = yield select(fromAccount.getCurrent);
    yield put(accountActions.authorizationReady(true, account));
  });

  yield takeEvery(ACCOUNT.REGISTRATION_COMPLETE, function*(action) {
    const { account } = action.payload;
    yield put(accountActions.authorizationReady(true, account));
  });

  yield takeEvery(ACCOUNT.REGISTRATION_ERROR, function*() {
    yield put(accountActions.authorizationReady(false));
  });

  yield takeEvery(ACCOUNT.SIGN_OUT, function*() {
    yield put(accountActions.authorizationReady(false));
  });
}

export function* signUpWatcher() {
  yield takeEvery(ACCOUNT.REGISTRATION_REQUEST, signUp);
}

export function* persistenceWatcher() {
  const accountPersistor = createAccountPersistor();

  const rehydrate = function*() {
    const account = yield call(accountPersistor.read);
    if (!account) {
      yield put(accountActions.ready());
    }
    yield put(accountActions.rehydrate(account));
  };

  const persist = function*(account) {
    yield call(accountPersistor.write, account);
  };

  const getState = function*() {
    return yield select(fromAccount.getCurrent);
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
  yield takeDebounced('*', ifChanged(getState, persist), 200);
}

function* signIn({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(mixitApi().account().signIn, email, password);

    const authorization = response.authorization;

    const { account, entities } = AccountAdapter.normalize(response.data);

    yield put(accountActions.authorizationSuccess(authorization, account, entities));
    yield put(replace({ state: { success: true } }));
  } catch (e) {
    console.warn(e);
    yield put(accountActions.authorizationError('Неверный логин или пароль'));
  }
}

function* signInOrder({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(mixitApi().account().signIn, email, password);

    const authorization = response.authorization;
    const { account, entities } = AccountAdapter.normalize(response.data);

    yield put(accountActions.authorizationSuccess(authorization, account, entities));

    yield put(replace({ state: { success: true } }));
  } catch (e) {
    console.warn(e);
    yield put(accountActions.authorizationError('Неверный логин или пароль'));
  }
}

function* renewAuthorization(action) {
  const { authorization: expirableAuth } = action.payload;

  try {
    const { authorization } = yield call(mixitApi(expirableAuth).account().refresh);

    yield put(accountActions.authorizationRenewResponse(null, authorization));
  } catch (e) {
    console.warn(e);

    yield put(accountActions.authorizationRenewResponse('Время авторизации истекло'));
  }
}

function* signInWithVk(action) {
  const { userId, accessToken } = action.payload;

  try {
    const response = yield call(mixitApi().social().vk, userId, accessToken);

    const authorization = response.authorization;
    const { account, entities } = AccountAdapter.normalize(response.data);

    yield put(accountActions.authorizationSuccess(authorization, account, entities));
    yield put(replace({ state: { success: true } }));
  } catch (error) {
    console.warn(error);
    yield put(
      accountActions.authorizationError('Пользователь, связанный с данным VK аккаунтом, не найден')
    );
  }
}

function* signInWithFacebook(action) {
  const { accessToken } = action.payload;

  try {
    const response = yield call(mixitApi().social().facebook, accessToken);

    const authorization = response.authorization;
    const { account, entities } = AccountAdapter.normalize(response.data);

    yield put(accountActions.authorizationSuccess(authorization, account, entities));
    yield put(replace({ state: { success: true } }));
  } catch (error) {
    console.warn(error);
    yield put(
      accountActions.authorizationError(
        'Пользователь, связанный с данным facebook аккаунтом, не найден'
      )
    );
  }
}

function* signOut() {
  yield put(push(getIndexLink()));

  if (window.FB) {
    yield put(socialActions.logoutFacebookAuth());
  }
}

function* rehydrate() {
  const authorization = yield select(fromAccount.getAuthorization);

  if (!authorization) {
    return;
  }

  yield put(accountActions.authorizationRenewRequest(authorization));
}

function* signUp({ payload }) {
  const { email, password, firstName } = payload;

  try {
    const response = yield call(mixitApi().account().signUp, email, password, firstName);

    const authorization = response.authorization;
    const { account, entities } = AccountAdapter.normalize(response.data);

    yield put(accountActions.registrationComplete(authorization, account, entities));
  } catch (e) {
    console.warn(e);
    yield put(accountActions.registrationError('Пользователь с данным e-mail уже зарегистрирован'));
  }
}
