import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MassMediaAction from '../ducks/actions';
import { getMassMedia } from '../ducks/selectors';
import MassMediaList from '../components/mass-media-list/mass-media-list';

const mapDispatchToProps = {
  request: MassMediaAction.request
};

const mapState = createStructuredSelector({
  massMedia: getMassMedia
});

class MassMediaListContainer extends Component {
  state = {
    sort: 'desc'
  };

  massMediaList = null;

  render() {
    const { massMedia } = this.props;

    console.log('state', this.state);

    return (
      <MassMediaList
        pageAmount={massMedia.totalPages}
        onPageChange={this.onPageChange}
        currentPage={massMedia.page}
        article={massMedia.article}
      />
    );
  }

  componentDidMount() {
    const { request, massMedia } = this.props;
    if (!massMedia.articles || massMedia.articles.length === 0) {
      request({});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.massMedia !== prevProps.massMedia) {
      const { massMedia } = this.props;
      this.setState({ sort: massMedia.sort });
    }
  }

  onPageChange = id => {
    const { request } = this.props;
    const { sort } = this.state;

    request({ page: id, sort });
    this.scrollElementToTop();
  };

  scrollElementToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    });
  };
}

export default connect(mapState, mapDispatchToProps)(MassMediaListContainer);
