import { combineReducers } from 'redux';

import { createReducer, createAction, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as fromMedia from 'modules/media/reducers/media';
// import { createSelector } from 'reselect';

// import { getArticleList } from '../ducks/';

import * as TYPES from '../types';
import produce from 'immer';

/* REDUCER NAME */
export const NAME = 'articleList';

/* REDUCERS */

const all = createReducer(
  on(TYPES.MAGAZINE_HOME_RESPONSE, (state, { entities }, error) => {
    if (error) {
      return state;
    }

    return {
      ...state,
      ...entities.articles
    };
  }),

  on(TYPES.MAGAZINE_ARTICLE_RESPONSE, (state, { slug, entities }, error) => {
    if (error) {
      return state;
    }

    return {
      ...state,
      ...entities.articles,
      [slug]: {
        ...entities.articles[slug],
        _details: true
      }
    };
  }),

  on(
    TYPES.MAGAZINE_ARTICLE_LIKE,
    produce((state, { slug }) => {
      if (!(slug in state)) {
        return;
      }
      state[slug].isLiked = true;
      state[slug].counters.likes += 1;
    })
  ),

  on(
    TYPES.MAGAZINE_ARTICLE_UNLIKE,
    produce((state, { slug }) => {
      if (!(slug in state)) {
        return;
      }
      state[slug].isLiked = false;
      state[slug].counters.likes -= 1;
    })
  )
);

const shares = createReducer(
  on(TYPES.MAGAZINE_ARTICLE_SHARE_ADD, (state, { slug, share }) => {
    const shares = state[slug] || [];

    return {
      ...state,
      [slug]: [...shares, share]
    };
  })
);

export default combineReducers({
  all: all({}),
  shares: shares({})
});

/* SELECTORS */

export const getSlugFromRouteParams = (_, { match }) => {
  return match.params.slug;
};

export const getSlug = (_, { slug }) => slug;

export const getRoot = state => state.blog.articleList;
export const getAll = createSelector(getRoot, root => root.all);
export const getShares = createSelector(getRoot, root => root.shares);

export const getItemByRouteParams = createSelector(
  getAll,
  getSlugFromRouteParams,
  (all, slug) => all[slug]
);

export const makeGetItemBySlug = getSlug => {
  return createSelector(getAll, getSlug, function _getItemBySlug(all, slug) {
    if (!all || !slug) {
      return null;
    }

    const article = all[slug];

    if (!article) {
      return null;
    }

    return {
      ...article,
      readingTime: 'PT10M'
    };
  });
};

export const getItemBySlug = makeGetItemBySlug(getSlug);

export const getFindItem = createSelector(getAll, fromMedia.getFindItem, function _getFindItem(
  all,
  findMedia
) {
  return slug => {
    if (!all || !slug) {
      return null;
    }

    const article = all[slug];

    if (!article) {
      return null;
    }

    return {
      ...article,
      author: {
        ...article.author,
        photo: findMedia(article.author.photo)
      },
      cover: findMedia(article.cover)
    };
  };
});

export const getDetailsBySlug = createSelector(getItemBySlug, function _getDetailsBySlug(article) {
  if (!article || !article.details) {
    return null;
  }

  return article.details;
});

export const getSharesBySlug = createSelector(getSlug, getShares, function _getSharesBySlug(
  slug,
  shares
) {
  if (!shares || !slug) {
    return null;
  }
  return shares[slug] || [];
});

export const getExploreArticleList = createSelector(getItemBySlug, item => item.relatedArticleList);

export const getItemAdBySlug = createSelector(getItemBySlug, function _getDetailsBySlug(article) {
  if (!article || !article.details) {
    return null;
  }

  return {
    title: article.title,
    slug: article.slug,
    cover: article.cover
  };
});

/* ACTION_CREATORS */

export const actions = {
  like(slug) {
    return createAction(TYPES.MAGAZINE_ARTICLE_LIKE, { slug });
  },

  unlike(slug) {
    return createAction(TYPES.MAGAZINE_ARTICLE_UNLIKE, { slug });
  },

  share(slug, name) {
    return createAction(TYPES.MAGAZINE_ARTICLE_SHARE, { slug, name });
  }
};

/* HELPERS */
