import React, { Component } from 'react';
import SubscribeContainer from 'modules/core/containers/subscribe';
import Container from 'modules/core/components/container/container';
import SectionDefault from 'modules/core/components/section-default/section-default';

class SubscriptionSection extends Component {
  state = {};
  render() {
    return (
      <SectionDefault highlightBackground>
        <Container>
          <SubscribeContainer />
        </Container>
      </SectionDefault>
    );
  }
}

export default SubscriptionSection;
