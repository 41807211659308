import React from 'react';
import cn from 'classnames';

import './activity-card.css';

export default function ActivityCard({ data, colors }) {
  const specialistStyle = { color: colors[0] };
  const dateStyle = { WebkitTextStrokeColor: colors[0] };
  return (
    <div className={cn('ActivityCard')}>
      <div className="ActivityCard-date" style={dateStyle}>
        {data.date}
      </div>
      <div className="ActivityCard-specialist" style={specialistStyle}>
        {data.specialist}
      </div>
      <div className="ActivityCard-schedule">{data.schedule}</div>
      <div className="ActivityCard-location">{data.location}</div>
    </div>
  );
}
