import React from 'react';
import cn from 'classnames';

import CircleBorderedImage from '../../../../circle-bordered-image/circle-bordered-image';

import './activity-detail.css';

export default function ActivityDetail({ data, colors, index }) {
  return (
    <div className={cn('ActivityDetail')}>
      <div className="ActivityDetail-imageHolder">
        <div className="ActivityDetail-image">
          <div className="ActivityDetail-imageInner">
            <CircleBorderedImage image={data.image} colors={colors} inverted={index % 2 !== 0} />
          </div>
        </div>
      </div>
      <div className="ActivityDetail-title">{data.title}</div>
    </div>
  );
}
