import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import actions from '../ducks/actions/feedback';
import * as fromFeedback from '../ducks/feedback';
import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromAccount from 'modules/core/ducks/account';

import ComplainForm from '../components/complain-form/complain-form';

const mapStateToProps = createStructuredSelector({
  isLoading: fromFeedback.getIsFetching,
  profileFirstName: fromProfile.getFirstName,
  profilePhone: fromProfile.getPhone,
  profileEmail: fromAccount.getEmail,
});

const mapDispatchToProps = {
  submit: actions.send
};

class ComplainFormContainer extends Component {
  state = {
    phone: this.props.profilePhone || "",
    photoFileName: ''
  };

  _photoInputElement = null;

  render() {
    const { phone, photoFileName,  } = this.state;
    const { isLoading, description, withoutFile, options } = this.props;

    return (
      <ComplainForm
        isLoading={isLoading}
        phone={phone}
        photoFileName={photoFileName}
        photoInputRef={this.setPhotoInputRef}
        onPhoneChange={this.handlePhoneChange}
        onPhotoChange={this.handlePhotoChange}
        onSubmit={this.handleSubmit}
        description={description}
        withoutFile={withoutFile}
        options={options}
      />
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setPhotoInputRef = element => {
    if (!element) {
      return;
    }
    this._photoInputElement = element;
  };

  setPhone(phone) {
    this.setState({
      phone
    });
  }

  setPhotoName(photoFileName) {
    this.setState({
      photoFileName
    });
  }

  resetFeedbackFormState() {
    this.setState({
      phone: '',
      photoFileName: ''
    });
  }

  createFeedbackData = formData => {
    const { phone } = this.state;
    const {withoutFile} = this.props;

    const firstName = formData.get('firstname');
    const email = formData.get('email');
    const text = formData.get('text');
    const photoFile = !withoutFile ? formData.get('photo_file') : null;

    const feedback = {
      text,
      firstName,
      email,
      phone,
      ...(Boolean(photoFile) && {photoFile})
    };

    return feedback;
  };

  handlePhoneChange = phone => {
    this.setPhone(phone);
  };

  handleSubmit = e => {
    const { submit } = this.props;
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = this.createFeedbackData(formData);

    submit(data);

    this.resetFeedbackFormState();
    form.reset();
  };

  handlePhotoChange = name => {
    this.setPhotoName(name);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplainFormContainer);
