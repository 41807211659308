import React, { Component } from 'react';

import './icon-cross.css';

class IconCross extends Component {
  render() {
    return <span className="IconCross" />;
  }
}

export default IconCross;
