import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { conformToMask } from 'react-text-mask';
import cn from "classnames";

import Input from 'modules/form/components/input/input';
// import InputAreaCode from './input-area-code/input-area-code';

import sliceUnusedParenthesis from './helpers/slice-unused-parenthesis';
import getPlaceholder from './helpers/get-placeholder';
import getMask from './helpers/get-mask';
import getSeparatedPhone from './helpers/get-separated-phone';
import hasAreaCode from './helpers/has-area-code';

import './input-phone-with-area-code.css';

const { string, func, arrayOf, shape, number, bool } = PropTypes;

const WARNING_TEXTS= {
  phoneFormat: "Проверьте, пожалуйста, номер. Вводите телефон без 8"
}

class InputPhoneWithAreaCode extends Component {
  static propTypes = {
    codeList: arrayOf(
      shape({
        id: number,
        title: string,
        value: string,
        name: string
      })
    ),
    value: string,
    size: string,
    forceDropdown: bool,
    onChange: func,
    onPhoneChange: func
  };

  state = {
    wasEdited: false,
  };

  static defaultProps = {
    size: 'small'
  };

  // getRenderValue = () => {
  //   const { wasEdited } = this.state;
  //   const { value } = this.props;

  //   const cleanedValue = String(value).replace(/\D/g,'')

  //   if(Boolean(value) && Boolean(cleanedValue)) {
  //     return this.getMaskedPhone(value).trim()
  //   } else if (Boolean(value)){
  //       return value;
  //   }

  //   return wasEdited ? "" : "+7";
  // }


  render() {
    const {  size, codeList, value, disabled } = this.props;
    const codeId = this.getCode(value);
    const code = codeList[codeId];
    const {phoneLength} = code
    const placeholder = getPlaceholder(phoneLength, true);

    const {wasEdited} = this.state;

    const cleanedValue = String(value).replace(/\D/g,'')

    const isFullLength = phoneLength <= cleanedValue.length

    // const { warning } = this.state;

    const _value = Boolean(value) ? this.getMaskedPhone(value).trim() : wasEdited ? "+" : "+7";
    // const _value = this.getRenderValue();

    

    return (
      <div className={cn("InputPhoneWithAreaCode", {
        "InputPhoneWithAreaCode--isFullLength": isFullLength
      })}>
        <div className="InputPhoneWithAreaCode-phoneField">
          <Input
            size={size}
            // type="tel"
            placeholder={placeholder}
            // {...rest}
            // value={this.getValue()}
            value={_value}
            disabled={disabled}
            onChange={this.handleInputChange}
            onPaste={this.handlePasteDate}
            onInput={this.handleInput}
            autoComplete="on"
            name="mobilephone"
          />
          {/* <input {...rest} value={Boolean(value) ? value === "+" ? null : this.getMaskedPhone(value).trim(): ""} onChange={this.handleInputChange}/> */}
          {/* {Boolean(warning) && (
            <div className="InputPhoneWithAreaCode-phoneFieldWarning">
              {warning}
              <button onClick={this.handleWarningTooltipClose} className="InputPhoneWithAreaCode-phoneFieldWarningCloce">&times;</button>
            </div>
          ) } */}
        </div>
      </div>
    );
  }


  handleInput = (e) => {
  }

  handleWarningTooltipClose=()=>{
    this.setState({
      warning: null
    })
  }

  getValue() {
    const { value } = this.props;
    // const _value = "+" + value.replace(/\+/, "")
    const { codeList } = this.props;
    const separatedPhone = getSeparatedPhone(value, codeList).phone;

    return this.getMaskedPhone(separatedPhone);
  }

  getCode(value) {
    // const _value = "+" + value.replace(/\+/, "")
    const { codeList } = this.props;
    const separatedPhone = getSeparatedPhone(value, codeList);

    return separatedPhone.code ? separatedPhone.code.id : 0;
  }

  /** helpers - start */
  getUsedMask(value) {
    const { codeList } = this.props;

    const codeId = this.getCode(value);
    const code = codeList[codeId];
    const usedMask = getMask(code.phoneLength, true);

    return usedMask;
  }

  getMaskedPhone(value) {
    const usedMask = this.getUsedMask(value);
    const maskedPhone = conformToMask(value, usedMask, {
      guide: false
    }).conformedValue.trim();

    const cleanedPhone = sliceUnusedParenthesis(maskedPhone);

    return cleanedPhone;
  }
  /** helpers - end */

  /** state changers - end */
  // open() {
  //   this.setState({
  //     isShowed: true
  //   });
  // }

  // close() {
  //   this.setState({
  //     isShowed: false
  //   });
  // }
  /** state changers - end */

  /** handlers - start */
  // handleCodeSelectClose = () => {
  //   this.close();
  // };

  // handleCodeSelectOpen = () => {
  //   this.open();
  // };

  // handleCodeIdSelect = codeId => {
  //   const { codeList } = this.props;

  //   const phone = this.getValue();

  //   this.changePhone(phone, codeList[codeId]);

  //   this.close();
  // };

  handleInputChange = e => {
    const { value } = e.target;

    this.setState({wasEdited: true})

    // const isTyped = Boolean(e.nativeEvent.data);
    // const _value = isTyped ? value : "+" + value.replace(/\+/, "");
    const _value = "+" + value.replace(/\D/g,'').replace(/\+/, "").trim();

    // console.log("InputPhoneWithAreaCode -> _value", _value)
    // const { codeList } = this.props;

    // /** условие может сработать только если телефон был вставлен (с "+") */
    // if (hasAreaCode(_value, codeList)) {
    //   console.log("hasAreaCode InputPhoneWithAreaCode -> value", _value)
    //   const separatedPhone = getSeparatedPhone(value, codeList);
    //   console.log("hasAreaCode InputPhoneWithAreaCode -> separatedPhone", separatedPhone)

    //   this.changePhone(separatedPhone.phone, separatedPhone.code);
    //   // this.checkWarning(value)
    //   return;
    // }
    this.changePhone(_value);
    // this.checkWarning(value)
  };

  handlePasteDate = e => {
    e.preventDefault();
    const { codeList } = this.props;
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    if (hasAreaCode(pastedData, codeList)) {
      const separatedPhone = getSeparatedPhone(pastedData, codeList);
      this.changePhone(separatedPhone.phone, separatedPhone.code);
      // this.checkWarning(separatedPhone.phone)
      return;
    }

    // this.checkWarning(pastedData)
    this.changePhone(pastedData);
  };
  /** handlers - end */


  checkWarning(value) {
    const cleanedValue = value ?  String(value).replace(/\D/g,'') : null
    if (value && cleanedValue.startsWith("8")){
        this.setState({
          warning: WARNING_TEXTS.phoneFormat
        })
      } else {
        this.setState({
          warning: null
        })
      }

  }

  /** send data outside */

  changePhone(phone, code = null) {
    const maskedPhone = code ? this.getMaskedPhone(code.value + phone):  this.getMaskedPhone(phone);

    if (code) {
      this.changeValue(code.id, maskedPhone);
      return;
    }

    const _code = this.getCode();

    this.changeValue(_code, maskedPhone);
  }

  changeValue(codeId, phone) {
    const { 
      // codeList, 
      onChange } = this.props;
    // const usedMask = this.getUsedMask();
    // const maskedPhone = conformToMask(phone, usedMask, { guide: false }).conformedValue;

    // const fullNumber = codeList[codeId].value + ' ' + maskedPhone;

    const value = "+" + phone.replace(/\+/, "").trim()
    const _value = value === "+" ? "" : value;

    onChange(_value);
  }
}

export default InputPhoneWithAreaCode;
