import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromFilterOptions from '../ducks/filter-options';
import FilterTag from '../components/filter-tag/filter-tag';

const mapStateToProps = createStructuredSelector({
  filterOption: fromFilterOptions.getItemBySlug
});

class FilterOption extends Component {
  static propTypes = {
    /** Filter option slug */
    slug: PropTypes.string.isRequired
  };

  render() {
    const { filterOption } = this.props;

    return <FilterTag title={filterOption.title} onRemove={this.handleRemove} />;
  }

  handleRemove = () => {
    const { onRemove, slug } = this.props;

    onRemove(slug);
  };
}

export default connect(mapStateToProps)(FilterOption);
