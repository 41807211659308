import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import contains from 'dom-helpers/query/contains';

import productShape from 'modules/product/prop-types/product-shape';
import isFunction from 'modules/utils/is-function';
import Loader from 'modules/core/components/loader/loader';
import LockScroll from 'modules/core/components/lock-scroll/lock-scroll';

import IconContainer from '../icon-container/icon-container';
import { IconSearch, IconSignArrowLeft } from '../icons';

import './search-overlay.css';
import Product from './product';
import RecentProduct from './recent-product';

const { string, arrayOf } = PropTypes;

const ESC_KEY = 27;

class SearchOverlay extends Component {
  static propTypes = {
    query: string,
    searchResult: arrayOf(productShape),
    recentProducts: arrayOf(productShape)
  };

  searchContent = null;
  field = null;

  render() {
    const { query, searchResult, recentProducts, isLoading } = this.props;

    const hasResults = searchResult && searchResult.length > 0;
    const hasRecent = recentProducts && recentProducts.length > 0;

    return (
      <Portal>
        <LockScroll />
        <div className="SearchOverlay" onClick={this.handleOverlayClick}>
          <div className="SearchOverlay-content" ref={this.setSearchContentRef}>
            <form className="SearchOverlay-field" onSubmit={this.handleSubmit}>
              <div className="SearchOverlay-prepended">
                <button type="button" className="SearchOverlay-button" onClick={this.handleCancel}>
                  <IconContainer inline>
                    <IconSignArrowLeft />
                  </IconContainer>
                </button>
              </div>
              <input
                className="SearchOverlay-input"
                placeholder="Начните вводить название продукта"
                value={query}
                type="search"
                onChange={this.handleChange}
                ref={this.handleFieldRef}
                autoFocus
              />
              <div className="SearchOverlay-appended">
                <button type="submit" className="SearchOverlay-button">
                  <IconContainer inline>
                    <IconSearch />
                  </IconContainer>
                </button>
              </div>
            </form>

            <div className="SearchOverlay-body">
              {isLoading && (
                <div className="SearchOverlay-loader">
                  <Loader type="spin" size="small" />
                </div>
              )}

              {hasResults && (
                <ul className="SearchOverlay-resultList">
                  {searchResult.map(product => (
                    <li key={product.id} className="SearchOverlay-resultItem">
                      <Product product={product} query={query} onClose={this.props.onClose} />
                    </li>
                  ))}
                </ul>
              )}

              {hasRecent && (
                <div className="SearchOverlay-recent">
                  <div className="SearchOverlay-recentTitle">Недавно просмотренные товары:</div>
                  <ul className="SearchOverlay-recentList">
                    {recentProducts.map(product => (
                      <li key={product.id} className="SearchOverlay-recentItem">
                        <RecentProduct
                          product={product}
                          query={query}
                          onClick={this.handleRecentClick}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  close() {
    const { onClose } = this.props;

    if (!isFunction(onClose)) {
      return;
    }
    onClose();
  }

  setSearchContentRef = element => {
    if (!element) return;

    this.searchContent = element;
  };

  isOverlay = target => {
    return !contains(this.searchContent, target);
  };

  handleRecentClick = () => {
    this.close();
  };

  handleChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    if (!isFunction(onChange)) {
      return;
    }
    onChange(value);
  };

  handleCancel = () => {
    this.close();
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onSubmit, query } = this.props;

    if (this.field) {
      this.field.blur();
    }

    if (!isFunction(onSubmit)) {
      return;
    }
    onSubmit(query);
  };

  handleOverlayClick = event => {
    const { target } = event;

    if (!this.isOverlay(target)) {
      return;
    }

    this.close();
  };

  handleKeyDown = e => {
    const { keyCode } = e;

    if (keyCode !== ESC_KEY) {
      return;
    }

    this.close();
  };

  handleFieldRef = element => {
    this.field = element;
  };
}

export default SearchOverlay;
