import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import {
  IconVk,
  // IconInstagram - deprecated,
  IconFacebook,
  IconYoutubeInverted,
  // IconYoutube - deprecated,
  IconInstagram2
} from 'modules/core/components/icons';

import './button.css';

const { oneOf } = PropTypes;

const buttonToIconSize = {
  mediumPlus: 'medium',
  medium: 'normal',
  normal: 'small',
  small: 'xsmall'
};

const ICONS = {
  vk: <IconVk />,
  instagram: <IconInstagram2 />,
  facebook: <IconFacebook />,
  youtube: <IconYoutubeInverted />
};

class Button extends Component {
  static propTypes = {
    /**
     * Название социальной сети.
     * В зависимости от значения этого атрибута меняется цвет заливки кнопки при наведении, а также иконка.
     */
    social: oneOf(['vk', 'instagram', 'facebook', 'youtube']).isRequired,

    /** Размер кнопки */
    size: oneOf(['normal', 'small'])
  };

  static defaultProps = {
    size: 'normal',
    component: 'button'
  };

  render() {
    const { social, size, component: Component, ...rest } = this.props;
    return (
      <Component
        className={classNames('Social_Button', `Social_Button--size-${size}`)}
        type="button"
        {...rest}
      >
        <IconContainer size={buttonToIconSize[size]}>{ICONS[social]}</IconContainer>
      </Component>
    );
  }
}

export default Button;
