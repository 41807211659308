import React from 'react';
import Container from 'modules/core/components/container/container';

// import ConditionalRenderer from 'modules/core/components/conditional-renderer/conditional-renderer';
// import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import Json from './Json';

import { arrayToObject } from 'modules/utils';

import './desctiption_section.css';

export default function DesctiptionSection({ data, totalData }) {
  const components = arrayToObject(totalData.components, 'type');
  // const renderConditionalConfig = {
  //   isBrowser: true
  // };
  return (
    <div className="DesctiptionSection">
      <Container>
        <div className="DesctiptionSection-title">{data.text}</div>
        <div className="DesctiptionSection-json">
          <Json components={components[data.text]} />
        </div>
        {/* <HideErrorBoundary>
          <ConditionalRenderer config={renderConditionalConfig}>
            <div className="DesctiptionSection-json">
              <Json components={components[data.text]} />
            </div>
          </ConditionalRenderer>
        </HideErrorBoundary> */}
      </Container>
    </div>
  );
}
