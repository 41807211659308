import React, { Component, Fragment } from 'react';

import AutocompleteInputProvider from 'modules/form/components/autocomplete/autocomplete-input-provider';

import './select-city-desktop.css';

class SelectCityDesktop extends Component {
  render() {
    const { cityList, activeOptionIndex, isDisabled } = this.props;

    return (
      <div className="SelectCityDesktop">
        <AutocompleteInputProvider
          options={cityList}
          defaultActiveOptionIndex={activeOptionIndex}
          onOptionClick={this.handleOptionClick}
          selectTitle={option => {
            if (!option.country) {
              return option.title;
            }

            return `${option.title}, ${option.country.title}`;
          }}
          renderTitle={option => (
            <span className="SelectCityDesktop-option">
              {option.title}
              {option.country ? (
                <Fragment>
                  , <span className="SelectCityDesktop-optionCountry">{option.country.title}</span>
                </Fragment>
              ) : (
                ''
              )}
            </span>
          )}
          placeholder="Выберите город"
          disabled={isDisabled}
        />
      </div>
    );
  }

  handleOptionClick = option => {
    const { onCitySelect } = this.props;

    onCitySelect(option.id);
  };
}

export default SelectCityDesktop;
