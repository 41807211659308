export default {
  serialize(lastOrder) {
    return JSON.stringify(lastOrder);
  },

  deserialize(persistedJson) {
    const lastOrder = JSON.parse(persistedJson);

    return lastOrder;
  }
};
