import React, { Component } from 'react';
import OrderStepSummary from '../order-step-summary/order-step-summary';
import './personal-info-summary.css';

class PersonalInfoSummary extends Component {
  render() {
    const { phone, email, firstName, lastName,  ...summaryProps } = this.props;
    return (
      <div className="PersonalInfoSummary">
        <OrderStepSummary title={this.getTitle()} {...summaryProps}>
          <div className="PersonalInfoSummary-row">{phone}</div>
          <div className="PersonalInfoSummary-row">{email}</div>
        </OrderStepSummary>
      </div>
    );
  }

  getTitle = () => {
    const { firstName, lastName } = this.props;
    let title = firstName;

    if(Boolean(lastName)) {
      title = title + " " + lastName
    }
    return title;
  }
}

export default PersonalInfoSummary;
