import React, { Component } from 'react';

import Dialog from '../dialog/dialog';
import DialogSection from '../dialog/dialog-section/dialog-section';

import './password-recovery-request-success-dialog.css';

class PasswordRecoveryRequestSuccessDialog extends Component {
  render() {
    const { onClose, ...rest } = this.props;

    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="PasswordRecoveryRequestSuccessDialog-content">
          <DialogSection title="Восстановление пароля">
            <div className="PasswordRecoveryRequestSuccessDialog-info">
              Инструкция по восстановлению пароля отправлены на Ваш email
            </div>
          </DialogSection>
        </div>
      </Dialog>
    );
  }
}

export default PasswordRecoveryRequestSuccessDialog;
