import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Link from '../link/short-link';
import ShowOnMapIcon from '../show-on-map-icon/show-on-map-icon';

import Guide from './guide';

import { weekdaysToString } from 'modules/utils';

import geographyShape from '../../prop-types/geography-shape';
import guideShape from '../../prop-types/guide-shape';

import './shop.css';

const { string, number, shape, arrayOf } = PropTypes;

const FEATURE_TYPES = {
  'FEATURE/PPD': 'ppd',
  'FEATURE/STORE_OPENING': 'store-opening'
};

class Shop extends Component {
  static propTypes = {
    /**
     * Shop info
     */
    shop: shape({
      id: number,
      title: string,
      geography: geographyShape,
      guides: arrayOf(guideShape),
      schedule: arrayOf(
        shape({
          dayList: arrayOf(number),
          time: string
        })
      ),
      phones: arrayOf(string)
    }).isRequired
  };

  render() {
    const { shop, isActive } = this.props;
    const isShopGuidesExists = !!shop.guides;
    const isNew = shop.isNew;

    return (
      <div className={cn('Shop', { 'Shop--active': isActive })}>
        <div className="Shop-contentCol">
          <div className="Shop-col">
            <h3 className="Shop-title">{shop.title}</h3>

            {isShopGuidesExists && (
              <ul className="Shop-guideList">
                {shop.guides.map((guide, index) => (
                  <li key={index} className="Shop-guideItem">
                    <Guide title={guide.title} subwayLine={guide.subwayLine} type={guide.type} />
                  </li>
                ))}
              </ul>
            )}

            <div className="Shop-address">{shop.geography.address}</div>

            <div className="Shop-showOnMap">
              <Link
                prependedIcon={<ShowOnMapIcon active={isActive} />}
                component="button"
                type="button"
                onClick={this.handleClick}
              >
                Показать на карте
              </Link>
            </div>
          </div>

          <div class="Shop-col">
            <ul className="Shop-phoneList">
              {shop.phones.map((phone, index) => (
                <li key={index} className="Shop-phoneItem">
                  <a href={`tel:${phone}`} className="Shop-phoneItemLink">
                    <span className="FooterContacts-number">{phone}</span>
                  </a>
                </li>
              ))}
            </ul>

            <ul className="Shop-scheduleList">
              {shop.schedule
                ? shop.schedule.map(this.renderSchedule)
                : this.renderSingleSchedule(shop.schedule_raw)}
            </ul>
            {this.renderFeatures()}
          </div>
        </div>

        <div className="Shop-labelCol">{isNew && <div className="Shop-labelNew">New</div>}</div>
      </div>
    );
  }

  renderFeatures() {
    const {
      shop: { features }
    } = this.props;

    if (!features || features.length === 0) {
      return null;
    }

    const existingFeatures = features.filter(item => !!item.title);

    return (
      <ul className="Shop-featureList">
        {existingFeatures.map((feature, index) => (
          <li
            key={index}
            className={cn('Shop-feature', {
              [`Shop-feature--${FEATURE_TYPES[feature.type]}`]:
                feature.type && Boolean(FEATURE_TYPES[feature.type])
            })}
            title={feature.description}
            style={feature.style}
          >
            {feature.title}
          </li>
        ))}
      </ul>
    );
  }

  renderPhone = (item, index) => {
    return (
      <li key={index} className="Shop-phoneItem">
        {item}
      </li>
    );
  };

  renderSchedule = (item, index) => {
    return (
      <li key={index} className="Shop-scheduleItem">
        <span className="Shop-days">{weekdaysToString(item.dayList)}</span>
        <span className="Shop-time">{item.time}</span>
      </li>
    );
  };

  renderSingleSchedule = schedule => {
    return (
      <li className="Shop-scheduleItem">
        <span className="Shop-time">{schedule}</span>
      </li>
    );
  };

  handleClick = () => {
    const { shop, onShopSelect } = this.props;

    onShopSelect(shop);
  };
}

export default Shop;
