import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromDialogs from '../ducks/dialogs';
import AcquaintanceDialog from '../components/acquaintance-dialog/acquaintance-dialog';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsAcquaintanceOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close
};

class AcquaintanceDialogContainer extends Component {
  render() {
    const { isOpened, close } = this.props;
    return <AcquaintanceDialog show={isOpened} onClose={close} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquaintanceDialogContainer);
