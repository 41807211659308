import pickPointListSeeds from './seeds/pick-point-list';
import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import * as CITY_DELIVERY from './types/city-delivery';
import * as ORDERS from './types/orders';

/* REDUCER NAME */
export const NAME = 'pickPointList';

/* ACTIONS */

const RESET = 'ordering/pick-point-list/RESET';

/* REDUCERS */

const reducer = createReducer(
  handle(RESET, () => pickPointListSeeds),
  handle(CITY_DELIVERY.PICK_POINT_RESPONSE, (state, { pickPoints }) => {
    if (!pickPoints || !pickPoints.entities || !pickPoints.entities.pickPoint) {
      return state;
    }

    return {
      ...state,
      ...pickPoints.entities.pickPoint
    };
  }),
  handle(ORDERS.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order || !order.delivery || !order.delivery.pickPoint) {
      return state;
    }

    const pickPoint = order.delivery.pickPoint;

    return {
      ...state,
      [pickPoint.id]: pickPoint
    };
  })
);

export default reducer(pickPointListSeeds);

/* SELECTORS */

export const getRoot = state => state.ordering.pickPointList;

export const getAll = getRoot;

const getId = (_, { id }) => id;

export const getPickPoint = createSelector(
  getAll,
  getId,
  (all, id) => {
    if (!id || !all) {
      return null;
    }

    return all[id];
  }
);

export const makeGetItemById = createSelector(
  getAll,
  function makeGetItemById(all) {
    return function _getItemById(id) {
      if (!all || !id) {
        return null;
      }

      const pickPoint = all[id];

      if (!pickPoint) {
        return null;
      }

      return pickPoint;
    };
  }
);
