export default {
  serialize(utm) {
    const persisted = utm.utmList;

    return JSON.stringify(persisted);
  },

  deserialize(persistedJson) {
    const persisted = JSON.parse(persistedJson);

    return persisted;
  }
};
