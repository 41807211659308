import React, { Component } from 'react';
import BasketDropdown from 'modules/ordering/components/basket-dropdown/basket-dropdown';
import ProfileDropdown from 'modules/profile/components/profile-dropdown/profile-dropdown';
import { IconBasket, IconUser } from '../icons';
import ProfileButton from './profile-button/profile-button';
import HeaderButton from '../header-button/header-button';
import { Link } from 'react-router-dom';
import { getProfileLink, getBasketLink } from 'routes/links';
import Media from 'react-media';

import './user-menu.css';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

class UserMenu extends Component {
  render() {
    const {
      photo,
      firstName,
      basketProducts,
      removeProduct,
      openAuthDialog,
      deliveryCost,
      productsCount,
      productsCost,
      productsAmount,
      isAuthorized,
      isProfileLoading,
      firstNameLetter,
      isBasketDropdownShowed,
      isProfileDropdownShowed,
      isBasketFetching
    } = this.props;

    return (
      <div className="UserMenu">
        <div className="UserMenu-item">
          {isAuthorized || isProfileLoading ? (
            <Media query="(max-width:1100px)">
              {isMobile =>
                isMobile ? (
                  <ProfileButton
                    to={getProfileLink()}
                    photo={photo}
                    firstName={firstName}
                    isLoading={isProfileLoading}
                    firstNameLetter={firstNameLetter}
                  />
                ) : (
                  <ProfileDropdown
                    trigger={
                      <div title={isProfileLoading ? 'Загрузка личного кабинета...' : null}>
                        <ProfileButton
                          to={getProfileLink()}
                          photo={photo}
                          firstName={firstName}
                          isLoading={isProfileLoading}
                          firstNameLetter={firstNameLetter}
                        />
                      </div>
                    }
                    action="hover"
                    align="right"
                    show={isProfileDropdownShowed && !isProfileLoading}
                    onChange={this.handleProfileDropdownChange}
                  />
                )
              }
            </Media>
          ) : (
            <HeaderButton
              onClick={openAuthDialog}
              title="Авторизоваться"
              isLoading={isProfileLoading}
              icon={<IconUser />}
            />
          )}
        </div>
        <div className="UserMenu-item">
          <BasketDropdown
            trigger={
              <HeaderButton
                component={Link}
                to={getBasketLink()}
                count={productsAmount}
                title="Корзина"
                caption={productsCost > 0 && <Price number={productsCost} />}
                icon={<IconBasket />}
              />
            }
            action="hover"
            align="right"
            productsCount={productsCount}
            show={isBasketDropdownShowed}
            onChange={this.handleBasketDropdownChange}
            productList={basketProducts}
            removeProduct={removeProduct}
            deliveryCost={deliveryCost}
            productsCost={productsCost}
            isBasketFetching={isBasketFetching}
          />
        </div>
      </div>
    );
  }

  handleBasketDropdownChange = isOpened => {
    const { closeBasketDropdown, openBasketDropdown } = this.props;

    if (isOpened) {
      openBasketDropdown();
    } else {
      closeBasketDropdown();
    }
  };

  handleProfileDropdownChange = isOpened => {
    const { closeProfileDropdown, openProfileDropdown } = this.props;

    if (isOpened) {
      openProfileDropdown();
    } else {
      closeProfileDropdown();
    }
  };
}

export default UserMenu;
