import React, { Children, Component } from 'react';
import Media from 'react-media';
import Carousel from '../carousel/carousel';
import { IconArrowLeft, IconArrowRight } from '../icons';
import Item from './item';
import './main-carousel.css';
import IconContainer from '../icon-container/icon-container';

const PER_PAGE = 4;

class MainCarousel extends Component {
  state = {
    currentPage: 0
  };

  render() {
    const { children } = this.props;
    const { currentPage } = this.state;

    const hasPages = Children.count(children) > PER_PAGE;

    return (
      <Media query="(max-width: 1100px)">
        {isMobile => (
          <div className="MainCarousel">
            <div className="MainCarousel-content">
              {isMobile ? (
                children
              ) : (
                <Carousel
                  perPage={PER_PAGE}
                  currentPage={currentPage}
                  overflowVisible={true}
                  onChange={this.handlePageChange}
                >
                  {children}
                </Carousel>
              )}
            </div>
            {hasPages && this.renderControls(isMobile)}
          </div>
        )}
      </Media>
    );
  }

  renderControls(isMobile) {
    return (
      !isMobile && (
        <div className="MainCarousel-controls">
          <button type="button" className="MainCarousel-prev" onClick={this.handlePrevButtonClick}>
            <IconContainer size="xsmall">
              <IconArrowLeft />
            </IconContainer>
          </button>
          <button type="button" className="MainCarousel-next" onClick={this.handleNextButtonClick}>
            <IconContainer size="xsmall">
              <IconArrowRight />
            </IconContainer>
          </button>
        </div>
      )
    );
  }

  handleNextButtonClick = e => {
    e.preventDefault();
    this.goToNextPage();
  };

  handlePrevButtonClick = e => {
    e.preventDefault();
    this.goToPrevPage();
  };

  handlePageChange = data => {
    this.changePage(data.page);
  };

  changePage(page) {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    const lastPage = childrenCount - PER_PAGE;
    const firstPage = 0;

    if (page >= lastPage) {
      this.setState({
        currentPage: lastPage
      });
      return;
    }

    if (page <= firstPage) {
      this.setState({
        currentPage: firstPage
      });
      return;
    }

    this.setState({
      currentPage: page
    });
  }

  goToNextPage() {
    const { children } = this.props;
    const childrenCount = Children.count(children);
    if (this.state.currentPage >= childrenCount - PER_PAGE) {
      return;
    }
    this.setState(state => ({
      currentPage: state.currentPage + 1
    }));
  }

  goToPrevPage() {
    if (this.state.currentPage <= 0) {
      return;
    }

    this.setState(state => ({
      currentPage: state.currentPage - 1
    }));
  }
}

MainCarousel.Item = Item;

export default MainCarousel;
