import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import { Portal } from 'react-portal';
import { Spring, animated } from 'react-spring';
import contains from 'dom-helpers/query/contains';
import isFunction from 'modules/utils/is-function';
import LockScroll from '../lock-scroll/lock-scroll';
import './overlay.css';

const getProgressFromOpenState = openState => (openState ? 1 : 0);

class Overlay extends Component {
  spring = React.createRef();
  element = React.createRef();
  content = React.createRef();

  state = {
    opened: null,
    animating: false
  };

  static getDerivedStateFromProps(props, prevState) {
    if (prevState.opened !== props.opened) {
      return {
        opened: props.opened,
        animating: prevState.opened !== null
      };
    }

    return prevState;
  }

  render() {
    const { opened, animating } = this.state;
    const { scrollable, fullOppened, withoutSpacer } = this.props;
    const target = getProgressFromOpenState(opened);
    const from = animating ? getProgressFromOpenState(!opened) : target;

    return (
      <Fragment>
        {opened && <LockScroll />}
        <Portal>
          <Spring
            native
            reset
            from={{ progress: from }}
            to={{ progress: target }}
            ref={this.spring}
            immediate={!animating}
            onRest={this.handleAnimationRest}
          >
            {({ progress }) => (
              <animated.div
                className={cn('Overlay', {
                  'Overlay--scrollable': scrollable,
                  'Overlay--fullOppened': fullOppened,
                  'Overlay--withoutSpacer': withoutSpacer
                })}
                style={{
                  display: progress.interpolate(x => x === 0 && 'none'),
                  zIndex: progress.interpolate(x => x === 0 && '-1'),
                  opacity: progress
                }}
                onTouchStart={this.handleTouchStart}
                onTouchCancel={this.handleTouchCancel}
                onTouchEnd={this.handleTouchEnd}
                onClick={this.handleClick}
                ref={this.element}
              >
                <div className="Overlay-spacer" appearance="none" />
                <animated.div
                  className="Overlay-content"
                  ref={this.content}
                  style={{
                    transform: progress.interpolate(x => {
                      const value = scrollable ? `${320 * (1 - x)}px` : `${100 * (1 - x)}%`;

                      return `translateY(${value})`;
                    })
                  }}
                >
                  {this.props.children}
                </animated.div>
              </animated.div>
            )}
          </Spring>
        </Portal>
      </Fragment>
    );
  }

  stopAnimation() {
    this.spring.current.stop();
    this.setState({
      animating: false
    });
  }

  handleClick = event => {
    const { onClose } = this.props;

    if (!contains(this.content.current, event.target) && isFunction(onClose)) {
      onClose();
    }
  };

  handleAnimationRest = () => {
    const { animating } = this.state;

    if (!animating) {
      return;
    }

    this.setState({
      animating: false
    });
  };
}

export default Overlay;
