import featuredArticleListSeeds from './seeds/featured-article-list';
import { combineReducers } from 'redux';
import LOADING_STATE from 'modules/core/constants/loading-state';

import { createReducer /*, createAction */, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as TYPES from '../types';
import * as fromArticleList from './article-list';

/* ACTIONS */

/* REDUCERS */

const featured = createReducer(
  on(TYPES.MAGAZINE_HOME_RESPONSE, (state, { promoted }, error) => {
    if (error) {
      return state;
    }
    return promoted;
  })
);

const status = createReducer(
  on(TYPES.MAGAZINE_HOME_REQUEST, () => LOADING_STATE.LOADING),
  on(TYPES.MAGAZINE_HOME_RESPONSE, (state, payload, error) => {
    return error ? LOADING_STATE.FAILED : LOADING_STATE.LOADED;
  })
);

export default combineReducers({
  featured: featured(featuredArticleListSeeds),
  _status: status(LOADING_STATE.NOT_ASKED)
});

/* SELECTORS */

export const getRoot = state => state.blog.featuredArticleList;

export const getStatus = createSelector(
  getRoot,
  root => root._status
);

export const getIsLoading = createSelector(
  getStatus,
  status => status !== LOADING_STATE.LOADED
);

export const getIsPending = createSelector(
  getStatus,
  status => status === LOADING_STATE.LOADING
);

export const getFeatured = createSelector(
  getRoot,
  root => root.featured
);

export const getList = createSelector(
  fromArticleList.getAll,
  getFeatured,
  (all, featured) => featured.map(slug => all[slug])
);
