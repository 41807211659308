import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import './footer-mobile-navigation.css';

const { string } = PropTypes;

class FooterMobileNavigationLink extends Component {
  static propTypes = {
    title: string.isRequired,
    to: string,
    href: string
  };

  render() {
    const { title, to, href, ...rest } = this.props;

    if (to) {
      return (
        <RouterLink to={to} className="FooterMobileNavigationLink-link" {...rest}>
          {title}
        </RouterLink>
      );
    }

    return (
      <a
        href={href}
        className="FooterMobileNavigationLink-link"
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {title}
      </a>
    );
  }
}

export default FooterMobileNavigationLink;
