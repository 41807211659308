import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import productShape from 'modules/product/prop-types/product-shape';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowRight } from 'modules/core/components/icons/index';
import Accordion from 'modules/core/components/accordion/accordion';
import AccordionItem from 'modules/core/components/accordion/accordion-item/accordion-item';

import OrderProductContainer from '../../../containers/succes-order-product';
import LastOrderProductContainer from '../../../containers/last-succes-order-product';
import Price from '../../price-formatted/price-formatted';
import { numfix } from './hepers.js';

import './table-thanks.css';

const poroductStrs = ['товар', 'товара', 'товаров'];

const { number, shape, arrayOf } = PropTypes;

export default class TableThanks extends Component {
  static propTypes = {
    /**
     * Displayed products list
     **/
    goodsList: arrayOf(
      shape({
        product: productShape,
        amount: number.isRequired
      })
    ),

    productsCost: number.isRequired,
    deliveryCost: number.isRequired,
    totalCost: number.isRequired
  };

  state = {
    isListShowed: false
  };

  render() {
    const { productsCost, deliveryCost, totalCost } = this.props;
    const discount = totalCost - productsCost - deliveryCost;

    return (
      <div className="TableThanks">
        <div className="TableThanks-header">
          <div className="TableThanks-headerItem">{this.getOrderProductsAmountString()}</div>
          <div className="TableThanks-headerItem">Сумма</div>
        </div>

        <Media query="(max-width: 840px)">
          {isMobile => (isMobile ? this.renderOrderListAccordion() : this.renderOrderList())}
        </Media>

        <div className="TableThanks-footer">
          <div className="TableThanks-price">
            <div className="TableThanks-priceRow">
              <div className="TableThanks-priceTitle">Стоимость товаров:</div>
              <div className="TableThanks-priceAmount">
                <Price variant="secondary" number={productsCost} />
              </div>
            </div>
            <div className="TableThanks-priceRow">
              <div className="TableThanks-priceTitle">Доставка:</div>
              <div className="TableThanks-priceAmount">
                <Price variant="secondary" number={deliveryCost} />
              </div>
            </div>
            {discount > 0 && (
              <div className="TableThanks-priceRow TableThanks-priceRow--discount">
                <div className="TableThanks-priceTitle">Скидка:</div>
                <div className="TableThanks-priceAmount">
                  <Price variant="discount" number={discount} />
                </div>
              </div>
            )}

            <div className="TableThanks-priceRow">
              <div className="TableThanks-totalPriceTitle">Итого:</div>
              <div className="TableThanks-totalPriceAmount">
                <Price number={totalCost} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderList() {
    const { goodsList } = this.props;

    return <ul className="TableThanks-list">{goodsList.map(this.renderOrderProduct)}</ul>;
  }

  renderCollapsedOrderList() {
    const { isListShowed } = this.state;
    return (
      <Fragment>
        <button onClick={this.handleTriggerListClick} type="button" className="TableThanks-button">
          <div className="TableThanks-buttonTitle">
            {isListShowed ? 'Скрыть' : 'Показать'} содержимое заказа
          </div>
          <div className="TableThanks-buttonIcon">
            <IconContainer size="xsmall">
              <IconAngleArrowRight />
            </IconContainer>
          </div>
        </button>
        {isListShowed && this.renderOrderList()}
      </Fragment>
    );
  }

  renderOrderListAccordion() {
    return (
      <Accordion clickableHeadings animatedCollapsing fade>
        <AccordionItem title="Содержимое заказа" panel={this.renderOrderList()} />
      </Accordion>
    );
  }

  renderOrderProduct = (item, index) => {
    const { listId } = this.props;

    const isOrderingPage = __BROWSER__ && window.location.pathname.indexOf('/success') >= 0;

    return (
      <li className="TableThanks-listItem" key={index}>
        {isOrderingPage ? (
          <LastOrderProductContainer
            slug={item.product}
            isGift={item.gift}
            amount={item.amount}
            price={item.price}
            separatePrice
            listId={listId}
          />
        ) : (
          <OrderProductContainer
            slug={item.product}
            isGift={item.gift}
            amount={item.amount}
            price={item.price}
            separatePrice
            listId={listId}
          />
        )}
      </li>
    );
  };

  getOrderProductsAmount() {
    const { goodsList } = this.props;

    return goodsList.map(item => item.amount || 0).reduce((a, b) => a + b, 0);
  }

  getOrderProductsAmountString() {
    return `${this.getOrderProductsAmount()} ${numfix(
      this.getOrderProductsAmount(),
      poroductStrs
    )}`;
  }

  showList() {
    this.setState({
      isListShowed: true
    });
  }

  hideList() {
    this.setState({
      isListShowed: false
    });
  }

  handleTriggerListClick = () => {
    const { isListShowed } = this.state;
    if (isListShowed) {
      this.hideList();
    } else {
      this.showList();
    }
  };
}
