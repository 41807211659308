import * as TYPES from '../types';
import { createAction, createActionError } from 'modules/utils/dux';

export function homeRequest() {
  return createAction(TYPES.MAGAZINE_HOME_REQUEST);
}

export function homeResponse(error, promoted, articles, entities) {
  if (error) {
    return createActionError(TYPES.MAGAZINE_HOME_RESPONSE, error);
  }
  return createAction(TYPES.MAGAZINE_HOME_RESPONSE, {
    promoted,
    articles,
    entities
  });
}

export function articleRequest(slug) {
  return createAction(TYPES.MAGAZINE_ARTICLE_REQUEST, { slug });
}

export function articleResponse(error, slug, entities) {
  if (error) {
    return createActionError(TYPES.MAGAZINE_ARTICLE_RESPONSE, error);
  }
  return createAction(TYPES.MAGAZINE_ARTICLE_RESPONSE, {
    slug,
    entities
  });
}

export function articleSharesAdd(slug, share) {
  return createAction(TYPES.MAGAZINE_ARTICLE_SHARE_ADD, { slug, share });
}
