import React, { Component } from 'react';

import Button from 'modules/core/components/button/button';
import { Link } from 'react-router-dom';
import Container from 'modules/core/components/container/container';

import { getIndexLink } from 'routes/links';

import './feedback-thanks.css';

class FeedbackThanks extends Component {
  render() {
    const { comment } = this.props;

    return (
      <Container>
        <div className="FeedbackThanks">
          {comment && <div className="FeedbackThanks-comment">{comment}</div>}

          <div className="FeedbackThanks-button">
            <Button
              title="Перейти главную"
              variant="primary"
              component={Link}
              to={getIndexLink()}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default FeedbackThanks;
