import { createReducer, handle } from 'modules/utils/dux';
import * as types from '../types/products';
import * as categoriesTypes from '../types/categories';
import * as GROUPS from '../types/groups';
import * as RECENT_TYPES from '../types/recent';
import * as PROFILE from 'modules/profile/types/profile';
import * as SEARCH from '../types/search';
import * as ORDER from 'modules/ordering/ducks/types/orders';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import {
  selectIdentifier,
  reduceListBySlug,
  productsFromDetails,
  resolveCategories
} from './helpers';
import * as BASKET from 'modules/ordering/ducks/basket/types';
import { deserializeProduct } from 'modules/product/deserializers/product';
import { deserializeDetails } from 'modules/product/deserializers/details';

const reducer = createReducer(
  handle(categoriesTypes.ADD_PRODUCTS, (state, payload) => {
    const productsBySlug = reduceListBySlug(payload.products.map(deserializeProduct));

    return {
      ...state,
      ...productsBySlug
    };
  }),
  handle(GROUPS.ADD_PRODUCTS, (state, payload) => {
    const productsBySlug = reduceListBySlug(payload.products.map(deserializeProduct));

    return {
      ...state,
      ...productsBySlug
    };
  }),

  handle(types.ADD_ITEM, (state, payload) => {
    const product = state[payload.slug] || {};
    return {
      ...state,
      [payload.slug]: {
        ...product,
        ...deserializeProduct(payload.product)
      }
    };
  }),

  handle(ACCOUNT.AUTHORIZATION_SUCCESS, (state, { entities }) => {
    if (!entities.products) {
      return state;
    }

    return {
      ...entities.products,
      ...state
    };
  }),

  handle(GROUPS.ADD_ITEM, (state, payload, error) => {
    if (payload.group.type === 'SECTIONS') {
      const productList = payload.group.sections.reduce(
        (resArr, item) => [...resArr, ...item.products],
        []
      );

      const productListWidthoutRepeat = productList.filter(
        (item, index) => index === productList.indexOf(item)
      );

      const productListDeserialize = productListWidthoutRepeat
        .map(deserializeProduct)
        .filter(Boolean);

      const productsBySlug = reduceListBySlug(productListDeserialize);

      return {
        ...state,
        ...productsBySlug
      };
    }

    if (error) return state;
    const productList = payload.group.products.map(deserializeProduct).filter(Boolean);

    const productsBySlug = reduceListBySlug(productList);

    return {
      ...state,
      ...productsBySlug
    };
  }),

  handle(types.ADD_DETAILS, (state, payload) => {
    const productsList = productsFromDetails(payload.details).map(deserializeProduct);
    const productsBySlug = reduceListBySlug(productsList);

    return {
      ...productsBySlug,
      ...state,
      [payload.slug]: {
        ...state[payload.slug],
        isDetailsLoaded: true,
        details: deserializeDetails(payload.details)
      }
    };
  }),

  handle(BASKET.UPDATE_FROM_SERVER, (state, { products }) => {
    const missedProducts = products.filter(item => !state[selectIdentifier(item)]);

    return {
      ...state,
      ...reduceListBySlug(missedProducts)
    };
  }),

  handle(BASKET.SYNC_RESPONSE, (state, { products }) => {
    const missedProducts = products.filter(item => !state[selectIdentifier(item)]);

    return {
      ...state,
      ...reduceListBySlug(missedProducts)
    };
  }),

  handle([BASKET.REHYDRATE], (state, { basket }) => {
    const { goods } = basket;
    const missedProducts = goods
      .map(item => item.product)
      .filter(item => !state[selectIdentifier(item)]);

    return {
      ...reduceListBySlug(missedProducts),
      ...state
    };
  }),

  handle([BASKET.UPDATE_FROM_ANOTHER_TAB], (state, { goods }) => {
    const missedProducts = goods
      .map(item => item.product)
      .filter(item => !state[selectIdentifier(item)]);

    return {
      ...reduceListBySlug(missedProducts),
      ...state
    };
  }),

  handle(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.products) {
      return state;
    }

    return {
      ...entities.products,
      ...state
    };
  }),

  handle(SEARCH.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.product) {
      return state;
    }

    const products = resolveCategories(entities.product, entities.category);

    return {
      ...state,
      ...products
    };
  }),

  handle(SEARCH.FILTER_RESPONSE, (state, { entities }, error) => {
    if (error || !entities.product) {
      return state;
    }

    const products = resolveCategories(entities.product, entities.category);

    return {
      ...state,
      ...products
    };
  }),

  handle(ORDER.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order.products) {
      return state;
    }

    return {
      ...order.products,
      ...state
    };
  }),

  handle(RECENT_TYPES.REHYDRATE, (state, { recentList }) => {
    if (!recentList) {
      return state;
    }

    const productsFromRecent = reduceListBySlug(recentList);

    return {
      ...productsFromRecent,
      ...state
    };
  })
);

export default reducer({});
