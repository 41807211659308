import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import Button from 'modules/core/components/button/button';
import Loader from 'modules/core/components/loader/loader';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';

import RewardsSection from '../components/rewards-section/rewards-section';

const DECLARATIONS_API = 'https://mixit.ru/api/v3/';
const QUERY = 'awards';

class RewardsSectionsContainer extends Component {
  state = {
    isLoaded: false,
    isFetching: false,
    data: [],
    error: null
  };

  render() {
    const { isLoaded, isFetching, error, data } = this.state;

    if (!isLoaded || !isFetching) {
      this.renderLoader();
    }

    if (error) {
      this.renderNotLoaded();
    }

    return <RewardsSection data={data} />;
  }

  renderLoader() {
    return (
      <div style={{ position: 'relative;', padding: '160px 0' }}>
        <Loader type="spin" />
      </div>
    );
  }

  renderNotLoaded() {
    const { isFetching } = this.state;
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <Fragment>
            <p>Не удалось загрузить данные</p>
            <Button
              variant="primary"
              title="Загрузить"
              onClick={this.handleFetch}
              iconAppend={isFetching && <IconFlatLoader />}
              size={isMobile ? 'small' : 'normal'}
            />
          </Fragment>
        )}
      </Media>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  setLoadingState() {
    this.setState({ isFetching: true });
  }

  fetchData() {
    this.setLoadingState();
    fetch(DECLARATIONS_API + QUERY)
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data.data, isLoaded: true, isFetching: false, error: null });
      })
      .catch(error => this.setState({ error, isFetching: false }));
  }

  handleFetch = () => {
    this.fetchData();
  };
}

export default RewardsSectionsContainer;
