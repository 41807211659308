import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromBasket from '../ducks/basket';
import * as fromBasketProducts from '../ducks/basket-products';
import * as fromOrders from '../ducks/orders';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromAccount from 'modules/core/ducks/account';
import { actions as Dialog } from 'modules/core/ducks/dialogs';
import * as fromLocation from 'modules/geography/ducks/location';
import * as fromShopList from 'modules/core/ducks/shop-list';

import basketActions from '../ducks/basket/actions';
import Basket from '../components/basket/basket';

import { actions as BonusesConfirmation } from '../ducks/bonuses-conformation';

const mapStateToProps = createStructuredSelector({
  products: fromBasketProducts.getListWithRemoved,
  productsCost: fromBasketProducts.getProductsCost,
  productsDefaultCost: fromBasketProducts.getProductsDefaultCost,
  discount: fromBasketProducts.getDiscount,
  deliveryCost: fromBasketProducts.getDeliveryCost,
  isDeliveryFree: fromBasketProducts.getIsDeliveryFree,
  // freeDeliveryPrice: fromBasket.getFreeDeliveryPrice,
  freeDeliveryPrice: fromLocation.getFreeDeliveryFrom,
  coupon: fromBasket.getLastCoupon,
  couponLoaded: fromBasket.getLastCouponLoaded,
  couponRemoving: fromBasket.getLastCouponRemoving,
  promo: fromBasket.getLastPromo,
  giftProductSlugs: fromBasket.getGiftProductSlugs,
  promoOptions: fromBasket.getPromoOptions,
  isPromoVerified: fromBasket.getIsPromoVerified,

  promoOptionId: fromBasket.getPromoOptionId,
  isPlacing: fromBasket.getIsPlacing,
  isVerifying: fromBasket.getIsVerifying,
  isGiftChoiseShowed: fromBasket.getIsGiftChoiseShowed,
  isAuthorized: fromAccount.getIsAuthorized,

  bonuses: fromBasket.getRowBonuses,
  bonus: fromBasket.getBonuses,
  availableBonuses: fromBasket.getAvailableBonuses,
  appliedBonuses: fromBasket.getAppliedBonuses,
  earnedBonuses: fromBasket.getEarnedBonuses,
  maxDeliveryPrice: fromBasket.getMaxDeliveryPrice,
  isBasketFetching: fromBasket.getIsBasketFetching,
  isNoRussia: fromShopList.getIsBelarusOrKazahstan
});

const mapDispatchToProps = {
  changeAmount: basketActions.changeProductAmount,
  removeProduct: basketActions.removeProduct,
  cancelProductRemoval: basketActions.cancelProductRemoval,
  submitBasket: fromOrders.actions.place,
  show: basketActions.show,
  applyPromo: basketActions.applyPromo,
  selectPromoOption: basketActions.selectPromoOption,
  removePromo: basketActions.removePromo,
  openGiftDialog: fromDialogs.actions.openGiftDialog,
  addProductToBasket: basketActions.addProduct,
  openAuthorizationDialog: Dialog.openAuthDialog,

  applyBonus: basketActions.applyBonus,
  removeBonus: basketActions.removeBonus,
  onRequestBonusesConfirmationCode: BonusesConfirmation.requestCode,
  openConfirmCodeDialog: fromDialogs.actions.openBonusesConfirmationCodeDialog,
  openApplyAnotherGiftDialog: basketActions.openApplyAnotherGiftDialog,
  closeApplyAnotherGiftDialog: basketActions.closeApplyAnotherGiftDialog
};

class BasketContainer extends Component {
  render() {
    const {
      isGiftChoiseShowed,
      promoOptions,
      bonuses,
      bonus,
      availableBonuses,
      appliedBonuses,
      earnedBonuses,
      onRequestBonusesConfirmationCode,
      openConfirmCodeDialog,
      ...rest
    } = this.props;

    return (
      <Basket
        {...rest}
        onPromoCodeSubmit={this.handlePromoCodeSubmit}
        onPromoCodeReset={this.handlePromoCodeReset}
        onBasketSubmit={this.handleBasketSubmit}
        onGiftDialogOpen={this.handleGiftDialogOpen}
        onPromoOptionChose={this.handlePromoOptionChose}
        isPromoLoading={
          (!this.props.couponLoaded || this.props.couponRemoving) && !!this.props.coupon
        }
        promoOptions={promoOptions}
        isGiftChoiseShowed={isGiftChoiseShowed}
        onApplyBonus={this.handleApplyBonuses}
        onRemoveBonus={this.handleRemoveBonuses}
        bonus={bonus}
        onConfirmBonuses={this.handleConfirmBonuses}
      />
    );
  }

  handlePromoCodeSubmit = ({ code }) => {
    this.props.applyPromo(code);
  };

  handlePromoOptionChose = ({ id }) => {
    const { promo, selectPromoOption, closeApplyAnotherGiftDialog } = this.props;

    closeApplyAnotherGiftDialog();
    selectPromoOption(id, promo.id);
  };

  handlePromoCodeReset = () => {
    const { coupon, removePromo, closeApplyAnotherGiftDialog } = this.props;

    closeApplyAnotherGiftDialog();
    removePromo(coupon);
  };

  handleBasketSubmit = () => {
    this.props.submitBasket();
  };

  handleGiftDialogOpen = () => {
    const { openGiftDialog } = this.props;

    openGiftDialog();
  };

  restLoaded() {
    this.setState({ isBonusLoaded: false });
  }

  handleApplyBonuses = value => {
    const { applyBonus } = this.props;

    applyBonus(value);
  };

  handleRemoveBonuses = () => {
    const { removeBonus, availableBonuses } = this.props;

    removeBonus({ value: 0, type: availableBonuses.type });
  };

  handleConfirmBonuses = () => {
    const { bonus, onRequestBonusesConfirmationCode, openConfirmCodeDialog } = this.props;
    const { phone } = bonus.conformationOptions;

    if (!phone) {
      onRequestBonusesConfirmationCode({ value: bonus.applied });
    }

    openConfirmCodeDialog();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketContainer);
