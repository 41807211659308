import React, { Component } from 'react';
import './icon-apple-pay.css';

class IconApplePay extends Component {
  static defaultProps = {
    opacity: 1
  };

  render() {
    const { opacity } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 79.9 34"
        className="IconApplePay"
        style={{ opacity: opacity }}
      >
        <path
          fill="#828da2"
          d="M22.2 8.1c.7-.8 1.1-2 1-3.1-1 0-2.2.7-2.9 1.5-.6.7-1.2 1.9-1 3 1.1 0 2.2-.6 2.9-1.4m1 1.6c-1.6-.1-3 .9-3.7.9-.8 0-1.9-.8-3.2-.8-1.6 0-3.1.9-4 2.4-1.7 3-.5 7.3 1.2 9.7.8 1.2 1.8 2.5 3 2.4 1.2 0 1.7-.8 3.1-.8s1.9.8 3.2.8c1.3 0 2.2-1.2 3-2.4.9-1.4 1.3-2.7 1.3-2.7s-2.6-1-2.6-3.9c0-2.4 2-3.6 2.1-3.7-1.1-1.7-2.8-1.9-3.4-1.9M36.9 6.4c3.5 0 5.9 2.4 5.9 5.8 0 3.5-2.4 5.9-6 5.9H33v6h-2.8V6.4h6.7zM33 15.7h3.1c2.4 0 3.8-1.3 3.8-3.5 0-2.3-1.4-3.5-3.8-3.5H33v7zm10.5 4.8c0-2.3 1.7-3.7 4.8-3.8l3.6-.2v-1c0-1.5-1-2.3-2.6-2.3-1.5 0-2.5.8-2.7 1.9h-2.5c.1-2.3 2.2-4.1 5.3-4.1 3.1 0 5.2 1.6 5.2 4.3v8.9H52V22c-.7 1.5-2.4 2.3-4.1 2.3-2.6.1-4.4-1.5-4.4-3.8zm8.4-1.2v-1l-3.2.2c-1.6.1-2.5.8-2.5 1.9s.9 1.9 2.3 1.9c1.9 0 3.4-1.3 3.4-3zm5 9.6v-2.2h.8c1.2 0 1.9-.5 2.3-1.8l.2-.8-4.7-12.9h2.9l3.3 10.5L65 11.2h2.8L63 24.9C61.9 28 60.7 29 58 29c-.2 0-.9 0-1.1-.1z"
        />
      </svg>
    );
  }
}

export default IconApplePay;
