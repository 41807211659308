// import { createSelector } from 'reselect';


export default function routerHistory(state = [], action) {
    switch (action.type) {
      case "@@router/LOCATION_CHANGE":
        return [...state, action.payload]
      default:
        return state;
    }
  }

export const getRouterHistory = state => state.core.routerHistory;