import React, { Component } from 'react';
import Media from 'react-media';
import Input from 'modules/form/components/input/input';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import setCaretPosition from './helpers/set-caret-position';

const priceMaskOptions = {
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  suffix: ' руб.'
};

const priceMask = createNumberMask(priceMaskOptions);

class InputPrice extends Component {
  state = {
    isFocused: false
  };
  render() {
    const { isFocused } = this.state;
    const { value, max, onChange, ...rest } = this.props;
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <Input
            {...rest}
            value={value || ''}
            type="text"
            inputmode="numeric"
            integerLimit={max}
            placeholder={
              isFocused ? '  руб.' : isMobile ? 'Введите желаемую для списания сумму' : 'Введите...'
            }
            title={value === 0 ? 'Введите желаемую для списания сумму' : null}
            guide={false}
            mask={priceMask}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onClick={this.handleClick}
          />
        )}
      </Media>
    );
  }

  setFocused() {
    this.setState({
      isFocused: true
    });
  }

  unsetFocused() {
    this.setState({
      isFocused: false
    });
  }

  handleFocus = e => {
    this.setFocused();
  };

  handleBlur = e => {
    this.unsetFocused();
  };

  handleClick = ({ target }) => {
    const { selectionStart, selectionEnd, value } = target;
    const isSelected = selectionStart !== selectionEnd;
    const suffixLength = priceMaskOptions.suffix.length;
    const valueEndPosition = value.length - suffixLength;
    const isOutOfSuffix = selectionStart > valueEndPosition;

    if ((!isSelected && isOutOfSuffix) || isSelected) {
      setCaretPosition(target, valueEndPosition);
    }
  };
}

export default InputPrice;
