import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-eye.css';

class IconEye extends Component {
  render() {
    const { size, inline } = this.props;
    const _size = !size || inline ? 'xsmall' : size;
    switch (_size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconEye">
            <path d="M8 13.6c-3.8 0-6.8-3.8-6.9-4-.1-.2-.1-.4 0-.6.1-.2 2.8-4.3 7-4.3 4.1 0 6.7 4.1 6.8 4.3.1.2.1.4 0 .6-.1.2-3.1 4-6.9 4zM2.1 9.3c.7.8 3.1 3.3 5.9 3.3 2.7 0 5.1-2.5 5.9-3.3-.7-.9-2.9-3.6-5.8-3.6-3.1 0-5.3 2.7-6 3.6z" />
            <path d="M8 7.1a2 2 0 0 1 2 2c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2M8 5.3c-.3 0-.5-.2-.5-.5V2.9c0-.3.2-.5.5-.5s.5.2.5.5v1.9c0 .3-.2.5-.5.5zM4.4 6.7c-.2 0-.3-.1-.4-.2l-1.6-2c-.1-.2-.1-.5.1-.7.2-.2.5-.1.7.1l1.5 1.9c.2.2.1.5-.1.7 0 .1-.1.2-.2.2zM11.7 6.7c-.1 0-.2 0-.3-.1-.2-.2-.3-.5-.1-.7L12.9 4c.2-.2.5-.3.7-.1.2.2.3.5.1.7l-1.6 1.9c-.1.1-.2.2-.4.2z" />
          </Svg>
        );

      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 24 24" className="IconEye">
            <path d="M20.9 13.4c-.1-.1-1.3-2.1-3.5-3.6l1.8-2.2c.2-.2.1-.6-.1-.7-.2-.2-.6-.1-.8.1l-1.9 2.3C15.4 8.5 14 8 12.5 8V5.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V8c-1.5.1-2.8.6-3.9 1.2L5.7 6.9c-.2-.2-.5-.3-.7-.1-.3.2-.3.5-.1.7l1.8 2.2a13.5 13.5 0 0 0-3.6 3.6c-.1.3-.1.5 0 .7.2.2 4 5 8.9 5s8.7-4.8 8.9-5c.1-.2.1-.4 0-.6zM12 17.9c-3.7 0-6.9-3.3-7.8-4.3C5 12.5 8 9 12.2 9c3.9 0 6.8 3.5 7.6 4.6-.9 1-4.1 4.3-7.8 4.3z" />
            <path d="M12 10.9a2.6 2.6 0 0 0-2.7 2.6c0 1.4 1.2 2.6 2.7 2.6s2.7-1.1 2.7-2.6c0-1.4-1.2-2.6-2.7-2.6z" />
          </Svg>
        );
      default:
    }
  }
}

export default IconEye;
