import React, { Component } from 'react';

import './icon-delivery.css';

class IconDelivery extends Component {
  render() {
    return (
      <svg
        className="IconDelivery"
        viewBox="0 0 33 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5.5H21a1 1 0 011 1V2m8 6.5h-7a1 1 0 01-1-1V2m8 6.5l2.207 2.207a1 1 0 01.293.707V17a1 1 0 01-1 1h-27M30 8.5l-2.253-5.859A1 1 0 0026.813 2H22M12.5 5.5h-9M12.5 10.5H.5"
          // stroke="#262931"
          stroke-linecap="round"
        />
        <circle
          cx="11"
          cy="18"
          r="2.5"
          fill="#fff"
          // stroke="#262931"
        />
        <circle
          cx="26"
          cy="18"
          r="2.5"
          fill="#fff"
          // stroke="#262931"
        />
      </svg>
    );
  }
}

export default IconDelivery;
