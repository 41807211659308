import { createSelector } from 'reselect';
import { rootSelector } from '../index';

const getIdFromProps = (_, props) => props.id;
const getMedia = state => rootSelector(state).media;

export const getAll = createSelector(
  getMedia,
  media => media.all
);
export const getItemById = createSelector(
  getAll,
  getIdFromProps,
  (all, id) => all[id]
);
