import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromProducts from '../ducks/products';
import ProductSetCard from '../components/product-set-card/product-set-card';
import { ToastConsumer } from 'modules/core/containers/toast-stack';

const mapStateToProps = createStructuredSelector({
  set: fromProducts.getItemBySlug
});

const mapDispatchToProps = {
  addProductToBasket: fromBasket.actions.addProduct
};

class ProductSetCardContainer extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    return (
      <ToastConsumer>
        {({ create, close }) => (
          <ProductSetCard {...this.props} createToast={create} closeToast={close} />
        )}
      </ToastConsumer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSetCardContainer);
