import React, { Component } from 'react';

import isFunction from 'modules/utils/is-function';
import Container from 'modules/core/components/container/container';
import PropTypes from 'prop-types';

import ButtonClose from './../button-close/button-close';

import './roll-up.css';

const { any, bool, func } = PropTypes;

class RollUp extends Component {
  static propTypes = {
    content: any,
    show: bool,
    onClose: func
  };

  static defaultProps = {
    show: true
  };

  render() {
    const { content, show, style } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div style={style} className="RollUp">
        <div className="RollUp-content">
          <Container>{content({ close: this.handleClose })}</Container>
        </div>
        <div className="RollUp-close">
          <ButtonClose onClick={this.handleClose} />
        </div>
      </div>
    );
  }

  handleClose = () => {
    const { onClose } = this.props;

    if (!isFunction(onClose)) {
      return;
    }

    onClose();
  };
}

export default RollUp;
