import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SubscriptionSection from 'modules/core/components/subscription-section/subscription-section';
import CategoryContainer from '../containers/category';
import LastViewedProductsSectionContainer from '../containers/last-viewed-products-section';
import { createStructuredSelector } from 'reselect';
import { actions as Categories } from '../ducks/categories';
import * as fromCategories from '../ducks/categories';
import { getRouterState, getIsPreviousProductPage } from 'modules/core/ducks/router/selectors';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

const getItemSlugFromRouteParams = (_, props) => {
  return props.match.params.item;
};

const getNestedSlugFromRouteParams = (_, props) => {
  if (!props.match.params.nested) {
    return null;
  }
  return props.match.params.nested;
};

const mapStateToProps = createStructuredSelector({
  category: getItemSlugFromRouteParams,
  nested: getNestedSlugFromRouteParams,
  filters: fromCategories.getAppliedFiltersFromRoute,
  routerState: getRouterState,
  isPreviousProductPage: getIsPreviousProductPage
});

const mapDispatchToProps = {
  showCategory: Categories.showItem,
  setFilter: fromCategories.actions.setFilter
};

class CategoryPage extends Component {
  render() {
    const { category, nested } = this.props;

    return (
      <Fragment>
        <CategoryContainer slug={category} nested={nested} />
        <LastViewedProductsSectionContainer />
        <SubscriptionSection />
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Fragment>
    );
  }

  scrollTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  show(category, nested, filters) {
    this.props.showCategory(category, nested, filters);
  }

  componentDidMount() {
    const {
      category,
      nested,
      filters,
      /*routerState,*/ isPreviousProductPage,
      setFilter
    } = this.props;

    // if (routerState && routerState.hasScroll) {
    // this.scrollTop();
    // }
    if (!isPreviousProductPage) {
      this.scrollTop();
    }

    if (filters.length > 0) {
      setFilter(category, nested, filters);
    }

    this.show(category, nested, filters);
  }

  componentWillMount() {
    const { category, nested, filters } = this.props;
    if (__SERVER__) {
      this.show(category, nested, filters);
    }
  }
  componentDidUpdate(prevProps) {
    this.organizeScrollingToTop(prevProps);
  }

  UNSAFE_componentWillUpdate(prevProps) {
    if (prevProps.category === this.props.category && prevProps.nested === this.props.nested) {
      return;
    }

    // if (props.category !== this.props.category) {
    //   this.scrollTop();
    // }

    this.show(prevProps.category, prevProps.nested);
  }

  organizeScrollingToTop = prevProps => {
    if (prevProps.category !== this.props.category) {
      this.scrollTop();
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
