import React, { Component } from 'react';

import Item from './item';

import './footer-navigation-group.css';

class FooterNavigationGroup extends Component {
  static Item = Item;

  render() {
    const { title, children } = this.props;
    return (
      <div className="FooterNavigationGroup">
        <h3 className="FooterNavigationGroup-title">{title}</h3>
        <span className="arrow" />
        <ul className="FooterNavigationGroup-list">{children}</ul>
      </div>
    );
  }
}

export default FooterNavigationGroup;
