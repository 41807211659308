import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconContainer from '../icon-container/icon-container';

import './link.css';

const { string, bool, element, oneOfType, func, oneOf } = PropTypes;

class Link extends Component {
  static propTypes = {
    /**
     * Link string
     **/
    href: string,

    /**
     * Active link modifier
     **/
    active: bool,

    /**
     * Link without underline
     **/
    noUnderline: bool,

    /**
     * Icon before link title
     **/
    prependedIcon: element,

    /**
     * Icon after link title
     **/
    appendedIcon: element,

    /**
     * Icon size
     **/
    size: oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),

    /**
     * Container tag
     **/
    component: oneOfType([string, func])
  };

  static defaultProps = {
    active: false,
    noUnderline: false,
    prependedIcon: null,
    appendedIcon: null,
    size: 'small',
    component: 'a'
  };

  render() {
    const {
      component: LinkComponent,
      noUnderline,
      active,
      prependedIcon,
      appendedIcon,
      children,
      size,
      inlineIcon,
      ...rest
    } = this.props;

    return (
      <LinkComponent
        className={classNames('Link', {
          'Link--noUnderline': noUnderline,
          'Link--active': active,
          'Link--inlineIcon': inlineIcon
        })}
        {...rest}
      >
        {prependedIcon && (
          <span className="Link-icon">
            <IconContainer size={size} inline={inlineIcon}>
              {prependedIcon}
            </IconContainer>
          </span>
        )}
        <span className="Link-text">{children}</span>
        {appendedIcon && (
          <span className="Link-icon">
            <IconContainer size={size} inline={inlineIcon}>
              {appendedIcon}
            </IconContainer>
          </span>
        )}
      </LinkComponent>
    );
  }
}

export default Link;
