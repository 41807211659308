const uniteOption = {
  title: 'Все магазины',
  id: 'all'
};

function sortCityList(cityList) {
  const sortedCityList = cityList.sort((prev, next) => (prev.title > next.title ? 1 : -1));
  return sortedCityList;
}

function formCitiesOptionList(cityList, ignoredCountryId) {
  const citiesOptionList = cityList.map(city => formCityOption(city, ignoredCountryId));

  return [uniteOption, ...citiesOptionList];
}

function formCityOption(city, ignoredCountryId) {
  const { title, id, country } = city;
  const isCountryNonIgnored = country && country.id !== ignoredCountryId;

  if (isCountryNonIgnored) {
    return {
      title,
      id,
      country
    };
  }

  return { title, id };
}

export default function prepareCitiesOption(cityList, ignoredCountryId) {
  const sortedCityList = sortCityList(cityList);
  const citiesOptionList = formCitiesOptionList(sortedCityList, ignoredCountryId);

  return citiesOptionList;
}
