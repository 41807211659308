export default function initBlogRequest(createRequest) {
  return function _getBlogRequest() {
    return {
      item: createRequest(() => ({
        method: 'GET',
        path: '/blog'
      })),
      articles: slug => ({
        list: createRequest(() => ({
          method: 'GET',
          path: `/blog/articles`
        })),
        item: createRequest(() => ({
          method: 'GET',
          path: `/blog/articles/${slug}`
        })),
        like: createRequest(() => ({
          method: 'POST',
          path: `/blog/articles/${slug}/counters/like`
        })),
        unlike: createRequest(() => ({
          method: 'DELETE',
          path: `/blog/articles/${slug}/counters/like`
        })),
        share: createRequest(() => ({
          method: 'POST',
          path: `/blog/articles/${slug}/counters/share`
        }))
      })
    };
  };
}
