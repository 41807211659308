import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromPasswordChange from 'modules/profile/ducks/password-change';
import PasswordChange from 'modules/profile/actions/password-change';
import * as TOASTS from 'modules/profile/types/password-change';

import { ToastConsumer } from './toast-stack';

const mapStateToProps = createStructuredSelector({
  isShow: fromPasswordChange.getIsToastOpened
});

const mapDispatchToProps = {
  acceptToast: PasswordChange.closeToast,
  closeToast: PasswordChange.closeToast
};

const defaultToastProperties = {
  id: 'change-password-toast',
  state: 'loading',
  content: 'Ваш пароль был изменен',
  delay: 3500,
  actionList: [
    {
      title: 'Ок',
      type: TOASTS.CLOSE_TOAST,
      variant: 'primary'
    }
  ]
};

class PasswordChangeToast extends Component {
  state = {
    isToastCreated: false
  };

  render() {
    const { isShow } = this.props;

    const { isToastCreated } = this.state;

    if (!isShow || isToastCreated) {
      return null;
    }

    return <ToastConsumer>{({ create, close }) => this.createToast(create, close)}</ToastConsumer>;
  }

  componentDidMount() {
    if (this.props.isShow) {
      this.noticeToastCreating();
    }
  }

  componentDidUpdate(_, prevState) {
    const { isToastCreated } = this.state;
    const { isShow } = this.props;

    if (!isToastCreated === prevState.isToastCreated) {
      return;
    }

    if (isToastCreated === prevState.isToastCreated && !isShow) {
      this.setState({ isToastCreated: false });
      return;
    }

    if (isToastCreated === prevState.isToastCreated && isShow) {
      this.noticeToastCreating();
    }
  }

  noticeToastCreating() {
    this.setState({ isToastCreated: true });
  }

  createToast(createToast, closeToast) {
    const onActionTrigger = this.handleToastAction(closeToast);
    const toast = { ...defaultToastProperties, onClose: this.handleToastClose(closeToast) };

    // setTimeout(() => createToast({ toast, onActionTrigger }), DELAY);
    createToast({ toast, onActionTrigger });

    return null;
  }

  handleToastClose = closeFunction => () => {
    const { closeToast } = this.props;
    const { id } = defaultToastProperties;

    closeToast();
    closeFunction(id);
  };

  handleToastAction = closeFunction => action => {
    const { acceptToast } = this.props;
    const { id } = defaultToastProperties;

    if (action.type === TOASTS.CLOSE_TOAST) {
      acceptToast();
      closeFunction(id);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChangeToast);
