import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import * as fromAccount from 'modules/core/ducks/account';
import { actions as Dialog } from 'modules/core/ducks/dialogs';

import Footer from '../components/footer/footer';

const mapState = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

const mapActions = {
  onAuthorizationClick: Dialog.openAuthDialog
};

class FooterContainer extends Component {
  render() {
    const { location, ...rest } = this.props;
    return <Footer {...rest} />;
  }
}

export default withRouter(
  connect(
    mapState,
    mapActions
  )(FooterContainer)
);
