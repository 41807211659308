import * as TYPES from '../types/city-list';
import { createAction } from 'modules/utils/dux';

export default {
  responseCityList(cityList) {
    return createAction(TYPES.CITY_LIST_RESPONSE, { cityList });
  },

  rehydrateAll(all) {
    return createAction(TYPES.REHYDRATE_ALL, { all });
  }
};
