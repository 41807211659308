import React, { Component } from 'react';
import './icon-instagram.css';

class IconInstagram extends Component {
  render() {
    return (
      <svg className="IconInstagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path d="M32.12 15.1v11.56a5.46 5.46 0 0 1-5.46 5.46H9.34a5.46 5.46 0 0 1-5.46-5.46V9.34a5.46 5.46 0 0 1 5.46-5.46h17.32a5.46 5.46 0 0 1 5.46 5.46zM18 23a5 5 0 1 0-5-5 5 5 0 0 0 5 5zm10.85-11V7.13h-4.78v4.79zm-3.13 6a7.72 7.72 0 1 1-14.88-2.9H6.63v11.56a2.71 2.71 0 0 0 2.71 2.7h17.32a2.71 2.71 0 0 0 2.71-2.7V15.1h-4.22a7.62 7.62 0 0 1 .57 2.9z" />
      </svg>
    );
  }
}

export default IconInstagram;
