import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromToasts from '../ducks/toasts';
import Toasts from '../ducks/actions/toasts';
import TOASTS from '../ducks/types/toasts';

import { ToastConsumer } from './toast-stack';

const mapStateToProps = createStructuredSelector({
  hasShowed: fromToasts.getHaveCookieToastBeenShowed
});

const mapDispatchToProps = {
  acceptToast: Toasts.acceptCookieToast,
  closeToast: Toasts.closeCookieToast
};

const defaultToastProperties = {
  id: 'cookie-toast',
  state: 'loading',
  content:
    'Продолжая использовать сайт, Вы даете свое согласие на использование cookie для хранения данных.',
  delay: false,
  actionList: [
    {
      title: 'Ок',
      type: TOASTS.COOKIE_TOAST_ACCEPT,
      variant: 'primary'
    }
  ]
};

const DELAY = 5000;

class CookieToast extends Component {
  state = {
    isToastCreated: false
  };

  render() {
    const { hasShowed } = this.props;
    const { isToastCreated } = this.state;

    if (hasShowed || isToastCreated) {
      return null;
    }

    return <ToastConsumer>{({ create, close }) => this.createToast(create, close)}</ToastConsumer>;
  }

  componentDidMount() {
    this.noticeToastCreating();
  }

  noticeToastCreating() {
    this.setState({ isToastCreated: true });
  }

  createToast(createToast, closeToast) {
    const onActionTrigger = this.handleToastAction(closeToast);
    const toast = { ...defaultToastProperties, onClose: this.handleToastClose(closeToast) };

    setTimeout(() => createToast({ toast, onActionTrigger }), DELAY);

    return null;
  }

  handleToastClose = closeFunction => () => {
    const { closeToast } = this.props;
    const { id } = defaultToastProperties;

    closeToast();
    closeFunction(id);
  };

  handleToastAction = closeFunction => action => {
    const { acceptToast } = this.props;
    const { id } = defaultToastProperties;

    if (action.type === TOASTS.COOKIE_TOAST_ACCEPT) {
      acceptToast();
      closeFunction(id);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieToast);
