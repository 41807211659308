import PropTypes from 'prop-types';

const { string, shape, arrayOf, number } = PropTypes;

export default shape({
  id: number,
  mainProperty: string,
  listOfProperty: arrayOf(
    shape({
      key: string,
      value: string
    })
  ),
  description: string,
  shortDescription: string
});
