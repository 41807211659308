import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

// import MediaQuery from 'react-media';

import { IconLocation, IconPrinter } from 'modules/core/components/icons';

import Media from 'modules/core/components/media/media';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
import SVGNewCircle from '../svg-new-circle/svg-new-circle';
import SVGArrow from '../svg-arrow/svg-arrow';
import LandingButton from '../landing-button/landing-button';
// import Container from 'modules/core/components/container/container';

import './landing-banner.css';
import './lb-action.css';
import './lb-address.css';
import './lb-address-new.css';
import './lb-images.css';
import './lb-label.css';

export default function LandingBanner({ data, colors, light }) {
  return (
    <div className={cn('LandingBanner')}>
      <div className="LandingBanner-top">
        <div className="LandingBanner-label">
          <Label title={data.label} colors={colors} />
        </div>
        <div className="LandingBanner-title">
          <LandingSectionTitle
            data={data.title}
            top={data.title[0]}
            bottom={data.title[1]}
            light={light}
            noGap
          />
        </div>
      </div>
      {/* <div className="LandingBanner-address">
        <Address data={data.address} />
      </div> */}
      <div className="LandingBanner-addressNew">
        <AddressNew data={data.address} colors={colors} />
      </div>
      <div className="LandingBanner-images">
        <Images images={data.images} />
      </div>
      {data.action && data.action.show && (
        <div className="LandingBanner-bottom">
          <div className="LandingBanner-action">
            <Action data={data.action} colors={colors} />
          </div>
        </div>
      )}
    </div>
  );
}

function Label({ title, colors }) {
  const style = { backgroundColor: colors[0] };

  return (
    <div className="LB_Label">
      <div className="LB_Label-bg" style={style}></div>
      <div className="LB_Label-inner">{title}</div>
    </div>
  );
}

// function Address({ data }) {
//   return (
//     <div className="LB_Address">
//       <div className="LB_Address-iconHolder">
//         <div className="LB_Address-icon">
//           <IconLocation />
//         </div>
//       </div>
//       <div className="LB_Address-list">
//         <div className="LB_Address-item">{data[0]}</div>
//         <div className="LB_Address-item">{data[1]}</div>
//       </div>
//     </div>
//   );
// }
function AddressNew({ data, colors }) {
  const style = { color: colors[0] };
  const styleIcon = { backgroundColor: colors[0] };

  return (
    <div className="LB_AddressNew">
      <div className="LB_AddressNew-iconHolder">
        <div className="LB_AddressNew-icon" style={styleIcon}>
          <IconLocation />
        </div>
      </div>
      <div className="LB_AddressNew-main">
        <div className="LB_AddressNew-title" style={style}>
          {data.title}
        </div>
        <div className="LB_AddressNew-list">
          {data.date_list.map((item, index) => (
            <div key={index} className="LB_AddressNew-item">
              <span className="LB_AddressNew-itemDate" style={style}>
                {item.date}
              </span>
              <span className="LB_AddressNew-itemLocation">{item.location}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function isURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
}

function Action({ data, colors }) {
  const { description, title, link } = data;

  if (!link) {
    return null;
  }

  const { href, ...rest } = link;

  const hasLink = link !== 'indefined' && Boolean(link);

  const buttonProps = {
    ...(hasLink && { component: isURL(href) ? 'a' : Link }),
    ...(hasLink && isURL(href) && { href }),
    ...(hasLink && !isURL(href) && { to: href }),
    ...(hasLink && { ...rest })
  };

  return (
    <div className="LB_Action">
      <div className="LB_Action-header">
        <div className="LB_Action-description">{description}</div>
        <div className="LB_Action-arrow">
          <SVGArrow colors={colors} />
        </div>
      </div>
      <LandingButton title={title} icon={IconPrinter} colors={colors} {...buttonProps} />
    </div>
  );
}

function Images({ images }) {
  return (
    <div className="LB_Images">
      <div className="LB_Images-main">
        <Media media={images[0]} alt="" stretch="horizontal" nocaption />
      </div>
      <div className="LB_Images-secondary">
        <Media media={images[1]} alt="" stretch="horizontal" nocaption />
      </div>
      <div className="LB_Images-circle">
        <SVGNewCircle />
      </div>
    </div>
  );
}
