const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

function dateToString(date) {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = months[_date.getMonth()];
  const year = _date.getFullYear();

  return `${day} ${month} ${year}`;
}

function getTime(date) {
  const _date = new Date(date);
  const hours = _date.getHours();
  let seconds = _date.getSeconds();

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${hours}:${seconds}`;
}

export function getParsedDate(date) {
  return `${dateToString(date)} ${getTime(date)}`;
}
