export default {
  1: {
    id: 1,
    ingredient: 1,
    mainProperty: 'Регенерация и востановление',
    properties: [
      {
        key: 'Наименование',
        value: 'Витамин F (ненасыщенные жирные кислоты)'
      },
      {
        key: 'Тип',
        value: 'Витамины'
      },
      {
        key: 'Международная номенклатура (INCI)',
        value: 'Vitamin F (Linoleic and linolenic acids)'
      }
    ],
    description: `<h3>Происхождение и получение</h3>
                  <h4>Витамин F – это ненасыщенные жирные кислоты - линолевая, линоленовая и арахидоновая, объединённые под одним наименованием – от английского «fat», что означает – «жир».</h4> 
                  <p>Данные вещества относятся к незаменимым: они просто необходимы для нашей жизни. Основной кислотой является линолевая: если ее в организме хватает, то линоленовая и арахидоновая кислоты могут дальше уже синтезироваться сами. Интерес к полиненасыщенным жирным кислотам резко возрос на рубеже 70-х и 80-х годов прошлого века, благодаря исследованиям датских ученых.</p>
                  <p>Лучшие натуральные источники витамина F: растительные масла из завязи пшеницы, льняного семени, подсолнечника, сафлора, соевых бобов, арахиса; миндаль, авокадо, овсяные хлопья, кукуруза, неочищенный рис, орехи. Ненасыщенные жирные кислоты легко поступают в организм также и через кожные покровы, не только с пищей, поэтому широко применяются в косметике.</p>
                  <h3>Польза для лица</h3>
                  <p>Витамин F стимулирует процессы регенерации и обмен веществ, поэтому его также называют витамином красоты, как и биотин – витамин Н.</p>
                  <p>При нехватке витамина F кожные покровы очень страдают: образуются язвы, а в некоторых местах даже некрозы; нарушается пигментация кожи, так как выработка меланина не соответствует норме.</p>
                  <p>Предотвращая холестериновые отложения на стенках сосудов и приводя в норму кровообращение во всём организме, витамин F способствует разглаживанию морщин и улучшению цвета лица. Ненасыщенные жирные кислоты поддерживают правильную проницаемость липидного барьера кожи, регулируют уровень увлажненности кожи, снимают воспаления, фактически, являются «строительным материалом» для новых клеток кожи.</p>
                  <h3>Польза для тела</h3>
                  <p>Уникальное свойство витамина F – способность восстанавливать мышечную ткань, используя для этого жировые отложения. Известно, что наша мышечная масса постоянно уменьшается, если мы мало двигаемся, а вместо неё появляется жир. Наиболее выраженное действие на жир оказывает линолевая кислота – она превращает его в мышцы, и для этого даже не нужно выполнять физические упражнения.</p>`,
    shortDescription:
      'Витамин F также препятствует образованию тромбов, естественным образом разжижая кровь, и оказывает благотворное влияние на всю сердечно-сосудистую систему.'
  },
  2: {
    id: 2,
    ingredient: 2,
    mainProperty: 'Регенерация и востановление',
    properties: [
      {
        key: 'Наименование',
        value: 'Витамин F (ненасыщенные жирные кислоты)'
      },
      {
        key: 'Тип',
        value: 'Витамины'
      },
      {
        key: 'Международная номенклатура (INCI)',
        value: 'Vitamin F (Linoleic and linolenic acids)'
      }
    ],
    description: `<h3>Происхождение и получение</h3>
                  <h4>Витамин F – это ненасыщенные жирные кислоты - линолевая, линоленовая и арахидоновая, объединённые под одним наименованием – от английского «fat», что означает – «жир».</h4> 
                  <p>Данные вещества относятся к незаменимым: они просто необходимы для нашей жизни. Основной кислотой является линолевая: если ее в организме хватает, то линоленовая и арахидоновая кислоты могут дальше уже синтезироваться сами. Интерес к полиненасыщенным жирным кислотам резко возрос на рубеже 70-х и 80-х годов прошлого века, благодаря исследованиям датских ученых.</p>
                  <p>Лучшие натуральные источники витамина F: растительные масла из завязи пшеницы, льняного семени, подсолнечника, сафлора, соевых бобов, арахиса; миндаль, авокадо, овсяные хлопья, кукуруза, неочищенный рис, орехи. Ненасыщенные жирные кислоты легко поступают в организм также и через кожные покровы, не только с пищей, поэтому широко применяются в косметике.</p>
                  <h3>Польза для лица</h3>
                  <p>Витамин F стимулирует процессы регенерации и обмен веществ, поэтому его также называют витамином красоты, как и биотин – витамин Н.</p>
                  <p>При нехватке витамина F кожные покровы очень страдают: образуются язвы, а в некоторых местах даже некрозы; нарушается пигментация кожи, так как выработка меланина не соответствует норме.</p>
                  <p>Предотвращая холестериновые отложения на стенках сосудов и приводя в норму кровообращение во всём организме, витамин F способствует разглаживанию морщин и улучшению цвета лица. Ненасыщенные жирные кислоты поддерживают правильную проницаемость липидного барьера кожи, регулируют уровень увлажненности кожи, снимают воспаления, фактически, являются «строительным материалом» для новых клеток кожи.</p>
                  <h3>Польза для тела</h3>
                  <p>Уникальное свойство витамина F – способность восстанавливать мышечную ткань, используя для этого жировые отложения. Известно, что наша мышечная масса постоянно уменьшается, если мы мало двигаемся, а вместо неё появляется жир. Наиболее выраженное действие на жир оказывает линолевая кислота – она превращает его в мышцы, и для этого даже не нужно выполнять физические упражнения.</p>`,
    shortDescription:
      'Витамин F также препятствует образованию тромбов, естественным образом разжижая кровь, и оказывает благотворное влияние на всю сердечно-сосудистую систему.'
  },
  3: {
    id: 3,
    ingredient: 3,
    mainProperty: 'Регенерация и востановление',
    properties: [
      {
        key: 'Наименование',
        value: 'Витамин F (ненасыщенные жирные кислоты)'
      },
      {
        key: 'Тип',
        value: 'Витамины'
      },
      {
        key: 'Международная номенклатура (INCI)',
        value: 'Vitamin F (Linoleic and linolenic acids)'
      }
    ],
    description: `<h3>Происхождение и получение</h3>
                  <h4>Витамин F – это ненасыщенные жирные кислоты - линолевая, линоленовая и арахидоновая, объединённые под одним наименованием – от английского «fat», что означает – «жир».</h4> 
                  <p>Данные вещества относятся к незаменимым: они просто необходимы для нашей жизни. Основной кислотой является линолевая: если ее в организме хватает, то линоленовая и арахидоновая кислоты могут дальше уже синтезироваться сами. Интерес к полиненасыщенным жирным кислотам резко возрос на рубеже 70-х и 80-х годов прошлого века, благодаря исследованиям датских ученых.</p>
                  <p>Лучшие натуральные источники витамина F: растительные масла из завязи пшеницы, льняного семени, подсолнечника, сафлора, соевых бобов, арахиса; миндаль, авокадо, овсяные хлопья, кукуруза, неочищенный рис, орехи. Ненасыщенные жирные кислоты легко поступают в организм также и через кожные покровы, не только с пищей, поэтому широко применяются в косметике.</p>
                  <h3>Польза для лица</h3>
                  <p>Витамин F стимулирует процессы регенерации и обмен веществ, поэтому его также называют витамином красоты, как и биотин – витамин Н.</p>
                  <p>При нехватке витамина F кожные покровы очень страдают: образуются язвы, а в некоторых местах даже некрозы; нарушается пигментация кожи, так как выработка меланина не соответствует норме.</p>
                  <p>Предотвращая холестериновые отложения на стенках сосудов и приводя в норму кровообращение во всём организме, витамин F способствует разглаживанию морщин и улучшению цвета лица. Ненасыщенные жирные кислоты поддерживают правильную проницаемость липидного барьера кожи, регулируют уровень увлажненности кожи, снимают воспаления, фактически, являются «строительным материалом» для новых клеток кожи.</p>
                  <h3>Польза для тела</h3>
                  <p>Уникальное свойство витамина F – способность восстанавливать мышечную ткань, используя для этого жировые отложения. Известно, что наша мышечная масса постоянно уменьшается, если мы мало двигаемся, а вместо неё появляется жир. Наиболее выраженное действие на жир оказывает линолевая кислота – она превращает его в мышцы, и для этого даже не нужно выполнять физические упражнения.</p>`,
    shortDescription:
      'Витамин F также препятствует образованию тромбов, естественным образом разжижая кровь, и оказывает благотворное влияние на всю сердечно-сосудистую систему.'
  },
  4: {
    id: 4,
    ingredient: 4,
    mainProperty: 'Регенерация и востановление',
    properties: [
      {
        key: 'Наименование',
        value: 'Витамин F (ненасыщенные жирные кислоты)'
      },
      {
        key: 'Тип',
        value: 'Витамины'
      },
      {
        key: 'Международная номенклатура (INCI)',
        value: 'Vitamin F (Linoleic and linolenic acids)'
      }
    ],
    description: `<h3>Происхождение и получение</h3>
                  <h4>Витамин F – это ненасыщенные жирные кислоты - линолевая, линоленовая и арахидоновая, объединённые под одним наименованием – от английского «fat», что означает – «жир».</h4> 
                  <p>Данные вещества относятся к незаменимым: они просто необходимы для нашей жизни. Основной кислотой является линолевая: если ее в организме хватает, то линоленовая и арахидоновая кислоты могут дальше уже синтезироваться сами. Интерес к полиненасыщенным жирным кислотам резко возрос на рубеже 70-х и 80-х годов прошлого века, благодаря исследованиям датских ученых.</p>
                  <p>Лучшие натуральные источники витамина F: растительные масла из завязи пшеницы, льняного семени, подсолнечника, сафлора, соевых бобов, арахиса; миндаль, авокадо, овсяные хлопья, кукуруза, неочищенный рис, орехи. Ненасыщенные жирные кислоты легко поступают в организм также и через кожные покровы, не только с пищей, поэтому широко применяются в косметике.</p>
                  <h3>Польза для лица</h3>
                  <p>Витамин F стимулирует процессы регенерации и обмен веществ, поэтому его также называют витамином красоты, как и биотин – витамин Н.</p>
                  <p>При нехватке витамина F кожные покровы очень страдают: образуются язвы, а в некоторых местах даже некрозы; нарушается пигментация кожи, так как выработка меланина не соответствует норме.</p>
                  <p>Предотвращая холестериновые отложения на стенках сосудов и приводя в норму кровообращение во всём организме, витамин F способствует разглаживанию морщин и улучшению цвета лица. Ненасыщенные жирные кислоты поддерживают правильную проницаемость липидного барьера кожи, регулируют уровень увлажненности кожи, снимают воспаления, фактически, являются «строительным материалом» для новых клеток кожи.</p>
                  <h3>Польза для тела</h3>
                  <p>Уникальное свойство витамина F – способность восстанавливать мышечную ткань, используя для этого жировые отложения. Известно, что наша мышечная масса постоянно уменьшается, если мы мало двигаемся, а вместо неё появляется жир. Наиболее выраженное действие на жир оказывает линолевая кислота – она превращает его в мышцы, и для этого даже не нужно выполнять физические упражнения.</p>`,
    shortDescription:
      'Витамин F также препятствует образованию тромбов, естественным образом разжижая кровь, и оказывает благотворное влияние на всю сердечно-сосудистую систему.'
  }
};
