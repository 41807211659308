import React, { Component } from 'react';
import Page from 'modules/core/components/page/page';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';
import BonusConditionsSection from '../components/bonus-conditions/bonus-conditions';

import { getIndexLink } from 'routes/links';

class BonusСonditionsPage extends Component {
  render() {
    return (
      <Page
        title="Бонусная программа"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Бонусная программа'
          }
        ]}
      >
        <Container>
          <BonusConditionsSection />
        </Container>

        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default BonusСonditionsPage;
