import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import OrderInfo from '../components/order-info/order-info';

import * as fromOrders from '../ducks/orders';
import * as fromBasket from '../ducks/basket';

const mapStateToProps = createStructuredSelector({
  productsAmount: fromBasket.getProductsAmount,
  totalCost: fromOrders.getTotalCostById
});

class OrderInfoContainer extends Component {
  render() {
    const { id, productsAmount, totalCost } = this.props;

    return (
      <OrderInfo id={id} productsAmount={productsAmount} totalCost={totalCost} {...this.props} />
    );
  }
}

export default connect(mapStateToProps)(OrderInfoContainer);
