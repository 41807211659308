import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Input from 'modules/form/components/input/input';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import IconSpinner from 'modules/core/components/icons/icon-spinner/icon-spinner';

import Option from './option';

import './autocomplete.css';

class Autocomplete extends Component {
  static Option = Option;

  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    ),
    showOptions: PropTypes.bool
  };

  render() {
    const { showOptions, onInputClick, isLoading, ...inputProps } = this.props;

    delete inputProps.options;
    delete inputProps.onChange;
    delete inputProps.onKeyDown;

    return (
      <div
        className={cn('Autocomplete', `Autocomplete--size-${inputProps.size}`, {
          'Autocomplete--isOpened': showOptions
        })}
      >
        <div className="Autocomplete-current">
          <div className="Autocomplete-input">
            <Input
              {...inputProps}
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              onClick={onInputClick}
              appended={this.renderLoader()}
              autoComplete = 'off'
              autoFill = 'off'
            />
          </div>
        </div>
        {showOptions && this.renderOptionList()}
      </div>
    );
  }

  renderOptionList() {
    const {
      options,
      highlightedOption,
      activeOptionIndex,
      size,
      listRef,
      selectTitle,
      renderTitle
    } = this.props;

    return (
      <div className="Autocomplete-bottom">
        <div className="Autocomplete-optionListHolder">
          <ul className="Autocomplete-optionList" ref={listRef}>
            {options.map((option, index) => (
              <li key={index} className="Autocomplete-option">
                <Option
                  active={activeOptionIndex === index}
                  highlighted={highlightedOption === index}
                  size={size}
                  onClick={this.handleOptionClick(index)}
                  onMouseDown={this.handleOptionMouseDown(index)}
                  innerRef={this.handleOptionRef(index)}
                >
                  {renderTitle
                    ? renderTitle(option, {
                        active: activeOptionIndex === index,
                        highlighted: highlightedOption === index
                      })
                    : selectTitle(option)}
                </Option>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  renderLoader() {
    const { isLoading, appended } = this.props;

    if (!isLoading) {
      return appended;
    }

    return (
      <div className="Autocomplete-appendedIcon">
        <IconContainer size="xsmall">
          <IconSpinner />
        </IconContainer>
      </div>
    );
  }

  handleInputChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;
    onChange(value);
  };

  handleKeyDown = e => {
    const { onKeyDown } = this.props;
    const { keyCode, which } = e;
    onKeyDown(which || keyCode, e);
  };

  handleOptionRef = index => element => {
    const { optionRef } = this.props;
    optionRef(element, index);
  };

  handleOptionClick = index => event => {
    const { onOptionClick } = this.props;
    onOptionClick(event, index);
  };

  handleOptionMouseDown = () => e => {
    e.preventDefault();
  };
}

export default Autocomplete;
