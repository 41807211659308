import { takeEvery, put, select, call } from 'redux-saga/effects';
import takeDebounced from 'modules/utils/saga/take-debounced';
import ifChanged from 'modules/utils/saga/if-changed';

import { queryStringToJSON } from 'modules/utils/get-utm';

import {
  // replace,
  LOCATION_CHANGE
} from 'connected-react-router';
import * as fromUTMBanner from '../ducks/utm-banner';

import { mixitApi } from 'services/mixit';

import { createUtmPersistor } from 'services/local-storage';

import actions from '../ducks/actions/utm-banner';
import { actions as DialogActions } from '../ducks/dialogs';

const OUTPUT_METHODS = {
  BANNER: 'BANNER',
  DIALOG: 'POP_UP'
};

export function* UTMBannerWatcher() {
  yield takeEvery(LOCATION_CHANGE, openUTMBanner);
}

export function* utmPersistenceWatcher() {
  const utmPersistor = createUtmPersistor();

  const rehydrate = function*() {
    const utmList = yield call(utmPersistor.read);

    if (!utmList) {
      return;
    }

    yield put(actions.rehydrate(utmList));
  };

  const persist = function*(utmList) {
    yield call(utmPersistor.write, utmList);
  };

  const getState = function*() {
    return yield select(fromUTMBanner.getAll);
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
  yield takeDebounced('*', ifChanged(getState, persist), 200);
}

function* openUTMBanner(action) {
  const { search } = action.payload.location;
  const query = search;
  // const isUTMDataInStore = yield select(fromUTMBanner.getData);

  const parameters = queryStringToJSON(query);

  // if (isUTMDataInStore) {
  //   yield put(actions.resetData());
  // }

  if (!parameters.utm_campaign && !parameters.utm_term) {
    return;
  }

  try {
    // let UTMData;
    // let utmCodeList = [];

    const UTMData = yield call(mixitApi().targetOffers, query);

    // if (parameters.utm_campaign) {
    //   utmCodeList.push(parameters.utm_campaign);
    // }

    // if (parameters.utm_term) {
    //   utmCodeList.push(parameters.utm_term);
    // }

    // try {
    //   UTMData = yield call(mixitApi().targetOffers, parameters.utm_term);
    //   utmCode = parameters.utm_term;
    // } catch (e) {
    //   UTMData = yield call(mixitApi().targetOffers, parameters.utm_campaign);
    //   utmCode = parameters.utm_campaign;
    // }

    // const utmList = yield select(fromUTMBanner.getUtmList);

    // utmCodeList.forEach(function*(utmCode) {
    //   if (utmList.indexOf(utmCode) !== -1) {
    //     yield removeUTMFromLocation(action);
    //   } else {
    //     yield put(actions.noteUtm(utmCode));
    //   }
    // });

    const normalizedDataList = normalizeData(UTMData.data);

    // const normalizedDataList = normalizeData(mockedUTMData.data);

    yield put(actions.response(normalizedDataList));

    if (hasOutputMethod(OUTPUT_METHODS.BANNER, normalizedDataList)) {
      yield put(actions.openBanner());
    }

    if (hasOutputMethod(OUTPUT_METHODS.DIALOG, normalizedDataList)) {
      yield put(DialogActions.openUTMDialog());
    }
  } catch (e) {
    console.warn(e);
  }
}

function hasOutputMethod(method, dataList) {
  return dataList.some(data => data.type === method);
}

function normalizeData(row_dataList) {
  return row_dataList.map(row_data => {
    const {
      description,
      description_title,
      label,
      title,
      type = OUTPUT_METHODS.BANNER,
      promocode
    } = row_data;
    return {
      description,
      descriptionTitle: description_title,
      label,
      title,
      type: type,
      promoCode: promocode
    };
  });
}

// function* removeUTMFromLocation(action) {
//   const { location } = action.payload;
//   const search = filterUTM(location.search);

//   if (search === location.search) {
//     return;
//   }

//   yield put(
//     replace({
//       ...location,
//       search,
//       skipTrack: true
//     })
//   );
// }

// function queryParametersToObject(search) {
//   return JSON.parse(
//     '{"' +
//       decodeURI(search)
//         .replace(/"/g, '\\"')
//         .replace(/&/g, '","')
//         .replace(/=/g, '":"') +
//       '"}'
//   );
// }

// function filterUTM(search) {
//   if (!search || !search.length) {
//     return search;
//   }
//   const query = search.substring(1);
//   const filteredQuery = query
//     .split('&')
//     .filter(param => !/utm/.test(param))
//     .join('&');

//   return `?${filteredQuery}`;
// }
