import React, { Component } from 'react';
import './icon-phone.css';

class IconPhone extends Component {
  render() {
    return (
      <svg className="IconPhone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M11.7 15c-.8 0-3.7-.3-7.1-3.6C1.8 8.5.8 6.4 1 4c0-.3.1-.6.2-.9.1-.3.3-.7.5-1l.6-.6C3 .9 4 .9 4.6 1.4l2 2c.6.6.5 1.6-.1 2.2l-.7.8a8.52 8.52 0 0 0 3.8 3.8l.8-.8c.6-.6 1.6-.6 2.1 0l2 2c.6.6.6 1.5 0 2.1l-.6.6c-.3.2-.6.4-1 .6-.3.1-.6.2-1 .2.1.1 0 .1-.2.1zM3.5 1.9c-.2 0-.3.1-.4.2l-.6.6c-.1.2-.3.4-.3.7-.1.2-.2.5-.2.7-.2 2.1.7 4 3.3 6.6 3.1 3.1 5.7 3.4 6.4 3.4h.2c.2 0 .5-.1.7-.2.3-.1.5-.2.7-.4l.6-.5c.2-.2.2-.6 0-.8l-2-2a.6.6 0 0 0-.8 0l-1.3 1.3-.5-.3c-.7-.4-1.6-.9-2.6-1.9-1-1-1.5-1.9-1.8-2.6l-.3-.5 1.3-1.3c.2-.2.2-.6 0-.8l-2-2c-.1-.1-.2-.2-.4-.2z" />
      </svg>
    );
  }
}

export default IconPhone;
