import React, { Component } from 'react';
import './icon-lock.css';

class IconLock extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M17.8 8.5v-2c0-2.9-2.6-5.3-5.8-5.3S6.2 3.5 6.2 6.5v2C4.9 8.7 4 9.8 4 11.1v7.1c0 1.4 1.2 2.6 2.6 2.6h10.7c1.4 0 2.6-1.2 2.6-2.6v-7.1c.1-1.3-.8-2.4-2.1-2.6zm-10.9-2C6.9 4 9.2 2 12 2s5.1 2 5.1 4.5v2H6.9v-2zm12.4 11.7c0 1-.9 1.9-1.9 1.9H6.6c-1 0-1.9-.9-1.9-1.9v-7.1c0-1 .9-1.9 1.9-1.9h10.7c1 0 1.9.9 1.9 1.9v7.1z" />
        <path d="M12 11.4c-.8 0-1.5.7-1.5 1.5V15c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-2.2c0-.8-.7-1.4-1.5-1.4zm.7 3.6c0 .4-.3.7-.7.7s-.7-.3-.7-.7v-2.2c0-.4.3-.7.7-.7s.7.3.7.7V15z" />
      </svg>
    );
  }
}

export default IconLock;
