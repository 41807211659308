import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './trigger-switcher.css';

export default class TriggerSwitcher extends Component {
  static propTypes = {
    active: PropTypes.bool
  };

  render() {
    const { active } = this.props;
    return (
      <div
        className={cn('TriggerSwitcher', {
          'TriggerSwitcher-active': active
        })}
      >
        <label className="TriggerSwitcher-label">
          <input
            onChange={this.handleChange}
            type="checkbox"
            checked={active}
            className="TriggerSwitcher-input"
          />
          <div className="TriggerSwitcher-outer">
            <span className="TriggerSwitcher-circle" />
          </div>
        </label>
      </div>
    );
  }

  handleChange = e => {
    const { checked } = e.currentTarget;
    const { onChange } = this.props;
    onChange(checked);
  };
}
