import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '../radio/radio';
import './address-choice.css';

const { number, string, shape, arrayOf, func, oneOfType } = PropTypes;

const addressShape = shape({
  id: oneOfType([number, string]),
  city: string,
  street: string,
  house: string,
  housing: string,
  building: string,
  apartment: string,
  full: string
});

class AddressChoice extends Component {
  static propTypes = {
    /** Массив объектов адресов */
    list: arrayOf(addressShape),
    /** Объект выбранного адреса */
    selected: addressShape,
    /** Ф-я обработчик изменения выбранного города */
    onChange: func
  };

  render() {
    const { list } = this.props;
    return (
      <div className="AddressChoice">
        {list.map(address => (
          <div key={address.id} className="AddressChoice-item">
            <Radio
              name="deliveryAddress"
              value={address.full}
              label={address.full}
              checked={this.isChecked(address)}
              onChange={this.handleChange(address)}
            />
          </div>
        ))}
      </div>
    );
  }

  isChecked(address) {
    const { selected } = this.props;
    if (!selected) {
      return false;
    }
    return address.id === selected.id;
  }

  handleChange = address => e => {
    const { onChange } = this.props;
    if (!e.target.checked) {
      return;
    }
    onChange(address);
  };
}

export default AddressChoice;
