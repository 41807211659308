import React, { Component } from 'react';

import Button from 'modules/core/components/button/button';
import { Link } from 'react-router-dom';
import { getIndexLink } from 'routes/links';

import './empty-basket.css';

class EmptyBasket extends Component {
  render() {
    return (
      <div className="EmptyBasket">
        <div className="EmptyBasket-message">Обрати внимание на мастхэвы MIXIT или</div>
        <div className="EmptyBasket-action">
          <Button title="Начать покупки" variant="primary" component={Link} to={getIndexLink()} />
        </div>
      </div>
    );
  }
}

export default EmptyBasket;
