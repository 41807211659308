import React from 'react';

import './html.css';

export default function Htlm({ data }) {
  return (
    <div
      className="Htlm"
      dangerouslySetInnerHTML={{
        __html: data.html
      }}
    ></div>
  );
}
