import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './button.css';

export default class CatalogButton extends Component {
  static propTypes = {
    /* наличие стрелочки*/
    arrow: PropTypes.bool,
    /** текст кнопки */
    text: PropTypes.string,
    /* иконка кнопки*/
    icon: PropTypes.node,
    /** счетчик кнопки */
    amount: PropTypes.number,
    /** обоработчик клика */
    onClick: PropTypes.func
  };

  static defaultProps = {
    arrow: true
  };

  render() {
    const { text, icon, ...rest } = this.props;
    return (
      <button type="button" className="CategoryHeader_Button" {...rest}>
        {text && <span className="CategoryHeader_Button-title">{text}</span>}
        {this.renderCount()}
        {icon && <span className="CategoryHeader_Button-icon">{icon}</span>}
      </button>
    );
  }

  renderCount() {
    const { count } = this.props;

    if (!count) {
      return null;
    }

    return <span className="CategoryHeader_Button-count">{count}</span>;
  }
}
