import PropTypes from 'prop-types';

const { string, number, shape, oneOf, oneOfType } = PropTypes;

const subwayStation = shape({
  id: number,
  type: oneOf(['SUBWAY_STATION']),
  title: string,
  subwayLine: shape({
    id: number,
    color: string
  }),
  distance: string,
  walkingTime: string
});

const trainStation = shape({
  id: number,
  type: oneOf(['TRAIN_STATION']),
  title: string,
  distance: string,
  walkingTime: string
});

const shoppingMall = shape({
  id: number,
  type: oneOf(['SHOPPING_MALL']),
  title: string,
  distance: string,
  walkingTime: string
});

export default oneOfType([subwayStation, trainStation, shoppingMall]);
