import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'modules/core/components/dropdown/dropdown';
import Button from 'modules/core/components/button/button';
import Link from 'modules/core/components/link/short-link';

import cityShape from '../../prop-types/city-shape';

import './city-select-dropdown.css';

const { func } = PropTypes;

class CitySelectDropdown extends Component {
  static propTypes = {
    current: cityShape,

    /** Обработчик функции подтверждения города */
    onSuggestionConfirm: func.isRequired,

    /** Обработчик функции выбора города */
    onCityChooseClick: func.isRequired
  };

  static propTypes = {
    current: null
  };

  render() {
    const { current, ...rest } = this.props;

    return (
      <Dropdown {...rest}>
        <div className="CitySelectDropdown">
          <div className="CitySelectDropdown-title">
            <nobr>
              Ваш город — <b>{current.title}</b>?
            </nobr>
          </div>
          <div className="CitySelectDropdown-controls">
            <div className="CitySelectDropdown-control">
              <Button
                onClick={this.handleSuggestionConfirm}
                variant="primary"
                title="Да"
                size="small"
              />
            </div>
            <div className="CitySelectDropdown-control">
              <Link component="button" onClick={this.handleChooseCityClick}>
                Выбрать другой
              </Link>
            </div>
          </div>
          <div className="CitySelectDropdown-info">
            От выбранного города зависит наличие товаров и способы доставки
          </div>
        </div>
      </Dropdown>
    );
  }

  handleChooseCityClick = () => {
    const { onCityChooseClick, onChange } = this.props;

    onCityChooseClick();
    onChange();
  };

  handleSuggestionConfirm = () => {
    const { current, onSuggestionConfirm, onChange } = this.props;

    onSuggestionConfirm(current.id);
    onChange();
  };
}

export default CitySelectDropdown;
