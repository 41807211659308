import React, { Component } from 'react';

import CitiesWithShopsBottomSheet from '../cities-with-shops-bottom-sheet/cities-with-shops-bottom-sheet';

import './select-city-mobile.css';

function formButtonTitle(activeOption) {
  if (!activeOption) {
    return '';
  }

  if (activeOption.country) {
    return `${activeOption.title}, ${activeOption.country.title}`;
  }

  return activeOption.title;
}

class SelectCityMobile extends Component {
  state = {
    isOpened: false
  };

  render() {
    const { cityList, activeOptionIndex, isDisabled } = this.props;
    const { isOpened } = this.state;

    const activeOption = cityList[activeOptionIndex];
    const buttonTitle = formButtonTitle(activeOption);

    return (
      <div className="SelectCityMobile">
        <button
          className="SelectCityMobile-button"
          type="button"
          disabled={isDisabled}
          onClick={this.handleClick}
        >
          {buttonTitle}
        </button>

        <CitiesWithShopsBottomSheet
          list={cityList}
          activeOptionIndex={activeOptionIndex}
          opened={isOpened}
          onCitySelect={this.handleCitySelect}
          onClose={this.handleClose}
          scrollable
        />
      </div>
    );
  }

  openBottomSheet() {
    this.setState({ isOpened: true });
  }

  closeBottomSheet() {
    this.setState({ isOpened: false });
  }

  handleClick = () => {
    this.openBottomSheet();
  };

  handleClose = () => {
    this.closeBottomSheet();
  };

  handleCitySelect = city => {
    const { onCitySelect } = this.props;

    this.closeBottomSheet();
    onCitySelect(city.id);
  };
}

export default SelectCityMobile;
