import React, { Component } from 'react';

class LabelGroupItem extends Component {
  render() {
    const { children, inputRef, ...rest } = this.props;
    return (
      <label className="LabelGroup-item">
        {children}
        <input className="LabelGroup-input" ref={inputRef} type="file" {...rest} />
      </label>
    );
  }
}

export default LabelGroupItem;
