import React, { Component } from 'react';
import Page from 'modules/core/components/page/page';

import PaymentResult from '../components/payment-result/payment-result';

class PaymentWrongPage extends Component {
  static path = '/payment-wrong';
  static link = () => '/payment-wrong';
  static exact = true;

  render() {
    return (
      <Page title="К сожалению, оплата не совершена :(">
        {/* <PaymentResult comment="Свяжись с сотрудниками кол-центра по номеру 8 (495) 252-07-43" /> */}
        <PaymentResult comment="Свяжись с сотрудниками кол-центра по номеру 8 (800) 550-98-70 " />
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default PaymentWrongPage;
