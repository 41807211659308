import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import IconArrowDown from 'modules/core/components/icons/icon-arrow-down/icon-arrow-down';
import Input from 'modules/form/components/input/input';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import isFunction from 'modules/utils/is-function';

import Option from './dropdown-option';

import { mapSelectSizeToIcon } from './helpers';

import './dropdown-select.css';

class DropdownSelect extends Component {
  static Option = Option;

  static propTypes = {
    /** Propvided options */
    children: PropTypes.node,
    /** Displayed icon when no options selected */
    placeholderIcon: PropTypes.node,
    /** Provide options filtration when `true` */
    withFilter: PropTypes.bool,
    /** Size of component */
    size: PropTypes.oneOf(['small', 'normal'])
  };

  static defaultProps = {
    children: null,
    placeholderIcon: null,
    withFilter: false,
    size: 'normal'
  };

  state = {
    isOpened: false,
    filterQuery: '',
    selected: false,
    focusIn: false
  };

  render() {
    const { size } = this.props;
    const { isOpened } = this.state;

    return (
      <div
        className={cn('DropdownSelect', `DropdownSelect--size-${size}`, {
          'DropdownSelect--isOpened': isOpened
        })}
      >
        {this.renderCurrent()}
        {isOpened && this.renderOptionList()}
      </div>
    );
  }

  renderCurrent() {
    const { withFilter, children, ...rest } = this.props;
    const { handleInputChange, handleMouseDown, handleInputFocus, handleInputBlur } = this;

    const inputValue = this.getInputValue();

    delete rest.onChange;
    delete rest.placeholderIcon;
    delete rest.onFilterQueryChange;
    delete rest.defaultValue;

    return (
      <div className="DropdownSelect-current">
        <div className="DropdownSelect-input">
          <Input
            value={inputValue}
            selectable={!withFilter}
            onMouseDown={handleMouseDown}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            prepended={this.renderCurrentIcon()}
            appended={this.renderArrowIcon()}
            {...rest}
          />
        </div>
      </div>
    );
  }

  renderCurrentIcon() {
    const { size } = this.props;
    const currentIcon = this.getCurrentIcon();

    if (!currentIcon) return null;

    return (
      <div className="DropdownSelect-currentIcon">
        <IconContainer size={mapSelectSizeToIcon(size)}>{currentIcon}</IconContainer>
      </div>
    );
  }

  renderArrowIcon() {
    const { size } = this.props;

    return (
      <div className="DropdownSelect-arrowIcon">
        <IconContainer size={mapSelectSizeToIcon(size)}>
          <IconArrowDown />
        </IconContainer>
      </div>
    );
  }

  renderOptionList() {
    const { children } = this.props;

    return (
      <div className="DropdownSelect-bottom">
        <div className="DropdownSelect-optionListHolder">
          <Scrollbars autoHeight>
            <ul className="DropdownSelect-optionList">
              {Children.map(children, (child, index) => (
                <li className="DropdownSelect-option">{this.renderOption(child, index)}</li>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    );
  }

  renderOption(option, index) {
    const { size } = this.props;
    const { selected } = this.state;

    return cloneElement(option, {
      size,
      active: selected === index,
      onClick: this.handleOptionClick(index),
      onFocus: this.handleOptionFocus,
      onBlur: this.handleOptionBlur
    });
  }

  getInputValue() {
    const { children } = this.props;
    const { filterQuery, selected, focusIn } = this.state;

    return selected !== false && !focusIn ? children[selected].props.children : filterQuery;
  }

  getCurrentIcon() {
    const { placeholderIcon, children } = this.props;
    const { selected } = this.state;

    if (selected !== false) {
      return children[selected].props.icon;
    }

    if (placeholderIcon) {
      return placeholderIcon;
    }

    return null;
  }

  setFilterQuery(filterQuery) {
    this.setState({
      filterQuery
    });
  }

  open() {
    this.clearBlurTimer();
    this.setState({
      isOpened: true
    });
  }

  close() {
    this.clearBlurTimer();
    this.setState({
      isOpened: false,
      focusIn: false
    });
  }

  select(index) {
    const { onChange, children } = this.props;

    this.setState({
      selected: index
    });

    if (!isFunction(onChange)) return;

    onChange(children[index].props.value);
  }

  focusIn() {
    this.setState({
      focusIn: true
    });
  }

  focusOut() {
    this.setState({
      focusIn: false
    });
  }

  blur() {
    this.clearBlurTimer();
    this._blurTimer = setTimeout(this.handleBlurTimeout, 100);
  }

  handleInputChange = e => {
    const { onFilterQueryChange } = this.props;
    const value = e.target.value;
    this.setFilterQuery(value);
    if (!isFunction(onFilterQueryChange)) {
      return;
    }
    onFilterQueryChange(value);
  };

  handleMouseDown = e => {
    const { isOpened } = this.state;
    const { withFilter } = this.props;

    if (isOpened && !withFilter) {
      this.close();
    } else {
      this.open();
    }
  };

  handleInputFocus = e => {
    this.focusIn();
    this.open();
  };

  handleInputBlur = e => {
    this.focusOut();
    this.blur();
  };

  clearBlurTimer = () => {
    clearTimeout(this._blurTimer);
  };

  handleBlurTimeout = () => {
    const { focusIn } = this.state;
    if (focusIn) return;
    this.close();
  };

  handleOptionClick = index => e => {
    this.select(index);
    this.close();
  };

  handleOptionFocus = () => {
    this.focusIn();
  };

  handleOptionBlur = () => {
    this.focusOut();
    this.blur();
  };
}

export default DropdownSelect;
