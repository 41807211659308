import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-basket.css';

class IconBasket extends Component {
  render() {
    const { size, inline } = this.props;
    const _size = !size || inline ? 'xsmall' : size;
    switch (_size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconBasket">
            <path d="M7.1 11.7c.6 0 1.2.5 1.2 1.2 0 .6-.5 1.2-1.2 1.2-.6-.1-1.1-.6-1.1-1.3 0-.6.5-1.1 1.1-1.1M11.6 11.7c.6 0 1.2.5 1.2 1.2 0 .6-.5 1.2-1.2 1.2-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2M13.3 10.1H6c-.2 0-.4-.1-.4-.3L3.5 2.9h-2c-.3 0-.5-.2-.5-.4s.2-.5.5-.5h2.4c.1 0 .3.1.4.3l2.1 6.8h6.9c.3 0 .5.2.5.5s-.3.5-.5.5z" />
            <path d="M14.1 8.2H5.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8.5c.3 0 .5.2.5.5s-.3.5-.5.5zM14.5 6.3H5c-.3 0-.5-.2-.5-.5s.3-.4.5-.4h9.5c.3 0 .5.2.5.5s-.2.4-.5.4z" />
          </Svg>
        );
      case 'small':
        return (
          <Svg viewBox="0 0 24 24" className="IconBasket">
            <path d="M10.9 16.9c-.8 0-1.5.7-1.5 1.6 0 .9.7 1.6 1.5 1.6s1.5-.7 1.5-1.6c0-.9-.7-1.6-1.5-1.6zM16.7 16.9c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM20.5 8.5H8.3L7 4.4c0-.3-.2-.4-.4-.4H3.5c-.3 0-.5.2-.5.5s.2.5.5.5h2.7l2.7 9.2c.1.2.3.4.5.4h9.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H9.8l-.4-1.5H20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H9l-.4-1.5h11.9c.3 0 .5-.2.5-.5s-.2-.6-.5-.6z" />
          </Svg>
        );
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="1 0 36 36" className="IconBasket">
            <path d="M16.4 24.7c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2zM24.7 24.7c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2zM27.8 21.6H14.3c-.3 0-.5-.2-.6-.5l-4-12.8H5.8c-.3 0-.6-.3-.6-.6 0-.4.3-.7.6-.7h4.4c.3 0 .5.2.6.5l3.9 12.8h13.1c.4 0 .7.3.7.7s-.3.6-.7.6z" />
            <path d="M29.3 18.1H13.5c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h15.8c.4 0 .7.3.7.7s-.4.7-.7.7zM30.2 14.6H12.5c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h17.7c.4 0 .7.3.7.7-.1.4-.4.7-.7.7z" />
          </Svg>
        );
      default:
    }
  }
}

export default IconBasket;
