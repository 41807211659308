import React from "react";
import Media from 'modules/core/components/media/media';
import cn from 'classnames';

export default function ProductGroupVideo({group}) {
    
    const isHorizontal =
    !!group.promoMedia &&
    !!group.promoMedia.media &&
    !!(group.promoMedia.media.length > 0) &&
    getIsHorizontal(group.promoMedia.media[0]);

    return (
        <div className="ProductSectionList-mediaContent" style={{paddingTop: "32px"}}>
        <h2 className="ProductSectionList-groupTitle">{group.promoMedia.title}</h2>
        <div
          className={cn('ProductSectionList-mediaCotainer', {
            'ProductSectionList--mediaCotainerHorizontal': isHorizontal
          })}
        >
          <Media media={group.promoMedia.media[0]} nocaption darkened />
        </div>
      </div>
    )

    function getIsHorizontal(media) {
        if (
          !media.sources ||
          !(media.sources.length > 0) ||
          !media.sources[0].dimensions ||
          !media.sources[0].dimensions.width ||
          !media.sources[0].dimensions.height
        ) {
          return false;
        }
    
        if (media.sources[0].dimensions.width > media.sources[0].dimensions.height) {
          return true;
        }
    
        return false;
      };
}