import React, { Component } from 'react';
import IconFiltering from 'modules/core/components/icons/icon-filtering/icon-filtering';
import IconSorting from 'modules/core/components/icons/icon-sorting/icon-sorting';

// import SortingDialog from '../sorting-dialog/sorting-dialog';
// import FilterDialog from '../filter-dialog/filter-dialog';
import FilterBottomSheet from '../filter-bottom-sheet/filter-bottom-sheet';
import SortingBottomSheet from '../sorting-bottom-sheet/sorting-bottom-sheet';

import CatalogButton from './button';

import './category-header.css';

class CategoryHeader extends Component {
  state = {
    isFilterDialogShow: false,
    isSortingDialogShow: false,
    activeSortTypeIndex: 0
  };

  render() {
    const {
      filters,
      applied,
      quick,
      sortTypeList,
      onQuickFilterApply,
      onFilterChange,
      onFiltersReset,
      renderItem,
      categories
    } = this.props;

    const { isFilterDialogShow, isSortingDialogShow, activeSortTypeIndex } = this.state;

    return (
      <div className="CategoryHeader">
        {!!filters && filters.length > 1 && (
          <div className="CategoryHeader-item">
            <CatalogButton
              text="Фильтровать"
              count={applied.length}
              icon={<IconFiltering />}
              onClick={this.handleFilterDialogShow}
            />

            <FilterBottomSheet
              filters={filters}
              quick={quick}
              applied={applied}
              show={isFilterDialogShow}
              opened={isFilterDialogShow}
              onQuickFilterApply={onQuickFilterApply}
              onClose={this.handleFilterDialogClose}
              onApply={this.handleApplyFilters}
              onReset={this.handleResetFilters}
              onFilterChange={onFilterChange}
              onFiltersReset={onFiltersReset}
            />
          </div>
        )}

        {renderItem && categories.length > 1 && (
          <div className="CategoryHeader-item">{renderItem}</div>
        )}

        <div className="CategoryHeader-item">
          <CatalogButton
            text={sortTypeList[activeSortTypeIndex].title}
            icon={<IconSorting />}
            onClick={this.handleSortingDialogShow}
          />

          <SortingBottomSheet
            sortTypeList={sortTypeList}
            activeSortTypeIndex={activeSortTypeIndex}
            show={isSortingDialogShow}
            opened={isSortingDialogShow}
            onClose={this.handleSortingDialogClose}
            onChange={this.handleSortingChange}
          />
        </div>
      </div>
    );
  }

  showSortingDialog() {
    this.setState({
      isSortingDialogShow: true
    });
  }

  hideSortingDialog() {
    this.setState({
      isSortingDialogShow: false
    });
  }

  showFilterDialog() {
    this.setState({
      isFilterDialogShow: true
    });
  }

  hideFilterDialog() {
    this.setState({
      isFilterDialogShow: false
    });
  }

  handleApplyFilters = () => {};
  handleResetFilters = () => {};

  handleSortDropdownChange = () => {
    const { isSortDropdownShow } = this.state;
    if (isSortDropdownShow) {
      this.closeSortDropdown();
    } else {
      this.openSortDropdown();
    }
  };

  handleViewTrigger = () => {
    const { productsOutput } = this.state;

    if (productsOutput === 2) {
      this.setOneCol();
    } else if (productsOutput === 1) {
      this.setTwoCol();
    } else {
      this.setOneCol();
    }
  };

  handleSortingDialogShow = () => {
    this.showSortingDialog();
  };

  handleSortingDialogClose = () => {
    this.hideSortingDialog();
  };

  handleFilterDialogShow = () => {
    this.showFilterDialog();
  };

  handleFilterDialogClose = () => {
    this.hideFilterDialog();
  };

  handleSortingChange = currentType => {
    const { onSortingChange, sortTypeList } = this.props;

    onSortingChange(sortTypeList[currentType].id);
    this.setState({ activeSortTypeIndex: currentType });
  };
}

export default CategoryHeader;
