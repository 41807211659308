import { createSelector } from 'reselect';
import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';

export const getSerialized = createSelector(
  fromBasket.getEnabledPromos,
  fromBasketProducts.getRawListWithoutGifts,
  fromBasket.getLastChangeDate,
  (promos, goods, lastChangeDate) => {
    return {
      promos,
      goods,
      lastChangeDate
    };
  }
);
