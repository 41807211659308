import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as fromDialogs from '../ducks/dialogs';

import Dialog from 'modules/core/components/dialog/dialog';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsPhysicalTraitsConfirmOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeConfirmPhysicalTraitsDialog
};

class PhysicalTraitsConfirmDialogContainer extends Component {
  render() {
    const { isOpened, close } = this.props;

    return (
      <Dialog closeable show={isOpened} onClose={close}>
        <div className="Questionnaire-confirmDialog">
          <h2 className="Questionnaire-confirmDialogTitle">Физиологические данные были изменены</h2>
        </div>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhysicalTraitsConfirmDialogContainer);
