import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './user-welcome.css';

const { shape, number, string } = PropTypes;

class UserWelcome extends Component {
  static propTypes = {
    /** Данные о пользователе */
    profile: shape({
      /** Полное имя */
      fullName: string,

      /** Фото пользователя */
      photo: string,

      /** Заполненность профиля */
      completeness: number
    }).isRequired,

    /** Данные о бонусном счёте */
    bonusAccount: shape({
      /** Остаток бонусов на счету */
      remained: number,
      available: number
    }).isRequired
  };

  render() {
    const { profile } = this.props;

    return (
      <div className="UserWelcome">
        <div className="UserWelcome-greeting">
          {profile.firstName}, добро пожаловать в личный кабинет!
        </div>
      </div>
    );
  }
}

export default UserWelcome;
