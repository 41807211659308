import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DashboardRow } from './dashboard-row';

const { element, oneOfType, arrayOf } = PropTypes;

export class DashboardStrictRow extends Component {
  static propTypes = {
    /** React элемент(ы) - ячейки сетки  */
    children: oneOfType([element, arrayOf(element)])
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <DashboardRow className="DashboardGrid-row--strictRow" {...rest}>
        {children}
      </DashboardRow>
    );
  }
}

export default DashboardStrictRow;
