import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CityButton from '../components/city-dialog/button';

import locationActions from '../ducks/actions/location';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromCityList from '../ducks/city-list';

const mapStateToProps = createStructuredSelector({
  city: fromCityList.getCityById
});

const mapDispatchToProps = {
  chooseCity: locationActions.chooseCity,
  close: fromDialogs.actions.close
};

class CityButtonContainer extends Component {
  render() {
    const { city } = this.props;

    return <CityButton city={city} onClick={this.handleButtonClick} {...this.props} />;
  }

  handleButtonClick = id => {
    const { chooseCity, close } = this.props;

    chooseCity(id);
    close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityButtonContainer);
