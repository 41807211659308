import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getAnchorPoints from './utils/anchor-points';

class Position extends Component {
  static propTypes = {
    target: __BROWSER__ && PropTypes.instanceOf(HTMLElement).isRequired,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    valign: PropTypes.oneOf(['top', 'bottom']),
    fixed: PropTypes.bool
  };

  static defaultProps = {
    align: 'left',
    valign: 'bottom',
    fixed: false
  };

  render() {
    const { children, style } = this.props;
    return React.cloneElement(children, {
      style: {
        ...style,
        ...this.getStyles()
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this._timeout);
  }

  getStyles() {
    const { align, valign, target, container, fixed } = this.props;
    const anchorPoints = getAnchorPoints(target, container);
    const screenScrollOffset = fixed ? [window.scrollX, window.scrollY] : [0, 0];

    const topScreenOffset = parseInt(container.style.top, 10) || 0;
    const leftScreenOffset = parseInt(container.style.left, 10) || 0;
    const rightScreenOffset = parseInt(container.style.right, 10) || 0;
    const bottomScreenOffset = parseInt(container.style.bottom, 10) || 0;

    let dimensions = {
      top: anchorPoints.bottom - screenScrollOffset[1] - topScreenOffset,
      left: anchorPoints.left - screenScrollOffset[0] - leftScreenOffset,
      right: anchorPoints.right - screenScrollOffset[0] - rightScreenOffset,
      /* document.documentElement.clientHeight */
      bottom: window.innerHeight + screenScrollOffset[1] - anchorPoints.top - bottomScreenOffset
    };

    if (valign === 'top') {
      dimensions.top = 'auto';
    }

    if (valign === 'bottom') {
      dimensions.bottom = 'auto';
    }

    if (align === 'right') {
      dimensions.left = 'auto';
    }

    if (align === 'left') {
      dimensions.right = 'auto';
    }

    if (align === 'center') {
      dimensions.right = 'auto';
      dimensions.left = '50%';
      dimensions.WebkitTransform = 'translateX(-50%)';
      dimensions.MsTransform = 'translateX(-50%)';
      dimensions.transform = 'translateX(-50%)';
    }

    return dimensions;
  }

  handleResize = () => {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => this.forceUpdate(), 50);
  };
}

export default Position;
