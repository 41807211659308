import { createReducer, handle } from 'modules/utils/dux';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { getProductsModule } from '../meta';
import * as CATEGORY from './types/categories';
import * as PRODUCT from './types/products';
import * as GROUP from './types/groups';
import * as BASKET from 'modules/ordering/ducks/basket/types';
import * as PROFILE from 'modules/profile/types/profile';
import * as SEARCH from 'modules/product/ducks/types/search';
import * as ORDER from 'modules/ordering/ducks/types/orders';

import { productsFromDetails } from './products/helpers';

/* REDUCER NAME */
export const NAME = 'variantOptions';

/* ACTIONS */

/* REDUCERS */

const all = createReducer(
  handle(CATEGORY.ADD_PRODUCTS, (state, payload) => {
    return optionsFromProductList(payload.products, state);
  }),

  handle(PRODUCT.ADD_ITEM, (state, payload) => {
    return optionsFromProduct(payload.product, state);
  }),

  handle(GROUP.ADD_ITEM, (state, payload, error) => {
    if (error || !payload.group.products) return state;

    return optionsFromProductList(payload.group.products, state);
  }),

  handle(PRODUCT.ADD_DETAILS, (state, payload) => {
    const productsList = productsFromDetails(payload.details);
    return optionsFromProductList(productsList, state);
  }),

  handle(BASKET.UPDATE_FROM_SERVER, (state, { products }) => {
    return optionsFromProductList(products, state);
  }),

  handle([BASKET.REHYDRATE], (state, { basket }) => {
    const { goods } = basket;
    const productList = goods.map(item => item.product);

    return optionsFromProductList(productList, state);
  }),

  handle(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.products) {
      return state;
    }

    return optionsFromProductTable(entities.products, state);
  }),

  handle(SEARCH.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.product) {
      return state;
    }

    return optionsFromProductTable(entities.product, state);
  }),

  handle(SEARCH.FILTER_RESPONSE, (state, { entities }, error) => {
    if (error || !entities.product) {
      return state;
    }

    return optionsFromProductTable(entities.product, state);
  }),

  handle(ORDER.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order.products) {
      return state;
    }

    return optionsFromProductTable(order.products, state);
  })
);

export default combineReducers({
  all: all({})
});

/* SELECTORS */

export const getRoot = createSelector(getProductsModule, productsModule => productsModule[NAME]);

export const getAll = createSelector(getRoot, root => root.all);

/* ACTION_CREATORS */

/* HELPERS */

function optionsFromProductList(products, options = {}) {
  return products.reduce((options, product) => {
    return optionsFromProduct(product, options);
  }, options);
}

function optionsFromProduct(product, options = {}) {
  if (!product.variant_options) {
    return options;
  }

  return product.variant_options.reduce((options, option) => {
    return {
      ...options,
      [option.id]: option
    };
  }, options);
}

function optionsFromProductTable(productTable, options = {}) {
  return Object.values(productTable).reduce((options, product) => {
    return optionsFromProduct(product, options);
  }, options);
}
