import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

const { number, bool, string, oneOfType } = PropTypes;

class PaginationButton extends Component {
  static propTypes = {
    current: bool,

    page: oneOfType([number, string])
  };

  render() {
    const { current, onClick, page, ...rest } = this.props;

    return (
      <button
        // href="#ProductReview"
        disabled={current}
        aria-pressed={current}
        data-testid={current ? 'pagination-button-current' : 'pagination-button'}
        className={classNames({
          'Pagination-button': !current,
          'Pagination-current': current
        })}
        onClick={this.handleClick(current)}
        {...rest}
      >
        <span className="Pagination-page">{page}</span>
      </button>
    );
  }

  handleClick = current => () => {
    const { onClick } = this.props;
    if (current) {
      return;
    }

    if (!isFunction(onClick)) {
      return;
    }
    onClick();
  };
}

export default PaginationButton;
