import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import articleShape from './../../prop-types/article-shape';
import ArticleBanner from '../article-banner/article-banner';
import ArticleCard from '../article-card/article-card';

import './hero.css';

class Hero extends Component {
  static propTypes = {
    articleList: PropTypes.arrayOf(articleShape)
  };

  render() {
    const { articleList } = this.props;

    if (articleList.length > 3) {
      articleList.length = 3;
    }
    const articleAmount = articleList.length;

    return (
      <div className={cn('Hero', `Hero--${articleAmount}cols`)}>
        {articleAmount === 1 && <ArticleBanner article={articleList[0]} />}
        {articleAmount > 1 && (
          <div className="Hero-itemsWrapper">
            {articleList.map(article => (
              <div key={article.id} className="Hero-item">
                {this.renderCard(article, articleAmount)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  renderCard(article, amount) {
    return <ArticleCard article={article} size={amount === 2 ? 'medium' : 'small'} />;
  }
}

export default Hero;
