import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import * as fromProfile from '../ducks/profile';
import * as routes from '../constants/routes';
import { createStructuredSelector } from 'reselect';
import AccountActions from 'modules/core/ducks/actions/account';

import {
  IconUser,
  // IconComments,
  IconPackage,
  IconLock
} from 'modules/core/components/icons';

function getGeneralItem() {
  return {
    ...routes.general,
    icon: <IconUser />
  };
}

function getOrdersItem(count) {
  return {
    ...routes.orderings,
    label: count ? count : undefined,
    icon: <IconPackage />
  };
}

// function getSubscriptions() {
//   return {
//     ...routes.subscriptions,
//     icon: <IconComments />
//   };
// }

function changePassword() {
  return {
    ...routes.changePassword,
    icon: <IconLock />
  };
}

function getNavigation(ordersCount) {
  return [
    getGeneralItem(),
    // routes.dashboard,
    getOrdersItem(ordersCount),
    // routes.offers,
    // routes.shops,
    // getSubscriptions(),
    changePassword()
  ];
}

const mapStateToProps = createStructuredSelector({
  ordersCount: fromProfile.getOrderCount
});

const mapDispatchToProps = {
  signOut: AccountActions.signOut
};

class NavigationContainer extends Component {
  render() {
    const { signOut } = this.props;
    const navigation = getNavigation(this.props.ordersCount);

    return <Navigation list={navigation} onLogOut={signOut} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);
