import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import isFunction from 'modules/utils/is-function';
import Questionnaire from '../components/questionnaire/questionnaire';
import * as fromPhysicalTraits from '../ducks/physical-traits';
import Profile from '../actions/profile';
import Loader from 'modules/core/components/loader/loader';

import { FACE_TRAITS, HAIR_TRAITS, BODY_TRAITS } from './traits/index';

const allTraits = [FACE_TRAITS, HAIR_TRAITS, BODY_TRAITS];

const mapState = createStructuredSelector({
  checkedTraits: fromPhysicalTraits.getCheckedPhysicalTraits,
  isInitialLoading: fromPhysicalTraits.getIsInitialLoading,
  isChangesLoading: fromPhysicalTraits.getIsChangesLoading,
  errorMessage: fromPhysicalTraits.getErrorMessage,
  isConfirmDialogOpened: fromPhysicalTraits.getIsConfirmDialogOpened
});

const mapActions = {
  onChange: Profile.physicalTraitsChange,
  onShow: Profile.physicalTraitsShow
};

class QuestionnaireContainer extends Component {
  render() {
    const {
      onChange,
      isChangesLoading,
      errorMessage,
      isInitialLoading,
      isConfirmDialogOpened,
      checkedTraits
    } = this.props;

    if (isInitialLoading) {
      return (
        <div style={{ position: 'relative', padding: '120px' }}>
          <Loader type="spin" />
        </div>
      );
    }

    return (
      <Questionnaire
        key={isConfirmDialogOpened}
        allOptions={allTraits}
        checkedValues={checkedTraits}
        onSubmit={onChange}
        isLoading={isChangesLoading}
        errorMessage={errorMessage}
        isConfirmDialogOpened={isConfirmDialogOpened}
      />
    );
  }

  componentDidMount() {
    const { onShow } = this.props;

    if (!isFunction(onShow)) {
      return;
    }
    onShow();
  }
}

export default connect(
  mapState,
  mapActions
)(QuestionnaireContainer);
