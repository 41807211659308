const pickPointList = {
  1: {
    id: 1,
    title: 'Зеленоград ПС1',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. 1-я Останкинская',
        building: 'д. 55',
        raw: 'ул. 1-я Останкинская, д. 55'
      },
      coordinates: {
        lat: 44.71677,
        lng: 65.482338
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Выставочная',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [1, 2, 3, 5],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [6],
        time: '13:00:00/18:00:00',
        break: '12:00:00/13:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: true
    }
  },
  2: {
    id: 2,
    title: 'Марксистская',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Марксистская',
        building: 'д. 16',
        raw: 'ул. Марксистская,  д. 16'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Выставочная',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [1, 2, 3, 4, 5, 6],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [0],
        time: '10:00:00/18:00:00',
        break: '14:00:00/16:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  3: {
    id: 3,
    title: 'Сыромятническая',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Сыромятническая',
        building: 'д. 11к1',
        raw: 'ул. Сыромятническая, д. 11к1'
      },
      coordinates: {
        lat: 55.75336207,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Багратионовская',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '12:00:00/14:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  4: {
    id: 4,
    title: 'Дмитрия Ульянова',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Дмитрия Ульянова',
        building: 'д. 8к1',
        raw: 'ул. Дмитрия Ульянова, д. 8к1'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Юго-Западная',
          subwayLine: 1,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '11:00:00/12:30:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:30:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  5: {
    id: 5,
    title: 'Ломоносовский',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Ломоносовский',
        building: 'д. 18',
        raw: 'ул. Ломоносовский, д. 18'
      },
      coordinates: {
        lat: 55.71677,
        lng: 37.482338
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Беляево',
          subwayLine: 6,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '13:00:00/14:30:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:30:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: true
    }
  },
  6: {
    id: 6,
    title: 'Студенстуденческая',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Студенческая',
        building: 'д. 31',
        raw: 'ул. Студенческая, д. 31'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Крымская',
          subwayLine: 14,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '14:00:00/15:00:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:00:00'
      }
    ],
    payment: {
      hasTerminal: true
    }
  },
  7: {
    id: 7,
    title: 'Зеленоград ПС1',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. 1-я Останкинская',
        building: 'д. 55',
        raw: 'ул. 1-я Останкинская, д. 55'
      },
      coordinates: {
        lat: 55.71677,
        lng: 37.482338
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Выставочная',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [1, 2, 3, 5],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [6],
        time: '13:00:00/18:00:00',
        break: '12:00:00/13:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: true
    }
  },
  8: {
    id: 8,
    title: 'Марксистская',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Марксистская',
        building: 'д. 16',
        raw: 'ул. Марксистская,  д. 16'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Выставочная',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [1, 2, 3, 4, 5, 6],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [0],
        time: '10:00:00/18:00:00',
        break: '14:00:00/16:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  9: {
    id: 9,
    title: 'Сыромятническая',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Сыромятническая',
        building: 'д. 11к1',
        raw: 'ул. Сыромятническая, д. 11к1'
      },
      coordinates: {
        lat: 55.75336207,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Багратионовская',
          subwayLine: 4,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '12:00:00/14:00:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '12:00:00/14:00:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  10: {
    id: 10,
    title: 'Дмитрия Ульянова',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Дмитрия Ульянова',
        building: 'д. 8к1',
        raw: 'ул. Дмитрия Ульянова, д. 8к1'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Юго-Западная',
          subwayLine: 1,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '11:00:00/12:30:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:30:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: false
    }
  },
  11: {
    id: 11,
    title: 'Ломоносовский',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Ломоносовский',
        building: 'д. 18',
        raw: 'ул. Ломоносовский, д. 18'
      },
      coordinates: {
        lat: 55.71677,
        lng: 37.482338
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Беляево',
          subwayLine: 6,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '13:00:00/14:30:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:30:00'
      }
    ],
    contacts: ['+44(0870)770-53-70', '+43(6764)50-55-09'],
    payment: {
      hasTerminal: true
    }
  },
  12: {
    id: 12,
    title: 'Студенческая',
    type: 'PICK_POINT_TYPE/PICK_POINT',
    delivery: {
      minDuration: 'P2D',
      maxDuration: 'P5D'
    },
    description: 'Самый лучший PickPoint',
    geography: {
      address: {
        streetTitle: 'ул. Студенческая',
        building: 'д. 31',
        raw: 'ул. Студенческая, д. 31'
      },
      coordinates: {
        lat: 55.753994,
        lng: 37.622093
      },
      guides: [
        {
          id: 1,
          type: 'GUIDE_TYPE/SUBWAY_STATION',
          title: 'Крымская',
          subwayLine: 14,
          distance: '2км',
          walkingTime: 'PT15M'
        },
        {
          id: 2,
          type: 'GUIDE_TYPE/SHOPPING_MALL',
          title: 'Ocean Plaza',
          distance: '1км',
          walkingTime: 'PT2M'
        }
      ]
    },
    schedule: [
      {
        days: [0, 1, 2, 3, 4, 5],
        time: '10:00:00/20:00:00',
        break: '14:00:00/15:00:00'
      },
      {
        days: [6],
        time: '10:00:00/18:00:00',
        break: '11:00:00/12:00:00'
      }
    ],
    payment: {
      hasTerminal: true
    }
  }
};

export default pickPointList;
