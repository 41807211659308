import React, { Component } from 'react';
import './icon-printer.css';

class IconPrinter extends Component {
  render() {
    return (
      <svg
        className="IconPrinter"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 24 24"
      >
        <path d="M20.3 7.3h-2.1V3.5c0-.4-.3-.7-.7-.7H6.2c-.4 0-.7.3-.7.7v3.8H3.4c-.8 0-1.5.7-1.5 1.5v6.6c0 .8.7 1.5 1.6 1.5h2.1v3.5c0 .4.3.7.7.7h11.3c.4 0 .7-.3.7-.7V17h2.1c.9 0 1.6-.7 1.6-1.5V8.8c-.1-.8-.8-1.5-1.7-1.5zM6.9 4.2h10v3.1h-10V4.2zm10 15.6h-10v-5.4h10v5.4zm3.6-4.4c0 .1-.1.2-.2.2h-2.1v-1.8c0-.4-.3-.7-.7-.7H6.2c-.4 0-.7.3-.7.7v1.8H3.4c-.1 0-.2-.1-.2-.2V8.8c0-.1.1-.2.2-.2h16.9c.1 0 .2.1.2.2v6.6z" />
        <path d="M17.5 9.8h-1.7c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h1.7c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7zM14.8 17.5H9c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h5.8c.4 0 .7-.3.7-.7-.1-.4-.4-.7-.7-.7zM14.8 15.5H9c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h5.8c.4 0 .7-.3.7-.7-.1-.4-.4-.7-.7-.7z" />
      </svg>
    );
  }
}

export default IconPrinter;
