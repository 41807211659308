export const CHANGE_COUNTRY = 'ordering/ordering-form/CHANGE_COUNTRY';
export const CHANGE_CITY = 'ordering/ordering-form/CHANGE_CITY';
export const CHANGE_PAYMENT_METHOD = 'ordering/ordering-form/CHANGE_PAYMENT_METHOD';
export const CHANGE_DELIVERY_METHOD = 'ordering/ordering-form/CHANGE_DELIVERY_METHOD';
export const CHANGE_PASSWORD_CREATING = 'ordering/ordering-form/CHECK_PASSWORD_CREATING';
export const CHANGE_COMMENT = 'ordering/ordering-form/CHANGE_COMMENT';
export const CHANGE_EMAIL = 'ordering/ordering-form/CHANGE_EMAIL';
export const CHANGE_NUMBER = 'ordering/ordering-form/CHANGE_NUMBER';
export const CHANGE_NAME = 'ordering/ordering-form/CHANGE_NAME';
export const CHANGE_STREET = 'ordering/ordering-form/CHANGE_STREET';
export const CHANGE_HOUSE = 'ordering/ordering-form/CHANGE_HOUSE';
export const CHANGE_HOUSING = 'ordering/ordering-form/CHANGE_HOUSING';
export const CHANGE_BUILDING = 'ordering/ordering-form/CHANGE_BUILDING';
export const CHANGE_APARTMENT = 'ordering/ordering-form/CHANGE_APARTMENT';
