import { takeEvery, select, put } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
// import { replace } from 'connected-react-router';
// import { matchPath } from 'react-router';

// import { getError404Link } from 'routes/links';
// import { dataList2 } from 'modules/landing/pages/configs/new-shops';

// import * as LANDINGS_TYPES from '../ducks/types';
import * as fromNavigations from '../ducks/navigations';

import normalizeNavigation from './helpers/navigation-normalizr';

import mockedData from './helpers/mocked-navigation-data'; // , {navigationConfig2}

export function* watchLandings() {
  // yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}

function* handleLocationChange() {
  const isLandingDataLoaded = yield select(fromNavigations.getNavigationDataLoaded);
  if (isLandingDataLoaded) {
    return;
  }

  let normalizedMockedData = normalizeNavigation(mockedData);

  yield put(fromNavigations.actions.add(normalizedMockedData));
  yield put(fromNavigations.actions.setLoaded());
  return;
}
