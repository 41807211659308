import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Page, PageContent } from 'modules/core/components/page';
import { getIndexLink } from 'routes/links';
import SectionShopsContainer from '../containers/section-shops';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';

class ShopsPage extends Component {
  render() {
    return (
      <Page
        title="Наши магазины"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Магазины'
          }
        ]}
      >
        <Helmet>
          <title>Все магазины MIXIT. Адреса, телефоны, график работы.</title>

          <meta
            name="description"
            content="Список всех магазинов MIXIT в России. Адреса, телефоны, график работы. Найти магазин Миксит в вашем городе можно на этой странице."
          />
        </Helmet>
        <PageContent>
          <SectionShopsContainer />
          <SectionDefault>
            <Container>
              <FeedbackCall />
            </Container>
          </SectionDefault>
        </PageContent>
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default ShopsPage;
