import React, { Component } from 'react';

import Dialog from 'modules/core/components/dialog/dialog';

import FieldsetChoice from 'modules/form/components/fieldset-choice/fieldset-choice';
import Checkbox from 'modules/form/components/checkbox/checkbox';

import CategoryFieldTrigger from './category-field-trigger';

import './category-field-dialog.css';

class CategoryFieldDialog extends Component {
  state = {
    show: false
  };

  render() {
    const { categories, selected } = this.props;

    const { show } = this.state;

    return (
      <div className="CategoryFieldDialog">
        <CategoryFieldTrigger count={selected.length} onClick={this.handleDialogOpen} />

        <Dialog title="Фильтр категорий" onClose={this.handleDialogClose} show={show} closeable>
          <div className="CategoryFieldDialog-content">
            <FieldsetChoice>
              {categories.map(item => {
                return (
                  <FieldsetChoice.Item>
                    <Checkbox
                      label={item.title}
                      name="category_filter"
                      value={item.slug}
                      checked={selected.indexOf(item.slug) !== -1}
                      onChange={this.handleChange(item.slug)}
                    />
                  </FieldsetChoice.Item>
                );
              })}
            </FieldsetChoice>
          </div>
        </Dialog>
      </div>
    );
  }

  handleChange = slug => e => {
    const { checked } = e.target;
    const { onChange } = this.props;
    onChange(slug, checked);
  };

  handleDialogClose = () => {
    this.setState({
      show: false
    });
  };

  handleDialogOpen = () => {
    this.setState({
      show: true
    });
  };
}

export default CategoryFieldDialog;
