import React, { Component } from 'react';
import './section-product-consistency.css';
import Container from 'modules/core/components/container/container';
import ProductConsistency from '../product-consistency/product-consistency';

class SectionProductConsistency extends Component {
  render() {
    const { consistency } = this.props;
    return (
      <section className="SectionProductConsistency" id="consistency" name="consistency">
        <Container>
          <ProductConsistency consistency={consistency} />
        </Container>
      </section>
    );
  }
}

export default SectionProductConsistency;
