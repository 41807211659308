import React, { Component } from 'react';
import Dialog from 'modules/core/components/dialog/dialog';
import OrderReceiptContainer from '../../containers/order-receipt';
import './product-overview-dialog.css';

class ProductOverviewDialog extends Component {
  render() {
    const { id, ...rest } = this.props;
    return (
      <Dialog closeable {...rest}>
        <div className="ProductOverviewDialog">
          <OrderReceiptContainer id={id} />
        </div>
      </Dialog>
    );
  }
}

export default ProductOverviewDialog;
