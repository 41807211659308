import * as ProfileAdapter from './profile-adapter';

export function normalize(apiAccount) {
  const { email, phone, id } = apiAccount;

  const account = {
    id,
    email,
    phone
  };

  const { profile, entities } = ProfileAdapter.normalize(apiAccount.profile);

  return {
    account,
    entities: {
      profile,
      ...entities
    }
  };
}
