import React, { Component } from 'react';

import Container from '../container/container';

import './section-shops.css';

class SectionShops extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="SectionShops">
        <Container>{children}</Container>
      </div>
    );
  }
}

export default SectionShops;
