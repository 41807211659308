import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import './switch.css';
import Option from './switch-option';
import current from './helpers.js';

const arrowLeft = 37;
const arrowUp = 38;
const arrowRight = 39;
const arrowDown = 40;

class Switch extends Component {
  static Option = Option;

  static propTypes = {
    /** обработчик изменения опции */
    onChange: PropTypes.func,
    /** элемент который оборачивает все опции */
    switchHolder: PropTypes.element,
    /** элемент который оборачивает каждую опцию */
    optionHolder: PropTypes.element,
    /** передаются элементы, которые будут являтся опциями */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
      .isRequired,
    /** selected option id */
    selected: PropTypes.number
  };

  static defaultProps = {
    switchHolder: <div className="Switch" />
  };

  state = {
    currentOption: current(this.props)
  };

  optionElements = [];

  render() {
    const { switchHolder } = this.props;

    return React.cloneElement(switchHolder, {
      role: 'radiogroup',
      onKeyDown: this.handleKeyDown,
      children: this.renderOptions()
    });
  }

  renderOptions() {
    const { children, optionHolder, selected } = this.props;
    const { currentOption } = this.state;

    const current = selected || currentOption;

    return React.Children.map(children, element => {
      const { value } = element.props;

      return React.cloneElement(element, {
        optionHolder,
        key: value,
        checked: current === value,
        onClick: this.handleClick(element),
        buttonRef: this.handleOptionRef(value)
      });
    });
  }

  handleOptionRef = value => element => {
    this.optionElements[value] = findDOMNode(element);
  };

  handleClick = element => e => {
    const { value, disabled } = element.props;
    if (disabled) return;
    if (this.isCurrent(value)) return;
    this.setCurrent(value);
  };

  isCurrent(value) {
    const { currentOption } = this.state;
    return value === currentOption;
  }

  change(value) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') onChange(value);
  }

  setCurrent(value) {
    this.setState({ currentOption: value });
    this.focusOption(value);
    this.change(value);
  }

  handleKeyDown = e => {
    switch (e.keyCode) {
      case arrowLeft:
      case arrowUp:
        e.preventDefault();
        this.goToPreviousOption();
        break;
      case arrowRight:
      case arrowDown:
        e.preventDefault();
        this.goToNextOption();
        break;
      default:
        break;
    }
  };

  goToNextOption() {
    this.step(1);
  }

  goToPreviousOption() {
    this.step(-1);
  }

  step(size) {
    const { currentOption } = this.state;
    const { children } = this.props;

    const activeOptions = children.filter(item => !item.props.disabled);

    if (!activeOptions.length) {
      return null;
    }

    const values = activeOptions.map(item => item.props.value);
    const currentIndex = values.indexOf(currentOption);
    const count = activeOptions.length;

    let newIndex = (currentIndex + size + count) % count;

    this.setCurrent(values[newIndex]);
  }

  focusOption(value) {
    this.optionElements[value].focus();
  }
}

export default Switch;
