import { mixitApi } from 'services/mixit';
import { takeEvery, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { SuggestAdapter } from 'services/mixit/adapters';
import AutosuggestedProducts from '../ducks/autosuggested-products/actions';
import * as AUTOSUGGESTED_PRODUCTS from '../ducks/autosuggested-products/types';

export function* suggestWatcher() {
  yield takeEvery(AUTOSUGGESTED_PRODUCTS.REQUEST, suggest);
}

function* suggest(action) {
  const { query } = action.payload;

  if (!query) {
    return;
  }

  yield delay(200);

  const { error, response } = yield call(suggestRequest, query, {
    withFilters: false,
    withCategories: false
  });

  if (error) {
    yield put(AutosuggestedProducts.responseError(query, 'Ошибка получения результатов'));
    return;
  }

  yield put(
    AutosuggestedProducts.response(response.products, response.amount, response.totalAmount)
  );
}

function* suggestRequest(query, { category, filters, sort, skip, count }) {
  try {
    const sortParameter = (sort || {}).parameter;
    const sortOrder = (sort || {}).order;

    const parameters = {
      category_slug: category,
      filter: filters,
      sort: sortParameter,
      order: sortOrder,
      skip: skip,
      count: count
    };

    const rawResponse = yield call(mixitApi().suggest, query, parameters);

    const response = SuggestAdapter.normalize(rawResponse.data);

    return { response };
  } catch (error) {
    return { error };
  }
}
