import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getIndexLink } from 'routes/links';

class LoginDialogRoute extends Component {
  render() {
    return <Redirect {...this.props} to={getIndexLink()} />;
  }

  componentDidMount() {
    const { isAuthorized, openRegistrationDialog } = this.props;

    if (!isAuthorized) {
      openRegistrationDialog();
    }
  }
}

export default LoginDialogRoute;
