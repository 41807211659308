export default {
  serialize(account) {
    const persisted = {
      id: account.id,
      email: account.email,
      authorization: account.authorization
    };

    return JSON.stringify(persisted);
  },

  deserialize(persistedJson) {
    const persisted = JSON.parse(persistedJson);

    return {
      id: persisted.id,
      email: persisted.email,
      authorization: persisted.authorization
    };
  }
};
