import React, { Component } from 'react';

import IconContainer from 'modules/core/components/icon-container/icon-container';

import { IconAngleArrowDown } from 'modules/core/components/icons';

import SortingDropdown from '../../sorting-dropdown/sorting-dropdown';
import isFunction from 'modules/utils/is-function';
import './select-field.css';

class SelectField extends Component {
  state = {
    activeSortTypeIndex: 0,
    isDropdownShowed: false
  };
  render() {
    const { sortTypeList } = this.props;

    const { activeSortTypeIndex, isDropdownShowed } = this.state;

    return (
      <div className="SelectField">
        <div className="SelectField-label">Сортировать</div>
        <div className="SelectField-dropdown">
          <SortingDropdown
            align="right"
            show={isDropdownShowed}
            trigger={
              <button className="SelectField-button" onClick={this.handleTriggerClick}>
                <span className="SelectField-buttonText">
                  {sortTypeList[activeSortTypeIndex].title}
                </span>
                <span className="SelectField-buttonIcon">
                  <IconContainer inline>
                    <IconAngleArrowDown />
                  </IconContainer>
                </span>
              </button>
            }
            list={sortTypeList}
            onSortTypeSelect={this.handleSortTypeSelect}
            closeDropdown={this.handleDropdownClose}
            openDropdown={this.handleDropdownOpen}
          />
        </div>
      </div>
    );
  }

  handleDropdownClose = () => {
    this.setState({ isDropdownShowed: false });
  };

  handleDropdownOpen = () => {
    this.setState({ isDropdownShowed: true });
  };

  handleSortTypeSelect = activeSortTypeIndex => {
    const { sortTypeList, onChange } = this.props;
    this.setState({ activeSortTypeIndex, isDropdownShowed: false });
    if (isFunction(onChange)) {
      onChange(sortTypeList[activeSortTypeIndex].id);
    }
  };
}

export default SelectField;
