import React, { Component } from 'react';

class WarningPicture extends Component {
  state = {};
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 49">
        <circle cx="24" cy="24.25" r="24" fill="#FFCB47" fillOpacity=".2" />
        <path
          fill="#FFCB47"
          d="M22.94 14.02a1.252 1.252 0 0 1 2.12 0l10.745 17.06a1.252 1.252 0 0 1-1.06 1.92h-21.49a1.252 1.252 0 0 1-1.06-1.92L22.94 14.02z"
        />
        <path
          fill="#000"
          d="M24.898 27.216h-1.761l-.25-7.404h2.26l-.25 7.404zm-.88 1.159c.356 0 .642.105.858.316.22.21.33.479.33.807 0 .322-.11.59-.33.8-.216.21-.502.315-.859.315-.352 0-.638-.105-.858-.316a1.071 1.071 0 0 1-.323-.8c0-.322.107-.589.323-.8.22-.214.506-.322.858-.322z"
        />
      </svg>
    );
  }
}

export default WarningPicture;
