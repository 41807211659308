import ProductsSale from 'modules/product/components/products-sale/products-sale';
import { connect } from 'react-redux';
import * as fromGroups from 'modules/product/ducks/groups';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({
  sale: fromGroups.getSale
});

const mapDispatchToProps = {
  showItems: fromGroups.actions.showSale
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsSale);
