import React, { Component } from 'react';
import cn from 'classnames';

import './icon-dislike.css';

class IconDislike extends Component {
  render() {
    const { filled } = this.props;
    return (
      <svg
        className={cn('IconDislike', {
          'IconDislike--filled': filled
        })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
      >
        <path d="M22.1 4.6c2.9 0 6.4 0 6.4-.1 1.5 0 2.7 1 2.7 2.3 0 .4-.2.8-.4 1.2.7.5 1.4 1.1 1.4 2 0 .8-.4 1.3-.7 1.7.7.5 1.4 1.4 1.4 2.4 0 1.1-.6 2-1.4 2.5.3.5.7 1.1.7 1.7.2 1.9-1.4 2.9-2.9 2.9h-7l.1.4c.2.7 1.6 5.3-.1 8.2-.8 1.5-1.6 1.8-2.2 1.8-.5 0-.9-.1-1.2-.5-.6-.6-.8-1.6-.7-2.8.4-3.4-3.8-6.9-3.8-6.9l-.1-.1c-2.3-1.9-3.6-4.5-3.6-7.3 0-5.6 2.7-8.8 7.6-9.4h3.8m-15.3.5c1.9 0 3.4 1.3 3.3 2.9v10.6c0 1.5-1.5 2.8-3.3 2.8h-.4c-1.8 0-3.3-1.2-3.3-2.8V7.9c0-1.5 1.5-2.8 3.3-2.8h.4" />
      </svg>
    );
  }
}

export default IconDislike;
