import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromCategories from 'modules/product/ducks/categories';

import PackingDialog from '../components/packing-dialog/packing-dialog';

const mapStateToProps = createStructuredSelector({
  products: fromCategories.getProductSlugList,
  availableProducts: fromCategories.getAvailableProductSlugList,
  isLoaded: fromCategories.getIsProductsLoaded,
  isOpened: fromDialogs.getIsGiftOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close,
  loadCategoryProducts: fromCategories.actions.loadProducts
};

class PackingDialogContainer extends Component {
  render() {
    const { availableProducts, isLoaded, isOpened } = this.props;

    return (
      <PackingDialog
        products={availableProducts}
        isLoaded={isLoaded}
        show={isOpened}
        onClose={this.handleDialogClose}
      />
    );
  }

  componentDidMount() {
    const { slug } = this.props;

    this.showCategory(slug);
  }

  showCategory(category) {
    const { loadCategoryProducts } = this.props;
    loadCategoryProducts(category);
  }

  handleDialogClose = () => {
    const { close } = this.props;

    close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingDialogContainer);
