import { Component } from 'react';
import * as Sentry from '@sentry/browser';

class HideErrorBoundary extends Component {
  state = {
    error: null
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!children || error) {
      return null;
    }

    return children;
  }

  componentDidCatch(error, errorInfo) {
    const { errorMassage } = this.props;

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtra('message', errorMassage);
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }

    console.warn(errorMassage || error);

    this.setState({ error });
  }
}

export default HideErrorBoundary;
