import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ConfirmPasswordRecoveryDialog from '../components/confirm-password-recovery-dialog/confirm-password-recovery-dialog';
import * as fromDialogs from '../ducks/dialogs';

import { getConfirmKey } from '../ducks/recovery-password';

import actions from '../ducks/actions/recovery-password';

// function getParameterByName(name, url) {
//   if (!url) url = window.location.href;
//   name = name.replace(/[[\]]/g, '\\$&');
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsConfirmPasswordRecoveryOpened,
  confirmationKey: getConfirmKey
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closeConfirmPasswordRecovery,
  confirmPasswordRequest: actions.confirmPasswordRequest
};

class ConfirmPasswordRecoveryDialogContainer extends Component {
  // state = {
  //   confirmationKey: null
  // };

  render() {
    const { isOpened, confirmPasswordRequest, confirmationKey } = this.props;

    if (!confirmationKey) {
      return null;
    }

    return (
      <ConfirmPasswordRecoveryDialog
        show={isOpened}
        onClose={this.handleClose}
        confirmationKey={confirmationKey}
        onSubmit={confirmPasswordRequest}
      />
    );
  }

  // componentDidMount() {
  //   const url = window.location.search;
  //   const confirmationKey = getParameterByName('code', url);
  //   this.setConfirmationKey(confirmationKey);
  // }

  // setConfirmationKey(confirmationKey) {
  //   this.setState({ confirmationKey });
  // }

  handleClose = () => {
    this.props.close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPasswordRecoveryDialogContainer);
