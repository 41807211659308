import React, { Component } from 'react';

import Dialog from '../dialog/dialog';
import DialogSection from '../dialog/dialog-section/dialog-section';
import Button from 'modules/core/components/button/button';

import './confirm-password-error-dialog.css';

class ConfirmPasswordRecoveryErrorDialog extends Component {
  render() {
    const { onClose, openRecoveryPasswordDialog, ...rest } = this.props;

    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="ConfirmPasswordRecoveryErrorDialog-content">
          <DialogSection title="Ошибка изменения пароля">
            <div className="ConfirmPasswordRecoveryErrorDialog-info">
              Не удалось изменить пароль. Повторите попытку.
            </div>
            <div className="ConfirmPasswordRecoveryErrorDialog-recover">
              <Button
                type="submit"
                variant="primary"
                title={'Восстановить пароль'}
                onClick={openRecoveryPasswordDialog}
                // size={isMobile ? 'small' : 'normal'}
                // disabled={isSubmitting}
              />
            </div>
          </DialogSection>
        </div>
      </Dialog>
    );
  }
}

export default ConfirmPasswordRecoveryErrorDialog;
