import PropTypes from 'prop-types';

const { string, number, shape, arrayOf } = PropTypes;

export default shape({
  id: number,
  title: string,
  coordinates: shape({
    lat: number,
    lng: number,
    latlng: arrayOf(number)
  }),
  shopsAmount: number
});
