import React, { Component } from 'react';
import { IconClose } from '../icons';
import IconContainer from '../icon-container/icon-container';

import './button-remove.css';

class ButtonRemove extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <button type="button" className="ButtonRemove" {...rest}>
        <span className="ButtonRemove-icon">
          <IconContainer inline>
            <IconClose />
          </IconContainer>
        </span>
      </button>
    );
  }
}

export default ButtonRemove;
