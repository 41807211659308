// Pages
import pages from './pages';

// Domain
import reducer, { MODULE_NAME } from './ducks';

// PropTypes
import propTypes from './prop-types';

export default {
  pages,
  MODULE_NAME,
  reducer,
  propTypes
};

// Main module component
export { default as App } from './components/app/app-wrapper';

// Rest components
