import React, { Component } from 'react';
import './call-to-auth.css';
import CheckedList from '../checked-list/checked-list';
import Button from '../button/button';

class CallToAuth extends Component {
  render() {
    const { onAuthOpen } = this.props;
    return (
      <div className="CallToAuth">
        <p className="CallToAuth-checkedListTitle">Преимущества авторизованных пользователей</p>
        <div className="CallToAuth-checkedList">
          <CheckedList
            list={[
              'Получение информации о новинках и акциях',
              'Бонусы постоянным покупателям',
              'Просмотр истории заказов в личном кабинете'
            ]}
            iconColor="yellow"
          />
        </div>
        <div className="CallToAuth-footer">
          <Button onClick={onAuthOpen} title="Войти" />
        </div>
      </div>
    );
  }
}

export default CallToAuth;
