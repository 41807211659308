import { createSelector } from 'reselect';
import { createReducer, handle as on } from 'modules/utils/dux';

import * as TYPES from './types/location';

import * as fromCityList from './city-list';
import * as fromProfile from 'modules/profile/ducks/profile';

const reducer = createReducer(
  on(TYPES.CITY_CHOOSE, (state, { id }) => ({
    ...state,
    selectedCityId: id
  })),

  on(TYPES.CITY_SUGGESTED_SET, (state, { city }) => {
    if (!city) {
      return state;
    }

    return {
      ...state,
      suggestedCityId: city.id
    };
  }),

  on(TYPES.CITY_REHYDRATE, (state, { cityId }) => {
    if (!cityId) {
      return state;
    }

    return {
      ...state,
      selectedCityId: cityId
    };
  }),

  on(TYPES.CITY_SELECTED_RESET, state => ({
    ...state,
    selectedCityId: null
  }))
);

export default reducer({
  suggestedCityId: null,
  selectedCityId: null
});

/* SELECTORS */

export const getRoot = state => state.geography.location;

export const getLocalCityId = createSelector(getRoot, function _getSelectedCityId(location) {
  return location.selectedCityId;
});

export const getChosenId = createSelector(
  fromProfile.getCity,
  getLocalCityId,
  (profileCityId, localCityId) => {
    if (profileCityId) {
      return profileCityId;
    }

    if (localCityId) {
      return localCityId;
    }

    return null;
  }
);

export const getChosenCity = createSelector(
  getChosenId,
  fromCityList.getAll,
  function _getCurrentCity(id, list) {
    if (!id) {
      return null;
    }

    return list[id];
  }
);

export const getSuggestedCityId = createSelector(getRoot, function _getSelectedCityId(location) {
  return location.suggestedCityId;
});

export const getCurrentCityId = createSelector(
  getChosenId,
  getSuggestedCityId,
  function _getSelectedCityId(chosenId, suggestedCityId) {
    return chosenId || suggestedCityId;
  }
);

export const getCurrentCity = createSelector(
  getCurrentCityId,
  fromCityList.getAll,
  function _getCurrentCity(id, list) {
    if (!id) {
      return null;
    }

    return list[id];
  }
);

export const getSuggestedCity = createSelector(
  getSuggestedCityId,
  fromCityList.getAll,
  function _getCurrentCity(id, list) {
    if (!id) {
      return null;
    }

    return list[id];
  }
);

export const getHadChosen = createSelector(getChosenId, function _getHadChosen(chosenId) {
  return Boolean(chosenId);
});

export const getCurrentCityIdWithoutProfile = createSelector(
  getLocalCityId,
  getSuggestedCityId,
  function _getCurrentCityIdWithoutProfile(selectedId, suggestedCityId) {
    return selectedId || suggestedCityId;
  }
);

export const getFiasId = createSelector(
  getCurrentCityIdWithoutProfile,
  fromCityList.getAll,
  function _getFiasId(id, all) {
    if (!id) {
      return null;
    }

    if (all[id].fiasId) {
      return all[id].fiasId;
    }

    return null;
  }
);

export const getFreeDeliveryFrom = createSelector(
  getCurrentCityIdWithoutProfile,
  fromCityList.getAll,
  function _getFreeDeliveryFrom(id, all) {
    const DEFAULT_DELIVERY_PRICE = 3000;

    if (!id) {
      return DEFAULT_DELIVERY_PRICE;
    }

    if (!all[id]) {
      return DEFAULT_DELIVERY_PRICE;
    }

    if (!all[id].freeDeliveryFrom) {
      return DEFAULT_DELIVERY_PRICE;
    }

    return all[id].freeDeliveryFrom;
  }
);


