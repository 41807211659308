import { mixitApi } from 'services/mixit';
import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { replace } from 'connected-react-router';
import { SearchAdapter, SuggestAdapter } from 'services/mixit/adapters';
import { actions as Search } from '../ducks/search';
import * as SEARCH from '../ducks/types/search';
import { getSearchLink } from 'routes/links';

export function* searchWatcher() {
  yield takeEvery(SEARCH.REQUEST, search);
  yield takeEvery(SEARCH.FILTER_REQUEST, filter);
  yield takeLatest(SEARCH.GLOBAL_QUERY_CHANGE, suggest);
}

function* search(action) {
  const { query, category, filters, sort, skip, count } = action.payload;

  const { error, response } = yield call(searchRequest, query, {
    category,
    filters,
    sort,
    skip,
    count
  });

  if (error) {
    yield put(Search.responseError(query, 'Ничего не найдено'));
    return;
  }

  yield put(Search.response(query, response.result, response.entities));
}

function* filter(action) {
  const { query, category, filters, sort, skip, count } = action.payload;

  yield put(replace(getSearchLink(query, category, { filter: filters })));

  const { error, response } = yield call(suggestRequest, query, {
    category,
    filters,
    sort,
    skip,
    count,
    withFilters: false,
    withCategories: false
  });

  if (error) {
    yield put(Search.filterResponseError(query, filters, 'Ошибка получения результатов'));
    return;
  }

  yield put(Search.filterResponse(query, filters, response.result, response.entities));
}

function* suggest(action) {
  const { query } = action.payload;

  if (!query) {
    return;
  }

  yield delay(200);

  const { error, response } = yield call(searchRequest, query, {
    withFilters: false,
    withCategories: false
  });

  if (error) {
    yield put(Search.responseError(query, 'Ошибка получения результатов'));
    return;
  }

  yield put(Search.response(query, response.result, response.entities));
}

function* suggestRequest(query, { category, filters, sort, skip, count }) {
  try {
    const sortParameter = (sort || {}).parameter;
    const sortOrder = (sort || {}).order;

    const parameters = {
      category_slug: category,
      filter: filters,
      sort: sortParameter,
      order: sortOrder,
      skip: skip,
      count: count
    };

    const rawResponse = yield call(mixitApi().suggest, query, parameters);

    const response = SuggestAdapter.normalize(rawResponse.data);

    return { response };
  } catch (error) {
    return { error };
  }
}

function* searchRequest(
  query,
  { category, filters, sort, skip, count, withFilters = true, withCategories = true }
) {
  try {
    const sortParameter = (sort || {}).parameter;
    const sortOrder = (sort || {}).order;

    const parameters = {
      category_slug: category,
      filter: filters,
      sort: sortParameter,
      order: sortOrder,
      skip: skip,
      count: count,
      with_filter_info: withFilters,
      with_categories: withCategories
    };

    const rawResponse = yield call(mixitApi().search, query, parameters);
    const response = SearchAdapter.normalize(rawResponse.data);

    return { response };
  } catch (error) {
    return { error };
  }
}
