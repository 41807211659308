export default {
  serialize(subscribe) {
    const persisted = subscribe.haveSuggestionBeenShowed;

    return JSON.stringify(persisted);
  },

  deserialize(persistedJson) {
    const persisted = JSON.parse(persistedJson);

    return persisted;
  }
};
