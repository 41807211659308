import React, { Component } from 'react';
import './icon-sorting.css';

class IconSorting extends Component {
  render() {
    return (
      <svg className="IconSorting" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.48 427.48">
        <path d="M405.94 290.25L302.1 425.34a5.52 5.52 0 0 1-8.75 0L189.51 290.25a5.46 5.46 0 0 1-.03-6.68 5.55 5.55 0 0 1 6.43-1.8l68.76 27.16V21.01a5.5 5.5 0 0 1 5.51-5.5h55.11a5.5 5.5 0 0 1 5.5 5.5v287.92l68.77-27.16a5.52 5.52 0 0 1 6.38 8.48zM238.01 143.91a5.54 5.54 0 0 0-.03-6.68L134.13 2.15a5.52 5.52 0 0 0-8.75 0L21.54 137.23a5.5 5.5 0 0 0 6.39 8.49l68.76-27.17v287.92a5.5 5.5 0 0 0 5.51 5.51h55.1a5.51 5.51 0 0 0 5.52-5.5V118.54l68.77 27.17c2.3.9 4.92.16 6.42-1.8z" />
      </svg>
    );
  }
}

export default IconSorting;
