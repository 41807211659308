function initItemRequest(slug) {
  return function getItemRequest(parameters = null) {
    return {
      method: 'GET',
      path: `/categories/${slug}`,
      query: parameters
    };
  };
}

function initProductsRequest(slug) {
  return function getItemRequest(parameters = null) {
    return {
      method: 'GET',
      path: `/categories/${slug}/products`,
      query: parameters
    };
  };
}

export default function initCategoryRequest(createRequest) {
  return function _getCategoryRequest(slug) {
    return {
      item: createRequest(initItemRequest(slug)),
      products: createRequest(initProductsRequest(slug))
    };
  };
}
