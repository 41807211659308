import React, { Component, Fragment } from 'react';

import CityChoiceButton from '../city-choice-button/city-choice-button';
import CityChoiceBottomSheetContainer from '../../containers/city-choice-bottom-sheet';
import CitySuggestBottomSheetContainer from '../../containers/city-suggest-bottom-sheet';

class CityChoice extends Component {
  render() {
    const {
      title,
      isChoiceOpened,
      isSuggestOpened,
      onButtonClick,
      onChoiceClose,
      onSuggestClose,
      onAnotherSelect
    } = this.props;
    return (
      <Fragment>
        <CityChoiceButton title={title} onClick={onButtonClick} />

        <CityChoiceBottomSheetContainer
          opened={isChoiceOpened}
          onClose={onChoiceClose}
          scrollable
        />

        <CitySuggestBottomSheetContainer
          opened={isSuggestOpened}
          onClose={onSuggestClose}
          onAnotherSelect={onAnotherSelect}
        />
      </Fragment>
    );
  }
}

export default CityChoice;
