import React, { Component } from 'react';
import cn from 'classnames';

import DotPaginationButton from './dot-pagination-button';

class NavigationButton extends Component {
  render() {
    const { onClick, current, page, count, currentIndex } = this.props;

    const isMedium = this.getIsMedium(count, currentIndex, page);
    const isSmall = this.getIsSmall(count, currentIndex, page);
    const isHidden = this.getIsHidden(count, currentIndex, page);

    return (
      <div
        className={cn('MobileProductCardSlider-paginationItem', {
          // 'MobileProductCardSlider-paginationItem--hidden': isHidden
        })}
      >
        <DotPaginationButton
          onClick={onClick}
          isActive={current}
          isMedium={isMedium}
          isSmall={isSmall}
          isHidden={isHidden}
        />
      </div>
    );
  }

  getIsMedium(count, currentIndex, page) {
    const { buttonAmount } = this.props;
    // const indexSecond = 2;
    // const indexPenultimate = count - 2;
    const RENDERED_AMOUNT = buttonAmount;

    // const shouldApplyExtraRules = count < RENDERED_AMOUNT;
    // const isExtremeItem = page <= indexSecond || page >= indexPenultimate;

    const SIDE_VIEWED_AMOUNT = (RENDERED_AMOUNT - 1) / 2;

    const startCondition = currentIndex < SIDE_VIEWED_AMOUNT + 1;
    const centerCondition =
      currentIndex > SIDE_VIEWED_AMOUNT && currentIndex < count - (SIDE_VIEWED_AMOUNT + 1);
    const endConditions = currentIndex + 1 > count - SIDE_VIEWED_AMOUNT - 1;

    // if (shouldApplyExtraRules || isExtremeItem) {
    //   return false;
    // }

    if (RENDERED_AMOUNT === count) {
      return false;
    }

    if (centerCondition) {
      if (
        page === currentIndex - SIDE_VIEWED_AMOUNT + 1 ||
        page === currentIndex + SIDE_VIEWED_AMOUNT - 1
      )
        return true;
    }

    if (startCondition) {
      if (page === RENDERED_AMOUNT - 2) {
        return true;
      }
    }

    if (endConditions) {
      if (page === count - RENDERED_AMOUNT + 1) {
        return true;
      }
    }

    return false;
  }
  getIsSmall(count, currentIndex, page) {
    const { buttonAmount } = this.props;
    const indexFirst = 0;
    const indexLast = count - 1;
    const RENDERED_AMOUNT = buttonAmount;

    const shouldApplyExtraRules = count < RENDERED_AMOUNT;
    const isExtremeItem = page === indexFirst || page === indexLast;

    const SIDE_VIEWED_AMOUNT = (RENDERED_AMOUNT - 1) / 2;

    const startCondition = currentIndex < SIDE_VIEWED_AMOUNT + 1;
    const centerCondition =
      currentIndex > SIDE_VIEWED_AMOUNT && currentIndex < count - (SIDE_VIEWED_AMOUNT + 1);
    const endConditions = currentIndex + 1 > count - SIDE_VIEWED_AMOUNT - 1;

    if (shouldApplyExtraRules || isExtremeItem) {
      return false;
    }

    if (centerCondition) {
      if (page === currentIndex - SIDE_VIEWED_AMOUNT || page === currentIndex + SIDE_VIEWED_AMOUNT)
        return true;
    }

    if (startCondition) {
      if (page === RENDERED_AMOUNT - 1) {
        return true;
      }
    }

    if (endConditions) {
      if (page === count - RENDERED_AMOUNT) {
        return true;
      }
    }

    return false;
  }

  getIsHidden(count, currentIndex, page) {
    const { buttonAmount } = this.props;

    const RENDERED_AMOUNT = buttonAmount;

    const shouldApplyExtraRules = count < RENDERED_AMOUNT;

    const SIDE_VIEWED_AMOUNT = (RENDERED_AMOUNT - 1) / 2;

    const startCondition = currentIndex < SIDE_VIEWED_AMOUNT + 1;
    const centerCondition =
      currentIndex > SIDE_VIEWED_AMOUNT && currentIndex < count - (SIDE_VIEWED_AMOUNT + 1);
    const endConditions = currentIndex + 1 > count - SIDE_VIEWED_AMOUNT - 1;

    if (shouldApplyExtraRules) {
      return false;
    }

    if (startCondition) {
      if (page > RENDERED_AMOUNT - 1) {
        return true;
      }
    }
    if (centerCondition) {
      if (page < currentIndex - SIDE_VIEWED_AMOUNT || page > currentIndex + SIDE_VIEWED_AMOUNT) {
        return true;
      }
    }
    if (endConditions) {
      if (page < count - RENDERED_AMOUNT) {
        return true;
      }
    }
  }
}

export default NavigationButton;
