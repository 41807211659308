export default {
  ksTRnBG8Dy8: {
    id: 'ksTRnBG8Dy8',
    title: 'Регенерация кожи с помощью антиоксидантов',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-1.jpg',
            double: 'content/blog/video-capture-1.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-1.jpg',
            double: 'content/blog/video-capture-1.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  iZLP4qOwY8I: {
    id: 'iZLP4qOwY8I',
    title: 'Они попробовали это. Тест масок',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-2.jpg',
            double: 'content/blog/video-capture-2.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-2.jpg',
            double: 'content/blog/video-capture-2.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  Ke90Tje7VS0: {
    id: 'Ke90Tje7VS0',
    title: 'Почему живые маски Mixit «Food fetish» необходимо хранить в холодильнике?',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-3.jpg',
            double: 'content/blog/video-capture-3.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-3.jpg',
            double: 'content/blog/video-capture-3.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  I3yoIJe8dyA: {
    id: 'I3yoIJe8dyA',
    title: 'Декоративная косметика MIX IT I Надо не надо?',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/article-preview-1.jpg',
            double: 'content/blog/article-preview-1.jpg'
          },
          fallback: {
            normal: 'content/blog/article-preview-1.jpg',
            double: 'content/blog/article-preview-1.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  'M-Bc0dKBs44': {
    id: 'M-Bc0dKBs44',
    title: 'ОШИБКИ В МАКИЯЖЕ',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-1.jpg',
            double: 'content/blog/video-capture-1.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-1.jpg',
            double: 'content/blog/video-capture-1.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  '0tSvr4GWL_A': {
    id: '0tSvr4GWL_A',
    title: 'ВИТАМИНЫ ОТ БЛОГГЕРА! Halo Beauty и немного ДРАМЫ',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-2.jpg',
            double: 'content/blog/video-capture-2.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-2.jpg',
            double: 'content/blog/video-capture-2.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  '445ftj0RXGQ': {
    id: '445ftj0RXGQ',
    title: 'МАСКА с БЛЕСТКАМИ? WTF | ОБЗОР MIXIT',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/video-capture-3.jpg',
            double: 'content/blog/video-capture-3.jpg'
          },
          fallback: {
            normal: 'content/blog/video-capture-3.jpg',
            double: 'content/blog/video-capture-3.jpg'
          },
          cover: 0
        }
      ]
    }
  },
  vJ7qCbTk6Z0: {
    id: 'vJ7qCbTk6Z0',
    title: 'MIXIT самая раскрученная инстаграмная Косметика',
    description: 'Они попробовали это. Тест масок',
    cover: {
      id: 1,
      type: 'IMAGE',
      title: 'Они попробовали это. Тест масок',
      caption: 'Они попробовали это. Тест масок',
      sources: [
        {
          size: 1120,
          main: {
            normal: 'content/blog/article-preview-1.jpg',
            double: 'content/blog/article-preview-1.jpg'
          },
          fallback: {
            normal: 'content/blog/article-preview-1.jpg',
            double: 'content/blog/article-preview-1.jpg'
          },
          cover: 0
        }
      ]
    }
  }
};
