import React, { Component } from 'react';
import './input-rating.css';

import { IconStar } from 'modules/core/components/icons';

class InputRating extends Component {
  render() {
    return (
      <div className="InputRating">
        <input
          type="radio"
          className="InputRating-radio"
          id="ratingProd5"
          name="ratingProd"
          value="5"
        />
        <label htmlFor="ratingProd5" className="InputRating-label">
          <IconStar setRating />
        </label>
        <input
          type="radio"
          className="InputRating-radio"
          id="ratingProd4"
          name="ratingProd"
          value="4"
        />
        <label htmlFor="ratingProd4" className="InputRating-label">
          <IconStar setRating />
        </label>
        <input
          type="radio"
          className="InputRating-radio"
          id="ratingProd3"
          name="ratingProd"
          value="3"
        />
        <label htmlFor="ratingProd3" className="InputRating-label">
          <IconStar setRating />
        </label>
        <input
          type="radio"
          className="InputRating-radio"
          id="ratingProd2"
          name="ratingProd"
          value="2"
        />
        <label htmlFor="ratingProd2" className="InputRating-label">
          <IconStar setRating />
        </label>
        <input
          type="radio"
          className="InputRating-radio"
          id="ratingProd1"
          name="ratingProd"
          value="1"
        />
        <label htmlFor="ratingProd1" className="InputRating-label">
          <IconStar setRating />
        </label>
      </div>
    );
  }
}

export default InputRating;
