import { createSelector } from 'reselect';

export const getLastOrder = state => state.ordering.lastOrder;

export const getLastOrderAuthorization = createSelector(
  getLastOrder,
  lastOrder => lastOrder.orderAuthorization
);

export const getLastOrderId = createSelector(getLastOrder, lastOrder => {
  if (!lastOrder.order) {
    return null;
  }

  return lastOrder.order.id;
});

export const getGoodList = createSelector(getLastOrder, lastOrder => {
  if (!lastOrder) {
    return null;
  }

  return lastOrder.goodsList;
});

export const getProductList = createSelector(getLastOrder, lastOrder => {
  if (!lastOrder) {
    return null;
  }

  return lastOrder.productList;
});

export const getTotalCost = createSelector(getLastOrder, lastOrder => {
  if (!lastOrder) {
    return null;
  }

  return lastOrder.totalCost;
});
