import { takeEvery, call, put } from 'redux-saga/effects';
import { SOCIAL } from '../ducks/types';
import * as SocialActions from '../ducks/actions/social';
import { actions as DialogActions } from 'modules/core/ducks/dialogs';
import * as FacebookSdk from 'services/facebook';

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export function* facebookAuth() {
  yield takeEvery(SOCIAL.FACEBOOK_AUTH_CALL, callFacebookAuth);
  yield takeEvery(SOCIAL.FACEBOOK_AUTH_LOGOUT, logoutFacebookAuth);
}

function* logoutFacebookAuth() {
  try {
    yield call(FacebookSdk.unAuthorize);
  } catch (err) {
    console.warn(err);
  }
}

function* callFacebookAuth() {
  try {
    yield call(FacebookSdk.load, FACEBOOK_APP_ID);

    const authResponse = yield call(FacebookSdk.authorize);

    const authData = {
      accessToken: authResponse.accessToken,
      expirationTime: authResponse.data_access_expiration_time,
      expiresIn: authResponse.expiresIn,
      reauthorizeIn: authResponse.reauthorize_required_in,
      signedRequest: authResponse.signedRequest,
      userID: authResponse.userID
    };

    yield put(SocialActions.receiveFacebookAuth(authData));
  } catch (err) {
    // Сработает когда закроем диалог авторизации в ФБ
    yield put(DialogActions.openAuthDialog());
    console.warn(err);
  }
}
