import React from 'react';

function CouponFormikField({ ...rest }) {
  return (
    <span className="Coupon-buttonAcceptWrapper">
      <button className="Coupon-buttonAccept" {...rest}>
        Ок
      </button>
    </span>
  );
}

export default CouponFormikField;
