import React from 'react';

import HappyMasterLanding from 'modules/happy-master-landing/happy-master-landing.jsx';
import Page from 'modules/core/components/page/page';
// import { getIndexLink } from 'routes/links';

const HappyMasterPage = () => {
  return (
  <Page>
    <HappyMasterLanding />
  </Page>
  );
};

export default HappyMasterPage;
