import React, { Component } from 'react';

import RewardsSection from '../components/rewards-section/rewards-section';

const data = [
  {
    title: 'Система экологического менеджмента и безопасности',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Система экологического менеджмента и безопасности',
      caption: 'Система экологического менеджмента и безопасности',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/8d0/8d0f529f3fce3c73c6c826a81db98715.jpg',
            double: 'https://mixit.ru/upload/iblock/8d0/8d0f529f3fce3c73c6c826a81db98715.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/8d0/8d0f529f3fce3c73c6c826a81db98715.jpg',
            double: 'https://mixit.ru/upload/iblock/8d0/8d0f529f3fce3c73c6c826a81db98715.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Система экологического менеджмента и безопасности',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Система экологического менеджмента и безопасности',
      caption: 'Система экологического менеджмента и безопасности',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/e19/e19f3ad3e247ef3e7cba67ae23df9575.jpg',
            double: 'https://mixit.ru/upload/iblock/e19/e19f3ad3e247ef3e7cba67ae23df9575.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/e19/e19f3ad3e247ef3e7cba67ae23df9575.jpg',
            double: 'https://mixit.ru/upload/iblock/e19/e19f3ad3e247ef3e7cba67ae23df9575.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Система менеджмента качества',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Система менеджмента качества',
      caption: 'Система менеджмента качества',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/fd7/fd7f1282c1a87c12e1aad517cf6c6496.jpg',
            double: 'https://mixit.ru/upload/iblock/fd7/fd7f1282c1a87c12e1aad517cf6c6496.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/fd7/fd7f1282c1a87c12e1aad517cf6c6496.jpg',
            double: 'https://mixit.ru/upload/iblock/fd7/fd7f1282c1a87c12e1aad517cf6c6496.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Система менеджмента качества',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Система менеджмента качества',
      caption: 'Система менеджмента качества',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/e03/e03b2185e641784285d278488c7b8c9b.jpg',
            double: 'https://mixit.ru/upload/iblock/e03/e03b2185e641784285d278488c7b8c9b.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/e03/e03b2185e641784285d278488c7b8c9b.jpg',
            double: 'https://mixit.ru/upload/iblock/e03/e03b2185e641784285d278488c7b8c9b.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Не тестируется на животных',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Не тестируется на животных',
      caption: 'Не тестируется на животных',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/f03/f0392434d67b094f82fced909c738991.jpg',
            double: 'https://mixit.ru/upload/iblock/f03/f0392434d67b094f82fced909c738991.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/f03/f0392434d67b094f82fced909c738991.jpg',
            double: 'https://mixit.ru/upload/iblock/f03/f0392434d67b094f82fced909c738991.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Не тестируется на животных',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Не тестируется на животных',
      caption: 'Не тестируется на животных',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/c7b/c7b1492c5a9e5519f8810ee738e670e6.jpg',
            double: 'https://mixit.ru/upload/iblock/c7b/c7b1492c5a9e5519f8810ee738e670e6.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/c7b/c7b1492c5a9e5519f8810ee738e670e6.jpg',
            double: 'https://mixit.ru/upload/iblock/c7b/c7b1492c5a9e5519f8810ee738e670e6.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Надлежащая производственная практика',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Надлежащая производственная практика',
      caption: 'Надлежащая производственная практика',
      sources: [
        {
          dimensions: {
            width: 0,
            height: 0
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/73e/73e96132c24036b5e553d98b700ece47.jpg',
            double: 'https://mixit.ru/upload/iblock/73e/73e96132c24036b5e553d98b700ece47.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/73e/73e96132c24036b5e553d98b700ece47.jpg',
            double: 'https://mixit.ru/upload/iblock/73e/73e96132c24036b5e553d98b700ece47.jpg'
          }
        }
      ]
    }
  },
  {
    title: 'Надлежащая производственная практика',
    media: {
      type: 'MEDIA_TYPE/IMAGE',
      title: 'Надлежащая производственная практика',
      caption: 'Надлежащая производственная практика',
      sources: [
        {
          dimensions: {
            width: 3200,
            height: 3200
          },
          fallback: {
            normal: 'https://mixit.ru/upload/iblock/ee9/ee9b4d9073126073f0f7070da7c1f1d1.jpg',
            double: 'https://mixit.ru/upload/iblock/ee9/ee9b4d9073126073f0f7070da7c1f1d1.jpg'
          },
          main: {
            normal: 'https://mixit.ru/upload/iblock/ee9/ee9b4d9073126073f0f7070da7c1f1d1.jpg',
            double: 'https://mixit.ru/upload/iblock/ee9/ee9b4d9073126073f0f7070da7c1f1d1.jpg'
          }
        }
      ]
    }
  }
];

class DeclarationsSectionsContainer extends Component {
  render() {
    return <RewardsSection data={data} />;
  }
}

export default DeclarationsSectionsContainer;
