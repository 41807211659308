function parseJson(response) {
  const authorization = response.headers.get('authorization');

  return response.json().then(data => {
    return {
      ...data,
      authorization
    };
  });
}

export default parseJson;
