import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Media from 'react-media';

import * as fromLocation from 'modules/geography/ducks/location';
import * as fromShopList from '../ducks/shop-list';
import ShopList from '../ducks/actions/shop-list';

import SectionShops from '../components/section-shops/section-shops';
import ShopsGrid from '../components/shops-grid/shops-grid';

const mapStateToProps = createStructuredSelector({
  cities: fromShopList.getCities,
  isCitiesLoading: fromShopList.getIsCitiesLoading,
  isCitiesLoaded: fromShopList.getIsCitiesLoaded,

  fullShopList: fromShopList.getShops,
  currentCity: fromLocation.getCurrentCity,
  currentCityId: fromLocation.getCurrentCityId,
  isLoaded: fromShopList.getIsFullLoaded,
  isShopsLoading: fromShopList.getStatusByCities
});

const mapDispatchToProps = {
  requestCities: ShopList.requestCities,
  requestShopList: ShopList.requestShopList
};

const LOCATIONS = {
  SHOP: 'LOCATION_SHOP',
  CITY: 'LOCATION_CITY',
  COUNTRY: 'LOCATION_COUNTRY'
};

const MAP_ZOOMS = {
  SHOP: 15,
  CITY: 9,
  COUNTRY: 3
};

const DEFAULT_COORDINATES = [61.22704, 102.342258];

const staticMapProps = {
  behaviors: ['scrollZoom']
};

class SectionShopsContainer extends Component {
  state = {
    activeShop: null,
    activeCityId: this.getInitilActiveCityId(),
    isActiveCityWasChanged: false,
    location: LOCATIONS.COUNTRY,
    dynamicMapProps: {
      zoom: MAP_ZOOMS.COUNTRY,
      center: DEFAULT_COORDINATES
    }
  };

  render() {
    const {
      cities,
      currentCity,
      fullShopList,
      isLoaded,
      isCitiesLoading,
      isCitiesLoaded,
      ...rest
    } = this.props;
    const { activeCityId, activeShop, dynamicMapProps, isActiveCityWasChanged } = this.state;

    const shopList = this.getShopList();
    const activeShopId = activeShop ? activeShop.id : null;
    const isCityListAvailable = !isCitiesLoading && isCitiesLoaded;
    const activeCity = cities[activeCityId] || null;

    return (
      <Fragment>
        <Media query="(min-width: 1101px)">
          {isDesktop => {
            const finalStaticMapProps = {
              ...staticMapProps,
              behaviors: [...staticMapProps.behaviors, isDesktop ? 'drag' : 'multiTouch']
            };

            const mapState = {
              ...finalStaticMapProps,
              ...dynamicMapProps
            };

            return (
              <SectionShops>
                <ShopsGrid
                  activeCityId={activeCityId}
                  activeCity={activeCity}
                  activeShopId={activeShopId}
                  shopList={shopList}
                  mapState={mapState}
                  isLoaded={isLoaded}
                  isCityListAvailable={isCityListAvailable}
                  isActiveCityWasChanged={isActiveCityWasChanged}
                  onCitySelect={this.handleCitySelect}
                  onShopSelect={this.handleShopSelect}
                  onApiAvaliable={this.handleYandexApi}
                  {...rest}
                />
              </SectionShops>
            );
          }}
        </Media>
      </Fragment>
    );
  }

  componentDidMount() {
    const { isCitiesLoaded, isCitiesLoading, requestCities, requestShopList } = this.props;

    if (!isCitiesLoaded && !isCitiesLoading) {
      requestCities();
    }

    requestShopList();
  }

  componentDidUpdate() {
    const { currentCityId } = this.props;
    const { activeCityId, isActiveCityWasChanged } = this.state;

    // Will be removed
    const additionCondition = currentCityId && currentCityId.toString().length > 5;

    if (currentCityId && activeCityId === 'all' && !additionCondition && !isActiveCityWasChanged) {
      this.setState({ activeCityId: currentCityId });
    }
  }

  componentWillMount() {
    const { requestShopList } = this.props;

    if (__SERVER__) {
      requestShopList();
    }
  }

  getInitilActiveCityId() {
    const { currentCityId } = this.props;

    // Will be removed
    const additionCondition = currentCityId && currentCityId.toString().length > 5;

    if (!currentCityId || additionCondition) {
      return 'all';
    }

    const numericalId = parseInt(currentCityId);

    return numericalId;
  }

  getShopList() {
    const { fullShopList } = this.props;
    const { activeCityId } = this.state;

    if (activeCityId === 'all') {
      return fullShopList;
    }

    const filteredShopList = fullShopList.filter(shop => shop.geography.cityId === activeCityId);

    // if (filteredShopList.length === 0) {
    //   return fullShopList;
    // }

    return filteredShopList;
  }

  setDynamicMapProps(location, dynamicMapProps) {
    this.setState({ location, dynamicMapProps });
  }

  setActiveShop(shop) {
    this.setState({ activeShop: shop });
  }

  resetActiveShop() {
    this.setState({
      activeShop: null
    });
  }

  selectCity(id) {
    const { cities } = this.props;
    const { activeCityId, isActiveCityWasChanged } = this.state;

    if (id === activeCityId) {
      return;
    }

    this.resetActiveShop();

    if (!isActiveCityWasChanged) {
      this.setState({ isActiveCityWasChanged: true });
    }

    if (id === 'all') {
      this.resetCity();
      return;
    }

    const cityTitle = cities[id].title;
    this.geocodeCity(cityTitle);

    this.setState({ activeCityId: id });
  }

  resetCity() {
    const dynamicMapProps = {
      zoom: MAP_ZOOMS.COUNTRY,
      center: DEFAULT_COORDINATES
    };

    this.setState({ activeCityId: 'all' });
    this.setDynamicMapProps(LOCATIONS.COUNTRY, dynamicMapProps);
  }

  geocodeCity(cityTitle) {
    if (!this.ymaps) {
      return null;
    }

    this.ymaps.geocode(cityTitle).then(this.useLocationGeocoding);
  }

  useLocationGeocoding = result => {
    try {
      const city = result.geoObjects.get(0);

      const dynamicMapProps = {
        zoom: MAP_ZOOMS.CITY,
        center: city.geometry.getCoordinates()
      };

      this.setDynamicMapProps(LOCATIONS.CITY, dynamicMapProps);
    } catch (error) {
      console.log(error);
    }
  };

  handleShopSelect = shop => {
    const dynamicMapProps = {
      center: shop.geography.coordinates.latlng,
      zoom: MAP_ZOOMS.SHOP
    };

    this.setDynamicMapProps(LOCATIONS.SHOP, dynamicMapProps);
    this.setActiveShop(shop);
  };

  handleCitySelect = id => {
    this.selectCity(id);
  };

  handleYandexApi = ymaps => {
    const { cities } = this.props;
    const { activeCityId } = this.state;

    if (!!this.ymaps) {
      return;
    }

    this.ymaps = ymaps;
    const activeCity = cities[activeCityId];

    if (!activeCity) {
      return;
    }

    this.geocodeCity(activeCity.title);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionShopsContainer);
