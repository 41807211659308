import React from 'react';
import cn from 'classnames';

import './landing-button.css';

export default function LandingButton({
  title,
  icon,
  component = 'button',
  colors,
  small,
  dark,
  fullWidth,
  ...rest
}) {
  const Icon = icon;
  const buttonBGStyle = { backgroundColor: colors[0] || '#a04ab0' };
  const ButtonComponent = component;

  return (
    <ButtonComponent
      className={cn('LandingButton', {
        'LandingButton--small': small,
        'LandingButton--dark': dark,
        'LandingButton--fullWidth': fullWidth
      })}
      {...rest}
    >
      <div className="LandingButton-BG" style={buttonBGStyle} />
      <div className="LandingButton-content">
        {Boolean(Icon) && (
          <div className="LandingButton-icon">
            <Icon />
          </div>
        )}
        <div className="LandingButton-title">{title}</div>
      </div>
    </ButtonComponent>
  );
}
