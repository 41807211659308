import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Page from 'modules/core/components/page/page';
import { getIndexLink } from 'routes/links';
import FeaturedArticlesSectionContainer from '../containers/featured-articles-section';
import LatestArticlesSectionContainer from '../containers/latest-articles-section';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

class BlogPage extends Component {
  static path = '/magazine';

  static exact = true;

  render() {
    return (
      <Page
        title="Журнал"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Журнал'
          }
        ]}
      >
        <Helmet>
          <title>Beauty-блог о красоте и современной косметики от MIXIT</title>

          <meta
            name="description"
            content="Как дольше выглядеть молодой, как сохранить свое здоровье, какую косметику использовать и ответы на многие другие вопросы в нашем новом Beauty-блоге."
          />
        </Helmet>

        <FeaturedArticlesSectionContainer />
        <LatestArticlesSectionContainer />
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  componentDidMount() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default BlogPage;
