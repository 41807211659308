import React, { Component } from 'react';

import IconButton from 'modules/social/components/icon-button/icon-button';

import './additional-info-item.css';

class AdditionalInfoItem extends Component {
  render() {
    const { title, list } = this.props;
    return (
      <div className="AdditionalInfoItem">
        <h4 className="AdditionalInfoItem-title">{title}</h4>
        <div className="AdditionalInfoItem-list">{list.map(this.renderButton)}</div>
      </div>
    );
  }

  renderButton = (item, index) => {
    return (
      <div key={index} className="AdditionalInfoItem-item">
        <IconButton component="a" href={item.href} icon={item.icon} size="small" />
      </div>
    );
  };
}

export default AdditionalInfoItem;
