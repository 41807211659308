function initFacebookRequest() {
  return function _facebookRequest(access_token) {
    return {
      method: 'POST',
      path: `/social/facebook`,
      data: {
        access_token
      }
    };
  };
}

function initVkRequest() {
  return function _vkRequest(user_id, access_token) {
    return {
      method: 'POST',
      path: `/social/vk`,
      data: {
        user_id,
        access_token
      }
    };
  };
}

export default function initSocialRequest(createRequest) {
  return function _getSocialRequest() {
    return {
      facebook: createRequest(initFacebookRequest()),
      vk: createRequest(initVkRequest())
    };
  };
}
