import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import generateResizedImageLink from 'modules/core/components/media/helpers/generate-resized-image-link';
import { getCategoryLink } from 'routes/links';

import './subcategory-card.css';

class SubcategorySliderCard extends Component {
  render() {
    const { category, parent } = this.props;

    const banner = category.banner ? category.banner.sources[0].main.normal : null;
    const backgroundImage = banner
      ? generateResizedImageLink(banner, { width: 420, height: 152 })
      : undefined;

    return (
      <Link
        className={cn('SubcategorySliderCard', {
          'SubcategorySliderCard--withBanner': banner
        })}
        to={getCategoryLink(parent, category)}
        ref={this.setCardElementRef}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="SubcategorySliderCard-titleHolder">
          <h4 className="SubcategorySliderCard-title">{category.title}</h4>
        </div>
      </Link>
    );
  }
}

export default SubcategorySliderCard;
