import React, { Component } from 'react';
import cn from 'classnames';

import './dash-list.css';

class DashList extends Component {
  render() {
    const { list, withListMark } = this.props;

    return (
      <ul className={cn('DashList', { 'DashList--withListMark': withListMark })}>
        {list.map((item, index) => (
          <li key={index} className="DashList-item">
            {item.title} {item.importantTitle && item.importantTitle} —{' '}
            {item.value || item.importantInfo}
          </li>
        ))}
      </ul>
    );
  }
}

export default DashList;
