import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types';
import { createSelector } from '../../utils/debug-reselect';
import { arrayToObject } from 'modules/utils';

export { default as actions } from './actions';
/* REDUCERS */

const getSlugFromRouteParams = (_, props) => {
  if (!props || !props.match || !props.match.params) {
    return null;
  }

  return props.match.params.slug;
};

const getSlugFromProps = (_, { slug }) => slug;

function getBanners(list) {
  const landingsWithBanners = list.filter(item => Boolean(item.related_banner));
  const bannersList = landingsWithBanners.map(landingData => {
    const slug = landingData.slug;
    const bannerData = {
      ...landingData.related_banner,
      slug,
      landingPath: `/landing/${slug}`,
      imagesPath: `https://mixit.ru/upload/landings/images/${slug}`
      // imagesPath: `/content/landing-banner/${slug}`
    };
    return bannerData;
  });
  return bannersList;
}

const reducer = createReducer(
  handle(TYPES.ADD, (state, { data }) => {
    return {
      ...state,
      table: {
        ...state.table,
        [data.slug]: data
      }
    };
  }),
  handle(TYPES.ADD_LIST, (state, { list }) => {
    return {
      ...state,
      table: {
        ...state.table,
        ...arrayToObject(list, 'slug')
      },
      banners: getBanners(list)
    };
  }),
  handle(TYPES.SET_LOADED, state => {
    return {
      ...state,
      isLoaded: true
    };
  })
);

export default reducer({
  table: {},
  banners: [],
  isLoaded: false
});

/* SELECTORS */

const getRoot = state => state.landings;

export const getTable = createSelector(getRoot, root => root.table);
export const getIsLandingDataLoaded = createSelector(getRoot, root => root.isLoaded);
export const getBannersList = createSelector(getRoot, root => root.banners);

export const getLandingBySlugFromRouteParams = createSelector(
  getTable,
  getSlugFromRouteParams,
  function _getLanding(table, slug) {
    return table[slug];
  }
);
export const getLandingBySlugFromProps = createSelector(
  getTable,
  getSlugFromProps,
  function _getLanding(table, slug) {
    return table[slug];
  }
);
