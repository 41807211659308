import React, { Component } from 'react';
import YouTube from 'react-youtube';

import VideoTrigger from '../video-trigger/video-trigger';

import './youtube-video.css';

class YoutubeVideo extends Component {
  static defaultProps = {
    autoplay: false
  };

  state = {
    isPlaying: false
  };

  render() {
    const { id, autoplay } = this.props;
    const { isPlaying } = this.state;

    const opts = {
      playerVars: {
        autoplay: autoplay || isPlaying
      }
    };

    const showTrigger = !isPlaying && !autoplay;

    return (
      <div className="YoutubeVideo">
        {showTrigger && (
          <div className="YoutubeVideo-trigger">
            <VideoTrigger identifier={id} onClick={this.handleClick} expandedClickArea keepRatio />
          </div>
        )}

        <YouTube
          className="YoutubeVideo-player"
          key={autoplay || isPlaying}
          videoId={id}
          opts={opts}
        />
      </div>
    );
  }

  play() {
    this.setState({
      isPlaying: true
    });
  }

  handleClick = () => {
    this.play();
  };
}

export default YoutubeVideo;
