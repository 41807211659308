import React, { Component } from 'react';

import './icon-slider-arrow-left.css';

class SliderArrowLeft extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="SliderArrowLeft">
        <path d="M2.5 16.9l10.8 9.5c.5.5 1.4.4 1.8-.1s.4-1.4-.1-1.9l-8.2-7.2h21.7c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3H6.8L15 7.4c.5-.5.5-1.3.1-1.8-.5-.5-1.2-.5-1.8-.1L2.5 14.9c-.5.5-.6 1.3-.1 1.8 0 .1 0 .1.1.2z" />
      </svg>
    );
  }
}

export default SliderArrowLeft;
