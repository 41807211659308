import setList from './seeds/set-list';

import { createReducer /*, createAction */ } from 'modules/utils/dux';
import { createSelector } from 'reselect';

/* TYPES */

/* REDUCERS */

const reducer = createReducer({});

export default reducer(setList);

/* SELECTORS */

export const getALLProducts = state => state.products.products;

export const getSetIdList = () => setList;

export const getList = createSelector(
  getALLProducts,
  getSetIdList,
  (allProducts, setIdList) => setIdList.map(setIdList => allProducts[setIdList])
);

/* ACTIONS */
