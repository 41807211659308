import { createReducer, createAction, handle } from 'modules/utils/dux';

import { createSelector } from 'reselect';

import * as PICK_POINT_QUERY from './types/pick-point-query';
import * as fromPickPointList from './pick-point-list';

import isStringMatched from './helpers/is-matched-string';

export const actions = {
  change(query) {
    return createAction(PICK_POINT_QUERY.CHANGE, { query });
  },

  reset() {
    return createAction(PICK_POINT_QUERY.RESET);
  }
};

const reducer = createReducer(
  handle(PICK_POINT_QUERY.CHANGE, (state, { query }) => {
    return {
      ...state,
      query
    };
  }),

  handle(PICK_POINT_QUERY.RESET, state => {
    return {
      ...state,
      query: ''
    };
  })
);

export default reducer({
  query: ''
});

const getRoot = state => state.ordering.pickPointQuery;

export const getIdListFromProps = (_, { idList }) => idList;

export const getQuery = createSelector(
  getRoot,
  root => root.query
);

export const getFilteredIdList = createSelector(
  getIdListFromProps,
  getQuery,
  fromPickPointList.getAll,

  (idListFromProps, query, allPickPoints) => {
    if (idListFromProps) {
      const filteredIdList = idListFromProps.filter(id => {
        const title = allPickPoints[id].title;
        const address = allPickPoints[id].geography.address.raw;
        return isStringMatched(title, query) || isStringMatched(address, query);
      });

      return filteredIdList;
    }

    return idListFromProps;
  }
);
