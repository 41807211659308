import React, { Component } from 'react';

import Dialog from '../dialog/dialog';
import DialogSection from '../dialog/dialog-section/dialog-section';

import './confirm-password-success-dialog.css';

class ConfirmPasswordRecoverySuccessDialog extends Component {
  render() {
    const { onClose, ...rest } = this.props;

    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="ConfirmPasswordRecoverySuccessDialog-content">
          <DialogSection title="Изменение пароля">
            <div className="ConfirmPasswordRecoverySuccessDialog-info">
              Ваш пароль был успешно изменен
            </div>
          </DialogSection>
        </div>
      </Dialog>
    );
  }
}

export default ConfirmPasswordRecoverySuccessDialog;
