import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createReducer, handle, createAction } from 'modules/utils/dux';

export const TYPES = {
  REQUEST: 'core/faq/REQUEST',
  RESPONSE: 'core/faq/RESPONSE',
  CANCEL_REQUEST: 'core/faq/CANCEL_REQUEST',
  SEND_QUESTION: 'core/faq/SEND_QUESTION',
};

export const Actions = {
  request() {
    return createAction(TYPES.REQUEST);
  },

  response(list) {
    return createAction(TYPES.RESPONSE, { list });
  },

  cancelRequest() {
    return createAction(TYPES.CANCEL_REQUEST);
  },

  sendQuestion(data) {
    return createAction(TYPES.SEND_QUESTION, { data });
  },
};

const list = createReducer(
  handle(TYPES.RESPONSE, (_, { list }) => {
    return list;
  })
);

export default combineReducers({
  list: list(null)
});

export const getRoot = state => state.core.faq;

export const getList = createSelector(getRoot, root => root.list)

