import React from 'react';
import './svg-arrow.css';

export default function SVGArrow({ colors }) {
  const arrowFill = colors[0] || '#a04ab0';

  return (
    <svg className="SVGArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.5 59">
      <path
        d="M3.6 44.2c-.2-.3-.3-.7-.2-1-.7-1 .1-2.4 1.5-2.3.5 0 1 .1 1.5.2-.5-.8.4-2.2 1.6-2.1 1.4.1 2.7.3 4.1.5.2-.6.8-1.2 1.6-1.1h1c0-.2-.1-.6 0-.9 0 0 .1-.1 0-.2-.4-.2-.9-.4-1.3-.6-.7-.3-.8-1-.3-1.6-.5-.3-1-.5-1.5-.8-1.4-.7.6-2.4 1.6-2.4.9 0 1.8.1 2.8.2-.5-.4-1-.7-1.6-1-1.3-.8.5-2.4 1.6-2.4h.1c-1-.9-1.8-1.9-2.6-2.9-.8-1.1.6-2.5 1.8-2.3.6.1 1.1.2 1.6.4-.9-.7-1.8-1.4-2.7-2.2-.9-.7.2-2.3 1.2-2.3.2 0 .5 0 .8-.1-.8-.8-1.4-1.6-2.2-2.4-.6-.8.4-2 1.3-2.2l-1.1-1.1c-.5-.6.1-1.6.8-2-.1-.1-.1-.2-.2-.3-.5-1.2 1.2-2.1 2.2-1.8.3.1.8.2 1.1.3-.9-.7.3-2.1 1.2-2.2.6-.1 1.1-.2 1.6-.2-.1-.2-.3-.3-.4-.4-1.1-1.1 1.4-2.9 2.4-2 1 1 2 2 3.1 3 1 1-.7 2.6-1.8 2.3-.3 0-.6-.2-.9-.2.6.5 1.2 1.1 1.9 1.6 1.2 1-1 2.4-2 2.2-1.3-.3-2.6-.6-3.8-1 .1.2.2.3.3.4 1.4.9 2.5 1.9 3.6 3.1.5.5.3 1.1-.3 1.5.9.8 1.9 1.5 2.8 2.3 1.3 1-1.3 3-2.4 2-.5-.4-1-.7-1.5-1.1.1.2.3.3.5.5.3.3.6.5.8.9.6.7.1 1.5-.7 1.9 1.1.8 2.1 1.7 3.1 2.4 1.3 1-.8 2.9-2 2.2-1.5-.8-3.1-1.5-4.7-2.1 1.1 1.1 2.3 2 3.6 3 1.1.5 2.1 1.2 3 2 1.2 1.1-.9 2.9-2.1 2.2-.1-.1-.2-.1-.3-.2-.2-.1-.5-.3-.7-.4.5.6 1.1 1.2 1.6 1.8.2.3.3.5.2.8 1.6.4 3.1.7 4.7 1.1 1.9.5.5 3-1.4 2.6-.9-.2-1.9-.4-2.8-.6 0 .2-.2.4-.2.5 1 .3 1.9.7 2.9 1.2.6.3.8 1.1.3 1.8 0 1.3-1 1.3-1.7 1-.3-.1-.7-.2-1-.4 1.3.9-.1 2.9-1.8 2.4-1.5-.5-3-1.1-4.4-1.7-1.4-.5-2.8-.8-4.2-1.2 3.8 1.1 7.4 2.6 10.8 4.5 1.5.9.1 2.9-1.5 2.5-.6-.2-1.1-.3-1.8-.5.1.1.2.1.3.2 1.5 1.1.1 3.1-1.7 2.5-1.2-.4-2.3-.8-3.3-1.2.4.4.8 1 1.1 1.7.6 1.2-.7 2.8-2 2.2 0 .2 0 .6-.1.8-.4.8-1.5 1.2-2.1.7-2.6-2.1-5-4.4-7-7.2-.2-.3-.3-.7-.2-1.1-1.5-.9-2.5-2.3-3.5-3.7z"
        fill={arrowFill}
      />
      <path
        d="M.8 41.7c3-1.1 6.6-2 9.4-3.6 1.2 0 1.9-.6 2.1-1.8-.1-1-.2-2.1-.3-3.1-.2-2-.4-3.9-.8-5.8-1-6.9-2.7-13.6-4.8-20.2-.1-.8 1-1.7 1.7-1.3 1.3.8 2.7 1.7 4 2.5 2.4 1.5 2.9 2 5.7-.1C20.9 6 23.9 3 26.9.4c.6-.5 2-.5 1.8.6-1.8 11.6-3.6 23.2-5.4 34.7 3.8-.8 7.5-1.2 11.2-1.3.9 0 1.3.9.7 1.7-6.1 7.6-12.1 15-18.2 22.5-.4.5-1.1.4-1.5.2C10 54.1 4.9 49 .1 43.6c-.3-.7-.1-1.7.7-1.9zm15.7 14.7c5.3-6.6 10.7-13.3 16-19.9-3.6.1-7.2.4-10.8.8-.5 0-.7-.7-.4-1.1-.1-.1 0-.2 0-.2 1.6-10.8 3.4-21.7 5-32.5-1.7 1.4-3.3 2.8-4.9 4.2-1.4 1.2-3.8 4.1-5.5 4.5-2 .4-4.3-1.1-6.3-2.5 2.7 9 4.3 18.4 5 28.2 0 .5-.4.9-.8 1.1-3.8 1.2-7.4 2.6-11 4 4.4 4.8 8.8 9.3 13.7 13.4z"
        fill="#0f0f0f"
      />
    </svg>
  );
}
