import React, { Component, Fragment } from 'react';
import ReactMedia from 'react-media';

import Button from 'modules/core/components/button/button';
import Media from 'modules/core/components/media/media';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import Content from './content';

import productShape from '../../prop-types/product-shape';

import './gift-product.css';

export default class GiftProduct extends Component {
  static propTypes = {
    product: productShape.isRequired
  };

  render() {
    const { product, isGift } = this.props;

    return (
      <div className="GiftProduct">
        <ReactMedia query="(min-width: 1101px)">
          {isDesktop => (
            <Fragment>
              <Content
                isDesktop={isDesktop}
                className="GiftProduct-content"
                onClick={this.handleButtonAddClick}
              >
                <div className="GiftProduct-visualGroup">
                  <div className="GiftProduct-image">
                    <Media
                      media={product.announcementMedia}
                      resizeWidth={320}
                      stretch="horizontal"
                      nocaption
                    />
                  </div>
                </div>

                <div className="GiftProduct-contentGroup">
                  <h3 className="GiftProduct-title">{product.title}</h3>

                  <div className="GiftProduct-description">{product.description}</div>

                  {!isGift && (
                    <div className="GiftProduct-priceHolder">
                      {product.hasDiscount && (
                        <span className="GiftProduct-previousPrice">
                          <Price number={product.price.default} variant="previous" />
                        </span>
                      )}

                      <span className="GiftProduct-currentPrice">
                        <Price number={product.price.current} />
                      </span>
                    </div>
                  )}
                </div>
              </Content>

              {isDesktop && (
                <div className="GiftProduct-action">
                  <Button
                    title="Выбрать"
                    size="small"
                    onClick={this.handleButtonAddClick}
                    expanded
                  />
                </div>
              )}
            </Fragment>
          )}
        </ReactMedia>
      </div>
    );
  }

  handleButtonAddClick = () => {
    const { product, onSelect } = this.props;

    onSelect(product);
  };
}
