import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './status-indicator.css';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import IconDot from 'modules/core/components/icons/icon-dot/icon-dot';

export default class StatusIndicator extends Component {
  static propTypes = {
    title: PropTypes.string,
    status: PropTypes.oneOf(['success', 'canceled', 'waiting', 'empty'])
  };

  static defaultProps = {
    status: 'success'
  };

  render() {
    const { title, status } = this.props;
    return (
      <div
        className={cn('StatusIndicator', {
          [`StatusIndicator--${status}`]: status
        })}
      >
        <div className="StatusIndicator-icon">
          <IconContainer size="xsmall">
            <IconDot />
          </IconContainer>
        </div>
        <div className="StatusIndicator-title">{title}</div>
      </div>
    );
  }
}
