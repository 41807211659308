import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/account';
import * as SOCIAL from './types/social';
import * as PROFILE from 'modules/profile/types/profile';
import { createSelector } from '../../utils/debug-reselect';

import * as fromDialogs from 'modules/core/ducks/types/dialogs';

const STATUS = {
  PENDING: 'STATUS/PENDING',
  ACCEPTED: 'STATUS/ACCEPTED',
  DECLINED: 'STATUS/DECLINED'
};

const reducer = createReducer(
  handle(TYPES.REHYDRATE, (state, { account }) => {
    const status = account && account.authorization ? STATUS.PENDING : STATUS.DECLINED;

    return {
      ...state,
      ...account,
      status
    };
  }),

  handle(TYPES.READY, state => {
    return {
      ...state,
      status: STATUS.DECLINED
    };
  }),

  handle(SOCIAL.FACEBOOK_RECEIVE_AUTH, state => {
    return {
      ...state,
      status: STATUS.PENDING
    };
  }),

  handle(SOCIAL.VK_RECEIVE_AUTH, state => {
    return {
      ...state,
      status: STATUS.PENDING
    };
  }),

  handle(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.account) {
      return state;
    }

    return {
      ...state,
      ...entities.account,
      status: STATUS.ACCEPTED
    };
  }),

  handle(TYPES.AUTHORIZATION_SUCCESS, (state, { authorization, account }) => ({
    ...state,
    ...account,
    authorization,
    status: STATUS.ACCEPTED
  })),

  handle(TYPES.AUTHORIZATION_RENEW_RESPONSE, (state, { error, authorization }) => {
    if (error) {
      return {
        ...state,
        authorization: null,
        status: STATUS.DECLINED
      };
    }

    return {
      ...state,
      authorization,
      status: STATUS.ACCEPTED
    };
  }),

  handle(TYPES.REGISTRATION_COMPLETE, (state, { authorization }) => ({
    ...state,
    authorization,
    status: STATUS.ACCEPTED
  })),

  handle(TYPES.SIGN_OUT, state => ({
    ...state,
    id: null,
    email: null,
    phone: null,
    authorization: null,
    last: state,
    status: STATUS.DECLINED
  })),

  handle(TYPES.AUTHORIZATION_ERROR, (state, { message }) => {
    return {
      ...state,
      _authorizationError: message,
      status: STATUS.DECLINED
    };
  }),

  handle(TYPES.AUTHORIZATION_RESET, state => ({
    ...state,
    id: null,
    email: null,
    phone: null,
    authorization: null,
    last: state,
    status: STATUS.DECLINED
  })),

  handle(fromDialogs.AUTH_CLOSE, state => ({
    ...state,
    _authorizationError: null
  })),

  handle(fromDialogs.REGISTRATION_OPEN, state => ({
    ...state,
    _authorizationError: null
  }))
);

export default reducer({
  email: null,
  phone: null,
  authorization: null,
  status: STATUS.PENDING,
  _authorizationError: false
});

/* SELECTORS */

const getRoot = state => state.core.account;

export const getCurrent = getRoot;

export const getEmail = createSelector(getRoot, root => root.email);

export const getStatusIsAccepted = createSelector(getRoot, root => root.status === STATUS.ACCEPTED);

export const getStatusIsPending = createSelector(getRoot, root => root.status === STATUS.PENDING);

export const getStatusIsDeclined = createSelector(getRoot, root => root.status === STATUS.DECLINED);

export const getStatusIsReady = createSelector(getRoot, root => root.status !== STATUS.PENDING);

export const getAuthorization = createSelector(getRoot, root => root.authorization);

export const getAuthorizationError = createSelector(getRoot, root => root._authorizationError);

export const getIsAuthorized = createSelector(getAuthorization, function _getIsAuthorized(
  authorization
) {
  if (!authorization) {
    return false;
  }
  return true;
});
