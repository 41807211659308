import React, { Component, Fragment } from 'react';
import Container from 'modules/core/components/container/container';
import Sorting from '../sorting/sorting';
import AppliedFilters from '../applied-filters/applied-filters';
import CategoryProductListContainer from '../../containers/category-product-list';
import Media from 'react-media';
import CategoryHeader from '../category-header/category-header';
import CategoryFilterSummary from '../category-filter-summary/category-filter-summary';
import CategoryFilterPanel from '../category-filter-panel/category-filter-panel';

import SubcategorySlider from 'modules/core/components/subcategory-slider/subcategory-slider';

import './category-products.css';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

class CategoryProducts extends Component {
  render() {
    const {
      productsCategorySlug,
      category,
      nested,
      nestedList,
      currentNested,
      filters,

      appliedFilters,
      quickFilters,
      isFiltersApplied,

      sortOptions,

      listedAmount,
      totalAmount,

      onFilterChange,
      onFilterRemove,
      onFiltersReset,

      onSortingChange
    } = this.props;

    if (!category) {
      return null;
    }

    const showSubcategorySlider = !nested && nestedList && nestedList.length > 4;

    /* TODO: change width value to consistent with project */
    return (
      <Media query="(max-width: 1240px)">
        {isMobile => (
          <div className="CategoryProducts">
            {!isMobile && (
              <HideErrorBoundary>
                <Container>
                  <div className="CategoryProducts-panel">
                    <CategoryFilterPanel
                      currentCategory={category}
                      currentNested={currentNested}
                      nestedList={nestedList}
                      filters={filters}
                      appliedFilters={appliedFilters}
                      onFilterChange={onFilterChange}
                    />
                  </div>
                </Container>
              </HideErrorBoundary>
            )}

            {isMobile && (
              <Fragment>
                {showSubcategorySlider && (
                  <div className="CategoryProducts-subcategorySlider">
                    <SubcategorySlider category={category} nested={nestedList} />
                  </div>
                )}
                <HideErrorBoundary>
                  <div className="CategoryProducts-header">
                    <CategoryHeader
                      filters={filters}
                      quick={quickFilters}
                      applied={appliedFilters}
                      sortTypeList={sortOptions}
                      onFilterChange={onFilterChange}
                      onQuickFilterApply={() => console.warn('Not implemented yet.')}
                      onFiltersReset={onFiltersReset}
                      onSortingChange={onSortingChange}
                    />
                  </div>
                </HideErrorBoundary>
              </Fragment>
            )}
            {appliedFilters.length > 0 && (
              <div className="CategoryProducts-appliedFilters">
                {isMobile ? (
                  <AppliedFilters
                    appliedFilters={appliedFilters}
                    onFiltersReset={onFiltersReset}
                    onFilterRemove={onFilterRemove}
                  />
                ) : (
                  <Container>
                    <AppliedFilters
                      appliedFilters={appliedFilters}
                      onFiltersReset={onFiltersReset}
                      onFilterRemove={onFilterRemove}
                    />
                  </Container>
                )}
              </div>
            )}
            {!isMobile && (
              <Container>
                <div className="CategoryProducts-sorting">
                  <Sorting
                    sortTypeList={sortOptions}
                    summary={
                      <CategoryFilterSummary
                        filteredAmount={listedAmount}
                        totalAmount={totalAmount}
                      />
                    }
                    onChange={onSortingChange}
                  />
                </div>
              </Container>
            )}

            {isMobile && (
              <div className="CategoryProducts-filterSummary">
                <CategoryFilterSummary
                  withFilters={isFiltersApplied}
                  filteredAmount={listedAmount}
                  totalAmount={totalAmount}
                />
              </div>
            )}

            <Container>
              <div className="CategoryProducts-productList">
                <CategoryProductListContainer
                  slug={productsCategorySlug}
                  appliedFilters={appliedFilters}
                  parent={currentNested.slug ? category.slug : null}
                />
              </div>
            </Container>
          </div>
        )}
      </Media>
    );
  }
}

export default CategoryProducts;
