import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class OrderTableCell extends Component {
  static propTypes = {
    variant: PropTypes.oneOf(['number', 'date', 'price', 'status'])
  };

  render() {
    const { children, variant, ...rest } = this.props;
    return (
      <div
        className={cn('OrderTable-cell', {
          [`OrderTable-cell--${variant}`]: variant
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

export default OrderTableCell;
