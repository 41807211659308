import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmailFeedbackCotnainer from '../containers/email-feedback';

import Page from '../components/page/page';
import Container from '../components/container/container';

class EmailFeedbackPage extends Component {
  static path = '/email-feedback/:variant';
  static exact = true;

  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    const { variant } = this.props.match.params;

    return (
      <Page title="Спасибо за оценку!">
        <Container>
          <EmailFeedbackCotnainer variant={variant} />
        </Container>
      </Page>
    );
  }

  componentDidMount() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default EmailFeedbackPage;
