import React, { Component } from 'react';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getPathName } from 'modules/core/ducks/router/selectors';

import MobileCatalogNavigation from '../components/mobile-catalog-navigation/mobile-catalog-navigation';

import * as fromNavigations from 'modules/core/ducks/navigations';
import Loader from 'modules/core/components/loader/loader';

const CATEGORY_SLUG = 'category';
const SPECIAL_OFFERS_SLUG = 'special-offers';

const mapStateToProps = createStructuredSelector({
  pathname: getPathName,
  navigation: fromNavigations.getMainCatalogNavigation,
  isNavigationLoaded: fromNavigations.getNavigationDataLoaded
});

const mapDispatchToProps = {};

class MobileCatalogNavigationContainer extends Component {
  state = {
    currentCategory: null
  };

  render() {
    const { navigation, isNavigationLoaded } = this.props;
    const { currentCategory } = this.state;

    if (!isNavigationLoaded) {
      return (
        <div style={{ position: 'relative', paddingTop: '64px' }}>
          <Loader size="small" secondary />
        </div>
      );
    }

    if (!navigation) {
      return null;
    }

    return (
      <MobileCatalogNavigation
        // key={currentCategory}
        currentCategory={currentCategory}
        onSetCurrentCategorySlug={this.handleSetCurrentCategorySlug}
        navigation={navigation}
      />
    );
  }

  componentDidMount() {
    this.setCurrentCategory();
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props;
    if (prevProps.pathname !== pathname) {
      this.setCurrentCategory();
    }
  }

  setCurrentCategory() {
    const { pathname } = this.props;
    const isCategoryPage = this.getIsCategoryPage(pathname);
    const isSpecialOffersPage = this.getIsSpecialOffersPage(pathname);
    if (isCategoryPage) {
      const slug = this.getCategorySlug(pathname);
      this.setCurrentCategoryState(slug);
      return;
    }

    if (isSpecialOffersPage) {
      this.setCurrentCategoryState(SPECIAL_OFFERS_SLUG);
      return;
    }

    this.setCurrentCategoryState(null);
  }

  getIsCategoryPage(pathname) {
    return pathname.split('/')[1] === CATEGORY_SLUG;
  }

  getIsSpecialOffersPage(pathname) {
    return pathname.split('/')[1] === SPECIAL_OFFERS_SLUG;
  }

  getCategorySlug(pathname) {
    return pathname.split('/')[2];
  }

  handleSetCurrentCategorySlug = slug => {
    this.setCurrentCategoryState(slug);
  };

  setCurrentCategoryState(currentCategory) {
    this.setState({
      currentCategory
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileCatalogNavigationContainer);
