import { schema } from 'normalizr';

export default function build({ media }) {
  const article = new schema.Entity(
    'articles',
    {
      author: {
        firstName: 'string',
        lastName: 'string'
        // photo: media
      }
      // cover: media
    },
    {
      idAttribute: 'slug',
      processStrategy: transformToEntity
    }
  );

  article.define({
    nextArticle: article,
    previousArticle: article,
    relatedArticleList: [article]
  });

  return article;
}

function transformToEntity(value) {
  const {
    author,
    create_date,
    next_article,
    previous_article,
    related_articles,
    is_liked,
    ...rest
  } = value;

  const article = {
    author: {
      firstName: author.first_name,
      lastName: author.last_name
    },
    nextArticle: next_article,
    previousArticle: previous_article,
    relatedArticleList: related_articles,
    createDate: Date.parse(create_date),
    isLiked: is_liked,
    ...rest
  };

  return article;
}
