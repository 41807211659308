import * as TYPES from '../types/location';
import { createAction } from 'modules/utils/dux';

export default {
  chooseCity(id, citySearchData) {
    return createAction(TYPES.CITY_CHOOSE, { id, citySearchData });
  },

  setSuggestedCity(city) {
    return createAction(TYPES.CITY_SUGGESTED_SET, { city });
  },

  resetSelectedCity() {
    return createAction(TYPES.CITY_SELECTED_RESET);
  },

  cityRehydrate(cityId) {
    return createAction(TYPES.CITY_REHYDRATE, { cityId });
  }
};
