import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SearchField from '../components/search-field/search-field';
// import * as fromSearch from 'modules/product/ducks/search';
import AutosuggestedProducts from 'modules/product/ducks/autosuggested-products/actions';
import * as fromAutosuggestedProducts from 'modules/product/ducks/autosuggested-products/reducer';
import { getProductLink, getSearchLink } from 'routes/links';
import { push } from 'connected-react-router';
import { actions as Search } from 'modules/product/ducks/search';

// const getQuery = fromSearch.getGlobalQuery;
// const getSuggestions = createSelector(
//   fromSearch.makeGetProductListByQuery(getQuery),
//   fromProducts.getFindProductBySlug,
//   (slugList, findProduct) => {
//     if (!slugList) {
//       return [];
//     }
//     return slugList.map(findProduct).map(product => ({
//       ...product,
//       value: product.slug
//     }));
//   }
// );

const mapState = createStructuredSelector({
  query: fromAutosuggestedProducts.getQuery,
  products: fromAutosuggestedProducts.getProducts
});

const mapActions = {
  push,
  changeQuery: AutosuggestedProducts.request,
  reset: AutosuggestedProducts.reset,
  searchRequest: Search.request
};

class SearchFieldContainer extends Component {
  state = {
    focused: false,
    suggestions: []
  };

  render() {
    const { products, query } = this.props;
    const { focused } = this.state;
    const placeholder = focused ? 'Начните вводить название' : 'Поиск по товарам';

    const suggestions = this.formatSuggestion(products);

    return (
      <SearchField
        placeholder={placeholder}
        wide={focused || query}
        focused={focused}
        suggestions={suggestions}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onSubmit={this.handleSubmit}
        onProductSelect={this.handleProductSelect}
      />
    );
  }

  formatSuggestion(products) {
    return products.map(product => ({ ...product, value: product.slug }));
  }

  focusSearch() {
    this.setState({
      focused: true
    });
  }

  blurSearch() {
    this.setState({
      focused: false
    });
  }

  search(query) {
    if (!query) {
      return;
    }

    this.props.searchRequest(this.props.query);
    this.props.push(getSearchLink(query));
  }

  handleBlur = () => {
    setTimeout(() => this.blurSearch(), 200);
  };

  handleFocus = () => {
    this.focusSearch();
  };

  handleSubmit = () => {
    const { query } = this.props;

    this.search(query);
    this.blurSearch();
  };

  handleChange = query => {
    const { changeQuery } = this.props;

    changeQuery(query);
  };

  handleProductSelect = option => {
    const { push, reset } = this.props;

    reset();
    push(getProductLink(option));
  };
}

export default connect(
  mapState,
  mapActions
)(SearchFieldContainer);
