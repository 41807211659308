import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import PaginationButton from './pagination-button';
import PaginationBreak from './pagination-break';

import './pagination.css';

class Pagination extends Component {
  render() {
    const { amount, current } = this.props;
    const notSinglePage = amount !== 1;

    return (
      <ul className="Pagination">
        <li key={1} className="Pagination-item">
          <PaginationButton page={1} current={current === 1} onClick={this.handleClick(1)} />
        </li>
        {notSinglePage && (
          <Fragment>
            {current === 4 && (
              <li key={2} className="Pagination-item">
                <PaginationButton page={2} current={false} onClick={this.handleClick(2)} />
              </li>
            )}

            {current > 4 && (
              <li key={'break_1'} className="Pagination-item">
                <PaginationBreak />
              </li>
            )}

            {current > 2 && (
              <li key={current - 1} className="Pagination-item">
                <PaginationButton
                  page={current - 1}
                  current={false}
                  onClick={this.handleClick(current - 1)}
                />
              </li>
            )}

            {current !== 1 && current !== amount && (
              <li key={current} className="Pagination-item">
                <PaginationButton
                  page={current}
                  current={true}
                  onClick={this.handleClick(current)}
                />
              </li>
            )}

            {amount - current > 1 && (
              <li key={current + 1} className="Pagination-item">
                <PaginationButton
                  page={current + 1}
                  current={false}
                  onClick={this.handleClick(current + 1)}
                />
              </li>
            )}

            {amount - current > 2 && (
              <li key={'break_2'} className="Pagination-item">
                <PaginationBreak />
              </li>
            )}
            <li key={amount} className="Pagination-item">
              <PaginationButton
                page={amount}
                current={current === amount}
                onClick={this.handleClick(amount)}
              />
            </li>
          </Fragment>
        )}
      </ul>
    );
  }

  changePage(index) {
    const { onChange } = this.props;

    if (!isFunction(onChange)) {
      return;
    }
    onChange(index);
  }

  handleClick = index => () => {
    this.changePage(index);
  };
}

export default Pagination;
