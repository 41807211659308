export default function initOrderRequest(createRequest) {
  return function _getOrderRequest() {
    return {
      all: createRequest(initListRequest()),
      item: function _getItemRequest(id = null) {
        return {
          add: createRequest(initItemAddRequest()),
          item: createRequest(initItemGetRequest(id)),
          change: createRequest(initItemChangeRequest(id)),
          payment: createRequest(initItemPaymentRequest(id))
        };
      },
      bonuses: function _getBonusesCodeRequest({ bonuses, code }) {
        return {
          code: createRequest(initBonusesCodeRequest(bonuses)),
          confirm: createRequest(initBonusesConfirmRequest(code))
        };
      }
    };
  };
}

/* BONUSES */
function initBonusesCodeRequest(bonuses) {
  return function _getBonusesCodeRequest() {
    return {
      method: 'POST',
      path: `/bonus-account/confirm-request`,
      data: bonuses
    };
  };
}

function initBonusesConfirmRequest(code) {
  return function _getBonusesConfirmRequest() {
    return {
      method: 'POST',
      path: `/bonus-account/confirm`,
      data: code
    };
  };
}

function initListRequest() {
  return function getItemRequest(parameters = null) {
    return {
      method: 'GET',
      path: `/orders`,
      query: parameters
    };
  };
}

function initItemGetRequest(id) {
  return function getItemRequest() {
    return {
      method: 'GET',
      path: `/orders/${id}`
    };
  };
}

function initItemAddRequest() {
  return function getItemRequest(order = {}, parameters = null) {
    return {
      method: 'POST',
      path: `/orders`,
      data: order,
      query: parameters
    };
  };
}

function initItemChangeRequest(id) {
  return function getItemRequest(order = {}, parameters = null) {
    return {
      method: 'PUT',
      path: `/orders/${id}`,
      data: order,
      query: parameters
    };
  };
}

function initItemPaymentRequest(id) {
  return function getItemPaymentRequest() {
    return {
      method: 'GET',
      path: `/orders/${id}/payment`
    };
  };
}
