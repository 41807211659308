import React, { Component } from 'react';
import { IconEye } from 'modules/core/components/icons';

import Button from 'modules/core/components/button/button';

class ButtonQuickViewicon extends Component {
  render() {
    const { ...rest } = this.props;
    return <Button icon={<IconEye />} variant="secondary" size="small" {...rest} />;
  }
}

export default ButtonQuickViewicon;
