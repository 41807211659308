import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Page from 'modules/core/components/page/page';
import { getIndexLink } from 'routes/links';
import MassMediaList from '../containers/mass-media-list';
import Container from 'modules/core/components/container/container';
import MassMediaSortPanel from '../containers/mass-media-sort-panel';

class MassMediaPage extends Component {
  static path = '/mass-media-about-us';

  static exact = true;

  render() {
    return (
      <Page
        title="Пресса о нас"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Пресса о нас'
          }
        ]}
      >
        <Helmet>
          <title>Пресса о нас - интернет-магазин MIXIT</title>

          <meta name="description" content="Пресса о нас." />
        </Helmet>

        <Container>
          <MassMediaSortPanel />
        </Container>

        <Container>
          <MassMediaList />
        </Container>
      </Page>
    );
  }

  componentDidMount() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default MassMediaPage;
