import React, { Component } from 'react';

class NoSsr extends Component {
  static defaultProps = {
    fallback: <span />
  };

  state = {
    mounted: false
  };

  componentDidMount() {
    if (this.state.mounted) {
      return;
    }

    this.setState({
      mounted: true
    });
  }

  render() {
    return this.state.mounted ? this.props.children : this.props.fallback;
  }
}

export default NoSsr;
