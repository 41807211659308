import React, { Component, Children } from 'react';

class PaginationContainer extends Component {
  _outerHolder = null;
  _innerHolder = null;
  _hasPagination = null;
  render() {
    const { children } = this.props;

    this._hasPagination = Children.count(children) > 1;

    if (this._hasPagination) {
      return (
        <div className="bannerFeaturedSlider-pagination">
          <div className="bannerFeaturedSlider-paginationOuterHolder" ref={this.setOuterHolderRef}>
            <div className="bannerFeaturedSlider-paginationHolder" ref={this.setInnerHolderRef}>
              {children}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  componentDidMount() {
    if (this._hasPagination) {
      this.setSizesOfPaginationContainer();
      this.animatePaginationDots();
    }
  }

  componentDidUpdate() {
    if (this._hasPagination) {
      this.animatePaginationDots();
    }
  }

  animatePaginationDots() {
    this._innerHolder.style.transform = `translateX(${this.getTranslateValue()})`;
  }

  setSizesOfPaginationContainer() {
    const { children, buttonAmount } = this.props;
    const amount = Children.count(children);
    const { height, width } = this._innerHolder.getBoundingClientRect();
    const apearedWidth = (width / amount) * buttonAmount;
    const usedWidth = amount < buttonAmount ? width : apearedWidth;

    this._outerHolder.style.width = usedWidth + 'px';
    this._outerHolder.style.height = height + 'px';
  }

  getTranslateValue() {
    const { children, currentPage, buttonAmount } = this.props;
    const amount = Children.count(children);
    const { width } = this._innerHolder.getBoundingClientRect();
    const oneElementWidth = width / amount;
    const apearedWidth = oneElementWidth * buttonAmount;
    const SIDE_VIEWED_AMOUNT = (buttonAmount - 1) / 2;

    const startCondition = currentPage < SIDE_VIEWED_AMOUNT + 1;
    const centerCondition =
      currentPage > SIDE_VIEWED_AMOUNT && currentPage < amount - (SIDE_VIEWED_AMOUNT + 1);
    const endConditions = currentPage + 1 > amount - SIDE_VIEWED_AMOUNT - 1;

    if (amount < buttonAmount) {
      return '0px';
    }

    if (startCondition) {
      return '0px';
    }
    if (centerCondition) {
      const translateX =
        apearedWidth + oneElementWidth * (currentPage - buttonAmount - SIDE_VIEWED_AMOUNT);
      return -translateX + 'px';
    }
    if (endConditions) {
      return apearedWidth - width + 'px';
    }
  }

  setOuterHolderRef = element => {
    if (!element) return;
    this._outerHolder = element;
  };

  setInnerHolderRef = element => {
    if (!element) return;
    this._innerHolder = element;
  };
}

export default PaginationContainer;
