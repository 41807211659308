import { createReducer, handle } from 'modules/utils/dux';

import { createSelector } from '../../utils/debug-reselect';

import * as TYPES from './types/draw-roll-up';

/* REDUCERS */

const reducer = createReducer(
  handle(TYPES.DRAW_ROLL_UP_OPEN, state => {
    return {
      ...state,
      isOpened: true
    };
  }),

  handle(TYPES.DRAW_ROLL_UP_CLOSE, state => {
    return {
      ...state,
      isOpened: false
    };
  })
);

export default reducer({
  isOpened: false
});

const getRoot = state => state.core.drawRollUp;

export const getIsOpened = createSelector(getRoot, root => root.isOpened);
