import { combineReducers } from 'redux';

import products from './products';
import search from './search';
import productsDetails from './products-details';
import categories from './categories';
import filters from './filters';
import filterOptions from './filter-options';
import groups from './groups';
import setList from './set-list';
import recent from './recent';
import sortOptions from './sort-options';
import autosuggestedProducts from './autosuggested-products/reducer';
import variantOptions, { NAME as VARIANT_OPTIONS_NAME } from './variant-options';

/* REDUCERS EXPORT */

const reducer = combineReducers({
  products,
  productsDetails,
  categories,
  filters,
  filterOptions,
  setList,
  groups,
  recent,
  search,
  sortOptions,
  autosuggestedProducts,
  [VARIANT_OPTIONS_NAME]: variantOptions
});

export default reducer;
