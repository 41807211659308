import React, { Component } from 'react';
import './icon-arrow-right.css';

class IconArrowRight extends Component {
  render() {
    return (
      <svg className="IconArrowRight" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.2 95.9">
        <path
          d="M235.3 43.4L193.8 1.9a6.5 6.5 0 0 0-9.2 9.2L215 41.5H6.5a6.5 6.5 0 1 0 0 13H215l-30.4 30.4a6.5 6.5 0 0 0 9.2 9.1l41.5-41.5a6.5 6.5 0 0 0 0-9.2zm0 0"
          data-name="Layer 1"
        />
      </svg>
    );
  }
}

export default IconArrowRight;
