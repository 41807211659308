import React, { Component } from 'react';
import { string, any, func, bool } from 'prop-types';
import Media from 'react-media';

import Link from 'modules/core/components/link/short-link';
import { IconEdit } from 'modules/core/components/icons';
import IconContainer from 'modules/core/components/icon-container/icon-container';

import './order-step-summary.css';

class OrderStepSummary extends Component {
  static propTypes = {
    title: string.isRequired,
    summary: any.isRequired,
    editAbility: bool,
    onEdit: func.isRequired
  };

  render() {
    const { title, children, editAbility, onEdit } = this.props;

    return (
      <div className="OrderStepSummary">
        <div className="OrderStepSummary-titleRow">
          <h3 className="OrderStepSummary-title">
            <span className="OrderStepSummary-titleMark">
              <span className="OrderStepSummary-checkMark" role="presentation" title="Готово!" />
            </span>
            <span className="OrderStepSummary-titleString">{title}</span>
          </h3>

          {editAbility && (
            <div className="OrderStepSummary-buttonEdit">
              <Media query="(max-width:840px)">
                {isMobile =>
                  isMobile ? (
                    <button className="OrderStepSummary-editButton" type="button" onClick={onEdit}>
                      <IconContainer size="small">
                        <IconEdit />
                      </IconContainer>
                    </button>
                  ) : (
                    <Link component="button" type="button" onClick={onEdit}>
                      Редактировать
                    </Link>
                  )
                }
              </Media>
            </div>
          )}
        </div>
        {children && <div className="OrderStepSummary-summary">{children}</div>}
      </div>
    );
  }
}

export default OrderStepSummary;
