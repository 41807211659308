import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { actions as DialogActions } from 'modules/core/ducks/dialogs';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromUTMBanner from '../ducks/utm-banner';

import UTMBannerActions from '../ducks/actions/utm-banner';

import UTMDialog from '../components/utm-dialog/utm-dialog';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsUTMOpened,
  data: fromUTMBanner.getDialogData
});

const mapDispatchToProps = {
  close: DialogActions.close,
  resetData: UTMBannerActions.resetData
};

class UTMDialogContainer extends Component {
  render() {
    const { isOpened, data } = this.props;

    return <UTMDialog show={isOpened} onClose={this.handleClose} {...data} />;
  }

  handleClose = () => {
    const { close } = this.props;

    close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UTMDialogContainer);
