import React, { Component } from 'react';
import UnauthorizedMenu from 'modules/core/components/unauthorized-menu/unauthorized-menu';
import UserOverviewContainer from 'modules/profile/containers/user-overview';
import Loader from 'modules/core/components/loader/loader';

class NavigationDrawerHeader extends Component {
  render() {
    const {
      isAuthorized,
      onUserOverviewClick,
      openRegistrationDialog,
      openAuthDialog,
      isProfileLoading
    } = this.props;

    return (
      <div className="NavigationDrawer-header">
        {isAuthorized ? (
          <div className="NavigationDrawer-authorizedPanel">
            <UserOverviewContainer
              onUserOverviewClick={onUserOverviewClick}
              direction="row"
              fullSizeInteractive
            />
          </div>
        ) : (
          isProfileLoading ? (
            <div className="NavigationDrawer-loader">
              <Loader secondary />
            </div>
          ) : (
            <div className="NavigationDrawer-unauthorizedPanel">
            <UnauthorizedMenu
              openAuthDialog={openAuthDialog}
              openRegistrationDialog={openRegistrationDialog}
            />
          </div>
          )
        )}
      </div>
    );
  }
}

export default NavigationDrawerHeader;
