import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromLocation from '../ducks/location';
import * as fromCityList from '../ducks/city-list';

import CityDialog from '../components/city-dialog/city-dialog';
import Location from '../ducks/actions/location';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsCityOpened,
  idList: fromCityList.getIdList,
  currentId: fromLocation.getChosenId
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close,
  chooseCity: Location.chooseCity
};

class CityDialogContainer extends Component {
  render() {
    const { idList, currentId, isOpened } = this.props;

    return (
      <CityDialog
        list={idList}
        currentId={currentId}
        show={isOpened}
        onSearchQueryChange={this.handleSearchQueryChange}
        onChooseFromSearch={this.handleChooseFromSearch}
        onClose={this.handleDialogClose}
      />
    );
  }

  handleDialogClose = () => {
    const { close } = this.props;

    close();
  };

  handleSearchQueryChange = query => {
    console.warn('handleSearchQueryChange is not implemented! ', query);
  };

  handleChooseFromSearch = data => {
    this.props.chooseCity(data.id, data);
    this.props.close();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CityDialogContainer);
