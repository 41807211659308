import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useThrottle } from 'react-use';

import { isTouchDevice } from 'modules/utils';

import './zoomer.css';

const OUT_PLACES = ['left', 'right', 'top', 'bottom'];

function getOffset(coordinate, ratio) {
  return -coordinate * (ratio - 1) * 100;
}

function getOutPosition(place) {
  if (typeof place === 'boolean' && Boolean(place)) {
    return 'right';
  }
  return place;
}

export default function ZoomerConditionalChecker({ ...rest }) {
  const { children } = rest;
  if (isTouchDevice()) {
    return children;
  }

  return <Zoomer {...rest} />;
}

function Zoomer({ place = 'above', children, underlay, ratio, scaleByWheel, ...rest }) {
  const [isHovered, setIsHovered] = useState(false);
  const [_ratio, setRatio] = useState(ratio);
  const [coordinates, setCoordinate] = useState([0, 0]);

  const isOut = OUT_PLACES.includes(place);

  const holderRef = useRef(null);

  const outPosition = getOutPosition(place);

  const resetRatio = () => {
    setRatio(ratio);
  };

  const wheelProps = {
    ...(scaleByWheel && { onWheel: handleMouseWheel })
  };

  return (
    <div
      className={cn('Zoomer', {
        'Zoomer--isHovered': isHovered,
        [`Zoomer--underlay`]: underlay,
        [`Zoomer--out`]: isOut,
        [`Zoomer--out_${outPosition}`]: isOut
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      {...wheelProps}
      ref={holderRef}
    >
      <ZoomerContent
        coordinates={coordinates}
        isHovered={isHovered}
        {...rest}
        ratio={_ratio}
        setRatio={setRatio}
        resetRatio={resetRatio}
      >
        {children}
      </ZoomerContent>
    </div>
  );

  function handleMouseWheel(e) {
    if (e.deltaY < 0) {
      setRatio(_ratio * (1 + 0.4));
    } else if (e.deltaY > 0) {
      setRatio(_ratio * (1 - 0.4));
    }
  }

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
    // setCoordinate([0, 0]);
  }

  function handleMouseMove(e) {
    const { left, top, width, height } = holderRef.current.getBoundingClientRect();

    const coordX = e.clientX - left;
    const coordY = e.clientY - top;

    const relativeX = (coordX / width).toFixed(2);
    const relativeY = (coordY / height).toFixed(2);

    const [prevCoordX, prevCoordY] = coordinates;

    const hasCoordinatesChanged = prevCoordX !== relativeX || prevCoordY !== relativeY;

    if (hasCoordinatesChanged) {
      setCoordinate([relativeX, relativeY]);
    }
  }
}

function ZoomerContent({
  coordinates,
  ratio = 1.5,
  isHovered,
  children,
  throttleTime = 60,
  pointer,
  scalePanel,
  setRatio,
  resetRatio
}) {
  const throttledcoordinates = useThrottle(coordinates, throttleTime);

  // const [lastThrottledValue, setLastThrottledValue] = React.useState(throttledcoordinates);
  // const [count, { inc }] = useCounter();

  // React.useEffect(() => {
  //   if (lastThrottledValue !== throttledcoordinates) {
  //     setLastThrottledValue(throttledcoordinates);
  //     inc();
  //   }
  // }, [lastThrottledValue, throttledcoordinates, inc]);

  const hoveredStyle = {
    ...(isHovered && {
      transform: `translate3d(${getOffset(throttledcoordinates[0], ratio) + 0.01}%, ${getOffset(
        throttledcoordinates[1],
        ratio
      ) + 0.01}%, 0) scale(${ratio})`
    })
  };

  const pointerCoordinater = {
    left: `${throttledcoordinates[0] * 100}%`,
    top: `${throttledcoordinates[1] * 100}%`
  };

  return (
    <div className="Zoomer-content">
      {scalePanel && renderScalePanel()}
      <div className="Zoomer-main">{children}</div>
      <div className="Zoomer-topHolder">
        <div className="Zoomer-top" style={hoveredStyle}>
          {children}
        </div>
        {pointer && <div style={pointerCoordinater} className="Zoomer-topPointer"></div>}
      </div>
      {/* {count} */}
    </div>
  );

  function renderScalePanel() {
    return (
      <div className="Zoomer-scalePanel">
        <button className="Zoomer-scaleButton" onClick={handleUpRatio}>
          up
        </button>
        <button className="Zoomer-scaleButton" onClick={handleDownRatio}>
          Down
        </button>
        <button className="Zoomer-scaleButton" onClick={handleResetRatio}>
          reset
        </button>
      </div>
    );
    function handleResetRatio() {
      resetRatio();
    }
    function handleUpRatio() {
      setRatio(ratio * (1 + 0.2));
    }
    function handleDownRatio() {
      setRatio(ratio * (1 - 0.2));
    }
  }
}
