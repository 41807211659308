import React, { Component } from 'react';
import Media from 'react-media';

import StatusIndicator from 'modules/core/components/status-indicator/status-indicator';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconAngleArrowRight } from 'modules/core/components/icons/index';
import OrderTable from 'modules/ordering/components/order-table/order-table';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import ORDER_STATUS from 'modules/ordering/constants/order-status';

import { dateToString } from './helpers';

import { getProfileOrderLink } from 'routes/links';

function OrderStatus({ order }) {
  switch (order.status) {
    case ORDER_STATUS.ABANDONED:
      return <StatusIndicator status="empty" title="Не заполнен" />;
    case ORDER_STATUS.ACCEPTED:
      return <StatusIndicator status="waiting" title="Подтвержден" />;
    case ORDER_STATUS.AWAITING_CALL:
      return <StatusIndicator status="waiting" title="В обработке" />;
    case ORDER_STATUS.NEW:
      return <StatusIndicator status="waiting" title="Новый" />;
    case ORDER_STATUS.IN_DELIVERY:
      return <StatusIndicator status="waiting" title="Передан в доставку" />;
    case ORDER_STATUS.DELIVERED:
      return <StatusIndicator status="success" title="Доставлен" />;
    case ORDER_STATUS.CANCELED:
      return <StatusIndicator status="canceled" title="Отменен" />;
    case ORDER_STATUS.EXPECTING_PAYMENT:
      return <StatusIndicator status="waiting" title="Ожидает оплату" />;
    case ORDER_STATUS.MISSED_CALL:
      return <StatusIndicator status="waiting" title="Не смогли дозвониться" />;
    default:
      return null;
  }
}

class Row extends Component {
  render() {
    const { order, isTrackingNumberExist } = this.props;
    return (
      <OrderTable.Row key={order.id} to={getProfileOrderLink(order)}>
        <OrderTable.Cell variant="number">
          <OrderTable.SubCell>№{order.id}</OrderTable.SubCell>
        </OrderTable.Cell>
        <OrderTable.Cell variant="date">
          <OrderTable.SubCell>
            {order.placingDate ? dateToString(order.placingDate) : '-'}
          </OrderTable.SubCell>
        </OrderTable.Cell>
        <OrderTable.Cell variant="price">
          <OrderTable.SubCell>
            <Media query="(max-width: 840px)">
              {matches => (
                <div>
                  {order.isPayed && <div style={{ fontWeight: '700' }}>Оплачен</div>}
                  <Price number={order.total || 0} variant={matches ? 'default' : 'secondary'} />
                </div>
              )}
            </Media>
          </OrderTable.SubCell>
        </OrderTable.Cell>
        {isTrackingNumberExist && (
          <OrderTable.Cell variant="trackingNumber">
            <OrderTable.SubCell>
                { Boolean(order.trackingNumber) ?(
                  <>
                    {order.trackingNumber}
                    {/* {
                      order.deliveryCompanyTitle && (
                          <>
                            <br/>
                            {`(${order.deliveryCompanyTitle})`}
                          </>
                      )
                    } */}
                  </>
                )  :  "---" }
            </OrderTable.SubCell>
          </OrderTable.Cell>
        )}
        <OrderTable.Cell variant="status">
          <OrderTable.SubCell>
            <OrderStatus order={order} />
            <div className="OrderTable-arrow">
              <IconContainer size="xsmall">
                <IconAngleArrowRight />
              </IconContainer>
            </div>
          </OrderTable.SubCell>
        </OrderTable.Cell>
      </OrderTable.Row>
    );
  }
}

export default Row;
