import React, { Component } from 'react';
import './icon-world-card.css';

class IconCardWorld extends Component {
  static defaultProps = {
    opacity: 1
  };

  render() {
    const { opacity } = this.props;

    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 50 35"
        className="IconCardWorld"
        fill="#828da2"
        style={{ opacity: opacity }}
      >
        <linearGradient
          id="path10_1_"
          gradientUnits="userSpaceOnUse"
          x1="34.896"
          y1="-1498.55"
          x2="49.108"
          y2="-1498.55"
          gradientTransform="matrix(1 0 0 -1 0 -1485)"
        >
          <stop offset="0" stop-color="#00b4e6" />
          <stop offset="1" stop-color="#088ccb" />
        </linearGradient>
        <path
          d="M44.1 10.6h-9.3c.5 3.2 3.5 5.9 6.8 5.9H49v-1.1c.2-2.7-2.1-4.8-4.9-4.8z"
          fill-rule="evenodd"
          clip-rule="evenodd"
        />
        <path id="path12" class="st1" d="M35.7 17.1v7.5h4.4v-3.9h4c2.1 0 4-1.6 4.7-3.5l-13.1-.1z" />
        <path d="M19.8 10.6v14h4s1.1 0 1.6-1.1c2.6-5.4 3.5-7 3.5-7h.5v8.1h4.4v-14h-4s-1 0-1.6 1.1c-2.3 4.5-3.5 7-3.5 7h-.5v-7.9h-4.4v-.2z" />
        <path d="M.9 24.4V10.6h4.4s1.2 0 1.9 1.9c1.8 5.3 1.9 5.9 1.9 5.9s.3-1.2 1.9-5.9c.7-1.9 1.9-1.9 1.9-1.9h4.4v14h-4.4v-7.5h-.5L10 24.6H8.2l-2.4-7.5h-.5v7.5l-4.4-.2z" />
      </svg>
    );
  }
}

export default IconCardWorld;
