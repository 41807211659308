import * as categories from './categories';
import * as products from './products';
import * as search from './search';
import * as recent from './recent';

export default {
  categories,
  products,
  search,
  recent
};
