import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dialog from 'modules/core/components/dialog/dialog';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
import Input from 'modules/form/components/input/input';
import Button from 'modules/core/components/button/button';

import './edit-user-name-modal.css';

class EditUserNameModal extends Component {

  render() {
    const { firstName, lastName, ...rest } = this.props;

    const initialValues = {
      firstName: firstName || '',
      lastName: lastName || ''
    };

    const АШКІЕ_NAME_REQUIRED_ERROR = 'Укажите Ваше имя';
    const LAST_NAME_REQUIRED_ERROR = 'Укажите Вашу Фамилию';

    const credentialsSchema = Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(АШКІЕ_NAME_REQUIRED_ERROR),
      lastName: Yup.string()
        .trim()
        .required(LAST_NAME_REQUIRED_ERROR)
    });

    return (
      <Dialog closeable overflowVisible {...rest}>
        <Formik
          validationSchema={credentialsSchema}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          // isInitialValid={credentialsSchema.isValidSync(initialValues)}
          render={props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid
            } = props;

            return (
              <div className="EditUserNameModal">
                <div className="EditUserNameModal-form">
                  <Form onSubmit={handleSubmit}>
                    <Media query="(max-width: 840px)">
                      {isMobile => (
                        <Fragment>
                          <Form.LabeledRow>
                            <Field
                              label="Имя"
                              error={touched.firstName && errors.firstName}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="firstName"
                                value={values.firstName}
                                state={touched.firstName && errors.firstName && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                // required
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.LabeledRow>
                            <Field
                              label="Фамилия"
                              error={touched.lastName && errors.lastName}
                              size={isMobile ? 'small' : 'normal'}
                            >
                              <Input
                                name="lastName"
                                value={values.lastName}
                                state={touched.lastName && errors.lastName && 'error'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={isMobile ? 'small' : 'normal'}
                                // required
                              />
                            </Field>
                          </Form.LabeledRow>
                          <Form.Row>
                            <Field>
                              <div className="CallbackDialog-submit">
                                <Button
                                  title="Изменить"
                                  variant="primary"
                                  type="submit"
                                  size={isMobile ? 'small' : 'normal'}
                                  visualyDisabled={!isValid}
                                  // disabled = {!isValid}
                                  expanded
                                />
                              </div>
                            </Field>
                          </Form.Row>
                        </Fragment>
                      )}
                    </Media>
                  </Form>
                </div>
              </div>
            );
          }}
        />
      </Dialog>
    );
  }



  handleSubmit = data => {
    const { onClose, changeFullName } = this.props;

    // const requestData = {
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    // };

    const requestData = data.firstName + " " + data.lastName

    changeFullName(requestData);
    onClose();
  };

}

export default EditUserNameModal;
