import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './consistency-content.css';
import IngredientTitlesContainer from '../../containers/ingredient-titles';

const { string, number, shape, arrayOf } = PropTypes;

class ConsistencyContent extends Component {
  static propTypes = {
    list: arrayOf(
      shape({
        id: number.isRequired,
        title: string.isRequired,
        name: string.isRequired,
        color: string,
        list: arrayOf(number).isRequired
      })
    )
  };

  render() {
    const { list } = this.props;

    return <ul className="ConsistencyContent">{list.map(this.renderItem)}</ul>;
  }

  renderItem = (item, index) => {
    return (
      <li key={index} className="ConsistencyContent-ingredients" style={{ color: item.color }}>
        {item.raw ? item.raw : <IngredientTitlesContainer ids={item.list} withLinks />}
      </li>
    );
  };
}

export default ConsistencyContent;
