import { combineReducers } from 'redux';

import basket from './basket';
import orderingForm from './ordering-form';
import pickPointList from './pick-point-list';
import personalInfoForm from './personal-info-form';
import paymentMethods from './payment-methods';
import deliveryForm from './delivery-form';
import deliveryMethods from './delivery-methods';
import cityDelivery from './city-delivery';
import orders from './orders';
import pickPointQuery from './pick-point-query';
import cityTable from './city-table';
import deliveryMethodTable from './delivery-method-table';
import lastOrder from './last-order/reducer';
import basketPreview from './basket-preview/reducer';

export const MODULE_NAME = 'ordering';

const reducers = {
  basket,
  orderingForm,
  pickPointList,
  personalInfoForm,
  paymentMethods,
  deliveryForm,
  deliveryMethods,
  cityDelivery,
  orders,
  pickPointQuery,
  cityTable,
  deliveryMethodTable,
  lastOrder,
  basketPreview
};

export const reducer = combineReducers(reducers);
export default reducer;

export const rootSelector = state => state[MODULE_NAME];
export const getModule = state => state[MODULE_NAME];
export const getPersonalInfoForm = state => getModule(state).personalInfoForm;
export const getDeliveryForm = state => getModule(state).deliveryForm;
