import React, { Component, Fragment } from 'react';
import Media from 'modules/core/components/media/media';

import Dialog from '../dialog/dialog';

import './declaration-card.css';

class DeclarationCard extends Component {
  state = {
    isDialogOpened: false
  };

  render() {
    const { title, media } = this.props;

    const { isDialogOpened } = this.state;

    return (
      <Fragment>
        <button className="DeclarationCard" onClick={this.handleClick}>
          <div className="DeclarationCard-content">
            <div className="DeclarationCard-imageHolder">
              <Media
                media={media}
                // resizeWidth={250}
                nocaption
                stretch="horizontal"
                // background="red"
              />
            </div>
            <div className="DeclarationCard-title">{title}</div>
          </div>
        </button>
        {isDialogOpened && this.renderDialog()}
      </Fragment>
    );
  }

  renderDialog() {
    const { isDialogOpened } = this.state;
    const { title, media } = this.props;
    return (
      <Dialog closeable onClose={this.handleClose} show={isDialogOpened} expanded>
        <div className="DeclarationCard_Dialog">
          <div className="DeclarationCard_Dialog-title">{title}</div>
          <div className="DeclarationCard-imageHolder">
            <Media
              media={media}
              // resizeWidth={100}
              stretch="horizontal"
              // background="red"
              nocaption
            />
          </div>
        </div>
      </Dialog>
    );
  }

  handleClick = () => {
    this.setDialogOpen();
  };

  handleClose = () => {
    this.setDialogClose();
  };

  setDialogOpen() {
    this.setState({
      isDialogOpened: true
    });
  }

  setDialogClose() {
    this.setState({
      isDialogOpened: false
    });
  }
}

export default DeclarationCard;
