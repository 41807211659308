import React, { Component, Fragment } from 'react';
import { getProductLink } from 'routes/links';
import { withRouter } from 'react-router';

import Amount from 'modules/ordering/components/amount/amount';
import Media from 'modules/core/components/media/media';
import Price from 'modules/ordering/components/price-formatted/price-formatted';
import { IconClose } from 'modules/core/components/icons';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';
import ProductFeatures from 'modules/product/components/product-features/product-features';

import './product-preview.css';

class ProductPreview extends Component {
  render() {
    const {
      product,
      price,
      isBasketFetching,
      amount,
      onAmountChange,
      withCounter,
      isGift,
      listId
    } = this.props;

    const filteredFeatures = this.getFilteredfeaturesBy(product.features, 'FEATURE/MIXIT_ART');

    const isMixitArtProduct = filteredFeatures.length !== 0;

    return (
      <ProductAnalyticsProvider
        productId={product.slug}
        isGift={isGift}
        listId={listId}
        render={({ productRef, handleClick }) => (
          <div className="ProductPreview" ref={productRef}>
            <div className="ProductPreview-imageHolder">
              {this.renderProductLinkComponent(
                handleClick, 
                "ProductPreview-imageWrapper", 
                <Media
                  media={product.announcementMedia}
                  stretch="horizontal"
                  resizeWidth={72}
                  background="#fff"
                  nocaption
                />)}
            </div>
            <div className="ProductPreview-contentHolder">
              {!isGift && (
                <button
                  className="ProductPreview-buttonRemove"
                  onClick={this.handleRemoveButtonClick}
                  disabled={isBasketFetching}
                >
                  <IconClose />
                </button>
              )}

              <div className="ProductPreview-info">
                {isMixitArtProduct && (
                  <div className="ProductPreview-features">
                    <ProductFeatures
                      size="small"
                      // isGift={product.isGift}
                      features={filteredFeatures}
                    />
                  </div>
                )}
                {product.description && (
                  this.renderProductLinkComponent(
                    handleClick, 
                    "ProductPreview-description", 
                    product.description)
                )}

                {product.title && (
                  <h3 className="ProductPreview-title">
                    {this.renderProductLinkComponent(
                        handleClick, 
                        "ProductPreview-link", 
                        product.title)}
                  </h3>
                )}

                {product.variantOptionsAll &&
                  product.variantOptionsAll.map(item => (
                    <div className="ProductPreview-selectedVariant">
                      {item.variantTitle}: {item.value} {(item.payload || {}).unit || ''}
                    </div>
                  ))}
              </div>

              <div className="ProductPreview-purchaseInformation">
                <div className="ProductPreview-quantity">
                  {withCounter ? (
                    <div className="ProductPreview-amountHolder">
                      <Amount
                        size="small"
                        value={amount}
                        onChange={onAmountChange}
                        withoutStroke
                        disabled={isBasketFetching}
                      />
                    </div>
                  ) : (
                    `${amount} шт.`
                  )}
                </div>

                {isGift ? (
                  <span className="ProductPreview-giftLabel">Подарок!</span>
                ) : (
                  <div className="ProductPreview-price">
                    {price.discount > 0 ? (
                      <Fragment>
                        <Price number={price.default} variant="previous" />{' '}
                        <Price number={price.current} />
                      </Fragment>
                    ) : (
                      <Price number={price.current} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  renderProductLinkComponent = (handleClick, className, content) => {
    const {
      product,isGift 
    } = this.props;

    const filteredFeatures = this.getFilteredfeaturesBy(product.features, 'FEATURE/MIXIT_ART');

    const isMixitArtProduct = filteredFeatures.length !== 0;

    const productLink = isMixitArtProduct
    ? this.getMixitArtLink(product.slug)
    : getProductLink(product);
    
    const UsedTag = isGift ? "div": "a";
    const paremeters = {
      ...(!isGift && {href: productLink}),
      ...(!isGift && {target: "_blank"}),
      ...(!isGift && {rel: "noreferrer noopener"}),
    } 
    
    return (
      <UsedTag
        {...paremeters}
        className={className}
        onClick={handleClick()}
      >
        {content}
      </UsedTag>
    )
  }

  getFilteredfeaturesBy(features, type) {
    return features.filter(feature => feature.type === type);
  }

  getMixitArtLink(slug) {
    const { host } = window.location;
    const link = `https://art.${host}/product/${slug}`;

    return link;
  }

  handleRemoveButtonClick = e => {
    e.preventDefault();

    const { onRemove } = this.props;
    if (typeof onRemove === 'function') {
      onRemove.apply(this);
    }
  };
}

const ProductPreviewWithRouter = withRouter(ProductPreview);

export default ProductPreviewWithRouter;
