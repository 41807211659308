import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { getIndexLink } from 'routes/links';

import './quarantin-logo.css';

const QuarantinLogo = () => {
  return (
    <Link to={getIndexLink()} className="MixitLogo-link">
      <div className="QuarantinLogo">
        {/* <div className="QuarantinLogo-absolutePoitionWrapper"> */}
        <div className="QuarantinLogo-content">
          <div className={cn('QuarantinLogo-itemPink', 'QuarantinLogo-item')} />
          <div className={cn('QuarantinLogo-itemYellow', 'QuarantinLogo-item')} />
          <div className={cn('QuarantinLogo-itemBlue', 'QuarantinLogo-item')} />
          <div className={cn('QuarantinLogo-itemViolet', 'QuarantinLogo-item')} />
          <div className={cn('QuarantinLogo-inclineLeft', 'QuarantinLogo-line')} />
          <div className={cn('QuarantinLogo-inclineRight', 'QuarantinLogo-line')} />
        </div>
        {/* </div> */}
      </div>
    </Link>
  );
};

export default QuarantinLogo;
