import React, {useRef} from 'react';
import { Link } from 'react-scroll';
import cn from "classnames"

import Container from 'modules/core/components/container/container';
import computeScrollIntoView from 'compute-scroll-into-view';


import useSticky from "./helpers/useSticky"

import './faq-navigation.css';

export default function FAQNavigation({ list, isMobile }) {

  const navigationElement = useRef(null);
  const { isSticky, element } = useSticky();

  return (
    <div ref={element} className={cn("FAQNavigation", {
      "FAQNavigation-isSticky": isSticky
    })}>
      <Container>
        <div className="FAQNavigation-container" ref={navigationElement}>
          <ul className="FAQNavigation-list" >
            {list.map(category => (
              <li key={category.slug} className="FAQNavigation-item" >
                <Link
                  className={'FAQNavigation-anchor'}
                  activeClass="FAQNavigation-anchor--isActive"
                  to={category.slug}
                  offset={isMobile ? -90 : -100}
                  duration={300}
                  isDynamic
                  hashSpy
                  smooth
                  onClick={handleElementClick}
                  spy>
                  <span className="FAQNavigation-anchorText">{category.categoryTitle}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );

  function handleElementClick({target}) {

    const actions = computeScrollIntoView(target, {
      boundary: navigationElement.current,
      inline: 'center',
      // scrollMode: 'if-needed'
    });
    actions.forEach(({ el, top, left }) => {
      el.scrollTop = top;
      el.scrollLeft = left;
    });
  }
}
