import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as PROFILE from '../types/profile';
import * as DIALOG_TYPES from 'modules/core/ducks/types/dialogs';

const reducer = createReducer(
  /** изначальная загрузка данных (ответ с данными) */
  on(PROFILE.PHYSICAL_TRAITS_INITIAL_RESPONSE, (state, value) => {
    return {
      ...state,
      value,
      isInitialLoading: false
    };
  }),
  /** запрос на изменения данных */
  on(PROFILE.PHYSICAL_TRAITS_CHANGE, state => {
    return { ...state, isChangesLoading: false, errorMessage: '' };
  }),
  /** сохраняем выбранные параметры перед отправкой запросов на изменения */
  on(PROFILE.PHYSICAL_TRAITS_TEMPORARY_SAVE, (state, value) => {
    return { ...state, value, isChangesLoading: true };
  }),
  /** ответ с измененными данными по изменению */
  on(PROFILE.PHYSICAL_TRAITS_RESPONSE, (state, value) => {
    return {
      ...state,
      value,
      isChangesLoading: false,
      confirmDialogOpened: true
    };
  }),
  /** если произошла ошибка при запросе на изменение */
  on(PROFILE.PHYSICAL_TRAITS_ERROR, (state, message) => {
    return { ...state, isChangesLoading: false, errorMessage: message };
  }),
  // /** если фактически изменений не произошло, запросы не отправились, отключаем лоадинг установленный на изменение */
  on(PROFILE.PHYSICAL_TRAITS_RESET_CHANGES_LOADING, state => {
    return { ...state, isChangesLoading: false };
  }),
  /** закрытие диалога уведомление изменения */
  on(PROFILE.PHYSICAL_TRAITS_CONFIRM_DIALOG_CLOSE, state => {
    return { ...state, confirmDialogOpened: false };
  }),

  on(DIALOG_TYPES.CONFIRM_PHYSICAL_TRAITS_CLOSE, state => {
    return { ...state, confirmDialogOpened: false };
  })
);

export default reducer({
  value: [],
  isInitialLoading: true,
  isChangesLoading: false,
  errorMessage: '',
  confirmDialogOpened: false
});

const getRoot = state => state.profile.physicalTraits;

export const getCheckedPhysicalTraits = createSelector(
  getRoot,
  root => root.value
);

export const getCheckedValues = createSelector(
  getCheckedPhysicalTraits,
  checkedPhysicalTraitsList => checkedPhysicalTraitsList.map(item => item.value)
);

export const getIsInitialLoading = createSelector(
  getRoot,
  root => root.isInitialLoading
);

export const getIsChangesLoading = createSelector(
  getRoot,
  root => root.isChangesLoading
);

export const getErrorMessage = createSelector(
  getRoot,
  root => root.errorMessage
);

export const getIsConfirmDialogOpened = createSelector(
  getRoot,
  root => root.confirmDialogOpened
);
