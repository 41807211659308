import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';

import isFunction from 'modules/utils/is-function';
import Form from 'modules/form/components/form/form';
import Input from 'modules/form/components/input/input';
import InputPhoneWithAreaCode from 'modules/core/components/input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';

import Field from 'modules/form/components/field/field';
import Button from 'modules/core/components/button/button';
import FormGroup from 'modules/form/components/form-group/form-group';
// import IconContainer from 'modules/core/components/icon-container/icon-container';
// import IconCalendar from 'modules/core/components/icons/icon-calendar/icon-calendar';

import './mobile-profile-form.css';

const { string, func, object, shape } = PropTypes;

class MobileProfileForm extends Component {
  static propTypes = {
    profile: shape({
      fullName: string,
      phone: string,
      birthDate: string,
      address: {
        street: string,
        building: string,
        apartment: string
      }
    }),
    errors: object,
    touched: object,
    onCancel: func,
    onChange: func,
    onSubmit: func,
    onBlur: func
  };

  render() {
    const {
      onCancel,
      errors,
      touched,
      onChange,
      onSubmit,
      onBlur,
      profile,
      isPhoneEditable,
      onCallbackDialogOpen
    } = this.props;

    return (
      <Form className="MobileProfileForm" onSubmit={onSubmit}>
        <Form.Part label="Контактные данные">
          <Form.Row>
            <Field label="Имя и фамилия" error={touched.fullName && errors.fullName}>
              <Input
                name="fullName"
                value={profile.fullName}
                size="small"
                state={touched.fullName && errors.fullName && 'error'}
                onChange={onChange}
                onBlur={onBlur}
              />
            </Field>
          </Form.Row>

          <Form.Row labelTop>
            <Field label="Номер телефона" error={touched.phone && errors.phone}>
              <FormikField
                name="phone"
                render={({ field, form }) => (
                  <InputPhoneWithAreaCode
                    forceDropdown
                    codeList={areaCodes}
                    name={field.name}
                    value={field.value}
                    size="small"
                    onBlur={onBlur}
                    state={touched.phone && errors.phone && 'error'}
                    disabled={!isPhoneEditable}
                    onChange={this.handlePhoneChange(form)(field.name)({ onChange })}
                  />
                )}
              />
            </Field>

            {!isPhoneEditable && (
              <div className="MobileProfileForm-editPhoneTip">
                Вы не можете редактировать телефон, так как он привязан к бонусной карте.
                <span className="MobileProfileForm-editPhoneSeparator" role="presentation" />
                Чтобы изменить его,{' '}
                <button
                  type="button"
                  onClick={onCallbackDialogOpen}
                  className="MobileProfileForm-editPhoneButton"
                >
                  свяжитесь с нашим оператором
                </button>
              </div>
            )}
          </Form.Row>
        </Form.Part>

        <Form.Part label="Адрес доставки">
          <Form.Row>
            <Field
              label="Улица"
              error={
                touched.address && touched.address.street && errors.address && errors.address.street
              }
            >
              <Input
                name="address.street"
                value={profile.address.street}
                size="small"
                state={
                  touched.address &&
                  touched.address.street &&
                  errors.address &&
                  errors.address.street &&
                  'error'
                }
                onChange={onChange}
                onBlur={onBlur}
              />
            </Field>
          </Form.Row>
          <Form.Row labelTop>
            <FormGroup>
              <FormGroup.ItemWide>
                <Field
                  label="Дом, строение"
                  error={
                    touched.address &&
                    touched.address.building &&
                    errors.address &&
                    errors.address.building
                  }
                >
                  <Input
                    name="address.building"
                    value={profile.address.building}
                    size="small"
                    state={
                      touched.address &&
                      touched.address.building &&
                      errors.address &&
                      errors.address.building &&
                      'error'
                    }
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Field>
              </FormGroup.ItemWide>
              <FormGroup.ItemWide>
                <Field label="Квартира/офис">
                  <Input
                    name="address.apartment"
                    value={profile.address.apartment}
                    size="small"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Field>
              </FormGroup.ItemWide>
            </FormGroup>
          </Form.Row>
        </Form.Part>
        <Form.Part label="Дата рождения">
          <Form.Row>
            <Field>
              <Input
                name="birthDate"
                value={profile.birthDate}
                type="date"
                size="small"
                placeholder="хх.хх.ххх"
                onChange={onChange}
                onBlur={onBlur}
                nocontrols
                // appended={
                //   <div className="MobileProfileForm-iconHolder">
                //     <IconContainer size="small">
                //       <IconCalendar />
                //     </IconContainer>
                //   </div>
                // }
              />
            </Field>
          </Form.Row>
          <Form.ActionRow>
            <FormGroup center>
              <FormGroup.ItemWide>
                <Button title="Отменить" size="small" expanded onClick={onCancel} />
              </FormGroup.ItemWide>
              <FormGroup.ItemWide>
                <Button type="submit" title="Применить" size="small" variant="primary" expanded />
              </FormGroup.ItemWide>
            </FormGroup>
          </Form.ActionRow>
        </Form.Part>
      </Form>
    );
  }

  handleNameChange = e => {
    const { value } = e.target;
    const { onNameChange } = this.props;

    if (!isFunction(onNameChange)) {
      return;
    }
    onNameChange(value);
  };

  handlePhoneChange = form => name => ({ onChange }) => value => {
    const { onPhoneChange } = this.props;
    const _value = '+' + value.replace(/\D/g, '');
    form.setFieldValue(name, _value);

    if (!isFunction(onChange)) {
      return;
    }
    onChange(_value);

    if (!isFunction(onPhoneChange)) {
      return;
    }
    onPhoneChange(_value);
  };

  handleStreetChange = e => {
    const { value } = e.target;
    const { onStreetChange } = this.props;

    if (!isFunction(onStreetChange)) {
      return;
    }
    onStreetChange(value);
  };

  handleBuildingChange = e => {
    const { value } = e.target;
    const { onBuildingChange } = this.props;

    if (!isFunction(onBuildingChange)) {
      return;
    }
    onBuildingChange(value);
  };

  handleApartmentChange = e => {
    const { value } = e.target;
    const { onApartmentChange } = this.props;

    if (!isFunction(onApartmentChange)) {
      return;
    }
    onApartmentChange(value);
  };

  handleBirthDateChange = e => {
    const { value } = e.target;
    const { onBirthDateChange } = this.props;

    if (!isFunction(onBirthDateChange)) {
      return;
    }
    onBirthDateChange(value);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;

    if (!isFunction(onSubmit)) {
      return;
    }
    onSubmit();
  };
}

export default MobileProfileForm;
