import React, { Component } from 'react';
import './main-tabs.css';
import cn from 'classnames';

import Tab from './tab';

const arrowLeft = 37;
const arrowUp = 38;
const arrowRight = 39;
const arrowDown = 40;

class MainTabs extends Component {
  static Tab = Tab;
  static defaultProps = {
    align: 'left'
  };

  state = {
    currentTab: this.props.currentTab || 0
  };

  tabElements = [];

  render() {
    const { alignment } = this.props;

    return (
      <div
        className={cn('MainTabs', `MainTabs--titlesAlignment-${alignment}`)}
        role="tablist"
        onKeyDown={this.handleKeyDown}
      >
        <div className="MainTabs-list">{this.renderTabs()}</div>
        <div className="MainTabs-panel" role="tabpanel">
          {this.getTabContent()}
        </div>
      </div>
    );
  }

  renderTabs() {
    const { children } = this.props;
    return React.Children.map(
      children,
      (element, index) =>
        element &&
        React.cloneElement(element, {
          onClick: this.handleTabClick(index),
          tabIndex: this.getTabIndex(index),
          isActive: this.isActive(index),
          buttonRef: element => {
            this.tabElements[index] = element;
          }
        })
    );
  }

  getTabContent() {
    const { currentTab } = this.state;
    const { children } = this.props;
    return children[currentTab].props.panel;
  }

  handleKeyDown = e => {
    switch (e.keyCode) {
      case arrowLeft:
      case arrowUp:
        e.preventDefault();
        this.goToPreviousTab();
        break;
      case arrowRight:
      case arrowDown:
        e.preventDefault();
        this.goToNextTab();
        break;
      default:
        break;
    }
  };

  goToNextTab() {
    this.setTab(this.state.currentTab + 1);
  }

  goToPreviousTab() {
    this.setTab(this.state.currentTab - 1);
  }

  setTab(number) {
    const currentTab = this.normalizeCurrentTab(number);
    this.setState({
      currentTab
    });
    this.focusTab(currentTab);
  }

  normalizeCurrentTab(number) {
    const { children } = this.props;
    return (number + children.length) % children.length;
  }

  focusTab(number) {
    this.tabElements[number].focus();
  }

  handleTabClick = index => () => {
    this.setState({
      currentTab: index
    });
  };

  getTabIndex(index) {
    const { currentTab } = this.state;
    return index === currentTab ? 0 : -1;
  }

  isActive(index) {
    const { currentTab } = this.state;
    return index === currentTab;
  }
}

export default MainTabs;
