import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconCross } from 'modules/core/components/icons';

import Timer from './helpers/timer';

import './toast.css';

const { bool, string, func, oneOf, any, number, element, arrayOf, shape } = PropTypes;

class Toast extends Component {
  static propTypes = {
    id: string.isRequired,
    title: string,
    state: oneOf(['neutral', 'success', 'fail', 'loading', 'notification']),
    content: any,
    delay: number,
    icon: element,
    actionList: arrayOf(
      shape({
        title: string.isRequired,
        type: string.isRequired,
        variant: oneOf(['secondary', 'primary']).isRequired
      })
    ),
    isClosable: bool,

    /** Флаг паузы таймера по наведению */
    isPauseOnHover: bool,

    /** Обработчик функции нажатия на кнопку действия */
    onActionTrigger: func,

    onClose: func.isRequired
  };

  render() {
    const { state, icon, title, isClosable, content, actionList } = this.props;

    return (
      <div
        className={cn('Toast', `Toast--state-${state}`, {
          'Toast--withIcon': icon
        })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {isClosable && (
          <div className="Toast-buttonCloseHolder">
            <button className="Toast-buttonClose" onClick={this.handleButtonCloseClick}>
              <IconCross />
            </button>
          </div>
        )}

        <div className="Toast-header">
          {icon && (
            <div className="Toast-icon">
              <IconContainer size="small">{icon}</IconContainer>
            </div>
          )}

          {title && (
            <h3 className="Toast-title">
              <span className="Toast-titleText">{title}</span>
            </h3>
          )}
        </div>

        {content && <div className="Toast-content">{content}</div>}

        {actionList && (
          <ul className="Toast-actionList">{actionList.map(this.renderActionItem)}</ul>
        )}
      </div>
    );
  }

  componentDidMount() {
    const { delay, onClose } = this.props;
    if (delay) {
      this.timer = new Timer(delay, onClose);
      this.closeOnTimer();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      this.timer.stop();
      delete this.timer;
    }
  }

  renderActionItem = (item, index) => {
    return (
      <li key={index} className="Tosat-actionItem">
        <button
          className={cn('Toast-action', `Toast-action--variant-${item.variant}`)}
          onClick={this.handleButtonActionClick(item)}
        >
          {item.title}
        </button>
      </li>
    );
  };

  closeOnTimer() {
    this.timer.start();
  }

  pauseClosingOnTimer() {
    this.timer.pause();
  }

  resumeClosingOnTimer() {
    this.timer.resume();
  }

  handleMouseEnter = () => {
    const { delay, isPauseOnHover } = this.props;

    if (!isPauseOnHover || !delay) {
      return;
    }

    this.pauseClosingOnTimer();
  };

  handleMouseLeave = () => {
    const { delay, isPauseOnHover } = this.props;

    if (!isPauseOnHover || !delay) {
      return;
    }

    this.resumeClosingOnTimer();
  };

  handleButtonCloseClick = () => {
    const { onClose } = this.props;

    onClose();
  };

  handleButtonActionClick = action => () => {
    const { onActionTrigger } = this.props;

    onActionTrigger(action);
  };
}

export default Toast;
