import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import isFunction from 'modules/utils/is-function';
import { throttle, debounce } from 'lodash/function';
import { Waypoint } from 'react-waypoint';
import { connect } from 'react-redux';
import ProductActions from '../ducks/products/actions';

const Context = React.createContext();

class ProductAnalyticsManagerBase extends Component {
  elements = {};
  revealedBuffer = [];

  state = {
    positions: {}
  };

  render() {
    const { positions } = this.state;
    const { children } = this.props;

    return (
      <Context.Provider
        value={{
          register: this.register,
          unregister: this.unregister,
          reveal: this.handleReveal,
          click: this.props.click,
          positions
        }}
      >
        {children}
      </Context.Provider>
    );
  }

  register = (slug, element) => {
    if (this.elements[slug] && this.elements[slug] === element) {
      return;
    }

    this.elements[slug] = element;
    this.throttledRecalc();
  };

  unregister = slug => {
    delete this.elements[slug];
    this.throttledRecalc();
  };

  _recalc() {
    const screen = [];

    Object.entries(this.elements).forEach(([slug, element]) => {
      screen.push({
        slug,
        position: this.getPositionOnScreen(element)
      });
    });

    const sortedScreen = screen.sort((itemA, itemB) => {
      if (itemA.position[1] === itemB.position[1]) {
        return itemA.position[0] - itemB.position[0];
      }

      return itemA.position[1] - itemB.position[1];
    });

    const positions = sortedScreen.reduce((all, product, index) => {
      return {
        ...all,
        [product.slug]: index
      };
    }, {});

    this.props.update(positions);
    this.setState({ positions });
  }

  throttledRecalc = throttle(this._recalc, 2000);

  _reveal = () => {
    const revealed = this.revealedBuffer;
    this.revealedBuffer = [];
    this.props.reveal(revealed);
  };

  debouncedReveal = debounce(this._reveal, 1000);

  handleReveal = (slug, listId, isGift, position) => {
    this.revealedBuffer.push({ slug, listId, isGift, position });
    this.debouncedReveal();
  };

  getPositionOnScreen(element) {
    if (!element) {
      return [0, 0];
    }

    const { left, top } = element.getBoundingClientRect();

    return [left, top];
  }
}

export const ProductAnalyticsManager = connect(
  null,
  {
    reveal: ProductActions.reveal,
    click: ProductActions.click,
    update: ProductActions.positionsUpdate
  }
)(ProductAnalyticsManagerBase);

const useCombinedRefs = (...refs) =>
  React.useCallback(
    element =>
      refs.forEach(ref => {
        if (!ref) {
          return;
        }

        // Ref can have two types - a function or an object. We treat each case.
        if (typeof ref === 'function') {
          return ref(element);
        }

        // As per https://github.com/facebook/react/issues/13029
        // it should be fine to set current this way.
        ref.current = element;
      }),
    [refs]
  );

const WaypointProvideProduct = ({ productRef, innerRef, render, handleClick }) => {
  const combinedProductRef = useCombinedRefs(productRef, innerRef);

  return render({
    handleClick: handleClick,
    productRef: combinedProductRef
  });
};

class ProductAnalyticsProvider extends Component {
  static propTypes = {
    listId: string,
    productId: string,
    isGift: bool
  };

  static contextType = Context;

  revealed = false;
  productRef = React.createRef();

  componentDidMount() {
    if (!this.context) {
      /** for using Product in Storybook, because it does't have context   */
      return;
    }
    const { register } = this.context;
    register(this.props.productId, this.productRef.current);
  }

  componentWillUnmount() {
    if (!this.context) {
      /** for using Product in Storybook, because it does't have context   */
      return;
    }
    const { unregister } = this.context;
    unregister(this.props.productId);
  }

  render() {
    if (!isFunction(this.props.render)) {
      return null;
    }

    // const position = this.context.positions[this.props.productId];
    // console.log(position);

    return (
      <Waypoint onEnter={this.handleWaypointEnter}>
        <WaypointProvideProduct
          render={this.props.render}
          productRef={this.productRef}
          handleClick={this.handleClick}
        />
      </Waypoint>
    );
  }

  handleWaypointEnter = () => {
    if (this.revealed) {
      return;
    }
    this.handleView();
  };

  handleView = () => {
    const position = this.context.positions[this.props.productId];
    this.context.reveal(this.props.productId, this.props.listId, this.props.isGift, position);
    this.revealed = true;
  };

  handleClick = callback => e => {
    const position = this.context.positions[this.props.productId];
    this.context.click(this.props.productId, this.props.listId, position);

    if (isFunction(callback)) {
      callback(e);
    }
  };
}

export default ProductAnalyticsProvider;
