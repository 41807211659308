import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';

import mediaShape from 'modules/product/prop-types/media-shape';
import isFunction from 'modules/utils/is-function';

import Picture from './picture';
import Video from './video';
import Thumb from './thumb';

import { MEDIA_TYPE } from './constants';

import './media.css';

class Media extends Component {
  static propTypes = {
    /**
     * Входящие данные - изображение или видео.
     * В случае с последним проверяем входящие данные на наличие картинки-заставки.
     * Если таковая есть, то предполагаем, что размер у нее такой же, как у видео.
     */
    media: mediaShape,

    nocaption: PropTypes.bool,

    ratio: PropTypes.number,

    stretch: PropTypes.oneOf(['none', 'vertical', 'horizontal'])
  };

  static defaultProps = {
    stretch: 'none',
    local: false
  };

  static Thumb = Thumb;

  state = {
    isLoaded: false,
    isError: false,
    inScreen: false,
    fetchLocal: this.props.local
  };

  render() {
    const {
      media,
      nocaption,
      stretch,
      onLoad,
      ratio,
      lazy,
      noControls,
      noButtonPlay,
      muted,
      active,
      onCanPlay,
      noClickableVideo,
      setVideoState,
      darkened,
      ...rest
    } = this.props;
    const { isLoaded, inScreen, fetchLocal } = this.state;

    const ratioStyle = ratio ? { paddingTop: `${ratio * 100}%` } : null;
    const wait = lazy && !inScreen;

    if (!media) {
      return null;
    }

    return (
      <Waypoint onEnter={this.handleWaypointEnter}>
        <figure
          className={classNames('Media', `Media--stretch-${stretch}`, {
            'Media--loaded': isLoaded,
            'Media--withRatio': ratio
          })}
          style={ratioStyle}
        >
          {media.type === MEDIA_TYPE.IMAGE && (
            <Picture
              sources={media.sources}
              alt={media.title}
              isLoaded={isLoaded}
              onLoad={this.handleLoad}
              onError={this.handleError}
              ratio={ratio}
              wait={wait}
              {...rest}
              local={fetchLocal}
            />
          )}

          {media.type === MEDIA_TYPE.VIDEO && (
            <Video
              source={media.sources}
              cover={media.cover}
              isLoaded={isLoaded}
              alt={media.title}
              onLoad={this.handleLoad}
              noControls={noControls}
              noButtonPlay={noButtonPlay}
              muted={muted}
              active={active}
              onCanPlay={onCanPlay}
              noClickableVideo={noClickableVideo}
              setVideoState={setVideoState}
              darkened={darkened}
            />
          )}

          {!nocaption && <figcaption className="Media-caption">{media.title}</figcaption>}
        </figure>
      </Waypoint>
    );
  }

  handleError = () => {
    if (this.state.fetchLocal) {
      // try fetch by url if failed local link
      this.setState({
        isLoaded: false,
        isError: false,
        fetchLocal: false
      });
      return;
    }

    this.setState({
      isLoaded: true,
      isError: true
    });

    if (isFunction(this.props.onError)) {
      this.props.onError();
    }
  };

  handleLoad = () => {
    this.setState({
      isLoaded: true,
      isError: false
    });

    if (isFunction(this.props.onLoad)) {
      this.props.onLoad();
    }
  };

  handleWaypointEnter = () => {
    this.setState({
      inScreen: true
    });
  };
}

export default Media;
