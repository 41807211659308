import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-reset.css';

class IconReset extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 24 24" className="IconReset">
            <path d="M12 3.5c-2.3 0-4.5 1-6.1 2.6V4c0-.3-.2-.5-.5-.5s-.5.2-.5.5v3.5c0 .2.1.3.2.4 0 0 .1 0 .1.1h3.6c.3 0 .5-.2.5-.5S9.1 7 8.9 7H6.4C7.9 5.4 9.9 4.5 12 4.5c4.1 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5-7.5-3.4-7.5-7.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5z" />
          </Svg>
        );

      default:
        return;
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconReset;
