import * as TYPES from '../types/a-b-test-markers';
import { createAction } from 'modules/utils/dux';

export default {
  set({name, type}) {
    return createAction(TYPES.SET, {
      name, type
    });
  }
};
