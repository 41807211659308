import * as TYPES from '../types/password-change';
import { createAction } from 'modules/utils/dux';

export default {
  request({ currentPassword, newPassword }) {
    return createAction(TYPES.REQUEST, {
      currentPassword,
      newPassword
    });
  },

  response() {
    return createAction(TYPES.RESPONSE);
  },

  error({ message }) {
    return createAction(TYPES.ERROR, {
      message
    });
  },

  closeToast() {
    return createAction(TYPES.CLOSE_TOAST);
  },

  resetErrorMessage() {
    return createAction(TYPES.RESET_ERROR_MESSAGE);
  }
};
