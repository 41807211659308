function initItemRequest(slug) {
  return function _getItemRequest() {
    return {
      method: 'GET',
      path: `/products/${slug}`
    };
  };
}

function initDetailsRequest(slug) {
  return function _getDetailsRequest() {
    return {
      method: 'GET',
      path: `/products/${slug}/details`
    };
  };
}

export default function initProductsRequest(createRequest) {
  return function _getProductsRequest(slug) {
    return {
      item: createRequest(initItemRequest(slug)),
      details: createRequest(initDetailsRequest(slug)),
      reviews: () => ({
        add: createRequest(data => {
          return {
            method: 'POST',
            path: `/products/${slug}/details/reviews`,
            data
          };
        })
      })
    };
  };
}
