import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LocationActions from 'modules/geography/ducks/actions/location';
import * as fromCityList from 'modules/geography/ducks/city-list';

import { actions as Dialogs } from '../ducks/dialogs';

import CityChoiceBottomSheetButton from '../components/city-choice-bottom-sheet/button';

const mapStateToProps = createStructuredSelector({
  city: fromCityList.getCityById
});

const mapDispatchToProps = {
  chooseCity: LocationActions.chooseCity,
  close: Dialogs.close
};

class CityChoiceBottomSheetButtonContainer extends Component {
  render() {
    const { city, ...rest } = this.props;

    return <CityChoiceBottomSheetButton city={city} onClick={this.handleButtonClick} {...rest} />;
  }

  handleButtonClick = city => {
    const { onChoose } = this.props;
    onChoose(city);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityChoiceBottomSheetButtonContainer);
