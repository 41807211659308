import { call, take, cancel, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

export default function takeDebounced(action, handler, debounce = 500) {
  const makeWaitBeforeCall = function(task, delaySize) {
    return function*(...args) {
      yield delay(delaySize);
      yield call(task, ...args);
    };
  };

  return fork(function*() {
    let task = null;
    while (true) {
      yield take(action);
      if (task) {
        yield cancel(task);
      }

      task = yield fork(makeWaitBeforeCall(handler, debounce));
    }
  });
}
