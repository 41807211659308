import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-media';

import Button from 'modules/core/components/button/button';

import articleShape from '../../prop-types/article-shape';
import { getArticleLink } from 'routes/links';

import './article-banner.css';

class ArticleBanner extends Component {
  static propTypes = {
    article: articleShape
  };

  render() {
    const { title, description, cover } = this.props.article;

    const style = { backgroundImage: `url(${cover.sources[0].main.normal})` };
    return (
      <div className="ArticleBanner">
        <div className="ArticleBanner-background" style={style} />
        <div className="ArticleBanner-contentHolder">
          <div className="ArticleBanner-content">
            <h2 className="ArticleBanner-title">{title}</h2>
            <div className="ArticleBanner-description">{description}</div>
            <div className="ArticleBanner-toArticle">{this.renderButton()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <Media query="(max-width: 840px)">
        {matches =>
          matches ? (
            <Button
              title="Читать"
              variant="secondary"
              component={Link}
              inverted
              href={getArticleLink(this.props.article)}
              size="small"
            />
          ) : (
            <Button
              title="Читать"
              variant="secondary"
              component={Link}
              inverted
              to={getArticleLink(this.props.article)}
            />
          )
        }
      </Media>
    );
  }
}

export default ArticleBanner;
