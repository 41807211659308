import React, { Component, Children } from 'react';
import { getDistanceSq } from './utils/distance';
import { getEasings } from './utils/easing';
import Slide from './slide';

const PIXEL_RATIO = (__BROWSER__ && window.devicePixelRatio) || 1;
const EDGE_EASING_SIZE = 120;
const SAFE_ZONE = 80 / PIXEL_RATIO;
const MOUSE_BUTTON_LEFT = 0;

const [makeStartEasing, makeEndEasing] = getEasings(EDGE_EASING_SIZE);

function clearSelection() {
  if (document.selection) {
    // IE?
    document.selection.empty();
    return;
  }

  if (window.getSelection && window.getSelection().empty) {
    // Chrome
    window.getSelection().empty();
    return;
  }

  if (window.getSelection && window.getSelection().removeAllRanges) {
    // Firefox
    window.getSelection().removeAllRanges();
    return;
  }
}

class Frame extends Component {
  state = {
    startPoint: {
      x: 0,
      y: 0
    },
    endPoint: {
      x: 0,
      y: 0
    },
    scrolling: false,
    mousedown: false,
    dragging: false,
    current: 0
  };

  render() {
    const style = this.getStyles();
    const position = this.getPosition();
    const { container, children, draggable, slidesCount } = this.props;

    const slideWidth = 100 / slidesCount;
    const itemWidth = this.getItemWidth();

    const childrenResult = Children.map(children, (child, index) => {
      const inProgress = Math.min(Math.max((position - (index - 1) * itemWidth) / itemWidth, 0), 1);

      const outProgress = 1 - Math.min(Math.max((position - index * itemWidth) / itemWidth, 0), 1);

      const progress = Math.min(inProgress, outProgress);

      return <Slide key={index} child={child} width={slideWidth} progress={progress} />;
    });

    if (!draggable) {
      return React.cloneElement(container, {
        style,
        children: childrenResult
      });
    }

    return React.cloneElement(container, {
      style,
      onTouchStart: this.handleTouchStart,
      onTouchEnd: this.handleTouchEnd,
      onTouchMove: this.handleTouchMove,
      onMouseDown: this.handleMouseDown,
      onMouseUp: this.handleMouseUp,
      onMouseLeave: this.handleMouseLeave,
      onMouseMove: this.handleMouseMove,
      onClick: this.handleClick,
      onDragStart: this.handleDragStart,
      children: childrenResult
    });
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.handleWindowTouchMove, {
      passive: false
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleWindowTouchMove, {
      passive: false
    });
    window.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this._scrollTimer);
  }

  handleWindowTouchMove = event => {
    if (!this.isDragging()) {
      return;
    }
    event.preventDefault();
  };

  handleScroll = () => {
    if (!this._listenScroll || this.state.scrolling) {
      return;
    }

    this.setState({
      scrolling: true
    });
  };

  scrollListen() {
    this._listenScroll = true;
  }

  scrollEnd() {
    this._listenScroll = false;

    clearTimeout(this._scrollTimer);

    this._scrollTimer = setTimeout(() => {
      this.setState({
        scrolling: false
      });
    }, 100);
  }

  isDragAllowed() {
    return !this.state.scrolling && this.isMouseDown();
  }

  getTransition() {
    const { duration, easing } = this.props;

    const transition = `transform ${duration}ms ${easing}`;
    const mousedownTransition = `transform 0ms ${easing}`;

    return this.isDragAllowed() ? mousedownTransition : transition;
  }

  getTransform(x = 0) {
    const position = this.getPosition();
    return `translate3d(${-Math.round(position)}px, 0, 0)`;
  }

  getPosition() {
    const { width, current } = this.props;

    const itemWidth = this.getItemWidth();
    const offset = current * itemWidth;

    if (!this.isDragAllowed()) {
      return offset;
    }

    const { slidesCount } = this.props;

    const workWidth = itemWidth * slidesCount - width;

    const startEasing = makeStartEasing();
    const endEasing = makeEndEasing(workWidth);

    const movement = this.getMovement();
    const position = offset + movement;

    if (position < 0) {
      return startEasing(position);
    }

    if (position > workWidth) {
      return endEasing(position);
    }

    return position;
  }

  getStyles() {
    const { draggable } = this.props;
    const transform = this.getTransform();
    const transition = this.getTransition();
    const cursor = draggable
      ? this.isDragAllowed()
        ? '-webkit-grabbing'
        : '-webkit-grab'
      : 'default';

    return {
      display: 'inline-flex',
      verticalAlign: 'top',
      transform,
      transition,
      cursor,
      WebkitTransition: transition,
      width: `${this.getFullWidth()}px`
    };
  }

  getItemWidth() {
    const { width, perPage } = this.props;
    return width / perPage;
  }

  getFullWidth() {
    const { slidesCount } = this.props;
    const itemWidth = this.getItemWidth();
    return itemWidth * slidesCount;
  }

  getMovement() {
    const { startPoint, endPoint } = this.state;
    return startPoint.x - endPoint.x;
  }

  getCurrentPage() {
    const { current } = this.props;
    const offset = this.getSafePageOffset();
    return current + offset;
  }

  getSafePageOffset() {
    const movement = this.getMovement();
    const itemWidth = this.getItemWidth();

    const relativeSafeZone = SAFE_ZONE / itemWidth;

    const direction = Math.sign(movement);
    const absoluteMovement = direction * movement;

    const offset = absoluteMovement / itemWidth;
    const offsetWhole = Math.floor(offset);
    const offsetFraction = offset - offsetWhole;

    if (offsetFraction - relativeSafeZone > 0) {
      return direction * (offsetWhole + 1);
    }

    return direction * offsetWhole;
  }

  setDraggingState(start, current) {
    if (!this.isDragging() && getDistanceSq(start, current) > Math.pow(10 * PIXEL_RATIO, 2)) {
      this.enableDragging();
    }
  }

  moveStart(point) {
    this.setState({
      startPoint: point,
      endPoint: point,
      mousedown: true
    });
  }

  move(point) {
    const { startPoint } = this.state;

    this.setState({
      endPoint: point
    });

    this.setDraggingState(startPoint, point);
  }

  moveEnd() {
    const currentPage = this.getCurrentPage();

    this.props.onChange({
      current: currentPage
    });

    this.setState({
      mousedown: false
    });

    this.disableDragging();
  }

  enableDragging() {
    clearTimeout(this._draggingTimer);
    clearSelection();
    this.setState({
      dragging: true
    });
  }

  disableDragging() {
    this._draggingTimer = setTimeout(() => {
      this.setState({
        dragging: false
      });
    }, 20);
  }

  isDragging() {
    const { dragging } = this.state;
    return dragging;
  }

  isMouseDown() {
    const { mousedown } = this.state;
    return mousedown;
  }

  handleClick = e => {
    if (this.isDragging()) {
      e.preventDefault();
      e.stopPropagation();

      if (e.nativeEvent) {
        e.nativeEvent.stopPropagation();
      }
    }
  };

  handleDragStart = e => {
    e.preventDefault();
  };

  handleTouchStart = e => {
    this.scrollListen();

    const point = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY
    };

    this.moveStart(point);
  };

  handleTouchEnd = e => {
    this.scrollEnd();
    this.moveEnd(this.state.endPoint);
  };

  handleTouchMove = e => {
    const point = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY
    };

    if (!this.isDragAllowed()) {
      this.moveStart(point);
      return;
    }

    this.move(point);
  };

  handleMouseDown = e => {
    if (e.button !== MOUSE_BUTTON_LEFT) {
      return;
    }

    const point = {
      x: e.pageX,
      y: e.pageY
    };

    this.moveStart(point);
  };

  handleMouseMove = e => {
    if (!this.isDragAllowed()) {
      return;
    }

    if (this.isDragging()) {
      e.preventDefault();
    }

    const point = {
      x: e.pageX,
      y: e.pageY
    };

    this.move(point);
  };

  handleMouseUp = e => {
    const { endPoint } = this.state;
    this.moveEnd(endPoint);
  };

  handleMouseLeave = e => {
    if (!this.isDragAllowed()) {
      return;
    }

    const endPoint = {
      x: e.pageX,
      y: e.pageY
    };

    this.moveEnd(endPoint);
  };
}

export default Frame;
