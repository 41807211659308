import React, { Component } from 'react';

import './icon-slider-arrow-right.css';

class SliderArrowRight extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="SliderArrowRight">
        <path d="M29.4 15L18.6 5.5c-.5-.5-1.4-.4-1.8.1s-.4 1.4.1 1.9l8.2 7.2H3.4c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h21.7l-8.2 7.2c-.5.5-.5 1.3-.1 1.8.5.5 1.2.5 1.8.1L29.4 17c.5-.5.6-1.3.1-1.8 0-.1 0-.1-.1-.2z" />
      </svg>
    );
  }
}

export default SliderArrowRight;
