import React, { Component, Fragment } from 'react';

import Field from 'modules/form/components/field/field';

import BottomSheet from '../bottom-sheet/bottom-sheet';
import Button from '../button/button';
import IconContainer from '../icon-container/icon-container';
import { IconSearch } from '../icons';

import CityChoiceBottomSheetButtonContainer from '../../containers/city-choice-bottom-sheet-button';

import './city-choice-bottom-sheet.css';
import { Input } from 'modules/form';
import CityChoiceBottomSheetButton from './button';

class CityChoiceBottomSheet extends Component {
  render() {
    const {
      list,
      currentId,
      suggestions,
      onFilterChange,
      onCityChoose,
      onClose,
      ...rest
    } = this.props;

    return (
      <BottomSheet
        header={
          <Field>
            <Input
              placeholder="Введите название города"
              noPrimary
              size="small"
              appended={
                <div className="CityChoiceBottomSheet-iconWrapper">
                  <IconContainer size="small">
                    <IconSearch />
                  </IconContainer>
                </div>
              }
              onChange={onFilterChange}
            />
          </Field>
        }
        footer={<Button title="Назад" size="small" onClick={() => onClose()} expanded />}
        onClose={onClose}
        {...rest}
      >
        <div className="CityChoiceBottomSheet">
          {suggestions.length > 0 && (
            <Fragment>
              <h4 className="CityChoiceBottomSheet-searchTitle">Города по запросу:</h4>
              <ul className="CityChoiceBottomSheet-list">
                {suggestions.map(city => {
                  return (
                    <li className="CityChoiceBottomSheet-item" key={city.id}>
                      <CityChoiceBottomSheetButton
                        city={city}
                        onClick={() => onCityChoose(city, city)}
                      />
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )}
          <ul className="CityChoiceBottomSheet-list">
            {list.map(id => (
              <li className="CityChoiceBottomSheet-item" key={id}>
                <CityChoiceBottomSheetButtonContainer
                  onClose={onClose}
                  onChoose={onCityChoose}
                  id={id}
                  isActive={id === currentId}
                />
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>
    );
  }
}

export default CityChoiceBottomSheet;
