import React, { Component } from 'react';
import Media from 'react-media';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRouterHistory, getRouterHistoryLength } from 'modules/core/ducks/router/selectors';

import PageCounter from "./page-counter"
// import Helmet from 'react-helmet';

// import shallowCompare from 'react-addons-shallow-compare';
import ShortListBasketProductsPanel from 'modules/core/containers/basket-preview-panel-container';
import ShortListBasketProductsPanelSection from 'modules/core/components/basket-preview-panel/basket-preview-panel-section-container';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import Container from '../container/container';
import CompactBreadcrumbs from '../compact-breadcrumbs/compact-breadcrumbs';
import Loader from '../loader/loader';
import NavigationBackButton from '../navigation-back-button/navigation-back-button';

import Banner from './banner';
import PageTitle from './page-title';
import PageDescription from './page-description';
import Content from '../content/content';

// import BlackFridayNotification from 'modules/core/components/black-friday-notification/black-friday-notification';
// import HolidaysNotification from 'modules/core/components/holidays-notification/holidays-notification';
// import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import './page.css';

const mapStateToProps = createStructuredSelector({
  routerHistory: getRouterHistory,
  historyLength: getRouterHistoryLength
});

const mapDispatchToProps = {};

class Wait extends Component {
  shouldComponentUpdate(nextProps) {
    const { on, ...rest } = this.props;

    if (on && !nextProps.on) {
      return true;
    }

    if (!on && nextProps.on) {
      this.replacedProps = rest;
      return true;
    }

    return !on;
  }

  render() {
    const { on, ...rest } = this.props;
    if (this.replacedProps && on) {
      const props = this.replacedProps;
      this.replacedProps = null;
      return this.props.render({ isWaiting: on, ...props });
    }
    return this.props.render({ isWaiting: on, ...rest });
  }
}
// class Wait extends Component {
//   render() {
//     return this.props.children;
//   }
// }

class BasePage extends Component {
  static Banner = Banner;
  static Title = PageTitle;
  static Description = PageDescription;

  static Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="Page-breadcrumbs">
        <Container>
          <Breadcrumbs pages={breadcrumbs} />
        </Container>
      </div>
    );
  };

  render() {
    const {
      title,
      banner,
      notification,
      breadcrumbs,
      isLoading,
      bannerDescription,
      bannerTitle,
      isGroup,
      children,
      bannerCounter
      // withoutSaleNotification
    } = this.props;    
    
    const { pathname } = this.props.location;
    
    const urlModificator = pathname.split('/')[1];
    const isAllowRenderBasketPannel = this.getIsAllowedRenderBasketPannel(urlModificator);
    
    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <section
            className={cn('Page', {
              [`Page--url-${urlModificator}`]: Boolean(urlModificator),
              'Page--url-index': !Boolean(urlModificator),
              'Page--noBreadcrumbs': !breadcrumbs,
              'Page--isLoading': isLoading,
              'Page--withBanner': Boolean(banner),
              'Page--withoutBanner': !Boolean(banner)
            })}
          >

            {/* <div className="Page-notification">
              <HolidaysNotification />
            </div> */}
            {breadcrumbs && isMobile && this.renderCompactBreadcrumbs()}

            {/* {!withoutSaleNotification && (
              // для отключения этой нотификации нужно так же убрать в index-page и в group-page
              <div className="Page-saleNotification">
                <HideErrorBoundary>
                  <BlackFridayNotification
                    expireData="2019-11-26T23:59:59+03:00"
                    endDate="2019-12-01T23:59:59+03:00"
                  />
                </HideErrorBoundary>
              </div>
            )} */}
            {Boolean(banner) && isAllowRenderBasketPannel && this.renderBasketProductsPanel()}

            {banner &&
              (isGroup ? (
                <Banner image={banner} title={bannerTitle} description={bannerDescription} />
              ) : (
                <Banner image={banner} title={title} description={bannerDescription} />
              ))}
            {notification && <div className="Page-notification">{notification}</div>}
            {!Boolean(banner) && isAllowRenderBasketPannel && this.renderBasketProductsPanel()}
            {breadcrumbs && !isMobile && this.renderBreadcrumbs()}
            {bannerCounter && bannerCounter.endDate && this.renderCounter(bannerCounter)}

            {this.getTitle()}

            {this.getDescription()}

            {isLoading ? (
              <div className="Page-loader">
                <Loader />
              </div>
            ) : (
              <>
                {/* <Helmet>
                  <script src="//code-ya.jivosite.com/widget/Uk3wSnLQ5R" async></script>
                </Helmet> */}
                <div className="Page-content">{children}</div>
              </>
            )}
          </section>
        )}
      </Media>
    );
  }

  renderCounter =(bannerCounter)=> {
    console.log("renderCounter -> bannerCounter", bannerCounter)
    return (
      <PageCounter endDate={bannerCounter.endDate}/>
    )
  }

  getIsAllowedRenderBasketPannel = (urlModificator) => {
    return urlModificator !== "basket" && urlModificator !== "ordering"
  }

  renderBasketProductsPanel = () => {
    return (
      <ShortListBasketProductsPanelSection>
        <ShortListBasketProductsPanel />
      </ShortListBasketProductsPanelSection>
    )
  }

  renderBreadcrumbs() {
    const { breadcrumbs } = this.props;
    return (
      <div className="Page-breadcrumbs">
        <Container>
          <Breadcrumbs pages={breadcrumbs} />
        </Container>
      </div>
    );
  }

  renderCompactBreadcrumbs() {
    const { breadcrumbs, withBackButton, historyLength } = this.props;
    // console.log("renderCompactBreadcrumbs -> this.props.history.length", this.props.history.length)
    if (withBackButton && historyLength > 1) {
      return (
        <div className="Page-breadcrumbs">
          <NavigationBackButton />
        </div>
      );
    }

    const previousItem = breadcrumbs[breadcrumbs.length - 2];

    return (
      <div className="Page-breadcrumbs">
        <CompactBreadcrumbs
          name={previousItem.name}
          link={previousItem.link}
          absoluteLink={previousItem.absoluteLink}
        />
      </div>
    );
  }

  getTitle() {
    const { banner, title, bannerTitle } = this.props;

    const isTitleExist = !banner && (title || bannerTitle);
    const finalyTitle = bannerTitle || title || '';

    return <Container>{isTitleExist && <h1 className="Page-title">{finalyTitle}</h1>}</Container>;
  }

  getDescription() {
    const { description } = this.props;

    return (
      !!description && (
        <Container>
          <div className="Page-description">
            <Content html={description} customTextProps />
          </div>
        </Container>
      )
    );
  }
}

const enhancedPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(BasePage))

export const Page = enhancedPage;

export default function WaitingPage({ isLoading, ...props }) {
  return (
    <Wait
      on={isLoading}
      {...props}
      render={({ isWaiting, ...props }) => <Page isLoading={isWaiting} {...props} />}
    />
  );
}

// export default Page;
