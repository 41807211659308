import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { findAll } from 'highlight-words-core';

import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';
import Price from 'modules/ordering/components/price-formatted/price-formatted';

import { getProductLink } from 'routes/links';

import './product.css';

class Product extends Component {
  render() {
    const { product, query, onClose } = this.props;
    return (
      <ProductAnalyticsProvider
        productId={product.slug}
        listId="search-field"
        render={({ productRef, handleClick }) => (
          <div className="SearchOverlay_Product" ref={productRef}>
            <div className="SearchOverlay_Product-text">
              <Link
                to={getProductLink(product)}
                className="SearchOverlay_Product-description"
                onClick={handleClick(onClose)}
              >
                {this.highlight(product.description, query)}
              </Link>
              <br />
              <span className="SearchOverlay_Product-title">
                {this.highlight(product.title, query)}
              </span>
            </div>

            <div className="SearchOverlay_Product-price">
              {product.hasDiscount && <Price number={product.price.default} variant="previous" />}{' '}
              <Price
                number={product.price.current}
                variant={product.hasDiscount ? 'discount' : 'default'}
              />
            </div>
          </div>
        )}
      />
    );
  }

  highlight(text, query) {
    const chunks = findAll({
      textToHighlight: text,
      searchWords: query.split(/\s/)
    });

    return chunks.map(({ start, end, highlight }) => {
      const chunk = text.substr(start, end - start);
      return highlight ? <mark>{chunk}</mark> : chunk;
    });
  }
}

export default Product;
