import PropTypes from 'prop-types';

import mediaShape from './media-shape';

const { number, string, arrayOf, shape } = PropTypes;

export default shape({
  id: number.isRequired,
  product: number.isRequired,
  about: string,
  consistency: shape({
    ingredients: arrayOf(
      shape({
        id: number,
        title: string,
        name: string,
        list: arrayOf(number),
        color: string
      })
    ),
    primary: arrayOf(number)
  }),
  usage: shape({
    description: string,
    media: mediaShape
  }),
  storageConditions: arrayOf(
    shape({
      title: string,
      description: string,
      iconPath: string
    })
  ),
  similar: arrayOf(number),
  related: arrayOf(number)
});
