import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as fromNavigations from 'modules/core/ducks/navigations';
import Loader from 'modules/core/components/loader/loader';

import CategoryPanel from '../category-panel/category-panel';

import SelectItem from './select-item/select-item';
import FilterItemContainer from '../../containers/filter-item';

import { getCategoryLink, getGroupLink } from 'routes/links';


const mapStateToProps = createStructuredSelector({
  categories: fromNavigations.getCategotyFilterPanel,
});


const safeComplectSlug = 'safe-complect';

class CategoryFilterPanel extends Component {
  render() {
    const {
      history,
      currentCategory,
      currentNested,
      nestedList,
      filters,
      appliedFilters,
      onFilterChange,
      categories
    } = this.props;

    const safeComplectItem = {
      id: 'safe-complect',
      name: 'safe-complect',
      slug: 'safe-complect',
      title: 'Защитные комплекты',
      amount: 6
    };

    if(!categories || !categories.list ) {
      return (
      <div style={{ position: 'relative', paddingTop: '100px' }}>
        <Loader size="small" secondary />
      </div>
      )
    }

    return (
      <CategoryPanel hideBackButton={history.length <= 1} onBackButtonClick={this.handleBackClick}>
        <SelectItem
          current={currentCategory}
          options={categories.list}
          renderItem={({ item, onClick, ...props }) => {
            return <Link to={getCategoryLink(item)} {...props} />;
          }}
        />

        {nestedList && (
          <SelectItem
            current={currentNested}
            options={[...nestedList, safeComplectItem]}
            renderItem={({ item, onClick, ...props }) => {
              let link = getCategoryLink(currentCategory, item);

              if (item.slug === safeComplectSlug) {
                link = getGroupLink(item);
              }

              return <Link to={link} {...props} />;
            }}
          />
        )}

        {filters &&
          filters.map((id, index) => (
            <FilterItemContainer
              key={id}
              align={this.checkAlign(index)}
              id={id}
              appliedFilters={appliedFilters}
              withoutAmount={!!currentNested.slug}
              onFilterChange={onFilterChange}
            />
          ))}
      </CategoryPanel>
    );
  }

  checkAlign(index) {
    const { filters } = this.props;

    return index > filters.length - 3 ? 'right' : 'left';
  }

  handleBackClick = () => {
    const { history } = this.props;
    if (history.length) {
      history.goBack();
    }
  };
}

export default withRouter(connect(mapStateToProps)(CategoryFilterPanel));
