import * as TYPES from '../ducks/types';
import { createAction } from 'modules/utils/dux';

export default {
  request(queryParams) {
    return createAction(TYPES.REQUEST, { queryParams });
  },

  response(massMedia) {
    return createAction(TYPES.RESPONSE, { massMedia });
  },

  error(error) {
    return createAction(TYPES.ERROR, { error });
  }
};
