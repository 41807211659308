export default {
  138: {
    id: 138,
    name: 'PURPOSES',
    title: 'Цели применения',
    description: '',
    multiple: true,
    options: ['184446', '184436']
  },
  139: {
    id: 139,
    name: 'SKIN_TYPE',
    title: 'Типы кожи',
    description: 'Твой тип кожи',
    multiple: true,
    options: [
      'face_skin_type_problem',
      'face_skin_type_mixed',
      'face_skin_type_dry',
      'face_skin_type_fatty',
      'face_skin_type_normal'
    ]
  }
};
