import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const { string } = PropTypes;

class GlobalNavigationMobileLink extends Component {
  static propTypes = {
    title: string.isRequired,
    to: string,
    href: string
  };

  render() {
    const { title, linkProps, ...rest } = this.props;

    if (linkProps.to) {
      return (
        <RouterLink to={linkProps.to} className="GlobalNavigationMobile-link" {...rest}>
          {title}
        </RouterLink>
      );
    }

    return (
      <a
        href={linkProps.href}
        className="GlobalNavigationMobile-link"
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {title}
      </a>
    );
  }
}

export default GlobalNavigationMobileLink;
