export default function initFeedbackRequest(createRequest) {
  return function _getFeedbackRequest() {
    return {
      sendData: createRequest(initSendData()),
      sendMediaData: createRequest(initMediaData())
    };
  };
}

function initSendData() {
  return function _sendData({ feedback, id }) {
    const { first_name, email, phone, text } = feedback;
    return {
      method: 'POST',
      path: `/feedback`,
      data: {
        id,
        first_name,
        email,
        phone,
        text
      }
    };
  };
}

function initMediaData() {
  return function _sendMediaData({ photo_file }) {
    return {
      method: 'POST',
      path: `/feedback/photo`,
      data: {
        file: photo_file
      },
      multipart: true
    };
  };
}
