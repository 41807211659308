import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from 'modules/core/components/link/short-link';
import plural from 'modules/utils/plural';
import ProductOverviewDialog from '../product-overview-dialog/product-overview-dialog';
import Price from '../price-formatted/price-formatted';

import './order-info.css';

const { number } = PropTypes;

class OrderInfo extends Component {
  static propTypes = {
    /** Конечная  суммарная цена заказа  */
    totalCost: number.isRequired,

    /** Количество товаров в заказе  */
    productsAmount: number
  };

  state = {
    isDialogShowed: false
  };

  render() {
    const { productsAmount, totalCost, id } = this.props;
    const { isDialogShowed } = this.state;

    const goodsTitle = plural(productsAmount, ['товар', 'товара', 'товаров']);

    return (
      <div className="OrderInfo">
        <div className="OrderInfo-state">
          <Link component="button" onClick={this.handleButtonClick}>
            {productsAmount} {goodsTitle} на сумму{' '}
            <b>
              <Price number={totalCost} />
            </b>
          </Link>
        </div>

        <div className="OrderInfo-dialog">
          <ProductOverviewDialog id={id} show={isDialogShowed} onClose={this.closeDialog} />
        </div>
      </div>
    );
  }

  openDialog = () => {
    this.setState({ isDialogShowed: true });
  };

  closeDialog = () => {
    this.setState({ isDialogShowed: false });
  };

  handleButtonClick = () => {
    this.openDialog();
  };
}

export default OrderInfo;
