import React from 'react';
import cn from 'classnames';

import Media from 'modules/core/components/media/media';

import './landing-banner-images.css';

export default function LandingBannerImages({
  images,
  mainWidth = '50%',
  secondaryWidth = '50%',
  translateY = '20%',
  reverse = false
}) {
  const mainStyle = { width: mainWidth };
  const secondaryStyle = { width: secondaryWidth, transform: `translateY(${translateY})` };

  return (
    <div className={cn('LandingBannerImages', { 'LandingBannerImages--reverse': reverse })}>
      <div className="LandingBannerImages-main" style={mainStyle}>
        <Media media={images[0]} alt="" stretch="horizontal" nocaption />
      </div>
      <div className="LandingBannerImages-secondary" style={secondaryStyle}>
        <Media media={images[1]} alt="" stretch="horizontal" nocaption />
      </div>
    </div>
  );
}
