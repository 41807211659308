import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import mediaShape from 'modules/product/prop-types/media-shape';
import Picture from './picture';
import isFunction from 'modules/utils/is-function';
import { MEDIA_TYPE } from './constants';

class Thumb extends Component {
  static propTypes = {
    /**
     * Входящие данные - изображение или видео.
     * В случае с последним проверяем входящие данные на наличие картинки-заставки.
     * Если таковая есть, то предполагаем, что размер у нее такой же, как у видео.
     */
    media: mediaShape,

    stretch: PropTypes.oneOf(['none', 'vertical', 'horizontal'])
  };

  static defaultProps = {
    stretch: 'none'
  };

  state = {
    isLoaded: false,
    isError: false
  };

  render() {
    const { media, stretch, className, ...rest } = this.props;
    if (!media) {
      return null;
    }

    const sources = this.getSources(media);
    const { isLoaded } = this.state;

    if (!sources) {
      return null;
    }

    return (
      <figure
        className={classNames(className, 'Media', `Media--stretch-${stretch}`, {
          'Media--loaded': isLoaded
        })}
      >
        <Picture
          sources={sources}
          alt={media.caption}
          stretch={stretch}
          isLoaded={isLoaded}
          onLoad={this.handleLoad}
          onError={this.handleError}
          {...rest}
        />
      </figure>
    );
  }

  getSources(media) {
    switch (media.type) {
      case MEDIA_TYPE.IMAGE:
        return media.sources;
      case MEDIA_TYPE.VIDEO:
        return media.cover.sources;
      default:
        return null;
    }
  }

  handleError = () => {
    this.setState({
      isLoaded: true,
      isError: true
    });

    if (isFunction(this.props.onError)) {
      this.props.onError();
    }
  };

  handleLoad = () => {
    this.setState({
      isLoaded: true,
      isError: false
    });

    if (isFunction(this.props.onLoad)) {
      this.props.onLoad();
    }
  };
}

export default Thumb;
