import { createSelector } from 'reselect';

const getLocationProperties = state => state.core.location;

export const getCityList = createSelector(
  getLocationProperties,
  locationProperties => locationProperties.cityList
);

export const getSelectedCityId = createSelector(
  getLocationProperties,
  locationProperties => locationProperties.selectedCityId
);

export const getCityListObject = createSelector(
  [getCityList],
  cityList => Object.assign({}, ...cityList.map(city => ({ [city.id]: city })))
);

export const getSelectedCity = createSelector(
  [getCityListObject, getSelectedCityId],
  (cityListObject, selectedCityId) => cityListObject[selectedCityId].title
);

export const getSelectedCityObj = createSelector(
  [getCityListObject, getSelectedCityId],
  (cityListObject, selectedCityId) => cityListObject[selectedCityId]
);

export const getSelectedCityFiasId = createSelector(
  getSelectedCityObj,
  city => city.fiasId
);

export const getCityDialogVisibility = createSelector(
  [getLocationProperties],
  locationProperties => locationProperties.isCityDialogShowed
);

export const getCityDropdownVisibility = createSelector(
  [getLocationProperties],
  locationProperties => locationProperties.isCityDropdownShowed
);
