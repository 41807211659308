import React, { Component, Children } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import debounce from './utils/debounce';
import Frame from './frame';
import Slide from './slide';
import Pagination from './pagination';
import FrameStatic from './frame-static';

const createList = num => {
  let list = [];
  for (let i = 0; i < num; i++) {
    list.push(i);
  }
  return list;
};

class Carousel extends Component {
  static propTypes = {
    resizeDebounce: PropTypes.number,
    duration: PropTypes.number,
    easing: PropTypes.string,
    perPage: PropTypes.number,
    startIndex: PropTypes.number,
    currentPage: PropTypes.number,
    draggable: PropTypes.bool,
    threshold: PropTypes.number,
    loop: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    pagination: PropTypes.bool,
    page: PropTypes.element,
    container: PropTypes.node,
    paginationContainer: PropTypes.node,
    overflowVisible: PropTypes.bool
  };

  static defaultProps = {
    resizeDebounce: 250,
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    currentPage: false,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => {},
    onChange: false,
    pagination: false,
    page: <button />,
    container: <div />,
    paginationContainer: (
      <div
        style={{
          textAlign: 'center'
        }}
      />
    ),
    overflowVisible: false
  };

  state = {
    width: 0,
    current: 0
  };

  render() {
    const {
      children,
      pagination,
      paginationContainer,
      page,
      currentPage,
      leftControl,
      rightControl,
      ...rest
    } = this.props;

    const { perPage, overflowVisible } = this.props;

    const { width, current } = this.state;

    const slidesCount = Children.count(children);
    const slideWidth = 100 / slidesCount;

    const currentPageFinal = currentPage || current;
    const pageCount = slidesCount - perPage + 1;

    const styles = {
      overflow: overflowVisible ? 'visible' : 'hidden'
    };

    const hasLeftControl = this.getHasLeftControl();
    const hasRightControl = this.getHasRightControl();
    return (
      <div ref={this.handleSelectorRef} style={styles}>
        {width ? (
          <Frame
            slidesCount={slidesCount}
            width={width}
            {...rest}
            parent={this.selector}
            current={currentPageFinal}
            draggable={slidesCount > perPage}
            onChange={this.handleFrameChange}
          >
            {Children.map(children, (child, index) => (
              <Slide
                key={index}
                child={child}
                width={slideWidth}
                pixelWidth={perPage === 1 ? width : null}
                isCurrent={currentPageFinal === index}
              />
            ))}
          </Frame>
        ) : (
          <FrameStatic {...rest}>
            {createList(perPage)
              .slice(0, slidesCount - currentPageFinal - 1)
              .map(num => (
                <Slide
                  key={currentPageFinal + num}
                  child={children[currentPageFinal + num]}
                  width={100 / perPage}
                  isCurrent={true}
                />
              ))}
          </FrameStatic>
        )}

        {hasLeftControl &&
          React.cloneElement(leftControl, {
            onClick: this.handleLeftControlClick,
            type: 'button'
          })}

        {hasRightControl &&
          React.cloneElement(rightControl, {
            onClick: this.handleRightControlClick,
            type: 'button'
          })}

        {pagination && (
          <Pagination
            paginationContainer={paginationContainer}
            page={page}
            current={currentPageFinal}
            count={pageCount}
            pageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    const { startIndex, resizeDebounce } = this.props;
    this.setState({
      current: startIndex
    });

    this.handleResize = debounce(() => {
      this.processResize();
    }, resizeDebounce);

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getHasLeftControl() {
    const { leftControl, currentPage } = this.props;

    return leftControl && currentPage !== 0;
  }

  getHasRightControl() {
    const { rightControl, currentPage, children } = this.props;
    const lastElementIndex = Children.count(children) - 1;

    return rightControl && currentPage !== lastElementIndex;
  }

  handleFrameChange = data => {
    this.changePage(data.current);
  };

  handlePageChange = (e, page) => {
    this.changePage(page);
  };

  handleLeftControlClick = () => {
    const { currentPage } = this.props;
    this.changePage(currentPage - 1);
  };

  handleRightControlClick = () => {
    const { currentPage } = this.props;
    this.changePage(currentPage + 1);
  };

  handleSelectorRef = element => {
    this.selector = findDOMNode(element);

    this.updateWidth();
  };

  processResize() {
    this.updateWidth();
  }

  updateWidth() {
    if (!this.selector) {
      return;
    }

    this.setState({
      width: this.selector.getBoundingClientRect().width
    });
  }

  normalizeCurrent(slide) {
    const { perPage } = this.props;
    const slidesCount = Children.count(this.props.children);
    const firstPage = 0;
    const lastPage = slidesCount - perPage;

    if (slide <= firstPage) {
      return firstPage;
    }

    if (slide >= lastPage) {
      return lastPage;
    }

    return slide;
  }

  changePage(number) {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ page: number });
    } else {
      this.setState({
        current: this.normalizeCurrent(number)
      });
    }
  }
}

export default Carousel;
