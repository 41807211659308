import { createAction } from 'modules/utils/dux';
import SHOPS_LIST from '../types/shop-list';

function requestCities() {
  return createAction(SHOPS_LIST.CITIES_REQUEST);
}

function responseCities(cities) {
  return createAction(SHOPS_LIST.CITIES_RESPONSE, { cities });
}

function errorCities(error) {
  return createAction(SHOPS_LIST.CITIES_ERROR, { error });
}

function requestShopList(maybeCityId) {
  const cityId = maybeCityId;

  return createAction(SHOPS_LIST.SHOPS_REQUEST, { cityId });
}

function responseShopList(maybeCityId, shops) {
  const cityId = maybeCityId;

  return createAction(SHOPS_LIST.SHOPS_RESPONSE, { cityId, shops });
}

export default { requestCities, responseCities, errorCities, requestShopList, responseShopList };
