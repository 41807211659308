import React, { Component } from 'react';
import { Link } from 'react-scroll';
import cn from 'classnames';

class TabsItem extends Component {
  render() {
    const { title, name, offset, defaultActive } = this.props;

    return (
      <li className="Tabs-item">
        <Link
          className={cn('Tabs-link', {
            'Tabs-link--isActive': defaultActive
          })}
          activeClass="Tabs-link--isActive"
          to={name}
          offset={offset}
          duration={300}
          isDynamic
          hashSpy
          smooth
          spy
        >
          <h3 style={{ margin: '0', padding: '0', display: 'inline-block' }}>{title}</h3>
        </Link>
      </li>
    );
  }
}

export default TabsItem;
