export const SHOW = 'ordering/basket/SHOW';
export const REQUEST = 'ordering/basket/REQUEST';
export const PRODUCT_ADD = 'ordering/basket/PRODUCT_ADD';
export const PRODUCT_REMOVE = 'ordering/basket/PRODUCT_REMOVE';
export const PRODUCT_REMOVE_REQUEST = 'ordering/basket/PRODUCT_REMOVE_REQUEST';
export const PRODUCT_REMOVE_RESPONSE = 'ordering/basket/PRODUCT_REMOVE_RESPONSE';
export const PRODUCT_MARK_FOR_REMOVAL = 'ordering/basket/PRODUCT_MARK_FOR_REMOVAL';
export const PRODUCT_CANCEL_REMOVAL = 'ordering/basket/PRODUCT_CANCEL_REMOVAL';
export const PRODUCT_CHANGE_AMOUNT = 'ordering/basket/PRODUCT_CHANGE_AMOUNT';
export const DROPDOWN_OPEN = 'ordering/basket/DROPDOWN_OPEN';
export const DROPDOWN_CLOSE = 'ordering/basket/DROPDOWN_CLOSE';

export const PROMO_APPLY = 'ordering/basket/PROMO_APPLY';
export const PROMO_OPTION_SELECT = 'ordering/basket/PROMO_OPTION_SELECT';
export const PROMO_ERROR = 'ordering/basket/PROMO_ERROR';
export const PROMO_REMOVE = 'ordering/basket/PROMO_REMOVE';
export const OPEN_APPLY_ANOTHER_GIFT_DIALOG = 'ordering/basket/OPEN_APPLY_ANOTHER_GIFT_DIALOG';
export const CLOSE_APPLY_ANOTHER_GIFT_DIALOG = 'ordering/basket/CLOSE_APPLY_ANOTHER_GIFT_DIALOG';

export const BONUS_APPLY = 'ordering/basket/BONUS_APPLY';
export const BONUS_ERROR = 'ordering/basket/BONUS_ERROR';
export const BONUS_REMOVE = 'ordering/basket/BONUS_REMOVE';

export const UPDATE_FROM_SERVER = 'ordering/basket/UPDATE_FROM_SERVER';
export const RESET = 'ordering/basket/RESET';
export const RESET_PROMO = 'ordering/basket/RESET_PROMO';
export const REHYDRATE = 'ordering/basket/REHYDRATE';
export const SYNC = 'ordering/basket/SYNC';
export const SYNC_RESPONSE = 'ordering/basket/SYNC_RESPONSE';
export const VERIFY_REQUEST = 'ordering/basket/VERIFY_REQUEST';
export const VERIFY_CANCEL = 'ordering/basket/VERIFY_CANCEL';

export const SET_OUTER_BASKET_LOADING = 'ordering/basket/SET_OUTER_BASKET_LOADING';
export const SET_OUTER_BASKET_LOADED = 'ordering/basket/SET_OUTER_BASKET_LOADED';

export const CHANGES_NOTIFY = 'ordering/basket/CHANGES_NOTIFY';
export const UPDATE_FROM_ANOTHER_TAB = 'ordering/basket/UPDATE_FROM_ANOTHER_TAB';
