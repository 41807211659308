export const RESET = 'product/search/RESET';
export const REQUEST = 'product/search/REQUEST';
export const RESPONSE = 'product/search/RESPONSE';

export const ADD_PRODUCTS = 'product/search/ADD_PRODUCTS';
export const LOAD_MORE = 'product/search/LOAD_MORE';
export const SET_FILTER = 'product/search/SET_FILTER';
export const RESET_FILTER = 'product/search/RESET_FILTER';

export const FILTER_REQUEST = 'product/search/FILTER_REQUEST';
export const FILTER_RESPONSE = 'product/search/FILTER_RESPONSE';

export const GLOBAL_QUERY_CHANGE = 'product/search/GLOBAL_QUERY_CHANGE';
export const GLOBAL_QUERY_CLEAR = 'product/search/GLOBAL_QUERY_CLEAR';
