import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import isFunction from 'modules/utils/is-function';
import deleteNonNumeric from 'modules/utils/delete-non-numeric';
import FieldBottomLabeled from 'modules/profile/components/field-bottom-labeled/field-bottom-labeled';

import Input from '../input/input';

import './date-field.css';

const { shape, string, func } = PropTypes;

class DateField extends Component {
  static propTypes = {
    date: shape({
      day: string,
      month: string,
      year: string
    }),
    onChange: func
  };

  render() {
    // const { day, month, year } = this.props.date;

    const day = this.getDay();
    const month = this.getMonth();
    const year = this.getYear();

    return (
      <div className="DateField">
        <Media query="(max-width: 840px)">
          {isMobile =>
            isMobile ? (
              <div className="DateField-wideCol">
                <Input
                  type="date"
                  size="small"
                  value={this.getIsoDate()}
                  onChange={this.handleDateChange}
                  nocontrols
                />
              </div>
            ) : (
              <Fragment>
                <div className="DateField-narrowCol">
                  <FieldBottomLabeled
                    label="День"
                    size="small"
                    placeholder="дд"
                    value={day}
                    maxLength="2"
                    renderInput={props => <Input {...props} />}
                    onChange={this.handleDayChange}
                  />
                </div>
                <div className="DateField-narrowCol">
                  <FieldBottomLabeled
                    label="Месяц"
                    size="small"
                    placeholder="мм"
                    value={month}
                    maxLength="2"
                    renderInput={props => <Input {...props} />}
                    onChange={this.handleMonthChange}
                  />
                </div>
                <div className="DateField-wideCol">
                  <FieldBottomLabeled
                    label="Год"
                    size="small"
                    placeholder="гггг"
                    value={year}
                    maxLength="4"
                    renderInput={props => <Input {...props} />}
                    onChange={this.handleYearChange}
                  />
                </div>
              </Fragment>
            )
          }
        </Media>
      </div>
    );
  }

  getIsoDate() {
    const { date } = this.props;

    if (!date) {
      return null;
    }

    const { day, month, year } = date;

    const _date = new Date(year, month - 1, day + 1);

    return _date.toISOString().substring(0, 10);
  }

  getDay() {
    const { date } = this.props;

    if (!date || !date.day) {
      return null;
    }

    return date.day;
  }

  getMonth() {
    const { date } = this.props;

    if (!date || !date.month) {
      return null;
    }

    return date.month;
  }

  getYear() {
    const { date } = this.props;

    if (!date || !date.year) {
      return null;
    }

    return date.year;
  }

  setDay(rowDay) {
    const { date } = this.props;

    const day = deleteNonNumeric(rowDay);

    return {
      ...date,
      day
    };
  }

  setMonth(rowMonth) {
    const { date } = this.props;

    const month = deleteNonNumeric(rowMonth);

    return {
      ...date,
      month
    };
  }

  setYear(rowYear) {
    const { date } = this.props;

    const year = deleteNonNumeric(rowYear);

    return {
      ...date,
      year
    };
  }

  checkValidValue(value) {
    return deleteNonNumeric(value);
  }

  handleDateChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    const date = new Date(value);

    const newDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    onChange(newDate);
  };

  handleDayChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    const newDate = this.setDay(value);

    onChange(newDate);
  };

  handleMonthChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    const newDate = this.setMonth(value);

    onChange(newDate);
  };

  handleYearChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    const newDate = this.setYear(value);

    if (!isFunction(onChange)) {
      return;
    }

    onChange(newDate);
  };
}

export default DateField;
