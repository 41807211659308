import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RouterLink extends Component {
  render() {
    const { href, ...rest } = this.props;
    return <Link to={href} {...rest} />;
  }
}

export default RouterLink;
