import React, { Component } from 'react';
import './section-category-products.css';

class SectionCategoryProducts extends Component {
  render() {
    const { children } = this.props;
    return <section className="SectionCategoryProducts">{children}</section>;
  }
}

export default SectionCategoryProducts;
