import { createReducer, handle } from 'modules/utils/dux';
import * as TYPE from './types';
import * as ORDER from '../types/orders';
import { CHANGE_PAYMENT_LINK_EXPIRE_DATE } from './types';

const reducer = createReducer(
  handle(TYPE.REHYDRATE, (state, lastOrder) => {
    return {
      ...state,
      ...lastOrder
    };
  }),

  handle(ORDER.PAYMENT_RESPONSE, (state, { id, payment }, error) => {
    return {
      ...state,
      payment: payment
    };
  }),

  handle(CHANGE_PAYMENT_LINK_EXPIRE_DATE, (state, amount) => {
    return {
      ...state,
      paymentLinkExpireDate: amount
    };
  })
);

export default reducer({});
