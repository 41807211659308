import React, { Component } from 'react';
import Container from 'modules/core/components/container/container';
import SectionDefault from 'modules/core/components/section-default/section-default';
import ProductContainer from '../../containers/product';
import ProductCarousel from 'modules/core/components/product-carousel/product-carousel';
import Media from 'react-media';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

function OptionalContainer({ enabled, children }) {
  if (!enabled) {
    return children;
  }
  return <Container>{children}</Container>;
}

class LastViewedProductsSection extends Component {
  render() {
    const { productList } = this.props;

    if (!productList || !productList.length) {
      return null;
    }
    return (
      <SectionDefault title="Просмотренные ранее товары">
        <Media query="(max-width: 1100px)">
          {isMobile => (
            <OptionalContainer enabled={!isMobile}>
              <ProductCarousel>
                {productList.map(slug => (
                  <HideErrorBoundary>
                    <ProductCarousel.Item key={slug}>
                      <ProductContainer slug={slug} isInRecent listId="recent" />
                    </ProductCarousel.Item>
                  </HideErrorBoundary>
                ))}
              </ProductCarousel>
            </OptionalContainer>
          )}
        </Media>
      </SectionDefault>
    );
  }
}

export default LastViewedProductsSection;
