import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Article from '../components/article/article';

import * as fromArticleList from '../ducks/article-list';

const mapStateToProps = createStructuredSelector({
  article: fromArticleList.getItemBySlug,
  shares: fromArticleList.getSharesBySlug
});

const mapDispatchToProps = {
  like: fromArticleList.actions.like,
  unlike: fromArticleList.actions.unlike,
  share: fromArticleList.actions.share
};

class ArticleContainer extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    return (
      <Article
        {...this.props}
        onLike={this.handleLike}
        onUnlike={this.handleUnlike}
        onShare={this.handleShare}
      />
    );
  }

  handleLike = () => {
    const { slug, like } = this.props;
    like(slug);
  };

  handleUnlike = () => {
    const { slug, unlike } = this.props;
    unlike(slug);
  };

  handleShare = name => {
    const { slug, share } = this.props;

    share(slug, name);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleContainer);
