import React, { Component } from 'react';

import Button from 'modules/core/components/button/button';
import { Link } from 'react-router-dom';
import Container from 'modules/core/components/container/container';

import { getIndexLink } from 'routes/links';

import './payment-result.css';

class PaymentResult extends Component {
  render() {
    const { comment } = this.props;

    return (
      <Container>
        <div className="PaymentResult">
          {comment && <div className="PaymentResult-comment">{comment}</div>}

          <div className="PaymentResult-button">
            <Button
              title="Перейти на главную"
              variant="primary"
              component={Link}
              to={getIndexLink()}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default PaymentResult;
