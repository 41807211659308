import React, { Component } from 'react';

import './icon-mobile.css';

class IconMobile extends Component {
  render() {
    return (
      <svg
        className="IconMobile"
        viewBox="0 0 17 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".5"
          y=".5"
          width="16"
          height="29"
          rx="3.5"
          // stroke="#262931"
        />
        <path
          // stroke="#262931"
          stroke-linecap="round"
          d="M6.5 26.5h4"
        />
        <path
          d="M5 20v-8l3.431 4.49L12 12v8"
          // stroke="#000"
          stroke-linejoin="bevel"
        />
      </svg>
    );
  }
}

export default IconMobile;
