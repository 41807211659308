import React, { Component } from 'react';
import classNames from 'classnames';
import { IconShowOnMap } from 'modules/core/components/icons';

import './show-on-map-icon.css';

class ShowOnMapIcon extends Component {
  render() {
    const { active, ...rest } = this.props;
    return (
      <span
        className={classNames('ShowOnMapIcon', {
          'ShowOnMapIcon--active': active
        })}
      >
        <IconShowOnMap {...rest} />
      </span>
    );
  }
}

export default ShowOnMapIcon;
