import React, { Component } from 'react';
import cn from 'classnames';
import Media from 'react-media';
import { Field as FormikField } from 'formik';
// import Field from 'modules/form/components/field/field';

import isFunction from 'modules/utils/is-function';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import InputPhoneWithAreaCode from 'modules/core/components/input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import Form from 'modules/form/components/form/form';
import FormGroup from 'modules/form/components/form-group/form-group';
import LeftLabeledField from 'modules/form/components/left-labeled-field/left-labeled-field';
import Button from 'modules/core/components/button/button';
import Input from 'modules/form/components/input/input';
import Loader from 'modules/core/components/loader/loader';
// import RecipientForm from "modules/ordering/components/delivery-form/recipient-form"

import './personal-info-form.css';

class PersonalInfoForm extends Component {
  render() {
    return <Media query="(max-width: 840px)">{this.renderForDevice}</Media>;
  }

  renderForDevice = isMobile => {
    const {
      isLoading,
      values,
      touched,
      errors,
      isSubmitting,
      onChange,
      onFocus,
      onBlur,
      onSubmit,
      onPhoneChange,
      bonuses,
      onConfirmBonuses,
      isValid
    } = this.props;

    return (
      <div
        className={cn('PersonalInfoForm', {
          'PersonalInfoForm--loading': isLoading
        })}
      >
        {isLoading && (
          <div className="PersonalInfoForm-loader">
            <Loader type="goo" size="small" />
          </div>
        )}
        <Form onSubmit={onSubmit}>
          <div className="PersonalInfoForm-form">
            <div className="PersonalInfoForm-note">* Поля обязательны к заполнению</div>
            {/* <Input 
              fake
              type="text" 
              autoComplete="on" 
              value="" 
              readOnly={true}
            />
            <Input 
                fake
                type="text" 
                autoComplete="on" 
                value="" 
                readOnly={true}
            /> */}
            {/* <Form.LeftLabeledRow> */}
            {/* <FullNameSeparatedField
                name="name"
                value={values.name}
                touched={touched.name}
                error={errors.name}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
              /> */}
            {/* </Form.LeftLabeledRow> */}
            <Form.LeftLabeledRow>
              <LeftLabeledField
                label="Имя"
                error={touched.firstname && errors.firstname}
                size="small"
              >
                <Input
                  name="firstname"
                  // name={Boolean(values.firstname) ? "ххх_firstname" : "firstname" }
                  // type="text"
                  value={values.firstname}
                  size="small"
                  onChange={onChange}
                  onBlur={onBlur}
                  state={touched.firstname && errors.firstname && 'error'}
                  // autoComplete={Boolean(values.firstname) ? "off" : null }
                  // autoComplete={"off"}
                />
              </LeftLabeledField>
            </Form.LeftLabeledRow>
            <Form.LeftLabeledRow>
              <LeftLabeledField
                label="Фамилия"
                error={touched.lastname && errors.lastname}
                size="small"
              >
                <Input
                  name="lastname"
                  // type="text"
                  value={values.lastname}
                  size="small"
                  onChange={onChange}
                  onBlur={onBlur}
                  state={touched.lastname && errors.lastname && 'error'}
                  // autoComplete={Boolean(values.lastname) ? "off" : null}
                  // autoComplete={"off"}
                />
              </LeftLabeledField>
            </Form.LeftLabeledRow>

            <Form.LeftLabeledRow>
              <PhoneField
                name="phone"
                isMobile={isMobile}
                value={values.phone}
                touched={touched.phone}
                error={errors.phone}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onPhoneChange={onPhoneChange}
              />
            </Form.LeftLabeledRow>

            <Form.LeftLabeledRow>
              <TrimedEmailField
                name="email"
                value={values.email}
                touched={touched.email}
                error={errors.email}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </Form.LeftLabeledRow>

            <Form.ActionRow>
              <FormGroup right>
                <FormGroup.Item>
                  {bonuses.conformationOptions.required ? (
                    <ConfirmButton
                      onClick={onConfirmBonuses}
                      isMobile={isMobile}
                      isLoading={
                        bonuses.conformationOptions.confirmFetching ||
                        bonuses.conformationOptions.codeFetching
                      }
                    />
                  ) : (
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      isMobile={isMobile}
                      isValid={isValid}
                    />
                  )}
                </FormGroup.Item>
              </FormGroup>
            </Form.ActionRow>
          </div>
        </Form>
      </div>
    );
  };
}

function SubmitButton({ isSubmitting, isMobile, isValid }) {
  const visualyDisabled = isSubmitting || !isValid;
  return (
    <Button
      title="Далее"
      variant="primary"
      type="submit"
      // disabled={isSubmitting || !isValid}
      visualyDisabled={visualyDisabled}
      size={isMobile && 'small'}
      expanded={isMobile}
      // type="button"
    />
  );
}
function ConfirmButton({ onClick, isMobile, isLoading }) {
  return (
    <Button
      title="Подтвердить телефон"
      variant="primary"
      type="button"
      // disabled={isSubmitting}
      onClick={onClick}
      size={isMobile && 'small'}
      expanded={isMobile}
      disabled={isLoading}
      iconAppend={isLoading && <IconFlatLoader />}
    />
  );
}

// function EmailField({ name, error, value, touched, ...rest }) {
//   return (
//     <LeftLabeledField label="Email" size="small" error={touched && error} required>
//       <Input
//         name={name}
//         value={value}
//         // type="email"
//         size="small"
//         state={touched && error ? 'error' : 'default'}
//         autoComplete="on"
//         {...rest}
//       />
//     </LeftLabeledField>
//   );
// }

function TrimedEmailField({ name, error, value, touched, ...rest }) {
  return (
    <LeftLabeledField label="Email" size="small" error={touched && error} required>
      <FormikField
        name="email"
        render={({ field, form }) => (
          <Input
            name={field.name}
            value={field.value}
            // type="email"
            size="small"
            state={touched && error ? 'error' : 'default'}
            // autoComplete="on"
            {...rest}
            onChange={handleEmailChange(form)(field.name)}
          />
        )}
      />
    </LeftLabeledField>
  );

  function handleEmailChange(form) {
    return name => ({ target: { value } }) => {
      form.setFieldValue(name, value.trim());
    };
  }
}

// function FirstNameField({ name, value, error, touched, ...rest }) {
//   return (
//     <LeftLabeledField label="Имя" size="small" error={touched && error} required>
//       <Input
//         name={name}
//         value={value}
//         size="small"
//         state={touched && error ? 'error' : 'default'}
//         {...rest}
//         autoComplete="off"
//       />
//     </LeftLabeledField>
//   );
// }

// if it is needed to separate "firstName" and "lastName" - use next component instead of previous one
// function FullNameSeparatedField({ name, value, error, touched, ...rest }) {
//   const handleNameChange = form => name => value => {
//     form.setFieldValue(name, value);
//   };

//   return (
//     <FormikField
//         name="name"
//         render={({ field, form }) => {
//           // const fieldKeys = Object.keys(form.initialValues);
//           // const wasAllFieldsTouched = fieldKeys.map(field=>form.touched[field]).every(Boolean);

//           return (
//           <Field error={touched && error}>
//             <RecipientForm
//               {...rest}
//               value={value}
//               error={touched && error}
//               {...field}
//               onChange={handleNameChange(form)(field.name)}
//               autoFocus
//               />
//           </Field>
//           )
//         }}
//       />
//   );
// }

class PhoneField extends Component {
  render() {
    const { isMobile, name, value, touched, error, ...rest } = this.props;

    return (
      <LeftLabeledField label="Телефон" size="small" error={touched && error} required>
        <FormikField
          name="phone"
          render={({ field, form }) => (
            <InputPhoneWithAreaCode
              codeList={areaCodes}
              name={field.name}
              value={field.value}
              size="small"
              state={touched && error ? 'error' : 'default'}
              {...rest}
              onChange={this.handlePhoneChange(form)(field.name)}
              autoComplete="on"
            />
          )}
        />
      </LeftLabeledField>
    );
  }

  handlePhoneChange = form => name => value => {
    const { onPhoneChange } = this.props;

    // const phone = '+' + value.replace(/\D/g, '');

    form.setFieldValue(name, value);

    if (!isFunction(onPhoneChange)) {
      return;
    }
    onPhoneChange(value);
  };
}

export default PersonalInfoForm;
