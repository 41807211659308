import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

import events from './events';

export default function createBeaconMiddleware() {
  if (!window.dataLayer) {
    window.dataLayer = {
      push(...args) {
        console.warn('GTM is not available! Call arguments: ', args);
      }
    };
  }

  if (__BROWSER__) {
    const { protocol, hostname, pathname, search, port } = window.location;
    const originalLocation = `${protocol}//${hostname}${
      port ? `:${port}` : ''
    }${pathname}${search}`;

    window.dataLayer.push({
      originalLocation
    });
    window.originalLocation = originalLocation;
  }

  const gtag = GoogleTagManager();

  return createMiddleware(events, gtag);
}
