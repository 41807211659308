import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormPart extends Component {
  static propTypes = {
    label: PropTypes.string
  };
  render() {
    const { children, label } = this.props;
    return (
      <div className="Form-part">
        <div className="Form-partLabel">{label}</div>
        <div className="Form-partContent">{children}</div>
      </div>
    );
  }
}

export default FormPart;
