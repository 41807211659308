import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import cn from 'classnames';

import Toast from '../toast/toast';

import './toast-stack.css';

const { oneOf } = PropTypes;

class ToastStack extends Component {
  static propTypes = {
    /** Выравнивание по горизонтали */
    alignment: oneOf(['left', 'center', 'right']),

    /** Выравнивание по вертикали */
    verticalAlignment: oneOf(['top', 'bottom'])
  };

  static defaultProps = {
    alignment: 'right',
    verticalAlignment: 'top'
  };

  render() {
    const { alignment, verticalAlignment, toastList } = this.props;

    return (
      <Portal>
        <div
          className={cn(
            'ToastStack',
            `ToastStack--alignment-${alignment}`,
            `ToastStack--verticalAlignment-${verticalAlignment}`
          )}
        >
          {toastList.map(this.renderToast)}
        </div>
      </Portal>
    );
  }

  renderToast = toast => {
    const { id, exiting } = toast;
    return (
      <div
        key={id}
        className={cn('ToastStack-item', {
          'ToastStack-item--exiting': exiting
        })}
      >
        <Toast {...toast} />
      </div>
    );
  };
}

export default ToastStack;
