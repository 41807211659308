import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Availability from 'modules/core/components/availability/availability';
import Button from 'modules/core/components/button/button';
import ButtonClose from 'modules/core/components/button-close/button-close';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconTrack, IconLocation, IconDuration, IconCard } from 'modules/core/components/icons';

import pickPointShape from '../../prop-types/pick-point-shape';

import { weekdaysToString } from 'modules/utils';
import timeIntervalToString from '../../formatters/time-interval';

import { formatDuration } from 'modules/blog/formatters/format-duration';

import './pick-point-card.css';

const { bool, func } = PropTypes;

class PickupPointCard extends Component {
  static propTypes = {
    /** Данные постамата/пункта выдачи PickPoint */
    pickPoint: pickPointShape.isRequired,

    /** Флаг выбранного постамата/пункта выдачи PickPoint */
    isSelected: bool.isRequired,

    /** Обработчик функции выбора постамата/пункта выдачи PickPoint */
    onSelect: func.isRequired,

    /** Обработчик функции закрытия компонента PickPointCard*/
    onClose: func.isRequired
  };

  render() {
    const { pickPoint, isSelected } = this.props;

    return (
      <div className="PickPointCard">
        <div className="PickPointCard-header">
          <h3 className="PickPointCard-title">{pickPoint.title}</h3>

          {isSelected && <div className="PickPointCard-notification">Уже выбрано</div>}

          <div className="PickPointCard-buttonClose">
            <ButtonClose onClick={this.handleCloseButtonClick} />
          </div>
        </div>

        <div className="PickPointCard-content">
          <div className="PickPointCard-infoGroup">
            {pickPoint.delivery &&
              this.renderInfoItem({
                title: 'Доставка',
                icon: <IconTrack />,
                content: this.renderDeliveryDuration()
              })}

            {this.renderInfoItem({
              title: 'Адрес',
              icon: <IconLocation />,
              content: pickPoint.geography.address.raw
            })}

            {this.renderInfoItem({
              title: 'Время работы',
              icon: <IconDuration />,
              content: this.renderScheduleList()
            })}

            {pickPoint.payment &&
              this.renderInfoItem({
                title: 'Оплата картой',
                icon: <IconCard />,
                content: this.renderTerminalAbility()
              })}
          </div>
        </div>

        <div className="PickPointCard-footer">
          <div className="PickPointCard-buttonSelect">
            <Media query="(max-width: 640px)">
              {isMobile => (
                <Button
                  title="Забрать здесь"
                  variant="primary"
                  size={isMobile ? 'small' : 'normal'}
                  onClick={this.handleSelectButtonClick}
                  expanded
                />
              )}
            </Media>
          </div>
          <div className="PickPointCard-buttonBack">
            <Button title="Назад" size="small" onClick={this.handleBackButtonClick} expanded />
          </div>
        </div>
      </div>
    );
  }

  renderInfoItem({ title, icon, content }) {
    return (
      <div className="PickPointCard-infoItem">
        <div className="PickPointCard-infoItemTitle">
          <span className="PickPointCard-infoItemTitleIcon">
            <IconContainer size="small">{icon}</IconContainer>
          </span>
          <span className="PickPointCard-infoItemTitleString">{title}</span>
        </div>
        <div className="PickPointCard-infoItemContent">{content}</div>
      </div>
    );
  }

  renderDeliveryDuration() {
    const { pickPoint } = this.props;

    return `${formatDuration(pickPoint.delivery.minDuration, false)} - ${formatDuration(
      pickPoint.delivery.maxDuration,
      false
    )}`;
  }

  renderScheduleList() {
    const { pickPoint } = this.props;

    return (
      <div className="pickPoint-scheduleList">
        {pickPoint.schedule.map(this.renderScheduleItem)}
      </div>
    );
  }

  renderScheduleItem = (item, index) => {
    return (
      <span key={index} className="PickPoint-scheduleItem">
        {weekdaysToString(item.days)} {timeIntervalToString(item.time)}
      </span>
    );
  };

  renderTerminalAbility() {
    const { pickPoint } = this.props;

    return <Availability is={pickPoint.payment.hasTerminal} title="Есть" falsyTitle="Нет" />;
  }

  handleSelectButtonClick = () => {
    const { pickPoint, onSelect } = this.props;

    onSelect(pickPoint);
  };

  handleCloseButtonClick = () => {
    const { onClose } = this.props;

    onClose();
  };

  handleBackButtonClick = () => {
    const { onClose } = this.props;

    onClose();
  };
}

export default PickupPointCard;
