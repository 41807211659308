const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

export function dateToString(date) {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = months[_date.getMonth()];
  const year = _date.getFullYear();

  return `${day} ${month} ${year}`;
}

function isThisMonth(date) {
  const _date = new Date(date);
  // const _dateYear = _date.getFullYear();
  const _dateMonth = _date.getMonth();

  const currentDate = new Date();
  // const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return isThisYear(date) && _dateMonth === currentMonth;
}

function isPreviousMonth(date) {
  const _date = new Date(date);
  // const _dateYear = _date.getFullYear();
  const _dateMonth = _date.getMonth();

  const currentDate = new Date();
  // const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  if (currentDate !== 0) {
    return isThisYear(date) && _dateMonth === currentMonth - 1;
  } else {
    return isPreviousYear(date) && _dateMonth === 11;
  }
}

function isThisYear(date) {
  const _date = new Date(date);
  const _dateYear = _date.getFullYear();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return _dateYear === currentYear;
}

function isPreviousYear(date) {
  const _date = new Date(date);
  const _dateYear = _date.getFullYear();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return currentYear - 1 === _dateYear;
}

export const getfilteredOrders = (orders, activeFilter) => {
  let _orders = orders;
  if (activeFilter === 1) {
    _orders = orders.filter(order => isThisMonth(order.placing_date));
  } else if (activeFilter === 2) {
    _orders = orders.filter(order => isPreviousMonth(order.placing_date));
  } else if (activeFilter === 3) {
    _orders = orders.filter(order => isThisYear(order.placing_date));
  } else if (activeFilter === 4) {
    _orders = orders.filter(order => isPreviousYear(order.placing_date));
  }
  return _orders;
};

export const getIsTrackingNumberExistSomewhere = orders => {
  return orders.some(order => Boolean(order.trackingNumber));
} 
