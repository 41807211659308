import React, { Component } from 'react';
import './icon-basket2.css';

class IconBasket2 extends Component {
  render() {
    return (
      <svg
        className="IconBasket2"
        viewBox="0 0 29 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1.457h5.674L7.483 8.39m17.734 7.566H9.457l-.987-3.783m0 0h18.639m-18.64 0L7.484 8.39m0 0H29" />
        <circle cx="12.609" cy="23.522" r="2.022" />
        <circle cx="21.435" cy="23.522" r="2.022" />
      </svg>
    );
  }
}

export default IconBasket2;
