import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'modules/core/components/switch/switch';
import ColorSwitchOption from './color-switch-option';
import ColorSwitchItem from './color-switch-item';
import ColorSwitchContainer from './color-switch-container';
import './color-switch.css';

class ColorSwitch extends Component {
  static propTypes = {
    /**
     * получаем массив данных опций:
     * color - значение цвета в любом поддерживаемом в CSS формате
     * disabled - доступна ли эта опция в переключателе
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        disabled: PropTypes.bool
      }).isRequired
    ),
    /** событие по изменению (переключению) опции */
    onChange: PropTypes.func
  };

  render() {
    const { options, ...rest } = this.props;

    return (
      <Switch switchHolder={<ColorSwitchContainer />} optionHolder={<ColorSwitchItem />} {...rest}>
        {options.map(this.renderOption)}
      </Switch>
    );
  }

  renderOption(option) {
    return (
      <Switch.Option key={option.id} disabled={option.disabled} value={option.id}>
        <ColorSwitchOption color={option.value} />
      </Switch.Option>
    );
  }
}
export default ColorSwitch;
