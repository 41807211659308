import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import linkify from 'modules/utils/linkify';

import './counted-list.css';

class CountedList extends Component {
  render() {
    const { list } = this.props;

    return (
      <div className="CountedList">
        <ol className="CountedList-topLevel">
          {list.map((item, index) => {
            const ListItem = item.unordered ? 'ul' : 'ol';

            return (
              <Fragment>
                <li className="CountedList-topLevelTitle" key={index}>
                  <h2 className="CountedList-topLevelHeader">{this.renderTitle(item.title)}</h2>
                  {item.list && (
                    <ListItem className="CountedList-nestedLevel">
                      {this.renderNestedLevel(item.list)}
                    </ListItem>
                  )}
                </li>
              </Fragment>
            );
          })}
        </ol>
      </div>
    );
  }

  renderNestedLevel(list) {
    return list.map((subItem, index) => {
      const title = !this.hasNestedList(subItem) ? subItem : subItem.title;
      const linkifiedText = linkify(title);
      const ListItem = subItem.unordered ? 'ul' : 'ol';
      return (
        <li
          className={cn('CountedList-nestedLevelTitle', {
            'CountedList-nestedLevelTitle--unordered': subItem.unordered
          })}
          key={index}
        >
          {this.renderTitle(linkifiedText)}

          {this.hasNestedList(subItem) && subItem.list && (
            <ListItem className="CountedList-nestedLevel">
              {this.renderNestedLevel(subItem.list)}
            </ListItem>
          )}
        </li>
      );
    });
  }

  renderTitle(title) {
    return <span dangerouslySetInnerHTML={{ __html: title }} />;
  }

  hasNestedList(element) {
    return typeof element !== 'string';
  }
}

export default CountedList;
