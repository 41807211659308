import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InputPhoneWithAreaCode from 'modules/core/components/input-phone-with-area-code/input-phone-with-area-code_new';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import isFunction from 'modules/utils/is-function';

import BaseEditable from '../base-editable/base-editable';
import { selectInputValue } from '../../helpers';

import './editable-phone.css';

const { func, shape, string } = PropTypes;

class EditablePhone extends Component {
  static propTypes = {
    address: shape({
      building: string,
      apartment: string
    }),
    onChange: func
  };

  _scheduleInputFocus = false;
  inputPhone = null;

  render() {
    const { phone, apliable, ...rest } = this.props;
    return (
      <BaseEditable
        value={phone}
        apliable={apliable}
        renderEdit={({ editingValue, onChange }) => (
          <InputPhoneWithAreaCode
            inputRef={this.setInputRef}
            codeList={areaCodes}
            name="phone"
            value={editingValue}
            size="small"
            onFocus={this.handleFocus}
            onChange={this.handlePhoneChange(onChange)}
          />
        )}
        onEdit={this.handleEdit}
        {...rest}
      />
    );
  }

  setInputRef = element => {
    this.inputPhone = element;
    if (this._scheduleInputFocus && element) {
      this.inputPhone.focus();
      requestAnimationFrame(() => selectInputValue(this.inputPhone));
      this._scheduleInputFocus = false;
    }
  };

  setFocus() {
    if (this.inputPhone) {
      this.inputPhone.focus();
    } else {
      this._scheduleInputFocus = true;
    }
  }

  handleEdit = () => {
    const { onEdit } = this.props;

    this.setFocus();

    if (!isFunction(onEdit)) {
      return;
    }
    onEdit();
  };

  handlePhoneChange = onChange => value => {
    const { onPhoneStateChange } = this.props;

    onChange(value);

    if (!isFunction(onPhoneStateChange)) {
      return;
    }

    onPhoneStateChange(value);
  };
}

export default EditablePhone;
