import { takeEvery, put /*, select*/, call } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import { replace, LOCATION_CHANGE } from 'connected-react-router';

import { mixitApi } from 'services/mixit';

import * as fromDialogs from '../ducks/dialogs';

import * as types from '../ducks/types/recovery-password';
import actions from '../ducks/actions/recovery-password';

export function* passwordRecoveryWatcher() {
  try {
    yield takeEvery(types.START_RECOVERY, startPasswordRecovery);
    yield takeEvery(types.REQUEST, requestPasswordRecovery);
    yield takeEvery(types.CONFIRM_PASSWORD_REQUEST, requestPasswordConfirm);
    yield takeEvery(LOCATION_CHANGE, setConfirmCode);
  } catch (e) {
    console.warn(e);
  }
}

function* startPasswordRecovery({ payload }) {
  const { email } = payload;
  try {
    yield call(mixitApi().account().exists, email);

    yield put(actions.request(email));
  } catch (e) {
    console.warn(e);

    yield put(actions.setRegisteredEmail(email));
    yield put(actions.error('Данный e-mail не зарегистрирован'));
  }
}

function* requestPasswordRecovery({ payload }) {
  const { email } = payload;
  try {
    yield call(mixitApi().account().passwordRecovery, email);
    yield put(actions.resetSubmitting());
    yield put(fromDialogs.actions.openPasswordRecoveryRequestSuccessDialog());
  } catch (e) {
    console.warn(e);
  }
}

function* requestPasswordConfirm({ payload }) {
  const { confirmation_key, new_password } = payload;

  try {
    yield call(mixitApi().account().confirmPasswordRecovery, confirmation_key, new_password);
    yield put(actions.confirmPasswordResponse(new_password));
    yield put(actions.removeConfirmKey());
    yield put(fromDialogs.actions.openConfirmPasswordSuccessRecoveryDialog());
  } catch (e) {
    yield put(fromDialogs.actions.openConfirmPasswordErrorRecoveryDialog());
    console.warn(e);
  }
}

function* setConfirmCode(action) {
  const { search } = action.payload.location;

  try {
    const query = search.substr(1);

    if (!query) {
      return;
    }

    const parameters = queryParametersToObject(query);

    if (!parameters || !parameters.code) {
      return;
    }

    yield put(actions.saveConfirmKey(parameters.code));
    yield put(fromDialogs.actions.openConfirmPasswordRecovery());
    yield removeCode(action);
  } catch (e) {
    console.warn(e);
  }
}

function* removeCode(action) {
  const { location } = action.payload;
  const search = filterCode(location.search);
  if (search === location.search) {
    return;
  }

  yield put(
    replace({
      ...location,
      search,
      skipTrack: true
    })
  );
}

function queryParametersToObject(search) {
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}

function filterCode(search) {
  if (!search || !search.length) {
    return search;
  }
  const query = search.substring(1);
  const filteredQuery = query
    .split('&')
    .filter(param => !/code/.test(param))
    .join('&');

  return `?${filteredQuery}`;
}
