import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from '../../utils/debug-reselect';

import * as TYPES from '../types/password-change';
/* REDUCERS */

const reducer = createReducer(
  handle(TYPES.REQUEST, state => {
    return {
      ...state,
      isFetching: true,
      errorMessage: null
    };
  }),

  handle(TYPES.RESPONSE, state => {
    return {
      ...state,
      isFetching: false,
      isToastOpened: true
    };
  }),

  handle(TYPES.ERROR, (state, { message }) => {
    return {
      ...state,
      errorMessage: message,
      isFetching: false
    };
  }),

  handle(TYPES.RESET_ERROR_MESSAGE, state => {
    return {
      ...state,
      errorMessage: null
    };
  }),

  handle(TYPES.CLOSE_TOAST, state => {
    return {
      ...state,
      isToastOpened: false
    };
  })
);

export default reducer({
  errorMessage: null,
  isFetching: false,
  isToastOpened: false
});

/* SELECTORS */

const getRoot = state => state.profile.passwordChange;

export const getErrorMessage = createSelector(
  getRoot,
  root => root.errorMessage
);

export const getIsFetching = createSelector(
  getRoot,
  root => root.isFetching
);

export const getIsToastOpened = createSelector(
  getRoot,
  root => root.isToastOpened
);
