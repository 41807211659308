import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const { string, bool, oneOfType, number } = PropTypes;
class VolumeSwitchOption extends Component {
  static propTypes = {
    /** значение объема или веса */
    volume: oneOfType([string, number]),
    /** параметр который стилизует внешний вид опции на которую нельзя переключиться */
    disabled: bool,
    /**параметр который стилизует внешний вид активной (выбранной) опции */
    checked: bool
  };

  static defaultProps = {
    disabled: false,
    checked: false
  };

  render() {
    const { volume, disabled, unit, checked, single, small, ...rest } = this.props;
    return (
      <div
        className={classNames('VolumeSwitch-option', {
          'VolumeSwitch-option--disabled': disabled,
          'VolumeSwitch-option--checked': checked,
          'VolumeSwitch-option--single': single,
          'VolumeSwitch-option--small': small
        })}
        {...rest}
      >
        <span className="VolumeSwitch-num">{volume}</span>&nbsp;
        <span className="VolumeSwitch-unit">{unit}</span>
        {disabled && (
          <svg className="VolumeSwitch-cross">
            <line x1="0" y1="0" x2="100%" y2="100%" />
          </svg>
        )}
      </div>
    );
  }
}
export default VolumeSwitchOption;
