import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './section-article-details.css';

const { any } = PropTypes;

class SectionArticleDetails extends Component {
  static propTypes = {
    children: any
  };

  render() {
    const { children } = this.props;

    return <section className="SectionArticleDetails">{children}</section>;
  }
}

export default SectionArticleDetails;
