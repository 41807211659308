import React, { Component } from 'react';

import './icon-product.css';

class IconProduct extends Component {
  render() {
    return (
      <svg
        className="IconProduct"
        viewBox="0 0 13 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // stroke="#262931"
          d="M4.5.5h4v6h-4z"
        />
        <path
          d="M3.5 8A1.5 1.5 0 015 6.5h3A1.5 1.5 0 019.5 8v5.5h-6V8z"
          fill="#fff"
          // stroke="#262931"
        />
        <path
          d="M.5 16A4.5 4.5 0 015 11.5h3a4.5 4.5 0 014.5 4.5v20.5H.5V16z"
          fill="#fff"
          // stroke="#262931"
        />
        <path
          // stroke="#262931"
          d="M9 .5h4"
        />
      </svg>
    );
  }
}

export default IconProduct;
