import React, { Component } from 'react';
import './dialog-section.css';

class DialogSection extends Component {
  render() {
    const { title, children } = this.props;
    return (
      <div className="DialogSection">
        {title && <div className="DialogSection-title">{title}</div>}
        {children}
      </div>
    );
  }
}

export default DialogSection;
