import React from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-media';
import cn from 'classnames';
// import { Scrollbars } from 'react-custom-scrollbars';
import ShadowScrollbars from 'modules/core/components/shadow-scrollbars-need-replace2/shadow-scrollbars-need-replace';

import plural from 'modules/utils/plural';

import { getBasketLink, getProductLink } from 'routes/links';

import Container from '../container/container';
import Button from '../button/button';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import ProductButton from './product-button';
import { IconCross } from '../icons';
import IconContainer from '../icon-container/icon-container';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Media from 'modules/core/components/media/media';

import './basket-preview-panel.css';

const BasketPreviewPanel = ({
  productsAmount,
  isLoading,
  onBasketSubmit,
  productsCost,
  productsList,
  excessAmount,
  closeBasketPreview,
  excessList
}) => {
  const goodsTitle = plural(productsAmount, ['товар', 'товара', 'товаров']);

  return (
    <MediaQuery query="(max-width:1080px)">
      {isTablet => (
        <MediaQuery query="(max-width:840px)">
          {isWideMobile => (
            <MediaQuery query="(max-width:460px)">
              {isMobile => (
                <Container>
                  <div className="BasketPreviewPanel">
                    {isTablet && (
                      <div className="BasketPreviewPanel-buttonCloseWrapper">
                        <button
                          className="BasketPreviewPanel-buttonClose"
                          onClick={closeBasketPreview}
                        >
                          <IconContainer>
                            <IconCross />
                          </IconContainer>
                        </button>
                      </div>
                    )}

                    <div className="BasketPreviewPanel-narrowColumn">
                      <div className="BasketPreviewPanel-orderInfo">
                        <div className="BasketPreviewPanel-orderAmount">
                          В корзине {productsAmount} {goodsTitle}
                        </div>

                        <div className="BasketPreviewPanel-orderCost">
                          <span style={{ color: '#818690' }}>На сумму</span>{' '}
                          <span style={{ fontWeight: '700' }}>{productsCost} руб.</span>
                        </div>
                      </div>
                    </div>

                    <div className="BasketPreviewPanel-wideColumn">
                      <div className="BasketPreviewPanel-product">
                        <div className="BasketPreviewPanel-productMediaList">
                          {productsList.map(item => {
                            return (
                              <div className="BasketPreviewPanel-item">
                                <ProductButton
                                  product={item.product}
                                  media={item.product.media[0]}
                                  amount={item.amount}
                                />
                                {item.amount > 1 && (
                                  <div className="BasketPreviewPanel-amountLabel">
                                    {item.amount}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>

                        {excessAmount > 0 && (
                          <div className="BasketPreviewPanel-excessAmountHolder">
                            {!isWideMobile ? (
                              <Tooltip
                                content={renderExcessProducts(excessList)}
                                showDelay={300}
                                hideDelay={100}
                                // alignment={isWideMobile ? "right" : "center"}
                                alignment={'center'}
                              >
                                <div className="BasketPreviewPanel-excessAmount">
                                  + {excessAmount}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="BasketPreviewPanel-excessAmount">
                                + {excessAmount}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="BasketPreviewPanel-basketLinkColumn">
                        <Link
                          to={getBasketLink()}
                          title="Перейти в корзину"
                          className="BasketPreviewPanel-basketLink"
                        >
                          Перейти в корзину
                        </Link>
                      </div>

                      <div className="BasketPreviewPanel-orderColumn">
                        <Button
                          title="Оформить заказ"
                          variant="primary"
                          disabled={isLoading}
                          size={isMobile ? 'small' : 'normal'}
                          iconAppend={isLoading && <IconFlatLoader />}
                          onClick={handleSubmitButtonClick}
                        />
                      </div>

                      {!isTablet && (
                        <div className="BasketPreviewPanel-buttonCloseColumn">
                          <button
                            className="BasketPreviewPanel-buttonClose"
                            onClick={closeBasketPreview}
                          >
                            <IconContainer>
                              <IconCross />
                            </IconContainer>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
            </MediaQuery>
          )}
        </MediaQuery>
      )}
    </MediaQuery>
  );

  function renderExcessProducts(excessList) {
    return (
      <div className="BasketPreviewPanel-tooltip">
        <ShadowScrollbars autoHeight autoHeightMax={200}>
          {excessList.map(basketProduct => {
            const { product, amount } = basketProduct;
            const { isGift, description, title, media } = product;
            const UsedComponent = isGift ? 'div' : Link;

            const isAmountMoreThenOne = amount > 1;

            return (
              <div
                className={cn('BasketPreviewPanel-tooltipElementHolder', {
                  'BasketPreviewPanel-tooltipElementHolder--gift': isGift
                })}
              >
                <UsedComponent
                  title={!isGift && 'Перейти к продукту'}
                  className="BasketPreviewPanel-tooltipLink"
                  to={getProductLink(product)}
                >
                  <div className="BasketPreviewPanel-tooltipLinkImage">
                    {isAmountMoreThenOne && (
                      <div className="BasketPreviewPanel-tooltipLinkAmountLabel">{amount}</div>
                    )}
                    <Media media={media[0]} stretch="horizontal" resizeWidth={80} nocaption />
                  </div>
                  <div className="BasketPreviewPanel-tooltipLinkMain">
                    <div className="BasketPreviewPanel-tooltipLinkDescription">
                      {Boolean(isGift) && (
                        <div className="BasketPreviewPanel-tooltipLinkLabel">Подарок:</div>
                      )}
                      {description}
                    </div>
                    {!Boolean(isGift) && (
                      <div className="BasketPreviewPanel-tooltipLinkTitle">{title}</div>
                    )}
                  </div>
                </UsedComponent>
              </div>
            );
          })}
        </ShadowScrollbars>
      </div>
    );
  }

  function handleSubmitButtonClick(e) {
    e.preventDefault();
    onBasketSubmit();
  }
};

export default BasketPreviewPanel;
