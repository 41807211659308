import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OrderProduct from '../components/order-product/order-product';
import { getLastOrder } from 'modules/ordering/ducks/last-order/selectors';

const mapState = createStructuredSelector({
  lastOrder: getLastOrder
});

class OrderProductContainer extends Component {
  render() {
    const { slug, lastOrder } = this.props;
    const product = lastOrder.productList.find(item => item.slug === slug);

    return <OrderProduct {...this.props} product={product} />;
  }
}

export default connect(mapState)(OrderProductContainer);
