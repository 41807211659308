import React, { Fragment } from 'react';

 const ConditionalWrapper = ({ 
     condition, 
     children, 
     wrapper, 
     elseWrapper = Fragment, 
     wrappElement, 
     elseWrappElement }) => {

     if(condition && (Boolean(wrappElement) || Boolean(elseWrappElement))) {
         return React.cloneElement(wrappElement || elseWrappElement, {
            children
         })
     }

     const WrapperEl = condition ? wrapper : elseWrapper;
     return (
         <WrapperEl>
            {children}
         </WrapperEl>
     )
 }

export default ConditionalWrapper;

