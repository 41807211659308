import React, { Component } from 'react';
import CurrentCityContainer from 'modules/geography/containers/current-city';

import AnimatedLink from '../../animated-link/animated-link';
import { IconPhone } from '../../icons';

import './header-info.css';

function isTouchScreen() {
  return 'ontouchstart' in document.documentElement;
}

class HeaderInfo extends Component {
  render() {
    return (
      <div className="HeaderInfo">
        <ul className="HeaderInfo-list">
          <li className="HeaderInfo-item">
            <CurrentCityContainer />
          </li>
          <li className="HeaderInfo-item">
            <AnimatedLink
              href={isTouchScreen() && 'tel:+74958990134'}
              icon={<IconPhone />}
              // title="8 (495) 252-07-43"
              title="8 (800) 550-98-70 "
              nolink={!isTouchScreen()}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default HeaderInfo;
