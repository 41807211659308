import { takeEvery, select, put } from 'redux-saga/effects';
import * as PRODUCTS from '../ducks/types/products';
import recentActions from '../ducks/recent/actions';
import * as fromRecent from '../ducks/recent';

import fromRecentProductsLocalStorageActions from 'services/recent-products';

const RECENT_ITEM_AMOUNT = 16;

export function* recentProductsLocalStorage() {
  yield takeEvery([PRODUCTS.SHOW_ITEM, PRODUCTS.OVERVIEW_SHOWED], updateRecentProducts);
  yield takeEvery('@@REHYDRATE', rehydrate);
}

function* updateRecentProducts(action) {
  const { isInRecent } = action.payload;

  if (isInRecent) {
    return;
  }
  try {
    const cleanedProductList = yield select(fromRecent.getCleanedProductList);

    const slicedList = cleanedProductList.slice(0, RECENT_ITEM_AMOUNT);

    fromRecentProductsLocalStorageActions.setProducts(slicedList);
  } catch (e) {
    console.warn(e);
  }
}

function* rehydrate() {
  try {
    const recentProductsFromLocalStorage = fromRecentProductsLocalStorageActions.getProducts();

    yield put(recentActions.rehydrate(recentProductsFromLocalStorage));
  } catch (e) {
    console.warn(e);
  }
}
