import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromLocation from 'modules/geography/ducks/location';
import locationActions from 'modules/geography/ducks/actions/location';

import CitySuggestBottomSheet from '../components/city-suggest-bottom-sheet/city-suggest-bottom-sheet';

const mapState = createStructuredSelector({
  city: fromLocation.getSuggestedCity
});

const mapDispatchToProps = {
  chooseCity: locationActions.chooseCity
};

function getCityTitle(city) {
  if (!city || !city.title) {
    return null;
  }

  return city.title;
}

class CitySuggestBottomSheetContainer extends Component {
  render() {
    const { city, ...rest } = this.props;

    const cityTitle = getCityTitle(city);

    return (
      <CitySuggestBottomSheet
        title={cityTitle}
        onAccept={this.handleAccept}
        onAnotherSelect={this.handleAnotherSelect}
        {...rest}
      />
    );
  }

  handleAccept = () => {
    const { city, chooseCity, onClose } = this.props;

    chooseCity(city.id);
    onClose();
  };

  handleAnotherSelect = () => {
    const { onAnotherSelect } = this.props;

    onAnotherSelect();
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(CitySuggestBottomSheetContainer);
