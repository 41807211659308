export default function initSearchRequest(createRequest) {
  return createRequest(function getItemRequest(query, parameters) {
    return {
      method: 'GET',
      path: `/search`,
      query: {
        query,
        ...parameters
      }
    };
  });
}
