import { createReducer, handle } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import * as fromDeliveryMethods from './delivery-methods';
import * as CITY_DELIVERY from './types/city-delivery';
import * as ORDER from './types/orders';
import * as PROFILE from 'modules/profile/types/profile';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import * as DeliveryMethod from '../domain/delivery-method';

const reducer = createReducer(
  handle(CITY_DELIVERY.RESPONSE, (state, { delivery }, error) => {
    if (error) {
      return state;
    }

    return {
      ...state,
      ...delivery
    };
  }),

  handle(ORDER.SUCCESS, (state, { order }) => {
    const { deliveryMethod } = order.delivery;

    return {
      ...state,
      [deliveryMethod.id]: deliveryMethod
    };
  }),

  handle(ORDER.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order.delivery || !order.delivery.deliveryMethod) {
      return state;
    }

    const deliveryMethod = order.delivery.deliveryMethod;

    return {
      ...state,

      [deliveryMethod.id]: deliveryMethod
    };
  }),

  handle(CITY_DELIVERY.PICK_POINT_REQUEST, (state, { deliveryMethodId }) => {
    if (
      state[deliveryMethodId] &&
      state[deliveryMethodId].pickPoints &&
      state[deliveryMethodId].pickPoints.length > 0
    ) {
      return state;
    }

    return {
      ...state,
      [deliveryMethodId]: {
        ...state[deliveryMethodId],
        isLoaded: false
      }
    };
  }),

  handle(CITY_DELIVERY.PICK_POINT_RESPONSE, (state, { deliveryMethodId, pickPoints }) => {
    const pickPointResult = pickPoints ? pickPoints.result : null;

    return {
      ...state,
      [deliveryMethodId]: {
        ...state[deliveryMethodId],
        pickPoints: pickPointResult,
        isLoaded: true
      }
    };
  }),

  handle(ACCOUNT.AUTHORIZATION_SUCCESS, (state, { entities }) => {
    if (!entities.orders) {
      return state;
    }

    const deliveryMethods = Object.values(entities.orders).map(order => {
      if (!order.delivery || !order.delivery.deliveryMethod) {
        return null;
      }
      return DeliveryMethod.normalize(order.delivery.deliveryMethod);
    });

    return deliveryMethods.reduce((state, item) => {
      if (!item) {
        return state;
      }

      return {
        ...state,
        [item.id]: item
      };
    }, state);
  }),

  handle(PROFILE.RESPONSE, (state, { entities }) => {
    if (!entities.orders) {
      return state;
    }

    const deliveryMethods = Object.values(entities.orders).map(order => {
      if (!order.delivery || !order.delivery.deliveryMethod) {
        return null;
      }
      return DeliveryMethod.normalize(order.delivery.deliveryMethod);
    });

    return deliveryMethods.reduce((state, item) => {
      if (!item) {
        return state;
      }

      return {
        ...state,
        [item.id]: item
      };
    }, state);
  })
);

export default reducer({});

const getAll = state => state.ordering.deliveryMethodTable;

const getDeliveryMethodWithBasicInfo = basicDeliveryMethods => method => {
  const finalDeliveryMethod = {
    ...method,
    price: {
      ...method.price,
      current: method.price.default // TODO: Add discount to calculation
    }
  };

  return {
    ...basicDeliveryMethods[method.name],
    ...finalDeliveryMethod
  };
};

export const getFindItemById = createSelector(
  getAll,
  fromDeliveryMethods.getAll,
  (all, basicDeliveryMethods) => id => {
    if (!all || !id) {
      return null;
    }

    const method = all[id];

    if (!method) {
      return null;
    }

    return getDeliveryMethodWithBasicInfo(basicDeliveryMethods)(method);
  }
);

export const getFindPickPointsById = createSelector(
  getFindItemById,
  findItemById => methodId => {
    const method = findItemById(methodId);

    if (!method) {
      return null;
    }

    return method.pickPoints;
  }
);
