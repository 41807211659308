import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { getBasketLink } from 'routes/links';

import IconBasketAdd from 'modules/core/components/icons/icon-basket-add/icon-basket-add';
import Amount from 'modules/ordering/components/amount/amount';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Button from 'modules/core/components/button/button';

import './buy-form.css';

class BuyForm extends Component {
  static propTypes = {
    /** находится ли товар в корзине */
    isInBasket: PropTypes.bool.isRequired,
    /** кол-во едениц добавляемого товара в корзину */
    amount: PropTypes.number.isRequired,
    /** Обработчик изменения значения Аmount */
    onAmountChange: PropTypes.func.isRequired,
    /** Обработчик добавления продукта в корзину */
    onAddToBasket: PropTypes.func.isRequired
  };

  render() {
    const {
      isAvailable,
      isInBasket,
      amount,
      amountInBasket,
      onAmountChange,
      onAddToBasket,
      onButtonAddToWaitingListClick,
      isWaitingListRequestLoading,
      isGift,
      isBasketFetching
    } = this.props;

    if (!isAvailable) {
      return (
        <Media query="(max-width: 840px)">
          {isMobile => (
            <div className="BuyForm-buttonAddToWaitList">
              <Button
                title="Сообщить о появлении"
                onClick={onButtonAddToWaitingListClick}
                size={isMobile ? 'small' : 'normal'}
                expanded={false}
                disabled={isWaitingListRequestLoading}
              />
            </div>
          )}
        </Media>
      );
    }

    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <div className="BuyForm">
            {!isMobile && !isInBasket && (
              <div className="BuyForm-amount">
                <Amount card value={amount} disabled={isGift} onChange={onAmountChange} />
              </div>
            )}
            {isInBasket && (
              <div className="BuyForm-toBasket">
                <Button
                  title={
                    <Fragment>
                      В корзине (
                      <span style={{ textTransform: 'none' }}>{amountInBasket}&nbsp;шт.</span>)
                    </Fragment>
                  }
                  component={Link}
                  to={getBasketLink()}
                  expanded={isMobile}
                  disabled={isGift}
                />
              </div>
            )}

            {isInBasket ? (
              <div className="BuyForm-more">
                {' '}
                {isMobile ? (
                  <Button
                    variant="primary"
                    icon={<IconBasketAdd />}
                    disabled={isGift || isBasketFetching}
                    onClick={onAddToBasket}
                  />
                ) : (
                  <Tooltip
                    content={
                      <span>
                        <b>{amountInBasket} шт.</b> в корзине
                      </span>
                    }
                    title="Добавить ещё"
                    size="small"
                    showDelay={0}
                    hideDelay={200}
                  >
                    <Button
                      variant="primary"
                      icon={<IconBasketAdd />}
                      disabled={isGift || isBasketFetching}
                      onClick={onAddToBasket}
                    />
                  </Tooltip>
                )}
              </div>
            ) : (
              <div className="BuyForm-submit">
                <Button
                  title="Купить"
                  variant="primary"
                  expanded={isMobile}
                  disabled={isGift || isBasketFetching}
                  onClick={onAddToBasket}
                />
              </div>
            )}
          </div>
        )}
      </Media>
    );
  }
}
export default BuyForm;
