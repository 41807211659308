import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { UnmountClosed } from 'react-collapse';

import { IconAngleArrowDown, IconAngleArrowUp } from '../../icons/index';
import IconContainer from '../../icon-container/icon-container';

import './accordion-item.css';

const { func, node, bool, string } = PropTypes;

const KEY_UP = 38;
const KEY_DOWN = 40;

function OptionalButton({ clickable, onKeyDown, onClick, buttonRef, children, ...props }) {
  if (clickable) {
    return (
      <button type="button" onKeyDown={onKeyDown} onClick={onClick} ref={buttonRef} {...props}>
        {children}
      </button>
    );
  }

  return <div {...props}>{children}</div>;
}

export default class AccordionItem extends Component {
  static propTypes = {
    /** title of heading */
    title: string.isRequired,
    /** collapsable content */
    panel: node.isRequired,
    /** label (additional info) of element */
    label: node,
    /** callback by close/open panel */
    onVisibilityChange: func,
    /** set visibility of panel */
    visible: bool,
    /** set element in focus */
    focused: bool,
    /** if panel should collapsed*/
    collapsed: bool,
    /** fadeIn effect panel appearance  */
    fade: bool,
    /** set heading clickable */
    clickableHeading: bool,
    /** onFocusNext */
    onFocusNext: func,
    /** onFocusPrevious */
    onFocusPrevious: func
  };

  toggleButton = null;

  render() {
    const {
      title,
      label,
      visible,
      animatedCollapsing,
      anchor,
      clickableHeading
      // itemRef
    } = this.props;

    return (
      <div
        className={cn('AccordionItem', {
          'AccordionItem--clickableHeading': clickableHeading,
          'AccordionItem--isOpened': visible
        })}
        id={anchor}
      >
        <OptionalButton
          className="AccordionItem-heading"
          clickable={clickableHeading}
          onKeyDown={this.handleKeyDown}
          onClick={this.handleTriggerClick}
          buttonRef={this.setToggleButtonRef}
        >
          <div className="AccordionItem-titleHolder">
            <div className="AccordionItem-title" title={title}>
              {title}
            </div>
            {label && <div className="AccordionItem-label">{label}</div>}
          </div>
          <OptionalButton
            className="AccordionItem-trigger"
            clickable={!clickableHeading}
            onKeyDown={this.handleKeyDown}
            onClick={this.handleTriggerClick}
            buttonRef={this.setToggleButtonRef}
            title={clickableHeading && title}
          >
            <div className="AccordionItem-triggerIcon">
              <IconContainer size="xsmall">
                {visible ? <IconAngleArrowUp /> : <IconAngleArrowDown />}
              </IconContainer>
            </div>
          </OptionalButton>
        </OptionalButton>
        {animatedCollapsing ? (
          <UnmountClosed isOpened={visible}>{this.renderPanel()}</UnmountClosed>
        ) : (
          visible && this.renderPanel()
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setTriggerFocus();
  }

  renderPanel() {
    const { fade, panel /*openedAccordionItemRef*/ } = this.props;

    return (
      <div
        className={cn('AccordionItem-panel', {
          'AccordionItem-panel--fadein': fade
        })}
        // ref={openedAccordionItemRef}
      >
        {panel}
      </div>
    );
  }

  componentDidUpdate() {
    this.setTriggerFocus();
  }

  close() {
    const { onVisibilityChange } = this.props;
    onVisibilityChange(false);
  }

  open() {
    const { onVisibilityChange } = this.props;
    onVisibilityChange(true);
  }

  toggle() {
    const { onVisibilityChange, visible } = this.props;
    onVisibilityChange(!visible);
  }

  focus() {
    this.toggleButton.focus();
  }

  focusNext() {
    const { onFocusPrevious } = this.props;
    if (typeof onFocusPrevious === 'function') {
      onFocusPrevious();
    }
  }
  focusPrevious() {
    const { onFocusNext } = this.props;
    if (typeof onFocusNext === 'function') {
      onFocusNext();
    }
  }

  setTriggerFocus() {
    const { focused } = this.props;
    if (focused) {
      this.focus();
    }
  }

  handleTriggerClick = e => {
    this.toggle();
    /** prevent scroll to item top if item oi opened*/
    // if (visible) {
    //   e.preventDefault();
    // }
  };

  handleKeyDown = e => {
    const keyCode = e.keyCode;
    if (keyCode === KEY_UP) {
      e.preventDefault();
      this.focusNext();
    } else if (keyCode === KEY_DOWN) {
      e.preventDefault();
      this.focusPrevious();
    }
  };

  setToggleButtonRef = element => {
    if (!element) return;
    this.toggleButton = element;
  };
}
