import React, { Component } from 'react';
import './button-close.css';
// import { IconCross } from '../icons';
import { IconClose } from '../icons';
import IconContainer from '../icon-container/icon-container';

class ButtonClose extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <button className="ButtonClose" {...rest}>
        <IconContainer size="small">
          <IconClose />
          {/* <IconCross /> */}
        </IconContainer>
      </button>
    );
  }
}

export default ButtonClose;
