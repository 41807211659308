import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import cn from 'classnames';

import './dialog.css';
import ButtonClose from 'modules/core/components/button-close/button-close';
import Button from 'modules/form/components/button/button';
import EventBoundary from '../event-boundary/event-boundary';
import LockScroll from 'modules/core/components/lock-scroll/lock-scroll';
import { Transition, animated } from 'react-spring';

import isMobile from 'modules/utils/is-mobile';
import isReducedMotion from 'modules/utils/is-reduced-motion';

const springConfig = {
  mass: 1, // spring mass
  tension: 300, // spring energetic load
  friction: 25 // spring resistence
};

class Dialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    acceptable: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        title: PropTypes.string
      })
    ]),
    cancelable: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        title: PropTypes.string
      })
    ]),
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    show: PropTypes.bool,
    closeable: PropTypes.bool, // FIXME: change `closeable` to correct one `closable`
    /** Is modal has to be with max width */
    expanded: PropTypes.bool
  };

  render() {
    const { title, children, show, closeable, expanded, overflowVisible } = this.props;
    const { handleOverlayClick, handlerCloseWindow } = this;

    return (
      <Fragment>
        {show && <LockScroll />}
        <Transition
          native
          config={springConfig}
          items={show}
          from={{ opacity: 0, transform: 'scale(0.8)' }}
          enter={{ opacity: 1, transform: 'scale(1)' }}
          leave={{ opacity: 0, transform: 'scale(0.8)' }}
          immediate={isReducedMotion() || isMobile()}
        >
          {show =>
            show &&
            (props => (
              <Portal>
                <EventBoundary>
                  <animated.div
                    className={cn('Dialog', {
                      'Dialog--expanded': expanded,
                      'Dialog--overflowVisible': overflowVisible
                    })}
                    onMouseDown={handleOverlayClick}
                    style={{ opacity: props.opacity }}
                  >
                    <div className="Dialog-container">
                      <animated.div
                        style={props}
                        className="Dialog-window"
                        ref={this.setDialogWindowRef}
                      >
                        {closeable && (
                          <div className="Dialog-closeButton">
                            <ButtonClose onClick={handlerCloseWindow} />
                          </div>
                        )}
                        {title && (
                          <div className="Dialog-header">
                            <h3 className="Dialog-title">{title}</h3>
                          </div>
                        )}
                        <div className="Dialog-content">{children}</div>
                        {this.renderFooter()}
                      </animated.div>
                    </div>
                  </animated.div>
                </EventBoundary>
              </Portal>
            ))
          }
        </Transition>
      </Fragment>
    );
  }

  renderFooter() {
    let actions = [];
    const { acceptable, cancelable } = this.props;

    if (acceptable) {
      actions.push(
        <div className="Dialog-action">
          <Button
            onClick={this.handleAcceptDialog}
            primary
            title={acceptable.title || 'Подтвердить'}
          />
        </div>
      );
    }

    if (cancelable) {
      actions.push(
        <div className="Dialog-action">
          <Button onClick={this.handlerCloseWindow} title={cancelable.title || 'Отменить'} />
        </div>
      );
    }

    if (!actions.length) {
      return null;
    }

    return (
      <div className="Dialog-footer">
        <div className="Dialog-actionList">{actions}</div>
      </div>
    );
  }

  setDialogWindowRef = element => {
    this.dialogWindow = element;
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleAcceptDialog = () => {
    this.props.onAccept();
    this.close();
  };

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  handlerCloseWindow = () => {
    this.close();
  };

  handleOverlayClick = e => {
    const target = e.target;
    if (this.isOverlay(target)) {
      this.close();
    }
  };

  close() {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  isOverlay = target => {
    return !this.dialogWindow.contains(target);
  };
}

export default Dialog;
