import React, { Component } from 'react';
import { IconEye } from 'modules/core/components/icons';

import Button from 'modules/core/components/button/button';

class ButtonQuickView extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Button
        title="Быстрый просмотр"
        variant="secondary"
        size="small"
        icon={<IconEye />}
        {...rest}
        expanded
      />
    );
  }
}

export default ButtonQuickView;
