import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconContainer from 'modules/core/components/icon-container/icon-container';

import './icon-button.css';

const { string, element, oneOfType, func } = PropTypes;

const buttonToIconSize = {
  normal: 'small',
  small: 'xsmall'
};

class IconButton extends Component {
  static propTypes = {
    /**
     * Social network icon
     **/
    icon: element.isRequired,

    /**
     * Icon container size
     **/
    size: string,

    /**
     * Component container component tag
     **/
    component: oneOfType([string, func])
  };

  static defaultProps = {
    component: 'button',
    size: 'normal'
  };

  render() {
    const { component: ButtonComponent, icon, size, name, ...rest } = this.props;
    return (
      <ButtonComponent
        className={classNames('Social_IconButton', `Social_IconButton--size-${size}`)}
        type="button"
        {...rest}
      >
        <IconContainer size={buttonToIconSize[size]}>{icon}</IconContainer>
      </ButtonComponent>
    );
  }
}

export default IconButton;
