import React from 'react';
import Media from 'react-media';

import Loader from '../loader/loader';
import Container from '../container/container';

import './redirect-payment.css';

const RedirectPayment = () => {
  return (
    <Container>
      <Media query="(max-width: 480px)">
        {isMobile => (
          <div className="RedirectPayment">
            <div className="RedirectPayment-content">
              <div className="RedirectPayment-loader">
                <Loader size={isMobile ? 'small' : 'normal'} />
              </div>

              <h1 className="RedirectPayment-title">
                Пожалуйста, подождите идёт переход <nobr>к оплате...</nobr>
              </h1>
            </div>
          </div>
        )}
      </Media>
    </Container>
  );
};

export default RedirectPayment;
