import React, { Component, Fragment } from 'react';
import './instagram-post.css';
import MarkerLink from 'modules/core/components/marker-link/marker-link';
import { IconComments, IconHeart } from 'modules/core/components/icons';
// import textSlicer from 'modules/utils/text-slicer';
import textSlicer from './helpers/text-slicer';
import Media from 'modules/core/components/media/media';

const CHARACTERS_AMOUNT_ON_DESKTOP = 220;
const CHARACTERS_AMOUNT_ON_MOBILE = 110;

function replaceHashtagsWithLinks(text) {
  return text.replace(
    /#(\S*)/g,
    "<a href='https://www.instagram.com/explore/tags/$1' class='InstagramPost-hashTag' title='www.instagram.com/explore/tags/$1' target='_blank' rel='noopener noreferrer'>#$1</a>"
  );
}

function replaceNickNameWithLinks(text) {
  return text.replace(
    /@(\S*)/g,
    "<a href='https://www.instagram.com/$1' class='InstagramPost-userLink' title='www.instagram.com/$1' target='_blank' rel='noopener noreferrer'>@$1</a>"
  );
}

function replaceLineWrapWithBR(text) {
  return text.replace(/\n/g, '<br/>');
}

class InstagramPost extends Component {
  render() {
    const { instagramReview, isMobile, squareImage } = this.props;

    const { description, link, media, instagram_author, counters } = instagramReview;

    const charactersAmount = isMobile ? CHARACTERS_AMOUNT_ON_MOBILE : CHARACTERS_AMOUNT_ON_DESKTOP;

    const postTextSliced = textSlicer(description, charactersAmount);
    const textWithBR = replaceLineWrapWithBR(postTextSliced);
    const postTextWithHashtagLinks = replaceHashtagsWithLinks(textWithBR);
    const postTextWithUserLinks = replaceNickNameWithLinks(postTextWithHashtagLinks);

    function getPostMarkup() {
      return { __html: postTextWithUserLinks };
    }

    return (
      <div className="InstagramPost">
        <Fragment>
          <div className="InstagramPost-visualGroup">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="InstagramPost-imageLink"
              title="Перейти на страницу поста в Instagram"
            >
              {squareImage ? (
                <span className="InstagramPost-imageContainer">
                  <Media media={media} stretch="horizontal" resizeWidth={520} ratio={1} nocaption />
                </span>
              ) : (
                <Media media={media} stretch="horizontal" resizeWidth={520} nocaption />
              )}
            </a>
            <a
              href={instagram_author.url}
              className="InstagramPost-profileLink"
              title="Перейти на страницу профиля автора"
              target="_blank"
              rel="noopener noreferrer"
            >
              {instagram_author.name}
            </a>
          </div>

          <div className="InstagramPost-contentGroup">
            {(!!counters.likes || !!counters.comments) && (
              <div className="InstagramPost-actionGroup">
                {!!counters.likes && (
                  <div className="InstagramPost-actionItem">
                    <MarkerLink
                      title={counters.likes}
                      ico={IconHeart}
                      tag="a"
                      href={link}
                      underline
                    />
                  </div>
                )}
                {!!counters.comments && (
                  <div className="InstagramPost-actionItem">
                    <MarkerLink title={counters.comments} ico={IconComments} tag="a" href={link} />
                  </div>
                )}
              </div>
            )}

            <div
              className="InstagramPost-caption"
              title={description}
              dangerouslySetInnerHTML={getPostMarkup()}
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default InstagramPost;
