import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReviewRating from 'modules/review/components/review-rating/review-rating';
import plural from 'modules/utils/plural';
import isFunction from 'modules/utils/is-function';

import { HashLink } from 'react-router-hash-link';
import Link from 'modules/core/components/link/short-link';

import './card-reviews.css';

const reviewsMeasureStrings = ['отзыв', 'отзыва', 'отзывов'];
// TODO: move to review module
class CardReviews extends Component {
  static propTypes = {
    /**
     *
     */
    productLink: PropTypes.string.isRequired,
    reviews: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired
  };

  render() {
    const { productLink, reviews, rating } = this.props;

    return (
      <div className="CardReviews">
        <div className="CardReviews-info">
          <Link
            className="CardReviews-link"
            component={HashLink}
            to={{
              pathname: productLink,
              hash: '#reviews'
            }}
            scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            onClick={this.handleReviewClick}
          >
            {reviews ? ` ${reviews} ${plural(reviews, reviewsMeasureStrings)}` : 'Оставить отзыв'}
          </Link>
        </div>

        {!!rating && (
          <div className="CardReviews-rating">
            <ReviewRating rating={rating} />
          </div>
        )}
      </div>
    );
  }

  handleReviewClick = () => {
    const { onReviewClick } = this.props;

    if (!isFunction(onReviewClick)) {
      return;
    }

    onReviewClick();
  };
}

export default CardReviews;
