import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { Page, PageContent } from '../components/page';
import { getIndexLink } from 'routes/links';
import ComplainFormContainer from 'modules/core/containers/complain-form-container';

const title = 'Остались вопросы, напиши нам';

class AskQuestionPage extends Component {
  static path = '/ask-question';
  static link = () => 'ask-question';
  static exact = true;

  render() {
    return (
      <Page
        title={title}
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: title
          }
        ]}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
        </Helmet>
        <PageContent>
          <ComplainFormContainer 
            description={"Задай вопрос, и мы с Тобой обязательно свяжемся в ближайшее время!"} 
            withoutFile 
            options={{textAreaTitle:"Ваш вопрос", textAreaPlaceholder: "Ваш вопрос", submitTitle: "Отправить вопрос"}}
          />
        </PageContent>
      </Page>
    );
  }
}

export default AskQuestionPage;
