import * as Product from './product';
import nanoid from 'nanoid';

export function buildItem(productObject, variant = null, amount = 1, gift = false, price = null) {
  const product = Product.getIdentifier(productObject);
  const itemPrice =
    price || productObject.price.current
      ? {
          default: productObject.price.default,
          discount: {
            numerical: productObject.price.discount
          }
        }
      : productObject.price;
  let uid = product;

  if (gift) {
    uid = [uid, 'gift', nanoid()].join(':');
  }

  return {
    uid,
    product,
    price: itemPrice,
    variant,
    amount
  };
}

export function serialize(goods) {
  const { product, variant, amount, gift } = goods;

  if (gift) {
    return null;
  }

  // FIXUP: return immutable gift beheviour

  return {
    product: {
      slug: product
    },
    variant_id: variant,
    quantity: amount
  };
}

export function deserialize(externalGoods) {
  const { product, price, quantity, gift, variant_id, promo_id } = externalGoods;
  const amount = parseInt(quantity, 10) || 1;

  return {
    product: Product.getIdentifier(product),
    amount,
    gift,
    variant: variant_id,
    promoId: parseInt(promo_id, 10) || null,
    price
  };
}
