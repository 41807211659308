import { createReducer, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';

const CHANGE_CITY_FIAS_ID = 'ordering/delivery-form/CHANGE_CITY_FIAS_ID';
const CHANGE_CITY_GEONAME_ID = 'ordering/delivery-form/CHANGE_CITY_GEONAME_ID';
const SET_INITIAL_DATA = 'ordering/delivery-form/SET_INITIAL_DATA';

const cityTable = createReducer(
  on(SET_INITIAL_DATA, (state, { city }) => {
    if (!city || !city.title) {
      return state;
    }
    return {
      ...state,
      [city.fiasId]: city
    };
  }),

  on(CHANGE_CITY_FIAS_ID, (state, { fiasId, city }) => {
    return {
      ...state,
      [fiasId]: city
    };
  }),

  on(CHANGE_CITY_GEONAME_ID, (state, { geonameId, city }) => {
    return {
      ...state,
      [geonameId]: city
    };
  })
);

export default cityTable({});

export const getRoot = state => state.ordering.cityTable;
export const getAll = createSelector(
  getRoot,
  root => root
);

export const getCityByFiasId = createSelector(
  getAll,
  (_, fiasId) => fiasId,
  (all, fiasId) => {
    if (!all || !fiasId) {
      return null;
    }

    return all[fiasId];
  }
);

export const getCityByGeonameId = createSelector(
  getAll,
  (_, geonameId) => geonameId,
  (all, geonameId) => {
    if (!all || !geonameId) {
      return null;
    }

    return all[geonameId];
  }
);

export const getFindCityById = createSelector(
  getAll,
  all => {
    return id => {
      if (!all || !id) {
        return null;
      }

      if (id.fiasId) {
        return all[id.fiasId];
      }

      if (id.geonameId) {
        return all[id.geonameId];
      }

      return null;
    };
  }
);
