import React, { Component } from 'react';
import './icon-basket-add.css';

class IconBasketAdd extends Component {
  render() {
    return (
      <svg className="IconBasketAdd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          d="M10.477 5.177a.52.52 0 0 1 .52-.52h7.293a.52.52 0 0 1 0 1.04h-7.292a.52.52 0 0 1-.52-.52z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M14.644 1.01a.52.52 0 0 1 .52.521v7.292a.52.52 0 0 1-1.041 0V1.53a.52.52 0 0 1 .52-.52z"
          clipRule="evenodd"
        />
        <path d="M10.75 19.656c.938 0 1.667.73 1.667 1.667s-.73 1.667-1.667 1.667-1.667-.73-1.667-1.667c0-.938.73-1.667 1.667-1.667zM17.208 19.656c.938 0 1.667.73 1.667 1.667s-.73 1.667-1.667 1.667-1.666-.73-1.666-1.667c0-.938.729-1.667 1.666-1.667z" />
        <path
          fillRule="evenodd"
          d="M2 6.323a.52.52 0 0 1 .52-.52h3.438c.23 0 .432.15.499.369l3.013 9.943h10.238a.52.52 0 1 1 0 1.041H9.083a.52.52 0 0 1-.498-.37L5.572 6.845H2.52A.52.52 0 0 1 2 6.323z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M8.042 13.927a.52.52 0 0 1 .52-.52h12.292a.52.52 0 1 1 0 1.04H8.563a.52.52 0 0 1-.521-.52zM7.208 11.219a.52.52 0 0 1 .521-.521h13.75a.52.52 0 0 1 0 1.042H7.73a.52.52 0 0 1-.52-.521z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
}

export default IconBasketAdd;
