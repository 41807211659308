import React, { Component } from 'react';

import Container from '../container/container';

import './section-error-404.css';

class SectionError404 extends Component {
  render() {
    const { children } = this.props;
    return (
      <section className="SectionError404">
        <div className="SectionError404-content">
          <Container>{children}</Container>
        </div>
        <div className="SectionError404-background">
          <div className="SectionError404-leftCol" />
          <div className="SectionError404-centerCol" />
          <div className="SectionError404-rightCol" />
        </div>
      </section>
    );
  }
}

export default SectionError404;
