import React, { Component, Fragment } from 'react';
import plural from 'modules/utils/plural';

class CategoryFilterSummary extends Component {
  render() {
    const { filteredAmount, totalAmount, isFiltersApplied } = this.props;
    const amount = filteredAmount || 0;
    const total = totalAmount || 0;
    const pluralTitle = plural(amount, ['товар', 'товара', 'товаров']);
    const beginning = isFiltersApplied ? 'Подобрано' : 'Показано';

    if (!total) {
      return 'Товаров по заданным фильтрам не найдено';
    }

    return (
      <Fragment>
        {beginning} <b>{amount}</b> {pluralTitle} из {total}
      </Fragment>
    );
  }
}

export default CategoryFilterSummary;
