import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './order-table.css';

import Header from './order-table-header';
import Row from './order-table-row';
import SubRow from './order-table-sub-row';
import Cell from './order-table-cell';
import SubCell from './order-table-sub-cell';

class OrderTable extends Component {
  static propTypes = {
    prop: PropTypes
  };

  static Header = Header;
  static Row = Row;
  static SubRow = SubRow;
  static Cell = Cell;
  static SubCell = SubCell;

  render() {
    const { children } = this.props;
    return <div className="OrderTable">{children}</div>;
  }
}

export default OrderTable;
