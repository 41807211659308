export default {
  cityList: [
    {
      id: 1,
      title: 'Воронеж',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 52.753994,
        lng: 37.622093
      },
      pickPointIdList: [1, 12]
    },
    {
      id: 2,
      title: 'Волгоград',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 33.753994,
        lng: 37.622093
      },
      pickPointIdList: [2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      id: 3,
      title: 'Екатеринбург',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 12.753994,
        lng: 88.622093
      },
      pickPointIdList: [3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
      id: 4,
      title: 'Казань',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 62.753994,
        lng: 58.622093
      },
      pickPointIdList: [7, 8, 9, 10, 11, 12]
    },
    {
      id: 5,
      title: 'Краснодар',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 22.753994,
        lng: 38.622093
      },
      pickPointIdList: [1, 2, 3, 10, 11, 12]
    },
    {
      id: 6,
      title: 'Красноярск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 92.753994,
        lng: 18.622093
      },
      pickPointIdList: [11, 12]
    },
    {
      id: 7,
      title: 'Москва',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 12.753994,
        lng: 18.622093
      },
      pickPointIdList: [5, 6, 7, 8, 9, 10, 12]
    },
    {
      id: 8,
      title: 'Нижний Новгород',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 42.753994,
        lng: 39.622093
      },
      pickPointIdList: [1, 2, 3, 8, 9, 10, 12]
    },
    {
      id: 9,
      title: 'Новосибирск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 22.753994,
        lng: 23.622093
      },
      pickPointIdList: [5, 11]
    },
    {
      id: 10,
      title: 'Пермь',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 17.753994,
        lng: 89.622093
      },
      pickPointIdList: [1, 2, 3, 4, 5, 11]
    },
    {
      id: 11,
      title: 'Ростов-на-Дону',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 32.753994,
        lng: 29.622093
      },
      pickPointIdList: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    {
      id: 12,
      title: 'Самара',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 42.753994,
        lng: 49.622093
      },
      pickPointIdList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
      id: 13,
      title: 'Санкт-Петербург',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 54.753994,
        lng: 47.622093
      },
      pickPointIdList: [3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      id: 14,
      title: 'Уфа',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 11.753994,
        lng: 9.622093
      },
      pickPointIdList: [1, 2, 6, 7, 8, 9, 10]
    },
    {
      id: 15,
      title: 'Челябинск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 67.753994,
        lng: 47.622093
      },
      pickPointIdList: [1, 2, 3, 4, 10]
    },
    {
      id: 16,
      title: 'Черняховск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 81.753994,
        lng: 15.622093
      },
      pickPointIdList: [1, 2, 3, 4, 5, 6, 11]
    },
    {
      id: 17,
      title: 'Шимановск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 1.753994,
        lng: 2.622093
      },
      pickPointIdList: [5, 6, 7, 8, 9, 10]
    },
    {
      id: 18,
      title: 'Электрогорск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 6.753994,
        lng: 7.622093
      },
      pickPointIdList: [1, 2, 3, 4, 5, 6]
    },
    {
      id: 19,
      title: 'Якутск',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 88.753994,
        lng: 88.622093
      },
      pickPointIdList: [2, 3, 4, 5, 6, 7]
    },
    {
      id: 20,
      title: 'Янаул',
      fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      coordinates: {
        lat: 99.753994,
        lng: 1.622093
      },
      pickPointIdList: [5, 6, 7, 8, 9, 10]
    }
  ],
  selectedCityId: 1,
  isCityDialogShowed: false,
  isCityDropdownShowed: false
};
