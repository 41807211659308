import React, { Component } from 'react';
import UserOverviewContainer from '../../containers/user-overview';
import NavigationContainer from '../../containers/navigation';
import './sidebar.css';

class Sidebar extends Component {
  render() {
    return (
      <aside className="Profile_Sidebar">
        <div className="Profile_Sidebar-overview">
          <UserOverviewContainer />
        </div>
        <div className="Profile_Sidebar-navigation">
          <NavigationContainer />
        </div>
      </aside>
    );
  }
}

export default Sidebar;
