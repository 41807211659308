function urlSafeBase64(str) {
  return new Buffer(str)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}



const IMGPROXY_HOST =  'https://image.mixit.ru';
const IMGPROXY_ADV_HOST =  'https://image.mixit.adv.ru';

let USED_HOST = IMGPROXY_HOST
if (__BROWSER__) {
  const isAdv = window.location.href.indexOf(".adv.") !== -1
  if(isAdv) USED_HOST = IMGPROXY_ADV_HOST
}

function urlInsecureTemplate(options, encodedUrl, extension) {
  const ext = extension ? `.${extension}` : '';
  return `${USED_HOST}/insecure/${options}/${encodedUrl}${ext}`;
}

export default function generateResizedImageLink(url, options, local = false, renderWebp) {
  const finalUrl = local ? url.replace(/^https?:\/\//, 'local:///') : url;
  const encodedUrl = urlSafeBase64(finalUrl);
  const originalExtension = url
    .split('.')
    .pop()
    .toLowerCase();

  options.background = options.background && options.background.replace(/[^0-9A-Fa-f]/, '');

  // const usedExtension = renderWebp ? "webp" : originalExtension;

  // const extension = options.background ? 'jpg' : usedExtension;
  const extension = options.background ? 'jpg' : originalExtension;

  const usedExtension = renderWebp ? "webp" : extension;

  const optionString = Object.entries(options)
    .map(([key, value]) => (value ? `${key}:${value}` : false))
    .filter(Boolean)
    .join('/');

  return urlInsecureTemplate(optionString, encodedUrl, usedExtension);
}
