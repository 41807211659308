import { createReducer, handle } from 'modules/utils/dux';
import * as fromProducts from 'modules/product/ducks/types/products';
import { createSelector } from '../../utils/debug-reselect';

/* REDUCERS */

const reducer = createReducer(
  handle(fromProducts.ADD_TO_WAIT_LIST_REQUEST, state => {
    return {
      ...state,
      isLoading: true
    };
  }),

  handle(fromProducts.ADD_TO_WAIT_LIST_RESPONSE, state => {
    return {
      ...state,
      isLoading: false
    };
  })
);

export default reducer({
  isLoading: false
});

/* SELECTORS */

const getRoot = state => state.core.waitingList;

export const getIsLoading = createSelector(
  getRoot,
  root => root.isLoading
);
