import { Component } from 'react';
import LineNumberObserver from 'modules/utils/line-number-observer';
import isFunction from 'modules/utils/is-function';

class LineNumber extends Component {
  state = {
    lines: 0
  };

  render() {
    const { render } = this.props;
    const { lines } = this.state;

    if (!isFunction(render)) {
      throw new Error('Render prop must be a function');
    }

    return render({
      lines,
      elementProps: {
        ref: this.setChildElement
      }
    });
  }

  componentWillUnmount() {
    this.lineNumberObserver.release();
  }

  componentDidUpdate() {
    this.lineNumberObserver.recalculate();
  }

  handleLineNumberChange = lines => {
    const { onChange } = this.props;

    this.setState({
      lines
    });

    if (!isFunction(onChange)) {
      return;
    }

    onChange(lines);
  };

  setChildElement = element => {
    if (!element) {
      return;
    }

    this.childElement = element;

    if (this.lineNumberObserver) {
      this.lineNumberObserver.release();
    }

    this.lineNumberObserver = new LineNumberObserver(this.childElement);
    this.lineNumberObserver.subscribe(this.handleLineNumberChange);
  };
}

export default LineNumber;
