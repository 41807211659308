export const ADD_TO_BASKET = 'product/products/ADD_TO_BASKET';
export const CHANGE_OPTION = 'product/products/CHANGE_OPTION';

export const SHOW_ITEM = 'product/products/SHOW_ITEM';
export const OVERVIEW_SHOWED = 'product/products/OVERVIEW_SHOWED';

export const ADD_ITEM = 'product/products/ADD_ITEM';
export const ADD_DETAILS = 'product/products/ADD_DETAILS';

export const INSTANT_ORDER_STARTED = 'product/products/INSTANT_ORDER_STARTED';

export const ADD_TO_WAIT_LIST_REQUEST = 'product/products/ADD_TO_WAIT_LIST_REQUEST';
export const ADD_TO_WAIT_LIST_RESPONSE = 'product/products/ADD_TO_WAIT_LIST_RESPONSE';

export const CLICK = 'product/products/CLICK';
export const REVEAL = 'product/products/REVEAL';
export const POSITIONS_UPDATE = 'product/products/POSITIONS_UPDATE';
