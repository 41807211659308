import React, { Component } from 'react';
import { shape, string } from 'prop-types';
import Price from '../price-formatted/price-formatted';
import OrderStepSummary from '../order-step-summary/order-step-summary';

import './delivery-summary.css';

class DeliverySummary extends Component {
  static propTypes = {
    deliveryMethod: shape({
      additionalInfo: string.isRequired
    })
  };

  render() {
    const {
      subtitle,
      deliveryMethod,
      cityTitle,
      address,
      recipientName,
      recipientPhone,
      ...summaryProps
    } = this.props;

    return (
      <div className="DeliverySummary">
        <OrderStepSummary title={this.getStepTitle()} editAbility={true} {...summaryProps}>
          <div className="DeliverySummary-titleRow">{subtitle}</div>

          <div className="DeliverySummary-row">
            {cityTitle}, {address}
          </div>

          <div className="DeliverySummary-titleRow">Получатель</div>

          {recipientName && <div className="DeliverySummary-row">{recipientName}</div>}

          {recipientPhone && <div className="DeliverySummary-row">{recipientPhone}</div>}

          {deliveryMethod.additionalInfo && (
            <span className="DeliverySummary-hint">{deliveryMethod.additionalInfo}</span>
          )}
        </OrderStepSummary>
      </div>
    );
  }

  getStepTitle() {
    const { deliveryMethod, cost } = this.props;

    const title = deliveryMethod.title;
    const isPPD =
      deliveryMethod &&
      deliveryMethod.pickPointType &&
      deliveryMethod.pickPointType === 'PICK_POINT_TYPE/PPD';

    const RenderCost = isPPD ? (
      <span className="DeliverySummary-cost">
        <b>бесплатно</b>
      </span>
    ) : (
      <Price number={cost} variant="mark" />
    );

    return (
      <span className="DeliverySummary-title">
        {/* {title} – <Price number={cost} variant="mark" /> */}
        {/* {`${title} – ${renderCost}`} */}
        {title} – {RenderCost}
      </span>
    );
  }
}

export default DeliverySummary;
