import React, { Component } from 'react';

import './tile.css';

class Tile extends Component {
  render() {
    const { title, renderContent } = this.props;
    return (
      <div className="Payment_Tile">
        <h3 className="Payment_Tile-title">{title}</h3>
        <div className="Payment_Tile-content">{renderContent()}</div>
      </div>
    );
  }
}

export default Tile;
