// import searchList from './seeds/search-list';
import { combineReducers } from 'redux';
import { createReducer } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import qs from 'qs';
// import memoize from 'lodash/memoize';
import { createAction, createActionError, handle as on } from 'modules/utils/dux';
import * as SEARCH from './types/search';
import { getProducts } from './products/selectors';

const LOADING_STATE = {
  NOT_ASKED: 'LOADING_STATE/NOT_ASKED',
  LOADING: 'LOADING_STATE/LOADING',
  LOADED: 'LOADING_STATE/LOADED',
  FAILED: 'LOADING_STATE/FAILED'
};

/* ACTION_CREATORS */
export const actions = {
  changeGlobalQuery(query) {
    return createAction(SEARCH.GLOBAL_QUERY_CHANGE, { query });
  },

  clearGlobalQuery() {
    return createAction(SEARCH.GLOBAL_QUERY_CLEAR);
  },

  request(query, { category, filters, sort, skip, count } = {}) {
    return createAction(SEARCH.REQUEST, {
      query,
      category,
      filters,
      sort,
      skip,
      count
    });
  },

  response(query, searchResponse, entities) {
    return createAction(SEARCH.RESPONSE, { query, searchResponse, entities });
  },

  responseError(query, message) {
    return createActionError(SEARCH.RESPONSE, { query, message });
  },

  filterRequest(query, filters, { category, sort, skip, count } = {}) {
    return createAction(SEARCH.FILTER_REQUEST, {
      query,
      filters,
      category,
      sort,
      skip,
      count
    });
  },

  filterResponse(query, filters, searchResponse, entities) {
    return createAction(SEARCH.FILTER_RESPONSE, {
      query,
      filters,
      searchResponse,
      entities
    });
  },

  filterResponseError(query, filters, message) {
    return createActionError(SEARCH.FILTER_RESPONSE, {
      query,
      filters,
      message
    });
  }
};

/* REDUCERS */
const query = createReducer(
  on(SEARCH.GLOBAL_QUERY_CHANGE, (state, { query }) => query),
  on(SEARCH.GLOBAL_QUERY_CLEAR, () => '')
);

const result = createReducer(
  on(SEARCH.RESPONSE, (state, { query, searchResponse }, error) => {
    if (error) {
      return {
        ...state,
        [query]: {
          query,
          totalAmount: 0,
          _loadingState: LOADING_STATE.LOADED
        }
      };
    }

    return {
      ...state,
      [query]: {
        query,
        ...searchResponse,
        _partial: true,
        _loadingState: LOADING_STATE.LOADED
      }
    };
  }),

  on(SEARCH.FILTER_REQUEST, (state, { query, filters }) => {
    const filtered = {
      ...state[query].filtered,
      filters,
      products: null,
      _loadingState: LOADING_STATE.LOADING
    };

    return {
      ...state,
      [query]: {
        ...state[query],
        filtered
      }
    };
  }),

  on(SEARCH.FILTER_RESPONSE, (state, { query, filters, searchResponse }, error) => {
    if (!filters.length) {
      if (error) {
        return {
          ...state,
          [query]: {
            ...state[state],
            query,
            totalAmount: 0,
            _loadingState: LOADING_STATE.FAILED
          }
        };
      }

      return {
        ...state,
        [query]: {
          ...state[state],
          query,
          ...searchResponse,
          _loadingState: LOADING_STATE.LOADED
        }
      };
    }

    const filtered = error
      ? {
          filters,
          _loadingState: LOADING_STATE.FAILED
        }
      : {
          filters,
          products: searchResponse.products,
          totalAmount: searchResponse.totalAmount,
          _loadingState: LOADING_STATE.LOADED
        };

    return {
      ...state,
      [query]: {
        ...state[query],
        filtered
      }
    };
  })
);

const loadingState = createReducer(
  on(SEARCH.RESET, () => LOADING_STATE.NOT_ASKED),
  on(SEARCH.REQUEST, () => LOADING_STATE.LOADING),
  on(SEARCH.RESPONSE, (state, payload, error) => LOADING_STATE.LOADED)
);

export default combineReducers({
  query: query(''),
  result: result({}),
  _loadingState: loadingState(LOADING_STATE.NOT_ASKED)
});

/* SELECTORS */

export const getRoot = state => state.products.search;

export const getResultTable = createSelector(getRoot, root => root.result);

const getQueryString = ({ router }) => {
  return qs.parse(router.location.search, { ignoreQueryPrefix: true });
};

export const getGlobalQuery = createSelector(getRoot, root => {
  return root.query || null;
});

export const getAppliedFilters = createSelector(getQueryString, function _getAppliedFilters(query) {
  if (!query.filter) {
    return [];
  }

  if (typeof query.filter === 'string') {
    return [query.filter];
  }

  return query.filter;
});

export function makeGetResultByQuery(getQuery) {
  return createSelector(getQuery, getResultTable, function _getResults(query, resultTable) {
    if (!query || !resultTable) {
      return null;
    }

    return resultTable[query];
  });
}

export const getFindResult = createSelector(getResultTable, function _getResults(resultTable) {
  return query => {
    if (!query || !resultTable) {
      return null;
    }

    return resultTable[query];
  };
});

export function makeGetResultByQueryAndFilters(getQuery, getFilters) {
  return createSelector(getQuery, getFilters, getResultTable, function _getResults(
    query,
    filters,
    resultTable
  ) {
    if (!query || !resultTable) {
      return null;
    }

    if (!filters.length) {
      return resultTable[query];
    }

    const filtered = (resultTable[query] || {}).filtered;

    return {
      ...resultTable[query],
      ...filtered
    };
  });
}

export function makeGetCategoriesByQuery(getQuery) {
  return createSelector(makeGetResultByQuery(getQuery), function _getCategories(results) {
    if (!results) {
      return null;
    }

    return results.categories;
  });
}

export function makeGetProductListByQuery(getQuery) {
  return createSelector(makeGetResultByQuery(getQuery), function _getCategories(results) {
    if (!results) {
      return null;
    }

    return results.products;
  });
}

export function makeGetProductListByQueryAndFilters(getQuery, getFilters) {
  return createSelector(
    makeGetResultByQueryAndFilters(getQuery, getFilters),
    function _getCategories(results) {
      if (!results) {
        return null;
      }

      return results.products;
    }
  );
}

const getMicroMarkingObjectCreator = () => productList => {
  if (!productList || !productList.length > 0) {
    return null;
  }

  const offerCount = productList.length;

  const priceList = productList.map(item => {
    if (+item.price.discount.numerical) {
      return +item.price.default - +item.price.discount.numerical;
    }

    if (+item.price.discount.percentage) {
      return Math.ceil((1 - +item.price.discount.percentage) * +item.price.default);
    }

    return +item.price.default;
  });

  const highPrice = priceList.reduce((highPrice, item) => {
    if (item > highPrice) {
      return item;
    }

    return highPrice;
  }, 0);

  const lowPrice = priceList.reduce((lowPrice, item) => {
    if (item < lowPrice) {
      return item;
    }

    return lowPrice;
  }, priceList[0]);

  return { offerCount, highPrice, lowPrice };
};

export function getMicroMarkingObject(getQuery, getFilters) {
  return createSelector(
    makeGetResultByQueryAndFilters(getQuery, getFilters),
    getProducts,
    getMicroMarkingObjectCreator,
    function _getCategories(results, allProducts, microMarkingObjectCreator) {
      if (!results || !allProducts || !results.products) {
        return null;
      }

      const productList = results.products.map(item => allProducts[item]);

      return microMarkingObjectCreator(productList);
    }
  );
}

export function makeGetFilterListByQuery(getQuery) {
  return createSelector(makeGetResultByQuery(getQuery), function _getCategories(results) {
    if (!results) {
      return null;
    }

    return results.filters;
  });
}

export function makeGetAmountByQuery(getQuery) {
  return createSelector(makeGetResultByQuery(getQuery), function _getCategories(result) {
    if (!result) {
      return 0;
    }

    return result.totalAmount;
  });
}

export function makeGetAmountByQueryAndFilters(getQuery, getFilters) {
  return createSelector(
    makeGetResultByQueryAndFilters(getQuery, getFilters),
    function _getCategories(result) {
      if (!result) {
        return 0;
      }

      return result.totalAmount;
    }
  );
}

export function makeGetIsProductLoadedByQueryAndFilters(getQuery, getFilters) {
  return createSelector(
    makeGetResultByQueryAndFilters(getQuery, getFilters),
    function _getIsProductLoaded(result) {
      if (!result) {
        return false;
      }

      return result._loadingState === LOADING_STATE.LOADED;
    }
  );
}

export function makeGetIsResultLoadingByQuery(getQuery) {
  return createSelector(makeGetResultByQuery(getQuery), function _getIsResultLoading(result) {
    if (!result) {
      return false;
    }

    return result._loadingState === LOADING_STATE.LOADING;
  });
}

export const getIsLoaded = createSelector(getRoot, root => {
  return root._loadingState === LOADING_STATE.LOADED;
});

/* HELPERS */
