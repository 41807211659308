import React from 'react';
import cn from 'classnames';

import ActivityCard from './activity-card/activity-card';
import ActivityDetails from './activity-details/activity-details';

import SVGArrow from '../../svg-arrow/svg-arrow';

import './activity.css';

export default function Activity({ activity, colors }) {
  return (
    <div className={cn('Activity')}>
      <div className="Activity-card">
        <ActivityCard data={activity} colors={colors} />
      </div>
      <div className="Activity-arrow">
        <SVGArrow colors={colors} />
      </div>
      <div className="Activity-details">
        <ActivityDetails list={activity.list} colors={colors} />
      </div>
    </div>
  );
}
