import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MassMediaAction from '../ducks/actions';
import { getSort } from '../ducks/selectors';
import MassMediaSortPanel from '../components/mass-media-sort-panel/mass-media-sort-panel';

const mapDispatchToProps = {
  request: MassMediaAction.request
};

const mapState = createStructuredSelector({
  sort: getSort
});

class MassMediaSortPanelContainet extends Component {
  render() {
    const { sort } = this.props;

    return !!sort && <MassMediaSortPanel sort={sort} onChangeSort={this.onChangeSort} />;
  }

  onChangeSort = sort => {
    const { request } = this.props;

    request({ sort });
  };
}

export default connect(mapState, mapDispatchToProps)(MassMediaSortPanelContainet);
