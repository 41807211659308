import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

import SectionDefault from '../components/section-default/section-default';
import Container from '../components/container/container';

import { Page, PageContent } from '../components/page';
import SectionError404 from '../components/section-error-404/section-error-404';
import ErrorMessage from '../components/error-message/error-message';
import Status from '../components/status/status';

import { getRouterHistory } from 'modules/core/ducks/router-history';

class Error404 extends Component {
  static path = '/error';
  static link = () => 'error';
  static exact = true;

  render() {
    return (
      <Status code="404">
        <Page>
          <PageContent>
            <SectionError404>
              <ErrorMessage />
            </SectionError404>

            <SectionDefault>
              <Container>
                <FeedbackCall />
              </Container>
            </SectionDefault>
          </PageContent>
        </Page>
      </Status>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
    this.managePageReload();
  }

  managePageReload = () => {
    const isFirstPageInHistory = this.props.routerHistory.length <= 1;
    if (__BROWSER__ && !isFirstPageInHistory) {
      document.location.reload(true);
    }
  };
}

const mapStateToProps = createStructuredSelector({
  routerHistory: getRouterHistory
});

const enhancedError404 = connect(mapStateToProps)(Error404);
export default enhancedError404;
