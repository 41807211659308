import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const { string, element, oneOfType, arrayOf } = PropTypes;

export class DashboardRow extends Component {
  static propTypes = {
    /** React элемент(ы) - ячейки сетки  */
    children: oneOfType([element, arrayOf(element)]),
    /** Класс(ы), которые приходят DashboardStrictRow */
    className: string
  };

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div
        className={cn('DashboardGrid-row', {
          [className]: className
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

export default DashboardRow;
