import React from 'react';
import cn from 'classnames';

import './doubled-title.css';

function DoubledTitle({ text, light, options }) {
  const color = options && options.color ? options.color : null;
  const fontSize = options && options.fontSize ? options.fontSize : null;

  const holderStyle = {
    ...(Boolean(fontSize) && { fontSize })
  };
  const topStyle = {
    ...(Boolean(color) && { color })
  };

  const bottomStyle = {
    ...(!light && !Boolean(color) && { WebkitTextStrokeColor: '#262931' }),
    ...(Boolean(color) && { WebkitTextStrokeColor: color })
  };

  return (
    <div
      className={cn('DoubledTitle', {
        'DoubledTitle--light': light
      })}
      style={holderStyle}
    >
      <div className="DoubledTitle-holder">
        <div className="DoubledTitle-top" style={topStyle}>
          {text}
        </div>
        <div className="DoubledTitle-bottom" style={bottomStyle}>
          {text}
        </div>
      </div>
    </div>
  );
}

export default DoubledTitle;
