import React from 'react';
import cn from 'classnames';

import Media from 'modules/core/components/media/media';

import './circle-bordered-image.css';

export default function CircleBorderedImage({ inverted, image, colors }) {
  return (
    <div
      className={cn('CircleBorderedImage', {
        'CircleBorderedImage--inverted': inverted
      })}
    >
      <div className="CircleBorderedImage-imageHolder">
        <div className="CircleBorderedImage-circle" style={{ borderColor: colors[0] }}></div>
        <div className="CircleBorderedImage-circle" style={{ borderColor: colors[1] }}></div>
        <div className="CircleBorderedImage-image">
          <div className="CircleBorderedImage-imageSizer">
            <div className="CircleBorderedImage-imageInnerHolder">
              <Media media={image} alt="" stretch="horizontal" nocaption />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
