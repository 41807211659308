import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { Page } from 'modules/core/components/page';
import { getIndexLink } from 'routes/links';

import * as fromOrders from '../ducks/orders';
import * as fromAccount from 'modules/core/ducks/account';

import SuccessfulOrderContainer from '../containers/successful-order';
import Container from 'modules/core/components/container/container';
import SectionDefault from 'modules/core/components/section-default/section-default';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';

const mapState = createStructuredSelector({
  id: fromOrders.getIdByRouteParams,
  authIsReady: fromAccount.getStatusIsReady
});

const mapDispatch = {
  show: fromOrders.actions.show
};

function isReady(props) {
  return props.authIsReady;
}

class SuccessPage extends Component {
  static path = '/ordering/:id/success';
  static exact = true;

  render() {
    return (
      <Page
        title="Спасибо за заказ"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Спасибо за заказ'
          }
        ]}
      >
        {isReady(this.props) && (
          <Container narrow>
            <Helmet>
              <title>Спасибо за заказ</title>

              <meta name="description" content="Спасибо за заказ - интернет-магазин MIXIT" />
            </Helmet>
            <SuccessfulOrderContainer id={this.props.id} />
            <SectionDefault>
              <FeedbackCall />
            </SectionDefault>
          </Container>
        )}
      </Page>
    );
  }

  componentDidMount() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }
}

export default connect(mapState, mapDispatch)(SuccessPage);
