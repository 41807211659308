import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector, createSelector } from 'reselect';
import SearchOverlay from '../components/search-overlay/search-overlay';
import * as fromSearch from 'modules/product/ducks/search';
import * as fromRecent from 'modules/product/ducks/recent';
import * as fromProducts from 'modules/product/ducks/products';
import isFunction from 'modules/utils/is-function';
import { actions as Search } from 'modules/product/ducks/search';

import { getSearchLink } from 'routes/links';

const getQuery = fromSearch.getGlobalQuery;

const getResultsProductList = createSelector(
  fromSearch.makeGetProductListByQuery(getQuery),
  fromProducts.getFindProductBySlug,
  (slugList, findProduct) => {
    if (!slugList) {
      return [];
    }
    return slugList.map(findProduct);
  }
);

const getRecentProductList = createSelector(
  fromRecent.getList,
  fromProducts.getFindProductBySlug,
  (slugList, findProduct) => {
    if (!slugList) {
      return [];
    }
    return slugList.map(findProduct);
  }
);

const mapStateToProps = createStructuredSelector({
  query: fromSearch.getGlobalQuery,
  result: getResultsProductList,
  isLoading: fromSearch.makeGetIsResultLoadingByQuery(getQuery),
  recentProducts: getRecentProductList
});

const mapDispatchToProps = {
  push,
  changeQuery: fromSearch.actions.changeGlobalQuery,
  clearQuery: fromSearch.actions.clearGlobalQuery,
  searchRequest: Search.request
};

class SearchOverlayContainer extends Component {
  render() {
    const { recentProducts, result, ...rest } = this.props;

    return (
      <SearchOverlay
        {...rest}
        searchResult={result}
        recentProducts={recentProducts}
        onChange={this.handleChange}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
      />
    );
  }

  search(query) {
    if (!query) {
      return;
    }

    this.props.searchRequest(this.props.query);
    this.props.push(getSearchLink(query));
  }

  handleChange = query => {
    this.props.changeQuery(query);
  };

  handleClose = () => {
    this.props.clearQuery();

    if (isFunction(this.props.onClose)) {
      this.props.onClose();
    }
  };

  handleSubmit = query => {
    this.handleClose();
    this.search(query);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchOverlayContainer);
