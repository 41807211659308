import React, { Component } from 'react';

class VolumeSwitchContainer extends Component {
  render() {
    const { ariaLabelledby, ...rest } = this.props;
    return (
      <div className="VolumeSwitch" aria-labelledby={ariaLabelledby} {...rest}>
        {this.props.children}
      </div>
    );
  }
}
export default VolumeSwitchContainer;
