import xhr from 'xhr';

const DADATA_ADDRESS_API_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

const DADATA_CLEAN_API_URL = 'https://dadata.ru/api/v2/clean/address';

function checkResponse(response) {
  if (response.statusCode >= 200 && response.statusCode < 300) {
    return response;
  } else {
    let error = new Error(response);
    error.response = response;
    throw error;
  }
}

function parseBody(response) {
  return JSON.parse(response.body);
}

function getHeaders(token) {
  return {
    accept: 'application/json',
    authorization: `Token ${token}`,
    'content-type': 'application/json'
  };
}

function Request(method = 'get', url, headers, body) {
  let request;

  const promise = new Promise((resolve, reject) => {
    request = xhr(
      {
        method,
        url,
        headers,
        body
      },
      (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      }
    );
  })
    .then(checkResponse)
    .then(parseBody);

  request.isReady = () => request.readyState === 4;

  promise.request = request;

  return promise;
}

function Dadata(token) {
  token = '872e17f6cd47fd17d96d682f1d9a7e4d12d0efd3';

  return {
    city(query, { count = 6, withSettlements = false, locations = [] } = {}) {
      const data = {
        query,
        count,
        from_bound: { value: 'city' },
        to_bound: withSettlements ? { value: 'settlement' } : { value: 'city' },
        locations
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    street(cityFiasId, query, count = 6) {
      const location = {
        city_fias_id: cityFiasId
      };

      const data = {
        query,
        count,
        from_bound: { value: 'street' },
        to_bound: { value: 'house' },
        locations: [location],
        restrict_value: true
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    adress(query, count = 6) {
      const data = {
        query,
        count
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    clean(query) {
      const data = [query];

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_CLEAN_API_URL, headers, body);
    }
  };
}

export default Dadata;
