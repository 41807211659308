import { createReducer, handle as on /*, createAction */ } from 'modules/utils/dux';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import * as TYPES from '../types';
import * as fromArticleList from './article-list';
import LOADING_STATE from 'modules/core/constants/loading-state';

/* ACTIONS */

/* REDUCERS */

const list = createReducer(
  on(TYPES.MAGAZINE_HOME_RESPONSE, (state, { articles }, error) => {
    if (error) {
      return state;
    }
    return articles;
  })
);

const status = createReducer(
  on(TYPES.MAGAZINE_HOME_REQUEST, () => LOADING_STATE.LOADING),
  on(TYPES.MAGAZINE_HOME_RESPONSE, (state, payload, error) => {
    return error ? LOADING_STATE.FAILED : LOADING_STATE.LOADED;
  })
);

export default combineReducers({
  list: list([]),
  _status: status(LOADING_STATE.NOT_ASKED)
});

/* SELECTORS */

export const getRoot = state => state.blog.lastArticleList;

export const getSlugs = createSelector(
  getRoot,
  root => root.list
);

export const getList = createSelector(
  fromArticleList.getAll,
  getSlugs,
  (allArticles, lastArticleSlugs) => lastArticleSlugs.map(slug => allArticles[slug])
);

export const getStatus = createSelector(
  getRoot,
  root => root._status
);

export const getIsLoading = createSelector(
  getStatus,
  status => status !== LOADING_STATE.LOADED
);
