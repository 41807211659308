import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/recovery-password';
import { createSelector } from '../../utils/debug-reselect';

/* REDUCERS */

const reducer = createReducer(
  handle(TYPES.SAVE_CONFIRM_KEY, (state, { code }) => {
    return {
      ...state,
      confirmKey: code
    };
  }),

  handle(TYPES.RESET_ERROR, state => {
    return {
      ...state,
      _error: false
    };
  }),

  handle(TYPES.REQUEST, state => {
    return {
      ...state,
      _isSubmitting: true
    };
  }),

  handle(TYPES.START_RECOVERY, state => {
    return {
      ...state,
      _isSubmitting: true
    };
  }),

  handle(TYPES.ERROR, (state, { message }) => {
    return {
      ...state,
      _error: message,
      _isSubmitting: false
    };
  }),

  handle(TYPES.SET_SUBMITTING, state => {
    return {
      ...state,
      _isSubmitting: true
    };
  }),

  handle(TYPES.RESET_SUBMITTING, state => {
    return {
      ...state,
      _isSubmitting: false
    };
  }),

  handle(TYPES.SET_REGISTERED_EMAIL, (state, { email }) => {
    return {
      ...state,
      _registeredEmail: email
    };
  }),

  handle(TYPES.RESET_REGISTERED_EMAIL, state => {
    return {
      ...state,
      _registeredEmail: null
    };
  })
);

export default reducer({
  _error: false,
  _isSubmitting: false,
  _registeredEmail: null,
  confirmKey: null
});

/* SELECTORS */

const getRoot = state => state.core.recoveryPassword;

export const getError = createSelector(
  getRoot,
  root => root._error
);

export const getRegisteredEmail = createSelector(
  getRoot,
  root => root._registeredEmail
);

export const getConfirmKey = createSelector(
  getRoot,
  root => root.confirmKey
);

export const getIsSubmitting = createSelector(
  getRoot,
  root => root._isSubmitting
);
