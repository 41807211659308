import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PasswordRecoveryRequestDialog from '../components/password-recovery-request-dialog/password-recovery-request-dialog';
import actions from '../ducks/actions/recovery-password';
import * as fromDialogs from '../ducks/dialogs';
import * as fromAccount from 'modules/core/ducks/account';
import { getError, getIsSubmitting, getRegisteredEmail } from '../ducks/recovery-password';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsPasswordRecoveryRequestOpened,
  isSubmitting: getIsSubmitting,
  error: getError,
  defaultValue: getRegisteredEmail,
  accountEmail: fromAccount.getEmail
});

const mapDispatchToProps = {
  request: actions.request,
  startRecovery: actions.startRecovery,
  setSubmitting: actions.setSubmitting,
  resetSubmitting: actions.resetSubmitting,
  resetError: actions.resetError,
  resetRegisteredEmail: actions.resetRegisteredEmail,
  close: fromDialogs.actions.closePasswordRecoveryRequestDialog,
  openAuthDialog: fromDialogs.actions.openAuthDialog,
  openRegistrationDialog: fromDialogs.actions.openRegistrationDialog
};

class PasswordRecoveryRequestDialogContainer extends Component {
  render() {
    const { isOpened, error, isSubmitting, defaultValue, accountEmail } = this.props;

    return (
      <PasswordRecoveryRequestDialog
        show={isOpened}
        error={error}
        onClose={this.handleClose}
        onRegistrationOpen={this.handleRegisterOpen}
        onSubmit={this.handleSubmit}
        onAuthOpen={this.handleAuthOpen}
        onChange={this.handleChange}
        isSubmitting={isSubmitting}
        defaultValue={defaultValue || accountEmail}
      />
    );
  }

  handleAuthOpen = () => {
    const { openAuthDialog, resetError, resetSubmitting, resetRegisteredEmail } = this.props;
    resetError();
    resetRegisteredEmail();
    resetSubmitting();
    openAuthDialog();
  };

  handleRegisterOpen = () => {
    const { openRegistrationDialog, resetError, resetSubmitting } = this.props;
    resetError();
    resetSubmitting();
    openRegistrationDialog();
  };

  handleClose = () => {
    const { close, resetError, resetSubmitting, resetRegisteredEmail } = this.props;
    resetError();
    resetRegisteredEmail();
    resetSubmitting();
    close();
  };

  handleSubmit = email => {
    const { startRecovery } = this.props;
    // resetError();
    startRecovery(email);
    // setSubmitting();
  };

  handleChange = () => {
    const { resetError, error } = this.props;
    if (error) {
      resetError();
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryRequestDialogContainer);
