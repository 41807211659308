import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OrderProduct from '../components/order-product/order-product';
import * as fromProducts from 'modules/product/ducks/products';

const mapState = createStructuredSelector({
  product: fromProducts.getItemByPropsParams
});

class OrderProductContainer extends Component {
  render() {
    return <OrderProduct {...this.props} />;
  }
}

export default connect(mapState)(OrderProductContainer);
