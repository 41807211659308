import React, { Component } from 'react';

class PaginationBreak extends Component {
  render() {
    return (
      <div className="Pagination-break" data-testid="pagination-dots">
        ...
      </div>
    );
  }
}

export default PaginationBreak;
