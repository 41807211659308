import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PasswordRecoveryRequestSuccessDialog from '../components/password-recovery-request-success-dialog/password-recovery-request-success-dialog';
import * as fromDialogs from '../ducks/dialogs';

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsPasswordRecoveryRequestSuccessOpened
});

const mapDispatchToProps = {
  close: fromDialogs.actions.closePasswordRecoveryRequestSuccessDialog
};

class PasswordRecoveryRequestSuccessDialogContainer extends Component {
  render() {
    const { isOpened } = this.props;

    return <PasswordRecoveryRequestSuccessDialog show={isOpened} onClose={this.handleClose} />;
  }

  handleClose = () => {
    this.props.close();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryRequestSuccessDialogContainer);
