import React, { Component } from 'react';
import CitySuggest from '../components/city-suggest/city-suggest';
import PropTypes from 'prop-types';
import isFunction from 'modules/utils/is-function';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromCityList from '../ducks/city-list';

const mapStateToProps = createStructuredSelector({
  cityTitleList: fromCityList.getСityTitleList
});

class MixitCitySuggest extends Component {
  static propTypes = {
    selected: PropTypes.any,
    defaultOption: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    noPrimary: false,
    onlyRussia: true
  };

  state = {
    query: '',
    inputQuery: '',
    inputFocused: false,
    suggestions: [],
    suggestionIndex: -1,
    suggestionsVisible: true,
    isValid: false,

    /** Dropdown Select */
    isOpened: false,
    selected: false,
    focusIn: false
  };

  render() {
    const { defaultOption, ...rest } = this.props;

    const { suggestions, query, isOpened, selected, focusIn } = this.state;

    return (
      <CitySuggest
        {...rest}
        onFilterQueryChange={this.handleFilterQueryChange}
        onKeyDown={this.handleKeyDown}
        onOpen={this.open}
        onClose={this.close}
        onFocusIn={this.focusIn}
        onFocusOut={this.focusOut}
        onBlur={this.blur}
        onSelect={this.select}
        suggestions={suggestions}
        filterQuery={query}
        isOpened={query && isOpened}
        error={query && !suggestions.length && 'Городов по запросу не найдено'}
        selected={selected}
        focusIn={focusIn}
        inputValue={this.getInputValue()}
      />
    );
  }

  getInputValue() {
    const { query, selected, focusIn, suggestions } = this.state;
    const { selected: selectedId } = this.props;
    const selectedItem = this.getItemById(suggestions, selectedId);

    if (focusIn) {
      return query;
    }

    if (selectedItem !== null) {
      return selectedItem.title;
    }

    if (selected) {
      return suggestions[selected].title;
    }

    return query;
  }

  getItemById(items, selectedId) {
    if (!selectedId) {
      return null;
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].id === selectedId) {
        return items[i];
      }
    }

    return null;
  }

  open = () => {
    this.clearBlurTimer();
    this.setState({
      isOpened: true
    });
  };

  close = () => {
    this.clearBlurTimer();
    this.setState({
      isOpened: false,
      focusIn: false
    });
  };

  select = index => {
    const { onChange, onChangeFull } = this.props;
    const { suggestions } = this.state;

    this.setState({
      selected: index
    });

    if (isFunction(onChange)) {
      onChange(suggestions[index].id);
    }

    if (isFunction(onChangeFull)) {
      onChangeFull(suggestions[index]);
    }
  };

  applyFirst() {
    this.select(0);
    this.close();
  }

  focusIn = () => {
    this.setState({
      focusIn: true
    });
  };

  focusOut = () => {
    this.setState({
      focusIn: false
    });
  };

  blur = () => {
    this.clearBlurTimer();
    this._blurTimer = setTimeout(this.handleBlurTimeout, 100);
  };

  clearBlurTimer = () => {
    clearTimeout(this._blurTimer);
  };

  handleBlurTimeout = () => {
    const { focusIn } = this.state;
    if (focusIn) return;
    this.close();
  };

  getFilteredListByQuery = query => item => {
    const title = item.title.toLowerCase().trim();
    const _query = query.toLowerCase().trim();
    const isIncludesQuery = title.includes(_query);

    return isIncludesQuery;
  };

  handleFilterQueryChange = query => {
    const { cityTitleList } = this.props;

    const suggestions = cityTitleList.filter(this.getFilteredListByQuery(query));

    this.setState({
      query,
      inputQuery: query,
      isOpened: true,
      selected: null,
      suggestions
    });
  };

  handleKeyDown = e => {
    const { keyCode } = e;
    if (keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.applyFirst();
      return;
    }
  };
}

export default connect(mapStateToProps)(MixitCitySuggest);
