import BlogHomeResponseSchema from './schemas/blog-home-response-schema';
import ArticleSchema from './schemas/article-schema';
import MediaSchema from './schemas/media-schema';
import { normalize as _normalize } from 'normalizr';

export function normalize(apiBlogHomeResponse) {
  const media = MediaSchema();
  const article = ArticleSchema({ media });
  const blogHomeResponse = BlogHomeResponseSchema({ article });

  return _normalize(apiBlogHomeResponse, blogHomeResponse);
}
