import React, { Component } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromProfile from '../ducks/profile';

import UserOverview from '../components/user-overview/user-overview';

const mapStateToProps = createStructuredSelector({
  profile: fromProfile.getAll
});

class UserOverviewContainer extends Component {
  render() {
    const { profile, ...rest } = this.props;

    return <UserOverview profile={profile} {...rest} />;
  }
}

export default connect(mapStateToProps)(UserOverviewContainer);
