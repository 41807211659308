export default function initEmailFeedbackRequest(createRequest) {
  return function _getFeedbackRequest() {
    return {
      item: createRequest(initItemRequest())
    };
  };
}

function initItemRequest() {
  return function _itemItemRequest({ variant, option, comment }) {
    return {
      method: 'POST',
      path: '/email-feedback',
      data: {
        variant,
        option,
        comment
      }
    };
  };
}
