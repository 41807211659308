import React, { Component } from 'react';
import MediaQuery from 'react-media';
import cn from 'classnames';

// import { getIngredientLink } from 'routes/links';

import ingredientShape from 'modules/ingredient/prop-types/ingredient-details-shape';
import ButtonTooltip from 'modules/core/components/button-tooltip/button-tooltip';
import { IconArrowRight } from 'modules/core/components/icons';
import Tooltip from 'modules/core/components/tooltip/tooltip';
import Media from 'modules/core/components/media/media';

import IngredientDescriptionContainer from '../../containers/ingredient-description';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './ingredient.css';

class Ingredient extends Component {
  state = {
    isFullImageLoad: false
  };

  static propTypes = {
    ingredient: ingredientShape
  };

  render() {
    const { ingredient } = this.props;
    const { isFullImageLoad } = this.state;

    return (
      <div
        className={cn('Ingredient', {
          'Ingredient--withFullImage': isFullImageLoad
        })}
      >
        <div className="Ingredient-fullImage">
          <Media
            media={ingredient.media}
            resizeWidth={350}
            nocaption
            stretch="horizontal"
            onLoad={this.handleImageLoad}
          />
        </div>
        <div className="Ingredient-image">
          <Media
            media={ingredient.media}
            resizeWidth={100}
            blur={2}
            nocaption
            stretch="horizontal"
          />
        </div>

        {ingredient.short_description && (
          <MediaQuery
            query="(min-width: 841px)"
            render={() => (
              <div className="Ingredient-button">
                <Tooltip
                  title={ingredient.title}
                  content={<IngredientDescriptionContainer id={ingredient.id} />}
                  showDelay={0}
                >
                  <ButtonTooltip />
                </Tooltip>
              </div>
            )}
          />
        )}

        {/* <a href={getIngredientLink(ingredient)} className="Ingredient-link">
          <span className="Ingredient-linkText">{ingredient.title}</span>
          <span className="Ingredient-linkIcon">
            <IconArrowRight />
          </span>
        </a> */}
        <button onClick={() => this.props.openDialog(ingredient.id)} className="Ingredient-link">
          <span className="Ingredient-linkText">{ingredient.title}</span>
          <span className="Ingredient-linkIcon">
            <IconArrowRight />
          </span>
        </button>
      </div>
    );
  }

  showFullImage() {
    this.setState({ isFullImageLoad: true });
  }

  handleImageLoad = () => {
    this.showFullImage();
  };
}

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = {
  openDialog: fromDialogs.actions.openIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredient);
