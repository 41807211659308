import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PickPoint from '../components/pick-point/pick-point';

import * as fromPickPointList from '../ducks/pick-point-list';

const mapStateToProps = createStructuredSelector({
  pickPoint: fromPickPointList.getPickPoint
});

class PickPointContainer extends Component {
  render() {
    return <PickPoint {...this.props} onSelect={this.handleSelect} />;
  }

  handleSelect = pickPoint => {
    const { onSelect } = this.props;

    onSelect(pickPoint);
  };
}

export default connect(mapStateToProps)(PickPointContainer);
