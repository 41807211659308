import React, { Component } from 'react';
import './icon-arrow-left.css';

class IconArrowLeft extends Component {
  render() {
    return (
      <svg className="IconArrowLeft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.17 95.92">
        <path
          d="M1.9 52.54L43.38 94a6.48 6.48 0 0 0 9.17-9.16l-30.42-30.4h208.56a6.48 6.48 0 1 0 0-13H22.13l30.41-30.38a6.48 6.48 0 0 0-9.16-9.16L1.9 43.38a6.48 6.48 0 0 0 0 9.16zm0 0"
          data-name="Layer 1"
        />
      </svg>
    );
  }
}

export default IconArrowLeft;
