import React, { PureComponent } from 'react';
import './preheader.css';
import Container from '../container/container';
import GlobalNavigation from './global-navigation/global-navigation';
import HeaderInfo from './header-info/header-info';

class Preheader extends PureComponent {
  render() {
    return (
      <div className="Preheader">
        <Container>
          <div className="Preheader-content">
            <HeaderInfo />
            <GlobalNavigation />
          </div>
        </Container>
      </div>
    );
  }
}

export default Preheader;
