import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Form from 'modules/form/components/form/form';
import FormRow from 'modules/form/components/form/form-row';
import FormGroup from 'modules/form/components/form-group/form-group';
import Textarea from 'modules/form/components/textarea/textarea';
import Button from '../button/button';
import Container from '../container/container';
import InputPhoneWithAreaCode from '../input-phone-with-area-code/input-phone-with-area-code_new';
import IconFlatLoader from '../icons/icon-flat-loader/icon-flat-loader';

import Field from 'modules/form/components/field/field';

import Input from 'modules/form/components/input/input';
import Link from '../link/short-link';
import IconContainer from '../icon-container/icon-container';
import LabelGroup from 'modules/form/components/label-group/label-group';
import LabelGroupItem from 'modules/form/components/label-group-item/label-group-item';
import { IconPhoto } from '../icons';
import areaCodes from '../input-phone-with-area-code/area-codes';

import './complain-form.css';

const { number, func, string } = PropTypes;

class ComplainForm extends Component {
  static propTypes = {
    onSubmit: func,
    text: string,
    photoFileName: string,
    videoFileName: string,
    onTextChange: func,
    activeStarIndex: number,
    onStarClick: func,
    onStarHover: func,
    onStarMouseOut: func
  };

  render() {
    const { isLoading, onSubmit, formRef, isAuthorized, photoFileName, photoInputRef, description, withoutFile, options } = this.props;

    return (
      <div className="ComplainForm">
        <Media query="(max-width: 840px)">
          {isMobile => (
            <Container small>
              <div className="ComplainForm-description">
                {description}
              </div>

              <Form onSubmit={onSubmit} formRef={formRef}>
                {!isAuthorized && <FormRow>{this.renderNameField(isMobile)}</FormRow>}

                <FormRow labelTop>{this.renderEmailField(isMobile)}</FormRow>

                <FormRow labelTop>{this.renderPhoneField(isMobile)}</FormRow>

                <FormRow labelTop>
                  <Field label={(options && options.textAreaTitle) ? options.textAreaTitle :  "Комментарий"}>
                    <Textarea
                      opened
                      name="text"
                      id="text"
                      required
                      placeholder={(options && options.textAreaPlaceholder) ? options.textAreaPlaceholder :  "Общее впечатление от использования товара"}
                      disabled={isLoading}
                    />
                  </Field>
                </FormRow>

              {!withoutFile && (
                <FormRow noLabelGap>
                  <LabelGroup>
                    <LabelGroupItem
                      inputRef={photoInputRef}
                      accept="image/*"
                      id="photo_file"
                      name="photo_file"
                      disabled={isLoading}
                      onChange={this.handlePhotoChange}
                    >
                      <div className="ComplainForm-inputFile">
                        <div className="ComplainForm-inputFileLink">
                          <Link
                            prependedIcon={
                              <IconContainer>
                                <IconPhoto />
                              </IconContainer>
                            }
                          >
                            Прикрепить изображение
                          </Link>
                        </div>

                        <div className="ComplainForm-inputFileTitle">{photoFileName}</div>
                      </div>
                    </LabelGroupItem>
                  </LabelGroup>
                </FormRow>
              )}

                <Form.ActionRow>
                  <FormGroup center>
                    <FormGroup.Item>
                      <Button
                        variant="primary"
                        title="Отправить"
                        type="submit"
                        caption={(options && options.submitTitle) ? options.submitTitle :  "Отправить отзыв на модерацию"}
                        disabled={isLoading}
                        iconAppend={isLoading && <IconFlatLoader />}
                      />
                    </FormGroup.Item>
                  </FormGroup>
                </Form.ActionRow>
              </Form>
            </Container>
          )}
        </Media>
      </div>
    );
  }

  renderNameField(isMobile) {
    const { isLoading } = this.props;

    return (
      <Field label="Имя">
        <Input
          placeholder="Имя"
          type="text"
          name="firstname"
          id="firstname"
          required
          size={isMobile ? 'small' : 'medium'}
          disabled={isLoading}
        />
      </Field>
    );
  }

  renderEmailField(isMobile) {
    const { isLoading } = this.props;

    return (
      <Field label="Email">
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="E-mail"
          required
          size={isMobile ? 'small' : 'medium'}
          disabled={isLoading}
        />
      </Field>
    );
  }

  renderPhoneField(isMobile) {
    const { phone, isLoading } = this.props;

    return (
      <Field label="Телефон">
        <InputPhoneWithAreaCode
          codeList={areaCodes}
          value={phone}
          size={isMobile ? 'small' : 'medium'}
          required
          onChange={this.handlePhoneChange}
          disabled={isLoading}
        />
      </Field>
    );
  }

  handlePhoneChange = value => {
    const { onPhoneChange } = this.props;

    onPhoneChange(value);
  };

  handlePhotoChange = e => {
    const { onPhotoChange } = this.props;

    if (e.target.files[0]) {
      const { name } = e.target.files[0];
      onPhotoChange(name);
    }
  };
}

export default ComplainForm;
