import React, { Component } from 'react';

import Button from 'modules/core/components/button/button';



class ButtonAddToBasketText extends Component {
  render() {
    const { isInBasket, ...rest } = this.props;
    return (
      <Button
        title={isInBasket ? 'Добавить еще' : 'Добавить в корзину'}
        size="small"
        {...rest}
        expanded
      />
    );
  }
}

export default ButtonAddToBasketText;
