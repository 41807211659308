import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cn from "classnames"

import ProfileAvatar from 'modules/profile/components/profile-avatar/profile-avatar';

import Loader from '../../loader/loader';

import './profile-button.css';

class ProfileButton extends Component {
  render() {
    const { title, badge, icon, active, photo, isLoading, firstName,  ...rest } = this.props;

    return (
      <Link
        className={cn("ProfileButton", {
          "ProfileButton--isLoading": isLoading
        })}
        title={isLoading ? 'Личный кабинет' : `${firstName} - личный кабинет`}
        {...rest}
      >
        {isLoading ? (
          <span className="ProfileButton-loader">
            <Loader type="circle" size="small" />
          </span>
        ) : (
          <Fragment>
            <span className="ProfileButton-name">{firstName}</span>
            <div className="ProfileButton-photoWrapper">
              <ProfileAvatar name={firstName} photo={photo} resizeWidth={40} resizeHeight={40} />
            </div>
          </Fragment>
        )}
      </Link>
    );
  }
}

export default ProfileButton;
