import React, { Component } from 'react';
import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';
import { Link } from 'react-router-dom';

import { IconAngleArrowRight } from '../icons/index';
import CatalogNavigationMobileSubitem from './catalog-navigation-mobile-subitem';

import './catalog-navigation-mobile.css';

class CatalogNavigationMobileItem extends Component {
  render() {
    const {
      item,
      animatedCollapsing,
      active,
      subMenuTriggerRef,
      onTrigActiveMenuItem,
      onCatalogLinkClick
    } = this.props;

    const LinkComponent = !!item.submenu ? 'button' : item.href ? 'a' : Link;

    const linkProps = {
      ...(item.href && !item.submenu && { href: item.href }),
      ...(item.link && !item.submenu && { to: item.link }),
      ...item.props
    };

    return (
      <li
        className={classNames('CatalogNavigationMobile-item', {
          'CatalogNavigationMobile-item--active': active,
          'CatalogNavigationMobile-item--highlight': item.highlight
        })}
      >
        <div className="CatalogNavigationMobile-controls">
          <LinkComponent
            {...linkProps}
            onClick={onCatalogLinkClick(item)}
            className="CatalogNavigationMobile-link"
          >
            {item.title}
          </LinkComponent>
          {!!item.submenu && (
            <button
              onClick={onTrigActiveMenuItem(item.id)}
              className="CatalogNavigationMobile-submenuTrigger"
              ref={subMenuTriggerRef(item.id)}
            >
              <span className="CatalogNavigationMobile-iconSizer">
                <IconAngleArrowRight />
              </span>
            </button>
          )}
        </div>
        {animatedCollapsing ? (
          <UnmountClosed isOpened={active}>{this.renderSubmenu()}</UnmountClosed>
        ) : (
          active && this.renderSubmenu()
        )}
      </li>
    );
  }

  renderSubmenu() {
    const { item, subMenuRef, onNestedLinkClick } = this.props;

    return (
      <ul className="CatalogNavigationMobile-submenu" ref={subMenuRef(item.id)}>
        <Link
          // key={item.link}
          to={item.link}
          onClick={onNestedLinkClick}
          className="CatalogNavigationMobile-submenuLink"
        >
          Все
        </Link>
        {item.submenu.map((subItem, index) => this.renderSubmenuItem(subItem, index))}
      </ul>
    );
  }

  renderSubmenuItem = (subItem, index) => {
    const {
      onCatalogLinkClick,
      animatedCollapsing,
      subMenuRef,
      subMenuTriggerRef,
      onNestedLinkClick,
      onFilterLinkClick
    } = this.props;

    if (subItem.submenu) {
      return (
        <CatalogNavigationMobileSubitem
          key={subItem.id}
          index={subItem.id}
          item={subItem}
          animatedCollapsing={animatedCollapsing}
          onCatalogLinkClick={onCatalogLinkClick}
          subMenuRef={subMenuRef}
          subMenuTriggerRef={subMenuTriggerRef}
          onNestedLinkClick={onNestedLinkClick}
          onFilterLinkClick={onFilterLinkClick}
        />
      );
    }

    if (!subItem.link) {
      console.warn(`!_Пункт меню:${JSON.stringify(subItem)} (index: ${index}) не валидный`);
      return null;
    }
    return (
      <li key={index} className="CatalogNavigationMobile-submenuItem">
        <Link
          to={subItem.link}
          onClick={this.handleSubClick(subItem)}
          className="CatalogNavigationMobile-submenuLink"
        >
          {subItem.title}
        </Link>
      </li>
    );
  };

  handleSubClick = item => () => {
    const { onCatalogLinkClick, onNestedLinkClick, onFilterLinkClick, item: category } = this.props;

    // debugger;

    if (item.filter) {
      onFilterLinkClick(category.slug, [item.filter]);
      return;
    }

    onNestedLinkClick(category.slug, item.slug);

    onCatalogLinkClick(item)();
  };
}

export default CatalogNavigationMobileItem;
