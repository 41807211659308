import React, { Component } from 'react';

class ColorSwitchContainer extends Component {
  render() {
    const { ariaLabelledby, ...rest } = this.props;
    return (
      <div className="ColorSwitch" aria-labelledby={ariaLabelledby} {...rest}>
        {this.props.children}
      </div>
    );
  }
}
export default ColorSwitchContainer;
