export const SHOW = 'ordering/orders/SHOW';
export const REQUEST = 'ordering/orders/REQUEST';
export const RESET = 'ordering/orders/RESET';
export const PLACE = 'ordering/orders/PLACE';
export const SUBMIT = 'ordering/orders/SUBMIT';
export const SUCCESS = 'ordering/orders/SUCCESS';
export const ERROR = 'ordering/orders/ERROR';
export const CREATE_FROM_SERVER = 'ordering/orders/CREATE_FROM_SERVER';
export const CREATE_ON_SERVER = 'ordering/orders/CREATE_ON_SERVER';
export const CREATE_LOCAL = 'ordering/orders/CREATE_LOCAL';
export const LOAD_FROM_SERVER = 'ordering/orders/LOAD_FROM_SERVER';
export const UPDATE_FROM_SERVER = 'ordering/orders/UPDATE_FROM_SERVER';
export const SERVER_ERROR = 'ordering/orders/SERVER_ERROR';
export const PERSONAL_INFO_FILL = 'ordering/orders/PERSONAL_INFO_FILL';
export const PERSONAL_INFO_SUBMIT = 'ordering/orders/PERSONAL_INFO_SUBMIT';
export const PERSONAL_INFO_ERROR = 'ordering/orders/PERSONAL_INFO_ERROR';
export const PERSONAL_INFO_UPDATE = 'ordering/orders/PERSONAL_INFO_UPDATE';
export const DELIVERY_INFO_SUBMIT = 'ordering/orders/DELIVERY_INFO_SUBMIT';
export const DELIVERY_INFO_ERROR = 'ordering/orders/DELIVERY_INFO_ERROR';
export const DELIVERY_INFO_UPDATE = 'ordering/orders/DELIVERY_INFO_UPDATE';
export const PAYMENT_REQUEST = 'ordering/orders/PAYMENT_REQUEST';
export const PAYMENT_RESPONSE = 'ordering/orders/PAYMENT_RESPONSE';
export const PAYMENT_RESPONSE_ERROR = 'ordering/orders/PAYMENT_RESPONSE_ERROR';

export const CONFIRMATION_METHOD_CHANGE = 'ordering/orders/CONFIRMATION_METHOD_CHANGE';
