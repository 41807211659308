import React, { Component } from 'react';
import './dialog-grid.css';
import Column from './column';

class DialogGrid extends Component {
  static Column = Column;

  render() {
    const { children } = this.props;
    return <div className="DialogGrid">{children}</div>;
  }
}

export default DialogGrid;
