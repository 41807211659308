export default function initShopListRequest(createRequest) {
  return function _getShopListRequest(queryParameters) {
    return {
      cities: createRequest(initCitiesRequest()),
      shops: createRequest(initShopsRequest(queryParameters))
    };
  };
}

function initCitiesRequest(queryParameters) {
  return function _getCitiesRequest() {
    return {
      path: `/shops/cities`,
      query: { ...queryParameters }
    };
  };
}

function initShopsRequest(queryParameters) {
  return function _getShopsRequest() {
    return {
      path: `/shops`,
      query: {
        [queryParameters.key]: queryParameters.value
      }
    };
  };
}
