import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-close.css';

class IconClose extends Component {
  render() {
    const { size, inline } = this.props;
    const _size = !size || inline ? 'xsmall' : size;
    switch (_size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconClose">
            <path d="M8.8 8l5-5c.2-.2.2-.6 0-.8a.6.6 0 0 0-.8 0l-5 5-5-5a.6.6 0 0 0-.8 0c-.3.2-.3.6 0 .8l5 5-5 5c-.2.2-.2.6 0 .8.1.1.2.2.4.2.1 0 .3-.1.4-.2l5-5 5 5c.1.1.3.2.4.2.1 0 .3-.1.4-.2.2-.2.2-.6 0-.8l-5-5z" />
          </Svg>
        );
      case 'small':
        return (
          <Svg viewBox="0 0 24 24" className="IconClose">
            <path d="M12.9 12l7.9-7.9c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L12 11.1 4.1 3.2c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l7.9 7.9-7.9 7.9c-.2.2-.2.6 0 .9.1.1.3.2.4.2.2 0 .3-.1.4-.2l7.9-7.9 7.9 7.9c.1.1.3.2.4.2.2 0 .3-.1.4-.2.2-.2.2-.6 0-.9L12.9 12z" />
          </Svg>
        );
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 32 32" className="IconClose">
            <path d="M18.9 18l10-10c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0l-10 10L8 7.2c-.2-.2-.6-.2-.9 0-.2.2-.2.6.1.8l10 10-10 10c-.2.2-.2.6 0 .9.1 0 .2.1.4.1s.3-.1.4-.2l10-10 10 10c.1.1.3.2.4.2.2 0 .3-.1.4-.2.2-.2.2-.6 0-.9L18.9 18z" />
          </Svg>
        );
      default:
    }
  }
}

export default IconClose;
