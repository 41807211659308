export default function getIsMatchedCities(relatedCiyList, selectCityId, suggestedId) {
  const _selectCityId = String(selectCityId);
  const _suggestedId = String(suggestedId);
  const _relatedCiyList = relatedCiyList.map(item => String(item));

  // if (!_selectCityId && !_suggestedId) {
  //   return false;
  // }

  if (_relatedCiyList.includes(_selectCityId)) {
    return true;
  }

  // if (!!_selectCityId && !_relatedCiyList.includes(_selectCityId)) {
  //   return false;
  // }

  if (_relatedCiyList.includes(_suggestedId)) {
    return true;
  }

  return false;
}
