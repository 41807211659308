import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isFunction from 'modules/utils/is-function';

import BaseEditable from '../base-editable/base-editable';
import AddressField from '../address-field/address-field';

const { func, shape, string } = PropTypes;

class EditableAddress extends Component {
  static propTypes = {
    address: shape({
      street: string,
      building: string,
      apartment: string
    }),
    onChange: func
  };

  render() {
    const { address } = this.props;

    return (
      <BaseEditable
        initialValue={{ street: '', building: '', apartment: '' }}
        prepareValue={value => this.formatAddressString(value)}
        renderEdit={({ editingValue, onChange }) => (
          <AddressField address={editingValue} onChange={onChange} />
        )}
        onChange={this.handleChange}
        onEdit={this.handleEdit}
        onCancel={this.handleCancel}
        value={address}
      />
    );
  }

  formatAddressString(address) {
    if (!address) {
      return '';
    }

    let formattedAddress = '';

    if (address.street) {
      formattedAddress += address.street;
    }

    if (address.building) {
      const formattedBuilding = address.street ? `, ${address.building}` : address.building;

      formattedAddress += formattedBuilding;
    }

    if (address.apartment) {
      formattedAddress += `, кв./офис ${address.apartment}`;
    }

    return formattedAddress;
  }

  handleEdit = () => {
    const { onEdit } = this.props;

    if (!isFunction(onEdit)) {
      return;
    }
    onEdit();
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (!isFunction(onCancel)) {
      return;
    }
    onCancel();
  };

  handleChange = value => {
    const { onChange } = this.props;

    if (!isFunction(onChange)) {
      return;
    }
    onChange(value);
  };
}

export default EditableAddress;
