import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ButtonClose from '../button-close/button-close';

const { bool, oneOf } = PropTypes;

class TooltipInner extends Component {
  static propTypes = {
    /** Vertical alignment */
    alignment: oneOf(['left', 'center', 'right']),

    /** If tooltip must be closable or should close automatically */
    closable: bool
  };

  static defaultProps = {
    alignment: 'center',
    title: '',
    content: null,
    closable: false
  };

  render() {
    const { title, size, children, alignment, closable, style, onClose, ...rest } = this.props;

    return (
      <div
        {...rest}
        className={cn(
          'Tooltip Tooltip--top',
          `Tooltip--size-${size}`,
          `Tooltip--alignment-${alignment}`
        )}
        style={style}
      >
        <div className="Tooltip-box">
          {closable && (
            <div className="Tooltip-button">
              <ButtonClose onClick={onClose} />
            </div>
          )}

          {title && <h3 className="Tooltip-title">{title}</h3>}

          <div className="Tooltip-content">{children}</div>
        </div>
      </div>
    );
  }
}

export default TooltipInner;
