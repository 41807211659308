import { takeEvery } from 'redux-saga/effects';

import { LOCATION_CHANGE } from 'connected-react-router';

export function* watchLandings() {
  yield takeEvery(LOCATION_CHANGE, JivoLocalationChange);
}

function* JivoLocalationChange(action) {
  if (action.payload.location.pathname === '/happy-master') {
    return;
  }

  yield __BROWSER__ && window.jivo_api && window.jivo_api.close();
}

export default function register(middleware) {
  return () => Object.values({ watchLandings }).map(middleware.run);
}
