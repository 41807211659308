import playListSeeds from './seeds/play-list';

import { createReducer /*, createAction */ } from 'modules/utils/dux';
import { createSelector } from 'reselect';

import * as fromVideoList from './video-list';

/* ACTIONS */

/* REDUCERS */

const reducer = createReducer({});

export default reducer(playListSeeds);

/* SELECTORS */

export const getIds = state => state.blog.playList;

export const getList = createSelector(
  fromVideoList.getAll,
  getIds,
  (all, ids) => ids.map(id => all[id])
);
