import React, { Component } from 'react';
import SubcategoryCarousel from 'modules/core/components/subcategory-carousel/subcategory-carousel';

import SubcategoryCard from '../subcategory-card/subcategory-card';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

class SubcategorySlider extends Component {
  render() {
    const { category, nested } = this.props;

    const categories = nested.filter(item => item.slug);

    return (
      <SubcategoryCarousel scrolledElementRef={this.setScrolledElementRef}>
        {categories.map(item => (
          <HideErrorBoundary key={item.slug}>
            <SubcategoryCarousel.Item>
              <SubcategoryCard parent={category} category={item} />
            </SubcategoryCarousel.Item>
          </HideErrorBoundary>
        ))}
      </SubcategoryCarousel>
    );
  }
}

export default SubcategorySlider;
