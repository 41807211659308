export const SHOW_ITEM = 'product/categories/SHOW_ITEM';
export const ADD_ITEM = 'product/categories/ADD_ITEM';
export const PRODUCTS_LOAD = 'product/categories/PRODUCTS_LOAD';
export const ADD_PRODUCTS = 'product/categories/ADD_PRODUCTS';
export const LOAD_MORE = 'product/categories/LOAD_MORE';
export const SET_FILTER = 'product/categories/SET_FILTER';
export const SET_FILTER_RESPONSE = 'product/categories/SET_FILTER_RESPONSE';
export const RESET_FILTER = 'product/categories/RESET_FILTER';
export const LOAD_CATEGORY = 'product/categories/LOAD_CATEGORY';
export const CHANGE_NESTED = 'product/categories/CHANGE_NESTED';
export const SORT = 'product/categories/SORT';
