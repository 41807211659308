import React, { Component } from 'react';
import Dropdown from 'modules/core/components/dropdown/dropdown';
import FieldsetChoice from 'modules/form/components/fieldset-choice/fieldset-choice';
import Checkbox from 'modules/form/components/checkbox/checkbox';
import CategoryFieldTrigger from './category-field-trigger';
import './category-field.css';

class CategoryField extends Component {
  render() {
    const { categories, selected } = this.props;

    return (
      <div className="CategoryField">
        <Dropdown trigger={<CategoryFieldTrigger count={selected.length} />}>
          <div className="CategoryField-dropdown">
            <FieldsetChoice>
              {categories.map(item => {
                return (
                  <FieldsetChoice.Item>
                    <Checkbox
                      label={item.title}
                      name="category_filter"
                      value={item.slug}
                      checked={selected.indexOf(item.slug) !== -1}
                      onChange={this.handleChange(item.slug)}
                    />
                  </FieldsetChoice.Item>
                );
              })}
            </FieldsetChoice>
          </div>
        </Dropdown>
      </div>
    );
  }

  handleChange = slug => e => {
    const { checked } = e.target;
    const { onChange } = this.props;
    onChange(slug, checked);
  };
}

export default CategoryField;
