export default function initPickPointsRequest(createRequest) {
  return {
    list: createRequest((city_fias_id, type, basket, deliveryRequestOptions) => {
      const isPPDType = type === 'PICK_POINT_TYPE/PPD';
      const method = isPPDType ? 'POST' : 'GET';

      return {
        method,
        path: `/pick-points`,
        query: {
          type,
          city_fias_id,
          ...deliveryRequestOptions
        },
        ...(isPPDType && { data: { basket } })
      };
    }),

    item: createRequest(id => {
      return {
        method: 'GET',
        path: `/pick-points/${id}`
      };
    })
  };
}
