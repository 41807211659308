import React, { Component } from 'react';
import UserWelcomeContainer from '../../containers/user-welcome';
import NavigationContainer from '../../containers/navigation';

import './navigation-scene.css';

class NavigationScene extends Component {
  render() {
    return (
      <div className="NavigationScene">
        <div className="NavigationScene-overview">
          <UserWelcomeContainer />
        </div>
        <div className="NavigationScene-menu">
          <NavigationContainer />
        </div>
      </div>
    );
  }
}

export default NavigationScene;
