import React, { Component } from 'react';

import Dialog from 'modules/core/components/dialog/dialog';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import { IconSearch } from 'modules/core/components/icons';
import Field from 'modules/form/components/field/field';
// import Input from 'modules/form/components/input/input';
import Grid from './grid';
import MixitCitySuggest from 'modules/geography/containers/mixit-city-suggest';
import './city-dialog.css';

class CityDialog extends Component {
  render() {
    const { list, currentId, onChooseFromSearch, ...rest } = this.props;

    return (
      <Dialog overflowVisible closeable {...rest}>
        <div className="CityDialog">
          <h2 className="CityDialog-title">Выберите свой город</h2>
          <Grid list={list} currentId={currentId} />
          <h3 className="CityDialog-seachTitle">Или найдите другой населенный пункт</h3>
          <div className="CityDialog-seachField">
            <Field>
              <MixitCitySuggest
                placeholder="Начните ввод, чтобы увидеть подсказки"
                noPrimary
                // selected={cityFiasId}
                onChangeFull={onChooseFromSearch}
                // size="small"
                // appended={this.getSearchIcon()}
              />
            </Field>
          </div>
        </div>
      </Dialog>
    );
  }

  getSearchIcon() {
    return (
      <div className="CityDialog-iconWrapper">
        <IconContainer size="small">
          <IconSearch />
        </IconContainer>
      </div>
    );
  }

  handleInputChange = e => {
    const { onSearchQueryChange } = this.props;

    const { value } = e.target;
    onSearchQueryChange(value);
  };
}

export default CityDialog;
