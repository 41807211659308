import React, { Component } from 'react';

import Dropdown from 'modules/core/components/dropdown/dropdown';

import Button from './button/button';

class Item extends Component {
  render() {
    const { content, title, count, align, ...rest } = this.props;

    if (!content) {
      return <Button title={title} count={count} {...rest} />;
    }

    return (
      <Dropdown trigger={<Button title={title} count={count} {...rest} />} align={align}>
        {content}
      </Dropdown>
    );
  }
}

export default Item;
