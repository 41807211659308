export default function initBasketRequest(createRequest) {
  return function _getBasketRequest() {
    return {
      item: createRequest(initItemRequest()),
      add: createRequest(initItemAddRequest()),
      goods: function _getGoodsRequest(id = null) {
        return {
          add: createRequest(initGoodsAddRequest()),
          item: createRequest(initGoodsItemRequest(id)),
          change: createRequest(initGoodsChangeRequest(id)),
          delete: createRequest(initGoodsDeleteRequest(id))
        };
      },
      promos: function _getPromoRequest(id = null) {
        return {
          add: createRequest(initPromosAddRequest()),
          change: createRequest(initPromosChangeRequest(id)),
          delete: createRequest(initPromosDeleteRequest(id))
        };
      },
      bonuses: function _getBonusesRequest(bonuses) {
        return {
          apply: createRequest(initBonusesAddRequest(bonuses))
        };
      }
    };
  };
}

function initItemRequest() {
  return function _getItemRequest() {
    return {
      method: 'GET',
      path: `/basket`
    };
  };
}

function initItemAddRequest() {
  return function _getItemAddRequest(basket) {
    return {
      method: 'POST',
      path: '/basket',
      data: { ...basket }
    };
  };
}

/* GOODS */

function initGoodsItemRequest(id) {
  return function _getGoodsItemRequest() {
    return {
      method: 'GET',
      path: `/basket/goods/${id}`
    };
  };
}

function initGoodsAddRequest() {
  return function _getGoodsAddRequest(goods) {
    return {
      method: 'POST',
      path: `/basket/goods`,
      data: goods
    };
  };
}

function initGoodsChangeRequest(id) {
  return function _getGoodsChangeRequest(goods) {
    return {
      method: 'PUT',
      path: `/basket/goods/${id}`,
      data: goods
    };
  };
}

function initGoodsDeleteRequest(id) {
  return function _getGoodsDeleteRequest() {
    return {
      method: 'DELETE',
      path: `/basket/goods/${id}`
    };
  };
}

/* PROMOS */

function initPromosAddRequest() {
  return function _getPromosAddRequest(promo) {
    return {
      method: 'POST',
      path: `/basket/promo`,
      data: promo
    };
  };
}

function initPromosChangeRequest(id) {
  return function _getPromosAddRequest(promo) {
    return {
      method: 'PUT',
      path: `/basket/promo/${id}`,
      data: promo
    };
  };
}

function initPromosDeleteRequest(id) {
  return function _getPromosDeleteRequest() {
    return {
      method: 'DELETE',
      path: `/basket/promo/${id}`
    };
  };
}

/* BONUSES */
function initBonusesAddRequest(bonuses) {
  return function _getBonusesAddRequest() {
    return {
      method: 'POST',
      path: `/basket/bonuses/`,
      data: bonuses
    };
  };
}
