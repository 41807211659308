import React, { Component } from 'react';

import Dialog from '../dialog/dialog';
import YoutubeVideo from '../youtube-video/youtube-video';

import './video-dialog.css';

class VideoDialog extends Component {
  render() {
    const { id, autoplay, ...rest } = this.props;

    return (
      <Dialog closeable {...rest}>
        <div className="VideoDialog">
          <YoutubeVideo id={id} autoplay={autoplay} />
        </div>
      </Dialog>
    );
  }
}

export default VideoDialog;
