import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './article-action.css';

const { number, element, oneOf } = PropTypes;

class ArticleAction extends Component {
  static propTypes = {
    /** Интерактивный элемент-кнопка */
    button: element.isRequired,

    amount: number.isRequired,

    /** Ось, вдоль которой расположены элементы кнопки и счетчика */
    direction: oneOf(['row', 'column'])
  };

  static defaultProps = {
    direction: 'column'
  };

  render() {
    const { button, amount, direction } = this.props;

    return (
      <div className={cn('ArticleAction', `ArticleAction--direction-${direction}`)}>
        <div className="ArticleAction-button">{button}</div>
        <div className="ArticleAction-amount">{amount}</div>
      </div>
    );
  }
}

export default ArticleAction;
