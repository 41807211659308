import React, { Component } from 'react';
import Loader from 'modules/core/components/loader/loader';
import './placeholder.css';

class Placeholder extends Component {
  render() {
    return (
      <div className="Category_Placeholder">
        <Loader />
      </div>
    );
  }
}

export default Placeholder;
