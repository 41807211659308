import { createSelector } from 'reselect';
import * as fromProducts from 'modules/product/ducks/products';
import * as fromBasket from './basket';
import { getFreeDeliveryPrice, getMaxDeliveryPrice } from './basket/selectors';
import * as fromLocation from 'modules/geography/ducks/location';
import * as fromDeliveryForm from '../ducks/delivery-form';

const makeGetList = listSelector =>
  createSelector(listSelector, fromProducts.getFindProductBySlug, (list, findProduct) => {
    return list.map(item => ({
      ...item,
      product: findProduct(item.product)
    }));
  });

const makeGetRawList = listSelector =>
  createSelector(listSelector, fromProducts.getFindProduct, (list, findProduct) => {
    return list.map(item => ({
      ...item,
      product: findProduct(item.product)
    }));
  });

export const getRawListWithoutGifts = makeGetRawList(fromBasket.getListWithoutGifts);

export const getList = makeGetList(fromBasket.getList);
export const getRawList = makeGetRawList(fromBasket.getRawList);
export const getListWithRemoved = makeGetList(fromBasket.getListWithRemoved);
export const getListLength = createSelector(getList, list => list.length);

const makeGetIds = listSelector =>
  createSelector(listSelector, list => list.map(item => item.product));

export const getIds = makeGetIds(fromBasket.getList);
export const getIdsWithRemoved = makeGetIds(fromBasket.getListWithRemoved);

export const getListWithProductsNotRemoved = createSelector(
  fromBasket.getList,
  (list, getProductBySlug) => {
    return list.map(item => ({
      ...item,
      product: getProductBySlug(item.product)
    }));
  }
);

export const getSerialized = createSelector(
  fromBasket.getList,
  fromProducts.getFindProduct,
  function _getSerialized(list, findProduct) {
    const goods = list.map(item => {
      const product = findProduct(item.product);
      return {
        ...item,
        product
      };
    });

    return goods;
  }
);

export const getProductsCost = createSelector(getList, list =>
  list
    .filter(item => !item.gift)
    .reduce((summaryCost, item) => summaryCost + item.amount * item.price.current, 0)
);

export const getProductsDefaultCost = createSelector(getList, list =>
  list
    .filter(item => !item.gift)
    .reduce((summaryCost, item) => summaryCost + item.amount * item.price.default, 0)
);

export const getProductsDiscount = createSelector(getList, list =>
  list
    .filter(item => !item.gift)
    .map(item => item.amount * item.price.discount)
    .reduce((a, b) => a + b, 0)
);

export const getDeliveryCost = createSelector(
  fromBasket.getDeliveryCost,
  getFreeDeliveryPrice,
  getMaxDeliveryPrice,
  getProductsCost,
  (deliveryCost, freeDeliveryPrice, maxDeliveryPrice, productsCost) => {
    // if (freeDeliveryPrice > productsCost) {
    if (maxDeliveryPrice !== 0) {
      return deliveryCost;
    }

    return 0;
  }
);

export const getIsDeliveryFree = createSelector(
  getProductsCost,
  fromLocation.getFreeDeliveryFrom,
  function _getIsDeliveryFree(productsCost, freeDeliveryPrice) {
    return productsCost > freeDeliveryPrice;
  }
);
export const getIsDeliveryFreeByDeliveryCity = createSelector(
  getProductsCost,
  fromDeliveryForm.getDeliveryFrom,
  function _getIsDeliveryFree(productsCost, freeDeliveryFrom) {
    return productsCost > freeDeliveryFrom;
  }
);
export const getIsDeliveryFreeByDeliveryMethod = createSelector(
  getProductsCost,
  fromDeliveryForm.getDeliveryFromByDeliveryMethod,
  function _getIsDeliveryFree(productsCost, freeDeliveryPrice) {
    return productsCost > freeDeliveryPrice;
  }
);

export const getDeliveryDiscount = createSelector(
  fromBasket.getDeliveryCost,
  getDeliveryCost,
  (deliveryCost, finalDeliveryCost) => deliveryCost - finalDeliveryCost
);

export const getDiscount = createSelector(
  getProductsDiscount,
  getDeliveryDiscount,
  (productsDiscount, deliveryDiscount) => productsDiscount + deliveryDiscount
);
