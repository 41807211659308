import { createAction } from 'modules/utils/dux';

import * as SUBSCRIPTIONS from '../types/subscriptions';

export default {
  showSubscriptions() {
    return createAction(SUBSCRIPTIONS.SHOW);
  },

  addSubscriptions(channelList, topics) {
    return createAction(SUBSCRIPTIONS.ADD, { channelList, topics });
  },

  subscribeChannel(slug) {
    return createAction(SUBSCRIPTIONS.SUBSCRIBE_CHANNEL, { slug });
  },

  unsubscribeChannel(slug) {
    return createAction(SUBSCRIPTIONS.UNSUBSCRIBE_CHANNEL, { slug });
  },

  subscribeTopic(slug) {
    return createAction(SUBSCRIPTIONS.SUBSCRIBE_TOPIC, { slug });
  },

  subscribeTopicError(slug) {
    return createAction(SUBSCRIPTIONS.SUBSCRIBE_TOPIC_ERROR, { slug });
  },

  unsubscribeTopic(slug) {
    return createAction(SUBSCRIPTIONS.UNSUBSCRIBE_TOPIC, { slug });
  },

  unsubscribeTopicError(slug) {
    return createAction(SUBSCRIPTIONS.UNSUBSCRIBE_TOPIC_ERROR, { slug });
  }
};
