export const VARIANT_OPTION = {
  VOLUME: 'VARIANT_OPTION/VOLUME',
  WEIGHT: 'VARIANT_OPTION/WEIGHT',
  COLOR: 'VARIANT_OPTION/COLOR',
  SIZE: 'VARIANT_OPTION/SIZE',
  VALUE: 'VARIANT_OPTION/VALUE'
};

export const PRODUCT_TYPE = {
  DEFAULT: 'PRODUCT_TYPE/DEFAULT',
  SET: 'PRODUCT_TYPE/SET',
  CLOTHES: 'PRODUCT_TYPE/CLOTHES',
  COSMETICS: 'PRODUCT_TYPE/COSMETICS'
};
