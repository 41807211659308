import React, { Component } from 'react';

import IconSorting from 'modules/core/components/icons/icon-sorting/icon-sorting';

import './mass-media-sort-panel.css';

class MassMediaSortPanel extends Component {
  render() {
    const title = this.getButtonTitle();
    return (
      <div className="MassMediaSortPanel">
        <button className="MassMediaSortPanel-action" onClick={this.onSortChange}>
          <span className="MassMediaSortPanel-actionTitle">{title}</span>
          <span className="MassMediaSortPanel-actionIcon">
            <IconSorting />
          </span>
        </button>
      </div>
    );
  }

  getButtonTitle = () => {
    const { sort } = this.props;

    if (sort === 'asc') {
      return 'От старых к новым';
    }

    return 'От новых к старым';
  };

  onSortChange = () => {
    const { sort, onChangeSort } = this.props;

    if (sort === 'asc') {
      onChangeSort('desc');
    } else {
      onChangeSort('asc');
    }
  };
}

export default MassMediaSortPanel;
