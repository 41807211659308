import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './dotted-list.css';

export default class DottedList extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    const { list, table, highlightedTitle, markedList } = this.props;
    return (
      <ul
        className={cn('DottedList', {
          'DottedList--table': table,
          'DottedList--highlightedTitle': highlightedTitle,
          'DottedList--markedList': markedList
        })}
        x-ms-format-detection="none"
      >
        {list.map((item, index) => (
          <li key={index} className="DottedList-row">
            <div className="DottedList-titleCell">
              <div className="DottedList-titleHolder">
                <span className="DottedList-title">{item.title}</span>
                {item.importantTitle && (
                  <span className="DottedList-importantTitle">{` ${item.importantTitle}`}</span>
                )}

                <span className="DottedList-dots" />
              </div>
            </div>
            <div className="DottedList-valueCell">
              {!!item.value && <span className="DottedList-value">{item.value}</span>}
              {!!item.importantInfo && (
                <span className="DottedList-importantValue">{item.importantInfo}</span>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

// `, ${item.importantInfo}`
