import React, { Component } from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../button/button';

import './section-default.css';

const { string, bool } = PropTypes;
class SectionDefault extends Component {
  static propTypes = {
    /** Section title **/
    title: string,
    /** Button overview title **/
    buttonTitle: string,
    /** Link that lies in the title **/
    moreLink: string,
    /** Modifier of the absence of external gap of the section **/
    noGap: bool,
    /** Modifier of the gray background of the section **/
    highlightBackground: bool
  };

  static defaultProps = {
    moreLink: '',
    buttonTitle: '',
    noGap: false,
    highlightBackground: false
  };

  render() {
    const { title, noGap, highlightBackground, moreLink, overflowHidden } = this.props;

    return (
      <section
        className={classNames('SectionDefault', {
          'SectionDefault--noGap': noGap,
          'SectionDefault--highlightBackground': highlightBackground,
          'SectionDefault--overflowHidden': overflowHidden
        })}
      >
        {title && <h2 className="SectionDefault-title">{title}</h2>}
        <div className="SectionDefault-content">{this.props.children}</div>
        {moreLink && (
          <div className="SectionDefault-buttonMore">
            {/* <Media query="(max-width: 1100px)" render={this.renderButtonMore} /> */}
            {this.renderButtonMore()}
          </div>
        )}
      </section>
    );
  }

  renderButtonMore = () => {
    const { moreLink, buttonTitle } = this.props;

    return (
      <Media query="(max-width: 640px)">
        {matches => (
          <Button
            title={buttonTitle}
            component={Link}
            to={moreLink}
            variant="primary"
            size={matches ? 'small' : 'normal'}
          />
        )}
      </Media>
    );
  };
}

export default SectionDefault;
