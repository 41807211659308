import React from 'react';

import { IconEdit } from 'modules/core/components/icons';
import IconContainer from 'modules/core/components/icon-container/icon-container';


import './edit-user-name-by-modal.css';

function EditUserNameByModal(props) {
    const { name, openUserNameDialog } = props;

    return (
        <div className="EditUserName">
            <div className="EditUserName-title">{name}</div>
            <button className="EditUserName-editButton" type="button" onClick={openUserNameDialog}>
                <IconContainer inline>
                    <IconEdit />
                </IconContainer>
            </button>
        </div>
    )
}

export default EditUserNameByModal;