const parseDate = dateString => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
};

export default parseDate;
