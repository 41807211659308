import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import IconContainer from '../icon-container/icon-container';
import { IconYoutube } from '../icons';

import getYoutubeVideoPreviewImage from 'modules/utils/get-youtube-video-preview-image';

import './video-trigger.css';

const { string, boolean, func } = PropTypes;

class VideoTrigger extends Component {
  static propTypes = {
    /**Идентификатор видео */
    identifier: string.isRequired,

    /**Если true, то весь компонент кликабельный */
    expandedClickArea: boolean,

    onClick: func.isRequired
  };

  static defaultProps = {
    clickArea: 'point'
  };

  render() {
    const { identifier, expandedClickArea, keepRatio, onClick } = this.props;

    const img = getYoutubeVideoPreviewImage(identifier);

    return (
      <div
        className={cn('VideoTrigger', {
          'VideoTrigger--keepRatio': keepRatio,
          'VideoTrigger--expandedClickArea': expandedClickArea
        })}
        style={keepRatio ? { backgroundImage: `url(${img})` } : null}
      >
        {!keepRatio && <img className="VideoTrigger-image" src={img} alt="" />}

        <button type="button" className="VideoTrigger-button" onClick={onClick}>
          <span className="VideoTrigger-icon">
            <IconContainer inline>
              <IconYoutube />
            </IconContainer>
          </span>
        </button>
      </div>
    );
  }
}

export default VideoTrigger;
