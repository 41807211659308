import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import PickPointContainer from '../../containers/pick-point';

import './pick-point-list.css';

const { number, arrayOf, func, string } = PropTypes;

class PickPointList extends Component {
  static propTypes = {
    selectedId: number.isRequired,
    list: arrayOf(number),
    onSelect: func.isRequired,
    filterMask: string
  };

  render() {
    const { list, fullHeight } = this.props;

    return (
      <div
        className={cn('PickPointList', {
          'PickPointList--fullHeight': fullHeight
        })}
      >
        <div className="PickPointList-header">
          <div className="PickPointList-title">Пункт выдачи</div>
          <div className="PickPointList-address">Адрес</div>
          <div className="PickPointList-schedule">Время работы</div>
          {/* {!(pickPointType === 'PICK_POINT_TYPE/PPD') && (
            <div className="PickPointList-payment">Оплата картой</div>
          )} */}
        </div>

        <ul className="PickPointList-content">{list.map(this.renderPickPoint)}</ul>
      </div>
    );
  }

  renderPickPoint = id => {
    const { selectedId, filterMask, onSelect, pickPointType } = this.props;

    return (
      <li key={id} className="PickPointList-item">
        <PickPointContainer
          id={id}
          filterMask={filterMask}
          selected={id === selectedId}
          onSelect={onSelect}
          pickPointType={pickPointType}
        />
      </li>
    );
  };
}

export default PickPointList;
