import React, { Component } from 'react';
import cn from 'classnames';

import Price from 'modules/ordering/components/price-formatted/price-formatted';

import './card-price.css';

class CardPrice extends Component {
  render() {
    const { price, hasDiscount, isAvailable } = this.props;
    return (
      <div
        className={cn('CardPrice', {
          'CardPrice--notAvailable': !isAvailable
        })}
      >
        <div className="CardPrice-current">
          <Price number={price.current} variant={hasDiscount ? 'discount' : 'default'} />
        </div>
        {hasDiscount && (
          <div className="CardPrice-previous">
            <Price number={price.default} variant="previous" />
          </div>
        )}
      </div>
    );
  }
}

export default CardPrice;
