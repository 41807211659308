export default {
  title: 'Характеристики тела',
  id: 'body',
  description:
    'Выбери свой тип кожи лица и тела, тип волос и получай персональные рекомендации по уходу',
  options: [
    {
      slug: 'body-type',
      title: 'Тип кожи тела',
      values: [
        {
          name: 'BODY_TYPES/DRY_AND_SENSITIVE',
          title: 'Сухая и чувствительная'
        },
        {
          name: 'BODY_TYPES/NORMAL',
          title: 'Нормальная'
        },
        {
          name: 'BODY_TYPES/OILY',
          title: 'Жирная'
        },
        {
          name: 'BODY_TYPES/MIXED',
          title: 'Смешанная'
        }
      ]
    },
    {
      slug: 'cellulite',
      title: 'Целлюлит',
      values: [
        {
          name: 'CELLULITE/TRUE',
          title: 'Да'
        },
        {
          name: 'CELLULITE/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'skin-stretching',
      title: 'Растяжки',
      values: [
        {
          name: 'SKIN_STRETCHING/TRUE',
          title: 'Да'
        },
        {
          name: 'SKIN_STRETCHING/FALSE',
          title: 'Нет'
        }
      ]
    }
  ]
};
