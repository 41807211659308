import { createReducer, createAction, handle, createActionError } from 'modules/utils/dux';
import { combineReducers } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import * as Order from '../domain/order';
import * as DeliveryMethod from '../domain/delivery-method';
import * as PickPoint from '../domain/pick-point';
import * as fromDeliveryMethodTable from './delivery-method-table';
import * as fromPaymentMethods from './payment-methods';
import * as fromBasket from './basket/selectors';
import * as fromPickPointList from './pick-point-list';
import * as fromCityTable from './city-table';
import * as fromCityList from 'modules/geography/ducks/city-list';
import * as PROFILE from 'modules/profile/types/profile';
import * as ACCOUNT from 'modules/core/ducks/types/account';
import ORDER_STATUS from '../constants/order-status';

import * as ORDER from './types/orders';

export const actions = {
  show(id, isOrderPage = false) {
    return createAction(ORDER.SHOW, {
      id,
      isOrderPage
    });
  },

  request(id) {
    return createAction(ORDER.REQUEST, {
      id
    });
  },

  place() {
    return createAction(ORDER.PLACE);
  },

  submit(id, order) {
    return createAction(ORDER.SUBMIT, {
      id,
      order
    });
  },

  success(id, order, authorization) {
    return createAction(ORDER.SUCCESS, {
      id,
      order,
      authorization
    });
  },

  fail(id, order, message) {
    return createAction(ORDER.ERROR, {
      id,
      order,
      message
    });
  },

  createOnServer(order) {
    return createAction(ORDER.CREATE_ON_SERVER, {
      order
    });
  },

  createLocal(id) {
    return createAction(ORDER.CREATE_LOCAL, {
      id
    });
  },

  createOrder(order) {
    return createAction(ORDER.CREATE_FROM_SERVER, {
      order
    });
  },

  loadFromServer(id, redirect = false) {
    return createAction(ORDER.LOAD_FROM_SERVER, {
      id,
      redirect
    });
  },

  serverError(id, error, redirect = false) {
    return createActionError(ORDER.SERVER_ERROR, {
      id,
      error,
      redirect
    });
  },

  updateFromServer(order) {
    return createAction(ORDER.UPDATE_FROM_SERVER, {
      order
    });
  },

  fillPersonalInfo(orderId, email, phone, firstName) {
    return createAction(ORDER.PERSONAL_INFO_FILL, {
      id: orderId,
      personalInfo: {
        email,
        phone,
        firstName
      }
    });
  },

  submitPersonalInfo(orderId, email, phone, name, firstName, lastName) {
    let personalInfo = {
      email,
      phone,
      firstName,
      lastName
    };

    if (!email) {
      delete personalInfo.email;
    }

    if (!phone) {
      delete personalInfo.phone;
    }

    if (!firstName) {
      delete personalInfo.firstName;
    }
    
    if (!lastName) {
      delete personalInfo.lastName;
    }

    return createAction(ORDER.PERSONAL_INFO_SUBMIT, {
      id: orderId,
      personalInfo
    });
  },

  personalInfoError(message) {
    return createActionError(ORDER.PERSONAL_INFO_ERROR, {
      message
    });
  },

  updatePersonalInfo(id, personalInfo) {
    return createAction(ORDER.PERSONAL_INFO_UPDATE, {
      id,
      personalInfo
    });
  },

  submitDeliveryInfo(
    orderId,
    city,
    deliveryMethod,
    address,
    pickPoint,
    recipientPhone,
    recipientName
  ) {
    return createAction(ORDER.DELIVERY_INFO_SUBMIT, {
      id: orderId,
      city,
      deliveryMethod,
      address,
      pickPoint,
      recipientPhone,
      recipientName
    });
  },

  deliveryInfoError(message) {
    return createActionError(ORDER.DELIVERY_INFO_ERROR, {
      message
    });
  },

  updateDeliveryInfo(id, deliveryInfo) {
    return createAction(ORDER.DELIVERY_INFO_UPDATE, {
      id,
      deliveryInfo
    });
  },

  paymentRequest(id) {
    return createAction(ORDER.PAYMENT_REQUEST, {
      id
    });
  },

  paymentResponse(id, payment) {
    return createAction(ORDER.PAYMENT_RESPONSE, {
      id,
      payment
    });
  },

  paymentResponseError(id, message) {
    return createActionError(ORDER.PAYMENT_RESPONSE, {
      id,
      message
    });
  },

  changeConfirmationMethod(id, method) {
    return createAction(ORDER.CONFIRMATION_METHOD_CHANGE, {
      id,
      method
    });
  }
};

const all = createReducer(
  handle(ORDER.RESET, function() {
    return {};
  }),

  // handle(types.CREATE_ON_SERVER, function(state, payload) {
  //   if (!payload.order) {
  //     return state;
  //   }

  //   const order = Order.normalize(payload.order);

  //   return {
  //     ...state,
  //     [Order.getId(order)]: order
  //   };
  // }),

  handle(ORDER.LOAD_FROM_SERVER, function(state, payload) {
    const order = state[payload.id] || {};
    return {
      ...state,
      [payload.id]: {
        ...order,
        isLoading: true,
        isError: false
      }
    };
  }),

  handle(ORDER.SERVER_ERROR, function(state, payload) {
    const order = state[payload.id] || {};
    return {
      ...state,
      [payload.id]: {
        ...order,
        isLoading: false,
        isError: true
      }
    };
  }),

  handle(ORDER.CREATE_LOCAL, function(state, { id }) {
    return {
      ...state,
      [id]: {
        id: id,
        confirmationMethod: 'sms',
        _local: true
      }
    };
  }),

  handle(ORDER.SUBMIT, function(state, { id, order }) {
    return {
      ...state,
      [id]: {
        ...state[id],
        payment: {
          ...order.payment
        },
        comment: order.comment
      }
    };
  }),

  handle(ORDER.UPDATE_FROM_SERVER, function(state, payload) {
    const order = payload.order;

    return {
      ...state,
      [Order.getId(order)]: {
        ...order,
        isLoading: false
      }
    };
  }),

  handle(ORDER.PERSONAL_INFO_FILL, function(state, { id, personalInfo }) {
    const order = state[id] || {};
    return {
      ...state,
      [id]: {
        ...order,
        personalInfo
      }
    };
  }),

  handle(ORDER.PERSONAL_INFO_SUBMIT, function(state, { id, personalInfo }) {
    const order = state[id] || {};
    return {
      ...state,
      [id]: {
        ...order,
        personalInfo: {
          ...order.personalInfo,
          ...personalInfo
        }
      }
    };
  }),

  handle(ORDER.CONFIRMATION_METHOD_CHANGE, function(state, { id, method }) {
    const order = state[id] || {};
    return {
      ...state,
      [id]: {
        ...order,
        confirmationMethod: method
      }
    };
  }),

  handle(ORDER.PERSONAL_INFO_UPDATE, function(state, { id, personalInfo }) {
    const order = state[id] || {};
    return {
      ...state,
      [id]: {
        ...order,
        personalInfo
      }
    };
  }),

  handle(ORDER.DELIVERY_INFO_SUBMIT, function(state, payload) {
    const { id, city, deliveryMethod, address, pickPoint, recipientPhone, recipientName } = payload;
    const deliveryMethodId = DeliveryMethod.getId(deliveryMethod);
    const pickPointId = PickPoint.getId(pickPoint);
    const geography = {
      city,
      address
    };

    const order = state[id] || {};
    const price = deliveryMethod.price;

    return {
      ...state,
      [id]: {
        ...order,
        delivery: {
          deliveryMethodId,
          geography,
          pickPointId,
          recipientPhone,
          recipientName,
          price
        }
      }
    };
  }),

  handle(ORDER.DELIVERY_INFO_UPDATE, function(state, { id, deliveryInfo }) {
    const { price, pickPoint } = deliveryInfo;

    const pickPointId = PickPoint.getId(pickPoint);

    return {
      ...state,
      [id]: {
        ...state[id],
        delivery: {
          ...state[id].delivery,
          pickPointId,
          price
        }
      }
    };
    // const { deliveryMethod, geography, pickPoint } = deliveryInfo;
    // const deliveryMethodId = DeliveryMethod.getId(deliveryMethod);
    // const pickPointId = PickPoint.getId(pickPoint);

    // const order = state[id] || {};

    // return state;

    // return {
    //   ...state,
    //   [id]: {
    //     ...order,
    //     delivery: {
    //       deliveryMethodId,
    //       geography,
    //       pickPointId
    //     }
    //   }
    // };
  }),

  handle(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.orders) {
      return state;
    }

    return {
      ...entities.orders,
      ...state
    };
  }),

  handle(ACCOUNT.AUTHORIZATION_SUCCESS, (state, { entities }, error) => {
    if (!entities.orders || error) {
      return state;
    }

    return {
      ...entities.orders,
      ...state
    };
  }),

  handle(ORDER.PAYMENT_RESPONSE, (state, { id, payment }, error) => {
    if (error) {
      return state;
    }

    return {
      ...state,
      [id]: {
        ...state[id],
        payment: {
          ...state[id].payment,
          invoiceLink: payment.invoiceLink
        }
      }
    };
  }),

  handle(ORDER.SUCCESS, function(state, { id, order }) {
    const { delivery, payment, ...rest } = order;
    const { deliveryMethod, geography, pickPoint, recipientPhone, recipientName, price } = delivery;
    const { paymentMethod } = payment;

    const deliveryMethodId = DeliveryMethod.getId(deliveryMethod);
    const pickPointId = PickPoint.getId(pickPoint);

    return {
      ...state,
      [id]: {
        ...rest,
        delivery: {
          price,
          deliveryMethod,
          deliveryMethodId,
          geography,
          pickPoint,
          pickPointId,
          recipientPhone,
          recipientName
        },
        payment: {
          paymentMethodId: paymentMethod.id || null
        }
      }
    };
  }),

  handle(ORDER.ERROR, function(state, { id }) {
    return {
      ...state,
      [id]: {
        ...state[id],
        _error: true
      }
    };
  })
);

const authorized = createReducer(
  handle(ORDER.SUCCESS, (state, { id, authorization }) => {
    return {
      ...state,
      [id]: authorization
    };
  })
);

/*
SUGGEST: createReducer(options).on(ACTION, handler).on(ACTION, handler).build(initialState);

const orderReducer = createReducer(options).on().on().build();
USAGE: const newOrder = orderReducer(order, action);

cobmineHashReducers(orderReducer, (payload) => payload.order ? payload.order.id : null)

IMPLEMENTATION:
function cobmineHashReducers(entityReducer, getHash /* :payload -> hash * /) {
  return function hashReducer(state = {}, action) {
    const hash = getHash(action.payload);
    if(hash === null) {
      return state;
    }

    return {
      ...state,
      [hash]: entityReducer(state, action)
    }
  }
}

combineArrayReducers(orderReducer)
*/

const current = createReducer();
// handle(types.CREATE_ON_SERVER, function(state, payload) {
//   const order = Order.normalize(payload.order);

//   return Order.getId(order);
// })

const isSubmitting = createReducer(
  handle(ORDER.SUBMIT, () => true),
  handle(ORDER.SUCCESS, () => false),
  handle(ORDER.ERROR, () => false)
);

const isPaymentLoading = createReducer(
  handle(ORDER.PAYMENT_REQUEST, () => true),
  handle(ORDER.PAYMENT_RESPONSE, () => false)
);

export default combineReducers({
  all: all({}),
  current: current(null),
  authorized: authorized({}),
  _isSubmitting: isSubmitting(false),
  _isPaymentLoading: isPaymentLoading(false)
});

/* SELECTORS */
const makeGetStatus = getItem =>
  createSelector(getItem, function _getStatus(item) {
    if (!item || !item.status) {
      return ORDER_STATUS.ABANDONED;
    }
    return item.status;
  });

export const makeGetIsLoaded = getItem =>
  createSelector(getItem, function _getIsLoadedFromCurrent(item) {
    if (!item) {
      return false;
    }
    return !item.isLoading;
  });

export const makeGetIsLoading = getItem =>
  createSelector(getItem, function _getIsLoadedFromCurrent(item) {
    if (!item) {
      return true; // TODO: This is wrong. By default order is not loading. Order loading only by request.
    }

    return item.isLoading;
  });

export const makeGetIsError = getItem =>
  createSelector(getItem, function _getIsLoadedFromCurrent(item) {
    if (!item) {
      return false;
    }

    return item.isError;
  });

const makeGetPersonalInfo = getItem =>
  createSelector(getItem, function _getPersonalInfo(item) {
    if (!item) {
      return null;
    }

    if (!item.personalInfo) {
      return null;
    }

    return item.personalInfo;
  });

const makeGetDelivery = getItem =>
  createSelector(getItem, fromDeliveryMethodTable.getFindItemById, function _getDeliveryInfo(
    item,
    findDeliveryMethodById
  ) {
    if (!item) {
      return null;
    }

    if (!item.delivery) {
      return null;
    }

    const { delivery } = item;

    const deliveryMethod = findDeliveryMethodById(delivery.deliveryMethodId);

    return {
      ...delivery,
      deliveryMethod
    };
  });

const makeGetPayment = getItem =>
  createSelector(getItem, fromPaymentMethods.getFindItemById, (item, findPaymentMethod) => {
    if (!item || !item.payment) {
      return null;
    }

    return {
      ...item.payment,
      paymentMethod: item.payment.paymentMethod || findPaymentMethod(item.payment.paymentMethodId)
    };
  });

const makeGetComment = getItem =>
  createSelector(getItem, function _getComment(item) {
    if (!item) {
      return '';
    }

    return item.comment;
  });

const makeGetConfirmationMethod = getItem =>
  createSelector(getItem, function _getConfirmationMethod(item) {
    if (!item) {
      return '';
    }

    return item.confirmationMethod;
  });

export const getIdByRouteParams = (_, { match }) => {
  return match.params.id;
};

export const getIdFromProps = (_, { id }) => id;

export const getRoot = state => state.ordering.orders;

export const getAll = createSelector(getRoot, function _getAll(root) {
  return root.all;
});

export const getIsSubmitting = createSelector(getRoot, function _getIsSubmitting(root) {
  return root._isSubmitting || false;
});

export const makeGetItem = getId => {
  return createSelector(getAll, getId, function _getItemById(all, id) {
    if (!id || !all) {
      return null;
    }

    const order = all[id];

    if (!order) {
      return null;
    }

    return order;
  });
};

export const getItemById = makeGetItem(getIdFromProps);

function findOrderFromAll(all, findDeliveryMethodById) {
  return function findOrder(id) {
    if (!id || !all) {
      return null;
    }

    const order = all[id];

    if (!order) {
      return null;
    }

    const hasDelivery = order.delivery && order.delivery.geography;

    const deliveryMethod = hasDelivery && findDeliveryMethodById(order.delivery.deliveryMethodId);

    const delvieryPrice =
      (hasDelivery && deliveryMethod && (deliveryMethod.price || {}).default) || 0;

    const productPrice = order.basket.goods
      .map(calculatePrice)
      .map(item => item.price.current * item.amount)
      .reduce((a, b) => a + b, 0);

    const _delvieryPrice = productPrice >= 3000 ? 0 : delvieryPrice;

    const total = _delvieryPrice + productPrice;

    return {
      ...order,
      total
    };
  };
}

export const getCurrentId = createSelector(getRoot, root => root.current);

export const getCurrent = createSelector(getCurrentId, getAll, function _getCurrent(
  currentId,
  all
) {
  if (!currentId || !all) {
    return null;
  }

  const current = all[currentId];

  if (!current) {
    return null;
  }

  return current;
});

export const getIsLoadingFromCurrent = makeGetIsLoading(getCurrent);
export const getIsLoadingById = makeGetIsLoading(getItemById);
export const getIsErrorById = makeGetIsError(getItemById);
export const getPersonalInfoFromCurrent = makeGetPersonalInfo(getCurrent);

export const getStatusById = makeGetStatus(getItemById);
export const getPersonalInfoById = makeGetPersonalInfo(getItemById);
export const getDeliveryById = makeGetDelivery(getItemById);
export const getPaymentById = makeGetPayment(getItemById);
export const getCommentById = makeGetComment(getItemById);
export const getConfirmationMethod = makeGetConfirmationMethod(getItemById);

export const getError = createSelector(getItemById, item => {
  if (!item) {
    return false;
  }

  return item._error || false;
});

export const getPersonalInfoComplete = createSelector(
  getPersonalInfoById,
  fromBasket.getIsConformationBonusesRequired,
  (personalInfo, isConformationBonusesRequired) => {
    if (!personalInfo) {
      return false;
    }

    if (isConformationBonusesRequired) {
      return false;
    }

    // const hasLastName = personalInfo && personalInfo.firstName.indexOf(" ") !== -1
    // console.log("personalInfo.firstName", personalInfo.firstName)

    return Boolean(personalInfo.firstName && personalInfo.phone && personalInfo.email);
  }
);

export const getDeliveryComplete = createSelector(getDeliveryById, delivery => {
  return Boolean(delivery);
});

export const getPaymentComplete = createSelector(getPaymentById, payment => Boolean(payment));

export const getFullById = createStructuredSelector({
  status: getStatusById,
  personalInfo: getPersonalInfoById,
  delivery: getDeliveryById,
  payment: getPaymentById,
  comment: getCommentById,
  confirmationMethod: getConfirmationMethod
});

export const getPhoneById = createSelector(getPersonalInfoById, function _getPhoneById(
  personalInfo
) {
  if (!personalInfo) {
    return null;
  }
  return personalInfo.phone;
});

export const getFirstNameById = createSelector(getPersonalInfoById, function _getFirstNameById(
  personalInfo
) {
  if (!personalInfo) {
    return null;
  }
  return personalInfo.firstName;
});
export const getLastNameById = createSelector(getPersonalInfoById, function _getLastNameById(
  personalInfo
) {
  if (!personalInfo) {
    return null;
  }
  return personalInfo.lastName;
});

export const getPickPointId = createSelector(getDeliveryById, function _getPickPointId(delivery) {
  if (!delivery) {
    return null;
  }

  return delivery.pickPointId;
});

export const getPickPointById = createSelector(
  fromPickPointList.makeGetItemById,
  getPickPointId,
  function _getPickPointById(getPickPointById, pickPointId) {
    return getPickPointById(pickPointId);
  }
);

export const getIsPaymentRequiredById = createSelector(
  getPaymentById,
  function _getIsPaymentRequiredById(payment) {
    if (!payment || !payment.paymentMethod) {
      return false;
    }
    return payment.paymentMethod.requirePayment;
  }
);

export const getIsPaymentLoading = createSelector(getRoot, root => root._isPaymentLoading || false);

export const getGoodsListById = createSelector(
  getItemById,
  fromBasket.getListToOrder,
  (item, basketList) => {
    if (!item) {
      return null;
    }

    if (!item.basket || !item.basket.goods) {
      return basketList.map(calculatePrice);
    }

    return item.basket.goods.map(calculatePrice);
  }
);

export const getPromoById = createSelector(getItemById, item => {
  if (!item || !item.basket || !item.basket.promos) {
    return null;
  }

  return item.basket.promos[0];
});

export const getProductsCostById = createSelector(getGoodsListById, goodsList => {
  if (!goodsList) {
    return 0;
  }

  return goodsList.map(item => item.price.default * item.amount).reduce((a, b) => a + b, 0);
});

export const getSupposedDeliveryCostById = createSelector(getDeliveryById, delivery => {
  if (!delivery || !delivery.deliveryMethod) {
    return null;
  }
  const price = delivery.deliveryMethod.price;

  if (price.current || price.current === 0) {
    return price.current;
  }

  if (price.default || price.default === 0) {
    return price.default;
  }

  return null;
});

export const getProductsCostWithDiscount = createSelector(getGoodsListById, goodsList => {
  if (!goodsList) {
    return 0;
  }

  return goodsList.map(item => item.price.current * item.amount).reduce((a, b) => a + b, 0);
});

export const getDeliveryCostById = createSelector(
  getDeliveryById,
  getProductsCostWithDiscount,
  fromBasket.getFreeDeliveryPrice,
  (delivery, productsCost, freeDeliveryPrice) => {
    if (!delivery || !delivery.price) {
      return null;
    }

    if (productsCost >= freeDeliveryPrice) {
      return 0;
    }

    const price = delivery.price;

    const discount = price.discount.numerical || price.discount.percentage * price.default || 0;

    const current = price.default - discount;

    return current;
  }
);

export const getTotalCostById = createSelector(
  getProductsCostWithDiscount,
  getDeliveryCostById,

  (productsCost, deliveryCost) => {
    const deliveryCostNumber = deliveryCost || 0;

    return productsCost + deliveryCostNumber;
  }
);

export const getDiscountById = createSelector(
  getTotalCostById,
  getProductsCostById,
  getDeliveryCostById,
  (totalCost, productsCost, deliveryCost) => {
    const deliveryCostNumber = deliveryCost || 0;

    return productsCost + deliveryCostNumber - totalCost;
  }
);

export const getCityById = createSelector(
  fromCityList.getFindItem,
  fromCityTable.getAll,
  getDeliveryById,
  function _getCityTitleByFiasId(findCityById, fiasIdTable, delivery) {
    const city = delivery.geography.city;

    if (city.id) {
      return findCityById(city.id);
    }

    if (city.fiasId) {
      return fiasIdTable[city.fiasId];
    }

    return null;
  }
);

export const getCityTitleById = createSelector(getCityById, function _getCityTitleByFiasId(city) {
  if (!city) {
    return null;
  }

  return city.title;
});
const getAuthorizedOrders = createSelector(
  getRoot,

  root => root.authorized
);

export const getAuthorizationById = createSelector(
  getIdFromProps,
  getAuthorizedOrders,
  (id, authorizedOrders) => {
    if (!authorizedOrders || !id) {
      return null;
    }

    return authorizedOrders[id];
  }
);

function calculatePrice(item) {
  const price = item.price;
  const discount =
    price.discount.numerical > 0
      ? price.discount.numerical
      : price.discount.percentage > 0
      ? price.discount.percentage * price.default
      : 0;

  return {
    ...item,
    price: {
      ...price,
      discount,
      current: price.default - discount
    }
  };
}

export const getFindItem = createSelector(
  getAll,
  fromDeliveryMethodTable.getFindItemById,
  getDeliveryCostById,
  function _getFindItem(all, findDeliveryMethodById, getDeliveryCostById) {
    return findOrderFromAll(all, findDeliveryMethodById, getDeliveryCostById);
  }
);
