import React from 'react';
// import { prefix } from 'inline-style-prefixer';

import './plain-text.css';

export default function PlainText({ data, colors }) {
  return (
    <div style={data.style} className="TitleSection">
      {data.text}
    </div>
  );
}
