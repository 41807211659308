// import getFilledArray from './get-filled-array';

const PLACEHOLDERS = {
  ELEVENT_SYMBOLS: '(000) 000 00 00',
  TWELVE_SYMBOLS: '(00) 000 00 00'
};

const getPlaceholder = (numberAmount = 11, withCode = false) => {
  // const filling = '0';
  // const operatorPlaceholder = getFilledArray(operatorCodeLength, filling).join('');

  if (numberAmount === 12) {
    return withCode ? "+000 " + PLACEHOLDERS.TWELVE_SYMBOLS : PLACEHOLDERS.TWELVE_SYMBOLS;
  }

  return  withCode ? "+7 " + PLACEHOLDERS.ELEVENT_SYMBOLS : PLACEHOLDERS.ELEVENT_SYMBOLS;
};

export default getPlaceholder;
