import * as magazineSaga from './magazine-saga';
import * as articleCountersSaga from './article-counters-saga';

const sagas = {
  ...magazineSaga,
  ...articleCountersSaga
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
