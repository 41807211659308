import React, { Component } from 'react';
import ProductsNew from 'modules/product/components/products-new/products-new';
import { connect } from 'react-redux';
import * as fromGroups from 'modules/product/ducks/groups';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({
  groupNew: fromGroups.getNewIndex
});

const mapDispatchToProps = {
  showItems: fromGroups.actions.showNewIndex
};

const MAX_NEW_COUNT = 8;

class ProductsNewContainer extends Component {
  render() {
    const { groupNew, ...rest } = this.props;
    const productList = this.getProductList(groupNew);
    const error = this.getError(groupNew);
    const isLoading = this.getIsLoading(groupNew);

    return <ProductsNew productList={productList} error={error} isLoading={isLoading} {...rest} />;
  }

  getIsLoading(groupNew) {
    if (!groupNew || !groupNew.products) {
      return true;
    }
    return false;
  }

  getError(groupNew) {
    if (groupNew && groupNew.error) {
      return 'Failed loading new products';
    }

    return false;
  }

  getProductList(groupNew) {
    if (!groupNew || !groupNew.products) {
      return [];
    }
    return groupNew.products.slice(0, MAX_NEW_COUNT);
  }

  // componentWillMount() {
  //   if (__SERVER__) {
  //     this.props.showItems();
  //   }
  // }

  componentDidMount() {
    this.props.showItems();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsNewContainer);
