import { createReducer, createAction, createActionError, handle } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import * as PROFILE from 'modules/profile/types/profile';
import EMAIL_STATUS from '../constants/email-status';

/* TYPES */
const CHANGE_FIRST_NAME = 'ordering/personal-info-form/CHANGE_FIRST_NAME';
const SIGN_IN = 'ordering/personal-info-form/SIGN_IN';
const FIRST_NAME_VALIDATION = 'ordering/personal-info-form/FIRST_NAME_VALIDATION';
const CHANGE_PHONE = 'ordering/personal-info-form/CHANGE_PHONE';
const PHONE_VALIDATION = 'ordering/personal-info-form/PHONE_VALIDATION';
const CHANGE_EMAIL = 'ordering/personal-info-form/CHANGE_EMAIL';
const EMAIL_VALIDATION = 'ordering/personal-info-form/EMAIL_VALIDATION';
const EMAIL_STATUS_REQUEST = 'ordering/personal-info-form/EMAIL_STATUS_REQUEST';
const EMAIL_STATUS_REGISTERED = 'ordering/personal-info-form/EMAIL_STATUS_REGISTERED';
const EMAIL_STATUS_UNREGISTERED = 'ordering/personal-info-form/EMAIL_STATUS_UNREGISTERED';
const PASSWORD_CHANGE = 'ordering/personal-info-form/PASSWORD_CHANGE';
const CHANGE_INSTANT_ORDER = 'ordering/personal-info-form/CHANGE_INSTANT_ORDER';
const RESET = 'ordering/personal-info-form/RESET';
const FILL = 'ordering/personal-info-form/FILL';

export const types = {
  CHANGE_EMAIL,
  CHANGE_PHONE,
  CHANGE_FIRST_NAME,
  PASSWORD_CHANGE,
  SIGN_IN
};

const emptyField = {
  value: '',
  error: null
};

/* REDUCERS */
const handleFirstNameChange = (state, { firstName }) => {
  return {
    value: firstName,
    error: null
  };
};

const handlePhoneChange = (state, { phone }) => {
  return {
    value: phone,
    error: null
  };
};

const handleEmailChange = (state, { email }) => {
  return {
    value: email,
    error: null
  };
};

const passwordChange = (state, password) => {
  return {
    value: password,
    error: null
  };
};

const firstName = createReducer(
  handle(CHANGE_FIRST_NAME, handleFirstNameChange),
  handle(FILL, handleFirstNameChange),
  handle(RESET, () => emptyField),
  handle(FIRST_NAME_VALIDATION, (state, error, withError) => ({
    ...state,
    error: withError ? error : null
  })),
  handle(PROFILE.RESPONSE, (state, { profile }, error) => {
    if (error) return state;
    return {
      ...state,
      value: state.value || profile.firstName
    };
  })
);

const phone = createReducer(
  handle(CHANGE_PHONE, handlePhoneChange),
  handle(FILL, handlePhoneChange),
  handle(RESET, () => emptyField),
  handle(PHONE_VALIDATION, (state, error, withError) => ({
    ...state,
    error: withError ? error : null
  })),
  handle(PROFILE.RESPONSE, (state, { profile }, error) => {
    if (error) return state;
    return {
      ...state,
      value: state.value || profile.phone
    };
  })
);

const email = createReducer(
  handle(CHANGE_EMAIL, handleEmailChange),
  handle(FILL, handleEmailChange),
  handle(RESET, () => emptyField),
  handle(EMAIL_VALIDATION, (state, error, withError) => ({
    ...state,
    error: withError ? error : null
  })),
  handle(PROFILE.RESPONSE, (state, { entities }, error) => {
    if (error) return state;
    return {
      ...state,
      value: state.value || ((entities || {}).account || {}).email
    };
  })
);

const password = createReducer(
  handle(PASSWORD_CHANGE, (state, { password }) => passwordChange(state, password)),
  handle(RESET, () => emptyField)
);

const emailStatus = createReducer(
  handle(EMAIL_STATUS_REGISTERED, () => EMAIL_STATUS.REGISTERED),
  handle(EMAIL_STATUS_UNREGISTERED, () => EMAIL_STATUS.UNREGISTERED),
  handle(CHANGE_EMAIL, () => EMAIL_STATUS.UNDEFINED)
);

const emailStatusLoading = createReducer(
  handle(EMAIL_STATUS_REQUEST, () => true),
  handle(EMAIL_STATUS_REGISTERED, () => false),
  handle(EMAIL_STATUS_UNREGISTERED, () => false),
  handle(CHANGE_EMAIL, () => false)
);

const instantOrder = createReducer(
  handle(CHANGE_INSTANT_ORDER, (state, { instantOrder }) => instantOrder),
  handle(RESET, () => false)
);

export default combineReducers({
  firstName: firstName({}),
  phone: phone({}),
  email: email({}),
  password: password({}),
  emailStatus: emailStatus(EMAIL_STATUS.UNDEFINED),
  emailStatusLoading: emailStatusLoading(false),
  instantOrder: instantOrder(false)
});

/* SELECTORS */
const makeFieldSelector = fieldName =>
  createSelector(
    getRoot,
    root => root[fieldName]
  );

export const getRoot = state => state.ordering.personalInfoForm;
const getFirstNameField = makeFieldSelector('firstName');
const getPhoneField = makeFieldSelector('phone');
const getEmailField = makeFieldSelector('email');
const getPasswordField = makeFieldSelector('password');

export const getFirstName = createSelector(
  getFirstNameField,
  field => field.value
);
export const getFirstNameError = createSelector(
  getFirstNameField,
  field => field.error
);
export const getPhone = createSelector(
  getPhoneField,
  field => field.value
);
export const getPhoneError = createSelector(
  getPhoneField,
  field => field.error
);
export const getEmail = createSelector(
  getEmailField,
  field => field.value
);
export const getEmailError = createSelector(
  getEmailField,
  field => field.error
);
export const getEmailStatus = createSelector(
  getRoot,
  root => root.emailStatus
);
export const getEmailStatusLoading = createSelector(
  getRoot,
  root => root.emailStatusLoading
);

export const getPassword = createSelector(
  getPasswordField,
  field => field.value
);
export const getPasswordError = createSelector(
  getPasswordField,
  field => field.error
);

export const getInstantOrder = makeFieldSelector('instantOrder');

/* ACTIONS */
export const actions = {
  signIn: (email, password) => createAction(SIGN_IN, { email, password }),
  fill: (firstName = null, phone = null, email = null) =>
    createAction(FILL, { firstName, phone, email }),
  changeFirstName: firstName => createAction(CHANGE_FIRST_NAME, { firstName }),
  changePhone: phone => createAction(CHANGE_PHONE, { phone }),
  changeEmail: email => createAction(CHANGE_EMAIL, { email }),
  changePassword: password => createAction(PASSWORD_CHANGE, { password }),
  emailStatusRequest: () => createAction(EMAIL_STATUS_REQUEST),
  emailStatusRegistered: () => createAction(EMAIL_STATUS_REGISTERED),
  emailStatusUnregistered: () => createAction(EMAIL_STATUS_UNREGISTERED),
  emailValidationResult: error =>
    error ? createActionError(EMAIL_VALIDATION, error) : createAction(EMAIL_VALIDATION),
  firstNameValidationResult: error =>
    error ? createActionError(FIRST_NAME_VALIDATION, error) : createAction(FIRST_NAME_VALIDATION),
  phoneValidationResult: error =>
    error ? createActionError(PHONE_VALIDATION, error) : createAction(PHONE_VALIDATION),
  changeInstantOrder: instantOrder => createAction(CHANGE_INSTANT_ORDER, { instantOrder }),
  reset: () => createAction(RESET)
};
