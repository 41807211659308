const DIALOG_AUTH = 'DIALOG_AUTH';
const DIALOG_REGISTRATION = 'DIALOG_REGISTRATION';
const DIALOG_ACQUAINTANCE = 'DIALOG_ACQUAINTANCE';
const DIALOG_CITY = 'DIALOG_CITY';
const DIALOG_CALLBACK = 'DIALOG_CALLBACK';
const DIALOG_CALLBACK_CONFORMATION = 'DIALOG_CALLBACK_CONFORMATION';
const DIALOG_SUBSCRIBE_CONFORMATION = 'DIALOG_SUBSCRIBE_CONFORMATION';
const DIALOG_GIFT = 'DIALOG_GIFT';
const DIALOG_REPLY_CONFORMATION = 'DIALOG_REPLY_CONFORMATION';
const DIALOG_REVIEW_CONFORMATION = 'DIALOG_REVIEW_CONFORMATION';

const DIALOG_SUBSCRIBE_SUGGESTION = 'DIALOG_SUBSCRIBE_SUGGESTION';

const DIALOG_PASSWORD_RECOVERY_REQUEST = 'DIALOG_PASSWORD_RECOVERY_REQUEST';
const DIALOG_PASSWORD_RECOVERY_REQUEST_SUCCESS = 'DIALOG_PASSWORD_RECOVERY_REQUEST_SUCCESS';

const DIALOG_CONFIRM_PASSWORD_RECOVERY = 'DIALOG_CONFIRM_PASSWORD_RECOVERY';
const DIALOG_CONFIRM_PASSWORD_RECOVERY_SUCCESS = 'DIALOG_CONFIRM_PASSWORD_RECOVERY_SUCCESS';
const DIALOG_CONFIRM_PASSWORD_RECOVERY_ERROR = 'DIALOG_CONFIRM_PASSWORD_RECOVERY_ERROR';

const DIALOG_ADD_TO_WAITING_LIST_CONFORMATION = 'DIALOG_ADD_TO_WAITING_LIST_CONFORMATION';

const DIALOG_CONFIRM_PHYSICAL_TRAITS = 'DIALOG_CONFIRM_PHYSICAL_TRAITS';

const DIALOG_BONUSES_CONFIRM_CODE = 'DIALOG_BONUSES_CONFIRM_CODE';

const DIALOG_FEEDBACK_CONFIRM = 'DIALOG_FEEDBACK_CONFIRM';

const DIALOG_UTM = 'DIALOG_UTM';

const DIALOG_CHANGE_PROFILE_NAME = 'DIALOG_CHANGE_PROFILE_NAME';
const INGREDIENT_DIALOG = 'INGREDIENT_DIALOG';
const DIALOG_FAQ_QUESTION = 'DIALOG_FAQ_QUESTION';

export default {
  DIALOG_AUTH,
  DIALOG_REGISTRATION,
  DIALOG_ACQUAINTANCE,
  DIALOG_CITY,
  DIALOG_CALLBACK,
  DIALOG_CALLBACK_CONFORMATION,
  DIALOG_SUBSCRIBE_SUGGESTION,
  DIALOG_SUBSCRIBE_CONFORMATION,
  DIALOG_GIFT,
  DIALOG_REPLY_CONFORMATION,
  DIALOG_REVIEW_CONFORMATION,
  DIALOG_ADD_TO_WAITING_LIST_CONFORMATION,
  DIALOG_PASSWORD_RECOVERY_REQUEST,
  DIALOG_PASSWORD_RECOVERY_REQUEST_SUCCESS,
  DIALOG_CONFIRM_PASSWORD_RECOVERY,
  DIALOG_CONFIRM_PASSWORD_RECOVERY_SUCCESS,
  DIALOG_CONFIRM_PASSWORD_RECOVERY_ERROR,
  DIALOG_CONFIRM_PHYSICAL_TRAITS,
  DIALOG_BONUSES_CONFIRM_CODE,
  DIALOG_FEEDBACK_CONFIRM,
  DIALOG_UTM,
  DIALOG_CHANGE_PROFILE_NAME,
  INGREDIENT_DIALOG,
  DIALOG_FAQ_QUESTION
};
