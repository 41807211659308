import React, { Component } from 'react';

import Pagination from 'modules/core/components/pagination/pagination';
import MassMediaArticle from '../mass-media-article/mass-media-article';

import './mass-media-list.css';

class MassMediaList extends Component {
  render() {
    const { pageAmount, currentPage, onPageChange, article } = this.props;

    if (!article) {
      return null;
    }

    return (
      <div className="MassMediaList">
        <div className="MassMediaList-content">
          {article.map(item => (
            <div className="MassMediaList-item">
              <MassMediaArticle {...item}></MassMediaArticle>
            </div>
          ))}
        </div>
        {pageAmount > 1 && (
          <div className="MassMediaList-pagination">
            <Pagination amount={pageAmount} current={currentPage} onChange={onPageChange} />
          </div>
        )}
      </div>
    );
  }
}

export default MassMediaList;
