import React, { Component } from 'react';
import { Input } from 'modules/form';

import './input-apartment.css';

class InputApartment extends Component {
  state = {};
  render() {
    const { ...inputProps } = this.props;
    return (
      <div className="InputApartment">
        <Input type="text" {...inputProps} />
      </div>
    );
  }
}

export default InputApartment;
