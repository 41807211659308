import React, { Component } from 'react';
import SimilarProducts from './similar-products';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import Media from 'react-media';

function OptionalContainer({ enabled, children }) {
  if (!enabled) {
    return children;
  }
  return <Container>{children}</Container>;
}

class ProductCarouselSection extends Component {
  state = {};
  render() {
    const { title, list, listId } = this.props;
    return (
      <SectionDefault title={title}>
        <Media query="(max-width: 1100px)">
          {isMobile => (
            <OptionalContainer enabled={!isMobile}>
              <SimilarProducts list={list} listId={listId} />
            </OptionalContainer>
          )}
        </Media>
      </SectionDefault>
    );
  }
}

export default ProductCarouselSection;
