import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from 'react-media';

import { getIndexLink } from 'routes/links';

// import FilterMenu from 'modules/profile/components/filter-menu/filter-menu';
import OrderTable from 'modules/ordering/components/order-table/order-table';
import isFunction from 'modules/utils/is-function';
import Button from 'modules/core/components/button/button';
import Loader from 'modules/core/components/loader/loader';

import Row from './row';

import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import { getfilteredOrders, getIsTrackingNumberExistSomewhere } from './helpers';
// import filters from './filters';

import './order-scene.css';

class OrderScene extends Component {
  static Row = Row;

  static propTypes = {
    orders: PropTypes.object
  };

  render() {
    const { orders, activeFilter, isLoading, isOrdersExist, isReady } = this.props;

    if (isLoading) {
      return null;
    }

    if (!isReady) {
      return (
        <div className="OrderScene">
          <div className="OrderScene-loader">
            <Loader />
          </div>
        </div>
      );
    }

    const filteredOrders = getfilteredOrders(orders, activeFilter);
    const isTrackingNumberExist = getIsTrackingNumberExistSomewhere(filteredOrders);

    if (!isOrdersExist) {
      return (
        <div className="OrderScene">
          <div className="OrderScene-emptyComment">Упс, ты еще не совершал покупок :(</div>

          <div className="OrderScene-emptyButton">
            <Media query="(max-width: 480px)">
              {isLowMobile => (
                <Button
                  variant="primary"
                  title="Начать покупки"
                  component={Link}
                  to={getIndexLink()}
                  size={isLowMobile ? 'small' : 'normall'}
                  expanded={isLowMobile}
                />
              )}
            </Media>
          </div>
        </div>
      );
    }

    return (
      <div className="OrderScene">
        {/* <div className="OrderScene-filter">
          <FilterMenu
            all
            filters={filters}
            onChange={this.handleChange}
            activeFilter={activeFilter}
          />
        </div> */}
        <div className="OrderScene-table">
          <OrderTable>
            {this.renderHeader(isTrackingNumberExist)}
            {filteredOrders.map(order => this.renderRow(order, isTrackingNumberExist))}
          </OrderTable>
        </div>
      </div>
    );
  }

  renderHeader(isTrackingNumberExist) {
    return (
      <OrderTable.Header>
        <OrderTable.Cell variant="number">
          <OrderTable.SubCell>Номер заказа</OrderTable.SubCell>
        </OrderTable.Cell>
        <OrderTable.Cell variant="date">
          <OrderTable.SubCell>Дата</OrderTable.SubCell>
        </OrderTable.Cell>
        <OrderTable.Cell variant="price">
          <OrderTable.SubCell>Сумма</OrderTable.SubCell>
        </OrderTable.Cell>
        {isTrackingNumberExist && (
          <OrderTable.Cell variant="trackingNumber">
            <OrderTable.SubCell>Трек-номер</OrderTable.SubCell>
          </OrderTable.Cell>
        )}
        <OrderTable.Cell variant="status">
          <OrderTable.SubCell>Статус</OrderTable.SubCell>
        </OrderTable.Cell>
      </OrderTable.Header>
    );
  }

  renderRow(order, isTrackingNumberExist) {
    return (
      <HideErrorBoundary>
        <Row key={order.id} order={order} isTrackingNumberExist={isTrackingNumberExist} />
      </HideErrorBoundary>
    );
  }

  handleChange = id => {
    const { onFilterChange } = this.props;

    if (!isFunction(onFilterChange)) {
      return;
    }
    onFilterChange(id);
  };
}

export default OrderScene;
