import React, { useEffect, useState, useCallback } from 'react';
import isFunction from 'modules/utils/is-function';

const calculateTimeLeft = expireDate => {
  const expireTime = +new Date(expireDate);
  const timeStart = +new Date();
  const difference = expireTime - timeStart;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      timeEnd: expireTime,
      difference
    };
  }

  return timeLeft;
};

function CountdownTimer({ expireDate, onTick, onExpire, tickDuration = 1000 }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expireDate));

  const _onTick = useCallback(onTick, []);
  const _onExpire = useCallback(onExpire, []);

  useEffect(() => {
    if (!timeLeft.difference) {
      if (isFunction(_onExpire)) {
        _onExpire();
      }

      return;
    }

    setTimeout(() => {
      setTimeLeft(calculateTimeLeft(expireDate));
      if (isFunction(_onTick)) {
        _onTick(timeLeft);
      }
    }, tickDuration);
  }, [timeLeft, expireDate, tickDuration, _onTick, _onExpire]);

  //   const timerComponents = [];

  //   Object.keys(timeLeft).forEach(interval => {
  //     if (!timeLeft[interval]) {
  //       return;
  //     }

  //     timerComponents.push(
  //       <span>
  //         {timeLeft[interval]} {interval}{' '}
  //       </span>
  //     );
  //   });

  return <></>;

  //   return (
  //     <div>
  //       <h1>Alligator.io New Year's 2020 Countdown</h1>
  //       <h2>With React Hooks!</h2>
  //       {timerComponents.length ? timerComponents : <span>Time's up!</span>}
  //     </div>
  //   );
}

export default CountdownTimer;
