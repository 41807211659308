function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(`Status code is ${response.status}`);
    error.response = response;
    throw error;
  }
}

export default checkStatus;
