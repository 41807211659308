import { createReducer, handle } from 'modules/utils/dux';
import * as TYPES from './types/navigations';
import { createSelector } from '../../utils/debug-reselect';

export { default as actions } from './actions/navigations';

const reducer = createReducer(
  handle(TYPES.ADD, (state, { table }) => {
    return {
      ...state,
      table
    };
  }),
  handle(TYPES.SET_LOADED, state => {
    return {
      ...state,
      isLoaded: true
    };
  })
);

export default reducer({
  table: {},
  isLoaded: false
});

/* SELECTORS */

const getRoot = state => state.core.navigations;

export const getTable = createSelector(getRoot, root => root.table);
export const getNavigationDataLoaded = createSelector(getRoot, root => root.isLoaded);

export const getMainCatalogNavigation = createSelector(
  getTable,
  table => table.main_catalog_navigation
);

export const getMainCatalogNavigationMobile = createSelector(
  getTable,
  table => table.main_catalog_navigation_mobile || table.main_catalog_navigation
);

export const getMainCatalogNavigationWithSucatalogMobile = createSelector(
  getMainCatalogNavigationMobile,
  getCatalogWithCategoriesAsSubmenu
);

export const getDrawerCatalogNavigation = createSelector(
  getTable,
  table => table.drawer_catalog_navigation || table.main_catalog_navigation
);

export const getDrawerCatalogWithSucatalogNavigation = createSelector(
  getDrawerCatalogNavigation,
  getCatalogWithCategoriesAsSubmenu
);

function getCatalogWithCategoriesAsSubmenu(navigation) {
  if (!navigation) {
    return null;
  }
  if (!navigation.list) {
    return null;
  }

  return navigation.list.map(item => {
    if (!item.submenu) {
      return item;
    }
    const submenu = item.submenu.filter(subItem => subItem.slug === 'subcategory')[0].list;

    const submenuWidthItemSubmenu = submenu.map(subItem => {
      if (!subItem.submenu) {
        return subItem;
      }

      const subMenu = subItem.submenu.filter(
        itemOfSubmenu => itemOfSubmenu.slug === 'subcategory'
      )[0].list;

      return {
        ...subItem,
        submenu: subMenu
      };
    });

    const modifiedItem = {
      ...item,
      submenu: submenuWidthItemSubmenu
    };

    return modifiedItem;
  });
}

export const getGlogalMobile = createSelector(getTable, table => table.global_mobile_navigation);

export const getFooterFirstColumn = createSelector(getTable, table => table.footer_first_column);
export const getFooterSecondColumn = createSelector(getTable, table => table.footer_second_column);
export const getFooterThirdColumn = createSelector(getTable, table => table.footer_third_column);
export const getFooterFourthColumn = createSelector(getTable, table => table.footer_fourth_column);
export const getFooterMobile = createSelector(getTable, table => table.footer_mobile);

export const getCategotyFilterPanel = createSelector(
  getTable,
  table => table.category_filter_panel
);
