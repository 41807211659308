import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import LoginDialogRoute from '../../containers/login-dialog-route';
import LoginRecoverDialogRoute from '../../containers/login-recover-dialog-route';
import RegisterDialogRoute from '../../containers/register-dialog-route';

import Error404 from '../../pages/error-404';

import ContactsPage from 'modules/core/pages/contacts';
import AgreementsPage from 'modules/core/pages/agreements';
import AboutPage from 'modules/core/pages/about';
import FeedbackThanksPage from 'modules/core/pages/feedback-thanks';
import GiftSertifikatRulesPage from 'modules/core/pages/gift-sertifikat-rules';
import PaymentAndDeliveryPage from 'modules/core/pages/payment-and-delivery';
import RewardsPage from 'modules/core/pages/rewards';
import DeclarationsPage from 'modules/core/pages/declarations';
import CookiePage from 'modules/core/pages/cookie-use-rules';
import ProductPage from 'modules/product/pages/product-page';

import BasketPage from 'modules/ordering/pages/basket-page';
import OrderingPage from 'modules/ordering/pages/ordering-page';
import OrderingSuccess from 'modules/ordering/pages/success-page';
import FAQPage from 'modules/core/pages/faq-page';

import LandingPage, {
  // NewShopsPageBySlugFromRoute,
  LandingSlugFromRoute
} from 'modules/landing/pages/landing-page';
import ShopsPage from 'modules/core/pages/shops';
import IndexPage from 'modules/core/pages/index-page';
import IngredientPage from 'modules/ingredient/pages/ingredient-page/ingredient-page';
import CategoryPage from 'modules/product/pages/category-page';
import SearchPage from 'modules/product/pages/search-page';
import GroupPage from 'modules/core/pages/group-page';
import ProfilePage from 'modules/profile/pages/profile';
import BlogPage from 'modules/blog/pages/blog';
import ArticlePage from 'modules/blog/pages/article';
import Feedback from 'modules/core/pages/complaint-form-page';
import AskQuestionPage from 'modules/core/pages/ask_question_page';
import EmailFeedbackPage from 'modules/core/pages/email-feedback';
import EmailFeedbackThanksPage from 'modules/core/pages/email-feedback-thanks';
import PaymentSuccess from 'modules/core/pages/payment-success';
import PaymentWrong from 'modules/core/pages/payment-wrong';
import MassMediaPage from 'modules/mass-media/pages/mass-media-page';

import RedirectPaymentPage from 'modules/core/pages/redirect-payment-page';
import HappyMasterPage from 'modules/core/pages/happy-master-page';
import BonusConditionsPage from 'modules/core/pages/bonus-conditions';

class ContentScene extends Component {
  render() {
    const { inFooter } = this.props;

    return (
      <Switch>
        <Route path="/" exact={true} component={IndexPage} />

        <LoginDialogRoute path="/login" exact={true} />
        <LoginRecoverDialogRoute path="/login/recover" exact={true} />
        <Route path="/login/recover/check" render={() => <Redirect to="/" />} />
        <RegisterDialogRoute path="/register" />

        <Route path="/category/:item/:nested?" exact={true} component={CategoryPage} />
        <Route
          path="/product/:slug"
          exact={true}
          render={props => <ProductPage {...props} inFooter={inFooter} />}
        />
        <Route path="/ingredient/:id" exact={true} component={IngredientPage} />
        <Route path="/group/:slug" exact={true} component={GroupPage} />
        <Route path="/search/:query" exact={true} component={SearchPage} />

        <Route path="/basket" exact={true} component={BasketPage} />
        <Route path="/msk" exact={true} render={() => <LandingPage slug="msk" />} />
        <Route path="/spb" exact={true} render={() => <LandingPage slug="spb" />} />
        <Route path="/happy-master" exact={true} render={() => <HappyMasterPage />} />

        <Route
          path="/landing-constructor"
          exact={true}
          render={() => <LandingPage slug="new_shops" />}
        />
        {/* <Route
          path="/new_shops/:slug"
          exact={true}
          render={() => <NewShopsPageBySlugFromRoute />}
        /> */}
        <Route path="/landing/:slug" exact={true} render={() => <LandingSlugFromRoute />} />
        <Route path="/ordering/:id" exact={true} component={OrderingPage} />
        <Route path="/ordering/:id/success" exact={true} component={OrderingSuccess} />

        <Route path="/profile" exact={false} component={ProfilePage} />

        <Route path="/magazine" exact={true} component={BlogPage} />
        <Route path="/magazine/:slug" exact={true} component={ArticlePage} />

        <Route path="/contacts" exact={true} component={ContactsPage} />
        <Route path="/agreements" exact={true} component={AgreementsPage} />
        <Route path="/about" exact={true} component={AboutPage} />
        <Route path="/feedback/thanks" exact={true} component={FeedbackThanksPage} />

        <Route path="/gift-sertifikat-rules" exact={true} component={GiftSertifikatRulesPage} />

        <Route path="/feedback" exact={true} component={Feedback} />
        <Route path="/ask-question" exact={true} component={AskQuestionPage} />

        <Route path="/shops" exact={true} component={ShopsPage} />
        <Route path="/payment-and-delivery" exact={true} component={PaymentAndDeliveryPage} />
        <Route path="/cookie" exact={true} component={CookiePage} />
        <Route path="/reward" exact={true} component={RewardsPage} />
        <Route path="/declarations" exact={true} component={DeclarationsPage} />
        <Route
          path={'/email-feedback/:variant(bad|good|neutral)'}
          exact={true}
          component={EmailFeedbackPage}
        />
        <Route path="/email-feedback-thanks" exact={true} component={EmailFeedbackThanksPage} />
        <Route path="/payment-success" exact={true} component={PaymentSuccess} />
        <Route path="/payment-wrong" exact={true} component={PaymentWrong} />
        <Route path="/redirect-payment-page" exact={true} component={RedirectPaymentPage} />
        <Route path="/mass-media-about-us" exact={true} component={MassMediaPage} />
        <Route path="/bonus-conditions" exact={true} component={BonusConditionsPage} />
        <Route path="/faq" exact={true} component={FAQPage} />

        {/* <Route component={Error404} /> */}
        <Route path="/error-404" component={Error404} />
        <Route render={() => <Redirect to="/error-404" />} />
      </Switch>
    );
  }
}

export default ContentScene;
