import React, { Component } from 'react';

import Dialog from 'modules/core/components/dialog/dialog';

import './callback-conformation-dialog.css';

class CallbackConformationDialog extends Component {
  render() {
    const { onClose, name, ...rest } = this.props;
    return (
      <Dialog closeable {...rest} onClose={onClose}>
        <div className="CallbackConformationDialog">
          <h1 className="CallbackConformationDialog-title">{name}, cпасибо за обращение!</h1>
          <p className="CallbackConformationDialog-info">
            В ближайшее время наш менеджер свяжемся с Вами
          </p>

          {/* <div className="CallbackConformationDialog-form">
            <div className="CallbackConformationDialog-formItem">
              <Button
                title="Закрыть"
                variant="primary"
                expanded
                onClick={onClose}
              />
            </div>
          </div> */}
        </div>
      </Dialog>
    );
  }
}

export default CallbackConformationDialog;
