import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIndexLink, getBlogLink } from 'routes/links';

import Page from 'modules/core/components/page/page';
import SectionDefault from 'modules/core/components/section-default/section-default';
import Container from 'modules/core/components/container/container';
import FeedbackCall from 'modules/core/components/feedback-call/feedback-call';
import HideErrorBoundary from 'modules/core/components/hide-error-boundary/hide-error-boundary';

import SectionArticleDetails from '../components/section-article-details/section-article-details';
import ExploreArticleListContainer from '../containers/explore-article-list';

import ArticleContainer from '../containers/article';

import * as fromArticleList from '../ducks/article-list';
import { Magazine } from '../actions';

const mapState = createStructuredSelector({
  slug: fromArticleList.getSlugFromRouteParams,
  article: fromArticleList.getItemByRouteParams
});

const mapActions = {
  requestArticle: Magazine.articleRequest
};

class ArticlePage extends Component {
  static path = '/magazine/:slug';
  static exact = true;

  static propTypes = {
    slug: PropTypes.string.isRequired
  };

  render() {
    const { slug, article } = this.props;

    if (!article) {
      return null;
    }

    return (
      <Page
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Журнал',
            link: getBlogLink()
          },
          {
            name: article.title
          }
        ]}
      >
        <HideErrorBoundary>
          <SectionArticleDetails>
            <ArticleContainer slug={slug} />
          </SectionArticleDetails>
        </HideErrorBoundary>

        {article._details && article.relatedArticleList.length > 0 && (
          <HideErrorBoundary>
            <SectionDefault title="Читайте также">
              <Container>
                <ExploreArticleListContainer slug={slug} />
              </Container>
            </SectionDefault>
          </HideErrorBoundary>
        )}
        <SectionDefault>
          <Container>
            <FeedbackCall />
          </Container>
        </SectionDefault>
      </Page>
    );
  }

  update(props) {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
    props.requestArticle(props.slug);
  }

  componentDidMount() {
    this.update(this.props);
  }

  UNSAFE_componentWillUpdate(props) {
    if (props.slug !== this.props.slug) {
      this.update(props);
    }
  }
}

export default withRouter(
  connect(
    mapState,
    mapActions
  )(ArticlePage)
);
