import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromAccount from '../ducks/account';
import { actions as Dialogs } from '../ducks/dialogs';

import LoginRecoverDialogRoute from '../components/login-recover-dialog-route/login-recover-dialog-route';

const mapState = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

const mapActions = {
  openPasswordRecoveryRequestDialog: Dialogs.openPasswordRecoveryRequestDialog
};

class LoginRecoverDialogRouteContainer extends Component {
  render() {
    const { isAuthorized, openPasswordRecoveryRequestDialog } = this.props;

    return (
      <LoginRecoverDialogRoute
        isAuthorized={isAuthorized}
        openPasswordRecoveryRequestDialog={openPasswordRecoveryRequestDialog}
        {...this.props}
      />
    );
  }
}

export default connect(
  mapState,
  mapActions
)(LoginRecoverDialogRouteContainer);
