import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dadata from 'services/dadata';
import CitySuggest from '../components/city-suggest/city-suggest';
import PropTypes from 'prop-types';
import primaryCities from '../seeds/primary-cities';
import isFunction from 'modules/utils/is-function';
import * as fromCityList from 'modules/geography/ducks/city-list';

const dadata = new Dadata(process.env.REACT_APP_DADATA_API_KEY);

class DadataCitySuggest extends Component {
  static propTypes = {
    selected: PropTypes.any,
    defaultOption: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    noPrimary: false,
    onlyRussia: true
  };

  state = {
    query: '',
    inputQuery: '',
    inputFocused: false,
    suggestions: this.props.noPrimary ? [] : [...primaryCities],
    suggestionIndex: -1,
    suggestionsVisible: true,
    isValid: false,

    /** Dropdown Select */
    isOpened: false,
    selected: false,
    focusIn: false
  };

  render() {
    const { defaultOption, allCitiesList, ...rest } = this.props;

    const { suggestions, query, isOpened, selected, focusIn } = this.state;

    return (
      <CitySuggest
        {...rest}
        onFilterQueryChange={this.handleFilterQueryChange}
        onKeyDown={this.handleKeyDown}
        onOpen={this.open}
        onClose={this.close}
        onFocusIn={this.focusIn}
        onFocusOut={this.focusOut}
        onBlur={this.blur}
        onSelect={this.select}
        suggestions={suggestions}
        filterQuery={query}
        isOpened={(query || suggestions.length > 0) && isOpened}
        error={query && !suggestions.length && 'Городов по запросу не найдено'}
        selected={selected}
        focusIn={focusIn}
        inputValue={this.getInputValue()}
      />
    );
  }

  getInputValue() {
    const { query, selected, focusIn, suggestions } = this.state;
    const { selected: selectedId, initialCity } = this.props;
    const selectedItem = this.getItemById(suggestions, selectedId);

    if (focusIn) {
      return query;
    }

    if (selectedItem !== null) {
      return selectedItem.title;
    }

    if (selected) {
      return suggestions[selected].title;
    }

    if (initialCity) {
      return initialCity;
    }

    return query;
  }

  getItemById(items, selectedId) {
    if (!selectedId) {
      return null;
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].id === selectedId) {
        return items[i];
      }
    }

    return null;
  }

  fetchSuggestions(query) {
    dadata
      .city(query, {
        withSettlements: this.props.withSettlements,
        locations: this.props.onlyRussia
          ? []
          : [
              {
                country: 'Россия'
              },
              {
                country: 'Беларусь'
              },
              {
                country: 'Казахстан'
              }
            ]
      })
      .then(this.handleSuggestionsResponse)
      .catch(console.error);
  }

  getDeliveryRequestOptions = data => {
    const {
      country,
      region_type,
      region,
      settlement_type,
      city,
      settlement,
      city_type,
      country_iso_code
    } = data;
    return {
      ...(city && { city }),
      ...(city_type && { city_type }),
      ...(region && { region }),
      ...(region_type && { region_type }),
      ...(settlement && { settlement }),
      ...(settlement_type && {
        settlement_type
      }),
      ...(country && { country }),
      ...(country_iso_code && { country_iso_code })
    };
  };

  transformDadata(data) {
    return data.suggestions.map(item => {
      return {
        id: item.data.city_fias_id || item.data.settlement_fias_id || item.data.geoname_id,
        title: item.value,
        fiasId: item.data.city_fias_id || item.data.settlement_fias_id,
        name: item.data.city || item.data.settlement,
        type: item.data.city_type_full || item.data.settlement_type_full,
        country: item.data.country,
        geonameId: item.data.geoname_id,
        isSettlement: item.data.fias_level === '6',
        isCity: item.data.fias_level === '4',
        deliveryRequestOptions: this.getDeliveryRequestOptions(item.data),
        value: item.value
      };
    });
  }

  /*
    SELECT METHODS
  */

  open = () => {
    this.clearBlurTimer();
    this.setState({
      isOpened: true
    });
  };

  close = () => {
    this.clearBlurTimer();
    this.setState({
      isOpened: false,
      focusIn: false
    });
  };

  select = index => {
    const { onChange, onChangeFull } = this.props;
    const { suggestions } = this.state;

    this.setState({
      selected: index
    });

    if (isFunction(onChange)) {
      onChange(suggestions[index].id);
    }

    if (isFunction(onChangeFull)) {
      onChangeFull(suggestions[index]);
      // onChangeFull();
    }
  };

  applyFirst() {
    this.select(0);
    this.close();
  }

  focusIn = () => {
    this.setState({
      focusIn: true
    });
  };

  focusOut = () => {
    this.setState({
      focusIn: false
    });
  };

  blur = () => {
    this.clearBlurTimer();
    this._blurTimer = setTimeout(this.handleBlurTimeout, 500);
  };

  clearBlurTimer = () => {
    clearTimeout(this._blurTimer);
  };

  handleBlurTimeout = () => {
    const { focusIn } = this.state;
    if (focusIn) return;
    this.close();
  };

  /* END SELECT */

  handleSuggestionsResponse = data => {
    const suggestions = this.transformDadata(data);

    this.setState({
      suggestions
    });
  };

  handleFilterQueryChange = query => {
    this.setState({
      query,
      inputQuery: query,
      isOpened: true,
      selected: null
    });

    this.fetchSuggestions(query);
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.applyFirst();
    }
  };
}

const mapStateToProps = createStructuredSelector({
  allCitiesList: fromCityList.getAllList
});

export default connect(mapStateToProps)(DadataCitySuggest);
