import React, { Component } from 'react';

import Svg from '../svg/svg';
import './icon-star.css';

class IconStar extends Component {
  render() {
    const { fill } = this.props;

    const size = this.getSize();

    if (fill && fill === 'FULL') {
      switch (size) {
        case 'xsmall':
          return (
            <Svg viewBox="0 0 16 16" className="IconStar IconStar-full">
              <path d="M12 14.5c-.1 0-.2 0-.3-.1L8 11.8l-3.7 2.7c-.2.1-.4.1-.5 0-.2-.1-.2-.3-.2-.5L5 9.6 1.3 7c-.1-.2-.2-.4-.2-.6.1-.2.2-.3.4-.3h4.6l1.4-4.3c.1-.2.3-.3.5-.3s.4.1.4.3l1.4 4.3h4.6c.2 0 .4.1.4.3.1.2 0 .4-.2.5L11 9.6l1.4 4.3c.1.2 0 .4-.2.5 0 .1-.1.1-.2.1z" />
            </Svg>
          );
        case 'small':
        case 'medium':
          return (
            <Svg viewBox="0 0 24 24" className="IconStar IconStar-full">
              <path d="M17 20c-.1 0-.2 0-.3-.1L12 16.5l-4.7 3.4c-.2.1-.4.1-.6 0-.2-.1-.2-.3-.2-.5L8.3 14l-4.7-3.4c-.2-.1-.2-.3-.2-.5.2-.2.4-.4.6-.4h5.8l1.8-5.4c.1-.4.8-.4.9 0l1.8 5.4H20c.2 0 .4.1.4.3s0 .4-.2.5l-4.7 3.4 1.8 5.4c.1.2 0 .4-.2.5.1.2 0 .2-.1.2z" />
            </Svg>
          );
        case 'mediumPlus':
        case 'large':
        case 'xlarge':
          return (
            <Svg viewBox="0 0 36 36" className="IconStar IconStar-full">
              <path d="M25.1 29.5c-.1 0-.3 0-.4-.1L18 24.5l-6.7 4.8c-.2.2-.5.2-.8 0-.2-.2-.3-.5-.2-.7l2.6-7.8L6.1 16c-.2-.2-.3-.5-.2-.7s.3-.5.6-.5h8.3L17.4 7c.1-.3.3-.4.6-.4s.5.2.6.4l2.6 7.8h8.3c.3 0 .5.2.6.5s0 .6-.2.7l-6.7 4.8 2.6 7.8c.1.3 0 .6-.2.7-.2.1-.4.2-.5.2z" />
            </Svg>
          );
        default:
      }
    }

    if (fill && fill === 'HALF') {
      switch (size) {
        case 'xsmall':
          return (
            <Svg viewBox="0 0 16 16" className="IconStar IconStar-full">
              <path
                fill="none"
                d="M8.3 10.8l2.8 2L10 9.6c-.1-.2 0-.4.2-.6L13 7H9.5c-.2 0-.4-.1-.4-.3L8 3.5v7.3c.1-.1.2 0 .3 0z"
              />
              <path d="M14.9 6.4c-.1-.2-.2-.3-.4-.3H9.8L8.4 1.8c0-.2-.2-.3-.4-.3v2l1.1 3.3c0 .1.2.2.4.2H13l-2.8 2c-.2.2-.3.4-.2.6l1.1 3.3-2.8-2c-.1-.1-.2-.1-.3-.1v1l3.7 2.7c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.1.2-.3.2-.5L11 9.6 14.7 7c.1-.2.2-.4.2-.6zM7.6 1.8L6.1 6.1H1.6c-.2 0-.4.1-.4.3-.1.2 0 .4.1.6L5 9.6l-1.4 4.3c-.1.2 0 .4.2.5.2.1.4.1.5 0L8 11.8V1.5c-.2 0-.4.1-.4.3z" />
            </Svg>
          );
        case 'small':
        case 'medium':
          return (
            <Svg viewBox="0 0 24 24" className="IconStar IconStar-full">
              <path
                fill="none"
                d="M12.3 15.6l3.8 2.7-1.4-4.4c-.1-.2 0-.4.2-.5l3.8-2.7H14c-.2 0-.4-.1-.4-.3L12 6v9.5c.1 0 .2 0 .3.1z"
              />
              <path d="M20.5 10.1c-.1-.2-.2-.3-.4-.3h-5.8l-1.8-5.4c-.1-.3-.3-.4-.5-.4v2l1.4 4.4c.1.2.2.3.4.3h4.7l-3.8 2.7c-.2.1-.2.3-.2.5l1.4 4.4-3.8-2.7c-.1-.1-.2-.1-.3-.1v1.1l4.7 3.4h.5c.1 0 .2 0 .3-.1.2-.1.2-.3.2-.5L15.6 14l4.7-3.4c.2-.1.2-.3.2-.5z" />
              <path d="M11.6 4.3L9.8 9.7H4c-.2 0-.4.1-.4.3-.1.2 0 .4.2.5L8.4 14l-1.8 5.4c-.1.2 0 .4.2.5.2.1.4.1.6 0l4.7-3.4V4c-.3 0-.5.1-.5.3z" />
            </Svg>
          );
        case 'mediumPlus':
        case 'large':
        case 'xlarge':
          return (
            <Svg viewBox="0 0 36 36" className="IconStar IconStar-full">
              <path
                fill="none"
                d="M18.4 23.2l5.5 3.9-2.1-6.4c-.1-.3 0-.6.2-.7l5.5-3.9h-6.8c-.3 0-.5-.2-.6-.4L18 9.3v13.8c.1 0 .3 0 .4.1z"
              />
              <path d="M30.1 15.2c-.1-.3-.3-.5-.6-.5h-8.3L18.6 7c-.1-.3-.3-.4-.6-.4v2.7l2.1 6.4c.1.3.3.4.6.4h6.8L22 20c-.2.2-.3.5-.2.7l2.1 6.4-5.5-3.9c-.1-.1-.2-.1-.4-.1v1.5l6.7 4.8c.1.1.2.1.4.1.1 0 .3 0 .4-.1.2-.2.3-.5.2-.7l-2.6-7.8 6.7-4.8c.3-.3.4-.6.3-.9zM17.4 7l-2.6 7.8H6.5c-.3 0-.5.2-.6.5s0 .6.2.7l6.7 4.8-2.6 7.8c-.1.3 0 .6.2.7.2.2.5.2.8 0l6.7-4.8v-18c-.2 0-.4.2-.5.5z" />
            </Svg>
          );
        default:
      }
    }

    switch (size) {
      case 'xsmall':
        return (
          <Svg viewBox="0 0 16 16" className="IconStar IconStar-stroke">
            <path d="M12 14.5c-.1 0-.2 0-.3-.1L8 11.8l-3.7 2.7c-.2.1-.4.1-.5 0-.2-.1-.2-.3-.2-.5L5 9.6 1.3 7c-.1-.2-.2-.4-.2-.6.1-.2.2-.3.4-.3h4.6l1.4-4.3c.1-.2.3-.3.5-.3s.4.1.4.3l1.4 4.3h4.6c.2 0 .4.1.4.3.1.2 0 .4-.2.5L11 9.6l1.4 4.3c.1.2 0 .4-.2.5 0 .1-.1.1-.2.1zM3 7l2.8 2c.2.2.3.4.2.6l-1.1 3.3 2.8-2c.2-.1.4-.1.5 0l2.8 2-1-3.3c-.1-.2 0-.4.2-.6L13 7H9.5c-.2 0-.4-.1-.4-.3L8 3.5 6.9 6.7c0 .2-.2.3-.4.3H3z" />
          </Svg>
        );
      case 'small':
      case 'medium':
        return (
          <Svg viewBox="0 0 24 24" className="IconStar IconStar-stroke">
            <path d="M7 20c-.1 0-.2 0-.3-.1-.2-.1-.2-.3-.2-.5L8.3 14l-4.7-3.4c-.2-.1-.2-.3-.2-.5.1-.2.2-.3.4-.3h5.8l1.8-5.4c.2-.3.3-.4.5-.4s.4.1.4.3l1.8 5.4H20c.2 0 .4.1.4.3.1.2 0 .4-.2.5L15.6 14l1.8 5.4c.1.2 0 .4-.2.5-.2.1-.4.1-.6 0l-4.7-3.4-4.7 3.4c0 .1-.1.1-.2.1zm-1.6-9.3l3.8 2.7c.2.1.2.3.2.5L8 18.3l3.8-2.7c.2-.1.4-.1.6 0l3.8 2.7-1.4-4.4c-.1-.2 0-.4.2-.5l3.8-2.7h-4.7c-.2 0-.4-.1-.4-.3L11.9 6l-1.4 4.4c-.1.2-.2.3-.4.3H5.4z" />
          </Svg>
        );
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconStar IconStar-stroke">
            <path d="M25.1 29.5c-.1 0-.3 0-.4-.1L18 24.5l-6.7 4.8c-.2.2-.5.2-.8 0-.2-.2-.3-.5-.2-.7l2.6-7.8L6.1 16c-.2-.2-.3-.5-.2-.7s.3-.5.6-.5h8.3L17.4 7c.1-.3.3-.4.6-.4s.5.2.6.4l2.6 7.8h8.3c.3 0 .5.2.6.5s0 .6-.2.7l-6.7 4.8 2.6 7.8c.1.3 0 .6-.2.7-.2.1-.4.2-.5.2zM8.5 16.1L14 20c.2.2.3.5.2.7l-2.1 6.4 5.5-3.9c.2-.2.5-.2.8 0l5.5 3.9-2.1-6.4c-.1-.3 0-.6.2-.7l5.5-3.9h-6.8c-.3 0-.5-.2-.6-.4L18 9.3l-2.1 6.4c-.1.3-.3.4-.6.4H8.5z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size = 'medium', inline } = this.props;

    if (inline) {
      return 'xsmall';
    }
    return size;
  }

  // render() {
  //   const { fill } = this.props;

  //   return (
  //     <svg
  //       className={classNames('IconStar', {
  //         [`IconStar--${FILL_VARIANT[fill]}`]: fill
  //       })}
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 161.38 153.55"
  //     >
  //       <path
  //         className="IconStar-full"
  //         strokeMiterlimit="10"
  //         d="M160 60.07L114.55 93a4.67 4.67 0 0 0-1.55 5l17.34 53.37c.55 1.69-.17 2.21-1.6 1.18l-45.41-33a4.7 4.7 0 0 0-5.21 0l-45.39 33c-1.44 1.05-2.16.52-1.61-1.16L48.42 98a4.68 4.68 0 0 0-1.61-5L1.42 60C0 59.07.26 58.19 2 58.19h56.12a4.7 4.7 0 0 0 4.22-3L79.69 1.77c.55-1.69 1.43-1.69 2 0L99 55.14a4.69 4.69 0 0 0 4.2 3h56.13c1.79.05 2.06.86.67 1.93z"
  //       />
  //       <path
  //         className="IconStar-half"
  //         d="M79.59 1.77l-17.3 53.4a4.51 4.51 0 0 1-4.2 3H2c-1.8 0-2 .9-.6 1.9l45.4 33a4.79 4.79 0 0 1 1.6 5l-17.3 53.3c-.6 1.7.2 2.2 1.6 1.2l45.4-33a5 5 0 0 1 2.6-.8V.47c-.51 0-.91.4-1.11 1.3z"
  //       />
  //     </svg>
  //   );
  // }
}

export default IconStar;
