import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Ingredient from 'modules/ingredient/components/ingredient/ingredient';
import { getIngredientById } from 'modules/ingredient/ducks/ingredients/selectors';

const mapStateToProps = (state, props) => ({
  ingredient: getIngredientById(state, props)
});

const mapDispatchToProps = (dispatch, props) => {
  const actions = {};
  return bindActionCreators(actions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ingredient);
