import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import Form from 'modules/form/components/form/form';
import Field from 'modules/form/components/field/field';
import Input from 'modules/form/components/input/input';
import IconFlatLoader from 'modules/core/components/icons/icon-flat-loader/icon-flat-loader';
import Button from 'modules/core/components/button/button';

import './password-recovery-request-form.css';

class PasswordRecoveryRequestForm extends Component {
  render() {
    const { error, onChange, defaultValue } = this.props;

    return (
      <div className="PasswordRecoveryRequestForm">
        <Form
          onSubmit={this.handleSubmit}
          // method="POST"
        >
          <Media query="(max-width: 840px)">
            {isMobile => (
              <Fragment>
                <Form.LabeledRow>
                  <Field
                    label="E-mail"
                    size={isMobile ? 'small' : 'normal'}
                    error={this.getErrorMessage()}
                  >
                    <Input
                      name="email"
                      type="email"
                      required
                      size={isMobile ? 'small' : 'normal'}
                      onChange={onChange}
                      state={error ? 'error' : null}
                      defaultValue={defaultValue ? defaultValue : null}
                    />
                  </Field>
                </Form.LabeledRow>

                <Form.ActionRow>{this.renderSubmitButton(isMobile)}</Form.ActionRow>
              </Fragment>
            )}
          </Media>
        </Form>
      </div>
    );
  }

  getErrorMessage() {
    const { error } = this.props;
    return error ? error : null;
  }

  renderSubmitButton(isMobile) {
    const { error, isSubmitting } = this.props;
    return (
      <Button
        type="submit"
        variant="primary"
        title={error ? 'Зарегистрироваться' : 'Отправить'}
        expanded={true}
        size={isMobile ? 'small' : 'normal'}
        disabled={isSubmitting}
        iconAppend={isSubmitting && <IconFlatLoader />}
      />
    );
  }

  handleSubmit = e => {
    const { error, onRegistrationOpen } = this.props;
    e.preventDefault();

    if (error) {
      onRegistrationOpen();
      return;
    }

    const form = new FormData(e.target);
    const { onSubmit } = this.props;

    const email = form.get('email');

    onSubmit(email);
  };
}

export default PasswordRecoveryRequestForm;
