import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromBasket from 'modules/ordering/ducks/basket';

import BasketPreviewPanelSection from './basket-preview-panel-section';

const mapStateToProps = createStructuredSelector({
  productsAmount: fromBasket.getProductsAmount
});

class BasketPreviewPanelSectionContainer extends Component {
  render() {
    const { children, productsAmount } = this.props;

    return !!productsAmount && <BasketPreviewPanelSection>{children}</BasketPreviewPanelSection>;
  }
}

export default connect(mapStateToProps)(BasketPreviewPanelSectionContainer);
