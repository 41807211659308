import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Media from 'modules/core/components/media/media';
import Carousel from 'modules/core/components/carousel/carousel';
import VideoTrigger from 'modules/core/components/video-trigger/video-trigger';

import mediaShape from '../../prop-types/media-shape';

import NavigationButton from './navigation-button.jsx';

import { MEDIA_TYPE } from 'modules/core/components/media/constants';

import getYoutubeVideoIdentifier from 'modules/utils/get-youtube-video-identifier';

import PaginationContainer from './pagination-container';

import CosmopolitenIcon from 'modules/core/components/icons/cosmopoliten-icon/cosmopoliten-icon';

import './mobile-product-card-slider.css';

const PAGINATION_BUTTON_AMOUNT = 7;

class MediaSlide extends Component {
  state = {
    isFullImageLoad: false
  };

  render() {
    const { media, onVideoTriggerClick, isCurrent } = this.props;

    const { isFullImageLoad } = this.state;

    if (media.type === MEDIA_TYPE.VIDEO) {
      const link = media.sources[0].main.normal;
      const youtubeIdentifier = getYoutubeVideoIdentifier(link);

      if (youtubeIdentifier) {
        return (
          <div key={media.id} className="ProductCardSlider-item">
            <div className="ProductCardSlider-media">
              <VideoTrigger
                key={media.id}
                identifier={youtubeIdentifier}
                onClick={onVideoTriggerClick(youtubeIdentifier)}
                isCurrent={isCurrent}
              />
            </div>
          </div>
        );
      }

      return (
        <div key={media.id} className="ProductCardSlider-item">
          <div className="ProductCardSlider-media">
            <Media media={media} nocaption noClickableVideo active={isCurrent} />
          </div>
        </div>
      );
    }

    return (
      <div
        key={media.id}
        className={cn('ProductCardSlider-item', {
          'ProductCardSlider-item--withFullImage': isFullImageLoad
        })}
      >
        <div className="ProductCardSlider-media">
          <Media
            media={media}
            resizeWidth={560}
            background="#fff"
            stretch="horizontal"
            nocaption
            onLoad={this.handleImageLoad}
          />
        </div>
        <div className="ProductCardSlider-blurredImage">
          <div className="ProductCardSlider-media">
            <Media
              media={media}
              resizeWidth={100}
              background="#fff"
              stretch="horizontal"
              nocaption
            />
          </div>
        </div>
      </div>
    );
  }

  showFullImage() {
    this.setState({ isFullImageLoad: true });
  }

  handleImageLoad = () => {
    this.showFullImage();
  };
}

const CarouselPage = ({ ...rest }) => <NavigationButton {...rest} />;

class MobileProductCardSlider extends Component {
  static propTypes = {
    /**
     * madiaList - массив всех переданных медиа
     */
    mediaList: PropTypes.arrayOf(mediaShape)
  };

  state = {
    currentPage: 0
  };

  render() {
    const { mediaList, isCosmopolitanIconExist } = this.props;

    const { currentPage } = this.state;

    return (
      <div className="MobileProductCardSlider">
        {isCosmopolitanIconExist && (
          <div className="ProductCardSlider-cosmopolitenMobileLabel">
            <CosmopolitenIcon />
          </div>
        )}
        <Carousel
          duration={400}
          currentPage={currentPage}
          onChange={this.handleCarouselChange}
          pagination={true}
          container={<div className="MobileProductCardSlider-list" />}
          paginationContainer={
            <PaginationContainer
              currentPage={currentPage}
              buttonAmount={PAGINATION_BUTTON_AMOUNT}
            />
          }
          page={<CarouselPage buttonAmount={PAGINATION_BUTTON_AMOUNT} />}
        >
          {mediaList.map((media, index) => (
            <MediaSlide
              key={index}
              media={media}
              onVideoTriggerClick={this.handleVideoTriggerClick}
              isCurrent={currentPage === index}
            />
          ))}
        </Carousel>
      </div>
    );
  }

  handleCarouselChange = data => {
    this.changePage(data.page);
  };

  handleVideoTriggerClick = identefier => () => {
    const { onVideoTriggerClick } = this.props;

    onVideoTriggerClick(identefier);
  };

  changePage(page) {
    const { mediaList } = this.props;
    const currentPage = this.normalizePage(page, 0, mediaList.length - 1);

    this.setState({
      currentPage
    });
  }

  normalizePage(page, first, last) {
    if (page >= last) {
      return last;
    }

    if (page <= first) {
      return first;
    }

    return page;
  }
}

export default MobileProductCardSlider;
