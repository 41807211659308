import React, { Component } from 'react';
import classNames from 'classnames';

class NavigationButton extends Component {
  render() {
    const { isActive, ...buttonProps } = this.props;
    return (
      <button
        className={classNames('VerticalSlider-navigationButton', {
          'VerticalSlider-navigationButton--isActive': isActive
        })}
        type="button"
        disabled={isActive}
        {...buttonProps}
      />
    );
  }
}

export default NavigationButton;
