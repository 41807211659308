import { takeEvery, put, call, select } from 'redux-saga/effects';
import takeDebounced from 'modules/utils/saga/take-debounced';
import ifChanged from 'modules/utils/saga/if-changed';

import { createCookieToastPresistor } from 'services/local-storage';

import Toasts from '../ducks/actions/toasts';
import * as fromToast from '../ducks/toasts';

export function* toastsPersistenceWatcher() {
  const toastPersistor = createCookieToastPresistor();

  const rehydrate = function*() {
    const haveCookieToastBeenShowed = yield call(toastPersistor.read);

    if (!haveCookieToastBeenShowed && typeof haveCookieToastBeenShowed !== 'boolean') {
      return;
    }

    yield put(Toasts.rehydrate(haveCookieToastBeenShowed));
  };

  const persist = function*(toasts) {
    yield call(toastPersistor.write, toasts);
  };

  const getState = function*() {
    return yield select(fromToast.getAll);
  };

  yield takeEvery('@@REHYDRATE', rehydrate);
  yield takeDebounced('*', ifChanged(getState, persist), 200);
}
