import PropTypes from 'prop-types';
import priceShape from 'modules/ordering/prop-types/price-shape';

const { number, string, arrayOf, shape, any } = PropTypes;

export default shape({
  id: number,
  price: priceShape,
  /** Media ids related to product media array */
  media: arrayOf(number),
  options: arrayOf(
    shape({
      id: number,
      name: string,
      value: string,
      payload: any
    })
  )
});
