import he from 'he';
import stripTags from 'modules/utils/strip-tags';
import { deserializeVariantOption } from './variant-option';
import { deserializeMedia } from './media';

export function deserializeProduct(product) {
  try {
    const {
      external_id,
      announcement_media,
      create_date,
      description,
      title,
      media,
      property_options,
      variant_options,
      in_basket,
      is_available,
      is_gift,
      meta,
      rating,
      ...rest
    } = product;

    return {
      ...rest,
      // ...(rating && { rating: { ...rating, value: 2.5 } }),
      ...(rating && { rating: { ...rating } }),
      announcementMedia: announcement_media,
      createDate: create_date,
      externalId: external_id || null,
      title: he.decode(title).trim(),
      description: he.decode(stripTags(description)).trim(),
      media: media ? media.map(deserializeMedia) : [],
      properties: property_options,
      variantOptions: (variant_options || []).map(item => item.id),
      variantOptionsAll: (variant_options || []).map(deserializeVariantOption),
      inBasket: in_basket,
      isAvailable: is_available,
      isGift: is_gift,
      meta: Object.prototype.toString.call(meta) === '[object Object]' ? meta : null
    };
  } catch (e) {
    console.error('Failed to deserialize product', product, e);
    return null;
  }
}
