import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import UserMenu from '../components/user-menu/user-menu';

import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';
import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromAccount from '../ducks/account';
import * as fromProfile from 'modules/profile/ducks/profile';
import profileAction from 'modules/profile/actions/profile';
import { withRouter } from 'react-router';
import { getBasketLink } from 'routes/links';

const getIsBasket = (_, { location }) => location.pathname === getBasketLink();
const getShowBasketDropdown = createSelector(
  fromBasket.getDropdownShow,
  getIsBasket,
  (show, isBasket) => {
    return show && !isBasket;
  }
);

const getIsProfile = (_, { location }) => location.pathname.indexOf('profile') >= 0;
const getShowProfileDropdown = createSelector(
  fromProfile.getIsProfileDropdownOpened,
  getIsProfile,
  (show, isBasket) => {
    return show && !isBasket;
  }
);

const mapStateToProps = createStructuredSelector({
  productsCount: fromBasket.getProductsAmount,
  basketProducts: fromBasketProducts.getList,
  deliveryCost: fromBasket.getDeliveryCost,
  productsCost: fromBasketProducts.getProductsCost,
  productsAmount: fromBasket.getProductsAmount,
  isAuthorized: fromAccount.getIsAuthorized,
  isBasketDropdownShowed: getShowBasketDropdown,
  isProfileDropdownShowed: getShowProfileDropdown,
  firstName: fromProfile.getFirstName,
  lastName: fromProfile.getLastName,
  photo: fromProfile.getPhoto,
  isProfileLoading: fromProfile.getIsLoading,
  firstNameLetter: fromProfile.getFirstNameLetter,
  isBasketFetching: fromBasket.getIsBasketFetching
});

const mapDispatchToProps = {
  removeProduct: fromBasket.actions.removeProduct,
  openAuthDialog: fromDialogs.actions.openAuthDialog,
  openRegistrationDialog: fromDialogs.actions.openRegistrationDialog,
  openBasketDropdown: fromBasket.actions.openBasketDropdown,
  closeBasketDropdown: fromBasket.actions.closeBasketDropdown,
  openProfileDropdown: profileAction.openProfileDropdown,
  closeProfileDropdown: profileAction.closeProfileDropdown
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu));
