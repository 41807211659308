export function deserializeTopic(topic) {
  const { slug, title, is_active, channel_slug } = topic;

  return {
    slug,
    title,
    isActive: is_active,
    channelSlug: channel_slug
  };
}
