import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromSubscriptions from '../ducks/subscriptions';
import Subscription from '../actions/subscriptions';

import SubscriptionScene from '../components/subscription-scene/subscription-scene';

const mapState = createStructuredSelector({
  channelList: fromSubscriptions.getChannelListWithTopics,
  isLoaded: fromSubscriptions.getIsLoaded
});

const mapDispatchToProps = {
  showSubscriptions: Subscription.showSubscriptions,
  subscribeChannel: Subscription.subscribeChannel,
  unsubscribeChannel: Subscription.unsubscribeChannel,
  subscribeTopic: Subscription.subscribeTopic,
  unsubscribeTopic: Subscription.unsubscribeTopic
};

class SubscriptionSceneContainer extends Component {
  render() {
    const { channelList, isLoaded, ...rest } = this.props;

    return (
      <SubscriptionScene
        channelList={channelList}
        isLoaded={isLoaded}
        onChannelSubscribe={this.handleChannelSubscribe}
        onChannelUnsubscribe={this.handleChannelUnsubscribe}
        onTopicSubscribe={this.handleTopicSubscribe}
        onTopicUnsubscribe={this.handleTopicUnsubscribe}
        {...rest}
      />
    );
  }

  componentDidMount() {
    const { showSubscriptions } = this.props;

    showSubscriptions();
  }

  handleChannelSubscribe = channel => {
    const { subscribeChannel } = this.props;

    subscribeChannel(channel.slug);
  };

  handleChannelUnsubscribe = channel => {
    const { unsubscribeChannel } = this.props;

    unsubscribeChannel(channel.slug);
  };

  handleTopicSubscribe = topic => {
    const { subscribeTopic } = this.props;

    subscribeTopic(topic.slug);
  };

  handleTopicUnsubscribe = topic => {
    const { unsubscribeTopic } = this.props;

    unsubscribeTopic(topic.slug);
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(SubscriptionSceneContainer);
