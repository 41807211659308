import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromBasket from 'modules/ordering/ducks/basket';
import * as fromOrders from 'modules/ordering/ducks/orders';
import * as fromBasketProducts from 'modules/ordering/ducks/basket-products';
import { getIsBasketPreviewOpen } from 'modules/ordering/ducks/basket-preview/reducer';
import { closeBasketPreview } from 'modules/ordering/ducks/basket-preview/actions';

import BasketPreviewPanel from '../components/basket-preview-panel/basket-preview-panel';

const mapStateToProps = createStructuredSelector({
  productsAmount: fromBasket.getProductsAmount,
  bonus: fromBasket.getBonuses,
  productsCost: fromBasketProducts.getProductsCost,
  products: fromBasketProducts.getListWithRemoved,
  isBasketPreviewOpen: getIsBasketPreviewOpen
});

const mapDispatchToProps = {
  submitBasket: fromOrders.actions.place,
  closeBasketPreview: closeBasketPreview
};

const BasketPreviewPanelContainer = ({
  productsAmount,
  bonus,
  submitBasket,
  productsCost,
  products,
  isBasketPreviewOpen,
  closeBasketPreview
}) => {
  const excessAmount = getExcessAmount(products);

  const productsList = productsListSlice(products);
  const excessList = products.slice(5);

  if (!isBasketPreviewOpen) {
    return null;
  }

  return (
    <BasketPreviewPanel
      productsAmount={productsAmount}
      isLoading={bonus.isLoading}
      onBasketSubmit={handleBasketSubmit}
      productsCost={productsCost}
      excessAmount={excessAmount}
      excessList={excessList}
      productsList={productsList}
      closeBasketPreview={closeBasketPreview}
    />
  );

  function handleBasketSubmit() {
    submitBasket();
  }

  function getExcessAmount(list) {
    return list.length - 5;
  }

  function productsListSlice(list) {
    return list.slice(0, 5);
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketPreviewPanelContainer);
