import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { getIndexLink } from 'routes/links';
import './mixit-logo.css';

import QuarantinLogoAnimation from './quarantin-logo/quarantin-logo';
// import SparkleRainAnimation from './sparkle-rain-animation/sparkle-rain-animation';

class MixitLogo extends Component {
  render() {
    const { quarantin } = this.props;
    return (
      <div
        className={cn('MixitLogo', {
          'MixitLogo--quarantin': quarantin
        })}
      >
        <Link to={getIndexLink()} className="MixitLogo-link">
          <div className="MixitLogo-image">
            {!quarantin ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173 30.8">
                <path fill="#d65d8b" d="M100.7 0H73.1l13.8 13.8z" />
                <path fill="#ffea47" d="M73.1 30.7h27.6L86.9 16.9z" />
                <path d="M0 0h2.2l12.3 18.2L26.8 0H29v30.7h-2.3V4L14.5 21.7h-.1L2.2 4v26.7H0zM50.3 0h2.3v30.7h-2.3zM121.2 0h2.3v30.7h-2.3zM157.8 2.1h-13.1V0H173v2.1h-12.9v28.6h-2.3zM71.439 1.56L72.994.005l29.133 29.132-1.556 1.556z" />
                <path d="M71.551 29.207L100.684.075l1.555 1.555-29.132 29.133z" />
                <path fill="#7cc3e3" d="M71.5 1.6v27.6l13.8-13.8z" />
                <path fill="#8072af" d="M102.2 29.2V1.6L88.4 15.4z" />
                <g fill="#000000" fill-rule="nonzero">
                  <polygon points="0 0 2.2 0 14.5 18.2 26.8 0 29 0 29 30.7 26.7 30.7 26.7 4 14.5 21.7 14.4 21.7 2.2 4 2.2 30.7 0 30.7"></polygon>
                  <polygon points="50.3 0 52.6 0 52.6 30.7 50.3 30.7"></polygon>
                  <polygon points="121.2 0 123.5 0 123.5 30.7 121.2 30.7"></polygon>
                  <polygon points="157.8 2.1 144.7 2.1 144.7 0 173 0 173 2.1 160.1 2.1 160.1 30.7 157.8 30.7"></polygon>
                </g>
              </svg>
            ) : (
              <svg
                className="MixitLogo-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 220 31"
              >
                <polygon
                  className="MixitLogo-letter MixitLogo-letter_1"
                  points="23.5,0 25.7,0 38,18.2 50.3,0 52.5,0 52.5,30.7 50.2,30.7 50.2,4 38,21.7 37.9,21.7 25.7,4 25.7,30.7 23.5,30.7"
                />
                <polygon
                  className="MixitLogo-letter MixitLogo-letter_2"
                  points="73.8,0 76.1,0 76.1,30.7 73.8,30.7"
                />
                <polygon
                  className="MixitLogo-letter MixitLogo-letter_3"
                  points="144.7,0 147,0 147,30.7 144.7,30.7"
                />
                <polygon
                  className="MixitLogo-letter MixitLogo-letter_4"
                  points="181.3,2.1 168.2,2.1 168.2,0 196.5,0 196.5,2.1 183.6,2.1 183.6,30.7 181.3,30.7"
                />
              </svg>
            )}
          </div>
          <div className="MixitLogo-text">Лаборатория современной косметики</div>
          {quarantin && (
            <div className="MixitLogo-quarantinText">
              Оставайтесь <span style={{ color: '#7cc3e3' }}>Д</span>
              <span style={{ color: '#ffea47' }}>О</span>
              <span style={{ color: '#8072af' }}>М</span>
              <span style={{ color: '#d65d8b' }}>А</span>
            </div>
          )}{' '}
        </Link>
        {quarantin && (
          <div className="MixitLogo-quarantinAnimation">
            <QuarantinLogoAnimation />
          </div>
        )}
        {/* <div className="MixitLogo-sparkleRainAnimation">
          <SparkleRainAnimation />
        </div> */}
      </div>
    );
  }
}

export default MixitLogo;
