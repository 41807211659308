import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'modules/core/components/link/short-link';
import IconExit from 'modules/core/components/icons/icon-exit/icon-exit';
import { Link as RouterLink } from 'react-router-dom';

import './navigation.css';
import NavigationLabel from './navigation-label/navigation-label';
import NavigationLink from './navigation-link/navigation-link';

const { string, arrayOf, shape } = PropTypes;

class Navigation extends Component {
  static propTypes = {
    /**
     * An array of links, that displayed in the menu before the horizontal separator
     */
    list: arrayOf(
      shape({
        title: string.isRequired,
        path: string.isRequired
      })
    ).isRequired
  };

  render() {
    const { list } = this.props;
    return (
      <ul className="Profile_Navigation">
        {list.map(this.renderItem)}
        {this.renderSeparator()}
        {this.renderExit()}
      </ul>
    );
  }

  renderItem = link => {
    return (
      <li key={link.path} className="Profile_Navigation-item">
        <NavigationLink
          path={link.path}
          title={link.title}
          label={link.label}
          icon={link.icon}
          component={RouterLink}
          to={link.path}
        />
      </li>
    );
  };

  renderMobileItem = link => {
    return (
      <li key={link.path} className="Profile_Navigation-mobileItem">
        <a href={link.path} className="Profile_Navigation-link">
          <span className="Profile_Navigation-linkTitle">{link.title}</span>
          {link.label && (
            <span className="Profile_Navigation-linkLabel">
              <NavigationLabel label={link.label} />
            </span>
          )}
        </a>
      </li>
    );
  };

  renderSeparator() {
    return (
      <li className="Profile_Navigation-item" role="presentation">
        <div className="Profile_Navigation-separator" />
      </li>
    );
  }

  renderExit() {
    return (
      <li className="Profile_Navigation-item">
        <Link
          href="/"
          prependedIcon={<IconExit />}
          noUnderline
          size="xsmall"
          onClick={this.handleClick}
        >
          Выход
        </Link>
      </li>
    );
  }

  handleClick = e => {
    e.preventDefault();
    const { onLogOut } = this.props;
    onLogOut();
  };
}

export default Navigation;
