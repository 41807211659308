import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const { string, bool } = PropTypes;
class SizeSwitchOption extends Component {
  static propTypes = {
    /** значение размера */
    size: string.isRequired,
    /** параметр который стилизует внешний вид опции на которую нельзя переключиться */
    disabled: bool,
    /**параметр который стилизует внешний вид активной (выбранной) опции */
    checked: bool
  };

  static defaultProps = {
    disabled: false,
    checked: false
  };

  render() {
    const { size, disabled, checked, ...rest } = this.props;
    return (
      <div
        className={classNames('SizeSwitch-option', {
          'SizeSwitch-option--disabled': disabled,
          'SizeSwitch-option--checked': checked
        })}
        {...rest}
      >
        <span className="SizeSwitch-value">{size}</span>
        {disabled && (
          <svg className="SizeSwitch-cross">
            <line x1="0" y1="0" x2="100%" y2="100%" />
          </svg>
        )}
      </div>
    );
  }
}
export default SizeSwitchOption;
