import React, { Component } from 'react';

class Slide extends Component {
  render() {
    const { child, isCurrent } = this.props;
    const styles = this.getStyles();

    return <div style={styles}>{React.cloneElement(child, { isCurrent })}</div>;
  }

  getStyles() {
    const { width, pixelWidth } = this.props;

    if (pixelWidth) {
      return {
        flexShrink: '0',
        flexGrow: '0',
        flexBasis: `${pixelWidth}px`,
        width: `${pixelWidth}px`,
        maxWidth: `${pixelWidth}px`,
        boxSizing: 'border-box'
      };
    }

    return {
      flexShrink: '0',
      flexGrow: '0',
      flexBasis: `${width}%`,
      width: `${width}%`,
      maxWidth: `${width}%`,
      boxSizing: 'border-box'
    };
  }
}

export default Slide;
