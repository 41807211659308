import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './phone-number.css';

import cn from 'classnames';

export default class PhoneNumber extends Component {
  static propTypes = {
    tel: PropTypes.string,
    bold: PropTypes.bool
  };

  render() {
    return (
      <a
        href={`tel:${this.props.tel}`}
        className={cn('PhoneNumber', {
          'PhoneNumber--bold': this.props.bold
        })}
      >
        {this.props.tel}
      </a>
    );
  }
}
