import React, { Component } from 'react';
import cn from 'classnames';

import './count-down.css';

class Expire extends Component {
  render() {
    const { amount, unitItem, fontSizeInherited, theme } = this.props;

    const currentAmount = this.getNormaliseAmount(amount);

    return (
      <div
        className={cn('Expire-timeItem', {
          'Expire-timeItem--fontSizeInherited': fontSizeInherited,
          [`Expire-timeItem--theme-${theme}`]: theme
        })}
      >
        <div className="Expire-amount">{currentAmount}</div>
        <div className="Expire-unit">{unitItem}</div>
        {/* <div className="Expire-separator">:</div> */}
      </div>
    );
  }

  getNormaliseAmount(amount) {
    const currentAmount = amount >= 10 ? amount : `0${amount}`;

    return currentAmount;
  }
}

export default Expire;
