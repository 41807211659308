import { createReducer, handle as on } from 'modules/utils/dux';
import * as TYPES from '../ducks/types';

const massMedia = createReducer(
  on(TYPES.RESPONSE, (_, { massMedia }) => {
    return {
      ...massMedia
    };
  })
);

export default massMedia({});
