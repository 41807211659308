import React, { Component } from 'react';
import Media from 'react-media';

import Dialog from '../dialog/dialog';
import DialogGrid from '../dialog-grid/dialog-grid';
import AuthForm from '../auth-form/auth-form';
import DialogSection from '../dialog/dialog-section/dialog-section';
import CallToRegistration from '../call-to-registration/call-to-registration';

import './auth-dialog.css';

class AuthDialog extends Component {
  render() {
    const {
      authError,
      onRegistrationOpen,
      onRecoveryPasswordOpen,
      onClose,
      onSubmit,
      onVkAuthCall,
      onFacebookAuthCall,
      ...rest
    } = this.props;
    return (
      <Dialog onClose={onClose} {...rest} closeable>
        <div className="AuthDialog-content">
          <Media query="(min-width: 1101px)">
            {isDesktop =>
              isDesktop ? (
                <DialogGrid>
                  <DialogGrid.Column>
                    <Media query="(min-width: 641px)">
                      {matches => (
                        <DialogSection title={matches ? 'Вход в личный кабинет' : 'Вход'}>
                          <AuthForm
                            authError={authError}
                            onClose={onClose}
                            onSubmit={onSubmit}
                            onRegistrationOpen={onRegistrationOpen}
                            onRecoveryPasswordOpen={onRecoveryPasswordOpen}
                            onVkAuthCall={onVkAuthCall}
                            onFacebookAuthCall={onFacebookAuthCall}
                          />
                        </DialogSection>
                      )}
                    </Media>
                  </DialogGrid.Column>

                  {this.renderCallToRegistrationColumn()}
                </DialogGrid>
              ) : (
                <DialogSection title="Вход в личный кабинет">
                  <AuthForm
                    authError={authError}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    onRegistrationOpen={onRegistrationOpen}
                    onRecoveryPasswordOpen={onRecoveryPasswordOpen}
                    onVkAuthCall={onVkAuthCall}
                    onFacebookAuthCall={onFacebookAuthCall}
                  />
                </DialogSection>
              )
            }
          </Media>
        </div>
      </Dialog>
    );
  }

  renderCallToRegistrationColumn = () => {
    const { onRegistrationOpen } = this.props;
    return (
      <DialogGrid.Column>
        <DialogSection title="Еще не зарегистрированы?">
          <CallToRegistration onRegistrationOpen={onRegistrationOpen} />
        </DialogSection>
      </DialogGrid.Column>
    );
  };
}

export default AuthDialog;
