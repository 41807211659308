import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import ProductFilter from '../components/product-filter/product-filter';
import { connect } from 'react-redux';
import * as fromFilters from '../ducks/filters';
import isFunction from 'modules/utils/is-function';

const mapStateToProps = createStructuredSelector({
  filter: fromFilters.getFilterWithCheckedOptions
});

const mapDispatchToProps = {};

class ProductFilterContainer extends Component {
  static propTypes = {
    filter: PropTypes.any,
    appliedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  state = {
    opened: true
  };

  render() {
    const { filter } = this.props;
    const { opened } = this.state;

    return (
      <ProductFilter
        opened={opened}
        filter={filter}
        onChange={this.handleFilterChange}
        onShowToggle={this.handleShowToggle}
      />
    );
  }

  getResultedOptions(changedOption, isEnabled) {
    const { filter, appliedFilters } = this.props;
    const implicitOptions = [];
    const explicitOptions = appliedFilters.filter(option => {
      const isInFilter = filter.options.includes(option);

      if (isInFilter) {
        implicitOptions.push(option);
        return false;
      }

      return !isInFilter;
    });

    if (!filter.multiple) {
      return [...explicitOptions, changedOption];
    }

    const implicitFinalOptions = isEnabled
      ? implicitOptions.concat(changedOption)
      : implicitOptions.filter(item => item !== changedOption);

    return [...explicitOptions, ...implicitFinalOptions];
  }

  handleShowToggle = e => {
    e.preventDefault();

    this.setState(state => ({
      opened: !state.opened
    }));
  };

  handleFilterChange = event => {
    const { onFilterChange, filter } = this.props;
    const { value, checked } = event.target;
    const checkedOptions = filter.options
      .filter(item => {
        if (item.slug === value) {
          return checked;
        }

        return filter.multiple && item.checked;
      })
      .map(item => item.slug);

    if (isFunction(onFilterChange)) {
      onFilterChange({
        checked,
        filterId: filter.id,
        option: value,
        options: [...filter.explicitOptions, ...checkedOptions]
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFilterContainer);
