import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map, Clusterer, ZoomControl } from 'react-yandex-maps';

import PickPointPlacemarkContainer from '../../containers/pick-point-placemark';
import PickPointCardContainer from '../../containers/pick-point-card';
import Media from 'modules/core/components/shops-map/media';

import './pick-point-map.css';

const API_KEY = '6552443e-463a-464c-ada6-14f09df45c2f';

const { number, string, shape, arrayOf } = PropTypes;

const MAP_HEIGHTS = {
  desktop: '534px',
  tablet: '536px',
  mobile: '360px'
};

class PickPointMap extends Component {
  static propTypes = {
    /** Массив id постаматов и пунктов выдачи PickPoint */
    list: arrayOf(number).isRequired,

    /** Состояние карты */
    mapState: shape({
      center: arrayOf(number),
      zoom: number,
      behaviors: arrayOf(string),
      controls: arrayOf(string)
    }).isRequired,
    filterMask: string,
    /** id просматриваемого PickPoint(всплывает PickPointCard ) */
    activeId: number.isRequired
  };

  render() {
    const {
      mapState,
      list,
      activeId,
      selectedId,
      onSelect,
      onCardHide,
      onApiAvailable
    } = this.props;

    const clustererOptions = { hasBalloon: false, clusterIconColor: '#ff0000' };

    return (
      <div className="PickPointMap">
        <div className="PickPointMap-map">
          <YMaps query={{ load: 'geocode', apikey: API_KEY }}>
            <Media>
              {query => (
                <Map
                  width="100%"
                  height={MAP_HEIGHTS[query]}
                  state={mapState}
                  onLoad={onApiAvailable}
                >
                  <ZoomControl />
                  <Clusterer options={clustererOptions}>{list.map(this.renderPlacemark)}</Clusterer>
                </Map>
              )}
            </Media>
          </YMaps>
        </div>

        {activeId && (
          <div className="PickPointMap-infoCard">
            <PickPointCardContainer
              id={activeId}
              isSelected={activeId === selectedId}
              onSelect={onSelect}
              onClose={onCardHide}
            />
          </div>
        )}
      </div>
    );
  }

  renderPlacemark = pickPointId => {
    const { selectedId, onCardShow, filterMask } = this.props;

    return (
      <PickPointPlacemarkContainer
        filterMask={filterMask}
        key={pickPointId}
        id={pickPointId}
        selected={pickPointId === selectedId}
        onClick={onCardShow}
      />
    );
  };

  getClustererOptions() {
    return { iconColor: '#000' };
  }
}

export default PickPointMap;
