import React, { Component } from 'react';
import './icon-viber.css';

class IconViber extends Component {
  render() {
    return (
      <svg viewBox="0 0 33 37" xmlns="http://www.w3.org/2000/svg" className="IconViber">
        <path d="M31.93 8.708c-.61-2.175-1.817-3.894-3.584-5.11-2.23-1.538-4.796-2.08-7.01-2.413-3.066-.457-5.841-.52-8.49-.198-2.48.305-4.347.79-6.05 1.573C3.456 4.095 1.45 6.578.839 9.94a45.89 45.89 0 00-.615 4.515c-.269 3.254-.025 6.133.743 8.802.75 2.602 2.057 4.46 3.998 5.682.493.311 1.127.537 1.74.753.347.122.685.24.962.372.256.119.256.14.253.353-.021 1.85 0 5.475 0 5.475l.006.774h1.39l.225-.22c.15-.14 3.592-3.466 4.82-4.807l.168-.186c.21-.244.21-.244.426-.247 1.658-.033 3.352-.097 5.034-.189 2.038-.11 4.4-.307 6.624-1.234 2.035-.85 3.522-2.2 4.415-4.01.932-1.888 1.483-3.933 1.69-6.252.366-4.076.107-7.614-.789-10.813zm-7.331 15.853c-.512.832-1.274 1.41-2.173 1.785-.658.275-1.328.217-1.977-.057-5.433-2.3-9.695-5.924-12.51-11.134-.583-1.072-.985-2.242-1.448-3.376-.094-.231-.088-.505-.131-.761.04-1.831 1.444-2.864 2.864-3.175.542-.122 1.024.07 1.426.457a12.484 12.484 0 012.66 3.677c.29.607.158 1.143-.335 1.594-.104.094-.21.18-.323.265-1.128.847-1.292 1.49-.692 2.767 1.024 2.172 2.724 3.631 4.92 4.536.58.238 1.125.12 1.567-.35.06-.06.128-.122.17-.195.866-1.444 2.124-1.301 3.285-.478.762.542 1.502 1.115 2.255 1.666 1.148.844 1.14 1.637.442 2.78zm-7.206-14.415c-.256 0-.512.022-.765.064a.78.78 0 11-.256-1.539 6.134 6.134 0 017.063 7.063.78.78 0 01-1.539-.256 4.575 4.575 0 00-4.503-5.332zm3.787 4.68c0 .43-.35.78-.78.78-.43 0-.78-.35-.78-.78a2.345 2.345 0 00-2.34-2.34c-.43 0-.78-.35-.78-.78 0-.43.35-.78.78-.78 2.151 0 3.9 1.75 3.9 3.9zm4.454 1.734a.78.78 0 01-1.523-.347c.113-.497.17-1.012.17-1.53 0-3.79-3.086-6.877-6.876-6.877-.518 0-1.033.058-1.53.17a.777.777 0 01-.932-.587.777.777 0 01.588-.932c.61-.14 1.24-.21 1.874-.21 4.652 0 8.437 3.784 8.437 8.436 0 .634-.07 1.264-.207 1.877z" />
      </svg>
    );
  }
}

export default IconViber;
