import React, { Component } from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Button from 'modules/core/components/button/button';
import { Form, Group } from 'modules/form';

import FormikField from './formik-field';
import ButtonAccept from './button-accept';

const { string } = PropTypes;

class CouponForm extends Component {
  static propTypes = {
    /** Текст ошибки */
    error: string
  };

  static defaultProps = {
    error: ''
  };

  render() {
    const { error, initial, disabled, ...rest } = this.props;
    const initialValues = { code: initial || '' };

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Media query="(max-width: 410px)">
              {
                isNarrowMobile => (
                  <Media query="(min-width: 1101px)">
                  {isDesktop => (
                    <Group>
                      <Group.ItemWide>
                        <FormikField
                          name="code"
                          placeholder={isNarrowMobile ?  "Введите промокод или сертификат" : "Введите промокод или подарочный сертификат"}
                          size={isDesktop ? 'normal' : 'small'}
                          error={error}
                          disabled={disabled}
                          appended={!isDesktop && <ButtonAccept disabled={!values.code} />}
                          onChange={handleChange}
                          {...rest}
                        />
                      </Group.ItemWide>
  
                      {isDesktop && (
                        <Group.Item>
                          <Button
                            type="submit"
                            variant="accent"
                            title="Применить"
                            disabled={!values.code || disabled}
                          />
                        </Group.Item>
                      )}
                    </Group>
                  )}
                </Media>
                )
              }

            </Media>
          </Form>
        )}
      </Formik>
    );
  }

  handleSubmit = coupon => {
    const { onSubmit } = this.props;

    onSubmit(coupon);
  };
}

export default CouponForm;
