export default {
  title: 'Состояние волос',
  id: 'hair',
  description:
    'Выбери свой тип кожи лица и тела, тип волос и получай персональные рекомендации по уходу',
  options: [
    {
      slug: 'hair-type',
      title: 'Тип волос',
      values: [
        {
          name: 'HAIR_TYPES/DRY',
          title: 'Сухие'
        },
        {
          name: 'HAIR_TYPES/NORMAL',
          title: 'Нормальные'
        },
        {
          name: 'HAIR_TYPES/OILY',
          title: 'Жирные'
        },
        {
          name: 'HAIR_TYPES/COLORED',
          title: 'Окрашенные'
        }
      ]
    },
    {
      slug: 'dry-roots-and-oily-tips',
      title: 'Сухие корни и жирные кончики',
      values: [
        {
          name: 'DRY_ROOTS_AND_OILY_TIPS/TRUE',
          title: 'Да'
        },
        {
          name: 'DRY_ROOTS_AND_OILY_TIPS/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'oily-roots-and-dry-tips',
      title: 'Жирные корни и сухие кончики',
      values: [
        {
          name: 'OILY_ROOTS_AND_DRY_TIPS/TRUE',
          title: 'Да'
        },
        {
          name: 'OILY_ROOTS_AND_DRY_TIPS/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'split-hair',
      title: 'Секущиеся волосы',
      values: [
        {
          name: 'SPLIT_HAIR/TRUE',
          title: 'Да'
        },
        {
          name: 'SPLIT_HAIR/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'falling-hair',
      title: 'Выпадающие волосы',
      values: [
        {
          name: 'FALLING_HAIR/TRUE',
          title: 'Да'
        },
        {
          name: 'FALLING_HAIR/FALSE',
          title: 'Нет'
        }
      ]
    },
    {
      slug: 'dandruff',
      title: 'Перхоть',
      values: [
        {
          name: 'DANDRUFF/TRUE',
          title: 'Да'
        },
        {
          name: 'DANDRUFF/FALSE',
          title: 'Нет'
        }
      ]
    }
  ]
};
