import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import * as fromDialogs from 'modules/core/ducks/dialogs';
import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromAccount from 'modules/core/ducks/account';
import * as fromFAQ from 'modules/core/ducks/faq';


import FAQDialog from '../components/faq-dialog/faq-dialog';

const { bool } = PropTypes;

const mapStateToProps = createStructuredSelector({
  isOpened: fromDialogs.getIsFAQQuestionOpened,
  profileFirstName: fromProfile.getFirstName,
  profilePhone: fromProfile.getPhone,
  profileEmail: fromAccount.getEmail,
});

const mapDispatchToProps = {
  close: fromDialogs.actions.close,
  request: fromFAQ.Actions.sendQuestion,
};

class FAQQuestionDialogContainer extends Component {
  static propTypes = {
    isOpened: bool,
    close: bool
  };

  render() {
    const { isOpened, profileFirstName, profilePhone, profileEmail, request, close } = this.props;

    return (
      <FAQDialog
        show={isOpened}
        request={request}
        profileFirstName={profileFirstName}
        profilePhone={profilePhone}
        profileEmail={profileEmail}
        onClose={close}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FAQQuestionDialogContainer);
