import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Item from './item';

import './trigger.css';

const { bool, oneOf } = PropTypes;

class Trigger extends Component {
  static Item = Item;

  static propTypes = {
    /** Флаг-модификатор растянутого на 100% триггера */
    expanded: bool,

    size: oneOf(['normal', 'small'])
  };

  static defaultProps = {
    expanded: false,
    size: 'normal'
  };

  render() {
    const { value, expanded, size, children } = this.props;

    return (
      <div
        className={cn('Trigger', `Trigger--size-${size}`, {
          'Trigger--expanded': expanded
        })}
      >
        {Children.map(children, child =>
          cloneElement(child, {
            onClick: this.handleClick(child.props.value),
            active: value === child.props.value
          })
        )}
      </div>
    );
  }

  onChange(...options) {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(...options);
    }
  }

  handleClick = value => e => {
    if (value === this.props.value) return;
    this.onChange(value);
  };
}

export default Trigger;
