import React, { Component } from 'react';
import './icon-visa.css';

class IconVisa extends Component {
  static defaultProps = {
    opacity: 1
  };

  render() {
    const { opacity } = this.props;

    return (
      <svg className="IconVisa" viewBox="0 0 64 34" style={{ opacity: opacity }}>
        <path
          fill="#828da2"
          d="M27.2,10.2l-2.5,15.4h4l2.5-15.4L27.2,10.2z M21.3,10.2l-3.8,10.6L17,18.5l-1.4-7c0,0-0.2-1.4-1.9-1.4H7.4
          l-0.1,0.3c0,0,0.1,0,0.2,0.1h0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1s0.2,0.1,0.3,0.1c0.2,0,0.4,0.1,0.5,0.2
c0.1,0,0.2,0.1,0.4,0.2l0.6,0.3c0.1,0.1,0.2,0.1,0.3,0.2l0.6,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0.1,0.2,0.1L15,25.6h4.2
l6.4-15.5L21.3,10.2L21.3,10.2z M37,14.5c0-1.7,3.9-1.5,5.6-0.6l0.6-3.3c0,0-1.8-0.7-3.6-0.7c-2,0-6.7,0.9-6.7,5.1
c0,4,5.6,4,5.6,6.1c0,2.1-5,1.7-6.6,0.4L31.3,25c0,0,1.8,0.9,4.5,0.9s6.9-1.4,6.9-5.3C42.6,16.6,37,16.2,37,14.5z M53.4,10.2h-3.2
c-1.5,0-1.9,1.2-1.9,1.2l-6,14.3h4.2l0.8-2.3h5.1l0.5,2.3h3.7C56.6,25.7,53.4,10.2,53.4,10.2z M48.5,20.2l2.1-5.8l1.2,5.8H48.5z"
        />
      </svg>
    );
  }
}

export default IconVisa;
