import React, { Component } from 'react';
import BannerFeaturedSlider from '../banner-featured-slider/banner-featured-slider';

import FeaturedBanner from '../featured-banner/featured-banner';
import BannerProvider from '../../containers/banner-provider';

class HomePageFeaturedSlider extends Component {
  state = {
    expiredIndexes: []
  };

  render() {
    return (
      <BannerProvider>
        {({ list }) => {
          if (!list || !list.length) {
            return null;
          }

          const filteredByNotExpiredList = list.filter(
            (_, index) => !this.state.expiredIndexes.includes(index)
          );

          return (
            <div className="HomePageFeaturedSlider">
              <BannerFeaturedSlider>
                {filteredByNotExpiredList.map(
                  (
                    {
                      title,
                      label,
                      description,
                      image,
                      url,
                      promocode,
                      video,
                      start_date,
                      end_date,
                      id
                    },
                    index
                    ) => {
                    
                    const themeColors = null;
                    const timeProps = {
                      ...(start_date && { startDate: start_date }),
                      ...(end_date && { endDate: end_date })
                    };
                    return (
                      <BannerFeaturedSlider.Item key={index}>
                        <FeaturedBanner
                          banner={{
                            image,
                            label,
                            title,
                            description,
                            video,
                            themeColors,
                            id,
                            ...timeProps
                          }}
                          index={index}
                          onExpire={this.handleExpire}
                          promo={promocode}
                          link={url}
                        />
                      </BannerFeaturedSlider.Item>
                    );
                  }
                )}
              </BannerFeaturedSlider>
            </div>
          );
        }}
      </BannerProvider>
    );
  }
  handleExpire = index => {
    this.setState(prevState => ({
      ...prevState,
      expiredIndexes: [...prevState.expiredIndexes, index]
    }));
  };
}

export default HomePageFeaturedSlider;
