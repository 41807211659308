import React, { Component } from 'react';
import './section-product-info.css';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Container from 'modules/core/components/container/container';

class SectionProductInfo extends Component {
  static propTypes = {
    /**
     *
     */
    about: PropTypes.string
  };
  render() {
    const { about, isMobile } = this.props;
    return (
      <section
        className={cn('SectionProductInfo', {
          'SectionProductInfo--isMobile': isMobile
        })}
        id="about"
        name="about"
      >
        {isMobile ? (
          <div className="SectionProductInfo-content">
            <div itemprop="description">
              <div dangerouslySetInnerHTML={{ __html: about }} />
            </div>
          </div>
        ) : (
          <Container small>
            <div className="SectionProductInfo-content">
              <div itemprop="description">
                <div dangerouslySetInnerHTML={{ __html: about }} />
              </div>
            </div>
          </Container>
        )}
      </section>
    );
  }
}

export default SectionProductInfo;
