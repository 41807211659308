import React, { Component, Fragment } from 'react';
import './local-breadcrumbs.css';
import PropTypes from 'prop-types';

// TODO: rename to ProductBreadcrumbs
class LocalBreadcrumbs extends Component {
  static propTypes = {
    /**
     *
     */
    list: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
      })
    )
  };

  render() {
    const lastIndex = this.props.list.length - 1; //index Of the Last Element Of the List

    return (
      <ul className="LocalBreadcrumbs">
        {this.props.list.map((item, index) => (
          <li key={index} className="LocalBreadcrumbs-item">
            {lastIndex !== index ? this.renderItem(item) : this.renderLastItem(item)}
          </li>
        ))}
      </ul>
    );
  }
  renderItem(item) {
    return (
      <Fragment>
        <a className="LocalBreadcrumbs-link" href={item.link}>
          {item.name}
        </a>
        <span className="LocalBreadcrumbs-separator">/</span>
      </Fragment>
    );
  }
  renderLastItem(item) {
    return <h4 className="LocalBreadcrumbs-link LocalBreadcrumbs-link--isActive">{item.name}</h4>;
  }
}

export default LocalBreadcrumbs;
