import React, { Component } from 'react';
import cn from 'classnames';
import './navigation-drawer.css';
import NavigationDrawerCatalog from '../navigation-drawer/navigation-drawer-catalog';
import NavigationDrawerPages from '../navigation-drawer/navigation-drawer-pages';
import NavigationDrawerFooter from '../navigation-drawer/navigation-drawer-footer';
import GlobalNavigationMobile from '../global-navigation-mobile/global-navigation-mobile';
import LogoutButton from '../logout-button/logout-button';
// import { CATALOG_NAVIGATION_TREE } from '../navigation-drawer/CATALOG_NAVIGATION_TREE';
// import { draverNavifationOptions } from '../navigation-drawer/catalog-navigation-config';
import NavigationDrawerHeader from '../navigation-drawer/navigation-drawer-header';
import CatalogNavigationMobile from '../catalog-navigation-mobile/catalog-navigation-mobile';
import CityChoiceContainer from '../../containers/city-choice';

class NavigationDrawer extends Component {
  state = {
    authorized: false
  };

  render() {
    const {
      drawerRef,
      isOpened,
      isAuthorized,
      onAuthorizationDialogOpen,
      onRegistrationDialogOpen,
      onCityDialogOpen,
      onNavigationItemOpen,
      onCatalogLinkClick,
      onUserOverviewClick,
      onSingOut,
      navigation,
      isNavigationLoaded,
      isProfileLoading
    } = this.props;

    return (
      <div
        className={cn('NavigationDrawer', {
          'NavigationDrawer--isOpened': isOpened
        })}
        ref={drawerRef}
      >
        <NavigationDrawerHeader
          isAuthorized={isAuthorized}
          onUserOverviewClick={onUserOverviewClick}
          openAuthDialog={onAuthorizationDialogOpen}
          openRegistrationDialog={onRegistrationDialogOpen}
          isProfileLoading={isProfileLoading}
        />
        <NavigationDrawerCatalog>
          <CityChoiceContainer onDialogOpen={onCityDialogOpen} />

          <CatalogNavigationMobile
            catalog={navigation}
            onCatalogLinkClick={onCatalogLinkClick}
            onNavigationItemOpen={onNavigationItemOpen}
            isNavigationLoaded={isNavigationLoaded}

            // animatedCollapsing
          />
        </NavigationDrawerCatalog>

        <NavigationDrawerPages>
          <GlobalNavigationMobile onLinkClick={onCatalogLinkClick} />
        </NavigationDrawerPages>

        <NavigationDrawerFooter>
          {isAuthorized && <LogoutButton onClick={onSingOut} />}
        </NavigationDrawerFooter>
      </div>
    );
  }
}

export default NavigationDrawer;
