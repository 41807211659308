import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as fromAccount from 'modules/core/ducks/account';

import { createStructuredSelector } from 'reselect';
import * as fromOrders from '../../../ducks/orders';
import Notification from 'modules/core/components/notification/notification';
import Link from 'modules/core/components/link/short-link';
import * as fromDialogs from 'modules/core/ducks/dialogs';


const mapStateToProps = createStructuredSelector({
    id: fromOrders.getIdByRouteParams,
    personalInfoComplete: fromOrders.getPersonalInfoComplete,
    isAuthorized: fromAccount.getIsAuthorized,

  });
  
  const mapDispatchToProps = {
    openAuthDialog: fromDialogs.actions.openAuthDialog
  };

  function OrderingHeader({openAuthDialog, personalInfoComplete, isAuthorized}){

    if(personalInfoComplete || isAuthorized) {
        return null
    }

    return (
        <Notification type="info">
            Уже есть аккаунт?{' '}
            <Link component="button" type="button" onClick={() => openAuthDialog()}>
                Авторизоваться
            </Link>
        </Notification>
    )

  }


  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderingHeader));