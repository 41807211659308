export const chunkArrayInGroups = (arr, size) => {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};

export const getCols = cols => {
  let _cols = cols;
  if (cols <= 0) {
    _cols = 1;
  }
  return _cols;
};

export const amountItemsInCol = (list, cols) => {
  return Math.ceil(list.length / cols);
};
