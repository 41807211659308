import React, { Component } from 'react';
import Item from './item';
import './subcategory-carousel.css';

class SubcategoryCarousel extends Component {
  render() {
    const { children, scrolledElementRef } = this.props;

    return (
      <div className="SubcategoryCarousel" ref={scrolledElementRef}>
        <div className="SubcategoryCarousel-content">{children}</div>
      </div>
    );
  }
}

SubcategoryCarousel.Item = Item;

export default SubcategoryCarousel;
