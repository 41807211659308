import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const { number } = PropTypes;

class Countdown extends Component {
  state = {
    count: Math.round((this.props.finishTime - Date.now()) / 1000)
  };

  static propTypes = {
    finishTime: number
  };

  _interval = null;

  render() {
    const { count } = this.state;
    return <Fragment>{count}</Fragment>;
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    if (this._interval) {
      this.stop();
    }
  }

  start() {
    this._interval = setInterval(() => {
      if (this.state.count <= 0) {
        this.stop();
      }
      const newCount = this.state.count - 1;
      this.setState({ count: newCount >= 0 ? newCount : 0 });
    }, 1000);
  }

  stop() {
    clearInterval(this._interval);
  }
}

export default Countdown;
