import React, { Component } from 'react';
// import Helmet from 'react-helmet';
import MediaQuery from 'react-media';

import Container from 'modules/core/components/container/container';
import Media from 'modules/core/components/media/media';
import ReactMedia from 'react-media';
import {
  IconBasket2,
  IconProduct,
  IconMobile,
  IconDelivery,
  // IconVk,
  // IconFacebook,
  // IconTelegram,
  // IconOdnoklassniki,
  // IconViber
} from 'modules/core/components/icons';
import HappyNavigation from './happy-master-navigation';
import { StickyContainer } from 'react-sticky';
import Button from 'modules/core/components/button/button';


import './happy-master-landing.css';

const videoMedia = {
  id: 1,
  type: 'MEDIA_TYPE/VIDEO',
  title: 'Happy boss video',
  sources: [
    {
      main: { normal: '/content/happy-master/video.mp4' },
      fallback: { normal: '/content/happy-master/video.mp4' }
    }
  ],
  source: [
    {
      main: { normal: '/content/happy-master/video.mp4' },
      fallback: { normal: '/content/happy-master/video.mp4' }
    }
  ],
  cover: '/content/happy-master/video-poster.png'
};

class HappyMasterLanding extends Component {
  state = {
    isVideoPlaying: false
  };

  render() {
    const { isVideoPlaying } = this.props;

    return (
    <MediaQuery query="(max-width: 840px)">
    {isMobile => (
      <div className="HappyMasterLanding">
        {/* <Helmet>
          <script src="//code-ya.jivosite.com/widget/Uk3wSnLQ5R" async></script>
        </Helmet> */}

        <StickyContainer>
          <section className="HappyMasterLanding-section">
            <Container>
              <div className="HappyMasterLanding-navigationInfo" id="whoIAm" name="whoIAm">
                <div className="HappyMasterLanding-navigationInfoMedia">
                  <img
                    className="HappyMasterLanding-navigationInfoMediaImage"
                    src="/content/happy-master/master-photo-landing.jpg"
                    srcSet="/content/happy-master/master-photo-landing.jpg 2x"
                    alt=""
                  ></img>
                </div>
                <div className="HappyMasterLanding-navigationInfoContent">
                  <h2 className="HappyMasterLanding-navigationInfoTitle">Всем привет! </h2>
                  <div className="HappyMasterLanding-navigationInfoDescription">
                    Меня зовут Даша и теперь я отвечаю за клиентское <nobr>счастье :)</nobr>
                  </div>
                  <ul className="HappyMasterLanding-navigationInfoList">
                    <li className="HappyMasterLanding-navigationInfoListItem">Кто я?</li>
                    <li className="HappyMasterLanding-navigationInfoListItem">
                      Проблемы, которые я решаю
                    </li>
                    <li className="HappyMasterLanding-navigationInfoListItem">Видео приветствие</li>
                    <li className="HappyMasterLanding-navigationInfoListItem">Связаться</li>
                  </ul>
                </div>
              </div>
            </Container>
          </section>

          <ReactMedia query="(min-width:840px)">
            <HappyNavigation />
          </ReactMedia>

          <section className="HappyMasterLanding-section HappyMasterLanding-section--highlight">
            <Container>
              <div className="HappyMasterLanding-acquaintance">
                <div className="HappyMasterLanding-acquaintanceInfo">
                  <p>
                    Со многими мы уже знакомы – за три года работы в Миксит я познакомилась со
                    многими покупателями, узнала о ваших желаниях и предпочтениях, любимых
                    продуктах.
                  </p>

                  <p>
                    Также, за три года я узнала – как вас много! И самое большое счастье для нас –
                    это счастье каждого покупателя.
                  </p>

                  <p>
                    А для этого очень важно ежедневное развитие. И в этом нам помогаете именно вы!
                  </p>

                  <ReactMedia query="(min-width:1101px)">
                    <p>
                      Мы не застрахованы от ошибок, и всегда учимся на них. А ваша обратная связь –
                      то, что помогает нам становиться лучше день за днем. Именно это сформировало
                      наши главные принципы в работе – забота, развитие и вдохновение!
                    </p>
                  </ReactMedia>
                </div>

                <div className="HappyMasterLanding-acquaintanceMedia">
                  <div className="HappyMasterLanding-acquaintanceMediaEditional">
                    <img
                      className="HappyMasterLanding-acquaintanceMediaEditionalImage"
                      src="/content/happy-master/master-photo-landing_2.png"
                      srcSet="/content/happy-master/master-photo-landing_2@2x.png 2x"
                      alt=""
                    ></img>
                  </div>
                  <img
                    className="HappyMasterLanding-acquaintanceMediaImage"
                    src="/content/happy-master/master-photo-landing_1.png"
                    srcSet="/content/happy-master/master-photo-landing_1@2x.png 2x"
                    alt=""
                  ></img>
                </div>

                <ReactMedia query="(max-width:1100px)">
                  <div className="HappyMasterLanding-acquaintanceInfo">
                    <p>
                      Мы не застрахованы от ошибок, и всегда учимся на них. А ваша обратная связь –
                      то, что помогает нам становиться лучше день за днем. Именно это сформировало
                      наши главные принципы в работе – забота, развитие и вдохновение!
                    </p>
                  </div>
                </ReactMedia>
              </div>
            </Container>
          </section>

          <section className="HappyMasterLanding-section">
            <Container>
              <div
                className="HappyMasterLanding-thanks"
                id="problemsAndSolution"
                name="problemsAndSolution"
              >
                <div className="HappyMasterLanding-thanksInfoContent">
                  <h1 className="HappyMasterLanding-thanksInfoContentTitle">
                    Мы благодарим каждого и хотим стать <nobr>еще ближе</nobr> к вам
                  </h1>

                  <div className="HappyMasterLanding-thanksInfoContentText">
                    Быть друг для друга друзьями, которые могут рассказать друг-другу обо всём, что
                    по-настоящему важно. В любое время и в любой день – я всегда на связи и готова
                    ответить на каждый вопрос:
                  </div>

                  <div className="HappyMasterLanding-thanksInfoFailGallery">
                    <div className="HappyMasterLanding-thanksInfoFailGalleryItem">
                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemMedia">
                        <div className="HappyMasterLanding-thanksInfoBasketContainer">
                          <IconBasket2 />
                        </div>

                        {/* <div className="HappyMasterLanding-thanksInfoFailIcon">
                          <div className="appyMasterLanding-thanksInfoFailIconTopItem"></div>
                          <div className="appyMasterLanding-thanksInfoFailIconBottomItem"></div>
                        </div> */}
                      </div>

                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemDescription">
                        Не понравилось обслуживание
                      </div>
                    </div>

                    <div className="HappyMasterLanding-thanksInfoFailGalleryItem">
                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemMedia">
                        <div className="HappyMasterLanding-thanksInfoProductContainer">
                          <IconProduct />
                        </div>
                        {/* <div className="HappyMasterLanding-thanksInfoFailIcon">
                          <div className="appyMasterLanding-thanksInfoFailIconTopItem"></div>
                          <div className="appyMasterLanding-thanksInfoFailIconBottomItem"></div>
                        </div> */}
                      </div>

                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemDescription">
                        Не понравился продукт
                      </div>
                    </div>

                    <div className="HappyMasterLanding-thanksInfoFailGalleryItem">
                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemMedia">
                        <div className="HappyMasterLanding-thanksInfoMobileContainer">
                          <IconMobile />
                        </div>
                        {/* <div className="HappyMasterLanding-thanksInfoFailIcon">
                          <div className="appyMasterLanding-thanksInfoFailIconTopItem"></div>
                          <div className="appyMasterLanding-thanksInfoFailIconBottomItem"></div>
                        </div> */}
                      </div>

                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemDescription">
                        Проблемы с сайтом или приложением
                      </div>
                    </div>

                    <div className="HappyMasterLanding-thanksInfoFailGalleryItem">
                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemMedia">
                        <div className="HappyMasterLanding-thanksInfoDeliveryContainer">
                          <IconDelivery />
                        </div>
                        {/* <div className="HappyMasterLanding-thanksInfoFailIcon">
                          <div className="appyMasterLanding-thanksInfoFailIconTopItem"></div>
                          <div className="appyMasterLanding-thanksInfoFailIconBottomItem"></div>
                        </div> */}
                      </div>

                      <div className="HappyMasterLanding-thanksInfoFailGalleryItemDescription">
                        Проблемы с доставкой
                      </div>
                    </div>
                  </div>

                  <div className="HappyMasterLanding-thanksInfoContentText HappyMasterLanding-thanksInfoContentText--centrAligned">
                    Или просто поделиться впечатлениями о покупке!
                  </div>
                </div>

                <div className="HappyMasterLanding-thanksPromiseContent">
                  <div className="HappyMasterLanding-thanksPromiseContentContainer">
                    Будьте уверены – каждый покупатель будет услышан, <nobr>а каждый</nobr> вопрос
                    или проблема решены!
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <section
            className="HappyMasterLanding-section HappyMasterLanding-section--noGaps"
            id="video"
            name="video"
          >
            <Media
              nocaption
              noClickableVideo
              setVideoState={this.handleSetVideoState}
              active={isVideoPlaying}
              media={videoMedia}
            ></Media>
          </section>

          <section className="HappyMasterLanding-section HappyMasterLanding-section--noGaps">
            <Container>
              <div className="HappyMasterLanding-feedback" id="feedback" name="feedback">
                <div className="HappyMasterLanding-feedbackContent">
                  <div className="HappyMasterLanding-feedbackOpen">
                    <Button
                      onClick={this.handleClick}
                      title="Написать Дарье"
                      variant="primary"
                      size={isMobile ? "small": "normal"}
                    />
                  </div>
                  {/* <div className="HappyMasterLanding-feedbackCaption">Всегда на связи!</div> */}
                  {/* <div className="HappyMasterLanding-feedbackPanel">
                    <div className="HappyMasterLanding-feedbackPanelItem">
                      <button className="HappyMasterLanding-feedbackButton HappyMasterLanding-feedbackButton--vk">
                        <div className="HappyMasterLanding-feedbackIconContainer--vk">
                          <IconVk />
                        </div>
                      </button>
                    </div>
                    <div className="HappyMasterLanding-feedbackPanelItem">
                      <button className="HappyMasterLanding-feedbackButton HappyMasterLanding-feedbackButton--facebook">
                        <div className="HappyMasterLanding-feedbackIconContainer--facebook">
                          <IconFacebook />
                        </div>
                      </button>
                    </div>
                    <div className="HappyMasterLanding-feedbackPanelItem">
                      <button className="HappyMasterLanding-feedbackButton HappyMasterLanding-feedbackButton--telegram">
                        <div className="HappyMasterLanding-feedbackIconContainer--telegram">
                          <IconTelegram />
                        </div>
                      </button>
                    </div>
                    <div className="HappyMasterLanding-feedbackPanelItem">
                      <button className="HappyMasterLanding-feedbackButton HappyMasterLanding-feedbackButton--viber">
                        <div className="HappyMasterLanding-feedbackIconContainer--viber">
                          <IconViber />
                        </div>
                      </button>
                    </div>
                    <div className="HappyMasterLanding-feedbackPanelItem ">
                      <button className="HappyMasterLanding-feedbackButton HappyMasterLanding-feedbackButton--odnoklassniki">
                        <div className="HappyMasterLanding-feedbackIconContainer--odnoklassniki">
                          <IconOdnoklassniki />
                        </div>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </Container>
          </section>
        </StickyContainer>
      </div>
    )}
    </MediaQuery>
    );
  }

  handleClick = () => {

    if(__BROWSER__ && window.jivo_api) {
      window.jivo_api.open();
    };
  }

  handleSetVideoState = isVideoPlaying => {
    this.setState({ isVideoPlaying });
  };
}

export default HappyMasterLanding;
