import React, { Component } from 'react';
import cn from 'classnames';
import Price from 'modules/ordering/components/price-formatted/price-formatted';
import { IconBasket } from 'modules/core/components/icons';

import './product-toolbar.css';
import Button from 'modules/core/components/button/button';
import VolumeSwitchOption from 'modules/core/components/switch/volume-switch/volume-switch-option';
import NotificationCounter from 'modules/core/components/notification-counter/notification-counter';
import { getBasketLink } from 'routes/links';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-media';
import Media from 'modules/core/components/media/media';
import Container from 'modules/core/components/container/container';
import { IconHeart } from 'modules/core/components/icons';

function ToolbarContainer({ show, children }) {
  if (show) {
    return <Container>{children}</Container>;
  }
  return children;
}

class ProductToolbar extends Component {
  render() {
    return (
      <MediaQuery query="(max-width: 420px)">
        {smallMobile => (
          <MediaQuery query="(max-width: 840px)">
            {mobile => (
              <MediaQuery query="(max-width: 1100px)">
                {tablet =>
                  this.renderContent({
                    smallMobile,
                    mobile,
                    tablet
                  })
                }
              </MediaQuery>
            )}
          </MediaQuery>
        )}
      </MediaQuery>
    );
  }

  renderContent({ smallMobile, mobile, tablet }) {
    const {
      opened,
      title,
      description,
      price,
      media,
      amountInBasket,
      defaultWeight,
      productsAmount,
      onAddToBasket,
      isABTEst,
      product
    } = this.props;

    const hasDiscount = price.discount > 0;
    const inBasket = amountInBasket > 0;

    return (
      <div
        className={cn('ProductToolbar', {
          'ProductToolbar--opened': opened
        })}
      >
        <ToolbarContainer show={!smallMobile}>
          <div className="ProductToolbar-box">
            {isABTEst && mobile ? (
              <div className="ProductToolbar-ABTest">
                <div className="ProductToolbar-ABTest-toBasket">
                  <Button
                    title={inBasket ? 'Добавить еще в корзину' : 'Добавить в корзину'}
                    variant="primary"
                    size={tablet ? 'small' : 'normal'}
                    reducedGap={inBasket}
                    onClick={onAddToBasket}
                    expanded
                  />
                </div>
                <div
                  className={cn('ProductToolbar-ABTest-toFavourite', {
                    'ProductToolbar-ABTest-toFavourite--inFavourite': product.inFavorite
                  })}
                >
                  <Button
                    icon={<IconHeart active={product.inFavorite} />}
                    onClick={this.handleFavoriteClick}
                    size={tablet ? 'small' : 'normal'}
                  />
                </div>
              </div>
            ) : (
              <>
                {!smallMobile && (
                  <div className="ProductToolbar-mediaCol">
                    <div className="ProductToolbar-mediaHolder">
                      <Media
                        media={media}
                        stretch="horizontal"
                        resizeWidth={72}
                        background="#fff"
                        nocaption
                      />
                    </div>
                  </div>
                )}

                {!mobile && (
                  <div className="ProductToolbar-descriptionCol">
                    <div className="ProductToolbar-titleGroup">
                      <h2 className="ProductToolbar-title">{title}</h2>
                      {defaultWeight && (
                        <div className="ProductToolbar-volumeHolder">
                          <VolumeSwitchOption
                            volume={defaultWeight.value}
                            unit={defaultWeight.payload.unit}
                            small
                          />
                        </div>
                      )}
                    </div>
                    <div className="ProductToolbar-descriptionGroup">
                      <p className="ProductToolbar-description">{description}</p>
                    </div>
                  </div>
                )}

                <div className="ProductToolbar-priceCol">
                  <div className="ProductToolbar-priceGroup">
                    {!smallMobile && mobile && (
                      <div className="ProductToolbar-titleGroup">
                        <h2 className="ProductToolbar-title">{title}</h2>
                      </div>
                    )}
                    <div className="ProductToolbar-priceHolder">
                      <b>
                        <Price
                          number={price.current}
                          variant={hasDiscount ? 'discount' : 'default'}
                        />
                      </b>{' '}
                      {hasDiscount && <Price number={price.default} variant="previous" />}
                    </div>
                    {mobile && defaultWeight && (
                      <div className="ProductToolbar-volumeHolder">
                        <VolumeSwitchOption
                          volume={defaultWeight.value}
                          unit={defaultWeight.payload.unit}
                          small
                        />
                      </div>
                    )}
                  </div>
                  {inBasket && (
                    <div className="ProductToolbar-inBasket">
                      {amountInBasket} шт. уже в корзине
                    </div>
                  )}
                </div>

                <div className="ProductToolbar-actionCol">
                  <div className="ProductToolbar-action">
                    <Button
                      title={inBasket ? '+ 1 шт.' : 'Купить'}
                      variant="primary"
                      size={tablet ? 'small' : 'normal'}
                      reducedGap={inBasket}
                      onClick={onAddToBasket}
                    />
                  </div>
                  {inBasket && !smallMobile && (
                    <div className="ProductToolbar-action">
                      {tablet && (
                        <span className="ProductToolbar-badge">
                          <NotificationCounter count={productsAmount} />
                        </span>
                      )}
                      <Button
                        component={Link}
                        to={getBasketLink()}
                        icon={tablet && <IconBasket />}
                        title={!tablet && 'В корзину'}
                        size={tablet ? 'small' : 'normal'}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </ToolbarContainer>
      </div>
    );
  }
}

export default ProductToolbar;
