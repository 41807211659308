import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromLocation from 'modules/geography/ducks/location';

import CityChoice from '../components/city-choice/city-choice';

const mapStateToProps = createStructuredSelector({
  currentCity: fromLocation.getCurrentCity
});

function getCityTitle(city) {
  if (!city || !city.title) {
    return 'Выберите город';
  }

  return city.title;
}

class CitySuggestBottomSheetContainer extends Component {
  state = {
    isChoiceDialogOpened: false,
    isSuggestDialogOpened: false
  };

  render() {
    const { currentCity } = this.props;
    const { isChoiceDialogOpened, isSuggestDialogOpened } = this.state;

    const cityTitle = getCityTitle(currentCity);

    return (
      <CityChoice
        title={cityTitle}
        isChoiceOpened={isChoiceDialogOpened}
        isSuggestOpened={isSuggestDialogOpened}
        onButtonClick={this.handleButtonClick}
        onChoiceClose={this.handleChoiceClose}
        onSuggestClose={this.handleSuggestClose}
        onAnotherSelect={this.handleAnotherSelect}
      />
    );
  }

  openSuggestDialog() {
    const { onDialogOpen } = this.props;

    this.setState({
      isSuggestDialogOpened: true,
      isChoiceDialogOpened: false
    });

    onDialogOpen();
  }

  openChoiceDialog() {
    const { onDialogOpen } = this.props;

    this.setState({
      isChoiceDialogOpened: true,
      isSuggestDialogOpened: false
    });

    onDialogOpen();
  }

  closeSuggestDialog() {
    this.setState({
      isSuggestDialogOpened: false
    });
  }

  closeChoiceDialog() {
    this.setState({
      isChoiceDialogOpened: false
    });
  }

  handleAnotherSelect = () => {
    this.openChoiceDialog();
  };

  handleChoiceClose = () => {
    this.closeChoiceDialog();
  };

  handleSuggestClose = () => {
    this.closeSuggestDialog();
  };

  handleButtonClick = () => {
    const { chosenCity, suggestedCity } = this.props;

    if (!chosenCity && suggestedCity) {
      this.openSuggestDialog();
      return;
    }

    this.openChoiceDialog();
  };
}

export default connect(mapStateToProps)(CitySuggestBottomSheetContainer);
