import { createSelector } from 'reselect';
import { getProductsModule } from '../meta';
import { createReducer, handle as on } from 'modules/utils/dux';

import * as CATEGORY from './types/categories';
import * as SEARCH from './types/search';

import filterOptionSeeds from './seeds/filter-option';

const all = createReducer(
  on(CATEGORY.ADD_ITEM, (state, { category }) => {
    const all = category.filters.reduce(
      (all, filter) =>
        filter.options.reduce(
          (all, option) => ({
            ...all,
            [option.slug]: { ...option, parent: filter.id }
          }),
          all
        ),
      {}
    );

    return {
      ...state,
      ...all
    };
  }),

  on(SEARCH.RESPONSE, (state, { entities }, error) => {
    if (error || !entities.filterOption) {
      return state;
    }

    return {
      ...state,
      ...entities.filterOption
    };
  })
);

export default all(filterOptionSeeds);

export const getFilterOptions = state => getProductsModule(state).filterOptions;

export const getSlugFormProps = (_, { slug }) => slug;

export const getItemBySlug = createSelector(
  getFilterOptions,
  getSlugFormProps,
  function _getFilterOptionBySlug(filterOptions, slug) {
    if (!(slug in filterOptions)) {
      return null;
    }

    return filterOptions[slug];
  }
);

export const makeGetItemBySlug = createSelector(
  getFilterOptions,
  function _makeGetItemBySlug(filterOptions) {
    return slug => {
      if (!(slug in filterOptions)) {
        return null;
      }

      return filterOptions[slug];
    };
  }
);
