import * as basketSaga from './basket-saga';
import * as orderingSaga from './ordering-saga';
import * as cityDeliverySaga from './city-delivery-saga';
import * as personalInfoFormSaga from './personal-info-form-saga';
import * as paymentMethodsSaga from './payment-methods-saga';
import * as basketSyncSaga from './basket-sync-saga';
import * as lastOrderSaga from './last-order-saga';
import * as basketPreview from './basket-preview';

const sagas = {
  ...basketSaga,
  ...orderingSaga,
  ...cityDeliverySaga,
  ...personalInfoFormSaga,
  ...paymentMethodsSaga,
  ...basketSyncSaga,
  ...lastOrderSaga,
  ...basketPreview
};

export default function register(middleware) {
  return () => Object.values(sagas).map(middleware.run);
}
