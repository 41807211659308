import { take, call, put, takeEvery, fork, select } from 'redux-saga/effects';
import { mixitApi } from 'services/mixit';
import * as TYPES from '../types';
import * as fromAccount from 'modules/core/ducks/account';
import { Magazine } from '../actions';
import * as BlogResponseAdapter from 'services/mixit/adapters/blog-response-adapter';
import * as ArticleResponseAdapter from 'services/mixit/adapters/article-response-adapter';
import { getArticleLink } from 'routes/links';

export function* fetchMagazine() {
  while (true) {
    yield take(TYPES.MAGAZINE_HOME_REQUEST);

    try {
      const response = yield call(mixitApi().blog().item);
      const { result, entities } = BlogResponseAdapter.normalize(response.data);
      yield put(Magazine.homeResponse(null, result.promoted, result.articles, entities));
    } catch (error) {
      yield put(Magazine.homeResponseError(error));
    }
  }
}

export function* articleWatcher() {
  yield takeEvery(TYPES.MAGAZINE_ARTICLE_REQUEST, fetchArticle);
}

function* fetchArticle(action) {
  const { slug } = action.payload;

  yield fork(fetchArticleShares, slug);

  const authorization = yield select(fromAccount.getAuthorization);

  const { response, error } = yield requestArticle(authorization, slug);

  if (error) {
    yield put(Magazine.articleResponse(error));
  }

  const { result, entities } = ArticleResponseAdapter.normalize(response.data);

  yield put(Magazine.articleResponse(null, result, entities));
}

function* requestArticle(authorization, slug) {
  try {
    const response = yield call(
      mixitApi(authorization)
        .blog()
        .articles(slug).item
    );

    return { response };
  } catch (error) {
    return { error };
  }
}

function* fetchArticleShares(slug) {
  const articleLink = `${process.env.REACT_APP_HOST}${getArticleLink({
    slug
  })}`;

  yield fork(addFacebookShares, slug, articleLink);
  yield fork(addVkShares, slug, articleLink);
}

function* addFacebookShares(slug, link) {
  try {
    const response = yield call(fetchFacebookCount, link);

    yield put(
      Magazine.articleSharesAdd(slug, {
        name: 'facebook',
        link: `https://www.facebook.com/sharer/sharer.php?${link}`,
        amount: response.share.share_count
      })
    );
  } catch (e) {}
}

function* addVkShares(slug, link) {
  try {
    const response = yield call(fetchVkCount, link);

    console.log(response);

    yield put(
      Magazine.articleSharesAdd(slug, {
        name: 'vk',
        link: `https://vk.com/share.php?url=${link}`,
        amount: parseInt(response.substring(18), 10)
      })
    );
  } catch (e) {}
}

function fetchVkCount(url) {
  return fetch(`https://vk.com/share.php?act=count&url=${url}`, {
    credentials: 'include'
  }).then(response => response.text());
}

function fetchFacebookCount(url) {
  return fetch(`https://graph.facebook.com/?fields=share&id=${url}`).then(response =>
    response.json()
  );
}
