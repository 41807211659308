import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'modules/form/components/form/form';
import LeftLabeledField from 'modules/form/components/left-labeled-field/left-labeled-field';
import FieldsetChoice from 'modules/form/components/fieldset-choice/fieldset-choice';
import Radio from 'modules/form/components/radio/radio';

import './payment-form.css';
import Loader from 'modules/core/components/loader/loader';

const { func } = PropTypes;

class PaymentForm extends Component {
  static propTypes = {
    /** Payment method changing function */
    onPaymentMethodChange: func.isRequired,

    /** ButtonAccept click function */
    onSubmit: func.isRequired
  };
  state = {
    currentCheckId: false
  };

  render() {
    const { paymentMethodList, isLoading } = this.props;

    return (
      <div className="PaymentForm">
        <Form>
          <div className="PaymentForm-radioList">
            <LeftLabeledField label="Оплата" size="inline">
              {!paymentMethodList || isLoading ? (
                <div className="PaymentForm-loader">
                  <Loader type="goo" size="small" />
                </div>
              ) : (
                <FieldsetChoice>
                  {paymentMethodList.map(item => {
                    return (
                      <FieldsetChoice.Item key={item.id}>
                        <Radio
                          label={item.title}
                          name="payment"
                          value={item.id}
                          checked={item.id === this.state.currentCheckId}
                          onChange={this.handleRadioChange(item.id)}
                        />
                      </FieldsetChoice.Item>
                    );
                  })}
                </FieldsetChoice>
              )}
            </LeftLabeledField>
          </div>
        </Form>
      </div>
    );
  }

  componentDidMount() {
    this.initialChecked();
  }

  componentDidUpdate(prevProps) {
    if (this.props.paymentMethodList !== prevProps.paymentMethodList) {
      this.initialChecked();
    }
  }

  initialChecked() {
    const { paymentMethodList } = this.props;

    if (paymentMethodList && paymentMethodList[0]) {
      this.handleRadioChange(paymentMethodList[0].id)();
    }
  }

  handleRadioChange = payment => () => {
    this.setState({ currentCheckId: payment });
    const { onPaymentTypeChange } = this.props;

    onPaymentTypeChange(payment);
  };
}

export default PaymentForm;
