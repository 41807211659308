import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './article-section.css';
import Container from 'modules/core/components/container/container';

const { string, node } = PropTypes;

class ArticleSection extends Component {
  static propTypes = {
    title: string,
    side: node,
    children: node
  };

  render() {
    const { side, children } = this.props;
    return (
      <Container>
        <div
          className={cn('ArticleSection', {
            'ArticleSection--withSideBar': side
          })}
        >
          <div className="ArticleSection-mainContent">{children}</div>
          {side && (
            <div className="ArticleSection-sideBar">
              <div className="ArticleSection-sideBarSticky">{side}</div>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default ArticleSection;
