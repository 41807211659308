export function normalize(response) {
  const { data: list } = response;

  const normalizedList = list.map(category => ({
    categoryTitle: category.category_title,
    slug: category.anchor,
    list: category.list.map(question => ({
      subcategoryTitle: question.question,
      subcategoryDescription: question.answer
    }))
  }));

  return normalizedList;
}
