import React, { Component, Fragment } from 'react';
import Media from 'react-media';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromOrders from '../ducks/orders';

import DeliverySummary from '../components/delivery-summary/delivery-summary';
import * as fromBasket from '../ducks/basket/selectors';
import formatStreet from '../components/delivery-summary/helpers/format-street';
import * as fromBasketProducts from '../ducks/basket-products';
import * as fromDeliveryForm from '../ducks/delivery-form';

const mapStateToProps = createStructuredSelector({
  delivery: fromOrders.getDeliveryById,
  cost: fromOrders.getDeliveryCostById,
  selectedPickPoint: fromOrders.getPickPointById,
  cityTitle: fromOrders.getCityTitleById,
  productsCost: fromOrders.getProductsCostWithDiscount,
  maxDeliveryPrice: fromBasket.getMaxDeliveryPrice,
  currentDeliveryCost: fromDeliveryForm.getDeliveryCost,
  isDeliveryFreeByDeliveryCity: fromBasketProducts.getIsDeliveryFreeByDeliveryCity,
  method: fromDeliveryForm.getDeliveryMethod,
});

// const FREE_DELIVERY_COST = 3000;

class DeliverySummaryContainer extends Component {
  render() {
    const {
      delivery,
      cityTitle,
      selectedPickPoint,
      productsCost,
      cost,
      maxDeliveryPrice,
      isDeliveryFreeByDeliveryCity,
      method,
      currentDeliveryCost,
      ...rest
    } = this.props;
    
    const address = this.normalizeAddress();
    const pickPointTitle = selectedPickPoint && selectedPickPoint.title;
    
    // const deliveryMethodExist = Boolean(method);
    const noRussia = method && method.isNoDeliveryRussia;
 
    
    // let deliveryPrice =  noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost : 0;
    let deliveryPrice =  noRussia || maxDeliveryPrice !== 0 ? currentDeliveryCost || cost : 0;


    if (isDeliveryFreeByDeliveryCity && !noRussia) {
      deliveryPrice = 0;
    }

    return (
      <DeliverySummary
        deliveryMethod={delivery.deliveryMethod}
        cityTitle={cityTitle}
        address={address}
        subtitle={pickPointTitle}
        recipientName={delivery.recipientName}
        recipientPhone={delivery.recipientPhone}
        // cost={productsCost >= FREE_DELIVERY_COST ? 0 : cost}
        // cost={maxDeliveryPrice === 0 || isDeliveryFreeByDeliveryCity ? 0 : cost}
        cost={deliveryPrice}
        {...rest}
      />
    );
  }

  normalizeAddress() {
    const { delivery, selectedPickPoint } = this.props;

    if (selectedPickPoint) {
      const street = formatStreet(selectedPickPoint.geography.address.street_title);

      const building = selectedPickPoint.geography.address.building;

      return `${street}, ${building}`;
    }

    const address = delivery.geography.address;

    return (
      <Media query="(max-width: 480px)">
        {isMobile => (
          <Fragment>
            {isMobile ? (
              <Fragment>
                {address && address.street && address.street.raw}
                {address && address.apartment && ','}
              </Fragment>
            ) : (
              <nobr>
                {address && address.street && address.street.raw}
                {address && address.apartment && ','}
              </nobr>
            )}

            {address && address.apartment && (
              <Fragment>
                {' '}
                <nobr>квартира {address.apartment}</nobr>
              </Fragment>
            )}
          </Fragment>
        )}
      </Media>
    );
  }
}

export default connect(mapStateToProps)(DeliverySummaryContainer);
