export default [
  {
    id: 0,
    title: 'Россия',
    value: '+7',
    name: 'rus',
    phoneLength: 11
  },
  {
    id: 1,
    title: 'Беларусь',
    value: '+375',
    name: 'bel',
    phoneLength: 12
  },
  {
    id: 2,
    title: 'Армения',
    value: '+996',
    name: 'arm',
    phoneLength: 12
  },
  {
    id: 3,
    title: 'Укранина',
    value: '+380',
    name: 'ukr',
    phoneLength: 12
  },
  {
    id: 4,
    title: 'Киргизия',
    value: '+374',
    name: 'kirg',
    phoneLength: 12
  }
];
