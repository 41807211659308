function plural(number, [one, few, many]) {
  const absNumber = Math.abs(number);

  const mod100 = absNumber % 100;
  if (mod100 >= 5 && mod100 <= 20) {
    return many;
  }

  const mod10 = absNumber % 10;
  if (mod10 === 1) {
    return one;
  }

  if (mod10 >= 2 && mod10 <= 4) {
    return few;
  }

  return many;
}

export default plural;
