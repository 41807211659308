import React from 'react';
import cn from 'classnames';
import LandingSectionTitle from '../landing-section-title/landing-section-title';
import TimetableItem from './timetable-item/timetable-item';

import './opening-timetable.css';

export default function OpeningTimetable({ data, colors, light }) {
  return (
    <div className={cn('OpeningTimetable', { 'OpeningTimetable--light': light })}>
      <div className="OpeningTimetable-title">
        <LandingSectionTitle
          data={data.title}
          top={data.title[0]}
          bottom={data.title[1]}
          light={light}
        />
      </div>
      <div className="OpeningTimetable-listHolder">
        <ul className="OpeningTimetable-list">
          {data.list.map((event, index) => (
            <li key={index} className="OpeningTimetable-item">
              <TimetableItem event={event} colors={colors} light={light} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
