import React, { Component } from 'react';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import IconCross from 'modules/core/components/icons/icon-cross/icon-cross';
import './filter-tag.css';

class FilterTag extends Component {
  render() {
    const { title, onRemove } = this.props;
    return (
      <span className="FilterTag">
        <span className="FilterTag-title">{title}</span>
        <span className="FilterTag-remove">
          <button className="FilterTag-removeButton" type="button" onClick={onRemove}>
            <IconContainer inline>
              <IconCross />
            </IconContainer>
          </button>
        </span>
      </span>
    );
  }
}

export default FilterTag;
