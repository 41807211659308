import React, { Component } from 'react';
import './icon-minus.css';

class IconMinus extends Component {
  render() {
    return <span className="IconMinus" />;
  }
}

export default IconMinus;
