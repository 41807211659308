export default [
  {
    title: 'Волгоград',
    id: 'a52b7389-0cfe-46fb-ae15-298652a64cf8',
    fiasId: 'a52b7389-0cfe-46fb-ae15-298652a64cf8'
  },
  {
    title: 'Воронеж',
    id: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1',
    fiasId: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1'
  },
  {
    title: 'Екатеринбург',
    id: '2763c110-cb8b-416a-9dac-ad28a55b4402',
    fiasId: '2763c110-cb8b-416a-9dac-ad28a55b4402'
  },
  {
    title: 'Казань',
    id: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
    fiasId: '93b3df57-4c89-44df-ac42-96f05e9cd3b9'
  },
  {
    title: 'Краснодар',
    id: '7dfa745e-aa19-4688-b121-b655c11e482f',
    fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f'
  },
  {
    title: 'Красноярск',
    id: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd',
    fiasId: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd'
  },
  {
    title: 'Москва',
    id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'
  },
  {
    title: 'Нижний Новгород',
    id: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
    fiasId: '555e7d61-d9a7-4ba6-9770-6caa8198c483'
  },
  {
    title: 'Новосибирск',
    id: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
    fiasId: '8dea00e3-9aab-4d8e-887c-ef2aaa546456'
  },
  {
    title: 'Омск',
    id: '140e31da-27bf-4519-9ea0-6185d681d44e',
    fiasId: '140e31da-27bf-4519-9ea0-6185d681d44e'
  },
  {
    title: 'Пенза',
    id: 'ff3292b1-a1d2-47d4-b35b-ac06b50555cc',
    fiasId: 'ff3292b1-a1d2-47d4-b35b-ac06b50555cc'
  },
  {
    title: 'Пермь',
    id: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95',
    fiasId: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95'
  },
  {
    title: 'Ростов-на-Дону',
    id: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
    fiasId: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5'
  },
  {
    title: 'Самара',
    id: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
    fiasId: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b'
  },
  {
    title: 'Санкт-Петербург',
    id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    fiasId: 'c2deb16a-0330-4f05-821f-1d09c93331e6'
  },
  {
    title: 'Уфа',
    id: '7339e834-2cb4-4734-a4c7-1fca2c66e562',
    fiasId: '7339e834-2cb4-4734-a4c7-1fca2c66e562'
  },
  {
    title: 'Саратов',
    id: 'bf465fda-7834-47d5-986b-ccdb584a85a6',
    fiasId: 'bf465fda-7834-47d5-986b-ccdb584a85a6'
  },
  {
    title: 'Ставрополь',
    id: '2a1c7bdb-05ea-492f-9e1c-b3999f79dcbc',
    fiasId: '2a1c7bdb-05ea-492f-9e1c-b3999f79dcbc'
  },
  {
    title: 'Тольятти',
    id: '242e87c1-584d-4360-8c4c-aae2fe90048e',
    fiasId: '242e87c1-584d-4360-8c4c-aae2fe90048e'
  },
  {
    title: 'Тула',
    id: 'b2601b18-6da2-4789-9fbe-800dde06a2bb',
    fiasId: 'b2601b18-6da2-4789-9fbe-800dde06a2bb'
  },
  {
    title: 'Челябинск',
    id: 'a376e68d-724a-4472-be7c-891bdb09ae32',
    fiasId: 'a376e68d-724a-4472-be7c-891bdb09ae32'
  },
  {
    title: 'Калининград',
    id: 'df679694-d505-4dd3-b514-4ba48c8a97d8',
    fiasId: 'df679694-d505-4dd3-b514-4ba48c8a97d8'
  }
];
