import React, { PureComponent } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';

import './main-header-mobile.css';
import MixitLogo from 'modules/core/components/mixit-logo/mixit-logo';
import Container from 'modules/core/components/container/container';
import { Link } from 'react-router-dom';
import { getBasketLink } from 'routes/links';
import { IconBasket, IconUser } from '../icons';
import isFunction from 'modules/utils/is-function';
import { IconSearch, IconHamburger, IconClose } from 'modules/core/components/icons';
import HeaderButton from '../header-button/header-button';
import Loader from 'modules/core/components/loader/loader';

class MainHeaderMobile extends PureComponent {
  render() {
    const {
      onHamburgerClick,
      navigationOpened,
      triggerRef,
      productsAmount,
      isAuthorized,
      isProfileLoading
    } = this.props;

    return (
      <div className="MainHeaderMobile">
        <Container>
          <div className="MainHeaderMobile-content">
            <div className="MainHeaderMobile-leftCol">
              <div
                className={cn('MainHeaderMobile-trigger', {
                  'MainHeaderMobile-trigger--inverted': navigationOpened
                })}
              >
                <HeaderButton
                  onClick={onHamburgerClick}
                  active={navigationOpened}
                  icon={navigationOpened ? <IconClose /> : <IconHamburger />}
                  ref={triggerRef}
                />
              </div>
              <div className="MainHeaderMobile-trigger">
                <HeaderButton icon={<IconSearch />} onClick={this.handleSearchClick} />
              </div>
            </div>
            <div className="MainHeaderMobile-logoCol">
              <MixitLogo key={this.props.location.pathname} />
            </div>
            <div className="MainHeaderMobile-rightCol">
              <div className="MainHeaderMobile-trigger">
                {isAuthorized ? null : isProfileLoading ? (
                  <HeaderButton
                    icon={
                      <div className="MainHeaderMobile-buttonLoader">
                        <Loader type="circle" size="small" />
                      </div>
                    }
                    loading
                  />
                ) : (
                  <HeaderButton
                    title="Войти в личный кабинет"
                    icon={<IconUser />}
                    onClick={this.handleAuthClick}
                  />
                )}
              </div>

              <div className="MainHeaderMobile-trigger">
                <HeaderButton
                  component={Link}
                  to={getBasketLink()}
                  count={productsAmount}
                  title="Корзина"
                  icon={<IconBasket />}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  handleAuthClick = e => {
    const { openAuthDialog } = this.props;

    e.preventDefault();
    openAuthDialog();
  };

  handleSearchClick = () => {
    const { onSearchClick } = this.props;

    if (!isFunction(onSearchClick)) {
      return;
    }
    onSearchClick();
  };
}

export default withRouter(MainHeaderMobile);
