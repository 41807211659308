import React, { Component } from 'react';
import { getProductLink } from 'routes/links';
import { withRouter } from 'react-router';

import Media from 'modules/core/components/media/media';
import BasketLabel from '../basket-label/basket-label';
import Amount from '../amount/amount';
import Price from '../price-formatted/price-formatted';
import ButtonRemove from 'modules/core/components/button-remove/button-remove';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';
import ProductFeatures from 'modules/product/components/product-features/product-features';

import Cell from './cell';
import Row from './row';

import './basket-product.css';

class BasketProduct extends Component {
  render() {
    const {
      product,
      price,
      amount,
      isGift,
      listId,
      onAmountChange,
      onRemove,
      withCounter,
      isBasketFetching
    } = this.props;

    const discount = this.getPercentageDiscount(price.discount, price.default);

    const filteredFeatures = this.getFilteredfeaturesBy(product.features, 'FEATURE/MIXIT_ART');

    const isMixitArtProduct = filteredFeatures.length !== 0;

    return (
      <div className="BasketProduct">
        <Row>
          <Cell type="mainInfo">
            <ProductAnalyticsProvider
              productId={product.slug}
              isGift={isGift}
              listId={listId}
              render={({ productRef, handleClick }) => (
                <div className="BasketProduct-mainInfo" ref={productRef}>
                  <div className="BasketProduct-imageWrapper">
                    <Media
                      media={product.announcementMedia}
                      resizeWidth={100}
                      nocaption
                      className="BasketProduct-image"
                      stretch="horizontal"
                    />
                  </div>

                  <div className="BasketProduct-descriptionHolder">
                    {isMixitArtProduct && (
                      <div className="BasketProduct-features">
                        <ProductFeatures
                          size="small"
                          // isGift={product.isGift}
                          features={filteredFeatures}
                        />
                      </div>
                    )}

                    {product.description && (
                      <div className="BasketProduct-description">{product.description}</div>
                    )}

                    {product.title && (
                      <h3 className="BasketProduct-title">
                        {this.renderProductLinkComponent(handleClick)}
                      </h3>
                    )}
                    {product.variantOptionsAll &&
                      product.variantOptionsAll.map(item => (
                        <div className="BasketProduct-selectedVariant">
                          {item.variantTitle}: {item.title || item.value}{' '}
                          {(item.payload || {}).unit || ''}
                        </div>
                      ))}

                    {/* <ul className="BasketProduct-labelList">
              {product.features.map(this.renderLabel)}
            </ul> */}
                  </div>
                </div>
              )}
            />
          </Cell>
          <Cell type="amount">
            {withCounter ? (
              <div className="BasketProduct-amount">
                <Amount value={amount} onChange={onAmountChange} disabled={isBasketFetching} />
              </div>
            ) : (
              <div className="BasketProduct-amount">{amount} шт.</div>
            )}
          </Cell>

          <Cell type="price">
            {isGift ? (
              <span className="BasketProduct-giftLabel">Подарок!</span>
            ) : price.discount > 0 ? (
              <div className="BasketProduct-priceHolder">
                <div className="BasketProduct-price">
                  <div className="BasketProduct-pricePrevious">
                    <Price number={price.default} variant="previous" />
                  </div>
                  <div className="BasketProduct-priceDefault">
                    <Price number={price.current} />
                  </div>
                </div>
                <div className="BasketProduct-priceDiscount">Скидка {discount}%</div>
              </div>
            ) : (
              <div className="BasketProduct-price">
                <div className="BasketProduct-priceDefault">
                  <Price number={price.current} />
                </div>
              </div>
            )}
          </Cell>

          <Cell type="actions">
            <div className="BasketProduct-actions">
              {!isGift && (
                <div className="BasketProduct-actionsItem">
                  <ButtonRemove onClick={onRemove} disabled={isBasketFetching} />
                </div>
              )}
            </div>
          </Cell>
        </Row>
      </div>
    );
  }

  renderProductLinkComponent = handleClick => {
    const { product, isGift } = this.props;

    const filteredFeatures = this.getFilteredfeaturesBy(product.features, 'FEATURE/MIXIT_ART');

    const isMixitArtProduct = filteredFeatures.length !== 0;

    const productLink = isMixitArtProduct
      ? this.getMixitArtLink(product.slug)
      : getProductLink(product);

    const UsedTag = isGift ? 'div' : 'a';
    const paremeters = {
      ...(!isGift && { href: productLink }),
      ...(!isGift && { target: '_blank' }),
      ...(!isGift && { rel: 'noreferrer noopener' })
    };

    return (
      <UsedTag {...paremeters} className="BasketProduct-link" onClick={handleClick()}>
        {product.title}
      </UsedTag>
    );
  };

  getFilteredfeaturesBy(features, type) {
    return features.filter(feature => feature.type === type);
  }

  getMixitArtLink(slug) {
    const { host } = window.location;
    const link = `https://art.${host}/product/${slug}`;

    return link;
  }

  getPercentageDiscount(discount, defaultPrice) {
    return Math.round((discount / defaultPrice) * 100);
  }

  renderLabel = (label, index) => {
    return (
      <li key={index} className="BasketProduct-labelItem">
        <BasketLabel title={label.title} type={label.type} />
      </li>
    );
  };

  handleAmountChange = amount => {
    const { product, changeAmount } = this.props;
    changeAmount(product, amount);
  };
}

const BasketProductWithRouter = withRouter(BasketProduct);

export default BasketProductWithRouter;
