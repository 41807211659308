import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconContainer from '../icon-container/icon-container';
import { IconArrowLeft, IconArrowRight } from '../icons/index';

import './link-navigation.css';

const { string, oneOfType, func, oneOf } = PropTypes;

class LinkNavigation extends Component {
  static propTypes = {
    /**
     * Link string
     **/
    href: string,

    /**
     * Icon size
     **/
    size: oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),

    /**
     * Container tag
     **/
    component: oneOfType([string, func])
  };

  static defaultProps = {
    size: 'tiny',
    component: 'a'
  };

  render() {
    const { back, next, children, component: LinkComponent, size, ...rest } = this.props;
    return (
      <LinkComponent className="LinkNavigation" {...rest}>
        {back && (
          <div className="LinkNavigation-prependedIcon">
            <IconContainer size={size}>
              <IconArrowLeft />
            </IconContainer>
          </div>
        )}
        <div className="LinkNavigation-text">{children}</div>
        {next && (
          <div className="LinkNavigation-appendedIcon">
            <IconContainer size={size}>
              <IconArrowRight />
            </IconContainer>
          </div>
        )}
      </LinkComponent>
    );
  }
}
export default LinkNavigation;
