import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'modules/core/components/switch/switch';
import SizeSwitchOption from './size-switch-option';
import SizeSwitchItem from './size-switch-item';
import SizeSwitchContainer from './size-switch-container';
import './size-switch.css';

class SizeSwitch extends Component {
  static propTypes = {
    /**
     *
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        disabled: PropTypes.bool
      }).isRequired
    ),
    /** событие по изменению (переключению) опции */
    onChange: PropTypes.func
  };

  render() {
    const { options, ...rest } = this.props;

    return (
      <Switch switchHolder={<SizeSwitchContainer />} optionHolder={<SizeSwitchItem />} {...rest}>
        {options.map(this.renderOption)}
      </Switch>
    );
  }

  renderOption(option) {
    return (
      <Switch.Option key={option.id} disabled={option.outOfStock} value={option.id}>
        <SizeSwitchOption size={option.title || option.value} />
      </Switch.Option>
    );
  }
}
export default SizeSwitch;
