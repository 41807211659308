import React, { Component } from 'react';
import './mobile-navigation-button.css';

class MobileNavigationButton extends Component {
  render() {
    const { children, title, ...rest } = this.props;
    return (
      <button type="button" className="MobileNavigationButton" {...rest}>
        {children && <span className="MobileNavigationButton-icon">{children}</span>}
        <span className="MobileNavigationButton-title">{title}</span>
      </button>
    );
  }
}

export default MobileNavigationButton;
