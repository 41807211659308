import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import Input from 'modules/form/components/input/input';
import IconContainer from 'modules/core/components/icon-container/icon-container';
import isFunction from 'modules/utils/is-function';

import Option from './dropdown-option';

import { mapSelectSizeToIcon } from './helpers';

import './dropdown-select.css';

class DropdownSelect extends Component {
  static Option = Option;

  static propTypes = {
    /** Propvided options */
    children: PropTypes.node,
    /** Displayed icon when no options selected */
    placeholderIcon: PropTypes.node,
    /** Provide options filtration when `true` */
    withFilter: PropTypes.bool,
    /** Size of component */
    size: PropTypes.oneOf(['small', 'normal']),

    isOpened: PropTypes.bool,
    filterQuery: PropTypes.string,
    selected: PropTypes.number,
    focusIn: PropTypes.bool,
    error: PropTypes.string
  };

  static defaultProps = {
    children: null,
    placeholderIcon: null,
    withFilter: false,
    size: 'normal'
  };

  render() {
    const { size, isOpened } = this.props;

    return (
      <div
        className={cn('DropdownSelect', `DropdownSelect--size-${size}`, {
          'DropdownSelect--isOpened': isOpened
        })}
      >
        {this.renderCurrent()}
        {isOpened && this.renderOptionList()}
      </div>
    );
  }

  renderCurrent() {
    const { withFilter, children, inputValue, autoFocus, ...rest } = this.props;

    const { handleInputChange, handleMouseDown, handleInputFocus, handleInputBlur } = this;

    delete rest.onChange;
    delete rest.placeholderIcon;
    delete rest.onFilterQueryChange;
    delete rest.defaultValue;
    delete rest.onSelect;
    delete rest.onFocusIn;
    delete rest.onFocusOut;
    delete rest.onOpen;
    delete rest.onClose;
    delete rest.onBlur;
    delete rest.onFocus;
    delete rest.cityTitleList;
    delete rest.filterQuery;
    delete rest.isOpened;

    return (
      <div className="DropdownSelect-current">
        <div className="DropdownSelect-input">
          <Input
            {...rest}
            value={inputValue}
            selectable={!withFilter}
            onMouseDown={handleMouseDown}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            prepended={this.renderCurrentIcon()}
            autoFocus={Boolean(autoFocus)}
          />
        </div>
      </div>
    );
  }

  renderCurrentIcon() {
    const { size } = this.props;
    const currentIcon = this.getCurrentIcon();

    if (!currentIcon) return null;

    return (
      <div className="DropdownSelect-currentIcon">
        <IconContainer size={mapSelectSizeToIcon(size)}>{currentIcon}</IconContainer>
      </div>
    );
  }

  renderOptionList() {
    const { children, error } = this.props;

    return (
      <div className="DropdownSelect-bottom">
        <div className="DropdownSelect-optionListHolder">
          <Scrollbars autoHeight>
            <ul className="DropdownSelect-optionList">
              {error && (
                <li className="DropdownSelect-option">
                  <span className="DropdownSelect-optionError">{error}</span>
                </li>
              )}
              {Children.map(children, (child, index) => (
                <li className="DropdownSelect-option">{this.renderOption(child, index)}</li>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    );
  }

  renderOption(option, index) {
    const { size, selected } = this.props;

    return cloneElement(option, {
      size,
      active: selected === index,
      onClick: this.handleOptionClick(index),
      onMouseUp: this.handleOptionClick(index),
      onTouchEnd: this.handleOptionClick(index),
      onFocus: this.handleOptionFocus,
      onBlur: this.handleOptionBlur
    });
  }

  getCurrentIcon() {
    const { placeholderIcon, children, selected } = this.props;

    if (!children || !children[selected]) {
      return null;
    }

    if (selected !== false) {
      return children[selected].props.icon;
    }

    if (placeholderIcon) {
      return placeholderIcon;
    }

    return null;
  }

  handleInputChange = e => {
    const { onFilterQueryChange } = this.props;
    const { value } = e.target;

    if (!isFunction(onFilterQueryChange)) {
      return;
    }

    onFilterQueryChange(value);
  };

  handleMouseDown = e => {
    const { withFilter, isOpened, onOpen, onClose } = this.props;

    if (isOpened && !withFilter) {
      if (isFunction(onClose)) {
        // onClose();
      }
    } else {
      if (isFunction(onOpen)) {
        onOpen();
      }
    }
  };

  handleInputFocus = e => {
    const { onFocusIn, onOpen } = this.props;

    if (isFunction(onFocusIn)) {
      onFocusIn();
    }

    if (isFunction(onOpen)) {
      onOpen();
    }
  };

  handleInputBlur = e => {
    const { onFocusOut, onBlur } = this.props;

    if (isFunction(onFocusOut)) {
      onFocusOut();
    }

    if (isFunction(onBlur)) {
      onBlur();
    }
  };

  handleOptionClick = index => e => {
    const { onSelect, onClose } = this.props;

    onSelect(index);

    if (isFunction(onClose)) {
      onClose();
    }
  };

  handleOptionFocus = () => {
    const { onFocusIn } = this.props;

    if (isFunction(onFocusIn)) {
      onFocusIn();
    }
  };

  handleOptionBlur = () => {
    const { onFocusOut, onBlur } = this.props;

    if (isFunction(onFocusOut)) {
      onFocusOut();
    }

    if (isFunction(onBlur)) {
      onBlur();
    }
  };
}

export default DropdownSelect;
