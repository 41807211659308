import React, {useState} from "react";

import CountDown from 'modules/core/components/count-down/count-down';
import CountdownTimer from 'modules/core/components/count-down-timer/count-down-timer';


function PageCounter({endDate}) {
    const [expiredTime, setExpiredTime] = useState({});
    const [isExpired, setIsExpired] = useState(false);

    if(isExpired) {
        return null;
    }

    return (
        <div className="PageCounter">
         <div className="PageCounter-countDown">
            <CountDown
              expireTime={expiredTime}
              detalized
              fontSizeInherited
              theme="inBanner"
              fullUnit
            />
            <CountdownTimer 
                expireDate={endDate} 
                onTick={handleTick} 
                onExpire={handleExpire} />
          </div>
        </div>
    )

    function handleTick(expiredTime) {
        setExpiredTime(expiredTime);
      }
      function handleExpire() {
        setIsExpired(true);
        // onExpire(index);
      }
}

export default PageCounter;
