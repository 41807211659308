import React, { Component } from 'react';
import Media from 'react-media';

import InstagramPost from '../components/instagram-post/instagram-post';

class InstagramPostContainer extends Component {
  render() {
    const { squareImage, post } = this.props;

    return (
      <Media query="(max-width: 840px)">
        {isMobile => (
          <InstagramPost instagramReview={post} isMobile={isMobile} squareImage={squareImage} />
        )}
      </Media>
    );
  }
}

export default InstagramPostContainer;
