import React, { Component } from 'react';
import './icon-check.css';

class IconCheck extends Component {
  render() {
    return <span className="IconCheck" />;
  }
}

export default IconCheck;
