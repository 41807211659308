import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from 'modules/form/components/input/input';
import { IconPlus, IconMinus } from 'modules/core/components/icons';

// import throttle from '../../../utils/throttle';

import './amount.css';

const REPEAT_DELAY = 400;
const REPEAT_INTERVAL = 100;

const KEY_ARROW_UP = 38;
const KEY_ARROW_DOWN = 40;
const KEY_ENTER = 13;

const MOUSE_BUTTON_LEFT = 0;

const { number, func, oneOf } = PropTypes;

class Amount extends Component {
  static propTypes = {
    /**
     * Quantity of goods in the basket
     */
    value: number.isRequired,

    /**
     * Counter change function
     */
    onChange: func.isRequired,

    /**
     * Adjust input size. The default state is normal
     */
    size: oneOf(['normal', 'small']),

    /**
     * Minimum value
     */
    min: number,

    /**
     * Maximum value
     */
    max: number
  };

  static defaultProps = {
    size: 'normal',
    min: 1,
    max: 100
  };

  static defaultProps = {
    min: 1,
    max: 100
  };

  state = {
    isInputFocused: false,
    isAmountFocused: false
  };

  render() {
    const { value, size, disabled, withoutStroke } = this.props;
    const { /* isAmountFocused, */ isInputFocused } = this.state;
    const stringWidth = this.getStringWidth(value);

    return (
      <div
        className={classNames('Amount', {
          'Amount--isInputFocused': isInputFocused
        })}
        style={{ width: `${stringWidth}px` }}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleAmountFocus}
        onBlur={this.handleAmountBlur}
        ref={this.handleAmountRef}
      >
        <Input
          type="text"
          size={size}
          value={value}
          disabled={disabled}
          // isFocused={isAmountFocused}
          prepended={this.renderPrepended()}
          appended={this.renderAppended()}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          inputRef={this.handleInputRef}
          tabIndex={-1}
          withoutStroke={withoutStroke}
        />
      </div>
    );
  }

  componentDidMount() {
    this.amountElement.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    this.amountElement.removeEventListener('mouseup', this.handleMouseUp);
    this.dummy.parentNode.removeChild(this.dummy);
  }

  getStringWidth(value) {
    let styles = {
      display: 'inline-block',
      position: 'absolute',
      top: '-1000px',
      left: '-1000px',
      zIndex: '-1',
      visibility: 'hidden',
      whiteSpace: 'nowrap',
      width: 'auto'
    };

    const textSizeValuableProperties = [
      'border-left-style',
      'border-left-width',
      'border-right-style',
      'border-right-width',
      'box-sizing',
      'font-family',
      'font-size',
      'font-stretch',
      'font-style',
      'font-variant',
      'font-wight',
      'letter-spacing',
      'padding-left',
      'padding-right',
      'white-space'
    ];

    if (!this.dummy) {
      this.dummy = document.createElement('span');
      this.dummy.setAttribute('aria-hidden', true);
      Object.entries(styles).map(([key, value]) => (this.dummy.style[key] = value));
      document.body.appendChild(this.dummy);
    }

    this.dummy.innerHTML = value;

    if (this.inputElement) {
      const inputComputedStyle = window.getComputedStyle(this.inputElement);

      Array.from(textSizeValuableProperties).forEach(property => {
        this.dummy.style.setProperty(
          property,
          inputComputedStyle.getPropertyValue(property),
          inputComputedStyle.getPropertyPriority(property)
        );
      });
    }

    const { width } = this.dummy.getBoundingClientRect();
    return Math.ceil(width + 1);
  }

  renderPrepended() {
    const { withoutStroke } = this.props;
    const isDecreaseAllowed = this.isDecreaseAllowed();

    return (
      <button
        type="button"
        className="Amount-button"
        disabled={!isDecreaseAllowed}
        onMouseDown={this.handleDecreaseMouseDown}
        onMouseLeave={this.handleDecreaseMouseLeave}
        tabIndex={-1}
      >
        {withoutStroke ? (
          <div className="Amount-buttonStroke">
            <IconMinus />
          </div>
        ) : (
          <IconMinus />
        )}
      </button>
    );
  }

  renderAppended() {
    const { withoutStroke } = this.props;
    const isIncreaseAllowed = this.isIncreaseAllowed();

    return (
      <button
        type="button"
        className="Amount-button"
        disabled={!isIncreaseAllowed}
        onMouseDown={this.handleIncreaseMouseDown}
        onMouseLeave={this.handleIncreaseMouseLeave}
        tabIndex={-1}
      >
        {withoutStroke ? (
          <div className="Amount-buttonStroke">
            <IconPlus />
          </div>
        ) : (
          <IconPlus />
        )}
      </button>
    );
  }

  getSidePaddings() {
    const { size } = this.props;
    const bigSidePadding = 50;
    const smallSidePadding = 40;
    if (size === 'small') {
      return smallSidePadding * 2;
    }
    return bigSidePadding * 2;
  }

  isIncreaseAllowed() {
    const { value, max, disabled } = this.props;
    return value < max && !disabled;
  }

  isDecreaseAllowed() {
    const { value, min, disabled } = this.props;
    return value > min && !disabled;
  }

  decrease() {
    const { value, min } = this.props;
    if (value > min) {
      this.onChange(value - 1);
    }
  }

  increase() {
    const { value, max } = this.props;
    if (value < max) {
      this.onChange(value + 1);
    }
  }

  apply() {
    this.amountElement.focus();
    this.exitEditing();
  }

  exitEditing() {
    this.setState({
      isInputFocused: false,
      isAmountFocused: true
    });
  }

  startRepeat = (action, delay = REPEAT_DELAY, interval = REPEAT_INTERVAL) => {
    this.stopRepeat();
    this.repeatDelay = setTimeout(this.doRepeat(action, interval), delay);
  };

  doRepeat = (action, interval) => () => {
    this.repeatInterval = setInterval(action, interval);
  };

  stopRepeat = () => {
    clearTimeout(this.repeatDelay);
    clearInterval(this.repeatInterval);
  };

  onChange(value) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      const number = this.fitInLimits(value);

      onChange(number);
    }
  }

  fitInLimits(value) {
    const { max, min } = this.props;

    if (value > max) {
      return max;
    }
    if (value < min) {
      return min;
    }

    return value;
  }

  handleAmountFocus = () => {
    this.setState({
      isAmountFocused: true
    });
  };

  handleAmountBlur = () => {
    this.setState({
      isAmountFocused: false
    });
  };

  handleInputFocus = () => {
    this.setState({
      isInputFocused: true
    });
  };

  handleInputBlur = () => {
    this.setState({
      isInputFocused: false
    });
  };

  handleInputChange = e => {
    const { value } = e.target;
    const { min } = this.props;

    let number = min;

    const parsed = parseInt(value, 10);

    if (!isNaN(parsed)) {
      number = parsed;
    }

    this.onChange(number);
  };

  handleKeyDown = e => {
    if (e.keyCode === KEY_ENTER) {
      e.preventDefault();
      this.apply();
    }

    if (e.keyCode === KEY_ARROW_UP) {
      e.preventDefault();
      this.increase();
    }

    if (e.keyCode === KEY_ARROW_DOWN) {
      e.preventDefault();
      this.decrease();
    }
  };

  handleIncreaseMouseDown = e => {
    if (e.button !== MOUSE_BUTTON_LEFT) {
      return;
    }
    this.increase();
    this.startRepeat(() => this.increase());
  };

  handleIncreaseMouseLeave = e => {
    this.stopRepeat();
  };

  handleDecreaseMouseDown = e => {
    this.decrease();
    this.startRepeat(() => this.decrease());
  };

  handleDecreaseMouseLeave = e => {
    this.stopRepeat();
  };

  handleMouseUp = e => {
    this.stopRepeat();
  };

  handleInputRef = element => {
    if (!element) {
      return;
    }

    this.inputElement = element;
    this.forceUpdate();
  };

  handleAmountRef = element => {
    this.amountElement = element;
  };

  handleApplyButtonClick = () => {
    this.setState({
      isInputFocused: false
    });
  };
}

export default Amount;
