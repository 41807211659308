import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getProductLink } from 'routes/links';

import Price from 'modules/ordering/components/price-formatted/price-formatted';
import Media from 'modules/core/components/media/media';
import ProductAnalyticsProvider from 'modules/product/containers/product-analytics';

import './recent-product.css';

class RecentProduct extends Component {
  render() {
    const { product, onClick } = this.props;
    return (
      <ProductAnalyticsProvider
        productId={product.slug}
        listId="search-field-recent"
        render={({ productRef, handleClick }) => (
          <div className="SearchOverlay_RecentProduct" ref={productRef}>
            <div className="SearchOverlay_RecentProduct-media">
              <Media
                media={product.announcementMedia}
                resizeWidth={80}
                stretch="horizontal"
                nocaption
              />
            </div>

            <div className="SearchOverlay_RecentProduct-text">
              <Link
                to={getProductLink(product)}
                className="SearchOverlay_RecentProduct-description"
                onClick={onClick}
              >
                {product.description}
              </Link>
              <div className="SearchOverlay_RecentProduct-title">{product.title}</div>
            </div>

            <div className="SearchOverlay_RecentProduct-price">
              {product.hasDiscount && <Price number={product.price.default} variant="previous" />}{' '}
              <Price
                number={product.price.current}
                variant={product.hasDiscount ? 'discount' : 'default'}
              />
            </div>
          </div>
        )}
      />
    );
  }
}

export default RecentProduct;
