import { createReducer, createAction, handle, createActionError } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import * as fromDeliveryMethodTable from './delivery-method-table';
import * as CITY_DELIVERY from './types/city-delivery';
import * as ORDER from './types/orders';
// import * as PROFILE from 'modules/profile/types/profile';
// import * as ACCOUNT from 'modules/core/ducks/types/account';
// import * as DeliveryMethod from '../domain/delivery-method';
import LOADING_STATE from 'modules/core/constants/loading-state';

export const actions = {
  request(fiasId) {
    return createAction(CITY_DELIVERY.REQUEST, { fiasId });
  },
  requestByGeonameId(geonameId) {
    return createAction(CITY_DELIVERY.REQUEST, { geonameId });
  },
  response(fiasId, delivery, idList) {
    return createAction(CITY_DELIVERY.RESPONSE, { fiasId, delivery, idList });
  },
  responseByGeonameId(geonameId, delivery, idList) {
    return createAction(CITY_DELIVERY.RESPONSE, {
      geonameId,
      delivery,
      idList
    });
  },
  responseError(fiasId, message) {
    return createActionError(CITY_DELIVERY.RESPONSE, { fiasId, message });
  },
  responseErrorByGeonameId(geonameId, message) {
    return createActionError(CITY_DELIVERY.RESPONSE, { geonameId, message });
  },

  pickPointRequest(cityFiasId, deliveryMethod) {
    return createAction(CITY_DELIVERY.PICK_POINT_REQUEST, {
      cityFiasId,
      deliveryMethodId: deliveryMethod.id,
      deliveryMethodName: deliveryMethod.name,
      pickPointType: deliveryMethod.pickPointType
    });
  },

  pickPointResponse(cityFiasId, deliveryMethodId, pickPoints) {
    return createAction(CITY_DELIVERY.PICK_POINT_RESPONSE, {
      cityFiasId,
      deliveryMethodId,
      pickPoints
    });
  }
};

const reducer = createReducer(
  handle(CITY_DELIVERY.REQUEST, (state, { fiasId }) => {
    return {
      ...state,
      [fiasId]: {
        methods: [],
        _loading: LOADING_STATE.LOADING
      }
    };
  }),

  handle(CITY_DELIVERY.RESPONSE, (state, { fiasId, geonameId, idList }, error) => {
    if (error) {
      return {
        ...state,
        [fiasId || geonameId]: {
          methods: [],
          _loading: LOADING_STATE.FAILED
        }
      };
    }

    return {
      ...state,
      [fiasId || geonameId]: {
        methods: idList,
        _loading: LOADING_STATE.LOADED
      }
    };
  }),

  // handle(ORDER.SUCCESS, (state, { order }) => {
  //   const { deliveryMethod, geography } = order.delivery;
  //   const { city } = geography;

  //   const methods = (state[city.fiasId] || {}).methods || [];

  //   if (methods.indexOf(deliveryMethod.id) !== -1) {
  //     return state;
  //   }

  //   return {
  //     ...state,
  //     [city.fiasId]: {
  //       ...state[city.fiasId],
  //       methods: [...methods, deliveryMethod.id]
  //     }
  //   };
  // }),

  handle(ORDER.UPDATE_FROM_SERVER, (state, { order }) => {
    if (!order.delivery) {
      return state;
    }

    const { deliveryMethod, geography } = order.delivery;
    if (!deliveryMethod || !geography) {
      return state;
    }

    const { city } = geography;
    if (!city) {
      return state;
    }

    const methods = (state[city.fiasId] || {}).methods || [];

    if (methods.indexOf(deliveryMethod.id) !== -1) {
      return state;
    }

    return {
      ...state,
      [city.fiasId]: {
        ...state[city.fiasId],
        methods: [...methods, deliveryMethod.id]
      }
    };
  })

  // handle(
  //   CITY_DELIVERY.PICK_POINT_REQUEST, // FIXME
  //   (state, { cityFiasId, deliveryMethodId }) => {
  //     const methods = (state[cityFiasId] || {}).methods || {};

  //     if (
  //       methods[deliveryMethodId].pickPoints &&
  //       methods[deliveryMethodId].pickPoints.length > 0
  //     ) {
  //       return state;
  //     }

  //     return {
  //       ...state,
  //       [cityFiasId]: {
  //         ...state[cityFiasId],
  //         methods: {
  //           ...methods,
  //           [deliveryMethodId]: {
  //             ...methods[deliveryMethodId],
  //             isLoaded: false
  //           }
  //         }
  //       }
  //     };
  //   }
  // ),

  // handle(
  //   CITY_DELIVERY.PICK_POINT_RESPONSE, // FIXME
  //   (state, { cityFiasId, deliveryMethodId, pickPoints }) => {
  //     const methods = (state[cityFiasId] || {}).methods || {};

  //     const pickPointResult = pickPoints ? pickPoints.result : null;

  //     return {
  //       ...state,
  //       [cityFiasId]: {
  //         ...state[cityFiasId],
  //         methods: {
  //           ...methods,
  //           [deliveryMethodId]: {
  //             ...methods[deliveryMethodId],
  //             pickPoints: pickPointResult,
  //             isLoaded: true
  //           }
  //         }
  //       }
  //     };
  //   }
  // )
);

export default reducer({});

const getAll = state => state.ordering.cityDelivery;

export const getMethodListByCityId = createSelector(
  getAll,
  fromDeliveryMethodTable.getFindItemById,
  (all, findDeliveryMethodById) => cityId => {
    if (!cityId) {
      return null;
    }

    const cityDelivery = all[cityId];

    if (!cityDelivery || !cityDelivery.methods) {
      return null;
    }

    const deliveryMethodsByCity = cityDelivery.methods.map(findDeliveryMethodById).filter(Boolean);

    return deliveryMethodsByCity;
  }
);

export const getLoadingStateByCityId = createSelector(getAll, function _getLoadingStateByCityId(
  all
) {
  return cityId => {
    if (!cityId || !all) {
      return LOADING_STATE.NOT_ASKED;
    }
    const cityDelivery = all[cityId];
    if (!cityDelivery || !cityDelivery._loading) {
      return LOADING_STATE.NOT_ASKED;
    }
    return cityDelivery._loading;
  };
});
