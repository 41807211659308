import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FreeDeliveryNotification from '../components/free-delivery-notification/free-delivery-notification';

import * as fromLocation from 'modules/geography/ducks/location';
import * as fromShopList from 'modules/core/ducks/shop-list';

const mapStateToProps = createStructuredSelector({
  freeDeliveryFrom: fromLocation.getFreeDeliveryFrom,
  isBelarusOrKazahstan: fromShopList.getIsBelarusOrKazahstan,
});

class FreeDeliveryNotificationContainer extends Component {
  render() {
    const { freeDeliveryFrom,  isBelarusOrKazahstan} = this.props;

    if(isBelarusOrKazahstan) {
      return null;
    }
    return <FreeDeliveryNotification freeDeliveryFrom={freeDeliveryFrom} />;
  }
}

export default connect(mapStateToProps)(FreeDeliveryNotificationContainer);
