import { combineReducers } from 'redux';
// import { createSelector } from 'reselect';

// import { getBlogModule } from '../../../store';

import articleList from './article-list';
import videoList from './video-list';
import playList from './play-list';
import lastArticleList from './last-article-list';
import featuredArticleList from './featured-article-list';

/* REDUCERS EXPORT */

export default combineReducers({
  articleList,
  lastArticleList,
  videoList,
  playList,
  featuredArticleList
});

// const getArticleList = state => state.blog.articleList;

// export const getArticleList = createSelector(
//   getBlogModule,
//   blogModule => blogModule.articleList
// );
