import React, { Component } from 'react';
import './call-to-registration.css';
import CheckedList from '../checked-list/checked-list';
import Button from '../button/button';

class CallToRegistration extends Component {
  render() {
    const { onRegistrationOpen } = this.props;
    return (
      <div className="CallToRegistration">
        <p className="CallToRegistration-checkedListTitle">
          Преимущества зарегистрированных пользователей
        </p>
        <div className="CallToRegistration-checkedList">
          <CheckedList
            list={[
              'Получение информации о новинках и акциях',
              'Бонусы постоянным покупателям',
              'Просмотр истории заказов в личном кабинете'
            ]}
            iconColor="yellow"
          />
        </div>
        <div className="CallToRegistration-footer">
          <Button onClick={onRegistrationOpen} title="Зарегистрироваться" />
        </div>
      </div>
    );
  }
}

export default CallToRegistration;
