import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dadata from 'services/dadata';
import AutocompleteProvider from 'modules/form/components/autocomplete/autocomplete-provider';
import debounce from 'modules/utils/debounce';

const dadata = new Dadata(process.env.REACT_APP_DADATA_API_KEY);

class DadataBuildingSuggest extends Component {
  static propTypes = {
    /* FIAS city id */
    city: PropTypes.string.isRequired
  };

  state = {
    query: this.props.defaultQuery || '',
    suggestions: [],
    isLoading: false,
    isValid: false
  };

  fetchSuggestions = debounce(this._fetchSuggestions, 200);

  render() {
    const { ...rest } = this.props;
    const { query, suggestions, isLoading } = this.state;

    return (
      <AutocompleteProvider
        onFilter={this.handleFilter}
        // onChange={this.handleChange}

        query={query}
        options={suggestions}
        selectTitle={option => option.title}
        // active={}
        isLoading={isLoading}
        {...rest}
      />
    );
  }

  componentDidMount() {
    if (this.props.defaultQuery) {
      this.filter(this.props.defaultQuery);
    }
  }

  _fetchSuggestions(query) {
    this.abortPreviousRequest();

    const { city } = this.props;

    const dadataPromise = dadata.street(city, query);

    this._request = dadataPromise.request;

    dadataPromise.then(this.handleSuggestionsResponse).catch(this.handleFailure);
  }

  filter(query) {
    this.setState({
      query
    });

    const { city } = this.props;

    if (!city || !city.length) {
      return;
    }

    this.startLoading();
    this.fetchSuggestions(query);
  }

  abortPreviousRequest() {
    if (!this._request || this._request.isReady()) {
      return;
    }

    this._request.abort();
  }

  startLoading() {
    this.setState({
      isLoading: true
    });
  }

  transformDadata(data) {
    return data.suggestions.map(item => {
      return {
        id: item.data.fias_id,
        streetFiasId: item.data.street_fias_id,
        street: item.data.street,
        streetType: item.data.street_type_full,
        house: item.data.house,
        houseType: item.data.house_type_full,
        block: item.data.block,
        blockType: item.data.block_type_full,
        title: item.value
      };
    });
  }

  handleSuggestionsResponse = data => {
    const suggestions = this.transformDadata(data);

    this.setState({
      suggestions,
      isLoading: false
    });
  };

  handleFailure = error => {
    this.setState({
      isLoading: false
    });

    console.error(error);
  };

  handleFilter = query => {
    this.filter(query);
  };

  handleChange = data => {
    console.log('changed to', data.value);
  };
}

export default DadataBuildingSuggest;
