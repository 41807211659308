import React, { Component } from 'react';

import Svg from '../svg/svg';

import './icon-youtube.css';

class IconYoutube extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconYoutube">
            <path d="M18.7 29.39c-3.34 0-7-.11-10.81-.34a4.77 4.77 0 0 1-4.27-4.6 66.6 66.6 0 0 1 0-12.91 4.8 4.8 0 0 1 4.26-4.6 151.27 151.27 0 0 1 20.22 0c2.29.15 4 2 4.27 4.6a66.76 66.76 0 0 1 0 12.91 4.76 4.76 0 0 1-4.26 4.6c-2.9.23-6.07.34-9.41.34zm-.78-21.56c-3.33 0-6.68.11-10 .34a3.6 3.6 0 0 0-3.21 3.51 65.3 65.3 0 0 0 0 12.65A3.57 3.57 0 0 0 8 27.83c3.81.22 7.43.34 10.75.34s6.45-.11 9.33-.34a3.61 3.61 0 0 0 3.22-3.51 65.31 65.31 0 0 0 0-12.65 3.6 3.6 0 0 0-3.3-3.5c-3.38-.22-6.79-.34-10.12-.34zm-2.8 5.84v8.4l7.44-4.17z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}

export default IconYoutube;
