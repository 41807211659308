import formatNumber from './format-number';

const formatDateFromObject = dateObject => {
  const day = formatNumber(dateObject.day);
  const month = formatNumber(dateObject.month);
  const year = dateObject.year;

  return day + '.' + month + '.' + year;
};

export default formatDateFromObject;
