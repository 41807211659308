import React from 'react';
import Container from 'modules/core/components/container/container';
import FAQList from './faq-list/faq-list';
import FAQNavigation from './faq-navigation/faq-navigation';
import FAQAction from './faq-action/faq-action';
import { IconMail } from 'modules/core/components/icons';

import './faq-section.css';

export default function FAQSection({ list, openFAQQuestion, isMobile }) {
  return (
    <div className="FAQ_Section">
      <div className="FAQ_Section-holder">
        <div className="FAQ_Section-navigation">
          <FAQNavigation list={list} isMobile={isMobile} />
        </div>
        <div className="FAQ_Section-main">
          <Container>
            <div className="FAQ_Section-mainInner">
              <div className="FAQ_Section-actions">
                <div className="FAQ_Section-actionsHolder">
                  <div className="FAQ_Section-action">
                    <FAQAction
                      callback={openFAQQuestion}
                      title="Остались вопросы, напиши нам"
                      icon={<IconMail />}
                    />
                  </div>
                </div>
              </div>
              <div className="FAQ_Section-content">
                <div className="FAQ_Section-list">
                  <FAQList list={list} />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
