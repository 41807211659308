import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../input/input';

import './fieldset-address.css';

const { string, func } = PropTypes;

class FieldsetAddress extends Component {
  static propTypes = {
    /**
     * Delivery address street
     */
    street: string,

    /**
     * Delivery address house
     */
    house: string,

    /**
     * Delivery address apartment
     */
    apartment: string,

    /**
     * Delivery address street changing function
     */
    onStreetChange: func.isRequired,

    /**
     * Delivery address house changing function
     */
    onHouseChange: func.isRequired,

    /**
     * Delivery address apartment changing function
     */
    onApartmentChange: func.isRequired
  };
  render() {
    const { street, house, apartment } = this.props;
    return (
      <div className="FieldsetAddress">
        <div className="FieldsetAddress-streetCol">
          <Input
            placeholder={this.getRequiredInputLabel('Улица')}
            value={street}
            type="text"
            size="small"
          />
        </div>
        <div className="FieldsetAddress-houseCol">
          <Input
            placeholder={this.getRequiredInputLabel('Дом/Корпус')}
            value={house}
            type="text"
            size="small"
          />
        </div>
        <div className="FieldsetAddress-apartmentCol">
          <Input placeholder="Квартира/Офис" value={apartment} type="text" size="small" />
        </div>
      </div>
    );
  }

  renderNoteSign() {
    return String.fromCharCode(42);
  }

  getRequiredInputLabel(label) {
    return label + this.renderNoteSign();
  }

  handleStreetChange = e => {
    const { onStreetChange } = this.props;
    onStreetChange(e.target.value);
  };

  handleHouseChange = e => {
    const { onHouseChange } = this.props;
    onHouseChange(e.target.value);
  };

  handleApartmentChange = e => {
    const { onApartmentChange } = this.props;
    onApartmentChange(e.target.value);
  };
}

export default FieldsetAddress;
