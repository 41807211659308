import React from 'react';
import cn from 'classnames';

// import Media from 'modules/core/components/media/media';
import CircleBorderedImage from '../circle-bordered-image/circle-bordered-image';

import './opening-action.css';

export default function OpeningAction({ inverted, action, colors, light }) {
  return (
    <div
      className={cn('OpeningAction', {
        'OpeningAction--inverted': inverted,
        'OpeningAction--light': light
      })}
    >
      <div className="OpeningAction-imageHolder">
        <div className="OpeningAction-image">
          {/* <Media media={action.media} alt="" stretch="horizontal" nocaption /> */}
          <CircleBorderedImage image={action.media} colors={colors} inverted={inverted} />
        </div>
      </div>
      <div className="OpeningAction-title">{action.title}</div>
    </div>
  );
}
