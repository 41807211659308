import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Loader from 'modules/core/components/loader/loader';
import isFunction from 'modules/utils/is-function';
import Tile from 'modules/core/components/tile/tile';
// import EditUserName from 'modules/core/components/edit-user-name/edit-user-name';
import EditUserNameByModal from 'modules/core/components/edit-user-name/edit-user-name-by-modal/edit-user-name-by-modal';

import ProfileAvatar from '../profile-avatar/profile-avatar';

import './general-tile.css';

const { string, func } = PropTypes;

class GeneralTile extends Component {
  static propTypes = {
    /** ссылка на фото */
    photo: string,
    /** Имя пользователя */
    name: string,
    /** email пользователя */
    email: string,
    /** Обработчик изменения имени */
    onChangeName: func,
    /** Обработчик изменения фото */
    onChangePhoto: func
  };

  state = {
    noLoadPhoto: false
  };

  textInput = null;

  render() {
    const { name, firstName, lastName, photo, email, isLoading, openUserNameDialog } = this.props;

    return (
      <Tile>
        <div
          className={cn('GeneralTile', {
            'GeneralTile-noLoadPhoto': this.state.noLoadPhoto
          })}
        >
          <div className="GeneralTile-avatarEditor">
            <div className="GeneralTile-avatarHolder" onClick={this.handleClick}>
              <div className="GeneralTile-avatar">
                <ProfileAvatar
                  name={name}
                  photo={photo}
                  resizeWidth={150}
                  resizeHeight={150}
                  onError={this.handleError}
                  onLoad={this.handleLoad}
                />
              </div>

              <input
                // accept="image/*"
                accept=".png, .jpg, .jpeg"
                type="file"
                className="GeneralTile-uploadInput"
                ref={this.setInputRef}
                onChange={this.handleChangePhoto}
              />
              <div className="GeneralTile-avatarEditorOverlay">
                {photo ? 'Обновить' : 'Загрузить'}
              </div>
            </div>
          </div>
          <div className="GeneralTile-info">
            {isLoading ? (
              <Loader type="spin" size="small" secondary />
            ) : (
              <Fragment>
                {name && (
                  <div className="GeneralTile-name">
                    {/* <EditUserName value={name} onChange={this.handleChangeName} /> */}
                    <EditUserNameByModal 
                      name={name} 
                      firstName={firstName}
                      lastName={lastName}
                      openUserNameDialog={openUserNameDialog} />
                  </div>
                )}
                {email && (
                  <div className="GeneralTile-login">
                    <div className="GeneralTile-loginTitle">E-mail</div>
                    <div className="GeneralTile-loginData">{email}</div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Tile>
    );
  }
  handleClick = () => {
    this.textInput.click();
  };

  handleChangeName = name => {
    const { onChangeName } = this.props;

    if (!isFunction(onChangeName)) {
      return;
    }
    onChangeName(name);
  };

  handleChangePhoto = e => {
    const { onChangePhoto } = this.props;
    const newPhoto = e.target.files[0];

    if (!isFunction(onChangePhoto)) {
      return;
    }
    onChangePhoto(newPhoto);
  };

  setInputRef = element => {
    this.textInput = element;
  };

  handleError = () => {
    this.setState({
      noLoadPhoto: true
    });
  };
  handleLoad = () => {
    this.setState({
      noLoadPhoto: false
    });
  };
}

export default GeneralTile;
