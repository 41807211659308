import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const { string, bool } = PropTypes;

class ColorSwitchOption extends Component {
  static propTypes = {
    /** значение цвета */
    color: string.isRequired,
    /** параметр который стилизует внешний вид опции на которую нельзя переключиться */
    disabled: bool,
    /**параметр который стилизует внешний вид активной (выбранной) опции */
    checked: bool
  };

  static defaultProps = {
    disabled: false,
    checked: false
  };

  render() {
    const { color, disabled, checked, ...rest } = this.props;
    return (
      <div
        className={classNames('ColorSwitch-option', {
          'ColorSwitch-option--disabled': disabled,
          'ColorSwitch-option--checked': checked
        })}
        style={{ color }}
        {...rest}
      />
    );
  }
}
export default ColorSwitchOption;
