import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../input/input';
import IconEye from 'modules/core/components/icons/icon-eye/icon-eye';
import IconCloseEye from 'modules/core/components/icons/icon-close-eye/icon-close-eye';
import Button from 'modules/core/components/button/button';

import './password-input.css';

const { string } = PropTypes;

const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password'
};

class PasswordInput extends Component {
  state = {
    isHidden: this.props.type === INPUT_TYPES.PASSWORD
  };
  static propTypes = {
    type: string
  };

  static defaultProps = {
    type: INPUT_TYPES.PASSWORD
  };

  render() {
    const { ...rest } = this.props;
    const { isHidden } = this.state;

    return (
      <div className="PasswordInput">
        <Input
          appended={this.renderTrigger()}
          {...rest}
          type={isHidden ? INPUT_TYPES.PASSWORD : INPUT_TYPES.TEXT}
        />
      </div>
    );
  }

  renderTrigger() {
    const { isHidden } = this.state;
    const { size } = this.props;
    return (
      <div className="PasswordInput-trigger">
        <Button
          variant="secondary"
          flat
          icon={isHidden ? <IconCloseEye /> : <IconEye />}
          onClick={this.handleTrigger}
          size={size}
          // disabled={disabled}
        />
      </div>
    );
  }

  handleTrigger = () => {
    this.setState(state => ({
      isHidden: !state.isHidden
    }));
  };
}

export default PasswordInput;
