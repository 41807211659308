import React, { Component } from 'react';
import { number, bool } from 'prop-types';
import { ToastConsumer } from 'modules/core/containers/toast-stack';

class ToastInner extends Component {
  componentDidMount() {
    if (this.props.show) {
      this.props.create({
        toast: this.props.toastProps
      });
    } else {
      this.props.close(this.props.toastProps.id);
    }
  }

  render() {
    return null;
  }
}

class Toast extends Component {
  static propTypes = {
    id: number.isRequired,
    show: bool.isRequired
  };

  render() {
    const { show, ...toastProps } = this.props;

    return (
      <ToastConsumer key={show}>
        {({ create, close }) => (
          <ToastInner
            key={show}
            show={show}
            create={create}
            close={close}
            toastProps={toastProps}
          />
        )}
      </ToastConsumer>
    );
  }
}

export default Toast;
