import PropTypes from 'prop-types';
import mediaShape from 'modules/product/prop-types/media-shape';

let { number, shape, string, arrayOf } = PropTypes;

export default shape({
  id: number.isRequired,
  title: string.isRequired,
  label: string,
  description: string,
  background: arrayOf(mediaShape)
});
