import React, { Component } from 'react';
// import cn from 'classnames';
import Dialog from '../dialog/dialog';
import Button from '../button/button';
import { Form /*,  Field, FieldsetChoice, Radio */ } from 'modules/form';

import './acquaintance-dialog.css';
// import PriceFormatted from '../../../ordering/components/price-formatted/price-formatted';
// import BodyImage from './body-image';
// import FaceImage from './face-image';
// import HairImage from './hair-image';

class AcquaintanceDialog extends Component {
  state = {
    current: 'body',
    done: false
  };

  render() {
    const { ...dialogProps } = this.props;
    // const { current, done } = this.state;

    // const showBody = current === 'body';
    // const showFace = current === 'face';
    // const showHair = current === 'hair';

    return (
      <Dialog {...dialogProps} closeable>
        <div className="AcquaintanceDialog">
          {/* <div className="AcquaintanceDialog-welcome">
            Добро пожаловать, %username%!
          </div> */}
          {/* <h2 className="AcquaintanceDialog-title">Давай знакомиться!</h2> */}
          <h2 className="AcquaintanceDialog-title">Добро пожаловать!</h2>

          {/* <p>
            Заполни анкету, чтобы получить полезные рекомендации. Ну или скидку{' '}
            <b>
              <PriceFormatted number={500} />
            </b>{' '}
            на первую покупку
          </p> */}

          <p>Вы были успешно зарегистрированы на нашем сайте!</p>

          {/* <Form>
            <div className="AcquaintanceDialog-tabs">
              <button
                type="button"
                className={cn('AcquaintanceDialog-imageTab', {
                  'AcquaintanceDialog-imageTab--active': showBody
                })}
                onClick={this.handleBodyTabClick}
              >
                <div className="AcquaintanceDialog-image">
                  <BodyImage />
                </div>
              </button>
              <button
                type="button"
                className={cn('AcquaintanceDialog-imageTab', {
                  'AcquaintanceDialog-imageTab--active': showFace
                })}
                onClick={this.handleFaceTabClick}
              >
                <div className="AcquaintanceDialog-image">
                  <FaceImage />
                </div>
              </button>
              <button
                type="button"
                className={cn('AcquaintanceDialog-imageTab', {
                  'AcquaintanceDialog-imageTab--active': showHair
                })}
                onClick={this.handleHairTabClick}
              >
                <div className="AcquaintanceDialog-image">
                  <HairImage />
                </div>
              </button>
            </div>

            {showBody && (
              <Form.Row>
                <h4 className="AcquaintanceDialog-label">Тип кожи тела</h4>
                <Field>
                  <FieldsetChoice>
                    <FieldsetChoice.Item>
                      <Radio
                        name="body_skin_type"
                        label="Нормальная"
                        value=""
                        onChange={this.handleBodyChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="body_skin_type"
                        label="Жирная"
                        value=""
                        onChange={this.handleBodyChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="body_skin_type"
                        label="Сухая и чувствительная"
                        value=""
                        onChange={this.handleBodyChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="body_skin_type"
                        label="Смешанная"
                        value=""
                        onChange={this.handleBodyChange}
                      />
                    </FieldsetChoice.Item>
                  </FieldsetChoice>
                </Field>
              </Form.Row>
            )}

            {showFace && (
              <Form.Row>
                <h4 className="AcquaintanceDialog-label">Тип кожи лица</h4>
                <Field>
                  <FieldsetChoice>
                    <FieldsetChoice.Item>
                      <Radio
                        name="face_skin_type"
                        label="Нормальная и комбинированная"
                        value=""
                        onChange={this.handleFaceChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="face_skin_type"
                        label="Жирная"
                        value=""
                        onChange={this.handleFaceChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="face_skin_type"
                        label="Сухая и чувствительная"
                        value=""
                        onChange={this.handleFaceChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="face_skin_type"
                        label="Проблемная"
                        value=""
                        onChange={this.handleFaceChange}
                      />
                    </FieldsetChoice.Item>
                  </FieldsetChoice>
                </Field>
              </Form.Row>
            )}

            {showHair && (
              <Form.Row>
                <h4 className="AcquaintanceDialog-label">Тип волос</h4>
                <Field>
                  <FieldsetChoice>
                    <FieldsetChoice.Item>
                      <Radio
                        name="hair_type"
                        label="Нормальные"
                        value=""
                        onChange={this.handleHairChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="hair_type"
                        label="Сухие"
                        value=""
                        onChange={this.handleHairChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="hair_type"
                        label="Жирные"
                        value=""
                        onChange={this.handleHairChange}
                      />
                    </FieldsetChoice.Item>
                    <FieldsetChoice.Item>
                      <Radio
                        name="hair_type"
                        label="Окрашенные"
                        value=""
                        onChange={this.handleHairChange}
                      />
                    </FieldsetChoice.Item>
                  </FieldsetChoice>
                </Field>
              </Form.Row>
            )}

            {done ? (
              <Form.ActionRow>
                <Button
                  title="Готово"
                  variant="primary"
                  onClick={dialogProps.onClose}
                />
              </Form.ActionRow>
            ) : (
              <Form.ActionRow>
                <Button title="Пропустить" onClick={dialogProps.onClose} />
              </Form.ActionRow>
            )}
          </Form> */}
          <Form.ActionRow>
            <Button title="Закрыть" variant="primary" onClick={dialogProps.onClose} />
          </Form.ActionRow>
        </div>
      </Dialog>
    );
  }

  done() {
    this.setState({
      done: true
    });
  }

  showBody() {
    this.setState({
      current: 'body'
    });
  }

  showFace() {
    this.setState({
      current: 'face'
    });
  }

  showHair() {
    this.setState({
      current: 'hair'
    });
  }

  handleBodyChange = () => {
    if (this.state.done) return;
    setTimeout(() => this.showFace(), 300);
  };

  handleFaceChange = () => {
    if (this.state.done) return;
    setTimeout(() => this.showHair(), 300);
  };

  handleHairChange = () => {
    if (this.state.done) return;
    setTimeout(() => this.done(), 200);
  };

  handleBodyTabClick = () => {
    this.showBody();
  };

  handleFaceTabClick = () => {
    this.showFace();
  };

  handleHairTabClick = () => {
    this.showHair();
  };
}

export default AcquaintanceDialog;
