import * as PRODUCTS from '../types/products';
import { createAction } from 'modules/utils/dux';

export default {
  click(slug, listId, position) {
    return createAction(PRODUCTS.CLICK, { slug, listId, position });
  },
  reveal(list) {
    return createAction(PRODUCTS.REVEAL, { list });
  },
  positionsUpdate(positions) {
    return createAction(PRODUCTS.POSITIONS_UPDATE, { positions });
  },
  overviewShowed(product, isInRecent) {
    return createAction(PRODUCTS.OVERVIEW_SHOWED, { product, isInRecent });
  },

  changeOption(product, currentVariant, variantOption) {
    return createAction(PRODUCTS.CHANGE_OPTION, {
      product,
      currentVariant,
      variantOption
    });
  },

  showItem(slug) {
    return createAction(PRODUCTS.SHOW_ITEM, {
      slug
    });
  },

  addItem(slug, product) {
    return createAction(PRODUCTS.ADD_ITEM, {
      slug,
      product
    });
  },

  addDetails(slug, details) {
    return createAction(PRODUCTS.ADD_DETAILS, {
      slug,
      details
    });
  },

  instantOrder(product) {
    return createAction(PRODUCTS.INSTANT_ORDER_STARTED, { product });
  },

  addToWaitListRequest(product_slug, email) {
    return createAction(PRODUCTS.ADD_TO_WAIT_LIST_REQUEST, {
      product_slug,
      email
    });
  },

  addToWaitListResponse() {
    return createAction(PRODUCTS.ADD_TO_WAIT_LIST_RESPONSE);
  }
};
