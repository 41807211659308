import React, { Component } from 'react';
import './icon-photo.css';

class IconPhoto extends Component {
  render() {
    return (
      <svg className="IconPhoto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19 19H5c-1.1 0-2-.9-2-2.1V9c0-1.1.9-2.1 2-2.1h2.2c0-1 .9-1.9 1.9-1.9h5.7c1.1 0 1.9.9 2 1.9H19c1.1 0 2 .9 2 2.1v7.9c0 1.2-.9 2.1-2 2.1zM5 7.9c-.6 0-1 .5-1 1.1v7.9c0 .6.5 1.1 1 1.1h14c.6 0 1-.5 1-1.1V9c0-.6-.5-1.1-1-1.1h-2.6c-.3 0-.5-.2-.5-.5v-.3c0-.6-.5-1.1-1-1.1H9.1c-.6 0-1 .5-1 1.1v.3c0 .3-.2.5-.5.5H5z" />
        <path d="M12 16.1c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-5.1c-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1s2.1-1 2.1-2.1S13.2 11 12 11z" />
      </svg>
    );
  }
}

export default IconPhoto;
