import { mixitApi } from 'services/mixit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { replace } from 'connected-react-router';
import { getError404Link } from 'routes/links';
import * as PRODUCTS from '../ducks/types/products';
import * as fromProducts from '../ducks/products';
import * as fromAccount from 'modules/core/ducks/account';
import ProductsActions from '../ducks/products/actions';

export default function* product() {
  yield takeEvery(PRODUCTS.SHOW_ITEM, showItem);
  yield takeEvery(PRODUCTS.ADD_TO_WAIT_LIST_REQUEST, addToWaitList);
  yield takeEvery(PRODUCTS.OVERVIEW_SHOWED, handleOverviewShowed);
}

function* addToWaitList({ payload }) {
  const { product_slug, email } = payload;

  try {
    yield call(delay, 1000);

    yield call(mixitApi().addToWaitingList, product_slug, email);
    yield put(fromProducts.actions.addToWaitListResponse());
  } catch (e) {
    console.warn(e);
  }
}

function* showItem({ payload }) {
  const { slug } = payload;

  const product = yield select(fromProducts.getItemBySlug, { slug });
  if (!product || product.shouldBeUpdated) {
    yield call(fetchProduct, slug);
  }

  if (!product || !product.details) {
    const { error, details } = yield call(fetchDetails, slug);

    if (error) {
      if (error.isFailed) {
        return;
      }
      yield put(replace(getError404Link()));
      return;
    }

    yield put(ProductsActions.addDetails(slug, details));
  }
}

function* handleOverviewShowed(action) {
  const { slug } = action.payload.product;

  const isDetailsLoaded = yield select(fromProducts.getIsDetailsLoaded, {
    slug
  });

  if (isDetailsLoaded) {
    return;
  }

  const { error, details } = yield call(fetchDetails, slug);

  if (error) {
    console.log(error);
    return;
  }

  yield put(ProductsActions.addDetails(slug, details));
}

function* fetchProduct(slug) {
  const authorization = yield select(fromAccount.getAuthorization);
  const productApi = mixitApi(authorization).products(slug);

  try {
    const productResponse = yield call(productApi.item);
    const product = productResponse.data;

    yield put(ProductsActions.addItem(slug, product));
  } catch (e) {
    console.error(e);
    if (e.isFailed) {
      return;
    }
    yield put(replace(getError404Link()));
  }
}

function* fetchDetails(slug) {
  const authorization = yield select(fromAccount.getAuthorization);
  const productApi = mixitApi(authorization).products(slug);
  try {
    const detailsResponse = yield call(productApi.details);
    const details = detailsResponse.data;
    return { details };
  } catch (e) {
    return { error: e.message };
  }
}
