import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { DashboardCell } from './dashboard-cell';

const WIDTH = {
  small: 'small',
  medium: 'medium',
  half: 'half',
  wide: 'wide',
  full: 'full'
};

const { element, oneOfType, arrayOf, string, node, oneOf } = PropTypes;

export class DashboardStrictCell extends Component {
  static propTypes = {
    /** Содержимое ячейки сетки */
    children: oneOfType([element, arrayOf(element), node, string]),
    /** параметр предопределенной ширины */
    width: oneOf(['small', 'medium', 'half', 'wide', 'full'])
  };

  render() {
    const { children, width, ...rest } = this.props;
    return (
      <DashboardCell
        className={cn('DashboardGrid-cell--strictCell', {
          [`DashboardGrid-cell--${WIDTH[width]}Cell`]: width
        })}
        {...rest}
      >
        {children}
      </DashboardCell>
    );
  }
}

export default DashboardStrictCell;
