import qs from 'qs';
import getHeaders from './helpers/get-headers';
import markFailedRequest from './helpers/mark-failed-request';
import checkStatus from './helpers/check-status';
import parseJson from './helpers/parse-json';

import initCategoriesRequest from './categories';
import initProductsRequest from './products';
import initAccountRequest from './account';
import initSubscriptionListRequest from './subscription-list';
import initProfileRequest from './profile';
import initProductGroupsRequest from './product-groups';
import initBasketRequest from './basket';
import initOrdersRequest from './orders';
import initIngredientsRequest from './ingredients';
import initSearchRequest from './search';
import initSuggestRequest from './suggest';
import initDeliveryMethodsRequest from './delivery-methods';
import initPaymentMethodsRequest from './payment-methods';
import initPickPointsRequest from './pick-points';
import initlBlogRequest from './blog';
import initSocialRequest from './social';
import initBannersRequest from './banners';
import initShopListRequest from './shop-list';
import initSubscriptionRequest from './subscription';
import initAddToWaitingListRequest from './add-to-waiting-list';
import initTargetOffersRequest from './target-offers.js';
import initFeedbackRequest from './feedback';
import initEmailFeedbackRequest from './email-feedback';
import initLocationRequest from './location';
import initCategoryRequest from './mass-media';
import initFaqRequest from './faq';

// Singleton object
let _mixitApi;

function toFormData(data) {
  const formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key]);
  }

  return formData;
}

function createMakeRequest(baseUrl, authorization) {
  return function makeRequest(request) {
    const headers = getHeaders(authorization, request.multipart);
    const queryString = qs.stringify(request.query, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true
    });

    const url = baseUrl.concat(request.path);
    const urlWithQuery = url.concat('?').concat(queryString);

    return fetch(queryString ? urlWithQuery : url, {
      method: request.method || 'GET',
      credentials: 'same-origin',
      headers,
      body: request.multipart ? toFormData(request.data) : JSON.stringify(request.data)
    })
      .catch(markFailedRequest)
      .then(checkStatus)
      .then(parseJson);
  };
}

function createRequest(requestMethod) {
  return function(requestBuilder) {
    return function(...parameters) {
      return requestMethod(requestBuilder(...parameters));
    };
  };
}

function MixitAPI(host, { protocol = 'https', suffix = 'api/v1' } = {}) {
  const baseUrl = `${protocol}://${host}/${suffix}`;

  return function _getMixitApiRequests(authorization = null) {
    const requestMethod = createMakeRequest(baseUrl, authorization);
    const makeRequest = createRequest(requestMethod);

    return {
      categories: initCategoriesRequest(makeRequest),
      products: initProductsRequest(makeRequest),
      account: initAccountRequest(makeRequest),
      productGroups: initProductGroupsRequest(makeRequest),
      basket: initBasketRequest(makeRequest),
      orders: initOrdersRequest(makeRequest),
      ingredients: initIngredientsRequest(makeRequest),
      profile: initProfileRequest(makeRequest),
      subscriptionList: initSubscriptionListRequest(makeRequest),
      search: initSearchRequest(makeRequest),
      suggest: initSuggestRequest(makeRequest),
      deliveryMethods: initDeliveryMethodsRequest(makeRequest),
      paymentMethods: initPaymentMethodsRequest(makeRequest),
      pickPoints: initPickPointsRequest(makeRequest),
      blog: initlBlogRequest(makeRequest),
      social: initSocialRequest(makeRequest),
      banners: initBannersRequest(makeRequest),
      shopListRequest: initShopListRequest(makeRequest),
      subscriptions: initSubscriptionRequest(makeRequest),
      addToWaitingList: initAddToWaitingListRequest(makeRequest),
      targetOffers: initTargetOffersRequest(makeRequest),
      feedback: initFeedbackRequest(makeRequest),
      emailFeedback: initEmailFeedbackRequest(makeRequest),
      location: initLocationRequest(makeRequest),
      massMedia: initCategoryRequest(makeRequest),
      faq: initFaqRequest(makeRequest)
    };
  };
}

export function initApi(...params) {
  _mixitApi = MixitAPI(...params);
}

export function mixitApi(...params) {
  return _mixitApi(...params);
}

export default MixitAPI;
