import React, { Component } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Media from 'modules/core/components/media/media';
import articleShape from '../../prop-types/article-shape';
import textSlicer from 'modules/utils/text-slicer';

import dateString from 'modules/utils/date-string';

import { getArticleLink } from 'routes/links';

import './article-vertical-preview.css';

class ArticalVerticalPreview extends Component {
  static propTypes = {
    article: articleShape.isRequired
  };

  render() {
    const { article } = this.props;

    return (
      <article className="ArticalVerticalPreview">
        <div className="ArticalVerticalPreview-imageHolder">
          <Media
            className="ArticalVerticalPreview-image"
            media={article.cover}
            resizeWidth={400}
            stretch="horizontal"
            nocaption
          />
        </div>

        <div className="ArticalVerticalPreview-metaHolder">
          <time className="ArticalVerticalPreview-publicationDate" dateTime={article.createDate}>
            {this.getHumanizedPublishDate()}
          </time>
        </div>

        <div className="ArticalVerticalPreview-contentHolder">
          <h2 className="ArticalVerticalPreview-title">
            <RouterLink to={getArticleLink(article)} className="ArticalVerticalPreview-link">
              {article.title}
            </RouterLink>
          </h2>
          <div className="ArticalVerticalPreview-description">
            {textSlicer(article.description, 176)}
          </div>
        </div>
      </article>
    );
  }

  getHumanizedPublishDate() {
    const { article } = this.props;

    const date = new Date(article.createDate);
    return dateString(date);
  }
}

export default ArticalVerticalPreview;
