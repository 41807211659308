import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Radio from 'modules/form/components/radio/radio';
import Link from 'modules/core/components/link/short-link';
import Price from '../price-formatted/price-formatted';
import PickPointDialogContainer from '../../containers/pick-point-dialog';

const { func, any } = PropTypes;

// const FREE_DELIVERY_COST = 3000;

class DeliveryMethodChoice extends Component {
  static propTypes = {
    list: any.isRequired,
    selected: any.isRequired,
    selectedPickPoint: any.isRequired,
    onPickPointChange: func.isRequired,
    onChange: func.isRequired
  };

  render() {
    const { list } = this.props;

    return (
      <Fragment>
        <div className="DeliveryForm-deliveryList">{list.map(this.renderRadioItem)}</div>
      </Fragment>
    );
  }

  renderRadioItem = item => {
    return (
      <div key={item.id} className="DeliveryForm-deliveryItem">
        <Radio
          label={this.renderLabel(item)}
          name="delivery"
          value={item.id}
          checked={this.isSelected(item)}
          onChange={this.handleRadioChange(item)}
        />
      </div>
    );
  };
  componentDidUpdate() {
    this.preselectItem();
  }
  componentDidMount() {
    this.preselectItem();
  }

  preselectItem = () => {
    const { list, selected } = this.props;

    if (!selected) {
      this.handleRadioChange(list[0])();
    }
  };

  renderPickPointChoice(pickPointType) {
    const {
      city,
      isDialogShowed,
      selected,
      selectedPickPoint,
      onPickPointChange,
      onPickPointDialogShow,
      onPickPointDialogClose
    } = this.props;

    return (
      <span className="DeliveryForm-actionList">
        {selectedPickPoint ? (
          <Fragment>
            <span className="DeliveryForm-pickPoint">{selectedPickPoint.title}</span>
            {/* <span className="DeliveryForm-action">
              <Link
                type="button"
                component="button"
                onClick={this.handleLookMapClick}
              >
                См.на карте
              </Link>
            </span> */}
            <span className="DeliveryForm-action">
              <Link type="button" component="button" onClick={onPickPointDialogShow}>
                Изменить
              </Link>
            </span>
          </Fragment>
        ) : (
          <span className="DeliveryForm-action">
            <Link type="button" component="button" onClick={onPickPointDialogShow}>
              Выбрать пункт выдачи
            </Link>
          </span>
        )}
        {selected.pickPointType === 'PICK_POINT_TYPE/PPD' && (
          <div className="DeliveryForm-WarningMassege">
            Состав подарков к заказу и упаковка для наборов может быть заменена, исходя из наличия
            товара в магазине
          </div>
        )}
        <PickPointDialogContainer
          expanded
          closeable
          city={city}
          show={isDialogShowed}
          selected={selectedPickPoint}
          idList={selected.pickPoints}
          isLoaded={selected.isLoaded}
          onClose={onPickPointDialogClose}
          onChange={onPickPointChange}
          pickPointType={pickPointType}
        />
      </span>
    );
  }

  renderLabel(item) {
    const { title, price, additionalInfo, pickPointType, isNoDeliveryRussia } = item;

    const {
      //  productsCost,
      maxDeliveryPrice,
      isDeliveryFree
    } = this.props;

    // const deliveryPrice = noRussia ? price.current : maxDeliveryPrice === 0 ? 0 : price.current;
    let deliveryPrice = isNoDeliveryRussia || maxDeliveryPrice !== 0 ? price.current : 0;

    if (isDeliveryFree && !isNoDeliveryRussia) {
      deliveryPrice = 0;
    }

    return (
      <span className="DeliveryForm-method">
        <span className="DeliveryForm-methodTitle">
          {title} –{' '}
          {pickPointType === 'PICK_POINT_TYPE/PPD' ? (
            <div className="DeliveryForm-freeDeliveryCaption">бесплатно</div>
          ) : (
            <Price number={deliveryPrice} variant="mark" />
          )}
        </span>
        {additionalInfo && (
          <Fragment>
            <br />
            <span className="DeliveryForm-methodTime">{additionalInfo}</span>
          </Fragment>
        )}
        {this.isSelected(item) && item.pickPointRequired && (
          <Fragment>
            <br />
            {this.renderPickPointChoice(pickPointType)}
          </Fragment>
        )}
      </span>
    );
  }

  isSelected(deliveryMethod) {
    const { selected } = this.props;
    if (!selected) {
      return false;
    }
    return selected.id === deliveryMethod.id;
  }

  handleRadioChange = deliveryMethod => () => {
    const { onChange } = this.props;
    onChange(deliveryMethod);
  };

  handleLookMapClick = () => {
    this.setState({ isDialogShow: true });
  };
}

export default DeliveryMethodChoice;
