import React, { Component } from 'react';
import Price from '../price-formatted/price-formatted';
import './order-summary.css';

class OrderSummary extends Component {
  render() {
    const {
      productsCost,
      deliveryCost,
      totalCost,
      discount,
      deliveryMethodExist,
      method
    } = this.props;
    const isPPD = method && method.pickPointType && method.pickPointType === 'PICK_POINT_TYPE/PPD';

    return (
      <div className="OrderSummary">
        <div className="OrderSummary-item">
          <span className="OrderSummary-title">Стоимость товаров:</span>
          <span className="OrderSummary-value">
            <Price number={productsCost} variant="secondary" />
          </span>
        </div>
        {/* {deliveryCost !== null && ( */}
        {(Boolean(deliveryCost) || deliveryMethodExist) && (
          <div className="OrderSummary-item">
            <span className="OrderSummary-title">Доставка:</span>
            <span className="OrderSummary-value">
              {isPPD ? <b>бесплатно</b> : <Price number={deliveryCost} variant="secondary" />}
              {/* <Price number={Number(deliveryCost)} variant="secondary" /> */}
            </span>
          </div>
        )}
        {/* {!!deliveryCost && !!estimatedDeliveryCost && (
          <div className="OrderSummary-item">
            <span className="OrderSummary-title">Доставка:</span>
            <span className="OrderSummary-value">
              <Price number={estimatedDeliveryCost} variant="secondary" />
            </span>
          </div>
        )} */}
        {discount > 0 && (
          <div className="OrderSummary-item">
            <span className="OrderSummary-title">Скидка:</span>
            <span className="OrderSummary-value">
              <Price number={-discount} variant="discount" />
            </span>
          </div>
        )}
        <div className="OrderSummary-total">
          <span className="OrderSummary-totalTitle">Итого:</span>
          <span className="OrderSummary-totalValue">
            <Price number={totalCost} />
          </span>
        </div>
      </div>
    );
  }
}

export default OrderSummary;
