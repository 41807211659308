import { createReducer, createAction, handle as on } from 'modules/utils/dux';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

import * as fromCityDelivery from './city-delivery';
import * as fromProfile from 'modules/profile/ducks/profile';
import * as fromPickPointList from './pick-point-list';
import * as PickPoint from '../domain/pick-point';
import * as fromCityTable from './city-table';
import * as fromDeliveryMethodTable from './delivery-method-table';
import areaCodes from 'modules/core/components/input-phone-with-area-code/area-codes';
import getSeparatedPhone from 'modules/core/components/input-phone-with-area-code/helpers/get-separated-phone';
import plural from 'modules/utils/plural';

import * as fromCityList from 'modules/geography/ducks/city-list';

/* CONSTANTS */
const NEW_ADDRESS_ID = 'NEW_ADDRESS_ID';

const NEW_ADDRESS_OPTION = {
  id: NEW_ADDRESS_ID,
  full: 'Добавить новый адрес',
  isNew: true
};

/* TYPES */
const CHANGE_DELIVERY_METHOD_ID = 'ordering/delivery-form/CHANGE_DELIVERY_METHOD';
const CHANGE_GEOGRAPHY_ID = 'ordering/delivery-form/CHANGE_GEOGRAPHY_ID';
const CHANGE_CITY_FIAS_ID = 'ordering/delivery-form/CHANGE_CITY_FIAS_ID';
const CHANGE_CITY_GEONAME_ID = 'ordering/delivery-form/CHANGE_CITY_GEONAME_ID';
const CHANGE_STREET = 'ordering/delivery-form/CHANGE_STREET';
const CHANGE_STREET_BULK = 'ordering/delivery-form/CHANGE_STREET_BULK';
const CHANGE_STREET_STRING = 'ordering/delivery-form/CHANGE_STREET_STRING';
const CHANGE_STREET_FIAS_ID = 'ordering/delivery-form/CHANGE_STREET_FIAS_ID';
const CHANGE_BUILDING = 'ordering/delivery-form/CHANGE_BUILDING';
const CHANGE_APARTMENT = 'ordering/delivery-form/CHANGE_APARTMENT';
const CHANGE_PICK_POINT = 'ordering/delivery-form/CHANGE_PICK_POINT';
const CHANGE_PHONE = 'ordering/delivery-form/CHANGE_PHONE';
const CHANGE_FULL_NAME = 'ordering/delivery-form/CHANGE_FULL_NAME';
const CHANGE_FIRST_NAME = 'ordering/delivery-form/CHANGE_FIRST_NAME';
const CHANGE_LAST_NAME = 'ordering/delivery-form/CHANGE_LAST_NAME';
const CHANGE_ADDRESS_ID = 'ordering/delivery-form/CHANGE_ADDRESS_ID';
const RESET = 'ordering/delivery-form/RESET';
const SET_INITIAL_DATA = 'ordering/delivery-form/SET_INITIAL_DATA';
const RESET_RECIPIENT_DATA = 'ordering/delivery-form/RESET_RECIPIENT_DATA';
const SET_INITIAL_RECIPIENT_DATA = 'ordering/delivery-form/SET_INITIAL_RECIPIENT_DATA';

export const types = {
  SET_INITIAL_DATA,
  CHANGE_CITY_FIAS_ID,
  CHANGE_CITY_GEONAME_ID,
  SET_INITIAL_RECIPIENT_DATA
};

/**
 * match(
 *  expression,
 *  on(2, doThis),
 *  on(3, doThat)
 * )
 */

/* REDUCERS */
const cityId = createReducer(
  on(
    SET_INITIAL_DATA,
    (state, { city }) =>
      (city &&
        ((city.fiasId && { fiasId: city.fiasId, title: city.title }) ||
          (city.geonameId && { geonameId: city.geonameId }))) ||
      state
  ),
  on(CHANGE_CITY_FIAS_ID, (state, { fiasId, city: { title } }) => ({ fiasId, title })),
  on(CHANGE_CITY_GEONAME_ID, (state, { geonameId }) => ({ geonameId })),
  on(RESET, () => null)
);

const deliveryMethodId = createReducer({
  [CHANGE_DELIVERY_METHOD_ID]: (state, { deliveryMethodId }) => deliveryMethodId,
  [RESET]: () => null
});

const pickPointId = createReducer({
  [CHANGE_PICK_POINT]: (state, { pickPoint }) => {
    return PickPoint.getId(pickPoint) || state;
  },
  [CHANGE_DELIVERY_METHOD_ID]: () => null,
  [CHANGE_CITY_FIAS_ID]: () => null,
  [CHANGE_CITY_GEONAME_ID]: () => null,
  [RESET]: () => null
});

const addressId = createReducer({
  [CHANGE_ADDRESS_ID]: (state, { addressId }) => addressId,
  [RESET]: () => null
});

const street = createReducer({
  [CHANGE_STREET]: (state, { street }) => street,
  [CHANGE_STREET_STRING]: (state, { streetString }) => ({
    ...state,
    raw: streetString,
    fiasId: null,
    title: null,
    type: null
  }),
  [CHANGE_STREET_FIAS_ID]: (state, { streetFiasId }) => ({
    ...state,
    fiasId: streetFiasId
  }),
  [CHANGE_STREET_BULK]: (state, { address }) => {
    return {
      fiasId: address.streetFiasId,
      title: address.street,
      type: address.streetType,
      raw: address.title
    };
  },

  [SET_INITIAL_DATA]: (state, { address }) => {
    if (!address || !address.street || !address.building) {
      return state;
    }
    return {
      title: address.street,
      raw: `${address.street}, ${address.building}`,
      fiasId: null,
      type: null
    };
  },
  [RESET]: () => ({
    raw: '',
    title: null,
    fiasId: null,
    type: null
  })
});

const building = createReducer({
  [CHANGE_BUILDING]: (state, { building }) => building,
  [CHANGE_STREET_BULK]: (state, { address }) => address.building,
  [SET_INITIAL_DATA]: (state, { address }) => {
    if (!address || !address.building) {
      return state;
    }
    return address.building;
  },
  [RESET]: () => null
});

const apartment = createReducer({
  [CHANGE_APARTMENT]: (state, { apartment }) => apartment,
  [SET_INITIAL_DATA]: (state, { address }) => {
    if (!address || !address.apartment) {
      return state;
    }
    return address.apartment;
  },
  [RESET]: () => null
});

const recipientPhone = createReducer(
  on(SET_INITIAL_DATA, (state, { phone }) => phone || state),
  on(SET_INITIAL_RECIPIENT_DATA, (state, { phone }) => phone || state),
  on(CHANGE_PHONE, (state, { phone }) => phone),
  on(RESET, () => null),
  on(RESET_RECIPIENT_DATA, () => '')
);

const recipientName = createReducer(
  on(SET_INITIAL_DATA, (state, { firstName }) => firstName || state),
  on(SET_INITIAL_RECIPIENT_DATA, (state, { firstName }) => firstName || state),
  on(CHANGE_FULL_NAME, (state, { fullName }) => fullName),
  on(RESET, () => null),
  on(RESET_RECIPIENT_DATA, () => '')
);
const recipientFirstName = createReducer(
  on(SET_INITIAL_DATA, (state, { firstName }) => firstName.split(' ')[0] || state),
  on(SET_INITIAL_RECIPIENT_DATA, (state, { firstName }) => firstName.split(' ')[0] || state),
  on(CHANGE_FIRST_NAME, (state, { firstName }) => firstName),
  on(RESET, () => null),
  on(RESET_RECIPIENT_DATA, () => '')
);
const recipientLastName = createReducer(
  on(
    SET_INITIAL_DATA,
    (state, data) =>
    {
      const {lastName} = data;

      return lastName;
    }
      
  ),
  on(
    SET_INITIAL_RECIPIENT_DATA,
    (state, data) =>
    {
      const {lastName} = data;
      return lastName;
    }
      
  ),
  on(CHANGE_LAST_NAME, (state, { lastName }) => lastName),
  on(RESET, () => null),
  on(RESET_RECIPIENT_DATA, () => '')
);

const address = combineReducers({
  addressId: addressId(null),
  street: street({
    title: null,
    type: null,
    fiasId: null,
    raw: ''
  }),
  building: building(''),
  apartment: apartment('')
});

export default combineReducers({
  cityId: cityId(null),
  deliveryMethodId: deliveryMethodId(null),
  pickPointId: pickPointId(null),
  address: address,
  recipientPhone: recipientPhone(''),
  recipientName: recipientName(''),
  recipientFirstName: recipientFirstName(''),
  recipientLastName: recipientLastName('')
});

/* SELECTORS */
const makeFieldSelector = fieldName =>
createSelector(getRoot, root => {
    if (!root) {
      return null;
    }

    return root[fieldName];
  });

export const getRoot = state => state.ordering.deliveryForm;
export const getCityId = makeFieldSelector('cityId');

export const getDeliveryCityTitle = createSelector(getCityId, city => {
  return city && city.title ? city.title : '';
});

export const getDeliveryFrom = createSelector(
  getDeliveryCityTitle,
  fromCityList.getAllList,
  (title, cityList) => {
    const filteredList = cityList.filter(item => item.title.toLowerCase() === title.toLowerCase());
    return filteredList[0] ? filteredList[0].freeDeliveryFrom : 3000;
  }
);

export const getCity = createSelector(
  getCityId,
  fromCityTable.getFindCityById,
  (cityId, findCityById) => {
    if (!cityId) {
      return null;
    }

    return findCityById(cityId);
  }
);

export const getCityFiasId = createSelector(getCityId, cityId => {
  if (!cityId) {
    return null;
  }

  return cityId.fiasId;
});

export const getHasCity = createSelector(getCityId, cityId => {
  if (!cityId) {
    return false;
  }

  return true;
});

export const getDeliveryMethodId = makeFieldSelector('deliveryMethodId');
export const getPickPointId = makeFieldSelector('pickPointId');
export const getRecipientPhone = makeFieldSelector('recipientPhone');
export const getRecipientName = makeFieldSelector('recipientName');
export const getRecipientFirstName = makeFieldSelector('recipientFirstName');
export const getRecipientLastName = makeFieldSelector('recipientLastName');

export const getAddress = createSelector(getRoot, root => root.address);
export const getAddressId = createSelector(getAddress, address => address.id);
export const getStreetString = createSelector(getAddress, address => address.street.raw);
export const getStreetFiasId = createSelector(getAddress, address => address.street.fiasId);
export const getBuilding = createSelector(getAddress, address => address.building);
export const getApartment = createSelector(getAddress, address => {
  return address.apartment;
});

export const getMethodList = createSelector(
  fromCityDelivery.getMethodListByCityId,
  getCityId,
  (methodListByCityId, cityId) => {
    if (cityId && cityId.fiasId) {
      return methodListByCityId(cityId.fiasId);
    }
    if (cityId && cityId.geonameId) {
      return methodListByCityId(cityId.geonameId);
    }
    return null;
  }
);

export const getLoadingByCityId = createSelector(
  fromCityDelivery.getLoadingStateByCityId,
  getCityId,
  (loadingStateByCityId, cityId) => {
    if (cityId && cityId.fiasId) {
      return loadingStateByCityId(cityId.fiasId);
    }

    if (cityId && cityId.geonameId) {
      return loadingStateByCityId(cityId.geonameId);
    }

    return false;
  }
);

export const getDeliveryMethod = createSelector(
  fromDeliveryMethodTable.getFindItemById,
  getDeliveryMethodId,
  (findDeliveryMethodById, methodId) => findDeliveryMethodById(methodId)
);

export const getSavedAddresses = createSelector(fromProfile.getDeliveryAddresses, addresses => [
  ...addresses,
  NEW_ADDRESS_OPTION
]);

export const getSavedAddress = createSelector(
  getAddressId,
  fromProfile.getAccessDeliveryAddressById,
  (addressId, accessAddressById) => {
    if (addressId === NEW_ADDRESS_ID) {
      return NEW_ADDRESS_OPTION;
    }

    return accessAddressById(addressId);
  }
);

export const getDeliveryCost = createSelector(getDeliveryMethod, function _getDeliveryCost(
  deliveryMethod
) {
  if (!deliveryMethod) {
    return null;
  }
  return deliveryMethod.price.current;
});

export const getDeliveryFromByMethod = createSelector(
  getDeliveryMethod,
  function _getDeliveryFromByMeyhod(deliveryMethod) {
    if (!deliveryMethod) {
      return null;
    }

    if (!deliveryMethod.freeDeliveryFrom) {
      return null;
    }
    return deliveryMethod.freeDeliveryFrom;
  }
);

export const getDeliveryFromByDeliveryMethod = createSelector(
  getDeliveryFrom,
  getDeliveryFromByMethod,
  (deliveryFrom, deliveryFromByMethod) => {
    return deliveryFromByMethod || deliveryFrom;
  }
);

export const getCityValidation = createSelector(getCityId, cityId => {
  if (cityId) {
    return null;
  }
  return [validationError('Выберите город из списка')];
});

export const getDeliveryMethodValidation = createSelector(getDeliveryMethodId, deliveryMethodId => {
  if (deliveryMethodId) {
    return null;
  }

  return [validationError('Выберите метод доставки')];
});

export const getPickPointValidation = createSelector(
  getDeliveryMethod,
  getPickPointId,
  (deliveryMethod, pickPointId) => {
    if (!deliveryMethod) {
      return null;
    }
    return (
      deliveryMethod.pickPointRequired &&
      !pickPointId && [validationError('Выберите пункт самовывоза')]
    );
  }
);

export const getPhoneValidation = createSelector(
  getDeliveryMethod,
  getRecipientPhone,
  (deliveryMethod, phone) => {
    const cleanedPhone = phone ? phone.replace(/[^0-9+]/gi, '') : '';
    const phoneLength =
      cleanedPhone && cleanedPhone.length && getSeparatedPhone(cleanedPhone, areaCodes).code
        ? getSeparatedPhone(cleanedPhone, areaCodes).code.phoneLength
        : areaCodes[0].phoneLength;

    if (!deliveryMethod || !deliveryMethod.phoneRequired) {
      return null;
    }

    if (cleanedPhone.length - 1 !== phoneLength) {
      return [
        validationError(
          `Номер должен содержать ${phoneLength} цифр, не хватает ${phoneLength -
            cleanedPhone.length +
            1}${plural(phoneLength - cleanedPhone.length + 1, ['-й', '-x', '-ти'])}`
        )
      ];
    }

    return deliveryMethod.phoneRequired && !phone && [validationError('Укажите номер телефона')];
  }
);

export const getFullNameValidation = createSelector(
  getDeliveryMethod,
  getRecipientName,
  (deliveryMethod, fullName) => {
    if (!deliveryMethod) {
      return null;
    }

    if (deliveryMethod.fullNameRequired && !fullName) {
      return [validationError('Укажите имя и фамилию получателя')];
    }

    const specialCharactersRegExp = new RegExp(
      `^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"\`\\/<>?:;|=.,{}()[\\]]+$`
    );

    if (deliveryMethod.fullNameRequired && !specialCharactersRegExp.test(fullName)) {
      return [
        validationError(
          'Имя пользователя может содержать кириллицу (а-я) <nobr>и латиницу (a-z)</nobr>'
        )
      ];
    }

    if (!fullName) {
      return;
    }

    const lastNameIndex = fullName.indexOf(' ');
    const lastName = fullName.slice(lastNameIndex).trim();

    if (deliveryMethod.fullNameRequired && (lastNameIndex === -1 || !lastName)) {
      return [validationError('Введите, пожалуйста, фамилию')];
    }

    return false;
  }
);

export const getFirstNameValidation = createSelector(
  getDeliveryMethod,
  getRecipientFirstName,
  (deliveryMethod, firstName) => {
    if (!deliveryMethod) {
      return null;
    }

    if (deliveryMethod.fullNameRequired && !firstName) {
      return [validationError('Укажите имя получателя')];
    }

    const specialCharactersRegExp = new RegExp(
      `^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"\`\\/<>?:;|=.,{}()[\\]]+$`
    );

    if (deliveryMethod.fullNameRequired && !specialCharactersRegExp.test(firstName)) {
      return [validationError('Имя может содержать кириллицу (а-я) и латиницу (a-z)')];
    }

    if (!firstName) {
      return;
    }

    return false;
  }
);
export const getLastNameValidation = createSelector(
  getDeliveryMethod,
  getRecipientLastName,
  (deliveryMethod, lastName) => {
    if (!deliveryMethod) {
      return null;
    }

    if (deliveryMethod.fullNameRequired && !lastName) {
      return [validationError('Укажите фамилию получателя')];
    }

    const specialCharactersRegExp = new RegExp(
      `^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"\`\\/<>?:;|=.,{}()[\\]]+$`
    );

    if (deliveryMethod.fullNameRequired && !specialCharactersRegExp.test(lastName)) {
      return [validationError('Фамилия может содержать кириллицу (а-я) и латиницу (a-z)')];
    }

    if (!lastName) {
      return;
    }

    return false;
  }
);

export const getAddressValidation = createSelector(
  getDeliveryMethod,
  getAddress,
  (deliveryMethod, address) => {
    if (!deliveryMethod || !deliveryMethod.addressRequired) {
      return null;
    }

    if (!address || (!address.street.raw && !address.street.fiasId)) {
      return [validationError('Укажите улицу и дом')];
    }

    if (address.street.fiasId && !address.building) {
      return [validationError('Укажите дом')];
    }

    return null;
  }
);

export const getApartmentValidation = createSelector(
  getDeliveryMethod,
  getAddress,
  (deliveryMethod, address) => {
    if (!deliveryMethod || !deliveryMethod.addressRequired) {
      return null;
    }

    if (!address || !address.apartment) {
      return [validationError('Укажите квартиру')];
    }

    return null;
  }
);

export const getGeography = createSelector(getAddress, getCity, function _getGeography(
  address,
  city
) {
  return {
    address,
    city
  };
});

export const getPickPoint = createSelector(
  fromPickPointList.makeGetItemById,
  getPickPointId,
  function _getPickPoint(getPickPointById, pickPointId) {
    return getPickPointById(pickPointId);
  }
);

export const getIsValid = createSelector(
  getCityValidation,
  getDeliveryMethodValidation,
  getPickPointValidation,
  getPhoneValidation,
  getFirstNameValidation,
  getLastNameValidation,
  getApartmentValidation,
  getAddressValidation,
  (
    cityValidation,
    deliveryMethodValidation,
    pickPointValidation,
    phoneValidation,
    firstNameValidation,
    lastNameValidation,
    addressValidation,
    apartmentValidation
  ) => {
    // console.log("cityValidation", cityValidation)
    // console.log("deliveryMethodValidation", deliveryMethodValidation)
    // console.log("pickPointValidation", pickPointValidation)
    // console.log("firstNameValidation", firstNameValidation)
    // console.log("lastNameValidation", lastNameValidation)
    // console.log("addressValidation", addressValidation)
    // console.log("apartmentValidation", apartmentValidation)
    if (
      cityValidation ||
      deliveryMethodValidation ||
      pickPointValidation ||
      phoneValidation ||
      firstNameValidation ||
      lastNameValidation ||
      addressValidation ||
      apartmentValidation
      ) {
        console.log("apartmentValidation", apartmentValidation)
        console.log("addressValidation", addressValidation)
        console.log("firstNameValidation", firstNameValidation)
        console.log("cityValidation", cityValidation)
        console.log("phoneValidation", phoneValidation)
        console.log("deliveryMethodValidation", deliveryMethodValidation)
      return false;
    }

    return true;
  }
);
export const getIsValidExceptFullName = createSelector(
  getCityValidation,
  getDeliveryMethodValidation,
  getPickPointValidation,
  getPhoneValidation,
  getApartmentValidation,
  getAddressValidation,
  (
    cityValidation,
    deliveryMethodValidation,
    pickPointValidation,
    phoneValidation,
    addressValidation,
    apartmentValidation
  ) => {
    if (
      cityValidation ||
      deliveryMethodValidation ||
      pickPointValidation ||
      phoneValidation ||
      addressValidation ||
      apartmentValidation
    ) {
      return false;
    }

    return true;
  }
);

/* ACTIONS */
export const actions = {
  changeCityFiasId: (fiasId, city) => createAction(CHANGE_CITY_FIAS_ID, { fiasId, city }),

  changeCity: (geonameId, city) => createAction(CHANGE_CITY_GEONAME_ID, { geonameId, city }),

  changeDeliveryMethodId: deliveryMethodId =>
    createAction(CHANGE_DELIVERY_METHOD_ID, {
      deliveryMethodId
    }),

  changePickPoint(pickPoint) {
    return createAction(CHANGE_PICK_POINT, { pickPoint });
  },

  changeGeographyId: geographyId =>
    createAction(CHANGE_GEOGRAPHY_ID, {
      geographyId
    }),

  changeStreetString: streetString => createAction(CHANGE_STREET_STRING, { streetString }),

  changeStreetFiasId: streetFiasId => createAction(CHANGE_STREET_FIAS_ID, { streetFiasId }),

  changeStreetAndBuilding(address) {
    return createAction(CHANGE_STREET_BULK, { address });
  },

  changeBuilding: building =>
    createAction(CHANGE_BUILDING, {
      building
    }),

  changeApartment: apartment =>
    createAction(CHANGE_APARTMENT, {
      apartment
    }),

  changeRecipientPhone: phone =>
    createAction(CHANGE_PHONE, {
      phone
    }),

  changeRecipientName: fullName =>
    createAction(CHANGE_FULL_NAME, {
      fullName
    }),

  changeRecipientFirstName: firstName =>
    createAction(CHANGE_FIRST_NAME, {
      firstName
    }),
  changeRecipientLastName: lastName =>
    createAction(CHANGE_LAST_NAME, {
      lastName
    }),

  changeAddressId: addressId => createAction(CHANGE_ADDRESS_ID, { addressId }),

  setInitial(city, firstName, phone, address, lastName="") {
    return createAction(SET_INITIAL_DATA, {
      city,
      firstName,
      phone,
      address,
      lastName
    });
  },

  setInitialRecipientData(firstName, phone, lastName) {
    return createAction(SET_INITIAL_RECIPIENT_DATA, {
      firstName,
      phone,
      lastName
    });
  },

  resetInitialRecipientData() {
    return createAction(RESET_RECIPIENT_DATA, {
      firstName: '',
      phone: ''
    });
  },

  reset() {
    return createAction(RESET);
  }
};

const validationError = message => {
  return {
    message
  };
};
