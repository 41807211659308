import videoListSeeds from './seeds/video-list';
import { createReducer /*, createAction */ } from 'modules/utils/dux';
// import { createSelector } from 'reselect';

// import { getArticleList } from '../ducks/';

/* REDUCER NAME */
export const NAME = 'videoList';

/* ACTIONS */

/* REDUCERS */

const reducer = createReducer({});

export default reducer(videoListSeeds);

/* SELECTORS */

export const getRoot = state => state.blog.videoList;
// export const getRoot = getArticleList;

export const getAll = getRoot;

/* ACTION_CREATORS */

/* HELPERS */
