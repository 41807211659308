import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from 'modules/core/components/dialog/dialog';
import Field from 'modules/form/components/field/field';
import Form from 'modules/form/components/form/form';
import PasswordInput from 'modules/form/components/password-input/password-input';

import Button from '../button/button';

import './confirm-password-recovery-dialog.css';

// Regex Letters, Numbers, Dashes, and Underscores
const LATIN_AND_NUMBERS = /([A-Za-z0-9\-_]+)/;

// const LATIN_REGEX = /[a-zA-Z]/;

// for more complicated passwords:
// Minimum eight characters, at least one letter and one number:
// "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"

// Minimum eight characters, at least one letter, one number and one special character:
// "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"

// Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"

const PASSWORD_REQUIRED_ERROR = 'Пароль является обязательным полем';
const PASSWORD_SHORT_ERROR = 'Используйте пароль не менее 6-ти символов';
const PASSWORD_NO_LATIN_ERROR = 'Пароль может содержать только латинские символы и цифры';
const PASSWORDS_NOT_EQUAL = 'Пароли не совпадают';

const credentialsSchema = Yup.object().shape({
  password: Yup.string()
    .required(PASSWORD_SHORT_ERROR)
    .min(6, PASSWORD_REQUIRED_ERROR)
    .matches(LATIN_AND_NUMBERS, PASSWORD_NO_LATIN_ERROR),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], PASSWORDS_NOT_EQUAL)
    .required(PASSWORD_REQUIRED_ERROR)
});

class ConfirmPasswordRecoveryDialog extends Component {
  render() {
    const { ...rest } = this.props;

    const initialValues = {
      password: '',
      confirmPassword: ''
    };

    return (
      <Dialog closeable {...rest}>
        <Formik
          validationSchema={credentialsSchema}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
            <div className="ConfirmPasswordRecoveryDialog">
              <h1 className="ConfirmPasswordRecoveryDialog-title">Изменение пароля</h1>
              <p className="ConfirmPasswordRecoveryDialog-info">Укажите Ваш новый пароль</p>

              <div className="ConfirmPasswordRecoveryDialog-form">
                <Form onSubmit={handleSubmit}>
                  <Media query="(max-width: 840px)">
                    {isMobile => (
                      <Fragment>
                        <Form.LabeledRow>
                          <Field
                            label="Пароль"
                            error={touched.password && errors.password}
                            size={isMobile ? 'small' : 'normal'}
                          >
                            <PasswordInput
                              name="password"
                              value={values.password}
                              state={touched.password && errors.password && 'error'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              size={isMobile ? 'small' : 'normal'}
                              type="password"
                            />
                          </Field>
                        </Form.LabeledRow>
                        <Form.LabeledRow>
                          <Field
                            label="Подтверждение пароля"
                            error={touched.confirmPassword && errors.confirmPassword}
                            size={isMobile ? 'small' : 'normal'}
                          >
                            <PasswordInput
                              name="confirmPassword"
                              value={values.confirmPassword}
                              state={touched.confirmPassword && errors.confirmPassword && 'error'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              size={isMobile ? 'small' : 'normal'}
                              type="password"
                            />
                          </Field>
                        </Form.LabeledRow>
                        <Form.Row>
                          <Field>
                            <Button
                              title="Применить пароль"
                              variant="primary"
                              type="submit"
                              size={isMobile ? 'small' : 'normal'}
                              expanded
                            />
                          </Field>
                        </Form.Row>
                      </Fragment>
                    )}
                  </Media>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </Dialog>
    );
  }

  handleSubmit = data => {
    const { onClose, confirmationKey, onSubmit } = this.props;

    const requestData = {
      new_password: data.password,
      confirmation_key: confirmationKey
    };

    onSubmit(requestData);
    onClose();
  };
}

export default ConfirmPasswordRecoveryDialog;
