import React from 'react';
import cn from 'classnames';

import './landing-section-title.css';

function LandingSectionTitle({ data, reverse, verticalReverse, light, centered, noGap }) {
  let topText;
  let bottomText;

  if (Array.isArray(data)) {
    topText = data[0];
    bottomText = data[1];
  } else {
    topText = data.top || '';
    bottomText = data.bottom || '';
    reverse = data.hasOwnProperty('reverse') ? data.reverse : reverse;
    verticalReverse = data.hasOwnProperty('verticalReverse')
      ? data.verticalReverse
      : verticalReverse;
    light = data.hasOwnProperty('light') ? data.light : light;
    centered = data.hasOwnProperty('centered') ? data.centered : centered;
    noGap = data.hasOwnProperty('noGap') ? data.noGap : noGap;
  }

  const holderStyle = {
    fontSize: data.fontSize || 'inherit'
  };

  const topTextStyle = {
    ...(data.color && !verticalReverse && { WebkitTextStrokeColor: data.color }),
    ...(data.color && verticalReverse && { color: data.color }),
    ...(data.topStyle && { ...data.topStyle })
  };

  const bottomTextStyle = {
    ...(data.color && !verticalReverse && { color: data.color }),
    ...(data.color && verticalReverse && { WebkitTextStrokeColor: data.color }),
    ...(data.bottomStyle && { ...data.bottomStyle })
  };

  return (
    <div style={holderStyle}>
      <div
        className={cn('LandingSectionTitle', {
          'LandingSectionTitle--reverse': reverse,
          'LandingSectionTitle--verticalReverse': verticalReverse,
          'LandingSectionTitle--light': light,
          'LandingSectionTitle--centered': centered,
          'LandingSectionTitle--noGap': noGap
        })}
      >
        <div className="LandingSectionTitle-holder">
          <div className="LandingSectionTitle-top" style={topTextStyle}>
            {topText}
          </div>
          <div className="LandingSectionTitle-bottom" style={bottomTextStyle}>
            {bottomText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingSectionTitle;
