import React, { Component } from 'react';
import cn from "classnames"
import PropTypes from 'prop-types';

const { any } = PropTypes;

class FormLeftLabeledRow extends Component {
  static propTypes = {
    children: any.isRequired
  };

  render() {
    const { children, smallGap } = this.props;
    return <div className={
      cn("Form-leftLabeledRow", {
        "Form-leftLabeledRow--smallGap": smallGap
      })
    }>{children}</div>;
  }
}

export default FormLeftLabeledRow;
