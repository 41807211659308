import React, { Component } from 'react';
import VolumeSwitch from 'modules/core/components/switch/volume-switch/volume-switch';
import VolumeSwitchOption from 'modules/core/components/switch/volume-switch/volume-switch-option';
import VolumeDropdown from 'modules/core/components/volume-dropdown/volume-dropdown';

export default class VolumeChoice extends Component {
  render() {
    const { options } = this.props;

    const props = {
      ...this.props,
      onChange: this.handleChange
    };

    if (options.length === 1) {
      return <VolumeSwitchOption volume={options[0].value} unit={options[0].payload.unit} single />;
    }

    return options.length <= 3 ? <VolumeSwitch {...props} /> : <VolumeDropdown {...props} />;
  }

  handleChange = optionId => {
    const { onChange, options } = this.props;

    const currentOption = options.filter(item => item.id === optionId)[0];

    onChange(currentOption);
  };
}
