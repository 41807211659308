export function serialize(promo) {
  if (promo._isRemoving) {
    return null;
  }

  return { id: promo.id, code: promo.code, option_id: promo.optionId };
}

export function deserialize(promo) {
  const { is_applied, ...rest } = promo;
  return {
    isApplied: is_applied,
    ...rest
  };
}
