import plural from 'modules/utils/plural';
import parseDuration from 'modules/utils/parse-duration';

const TIME_STRINGS = {
  year: ['год', 'года', 'лет'],
  month: ['месяц', 'месяца', 'месяцев'],
  day: ['день', 'дня', 'дней'],
  hour: ['час', 'часа', 'часов'],
  minute: ['минута', 'минуты', 'минут'],
  second: ['секунда', 'секунды', 'секунд']
};

const TIME_STRINGS_SHORT = {
  year: ['г', 'г', 'л'],
  month: ['мес', 'мес', 'мес'],
  day: ['д', 'д', 'д'],
  hour: ['ч', 'ч', 'ч'],
  minute: ['мин', 'мин', 'мин'],
  second: ['сек', 'сек', 'сек']
};

export const formatDuration = (duration, short) => {
  const durationObj = parseDuration(duration);

  const durationArray = [];

  const isDurationStringValid = durationObj;

  let selectedMeasureUnits = short ? TIME_STRINGS_SHORT : TIME_STRINGS;

  if (!isDurationStringValid) {
    throw new Error('Invalid duration string');
  }

  for (let unit in durationObj) {
    const timeValue = durationObj[unit];
    const arrayOfMeasureUnit = selectedMeasureUnits[unit];
    const declinedMeasureUnit = plural(timeValue, arrayOfMeasureUnit);
    const normalizedTimeString = `${timeValue} ${declinedMeasureUnit}`;
    const hasTimeProperty = durationObj[unit];

    if (!durationObj.hasOwnProperty(unit)) {
      continue;
    }
    if (!hasTimeProperty) {
      continue;
    }
    durationArray.push(normalizedTimeString);
  }

  return durationArray.join(' ');
};
