import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromAccount from '../ducks/account';
import { actions as Dialogs } from '../ducks/dialogs';

import LoginDialogRoute from '../components/login-dialog-route/login-dialog-route';

const mapState = createStructuredSelector({
  isAuthorized: fromAccount.getIsAuthorized
});

const mapActions = {
  openAuthDialog: Dialogs.openAuthDialog
};

class LoginDialogRouteContainer extends Component {
  render() {
    const { isAuthorized, openAuthDialog } = this.props;

    return (
      <LoginDialogRoute
        isAuthorized={isAuthorized}
        openAuthDialog={openAuthDialog}
        {...this.props}
      />
    );
  }
}

export default connect(
  mapState,
  mapActions
)(LoginDialogRouteContainer);
