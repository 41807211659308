import React, { Component } from 'react';
import Page from 'modules/core/components/page/page';

import FeedbackThanks from 'modules/core/components/feedback-thanks/feedback-thanks';

import { getIndexLink } from 'routes/links';

class FeedbackThanksPage extends Component {
  static path = '/feedback/thanks';
  static link = () => 'feedback/thanks';
  static exact = true;

  render() {
    return (
      <Page
        title="Спасибо за отзыв!"
        breadcrumbs={[
          {
            name: 'Главная',
            link: getIndexLink()
          },
          {
            name: 'Спасибо за отзыв'
          }
        ]}
      >
        <FeedbackThanks comment="Ваши замечания и пожелания помогают нам стать лучше" />
      </Page>
    );
  }

  scrollWindowToTop() {
    if (__BROWSER__) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.scrollWindowToTop();
  }
}

export default FeedbackThanksPage;
